<template>
  <div class="z-form">
    <ZField
      :label="label"
      :class="errors.has(internalName) ? 'is-danger' : ''"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      :isShort="isShort"
    >
      <div class="z-form__input" :class="unit ? 'has-unit' : ''">
        <b-input
          :name="internalName"
          :icon="icon"
          :size="size"
          :type="type"
          :placeholder="placeholder || label + 'を入力してください'"
          v-model="internalValue"
          :data-vv-as="as || label"
          v-validate="validate"
          :maxlength="maxlength"
          :password-reveal="passwordReveal"
          :onkeydown="type === 'number' ? 'return event.keyCode !== 69' : ''"
        >
        </b-input>
        <span v-if="unit" class="z-form__input__unit">{{ unit }}</span>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZInput",
  mixins: [InputMixin],
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    unit: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-form {
  &__input {
    position: relative;
    .is-short & {
      display: inline-block;
    }
    &.has-unit {
      /deep/ .input {
        padding-right: 30px;
      }
    }
    &__unit {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 18px;
      @include sp {
        font-size: 16px;
      }
    }
  }
}
</style>
