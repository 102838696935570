<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <!-- <h1>会計ステータス</h1>
      <b-select
        v-model="transaction.data.statusOfAccounting"
        class="select-margin"
      >
        <template v-for="o in $$ks.transaction.statusOfAccountingTypes">
          <option :value="o.value" :key="o.text">
            {{ o.text }}
          </option>
        </template>
      </b-select>
      <ZDatepicker
        label="専門家フィー入金日"
        v-model="transaction.data.paymentDt"
        placeholder="例)2010/01/01"
      />
      <ZDatepicker
        label="投資家キャッシュバック日"
        v-model="transaction.data.cashBackDt"
        placeholder="例)2010/01/01"
      />
      <button
        class="button button-margin"
        @click="save"
      >
        変更
      </button> -->

      <b-tabs>
        <b-tab-item label="会計ステータス">
          <div class="box">
            <h1>会計ステータス</h1>
            <b-select
              v-model="transaction.data.statusOfAccounting"
              class="select-margin"
            >
              <template v-for="o in $$ks.transaction.statusOfAccountingTypes">
                <option :value="o.value" :key="o.text">
                  {{ o.text }}
                </option>
              </template>
            </b-select>
            <ZDatepicker
              label="専門家フィー入金日"
              v-model="transaction.data.paymentDt"
              placeholder="例)2010/01/01"
            />
            <ZDatepicker
              label="投資家キャッシュバック日"
              v-model="transaction.data.cashBackDt"
              placeholder="例)2010/01/01"
            />
            <button class="button button-margin" @click="save">変更</button>
          </div>
        </b-tab-item>
        <b-tab-item label="成約情報">
          <div class="box">
            <section class="edit">
              <section class="edit__section">
                <z-input
                  label="投資家氏名"
                  v-model="transaction.data.user.name"
                  :validate="'required'"
                  placeholder="例)島崎 怜平"
                  maxlength="32"
                  isRequired
                />
                <z-input
                  label="電話番号"
                  v-model="transaction.data.user.phoneNumber"
                  type="tel"
                  placeholder="例)09011111111"
                  maxlength="11"
                  isRequired
                  :validate="'required|min:10|max:11|numeric'"
                />
                <z-select
                  label="契約種別"
                  v-model="transaction.data.transactionType"
                  :options="$$ks.realtor.realEstate.transactionTypes"
                  :validate="'required'"
                  isRequired
                />
                <ZRadio
                  label="購入 / 売却"
                  v-model="transaction.data.tradeType"
                  :options="$$ks.transaction.tradeTypes"
                  :validate="'required'"
                  isRequired
                />
                <ZDatepicker
                  label="売買契約日"
                  v-model="transaction.data.contractDate"
                  :validate="'required'"
                  placeholder="例)1999/01/01"
                  :maxOfToday="false"
                  isRequired
                />
                <ZDatepicker
                  label="決済実行日"
                  v-model="transaction.data.settlementDate"
                  :validate="'required'"
                  placeholder="例)1999/01/01"
                  :maxOfToday="false"
                  isRequired
                />
                <ZPriceInput
                  label="取引金額"
                  v-model="transaction.data.price"
                  placeholder="例)¥500,000"
                  :validate="'required|price_min_value:1'"
                  maxlength="12"
                  isRequired
                />
                <ZPriceInput
                  label="仲介手数料"
                  v-model="transaction.data.fee"
                  placeholder="例)¥500,000"
                  :validate="'required|price_min_value:1'"
                  maxlength="12"
                  isRequired
                />
              </section>

              <hr class="edit__hr" />

              <section class="edit__section">
                <h2 class="edit__subtitle">売買物件情報</h2>
                <z-input
                  label="物件名"
                  v-model="transaction.data.realEstate.name"
                  :validate="'required'"
                  maxlength="100"
                  isRequired
                />
                <z-select
                  label="物件種別"
                  v-model="transaction.data.realEstate.realEstateType"
                  :options="realEstateTypes"
                  :validate="'required'"
                  isRequired
                />
                <z-price-input
                  label="販売価格"
                  v-model="transaction.data.price"
                  maxlength="12"
                  placeholder="例)100,000,000"
                  :validate="'required|price_min_value:1 '"
                  isRequired
                />
                <z-price-input
                  label="年間想定収入"
                  v-model="transaction.data.realEstate.annualEstimatedIncome"
                  :validate="'required|price_min_value:0'"
                  maxlength="12"
                  placeholder="例)20,000,000"
                  helpMessage="当該物件が満室、かつ、想定賃料（又は現在の賃料）が一年間得られた場合の収入を想定。公租公課その他物件を維持するために必要な費用（管理費等）を控除する前のもの。"
                  isRequired
                />
                <CouponYieldRate
                  label="表面利回り"
                  v-model="transaction.data.realEstate.couponYieldRate"
                  placeholder="例)8.00"
                  disabled
                  isShort
                  isRequired
                />
                <template
                  v-if="
                    transaction.data.realEstate.realEstateType !== 7 &&
                    transaction.data.realEstate.realEstateType !== 10
                  "
                >
                  <z-select
                    label="現況空室率"
                    v-model="transaction.data.realEstate.vacancyRate"
                    :options="vacancyRates"
                    :validate="'required'"
                    isRequired
                  />
                </template>
                <template v-if="transaction.data.realEstate.vacancyRate === 2">
                  <z-input
                    label="空き室数"
                    v-model="transaction.data.realEstate.vacantRoom"
                    :validate="'required'"
                    placeholder="例)10部屋"
                    isRequired
                    maxlength="30"
                  />
                </template>

                <!-- 物件画像 -->
                <h3 class="edit__subtitle">物件画像</h3>
                <ZField label="外観" isRequired>
                  <ZThumbnailListSelect
                    v-model="transaction.data.realEstate.images.exteriors"
                    title="外観"
                    :max="10"
                    v-validate="'required'"
                    name="外観"
                  />
                </ZField>

                <hr class="edit__hr2" />

                <ZField label="内装">
                  <ZThumbnailListSelect
                    v-model="transaction.data.realEstate.images.interiors"
                    title="内装"
                    :max="10"
                  />
                </ZField>

                <hr class="edit__hr2" />

                <ZField label="間取り図・プラン">
                  <ZThumbnailListSelect
                    v-model="transaction.data.realEstate.images.layouts"
                    title="間取り図・プラン"
                    :max="10"
                  />
                </ZField>

                <hr class="edit__hr2" />

                <ZField label="その他">
                  <ZThumbnailListSelect
                    v-model="transaction.data.realEstate.images.others"
                    title="その他"
                    :max="10"
                  />
                </ZField>

                <!-- / 物件画像 -->
              </section>

              <hr class="edit__hr" />

              <section class="edit__section">
                <h3 class="edit__subtitle">所在地</h3>
                <z-prefecture-select
                  label="都道府県"
                  v-model="transaction.data.realEstate.prefecture"
                  :validate="'required'"
                  isRequired
                >
                </z-prefecture-select>

                <z-city-select
                  label="市区町村"
                  v-model="transaction.data.realEstate.city"
                  :prefecture="transaction.data.realEstate.prefecture"
                  :validate="'required'"
                  isRequired
                >
                </z-city-select>

                <z-input
                  label="番地"
                  v-model="transaction.data.realEstate.address"
                  placeholder="番地：1-1-1"
                  maxlength="30"
                  :validate="'required'"
                  isRequired
                />

                <z-input
                  label="マンション名・部屋番号"
                  v-model="transaction.data.realEstate.building"
                  maxlength="40"
                  placeholder="マンション名：ZIRITZ TOWER 1111号室"
                />

                <!-- 交通 -->
                <h3 class="edit__subtitle edit__transportation">交通</h3>

                <ul class="edit__list">
                  <li
                    v-for="(transport, i) in transaction.data.realEstate
                      .transports"
                    :index="i"
                    :key="i"
                    class="edit__list__item"
                  >
                    <z-edit-accordion :title="'交通' + (i + 1)" isProtrude>
                      <div>
                        <z-line-select
                          label="沿線"
                          :prefecture="transaction.data.realEstate.prefecture"
                          v-model="transport.line"
                          :validate="'required'"
                          isRequired
                        >
                        </z-line-select>
                        <z-station-select
                          label="駅"
                          :line="transport.line"
                          v-model="transport.station"
                          :validate="'required'"
                          isRequired
                        >
                        </z-station-select>

                        <z-input
                          label="駅からの距離（徒歩）"
                          v-model.number="transport.elapsedTime"
                          :validate="'required|integer|min_value:1|max_value:99'"
                          type="number"
                          maxlength="2"
                          placeholder="例)8"
                          unit="分"
                          isRequired
                          isShort
                        />

                        <div class="has-text-right">
                          <delete-button
                            v-if="i > 0"
                            label="削除する"
                            @click="deleteTransport(i)"
                          />
                        </div>
                      </div>
                    </z-edit-accordion>
                  </li>
                </ul>
                <add-button label="追加する" @click="addTransport()" />
                <!-- / 交通 -->
              </section>

              <hr class="edit__hr" />

              <!-- 建物情報 -->
              <template
                v-if="
                  transaction.data.realEstate.realEstateType !== 7 &&
                  transaction.data.realEstate.realEstateType !== 10
                "
              >
                <section class="edit__section">
                  <h3 class="edit__subtitle">建物情報</h3>

                  <z-select
                    label="建物種別"
                    v-model="transaction.data.realEstate.buildingType"
                    :options="typeOfBuildingTypes"
                    helpMessage="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
                    :validate="'required'"
                    isRequired
                  />

                  <z-year-month-select
                    label="築年月"
                    v-model.number="
                      transaction.data.realEstate.constructionDate
                    "
                    :validate="'required'"
                    isRequired
                  />

                  <z-input
                    label="建物面積"
                    v-model.number="transaction.data.realEstate.buildingArea"
                    :validate="'required|max_value:99999.99'"
                    type="number"
                    placeholder="例)100.00"
                    unit="㎡"
                    isRequired
                    isShort
                  />

                  <z-input
                    label="階数"
                    v-model="transaction.data.realEstate.numberOfFloors"
                    :validate="'required'"
                    maxlength="30"
                    placeholder="例)1階/6階建て"
                    helpMessage="建物の階数、及び物件の階数。"
                    isRequired
                  />

                  <!-- 間取り -->
                  <h3 class="edit__subtitle edit__layout">間取り</h3>

                  <ul class="edit__list">
                    <li
                      v-for="(lo, i) in transaction.data.realEstate.layouts"
                      :index="i"
                      :key="i"
                      class="edit__list__item"
                    >
                      <z-edit-accordion :title="'間取り' + (i + 1)" isProtrude>
                        <div>
                          <z-layout-select
                            label="間取"
                            :validate="'required'"
                            isRequired
                            v-model="transaction.data.realEstate.layouts[i]"
                          />
                          <div class="has-text-right">
                            <delete-button
                              v-if="i > 0"
                              label="削除する"
                              @click="deleteLayout(i)"
                            />
                          </div>
                        </div>
                      </z-edit-accordion>
                    </li>
                  </ul>
                  <add-button label="追加する" @click="addLayout()" />
                  <!--/ 間取り -->

                  <div class="edit__total-units">
                    <z-input
                      label="総戸数"
                      v-model="transaction.data.realEstate.numberOfHouseholds"
                      type="number"
                      maxlength="3"
                      placeholder="例)12"
                      helpMessage="その物件にある住戸の数の合計数。"
                      unit="戸"
                      :validate="'required|integer|min_value:1|max_value:999'"
                      isShort
                      isRequired
                    />
                  </div>

                  <z-input
                    label="駐車場"
                    v-model="transaction.data.realEstate.parking"
                    maxlength="100"
                    placeholder="例)屋根付き、10車まで駐車可能"
                    helpMessage="駐車場の有無。台数、料金などを表示。"
                    :validate="'required'"
                    isRequired
                  />
                </section>

                <hr class="edit__hr" />
              </template>
              <!-- / 建物情報 -->

              <!-- 土地情報 -->
              <section class="edit__section">
                <h3 class="edit__subtitle">土地情報</h3>

                <z-input
                  label="土地面積"
                  v-model.number="transaction.data.realEstate.landArea"
                  type="number"
                  placeholder="例)100.00"
                  unit="㎡"
                  :validate="'required|max_value:99999.99'"
                  isRequired
                />

                <z-select
                  label="土地権利"
                  v-model.number="transaction.data.realEstate.landRight"
                  :options="landRightTypes"
                  :validate="'required'"
                  isRequired
                  helpMessage="土地の権利形態。大きく、一般的な土地の売買である「所有権」、他人から土地を借りて、その上に自分の建物を建てる場合に生じる「借地権」がある。"
                />

                <z-select
                  label="地目"
                  v-model.number="transaction.data.realEstate.texture"
                  :options="textureTypes"
                  :validate="'required'"
                  isRequired
                  helpMessage="土地の現況と利用状況による区分のこと。「宅地」、「雑種地」、「畑」、「用悪水路」、「その他」で表示。"
                />

                <z-radio
                  label="都市計画区域"
                  v-model.number="transaction.data.realEstate.urbanPlanningArea"
                  :options="urbanPlanningAreaTypes"
                  :validate="'required'"
                  isRequired
                  helpMessage="都道府県ごとに定められ、無秩序な市街化を防止し、計画的な市街化を図るために定めている。「市街化区域」：優先的かつ計画的に市街化を進める区域「市街化調整区域」：開発行為は原則として抑制され、都市施設の整備も原則として行われない区域"
                />

                <z-select
                  label="用途地域"
                  v-model.number="transaction.data.realEstate.areaOfUse"
                  :options="areaOfUseTypes"
                  :validate="'required'"
                  isRequired
                  helpMessage="用途の混在を防ぐ目的で定められた、13種類の区域。設定された区域ごとに建築物の用途、容積率、構造等に関し一定の制限を加えることができる。"
                />

                <z-percent-input
                  label="建蔽率"
                  v-model.number="
                    transaction.data.realEstate.buildingCoverageRatio
                  "
                  :decimal="2"
                  placeholder="例)60"
                  maxlength="3"
                  :validate="'required|max_value:99999.99'"
                  isShort
                  isRequired
                  helpMessage="敷地（土地）面積に対する建築面積の割合。"
                />

                <z-percent-input
                  label="容積率"
                  v-model.number="transaction.data.realEstate.floorAreaRatio"
                  :decimal="2"
                  placeholder="例)300"
                  maxlength="4"
                  :validate="'required|max_value:99999.99'"
                  isShort
                  isRequired
                  helpMessage="敷地（土地）面積に対する建物の延床面積の割合。"
                />

                <z-input
                  label="私道負担面積"
                  v-model.number="
                    transaction.data.realEstate.roadBurdenAreaRatio
                  "
                  :validate="'required|max_value:99999.99'"
                  type="number"
                  placeholder="例)100.00"
                  maxlength="6"
                  unit="㎡"
                  isShort
                  isRequired
                />
              </section>
              <!-- / 土地情報 -->
              <button class="button button-margin" @click="save">変更</button>
            </section>
          </div>
        </b-tab-item>
        <b-tab-item label="融資情報">
          <div class="box">
            <z-price-input
              label="融資金額"
              v-model="transaction.data.loan.amount"
              :validate="'required|price_min_value:1'"
              maxlength="12"
              placeholder="例)100,000,000"
              isRequired
              isPrivate
            />

            <z-bank-select
              label="金融機関"
              v-model="transaction.data.loan.bankId"
              :validate="'required'"
              isRequired
              isPrivate
            />

            <z-branch-select
              label="支店名"
              :bank="transaction.data.loan.bankId"
              v-model="transaction.data.loan.branchId"
              :validate="'required'"
              isRequired
              isPrivate
            />

            <z-year-month-select
              label="融資実行年月"
              v-model="transaction.data.loan.executeDate"
              :validate="'required'"
              isRequired
              isPrivate
            />

            <z-year-month-period-input
              label="返済期間"
              v-model="transaction.data.loan.paymentPeriods"
              :validate="'required'"
              isRequired
              isPrivate
            />

            <z-percent-input
              label="金利"
              v-model="transaction.data.loan.interestRate"
              :validate="'required|min_value:0.01|max_value:100'"
              :decimal="2"
              placeholder="例)1.55"
              isRequired
              isPrivate
              isShort
            />

            <z-radio
              label="返済方法"
              v-model="transaction.data.loan.method"
              :validate="'required'"
              :options="loanMethodTypes"
              helpMessage="融資・ローンの毎月の返済方法。元利均等返済：毎月の支払いの返済額が一定となる返済方法。元金均等返済：毎月の支払いの返済額のうち、元金の額が一定となる返済方法。"
              isRequired
              isPrivate
            />
            <button class="button button-margin" @click="save">変更</button>
          </div>
        </b-tab-item>
        <b-tab-item label="不動産担当者に対する評価">
          <div class="box">
            <section class="edit">
              <div class="edit__section">
                <RealtorProfile :user="staff" />
                <z-select
                  label="不動産提案力"
                  v-model="
                    transaction.data.realtorEvaluation.realEstateProposal
                  "
                  placeholder="５段階から選択してください"
                  :options="evaluationTypes"
                  :validate="'required'"
                  isRequired
                />
                <z-select
                  label="融資先金融機関提案力"
                  v-model="transaction.data.realtorEvaluation.bankProposal"
                  placeholder="５段階から選択してください"
                  :options="evaluationTypes"
                  :validate="'required'"
                  isRequired
                />
                <z-select
                  label="投資知識・事業計画提案力"
                  v-model="
                    transaction.data.realtorEvaluation
                      .investmentKnowledgeAndBussinessPlanProposal
                  "
                  placeholder="５段階から選択してください"
                  :options="evaluationTypes"
                  :validate="'required'"
                  isRequired
                />
                <z-select
                  label="信頼感・対応力"
                  v-model="transaction.data.realtorEvaluation.trustAndResponse"
                  placeholder="５段階から選択してください"
                  :options="evaluationTypes"
                  :validate="'required'"
                  isRequired
                />
                <z-input
                  label="コメント"
                  v-model="transaction.data.realtorEvaluation.comment"
                  type="textarea"
                  placeholder="（今回お世話になった担当者にコメントを送りましょう！）"
                  maxlength="1000"
                  isRequired
                  :validate="'required'"
                />
              </div>
            </section>
            <button class="button button-margin" @click="save">変更</button>
          </div>
        </b-tab-item>
        <b-tab-item label="振込先口座 登録">
          <div class="box">
            <section class="edit">
              <div class="edit__section">
                <z-bank-select
                  label="金融機関"
                  v-model="transaction.data.bankAccount.bankId"
                  :validate="'required'"
                  isRequired
                  isPrivate
                />

                <z-branch-select
                  label="支店名"
                  :bank="transaction.data.bankAccount.bankId"
                  v-model="transaction.data.bankAccount.branchId"
                  :validate="'required'"
                  isRequired
                  isPrivate
                />

                <z-radio
                  label="預金種別"
                  v-model="transaction.data.bankAccount.depositType"
                  :validate="'required|min_value:1'"
                  :options="depositTypes"
                  isRequired
                  isPrivate
                />

                <z-input
                  label="口座番号"
                  v-model="transaction.data.bankAccount.accountNumber"
                  placeholder="例)1111111"
                  maxlength="7"
                  isRequired
                  :validate="'required|min:7|max:7|numeric'"
                  isPrivate
                />

                <z-input
                  label="口座名義"
                  v-model="transaction.data.bankAccount.accountName"
                  type="text"
                  placeholder="例)ミントリ タロウ"
                  maxlength="32"
                  isRequired
                  :validate="'required'"
                  isPrivate
                />
              </div>
            </section>
            <button class="button button-margin" @click="save">変更</button>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
import CouponYieldRate from "@/views/admin/components/CouponYieldRate";
import RealtorProfile from "@/components/realtor/RealtorProfile";

export default {
  name: "admin-setting-admin-transaction-detail",
  data: function () {
    return {
      isLoading: true,
      transaction: null,
      staff: null,
      realEstateTypes: kubuns.realEstateTypes,
      // realEstateTypes: kubuns.realEstateTypes,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
      landRightTypes: kubuns.landRightTypes,
      textureTypes: kubuns.textureTypes,
      urbanPlanningAreaTypes: kubuns.urbanPlanningAreaTypes,
      areaOfUseTypes: kubuns.areaOfUseTypes,
      vacancyRates: kubuns.realtor.realEstate.vacancyRates,
      loanMethodTypes: kubuns.loanMethodTypes,
      evaluationTypes: kubuns.transaction.evaluationTypes,
      depositTypes: kubuns.transaction.depositTypes,
    };
  },
  components: {
    CouponYieldRate,
    RealtorProfile,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    calcCouponYieldRate() {
      const calcResult =
        (10 ** 4 * this.transaction.data.realEstate.annualEstimatedIncome) /
        this.transaction.data.price;
      return Math.round(calcResult) / 100;
      // this.calcResult =
      //   (10 ** 4 * this.transaction.data.realEstate.annualEstimatedIncome) /
      //   this.transaction.data.price;
      // return Math.round(this.calcResult) / 100;
    },
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        // const transaction = await this.$$transaction$saveRealtorTransaction(
        //   this.auth.uid,
        //   this.transaction
        // );
        this.$$toast$openSuccess();

        await this.$router.go(-1);
        // this.$router.replace({
        //   name: "admin-setting-admin-transaction-detail",
        //   params: {
        //     id: transaction.data.link
        //   }
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.transaction =
          await this.$$transaction$loadRealtorTransactionByLink(this.id);
        this.staff = await this.$$realtor$loadUser(
          this.transaction.data.realtor
        );
        if (this.transaction.data.realEstate.transports.length === 0) {
          this.addTransport();
        }
        if (this.transaction.data.realEstate.layouts.length === 0) {
          this.addLayout();
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
        this.isLoading = false;
      }
    },
    addTransport() {
      let item = this.transaction.data.realEstate.transports;
      item.push(this.$$realEstate$getNewTransport(this.auth.uid));
    },
    deleteTransport(index) {
      let item = this.transaction.data.realEstate.transports;
      item.splice(index, 1);
    },
    addLayout() {
      let item = this.transaction.data.realEstate.layouts;
      item.push(this.$$transaction$getNewLayout());
    },
    deleteLayout(index) {
      let item = this.transaction.data.realEstate.layouts;
      item.splice(index, 1);
    },
  },
  watch: {
    "transaction.data.realEstate.realEstateType": function () {
      if (
        this.transaction.data.realEstate.realEstateType === 7 ||
        this.transaction.data.realEstate.realEstateType === 10
      ) {
        this.transaction.data.realEstate.vacancyRate = null;
        this.transaction.data.realEstate.vacantRoom = null;
        this.transaction.data.realEstate.buildingType = null;
        this.transaction.data.realEstate.buildingArea = null;
        this.transaction.data.realEstate.numberOfFloors = null;
        this.transaction.data.realEstate.layouts = [];
        this.transaction.data.realEstate.numberOfHouseholds = null;
        this.transaction.data.realEstate.parking = null;
        this.transaction.data.realEstate.constructionDate = {
          month: null,
          year: null,
        };
      }
      if (this.transaction.data.realEstate.layouts.length === 0) {
        this.addLayout();
      }
    },
    "transaction.data.price": function () {
      if (
        this.transaction.data.realEstate.annualEstimatedIncome === null ||
        !this.transaction.data.price
      ) {
        this.transaction.data.realEstate.couponYieldRate = 0;
      } else {
        this.transaction.data.realEstate.couponYieldRate =
          this.calcCouponYieldRate;
      }
    },
    "transaction.data.realEstate.annualEstimatedIncome": function () {
      if (
        this.transaction.data.realEstate.annualEstimatedIncome === null ||
        !this.transaction.data.price
      ) {
        this.transaction.data.realEstate.couponYieldRate = 0;
      } else {
        this.transaction.data.realEstate.couponYieldRate =
          this.calcCouponYieldRate;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.button-margin {
  margin-top: 40px;
}

.select-margin {
  margin-bottom: 40px;
}

.edit {
  &__subtitle {
    padding-top: 0;
    padding-bottom: 14px;
    font-weight: bold;
    font-size: 18px;
  }
  &__hr {
    margin-top: 40px;
    margin-bottom: 20px;
    background-color: #707070;
  }
  &__transportation {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }
  &__layout {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  }
  &__total-units {
    margin-top: 25px;
  }
}
</style>
