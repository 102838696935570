<template>
  <div>
    <div v-if="suggestions.length >= 1" class="suggestions">
      <div class="suggestions__labels">
        <div class="suggestions__label">
          <p>提案の特徴:</p>
        </div>
      </div>
      <div class="suggestions__tags">
        <template v-for="sg in options">
          <div
            v-if="suggestions.some((s) => s === sg.value)"
            class="suggestions__tags__tag"
            :key="sg.value"
          >
            <p class="suggestions__tags__tag__text">
              {{ sg.text }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios-jsonp-pro";
export default {
  name: "SuggestionsView",
  props: {
    suggestions: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    options() {
      return this.$$ks.message.suggestions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.suggestions {
  display: flex;
  flex-direction: row;
  width: 100%;
  &__labels {
  }
  &__label {
    width: 69px;
    font-size: 12px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 12px;
    padding: 2px;
  }
  &__tags {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 8px;
    &__tag {
      background: rgba(#0062d7, 0.1);
      border-radius: 9.5px;
      padding: 2px 9px;
      margin-right: 10px;
      margin-bottom: 10px;

      &__text {
        font-size: 14px;
        line-height: 14px;
        color: #0062d7;
        letter-spacing: 0;
      }
    }
  }
}
</style>
