<template>
  <div class="z-radio">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      :isShort="isShort"
    >
      <div class="block">
        <template v-for="o in options">
          <b-radio
            :key="o.value"
            :name="internalName"
            :native-value="o.value"
            :data-vv-as="label"
            v-model="internalValue"
            v-validate="validate"
            type="is-info"
          >
            {{ o.text }}
          </b-radio>
        </template>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZRadio",
  mixins: [InputMixin],
  props: {
    options: {
      type: [Array],
      required: true,
    },
    value: {
      type: [Number, String],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
