<template>
  <div>
    <div class="section">
      <div class="content has-text-centered">
        <h4>
          {{ me.user.company.data.name }}<br />
          {{ me.user.data.base.name }}様
        </h4>
        <hr />
        <p>
          StockFormerをご利用いただき、<br
            class="br-show"
          />ありがとうございます。
        </p>
        <br class="br-sp" />

        <p>
          以下ボタンから、<br />
          ご担当者様のプロフィール情報の<br
            class="br-show"
          />登録にお進みください。
        </p>
        <br class="br-sp" />
      </div>
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'auth-realtor-welcome-basic' }"
          class="button is-primary z-button is-rounded is-small"
          >プロフィールを登録する</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

// import UserBasicEditList from "@/components/realtor/FormUserBasic";

export default {
  name: "realtors-welcome-basic",
  //components: {
  //  UserBasicEditList: UserBasicEditList
  //},
  data: function () {
    return {
      user: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.box {
  padding-left: 60px;
  padding-right: 60px;
  .content {
    margin-bottom: 0px;
  }
  @include mobile {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
</style>
