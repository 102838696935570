<template>
  <div class="contents">
    <div v-if="!isLoading">
      <section>
        <template v-for="rsInfo in investor.realEstates">
          <section class="z-section" :key="rsInfo.id">
            <hr />
            <RealEstate :rsInfo="rsInfo" @saved="savedRealEstate" />
          </section>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
// import examination from "@/views/admin/components/InvestorExamination";
// import Information from "@/views/admin/components/Information.vue";

// import RealestateOverview from "@/components/realestate/RealestateOverview";
// import RealestateDetail from "@/components/realestate/RealestateDetail";
// import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
// import EditRealEstateUnit from "@/views/investor/common/EditRealEstateUnit";

//import RealestateOverview from "@/components/realestate/RealestateOverview";
//import RealestateDetail from "@/components/realestate/RealestateDetail";

import RealEstate from "@/components/investor/RealEstate";

export default {
  name: "admin-setting-investors-realtEstate",
  components: {
    RealEstate: RealEstate,
  },
  // components: {
  //   RealestateOverview: RealestateOverview,
  //   RealestateDetail: RealestateDetail,
  //   TileBoxOverlay: TileBoxOverlay,
  //   EditRealEstateUnit: EditRealEstateUnit
  // },
  data: function () {
    return {
      //information: this.$$information$getNewInformation(),
      isLoading: true,
      investor: null,
    };
  },
  computed: {
    uid() {
      return this.$route.params.uid;
    },
  },
  methods: {
    // openEdit() {
    //   this.$refs.overlay.open();
    // },
    savedRealEstate() {
      // this.rsInfo = rsInfo;
      // this.$refs.overlay.close();
      //if (!rsInfo.data.isActivate) {
      //  this.$router.push({ name: "investor-collection" });
      //}
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        this.investor = await this.$$investor$loadUser(this.uid);
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
