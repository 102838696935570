<script>
import { Bar } from "vue-chartjs";
import "chartjs-plugin-datalabels";

export default {
  name: "ZIncomeChart",
  extends: Bar,
  props: {
    quarterData: {
      type: Array,
      required: true,
    },
    currentPL: {
      type: Object,
      required: true,
    },
    isSalaryActive: {
      type: Boolean,
      default: true,
    },
    isDividendActive: {
      type: Boolean,
      default: true,
    },
    isPensionActive: {
      type: Boolean,
      default: true,
    },
    isRealEstateActive: {
      type: Boolean,
      default: true,
    },
    isOthersActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isInitialized: false,
      showToolTipIndex: null,
    };
  },
  computed: {
    chart() {
      const isMobile = this.bulma && this.bulma.isMobile;

      const chartData = {
        sum: [...Array(5)],
        salary: [...Array(5)],
        dividend: [...Array(5)],
        pension: [...Array(5)],
        realEstate: [...Array(5)],
        others: [...Array(5)],
        displayedSum: [...Array(5)],
      };

      [...this.quarterData, { PL: this.currentPL }].forEach((item, index) => {
        const { sum, salary, dividend, pension, realEstate, others } = item.PL;
        chartData.sum[index] = sum;
        chartData.salary[index] = salary;
        chartData.dividend[index] = dividend;
        chartData.pension[index] = pension;
        chartData.realEstate[index] = realEstate;
        chartData.others[index] = others;

        chartData.displayedSum[index] =
          this.isSalaryActive * salary +
          this.isDividendActive * dividend +
          this.isPensionActive * pension +
          this.isRealEstateActive * realEstate +
          this.isOthersActive * others;
      });

      return {
        labels: [...Array(6)],
        datasets: [
          // バーチャート
          // 合計値ラベルの出力用
          {
            type: "bar",
            borderColor: "rgba(0, 0, 0, 0)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: [chartData.displayedSum[0], ...chartData.displayedSum],
            datalabels: {
              anchor: "end",
              align: "end",
              offset: 4,
              font: {
                size: isMobile ? 10 : 16,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // その他
          {
            type: "line",
            stack: "income",
            hidden: !this.isOthersActive,
            lineTension: 0,
            data: [chartData.others[0], ...chartData.others],
            borderWidth: 0,
            backgroundColor: "#2d3f6b",
            pointBackgroundColor: "#2d3f6b",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            pointHoverBorderWidth: 2,
            datalabels: {
              display: this.showToolTipIndex === 1 && !this.bulma.isMobile,
              anchor: "start",
              align: "top",
              font: {
                size: 13,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // 年金
          {
            type: "line",
            stack: "income",
            hidden: !this.isPensionActive,
            lineTension: 0,
            data: [chartData.pension[0], ...chartData.pension],
            borderWidth: 0,
            backgroundColor: "#3d75f5",
            pointBackgroundColor: "#3d75f5",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            pointHoverBorderWidth: 2,
            datalabels: {
              display: this.showToolTipIndex === 2 && !this.bulma.isMobile,
              anchor: "start",
              align: "top",
              font: {
                size: 13,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // 配当・利子
          {
            type: "line",
            stack: "income",
            hidden: !this.isDividendActive,
            lineTension: 0,
            data: [chartData.dividend[0], ...chartData.dividend],
            borderWidth: 0,
            backgroundColor: "#5aeada",
            pointBackgroundColor: "#5aeada",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            pointHoverBorderWidth: 2,
            datalabels: {
              display: this.showToolTipIndex === 3 && !this.bulma.isMobile,
              anchor: "start",
              align: "top",
              font: {
                size: 13,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // 不動産収入
          {
            type: "line",
            stack: "income",
            hidden: !this.isRealEstateActive,
            lineTension: 0,
            data: [chartData.realEstate[0], ...chartData.realEstate],
            borderWidth: 0,
            backgroundColor: "#839dff",
            pointBackgroundColor: "#839dff",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            pointHoverBorderWidth: 2,
            datalabels: {
              display: this.showToolTipIndex === 4 && !this.bulma.isMobile,
              anchor: "start",
              align: "top",
              font: {
                size: 13,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // 給料
          {
            type: "line",
            stack: "income",
            hidden: !this.isSalaryActive,
            lineTension: 0,
            data: [chartData.salary[0], ...chartData.salary],
            borderWidth: 0,
            backgroundColor: "#9ad6ff",
            pointBackgroundColor: "#9ad6ff",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            pointHoverBorderWidth: 2,
            datalabels: {
              display: this.showToolTipIndex === 5 && !this.bulma.isMobile,
              anchor: "start",
              align: "bottom",
              font: {
                size: 13,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
        ],
      };
    },
    options() {
      const self = this;
      const isMobile = this.bulma && this.bulma.isMobile;

      const maxIncomeSum = Math.max.apply(
        null,
        [...this.quarterData, { PL: this.currentPL }].map((item) => item.PL.sum)
      );

      return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: this.isInitialized ? 0 : 1000,
          onComplete: () => {
            self.isInitialized = true;
          },
        },
        layout: {
          padding: {
            right: 0,
            top: 62,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          mode: isMobile ? null : "dataset",
          intersect: false,
        },
        legend: {
          display: false,
        },
        onHover: (event, target) => {
          if (target.length) {
            self.showToolTipIndex = target[0]._datasetIndex;
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                drawTicks: false,
              },
              ticks: {
                display: false,
                max: maxIncomeSum * 1.2 || 1000000,
              },
            },
          ],
        },
      };
    },
  },
  watch: {
    chart: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
    options: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
  },
  methods: {
    /**
     * 価格のフォーマット
     */
    formatPrice(price) {
      if (price === null || price === undefined) return null;
      return "¥" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.renderChart(this.chart, this.options);
    });
  },
};
</script>
