<template>
  <div class="investerHome">
    <ZHeader title="Score Chart" sub="市場スコアチャート">
      <template v-slot:rightIcons>
        <ZNotification color="#777" />
      </template>
    </ZHeader>
    <ScoreChart />
    <section class="z-section">
      <div class="z-section__inner">
        <div class="gridArea">
          <MarketTrend class="gridArea__trend" />
          <Offers class="gridArea__offers" />
          <NewArrival class="gridArea__newarrival" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Menu from "@/components/fl/my/Menu";
import MarketTrend from "./parts/MarketTrend";
import NewArrival from "./parts/NewArrival";
import Offers from "./parts/Offers";
import ScoreChart from "./parts/ScoreChart";

export default {
  name: "home-Index",
  data: function () {
    return {
      // isLoading: true,
    };
  },
  components: {
    ScoreChart,
    MarketTrend,
    NewArrival,
    Offers,
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investerHome {
  /deep/ .z-header {
    margin-bottom: 0;
  }
}

.gridArea {
  padding-top: 16px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(100% - 346px) 346px;
  grid-template-columns: calc(100% - 346px) 346px;
  @include md() {
    padding-top: 32px;
    display: block;
  }
  @include sp() {
    padding-top: 32px;
    display: block;
  }
  &__trend {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1 / span 1;
    @include sp {
      margin-bottom: 50px;
    }
    @include md {
      margin-bottom: 40px;
    }
    @include lg {
      margin-right: 16px;
    }
  }
  &__offers {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / span 2;
    min-height: 654px;
    @include sp {
      margin-bottom: 50px;
    }
    @include md {
      margin-bottom: 40px;
    }
  }
  &__newarrival {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1 / span 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / span 1;
    @include md {
      margin-top: 0;
    }
    @include sp {
      margin-top: 0;
    }
  }
}
</style>
