<template>
  <div class="portfolioSteps">
    <ul class="portfolioSteps__list">
      <li
        v-for="(stepItem, index) in stepItems"
        :key="index"
        :class="[
          {
            'is-active': activeStep === index,
            'is-previous': activeStep > index,
          },
        ]"
      >
        <div class="step">
          <span class="step__label"
            ><span>{{ stepItem }}</span></span
          >
          <i class="step__icon"
            ><span>{{ index + 1 }}</span></i
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PortfolioSteps",
  props: {
    activeStep: Number,
  },
  data: function () {
    return {
      stepItems: ["成約情報", "融資情報", "担当者評価", "振込先口座"],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.portfolioSteps {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 32px auto;
    width: 400px;
    @include sp {
      width: 100%;
    }
    & > li {
      position: relative;
      flex-grow: 1;
      flex-basis: 0;
      &:not(:first-child) {
        &::before {
          content: "";
          display: block;
          position: absolute;
          margin: 0 auto;
          bottom: 15px;
          left: calc(-50% + 22px);
          width: calc(100% - 44px);
          height: 6px;
          background-color: #dddddd;
          z-index: 1;
          @include sp {
            bottom: 11px;
            height: 4px;
          }
        }
      }
      &.is-previous,
      &.is-active {
        &::before {
          background-color: $primary;
        }
      }
    }
  }
}
.step {
  position: relative;
  text-align: center;
  z-index: 2;
  &__label {
    display: block;
    color: #696969;
    font-size: 14px;
    @include sp {
      font-size: 12px;
    }
    .is-previous & {
      color: $primary;
    }
    .is-active & {
      color: $primary;
      font-weight: bold;
    }
  }
  &__icon {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    border: solid 2px;
    border-color: #dddddd;
    background-color: #dddddd;
    color: #696969;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    .is-active & {
      box-shadow: 0px 2px 4px 0px rgba(#000000, 0.26);
    }
    @include sp {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      font-size: 14px;
    }
    span {
      line-height: 1em;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .is-previous & {
      color: #fff;
      border-color: $primary;
      background-color: $primary;
    }
    .is-active & {
      color: $primary;
      border-color: $primary;
      background-color: #fff;
    }
  }
}
</style>
