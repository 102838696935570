var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-view'),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"box animated fadeIn"},[_vm._m(0),_c('div',{staticClass:"columns is-gapless"},[_c('div',{staticClass:"column is-narrow is-3"},[_c('div',{staticClass:"has-label-centerted"},[_c('div',{staticClass:"content level-right"},[_c('p',[_vm._v(" 氏名 "),(
                        !_vm.$$validator$validRequire('user_firstName') ||
                        !_vm.$$validator$validRequire('user_lastName')
                      )?[_c('span',{staticClass:"tag is-danger"},[_vm._v("必須")])]:[_c('span',{staticClass:"tag is-success"},[_vm._v("ＯＫ")])]],2)])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":_vm.$$validator$validatedType('user_firstName'),"message":_vm.errors.first('user_firstName')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"user_firstName","type":"text","data-vv-as":"姓","placeholder":"民鳥","maxlength":"32"},model:{value:(_vm.user.data.firstName),callback:function ($$v) {_vm.$set(_vm.user.data, "firstName", $$v)},expression:"user.data.firstName"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":_vm.$$validator$validatedType('user_lastName'),"message":_vm.errors.first('user_lastName')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"user_lastName","type":"text","data-vv-as":"名","placeholder":"太郎","maxlength":"32"},model:{value:(_vm.user.data.lastName),callback:function ($$v) {_vm.$set(_vm.user.data, "lastName", $$v)},expression:"user.data.lastName"}})],1)],1)])])]),_c('div',{staticClass:"columns is-gapless"},[_c('div',{staticClass:"column is-narrow is-3"},[_c('div',{staticClass:"has-label-centerted"},[_c('div',{staticClass:"content level-right"},[_c('p',[_vm._v(" ふりがな "),(
                        !_vm.$$validator$validRequire('user_firstKana') ||
                        !_vm.$$validator$validRequire('user_lastKana')
                      )?[_c('span',{staticClass:"tag is-danger"},[_vm._v("必須")])]:[_c('span',{staticClass:"tag is-success"},[_vm._v("ＯＫ")])]],2)])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":_vm.$$validator$validatedType('user_firstKana'),"message":_vm.errors.first('user_firstKana')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"user_firstKana","type":"text","data-vv-as":"姓(せい)","placeholder":"みんとり","maxlength":"32"},model:{value:(_vm.user.data.firstKana),callback:function ($$v) {_vm.$set(_vm.user.data, "firstKana", $$v)},expression:"user.data.firstKana"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":_vm.$$validator$validatedType('user_lastKana'),"message":_vm.errors.first('user_lastKana')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"user_lastKana","type":"text","data-vv-as":"名(せい)","placeholder":"たろう","maxlength":"32"},model:{value:(_vm.user.data.lastKana),callback:function ($$v) {_vm.$set(_vm.user.data, "lastKana", $$v)},expression:"user.data.lastKana"}})],1)],1)])])]),_c('button',{staticClass:"button is-fullwidth is-info",attrs:{"disabled":_vm.$$validator$invalid},on:{"click":_vm.save}},[_vm._v(" 登録 ")])])]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"content is-small"},[_c('h2',[_vm._v("基本情報")])])])
}]

export { render, staticRenderFns }