<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告" sub="振込先口座の登録">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <!--
      <ZHeader title="不動産売買申告"
    /> -->
    <section class="edit">
      <div class="edit__notice">
        <h3>
          キャッシュバック金をご入金する振込先口座の<br
            class="br-show"
          />ご登録をお願いいたします。
        </h3>
      </div>
      <TransactionSteps :activeStep="3" />
      <div class="edit__section">
        <h3 class="edit__subtitle">振込先口座 登録</h3>

        <z-bank-select
          label="金融機関"
          v-model="transaction.data.bankAccount.bankId"
          :validate="'required'"
          isRequired
          isPrivate
        />

        <z-branch-select
          label="支店名"
          :bank="transaction.data.bankAccount.bankId"
          v-model="transaction.data.bankAccount.branchId"
          :validate="'required'"
          isRequired
          isPrivate
        />

        <z-radio
          label="預金種別"
          v-model="transaction.data.bankAccount.depositType"
          :validate="'required|min_value:1'"
          :options="depositTypes"
          isRequired
          isPrivate
        />

        <z-input
          label="口座番号"
          v-model="transaction.data.bankAccount.accountNumber"
          placeholder="例)1111111"
          maxlength="7"
          isRequired
          :validate="'required|min:7|max:7|numeric'"
          isPrivate
        />

        <z-input
          label="口座名義"
          v-model="transaction.data.bankAccount.accountName"
          type="text"
          placeholder="例)ミントリ タロウ"
          maxlength="32"
          isRequired
          :validate="'required'"
          isPrivate
        />
      </div>

      <div class="edit__foot">
        <router-link
          :to="{ name: 'transaction-review-staffevaluation' }"
          class="button z-button is-rounded"
          >戻る</router-link
        >
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button is-wide button__mobile"
          >申告する</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
import TransactionSteps from "../parts/TransactionSteps";

export default {
  name: "investor-transaction-review-bank-account",
  data: function () {
    return {
      isLoading: true,
      transaction: null,
      depositTypes: kubuns.transaction.depositTypes,
    };
  },
  components: {
    TransactionSteps,
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async init() {
      try {
        this.transaction =
          await this.$$transaction$loadRealtorTransactionByLink(
            this.transactionId
          );
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.transaction.data.statusOfRegister !== 990) {
          this.transaction.data.statusOfRegister = 990;
          this.transaction.data.investor = this.me.id;
          this.transaction.data.investorInfo.score = this.$$user$score;
          this.transaction.data.investorInfo.bs = this.$$user$BS;
          this.transaction.data.investorInfo.PL = this.$$user$PL;

          await this.$$transaction$saveRealtorTransaction(
            this.me.id,
            this.transaction
          );
          await this.$$investor$saveRealtorEvaluationOfUser(
            this.me.id,
            this.transaction
          );
          if (this.transaction.data.tradeType === 0) {
            await this.$$realEstate$saveRealEstateByTransaction(
              this.me.id,
              this.transaction
            );
          }
        }
        // this.$router.push({ name: "investor" });
        this.$router.push({ name: "transaction-review-finish" });
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  &__notice {
    padding: 0 22px;
    @include sp {
      padding: 0 16px;
    }
    .br-show {
      display: none;
      @include sp {
        display: block;
      }
    }
  }
}

.button {
  &__mobile {
    @include sp {
      margin: 10px 0 0 !important;
    }
  }
}
</style>
