<template>
  <div class="searchForm">
    <ZEditAccordion
      title="絞り込み検索条件"
      icon="magnify"
      isProtrude
      :opened="false"
    >
      <div class="columns">
        <div class="column">
          <z-score-from-to-select
            label="スコア"
            v-model="filter.score"
            hideRequired
          />
        </div>
        <div class="column">
          <z-year-from-to-select
            label="取引時期"
            v-model="filter.salePeriod"
            hideRequired
          />
        </div>
      </div>

      <section>
        <h4 class="edit__subtitle2">エリア・沿線</h4>
        <ZAreaLineFieldSet
          ref="areaLineFieldSet"
          v-model="filter"
          hideRequired
        />
      </section>

      <section>
        <h4 class="edit__subtitle2">物件情報</h4>

        <z-checkbox
          label="物件種別"
          v-model="filter.realEstateTypes"
          :options="realEstateTypes"
          hideRequired
        />

        <z-checkbox
          label="建物種別"
          v-model="filter.buildingTypes"
          :options="typeOfBuildingTypes"
          hideRequired
        />

        <div class="columns">
          <div class="column">
            <z-rate-from-to-select
              label="表面利回り"
              v-model="filter.couponYieldRate"
              hideRequired
            />
          </div>
          <div class="column">
            <z-price-from-to-select
              label="価格"
              v-model="filter.amount"
              hideRequired
            />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <z-age-ofbuilding-from-to-select
              label="築年数"
              v-model="filter.ageOfBuilding"
              hideRequired
            />
          </div>
          <div class="column">
            <z-transport-select
              label="最寄り駅までの徒歩分数"
              v-model="filter.transport"
              hideRequired
            />
          </div>
        </div>
      </section>

      <ul class="searchForm__buttons">
        <li>
          <b-button class="z-button" size="is-small" @click="resetFilter()">
            リセットする
          </b-button>
        </li>
        <li>
          <b-button
            class="z-button"
            type="is-primary"
            size="is-small"
            :disabled="$$validator$invalid"
            @click="setFilter()"
          >
            検索する
          </b-button>
        </li>
      </ul>
    </ZEditAccordion>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "SearchForm",
  inject: ["$validator"],
  props: {
    value: Object,
  },
  data() {
    return {
      filter: null,
      realEstateTypes: kubuns.realEstateTypes,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
      isFirst: true,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  methods: {
    resetFilter() {
      this.filter = this.$$investor$getNewMarketTrendFilter();
      if (this.$refs.areaLineFieldSet) this.$refs.areaLineFieldSet.reset();
    },
    setFilter() {
      const filter = this.$$cloneDeep(this.filter);
      this.$emit("input", filter);
      this.$store.commit("investor/setMtSearchFilter", filter);
    },
  },
  created() {
    this.filter = this.$$cloneDeep(this.value);
  },
  mounted() {
    // クエリから初期条件を付与する
    const { score_from, score_to } = this.$route.query;
    // スコア最小
    if (score_from) {
      this.filter.score.from = score_from;
    }
    // スコア最大
    if (score_to) {
      this.filter.score.to = score_to;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.searchForm {
  margin-bottom: 20px;
  padding: 0 $edit-padding;
  border: solid 1px #dddddd;
  border-radius: 8px;
  overflow: hidden;
  @include sp {
    padding: 0 $edit-padding-sp;
  }
  /deep/ .z-editAccordion {
    margin-bottom: 0;
    .z-editAccordion__body {
      padding-bottom: 32px;
    }
  }
  .column {
    @include sp {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__buttons {
    display: table;
    margin: 0 auto;
    padding-top: 12px;
    // text-align: center;
    @include sp {
      width: 100%;
    }
    & > li {
      display: table-cell;
      padding: 0 8px;
      @include sp {
        width: 50%;
      }
      .z-button {
        min-width: 210px;
        @include sp {
          min-width: 100%;
        }
      }
    }
  }
  &__hr {
    margin: 36px 0;
    height: 1px;
    background-color: #dddddd;
  }
}
</style>
