<template>
  <div>
    <router-view></router-view>
    <div class="container consultant-warp" v-if="!isLoading">
      <div class="message__list">
        <div class="content">
          <h1>お知らせ一覧</h1>
        </div>
        <hr />
        <b-table
          :data="informations"
          sort-icon="arrow-up"
          default-sort="data.sendDt"
          default-sort-direction="desc"
          detailed
          detail-key="id"
          custom-row-key="id"
          :show-detail-icon="true"
          sort-icon-size="is-small"
          :striped="true"
          :hoverable="true"
          :narrowed="true"
        >
          <template slot-scope="props">
            <b-table-column field="data.title" label="件名" sortable>
              <template v-if="props.row.data.asRead">
                <span class="tag">既読</span>
              </template>
              <template v-else>
                <span class="tag is-danger">未読</span>
              </template>
              {{ props.row.data.title }}
            </b-table-column>
            <b-table-column
              field="receiver.data.base.kana"
              label="送信先"
              sortable
            >
              {{ props.row.receiver.data.base.name }}
            </b-table-column>
            <b-table-column
              field="data.sendDt"
              label="受信日時"
              width="220"
              sortable
            >
              {{ $$calender$dateTimeFormat(props.row.data.sendDt) }}
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <div class="content message-detial">
              <h6>メッセージ：</h6>
              <div class="box">
                <pre class="normal" v-text="props.row.data.message"></pre>
              </div>
            </div>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "admin-settings-informations",
  data: function () {
    return {
      isLoading: true,
      informations: [],
    };
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.informations = await this.$$information$loadsWithUser();
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
      // this.isLoading = false;
      // this.informations = await this.$$information$loadsWithSender();
      // for (const info of this.informations) {
      //   this.$$information$asRead(this.me.user.id, info);
      // }
      // return;
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   next();
  //   this.init();
  // },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.consultant-warp {
  margin: 0 10px;
  @include mobile {
    margin: 0;
  }
}

.message {
  &__list {
    width: 100%;
  }
}
</style>
