import firebase from "@/plugins/firebase";
import { queryPagination } from "@/modules/pagination";
import { pairs, cloneDeep /* defaultsDeep */ } from "lodash";

import kubuns from "@/kubuns/kubuns";
import moment from "moment";
import "moment-timezone";

const defaultQuery = {
  query: {
    match_all: {},
  },
  size: 200,
};

export const search = async (query) => {
  const q = query || defaultQuery;
  // console.log(query);
  const ret = await firebase.functions("public_realtor_realestates_search", {
    query: q,
  });
  return ret.data;
};

const filterType = {
  realEstateTypes: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
  },
  price: {
    from: kubuns.filters.price.min,
    to: kubuns.filters.price.max,
  },
  yieldRate: {
    from: kubuns.filters.yieldRate.min,
    to: kubuns.filters.yieldRate.max,
  },
  yearOfConstruction: {
    from: kubuns.filters.yearOfconstruction.min,
    to: kubuns.filters.yearOfconstruction.max,
  },
  elapsedTime: 999,
  buildingTypes: {
    1: false,
    2: false,
    3: false,
    4: false,
  },
  layouts: {
    10: false,
    101: false,
    110: false,
    120: false,
    201: false,
    210: false,
    220: false,
    301: false,
    310: false,
    320: false,
    401: false,
    410: false,
    420: false,
    500: false,
  },
  buildingArea: {
    from: kubuns.filters.buildingArea.min,
    to: kubuns.filters.buildingArea.max,
  },
  landArea: {
    from: kubuns.filters.landArea.min,
    to: kubuns.filters.landArea.max,
  },
  area: {
    isSelected: false,
    prefecture: null, // 県
    cities: [], // 市町村
  },
  Line: {
    isSelected: false,
    prefecture: null, // 県
    line: null, // 沿線
    stations: [], // 駅
  },
};

export const createNewFilterType = () => {
  return cloneDeep(filterType);
};

export const sortTypes = [
  {
    value: 1,
    text: "新着順",
    field: {
      "data.registerDt": {
        order: "desc",
      },
    },
  },
  {
    value: 2,
    text: "価格の安い順",
    field: {
      "data.price": {
        order: "asc",
      },
    },
  },
  {
    value: 3,
    text: "価格の高い順",
    field: {
      "data.price": {
        order: "desc",
      },
    },
  },
  {
    value: 4,
    text: "利回りの高い順",
    field: {
      "data.couponYieldRate": {
        order: "desc",
      },
    },
  },
  {
    value: 5,
    text: "築年数の新しい順",
    field: {
      "data.constructionDate.date": {
        order: "desc",
      },
    },
  },
];

const _filter_constructionDate = (filter) => {
  // return []

  const now = new Date();
  // console.log(from.format());
  // console.log(to.format());
  const r = [];
  if (filter.from !== kubuns.filters.yearOfconstruction.min) {
    const from = moment(now).subtract(filter.from, "year");
    r.push({
      range: {
        "data.constructionDate.date": {
          lte: from.format(),
        },
      },
    });
  }
  if (filter.to !== kubuns.filters.yearOfconstruction.max) {
    const to = moment(now).subtract(filter.to, "year");
    r.push({
      range: {
        "data.constructionDate.date": {
          gte: to.format(),
        },
      },
    });
  }

  return r;
};

// 売却利回り
const _filter_couponYieldRate = (filter) => {
  const r = [];
  if (filter.from !== kubuns.filters.yieldRate.min) {
    r.push({
      range: {
        "data.couponYieldRate": {
          gte: filter.from,
        },
      },
    });
  }

  if (filter.to !== kubuns.filters.yieldRate.max) {
    r.push({
      range: {
        "data.couponYieldRate": {
          lte: filter.to,
        },
      },
    });
  }

  return r;
};

const _filter_price = (filter) => {
  // 価格
  // console.log(filter);
  const r = [];
  if (filter.from !== kubuns.filters.price.min) {
    r.push({
      range: {
        "data.price": {
          gte: filter.from,
        },
      },
    });
  }

  if (filter.to !== kubuns.filters.price.max) {
    r.push({
      range: {
        "data.price": {
          lte: filter.to,
        },
      },
    });
  }
  return r;
};

const _filter_realEstateType = (filter) => {
  const ts = Object.entries(filter)
    .filter(([key, value]) => value)
    .map(([key, value]) => Number(key));
  if (ts.length) {
    return [
      {
        terms: {
          "data.realEstateType.value": ts,
        },
      },
    ];
  } else {
    return [];
  }
};

// 建物面積
const _filter_buildingArea = (filter) => {
  const r = [];
  if (filter.from !== kubuns.filters.buildingArea.min) {
    r.push({
      range: {
        "data.buildingArea": {
          gte: filter.from,
        },
      },
    });
  }
  if (filter.to !== kubuns.filters.buildingArea.max) {
    r.push({
      range: {
        "data.buildingArea": {
          lte: filter.to,
        },
      },
    });
  }
  return r;
};

const _filter_buildingTypes = (filter) => {
  const ts = Object.entries(filter)
    .filter(([key, value]) => value)
    .map(([key, value]) => Number(key));

  if (ts.length) {
    // return [];
    return [
      {
        terms: {
          "data.buildingType.value": ts,
        },
      },
    ];
  } else {
    return [];
  }
};

// 土地面積
const _filter_landArea = (filter) => {
  // saleSetting: {
  const r = [];
  if (filter.from !== kubuns.filters.landArea.min) {
    r.push({
      range: {
        "data.saleSetting.landArea": {
          gte: filter.from,
        },
      },
    });
  }

  if (filter.to !== kubuns.filters.landArea.max) {
    r.push({
      range: {
        "data.saleSetting.landArea": {
          lte: filter.to,
        },
      },
    });
  }
  return r;
};

// 駅からの距離
const _filter_elapsedTime = (filter) => {
  if (filter !== 999) {
    return [
      {
        bool: {
          must: [
            {
              nested: {
                path: "data.transports",
                query: {
                  bool: {
                    must: [
                      {
                        range: {
                          "data.transports.elapsedTime": {
                            lte: filter,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          ],
        },
      },
    ];
  } else {
    return [];
  }
};

//間取り
const _filter_layout = (filter) => {
  const ts = Object.entries(filter)
    .filter(([key, value]) => value)
    .map(([key, value]) => Number(key));

  // console.log(ts);
  if (ts.length) {
    return [
      {
        bool: {
          must: [
            {
              nested: {
                path: "data.saleSetting.layouts",
                query: {
                  bool: {
                    must: [
                      {
                        terms: {
                          "data.saleSetting.layouts.layout.value": ts,
                        },
                      },
                    ],
                  },
                },
              },
            },
          ],
        },
      },
    ];
  } else {
    return [];
  }
};

const _filter_area = (filter) => {
  // area: {
  //   isSelected: false,
  //   prefecture: null, // 県
  //   cities: [] // 市町村
  // },
  const r = [];
  if (!filter.isSelected) return r;

  if (!filter.prefecture) return r;

  r.push({
    term: {
      "data.prefecture.value": filter.prefecture,
    },
  });

  if (filter.cities.length) {
    r.push({
      terms: {
        "data.city.cityCode": filter.cities,
      },
    });
  }
  return r;
};

const _filter_Line = (filter) => {
  // Line: {
  //   isSelected: false,
  //   prefecture: null, // 県
  //   line: null, // 沿線
  //   stations: [] // 駅
  // }
  const r = [];
  if (!filter.isSelected) return r;
  if (!filter.prefecture) return r;

  r.push({
    term: {
      "data.prefecture.value": filter.prefecture,
    },
  });

  if (!filter.line) return r;

  r.push({
    bool: {
      must: [
        {
          nested: {
            path: "data.transports",
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "data.transports.line.line.line_cd": filter.line,
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  });

  if (filter.stations.length) {
    r.push({
      bool: {
        must: [
          {
            nested: {
              path: "data.transports",
              query: {
                bool: {
                  must: [
                    {
                      terms: {
                        "data.transports.station.station.station_cd":
                          filter.stations,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    });
  }

  return r;
};

const _filter_force = (force) => {
  if (force) {
    return [];
  } else {
    return [
      {
        term: {
          "data.isActivate": true,
        },
      },
    ];
  }
};

const _filter_owner = (owner) => {
  if (!owner) {
    return [];
  } else {
    return [
      {
        term: {
          "data.owner.keyword": {
            value: owner,
          },
        },
      },
    ];
  }
};

export const searchByfilter = async (
  filter,
  sortType,
  page,
  perPage,
  owner = null,
  force = false
) => {
  const query = {
    bool: {
      must: [],
    },
  };

  // console.log(filter);
  query.bool.must = []
    .concat(_filter_force(force))
    .concat(_filter_owner(owner))
    .concat(_filter_realEstateType(filter.realEstateTypes))
    .concat(_filter_price(filter.price))
    .concat(_filter_couponYieldRate(filter.yieldRate))
    .concat(_filter_buildingArea(filter.buildingArea))
    .concat(_filter_landArea(filter.landArea))
    .concat(_filter_layout(filter.layouts))
    .concat(_filter_elapsedTime(filter.elapsedTime))
    .concat(_filter_buildingTypes(filter.buildingTypes))
    .concat(_filter_constructionDate(filter.yearOfConstruction))
    .concat(_filter_area(filter.area))
    .concat(_filter_Line(filter.Line));

  const sort = sortTypes
    .filter((s) => s.value === sortType)
    .map((s) => s.field);

  // console.log(sort);
  const from = (page - 1) * perPage;
  return await search({
    query,
    sort,
    from: from > 0 ? from : 0,
    size: perPage,
  });
};

export const searchByOwner = async (owner, pagination) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              "data.owner.keyword": {
                value: owner,
              },
            },
          },
          {
            term: {
              "data.isActivate": {
                value: true,
              },
            },
          },
        ],
      },
    },
    size: 9999,
  };
  return await search(queryPagination(query, pagination));
};

export const makeAllIndex = async (query) => {
  // console.log(query);
  // const q = query | defaultQuery;
  const ret = await firebase.functions(
    "public_realtor_realestates_makeAll",
    {}
  );
  return ret.data;
};

export const createIndex = async () => {
  const ret = await firebase.functions(
    "public_realtor_realestates_createIndex",
    {}
  );
  return ret.data;
};

export const putMapping = async () => {
  // const q = query | defaultQuery;
  const ret = await firebase.functions(
    "public_realtor_realestates_putMapping",
    {}
  );
  return ret.data;
};

export const deleteIndex = async () => {
  const ret = await firebase.functions(
    "public_realtor_realestates_deleteIndex",
    {}
  );
  return ret.data;
};

export const getIndex = async () => {
  const ret = await firebase.functions(
    "public_realtor_realestates_getIndex",
    {}
  );
  return ret.data;
};
