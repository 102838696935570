<template>
  <div>
    <router-view></router-view>
    <div class="container">
      <ZFavoriteRealtorButton @click="onClick" />
      <hr />
      <RealtorFavoriteCard
        v-if="!isLoading"
        :realtor="realtor"
        :favorite="favorite"
      />
      <hr />
      <RealtorFavoriteView
        v-if="!isLoading"
        :realtor="realtor"
        :favorite="favorite"
      />
      <hr />
      {{ realtor }}
      <hr />
      {{ favorite }}
    </div>
  </div>
</template>

<script>
import * as favorite from "@/modules/investor/favorite";

export default {
  name: "admin-demo-base2",
  components: {
    // PrefectureSelect: PrefectureSelect
  },
  data() {
    return {
      isLoading: true,
      realtor: {},
      realtorid: "svd2BlKbrudu54xUtkVdbNhyY2h2",
      favorite: {},
    };
  },
  computed: {},
  methods: {
    onClick() {
      // alert("click!");
    },
  },
  async mounted() {
    this.isLoading = true;
    const uid = this.auth.uid;
    this.realtor = await this.$$realtor$loadUser(this.realtorid);
    this.favorite = await favorite.load(uid, this.realtorid);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
