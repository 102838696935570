<template>
  <div class="editRealEstate" v-show="!isLoading">
    <section class="edit">
      <p class="edit__explanation">
        ポートフォリオを充実させ、よりご希望に沿った提案を受けることができます。
      </p>

      <h2 class="edit__title">保有不動産</h2>
      <FormRealEstateUnit v-if="rsInfo.data" v-model="rsInfo" />

      <div class="edit__foot">
        <b-button
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button is-wide"
          >保存する</b-button
        >
      </div>
      <div v-if="id" class="edit__delete">
        <delete-button label="削除する" @click="onDelete()" />
      </div>
    </section>
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import FormRealEstateUnit from "./FormRealEstateUnit";

export default {
  name: "EditRealEstateUnit",
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: true,
      rsInfo: {},
    };
  },
  components: {
    FormRealEstateUnit: FormRealEstateUnit,
  },
  // computed: {
  // },
  methods: {
    calculateCouponYieldRate(realEstate) {
      const result = this.$$realEstate$calcYieldPerYear(
        realEstate.data.annualEstimatedIncome,
        realEstate.data.saleSetting.askingPrice
      );
      return result;
    },
    onSubmit() {
      this.save();
    },
    onDelete() {
      this.rsInfo.data.isActivate = false;
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      // 売却利回りを計算
      const result = this.$$cloneDeep(this.rsInfo);
      result.data.saleSetting.couponYieldRate = this.calculateCouponYieldRate(
        this.rsInfo
      );
      try {
        await this.$$realEstate$saveRealEstate(this.auth.uid, result);
        await this.$$investor$saveOnlyScore(
          this.auth.uid,
          this.rsInfo.data.owner
        );
        // await this.$$investor$saveOnlyScore(this.auth.uid, this.me.user.id);
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
        this.$emit("saved", result);
      }
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.rsInfo = await this.$$realEstate$loadRealEstate(this.id);
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      }
    } else {
      this.rsInfo = this.$$realEstate$getNewRealEstate(this.auth.uid);
    }
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
