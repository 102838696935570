import firebase from "@/plugins/firebase";

import { cloneDeep, defaultsDeep } from "lodash";

const ActionType = {
  uid: null,
  type: 0, // 1:router
  action: {},
  createdDt: null,
  createdBy: null,
};

export const createNewAction = (uid) => {
  const data = cloneDeep(ActionType);
  data.uid = uid;
  return {
    id: null,
    data,
  };
};

const docRef = () => firebase.db.collection("analysis_actions_v1");

export const loads = async () => {
  const qs = await docRef().orderBy("createdDt", "desc").get();
  const ls = [];
  qs.docs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: defaultsDeep(doc.data(), ActionType),
    });
  });
  return ls;
};

export const loadByUID = async (uid) => {
  const qs = await docRef()
    .where("uid", "==", uid)
    .orderBy("createdDt", "desc")
    .get();

  const ls = [];
  qs.docs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: defaultsDeep(doc.data(), ActionType),
    });
  });
  return ls;
};

export const loadLatestByUID = async (uid) => {
  const qs = await docRef()
    .where("uid", "==", uid)
    .orderBy("createdDt", "desc")
    .limit(1)
    .get();

  const ls = [];
  qs.docs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: defaultsDeep(doc.data(), ActionType),
    });
  });
  return ls ? ls[0] : null;
};

export const loadByID = async (id) => {
  const doc = await docRef().doc(id).get();
  if (doc.exists) {
    return {
      id: doc.id,
      data: defaultsDeep(doc.data(), ActionType),
    };
  } else {
    return null;
  }
};

export const save = async (uid, action) => {
  const now = new Date();
  action.data.createdDt = now;
  action.data.createdBy = uid;
  if (action.id) {
    await docRef().doc(action.id).set(action.data, {
      merge: true,
    });
  } else {
    const r = await docRef().add(action.data);
    action.id = r.id;
  }
  return action;
};

//   if (doc.exists) {
//     return {
//       id: doc.id,
//       data: doc.data()
//     };
//   } else {
//     return null;
//   }

// export const save = async (uid, targetUID, template) => {
//   const now = new Date();
//   template.data.updatedDt = now;
//   template.data.updatedBy = uid;
//   if (template.id) {
//     await docRef(targetUID)
//       .doc(template.id)
//       .set(template.data);
//   } else {
//     template.data.createdDt = now;
//     template.data.createdBy = uid;
//     template.data.registerDt = now;
//     const ref = await docRef(targetUID).add(template.data);
//     template.id = ref.id;
//   }
//   return template;
// };
//
// export const remove = async (uid, targetUID, template) => {
//   if (template.id) {
//     await docRef(targetUID)
//       .doc(template.id)
//       .delete();
//   }
// };
