<template>
  <div class="z-yearmonth">
    <ZField
      :label="label"
      :type="hasError ? 'is-danger' : ''"
      :message="errorMessage"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
    >
      <div class="z-yearmonth__inputs">
        <b-input
          class="z-yearmonth__inputs__year z-select--unit"
          :name="internalName + '-年'"
          v-model.number="internalValue.year"
          :data-vv-as="label"
          v-validate="'min_value:0|max_value:99|' + validate"
          placeholder="例) 30"
          type="tel"
        />

        <b-select
          class="z-yearmonth__inputs__month z-select--unit"
          :name="internalName + '-月'"
          v-model="internalValue.month"
          :data-vv-as="label"
          v-validate="validate"
          placeholder="例) 04"
          ref="month"
        >
          <option v-for="o in countOfMonths" :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </b-select>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZYearMonthPeriodInput",
  mixins: [InputMixin],
  inject: ["$validator"],
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  data() {
    return {
      countOfMonths: kubuns.countOfMonths,
    };
  },
  computed: {
    hasError() {
      if (
        this.errors.has(this.internalName + "-年") ||
        this.errors.has(this.internalName + "-月")
      )
        return true;

      // 複合チェック
      if (this.isCompareError) return true;

      return false;
    },
    errorMessage() {
      let fromError = "";
      fromError = this.errors.first(this.internalName + "-年");
      if (fromError) return fromError;

      fromError = this.errors.first(this.internalName + "-月");
      if (fromError) fromError;

      // 複合チェック
      if (this.isCompareError)
        return this.label + "は1ヶ月以上になるよう入力してください";

      return fromError;
    },
    isCompareError() {
      if (this.internalValue.year != null || this.internalValue.month != null) {
        const months = this.internalValue.year * 12 + this.internalValue.month;
        return months < 1;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-yearmonth {
  &__inputs {
    &__year,
    &__month {
      display: inline-block;
      &:before {
        position: absolute;
        font-size: 12px;
        top: 8px;
        right: 26px;
      }
    }
    &__year {
      margin-right: 22px;
      width: 200px;
      @include sp {
        width: 120px;
      }
      &:before {
        content: "年";
      }
      /deep/ select {
        @include sp {
          min-width: auto;
          width: 120px;
        }
      }
    }
    &__month {
      &:before {
        content: "ヶ月";
      }
      /deep/ .select {
        select {
          min-width: 200px;
          @include sp {
            min-width: 100px;
          }
        }
      }
    }
  }
}
</style>
