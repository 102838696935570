<template>
  <div>
    <section>
      <ZHeader title="メッセージ" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div v-if="!isLoading">
          <InvestorProfile
            :hideProfile="hideProfile"
            :investor="message.investor"
          />

          <template v-if="!isNewPage">
            <div class="content message-title-wrap">
              <template
                v-if="$$ks.message.getType(message.data.type).value === 1"
              >
                <div class="message-title-wrap__exclusive-label">
                  <img src="@/assets/message/label-exclusive-M.png" />
                </div>
                <div
                  class="title is-size-6 message-title-wrap__title message-title-wrap__exclusive-title"
                >
                  {{ message.data.title }}
                </div>
              </template>
              <template v-else>
                <div class="title is-size-6 message-title-wrap__title">
                  {{ message.data.title }}
                </div>
              </template>
              <template
                v-if="
                  $$ks.message.getType(message.data.type).value === 3 ||
                  $$ks.message.getType(message.data.type).value === 4
                "
              >
                <div class="message-type">
                  【{{ $$ks.message.getType(message.data.type).en }} ({{
                    $$ks.message.getType(message.data.type).text
                  }})】
                </div>
              </template>
            </div>

            <hr class="message-title-separator" />
            <SuggestionsView :suggestions="message.data.suggestions" />

            <!-- <h6 class="list-title">メッセージ詳細</h6> -->
            <b-table
              :data="threads"
              sort-icon="arrow-up"
              detailed
              detail-key="id"
              custom-row-key="id"
              :opened-detailed="defaultOpenedDetails"
              :show-detail-icon="true"
              sort-icon-size="is-small"
              :striped="true"
              :hoverable="true"
              :narrowed="true"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="送信者" width="144">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-narrow">
                      <figure class="image is-32x32">
                        <z-profile-thumbnail
                          v-model="
                            getUser(props.row.data.from).data.profile.image
                          "
                        />
                      </figure>
                    </div>
                    <div class="column">
                      <div class="content">
                        <p class="is-elliptical is-size-7">
                          {{ getUser(props.row.data.from).data.base.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="contents" label="メッセージ">
                  <div class="content message-content">
                    <p class="is-elliptical is-size-7">
                      {{ latestThread(props.row.data.contents) }}
                    </p>
                  </div>
                </b-table-column>
                <b-table-column field="sendDt" label="送信日" width="80">
                  <div class="content">
                    <p class="is-size-7">
                      {{ $$calender$todayFormat(props.row.data.sendDt) }}
                    </p>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>メッセージがありません。</p>
                  </div>
                </section>
              </template>
              <template slot="detail" slot-scope="props">
                <div class="content message-detial">
                  <div class="box message-detial__box sharp" ref="messagebox">
                    <pre
                      :style="'width:' + widthOfMessageBox + 'px'"
                      class="is-size-7 normal"
                      v-html="$$message$displayText(props.row.data.contents)"
                    ></pre>
                  </div>
                </div>
              </template>
            </b-table>
          </template>
          <template v-if="message.investor.data.isActivate">
            <template v-if="isNewPage">
              <template
                v-if="message.data.type === 1 || message.data.type === 2"
              >
                <h4 class="list-title">不動産選択</h4>
                <RealEstateList
                  :checkable="true"
                  v-model="checkedRealEstates"
                  :realEstates="realEstates"
                  hideModifybutton
                >
                </RealEstateList>
                <h4 class="list-title">添付する物件・案件</h4>
                <div class="box">
                  <RealtorRealEstateList
                    :realEstates="checkedRealEstates"
                    :realtor="true"
                  />
                </div>
              </template>
            </template>
            <template v-if="this.messageRealEstates.length">
              <hr />
              <h4 class="list-title real-estate-title">
                添付されたご提案物件・案件
              </h4>
              <div class="box box__realestate-list">
                <template v-if="!isNewPage">
                  <template v-if="message.data.type === 3">
                    <div class="content box__price">
                      <p class="is-size-5 offer-price">
                        提案売却金額：<span class="has-text-danger">{{
                          $$price$priceJPFormat(message.data.proposalSalePrice)
                        }}</span>
                      </p>
                    </div>
                  </template>
                </template>
                <RealtorRealEstateList
                  :realtor="true"
                  :realEstates="messageRealEstates"
                />
              </div>
            </template>
            <template v-if="isDocs">
              <h4 class="list-title real-estate-title">添付ファイル</h4>
              <div class="box box__realestate-list">
                <z-message-docs-list :threads="message.data.threads" />
              </div>
            </template>
            <div class="box submitForm">
              <article class="media">
                <div class="media-content">
                  <div class="field">
                    <p class="control">
                      <template v-if="isNewPage">
                        <template v-if="message.data.type === 1">
                          <div class="message-count">
                            <p>
                              <b-icon icon="alert-circle" />
                              <span class="message-count__text">
                                残りのエクスクルーシブメッセージ数:
                                <span class="message-count__count">
                                  {{ me.user.data.exclusiveMessageCount }}
                                </span>
                              </span>
                            </p>
                          </div>
                        </template>
                        <template v-if="message.data.type === 3">
                          <z-price-input
                            label="提案売却金額"
                            v-model="message.data.proposalSalePrice"
                            :validate="'required|price_min_value:0'"
                            maxlength="12"
                            placeholder="例)20,000,000"
                            isRequired
                          />
                        </template>
                        <z-radio
                          label="メッセージ区分"
                          v-model="message.data.type"
                          :options="messageTypes"
                          validate="required"
                          isRequired
                        >
                        </z-radio>
                        <!--
                        <ZSuggestionsCheckbox
                          label="提案の特徴"
                          v-model="message.data.suggestions"
                        />
                        -->
                        <z-input
                          label="件名"
                          v-model="message.data.title"
                          type="text"
                          maxlength="100"
                          validate="required"
                          isRequired
                        >
                        </z-input>
                      </template>
                      <z-textarea
                        :label="isNewPage ? 'メッセージ' : '返信メッセージ'"
                        :rows="8"
                        v-model="thread.contents"
                        type="textarea"
                        maxlength="5000"
                        placeholder="メッセージを入力してください"
                        validate="required"
                        isRequired
                      >
                      </z-textarea>
                      <z-field label="添付ファイル">
                        <div class="mention">
                          ※添付ファイルは、ファイル個数・データ容量に制限はありません（無制限で添付送信可能です。）
                        </div>
                        <z-docs-list-select
                          label=""
                          v-model="thread.docs"
                          :uploadPath="uploadPath"
                        />
                      </z-field>
                    </p>
                  </div>
                  <nav class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <button
                          @click="send"
                          class="button is-primary is-rounded is-small z-wide z-button"
                          :disabled="$$validator$invalid"
                        >
                          送信
                        </button>
                      </div>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </template>
          <template v-else>
            <div class="nonactive-user-box__wrapper">
              <div class="nonactive-user-box__inner">
                <p class="nonactive-user-box__text">
                  現在、こちらの投資家は、StockFormerに登録されていないためご返信メッセージをお受けできません。ご了承くださいますようお願い申し上げます。
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RealtorRealEstateList from "@/components/realtor/RealtorRealEstateList";
import InvestorProfile from "@/components/investor/InvestorProfile";
import RealEstateList from "@/views/realtor/common/RealEstateList";

export default {
  name: "message-detail",
  components: {
    InvestorProfile: InvestorProfile,
    RealEstateList: RealEstateList,
    RealtorRealEstateList: RealtorRealEstateList,
  },
  data: function () {
    return {
      isLoading: true,
      message: null,
      thread: null,
      investor: null,
      realEstates: [],
      messageRealEstates: [],
      currentPage: 0,
      checkedRealEstates: [],
      defaultOpenedDetails: [],
      messageTypesHideName: [1, 2, 3],
      widthOfMessageBox: 0,
    };
  },
  computed: {
    messageTypes() {
      if (this.message) {
        if (this.message.data.type === 1 || this.message.data.type === 2) {
          return [
            { value: 1, text: "エクスクルーシブ" },
            { value: 2, text: "オファー" },
          ];
        } else if (this.message.data.type === 3) {
          return [{ value: 3, text: "売却", color: "is-primary" }];
        } else if (this.messageType === 4) {
          return [{ value: 4, text: "問い合せ", color: "" }];
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    hideProfile() {
      if (this.isNewPage) {
        return true;
      } else {
        if (this.message) {
          return !this.message.data.threads.some(
            (t) => t.from === this.message.data.investor
          );
        }
        return true;
      }
    },
    messageType() {
      //新規作成時のみ仕様
      if (this.$route.query) {
        return Number(this.$route.query.messageType);
      } else {
        return null;
      }
    },
    messageId: function () {
      if (this.$route.params) {
        return this.$route.params.id;
      } else {
        return null;
      }
    },
    realEstateId: function () {
      if (this.$route.query) {
        return this.$route.query.realEstateId;
      } else {
        return null;
      }
    },
    destinationId: function () {
      if (this.$route.query) {
        return this.$route.query.uid;
      } else {
        return null;
      }
    },
    isNewPage: function () {
      return this.messageId === "new";
    },
    threads: function () {
      const mes = [];
      for (const idx in this.message.data.threads) {
        mes.push({
          id: String(idx),
          data: this.message.data.threads[idx],
        });
      }
      return mes;
    },
    canSendExclusiveMessage() {
      return !(
        this.me.user.data.exclusiveMessageCount === 0 &&
        this.message.data.type === 1
      );
    },
    uploadPath() {
      return "docs";
    },
    isDocs() {
      let docsCount = 0;
      for (const i in this.message.data.threads) {
        if ("docs" in this.message.data.threads[i]) {
          docsCount += this.message.data.threads[i].docs.length;
        }
      }
      return docsCount;
    },
  },
  methods: {
    latestThread(m) {
      const ln = m.replace("\n", "");
      return ln;
    },
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.thread = this.$$message$getNewThread();
        if (this.isNewPage) {
          if (this.messageType === 3) {
            this.message = this.$$message$getNewMessage();
            this.message.data.type = 3;
            const realEstate = await this.$$realEstate$loadRealEstate(
              this.realEstateId
            );
            this.message.investor = await this.$$investor$loadUserWithMessage(
              realEstate.data.owner
            );
            this.messageRealEstates.push(await this.loadRealEstate(realEstate));
          } else {
            this.message = this.$$message$getNewMessage();
            this.message.data.type = 1;
            this.message.investor = await this.$$investor$loadUserWithMessage(
              this.destinationId
            );
            // 物件取得
            const res = await this.$$realtor$loadRealEstatesByCID(
              this.me.user.company.id
            );
            const promises = [];
            for (const r of res) {
              promises.push(this.loadRealEstate(r));
            }
            this.realEstates = await Promise.all(promises).then((rs) => rs);
          }
        } else {
          this.$$message$asRead(this.messageId, this.me.user.id);
          this.message = await this.$$message$loadMessage(this.messageId);
          this.message.investor = await this.$$investor$loadUserWithMessage(
            this.message.investor.id
          );
          const ps = [];
          for (const rid of this.message.data.realtorRealEstates) {
            ps.push(this.loadRealtorRealEstateById(rid));
          }
          for (const rid of this.message.data.realEstates) {
            ps.push(this.loadRealEstateById(rid));
          }
          this.messageRealEstates = await Promise.all(ps);
          this.defaultOpenedDetails = [
            String(this.message.data.threads.length - 1),
          ];
        }

        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.messagebox) {
            this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
          }
        });
        loadingComponent.close();
      }
    },
    async send() {
      if (this.isNewPage) {
        if (this.canSendExclusiveMessage) {
          try {
            const loadingComponent = this.$loading.open();
            const nm = await this.$$message$createMessage(
              this.auth.uid,
              this.auth.uid,
              this.message.investor.id,
              this.message,
              this.thread,
              this.fetchIdFromEstates(this.checkedRealEstates),
              this.fetchIdFromEstates(this.messageRealEstates)
            );
            await this.$$message$sendMailMessageToInvestor(nm.id);

            if (this.message.data.type === 1) {
              await this.$$realtor$decrementExclusiveMessageCount(
                this.auth.uid
              );
            }
            // await this.$router.go(-1);
            this.$router.push({
              name: "realtor-message-detail",
              params: { id: nm.id },
              query: {
                uid: this.destinationId,
                messageType: nm.data.type,
              },
            });
            this.$$toast$openSuccess("メッセージを送信しました。");
            loadingComponent.close();
          } catch (error) {
            this.$raven.captureException(error);
            this.$$toast$openError("メッセージの送信に失敗しました。");
          }
        } else {
          this.$$toast$openError(
            "エクスクルーシブメッセージが不足しています。"
          );
        }
      } else {
        try {
          const loadingComponent = this.$loading.open();
          const nm = await this.$$message$sendMessage(
            this.auth.uid,
            this.auth.uid,
            this.message.data.investor,
            this.message,
            this.thread
          );
          await this.$$message$sendMailMessageToInvestor(nm.id);
          this.init();
          this.$$toast$openSuccess("メッセージを送信しました。");
          loadingComponent.close();
        } catch (error) {
          this.$raven.captureException(error);
          this.$$toast$openError("メッセージの送信に失敗しました。");
        }
      }
    },
    getUser: function (uid) {
      if (this.message.investor.id === uid) {
        return this.message.investor;
      }
      if (this.message.realtor.id === uid) {
        return this.message.realtor;
      }
      return null;
    },
    // async getInvestorData(uid) {
    //   return await this.$$investor$loadUser(uid)
    // },

    // 物件表示
    async address(dt) {
      const text = await this.$$area$generateTextOfAddress(dt);
      return text;
    },
    countExclusive(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 1).length;
    },
    countNormal(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 2).length;
    },
    countContact(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 4).length;
    },
    async loadRealtorRealEstateById(rid) {
      const r = await this.$$realtor$loadRealEstate(rid);
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    async loadRealEstateById(rid) {
      const r = await this.$$realEstate$loadRealEstate(rid);
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    async loadRealEstate(r) {
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    buildingTypeText(val) {
      return this.$$ks.getTypeOfBuildingType(val).text;
    },
    fetchIdFromEstates(estates) {
      return estates.map((e) => e.id);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.nonactive-user-box {
  &__wrapper {
    margin-top: 32px;
    background-color: #fdebeb;
    border: 2px solid red;
  }

  &__inner {
    width: 100%;
    padding: 12px;
  }
  &__text {
    width: 100%;
    // text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: red;
  }
}
.realEstates {
  margin-bottom: 30px;
}

.rank {
  width: 23px;
  height: 23px;
  color: #ffffff;
  background-color: #0a244e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-flow: column;
}

.submitForm {
  margin-top: 30px;
}

.is-dt {
  margin-top: 3px;
  @include mobile {
    margin-top: 0px;
  }
}
.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

.message-content {
  width: auto;
  @include mobile {
    width: 240px;
  }
}

.message-detial {
  padding: 0;
  &__box {
    width: 100%;
    box-shadow: none;
    padding: 16px;
  }
}

.message-count {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
  &__text {
    vertical-align: 6px;
  }
  &__count {
    color: #f00;
    font-size: 15px;
  }
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 4px 22px;
//  background-color: #dbdbdb;
//  border-bottom: solid 2px #707070;
//  color: black;
//}

.newArrival {
  height: 300px;
  &__imageTileViewList {
    width: 100%;
    display: flex;
    overflow: scroll;
    &__item {
      padding: 12px;
    }
  }
}

.title {
  display: inline-block;
  width: 80%;
  @include sp {
    width: 100%;
  }
  @include md {
    width: 70%;
  }
  &__message {
    margin-bottom: 0px;
  }
}

.message-type {
  display: inline-block;
  width: 20%;
  text-align: right;
  @include sp {
    width: 100%;
    font-size: 14px;
  }
  @include md {
    width: 30%;
  }
}

.real-estate-title {
  background-color: white;
  color: black;
  font-weight: bold;
  border-bottom: none;
  padding-bottom: 0px;
}

.z-section {
  &__back-to-list {
    margin-bottom: 20px;
  }
}

.message-title-wrap {
  margin: 45px 0px 0px 0px;
  &__title {
    margin: 0px;
  }
  &__exclusive-label {
    width: 11%;
    display: inline-block;
    margin-right: 5px;
    @include md {
      margin-right: 15px;
    }
    @include sp {
      width: 30%;
      margin-right: 5px;
    }
  }
  &__exclusive-title {
    width: 86%;
    display: inline-block;
    vertical-align: 4px;
    @include sp {
      width: 67%;
    }
  }
}

.message-title-separator {
  margin-top: 0px;
  margin-bottom: 10px;
}

.box {
  &__realestate-list {
    box-shadow: none;
    padding-top: 0px;
    margin-top: 10px;
  }
  &__price {
    margin-bottom: 0px;
    .offer-price {
      font-size: 1.1rem !important;
    }
  }
}

.mention {
  color: #333333;
  font-size: 16px;
  margin: 8px 0;
}
</style>
