import realtorIndex from "@/views/realtor/Index";

//import realtorWelcomeWelcome from "@/views/realtor/welcome/Welcome";
//import realtorWelcomeProfile from "@/views/realtor/welcome/Profile";
//import realtorWelcomeSales from "@/views/realtor/welcome/Sales";

import realtorSettingBasic from "@/views/realtor/setting/Basic";
import realtorSettingDetail from "@/views/realtor/setting/Detail";
import realtorSettingUsersDetail from "@/views/realtor/setting/users/Detail";

import realtorSettingRealEstates_v1 from "@/views/realtor/setting/realEstates_v1/Index";
import realtorSettingRealEstatesDetail_v1 from "@/views/realtor/setting/realEstates_v1/Detail";
import realtorSettingRealEstatesPreview_v1 from "@/views/realtor/setting/realEstates_v1/Preview";

import realtorSettingRealEstates_v2 from "@/views/realtor/setting/realEstates_v2/Index";
import realtorSettingRealEstatesDetail_v2 from "@/views/realtor/setting/realEstates_v2/Detail";
import realtorSettingRealEstatesPreview_v2 from "@/views/realtor/setting/realEstates_v2/Preview";

import realtorMessageIndex from "@/views/realtor/message/Index";
import realtorMessageDetail from "@/views/realtor/message/Detail";
import realtorMessageNew from "@/views/realtor/message/New";

import realtorSettingInvestors from "@/views/realtor/setting/investors/Index";
import realtorSettingInvestors2 from "@/views/realtor/setting/investors/Index2";

import realtorInfomationsIndex from "@/views/realtor/setting/information/Index";

import realtorTransactionContractForm from "@/views/realtor/setting/transaction/ContractForm";
import realtorTransactionRealEstateForm from "@/views/realtor/setting/transaction/RealEstateForm";

import realtorSettingAccount from "@/views/realtor/setting/Account";

import realtorSearchRealEstateIndex from "@/views/realtor/search/realEstate/Index";
import realtorSearchRealEstateDetail from "@/views/realtor/search/realEstate/Detail";

import realtorBulkMessagesNew from "@/views/realtor/bulkmessages/New";

import realtorMy from "@/views/realtor/My";

import options from "@/routers/options";
const RequiresAuth = options.RequiresAuth;
const role = options.role;
const examination = options.examination;

const routes = [
  {
    path: "/realtor",
    meta: {
      examination: examination.allow,
      role: role.realtor,
      requiresAuth: RequiresAuth.required,
    },
    component: realtorIndex,
    children: [
      {
        path: "",
        name: "realtor-route",
        component: realtorMy,
        meta: {
          title: "マイページ",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "my",
        name: "realtor-my",
        component: realtorMy,
        meta: {
          title: "マイページ",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/informations",
        name: "realtor-setting-informations",
        component: realtorInfomationsIndex,
        meta: {
          title: "お知らせ",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/transaction/contract/:id",
        name: "realtor-setting-transaction-contract",
        component: realtorTransactionContractForm,
        meta: {
          title: "成約情報入力",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/transaction/realestate/:id",
        name: "realtor-setting-transaction-realestate",
        component: realtorTransactionRealEstateForm,
        meta: {
          title: "売買物件情報入力",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/accoutn",
        name: "realtor-setting-account",
        component: realtorSettingAccount,
        meta: {
          title: "アカウント設定",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/investors",
        name: "realtor-setting-investors",
        component: realtorSettingInvestors,
        meta: {
          title: "投資家検索",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/investors2",
        name: "realtor-setting-investors2",
        component: realtorSettingInvestors2,
        meta: {
          title: "投資家検索",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates/v1",
        name: "realtor-setting-realestates-v1",
        component: realtorSettingRealEstates_v1,
        meta: {
          title: "掲載物件管理",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates/v1/:id",
        name: "realtor-setting-realestates-detail-v1",
        component: realtorSettingRealEstatesDetail_v1,
        meta: {
          title: "掲載物件管理",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates/v1/:id/preview",
        name: "realtor-setting-realestates-preview-v1",
        component: realtorSettingRealEstatesPreview_v1,
        meta: {
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates",
        name: "realtor-setting-realestates",
        component: realtorSettingRealEstates_v2,
        meta: {
          title: "掲載物件管理",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates/:id",
        name: "realtor-setting-realestates-detail",
        component: realtorSettingRealEstatesDetail_v2,
        meta: {
          title: "掲載物件管理",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/realestates/:id/preview",
        name: "realtor-setting-realestates-preview",
        component: realtorSettingRealEstatesPreview_v2,
        meta: {
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/basic",
        name: "realtor-setting-basic",
        component: realtorSettingBasic,
        meta: {
          title: "プロフィール",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/basic/edit",
        name: "realtor-setting-basic-edit",
        component: realtorSettingDetail,
        meta: {
          title: "プロフィール編集",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "setting/compay/users/:uid",
        name: "realtor-setting-users-detail",
        component: realtorSettingUsersDetail,
        meta: {
          title: "コンサルタント設定",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "messages",
        name: "realtor-message",
        component: realtorMessageIndex,
        meta: {
          title: "メッセージ",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "solomessages/new",
        name: "realtor-message-new",
        meta: {
          title: "メッセージ",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
        components: {
          default: realtorMessageNew,
        },
        props: {
          default: true,
        },
      },
      {
        path: "messages/:id",
        name: "realtor-message-detail",
        component: realtorMessageDetail,
        meta: {
          title: "メッセージ詳細",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "search/investor/realestates/:id",
        name: "realtor-search-realestates-detail",
        meta: {
          title: "売却物件詳細",
          examination: examination.all,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
        component: realtorSearchRealEstateDetail,
      },
      {
        path: "search/investor/realestates",
        name: "realtor-search-realestates",
        meta: {
          title: "売却物件検索",
          examination: examination.all,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
        component: realtorSearchRealEstateIndex,
      },
      {
        path: "bulkmessages/new",
        name: "realtor-bulkmessages-new",
        meta: {
          title: "メッセージ一括送信",
          examination: examination.allow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
        components: {
          default: realtorBulkMessagesNew,
        },
        props: {
          default: true,
        },
      },
    ],
  },
];

export default { routes: routes };
