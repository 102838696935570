<template>
  <div>
    <ul class="edit__list">
      <li
        v-for="(realEstate, i) in value.realEstates"
        :index="i"
        :key="i"
        class="edit__list__item"
      >
        <template v-if="!realEstate.data.isActivate">
          <h2 class="edit__title">
            {{ "保有不動産 " + (i + 1) }} <span class="is-6">削除済み</span>
          </h2>
        </template>
        <template v-else>
          <z-edit-accordion :title="'保有不動産 ' + (i + 1)">
            <div>
              <FormRealEstateUnit v-model="value.realEstates[i]" />

              <div class="has-text-right">
                <delete-button
                  label="不動産を削除する"
                  @click="deleteRealEstates(i)"
                />
              </div>
            </div>
          </z-edit-accordion>
        </template>
      </li>
    </ul>

    <div class="edit__section is-onlySP">
      <add-button label="保有不動産を追加する" @click="addRealEstates()" />
    </div>
  </div>
</template>

<script>
import FormRealEstateUnit from "./FormRealEstateUnit";

export default {
  name: "FormRealEstates",
  inject: ["$validator"],
  props: {
    value: Object,
  },
  // data() {
  //   return {
  //   };
  // },
  components: {
    FormRealEstateUnit: FormRealEstateUnit,
  },
  // computed: {
  // },
  methods: {
    addRealEstates() {
      let item = this.value.realEstates;
      item.push(this.$$realEstate$getNewRealEstate(this.auth.uid));
    },
    deleteRealEstates(index) {
      let item = this.value.realEstates[index];
      item.data.isActivate = false;
      //item.splice(index, 1);
    },
  },
  mounted() {
    if (this.value.realEstates.length < 1) this.addRealEstates();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.edit__section.is-onlySP {
  padding: 0 !important;
  @include sp {
    padding: 0 $edit-padding-sp !important;
  }
}
</style>
