<template>
  <div class="register-base-profile">
    <div v-if="!isLoading">
      <div class="content has-text-grey is-small">
        <p>
          ご登録の内容にお間違いがない確認頂き、[次へ]を押下してください。<br />
          誤りがある場合は、該当箇所を修正の上、次にお進みください。
        </p>
      </div>
      <UserBasicEditList :user="user" />
      <div class="buttons is-centered">
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button is-small"
          >次へ</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

import UserBasicEditList from "@/components/realtor/FormUserBasic";

export default {
  name: "auth-realtors-welcome-basic",
  components: {
    UserBasicEditList: UserBasicEditList,
  },
  data: function () {
    return {
      user: null,
      isLoading: true,
    };
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveUser(this.auth.uid, this.user);
        this.$router.push({ name: "auth-realtor-welcome-profile" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      this.user = await this.$$realtor$loadUser(this.me.user.id);
      this.isLoading = false;
      return;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.register-base-profile {
  width: 700px;
  @include mobile {
    width: 450px;
  }
  .z-section {
    padding: 46px;
    &__inner {
      background-color: white;
      padding: 20px 46px;
    }
  }
}
</style>
