<template>
  <div class="z-form price">
    <ZField
      class="price"
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :isShort="isShort"
    >
      <b-input
        ref="input"
        :name="internalName"
        :type="type || 'tel'"
        :icon="icon"
        :size="size"
        :placeholder="placeholder || label + 'を入力してください'"
        :data-vv-as="label"
        :password-reveal="passwordReveal"
        :disabled="disabled"
        v-model="priceValue"
        v-validate="internalValidate"
        @keypress.native="onKeypress"
      />
    </ZField>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

const formatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});
// v-currency="format"

export default {
  name: "ZPriceInput",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Number],
      default: null,
    },
  },
  data() {
    return {
      internalValidate: "",
      priceValue: null,
    };
  },
  watch: {
    internalValue(val) {
      // console.log(val);
      if (val === null) {
        this.priceValue = null;
      } else {
        this.priceValue = formatter.format(val);
      }
    },
    priceValue(newVal) {
      // console.log("NEW:" + newVal);
      // console.log("OLD:" + oldVal);
      if (newVal === null || newVal.replace(/[^0-9]/g, "").length === 0) {
        this.internalValue = null;
        this.$nextTick(() => {
          this.priceValue = null;
        });
      } else {
        const nval = Number(newVal.replace(/[^0-9]/g, ""));
        this.internalValue = nval;
        this.$nextTick(() => {
          this.priceValue = formatter.format(nval);
        });
      }
    },
  },
  computed: {
    format() {
      return {
        currency: "JPY",
        locale: "ja",
        max: this.maxlength
          ? Math.pow(10, Number(this.maxlength || 0)) - 1
          : null,
        min: 0,
        distractionFree: {
          hideNegligibleDecimalDigit: false,
          hideCurrencySymbol: false,
          hideGroupingSymbol: false,
        },
      };
    },
    // priceValue: {
    //   get() {
    //     const val = this.internalValue;
    //     if (!val) {
    //       return null;
    //     } else {
    //       return formatter.format(val);
    //     }
    //   },
    //   set(val) {
    //     console.log("OLD:" + this.priceValue);
    //     console.log("NEW:" + val);
    //     if (val === this.priceValue) return;
    //     if (!val) {
    //       this.internalValue = null;
    //     } else {
    //       const nval = Number(val.replace(/[^0-9]/g, ""));
    //       this.$nextTick(() => {
    //         console.log(nval);
    //         this.internalValue = nval;
    //         // this.$refs.input.value = formatter.format(nval);
    //       });
    //     }
    //   },
    // },
    // priceValue: {
    //   get() {
    //     // if (this.internalValue == null) return null;
    //     return formatter.format(this.internalValue);
    //   },
    // },
    //   set(val) {
    //     // const old = this.priceValue;
    //     // console.log("new:" + val);
    //     // console.log("old:" + old);
    //     // if (val !== old) {
    //     //   if (val) {
    //     //     const ret = Number(val.replace(/[^0-9]/g, ""));
    //     //     console.log("ret:" + ret);
    //     //     this.internalValue = ret;
    //     //   } else {
    //     //     this.internalValue = null;
    //     //   }
    //     // }
    //   }
    // }
  },
  mounted() {
    if (this.internalValue === null) {
      this.priceValue = null;
    } else {
      this.priceValue = formatter.format(this.internalValue);
    }
  },
  methods: {
    onKeypress(e) {
      // console.log(e);
      const st = String.fromCharCode(e.which);
      if ("0123456789".indexOf(st, 0) < 0) {
        e.preventDefault();
        return false;
      }
      return true;
    },
  },
  created() {
    this.$validator.extend("price_min_value", {
      validate: function isPriceMin(value, min) {
        if (value === null) return false;
        const num = Number(String(value).replace(/[^0-9]/g, "")) || 0;
        return num >= Number(min[0]);
      },
      params: ["min"],
      getMessage: (field, min) => `${field}は${min}以上で入力してください`,
    });
    if (this.maxlength) {
      this.internalValidate =
        this.validate + "|price_max_value:" + Math.pow(10, this.maxlength);
    }
    this.$validator.extend("price_max_value", {
      validate: function isPriceMin(value, min) {
        if (value === null) return false;
        const num = Number(String(value).replace(/[^0-9]/g, "")) || 0;
        return num < Number(min[0]);
      },
      params: ["min"],
      getMessage: (field, min) => `${field}は${min}未満で入力してください`,
    });
  },
  // created() {
  //   this.$validator.extend("price_min_value", {
  //     validate: function isPriceMin(value, min) {
  //       if (value === null) return false;
  //       const num = Number(String(value).replace(/[^0-9]/g, "")) || 0;
  //       return num >= Number(min[0]);
  //     },
  //     params: ["min"],
  //     getMessage: (field, min) => `${field}は${min}以上で入力してください`
  //   });

  //   // maxlengthが指定されていた場合はprice_max_valueに適用
  //   if (this.maxlength) {
  //     this.internalValidate =
  //       this.validate + "|price_max_value:" + Math.pow(10, this.maxlength);
  //   }
  //   this.$validator.extend("price_max_value", {
  //     validate: function isPriceMin(value, min) {
  //       if (value === null) return false;
  //       const num = Number(String(value).replace(/[^0-9]/g, "")) || 0;
  //       return num < Number(min[0]);
  //     },
  //     params: ["min"],
  //     getMessage: (field, min) => `${field}は${min}未満で入力してください`
  //   });
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.price {
  position: relative;
  /deep/ .plane {
    &::before {
      content: "¥";
      display: block;
      position: absolute;
      padding-bottom: 1px;
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
    }
    &.is-disabled {
      &::before {
        color: #7a7a7a;
      }
    }
    input {
      padding-left: 1em;
    }
  }
}
</style>
