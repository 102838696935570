<template>
  <div class="realEstateTransactionChart">
    <b-tabs
      class="realEstateTransactionChart__tab"
      :animated="false"
      v-model="internalTabIndex"
    >
      <b-tab-item
        class="realEstateTransactionChart__tab__item"
        label="価格"
        :class="{ 'is-loading': isLoadingPrice }"
        v-model="tabIndex"
      >
        <ZMarketTrendTransaction
          v-if="priceGroups"
          type="price"
          :groups="priceGroups"
          :currentGroups.sync="currentPriceGroups"
          :score="$$user$score"
          :scoreRange="scoreRange"
          :priceRange="priceRange"
          :style="followingStyle"
          ref="price"
        />
      </b-tab-item>
      <b-tab-item
        class="realEstateTransactionChart__tab__item"
        label="利回り"
        :class="{ 'is-loading': isLoadingCouponYieldRate }"
      >
        <ZMarketTrendTransaction
          v-if="couponYieldRateGroups"
          type="couponYieldRate"
          :groups="couponYieldRateGroups"
          :currentGroups.sync="currentCouponYieldRateGroups"
          :score="$$user$score"
          :scoreRange="scoreRange"
          :couponYieldRateRange="couponYieldRateRange"
          :style="followingStyle"
          ref="couponYieldRate"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import ZMarketTrendTransaction from "@/components/charts/ZMarketTrendTransaction.vue";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "RealEstateTransactionCharts",
  components: {
    ZMarketTrendTransaction,
  },
  props: {
    // 絞り込み条件
    filter: {
      type: Object,
      required: true,
    },
    // 選択したバブルの条件
    selectedFilter: {
      type: Object,
      default: null,
    },
    // タブ位置
    tabIndex: {
      type: Number,
      default: 0,
    },
    // スクロールに合わせた吸着
    followingStyle: {
      top: 0,
    },
  },
  data() {
    return {
      isLoadingPrice: true,
      isLoadingCouponYieldRate: true,
      currentPriceGroups: null,
      currentCouponYieldRateGroups: null,
    };
  },
  computed: {
    internalTabIndex: {
      get() {
        return this.tabIndex;
      },
      set(value) {
        this.$emit("update:tabIndex", value);
      },
    },
    // 検索するスコアの幅
    scoreRange() {
      const min_score = 0;
      const max_score = 1000;
      const { from, to } = this.filter.score;

      // toに有効上限値が入ってきた時の対応
      const consider_to = to > 900 ? null : to;

      const diff = (consider_to || max_score) - (from || min_score);

      // 刻みを求める
      let span;
      if (diff >= 500) {
        span = 100;
      } else if (diff > 100) {
        span = 50;
      } else {
        span = 10;
      }

      const ticks = [
        ..._.range(from || min_score, consider_to || max_score, span),
        consider_to,
      ];

      return this.getAxesFromTicks(ticks);
    },
    // 検索する利回りの幅
    couponYieldRateRange() {
      const min_yield = 0;
      const max_yield = 0.2;
      const { from, to } = this.filter.couponYieldRate;

      // fromに有効上限値が入ってきたときの対応
      const consider_from = from <= min_yield ? null : from;
      // toに有効上限値が入ってきたときの対応
      const consider_to = to > max_yield ? null : to;

      const diff = (consider_to || max_yield) - (consider_from || min_yield);

      // デフォルト時のみリニアな軸ではないので固定値を読み込む
      if (consider_from === null && consider_to === null) {
        return DEFAULT_YAXES_YIELD;
      }

      // 刻みを求める
      let span;
      if (diff > 0.05) {
        span = 0.01;
      } else if (diff > 0.01) {
        span = 0.005;
      } else {
        span = 0.001;
      }

      // decimal対策のため1000をかけて計算
      const ticks = [
        ..._.map(
          _.range(
            consider_from * 1000 || min_yield * 1000,
            consider_to * 1000 || max_yield * 1000,
            span * 1000
          ),
          (i) => i / 1000
        ),
        consider_to,
      ];

      return this.getAxesFromTicks(ticks);
    },
    // 検索する金額の幅
    priceRange() {
      const min_amount = 0;
      const max_amount = 500000000;
      const { from, to } = this.filter.amount;

      // fromに有効上限値が入ってきたときの対応
      const consider_from = from <= min_amount ? null : from;
      // toに有効上限値が入ってきたときの対応
      const consider_to = to > max_amount ? null : to;

      const diff = (consider_to || max_amount) - (consider_from || min_amount);

      // デフォルト時のみリニアな軸ではないので固定値を読み込む
      if (consider_from === null && consider_to === null) {
        return DEFAULT_YAXES_AMOUNT;
      }

      // 刻みを求める
      let span;
      if (diff > 100000000) {
        span = 50000000;
      } else if (diff > 10000000) {
        span = 10000000;
      } else {
        span = 1000000;
      }

      const ticks = [
        ..._.range(
          consider_from || min_amount,
          consider_to || max_amount,
          span
        ),
        consider_to,
      ];

      return this.getAxesFromTicks(ticks);
    },
  },
  methods: {
    getAxesFromTicks(ticks) {
      let axes = [];
      _.forEach(ticks, (to, index) => {
        if (index === 0) return;
        axes.push({ from: ticks[index - 1], to });
      });

      return axes;
    },
    getFollowingCL() {
      if (this.tabIndex === 0) {
        return this.$refs.price.$el.getBoundingClientRect();
      } else {
        return this.$refs.couponYieldRate.$el.getBoundingClientRect();
      }
    },
  },
  asyncComputed: {
    // スコア-金額のバブルをAPI取得
    async priceGroups() {
      this.isLoadingPrice = true;
      const request = {
        marketTrendFilter: this.filter,
        scoreRange: this.scoreRange,
        priceRange: this.priceRange,
      };
      // console.log(JSON.stringify(request));
      // const groups = await this.$$MarketTrendChart$RealEstateTransactionChartsOfPrice(
      //   request
      // );
      const groups = await marketTrend.searchPriceCharts(request);
      // const groups = [];
      this.isLoadingPrice = false;
      // console.log(groups);
      return groups;
    },
    // スコア-利回りのバブルをAPI取得
    async couponYieldRateGroups() {
      this.isLoadingCouponYieldRate = true;
      const request = {
        marketTrendFilter: this.filter,
        scoreRange: this.scoreRange,
        couponYieldRateRange: this.couponYieldRateRange,
      };
      // console.log(JSON.stringify(request));
      // const groups = await this.$$MarketTrendChart$RealEstateTransactionChartsOfYield(
      //   request
      // );
      const groups = await marketTrend.searchYieldCharts(request);
      this.isLoadingCouponYieldRate = false;
      // console.log(groups);
      return groups;
    },
  },
  watch: {
    // 選択中の価格-スコアのバブル
    currentPriceGroups: {
      handler(newVal) {
        if (this.tabIndex === 0 && newVal) {
          this.$emit("update:selectedFilter", newVal);
          this.currentCouponYieldRateGroups = null;
        }
      },
      immediate: true,
    },
    // 選択中の利回り-スコアのバブル
    currentCouponYieldRateGroups: {
      handler(newVal) {
        if (this.tabIndex === 1 && newVal) {
          this.$emit("update:selectedFilter", newVal);
          this.currentPriceGroups = null;
        }
      },
      immediate: true,
    },
  },
};

// デフォルト時の金額軸
const DEFAULT_YAXES_AMOUNT = [
  {
    from: 0,
    to: 20000000,
  },
  {
    from: 20000000,
    to: 30000000,
  },
  {
    from: 30000000,
    to: 40000000,
  },
  {
    from: 40000000,
    to: 50000000,
  },
  {
    from: 50000000,
    to: 100000000,
  },
  {
    from: 100000000,
    to: 200000000,
  },
  {
    from: 200000000,
    to: 300000000,
  },
  {
    from: 300000000,
    to: 400000000,
  },
  {
    from: 400000000,
    to: 500000000,
  },
  {
    from: 500000000,
    to: null,
  },
];

// デフォルト時の利回り軸
const DEFAULT_YAXES_YIELD = [
  {
    from: 0,
    to: 0.04,
  },
  {
    from: 0.04,
    to: 0.05,
  },
  {
    from: 0.05,
    to: 0.06,
  },
  {
    from: 0.06,
    to: 0.07,
  },
  {
    from: 0.07,
    to: 0.08,
  },
  {
    from: 0.08,
    to: 0.09,
  },
  {
    from: 0.09,
    to: 0.1,
  },
  {
    from: 0.1,
    to: 0.15,
  },
  {
    from: 0.15,
    to: 0.2,
  },
  {
    from: 0.2,
    to: null,
  },
];
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realEstateTransactionChart {
  &__tab {
    &__item {
      // padding-top: 12px;
      transition: opacity 0.2s ease;
      @include sp {
        padding-top: 0;
      }
      &.is-loading {
        opacity: 0.8;
      }
    }
  }
  margin-right: 22px;
  @include sp {
    margin-right: 0;
  }
  /deep/ .tabs {
    padding-left: 0;
    padding-right: 0;
    a {
      font-size: 16px;
    }
  }
  /deep/ .tab-content {
    padding: 16px 0 0;
  }
}
</style>
