import { render, staticRenderFns } from "./InputInfoStatus.vue?vue&type=template&id=ab28a48e&scoped=true&"
import script from "./InputInfoStatus.vue?vue&type=script&lang=js&"
export * from "./InputInfoStatus.vue?vue&type=script&lang=js&"
import style0 from "./InputInfoStatus.vue?vue&type=style&index=0&id=ab28a48e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab28a48e",
  null
  
)

export default component.exports