<template>
  <div class="titleboxBodyDetail">
    <z-label-list v-model="value"> </z-label-list>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";
export default {
  name: "ZTileBoxBodyList",
  mixins: [InputMixin],
  inject: ["$validator"],
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.titleboxBodyDetail {
  padding: 16px;
}
</style>
