<template>
  <div class="z-carousel">
    <template v-if="images.length">
      <carousel
        :per-page="1"
        :navigationEnabled="true"
        :paginationEnabled="false"
        navigation-prev-label="〈"
        navigation-next-label="〉"
        :navigateTo="internalValue"
        :loop="true"
        v-model="internalValue"
        :speed="400"
        @page-change="changeCurrentIndex"
      >
        <template v-for="(image, idx) in images">
          <slide :key="image.id">
            <figure class="image">
              <div class="image__container">
                <z-image
                  class="image__container__img"
                  v-model="images[idx]"
                ></z-image>
              </div>
              <figcaption v-if="image.title" class="image__caption">
                {{ image.title }}
              </figcaption>
            </figure>
          </slide>
        </template>
      </carousel>
      <div class="z-carousel__page">{{ currentIndex }}/{{ images.length }}</div>
    </template>
    <template v-else>
      <img class="empty-img" :src="emptyImageSrc" />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
// import firebase from "@/plugins/firebase";
//const emptyImage = require("@/assets/empty.png");
const emptyImage = require("@/assets/empty/noimage.png");

export default {
  name: "ZCarousel",
  mixins: [InputMixin],
  props: {
    images: {
      type: [Array],
      default: () => [],
    },
    value: {
      type: [Number],
      default: 0,
    },
    emptySrc: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      crrIndex: 0,
    };
  },
  computed: {
    emptyImageSrc() {
      if (this.emptySrc) {
        return this.emptySrc;
      } else {
        return emptyImage;
      }
    },
    currentIndex() {
      return this.crrIndex + 1;
    },
  },
  methods: {
    changeCurrentIndex(index) {
      this.crrIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.empty-img {
  width: 100%;
  height: 100%;
}

.z-carousel {
  position: relative;
  &__page {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 8px;
    background: rgba(#000000, 0.3);
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
  }
  .image {
    &__container {
      position: relative;
      width: 100%;
      height: 450px;
      background-color: #f4f4f4;
      vertical-align: middle;
      @include sp {
        height: 238px;
      }
      &__img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__caption {
      padding: 4px 10px;
      background-color: #f4f4f4;
      font-size: 16px;
      @include sp {
        font-size: 14px;
      }
    }
  }
  /deep/ .VueCarousel {
    .VueCarousel-navigation-button {
      width: 44px;
      height: 44px;
      top: calc(50% - 12px);
      background: rgba(#000, 0.8);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
      border-radius: 4px;
      color: #fff;
      // デザイン未反映
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      // text-indent: -100px;
      // overflow: hidden;
      transition: 0.2s;
      &:hover {
        background-color: #666;
      }
      @include sp {
        width: 32px;
        height: 32px;
        // デザイン未反映
        font-size: 14px;
      }
    }
    .VueCarousel-navigation-next {
      right: 22px;
      @include sp {
        right: 20px;
      }
    }
    .VueCarousel-navigation-prev {
      left: 22px;
      @include sp {
        left: 20px;
      }
    }
  }
}
</style>
