import { render, staticRenderFns } from "./Parts2.vue?vue&type=template&id=54e44831&scoped=true&"
import script from "./Parts2.vue?vue&type=script&lang=js&"
export * from "./Parts2.vue?vue&type=script&lang=js&"
import style0 from "./Parts2.vue?vue&type=style&index=0&id=54e44831&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e44831",
  null
  
)

export default component.exports