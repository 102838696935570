// import Raven from "raven-js";

const initial = function (Vue) {
  Object.defineProperty(Vue.prototype, "$RetryPromise", {
    get: () => this,
  });
};

const then = function (func, retryCount = 5, delayms = 1000) {
  const delay = (time) => (result) =>
    new Promise((resolve) => setTimeout(() => resolve(result), time));
  let promise = Promise.reject().catch(() => func());
  for (let i = 0; i < retryCount; i++) {
    promise = promise.catch((err) => {
      console.error("Retry Promise of function@" + (i + 1) + ":" + err);
      return Promise.resolve()
        .then(() => {
          console.log("Delay function:" + delayms + "ms");
          return delay(delayms);
        })
        .then(() => {
          return func();
        });
    });
  }
  return promise;
};

export default { then, initial };
