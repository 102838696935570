<script>
import firebase from "@/plugins/firebase";
import realtorType from "@/types/realtorType";

const type = realtorType.type;

// const querys = function(email, query) {
//   const qus = Object.assign({ email: email }, query);
//   return Object.keys(qus)
//     .map(k => k + "=" + qus[k])
//     .reduce((qs, q) => qs + "&" + q, "?q=1");
// };

export default {
  name: "Mixins-realtor",
  methods: {
    $$realtor$getNewImage: function () {
      return this.$$cloneDeep(type.image);
    },
    $$realtor$getNewExamination: function () {
      return this.$$cloneDeep(type.examination);
    },
    $$realtor$getNewLayout: function () {
      return this.$$cloneDeep(type.layout);
    },
    $$realtor$getNewCompany: function () {
      return {
        id: null,
        data: this.$$cloneDeep(type.company),
        examination: {
          id: null,
          data: this.$$cloneDeep(type.examination),
        },
      };
    },
    $$realtor$getNewUser: function () {
      return {
        id: null,
        data: this.$$cloneDeep(type.user),
        examination: {
          id: null,
          data: this.$$cloneDeep(type.examination),
        },
      };
    },
    $$realtor$getNewApplication() {
      return {
        id: null,
        data: this.$$cloneDeep(type.application),
      };
    },
    $$realtor$getNewRealEstate(cid) {
      const data = this.$$cloneDeep(type.realEstate);
      data.owner = cid;
      return {
        id: null,
        data: data,
      };
    },
    async $$realtor$saveHistory(uid, now, user) {
      await firebase.db
        .collection("users_v1")
        .doc(user.id)
        .collection("histories_v1")
        .add({
          date: now,
          data: user,
        });
      return;
    },
    async $$realtor$issueAccountRealtor(uid, realtor) {
      if (realtor.company.examination.data.status === 1) {
        await this.$$realtor$issueAccountCompany(uid, realtor.company);
        for (const user of realtor.users) {
          await this.$$realtor$issueAccountUser(uid, user, true);
        }
      }
    },
    async $$realtor$denyAccountCompany(uid, company) {
      //企業を否認する
      company.data.isActivate = false;
      await this.$$realtor$saveCompany(uid, company);
      company.examination.data.status = 90; //否認
      const ret = await this.$$realtor$saveExaminationOfCompany(
        uid,
        company.id,
        company.examination
      );
      return ret;
    },
    async $$realtor$issueAccountCompany(uid, company) {
      //企業を承認する
      if (company.examination.data.status === 1) {
        company.data.isActivate = true;
        await this.$$realtor$saveCompany(uid, company);
        company.examination.data.status = 20; //承認
        const ret = await this.$$realtor$saveExaminationOfCompany(
          uid,
          company.id,
          company.examination
        );
        return ret;
      }
      return false;
    },
    async $$realtor$sendVerificationMail(uid, user) {
      const email = user.data.base.email;
      const name = user.data.base.name;
      const em = encodeURIComponent(email.replace("+", " "));

      const callback =
        location.protocol +
        "//" +
        location.host +
        "/auth/realtor/register" +
        "?email=" +
        em;
      //querys(encodeURIComponent(email), this.$route.query);
      // console.log(callback);
      const ret = await firebase.functions("sendMailToRealtorKari", {
        to: {
          email: email,
          name: name,
        },
        email: email,
        callback: callback,
        user: user,
      });
      return ret;
    },
    //async $$realtor$sendVerificationMailBy(email, name) {
    //  //const email = user.data.base.email;
    //  //const name = user.data.base.name;
    //  const callback =
    //    location.protocol +
    //    "//" +
    //    location.host +
    //    "/auth/realtor/register" +
    //    querys(encodeURIComponent(email), this.$route.query);
    //  const ret = await firebase.functions("sendMailToRealtorKari", {
    //    to: {
    //      email: email,
    //      name: name
    //    },
    //    email: email,
    //    callback: callback,
    //    user: user
    //  });
    //  return ret;
    //},
    async $$realtor$retrySendVerificationMail(uid, user) {
      // メールの再送信
      if (user.company.examination.data.status === 20) {
        if (user.examination.data.status === 5) {
          await this.$$realtor$sendVerificationMail(uid, user);
        }
      }
    },
    async $$realtor$issueAccountUser(uid, user, force = false) {
      // アカウント発行
      if (user.company.examination.data.status === 20 || force) {
        if (user.examination.data.status === 1) {
          //"アカウント未発行"
          if (force) {
            user.data.role = 10; //プライマリ
          }
          await this.$$realtor$sendVerificationMail(uid, user);
          user.examination.data.status = 5; //アカウント発行
          await this.$$realtor$saveExaminationOfUser(
            uid,
            user.id,
            user.examination
          );
        }
      }
    },
    async $$realtor$applictionAcceptUser(uid, user) {
      //"内容申請する
      if (user.company.examination.data.status === 20) {
        user.examination.data.status = 10; //"内容申請中"
        const newUser = await this.$$realtor$saveExaminationOfUser(
          uid,
          user.id,
          user.examination
        );

        await firebase.functions("sendMailToRealtor", {
          user: user,
          to: {
            name: user.data.base.name,
            email: user.data.base.email,
          },
        });
        return newUser;
      }
      return false;
    },
    async $$realtor$acceptUser(uid, user) {
      //"内容申請したものを承認する
      if (user.company.examination.data.status === 20) {
        if (user.examination.data.status === 10) {
          //"内容申請中"
          user.data.isActivate = true;
          await this.$$realtor$saveUser(uid, user);
          user.examination.data.status = 20; //承認
          const newUser = await this.$$realtor$saveExaminationOfUser(
            uid,
            user.id,
            user.examination
          );
          const mypage = location.protocol + "//" + location.host + "/realtor/";
          await firebase.functions("sendMailToRealotrWhenAllow", {
            user: user,
            mypage: mypage,
            to: {
              name: user.data.base.name,
              email: user.data.base.email,
            },
          });
          return newUser;
        }
      }
      return false;
    },
    async $$realtor$denyUser(uid, user) {
      // 否認
      user.data.isActivate = false;
      await this.$$realtor$saveUser(uid, user);
      user.examination.data.status = 90; //否認
      const newUser = await this.$$realtor$saveExaminationOfUser(
        uid,
        user.id,
        user.examination
      );
      const welcome =
        location.protocol + "//" + location.host + "/auth/realtor/welcome/";

      await firebase.functions("sendMailToRealotrWhenDeny", {
        user: user,
        examination: user.examination,
        welcome: welcome,
        to: {
          name: user.data.base.name,
          email: user.data.base.email,
        },
      });
      return newUser;
    },
    async $$realtor$saveExaminationOfUser(uid, userId, examination) {
      const now = firebase.serverTimestamp();
      //const examination = user.examination;
      if (examination.id) {
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;
        await firebase.db
          .collection("users_v1")
          .doc(userId)
          .collection("examinations_v1")
          .doc(userId)
          .set(examination.data, { merge: true });
      } else {
        examination.data.createdBy = uid;
        examination.data.createdDt = now;
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;
        examination.data.registerDt = now;

        await firebase.db
          .collection("users_v1")
          .doc(userId)
          .collection("examinations_v1")
          .doc(userId)
          .set(examination.data, { merge: true });
      }
    },
    async $$realtor$saveExaminationOfCompany(uid, cid, examination) {
      const now = firebase.serverTimestamp();
      // const examination = company.examination;
      if (examination.id) {
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;
        await firebase.db
          .collection("companies_v1")
          .doc(cid)
          .collection("examinations_v1")
          .doc(cid)
          .set(examination.data, { merge: true });
      } else {
        examination.data.createdBy = uid;
        examination.data.createdDt = now;
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;
        examination.data.registerDt = now;
        await firebase.db
          .collection("companies_v1")
          .doc(cid)
          .collection("examinations_v1")
          .doc(cid)
          .set(examination.data, { merge: true });
      }
    },
    async $$realtor$loadExaminationOfUser(id) {
      const doc = await firebase.db
        .collection("users_v1")
        .doc(id)
        .collection("examinations_v1")
        .doc(id)
        .get();
      return {
        id: doc.id,
        data: this.$$assign(type.examination, doc.data()),
      };
    },
    async $$realtor$loadExaminationOfCompany(id) {
      const doc = await firebase.db
        .collection("companies_v1")
        .doc(id)
        .collection("examinations_v1")
        .doc(id)
        .get();
      return {
        id: doc.id,
        data: this.$$assign(type.examination, doc.data()),
      };
    },
    // ******** Company ************
    async $$realtor$saveCompany(uid, company) {
      const now = firebase.serverTimestamp();
      if (company.id) {
        company.data.updatedBy = uid;
        company.data.updatedDt = now;
        // await this.$$realtor$saveExaminationOfCompany(uid, company);
        await firebase.db
          .collection("companies_v1")
          .doc(company.id)
          .set(company.data, { merge: true });
        return company;
      } else {
        company.data.createdBy = uid;
        company.data.createdDt = now;
        company.data.updatedBy = uid;
        company.data.updatedDt = now;
        company.data.registerDt = now;

        const newCompany = await firebase.db
          .collection("companies_v1")
          .add(company.data)
          .then((ref) => {
            const cid = ref.id;
            company.id = cid;
            return company;
          });
        // await this.$$realtor$saveExaminationOfCompany(uid, newCompany);
        return newCompany;
      }
    },
    // **** user ****
    async $$realtor$saveUser(uid, user) {
      const now = firebase.serverTimestamp();
      if (user.id) {
        user.data.updatedBy = uid;
        user.data.updatedDt = now;

        // await this.$$realtor$saveExaminationOfUser(uid, user);
        await firebase.db
          .collection("users_v1")
          .doc(user.id)
          .set(user.data, { merge: true });

        await this.$$realtor$saveHistory(uid, now, user);
        return user;
      } else {
        //作成
        user.data.createdBy = uid;
        user.data.createdDt = now;
        user.data.updatedBy = uid;
        user.data.updatedDt = now;
        user.data.registerDt = now;

        const newUser = await firebase
          .functions("createUserOfRealtor", {
            user: user,
          })
          .then((results) => {
            user.id = results.data.uid;
            return user;
          });

        //await firebase.functions("setCustomTokenRealtor", {
        //  uid: newUser.id
        //});

        await firebase.db
          .collection("users_v1")
          .doc(newUser.id)
          .set(newUser.data, { merge: true });

        await this.$$realtor$saveHistory(uid, now, newUser);
        // await this.$$realtor$saveExaminationOfUser(uid, newUser);
        return newUser;
      }
    },
    async $$realtor$createUser(uid, realtor, user) {
      const company = realtor.company;
      user.data.company.companyId = company.id;
      const newUser = await this.$$realtor$saveUser(uid, user);

      if (!company.data.users.some((u) => u === newUser.id)) {
        company.data.users.push(newUser.id);
      }
      await this.$$realtor$saveCompany(uid, company);
      newUser.company = company;
      return newUser;
    },
    async $$realtor$saveRealtor(uid, realtor) {
      const company = await this.$$realtor$saveCompany(uid, realtor.company);
      for (const user of realtor.users) {
        user.data.company.companyId = company.id;
        const newUser = await this.$$realtor$saveUser(uid, user);
        company.data.users = [];
        company.data.users.push(newUser.id);
      }

      await this.$$realtor$saveCompany(uid, company);
    },
    $$realtor$loadRealtors: async function () {
      const companies = await this.$$realtor$__loadRealtors();
      const prs = [];
      for (const com of companies) {
        prs.push(this.$$realtor$loadRealtorById(com.id));
      }
      return Promise.all(prs);
    },
    $$realtor$__loadRealtors: async function () {
      const self = this;
      const companies = await firebase.db
        .collection("companies_v1")
        .where("isRealtor", "==", true)
        .get()
        .then(function (querySnapshot) {
          const cs = [];
          querySnapshot.forEach(function (doc) {
            const c = {
              id: doc.id,
              data: self.$$assign(type.company, doc.data()),
            };
            cs.push(c);
          });
          return cs;
        });
      return companies;
    },
    $$realtor$loadRealtorById: async function (cid) {
      const company = await this.$$realtor$loadCompany(cid);
      const ps = [];
      for (const uid of company.data.users) {
        ps.push(this.$$realtor$loadUser(uid));
      }
      const users = await Promise.all(ps);

      return {
        company: company,
        users: users,
      };
    },
    $$realtor$loadCompany: async function (cid) {
      const doc = await firebase.db.collection("companies_v1").doc(cid).get();
      const examination = await this.$$realtor$loadExaminationOfCompany(cid);
      if (doc.exists) {
        const company = {
          id: doc.id,
          data: this.$$assign(type.company, doc.data()),
          examination: examination,
        };
        return company;
      } else {
        return null;
      }
    },
    $$realtor$loadCompanies: async function () {
      const companies = await firebase.db
        .collection("companies_v1")
        .get()
        .then(function (querySnapshot) {
          const companyIds = [];
          querySnapshot.forEach(function (doc) {
            companyIds.push(doc.id);
          });
          return companyIds;
        })
        .then((companyIds) => {
          const cs = [];
          for (const cid of companyIds) {
            cs.push(this.$$realtor$loadCompany(cid));
          }
          return Promise.all(cs);
        });
      return companies;
    },
    $$realtor$loadUsers: async function () {
      //const self = this;
      const users = await firebase.db
        .collection("users_v1")
        .where("isRealtor", "==", true)
        .get()
        .then(function (querySnapshot) {
          const uids = [];
          querySnapshot.forEach(function (doc) {
            uids.push(doc.id);
          });
          return uids;
        })
        .then((uids) => {
          const us = [];
          for (const uid of uids) {
            us.push(this.$$realtor$loadUser(uid));
          }
          return Promise.all(us);
        });
      return users;
    },
    $$realtor$loaduserByEmail: async function (email) {
      const users = await firebase.db
        .collection("users_v1")
        .where("base.email", "==", email)
        .get()
        .then(function (querySnapshot) {
          const uids = [];
          querySnapshot.forEach(function (doc) {
            uids.push(doc.id);
          });
          return uids;
        })
        .then((uids) => {
          const us = [];
          for (const uid of uids) {
            us.push(this.$$realtor$loadUser(uid));
          }
          return Promise.all(us);
        });
      return users[0];
    },
    $$realtor$loadUser: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();
      if (doc.exists) {
        const uid = doc.id;
        const cid = doc.data().company.companyId;
        const examination = await this.$$realtor$loadExaminationOfUser(uid);
        const company = await this.$$realtor$loadCompany(cid);
        const user = {
          id: doc.id,
          data: this.$$assign(type.user, doc.data()),
          examination: examination,
          company: company,
        };
        return user;
      } else {
        return null;
      }
    },
    // ****** Application
    async $$realtor$createApplication(uid, application) {
      const now = firebase.serverTimestamp();
      application.data.createdBy = uid;
      application.data.createdDt = now;
      application.data.updatedBy = uid;
      application.data.updatedDt = now;
      application.data.registerDt = now;

      const docRef = await firebase.db
        .collection("applications_v1")
        .add(application.data);
      await firebase.functions("sendMailToRealotrWhenApplication", {
        application: application,
        to: {
          name: application.data.user.name,
          email: application.data.user.email,
        },
        email: application.data.user.email,
      });
      return docRef.id;
    },
    async $$realtor$updateApplication(uid, application) {
      const now = firebase.serverTimestamp();
      application.data.updatedBy = uid;
      application.data.updatedDt = now;

      await firebase.db
        .collection("applications_v1")
        .doc(application.id)
        .set(application.data, { merge: true });
    },
    async $$realtor$loadApplications() {
      const applications = await firebase.db
        .collection("applications_v1")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.application, doc.data()),
            });
          });
          return ls;
        });
      return applications;
    },
    //************** realestate
    async $$realtor$saveRealEstate(uid, realEstate) {
      const now = firebase.serverTimestamp();
      if (!realEstate.id) {
        realEstate.data.createdBy = uid;
        realEstate.data.createdDt = now;
        realEstate.data.updatedBy = uid;
        realEstate.data.updatedDt = now;
        realEstate.data.registerDt = now;

        const ret = await firebase.db
          .collection("realtor_realEstates_v1")
          .add(realEstate.data)
          .then((docRef) => {
            return {
              id: docRef.id,
              data: realEstate.data,
            };
          });
        return ret;
      } else {
        realEstate.data.updatedBy = uid;
        realEstate.data.updatedDt = now;

        const ret = await firebase.db
          .collection("realtor_realEstates_v1")
          .doc(realEstate.id)
          .set(realEstate.data, { merge: true })
          .then(() => {
            return realEstate;
          });
        return ret;
      }
    },
    async $$realtor$deleteRealEstate(uid, realEstateId) {
      const now = firebase.serverTimestamp();
      await firebase.db
        .collection("realtor_realEstates_v1")
        .doc(realEstateId)
        .set(
          {
            isActivate: false,
            updatedDt: now,
            updatedBy: uid,
          },
          {
            merge: true,
          }
        );
    },
    async $$realtor$loadRealEstatesByJp() {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .where("isActivate", "==", true)
        .where("saleSetting.publicType", "<", 3)
        .orderBy("saleSetting.publicType")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });
      return ret.filter((r) => !r.data.overseasRealEstate);
    },
    async $$realtor$loadRealEstatesByOs() {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .where("isActivate", "==", true)
        .where("saleSetting.publicType", "<", 3)
        .orderBy("saleSetting.publicType")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });
      // return ret;
      return ret.filter((r) => r.data.overseasRealEstate);
    },
    async $$realtor$loadRealEstates() {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .where("isActivate", "==", true)
        .where("saleSetting.publicType", "<", 3)
        .orderBy("saleSetting.publicType")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });
      // return ret.filter(r => r.data.overseasRealEstate);
      return ret;
    },
    async $$realtor$loadNewArraivalRealEstates(isPremiun, limit = 10) {
      const rs = (await this.$$realtor$loadRealEstates()).sort((a, b) => {
        return a.data.registerDt < b.data.registerDt ? 1 : -1;
      });
      const ls = [];
      for (const r of rs) {
        ls.push(r);
        // if (!isPremiun) {
        //   if (r.data.saleSetting.publicType === 1) {
        //     ls.push(r);
        //   }
        // } else {
        //   ls.push(r);
        // }
        if (ls.length >= limit) {
          break;
        }
      }
      // console.log(ls);
      return ls;
    },
    async $$realtor$loadRealEstate(id) {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return {
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            };
          } else {
            return null;
          }
        });
      ret.staff = await this.$$realtor$loadUser(ret.data.staff);
      return ret;
    },
    async $$realtor$loadAllRealEstates() {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .where("isActivate", "==", true)
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach(async (doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });

      const users = await this.$$realtor$loadUsers();
      const promises = [];
      for (const r of ret) {
        const staff = users.find((u) => u.id === r.data.staff);
        r.staff = staff;
        promises.push(r);
      }
      return await Promise.all(promises).then((r) => r);
    },
    async $$realtor$loadRealEstatesByCID(cid) {
      const ret = await firebase.db
        .collection("realtor_realEstates_v1")
        .where("isActivate", "==", true)
        .where("owner", "==", cid)
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach(async (doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });

      const users = await this.$$realtor$loadUsers();
      const promises = [];
      for (const r of ret) {
        const staff = users.find((u) => u.id === r.data.staff);
        r.staff = staff;
        promises.push(r);
      }
      return await Promise.all(promises).then((r) => r);
    },
    async $$realtor$loadViews(realEstateId) {
      return await firebase.db
        .collection("realtor_realEstates_v1")
        .doc(realEstateId)
        .collection("views_v1")
        .get()
        .then((querySnapshot) => {
          const views = [];
          querySnapshot.forEach((doc) => {
            views.push({
              id: doc.id,
              data: this.$$assign(type.views, doc.data()),
            });
          });
          return views;
        });
    },
    async $$realtor$saveViews(uid, realEstateId) {
      const now = firebase.serverTimestamp();
      const views = await this.$$realtor$loadViews(realEstateId);

      if (views.some((v) => v.id === uid)) {
        const data = {
          lastViewedDt: now,
        };

        await firebase.db
          .collection("realtor_realEstates_v1")
          .doc(realEstateId)
          .collection("views_v1")
          .doc(uid)
          .set(data, { merge: true });
      } else {
        const data = {
          firstViewingDt: now,
          lastViewedDt: now,
        };

        await firebase.db
          .collection("realtor_realEstates_v1")
          .doc(realEstateId)
          .collection("views_v1")
          .doc(uid)
          .set(data, { merge: true });
      }
    },
    $$realtor$filterByJp(isJp, realEstates) {
      return realEstates.filter((re) =>
        isJp ? !re.data.overseasRealEstate : re.data.overseasRealEstate
      );
    },
    $$realtor$filterByPublicType(realEstates) {
      return realEstates;
      // return realEstates.filter(
      //   re =>
      //     re.data.saleSetting.publicType === 1 ||
      //     re.data.saleSetting.publicType === 2
      // );
    },
    $$realtor$filterByActivate(realtors) {
      return realtors.filter((u) => u.data.isActivate);
    },
    async $$realtor$loadRealEstatesByFilterByJp(filter) {
      const ret = await this.$$realtor$loadRealEstatesByJp();
      // console.log("hoge");
      // console.log(ret);

      const rets = ret
        .filter((r) => {
          const isDefaultValue =
            filter.yieldRate.from ===
              this.$$ks.filters.yieldRate.from[0].value &&
            filter.yieldRate.to === this.$$ks.filters.yieldRate.to[0].value;
          if (
            "yieldRate" in filter &&
            (filter.yieldRate.from || filter.yieldRate.to) &&
            !isDefaultValue
          ) {
            const couponYieldRate = r.data.couponYieldRate || 0;
            if (
              filter.yieldRate.from <= couponYieldRate &&
              couponYieldRate <= filter.yieldRate.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.price.from === this.$$ks.filters.price.from[0].value &&
            filter.price.to === this.$$ks.filters.price.to[0].value;
          if (
            "price" in filter &&
            (filter.price.from || filter.price.to) &&
            !isDefaultValue
          ) {
            if (
              filter.price.from <= r.data.price &&
              r.data.price <= filter.price.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.yearOfConstruction.from ===
              this.$$ks.filters.yearOfconstruction.from[0].value &&
            filter.yearOfConstruction.to ===
              this.$$ks.filters.yearOfconstruction.to[0].value;
          const yearOfConstruction = this.$$realEstate$calcYearOfConstruction(
            r.data.constructionDate
          );
          if (
            "yearOfConstruction" in filter &&
            (filter.yearOfConstruction.from || filter.yearOfConstruction.to) &&
            !isDefaultValue
          ) {
            if (
              filter.yearOfConstruction.from <= yearOfConstruction &&
              yearOfConstruction <= filter.yearOfConstruction.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (filter.elapsedTime && r.data.transports.length) {
            const elapsedTime = this.getMinimumElapsedTimeOfTransports(
              r.data.transports
            );
            if (elapsedTime <= filter.elapsedTime) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "realEstateTypes" in filter &&
            Object.values(filter.realEstateTypes).includes(true)
          ) {
            if (filter.realEstateTypes[1] && r.data.realEstateType === 1) {
              return true;
            }
            if (filter.realEstateTypes[2] && r.data.realEstateType === 2) {
              return true;
            }
            if (filter.realEstateTypes[3] && r.data.realEstateType === 3) {
              return true;
            }
            if (filter.realEstateTypes[4] && r.data.realEstateType === 4) {
              return true;
            }
            if (filter.realEstateTypes[5] && r.data.realEstateType === 5) {
              return true;
            }
            if (filter.realEstateTypes[6] && r.data.realEstateType === 6) {
              return true;
            }
            if (filter.realEstateTypes[7] && r.data.realEstateType === 7) {
              return true;
            }
            if (filter.realEstateTypes[8] && r.data.realEstateType === 8) {
              return true;
            }
            if (filter.realEstateTypes[9] && r.data.realEstateType === 9) {
              return true;
            }
            if (filter.realEstateTypes[10] && r.data.realEstateType === 10) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "buildingTypes" in filter &&
            Object.values(filter.buildingTypes).includes(true)
          ) {
            if (filter.buildingTypes[1] && r.data.buildingType === 1) {
              return true;
            }
            if (filter.buildingTypes[2] && r.data.buildingType === 2) {
              return true;
            }
            if (filter.buildingTypes[3] && r.data.buildingType === 3) {
              return true;
            }
            if (filter.buildingTypes[4] && r.data.buildingType === 4) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((r) => {
          const isDefaultValue =
            filter.buildingArea.from ===
              this.$$ks.filters.buildingArea.from[0].value &&
            filter.buildingArea.to ===
              this.$$ks.filters.buildingArea.to[0].value;
          if (
            "buildingArea" in filter &&
            (filter.buildingArea.from || filter.buildingArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.buildingArea.from <= r.data.buildingArea &&
              r.data.buildingArea <= filter.buildingArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.landArea.from === this.$$ks.filters.landArea.from[0].value &&
            filter.landArea.to === this.$$ks.filters.landArea.to[0].value;
          if (
            "landArea" in filter &&
            (filter.landArea.from || filter.landArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.landArea.from <= r.data.saleSetting.landArea &&
              r.data.saleSetting.landArea <= filter.landArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "area" in filter &&
            filter.area.isSelected &&
            filter.area.prefecture
          ) {
            if (filter.area.prefecture !== r.data.prefecture) {
              return false;
            }
            if (filter.area.cities.length) {
              return filter.area.cities.includes(r.data.city);
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "Line" in filter &&
            filter.Line.isSelected &&
            filter.Line.prefecture
          ) {
            if (filter.Line.line) {
              const lines = this.getLinesOfTransports(r.data.transports);
              if (!lines.includes(filter.Line.line)) {
                return false;
              }
            } else {
              // 最寄りの駅が県外の場合を想定して、lineがない場合のみ prefecture を判定している
              if (filter.Line.prefecture !== r.data.prefecture) {
                return false;
              }
            }
            if (filter.Line.stations.length) {
              const stations = this.getStationsOfTransports(r.data.transports);
              if (!stations.find((s) => filter.Line.stations.includes(s))) {
                return false;
              }
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "layouts" in filter &&
            Object.values(filter.layouts).includes(true)
          ) {
            const layouts = this.getLayoutsOfLayouts(
              r.data.saleSetting.layouts
            );
            if (filter.layouts[10] && layouts.includes(10)) {
              return true;
            }
            if (filter.layouts[101] && layouts.includes(101)) {
              return true;
            }
            if (filter.layouts[110] && layouts.includes(110)) {
              return true;
            }
            if (filter.layouts[120] && layouts.includes(120)) {
              return true;
            }
            if (filter.layouts[201] && layouts.includes(201)) {
              return true;
            }
            if (filter.layouts[210] && layouts.includes(210)) {
              return true;
            }
            if (filter.layouts[220] && layouts.includes(220)) {
              return true;
            }
            if (filter.layouts[301] && layouts.includes(301)) {
              return true;
            }
            if (filter.layouts[310] && layouts.includes(310)) {
              return true;
            }
            if (filter.layouts[320] && layouts.includes(320)) {
              return true;
            }
            if (filter.layouts[401] && layouts.includes(401)) {
              return true;
            }
            if (filter.layouts[410] && layouts.includes(410)) {
              return true;
            }
            if (filter.layouts[420] && layouts.includes(420)) {
              return true;
            }
            if (filter.layouts[500] && layouts.includes(500)) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        });
      return await Promise.all(rets).then((r) => r);
    },
    async $$realtor$loadRealEstatesByFilterByOs(filter) {
      const ret = await this.$$realtor$loadRealEstatesByOs();

      const rets = ret
        .filter((r) => {
          const isDefaultValue =
            filter.yieldRate.from ===
              this.$$ks.filters.yieldRate.from[0].value &&
            filter.yieldRate.to === this.$$ks.filters.yieldRate.to[0].value;
          if (
            "yieldRate" in filter &&
            (filter.yieldRate.from || filter.yieldRate.to) &&
            !isDefaultValue
          ) {
            const couponYieldRate = r.data.couponYieldRate || 0;
            if (
              filter.yieldRate.from <= couponYieldRate &&
              couponYieldRate <= filter.yieldRate.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.price.from === this.$$ks.filters.price.from[0].value &&
            filter.price.to === this.$$ks.filters.price.to[0].value;
          if (
            "price" in filter &&
            (filter.price.from || filter.price.to) &&
            !isDefaultValue
          ) {
            if (
              filter.price.from <= r.data.price &&
              r.data.price <= filter.price.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.yearOfConstruction.from ===
              this.$$ks.filters.yearOfconstruction.from[0].value &&
            filter.yearOfConstruction.to ===
              this.$$ks.filters.yearOfconstruction.to[0].value;
          const yearOfConstruction = this.$$realEstate$calcYearOfConstruction(
            r.data.constructionDate
          );
          if (
            "yearOfConstruction" in filter &&
            (filter.yearOfConstruction.from || filter.yearOfConstruction.to) &&
            !isDefaultValue
          ) {
            if (
              filter.yearOfConstruction.from <= yearOfConstruction &&
              yearOfConstruction <= filter.yearOfConstruction.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (filter.elapsedTime && r.data.transports.length) {
            const elapsedTime = this.getMinimumElapsedTimeOfTransports(
              r.data.transports
            );
            if (elapsedTime <= filter.elapsedTime) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "realEstateTypes" in filter &&
            Object.values(filter.realEstateTypes).includes(true)
          ) {
            if (filter.realEstateTypes[1] && r.data.realEstateType === 1) {
              return true;
            }
            if (filter.realEstateTypes[2] && r.data.realEstateType === 2) {
              return true;
            }
            if (filter.realEstateTypes[3] && r.data.realEstateType === 3) {
              return true;
            }
            if (filter.realEstateTypes[4] && r.data.realEstateType === 4) {
              return true;
            }
            if (filter.realEstateTypes[5] && r.data.realEstateType === 5) {
              return true;
            }
            if (filter.realEstateTypes[6] && r.data.realEstateType === 6) {
              return true;
            }
            if (filter.realEstateTypes[7] && r.data.realEstateType === 7) {
              return true;
            }
            if (filter.realEstateTypes[8] && r.data.realEstateType === 8) {
              return true;
            }
            if (filter.realEstateTypes[9] && r.data.realEstateType === 9) {
              return true;
            }
            if (filter.realEstateTypes[10] && r.data.realEstateType === 10) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "buildingTypes" in filter &&
            Object.values(filter.buildingTypes).includes(true)
          ) {
            if (filter.buildingTypes[1] && r.data.buildingType === 1) {
              return true;
            }
            if (filter.buildingTypes[2] && r.data.buildingType === 2) {
              return true;
            }
            if (filter.buildingTypes[3] && r.data.buildingType === 3) {
              return true;
            }
            if (filter.buildingTypes[4] && r.data.buildingType === 4) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((r) => {
          const isDefaultValue =
            filter.buildingArea.from ===
              this.$$ks.filters.buildingArea.from[0].value &&
            filter.buildingArea.to ===
              this.$$ks.filters.buildingArea.to[0].value;
          if (
            "buildingArea" in filter &&
            (filter.buildingArea.from || filter.buildingArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.buildingArea.from <= r.data.buildingArea &&
              r.data.buildingArea <= filter.buildingArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.landArea.from === this.$$ks.filters.landArea.from[0].value &&
            filter.landArea.to === this.$$ks.filters.landArea.to[0].value;
          if (
            "landArea" in filter &&
            (filter.landArea.from || filter.landArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.landArea.from <= r.data.saleSetting.landArea &&
              r.data.saleSetting.landArea <= filter.landArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        // .filter(r => {
        //   if (
        //     "area" in filter &&
        //     filter.area.isSelected &&
        //     filter.area.prefecture
        //   ) {
        //     if (filter.area.prefecture !== r.data.prefecture) {
        //       return false;
        //     }
        //     if (filter.area.cities.length) {
        //       return filter.area.cities.includes(r.data.city);
        //     }
        //     return true;
        //   } else {
        //     return true;
        //   }
        // })
        // .filter(r => {
        //   if (
        //     "Line" in filter &&
        //     filter.Line.isSelected &&
        //     filter.Line.prefecture
        //   ) {
        //     if (filter.Line.line) {
        //       const lines = this.getLinesOfTransports(r.data.transports);
        //       if (!lines.includes(filter.Line.line)) {
        //         return false;
        //       }
        //     } else {
        //       // 最寄りの駅が県外の場合を想定して、lineがない場合のみ prefecture を判定している
        //       if (filter.Line.prefecture !== r.data.prefecture) {
        //         return false;
        //       }
        //     }
        //     if (filter.Line.stations.length) {
        //       const stations = this.getStationsOfTransports(r.data.transports);
        //       if (!stations.find(s => filter.Line.stations.includes(s))) {
        //         return false;
        //       }
        //     }
        //     return true;
        //   } else {
        //     return true;
        //   }
        // })
        .filter((r) => {
          if (
            "layouts" in filter &&
            Object.values(filter.layouts).includes(true)
          ) {
            const layouts = this.getLayoutsOfLayouts(
              r.data.saleSetting.layouts
            );
            if (filter.layouts[10] && layouts.includes(10)) {
              return true;
            }
            if (filter.layouts[101] && layouts.includes(101)) {
              return true;
            }
            if (filter.layouts[110] && layouts.includes(110)) {
              return true;
            }
            if (filter.layouts[120] && layouts.includes(120)) {
              return true;
            }
            if (filter.layouts[201] && layouts.includes(201)) {
              return true;
            }
            if (filter.layouts[210] && layouts.includes(210)) {
              return true;
            }
            if (filter.layouts[220] && layouts.includes(220)) {
              return true;
            }
            if (filter.layouts[301] && layouts.includes(301)) {
              return true;
            }
            if (filter.layouts[310] && layouts.includes(310)) {
              return true;
            }
            if (filter.layouts[320] && layouts.includes(320)) {
              return true;
            }
            if (filter.layouts[401] && layouts.includes(401)) {
              return true;
            }
            if (filter.layouts[410] && layouts.includes(410)) {
              return true;
            }
            if (filter.layouts[420] && layouts.includes(420)) {
              return true;
            }
            if (filter.layouts[500] && layouts.includes(500)) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        });
      return await Promise.all(rets).then((r) => r);
    },
    getMinimumElapsedTimeOfTransports(transports) {
      return Math.min(...transports.map((t) => t.elapsedTime));
    },
    getLinesOfTransports(transports) {
      return transports.map((t) => t.line);
    },
    getStationsOfTransports(transports) {
      return transports.map((t) => t.station);
    },
    getLayoutsOfLayouts(layouts) {
      return layouts.map((t) => t.layout);
    },
    async $$realtor$decrementExclusiveMessageCount(uid) {
      let user = await this.$$realtor$loadUser(uid);
      user.data.exclusiveMessageCount--;
      this.$$realtor$saveUser(this.auth.uid, user);
      return true;
    },
  },
};
</script>
