<template>
  <div class="z-editAccordion">
    <div
      @click="toggle"
      class="z-editAccordion__head"
      :class="{ 'is-protrude': isProtrude }"
    >
      <h4 class="z-editAccordion__head__title">
        <b-icon
          class="z-editAccordion__head__title__icon"
          v-if="icon"
          :icon="icon"
        >
        </b-icon
        >{{ title }}
      </h4>
      <a class="z-editAccordion__head__icon">
        <b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'"> </b-icon>
      </a>
    </div>
    <transition>
      <div
        v-show="isOpen"
        class="z-editAccordion__body"
        :class="isOpen ? 'is-close' : ''"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ZEditAccordion",
  inject: ["$validator"],
  props: {
    title: {
      type: String,
      default: "",
    },
    isProtrude: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: this.opened,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit("toggle", this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-editAccordion {
  margin-bottom: $edit-padding;
  &__head {
    position: relative;
    padding: 8px ($edit-padding + 20px) 8px $edit-padding;
    border-bottom: solid 2px #707070;
    background-color: rgba($primary, 0.06);
    font-size: 20px;
    color: $primary;
    @include sp {
      padding: 9px ($edit-padding-sp + 20px) 9px $edit-padding-sp;
      font-size: 18px;
    }
    &__title {
      &__icon {
        margin-right: 2px;
        vertical-align: bottom;
        /deep/ .mdi:before {
          font-size: 1.3em;
        }
      }
    }
    &__icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $primary;
        &:hover {
          color: $primary;
        }
      }
    }
    &.is-protrude {
      margin-left: -($edit-padding);
      margin-right: -($edit-padding);
      padding: 12px ($edit-padding + 20px) 12px $edit-padding;
      border-bottom: none;
      font-size: 16px;
      cursor: pointer;
      @include sp {
        margin-left: -($edit-padding-sp);
        margin-right: -($edit-padding-sp);
        padding: 10px ($edit-padding-sp + 20px) 10px $edit-padding-sp;
      }
    }
  }
  &__body {
    position: relative;
    padding-top: $edit-padding;
  }
}
</style>
