<script>
import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";

export default {
  name: "Mixins-area",
  methods: {
    $$area$generateTextOfAddress: async function (add, isPublic = false) {
      let postalCode = "";
      if (add.postalCode) {
        postalCode = "〒" + String(add.postalCode);
      }
      const prefecture = (await this.$$prefecture$load(add.prefecture)) || "";
      const city = (await this.$$city$load(add.prefecture, add.city)) || "";
      const address = add.address && !isPublic ? add.address : "";
      const building = add.building && !isPublic ? add.building : "";
      return (
        postalCode +
        " " +
        prefecture["text"] +
        city["cityName"] +
        address +
        building
      );
    },
    $$prefecture$load: async function (prefectureCode) {
      const p = await this.$$ks.getPrefecture(prefectureCode);
      return p;
    },
    $$prefecture$loads: async function () {
      const ret = await this.$$ks.prefectures;
      return ret;
    },
    $$city$load: async function (prefectureCode, cityCode) {
      if (prefectureCode && cityCode) {
        const doc = await firebase.db
          .collection("areas_v1")
          .doc(String(prefectureCode))
          .collection("cities_v1")
          .doc(cityCode)
          .get();

        if (doc.exists) {
          return doc.data();
        } else {
          return { cityName: "" };
        }
        //const cities = await this.$$city$loads(prefectureCode);
        //if (cities) {
        //  return cities.find(c => c.cityCode == cityCode);
        //} else {
        //  return { cityName: "" };
        //}
      } else {
        return { cityName: "" };
      }
    },
    $$city$loads: async function (prefectureCode) {
      if (prefectureCode) {
        //let url = "";
        //if (this.$$env$isProduction) {
        //  url = "https://postcode.teraren.com/prefectures/" + prefectureCode + ".json";
        //} else {
        //  url = "http://localhost:4003/api/prefectures/" + prefectureCode + ".json";
        //}
        //const res = await axios.get(url);
        //console.log(res.data);

        const cities = await firebase.db
          .collection("areas_v1")
          .doc(String(prefectureCode))
          .collection("cities_v1")
          .get()
          .then((querySnapshot) => {
            const is = [];
            querySnapshot.forEach((doc) => {
              is.push(doc.data());
            });
            return is;
          });
        //console.log(cities);
        return cities;
        //return [
        //  {
        //    prefCode: 1,
        //    cityCode: "01100",
        //    cityName: "札幌市",
        //    bigCityFlag: "2"
        //  },
        //  {
        //    prefCode: 1,
        //    cityCode: "01101",
        //    cityName: "札幌市中央区",
        //    bigCityFlag: "1"
        //  },
        //  {
        //    prefCode: 1,
        //    cityCode: "01102",
        //    cityName: "札幌市北区",
        //    bigCityFlag: "1"
        //  },
        //  {
        //    prefCode: 1,
        //    cityCode: "01103",
        //    cityName: "札幌市東区",
        //    bigCityFlag: "1"
        //  }
        //];
      } else {
        return [];
      }
    },
  },
};
</script>
