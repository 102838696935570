<template>
  <div>
    <b-table
      :data="realEstates"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      :default-sort="['data.registerDt', 'desc']"
      current-page.sync="currentPage"
      per-page="50"
      paginated
      :loading="isLoading"
      :checked-rows.sync="internalValue"
      :checkable="checkable"
      :hoverable="true"
      :narrowed="true"
      custom-row-key="id"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="物件概要">
          <div class="columns is-multiline is-mobile">
            <div class="column is-4">
              <router-link
                :to="{
                  name: 'realtor-setting-realestates-preview',
                  params: { id: props.row.id },
                }"
              >
                <figure class="image">
                  <z-image v-model="images(props.row)[0]"> </z-image>
                </figure>
              </router-link>
              <br />
            </div>
            <div class="column">
              <div class="content is-small">
                <p>
                  <z-tag
                    :options="$$ks.realEstateTypes"
                    v-model="props.row.data.realEstateType"
                  />
                </p>
                <z-truncator
                  :text="props.row.data.name"
                  class="real-estate-name"
                >
                </z-truncator>

                <p class="red">
                  {{ $$price$priceJPFormat(props.row.data.price) }} /
                  {{ $$rete$rateFormat(props.row.data.couponYieldRate) }}
                </p>
                <template v-if="props.row.data.overseasRealEstate">
                  <p class="address">
                    {{ overseasAddress(props.row.data.overseas) }}
                  </p>
                </template>
                <template v-else>
                  <p v-text="props.row.address" class="address"></p>
                </template>
                <div class="flex-wrap">
                  <template
                    v-if="
                      props.row.data.realEstateType !== 10 &&
                      props.row.data.realEstateType !== 7
                    "
                  >
                    <p>
                      築年月：{{
                        $$calender$yearMonthFormat(
                          props.row.data.constructionDate
                        )
                      }}
                    </p>
                    <p>
                      建物種別：{{
                        buildingTypeText(props.row.data.buildingType)
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>築年月：-</p>
                    <p>建物種別：-</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-table-column>
        <b-table-column
          field="data.saleSetting.publicType"
          label="公開範囲"
          width="80"
          sortable
        >
          <div class="content is-small">
            {{
              $$ks.realtor.realEstate.publicType(
                props.row.data.saleSetting.publicType
              ).text
            }}
          </div>
        </b-table-column>
        <b-table-column field="" label="オファー状況・掲載状況" width="176">
          <div class="content is-small">
            <p>
              問い合せ：{{ countContact(props.row) }}件<br />
              オファー：{{ countOffers(props.row) }}件<br />
              返答数：{{ countReply(props.row) }}件<br />
              閲覧人数：{{ props.row.viewsCount }}件
            </p>
          </div>
        </b-table-column>
        <b-table-column
          field="data.registerDt"
          label="登録日"
          width="192"
          sortable
        >
          <div class="content is-small">
            <p>
              初回登録日&nbsp;&nbsp;&nbsp;:{{
                $$calender$dateFormat(props.row.data.registerDt)
              }}<br />
              更新日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{{
                $$calender$dateFormat(props.row.data.updatedDt)
              }}<br />
              更新予定日&nbsp;&nbsp;&nbsp;:{{
                $$calender$dateFormat(props.row.data.scheduledUpdateDate)
              }}<br />
            </p>
            <template v-if="!hideModifybutton">
              <router-link
                class="button is-primary is-small is-rounded z-button"
                :to="{
                  name: 'realtor-setting-realestates-detail',
                  params: { id: props.row.id },
                }"
                >表示/変更</router-link
              >
            </template>
          </div>
        </b-table-column>
      </template>
      <!--
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>不動産が登録されていません</p>
          </div>
        </section>
      </template>
      -->
    </b-table>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "RealEstateList",
  props: {
    realEstates: {
      type: [Array],
      default: () => [],
    },
    checkable: {
      type: [Boolean],
      default: false,
    },
    value: {
      type: [Array],
      default: () => [],
    },
    hideModifybutton: {
      type: [Boolean],
      default: false,
    },
    isLoading: {
      type: [Boolean],
      default: false,
    },
  },
  data: function () {
    return {
      //isLoading: true,
      currentPage: 0,
      //realEstates: []
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
  methods: {
    images(realEstate) {
      if (realEstate) {
        return []
          .concat(realEstate.data.images.exteriors)
          .concat(realEstate.data.images.interiors)
          .concat(realEstate.data.images.others);
      } else {
        return [];
      }
    },
    async address(dt) {
      const text = await this.$$area$generateTextOfAddress(dt);
      return text;
    },
    overseasAddress(val) {
      if (val.building) {
        return (
          this.addressText(val.country) +
          ", " +
          val.state +
          ", " +
          val.address +
          ", " +
          val.building
        );
      } else {
        return (
          this.addressText(val.country) + ", " + val.state + ", " + val.address
        );
      }
    },
    countOffers(realEstate) {
      return realEstate.messages.filter(
        (r) => r.data.type === 1 || r.data.type === 2
      ).length;
    },
    //countNormal(realEstate) {
    //  return realEstate.messages.filter(r => r.data.type === 2).length;
    //},
    //countBuying(realEstate) {
    //  //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
    //  //{ value: 2, text: "オファー", color: "is-info" },
    //  //{ value: 3, text: "売却", color: "is-primary" },
    //  //{ value: 4, text: "問い合せ", color: "" }
    //  return realEstate.messages.filter(r => r.data.type === 3).length
    //},
    countReply(realEstate) {
      return realEstate.messages
        .filter(
          (m) => m.data.type === 1 || m.data.type === 2 || m.data.type === 3
        )
        .filter((m) => {
          const uid = m.data.investor;
          return m.data.threads.some((t) => t.from === uid);
        }).length;
    },
    countContact(realEstate) {
      return realEstate.messages.filter((r) => r.data.type === 4).length;
    },
    //async loadRealEstate(r) {
    //  r.address = await this.address(r.data);
    //  r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
    //  return r;
    //},
    //async init() {
    //  const loadingComponent = this.$loading.open();
    //  try {
    //    this.isLoading = true;
    //    const res = await this.$$realtor$loadRealEstatesByCID(
    //      this.me.user.company.id
    //    );
    //    const promises = [];
    //    for (const r of res) {
    //      promises.push(this.loadRealEstate(r));
    //    }
    //    this.realEstates = await Promise.all(promises).then(rs => rs);
    //    this.isLoading = false;
    //    return;
    //  } catch (error) {
    //    this.$$error$catchLoadError(error);
    //  } finally {
    //    loadingComponent.close();
    //  }
    //},
    buildingTypeText(val) {
      return this.$$ks.getTypeOfBuildingType(val).text;
    },
    addressText(val) {
      return this.$$ks.getCountry(val).text;
    },
  },
  //beforeRouteUpdate(to, from, next) {
  //  next();
  //  return this.init();
  //},
  //mounted() {
  //  this.init();
  //}
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.consultant-warp {
  margin: 0 10px;
  @include mobile {
    margin: 0;
  }
}
//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: $important;
//  border-bottom: solid 2px #707070;
//  color: $white;
//}
.realtor-realestate-image {
}
.realestate {
  &__list {
    width: 100%;
    height: 756px;
    background-color: $mintrich-blank;
  }
}

.button {
  margin: 20px;
}

.content {
  p {
    margin: 2.5px 0;
  }
  p:nth-child(2) {
    font-size: 1.5em;
    font-weight: bold;
    //white-space:nowrap;
  }
  p:nth-child(3) {
    font-weight: bold;
    font-size: 1.5em;
    color: red;
  }
  .flex-wrap {
    display: flex;

    p {
      font-size: 1em;
      font-weight: lighter;
    }
    p:nth-child(1) {
      margin-right: 30px;
    }
  }
}

.image {
  max-width: 180px;
  max-height: 180px;
}

.real-estate-name {
  font-size: 18px;
}
</style>
