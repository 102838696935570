<template>
  <div class="marketTrend">
    <section>
      <ZHeader title="Market Trend" sub="市況情報">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <section class="z-section searchFormArea">
      <div class="z-section__inner">
        <SearchForm v-model="searchFilter" />
        <SearchFormLabel :filter="searchFilter" />
      </div>
    </section>

    <b-tabs
      class="z-tabs z-tabs--page z-tabs--jp"
      v-model="currentTabIndex"
      :destroy-on-hide="true"
    >
      <b-tab-item label="不動産取引動向">
        <RealEstateTransaction :filter="searchFilter" />
      </b-tab-item>

      <b-tab-item label="ローン取引動向">
        <LoanTransaction :filter="searchFilter" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import RealEstateTransaction from "./parts/RealEstateTransaction";
import LoanTransaction from "./parts/LoanTransaction";
import SearchForm from "./parts/SearchForm";
import SearchFormLabel from "./parts/SearchFormLabel";

export default {
  name: "Realestate-Index",
  data: function () {
    return {
      marketTrendPageInfo: null,
      currentTabIndex: 0,
      searchFilter: null,
    };
  },
  components: {
    RealEstateTransaction,
    LoanTransaction,
    SearchForm,
    SearchFormLabel,
  },
  watch: {
    currentTabIndex(to) {
      const ctg = to === 0 ? "realestate" : "loan";
      if (this.$route.query.ctg != ctg)
        this.$router.push({ path: "markettrend", query: { ctg: ctg } });
    },
  },
  created() {
    // console.log("created");
    // tabカレント
    const ctg = this.$route.query.ctg;
    if (ctg === "loan") this.currentTabIndex = 1;

    // filter選択済みの情報
    const filter = this.$store.state.investor.mtSearchFilter;
    // console.log(filter);
    if (filter) {
      this.searchFilter = this.$$cloneDeep(filter);
    } else {
      this.searchFilter = this.$$investor$getNewMarketTrendFilter(); // SearchFormで絞り込んだ検索条件
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
