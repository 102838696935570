//import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";
const type = {};

type.transaction = {};
type.transaction.data = {
  companyId: null,
  staffId: null,
  investor: null, // uid
  transactionType: null, // 契約種別
  contractDate: null, // 売買契約日
  settlementDate: null, // 決済実行日
  price: null, // 取引金額
  fee: null, // 仲介手数料
  tradeType: 0, // 0: 購入, 1: 売却
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

type.image = {
  title: "", //タイトル
  description: "",
  id: null,
  // src: "",
  isApproved: true, //承認
};

type.transport = {
  line: null, // 路線名
  station: null, //駅名
  elapsedTime: null, //分
};

type.layout = {
  layout: null, //間取り
  NumberOfUnits: "", //戸数
};

type.realtorTransaction = {
  investor: null, // uid
  realtor: null, // uid
  link: null,
  statusOfRegister: 100, //登録ステータス 専門家登録中
  statusOfAccounting: 100, //会計ステータス  { value: 100, text: "入金前" },
  user: {
    name: null, //投資家氏名
    phoneNumber: null, //電話番号
  },
  transactionType: null, // 契約種別 1:一般媒介, 2:専任媒介, 3:専属選任媒介, 4:代理, 5:仲介, 6:売主
  contractDate: null, // 売買契約日
  settlementDate: null, // 決済実行日
  price: null, // 取引金額
  fee: null, // 仲介手数料
  tradeType: 0, // 0: 購入, 1: 売却

  investorInfo: {
    score: {
      score: 0,
      bs: 0,
      income: 0,
      rank: 4, //C
    },
    bs: {
      assets: {
        sum: 0,
        cash: {
          sum: 0,
        },
        security: {
          sum: 0,
        },
        insurance: {
          sum: 0,
        },
        realEstate: {
          sum: 0,
        },
      },
      liabilities: {
        sum: 0,
      },
      netAssets: {
        sum: 0,
      },
    },
    PL: {
      sum: 0,
      salary: 0, // 給与	年収
      dividend: 0, // 配当・利子	年配当・利子
      pension: 0, // 年金	年収
      realEstate: 0, // 不動産
      others: 0, // その他	年収
    },
  },
  realtorInfo: {
    score: {
      score: 0,
      rank: 4, //C
    },
  },

  realEstate: {
    name: "", //物件名
    realEstateType: null, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
    buildingUse: 1, //不動産用途
    price: null, // 販売価格
    annualEstimatedIncome: null, // 年間想定収入
    couponYieldRate: null, // 表面利回り
    vacancyRate: null, // 現況空室率 1:満室 2:空室あり
    vacantRoom: "", // 空き室数
    // 物件画像
    images: {
      exteriors: [], //外観
      interiors: [], //内観
      layouts: [], //間取り図・プラン
      others: [], //その他
    },
    prefecture: null, //住所（県）
    city: null, // 住所（市町村）
    address: "", // 住所（番地）
    building: "", // 住所（マンション名・部屋番号）
    transports: [], // 交通機関
    buildingType: null, // 建物種別 1:RC・SRC 2:鉄骨 3:軽量鉄骨 4:木造
    constructionDate: {
      year: null,
      month: null,
    }, // 築年月
    buildingArea: null, // 建物面積
    numberOfFloors: null, // 階数
    layouts: [],
    numberOfHouseholds: "", // 総戸数
    parking: "", // 駐車場
    landArea: null, // 土地面積
    landRight: null, // 土地権利1:所有権 2:普通賃借権 3:旧法賃借権 4:普通地上権 5:旧法地上権
    texture: null, // 地目
    urbanPlanningArea: 0, // 都市計画区域 1:市街化区域 2:市街化調整区域 3:その他
    areaOfUse: null, // 用途地域
    buildingCoverageRatio: null, // 建蔽率
    floorAreaRatio: null, // 容積率
    roadBurdenAreaRatio: null, // 私道負担面積
  },
  loanOrCash: 1, // 融資有無
  loan: {
    bankId: "", // 金融機関・支店名 金融機関のID(String)
    branchId: null, // 支店 branchId(String)
    amount: null, // 融資金額
    executeDate: {
      year: null,
      month: null,
    }, // 融資実行年月
    paymentPeriods: {
      year: null,
      month: null,
    }, // 返済期間年月
    // interestType: 1, // 金利タイプ
    interestType: {
      type: null, // 金利タイプ
      interestYearMonth: {
        year: null, // 年
        month: null, // 月
      },
    },
    interestRate: null, // 金利
    method: 1, // 返済方法 1:元利均等 2:元金均等
  },
  realtorEvaluation: {
    realEstateProposal: null, // 不動産提案力
    bankProposal: null, // 融資先金融機関提案力
    investmentKnowledgeAndBussinessPlanProposal: null, // 投資知識・事業計画提案力
    trustAndResponse: null, // 信頼感・対応力
    comment: "", // コメント
  },
  bankAccount: {
    bankId: "", // 金融機関・支店名 金融機関のID(String)
    branchId: null, // 支店 branchId(String)
    depositType: null, // 預金種別
    accountNumber: null, // 口座番号
    accountName: null, // 口座名義
  },

  paymentDt: null,
  cashBackDt: null,

  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

export default {
  type,
};
