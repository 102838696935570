<template>
  <div class="z-income">
    <div class="z-income__inner">
      <ul class="z-income__legends">
        <li
          class="z-income__legends__item z-income__legends__item--salary"
          @click="onClickLegends('Salary')"
          :class="{ isChecked: isSalaryActive }"
        >
          <span class="check"></span>給与・役員報酬
        </li>
        <li
          class="z-income__legends__item z-income__legends__item--realestate"
          @click="onClickLegends('RealEstate')"
          :class="{ isChecked: isRealEstateActive }"
        >
          <span class="check"></span>不動産収入
        </li>
        <li
          class="z-income__legends__item z-income__legends__item--diviend"
          @click="onClickLegends('Dividend')"
          :class="{ isChecked: isDividendActive }"
        >
          <span class="check"></span>配当・利子
        </li>
        <li
          class="z-income__legends__item z-income__legends__item--pension"
          @click="onClickLegends('Pension')"
          :class="{ isChecked: isPensionActive }"
        >
          <span class="check"></span>年金
        </li>
        <li
          class="z-income__legends__item z-income__legends__item--others"
          @click="onClickLegends('Others')"
          :class="{ isChecked: isOthersActive }"
        >
          <span class="check"></span>その他
        </li>
      </ul>
      <ZIncomeChart
        class="z-income__chart"
        :quarterData="chartData"
        :currentPL="pl"
        :isSalaryActive="isSalaryActive"
        :isDividendActive="isDividendActive"
        :isPensionActive="isPensionActive"
        :isRealEstateActive="isRealEstateActive"
        :isOthersActive="isOthersActive"
      />
    </div>
    <ZQuarterAxis
      :quarterData="chartData"
      :currentScore="score"
      :year.sync="selectYear"
    />
  </div>
</template>

<script>
import ZIncomeChart from "@/components/charts/ZIncomeChart.vue";
import ZQuarterAxis from "@/components/charts/ZQuarterAxis.vue";

export default {
  name: "ZIncome",
  components: {
    ZIncomeChart,
    ZQuarterAxis,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    pl: {
      type: Object,
      required: true,
    },
    score: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickLegends(category) {
      this[`is${category}Active`] = !this[`is${category}Active`];
    },
  },
  data() {
    return {
      isSalaryActive: true,
      isDividendActive: true,
      isPensionActive: true,
      isRealEstateActive: true,
      isOthersActive: true,
    };
  },
  computed: {
    selectYear: {
      get() {
        return this.year;
      },
      set(newVal) {
        if (this.year !== newVal) this.$emit("update:year", newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-income {
  width: 100%;
  // padding: 0 46px;
  position: relative;
  overflow: hidden;
  @include sp {
    padding: 0;
  }
}

.z-income__inner {
  border: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  @include sp {
    border-radius: 0;
  }
}

.z-income__chart {
  padding-right: 65px;
  position: relative;
  margin-left: -20%;
  /deep/ canvas {
    height: 320px !important;
    position: relative;
  }
  @include sp {
    padding-right: 0;
  }
}

.z-income__legends {
  width: 100%;
  height: 62px;
  top: 0;
  padding: 0 20px;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  @include sp {
    flex-wrap: wrap;
  }
  &__item {
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
      margin-right: 20px;
    }
    @include sp {
      font-size: 10px;
    }
    .check {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      display: inline-block;
      position: relative;
      background-color: #333;
      vertical-align: -3.5px;
      @include sp {
        vertical-align: -5px;
      }
    }
    &.isChecked {
      .check::before {
        width: 10px;
        height: 5px;
        left: 3px;
        top: 5px;
        display: block;
        position: absolute;
        transform: rotate(-45deg);
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        content: "";
      }
    }
    &--salary {
      .check {
        background-color: #9ad6ff;
      }
    }
    &--realestate {
      .check {
        background-color: #839dff;
      }
    }
    &--diviend {
      .check {
        background-color: #5aeada;
      }
    }
    &--pension {
      .check {
        background-color: #3d75f5;
      }
    }
    &--others {
      .check {
        background-color: #2d3f6b;
      }
    }
  }
}
</style>
