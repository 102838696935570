<template>
  <div class="z-checkbox">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
    >
      <div v-if="showAllCheck" class="block">
        <b-checkbox
          key="allCheck"
          type="is-info"
          v-model="isAllCheck"
          @input="changeAllCheck()"
        >
          全選択する
        </b-checkbox>
      </div>
      <div class="block">
        <template v-for="o in options">
          <b-checkbox
            :key="o.value"
            :name="internalName"
            :native-value="o.value"
            :data-vv-as="label"
            v-model="internalValue"
            v-validate="validate"
            type="is-info"
          >
            {{ o.text }}
          </b-checkbox>
        </template>
      </div>
    </ZField>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZCheckbox",
  mixins: [InputMixin],
  props: {
    options: {
      type: [Array],
      required: true,
    },
    value: {
      type: [Array],
      default: () => [],
    },
    showAllCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAllCheck: false,
    };
  },
  methods: {
    changeAllCheck() {
      if (this.isAllCheck) {
        let ary = [];
        for (let i = 0; this.options.length > i; i++) {
          ary.push(this.options[i].value);
        }
        this.internalValue = ary;
      } else {
        this.internalValue = [];
      }
    },
  },
  watch: {
    internalValue(/*to, from*/) {
      if (this.internalValue.length === this.options.length) {
        this.isAllCheck = true;
      } else {
        this.isAllCheck = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
