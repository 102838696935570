<template>
  <div class="z-select">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      :isShort="isShort"
    >
      <b-select
        :name="internalName"
        :icon="icon"
        :size="size"
        :placeholder="placeholder || label + 'を選択してください'"
        v-model="internalValue"
        :data-vv-as="label"
        v-validate="validate"
        expanded
      >
        <template v-if="nullable">
          <option :value="null" :key="null">未選択</option>
        </template>
        <template v-for="o in options">
          <option :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </template>
      </b-select>
    </ZField>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZSelect",
  mixins: [InputMixin],
  props: {
    options: {
      type: [Array],
      default: () => [],
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    nullable: {
      type: [Boolean],
      default: false,
    },
  },
  created() {
    // if (this.value === 0) this.value = null;
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
</style>
