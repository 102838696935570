<template>
  <div>
    <b-table
      :data="realtorApplications"
      checkable
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="data.registerDt"
      default-sort-direction="desc"
      custom-row-key="id"
      :striped="true"
      :hoverable="true"
      :mobile-cards="true"
    >
      <template slot-scope="props">
        <b-table-column field="data.company.name" label="会社名" sortable>
          {{ props.row.data.company.name }}
        </b-table-column>
        <b-table-column field="data.user.name" label="名前" sortable>
          {{ props.row.data.user.name }}
        </b-table-column>
        <b-table-column
          field="data.user.department"
          label="所属・部署"
          sortable
        >
          {{ props.row.data.user.department }}
        </b-table-column>
        <b-table-column field="data.user.title" label="役職" sortable>
          {{ props.row.data.user.title }}
        </b-table-column>
        <b-table-column field="data.user.phoneNumber" label="電話番号" sortable>
          {{ props.row.data.user.phoneNumber }}
        </b-table-column>
        <b-table-column field="data.user.email" label="email" sortable>
          {{ props.row.data.user.email }}
        </b-table-column>
        <b-table-column field="data.comment" label="問合せ内容">
          <pre class="normal pre-text" v-text="props.row.data.comment"></pre>
        </b-table-column>
        <b-table-column field="data.registerDt" label="登録日" sortable>
          {{ $$calender$dateFormat(props.row.data.registerDt) }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "reartors-application-list",
  props: {
    realtorApplications: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.pre-text {
  white-space: pre-wrap;
}
</style>
