<template>
  <div>
    <router-view></router-view>
    <div class="chartParts">
      <div class="chartParts__left">
        <section class="section">
          <h1 class="title is-1">ZBalanceSheet</h1>
          <ZBalanceSheet
            :chartData="balanceSheetChartData"
            :score="score"
            :bs="bs"
            :year.sync="balanceSheetChartYear"
          />
        </section>
        <section class="section">
          <h1 class="title is-1">ZIncome</h1>
          <ZIncome
            :chartData="incomeChartData"
            :score="score"
            :pl="pl"
            :year.sync="incomeChartYear"
          />
        </section>
      </div>
      <div class="chartParts__right">
        <section class="section">
          <h2 class="title is-2">スコア</h2>
          <ZInput
            label="スコア"
            type="number"
            name="score"
            v-model.number="score.score"
          />

          <ZSelect
            label="ランク"
            :options="this.$$ks.ranks"
            v-model="score.rank"
            isRequired
          />

          <h2 class="title is-2">BS値</h2>

          <ZInput
            label="通常収入"
            type="number"
            name="bl.cash"
            v-model.number="bs.assets.cash.sum"
          />

          <ZInput
            label="投資収入(証券)"
            type="number"
            name="bl.investment"
            v-model.number="bs.assets.security.sum"
          />

          <ZInput
            label="投資収入(保険)"
            type="number"
            name="bl.investment"
            v-model.number="bs.assets.insurance.sum"
          />

          <ZInput
            label="不動産収入"
            type="number"
            name="bl.buildings"
            v-model.number="bs.assets.realEstate.sum"
          />

          <ZInput
            label="融資されている額"
            type="number"
            name="bl.longTermDebt"
            v-model.number="bs.liabilities.sum"
          />

          <ZInput
            label="純資産額"
            type="number"
            name="bl.equity"
            v-model.number="bs.netAssets.sum"
          />

          <h2 class="title is-2">PL値</h2>

          <ZInput
            label="合計"
            type="number"
            name="pl.sum"
            v-model.number="pl.sum"
          />

          <ZInput
            label="収入内訳1"
            type="number"
            name="pl.salary"
            v-model.number="pl.salary"
          />

          <ZInput
            label="収入内訳2"
            type="number"
            name="pl.dividend"
            v-model.number="pl.dividend"
          />

          <ZInput
            label="収入内訳3"
            type="number"
            name="pl.pension"
            v-model.number="pl.pension"
          />

          <ZInput
            label="収入内訳4"
            type="number"
            name="pl.realEstate"
            v-model.number="pl.realEstate"
          />

          <ZInput
            label="その他"
            type="number"
            name="pl.others"
            v-model.number="pl.others"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ZBalanceSheet from "@/components/charts/ZBalanceSheet.vue";
import ZIncome from "@/components/charts/ZIncome.vue";

export default {
  name: "admin-demo-chart-parts",
  components: {
    ZBalanceSheet,
    ZIncome,
  },
  data() {
    return {
      // バランスシート(APIのリクエスト)
      balanceSheetChartYear: "2019",
      // 収入区分グラフ(APIのリクエスト)
      incomeChartYear: "2019",
      // バランスシート(APIのレスポンス)
      balanceSheetChartData: [
        {
          quarter: 1,
          score: {
            score: 600,
            rank: 2,
          },
          BS: {
            assets: {
              sum: 10000000,
              cash: {
                sum: 5000000,
              },
              security: {
                sum: 2000000,
              },
              insurance: {
                sum: 2000000,
              },
              realEstate: {
                sum: 1000000,
              },
            },
            liabilities: {
              sum: 3000000,
            },
            netAssets: {
              sum: 7000000,
            },
          },
        },
        {
          quarter: 2,
          score: {
            score: 400,
            rank: 1,
          },
          BS: {
            assets: {
              sum: 10000000,
              cash: {
                sum: 5000000,
              },
              security: {
                sum: 2000000,
              },
              insurance: {
                sum: 2000000,
              },
              realEstate: {
                sum: 1000000,
              },
            },
            liabilities: {
              sum: 3000000,
            },
            netAssets: {
              sum: 7000000,
            },
          },
        },
        {
          quarter: 3,
          score: {
            score: 300,
            rank: 2,
          },
          BS: {
            assets: {
              sum: 10000000,
              cash: {
                sum: 5000000,
              },
              security: {
                sum: 2000000,
              },
              insurance: {
                sum: 2000000,
              },
              realEstate: {
                sum: 1000000,
              },
            },
            liabilities: {
              sum: 3000000,
            },
            netAssets: {
              sum: 7000000,
            },
          },
        },
        {
          quarter: 4,
          score: {
            score: 300,
            rank: 2,
          },
          BS: {
            assets: {
              sum: 10000000,
              cash: {
                sum: 5000000,
              },
              security: {
                sum: 2000000,
              },
              insurance: {
                sum: 2000000,
              },
              realEstate: {
                sum: 1000000,
              },
            },
            liabilities: {
              sum: 3000000,
            },
            netAssets: {
              sum: 7000000,
            },
          },
        },
      ],
      // 収入区分グラフ(APIのレスポンス)
      incomeChartData: [
        {
          quarter: 1,
          score: {
            score: 600,
            rank: 2,
          },
          PL: {
            sum: 1248000,
            salary: 500000,
            dividend: 240000,
            pension: 350000,
            realEstate: 120000,
            others: 38000,
          },
        },
        {
          quarter: 2,
          score: {
            score: 400,
            rank: 1,
          },
          PL: {
            sum: 1885000,
            salary: 520000,
            dividend: 220000,
            pension: 320000,
            realEstate: 800000,
            others: 25000,
          },
        },
        {
          quarter: 3,
          score: {
            score: 300,
            rank: 2,
          },
          PL: {
            sum: 1175830,
            salary: 540000,
            dividend: 200000,
            pension: 300000,
            realEstate: 50000,
            others: 35830,
          },
        },
        {
          quarter: 4,
          score: {
            score: 300,
            rank: 2,
          },
          PL: {
            sum: 1175830,
            salary: 540000,
            dividend: 200000,
            pension: 300000,
            realEstate: 50000,
            others: 35830,
          },
        },
      ],
      // スコア
      score: {
        score: 600,
        rank: 4,
      },
      // BS値
      bs: {
        assets: {
          sum: 10000000,
          cash: {
            sum: 5000000,
          },
          security: {
            sum: 2000000,
          },
          insurance: {
            sum: 2000000,
          },
          realEstate: {
            sum: 1000000,
          },
        },
        liabilities: {
          sum: 3000000,
        },
        netAssets: {
          sum: 7000000,
        },
      },
      // PL値
      pl: {
        sum: 1175830,
        salary: 540000,
        dividend: 200000,
        pension: 300000,
        realEstate: 50000,
        others: 35830,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.chartParts {
  display: flex;
  @include sp {
    display: block;
  }
  &__left {
    width: 100% * 2/3;
    @include sp {
      width: 100%;
    }
  }
  &__right {
    width: 100% * 1/3;
    @include sp {
      width: 100%;
    }
  }
}
</style>
