<template>
  <span
    class="z-helpIcon"
    :class="onlyPC ? 'is-onlyPC' : ''"
    :style="{ 'vertical-align': vertical }"
  >
    <template v-if="help">
      <b-tooltip type="is-dark" :label="help" multilined animated>
        <b-icon class="helpIcon" icon="help"></b-icon>
      </b-tooltip>
    </template>
    <template v-else>
      <b-icon class="helpIcon" icon="help"></b-icon>
    </template>
  </span>
</template>

<script>
export default {
  name: "ZHelpIcon",
  props: {
    help: String,
    onlyPC: Boolean,
    vertical: {
      type: String,
      default: "middle",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-helpIcon {
  display: inline-block;
  vertical-align: middle;
  .helpIcon {
    position: relative;
    background-color: #dddddd;
    color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    vertical-align: middle;
    /deep/ .icon {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    /deep/ .mdi {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1em;
      &::before {
        font-size: 12px !important;
        line-height: 1em;
      }
    }
    &::before {
      border-color: #000;
    }
    &::after {
      background-color: #000;
      color: #fff;
      font-size: 14px;
      line-height: 1.2em;
      text-align: left;
    }
  }
  &.is-onlyPC {
    @include sp {
      display: none;
    }
  }
}
</style>
