<template>
  <div class="summary" :class="{ 'is-show': isShowSummary }">
    <div class="summary__inner">
      <div class="summary__content">
        <div class="summary__score">
          <ZScoreProgress
            :score="score"
            :rank="$$user$score.rank"
            :transitionDuration="duration"
          >
            <template slot="footer">
              <div class="name">
                {{ name }}
              </div>
              <div class="company">
                {{ careerName }}
              </div>
            </template>
          </ZScoreProgress>
        </div>
        <ZBalanceTable class="summary__balance" :bs="$$user$BS" />
        <ZIncomeBar class="summary__income" :pl="$$user$PL" />
        <ZProfileThumbnail class="summary__profile" v-model="profileImage" />
        <router-link
          class="summary__link"
          :to="{ name: 'investor-portfolio', query: { ctg: 'profile' } }"
          v-if="!isHideMenu"
          >PROFILE</router-link
        >
      </div>
    </div>
    <a class="summary__pullBtn" @click="toggleShowSummary"></a>
  </div>
</template>

<script>
import { get } from "lodash";
import ZScoreProgress from "@/components/parts/ZScoreProgress.vue";
import ZBalanceTable from "@/components/parts/ZBalanceTable.vue";
import ZIncomeBar from "@/components/parts/ZIncomeBar.vue";
import ZProfileThumbnail from "@/components/parts/ZProfileThumbnail.vue";

export default {
  name: "Summary",
  components: {
    ZScoreProgress,
    ZBalanceTable,
    ZIncomeBar,
    ZProfileThumbnail,
  },
  props: {
    isHideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isInitialize: false,
      isShowSummary: false,
      offsetTimer: null,
    };
  },
  computed: {
    score() {
      // console.log("score");
      return this.$$user$score.score;
    },
    name() {
      if (this.me && this.me.user) {
        return get(this.me.user, "data.base.name", "");
      } else {
        return "";
      }
    },
    careerName() {
      if (this.me && this.me.user) {
        return get(this.me.user, "data.profile.currentOfCareer.name", "");
      } else {
        return "";
      }
    },
    profileImage() {
      if (this.me && this.me.user) {
        return get(
          this.me.user,
          "data.profile.image",
          this.$$investor$getNewImage()
        );
      } else {
        return "";
      }
    },
    duration() {
      return this.isInitialize ? 1000 : 0;
    },
  },
  methods: {
    toggleShowSummary() {
      this.isShowSummary = !this.isShowSummary;
    },
  },
  mounted() {
    this.offsetTimer = setTimeout(() => {
      this.isInitialize = true;
    }, 500);
  },
  beforeDestroy() {
    if (this.offsetTimer) {
      window.clearTimeout(this.offsetTimer);
      this.offsetTimer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.summary {
  width: $summary-width;
  height: 100%;
  top: 0;
  left: $menu-width-md;
  position: fixed;
  background-color: #0590e8;
  z-index: $zindex-summary;
  @include md {
    width: $summary-width-md-close;
    left: $menu-width-md;
    transition: width 0.3s ease;
    &.is-show {
      width: $summary-width-md;
    }
  }
  @include sp {
    width: 100%;
    height: auto;
    left: 0;
    position: relative;
    margin-top: $menu-height-sp;
    z-index: inherit;
  }
}

.summary__inner {
  width: 100%;
  height: 100%;
  background-color: #0590e8;
  text-align: center;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include sp {
    overflow-y: hidden;
  }
}

.summary__content {
  width: $summary-width;
  padding-bottom: 30px;
  @include md {
    width: $summary-width-md;
  }
  @include sp {
    width: 100%;
    height: 100%;
  }
}

.summary__score {
  padding: 20px 0 44px;
  background: linear-gradient(#37d2ff, #2a7bcf);
  .name {
    font-family: $family-secondary;
    font-size: 24px;
    color: #fff;
    letter-spacing: 0.025em;
  }
  .company {
    margin-top: 2px;
    font-size: 15px;
    color: #fff;
  }
}

.summary__balance {
  margin-top: -44px;
}

.summary__income {
  margin-top: 4px;
}

.summary__profile {
  width: 80px;
  height: 80px;
  margin: 21px auto 0;
  padding-top: 0;
  /deep/ img {
    box-shadow: 0px 0px 39px 0px rgba(0, 1, 1, 0.45);
  }
}

.summary__link {
  margin-top: 18px;
  padding: 0 34px;
  display: inline-block;
  border: solid 1px rgba(#fff, 0.3);
  border-radius: 17px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  &:hover {
    background-color: rgba(#fff, 0.8);
    color: #0590e8;
    transition: all 0.3s ease;
  }
}

.summary__pullBtn {
  width: 44px;
  height: 44px;
  top: 50vh;
  right: -28px;
  margin: auto;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #202c64;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  &::after {
    content: "";
    width: 14px;
    height: 14px;
    display: block;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: translate(-25%, -1.5px) rotate(45deg);
    .is-show & {
      transform: translate(15%, -1.5px) rotate(225deg);
    }
  }
  @include md {
    display: flex;
  }
}
</style>
