<script>
//import firebase from "@/plugins/firebase";
import { cloneDeep, defaultsDeep } from "lodash";

export default {
  name: "Mixins-cloneDeep",
  methods: {
    $$assign: function (type, target) {
      return defaultsDeep(cloneDeep(target), cloneDeep(type));
    },
    $$cloneDeep: function (target) {
      return cloneDeep(target);
    },
  },
};
</script>
