<template>
  <div>
    <section>
      <ZHeader title="My Agent" sub="不動産担当者評価">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="investor-favorite-realtor__msg">
          <p>
            過去に提案された不動産担当者に対し、今後の継続提案の可否を管理できます。<br />
            あなたのお気に入りの不動産担当者を見つけて、物件紹介リレーションを結びましょう。
          </p>
        </div>
        <div class="investor-favorite-realtor__msg2">
          <p>
            <span style="font-weight: bold">◎優先提案</span
            >：優先的に提案をもらえるようになります<br class="sp-show" />
            <span style="font-weight: bold">○継続提案</span
            >：継続して提案を受け取ることができます<br class="sp-show" />
            <span style="font-weight: bold">×提案拒否</span
            >：今後の提案をブロックします
          </p>
        </div>
        <div class="investor-favorite-realtor__sort">
          <div class="investor-favorite-realtor__sort__inner">
            <div class="investor-favorite-realtor__sort__title">
              <p>並び替え</p>
            </div>
            <div class="investor-favorite-realtor__sort__select">
              <z-select v-model="sortType" :options="sortTypes" />
            </div>
          </div>
        </div>
        <div v-if="!isLoading" class="investor-favorite-realtor__favs">
          <template v-for="realtor in realtors">
            <div
              :key="realtor.realtor.id"
              class="investor-favorite-realtor__fav"
            >
              <RealtorFavoriteCard
                :realtor="realtor.realtor"
                :favorite="realtor.favorite"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as favorite from "@/modules/investor/favorite";
import * as investor from "@/modules/investor/investor";
// import * as favorite from "@/modules/realtor";

export default {
  name: "investor-favorite-realtor",
  components: {},
  data: function () {
    return {
      sortType: 1,
      realtors: [],
      isLoading: true,
      sortTypes: [
        { value: 1, text: "提案ステータス順" },
        { value: 2, text: "直近提案日順" },
      ],
    };
  },
  watch: {
    sortType() {
      this.init();
    },
  },
  computed: {},
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        const uid = this.auth.uid;
        this.isLoading = true;
        const realtors = [];

        const ret = await investor.search({
          // _source: [
          //   'uid',
          //   'user.data.base.name',
          //   'user.data.latestScore',
          // ],
          query: {
            term: {
              "uid.keyword": {
                value: uid,
              },
            },
          },
        });

        if (!ret.hits[0]) return;
        const rss = ret.hits[0]._source.realtor.realtors;
        console.log(rss);
        for (const rs of rss) {
          const r = await this.$$realtor$loadUser(rs.realtor);
          const f = await favorite.load(uid, rs.realtor);
          if (r.data.isActivate) {
            realtors.push({
              latestSendDt: rs.latestSendDt,
              realtor: r,
              favorite: f,
            });
          }
        }
        if (this.sortType === 1) {
          this.realtors = realtors.sort(
            (a, b) => a.favorite.data.type - b.favorite.data.type
          );
        } else if (this.sortType === 2) {
          this.realtors = realtors.sort((a, b) =>
            a.latestSendDt.sendDt < b.latestSendDt.sendDt ? 1 : -1
          );
        } else {
          this.realtors = realtors;
        }
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investor-favorite-realtor {
  &__msg {
    margin-top: 24px;
    margin-bottom: 8px;
    > p {
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &__msg2 {
    background-color: #e6effc;
    padding: 12px 9px;
    > p {
      font-size: 16px;
      color: #333333;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &__sort {
    width: 100%;
    height: 67px;
    @include sp {
      height: 80px;
    }
    &__inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      @include sp {
        flex-direction: column;
        margin-top: 12px;
      }
    }
    &__title {
      flex: 1;
      @include sp {
        width: 100%;
      }
      > p {
        width: 100%;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        color: #3a3a3a;
        letter-spacing: 0;
        line-height: 16px;
        @include sp {
          text-align: left;
        }
      }
    }
    &__select {
      width: 176px;
      margin-left: 8px;
      margin-top: 29px;
      @include sp {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
  &__favs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    @include sp {
      margin-top: -16px;
    }
  }
  &__fav {
    margin: 0px 15px 15px 0px;
    @include sp {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}

.sp-show {
  display: none;
  @include sp {
    display: block;
  }
}
.sp-none {
  display: block;
  @include sp {
    display: none;
  }
}
</style>
