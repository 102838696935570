<template>
  <div class="formRealEstateUnit">
    <!-- 基本情報 -->
    <section class="edit__section">
      <h3 class="edit__subtitle">基本情報</h3>

      <z-input
        label="物件名"
        v-model="value.data.name"
        :validate="'required'"
        maxlength="100"
        placeholder="例）東京都千代田区マンション"
        additionalMessage="ご自身が管理しやすい名前をご自由に記載ください"
        isRequired
        isPrivate
      />

      <z-radio
        label="不動産用途"
        v-model="value.data.buildingUse"
        :options="buildingUses"
        :validate="'required'"
        isRequired
      />

      <z-radio
        label="物件種別"
        v-model="value.data.realEstateType"
        :options="realEstateTypes"
        :validate="'required'"
        isRequired
      />

      <z-prefecture-select
        label="都道府県"
        v-model="value.data.prefecture"
        :validate="'required'"
        isRequired
      >
      </z-prefecture-select>

      <z-city-select
        label="市区町村"
        v-model="value.data.city"
        :prefecture="value.data.prefecture"
        :validate="'required'"
        isRequired
      >
      </z-city-select>

      <z-input
        label="番地"
        v-model="value.data.address"
        placeholder="番地：1-1-1"
        maxlength="30"
        isPrivate
      />

      <z-input
        label="マンション名・部屋番号"
        v-model="value.data.building"
        maxlength="30"
        placeholder="マンション名：ZIRITZ TOWER 1111号室"
        isPrivate
      />

      <template
        v-if="
          value.data.realEstateType !== 7 && value.data.realEstateType !== 10
        "
      >
        <z-input
          label="階数"
          v-model="value.data.numberOfFloors"
          :validate="'required'"
          maxlength="30"
          placeholder="例)1階/6階建て"
          helpMessage="建物の階数、及び物件の階数。"
          isRequired
        />

        <z-radio
          label="建物種別"
          v-model="value.data.buildingType"
          :options="typeOfBuildingTypes"
          :validate="'required'"
          helpMessage="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
          isRequired
        />

        <z-year-month-select
          label="築年月"
          v-model.number="value.data.constructionDate"
          :validate="'required'"
          isRequired
        />

        <z-input
          label="建物面積"
          v-model.number="value.data.buildingArea"
          :validate="'required|min_value:0.01|max_value:99999.99'"
          placeholder="例)100.00㎡"
          type="number"
          unit="㎡"
          isRequired
          isShort
        />
      </template>

      <z-price-input
        label="年間想定収入"
        v-model="value.data.annualEstimatedIncome"
        :validate="'required'"
        maxlength="12"
        placeholder="例)20,000,000"
        helpMessage="当該物件が満室、かつ、想定賃料（又は現在の賃料）が一年間得られた場合の収入を想定。公租公課その他物件を維持するために必要な費用（管理費等）を控除する前のもの。"
        isRequired
        isPrivate
      />

      <z-price-input
        label="購入金額"
        v-model="value.data.purchase.amount"
        :validate="'required|price_min_value:1'"
        maxlength="12"
        placeholder="例)100,000,000"
        isRequired
        isPrivate
      />

      <z-year-month-select
        label="購入年月"
        v-model.number="value.data.purchase.buyingDt"
        :validate="'required'"
        isRequired
        isPrivate
      />
    </section>
    <!-- / 基本情報 -->

    <hr class="edit__hr" />

    <!-- 交通 -->
    <section class="edit__section">
      <h3 class="edit__subtitle">交通</h3>

      <ul class="edit__list">
        <li
          v-for="(transport, i) in value.data.transports"
          :index="i"
          :key="i"
          class="edit__list__item"
        >
          <z-edit-accordion :title="'交通' + (i + 1)" isProtrude>
            <div>
              <z-line-select
                label="沿線"
                :prefecture="value.data.prefecture"
                v-model="transport.line"
                :validate="'required'"
                isRequired
              >
              </z-line-select>
              <z-station-select
                label="最寄り駅"
                :line="transport.line"
                v-model="transport.station"
                :validate="'required'"
                isRequired
              >
              </z-station-select>

              <z-input
                label="駅からの距離（徒歩）"
                v-model.number="transport.elapsedTime"
                :validate="'required|integer|min_value:1|max_value:99'"
                type="tel"
                maxlength="2"
                placeholder="例)8"
                unit="分"
                isRequired
                isShort
              />

              <div class="has-text-right">
                <delete-button
                  v-if="i > 0"
                  label="削除する"
                  @click="deleteTransport(i)"
                />
              </div>
            </div>
          </z-edit-accordion>
        </li>
      </ul>
      <add-button label="追加する" @click="addTransport()" />
    </section>
    <!-- / 交通 -->

    <hr class="edit__hr" />

    <!-- 物件画像 -->
    <section class="edit__section">
      <h3 class="edit__subtitle">物件画像</h3>

      <ZField label="外観">
        <ZThumbnailListSelect
          v-model="value.data.images.exteriors"
          title="外観"
          :max="10"
        />
      </ZField>

      <hr class="edit__hr2" />

      <ZField label="内装">
        <ZThumbnailListSelect
          v-model="value.data.images.interiors"
          title="内装"
          :max="10"
        />
      </ZField>

      <hr class="edit__hr2" />

      <ZField label="間取り図・プラン">
        <ZThumbnailListSelect
          v-model="value.data.images.layouts"
          title="間取り図・プラン"
          :max="10"
        />
      </ZField>

      <hr class="edit__hr2" />

      <ZField label="その他">
        <ZThumbnailListSelect
          v-model="value.data.images.others"
          title="その他"
          :max="10"
        />
      </ZField>
    </section>
    <!-- / 物件画像 -->

    <hr class="edit__hr" />

    <!-- 融資情報 -->
    <section>
      <div class="edit__section">
        <h3 class="edit__subtitle">融資情報</h3>

        <z-radio
          label="融資有無"
          v-model="value.data.purchase.loanOrCash"
          :validate="'required'"
          :options="loanOrCashTypes"
          isRequired
          isPrivate
        />
        <template v-if="value.data.purchase.loanOrCash !== 2">
          <z-price-input
            label="融資金額"
            v-model="value.data.purchase.loan.amount"
            :validate="'required|price_min_value:1'"
            maxlength="12"
            placeholder="例)100,000,000"
            isRequired
            isPrivate
          />

          <z-bank-select
            label="金融機関"
            v-model="value.data.purchase.loan.bankId"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-branch-select
            label="支店名"
            :bank="value.data.purchase.loan.bankId"
            v-model="value.data.purchase.loan.branchId"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-year-month-select
            label="融資実行年月"
            v-model="value.data.purchase.loan.executeDate"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-year-month-period-input
            label="返済期間"
            v-model="value.data.purchase.loan.paymentPeriods"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-radio
            label="金利タイプ"
            v-model="value.data.purchase.loan.interestType.type"
            :validate="'required'"
            :options="interestTypes"
            helpMessage="借入金利の種類。固定金利：借入れ時の金利が全借入期間もしくは一定期間変わらないタイプ。変動金利：金融情勢の変化に伴い返済の途中でも定期的に金利が変動するタイプ。"
            isRequired
            isPrivate
          />
          <z-year-month-period-input
            v-if="value.data.purchase.loan.interestType.type === 1"
            class="no-fieldMargin"
            name="期間"
            v-model="value.data.purchase.loan.interestType.interestYearMonth"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-percent-input
            label="金利"
            v-model="value.data.purchase.loan.interestRate"
            :validate="'required|min_value:0.01|max_value:100'"
            :decimal="2"
            placeholder="例)1.55"
            isRequired
            isPrivate
            isShort
          />

          <z-radio
            label="返済方法"
            v-model="value.data.purchase.loan.method"
            :validate="'required'"
            :options="loanMethodTypes"
            helpMessage="融資・ローンの毎月の返済方法。元利均等返済：毎月の支払いの返済額が一定となる返済方法。元金均等返済：毎月の支払いの返済額のうち、元金の額が一定となる返済方法。"
            isRequired
            isPrivate
          />
        </template>
      </div>

      <template v-if="value.data.purchase.loanOrCash !== 2">
        <div
          class="edit__section"
          :class="isAutoCalc ? 'edit__section--box' : 'opt-margin-top'"
        >
          <div class="z-field">
            <b-switch
              v-model="value.data.purchase.isAutoCalc"
              class="z-switch"
              type="is-info"
            >
              自動計算
            </b-switch>
          </div>

          <z-price-input
            label="年返済額(利息)"
            v-model="amountInterestRepaidPerYear"
            :validate="'required|price_min_value:1'"
            maxlength="12"
            placeholder="例)1,000,000"
            isRequired
            isPrivate
            :disabled="isAutoCalc"
          />

          <z-price-input
            label="年返済額(元本)"
            v-model="amountPrincipalRepaidPerYear"
            :validate="'required|price_min_value:1'"
            maxlength="12"
            placeholder="例)1,000,000"
            isRequired
            isPrivate
            :disabled="isAutoCalc"
          />

          <z-price-input
            label="残債"
            v-model="outstandingDebt"
            :validate="'required|price_min_value:1'"
            maxlength="12"
            placeholder="例)100,000,000"
            isRequired
            isPrivate
            :disabled="isAutoCalc"
          />
        </div>
      </template>
    </section>
    <!-- / 融資情報 -->

    <!-- 売却設定情報 -->
    <template v-if="true">
      <template
        v-if="
          value.data.realEstateType !== 7 && value.data.realEstateType !== 10
        "
      >
        <hr class="edit__hr" />

        <!-- 建物情報 -->
        <section class="edit__section">
          <h3 class="edit__subtitle">建物情報</h3>

          <z-layout-select
            label="間取"
            v-model="value.data.saleSetting.layouts"
          />

          <z-input
            label="総戸数"
            v-model="value.data.saleSetting.numberOfHouseholds"
            :validate="'min_value:1|max_value:999'"
            type="tel"
            maxlength="3"
            placeholder="例)12"
            helpMessage="その物件にある住戸の数の合計数。"
            unit="戸"
            isShort
          />

          <z-input
            label="駐車場"
            v-model="value.data.saleSetting.parking"
            maxlength="100"
            placeholder="例)屋根付き、10車まで駐車可能"
            helpMessage="駐車場の有無。台数、料金などを表示。"
          />
        </section>
        <!-- / 建物情報 -->
      </template>

      <hr class="edit__hr" />

      <!-- 土地詳細 -->
      <section class="edit__section">
        <h3 class="edit__subtitle">土地詳細</h3>

        <z-input
          label="土地面積"
          v-model.number="value.data.saleSetting.landArea"
          type="number"
          :validate="'required|min_value:0.011|max_value:99999.99'"
          placeholder="例)100.00㎡"
          unit="㎡"
          isRequired
        />

        <z-select
          label="土地権利"
          v-model.number="value.data.saleSetting.landRight"
          :options="landRightTypes"
          :validate="'required'"
          isRequired
        />

        <z-select
          label="地目"
          v-model.number="value.data.saleSetting.texture"
          :options="textureTypes"
          helpMessage="土地の現況と利用状況による区分のこと。「宅地」、「雑種地」、「畑」、「用悪水路」、「その他」で表示。"
        />

        <z-radio
          label="都市計画区域"
          v-model.number="value.data.saleSetting.urbanPlanningArea"
          :options="urbanPlanningAreaTypes"
          helpMessage="都道府県ごとに定められ、無秩序な市街化を防止し、計画的な市街化を図るために定めている。「市街化区域」：優先的かつ計画的に市街化を進める区域。「市街化調整区域」：開発行為は原則として抑制され、都市施設の整備も原則として行われない区域"
        />

        <z-select
          label="用途地域"
          v-model.number="value.data.saleSetting.areaOfUse"
          :options="areaOfUseTypes"
          helpMessage="用途の混在を防ぐ目的で定められた、13種類の区域。設定された区域ごとに建築物の用途、容積率、構造等に関し一定の制限を加えることができる。"
        />

        <z-percent-input
          label="建蔽率"
          v-model.number="value.data.saleSetting.buildingCoverageRatio"
          :validate="'min_value:0.01|max_value:100'"
          :decimal="2"
          placeholder="例)60"
          helpMessage="敷地（土地）面積に対する建築面積の割合。"
          isShort
        />

        <z-percent-input
          label="容積率"
          v-model.number="value.data.saleSetting.floorAreaRatio"
          :validate="'min_value:1|max_value:9999'"
          :decimal="2"
          placeholder="例)300"
          helpMessage="敷地（土地）面積に対する建物の延床面積の割合。"
          isShort
        />

        <z-input
          label="私道負担面積"
          v-model.number="value.data.saleSetting.roadBurdenAreaRatio"
          :validate="'min_value:0.01|max_value:99999.99'"
          placeholder="例)100.00"
          type="number"
          unit="㎡"
          isShort
        />
      </section>
      <!-- / 土地詳細 -->
    </template>
    <!-- / 売却設定情報 -->
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "FormRealEstateUnit",
  inject: ["$validator"],
  props: {
    value: Object,
  },
  data() {
    return {
      realEstateTypes: kubuns.realEstateTypes,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
      loanOrCashTypes: kubuns.loanOrCashTypes,
      loanMethodTypes: kubuns.loanMethodTypes,
      landRightTypes: kubuns.landRightTypes,
      textureTypes: kubuns.textureTypes,
      urbanPlanningAreaTypes: kubuns.urbanPlanningAreaTypes,
      areaOfUseTypes: kubuns.areaOfUseTypes,
      deliveryDateMonths: kubuns.deliveryDateMonths,
      interestTypes: kubuns.interestTypes,
      buildingUses: kubuns.realEstate.buildingUses,
    };
  },
  computed: {
    isAutoCalc: function () {
      return this.value.data.purchase.isAutoCalc;
    },
    loanTable: function () {
      // console.log("loan : " + JSON.stringify(this.value.data.purchase.loan));
      return this.$$realEstate$calcRepaymentAmonts(
        this.value.data.purchase.loan
      );
    },
    amountInterestRepaidPerYear: {
      get: function () {
        if (this.isAutoCalc) {
          return this.$$realEstate$calcAmountInterestRepaidPerYear(
            this.loanTable
          );
        } else {
          return this.value.data.purchase.amountInterestRepaidPerYear;
        }
      },
      set: function (newVal) {
        this.value.data.purchase.amountInterestRepaidPerYear = newVal;
      },
    },
    amountPrincipalRepaidPerYear: {
      get: function () {
        if (this.isAutoCalc) {
          return this.$$realEstate$calcAmountPrincipalRepaidPerYear(
            this.loanTable
          );
        } else {
          return this.value.data.purchase.amountPrincipalRepaidPerYear;
        }
      },
      set: function (newVal) {
        this.value.data.purchase.amountPrincipalRepaidPerYear = newVal;
      },
    },
    outstandingDebt: {
      get: function () {
        if (this.isAutoCalc) {
          return this.$$realEstate$calcOutstandingDebt(this.loanTable);
        } else {
          return this.value.data.purchase.outstandingDebt;
        }
      },
      set: function (newVal) {
        this.value.data.purchase.outstandingDebt = newVal;
      },
    },
  },
  methods: {
    addTransport() {
      let item = this.value.data.transports;
      item.push(this.$$realEstate$getNewTransport(this.auth.uid));
    },
    deleteTransport(index) {
      let item = this.value.data.transports;
      item.splice(index, 1);
    },
  },
  watch: {
    "value.data.purchase.loan.paymentPeriods.year": function (val) {
      if (val) {
        if (
          this.value.data &&
          !this.value.data.purchase.loan.paymentPeriods.month
        ) {
          this.value.data.purchase.loan.paymentPeriods.month = 0;
        }
      }
    },
    "value.data.purchase.loan.interestType.interestYearMonth.year": function (
      val
    ) {
      if (val) {
        if (
          this.value.data &&
          !this.value.data.purchase.loan.interestType.interestYearMonth.month
        ) {
          this.value.data.purchase.loan.interestType.interestYearMonth.month = 0;
        }
      }
    },
    "value.data.purchase.loanOrCash": function () {
      if (this.value.data.purchase.loanOrCash === 2) {
        this.value.data.purchase.loan.amount = null;
        this.value.data.purchase.loan.bankId = null;
        this.value.data.purchase.loan.branchId = null;
        this.value.data.purchase.loan.executeDate = {
          month: null,
          year: null,
        };
        this.value.data.purchase.loan.paymentPeriods = {
          month: null,
          year: null,
        };
        this.value.data.purchase.loan.interestType.type = null;
        this.value.data.purchase.loan.interestType.interestYearMonth = {
          month: null,
          year: null,
        };
        this.value.data.purchase.loan.interestRate = null;
        this.value.data.purchase.loan.method = null;
        this.amountInterestRepaidPerYear = null;
        this.amountPrincipalRepaidPerYear = null;
        this.outstandingDebt = null;
      } else {
        this.value.data.purchase.loan.amount;
        this.value.data.purchase.loan.bankId;
        this.value.data.purchase.loan.branchId;
        this.value.data.purchase.loan.executeDate;
        this.value.data.purchase.loan.paymentPeriods;
        this.value.data.purchase.loan.interestType.type;
        this.value.data.purchase.loan.interestType.interestYearMonth;
        this.value.data.purchase.loan.interestRate;
        this.value.data.purchase.loan.method;
        this.amountInterestRepaidPerYear;
        this.amountPrincipalRepaidPerYear;
        this.outstandingDebt;
      }
    },
    "value.data.realEstateType": function () {
      if (
        this.value.data.realEstateType === 10 ||
        this.value.data.realEstateType === 7
      ) {
        this.value.data.numberOfFloors = null;
        this.value.data.buildingType = null;
        this.value.data.constructionDate = {
          month: null,
          year: null,
        };
        this.value.data.buildingArea = null;
        this.value.data.saleSetting.layouts = {
          NumberOfUnits: null,
          layout: null,
        };
        this.value.data.saleSetting.numberOfHouseholds = null;
        this.value.data.saleSetting.parking = null;
      }
    },
  },
  mounted() {
    if (!this.value.data.transports.length) this.addTransport();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.formRealEstateUnit {
  .edit__section--box {
    margin-top: 50px;
    @include sp {
      margin-top: 20px;
    }
  }
  .opt-margin-top {
    margin-top: 72px;
  }
  .z-switch {
    margin-bottom: 12px;
  }
}
</style>
