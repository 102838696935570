<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <section class="edit edit__cong">
      <section class="edit__section">
        <div class="content has-text-centered">
          <h4>
            不動産売買のご成約<br class="br-show" />誠におめでとうございます
          </h4>
          <br />

          <p>
            今回、売買契約をご担当した不動産会社から<br
              class="br-show"
            />成約した不動産情報の<br
              class="br-show"
            />ご確認依頼がございます。<br />
            以下のステップに沿って、登録内容のご確認及び不動産会社の担当者の<br
              class="br-show"
            />評価を入力してください。
          </p>
          <br />

          <p>
            また、本申告手続き完了後に、<br
              class="br-show"
            />お祝い金といたしまして<br
              class="br-show"
            />弊社からキャッシュバックがございます。<br />合わせてキャッシュバック金をご入金する<br
              class="br-show"
            />振込先口座のご登録をお願いいたします。
          </p>

          <p>
            【キャッシュバック対象】<br />プレミアムプラン会員の方（※）：<br
              class="br-show"
            />仲介手数料10％<br />無料会員の方：一律3万円
          </p>
          <p class="notice">
            （※）不動産売買契約日及び不動産決済日の両方の時点で<br
              class="br-show"
            />プレミアム会員に加入している方
          </p>
          <br class="br-sp" />
        </div>
        <TransactionSteps :activeStep="4" />

        <div class="edit__foot">
          <b-button
            @click="next"
            type="is-primary"
            rounded
            class="button z-button is-wide button__mobile"
            >次へ</b-button
          >
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import TransactionSteps from "../parts/TransactionSteps";

export default {
  name: "transaction-review-congratulations",
  data: function () {
    return {
      isLoading: true,
    };
  },
  components: {
    TransactionSteps,
  },
  methods: {
    async init() {
      // try {
      //   return;
      // } catch (error) {
      //   this.$$error$catchLoadError(error);
      // } finally {
      //   this.isLoading = false;
      // }
    },
    async next() {
      this.$router.push({
        name: "transaction-review-confirmation",
        params: { id: this.$route.params.id },
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit__cong {
  max-width: 800px;
}
.br-show {
  display: none;
  @include sp {
    display: block;
  }
}

.message-wrap {
  @include sp {
    padding: 46px 20px;
  }
}

.notice {
  font-size: 12px;
}
</style>
