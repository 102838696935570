<template>
  <div class="edit edit-profile">
    <h2 class="edit__title">基本情報</h2>
    <section class="edit__section">
      <div>
        <section class="edit__section">
          <z-input
            label="氏名（漢字）"
            v-model="user.data.base.name"
            type="text"
            placeholder="民鳥 太郎"
            maxlength="32"
            isRequired
            :validate="'required'"
          >
          </z-input>
          <z-input
            label="氏名（カナ）"
            v-model="user.data.base.kana"
            type="text"
            placeholder="ミントリ タロウ"
            maxlength="32"
            isRequired
            :validate="'required|isKata'"
          >
          </z-input>
          <z-input
            label="所属・部署"
            v-model="user.data.company.department"
            type="text"
            placeholder="所属・部署を入力してください"
            maxlength="30"
            isRequired
            :validate="'required'"
          >
          </z-input>
          <z-input
            label="職位・役職"
            v-model="user.data.company.title"
            type="text"
            placeholder="マネージャー"
            maxlength="30"
          >
          </z-input>
          <z-input
            label="電話番号"
            v-model="user.data.base.phoneNumber"
            type="tel"
            placeholder="例)0123456789"
            maxlength="11"
            isRequired
            :validate="'required|min:10|max:11|numeric'"
          >
          </z-input>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "realtors-user-basic-edit-list",
  inject: ["$validator"],
  props: {
    user: {
      type: [Object],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  margin: 0 auto 70px;
  max-width: $edit-maxwidth;
  &__section {
    padding: 0 $edit-padding;
    @include sp() {
      padding: 0 $edit-padding-sp;
    }
  }
  &__title {
    position: relative;
    margin-top: $edit-padding;
    margin-bottom: $edit-padding;
    padding: 5px $edit-padding;
    font-size: 24px;
    background-color: #f4f3f5;
    border-bottom: solid 2px #707070;
    color: #838383;
    @include sp {
      padding: 5px $edit-padding-sp;
    }
  }
  &__subtitle {
    padding-top: 0;
    padding-bottom: 14px;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
