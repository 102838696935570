<template>
  <div>
    <section>
      <ZHeader title="投資家検索" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777777" />
        </template>
      </ZHeader>
    </section>
    <section class="z-section">
      <div class="z-section__inner z-section__inner--investerSrc">
        <template v-if="!isLoading">
          <InvestorSearchConditions
            ref="investorSearchConditions"
            @toggleAccordion="floatingBarScrollHandle"
          />

          <div class="columns">
            <div class="column is-12">
              <template>
                <h4 class="list-title">投資家一覧</h4>
                <div class="control">
                  <div class="control--count">
                    該当件数
                    <span class="control--count__highlight">{{
                      $$num$commaFormat(this.total.value)
                    }}</span
                    ><span class="control--count__number">件</span>
                  </div>
                  <div class="control--favorite">
                    <p class="control--favorite__message">
                      あなたをお気に入りしてる投資家
                    </p>
                    <p class="control--favorite__cnt">{{ countOfFavorite }}</p>
                    <p class="control--favorite__nin">人</p>
                  </div>
                  <div class="control--buttons">
                    <b-button
                      type="is-primary"
                      outlined
                      @click="onOpenModalCreateTemplateMessage"
                    >
                      メッセージテンプレートを作成する
                    </b-button>
                    <!-- <b-button type="is-primary" outlined @click="">
                      表示項目を<template v-if="bulma.isMobile"><br /></template
                      >変更する
                    </b-button> -->
                  </div>
                </div>
                <div class="btableWrapper">
                  <div
                    class="tableScrollArea tableScrollArea--left"
                    :class="{ 'is-show': isTableScrollableToLeft }"
                  ></div>
                  <div
                    class="tableScrollArea tableScrollArea--right"
                    :class="{ 'is-show': isTableScrollableToRight }"
                  ></div>
                  <b-table
                    ref="btable"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    backend-pagination
                    backend-sorting
                    checkable
                    default-sort-direction="desc"
                    default-sort="user.data.registerDt"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    scrollable
                    paginated
                    :checked-rows.sync="checkedRows"
                    :current-page.sync="currentPage"
                    :data="investors"
                    :mobile-cards="false"
                    :per-page="perPage"
                    :row-class="
                      (row, index) =>
                        row._source.user.data.latestScore.score <= 350 &&
                        'is-score-below-350'
                    "
                    :total="total.value"
                    @page-change="onPageChange"
                    @sort="onSort"
                  >
                    <template slot-scope="props" slot="header">
                      <div>
                        <p>
                          {{ props.column.label.split("\n")[0] }}
                        </p>
                        <template
                          v-if="
                            props.column.label.split('\n')[1] ===
                            'hasSortFavorite'
                          "
                        >
                          <div class="investor--favorite__sort">
                            <a @click="onSortByFavorite">
                              <ZIcon
                                name="favorite_sort"
                                width="18"
                                height="18"
                                :color="sortByFavorite.iconColor"
                              />
                              <span class="investor--favorite__sublabel">
                                お気に入りのみ
                              </span>
                            </a>
                          </div>
                        </template>
                        <template v-else>
                          <p
                            class="sublabel"
                            v-if="props.column.label.split('\n')[1]"
                          >
                            {{ props.column.label.split("\n")[1] }}
                          </p>
                          <p v-else>&nbsp;</p>
                        </template>
                      </div>
                    </template>

                    <template slot-scope="props">
                      <b-table-column
                        v-for="(column, index) in columnsTemplate"
                        :key="index"
                        :label="column.label"
                        :field="column.field"
                        :sortable="column.sortable"
                        :visible="column.visible"
                      >
                        <!-- スコア -->
                        <div v-if="column.value === 0">
                          <div class="investor--score">
                            <ZScoreProgress
                              :score="
                                props.row._source.user.data.latestScore.score
                              "
                              :rank="
                                props.row._source.user.data.latestScore.rank
                              "
                              :transitionDuration="0"
                              scaleType="medium"
                            />
                          </div>
                          <b-button
                            class="investor--detail"
                            size="is-small"
                            rounded
                            @click="onOpenInvestorDetail(props.row)"
                            >詳細・提案</b-button
                          >
                        </div>
                        <!-- /スコア -->
                        <!-- 基本情報 -->
                        <div v-else-if="column.value === 1">
                          <InvestorBasicInfo
                            :basicInfo="props.row._source.user.data"
                            :hideProfile="hideProfile(props.row)"
                          />
                        </div>
                        <!-- /基本情報 -->
                        <!-- 会社名 -->
                        <div
                          v-if="column.value === 2"
                          class="investor--company"
                        >
                          <template
                            v-if="
                              $$isNullOrEmpty(
                                props.row._source.user.data.profile
                                  .currentOfCareer.name
                              )
                            "
                          >
                            <p>N/A</p>
                            <p>&nbsp;</p>
                          </template>
                          <template v-else>
                            <p>
                              {{
                                props.row._source.user.data.profile
                                  .currentOfCareer.name
                              }}
                            </p>
                          </template>
                        </div>
                        <!-- /会社名 -->
                        <!-- 業種/職種 -->
                        <div v-if="column.value === 3" class="investor--job">
                          <template
                            v-if="
                              $$isNullOrEmpty(
                                props.row._source.user.data.profile
                                  .currentOfCareer.categoryOfBusiness.value
                              ) &&
                              $$isNullOrEmpty(
                                props.row._source.user.data.profile
                                  .currentOfCareer.typeOfOccupation.value
                              )
                            "
                          >
                            <p>N/A</p>
                            <p>&nbsp;</p>
                          </template>
                          <template v-else>
                            <p>
                              <span>
                                {{
                                  $$isNullOrEmpty(
                                    props.row._source.user.data.profile
                                      .currentOfCareer.categoryOfBusiness.value
                                  )
                                    ? "N/A"
                                    : props.row._source.user.data.profile
                                        .currentOfCareer.categoryOfBusiness.text
                                }}
                              </span>
                              <span>/</span>
                              <span>
                                {{
                                  $$isNullOrEmpty(
                                    props.row._source.user.data.profile
                                      .currentOfCareer.typeOfOccupation.value
                                  )
                                    ? "N/A"
                                    : props.row._source.user.data.profile
                                        .currentOfCareer.typeOfOccupation.text
                                }}
                              </span>
                            </p>
                          </template>
                        </div>
                        <!-- /業種/職種 -->
                        <!-- 勤続年数 -->
                        <div v-if="column.value === 4" class="investor--career">
                          <p>
                            {{
                              $$isNullOrEmpty(
                                props.row._source.user.data.profile
                                  .currentOfCareer.period.from.month
                              ) ||
                              $$isNullOrEmpty(
                                props.row._source.user.data.profile
                                  .currentOfCareer.period.from.year
                              )
                                ? "N/A"
                                : $$yearOfServe(
                                    props.row._source.user.data.profile
                                      .currentOfCareer.period
                                  )
                            }}
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- /勤続年数 -->
                        <!-- 収入（不動産収入除く） -->
                        <div v-if="column.value === 5" class="investor--income">
                          <p class="investor--number__main">
                            {{
                              $$isNullOrZero(
                                props.row._source.user.data.latestPL.sum
                              )
                                ? "N/A"
                                : $$price$roundPriceJPFormat(
                                    props.row._source.user.data.latestPL.sum
                                  )
                            }}
                          </p>
                          <template
                            v-if="
                              !$$isNullOrZero(
                                props.row._source.user.data.latestPL.sum
                              )
                            "
                          >
                            <p class="investor--number__sub">
                              ({{
                                $$price$roundPriceJPFormat(
                                  props.row._source.user.data.latestPL.sum -
                                    props.row._source.user.data.latestPL
                                      .realEstate
                                )
                              }})
                            </p>
                          </template>
                          <template v-else>
                            <p>&nbsp;</p>
                          </template>
                        </div>
                        <!-- /収入（不動産収入除く） -->
                        <!-- 純資産（資産） -->
                        <div v-if="column.value === 6" class="investor--assets">
                          <p class="investor--number__main">
                            {{
                              $$isNullOrZero(
                                props.row._source.user.data.latestBS.netAssets
                                  .sum
                              )
                                ? "N/A"
                                : $$price$roundPriceJPFormat(
                                    props.row._source.user.data.latestBS
                                      .netAssets.sum
                                  )
                            }}
                          </p>
                          <template
                            v-if="
                              !$$isNullOrZero(
                                props.row._source.user.data.latestBS.netAssets
                                  .sum
                              )
                            "
                          >
                            <p class="investor--number__sub">
                              ({{
                                $$price$roundPriceJPFormat(
                                  props.row._source.user.data.latestBS.assets
                                    .sum
                                )
                              }})
                            </p>
                          </template>
                          <template v-else>
                            <p>&nbsp;</p>
                          </template>
                        </div>
                        <!-- /純資産（資産） -->
                        <!-- 保有不動産 -->
                        <div
                          v-if="column.value === 7"
                          class="investor--realestate"
                        >
                          <InvestorTooltip
                            v-if="
                              props.row._source.realEstate.countOfRealEstate > 0
                            "
                            :id="props.row._source.uid"
                            type="realEstate"
                            :title="column.label"
                            :count="
                              props.row._source.realEstate.countOfRealEstate
                            "
                            :data="props.row._source.realEstate.realEstates"
                          />
                          <p
                            v-if="
                              props.row._source.realEstate.countOfRealEstate ===
                              0
                            "
                          >
                            {{
                              props.row._source.realEstate.countOfRealEstate
                            }}件
                          </p>
                          <template
                            v-if="
                              props.row._source.realEstate.hasHomeOfRealEstate
                            "
                          >
                            <p>自宅有</p>
                          </template>
                          <template v-else>
                            <p>&nbsp;</p>
                          </template>
                        </div>
                        <!-- /保有不動産 -->
                        <!-- 借入金融機関 -->
                        <div v-if="column.value === 8" class="investor--bank">
                          <p>
                            {{
                              $$loanBanks(
                                props.row._source.realEstate.realEstates
                              )
                            }}
                          </p>
                        </div>
                        <!-- /借入金融機関 -->
                        <!-- 買いニーズ -->
                        <div
                          v-if="column.value === 9"
                          class="investor--buyingneeds"
                        >
                          <InvestorTooltip
                            v-if="
                              props.row._source.user.data.countOfBuyingNeeds > 0
                            "
                            :id="props.row._source.uid"
                            type="buyingNeeds"
                            :title="column.label"
                            :count="
                              props.row._source.user.data.countOfBuyingNeeds
                            "
                            :data="props.row._source.user.data.buyingNeeds"
                          />
                          <p
                            v-if="
                              props.row._source.user.data.countOfBuyingNeeds ===
                              0
                            "
                          >
                            {{
                              props.row._source.user.data.countOfBuyingNeeds
                            }}件
                          </p>
                          <template
                            v-if="
                              $$hasComment(
                                props.row._source.user.data.buyingNeeds
                              )
                            "
                          >
                            <p class="investor--text--small">※コメントあり</p>
                          </template>
                          <template v-else>
                            <p>&nbsp;</p>
                          </template>
                        </div>
                        <!-- /買いニーズ -->
                        <!-- 会員登録日 -->
                        <div
                          v-if="column.value === 10"
                          class="investor--register"
                        >
                          <p>
                            {{
                              props.row._source.user.data.registerDt
                                ? $$calender$dateFormat(
                                    new Date(
                                      props.row._source.user.data.registerDt
                                    )
                                  )
                                : ""
                            }}
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- /会員登録日 -->
                        <!-- 最終ログイン日 -->
                        <div
                          v-if="column.value === 22"
                          class="investor--latestActionDt"
                        >
                          <p>
                            {{
                              props.row._source.latestActionDt
                                ? $$calender$dateFormat(
                                    new Date(props.row._source.latestActionDt)
                                  )
                                : ""
                            }}
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- /最終ログイン日 -->
                        <!-- 閲覧（/総提案数） -->
                        <div
                          v-if="column.value === 11"
                          class="investor--asread"
                        >
                          <p>
                            {{
                              props.row._source.message.countOfMessageAsRead
                            }}件/{{ props.row._source.message.countOfOffer }}件
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- /閲覧（/総提案数） -->
                        <!-- 提案数（/総提案数） -->
                        <div
                          v-if="column.value === 21"
                          class="investor--asread"
                        >
                          <p>
                            {{ countOfMyMessages(props.row) }}件/{{
                              props.row._source.message.countOfOffer
                            }}件
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- 提案数（/総提案数） -->
                        <!-- 送信日 -->
                        <div
                          v-if="column.value === 12"
                          class="investor--message"
                        >
                          {{
                            $$latestSendMessageDate(
                              props.row.fields.realtorInfo
                            )
                          }}
                          <!--
                            v-if="props.row._source.message.messages.length > 0"
                            // :count="props.row.fields.countOfMessages[0]"
                          -->
                          <InvestorTooltip
                            v-if="countOfMessages(props.row) > 0"
                            :id="props.row._source.uid"
                            type="messages"
                            title="最近のメッセージ"
                            :mycount="countOfMyMessages(props.row)"
                            :count="countOfMessages(props.row)"
                            :basicInfo="props.row._source.user.data"
                          />
                        </div>
                        <!-- /送信日 -->
                        <!-- 返信日 -->
                        <div
                          v-if="column.value === 13"
                          class="investor--message"
                        >
                          <p>
                            {{
                              $$latestRecieveMessageDate(
                                props.row.fields.realtorInfo
                              )
                            }}
                          </p>
                          <p>&nbsp;</p>
                        </div>
                        <!-- /返信日 -->
                        <!-- 継続提案希望 -->
                        <div v-if="column.value === 14">
                          <div class="investor--favorite-realotr">
                            <p>
                              <img
                                v-if="
                                  favoriteRealtorType(
                                    props.row._source.favoriteRealtor
                                  ) === 1
                                "
                                src="@/assets/favorite/star.png"
                              />
                              {{
                                favoriteRealtorTypeStr(
                                  props.row._source.favoriteRealtor
                                )
                              }}
                            </p>
                          </div>
                        </div>
                        <!-- 継続提案希望 -->
                        <!-- お気に入り -->
                        <div v-if="column.value === 15">
                          <InvestorFavorite
                            ref="investorFavorite"
                            :uid="props.row._source.uid"
                          />
                          <p>&nbsp;</p>
                        </div>
                        <!-- /お気に入り -->
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="blankBox">
                        <z-blank-box>投資家は見つかりませんでした</z-blank-box>
                      </div>
                    </template>
                  </b-table>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>

      <template v-if="!isLoading">
        <section
          class="floatingBar"
          v-bind:class="{
            show: isFloatingBarVisible,
            hide: !isFloatingBarVisible,
          }"
        >
          <template v-if="bulkmessages.isValidated">
            <div class="floatingBar--header">提案メッセージを一括送信する</div>
          </template>
          <template v-else>
            <div class="floatingBar--error">{{ bulkmessages.errorText }}</div>
          </template>
          <div class="floatingBar--content">
            <div class="floatingBar--item">
              <div class="floatingBar--select">
                <p>
                  <span>選択中 : </span>
                  <span class="floatingBar--select__current">
                    {{ checkedRows.length }}
                  </span>
                  <span>/{{ bulkmessages.maxMessages }}</span>
                  <b-button
                    class="floatingBar--clear"
                    size="is-small"
                    rounded
                    @click="onClear"
                    >全クリア</b-button
                  >
                </p>
                <p>
                  <span>残りのエクスクルーシブ数：</span>
                  <span class="floatingBar--select__remain">
                    {{ loginUser.exclusiveMessageCount }}
                  </span>
                </p>
              </div>
            </div>
            <div class="floatingBar--item">
              <div class="floatingBar--button">
                <b-button
                  class="z-button floatingBar--primary"
                  type="is-primary"
                  size="is-medium"
                  rounded
                  outlined
                  @click="routerBulkMessages($$ks.message.types[0].value)"
                >
                  エクスクルーシブ<template v-if="bulma.isMobile"
                    ><br /></template
                  >提案<br />
                  <span class="floatingBar--primary__sub"
                    >（全会員が閲覧可能）</span
                  >
                </b-button>
                <b-button
                  class="z-button floatingBar--primary"
                  type="is-primary"
                  size="is-medium"
                  rounded
                  @click="routerBulkMessages($$ks.message.types[1].value)"
                >
                  通常提案<br />
                  <span class="floatingBar--primary__sub"
                    >（プレミアム会員のみ閲覧可能）</span
                  >
                </b-button>
                <template v-if="bulma.isMobile">
                  <b-tooltip
                    class="floatingBar--help--tooltip"
                    :label="labelTooltipProposal"
                    multilined
                    position="is-top"
                    type="is-dark"
                  >
                    <img
                      class="floatingBar--help--icon"
                      src="@/assets/investor/help.png"
                    />
                  </b-tooltip>
                </template>
              </div>
            </div>
            <div class="floatingBar--item">
              <template v-if="!bulma.isMobile">
                <b-tooltip
                  class="floatingBar--help--tooltip"
                  :label="labelTooltipProposal"
                  multilined
                  position="is-top"
                  type="is-dark"
                >
                  <img
                    class="floatingBar--help--icon"
                    src="@/assets/investor/help.png"
                  />
                </b-tooltip>
              </template>
              <p class="floatingBar--help--text">
                ※一括提案をするには絞り込み検索条件の設定が必要です。
              </p>
            </div>
          </div>
        </section>
      </template>
    </section>

    <!-- 投資家詳細オーバーレイ -->
    <template v-if="!isLoading">
      <tile-box-overlay
        title="投資家詳細・提案メッセージ送信"
        title1="投資家詳細・"
        title2="提案メッセージ送信"
        ref="overlayInvestorDetail"
        :isTitleNewline="bulma.isMobile"
      >
        <template v-slot:OverlayReceive>
          <InvestorDetail
            :investorInfo="selectedRow"
            :hideProfile="hideProfile(selectedRow)"
          />
        </template>
      </tile-box-overlay>
    </template>
    <!-- /投資家詳細オーバーレイ -->

    <!-- 表示内容設定モーダル -->
    <!-- <template v-if="!isLoading">
      <ZModal
        title="表示内容設定"
        :isActive="isOpenModalSetting"
        @close="isOpenModalSetting = false"
      >
        <template v-slot:content>
          <div class="selectModalBody">
            <div class="setting">
              <p class="setting--title">最大件数を選択してください</p>
              <ZRadio
                label=""
                :options="typeOfRowDisplay"
                v-model="settingRowDisplay"
                hideRequired
              />
              <p class="setting--title">
                表示する項目にチェックをいれてください（複数選択できます）
              </p>
              <div class="z-checkbox">
                <div class="block">
                  <b-checkbox type="is-info" v-model="settingDefault">
                    デフォルトに戻す
                  </b-checkbox>
                </div>
              </div>
              <ZCheckbox
                label="資産情報"
                :options="typeOfColumnDisplay.assetInfo"
                v-model="settingColumnDisplay"
                hideRequired
              />
              <ZCheckbox
                label="勤務情報"
                :options="typeOfColumnDisplay.workInfo"
                v-model="settingColumnDisplay"
                hideRequired
              />
              <ZCheckbox
                label="利用情報"
                :options="typeOfColumnDisplay.usageInfo"
                v-model="settingColumnDisplay"
                hideRequired
              />
              <div class="setting--footer">
                <b-button type="is-primary" rounded @click="onSaveSetting">
                  保存する
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </ZModal>
    </template> -->
    <!-- /表示内容設定モーダル -->

    <!-- メッセージテンプレート作成オーバーレイ -->
    <ModalCreateTemplateMessage
      ref="modalCreateTemplateMessage"
      :data="customTemplates"
      :mode="$$createTemplateMessage$modes().investors"
    />
    <!-- /メッセージテンプレート作成オーバーレイ -->
  </div>
</template>

<script>
import _ from "lodash";
import InvestorSearchConditions from "./parts/InvestorSearchConditions";
import InvestorBasicInfo from "./parts/InvestorBasicInfo";
import InvestorFavorite from "./parts/InvestorFavorite";
import InvestorDetail from "./parts/InvestorDetail";
import InvestorTooltip from "./parts/InvestorTooltip";
import ModalCreateTemplateMessage from "@/views/realtor/common/ModalCreateTemplateMessage";
import * as InvestorModule from "@/modules/investor/investor";
import RealtorType from "@/types/realtorType";
import DisplayTextMixin from "./parts/DisplayTextMixin";
import ESQueryMixin from "./parts/ESQueryMixin";
import CreateTemplateMessageMixin from "@/views/realtor/common/CreateTemplateMessageMixin";
import ScrollBooster from "scrollbooster";

export default {
  name: "realtor-setting-investors",
  mixins: [DisplayTextMixin, ESQueryMixin, CreateTemplateMessageMixin],
  components: {
    InvestorSearchConditions,
    InvestorBasicInfo,
    InvestorFavorite,
    InvestorDetail,
    InvestorTooltip,
    ModalCreateTemplateMessage,
  },
  data: function () {
    return {
      localStorageFilter: "filterInvestor",
      browserBack: "browserBack",
      countOfFavorite: 0,
      columnsTemplate: [
        {
          value: 0,
          label: "スコア",
          field: "user.data.latestScore.score",
          visible: true,
          sortable: true,
        },
        {
          value: 1,
          label: "基本情報",
          field: "",
          visible: true,
          sortable: false,
        },
        {
          value: 2,
          label: "会社名",
          field: "user.data.profile.currentOfCareer.name",
          visible: true,
          sortable: false,
        },
        {
          value: 3,
          label: "業種/職種",
          field: "",
          visible: true,
          sortable: false,
        },
        {
          value: 4,
          label: "勤続年数",
          field: "",
          visible: true,
          sortable: false,
        },
        {
          value: 5,
          label: "収入\n(不動産収入除く)",
          field: "user.data.latestPL.sum",
          visible: true,
          sortable: true,
        },
        {
          value: 6,
          label: "純資産\n(資産)",
          field: "user.data.latestBS.netAssets.sum",
          visible: true,
          sortable: true,
        },
        {
          value: 7,
          label: "保有不動産",
          field: "realEstate.countOfRealEstate",
          visible: true,
          sortable: true,
        },
        {
          value: 8,
          label: "借入金融機関",
          field: "realEstate.countOfBank",
          visible: true,
          sortable: true,
        },
        {
          value: 9,
          label: "買いニーズ",
          field: "user.data.countOfBuyingNeeds",
          visible: true,
          sortable: true,
        },
        {
          value: 10,
          label: "会員登録日",
          field: "user.data.registerDt",
          visible: true,
          sortable: true,
        },
        {
          value: 22,
          label: "最終ログイン日",
          field: "latestActionDt",
          visible: true,
          sortable: true,
        },
        {
          value: 11,
          label: "閲覧\n(/総提案数)",
          field: "message.countOfMessageAsRead",
          visible: true,
          sortable: true,
        },
        {
          value: 21,
          label: "提案回数\n(/総提案数)",
          field: "message.countOfOffer",
          visible: true,
          sortable: true,
        },
        {
          value: 12,
          label: "送信日",
          field: "latestSendMessageDate",
          visible: true,
          sortable: true,
        },
        {
          value: 13,
          label: "返信日",
          field: "latestRecieveMessageDate",
          visible: true,
          sortable: true,
        },
        {
          value: 14,
          label: "継続提案希望",
          field: "favoriteRealtor",
          visible: true,
          sortable: true,
        },
        {
          value: 15,
          label: "お気に入り\nhasSortFavorite",
          field: "",
          visible: true,
          sortable: false,
        },
      ],
      typeOfRowDisplay: [
        { value: 0, text: "30件", perPage: 30 },
        { value: 1, text: "50件", perPage: 50 },
        { value: 2, text: "100件", perPage: 100 },
      ],
      typeOfColumnDisplay: {
        assetInfo: [
          { value: 3, text: "収入" },
          { value: 4, text: "純資産" },
          { value: 5, text: "借入金融機関" },
          { value: 6, text: "保有不動産" },
        ],
        workInfo: [
          { value: 7, text: "業種" },
          { value: 8, text: "職種" },
          { value: 9, text: "会社" },
          { value: 10, text: "勤続年数" },
        ],
        usageInfo: [
          { value: 11, text: "会員登録日" },
          { value: 12, text: "閲覧数/総提案数" },
          { value: 13, text: "返信日" },
          { value: 14, text: "送信日" },
        ],
      },
      query: {
        query: {},
        _source: [],
      },
      isLoading: true,
      investors: [],
      checkedRows: [],
      customTemplates: [],
      currentPage: 1,
      perPage: 100,
      total: 0,
      filter: null,
      selectedRow: null,
      sort: [{ "user.data.registerDt": "desc" }],
      sortByFavorite: {
        iconColor: null,
        isSorted: false,
        filter: null,
      },
      isOpenModalSetting: false,
      settingRowDisplay: null,
      settingColumnDisplay: [],
      settingDefault: null,
      loginUser: null,
      bulkmessages: {
        maxMessages: 100,
        // maxMessages: 50,
        isValidated: true,
        errorText: "",
      },
      isTableScrollableToRight: false,
      isTableScrollableToLeft: false,
      autoScrollReq: null,
      autoScrollSpeed: 10,
      isFloatingBarVisible: true,
    };
  },
  methods: {
    // 初期化
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;

        // 元の検索結果画面に遷移する場合
        if (
          this.$route.query.isCallback ||
          (window.history &&
            window.history.state &&
            window.history.state.name === this.browserBack)
        ) {
          let savedData =
            JSON.parse(window.localStorage.getItem(this.localStorageFilter)) ||
            null;

          if (savedData) {
            this.filter = savedData.filter;

            // 一括提案送信画面から移動した場合、直近で提案した投資家は再度検索を実行するタイミングまで非表示する
            if (this.$route.query.isCallback && savedData.uidInvestors) {
              savedData.uidInvestors.forEach((uid) => {
                this.filter.bool.must_not.push(
                  this.$$createMatchQuery("uid", uid)
                );
              });
            }
          }
        }

        // カスタムテンプレートリストを取得
        this.customTemplates =
          await this.$$createTemplateMessage$getCustomTemplates();

        await this.loadData();

        const user = await this.$$realtor$loadUser(this.auth.uid);
        this.loginUser = user.data;

        // 権限によって表示
        switch (this.$$user$permission.searchInvestors) {
          case this.$$authority().normal: // 通常
            this.columnsTemplate.find(
              (item) => item.value === 2
            ).visible = false; // 会社名
            this.columnsTemplate.find(
              (item) => item.value === 8
            ).visible = false; // 借入金融機関
            this.columnsTemplate.find(
              (item) => item.value === 22
            ).visible = false; // 最終ログイン日
            this.columnsTemplate.find(
              (item) => item.value === 11
            ).visible = false; //閲覧（/総提案数）
            break;
          case this.$$authority().company: // 業務提携会社
            this.columnsTemplate.find(
              (item) => item.value === 22
            ).visible = false; // 最終ログイン日
            this.columnsTemplate.find(
              (item) => item.value === 11
            ).visible = false; //閲覧（/総提案数）
            break;
          case this.$$authority().ziritz: // ZIRITZ
            break;
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
        this.sortByFavorite.iconColor = this.$$favoriteIconColor(false, true);
      }

      // b-table x-scroll check and init scrollBooster
      setTimeout(() => {
        const me = this;
        const tableEl = document.querySelector(".b-table .table");
        const wrapperEl = document.querySelector(".b-table .table-wrapper");
        me.checkTableScroll(tableEl, wrapperEl);
        wrapperEl.onscroll = function () {
          me.checkTableScroll(tableEl, wrapperEl);
        };
        me.initScrollBooster(tableEl, wrapperEl);
        me.floatingBarScrollHandle();
      }, 500);
    },
    countOfMessages(row) {
      return row.fields.countOfMessages[0];
    },
    countOfMyMessages(row) {
      return row.fields.countOfMyMessages[0];
    },
    // countOfMyMessages(message) {
    //   if (!message || !message.messages) {
    //     return 0;
    //   }
    //   const uid = this.auth.uid;
    //   return message.messages.filter(m => m.realtor === uid).length;
    // },
    favoriteRealtorTypeStr(fr) {
      if (!fr) return "";
      const t = this.favoriteRealtorType(fr);
      if (!t) return "";
      return this.$$ks.favorite.type(t).text;
    },
    favoriteRealtorType(fr) {
      if (!fr) return null;
      const uid = this.auth.uid;
      const r = fr.favorites.find((f) => f.realtor === uid);
      if (!r) return null;
      return r.type;
    },
    // b-table x-scroll check method
    checkTableScroll(tableEl, wrapperEl) {
      var maxScrollVal = tableEl.clientWidth - wrapperEl.clientWidth;
      var currentScrollVal = wrapperEl.scrollLeft;
      if (0 < currentScrollVal && currentScrollVal < maxScrollVal) {
        this.isTableScrollableToLeft = true;
        this.isTableScrollableToRight = true;
        return;
      }
      if (maxScrollVal > 0 && currentScrollVal == maxScrollVal) {
        this.isTableScrollableToLeft = true;
        this.isTableScrollableToRight = false;
        return;
      }
      if (maxScrollVal > 0 && currentScrollVal == 0) {
        this.isTableScrollableToLeft = false;
        this.isTableScrollableToRight = true;
        return;
      }
      this.isTableScrollableToLeft = false;
      this.isTableScrollableToRight = false;
      return;
    },
    // init ScrollBooster method
    initScrollBooster(tableEl, wrapperEl) {
      new ScrollBooster({
        viewport: wrapperEl,
        content: tableEl,
        scrollMode: "native",
        direction: "horizontal",
        textSelection: true,
        friction: 0.2,
      });
    },
    // フローティングバーの表示制御
    floatingBarScrollHandle() {
      setTimeout(() => {
        const el = document.querySelector(".btableWrapper");
        if (!el) return;

        const top = el.getBoundingClientRect().top;
        const fromBottom = window.innerHeight - top;
        if (fromBottom > 70) {
          this.isFloatingBarVisible = true;
        } else {
          this.isFloatingBarVisible = false;
        }
      }, 100);
    },
    // ページ切り替え
    async onPageChange(page) {
      this.currentPage = page;
      this.$scrollTo("#accordionSearchConditions");
      const loadingComponent = this.$loading.open();
      try {
        await this.loadData();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // ソート
    async onSort(field, order) {
      const loadingComponent = this.$loading.open();
      try {
        switch (field) {
          case "latestSendMessageDate": // 送信
            this.sort = [
              this.$$createNestedWithOrderQuery(
                "realtor.realtors.latestSendDt.realtor",
                "realtor.realtors",
                order,
                this.$$createMatchQuery(
                  "realtor.realtors.realtor",
                  this.me.user.id
                )
              ),
            ];
            break;
          case "latestRecieveMessageDate": // 返信日
            this.sort = [
              this.$$createNestedWithOrderQuery(
                "realtor.realtors.latestSendDt.investor",
                "realtor.realtors",
                order,
                this.$$createMatchQuery(
                  "realtor.realtors.realtor",
                  this.me.user.id
                )
              ),
            ];
            break;
          case "favoriteRealtor":
            this.sort = [
              this.$$createNestedWithOrderQuery(
                "favoriteRealtor.favorites.type",
                "favoriteRealtor.favorites",
                order === "desc" ? "asc" : "desc",
                this.$$createMatchQuery(
                  "favoriteRealtor.favorites.realtor",
                  this.me.user.id
                )
              ),
            ];
            break;
          default:
            this.sort = [{ [field]: order }];
        }

        this.currentPage = 1;
        await this.loadData();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // 「お気に入り」でソート
    async onSortByFavorite() {
      const loadingComponent = this.$loading.open();
      try {
        this.sortByFavorite.isSorted = !this.sortByFavorite.isSorted;
        this.sortByFavorite.iconColor = this.$$favoriteIconColor(
          this.sortByFavorite.isSorted,
          true
        );

        this.sortByFavorite.filter = this.sortByFavorite.isSorted
          ? this.$$createMatchQuery(
              "favorite.favorites.keyword",
              this.me.user.id
            )
          : null;

        this.currentPage = 1;
        await this.loadData();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // データ検索
    async loadData() {
      console.log(`start loading:${new Date()}`);
      const uid = this.auth.uid;
      let query = _.cloneDeep(this.filter);

      if (this.sortByFavorite.filter) {
        if (this.filter) {
          query.bool.must.push(this.sortByFavorite.filter);
        } else {
          query = {
            bool: {
              must: [this.sortByFavorite.filter],
            },
          };
        }
      }

      // デフォルトフィルータ
      if (query) {
        query.bool.filter = this.createFilterDefault();
      } else {
        query = {
          bool: {
            filter: this.createFilterDefault(),
          },
        };
      }

      // 拒否されたInvestorを外す
      if (!query.bool.must_not) query.bool.must_not = [];
      if (!query.bool.must) query.bool.must = [];
      query.bool.must_not.push({
        nested: {
          path: "favoriteRealtor.favorites",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "favoriteRealtor.favorites.realtor": uid,
                  },
                },
                {
                  term: {
                    "favoriteRealtor.favorites.type": 999,
                  },
                },
              ],
            },
          },
        },
      });

      // console.log(JSON.stringify(query));
      this.query.query = _.cloneDeep(query);
      this.query.from = (this.currentPage - 1) * this.perPage;
      this.query.size = this.perPage;
      this.query.sort = this.sort;

      // query.bool.must.push({
      //   nested: {
      //     path: "realtor.realtors",
      //     query: {
      //       bool: {
      //         must: [
      //           {
      //             match: {
      //               "realtor.realtors.realtor": uid
      //             }
      //           },
      //         ]
      //       }
      //     }
      //   }
      // });

      // _source を限定
      this.query._source = [
        "realEstate",
        // "realEstate.countOfRealEstate",
        // "realEstate.countOfBank",
        // "realEstate.countOfRealEstate",
        // "realEstate.hasHomeOfRealEstate",
        // "realEstate.realEstates",
        // "favorite",
        "favoriteRealtor",
        "realtor",
        // "message",
        "message.countOfMessageAsRead",
        "message.countOfOffer",
        // "message.messages",
        // "user",
        "user.data.base",
        "user.data.profile",
        "user.data.membership",
        "user.data.latestScore",
        "user.data.latestBS",
        "user.data.latestPL",
        "user.data.profile.currentOfCareer",
        "user.data.buyingNeeds",
        "user.data.countOfBuyingNeeds",
        "user.data.registerDt",
        "cratedDtToES",
        "latestActionDt",
        "uid",
      ];

      this.query.script_fields = {
        countOfMessages: {
          script: {
            lang: "painless",
            source: "params._source.message.messages.size()",
          },
        },
        countOfMyMessages: {
          script: {
            lang: "painless",
            source: `
              List rs = new ArrayList();
              for (m in params._source.message.messages) {
                if (m.realtor == params.realtorId)
                  rs.add(m);
              }
              return rs.size();
            `,
            params: {
              realtorId: uid,
            },
          },
        },
        realtorInfo: {
          script: {
            lang: "painless",
            source: `
            List rs = new ArrayList();
            for (r in params._source.realtor.realtors) {
              if (r.realtor == params.realtorId) {
                rs.add(r)
              }
            }
            return rs;
            `,
            params: {
              realtorId: uid,
            },
          },
        },
      };

      const result = await InvestorModule.search(this.query);
      this.total = result.total;
      this.investors = result.hits;

      // お気に入りのカウントをとります
      const qu = _.cloneDeep(query);
      delete qu.script_fields; //不要なので削除
      delete qu._source; //不要なので削除
      delete qu.from;
      delete qu.size;
      delete qu.sort;

      qu.bool.must.push({
        nested: {
          path: "favoriteRealtor.favorites",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "favoriteRealtor.favorites.realtor": uid,
                  },
                },
                {
                  term: {
                    "favoriteRealtor.favorites.type": 1,
                  },
                },
              ],
            },
          },
        },
      });
      const ct = await InvestorModule.count({
        query: qu,
      });
      // console.log(ct);
      this.countOfFavorite = ct;
      console.log(`end loading:${new Date()}`);
    },
    // デフォルトフィルータを作成
    createFilterDefault: function () {
      return [this.$$createMatchQuery("isActivate", true)];
    },
    // 投資家詳細・提案モーダルを開く
    onOpenInvestorDetail(row) {
      this.selectedRow = row;
      this.$refs.overlayInvestorDetail.open();
    },
    // 表示内容設定モーダルを開く
    onOpenModalSetting() {
      const selectingTypeOfRowDisplay = this.typeOfRowDisplay.find(
        (item) => item.perPage === this.perPage
      );

      // 最大件数を設定
      if (selectingTypeOfRowDisplay) {
        this.settingRowDisplay = selectingTypeOfRowDisplay.value;
      }

      // 表示する項目を設定
      this.settingColumnDisplay = [];
      this.columnsTemplate.forEach((item) => {
        if (item.visible) {
          this.settingColumnDisplay.push(item.value);
        }
      });

      this.settingDefault = false;
      this.isOpenModalSetting = true;
    },
    // 表示内容設定を保存
    // onSaveSetting: function() {
    //   const selectingTypeOfRowDisplay = this.typeOfRowDisplay.find(
    //     item => item.value === this.settingRowDisplay
    //   );

    //   // 最大件数を設定
    //   if (selectingTypeOfRowDisplay) {
    //     this.perPage = selectingTypeOfRowDisplay.perPage;
    //   };

    //   // 表示する項目を設定
    //   this.columnsTemplate.forEach(item => {
    //     item.visible = this.settingColumnDisplay.includes(item.value);
    //   });

    //   // 表示内容設定モーダルを閉じる
    //   this.isOpenModalSetting = false;
    // },
    // メッセージテンプレート作成オーバーレイを開く
    onOpenModalCreateTemplateMessage() {
      this.$refs.modalCreateTemplateMessage.open();
    },
    // 選択中の投資家をクリア
    onClear: function () {
      this.checkedRows = [];
      this.bulkmessages.isValidated = true;
    },
    // プロフィールの項目を非公開する
    hideProfile: function (row) {
      const hideProfile = {
        name: true,
        email: true,
        phoneNumber: true,
      };

      // ZIRITZ権限 : デフォルトで表示
      if (
        this.$$user$permission.searchInvestors === this.$$authority().ziritz
      ) {
        hideProfile.name = false;
        hideProfile.email = false;
        hideProfile.phoneNumber = false;
      }

      // 専門家、提携会社権限 : 投資家から返信が来た場合にのみ表示。それ以外は「非公開」
      if (
        this.$$user$permission.searchInvestors === this.$$authority().company ||
        this.$$user$permission.searchInvestors === this.$$authority().normal
      ) {
        if (row && row.fields && row.fields.realtorInfo) {
          hideProfile.name =
            row.fields.realtorInfo.filter(
              (item) =>
                item.realtor === this.me.user.id &&
                item.latestSendDt.investor !== null
            ).length === 0;
        } else {
          hideProfile.name = true;
        }
        // hideProfile.name = data
        //   ? data.realtor.realtors.filter(
        //       item =>
        //         item.realtor === this.me.user.id &&
        //         item.latestSendDt.investor !== null
        //     ).length === 0
        //   : true;
      }

      return hideProfile;
    },
    // 一括送信画面に移動する
    routerBulkMessages(type) {
      const refSearchCondition = this.$refs.investorSearchConditions;

      this.bulkmessages.isValidated = true;
      this.bulkmessages.errorText = "";

      // 絞り込みがなされていない場合、絞り込みのアコーディオンを自動で展開し、絞り込み検索部分にページ内アンカーする
      if (
        refSearchCondition.checkValidateRequire() &&
        refSearchCondition.isSearched
      ) {
        refSearchCondition.saveFiltersToLocalStorage();
      } else {
        refSearchCondition.isShowErrorText = true;
        refSearchCondition.$refs.accordionSearchConditions.isOpen = true;
        this.$scrollTo("#accordionSearchConditions");
        return;
      }

      // 選択している投資家が0件だった場合
      if (this.checkedRows.length === 0) {
        this.bulkmessages.isValidated = false;
        this.bulkmessages.errorText = "1人以上の投資家を選択してください。";
      }

      // 選択している投資家数がMAX件以上の場合
      if (this.checkedRows.length > this.bulkmessages.maxMessages) {
        this.bulkmessages.isValidated = false;
        this.bulkmessages.errorText = "送信可能な件数を超えています。";
      }

      // 「エクスクルーシブ提案」と残りのエクスクルーシブ数以上の場合
      if (
        type === this.$$ks.message.types[0].value &&
        this.checkedRows.length > this.loginUser.exclusiveMessageCount
      ) {
        this.bulkmessages.isValidated = false;
        this.bulkmessages.errorText = `送信可能な件数を超えています。（エクスクルーシブ残数：${this.loginUser.exclusiveMessageCount}）`;
      }

      // 「不動産から絞り込み」を選択して投資家を絞り込んでいた場合は、絞り込みに使った不動産を渡す
      let query = {
        uid: this.checkedRows.map((item) => item._source.uid),
        type: type,
      };

      if (
        refSearchCondition.activeTab === 0 &&
        refSearchCondition.filterByRealEstate.selectedRealEstate
      ) {
        query.defaultRealEstateId =
          refSearchCondition.filterByRealEstate.selectedRealEstate._source.id;
      }

      //「メッセージ一括送信」に遷移
      if (this.bulkmessages.isValidated) {
        refSearchCondition.isShowErrorText = false;
        window.history.pushState(
          { name: this.browserBack },
          null,
          "/realtor/setting/investors"
        );

        const routeData = this.$router.resolve({
          name: "realtor-bulkmessages-new",
          query: query,
        });
        window.open(routeData.href, "_self");
      }
    },
  },
  computed: {
    // ツールチップの文言を表示
    labelTooltipProposal() {
      return `「エクスクルーシブ提案」は月${RealtorType.type.user.exclusiveMessageCount}件のみお送りできる特別なメッセージです。プレミアム会員および無料会員全ての投資家がメッセージを閲覧できます。「通常提案」はプレミアム会員のみメッセージ閲覧可能です。（無料会員は閲覧できません）`;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
    window.addEventListener("scroll", this.floatingBarScrollHandle);
  },
  destoroyed() {
    window.removeEventListener("scroll", this.floatingBarScrollHandle);
  },
  watch: {
    // // 表示内容設定をデフォルトに戻す
    // settingDefault: function() {
    //   if (!this.settingDefault) return;
    //   const defaultColumnDisplay = this.columnsTemplate;
    //   this.settingColumnDisplay = defaultColumnDisplay.map(item => item.value);
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

/deep/ .table-wrapper {
  overflow-x: auto;
}

.control {
  margin: 20px 0;
  overflow: hidden;

  @include sp {
    margin: 16px 0;
  }

  &--favorite {
    float: left;
    margin-left: 24px;
    background-color: #f1f6fc;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 23px;
    @include sp {
      margin-left: 0;
      width: 100%;
    }
    &__message {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      letter-spacing: 0;
      line-height: 14px;
    }
    &__cnt {
      margin-left: 14px;
      margin-top: -4px;
      font-size: 26px;
      font-weight: bold;
      color: #0062d7;
      letter-spacing: 0;
      line-height: 26px;
    }
    &__nin {
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #0062d7;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  &--count {
    height: 40px;
    float: left;
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 4px;

    &__highlight {
      color: $red;
      font-size: 26px;
      margin-left: 4px;
      margin-right: 2px;
    }

    &__number {
      color: $red;
      font-size: 14px;
    }
  }

  &--buttons {
    float: right;

    @include sp {
      width: 100%;
    }

    .button {
      @include sp {
        font-size: 14px;
        margin-top: 8px;
        width: 100%;
        // height: 60px;
      }
    }
  }
}

.btableWrapper {
  position: relative;
  .tableScrollArea {
    content: "";
    display: none;
    height: calc(100% - 85px);
    width: 28px;
    position: absolute;
    top: 1px;
    &--left {
      left: 0;
      border-radius: 8px 0 0 8px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    }
    &--right {
      right: 0;
      border-radius: 0 8px 8px 0;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    }
    &.is-show {
      display: block;
      @include sp {
        display: none;
      }
    }
  }
}

/deep/ .b-table {
  .table {
    border: 1px solid $input-border-color;
    border-radius: 8px;

    .checkbox-cell {
      padding: 0 15px;

      @include sp {
        padding: 8px 10px;
      }

      .checkbox {
        width: 20px;
      }
    }

    thead {
      tr {
        th {
          background-color: #f0f0f0;
          padding: 8px 7px;

          @include sp {
            padding: 8px 5px;
          }

          &.checkbox-cell {
            padding-top: 6px;
          }

          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
          &:nth-child(2) {
            padding-left: 15px;

            @include sp {
              padding-left: 5px;
            }
          }
          &:nth-child(15) {
            padding-right: 5px;
          }

          .sublabel {
            font-weight: normal;
          }

          /deep/ .icon {
            margin-top: -18px;

            .mdi-arrow-up {
              margin-bottom: 4px;

              &::before {
                border-style: solid;
                border-width: 0 5px 8px 5px;
                border-color: transparent transparent $input-color transparent;
                content: "";
                // display: block;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        &.is-checked {
          outline: 1px solid $info;

          td {
            &.checkbox-cell {
              background-color: #e1f6f2;
            }
          }
        }

        &.is-score-below-350 {
          td {
            background-color: #f9f9f9;
          }
        }

        td {
          color: $snackbar-color;
          padding: 8px 7px;
          @include sp {
            padding: 8px 5px;
          }
          &:first-child {
            vertical-align: middle;
          }
          &:nth-child(2) {
            padding-left: 4px;
            @include sp {
              padding-left: 5px;
            }
          }
          &:nth-child(15) {
            padding-right: 5px;
          }
          &:nth-child(n + 4) {
            font-size: 12px;
            vertical-align: unset;
          }
          &:last-child {
            vertical-align: middle;
          }
        }
      }
    }

    .b-checkbox.checkbox {
      input[type="checkbox"]:not(:disabled) {
        &:checked {
          + .check {
            background-color: $info;
            border-color: $info;
          }

          &:focus + .check {
            box-shadow: 0 0 0.5em rgba(0, 153, 126, 0.8);
          }
        }

        &:hover + .check {
          border-color: $info;
        }
      }
    }
  }
}

.investor {
  &--buyingneeds {
    width: 62px;
    text-align: right;
  }

  &--income,
  &--assets {
    width: 96px;
    text-align: right;
  }

  &--bank {
    width: 76px;
    padding-bottom: 10%;
  }

  &--realestate {
    width: 62px;
    text-align: right;
    margin: 0 auto 0 auto;
    padding-right: 8px;
  }

  &--job {
    width: 120px;
    padding-top: 32%;
    padding-bottom: 18%;
  }

  &--company {
    width: 70px;
  }

  &--career {
    width: 50px;
    text-align: right;
  }

  &--register {
    width: 70px;
    text-align: right;
  }

  &--latestActionDt {
    width: 104px;
    text-align: right;
  }

  &--asread {
    width: 66px;
    text-align: right;
  }

  &--message {
    width: 70px;
    text-align: right;
  }

  &--detail {
    color: #777777;
    font-size: 14px;
    font-weight: normal;
    height: 28px;
    width: 87px;
  }

  &--score {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#37d2ff),
      to(#2a7bcf)
    );
    background: linear-gradient(#37d2ff, #2a7bcf);
    height: 75px;
    margin: 0 6px 10px;
    padding: 6px;
    width: 75px;
  }

  &--text {
    &--small {
      font-size: 12px;
      line-height: 18px;
    }
  }

  // &--number {
  //   &__main,
  //   &__sub {
  //     font-size: 14px;
  //     @include sp {
  //       font-size: 12px;
  //     }
  //   }
  // }

  &--favorite-realotr {
    width: 90px;
    > p {
      font-size: 12px;
      color: #333333;
      letter-spacing: 0;
      line-height: 13px;
      text-align: center;
      > img {
        width: 13px;
        vertical-align: bottom;
      }
    }
  }

  &--favorite {
    &__sort {
      position: relative;
      width: 110px;
    }

    &__sublabel {
      color: #363636;
      font-weight: normal;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - 20px);
    }
  }
}

.blankBox {
  height: 300px;
}

.floatingBar {
  background-color: #e1eaf7;
  font-size: 15px;
  font-weight: bold;
  height: 100px;
  padding: 8px 8px 8px 100px;
  position: fixed;
  right: 0;
  z-index: 20;
  width: 100%;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  &.show {
    opacity: 1;
    bottom: 0;
  }
  &.hide {
    opacity: 0;
    bottom: -110px;
  }

  @include sp {
    font-size: 12px;
    height: auto;
    padding: 8px calc((100% - 335px) / 2);
  }

  &--header {
    color: $primary;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }

  &--error {
    color: $red;
    text-align: center;
    width: 100%;

    @include sp {
      font-size: 16px;
    }
  }

  &--content {
    display: flex;
    justify-content: space-between;

    @include sp {
      flex-flow: row wrap;
    }
  }

  &--item {
    position: relative;

    &:first-child {
      margin-top: 8px;
      width: calc((100% - 618px) / 2);
    }

    &:last-child {
      margin-top: 2px;
      width: calc((100% - 618px) / 2);
    }

    &:nth-child(2) {
      margin: 8px 20px 0;
      min-width: 580px;
    }

    @include sp {
      flex-basis: 100%;

      &:first-child,
      &:last-child,
      &:nth-child(2) {
        margin: 8px 0 0;
        min-width: unset;
        width: 100%;
      }
    }
  }

  &--button {
    overflow: hidden;
    text-align: center;
  }

  &--primary {
    line-height: 20px;
    width: 270px;
    font-size: 15px;
    &__sub {
      font-size: 12px;
      font-weight: normal;
      @include sp {
        display: none;
      }
    }

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    @include sp {
      font-size: 14px;
      height: 46px;
      line-height: 16px;
      min-width: unset;
      width: 135px;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }

  &--clear {
    border-color: #777777;
    color: #777777;
    font-size: 15px;
    height: 32px;
    right: 40px;
    margin-left: 15px;
    position: absolute;
    top: -4px;
    width: 120px;

    @include sp {
      font-size: 14px;
      height: 38px;
      left: unset;
      top: 0;
      width: 100px;
      right: 10px;
    }
  }

  &--select {
    float: right;
    line-height: 26px;
    width: 300px;

    @include sp {
      line-height: 18px;
      width: 100%;
      padding: 0 20px;
    }

    &__current {
      color: $red;
      display: inline-block;
      font-size: 26px;
      text-align: right;

      @include sp {
        font-size: 18px;
      }
    }

    &__remain {
      color: $red;
      font-size: 18px;

      @include sp {
        font-size: 14px;
      }
    }
  }

  &--help {
    &--tooltip {
      float: left;

      @include sp {
        float: right;
      }
    }

    &--icon {
      height: 48px;
      margin: 8px 16px 0 16px;
      width: 48px;

      @include sp {
        display: none;
      }
    }

    &--text {
      color: $primary;
      font-size: 14px;
      margin-top: 10px;
      max-width: 340px;

      @include sp {
        font-size: 12px;
        margin-top: 0;
        max-width: unset;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.setting {
  width: 600px;

  &--title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
  }

  &--footer {
    text-align: center;
    width: calc(100% - 28px);

    .button {
      height: 64px;
      margin: 0 auto;
      width: 344px;
    }
  }
}
</style>
