<template>
  <div class="realestate" @click="$emit('click')">
    <template v-if="!bulma.isMobile">
      <div class="columns">
        <div class="column is-narrow">
          <p>
            {{ $$ks.realEstateType(value.data.realEstateType).text }}
          </p>
          <figure class="image realestate__image">
            <z-image v-model="images(value)[0]" />
          </figure>
        </div>
        <div class="column">
          <table class="realestate__gross">
            <tr>
              <td colspan="5">
                <p class="is-elliptical">{{ value.data.name }}</p>
              </td>
            </tr>
            <div class="relative">
              <tr>
                <th class="realestate__gross__th">価格</th>
                <td class="realestate__gross__red is-elliptical">
                  {{ $$price$priceJPFormat(value.data.price) }}
                </td>
              </tr>
              <tr class="absolute">
                <th class="realestate__gross__th">利回り</th>
                <td class="realestate__gross__red">
                  {{ couponYieldRate }}
                  <!-- <template v-if="value.data.couponYieldRate">
                    {{ $$rete$rateFormat(value.data.couponYieldRate) }}
                  </template>
                  <template v-else>
                    -
                  </template> -->
                </td>
              </tr>
            </div>
          </table>

          <hr class="realestate__hr" />
          <table class="realestate__descriptions">
            <tr class="flex-absolute">
              <th class="flex-child">所在地</th>
              <td colspan="5" class="flex-child">
                <p>
                  {{ address }}
                </p>
                <template v-for="(t, idx) in transports">
                  <p :key="idx">{{ t }}</p>
                </template>
              </td>
            </tr>

            <template
              v-if="
                value.data.realEstateType !== 7 &&
                value.data.realEstateType !== 10
              "
            >
              <tr class="flex-absolute">
                <th class="flex-child">建物種別</th>
                <td class="flex-child">
                  {{ $$ks.getTypeOfBuildingType(value.data.buildingType).text }}
                </td>
                <th class="flex-child">築年月</th>
                <td class="flex-child" colspan="3">
                  {{ value.data.constructionDate.year }}年{{
                    value.data.constructionDate.month
                  }}月（築{{
                    this.$$realEstate$calcYearOfConstruction(
                      value.data.constructionDate
                    )
                  }}年）
                </td>
              </tr>

              <tr class="flex-absolute">
                <th class="flex-child">建物面積</th>
                <td class="flex-child">
                  {{ $$num$commaFormat(value.data.buildingArea) }}㎡
                </td>
                <th class="flex-child">土地面積</th>
                <td class="flex-child">
                  {{
                    $$num$commaFormat(
                      checkNull(value.data.saleSetting.landArea)
                    )
                  }}㎡
                </td>
                <th class="flex-child flex-absolute__floor">階数</th>
                <td class="flex-child">
                  {{ $$num$commaFormat(value.data.numberOfFloors) }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="flex-absolute">
                <th class="flex-child">建物種別</th>
                <td class="flex-child">-</td>
                <th class="flex-child">築年月</th>
                <td class="flex-child" colspan="3">-</td>
              </tr>

              <tr class="flex-absolute">
                <th class="flex-child">建物面積</th>
                <td class="flex-child">-</td>
                <th class="flex-child">土地面積</th>
                <td class="flex-child">
                  {{
                    $$num$commaFormat(
                      checkNull(value.data.saleSetting.landArea)
                    )
                  }}㎡
                </td>
                <th class="flex-child flex-absolute__floor">階数</th>
                <td class="flex-child">-</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content has-text-left">
        <p>
          <span class="has-text-grey is-size-6"
            >{{ $$ks.realEstateType(value.data.realEstateType).text }}<br
          /></span>
          <span class="is-elliptical">{{ value.data.name }}</span>
        </p>
      </div>
      <div class="columns is-gapless is-multiline is-mobile">
        <div class="column is-5">
          <figure class="image realestate__image">
            <z-image v-model="images(value)[0]" />
          </figure>
        </div>
        <div class="column is-7">
          <p class="realestate__gross__p">
            <span class="realestate__gross__th"> 価格&nbsp;&nbsp;&nbsp; </span>
            <span class="realestate__gross__red">
              {{ $$price$priceJPFormat(value.data.price) }}
            </span>
          </p>
          <p class="realestate__gross__p">
            <span class="realestate__gross__th"> 利回り </span>
            <span class="realestate__gross__red">
              {{ couponYieldRate }}
              <!-- {{ $$rete$rateFormat(value.data.couponYieldRate) }} -->
            </span>
          </p>
        </div>
      </div>
      <div class="columns is-gapless is-multiline is-mobile">
        <div class="column">
          <div class="content has-text-left realestate__descriptions">
            <p>
              <span class="realestate__descriptions__mobile__title">
                所在地
              </span>
              <br />
              <span>{{ address }}</span
              ><br />
              <template v-for="(t, idx) in transports">
                <span :key="idx"> {{ t }}<br /></span>
              </template>
            </p>
            <div class="columns is-gapless is-multiline is-mobile">
              <template
                v-if="
                  value.data.realEstateType !== 7 &&
                  value.data.realEstateType !== 10
                "
              >
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物種別 </span
                    ><br />
                    <span>
                      {{
                        $$ks.getTypeOfBuildingType(value.data.buildingType).text
                      }}</span
                    >
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物面積 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.buildingArea) }}㎡
                    </span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      階数 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.numberOfFloors) }}
                    </span>
                  </p>
                </div>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      築年月 </span
                    ><br />
                    <span>
                      {{ value.data.constructionDate.year }}年{{
                        value.data.constructionDate.month
                      }}月（築{{
                        this.$$realEstate$calcYearOfConstruction(
                          value.data.constructionDate
                        )
                      }}年）
                    </span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      土地面積 </span
                    ><br />
                    <span>
                      {{
                        $$num$commaFormat(
                          checkNull(value.data.saleSetting.landArea)
                        )
                      }}㎡
                    </span>
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物種別 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物面積 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      階数 </span
                    ><br />
                    <span>-</span>
                  </p>
                </div>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      築年月 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      土地面積 </span
                    ><br />
                    <span>
                      {{
                        $$num$commaFormat(
                          checkNull(value.data.saleSetting.landArea)
                        )
                      }}㎡
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "investor-search-realestates-parts",
  //data: function() {
  //  return {
  //    isLoading: true,
  //    currentPage: 0,
  //    realEstates: []
  //  };
  //},
  props: {
    value: {
      type: [Object],
      require: true,
    },
  },
  asyncComputed: {
    async address() {
      if (this.value.data.overseasRealEstate) {
        const country = this.$$ks.getCountry(
          this.value.data.overseas.country
        ).text;
        return this.value.data.overseas.building
          ? country +
              ", " +
              this.value.data.overseas.state +
              ", " +
              this.value.data.overseas.address +
              ", " +
              this.value.data.overseas.building
          : country +
              ", " +
              this.value.data.overseas.state +
              ", " +
              this.value.data.overseas.address;
      } else {
        const text = await this.$$area$generateTextOfAddress(this.value.data);
        return text;
      }
      // const text = await this.$$area$generateTextOfAddress(this.value.data);
      // return text;
    },
    async transports() {
      if (this.value.data.overseasRealEstate) {
        return this.$$realEstate$overseasTransportListFormt(
          this.value.data.overseas.transports
        );
      } else {
        return this.$$realEstate$transportListFormt(this.value.data.transports);
      }
      // return this.$$realEstate$transportListFormt(this.value.data.transports);
    },
  },
  computed: {
    couponYieldRate() {
      if (
        this.value.data.realEstateType === 7 ||
        this.value.data.realEstateType === 10
      ) {
        if (
          !this.value.data.annualEstimatedIncome ||
          this.value.data.annualEstimatedIncome === 0
        ) {
          return "-";
        }
      }
      if (this.value.data.couponYieldRate) {
        return this.$$rete$rateFormat(this.value.data.couponYieldRate);
      } else {
        return "-";
      }
    },
  },
  methods: {
    checkNull(val) {
      // console.log(val);
      // console.log(val == NaN);
      // console.log(val == "NaN");
      if (val && val != "NaN") return val;
      else return "- ";
    },
    images(realEstate) {
      if (realEstate) {
        return []
          .concat(realEstate.data.images.exteriors)
          .concat(realEstate.data.images.interiors)
          .concat(realEstate.data.images.others);
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.list-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 11px 22px;
  background-color: $important;
  border-bottom: solid 2px #707070;
  color: $white;
}

.realestate {
  cursor: pointer;
  &__image {
    width: 192px;
    @include mobile {
      width: 100%;
    }
  }
  &__hr {
    margin-top: 2px;
    margin-bottom: 4px;
  }
  &__gross {
    font-size: 1.3em;
    &__th {
      width: 88px;
      @include sp {
        font-size: 0.6em;
        width: 0px;
        text-align: left;
      }
    }
    &__red {
      @include sp {
        font-size: 0.9em;
      }
      color: #f00;
    }
    &__p {
      margin-left: 8px;
      text-align: left;
    }
    &__mobile {
      &__th {
        width: 0px;
        text-align: left;
      }
      &__red {
        color: #f00;
      }
      &__p {
        margin-left: 8px;
        text-align: left;
      }
    }
  }
  &__descriptions {
    font-size: 14px;
    @include sp {
      font-size: 0.7rem;
    }
    @include md {
      font-size: 0.8rem;
    }
    &__mobile {
      color: #363636;
      &__title {
        font-color: black;
        //font-size: 0.8rem;
      }
    }
  }
}
.table td,
.table th {
  border-width: 0;
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}
.relative {
  //position: relative;
  display: block;
  .absolute {
    white-space: nowrap;
    //position: absolute;
    left: 300px;
    top: 0;
  }
  th,
  td {
    white-space: nowrap;
  }
}
@media screen and (max-width: 1500px) {
  .absolute {
    position: static !important;
  }
}
@include sp {
  .column {
    p {
      font-size: 1.2em;
    }
  }
}
.realestate__descriptions {
  table-layout: fixed;
  width: 100%;
  th {
    width: 72px;
  }
  // .tr {
  //   display: flex;
  //   flex: auto;
  // }
  // th,
  // td {
  //   padding: 0 1vw 0 0;
  //   white-space: nowrap;
  // }
}
// .flex-absolute {
//   display: flex;
//   .flex-child {
//     width: calc(45px + 1.2vw);
//     padding-left: 7px;
//   }
//   &__floor {
//     width: 45px !important;
//   }
// }
</style>
