<template>
  <b-button
    class="delete-button"
    :label="label"
    @click="$emit('click', $event)"
    icon-left="close"
    type="is-text"
  />
</template>

<script>
export default {
  name: "DeleteButton",
  props: {
    label: String,
    callback: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.delete-button {
  color: #777777;
  font-size: 14px;
  text-decoration: none;
  &:hover {
    background-color: transparent;
  }
  /deep/ .icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    // margin-right: 0 !important;
    background-color: #f4f4f4;
    border-radius: 50%;
    font-size: 1.3em;
    .mdi {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
