import Vue from "vue";
import Vuex from "vuex";
// import { vuexfireMutations } from "vuexfire";
import auth from "@/stores/auth";
import user from "@/stores/user";
import investor from "@/stores/investor";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    auth,
    investor,
  },
  // mutations: vuexfireMutations,
  strict: process.env.NODE_ENV !== "production",
  state: {
    isShowLogoLoading: true,
  },
  mutations: {
    showLogoLoading(state) {
      if (!state.isShowLogoLoading) state.isShowLogoLoading = true;
    },
    hideLogoLoading(state) {
      if (state.isShowLogoLoading) state.isShowLogoLoading = false;
    },
  },
});

const sns = [
  // "@/stores/userAdmin",
  "@/stores/user",
  "@/stores/auth",
];

if (module.hot) {
  for (let sn of sns) {
    module.hot.accept([sn], () => {
      console.log("hotUpdate:" + sn);
      store.hotUpdate({
        modules: {
          auth: require(`${sn}`).default,
        },
      });
    });
  }
}

export default store;
