<template>
  <div class="formWorkExperience">
    <section class="edit__section">
      <h3 class="edit__subtitle">メイン職業</h3>

      <z-select2
        label="業種"
        name="career-categoryOfBusiness"
        v-model="value.data.profile.currentOfCareer.categoryOfBusiness"
        :options="categoryOfBusinesses"
        :validate="'required'"
        isRequired
      />

      <z-select2
        label="職種"
        name="career-typeOfOccupation"
        v-model="value.data.profile.currentOfCareer.typeOfOccupation"
        :options="typeOfOccupations"
        :validate="'required'"
        isRequired
      />

      <z-input
        label="会社名"
        name="career-name"
        v-model="value.data.profile.currentOfCareer.name"
        :validate="'required'"
        maxlength="30"
        placeholder="例)株式会社ZIRITZ"
        isRequired
        isPrivate
      />

      <z-switch
        label="勤務状況"
        name="career-isCurrent"
        v-model="value.data.profile.currentOfCareer.isCurrent"
        isRequired
        isPrivate
        >現在勤務中</z-switch
      >

      <z-year-month-select
        label="在籍期間(開始)"
        name="career-from"
        v-model="value.data.profile.currentOfCareer.period.from"
        :validate="'required'"
        isRequired
      />

      <z-year-month-select
        v-if="!value.data.profile.currentOfCareer.isCurrent"
        label="在籍期間(終了)"
        name="career-toDt"
        v-model="value.data.profile.currentOfCareer.period.toDt"
        :validate="'required'"
        isRequired
      />
    </section>

    <template v-if="!isRegister">
      <hr class="edit__hr" />

      <section class="edit__section">
        <h3 class="edit__subtitle">経験職業</h3>

        <ul class="edit__list">
          <li
            v-for="(career, i) in value.data.profile.careers"
            :index="i"
            :key="i"
            class="edit__list__item"
          >
            <z-edit-accordion :title="'経験職業 ' + (i + 1)" isProtrude>
              <div>
                <z-select2
                  label="業種"
                  name="career-categoryOfBusiness"
                  v-model="career.categoryOfBusiness"
                  :options="categoryOfBusinesses"
                  :validate="'required'"
                  isRequired
                  isPrivate
                />

                <z-select2
                  label="職種"
                  name="career-typeOfOccupation"
                  v-model="career.typeOfOccupation"
                  :options="typeOfOccupations"
                  :validate="'required'"
                  isRequired
                  isPrivate
                />

                <z-input
                  label="会社名"
                  name="career-name"
                  v-model="career.name"
                  :validate="'required'"
                  placeholder="例)株式会社ZIRITZ"
                  maxlength="30"
                  isRequired
                  isPrivate
                />

                <z-switch
                  label="在籍状況"
                  name="career-isCurrent"
                  v-model="career.isCurrent"
                  isRequired
                  isPrivate
                  >現在勤務中</z-switch
                >

                <z-year-month-select
                  label="在籍期間(開始月)"
                  name="career-from"
                  v-model="career.period.from"
                  :validate="'required'"
                  isRequired
                />

                <z-year-month-select
                  v-if="!career.isCurrent"
                  label="在籍期間（終了月）"
                  name="career-toDt"
                  v-model="career.period.toDt"
                  :validate="'required'"
                  isRequired
                />

                <div class="has-text-right">
                  <delete-button
                    label="削除する"
                    @click="onClickDeleteCareer(i)"
                  />
                </div>
              </div>
            </z-edit-accordion>
          </li>
        </ul>

        <add-button label="追加する" @click="onClickAddCareer()" />
      </section>
    </template>
    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">最終学歴</h3>

      <z-select
        label="学歴区分"
        name="education-type"
        v-model="value.data.profile.education.type"
        :options="educationTypes"
        :validate="'required'"
        isRequired
        isPrivate
      />

      <template v-if="!isRegister">
        <z-input
          label="学校名"
          name="education-name"
          v-model="value.data.profile.education.name"
          placeholder="例)東京大学"
          maxlength="50"
          isPrivate
        />

        <z-input
          label="学部・学科"
          name="education-department"
          v-model="value.data.profile.education.department"
          :placeholder="'例)経営学部\u3000経営学科'"
          maxlength="50"
          isPrivate
        />
      </template>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "FormWorkExperience",
  inject: ["$validator"],
  props: {
    value: Object,
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeOfOccupations: kubuns.typeOfOccupations,
      categoryOfBusinesses: kubuns.categoryOfBusinesses,
      educationTypes: kubuns.educationTypes,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  methods: {
    onClickAddCareer() {
      let careers = this.value.data.profile.careers;
      careers.push(this.$$investor$getNewCareer());
    },
    onClickDeleteCareer(index) {
      let careers = this.value.data.profile.careers;
      careers.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
