<template>
  <div>
    <router-view></router-view>
    <div class="container">
      <section class="section">
        <ZSuggestionsCheckbox label="提案の特徴" v-model="suggestions" />
      </section>
      <section class="section">
        <SuggestionsView :suggestions="suggestions" />
      </section>
      <section class="section">
        <ZTemplateButton text=" 興味あり（詳細資料の請求）" />
        <ZTemplateButton text="購入・融資の相談" />
        <ZTemplateButton text="価格交渉依頼" />
        <ZTemplateButton text=" お見送り（次回提案の希望）" />
      </section>

      <section class="section">
        <h1 class="title is-1">Text Font</h1>
        <h2 class="title">Title</h2>
        <h3 class="subtitle is-3">サブタイトル</h3>
        <p>body 1234567890 あいうえお アイウエオ 日本語</p>
      </section>
      <section class="section">
        <h1>三点リーダ</h1>
        <z-truncator :line="1" :text="tranText" />
        <hr />
        <z-truncator :line="2" :text="tranText" />
        <hr />
        <div class="content has-text-danger">
          <z-truncator :text="tranText" />
        </div>
        <hr />
        <div class="content is-small">
          <z-truncator :text="tranText" class="has-text-danger" />
        </div>
        <hr />
      </section>
      <section class="section">
        <h1 class="title is-1">Buttons</h1>
        <div class="buttons">
          <router-link
            class="button z-button is-rounded"
            :to="{ name: '/home' }"
          >
            戻る
          </router-link>
          <router-link
            disabled
            class="button z-button is-rounded"
            :to="{ name: '/home' }"
          >
            Disabled
          </router-link>
          <router-link
            class="button z-button is-primary is-rounded"
            :to="{ name: '/home' }"
          >
            始める
          </router-link>
          <router-link
            disabled
            class="button z-button is-primary is-rounded"
            :to="{ name: '/home' }"
          >
            Disabled
          </router-link>
          <router-link
            class="button z-button is-primary is-wide is-rounded"
            :to="{ name: '/home' }"
          >
            始める is-wide
          </router-link>
          <router-link class="button z-button is-text" :to="{ name: '/home' }">
            Text
          </router-link>
          <router-link
            class="button z-button is-primary is-small is-outlined is-rounded"
            :to="{ name: '/home' }"
          >
            全てのオファーを見る
          </router-link>
        </div>

        <div class="buttons">
          <b-button class="z-button" rounded>戻る</b-button>
          <b-button class="z-button" disabled rounded>Disabled</b-button>
          <b-button class="z-button" type="is-primary" rounded>始める</b-button>
          <b-button class="z-button" type="is-primary" disabled rounded
            >Disabled</b-button
          >
          <b-button class="z-button" type="is-primary is-wide" rounded>
            始める is-wide
          </b-button>
          <b-button class="z-button" type="is-text">Text</b-button>
          <b-button
            class="z-button"
            type="is-primary"
            size="is-small"
            rounded
            outlined
            >全てのオファーを見る</b-button
          >
          <b-button class="z-button" size="is-small">リセットする</b-button>
          <b-button class="z-button" type="is-primary" size="is-small"
            >検索する</b-button
          >
          <MoreButton label="もっと見る (50件)" />
          <MoreButton label="もっと見る (50件) expanded" expanded />
          <DeleteButton label="削除する" />
          <AddButton label="追加する" />
          <MembershipsButton />
        </div>
      </section>
      <section class="section">
        <h1 class="title is-1">Forms</h1>

        <ZInput
          label="姓名（漢字）"
          placeholder="例)須藤 九太郎"
          maxlength="50"
          helpMessage="ヘルプメッセージ"
          isRequired
          isPrivate
        />

        <ZInput
          placeholder="ラベルが未設定"
          maxlength="50"
          helpMessage="ヘルプメッセージ"
          isRequired
          isPrivate
          hideRequired
        />
        <ZInput
          label="hideRequired"
          placeholder="hideRequired"
          maxlength="50"
          helpMessage="ヘルプメッセージ"
          isRequired
          isPrivate
          hideRequired
        />

        <ZInput
          label="姓名（カナ）"
          value="アイウエオ"
          placeholder="例)須藤 九太郎"
          maxlength="50"
          helpMessage="ヘルプメッセージ"
          isRequired
          isPrivate
        />

        <ZInput
          label="電話番号"
          placeholder="例)09011111111"
          maxlength="11"
          message="携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
        />

        <ZInput
          label="電話番号"
          placeholder="例)09011111111"
          maxlength="11"
          message="携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
        />

        <ZTextarea
          :rows="8"
          label="備考"
          maxlength="256"
          message="携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
        />

        <ZPriceInput
          label="収入1"
          placeholder="例)¥500,000"
          isRequired
          v-model.number="testNum"
        />

        <ZPriceInput
          label="収入2"
          placeholder="例)¥600,000"
          maxlength="12"
          v-model.number="testNum2"
          validate="required|price_min_value:1"
          isRequired
        />

        <p>値：{{ testNum2 }}</p>
        <p>金額変換：{{ $$price$priceFormat(testNum2) }}</p>
        <p>金額変換(JP)：{{ $$price$priceJPFormat(testNum2) }}</p>
        <b-button
          class="z-button"
          type="is-primary"
          size="is-small"
          rounded
          outlined
          @click="testNum2 = Math.round(Math.random() * 10000000)"
          >値を更新</b-button
        >

        <ZPercentInput
          label="利回り"
          placeholder="例)¥600,000"
          isRequired
          maxlength="20"
          :decimal="4"
          v-model.number="testPercent"
        />

        <ZInput label="メッセージ" type="textarea" maxlength="200" />

        <ZSelect
          label="業種"
          placeholder="業種を選択"
          :options="typeOfOccupations"
          isRequired
        />

        {{ selected2 }}
        <ZSelect2
          label="業種"
          placeholder="業種を選択"
          v-model="selected2"
          :options="typeOfOccupations"
          isRequired
        />

        <ZCheckbox label="建物種別" :options="typeOfBuildingTypes" isRequired />

        <ZRadio label="性別" v-model="radio" :options="typeOfSex" isRequired />

        <ZSwitch label="有価証券" v-model="swit">有価証券を所有</ZSwitch>

        <ZDatepicker label="融資実行年月" placeholder="XXXX/XX/XX" isRequired />
        <hr />

        <ZDatepicker
          label="年月"
          v-model="dateOfNormal"
          placeholder="XXXX/XX/XX"
          isRequired
        />
        {{ $$calender$dateFormat(dateOfNormal) }}
        <ZDatepicker
          label="FirebaseからのDate"
          v-model="dateOfFB"
          placeholder="XXXX/XX/XX"
          isRequired
        />
        {{ $$calender$dateFormat(dateOfFB) }}

        <ZDatepicker
          label="JSのDate"
          v-model="dateOfJS"
          placeholder="XXXX/XX/XX"
          isRequired
        />
        {{ $$calender$dateTimeFormat(dateOfJS) }}<br />
        {{ $$calender$todayFormat(dateOfJS) }}<br />
        {{ $$calender$todayFormat(oldDateOfJS) }}
        <hr />

        <h1 class="title is-1">ZDateSelect</h1>

        <ZDateSelect
          label="年月日"
          v-model="dateOfNormal"
          :validate="'required'"
          isRequired
          isPrivate
        />

        <b-field label="都道府県 ※arrowエリアtapでinputクリアして全出しする？">
          <b-autocomplete
            v-model="name"
            placeholder="都道府県を選択"
            :open-on-focus="true"
            :data="filteredDataObj"
            field="text"
            @select="(option) => (selected = option)"
          >
          </b-autocomplete>
        </b-field>

        <b-field
          label="都道府県（Error）"
          type="is-danger"
          message="Something went wrong with this field"
        >
          <b-autocomplete
            v-model="name"
            placeholder="都道府県を選択"
            :open-on-focus="true"
            :data="filteredDataObj"
            field="text"
            @select="(option) => (selected = option)"
          >
          </b-autocomplete>
        </b-field>

        <div class="field">
          <tile-box v-on:receiveOnClose="onCloseFunction()">
            <template v-slot:title>
              <p>給与・役員報酬</p>
            </template>
            <template v-slot:body>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
            </template>
            <template v-slot:overlay>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
            </template>
          </tile-box>
        </div>

        <div class="field">
          <tile-box v-on:receiveOnClose="onCloseFunction()">
            <template v-slot:title>
              <p>給与・役員報酬</p>
            </template>
            <template v-slot:body>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
              <p>メインテキスト</p>
            </template>
            <template v-slot:overlay>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
            </template>
          </tile-box>
        </div>
      </section>

      <section class="section">
        <h1 class="title is-1">ZModal</h1>
        <b-button class="z-button" type="is-link" rounded @click="onOpenModal">
          モーダルを開く
        </b-button>

        <ZModal
          title="タイトル"
          :isActive="isActiveModal"
          @close="onCloseModal"
        >
          <template v-slot:content>
            <p>ここにモーダルのコンテンツが入ります。slotで指定できます。</p>
            <p>
              isActiveでモーダルの開閉を制御できますが、双方向バインディングな作りにしてはいないので、@closeの中でisActive=falseにしてください。
            </p>
          </template>
        </ZModal>
      </section>

      <section class="section">
        <h1 class="title is-1">ZIcon</h1>
        <p>
          /assets/icons/に格納したsvgファイルを動的に読み込むコンポーネントです。
        </p>
        <p>nameにはsvgのファイル名を指定してください。</p>
        <ZIcon name="dashboard" />
        <ZIcon name="dashboard" color="#ff0000" width="40" height="40" />
        <ZIcon name="offer" />
        <ZIcon name="portfolio" />
        <ZIcon name="property" />
        <ZIcon name="specialist" />
        <ZIcon name="transaction" />
        <ZIcon name="hand" />
        <ZHelpIcon />
      </section>

      <section class="section">
        <h1 class="title is-1">ZLabelList</h1>
        <div class="box">
          <z-label-list v-model="labelList"> </z-label-list>
        </div>

        <div class="field">
          <tile-box v-on:receiveOnClose="onCloseFunction()">
            <template v-slot:title>
              <z-tile-box-title :title="'タイトルだよ'"> </z-tile-box-title>
            </template>
            <template v-slot:body>
              <z-tile-box-body-list v-model="labelList" />
            </template>
            <template v-slot:overlay>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
              <p>オーバーレイテキスト</p>
            </template>
          </tile-box>
        </div>
      </section>

      <section class="section">
        <h1 class="title is-1">ZBlankBox</h1>
        <div style="height: 300px">
          <z-blank-box>幅・高さは、親で設定してください</z-blank-box>
        </div>
      </section>

      <section class="section">
        <h1 class="title is-1">ZProfileImageSelect</h1>
        <p>
          「適用」ボタンタップで画像のアップロードを行い、画像のimageオブジェクトが格納されます。
        </p>
        <ZProfileImageSelect v-model="profileimage" />
      </section>
      <section class="section">
        <h1>プロフィール</h1>
        <figure class="image is-128x128">
          <z-profile-thumbnail v-model="image" />
        </figure>
        ImageがNullの場合
        <figure class="image is-128x128">
          <z-profile-thumbnail v-model="nullImage" />
        </figure>
      </section>

      <section class="section">
        <h1 class="title is-1">ZDocsList</h1>
        <z-docs-list v-model="files" />
      </section>

      <section class="section">
        <h1 class="title is-1">ZDocsInput</h1>
        <z-docs-input
          v-model="file"
          v-if="files.length <= 5"
          uploadPath="docs"
          @addFile="addFile"
        />
      </section>

      <section class="section">
        <h1 class="title is-1">ZDocsListSelect</h1>
        <z-docs-list-select v-model="files" uploadPath="docs" max="5" />
      </section>

      <h1 class="title is-1">ファイルデータ</h1>
      {{ files }}

      <section class="section">
        <h1 class="title is-1">ZImage</h1>
        コンバート後
        <figure class="image is-128x128">
          <z-image :original="false" v-model="image" />
        </figure>
        オリジナル
        <figure class="image is-128x128">
          <z-image :original="true" v-model="oimage" />
        </figure>
        ImageオブジェクトがNULLの場合
        <figure class="image is-128x128">
          <z-image v-model="nullImage" />
        </figure>
        ImageオブジェクトがNULLの場合で外部から指定
        <figure class="image is-128x128">
          <z-image v-model="nullImage" :emptySrc="emptyImage" />
        </figure>
        エラー時
        <figure class="image is-128x128">
          <z-image v-model="bimage" />
        </figure>
      </section>
      <section class="section">
        <h1 class="title is-1">ZCarousel</h1>
        普通
        <figure class="image is-128x128">
          <z-carousel :images="images"> </z-carousel>
        </figure>
        <hr />
        空のリスト
        <figure class="image is-128x128">
          <z-carousel :images="emptyImages"> </z-carousel>
        </figure>
        空のリストで外部から指定
        <figure class="image">
          <z-carousel :images="emptyImages" :emptySrc="emptyImage">
          </z-carousel>
        </figure>
      </section>

      <section class="section">
        <h1 class="title is-1">ZRealestateImages</h1>
        <figure class="image is-128x128">
          <z-realestate-images :images="realestateImages" />
        </figure>
      </section>

      <section class="section">
        <h1 class="title is-1">ZThumbnailListSelect</h1>
        <p>
          「適用」ボタンタップで画像のアップロードを行い、画像のimageオブジェクトが格納されます。
        </p>
        <ZThumbnailListSelect
          v-model="thumbnailImageList"
          title="物件情報"
          :max="5"
        />
      </section>
    </div>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
//import firebase from "@/plugins/firebase";

const emptyImage = require("@/assets/top/top.jpg");

export default {
  name: "admin-demo-base",
  components: {
    // PrefectureSelect: PrefectureSelect
  },
  data() {
    return {
      suggestions: [1, 2, 3, 4, 5],
      swit: null,
      radio: null,
      prefectures: kubuns.prefectures,
      typeOfOccupations: kubuns.typeOfOccupations,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
      typeOfSex: kubuns.typeOfSex,
      name: "",
      selected: null,
      selected2: null,
      testNum: 10000,
      testNum2: 20000,
      testPercent: 0.17,
      isActiveModal: false,
      dateOfNormal: null,
      dateOfJS: null,
      dateOfFB: null,
      oldDateOfJS: new Date("1995-12-17T03:24:00"),
      profileimage: {
        title: "", //タイトル
        description: "", //説明
        id: null,
        isApproved: true, //承認
      },
      image: {
        title: "タイトル", //タイトル
        description: "いろいろな説明", //説明
        id: "0af07d3b-b241-49ea-9aae-a99a25d6309c",
        isApproved: true, //承認
      },
      oimage: {
        title: "タイトル", //タイトル
        description: "いろいろな説明", //説明
        id: "1f219c6a-42c7-4f55-b543-01a99e49fc7a",
        isApproved: true, //承認
      },
      nullImage: null,
      bimage: {
        title: "タイトル", //タイトル
        description: "いろいろな説明", //説明
        id: "blank-id",
        isApproved: true, //承認
      },
      emptyImages: [],
      realestateImages: {
        exteriors: [
          {
            title: "タイトル", //タイトル
            description: "いろいろな説明", //説明
            id: "0af07d3b-b241-49ea-9aae-a99a25d6309c",
            isApproved: true, //承認
          },
        ],
        interiors: [
          {
            title: "タイトル", //タイトル
            description: "いろいろな説明", //説明
            id: "4d59dbe1-8abc-4b6d-8b64-9ef219e34f44",
            isApproved: true, //承認
          },
        ],
        others: [
          {
            title: "タイトル", //タイトル
            description: "いろいろな説明", //説明
            id: "1f219c6a-42c7-4f55-b543-01a99e49fc7a",
            isApproved: true, //承認
          },
        ],
      },
      images: [
        {
          title: "タイトル", //タイトル
          description: "いろいろな説明", //説明
          id: "0af07d3b-b241-49ea-9aae-a99a25d6309c",
          isApproved: true, //承認
        },
        {
          title: "タイトル", //タイトル
          description: "いろいろな説明", //説明
          id: "4d59dbe1-8abc-4b6d-8b64-9ef219e34f44",
          isApproved: true, //承認
        },
        {
          title: "タイトル", //タイトル
          description: "いろいろな説明", //説明
          id: "1f219c6a-42c7-4f55-b543-01a99e49fc7a",
          isApproved: true, //承認
        },
        {
          title: "タイトル", //タイトル
          description: "いろいろな説明", //説明
          id: "empty-id",
          isApproved: true, //承認
        },
      ],
      thumbnailImageList: [
        {
          title: "タイトル", //タイトル
          description: "いろいろな説明", //説明
          id: "0af07d3b-b241-49ea-9aae-a99a25d6309c",
          isApproved: true, //承認
        },
      ],
      tranText:
        "じゅげむじゅげむごこうのすりきれかいじゃりすいぎょのすいぎょうまつうんらいまつふうらいまつくうねるところにすむところやぶらこうじのぶらこうじぱいぽぱいぽぱいぽのしゅーりんがんしゅーりんがんのぐーりんだいぐーりんだいのぽんぽこぴーのぽんぽこなのちょうきゅうめいのちょうすけじゅげむじゅげむごこうのすりきれかいじゃりすいぎょのすいぎょうまつうんらいまつふうらいまつくうねるところにすむところやぶらこうじのぶらこうじぱいぽぱいぽぱいぽのしゅーりんがんしゅーりんがんのぐーりんだいぐーりんだいのぽんぽこぴーのぽんぽこなのちょうきゅうめいのちょうすけ",
      file: null,
      files: [],
    };
  },
  computed: {
    emptyImage() {
      return emptyImage;
    },
    labelList() {
      const ls = [
        {
          label: "名前",
          value: "株式会社ZIRITZ",
          isPrivate: true,
          type: "text",
        },
        {
          label: "名前",
          value: "株式会社ZIRITZ",
          isPrivate: false,
          type: "text",
        },
        {
          label: "日付",
          value: Date.now(),
          isPrivate: true,
          type: "date",
        },
        {
          label: "日時",
          value: Date.now(),
          isPrivate: true,
          type: "dateTime",
        },
        {
          label: "イメージ",
          value: "https://bulma.io/images/placeholders/128x128.png",
          isPrivate: true,
          type: "image",
        },
      ];
      return ls;
    },
    filteredDataObj() {
      return this.prefectures.filter((option) => {
        return (
          option.text
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    onCloseFunction: function () {
      console.log("close");
    },
    onOpenModal: function () {
      this.isActiveModal = true;
    },
    onCloseModal: function () {
      this.isActiveModal = false;
    },
    addFile: function (value) {
      this.files.push(value.fileData);
    },
  },
  watch: {
    testPercent: {
      handler: function (value) {
        console.log(value);
      },
    },
  },
  mounted() {
    this.dateOfFB = new Date();
    this.dateOfJS = new Date();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
