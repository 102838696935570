//import firebase from "@/plugins/firebase";

const type = {};

type.image = {
  title: "", //タイトル
  description: "",
  id: null,
  // src: "",
  isApproved: true, //承認
};

type.application = {
  status: 0,
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
  user: {
    email: "",
    name: "",
    //kana: "",
    department: "", //所属
    title: null, //役職
    phoneNumber: "",
  },
  company: {
    name: "",
    //kana: "",
  },
  comment: "",
  agreement: false,
};

type.company = {
  status: null,
  isRealtor: true,
  isActivate: false,
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,

  name: "",
  // kana: "",
  phoneNumber: null, //代表電話
  //categoryOfBusiness: null, // 業種
  //typeOfOccupation: null, // 職種
  postalCode: "",
  prefecture: null,
  city: null,
  address: "",
  building: "",

  applicationId: null,

  users: [],
};

type.user = {
  v2: true,
  status: null,
  isRealtor: true,
  isActivate: false,

  exclusiveMessageCount: 500, //エクスクルーシブメッセージの残数
  exclusiveMessageUpdateHistory: [],
  score: {
    score: 0,
    rank: 4,
  },

  role: 20, //   { value: 10, text: "プライマリー会員"{ value: 20, text: "セカンダリー会員", color: "is-primary" }

  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
  base: {
    email: "",
    name: "",
    kana: "",
    sex: null,
    birthday: null,
    phoneNumber: null,
  },
  company: {
    companyId: null, //companyId
    department: "", //所属
    title: null, //役職
  },
  profile: {
    image: null,
    specialties: [], // 得意分野
    yearOfexperience: null, //不動産経i
    profile: "", // プロフィール
    pr: "", // 自己PR・実績
  },
};

type.examination = {
  status: 1, //申請中
  reasonForRejection: null,
  permission: {
    searchInvestors: 1, // 1:通常 20:業務提携会社   99:ZIRITZ
  },
  comment: "",
};

//type.image = {
//  title: "", //タイトル
//  description: "",
//  id: null,
//  // src: "",
//  isApproved: true //承認
//};

type.transport = {
  line: null, // 路線名
  station: null, //駅名
  elapsedTime: 0, //分
};

type.layout = {
  layout: null, //間取り
  NumberOfUnits: null, //戸数
};

type.realEstate = {
  linkage: {
    id: null,
    type: "", //　aqua
    linkageId: "", // xxxxxx
  },
  name: "",
  owner: null, //cid
  isReatlor: true,
  isActivate: true, //アクティブかどうか
  registerDt: null,
  price: null,
  realEstateType: null, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
  annualEstimatedIncome: null, // 年間想定収入
  couponYieldRate: null, // 表面利回り
  overseasRealEstate: false, // 海外物件かどうか

  overseas: {
    country: null, // 国名
    state: "", // 都市・州
    address: "", // 住所詳細
    building: "", // マンション名・部屋番号
    transports: [], // 交通機関
  },

  saleSetting: {
    layouts: [],
    numberOfHouseholds: "", // 総戸数
    parking: "", // 駐車場
    landArea: null, // 土地面積
    landRight: null, // 土地権利1:所有権 2:普通賃借権 3:旧法賃借権 4:普通地上権 5:旧法地上権
    texture: null, // 地目
    urbanPlanningArea: null, // 都市計画区域 1:市街化区域 2:市街化調整区域 3:その他
    areaOfUse: null, // 用途地域
    buildingCoverageRatio: null, // 建蔽率
    floorAreaRatio: null, // 容積率
    roadBurdenAreaRatio: null, // 私道負担面積
    //realEstateId: null, //realEstateのid
    //owner: null, //investorのuid
    ///saleSettingDt: null, // 売却希望設定日
    ///askingPrice: 0, // 売却希望金額
    publicType: 1, // 公開範囲 非公開デフォルト
  },
  vacancyRate: null, // 現況空室率 1:満室 2:空室あり
  vacantRoom: "", // 空き室数
  pr: "", // PR/注意事項
  // 物件画像
  images: {
    exteriors: [], //外観
    interiors: [], //内観
    layouts: [], //間取り図・プラン
    others: [], //その他
  },
  prefecture: null, //住所（県）
  city: null, // 住所（市町村）
  address: "", // 住所（番地）
  building: "", // 住所（マンション名・部屋番号）
  transports: [], // 交通機関
  buildingType: null, // 建物種別 1:RC・SRC 2:鉄骨 3:軽量鉄骨 4:木造
  constructionDate: {
    year: null,
    month: null,
  }, // 築年月
  numberOfFloors: null, // 階数
  buildingArea: null, // 建物面積
  sumOfLayouts: "", // 総戸数

  // 社内・担当者管理情報
  transactionType: null, // 取引種別
  company: {
    // 元付会社
    name: "", // －会社名
    staffName: "", // －担当者名
    phoneNumber: "", // －電話番号
    AdConfirmationDate: null, // －広告承諾確認日
  },
  staff: null, // UID 担当者
  scheduledUpdateDate: null, // 更新予定日
  memo: "", // 備考・メモ

  asReads: [], //投資家のリスト
  //publicType: null // 公開範囲
};

type.views = {
  firstViewingDt: null, // 初回閲覧日
  lastViewedDt: null, // 最終閲覧日
};

// 成約状況  これは別として設定 :TODO
//   成約件数（過去、半年分）
//   成約金額（過去、半年分）
//   最終成約日
// 投資家評価
//    点数
//    口コミ

export default {
  type,
};
