<template>
  <div class="plan">
    <ZHeader title="Plan" sub="プラン選択" />

    <section class="edit">
      <div class="edit__inner">
        <div class="plan__score">
          <div class="plan__score__inner">
            <div class="plan__score__text">あなたの資産スコア</div>
            <div class="plan__score__proggress">
              <ZScoreProgress
                :score="$$user$score.score"
                :rank="$$user$score.rank"
              >
              </ZScoreProgress>
            </div>
          </div>
        </div>

        <InputInfoStatus isFirst />

        <h3 class="plan__title">プランをお選びください</h3>
        <div class="plan__texts">
          <p>プランはいつでも変更できます。</p>
          <p>
            プラン選択することで、<a
              class="button z-button is-text"
              href="https://stockformer.com/terms"
              target="_blank"
              >利用規約</a
            >と<a
              class="button z-button is-text"
              href="https://stockformer.com/transaction"
              target="_blank"
              >特定商取引法</a
            >に基づく表記に同意するものとします。
          </p>
        </div>

        <PlanDetail />

        <div class="plan__texts">
          <a
            class="button z-button is-text"
            href="https://stockformer.com/"
            target="_blank"
            >機能概要はこちら</a
          >
        </div>

        <div class="plan__texts">
          <p>
            ポートフォリオの必要情報を入力すると<br />プレミアムプランをはじめることができます。
          </p>
        </div>

        <div class="plan__texts">
          <b-button
            class="button z-button is-primary is-wide is-rounded is-multiline"
            :disabled="!$$investor$checkSatisfyPremium()"
            @click="onClick"
          >
            プレミアムプランを<br />90日間無料トライアルではじめる
          </b-button>
        </div>

        <div class="plan__freeplan">
          <h3 class="plan__freeplan__title">無料プラン</h3>
          <div>
            <router-link
              class="button z-button is-text"
              :to="{ name: 'investor-home' }"
            >
              無料プランで始める<b-icon icon="chevron-double-right" />
            </router-link>
          </div>
        </div>

        <PlanAttention />
      </div>
    </section>
  </div>
</template>

<script>
import ZScoreProgress from "@/components/parts/ZScoreProgress.vue";
import InputInfoStatus from "../common/InputInfoStatus.vue";
import PlanDetail from "../common/PlanDetail";
import PlanAttention from "../common/PlanAttention";

export default {
  name: "Register-Plan",
  data: function () {
    return {
      // isLoading: true
    };
  },
  components: {
    ZScoreProgress,
    InputInfoStatus,
    PlanDetail,
    PlanAttention,
  },
  methods: {
    onClick() {
      const a8param = localStorage.getItem("analyticsParam");
      if (this.analyticsValidationCheck(a8param)) {
        this.changePremiumWithAnalytics(a8param);
      } else {
        this.changePremium();
      }
    },
    analyticsValidationCheck(a8param) {
      if (
        this.$route.path.indexOf("/investor/register/plan") > -1 &&
        a8param !== "" &&
        a8param !== null &&
        a8param !== undefined
      )
        return true;
      return false;
    },
    async changePremiumWithAnalytics(a8param) {
      const priceOption = {
        unitPrice: "1500",
        num: "1",
        sum: "1500",
        productCode: "premium",
        currencyCode: "JPY",
      };
      const analyticsOrderNum = this.$uuid.v4();
      const params = {
        a8: a8param,
        pid: "s00000020818001",
        so: analyticsOrderNum,
        si:
          priceOption.unitPrice +
          "-" +
          priceOption.num +
          "-" +
          priceOption.sum +
          "-" +
          priceOption.productCode,
        currency: priceOption.currencyCode,
      };
      try {
        // a8リクエスト用のurlを整形
        const url = "https://px.a8.net/a8fly/earnings";
        const urlparam = Object.keys(params)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          )
          .join("&");
        // body直下にダミーの画像を配置し、a8へのリクエストを設定
        document.body.insertAdjacentHTML(
          "beforeend",
          `<img src='${url}?${urlparam}' style="display: none;" />`
        );
        localStorage.removeItem("analyticsParam");
      } catch (error) {
        this.$raven.captureException(error);
      }
      //注文番号のパラメータを追加した状態でchangePremiumを実行
      this.changePremium(analyticsOrderNum);
    },
    async changePremium(analyticsOrderNum) {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$applicateUserByUID(
          this.auth.uid,
          this.me.id,
          analyticsOrderNum
        );
        this.$$toast$openSuccess();
        this.$router.push({ name: "investor-home" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.plan {
  &__score {
    margin-bottom: 12px;
    padding: 20px 0 8px;
    background: linear-gradient(90deg, #37d2ff, #2a7bcf);
    color: #fff;
    &__inner {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      @include sp {
        display: block;
      }
    }
    &__text {
      margin-right: 24px;
      font-size: 20px;
      font-weight: bold;
      @include sp {
        margin-right: 0;
        font-size: 18px;
        text-align: center;
      }
    }
    // &__proggress {
    // }
  }
  &__title {
    margin-top: 36px;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    @include sp {
      font-size: 20px;
    }
  }
  &__texts {
    margin-bottom: 16px;
    text-align: center;
    & > p:not(:first-child) {
      margin-top: 4px;
    }
    .z-button.is-text {
      display: inline;
      font-size: inherit;
    }
    @include sp {
      font-size: 14px;
    }
  }
  &__freeplan {
    margin-top: 60px;
    text-align: center;
    @include sp {
      margin-top: 50px;
    }
    &__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 56px;
      background-color: #f4f4f4;
      @include sp {
        margin-left: -$edit-padding-sp;
        margin-right: -$edit-padding-sp;
        font-size: 20px;
        line-height: 52px;
      }
    }
    .z-button.is-text {
      margin-top: 20px;
      font-size: 18px;
      text-decoration: none;
      @include sp {
        margin-top: 14px;
        font-size: 16px;
      }
      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }
      /deep/ .icon {
        margin-left: -4px;
        vertical-align: middle;
        .mdi::before {
          font-size: 24px;
          vertical-align: bottom;
          @include sp {
            font-size: 22px;
          }
        }
      }
    }
  }
  &__buttons {
    margin-bottom: 16px;
    text-align: center;
  }
}
</style>
