<template>
  <div class="loanTransactionLoan">
    <template v-if="transactionInfos.length">
      <ul class="loanTransactionLoan__list">
        <li v-for="info in transactionInfos" :key="info._source.id">
          <!-- <a @click="onClick(info.id)">
            <TransactionBox :info="info" />
          </a> -->
          <a
            :href="'/investor/markettrend/realestate/' + info._source.id"
            target="_blank"
          >
            <TransactionBox :realestate="info" type="loan" />
          </a>
        </li>
      </ul>

      <div
        class="loanTransactionLoan__more"
        v-if="maxCount > transactionInfos.length && !isLoading"
      >
        <MoreButton
          :label="'もっと見る (' + maxCount + '件)'"
          @click="loadMoreInfo(false)"
          expanded
        />
      </div>
    </template>
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import TransactionBox from "./TransactionBox";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "LoanTransactionList",
  components: {
    TransactionBox,
  },
  props: {
    filter: {
      type: Object,
      required: null,
    },
    bank: {
      type: String,
      required: null,
    },
    sortType: {
      type: Number,
      required: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      pageIndex: 0,
      loadNumber: 10,
      transactionInfos: [],
      maxCount: 0,
    };
  },
  computed: {
    request() {
      return {
        marketTrendFilter: this.filter, // 市況情報検索条件
        bank: this.bank, // 	金融機関 bank(String)
        sortType: this.sortType, // ソート順（1:スコア順、2:利回り順、3:価格順、4:取引時期順）
        getNumber: this.loadNumber, // 取得件数
      };
    },
  },
  methods: {
    onClick(id) {
      this.$router.push({
        name: "investor-markettrend-realestate",
        params: { id: id },
      });
    },
    onClickMore() {
      this.loadMoreInfo();
    },
    async loadMoreInfo(isRefresh) {
      this.isLoading = true;
      if (isRefresh) {
        this.pageIndex = 0;
      }

      try {
        // const result = await this.$$MarketTrendChart$LoanTransactionList({
        //   ...this.request,
        //   pagination: this.pageIndex
        // });
        const request = { ...this.request, pagination: this.pageIndex };
        const result = await marketTrend.searchRealestes(request);
        const { /* countOfData, */ pagination, maxCount, data } = result;
        if (isRefresh) {
          this.transactionInfos.splice(-this.transactionInfos.length);
        }
        this.transactionInfos.push(...data);
        this.pageIndex = pagination + 1;
        this.maxCount = maxCount;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("データの取得に失敗しました。");
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    request: {
      handler(newVal) {
        const { marketTrendFilter, bank, sortType } = newVal;
        if (marketTrendFilter !== null && bank !== null && sortType !== null) {
          this.loadMoreInfo(true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.loanTransactionLoan {
  position: relative;
  min-height: 200px;
  @include sp {
    min-height: 140px;
  }
  &__list {
    & > li {
      margin-bottom: 16px;
    }
  }
  &__more {
    padding: 12px 0 36px;
    text-align: center;
  }
}
</style>
