<template>
  <b-tag class="z-docs" size="is-medium">
    <a class="z-docs-text" :href="src" target="_blank">
      <p v-text="value.name" />
    </a>
  </b-tag>
</template>

<script>
export default {
  name: "ZDocs",
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  computed: {
    src() {
      return `${this.prefixStorageUrl}${this.value.path}/${this.value.id}`;
    },
    prefixStorageUrl() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_GCP_STORAGE_BUCKET}/`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-docs {
  background-color: #eeeeee;
}

.z-docs-text {
  font-size: 13px;
  font-weight: bold;
}
</style>
