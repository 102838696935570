<template>
  <div>
    <div class="selectRealEstate--container">
      <template v-for="item in selectedRealEstates">
        <div
          class="selectRealEstate--item"
          :class="{
            'selectRealEstate--item__investor':
              mode === $$selectRealEstates$modes().investor,
            'selectRealEstate--item__message':
              mode === $$selectRealEstates$modes().message,
          }"
          :key="item._source.id"
        >
          <template
            v-if="!$$selectRealEstates$isDefaultRealEstate(item._source.id)"
          >
            <span
              class="selectRealEstate--remove"
              @click="onRemove(item._source.id)"
            ></span>
          </template>
          <section class="selectRealEstate--section">
            <figure class="selectRealEstate--image">
              <z-image
                v-model="$$selectRealEstates$image(item._source.data)[0]"
              />
            </figure>
            <div class="selectRealEstate--detail">
              <div class="selectRealEstate--detail__type">
                {{
                  $$selectRealEstates$realEstateType(
                    item._source.data.realEstateType
                  )
                }}
              </div>
              <div class="selectRealEstate--detail__highlight">
                {{
                  $$selectRealEstates$priceCouponYieldRate(
                    item._source.data.price,
                    item._source.data.couponYieldRate
                  )
                }}
              </div>
              <div class="selectRealEstate--detail__address">
                {{
                  $$selectRealEstates$location(
                    item._source.data.prefecture,
                    item._source.data.city
                  )
                }}
              </div>
              <table class="selectRealEstate--table">
                <tr>
                  <td>建物面積</td>
                  <td>
                    {{
                      $$selectRealEstates$area(item._source.data.buildingArea)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>建物種別</td>
                  <td>
                    {{
                      $$selectRealEstates$buildingType(
                        item._source.data.buildingType
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>築年月</td>
                  <td>
                    {{
                      $$selectRealEstates$constructionDate(
                        item._source.data.constructionDate
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
            <a
              class="selectRealEstate--link"
              @click="$$selectRealEstates$routerRealEstate(item._source.id)"
            >
              詳しくみる
            </a>
          </section>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "selected-realEstate",
  mixins: [DisplayTextMixin],
  props: {
    mode: {
      type: Number, // 0: 絞り込み検索, 1: 添付不動産選択
      require: true,
    },
    selectedRealEstates: {
      type: Array,
      require: true,
    },
  },
  methods: {
    // 選択物件を削除
    async onRemove(id) {
      switch (this.mode) {
        case this.$$selectRealEstates$modes().investor: // 絞り込み検索
          this.$parent.$parent.$parent.$parent.filterByRealEstate.selectedRealEstate =
            null;
          break;
        case this.$$selectRealEstates$modes().message: // 添付不動産選択
          // デフォルトで不動産がセットされている状態からの削除は不可、追加のみ可能とする
          if (this.$$selectRealEstates$isDefaultRealEstate(id)) return;

          this.$parent.selectedRealEstates =
            this.$parent.selectedRealEstates.filter((item) => {
              return item._source.id !== id;
            });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.selectRealEstate {
  &--container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 12px;

    @include sp {
      margin: 0;
    }
  }

  &--item {
    position: relative;

    &__investor {
      width: 390px;
    }

    &__message {
      margin-bottom: 32px;
      width: 325px;

      @include sp {
        margin: 0 auto 32px;
      }
    }
  }

  @include sp {
    width: 100%;
  }

  &--section {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: 8px;
    color: $snackbar-color;
    overflow: hidden;
    padding: 16px 16px 24px 16px;

    @include sp {
      padding: 12px 12px 18px 12px;
    }
  }

  &--image {
    float: left;
    width: 94px;

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  &--detail {
    float: right;
    margin-bottom: 8px;
    width: calc(100% - 110px);

    @include sp {
      width: calc(100% - 106px);
    }

    &__type {
      font-size: 14px;
      overflow: hidden;

      @include sp {
        font-size: 12px;
      }
    }

    &__highlight {
      font-size: 14px;
      font-weight: bold;
      margin: 6px 0;

      @include sp {
        margin: 4px 0;
      }
    }

    &__address {
      font-size: 14px;
    }
  }

  &--table {
    font-size: 14px;
    width: 100%;

    tr {
      td {
        padding: 2px;

        &:nth-child(odd) {
          color: #777777;
          width: 64px;

          @include sp {
            width: 56px;
          }
        }
      }
    }

    @include sp {
      font-size: 12px;
    }
  }

  &--title {
    color: $primary;
    font-size: 12px;
    font-weight: bold;
    left: 8px;
    position: absolute;
    top: -20px;
  }

  &--link {
    color: $link;
    bottom: 6px;
    float: right;
    font-size: 14px;
    position: absolute;
    right: 16px;
    text-align: right;

    @include sp {
      bottom: 4px;
      font-size: 14px;
      right: 12px;
    }

    &:hover {
      color: $link;
    }
  }

  &--remove {
    background-color: $input-border-color;
    border-radius: 50%;
    height: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 18px;

    &:before,
    &:after {
      background-color: $tooltip-close-background-color;
      content: " ";
      height: 12px;
      left: 8px;
      position: absolute;
      top: 3px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
</style>
