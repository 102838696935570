<template>
  <div>
    <section>
      <ZHeader title="物件掲載管理" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="columns">
          <div class="column is-full">
            <h4 class="edit__subtitle2">エリア・沿線の選択</h4>
            <ZAreaLineFieldSet
              ref="areaLineFieldSet"
              v-model="filters"
              hideRequired
            />
            <div class="levels">
              <div class="level-right">
                <button class="button is-primary is-wide" @click="init">
                  検索する
                </button>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div class="levels">
          <div class="level-right count">
            該当件数
            <span class="count__number">{{ $$num$commaFormat(total) }}件</span>
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow">
            <RealtorRealEstateListFilter :filters="filters" @filter="init" />
          </div>
          <div class="column">
            <div class="realtor-setting-realtestae__con">
              <h4 class="list-title">物件一覧</h4>
              <div class="columns">
                <div class="column is-narrow">
                  <div class="buttons">
                    <router-link
                      :to="{
                        name: 'realtor-setting-realestates-detail',
                        params: { id: 'new' },
                      }"
                      class="button is-small is-primary z-button is-wide"
                      >新規物件登録</router-link
                    >
                  </div>
                </div>
                <div class="column">
                  <div class="levels">
                    <div class="level-right sort">
                      <label class="sort__title">並び替え</label>
                      <z-select
                        v-model="sortType"
                        :options="sortTypes"
                        @input="init"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <RealEstateList
                :isLoading="isLoading"
                :realEstates="realEstates"
              />
              <hr />
              <ZPagination
                :total="total"
                :perPage="perPage"
                :page.sync="page"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RealEstateList from "@/components/realtor/RealEstateList";
import RealtorRealEstateListFilter from "@/components/realtor/RealtorRealEstateListFilter";
import * as realestate from "@/modules/realtor/realestate";

export default {
  name: "realtor-setteing-realestates",
  components: {
    RealEstateList,
    RealtorRealEstateListFilter,
  },
  data: function () {
    return {
      isLoading: true,
      filters: realestate.createNewFilterType(),
      sortTypes: realestate.sortTypes,
      currentPage: 0,
      total: 0,
      page: 1,
      perPage: 25,
      sortType: 1,
      realEstates: [],
    };
  },
  computed: {},
  watch: {
    async page() {
      await this.init();
    },
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        // this.page = 1;
        let owner = null;
        if (this.$$user$permission.searchInvestors !== 99) {
          owner = this.me.user.company.id;
        }

        const rt = await realestate.searchByfilter(
          this.filters,
          this.sortType,
          this.page,
          this.perPage,
          owner
        );

        this.total = rt.total.value;
        this.realEstates = rt.hits;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    return this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.realtor-setting-realtestae {
  &__con {
    margin-left: 16px;
  }
}

// .consultant-warp {
//   margin: 0 10px;
//   @include mobile {
//     margin: 0;
//   }
// }
//
// .z-section {
//   @include md {
//     min-width: 800px;
//   }
// }
// .realestate {
//   &__list {
//     width: 100%;
//     height: 756px;
//     background-color: $mintrich-blank;
//   }
// }
.list-title {
  margin-bottom: 12px;
}

.count {
  margin-top: -25px;
  margin-bottom: 10px;
  &__number {
    font-size: 1.3rem;
    font-weight: bold;
    color: #f00;
    padding-left: 5px;
    line-height: 3px;
  }
}

.sort {
  &__title {
    margin: -28px 8px 0 0;
    font-weight: bold;
  }
}
// .content {
//   p:nth-child(2) {
//     font-size: 1.5em;
//   }
//   p:nth-child(3) {
//     font-size: 1.5em;
//   }
// }
</style>
