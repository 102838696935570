<template>
  <b-button
    class="more-button is-primary is-outlined is-rounded"
    type="is-primaly"
    :class="expanded ? 'is-expanded' : ''"
    @click="$emit('click', $event)"
  >
    <span class="more-button__label">{{ label }}</span>
  </b-button>
</template>

<script>
export default {
  name: "MoreButton",
  props: {
    callback: Function,
    label: {
      type: String,
      default: "",
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.more-button {
  min-width: 330px;
  height: 2.3em;
  border-color: $important;
  background-color: $important;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  @include sp {
    min-width: auto;
    width: 100%;
  }
  &.is-expanded {
    min-width: auto;
    width: 100%;
  }
  &__label {
    letter-spacing: -0.05em;
    line-height: 1;
  }
}
</style>
