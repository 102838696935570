<script>
export default {
  name: "Mixins-Rate",
  methods: {
    $$rete$rateFormat: function (rate, decimal = 2, suffix = "%") {
      if (rate === null) {
        return "-";
      }
      return (rate * 100).toFixed(decimal) + suffix;
    },
  },
};
</script>
