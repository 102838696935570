<template>
  <div>
    <router-view></router-view>
    <hr />
    <section class="section">
      <SearchForm v-model="searchFilter" />
      {{ searchFilter }}
      <b-button @click="onSearch"> 検索 </b-button>
      <hr />
      {{ prices }}
      <hr />
      <template v-for="(u, idx) in banks.data">
        <div :key="idx">
          {{ u }}<br />
          <hr />
        </div>
      </template>
      <hr />
      {{ rs.maxCount }}
      <hr />
      <template v-for="u in rs.data">
        <div :key="u._id">
          {{ u._id }}<br />
          <TransactionBox :realestate="u" type="loan" />
          <hr />
          <TransactionBox :realestate="u" type="realEstate" />
        </div>
      </template>
      <hr />
      {{ ycharts }}
      <hr />
      {{ pcharts }}
      <hr />
      <!--
      <template v-for="u in results">
        <div :key="u._id">
          {{ u._id }}<br />
          {{ u._source.user.uid }}<br />
          {{ u._source.user.user.data.latestScore.score }}<br />
          {{ u._source.data.purchase.buyingDt }}<br />
          {{ u._source.data.realEstateType }}<br />
          {{ u._source.data.buildingType }}<br />
          {{ u._source.data.couponYieldRate * 100 }}<br />
          {{ u._source.data.purchase.amount }}<br />
          {{ u._source.data.constructionDate }}<br />
          {{ u._source.data.prefecture }}<br />
          {{ u._source.data.city }}<br />
          {{ u._source.data.transports }}<br />
          <hr />
        </div>
      </template>
      -->
      <hr />
      <!--
        {{ results }}
      -->
      <hr />
    </section>
  </div>
</template>

<script>
// import RealEstateTransaction_v1 from "@/views/investor/markettrend_v1/parts/RealEstateTransaction";
// import LoanTransaction_v1 from "@/views/investor/markettrend_v1/parts/LoanTransaction";
// import RealEstateTransaction_v2 from "@/views/investor/markettrend_v2/parts/RealEstateTransaction";
// import LoanTransaction_v2 from "@/views/investor/markettrend_v2/parts/LoanTransaction";
import SearchForm from "@/views/investor/markettrend_v2/parts/SearchForm";
import TransactionBox from "@/views/investor/markettrend_v2/parts/TransactionBox";
// import SearchFormLabel from "@/views/investor/markettrend_v2/parts/SearchFormLabel";

import firebase from "@/plugins/firebase";
// import CitySelect from "@/components/parts/CitySelect";
import * as realestate from "@/modules/investor/realestate";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "admin-demo-transaction",
  data() {
    return {
      searchFilter: null,
      total: 0,
      results: [],
      pcharts: [],
      ycharts: [],
      prices: [],
      rs: {},
      banks: {},
    };
  },
  components: {
    // RealEstateTransaction_v1,
    // RealEstateTransaction_v2,
    // LoanTransaction_v1,
    // LoanTransaction_v2,
    SearchForm,
    TransactionBox,
    // SearchFormLabel
  },
  watch: {
    searchFilter: {
      async handler() {
        await this.onSearch();
      },
      deep: true,
    },
  },
  methods: {
    async onSearch() {
      const rs = await realestate.searchByfilter(this.searchFilter);
      this.results = rs.hits.hits;
      this.total = rs.hits.total.value;

      this.prices = await marketTrend.searchPriceCharts({
        marketTrendFilter: this.searchFilter,
        scoreRange: [
          {
            from: 0,
            to: 100,
          },
          {
            from: 100,
            to: 200,
          },
          {
            from: 200,
            to: 300,
          },
          {
            from: 300,
            to: 400,
          },
          {
            from: 400,
            to: 500,
          },
          {
            from: 500,
            to: 600,
          },
          {
            from: 600,
            to: 700,
          },
          {
            from: 700,
            to: 800,
          },
          {
            from: 800,
            to: 900,
          },
          {
            from: 900,
            to: 1000,
          },
        ],
        priceRange: [
          {
            from: 0,
            to: 20000000,
          },
          {
            from: 20000000,
            to: 30000000,
          },
          {
            from: 30000000,
            to: 40000000,
          },
          {
            from: 40000000,
            to: 50000000,
          },
          {
            from: 50000000,
            to: 100000000,
          },
          {
            from: 100000000,
            to: 200000000,
          },
          {
            from: 200000000,
            to: 300000000,
          },
          {
            from: 300000000,
            to: 400000000,
          },
          {
            from: 400000000,
            to: 500000000,
          },
          {
            from: 500000000,
            to: null,
          },
        ],
      });

      this.banks = await marketTrend.searchBankRank({
        marketTrendFilter: this.searchFilter,
      });

      this.banks = await marketTrend.searchBankRank({
        marketTrendFilter: this.searchFilter,
      });

      this.rs = await marketTrend.searchRealestes({
        marketTrendFilter: this.searchFilter,
        getNumber: 1,
        pagination: 0,
        sortType: 6,
        selectedFilter: {
          score: {
            from: null,
            to: null,
          },
          amount: {
            from: null,
            to: null,
          },
          couponYieldRate: {
            from: 0,
            to: null,
          },
        },
      });

      this.ycharts = await marketTrend.searchYieldCharts({
        marketTrendFilter: this.searchFilter,
        scoreRange: [
          {
            from: 0,
            to: 100,
          },
          {
            from: 100,
            to: 200,
          },
          {
            from: 200,
            to: 300,
          },
          {
            from: 300,
            to: 400,
          },
          {
            from: 400,
            to: 500,
          },
          {
            from: 500,
            to: 600,
          },
          {
            from: 600,
            to: 700,
          },
          {
            from: 700,
            to: 800,
          },
          {
            from: 800,
            to: 900,
          },
          {
            from: 900,
            to: null,
          },
        ],
        couponYieldRateRange: [
          {
            from: 0,
            to: 0.04,
          },
          {
            from: 0.04,
            to: 0.05,
          },
          {
            from: 0.05,
            to: 0.06,
          },
          {
            from: 0.06,
            to: 0.07,
          },
          {
            from: 0.07,
            to: 0.08,
          },
          {
            from: 0.08,
            to: 0.09,
          },
          {
            from: 0.09,
            to: 0.1,
          },
          {
            from: 0.1,
            to: 0.15,
          },
          {
            from: 0.15,
            to: 0.2,
          },
          {
            from: 0.2,
            to: null,
          },
        ],
      });

      this.pcharts = await marketTrend.searchPriceCharts({
        marketTrendFilter: this.searchFilter,
        scoreRange: [
          {
            from: 0,
            to: 100,
          },
          {
            from: 100,
            to: 200,
          },
          {
            from: 200,
            to: 300,
          },
          {
            from: 300,
            to: 400,
          },
          {
            from: 400,
            to: 500,
          },
          {
            from: 500,
            to: 600,
          },
          {
            from: 600,
            to: 700,
          },
          {
            from: 700,
            to: 800,
          },
          {
            from: 800,
            to: 900,
          },
          {
            from: 900,
            to: null,
          },
        ],
        priceRange: [
          {
            from: 0,
            to: 20000000,
          },
          {
            from: 20000000,
            to: 30000000,
          },
          {
            from: 30000000,
            to: 40000000,
          },
          {
            from: 40000000,
            to: 50000000,
          },
          {
            from: 50000000,
            to: 100000000,
          },
          {
            from: 100000000,
            to: 200000000,
          },
          {
            from: 200000000,
            to: 300000000,
          },
          {
            from: 300000000,
            to: 400000000,
          },
          {
            from: 400000000,
            to: 500000000,
          },
          {
            from: 500000000,
            to: null,
          },
        ],
      });
    },
  },
  created() {
    const filter = this.$store.state.investor.mtSearchFilter;
    if (filter) {
      this.searchFilter = this.$$cloneDeep(filter);
    } else {
      this.searchFilter = this.$$investor$getNewMarketTrendFilter(); // SearchFormで絞り込んだ検索条件
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
