import firebase from "@/plugins/firebase";
import {
  cloneDeep,
  //  defaultsDeep,
} from "lodash";

const Favorite = {
  realtor: null,
  type: 900, //未設定
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

export const createNewFavorite = (realtorId) => {
  const data = cloneDeep(Favorite);
  data.realtor = realtorId;
  return {
    id: null,
    data,
  };
};

const docRef = (owner) =>
  firebase.db
    .collection("users_v1")
    .doc(owner)
    .collection("favorite_realtors_v1");

const groupRef = () => firebase.db.collectionGroup("favorite_realtors_v1");

export const loads = async () => {
  const qs = await groupRef().orderBy("registerDt", "desc").get();

  const ls = [];
  qs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: doc.data(),
    });
  });

  return ls;
};

export const load = async (owner, realtorId) => {
  const doc = await docRef(owner).doc(realtorId).get();

  if (doc.exists) {
    return {
      id: doc.id,
      data: doc.data(),
    };
  } else {
    return createNewFavorite(realtorId);
  }
};

export const loadsByOwner = async (owner) => {
  const qs = await docRef(owner).orderBy("type").get();

  const ls = [];
  qs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: doc.data(),
    });
  });

  return ls;
};

export const save = async (uid, owner, favorite) => {
  const now = firebase.serverTimestamp();
  favorite.data.updatedBy = uid;
  favorite.data.updatedDt = now;
  if (favorite.id) {
    await docRef(owner).doc(favorite.id).set(favorite.data, {
      merge: true,
    });
  } else {
    favorite.data.createdBy = uid;
    favorite.data.registerDt = now;
    favorite.data.createdDt = now;
    await docRef(owner).doc(favorite.data.realtor).set(favorite.data, {
      merge: true,
    });
  }
};
