<template>
  <span class="z-icon">
    <component :is="svg" :width="width" :height="height" :style="style" />
  </span>
</template>

<script>
export default {
  name: "ZIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "inherit",
    },
  },
  computed: {
    svg() {
      return () => import(`@/assets/icons/${this.name}.svg`);
    },
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
        fill: this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
