<template>
  <div>
    <section class="section">
      <div class="container signup-warp">
        <h3 class="list-title">お申し込み</h3>
        <div class="signup__boxs">
          <div class="signup__box box-email">
            <div class="content has-text-centered">
              <div class="main-message">メールアドレスで登録</div>
            </div>
            <div class="z-input-vritical-warp input-mail">
              <z-input
                name="email"
                v-model="email"
                type="email"
                placeholder="メールアドレス"
                maxlength="512"
                hideRequired
                :validate="'required|email'"
                icon="email"
                isRequired
              >
              </z-input>
            </div>
            <div class="content signup__box__agreement-wrap">
              <span class="agreement-message">
                <a href="https://stockformer.com/terms" target="_blank"
                  >利用規約</a
                >/<a href="https://stockformer.com/privacy" target="_blank"
                  >プライバシーポリシー</a
                >
              </span>
            </div>
            <div class="buttons is-centered">
              <button
                id="signup-button"
                class="button is-primary is-rounded is-wide z-button is-small buttons__button"
                :disabled="$$validator$invalid"
                @click="signUp"
              >
                同意して「お申し込み」をする
              </button>
            </div>
            <div class="content has-text-centered">
              <div class="sub-message2">
                お申し込み後に「ご確認メール」を送信します。
              </div>
            </div>
          </div>
          <div
            class="is-divider-vertical signup-divider"
            data-content="OR"
          ></div>
          <div>
            <div class="signup__box box-sns">
              <div class="sns-login">
                <div class="main-message">SNSアカウントで登録</div>
                <div class="content signup__box__agreement-wrap">
                  <span class="agreement-message">
                    <a href="https://stockformer.com/terms" target="_blank"
                      >利用規約</a
                    >/<a href="https://stockformer.com/privacy" target="_blank"
                      >プライバシーポリシー</a
                    >
                  </span>
                  <div class="sub-message3">同意してSNSアカウントから登録</div>
                </div>
                <div class="sns-login__buttons">
                  <ZGoogleButton @click="signInWithGoogle" />
                </div>
                <div class="member-login">
                  すでに会員の方は、<router-link :to="{ name: 'auth-signin' }"
                    >こちら</router-link
                  >からログイン
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import SentMail from "@/mixins/investor/auth/SentMail";
import _ from "lodash";
import firebase, { GoogleAuthProvider } from "@/plugins/firebase";
import ZGoogleButton from "@/components/parts/ZGoogleButton.vue";

export default {
  name: "investor-auth-SignUp",
  components: {
    ZGoogleButton,
  },
  mixins: [SentMail],
  data() {
    return {
      agreement: true,
      email: "",
    };
  },
  async created() {
    const loadingComponent = this.$loading.open();
    try {
      const result = await firebase.auth().getRedirectResult();
      if (!result?.user) {
        firebase.auth().onAuthStateChanged((user) => {
          console.log("user", user);
          if (user && !user.isAnonymous) {
            window.location.href = "/";
          }
        });
        return;
      }

      const now = firebase.serverTimestamp();
      const email = result.user.email;
      const uid = result.user.uid;
      let user = await this.$$investor$loadUser(uid);

      console.log("user", user);
      if (!user?.data?.isActivate) {
        user = this.$$investor$getNewUser();
        user.id = uid;
        user.data.base.email = email;
        user.data.uid = uid;
        user.data.isInvestor = true;
        user.data.isActivate = true;
        user.data.updatedBy = uid;
        user.data.updatedDt = now;
        user.data.registerDt = now;
        user.data.createdBy = uid;
        user.data.createdDt = now;
        await this.$$investor$save(uid, user);

        if (this.$gtm) {
          await this.$gtm.trackEvent({
            event: "signup-with-google-su",
          });
        }
        window.location.href = "/investor/register";
      } else {
        user.id = uid;
        user.data.base.email = email;
        user.data.uid = uid;
        user.data.updatedBy = uid;
        user.data.updatedDt = now;
        await this.$$investor$save(uid, user);
        if (user.data.wasCompletedRegister) {
          window.location.href = "/";
        } else {
          window.location.href = "/investor/register";
        }
      }
    } catch (e) {
      this.$raven.captureException(e.message ? new Error(e.message) : e);
      this.$$toast$openError("サインアップに失敗しました");
    } finally {
      loadingComponent.close();
    }
  },
  methods: {
    async signInWithGoogle() {
      const loadingComponent = this.$loading.open();
      try {
        console.log("gtm", this.$gtm);
        const provider = new GoogleAuthProvider();
        await firebase.auth().signInWithRedirect(provider);
        // const email = gauth.user.email;
        // const uid = auth.uid;
        // const gauth = await firebase.auth().signInWithPopup(provider);
        // const gauth = await firebase.auth().signInWithRedirect(provider);
        // const auth = firebase.auth().currentUser;
        // const now = firebase.serverTimestamp();
        // const email = gauth.user.email;
        // const uid = auth.uid;
        // let user = await this.$$investor$loadUser(uid);

        // // if (!user?.data?.base?) {
        // if (!user?.data) {
        //   user = this.$$investor$getNewUser();
        //   user.id = uid;
        //   user.data.base.email = email;
        //   user.data.uid = uid;
        //   user.data.updatedBy = uid;
        //   user.data.updatedDt = now;
        //   user.data.registerDt = now;
        //   user.data.createdBy = uid;
        //   user.data.createdDt = now;
        //   await this.$$investor$save(uid, user);

        //   if (this.$gtm) {
        //     await this.$gtm.trackEvent({
        //       event: "signup-with-google-su", // Event type [default = 'interaction
        //       // category: 'Calculator',
        //       // action: 'click',
        //       // label: 'Home page SIP calculator',
        //       // value: 5000,
        //       // noninteraction: false, // Optional
        //     });
        //   }
        //   window.location.href = "/investor/register";
        // } else {
        //   user.id = uid;
        //   user.data.base.email = email;
        //   user.data.uid = uid;
        //   user.data.isInvestor = true;
        //   user.data.isActivate = true;
        //   user.data.updatedBy = uid;
        //   user.data.updatedDt = now;
        //   await this.$$investor$save(uid, user);
        //   window.location.href = "/investor/";
        // }
      } catch (e) {
        if (e.code == "auth/popup-blocked") {
          this.$$toast$openError(
            "サインアップのポップアップがブロックされました。"
          );
          this.$raven.captureException(new Error(e.message));
        } else {
          this.$raven.captureException(e.message ? new Error(e.message) : e);
          this.$$toast$openError("サインアップに失敗しました");
        }
      } finally {
        loadingComponent.close();
      }
    },
    async signUp() {
      const loadingComponent = this.$loading.open();
      try {
        const self = this;
        const email = this.email;
        let analyticsParam = "";

        const existsAccount = await firebase.db
          .collection("users_v1")
          .where("base.email", "==", email)
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot.empty);
            return !querySnapshot.empty;
          });

        if (existsAccount) {
          this.$$toast$openSuccess("既に登録されているメールアドレスです");
          this.$router.push({
            name: "auth-signin",
            query: {
              email,
            },
          });
          return;
        }
        //メールに解析用パラメータを付与
        var urlParam = location.search.substr(1);
        const queryObj = _.reduce(
          urlParam.split("&"),
          function (obj, v) {
            var pair = v.split("=");
            obj[pair[0].toLowerCase()] = decodeURIComponent(pair[1]);
            return obj;
          },
          {}
        );
        if (queryObj.hasOwnProperty("a8")) {
          analyticsParam = queryObj.a8;
        }

        await this.sendMail(email, analyticsParam).then(function () {
          self.$router.push({
            name: "auth-investor-sentmail",
            query: Object.assign({ email: email }, self.$route.query),
          });
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("確認メールの送信に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.signup-divider {
  background-color: white;
  @include sp {
    margin: 12px 14px;
  }
}
.sns-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__buttons {
    height: 100%;
    margin-bottom: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
  }
}

.signup-warp {
  width: 680px;
  background-color: white;
  @include mobile {
    width: 100%;
  }
}
.signup {
  width: 100%;
  &__boxs {
    // background-color: #707070;
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }
  }
  &__box {
    &.box-email {
      padding: 18px 0px 18px 24px;
      // width: 420px;
      @include sp {
        padding: 12px;
      }
    }
    &.box-sns {
      padding: 18px 24px 18px 0;
      @include sp {
        padding: 12px 12px 36px;
      }
    }
    width: 100%;
    height: 100%;
    background-color: white;
    @include sp {
      padding: 20px 15px 20px;
    }
    .main-message {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      @include sp {
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }
    .sub-message1 {
      margin-top: 12px;
      font-size: 12px;
      font-weight: bold;
    }
    .sub-message2 {
      //margin-top: 4px;
      margin-bottom: 12px;
      font-size: 12px;
      // font-weight: bold;
      @include sp {
        font-size: 10px;
      }
    }
    .sub-message3 {
      width: 100%;
      text-align: center;
      font-size: 10px;
      @include sp {
        font-size: 10px;
      }
    }
    .agreement-message {
      font-size: 10px;
      vertical-align: 3px;
      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .button {
      line-height: 10px;
      @include mobile {
        margin: 0 auto;
      }
    }
    .member-login {
      font-size: 10px;
      text-align: right;
      margin-top: 10px;
    }
    &__agreement-wrap {
      margin-top: 18px;
      margin-bottom: 10px;
      text-align: center;
      @include sp {
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
  }
}

.br-hide {
  display: block;
  @include mobile {
    display: none;
  }
}
.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
.agreement-check {
  font-weight: bold;
}

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0257bf;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  @include sp {
    padding: 10px 0px;
  }
}

.signup-divider {
  // margin: 0px -4px;
  @include sp {
    margin: 0px 16px;
  }
}

.b-checkbox {
  font-size: 14px;
}

.buttons {
  &__button {
    background-color: #0257bf;
  }
}

.input-mail {
  height: 50px;
}
</style>
