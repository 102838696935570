// import investorType from "@/types/investorType";

export default {
  namespaced: true,
  state: {
    mtSearchFilter: null,
    mtRealEstateFilter: {
      tabIndex: 0,
      sortType: 6,
      filter: null,
    },
    mtLoanFilter: {
      pageIndex: 0,
      sortType: 6,
      filter: null, // bank
    },
  },
  mutations: {
    setMtSearchFilter(state, filter) {
      if (filter) {
        state.mtSearchFilter = filter;
      }
    },
    setMtRealEstateFilter(state, { tabIndex, sortType, filter }) {
      state.mtRealEstateFilter.tabIndex = tabIndex;
      state.mtRealEstateFilter.sortType = sortType;
      state.mtRealEstateFilter.filter = filter;
    },
    setMtLoanFilter(state, { sortType, pageIndex, filter }) {
      state.mtLoanFilter.sortType = sortType;
      state.mtLoanFilter.pageIndex = pageIndex;
      state.mtLoanFilter.filter = filter;
    },
  },
};
