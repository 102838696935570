<template>
  <div>
    <router-view></router-view>
    <div style="background-color: #0590e8">
      <section class="section">
        <h1 class="title is-1">ZScoreProgress</h1>

        <ZScoreProgress :score="score" :rank="rank">
          <template slot="footer">
            <p>フッター要素をslotで指定できます</p>
          </template>
        </ZScoreProgress>

        <p>中バージョン</p>
        <ZScoreProgress
          :score="score"
          :rank="rank"
          :transitionDuration="0"
          scaleType="medium"
        />

        <p>小バージョン</p>
        <ZScoreProgress
          :score="score"
          :rank="rank"
          :transitionDuration="0"
          scaleType="small"
        />

        <ZInput
          label="スコア"
          type="number"
          name="score"
          v-model.number="score"
        />

        <ZSelect
          label="ランク"
          :options="this.$$ks.ranks"
          v-model="rank"
          isRequired
        />
      </section>
      <section class="section">
        <h1 class="title is-1">ZBalanceTable</h1>

        <ZBalanceTable :bs="bs" />

        <ZInput
          label="通常収入"
          type="number"
          name="bl.cash"
          v-model.number="bs.assets.cash.sum"
        />

        <ZInput
          label="投資収入(証券)"
          type="number"
          name="bl.investment"
          v-model.number="bs.assets.security.sum"
        />

        <ZInput
          label="投資収入(保険)"
          type="number"
          name="bl.investment"
          v-model.number="bs.assets.insurance.sum"
        />

        <ZInput
          label="不動産収入"
          type="number"
          name="bl.buildings"
          v-model.number="bs.assets.realEstate.sum"
        />

        <ZInput
          label="融資されている額"
          type="number"
          name="bl.longTermDebt"
          v-model.number="bs.liabilities.sum"
        />

        <ZInput
          label="純資産額"
          type="number"
          name="bl.equity"
          v-model.number="bs.netAssets.sum"
        />
      </section>
      <section class="section">
        <h1 class="title is-1">ZIncomeBar</h1>

        <ZIncomeBar :pl="pl" />

        <ZInput
          label="合計"
          type="number"
          name="pl.sum"
          v-model.number="pl.sum"
        />

        <ZInput
          label="収入内訳1"
          type="number"
          name="pl.salary"
          v-model.number="pl.salary"
        />

        <ZInput
          label="収入内訳2"
          type="number"
          name="pl.dividend"
          v-model.number="pl.dividend"
        />

        <ZInput
          label="収入内訳3"
          type="number"
          name="pl.pension"
          v-model.number="pl.pension"
        />

        <ZInput
          label="収入内訳4"
          type="number"
          name="pl.realEstate"
          v-model.number="pl.realEstate"
        />

        <ZInput
          label="その他"
          type="number"
          name="pl.others"
          v-model.number="pl.others"
        />
      </section>
      <section class="section">
        <h1 class="title is-1">ZAnimatedPriceLabel</h1>

        <ZAnimatedPriceLabel :value="price" class="z-incomeBar__value" />

        <ZInput
          label="値"
          type="number"
          name="pl.price"
          v-model.number="price"
        />
      </section>
    </div>
  </div>
</template>

<script>
import ZScoreProgress from "@/components/parts/ZScoreProgress.vue";
import ZBalanceTable from "@/components/parts/ZBalanceTable.vue";
import ZIncomeBar from "@/components/parts/ZIncomeBar.vue";
import ZAnimatedPriceLabel from "@/components/parts/ZAnimatedPriceLabel.vue";

export default {
  name: "admin-demo-summary-parts",
  components: {
    ZScoreProgress,
    ZBalanceTable,
    ZIncomeBar,
    ZAnimatedPriceLabel,
  },
  data: function () {
    return {
      score: 600,
      rank: 4,
      bs: {
        assets: {
          sum: 0,
          cash: {
            sum: 0,
          },
          security: {
            sum: 0,
          },
          insurance: {
            sum: 0,
          },
          realEstate: {
            sum: 0,
          },
        },
        liabilities: {
          sum: 0,
        },
        netAssets: {
          sum: 0,
        },
      },
      pl: {
        sum: 6000,
        salary: 2000,
        dividend: 1000,
        pension: 1000,
        realEstate: 1000,
        others: 1000,
      },
      price: 10000,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
