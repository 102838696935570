<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <b-tabs :animated="false" size="is-small" v-model="activeTab">
        <b-tab-item label="沿線">
          <b-tabs :animated="false" size="is-small" v-model="activeTabSub">
            <b-tab-item label="沿線">
              <button @click="onClickLoadLines" class="button">LOAD</button>
              <hr />
              <table>
                <thead>
                  <tr>
                    <th>line_cd</th>
                    <th>line_name</th>
                    <th>sf_line_cds</th>
                    <th>P1</th>
                    <th>P2</th>
                    <th>P3</th>
                    <th>P4</th>
                    <th>P5</th>
                    <th>P6</th>
                    <th>P7</th>
                    <th>P8</th>
                    <th>P9</th>
                    <th>P10</th>
                    <th>PrefectureCodes</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="l in lines">
                    <tr :key="l.line.line_cd">
                      <td>
                        {{ l.line.line_cd }}
                      </td>
                      <td>
                        {{ l.line.line_name }}
                      </td>
                      <td>
                        {{ l.sf_line_cds }}
                      </td>
                      <template v-if="l.prefectureCodes">
                        <template v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
                          <td :key="i">
                            {{ prefectureCodeText(l.prefectureCodes[i]) }}
                          </td>
                        </template>
                      </template>
                      <template v-else>
                        <template v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
                          <td :key="i"></td>
                        </template>
                      </template>
                      <td>
                        {{ l.prefectureCodes }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-tab-item>
            <b-tab-item label="駅">
              <button @click="onClickLoadStations" class="button">LOAD</button>
              <hr />
              <table>
                <thead>
                  <tr>
                    <th>line_cd</th>
                    <th>station_cd</th>
                    <th>station_name</th>
                    <th>sf_line_cd</th>
                    <th>sf_station_cd</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="l in stations">
                    <tr :key="l.station.station_cd">
                      <td>
                        {{ l.station.line_cd }}
                      </td>
                      <td>
                        {{ l.station.station_cd }}
                      </td>
                      <td>
                        {{ l.station.station_name }}
                      </td>
                      <td>
                        {{ l.sf_line_cd }}
                      </td>
                      <td>
                        {{ l.sf_station_cd }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-tab-item>
            <b-tab-item label="生成">
              <button @click="execAll" class="button">ALL</button>
              <hr />
              <br />
              <button @click="execLine" class="button">LINES</button>
              <br />
              <button @click="execStations" class="button">STATIONS</button>
              <br />
              <button @click="execStations2" class="button">
                STATIONの紐付け
              </button>
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import * as lineModule from "@/modules/line/line";

// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

// const sleep = function(msec) {
//   return new Promise(function(resolve) {
//     setTimeout(function() {
//       resolve();
//     }, msec);
//   });
// };

const files = [
  "veki-aa",
  "veki-ab",
  "veki-ac",
  "veki-ad",
  "veki-ae",
  "veki-af",
  "veki-ag",
  "veki-ah",
  "veki-ai",
  "veki-aj",
  "veki-ak",
];

export default {
  name: "admin-settings-area",
  data: function () {
    return {
      activeTab: 0,
      activeTabSub: 0,
      isLoading: true,
      lines: [],
      stations: [],
    };
  },
  methods: {
    async onClickLoadLines() {
      const loadingComponent = this.$loading.open();
      try {
        const lines = await lineModule.loads();
        // console.log(lines);
        this.lines = lines;
        // this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickLoadStations() {
      const loadingComponent = this.$loading.open();
      try {
        this.stations = [];
        const stations = await lineModule.loadStations();
        console.log(stations);
        for (const s of stations) {
          if (!s.station) {
            console.log(s);
          }
          this.stations.push(s);
        }
        // this.stations = stations;
        // console.log(stations);
        // const sts = [];
        // for (const st of stations) {
        //   const ln = await lineModule.load(st.lineCode);
        //   sts.push({
        //     station: st,
        //     line: ln;
        //   });
        // }
        // console.log(sts);
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    prefectureCodeText(code) {
      return this.$$ks.getPrefecture(code).text;
    },
    async execAll() {
      const loadingComponent = this.$loading.open();
      try {
        await this.execLine();
        // await firebase.functions(
        //   "v2-area-lines-lines_rains_stockformer",
        //   {},
        //   520000
        // );

        await this.execStations();
        // for (const f of files) {
        //   console.log("file:" + f);
        //   await firebase.functions(
        //     "v2-area-lines-stations_rains_stockformer",
        //     {
        //       fileName: f
        //     },
        //     520000
        //   );
        // }

        await this.execStations2();
        // await firebase.functions(
        //   "v2-area-lines-stations_sf_rains",
        //   {
        //     fileName: "sf_rains_station_dev.csv"
        //   },
        //   520000
        // );
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async execStations2() {
      const loadingComponent = this.$loading.open();
      try {
        await firebase.functions(
          "v2-area-lines-stations_sf_rains",
          {
            fileName: "sf_rains_station_dev.csv",
          },
          520000
        );
        await firebase.functions(
          "v2-area-lines-stations_sf_rains",
          {
            fileName: "sf_rains_station_prd.csv",
          },
          520000
        );
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async execStations() {
      const loadingComponent = this.$loading.open();
      try {
        for (const f of files) {
          console.log("file:" + f);
          await firebase.functions(
            "v2-area-lines-stations_rains_stockformer",
            {
              fileName: f,
            },
            520000
          );
        }
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async execLine() {
      const loadingComponent = this.$loading.open();
      try {
        await firebase.functions(
          "v2-area-lines-lines_rains_stockformer",
          {},
          520000
        );
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = false;
      return;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
