<script>
import firebase from "@/plugins/firebase";
export default {
  name: "VersionsMixin",
  computed: {
    version: function () {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    checkVersion: async function () {
      try {
        if (this.version) {
          const doc = await firebase.db
            .collection("versions_v1")
            .doc(this.version)
            .get();

          if (doc.exists) {
            const ver = doc.data();
            if (!ver.isActivate) {
              console.log("RELOAD:" + this.version);
              location.reload(true);
            } else {
              console.log(
                "NOT RELOAD, because isActivate is true.version:" + this.version
              );
            }
          } else {
            console.log("Can not get version:" + this.version);
          }
        }
      } catch (error) {
        console.error(error);
        // this.$$error$catchLoadError(error);
      }
    },
  },
  async created() {
    const self = this;
    await self.checkVersion();
    setInterval(async () => {
      await self.checkVersion();
    }, 300000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
