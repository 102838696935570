<template>
  <div class="z-switch">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
      :hideRequired="hideRequired"
      :isShort="isShort"
    >
      <div class="block">
        <b-switch :name="internalName" v-model="internalValue" type="is-info">
          <slot />
        </b-switch>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZSwitch",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Boolean],
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.block {
  margin-top: 12px;
}
</style>
