var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.realEstate.data.buildingUse !== 2)?[_c('div',{staticClass:"titlebar"},[_c('div',{staticClass:"titlebar__inner"},[_c('h2',{staticClass:"titlebar__title"},[_vm._v(" "+_vm._s(_vm.titleFix(_vm.count, "過去投資履歴"))+" ")])])]),_c('div',{staticClass:"rsOverview"},[_c('h3',{staticClass:"rsOverview__title"},[_vm._v("物件概要")]),_c('div',{staticClass:"rsOverview__sub"},[_c('div',{staticClass:"rsOverview__sub__type"},[_vm._v(" "+_vm._s(_vm.$$ks.realEstateType(_vm.realEstate.data.realEstateType).text)+" ")]),_c('div',{staticClass:"rsOverview__sub__buyingDt"},[_vm._v(" 購入年月 "+_vm._s(_vm.$$calender$yearMonthFormat(_vm.realEstate.data.purchase.buyingDt))+" ")])]),_c('div',{staticClass:"columns rsOverview__main"},[_c('div',{staticClass:"column col-wrap rsOverview__main__price-and-coupon-yield-rate"},[_vm._m(0),_c('div',{staticClass:"rsOverview__prices"},_vm._l((_vm.prices),function(price,index){return _c('div',{key:index,staticClass:"rsOverview__prices__value"},[_vm._v(" "+_vm._s(price.text)+" ")])}),0),_c('div',{staticClass:"rsOverview__graph"},_vm._l((10),function(i,index){return _c('div',{key:index,staticClass:"rsOverview__graph__box",class:{
                'rsOverview__graph__box-price': _vm.purchasePrice === i,
              }})}),0),_vm._m(1),_c('div',{staticClass:"rsOverview__coupon-yield-rates"},_vm._l((_vm.couponYieldRates),function(couponYieldRate,index){return _c('div',{key:index,staticClass:"rsOverview__coupon-yield-rates__value"},[_vm._v(" "+_vm._s(couponYieldRate.text)+" ")])}),0),_c('div',{staticClass:"rsOverview__graph"},_vm._l((10),function(i,index){return _c('div',{key:index,staticClass:"rsOverview__graph__box",class:{
                'rsOverview__graph__box-coupon-yield-rate':
                  _vm.couponYieldRate === i,
              }})}),0)]),_c('div',{staticClass:"column col-wrap rsOverview__detail"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_vm._m(2),_c('dd',[_vm._v(" "+_vm._s(_vm.$$ks.getPrefecture(_vm.realEstate.data.prefecture).text)+" "+_vm._s(_vm.cityInfo)+" ")])]),_c('dl',{staticClass:"rsOverview__labelDl"},[_vm._m(3),_c('dd',[_c('ul',_vm._l((_vm.transports),function(transport,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(transport)+" ")])}),0)])]),_c('div',{staticClass:"columns columns--noGap is-desktop"},[_c('div',{staticClass:"column col-wrap"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 建物種別 "),_c('z-help-icon',{attrs:{"help":"建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など","onlyPC":""}})],1)]),(_vm.realEstate.data.buildingType)?[_c('dd',[_vm._v(" "+_vm._s(_vm.$$ks.getTypeOfBuildingType(_vm.realEstate.data.buildingType) .text)+" ")])]:[_c('dd',[_vm._v("-")])]],2)]),_c('div',{staticClass:"column col-wrap"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_vm._m(4),_c('dd',[(
                      _vm.realEstate.data.realEstateType === 10 ||
                      _vm.realEstate.data.realEstateType === 7
                    )?[_c('span',[_vm._v("-")])]:[_vm._v(" "+_vm._s(_vm.$$calender$yearMonthFormat( _vm.realEstate.data.constructionDate ))+"(築"+_vm._s(_vm.$$realEstate$calcYearOfConstruction( _vm.realEstate.data.constructionDate ))+"年) ")]],2)])])]),_c('div',{staticClass:"columns columns--noGap is-desktop"},[_c('div',{staticClass:"column col-wrap"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_vm._m(5),(_vm.realEstate.data.buildingArea)?[_c('dd',[_vm._v(_vm._s(_vm.realEstate.data.buildingArea)+"㎡")])]:[_c('dd',[_vm._v("-")])]],2)]),_c('div',{staticClass:"column columns--noGap col-wrap"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 階数 "),_c('z-help-icon',{attrs:{"help":"建物の階数、及び物件の階数。","onlyPC":""}})],1)]),(_vm.realEstate.data.numberOfFloors)?[_c('dd',[_vm._v(" "+_vm._s(_vm.realEstate.data.numberOfFloors)+" ")])]:[_c('dd',[_vm._v("-")])]],2)])]),_c('div',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column col-wrap"},[_c('dl',{staticClass:"rsOverview__labelDl"},[_vm._m(6),_c('dd',[_vm._v(_vm._s(_vm.realEstate.data.saleSetting.landArea)+"㎡")])])])])])])])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"rsOverview__borderDl"},[_c('dt',[_vm._v("購入価格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"rsOverview__borderDl"},[_c('dt',[_vm._v("表面利回り")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 所在地 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 交通 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 築年月 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 建物面積 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('span',{staticClass:"rsOverview__labelDl__label"},[_vm._v(" 土地面積 ")])])
}]

export { render, staticRenderFns }