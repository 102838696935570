<template>
  <div ref="me">
    <svg :viewbox="viewbox" :width="width" :height="height">
      <a href="#" @click="onClick('assets')">
        <rect
          x="0"
          y="0"
          width="50%"
          :height="perOfAssets + '%'"
          fill="blue"
          stroke-width="1"
          :class="{ active: active }"
        />
        <text
          x="25%"
          :y="perOfAssets / 2"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          資産
        </text>
        <text
          x="25%"
          :y="perOfAssets / 2 + 18"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          {{ value.assets.sum }}万円
        </text>
      </a>
      <a href="#" @click="onClick('liabilities')">
        <rect
          x="50%"
          y="0"
          width="50%"
          :height="perOfLiabilities"
          fill="red"
          stroke-width="1"
        />
        <text
          x="75%"
          :y="perOfLiabilities / 2"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          負債
        </text>
        <text
          x="75%"
          :y="perOfLiabilities / 2 + 18"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          {{ value.liabilities.sum }}万円
        </text>
      </a>
      <a @click="onClick('netAssets')">
        <rect
          x="50%"
          :y="perOfLiabilities"
          width="50%"
          :height="perOfNetAssets"
          fill="green"
          stroke-width="1"
        />
        <text
          x="75%"
          :y="perOfLiabilities + perOfNetAssets / 2"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          純資産
        </text>
        <text
          x="75%"
          :y="perOfLiabilities + perOfNetAssets / 2 + 18"
          text-anchor="middle"
          font-size="16px"
          fill="white"
        >
          {{ value.netAssets.sum }}万円
        </text>
      </a>
    </svg>
  </div>
</template>

<script>
import ChartMixin from "./ChartMixin";

export default {
  name: "PLChart",
  mixins: [ChartMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
    height: Number,
    width: Number,
  },
  data: function () {
    return {
      active: false,
    };
  },
  methods: {
    onClick: function (id) {
      this.active = !this.active;
      this.$emit("selected", { id: id });
    },
  },
  computed: {
    viewbox: function () {
      return "0 0 " + this.width + " " + this.height;
    },
    perOfAssets: function () {
      if (this.value) {
        return this.height;
      } else {
        return 0;
      }
    },
    perOfLiabilities: function () {
      if (this.value) {
        const sum = this.value.sum;
        const liabilities = this.value.liabilities;
        return (liabilities.sum / sum) * this.height;
      } else {
        return 0;
      }
    },
    perOfNetAssets: function () {
      if (this.value) {
        const sum = this.value.sum;
        const netAssets = this.value.netAssets;
        return (netAssets.sum / sum) * this.height;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
