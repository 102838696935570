<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
import realEstateType from "@/types/realEstateType";
import kubuns from "@/kubuns/kubuns";

import { round } from "lodash";
const type = realEstateType.type;

const countPaymentPeriods = function (paymentPeriods) {
  return paymentPeriods.year * 12 + paymentPeriods.month;
};
export default {
  name: "Mixins-realEstate",
  methods: {
    $$realEstate$getNewImage: function () {
      return this.$$cloneDeep(type.image);
    },
    $$realEstate$getNewTransport: function () {
      return this.$$cloneDeep(type.transport);
    },
    $$realEstate$getNewRealEstate: function (uid) {
      const r = this.$$cloneDeep(type.realEstate);
      r.owner = uid;
      return {
        id: null,
        data: r,
      };
    },
    $$realEstate$getSaleSettingDetail: function () {
      return this.$$cloneDeep(type.saleSettingDetail);
    },
    //async $$realEstate$bankFormt(bankCd, branchCd) {
    //  //銀行の文字列生成
    //  const bank = await this.$$bank$getInfOfBank(bankCd);
    //  const branch = await this.$$bank$getInfOfBranch(branchCd);
    //  return bank.bank_name + " " + branch.branch_name;
    //},
    async $$realEstate$transportFormt(t) {
      //駅から何分というの文字列の生成  単独
      const ln = await this.$$line$load(t.line);
      const sn = await this.$$station$load(t.station);
      return ln.line_name + " " + sn.station_name + " " + t.elapsedTime + "分";
    },
    async $$realEstate$transportListFormt(transports) {
      //駅から何分というの文字列の生成 配列
      const ts = [];
      for (const t of transports) {
        ts.push(this.$$realEstate$transportFormt(t));
      }
      return Promise.all(ts);
    },
    async $$realEstate$overseasTransportFormt(t) {
      //駅から何分というの文字列の生成(海外物件用)  単独
      if (t.elapsedTime || t.line || t.station) {
        if (t.elapsedTime) {
          return (
            this.$$realEstate$checkNull(t.line) +
            " " +
            this.$$realEstate$checkNull(t.station) +
            " " +
            t.elapsedTime +
            "分"
          );
        } else {
          return (
            this.$$realEstate$checkNull(t.line) +
            " " +
            this.$$realEstate$checkNull(t.station)
          );
        }
      } else {
        return "-";
      }
    },
    async $$realEstate$overseasTransportListFormt(transports) {
      //駅から何分というの文字列の生成(海外物件用) 配列
      const ts = [];
      for (const t of transports) {
        ts.push(this.$$realEstate$overseasTransportFormt(t));
      }
      return Promise.all(ts);
    },
    $$realEstate$interestTypeFormt(interestType) {
      //金利タイプ/年月の表示文字列 生成
      if (interestType) {
        let str = "";
        str += kubuns.interestType(interestType.type).text + "金利 ";
        if (interestType.type === 1) {
          str +=
            interestType.interestYearMonth.year +
            "年" +
            interestType.interestYearMonth.month +
            "ヶ月";
        }
        return str;
      } else {
        return "";
      }
    },
    $$realEstate$calcYield: function (annualEstimatedIncome, amount) {
      // 年利回り =  年間想定収入 / 売却希望金額
      if (annualEstimatedIncome && amount) {
        return round(annualEstimatedIncome / amount, 4);
      } else {
        return 0;
      }
    },
    $$realEstate$calcYieldPerYear: function (
      annualEstimatedIncome,
      askingPrice
    ) {
      // 年利回り =  年間想定収入 / 売却希望金額
      if (annualEstimatedIncome && askingPrice) {
        return annualEstimatedIncome / askingPrice;
      } else {
        return 0;
      }
    },
    $$realEstate$calcStockByRealEstate: function (realEstate, debt) {
      // Stock計算
      const stock =
        realEstate.data.purchase.amount -
        (realEstate.data.purchase.loan.amount - debt.outstandingDebt);
      return stock;
    },
    $$realEstate$calcDebtSummary: function (loan) {
      // 債務のサマリー
      const debt = this.$$realEstate$calcRepaymentAmonts(loan);
      const amountInterestRepaidPerYear =
        this.$$realEstate$calcAmountInterestRepaidPerYear(debt);
      const amountPrincipalRepaidPerYear =
        this.$$realEstate$calcAmountPrincipalRepaidPerYear(debt);
      const outstandingDebt = this.$$realEstate$calcOutstandingDebt(debt);

      return {
        debt: debt,
        amountInterestRepaidPerYear: amountInterestRepaidPerYear, //年返済額（利息）
        amountPrincipalRepaidPerYear: amountPrincipalRepaidPerYear, // 年返済額（元本）
        outstandingDebt: outstandingDebt, //残債
      };
    },
    $$realEstate$calcOutstandingDebt: function (table) {
      // 残債
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;

      if (table.table) {
        const r = table.table.find((r) => r.year === year && r.month === month);
        return r ? r.amount : 0;
      } else {
        return 0;
      }
    },
    $$realEstate$calcAmountInterestRepaidPerYear: function (table) {
      //年間支払利息
      const now = new Date();
      const year = now.getFullYear();
      if (table.table) {
        return table.table
          .filter((r) => r.year === year)
          .reduce((sum, r) => sum + r.interest, 0);
      } else {
        return 0;
      }
    },
    $$realEstate$calcAmountPrincipalRepaidPerYear: function (table) {
      // 元本返済額
      const now = new Date();
      const year = now.getFullYear();
      if (table.table) {
        return table.table
          .filter((r) => r.year === year)
          .reduce((sum, r) => sum + (r.repaymentAmount - r.interest), 0);
      } else {
        return 0;
      }
    },
    $$realEstate$calcRepaymentAmonts: function (loan) {
      // 返済額
      if (loan.interestRate <= 0) {
        return {
          interestRatePerMonth: 0,
          repaymentAmountOfMonth: 0,
          table: [],
        };
      }
      if (loan.method === 1) {
        // 1:元利均等
        // 月利：金利(年) / 12

        const interestRatePerMonth =
          Math.round((loan.interestRate / 12) * 100000) / 100000;

        // 毎月の返済額：( 1 / ( ( 1 + 月利 ) ^ 返済期間 - 1 ) + 1 ) * 融資金額 * 月利
        const repaymentAmountOfMonth = Math.round(
          (1 /
            ((1 + interestRatePerMonth) **
              countPaymentPeriods(loan.paymentPeriods) -
              1) +
            1) *
            loan.amount *
            interestRatePerMonth
        );

        const table = [];
        let lastAmount = loan.amount;
        let year = loan.executeDate.year;
        let month = loan.executeDate.month;

        for (
          let cnt = 0;
          cnt < countPaymentPeriods(loan.paymentPeriods);
          cnt++
        ) {
          const amount = lastAmount;
          const interest = Math.round(lastAmount * interestRatePerMonth);
          const repaymentAmount = repaymentAmountOfMonth;
          lastAmount = lastAmount + interest - repaymentAmount;
          if (lastAmount < 0) lastAmount = 0;

          table.push({
            year: year,
            month: month,
            amount: amount,
            interest: interest,
            repaymentAmount: repaymentAmount,
            lastAmount: lastAmount,
          });

          month++;
          if (month > 12) {
            year++;
            month = 1;
          }
        }

        return {
          interestRatePerMonth: interestRatePerMonth,
          repaymentAmountOfMonth: repaymentAmountOfMonth,
          table: table,
        };
      } else if (loan.method === 2) {
        // 2:元金均等
        // 月利：金利(年) / 12
        const interestRatePerMonth =
          Math.round((loan.interestRate / 12) * 100000) / 100000;

        // 毎月の元本からの返済額：融資金額 / 返済期間(月)
        const repaymentAmountOfMonth = Math.round(
          loan.amount / countPaymentPeriods(loan.paymentPeriods)
        );

        const table = [];
        let lastAmount = loan.amount;
        let year = loan.executeDate.year;
        let month = loan.executeDate.month;

        for (
          let cnt = 0;
          cnt < countPaymentPeriods(loan.paymentPeriods);
          cnt++
        ) {
          const amount = lastAmount;
          const interest = Math.round(lastAmount * interestRatePerMonth);
          const repaymentAmount = interest + repaymentAmountOfMonth;
          lastAmount = lastAmount + interest - repaymentAmount;
          if (lastAmount < 0) lastAmount = 0;

          table.push({
            year: year,
            month: month,
            amount: amount,
            interest: interest,
            repaymentAmount: repaymentAmount,
            lastAmount: lastAmount,
          });

          month++;
          if (month > 12) {
            year++;
            month = 1;
          }
        }

        return {
          interestRatePerMonth: interestRatePerMonth,
          repaymentAmountOfMonth: repaymentAmountOfMonth,
          table: table,
        };
      } else {
        return {};
      }
    },
    async $$realEstate$countBuyingNeeds(realEstate, askingPrice) {
      //買いニーズの件数
      //{count:999 } というJsonで返ってきます。
      const r = this.$$cloneDeep(realEstate);
      r.data.saleSetting.askingPrice = askingPrice; // 売却希望金額
      const ret = await firebase.functions("calcCountBuyingNeeds", {
        realEstate: realEstate,
      });
      return ret.data;
    },
    async $$realEstate$saveSaleSettingOfRealEstate(
      uid,
      realEstate,
      askingPrice
    ) {
      const now = firebase.now();
      realEstate.data.saleSetting.askingPrice = askingPrice; // 売却希望金額
      realEstate.data.saleSetting.saleSettingDt = now; // 売却希望設定日
      realEstate.data.saleSetting.wasCompletedSaleSetting = true;
      realEstate.data.saleSetting.couponYieldRate =
        this.$$realEstate$calcYieldPerYear(
          realEstate.data.annualEstimatedIncome,
          realEstate.data.saleSetting.askingPrice
        );
      return await this.$$realEstate$saveRealEstate(uid, realEstate);
    },
    async $$realEstate$saveRealEstate(uid, realEstate) {
      const now = firebase.now();
      realEstate.data.couponYieldRate = this.$$realEstate$calcYield(
        realEstate.data.annualEstimatedIncome,
        realEstate.data.purchase.amount
      );
      if (realEstate.id) {
        // const self = this;
        realEstate.data.updatedBy = uid;
        realEstate.data.updatedDt = now;
        await firebase.db
          .collection("realEstates_v1")
          .doc(realEstate.id)
          .set(realEstate.data, { merge: true });
        return realEstate;
      } else {
        realEstate.data.updatedBy = uid;
        realEstate.data.updatedDt = now;
        realEstate.data.createdBy = uid;
        realEstate.data.createdDt = now;
        realEstate.data.registerDt = now;

        const newRealEstate = await firebase.db
          .collection("realEstates_v1")
          .add(realEstate.data)
          .then((ref) => {
            realEstate.id = ref.id;
            return realEstate;
          });
        return newRealEstate;
      }
    },
    async $$realEstate$saveRealEstateByTransaction(uid, transaction) {
      const now = firebase.now();
      const realEstate = this.$$realEstate$convertTransactionIntoRealEstate(
        uid,
        transaction
      );
      realEstate.couponYieldRate = this.$$realEstate$calcYield(
        realEstate.annualEstimatedIncome,
        realEstate.purchase.amount
      );
      realEstate.updatedBy = uid;
      realEstate.updatedDt = now;
      realEstate.createdBy = uid;
      realEstate.createdDt = now;
      realEstate.registerDt = now;

      const newRealEstate = await firebase.db
        .collection("realEstates_v1")
        .add(realEstate)
        .then((ref) => {
          realEstate.id = ref.id;
          return realEstate;
        });
      return newRealEstate;
    },
    $$realEstate$convertTransactionIntoRealEstate(uid, transaction) {
      const date = transaction.data.contractDate.toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const realEstate = this.$$cloneDeep(type.realEstate);

      realEstate.owner = uid; //uid
      realEstate.annualEstimatedIncome =
        transaction.data.realEstate.annualEstimatedIncome; // 年間想定収入
      realEstate.couponYieldRate = transaction.data.realEstate.couponYieldRate; // 表面利回り
      realEstate.name = transaction.data.realEstate.name; //物件名
      realEstate.buildingUse = transaction.data.realEstate.buildingUse; //不動産用途
      realEstate.realEstateType = transaction.data.realEstate.realEstateType; // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
      realEstate.prefecture = transaction.data.realEstate.prefecture; //住所（県）
      realEstate.city = transaction.data.realEstate.city; // 住所（市町村）
      realEstate.address = transaction.data.realEstate.address; // 住所（番地）
      realEstate.building = transaction.data.realEstate.building; // 住所（マンション名・部屋番号）
      realEstate.numberOfFloors = transaction.data.realEstate.numberOfFloors; // 階数
      realEstate.buildingArea = transaction.data.realEstate.buildingArea; // 建物面積
      realEstate.images = transaction.data.realEstate.images; // 物件画像
      realEstate.transports = transaction.data.realEstate.transports; // 交通機関
      realEstate.buildingType = transaction.data.realEstate.buildingType; // 建物種別 1:RC・SRC 2:鉄骨 3:軽量鉄骨 4:木造
      realEstate.constructionDate =
        transaction.data.realEstate.constructionDate; // 築年月
      realEstate.purchase.buyingDt = {
        year: year,
        month: month,
      }; // 購入年月
      realEstate.purchase.amount = transaction.data.price; // 購入金額
      realEstate.purchase.loanOrCash = transaction.data.loanOrCash; // 融資有無
      realEstate.purchase.loan = transaction.data.loan;
      realEstate.saleSetting.layouts = {
        layout: transaction.data.realEstate.layouts[0].layout, //間取り
        NumberOfUnits: transaction.data.realEstate.layouts[0].NumberOfUnits, //戸数
      };
      realEstate.saleSetting.numberOfHouseholds =
        transaction.data.realEstate.numberOfHouseholds; // 総戸数
      realEstate.saleSetting.parking = transaction.data.realEstate.parking; // 駐車場
      realEstate.saleSetting.landArea = transaction.data.realEstate.landArea; // 土地面積
      realEstate.saleSetting.landRight = transaction.data.realEstate.landRight; // 土地権利1:所有権 2:普通賃借権 3:旧法賃借権 4:普通地上権 5:旧法地上権
      realEstate.saleSetting.texture = transaction.data.realEstate.texture; // 地目
      realEstate.saleSetting.urbanPlanningArea =
        transaction.data.realEstate.urbanPlanningArea; // 都市計画区域 1:市街化区域 2:市街化調整区域 3:その他
      realEstate.saleSetting.areaOfUse = transaction.data.realEstate.areaOfUse; // 用途地域
      realEstate.saleSetting.buildingCoverageRatio =
        transaction.data.realEstate.buildingCoverageRatio; // 建蔽率
      realEstate.saleSetting.floorAreaRatio =
        transaction.data.realEstate.floorAreaRatio; // 容積率
      realEstate.saleSetting.roadBurdenAreaRatio =
        transaction.data.realEstate.roadBurdenAreaRatio; // 私道負担面積

      realEstate.transaction.investor = transaction.data.investor; // uid
      realEstate.transaction.realtor = transaction.data.realtor; // uid
      realEstate.transaction.link = transaction.data.link;
      realEstate.transaction.statusOfRegister =
        transaction.data.statusOfRegister; //登録ステータス 専門家登録中
      realEstate.transaction.statusOfAccounting =
        transaction.data.statusOfAccounting; //会計ステータス  { value: 100, text: "入金前" }
      realEstate.transaction.user = transaction.data.user;
      realEstate.transaction.transactionType = transaction.data.transactionType; // 契約種別 1:一般媒介, 2:専任媒介, 3:専属選任媒介, 4:代理, 5:仲介, 6:売主
      realEstate.transaction.contractDate = transaction.data.contractDate; // 売買契約日
      realEstate.transaction.settlementDate = transaction.data.settlementDate; // 決済実行日
      realEstate.transaction.price = transaction.data.price; // 取引金額
      realEstate.transaction.fee = transaction.data.fee; // 仲介手数料
      realEstate.transaction.tradeType = transaction.data.tradeType; // 0: 購入, 1: 売却
      realEstate.transaction.investorInfo = transaction.data.investorInfo;
      realEstate.transaction.realtorInfo = transaction.data.realtorInfo;
      realEstate.transaction.realtorEvaluation =
        transaction.data.realtorEvaluation;
      realEstate.transaction.bankAccount = transaction.data.bankAccount;
      realEstate.transaction.paymentDt = transaction.data.paymentDt;
      realEstate.transaction.cashBackDt = transaction.data.cashBackDt;
      realEstate.transaction.updatedBy = transaction.data.updatedBy;
      realEstate.transaction.updatedDt = transaction.data.updatedDt;
      realEstate.transaction.createdBy = transaction.data.createdBy;
      realEstate.transaction.createdDt = transaction.data.createdDt;
      realEstate.transaction.registerDt = transaction.data.registerDt;

      return realEstate;
    },
    $$realEstate$calcYearOfConstruction: function (constructionDate) {
      const yearMillisecond = 1000 * 60 * 60 * 24 * 365;
      const now = new Date();
      const cd = new Date();
      cd.setFullYear(constructionDate.year);
      cd.setMonth(constructionDate.month - 1);
      const diff = now.getTime() - cd.getTime();

      return diff > 0 ? Math.floor(diff / yearMillisecond) : 0;
    },
    $$realEstate$calcDayOfConstruction: function (constructionDate) {
      const dayMillisecond = 1000 * 60 * 60 * 24;
      const now = new Date();
      const cd = new Date();
      cd.setFullYear(constructionDate.year);
      cd.setMonth(constructionDate.month - 1);
      const diff = now.getTime() - cd.getTime();

      return diff > 0 ? Math.floor(diff / dayMillisecond) : 0;
    },
    $$realEstate$loadRealEstatesToRealtor: async function () {
      const qs = await firebase.db
        .collection("realEstates_v1")
        .where("isActivate", "==", true)
        .where("saleSetting.publicType", "==", 2)
        .orderBy("saleSetting.saleSettingDt", "desc")
        .get();

      const ls = [];
      qs.forEach((doc) => {
        const r = {
          id: doc.id,
          data: this.$$assign(type.realEstate, doc.data()),
        };
        ls.push(r);
      });
      return ls;
    },
    $$realEstate$loadRealEstatesByUID: async function (uid, isActivate = true) {
      let qs = null;
      if (isActivate) {
        qs = await firebase.db
          .collection("realEstates_v1")
          .where("owner", "==", uid)
          .where("isActivate", "==", isActivate)
          .get();
      } else {
        qs = await firebase.db
          .collection("realEstates_v1")
          .where("owner", "==", uid)
          .get();
      }

      const ls = [];
      qs.forEach((doc) => {
        const r = {
          id: doc.id,
          data: this.$$assign(type.realEstate, doc.data()),
        };
        ls.push(r);
      });
      return ls;
    },
    $$realEstate$loadRealEstateSaleInfo: async function (id) {
      const doc = await firebase.db.collection("realEstates_v1").doc(id).get();
      if (doc.exists) {
        const realEstate = {
          id: doc.id,
          data: this.$$assign(type.realEstate, doc.data()),
        };
        const owner = await this.$$investor$loadUserSimple(
          realEstate.data.owner
        );
        realEstate.owner = owner;
        return realEstate;
      } else {
        return null;
      }
    },
    $$realEstate$loadRealEstate: async function (id) {
      const doc = await firebase.db.collection("realEstates_v1").doc(id).get();
      if (doc.exists) {
        const realEstate = {
          id: doc.id,
          data: this.$$assign(type.realEstate, doc.data()),
        };
        return realEstate;
      } else {
        return null;
      }
    },
    $$realEstate$getLayoutsFormats: function (layouts) {
      const ls = [];
      for (const layout of layouts) {
        const s = this.$$realEstate$getLayoutsFormat(layout);
        ls.push(s);
      }
      return ls;
    },
    $$realEstate$getLayoutsFormat: function (layouts) {
      if (layouts) {
        let str = "";

        if (layouts.layout) {
          str += kubuns.getlayout(layouts.layout).text;
        }
        if (str) str += " × ";
        if (layouts.NumberOfUnits) {
          str += layouts.NumberOfUnits + "戸";
        }
        if (!str) str = "-";
        return str;
      } else {
        return "";
      }
    },
    async $$realEstate$loadRealEstatesByFilter(filter) {
      const ret = await firebase.db
        .collection("realEstates_v1")
        .where("isActivate", "==", true)
        .where("saleSetting.publicType", "==", 2)
        .orderBy("saleSetting.saleSettingDt", "desc")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realEstate, doc.data()),
            });
          });
          return ls;
        });

      const rets = ret
        .filter((r) => {
          const isDefaultValue =
            filter.yieldRate.from === 0 && filter.yieldRate.to === 100;
          if (
            "yieldRate" in filter &&
            (filter.yieldRate.from || filter.yieldRate.to) &&
            !isDefaultValue
          ) {
            const couponYieldRate = r.data.saleSetting.couponYieldRate || 0;
            if (
              filter.yieldRate.from <= couponYieldRate &&
              couponYieldRate <= filter.yieldRate.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.price.from === 0 && filter.price.to === 9999999999;
          if (
            "price" in filter &&
            (filter.price.from || filter.price.to) &&
            !isDefaultValue
          ) {
            if (
              filter.price.from <= r.data.saleSetting.askingPrice &&
              r.data.saleSetting.askingPrice <= filter.price.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.yearOfConstruction.from ===
              this.$$ks.filters.yearOfconstruction.from[0].value &&
            filter.yearOfConstruction.to ===
              this.$$ks.filters.yearOfconstruction.to[0].value;
          const yearOfConstruction = this.$$realEstate$calcYearOfConstruction(
            r.data.constructionDate
          );
          if (
            "yearOfConstruction" in filter &&
            (filter.yearOfConstruction.from || filter.yearOfConstruction.to) &&
            !isDefaultValue
          ) {
            if (
              filter.yearOfConstruction.from <= yearOfConstruction &&
              yearOfConstruction <= filter.yearOfConstruction.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue = filter.elapsedTime === 999;
          if (
            filter.elapsedTime &&
            r.data.transports.length &&
            !isDefaultValue
          ) {
            const elapsedTime = this.getMinimumElapsedTimeOfTransports(
              r.data.transports
            );
            if (elapsedTime <= filter.elapsedTime) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "realEstateTypes" in filter &&
            Object.values(filter.realEstateTypes).includes(true)
          ) {
            if (filter.realEstateTypes[1] && r.data.realEstateType === 1) {
              return true;
            }
            if (filter.realEstateTypes[2] && r.data.realEstateType === 2) {
              return true;
            }
            if (filter.realEstateTypes[3] && r.data.realEstateType === 3) {
              return true;
            }
            if (filter.realEstateTypes[4] && r.data.realEstateType === 4) {
              return true;
            }
            if (filter.realEstateTypes[5] && r.data.realEstateType === 5) {
              return true;
            }
            if (filter.realEstateTypes[6] && r.data.realEstateType === 6) {
              return true;
            }
            if (filter.realEstateTypes[7] && r.data.realEstateType === 7) {
              return true;
            }
            if (filter.realEstateTypes[8] && r.data.realEstateType === 8) {
              return true;
            }
            if (filter.realEstateTypes[9] && r.data.realEstateType === 9) {
              return true;
            }
            if (filter.realEstateTypes[10] && r.data.realEstateType === 10) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "buildingTypes" in filter &&
            Object.values(filter.buildingTypes).includes(true)
          ) {
            if (filter.buildingTypes[1] && r.data.buildingType === 1) {
              return true;
            }
            if (filter.buildingTypes[2] && r.data.buildingType === 2) {
              return true;
            }
            if (filter.buildingTypes[3] && r.data.buildingType === 3) {
              return true;
            }
            if (filter.buildingTypes[4] && r.data.buildingType === 4) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((r) => {
          const isDefaultValue =
            filter.buildingArea.from === 0 && filter.buildingArea.to === 9999;
          if (
            "buildingArea" in filter &&
            (filter.buildingArea.from || filter.buildingArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.buildingArea.from <= r.data.buildingArea &&
              r.data.buildingArea <= filter.buildingArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          const isDefaultValue =
            filter.landArea.from === 0 && filter.landArea.to === 9999;
          if (
            "landArea" in filter &&
            (filter.landArea.from || filter.landArea.to) &&
            !isDefaultValue
          ) {
            if (
              filter.landArea.from <= r.data.saleSetting.landArea &&
              r.data.saleSetting.landArea <= filter.landArea.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "area" in filter &&
            filter.area.isSelected &&
            filter.area.prefecture
          ) {
            if (filter.area.prefecture !== r.data.prefecture) {
              return false;
            }
            if (filter.area.cities.length) {
              return filter.area.cities.includes(r.data.city);
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "Line" in filter &&
            filter.Line.isSelected &&
            filter.Line.prefecture
          ) {
            if (filter.Line.line) {
              const lines = this.getLinesOfTransports(r.data.transports);
              if (!lines.includes(filter.Line.line)) {
                return false;
              }
            } else {
              // 最寄りの駅が県外の場合を想定して、lineがない場合のみ prefecture を判定している
              if (filter.Line.prefecture !== r.data.prefecture) {
                return false;
              }
            }
            if (filter.Line.stations.length) {
              const stations = this.getStationsOfTransports(r.data.transports);
              if (!stations.find((s) => filter.Line.stations.includes(s))) {
                return false;
              }
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((r) => {
          if (
            "layouts" in filter &&
            Object.values(filter.layouts).includes(true)
          ) {
            const layout = r.data.saleSetting.layouts.layout;
            if (filter.layouts[10] && layout === 10) {
              return true;
            }
            if (filter.layouts[101] && layout === 101) {
              return true;
            }
            if (filter.layouts[110] && layout === 110) {
              return true;
            }
            if (filter.layouts[120] && layout === 120) {
              return true;
            }
            if (filter.layouts[201] && layout === 201) {
              return true;
            }
            if (filter.layouts[210] && layout === 210) {
              return true;
            }
            if (filter.layouts[220] && layout === 220) {
              return true;
            }
            if (filter.layouts[301] && layout === 301) {
              return true;
            }
            if (filter.layouts[310] && layout === 310) {
              return true;
            }
            if (filter.layouts[320] && layout === 320) {
              return true;
            }
            if (filter.layouts[401] && layout === 401) {
              return true;
            }
            if (filter.layouts[410] && layout === 410) {
              return true;
            }
            if (filter.layouts[420] && layout === 420) {
              return true;
            }
            if (filter.layouts[500] && layout === 500) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        });
      return rets;
    },
    getMinimumElapsedTimeOfTransports(transports) {
      return Math.min(...transports.map((t) => t.elapsedTime));
    },
    getLinesOfTransports(transports) {
      return transports.map((t) => t.line);
    },
    $$realEstate$checkNull(val) {
      if (val) return val;
      else return "";
    },
  },
};
</script>
