<template>
  <div>
    <z-select
      label="否認理由"
      :name="'examination.data.reasonForRejection' + idx"
      v-model="examination.data.reasonForRejection"
      :options="$$ks.realtor.application.reasonForRejections"
      :validate="''"
    >
    </z-select>
    <z-input
      label="コメント"
      :name="'examination.data.comment' + idx"
      v-model="examination.data.comment"
      type="textarea"
      maxlength="1000"
      :validate="''"
    >
    </z-input>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

export default {
  name: "admin-setting-investors-detail-user",
  inject: ["$validator"],
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
    examination: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$saveExaminationOfUser(
          this.auth.uid,
          this.user.id,
          this.examination
        );
        this.$$toast$openSuccess();
        this.$router.replace({
          name: "admin-setting-investors-detail",
          params: {
            uid: this.user.id,
          },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
