<template>
  <div class="NormalOfferInfo">
    <OfferInfoCommon
      :tabOfferType="2"
      :isVisible="$$user$isPremium"
      :entity="entity"
      v-if="!isLoading"
    />
  </div>
</template>
<script>
import OfferInfoCommon from "./OfferInfoCommon";

export default {
  name: "NormalOfferInfo",
  props: {
    entity: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data: function () {
    return {};
  },
  components: {
    OfferInfoCommon: OfferInfoCommon,
  },
};
</script>
<style lang="scss" scoped>
.NormalOfferInfo {
  min-height: 498px;
}
</style>
