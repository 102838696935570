<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="columns transaction">
        <!-- <div class="column is-narrow">
          <div class="transaction__search">
            <z-input label="検索" name="search.text" v-model="search.text">
            </z-input>
            名前とemailのみ(簡易)
            <hr />
            <z-select
              label="会員区分"
              name="search.memberships"
              :options="$$ks.investor.application.memberships"
              v-model="search.memberships"
              nullable
            >
            </z-select>
            <z-select
              label="申請ステータス"
              name="search.statuses"
              :options="$$ks.investor.application.statuses"
              v-model="search.statuses"
              nullable
            >
            </z-select>
            <hr />
            スコア検索は未実装
          </div>
        </div> -->
        <div class="column">
          <div class="container" v-if="!isLoading">
            <b-table
              :data="transactions"
              checkable
              sort-icon="arrow-up"
              paginated
              :per-page="100"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              sort-icon-size="is-small"
              custom-row-key="id"
              :default-sort="['data.statusOfRegister', 'asc']"
              :checked-rows.sync="checkeds"
              :striped="true"
              :hoverable="true"
              :narrowed="true"
              :mobile-cards="true"
            >
              <template slot-scope="props">
                <b-table-column field="id" label="詳細" width="20" sortable>
                  <router-link
                    :to="{
                      name: 'admin-setting-admin-transaction-detail',
                      params: { id: props.row.data.link },
                    }"
                    class="button is-small"
                    >詳細</router-link
                  >
                </b-table-column>
                <b-table-column
                  field="data.statusOfRegister"
                  label="ステータス"
                  width="120"
                  sortable
                >
                  <div>
                    <z-tag
                      class="transaction__status"
                      :options="$$ks.transaction.statusOfRegisterTypes"
                      v-model="props.row.data.statusOfRegister"
                    />
                    <z-tag
                      class="transaction__status"
                      :options="$$ks.transaction.statusOfAccountingTypes"
                      v-model="props.row.data.statusOfAccounting"
                    />
                  </div>
                </b-table-column>
                <b-table-column field="data" label="成約情報" sortable>
                  <div class="content is-small">
                    {{ props.row.data.user.name }}<br />
                    {{ props.row.data.user.phoneNumber }}
                    <!-- ({{ $$age$calc(props.row.investor.data.base.birthday) }})
                      {{ $$ks.getTypeOfSex(props.row.investor.data.base.sex).text }}<br />
                      {{ props.row.investor.address }}<br />
                      {{ props.row.investor.data.base.phoneNumber }} -->
                    <hr class="score__detail__hr" />
                  </div>
                  <div class="transaction__contents">
                    <div class="content is-small transaction__content">
                      {{ "会社名:&nbsp;" + props.row.realtor.company.data.name
                      }}<br />
                      {{ "担当者名:&nbsp;" + props.row.realtor.data.base.name
                      }}<br />
                      {{
                        "契約種別:&nbsp;" +
                        $$ks.realtor.realEstate.getTransactionType(
                          props.row.data.transactionType
                        ).text
                      }}<br />
                      {{
                        "購入 / 売却:&nbsp;" +
                        $$ks.transaction.getTradeType(props.row.data.tradeType)
                          .text
                      }}
                    </div>
                    <div class="content is-small transaction__content">
                      {{
                        "売買契約日:&nbsp;" +
                        $$calender$dateFormat(props.row.data.contractDate)
                      }}<br />
                      {{
                        "決済実行日:&nbsp;" +
                        $$calender$dateFormat(props.row.data.settlementDate)
                      }}<br />
                      {{
                        "取引金額:&nbsp;" +
                        $$price$priceJPFormat(props.row.data.price)
                      }}<br />
                      {{
                        "仲介手数料:&nbsp;" +
                        $$price$priceJPFormat(props.row.data.fee)
                      }}<br />
                      <br />
                      {{
                        "専門家フィー入金日:&nbsp;" +
                        $$calender$dateFormat(props.row.data.paymentDt)
                      }}<br />
                      {{
                        "投資家キャッシュバック日:&nbsp;" +
                        $$calender$dateFormat(props.row.data.cashBackDt)
                      }}
                    </div>
                  </div>
                </b-table-column>
                <b-table-column
                  field="data.investor"
                  label="プレミアムプラン加入状況"
                  width="150"
                  sortable
                >
                  <template v-if="props.row.data.investor">
                    <div class="content is-small">
                      {{ props.row.investor.data.membership }}
                      {{
                        $$ks.investor.application.getMembership(
                          props.row.investor.data.membership
                        ).text
                      }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="content is-small">
                      【取引の振り返りが入力完了すると、表示されます】
                    </div>
                  </template>
                </b-table-column>
                <b-table-column
                  width="200"
                  field="data.investorInfo"
                  label="スコア"
                  sortable
                >
                  <div class="content is-small">
                    <div class="score__detail__warp">
                      <p>総合スコア</p>
                      <p>{{ props.row.data.investorInfo.score.score }}</p>
                    </div>
                    <div class="score__detail__warp">
                      <p>資産スコア</p>
                      <p>{{ props.row.data.investorInfo.score.bs }}</p>
                    </div>
                    <div class="score__detail__warp">
                      <p>収入スコア</p>
                      <p>{{ props.row.data.investorInfo.score.income }}</p>
                    </div>
                    <hr class="score__detail__hr" />
                    <div class="score__detail__warp">
                      <p>収入</p>
                      <p>
                        {{
                          $$price$priceFormat(
                            props.row.data.investorInfo.PL.sum
                          )
                        }}
                      </p>
                    </div>
                    <div class="score__detail__warp">
                      <p>資産合計</p>
                      <p>
                        {{
                          $$price$priceFormat(
                            props.row.data.investorInfo.bs.assets.sum
                          )
                        }}
                      </p>
                    </div>
                    <div class="score__detail__warp">
                      <p>純資産</p>
                      <p>
                        {{
                          $$price$priceFormat(
                            props.row.data.investorInfo.bs.netAssets.sum
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column
                  field="data.link"
                  label="登録リンク"
                  width="120"
                  sortable
                >
                  <!--
                  <div class="content is-small transaction__link">
                    {{ formatUrl(props.row.data.link) }}
                  </div>
                  -->
                  <input
                    class="transaction__link__input"
                    :id="'copyTarget' + props.row.data.link"
                    :value="formatUrl(props.row.data.link)"
                    type="text"
                    readonly
                  />
                  <div class="buttons">
                    <button
                      class="button is-small"
                      @click="copyToClipboard(props.row.data.link)"
                    >
                      copy link
                    </button>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "admin-setting-admin-transaction",
  data: function () {
    return {
      isLoading: true,
      // investors: [],
      transactions: [],
      checkeds: [],
      search: {
        text: "",
        memberships: null,
        statuses: null,
      },
    };
  },
  computed: {
    //   transactions: function() {
    //     return this.allTransactions
    //       .filter(t => {
    //         if (this.search.text) {
    //           const words = this.search.text.split(/\s+/);
    //           for (const w of words) {
    //             const reg = new RegExp(w, "i");
    //             return (
    //               t.investor.data.base.name.match(reg) ||
    //               t.investor.data.base.email.match(reg) ||
    //               t.investor.data.base.kana.match(reg) ||
    //               t.investor.id.match(reg)
    //             );
    //           }
    //           return true;
    //         } else {
    //           return true;
    //         }
    //       })
    //       .filter(t => {
    //         const st = this.search.statuses;
    //         if (st) {
    //           return t.investor.examination.data.status === st;
    //         } else {
    //           return true;
    //         }
    //       })
    //       .filter(t => {
    //         const st = this.search.memberships;
    //         if (st) {
    //           return t.investor.examination.data.membership === st;
    //         } else {
    //           return true;
    //         }
    //       });
    //   }
  },
  methods: {
    formatUrl(id) {
      const path = this.$router.resolve({
        // name: "transaction-review-confirmation",
        name: "transaction-review-congratulations",
        params: {
          id: id,
        },
      }).href;
      return location.origin + path;
    },
    // async address(t) {
    //   const text = await this.$$area$generateTextOfAddress(t.investor.data.base);
    //   t.investor.address = text;
    //   return t;
    // },
    copyToClipboard(id) {
      const target = document.getElementById("copyTarget" + id);
      target.select();
      document.execCommand("Copy");
      this.$$toast$openSuccess("URLをコピーしました");
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        // const transactions = await this.$$transaction$loadTransactions();
        // const ps = [];
        // for (const t of transactions) {
        //   ps.push(this.address(t));
        // }
        // this.transactions = await Promise.all(ps);
        this.transactions = await this.$$transaction$loadTransactions();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.transaction {
  .content {
    margin-bottom: 0;
  }
  // &__search {
  //   width: 240px;
  //   height: 100%;
  //   @include mobile {
  //     width: 100%;
  //   }
  // }
  &__status {
    margin-bottom: 15px;
  }
  &__contents {
    display: flex;
  }
  &__content {
    margin-right: 15px;
  }
  &__link {
    color: blue;
    width: 120px;
    &__input {
      position: fixed;
      left: 100vw; //画面外に飛ばしてます
    }
  }
}

.score__detail {
  &__warp {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__hr {
    margin: 2px;
  }
}
</style>
