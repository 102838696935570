<script>
import * as MessageTemplateModule from "@/modules/realtor/MessageTemplate";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "CreateTemplateMessageMixin",
  methods: {
    // 送信モード
    $$createTemplateMessage$modes() {
      return {
        investors: 0,
        messages: 1,
        bulkmessages: 2,
      };
    },
    // スコアパターン
    $$createTemplateMessage$scorePattern() {
      return "{{ 宛名（スコア）}}";
    },
    // 名前パターン
    $$createTemplateMessage$namePattern() {
      return "{{ 宛名（名前）}}";
    },
    // モーデル表示モード
    $$createTemplateMessage$labelTooltip() {
      return "一括送信時に、宛名の代わりとしてご利用いただけます。例）スコア500様";
    },
    // カスタムテンプレートリストを表示する
    async $$createTemplateMessage$getCustomTemplates() {
      let result = await MessageTemplateModule.loads(this.me.user.id);
      if (!result) return null;
      return result.sort((a, b) => a.data.ord - b.data.ord);
    },
    // 投資家のスコア名を入力する
    $$createTemplateMessage$onAddInvestorScore(
      targetTitle,
      targetContent,
      elemFocus,
      elemFocusType
    ) {
      let elemTarget = null;

      if (elemFocusType === "textarea") {
        elemTarget = document
          .querySelector(targetContent)
          .getElementsByTagName("textarea");
      } else if (elemFocusType === "textbox") {
        elemTarget = document
          .querySelector(targetTitle)
          .getElementsByTagName("input");
      }
      if (!elemTarget) return null;

      const selectionStart = elemTarget[0].selectionStart;
      const selectionEnd = elemTarget[0].selectionEnd;
      const scorePattern = this.$$createTemplateMessage$scorePattern();
      const newCursorPosition = selectionStart + scorePattern.length;
      const maxlength = 9999;

      if (newCursorPosition > maxlength) return elemTarget[0].value;

      elemTarget[0].setRangeText(
        scorePattern,
        selectionStart,
        selectionEnd,
        "end"
      );

      setTimeout(() => {
        elemTarget[0].focus();
        elemTarget[0].setSelectionRange(newCursorPosition, newCursorPosition);
      }, 100);

      return elemTarget[0].value;
    },
    // 無効にするかどうか
    $$createTemplateMessage$isCheckedTerms(checkedTermsLength) {
      return checkedTermsLength < kubuns.message.terms.length;
    },
    // 名前を置き換える
    $$createTemplateMessage$replaceName(text, name = null) {
      if (!text) return null;

      const pattern = this.$$createTemplateMessage$namePattern();

      // メッセージテンプレート作成モーダルで、プレビュー内部では"{{ score }}"を仮の固定値"750"等に置き換えて表示する
      if (name === null) {
        name = "名前";
      }

      // 送信する前に、該当の投資家のスコアを置き換える
      return text.replace(new RegExp(pattern, "g"), `${name}様`);
    },
    // スコアを置き換える
    $$createTemplateMessage$replaceScore(text, score = null) {
      if (!text) return null;

      const scorePattern = this.$$createTemplateMessage$scorePattern();

      // メッセージテンプレート作成モーダルで、プレビュー内部では"{{ score }}"を仮の固定値"750"等に置き換えて表示する
      if (score === null) {
        score = "○○○";
      }

      // 送信する前に、該当の投資家のスコアを置き換える
      return text.replace(new RegExp(scorePattern, "g"), `スコア${score}様`);
    },
    // フォカスを切り替える
    $$createTemplateMessage$focusChanged($event, elemFocus) {
      const elemTarget = $event.target;
      const tagName = elemTarget.tagName.toLowerCase();

      if (tagName === "textarea") {
        elemFocus.current = elemTarget;
        elemFocus.typeCurrent = "textarea";
      } else if (
        tagName === "input" &&
        elemTarget.type.toLowerCase() === "text"
      ) {
        elemFocus.current = elemTarget;
        elemFocus.typeCurrent = "textbox";
      } else {
        elemFocus.previous = elemFocus.current;
        elemFocus.typePrevious = elemFocus.typeCurrent;
        elemFocus.current = null;
        elemFocus.typeCurrent = null;
      }

      return elemFocus;
    },
    // textareaの高さを入力内容によって可変する
    $$createTemplateMessage$adjustHeightTextarea(target) {
      const elemTarget = document.querySelector(target);
      if (!elemTarget) return;
      const elemTextarea = elemTarget.getElementsByTagName("textarea");
      if (!elemTextarea) return;

      elemTextarea[0].style.height = `${elemTextarea[0].scrollHeight}px`;
    },
  },
};
</script>
