<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="columns">
        <div class="column is-narrow investors">
          <div class="investors__search">
            <z-input label="検索" name="search.text" v-model="search.text">
            </z-input>
            名前とemailのみ(簡易)
            <hr />
            <z-select
              label="会員区分"
              name="search.memberships"
              :options="$$ks.investor.application.memberships"
              v-model="search.memberships"
              nullable
            >
            </z-select>
            <z-select
              label="申請ステータス"
              name="search.statuses"
              :options="$$ks.investor.application.statuses"
              v-model="search.statuses"
              nullable
            >
            </z-select>
            <hr />
            スコア検索は未実装
          </div>
        </div>
        <div class="column">
          <div class="container" v-if="!isLoading">
            <div class="buttons">
              <button
                class="button is-danger"
                :disabled="!checkeds.length"
                @click="accept"
              >
                承認
              </button>
              <!--
                  <button class="button is-danger" @click="denial">否認</button>
                -->
            </div>
            <hr />
            <b-table
              :data="users"
              checkable
              sort-icon="arrow-up"
              paginated
              :per-page="100"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              sort-icon-size="is-small"
              :default-sort="['data.registerDt', 'desc']"
              :checked-rows.sync="checkeds"
              :striped="true"
              :hoverable="true"
              :narrowed="true"
              custom-row-key="id"
              :mobile-cards="true"
            >
              <template slot-scope="props">
                <b-table-column field="id" label="詳細" width="20" sortable>
                  <router-link
                    :to="{
                      name: 'admin-setting-investors-detail',
                      params: { uid: props.row.id },
                    }"
                    class="button is-small"
                    >詳細</router-link
                  >
                </b-table-column>
                <b-table-column
                  field="examination.data.status"
                  label="認証"
                  width="64"
                  sortable
                >
                  <z-tag
                    :options="statusKS"
                    v-model="props.row.examination.data.status"
                  />
                </b-table-column>
                <b-table-column field="data.name" label="基本情報" sortable>
                  <div class="content is-small">
                    {{ props.row.data.base.name }}
                    ({{ $$age$calc(props.row.data.base.birthday) }})
                    {{ $$ks.getTypeOfSex(props.row.data.base.sex).text }}<br />
                    {{ props.row.address }}<br />
                    <hr class="score__detail__hr" />
                    {{ props.row.data.base.phoneNumber }}<br />
                    {{
                      "会社名:&nbsp;" +
                      props.row.data.profile.currentOfCareer.name
                    }}<br />
                    {{
                      "業種:&nbsp;" +
                      $$ks.getCategoryOfBusiness(
                        props.row.data.profile.currentOfCareer
                          .categoryOfBusiness
                      ).text
                    }}
                    {{
                      "職種:&nbsp;" +
                      $$ks.getTypeOfOccupation(
                        props.row.data.profile.currentOfCareer.typeOfOccupation
                      ).text
                    }}<br />
                    {{ props.row.data.base.email }}
                  </div>
                </b-table-column>
                <b-table-column
                  width="176"
                  field="data.latestScore.score"
                  label="スコア"
                  sortable
                >
                  <div class="content is-small">
                    <div class="score__detail__warp">
                      <p>総合スコア</p>
                      <p>{{ props.row.data.latestScore.score }}</p>
                    </div>
                    <div class="score__detail__warp">
                      <p>資産スコア</p>
                      <p>{{ props.row.data.latestScore.bs }}</p>
                    </div>
                    <div class="score__detail__warp">
                      <p>収入スコア</p>
                      <p>{{ props.row.data.latestScore.income }}</p>
                    </div>
                    <hr class="score__detail__hr" />
                    <div class="score__detail__warp">
                      <p>収入</p>
                      <p>
                        {{ $$price$priceFormat(props.row.data.latestPL.sum) }}
                      </p>
                    </div>
                    <div class="score__detail__warp">
                      <p>資産合計</p>
                      <p>
                        {{
                          $$price$priceFormat(
                            props.row.data.latestBS.assets.sum
                          )
                        }}
                      </p>
                    </div>
                    <div class="score__detail__warp">
                      <p>純資産</p>
                      <p>
                        {{
                          $$price$priceFormat(
                            props.row.data.latestBS.netAssets.sum
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column
                  field="realEstates.length"
                  label="不動産情報"
                  width="96"
                  sortable
                >
                  <div class="content is-small">
                    <router-link
                      :to="{
                        name: 'admin-setting-investors-realestate',
                        params: { uid: props.row.id },
                      }"
                    >
                      登録{{ props.row.realEstates.length }}件
                    </router-link>
                  </div>
                </b-table-column>
                <b-table-column
                  field="data.registerDt"
                  label="会員区分"
                  width="360"
                  sortable
                >
                  <z-tag
                    v-model="props.row.examination.data.membership"
                    :options="statusMKS"
                  />
                  <div class="content is-small">
                    <template
                      v-if="props.row.examination.data.membership === 20"
                    >
                      <div class="score__detail__warp">
                        <p>トライアル</p>
                        <p>
                          {{
                            props.row.examination.data.isTrial
                              ? "トライアル中"
                              : "-"
                          }}
                        </p>
                      </div>
                      <div class="score__detail__warp">
                        <p>ステータス</p>
                        <p>
                          {{
                            props.row.examination.data.isCancelled
                              ? "自動解約"
                              : "自動更新"
                          }}
                        </p>
                      </div>
                      <div class="score__detail__warp">
                        <p>開始日</p>
                        <p>
                          {{
                            $$calender$dateFormat(
                              props.row.examination.data.startDt
                            )
                          }}
                        </p>
                      </div>
                      <div class="score__detail__warp">
                        <p>終了日</p>
                        <p>
                          {{
                            $$calender$dateFormat(
                              props.row.examination.data.endDt
                            )
                          }}
                        </p>
                      </div>
                      <hr class="score__detail__hr" />
                    </template>
                    <div>
                      <p>アカウント登録時刻</p>
                      {{ $$calender$dateTimeFormat(props.row.data.registerDt) }}
                    </div>
                    <p>{{ props.row.id }}</p>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

export default {
  name: "admin-setting-investors",
  data: function () {
    return {
      isLoading: true,
      investors: [],
      checkeds: [],
      isActive: true,
      showNav: false,
      search: {
        text: "",
        memberships: null,
        statuses: null,
      },
    };
  },
  computed: {
    statusMKS() {
      return this.$$ks.investor.application.memberships;
    },
    statusKS() {
      return this.$$ks.investor.application.statuses;
    },
    users: function () {
      return this.investors
        .filter((i) => {
          if (this.search.text) {
            const words = this.search.text.split(/\s+/);
            for (const w of words) {
              const reg = new RegExp(w, "i");
              return (
                i.data.base.name.match(reg) ||
                i.data.base.email.match(reg) ||
                i.data.base.kana.match(reg) ||
                i.id.match(reg)
              );
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((i) => {
          const st = this.search.statuses;
          if (st) {
            return i.examination.data.status === st;
          } else {
            return true;
          }
        })
        .filter((i) => {
          const st = this.search.memberships;
          if (st) {
            return i.examination.data.membership === st;
          } else {
            return true;
          }
        });
    },
  },
  methods: {
    //async calcImcom(user) {
    //   return this.$$investor$calcPL(user);
    //},
    ////async calcRatleste(user) {
    ////   return
    ////},
    navBarBurger: function () {
      document.addEventListener("DOMContentLoaded", () => {
        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(
          document.querySelectorAll(".navbar-burger"),
          0
        );

        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {
          // Add a click event on each of them
          $navbarBurgers.forEach((el) => {
            el.addEventListener("click", () => {
              // Get the target from the "data-target" attribute
              const target = el.dataset.target;
              const $target = document.getElementById(target);
              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              el.classList.toggle("is-active");
              $target.classList.toggle("is-active");
            });
          });
        }
      });
    },

    async accept() {
      const loadingComponent = this.$loading.open();
      try {
        for (const investor of this.checkeds) {
          await this.$$investor$acceptUser(this.auth.uid, investor);
        }
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async address(user) {
      const text = await this.$$area$generateTextOfAddress(user.data.base);
      user.address = text;
      return user;
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        const investors = await this.$$investor$loadUsers();
        const ps = [];
        for (const iv of investors) {
          ps.push(this.address(iv));
        }
        this.investors = await Promise.all(ps);
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investors {
  &__search {
    width: 256px;
    height: 100%;
    @include mobile {
      width: 100%;
    }
  }
}

.score__detail {
  &__warp {
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__hr {
    margin: 2px;
  }
}
</style>
