<template>
  <div>
    <section>
      <ZHeader title="Message" sub="メッセージ">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="z-section__back-to-list">
          <a @click="$router.go(-1)"> 前に戻る </a>
        </div>
        <!-- <hr /> -->
        <div v-if="!isLoading">
          <div class="message-realtor">
            <div class="message-realtor__realtor">
              <RealtorFavoriteView
                :realtor="message.realtor"
                :favorite="favorite"
              />
            </div>
            <div class="message-realtor__buyingNeeds">
              <div class="message-realtor__buyingNeeds__title">
                <p>ご希望の不動産条件<br class="sp-none" />を設定ください</p>
              </div>
              <div class="message-realtor__buyingNeeds__message">
                <p>
                  よりご希望に沿った提案を<br
                    class="sp-none"
                  />受け取ることができます。
                </p>
              </div>
              <buyingNeedsButton />
            </div>
          </div>
          <!--
              <router-link
              :to="{
              name: 'investor-search-realotrs-detail',
              params: { id: message.realtor.id }
              }"
              >
            <RealtorProfile :user="message.realtor" />
            </router-link>
            -->
          <!-- <hr /> -->

          <template v-if="!isNewPage">
            <div class="content message-wrap">
              <!-- <z-tag
                :options="$$ks.message.types"
                v-model="message.data.type"
              /> -->
              <template
                v-if="$$ks.message.getType(message.data.type).value === 1"
              >
                <div class="message-wrap__exclusive-label">
                  <img src="@/assets/message/label-exclusive-M.png" />
                </div>
                <div
                  class="title is-size-6 message-wrap__title message-wrap__exclusive-title"
                >
                  {{ message.data.title }}
                </div>
              </template>
              <template v-else>
                <div class="title is-size-6 message-wrap__title">
                  {{ message.data.title }}
                </div>
              </template>
              <template
                v-if="
                  $$ks.message.getType(message.data.type).value === 3 ||
                  $$ks.message.getType(message.data.type).value === 4
                "
              >
                <div class="message-wrap__type">
                  【{{ $$ks.message.getType(message.data.type).en }} ({{
                    $$ks.message.getType(message.data.type).text
                  }})】
                </div>
              </template>
            </div>
          </template>
          <template v-if="!isNewPage">
            <hr class="message-title-separator" />
            <SuggestionsView :suggestions="message.data.suggestions" />

            <!-- <h6 class="list-title">メッセージ詳細</h6> -->
            <b-table
              :data="threads"
              sort-icon="arrow-up"
              detailed
              detail-key="id"
              custom-row-key="id"
              :opened-detailed="defaultOpenedDetails"
              :show-detail-icon="true"
              sort-icon-size="is-small"
              :striped="true"
              :hoverable="true"
              :narrowed="true"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="送信者" width="160">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-narrow">
                      <figure class="image is-32x32">
                        <z-profile-thumbnail
                          v-model="
                            getUser(props.row.data.from).data.profile.image
                          "
                        />
                      </figure>
                    </div>
                    <div class="column">
                      <div class="content">
                        <z-truncator
                          class="is-size-7"
                          :text="getUser(props.row.data.from).data.base.name"
                        ></z-truncator>
                      </div>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="contents" label="Message">
                  <div class="content message-content">
                    <z-truncator
                      class="is-size-7"
                      :text="latestThread(props.row.data.contents)"
                    ></z-truncator>
                  </div>
                </b-table-column>
                <b-table-column field="sendDt" label="送信日" width="80">
                  <div class="content">
                    <p class="is-size-7">
                      {{ $$calender$todayFormat(props.row.data.sendDt) }}
                    </p>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>メッセージがありません。</p>
                  </div>
                </section>
              </template>
              <template slot="detail" slot-scope="props">
                <div class="content message-detial">
                  <div class="box message-detial__box sharp" ref="messagebox">
                    <pre
                      :style="'width:' + widthOfMessageBox + 'px'"
                      class="is-size-7 normal"
                      v-html="$$message$displayText(props.row.data.contents)"
                    ></pre>
                  </div>
                </div>
              </template>
            </b-table>
            <hr />
          </template>
          <template v-if="message.realtor.data.isActivate">
            <template v-if="this.messageRealEstates.length">
              <h4 class="list-title real-estate-title">
                添付されたご提案物件・案件
              </h4>
              <div class="box has-virtical-scroll box__realestate-list">
                <template v-if="!isNewPage">
                  <template v-if="message.data.type === 3">
                    <div class="content box__price">
                      <p class="is-size-5 offer-price">
                        提案売却金額：<span class="has-text-danger">{{
                          $$price$priceJPFormat(message.data.proposalSalePrice)
                        }}</span>
                      </p>
                    </div>
                  </template>
                </template>
                <RealtorRealEstateList
                  :investor="true"
                  :realEstates="messageRealEstates"
                />
              </div>
            </template>
            <template v-if="isDocs">
              <h4 class="list-title real-estate-title">添付ファイル</h4>
              <div class="box box__realestate-list">
                <z-message-docs-list :threads="message.data.threads" />
              </div>
            </template>
            <template v-if="!isNewPage">
              <div class="replayTitle">
                <p class="replayTitle__txt">メッセージに返信する</p>
              </div>
              <div class="replayMessage">
                <p class="replayMessage__txt">
                  返信率が高いほど、優先的に提案を受けやすくなります。
                </p>
              </div>
              <div class="replayTemplate">
                <div class="replayTemplate__txt">
                  <p>テンプレートで<br />クイック返信</p>
                </div>
                <div class="replayTemplate__btns">
                  <template v-for="template in templates">
                    <ZTemplateButton
                      :key="template.id"
                      @click="onClickTemplate(template)"
                      class="replayTemplate__btn"
                      :text="template.data.label"
                    />
                  </template>
                </div>
              </div>
            </template>
            <div class="box submit-form">
              <article class="media">
                <div class="media-content">
                  <div class="field">
                    <p class="control">
                      <template v-if="isNewPage">
                        <template v-if="message.data.type === 3">
                          <z-price-input
                            label="提案売却金額"
                            v-model="message.data.proposalSalePrice"
                            :validate="'required|price_min_value:1'"
                            maxlength="12"
                            placeholder="例)20,000,000"
                            isRequired
                            isPrivate
                          />
                        </template>
                        <z-radio
                          label="メッセージ区分"
                          v-model="message.data.type"
                          :options="messageTypes"
                          validate="required"
                          isRequired
                        >
                        </z-radio>
                        <z-input
                          label="件名"
                          v-model="message.data.title"
                          type="text"
                          validate="required"
                          isRequired
                        >
                        </z-input>
                      </template>
                      <z-textarea
                        :label="isNewPage ? 'メッセージ' : '返信メッセージ'"
                        :rows="8"
                        v-model="thread.contents"
                        type="textarea"
                        maxlength="5000"
                        placeholder="メッセージを入力してください"
                        validate="required"
                        isRequired
                      >
                      </z-textarea>
                      <z-docs-list-select
                        label="添付ファイル"
                        v-model="thread.docs"
                        :uploadPath="uploadPath"
                      />
                    </p>
                  </div>
                  <nav class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <button
                          @click="send"
                          class="button is-primary is-rounded is-small z-wide z-button"
                          :disabled="$$validator$invalid"
                        >
                          送信
                        </button>
                      </div>
                    </div>
                  </nav>
                </div>
              </article>
            </div>
          </template>
          <template v-else>
            <div class="nonactive-user-box__wrapper">
              <div class="nonactive-user-box__inner">
                <p class="nonactive-user-box__text">
                  現在、こちらの不動産会社・エージェントは、StockFormerに登録されていないためご返信メッセージをお受けできません。ご了承くださいますようお願い申し上げます。
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Menu from "@/components/fl/my/Menu";

//import bulmaAccordion from "bulma-accordion/dist/js/bulma-accordion.min.js";
// import RealtorProfile from "@/components/realtor/RealtorProfile";
//import ZImageTileView from "@/views/investor/home/parts/ZImageTileView.vue";
import RealtorRealEstateList from "@/components/realtor/RealtorRealEstateList";
import * as mtemplate from "@/modules/message/messageTemplate";
import * as favorite from "@/modules/investor/favorite";
import BuyingNeedsButton from "@/views/investor/common/BuyingNeedsButton";

export default {
  name: "investor-message-detail",
  components: {
    // RealtorProfile: RealtorProfile,
    // ZImageTileView: ZImageTileView,
    BuyingNeedsButton,
    RealtorRealEstateList: RealtorRealEstateList,
  },
  data: function () {
    return {
      isLoading: true,
      defaultOpenedDetails: null,
      //accordions: null,
      message: null,
      thread: null,
      favorite: null,
      templates: [],
      messageRealEstates: [],
      checkedRealEstates: [],
      widthOfMessageBox: 0,
    };
  },
  computed: {
    messageTypes() {
      if (this.message) {
        if (this.message.data.type === 1 || this.message.data === 2) {
          return [
            { value: 1, text: "エクスクルーシブ" },
            { value: 2, text: "オファー" },
          ];
        } else if (this.message.data.type === 3) {
          return [{ value: 3, text: "売却", color: "is-primary" }];
        } else if (this.messageType === 4) {
          return [{ value: 4, text: "問い合せ", color: "" }];
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    messageId: function () {
      if (this.$route.params) {
        return this.$route.params.id;
      } else {
        return null;
      }
    },
    messageType() {
      //新規作成時のみ仕様
      if (this.$route.query) {
        return Number(this.$route.query.messageType);
      } else {
        return null;
      }
    },
    realEstateId: function () {
      if (this.$route.query) {
        return this.$route.query.realEstateId;
      } else {
        return null;
      }
    },
    isNewPage: function () {
      return this.messageId === "new";
    },
    destinationId: function () {
      if (this.$route.query) {
        return this.$route.query.uid;
      } else {
        return null;
      }
    },
    threads: function () {
      const mes = [];
      for (const idx in this.message.data.threads) {
        mes.push({
          id: String(idx),
          data: this.message.data.threads[idx],
        });
      }
      return mes;
    },
    uploadPath() {
      return "docs";
    },
    isDocs() {
      let docsCount = 0;
      for (const i in this.message.data.threads) {
        if ("docs" in this.message.data.threads[i]) {
          docsCount += this.message.data.threads[i].docs.length;
        }
      }
      return docsCount;
    },
    isAllowedViewingMail() {
      return (
        this.$$user$isPremium ||
        this.message.data.type === 1 ||
        this.message.data.type === 3 ||
        this.message.data.type === 4
      );
    },
  },
  methods: {
    async init() {
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.isLoading = true;
        const uid = this.auth.uid;
        this.thread = this.$$message$getNewThread();
        this.templates = await mtemplate.loads();
        if (this.isNewPage) {
          if (this.messageType === 4) {
            this.message = this.$$message$getNewMessage();
            this.message.data.type = 4;
            const realEstate = await this.$$realtor$loadRealEstate(
              this.realEstateId
            );
            this.message.realtor = await this.$$realtor$loadUser(
              realEstate.data.staff
            );
            this.messageRealEstates.push(await this.loadRealEstate(realEstate));
          }
        } else {
          this.$$message$asRead(this.messageId, this.me.user.id);
          this.message = await this.$$message$loadMessage(this.messageId);
          const ps = [];
          for (const rid of this.message.data.realtorRealEstates) {
            ps.push(this.loadRealtorRealEstateById(rid));
          }
          for (const rid of this.message.data.realEstates) {
            ps.push(this.loadRealEstateById(rid));
          }
          this.messageRealEstates = await Promise.all(ps);
          this.defaultOpenedDetails = [
            String(this.message.data.threads.length - 1),
          ];
        }
        this.favorite = await favorite.load(uid, this.message.realtor.id);
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.messagebox) {
            this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
          }
        });
        /* loadingComponent.close(); */
      }
    },
    async onClickTemplate(template) {
      this.thread.contents = template.data.message;
    },
    async send() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.isNewPage) {
          const mes = await this.$$message$createMessage(
            this.auth.uid,
            this.auth.uid,
            this.message.realtor.id,
            this.message,
            this.thread,
            this.fetchIdFromEstates(this.messageRealEstates),
            []
          );

          await this.$$message$sendMailMessageToRealtor(mes.id);
          await this.$router.go(-1);
        } else {
          const mes = await this.$$message$sendMessage(
            this.auth.uid,
            this.auth.uid,
            this.message.data.realtor,
            this.message,
            this.thread
          );
          await this.$$message$sendMailMessageToRealtor(mes.id);
          this.init();
        }
        this.$$toast$openSuccess("メッセージを送信しました。");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("メッセージの送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
    async address(dt) {
      const text = await this.$$area$generateTextOfAddress(dt);
      return text;
    },
    latestThread(m) {
      const ln = m.replace("\n", "");
      return ln;
    },
    async loadRealtorRealEstateById(rid) {
      const r = await this.$$realtor$loadRealEstate(rid);
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    async loadRealEstateById(rid) {
      const r = await this.$$realEstate$loadRealEstate(rid);
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    async loadRealEstate(r) {
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      return r;
    },
    getUser: function (uid) {
      if (this.message) {
        if (this.message.investor.id === uid) {
          return this.message.investor;
        }
        if (this.message.realtor.id === uid) {
          return this.message.realtor;
        }
        return null;
      } else {
        return null;
      }
    },
    fetchIdFromEstates(estates) {
      return estates.map((e) => e.id);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  async mounted() {
    await this.init();
    if (!this.isAllowedViewingMail) {
      this.$router.push({
        name: "investor-message",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.message-realtor {
  display: flex;
  @include sp {
    flex-direction: column;
  }
  &__realtor {
    flex: 1;
    @include sp {
      flex: unset;
      width: 100%;
    }
  }
  &__buyingNeeds {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    width: 200px;
    background-color: #e6effc;
    // padding: 0 18px;
    @include sp {
      height: 120px;
      width: 100%;
      margin-top: 10px;
      margin-left: 0px;
    }
    &__title {
      margin-top: 33px;
      @include sp {
        margin-top: 14px;
      }
      > p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 24px;
      }
    }
    &__message {
      margin-top: 8px;
      margin-bottom: 11px;
      @include sp {
        margin-top: 4px;
        margin-bottom: 8px;
      }
      > p {
        font-size: 12px;
        color: #4a4a4a;
        text-align: center;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
}

.replayTitle {
  margin-top: 16px;
  background-color: #f4f4f4;
  margin-left: -46px;
  margin-right: -46px;
  @include lg {
    margin-left: -100px;
    margin-right: -100px;
  }
  @include sp {
    margin-left: -16px;
    margin-right: -16px;
  }
  &__txt {
    font-size: 24px;
    line-height: 48px;
    padding-left: 46px;
    color: #787878;
    @include lg {
      padding-left: 100px;
    }
    @include sp {
      padding-left: 16px;
      font-size: 20px;
    }
  }
}

.replayMessage {
  margin-top: 18px;
  margin-bottom: 24px;
  &__txt {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    letter-spacing: 0;
    @include sp {
      margin-top: 14px;
      margin-bottom: 20px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}

.replayTemplate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;

  width: 100%;
  margin-bottom: 22px;
  background-color: #e6effc;
  padding-left: 23px;
  padding-top: 12px;
  padding-bottom: 12px;

  @include sp {
    padding-left: 16px;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }

  &__txt {
    width: 103px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    line-height: 19.6px;
  }
  &__btns {
    margin-left: 11px;
    @include sp {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  &__btn {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 11px;
    @include sp {
      margin-left: 0px;
    }
  }
}

.nonactive-user-box {
  &__wrapper {
    margin-top: 32px;
    background-color: #fdebeb;
    border: 2px solid red;
  }

  &__inner {
    width: 100%;
    padding: 12px;
  }
  &__text {
    width: 100%;
    // text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: red;
  }
}

.rank {
  width: 23px;
  height: 23px;
  background-color: lightgray;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-flow: column;
}

.submitForm {
  margin-top: 30px;
}

.is-dt {
  margin-top: 3px;
  @include mobile {
    margin-top: 0px;
  }
}
.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

.message-content {
  width: auto;
  @include mobile {
    width: 240px;
  }
}

.message-detial {
  padding: 0;
  &__box {
    width: 100%;
    box-shadow: none;
    padding: 16px;
  }
}

.message-wrap {
  margin: 45px 0px 0px 0px;
  &__title {
    display: inline-block;
    margin: 0px;
    width: 80%;
    @include sp {
      width: 100%;
    }
    @include md {
      width: 70%;
    }
  }
  &__type {
    display: inline-block;
    width: 20%;
    text-align: right;
    @include sp {
      width: 100%;
      font-size: 14px;
    }
    @include md {
      width: 30%;
    }
  }
  &__exclusive-label {
    width: 11%;
    display: inline-block;
    margin-right: 5px;
    @include md {
      width: 14%;
    }
    @include sp {
      width: 30%;
      margin-right: 5px;
    }
  }
  &__exclusive-title {
    width: 85%;
    display: inline-block;
    vertical-align: 4px;
    @include sp {
      width: 67%;
    }
  }
}

.real-estate-title {
  background-color: white;
  color: black;
  font-weight: bold;
  border-bottom: none;
  padding-bottom: 0px;
}

.z-section {
  &__back-to-list {
    margin-bottom: 20px;
  }
}

.message-title-separator {
  margin-top: 0px;
  margin-bottom: 10px;
}

.box {
  &__realestate-list {
    padding-top: 0px;
    box-shadow: none;
  }
  &__price {
    margin-bottom: 0px;
    margin-top: 10px;
    .offer-price {
      font-size: 1.1rem !important;
    }
  }
}

.sp-none {
  @include sp {
    display: none;
  }
}
</style>
