<template>
  <router-link :to="{ name: 'investor-premium' }">
    <b-button class="memberships-button" @click="$emit('click', $event)">
      <span class="memberships-button__label">
        <b-icon icon="key-variant" v-if="isKeyIconActive"></b-icon>
        <span
          class="memberships-button__label__txt"
          v-if="$$user$alreadyUsedTrial"
          >プレミアムプランに変更する</span
        >
        <span class="memberships-button__label__txt" v-else
          >90日間の無料体験をはじめる</span
        >
      </span>
    </b-button>
  </router-link>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    callback: Function,
    isKeyIconActive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.memberships-button {
  width: 330px;
  height: 3.6em;
  border-color: $important;
  background-color: $important;
  color: #fff;
  font-size: 20px;
  @include sp {
    width: 234px;
    height: 3.4em;
    font-size: 14px;
  }
  &__label {
    display: block;
    font-weight: bold;
    letter-spacing: -0.05em;
    line-height: 1;
    &__txt {
      line-height: 30px;
      @include sp {
        line-height: 18px;
      }
    }
  }
  &__sub {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 1;
    @include sp {
      font-size: 11px;
    }
  }
  /deep/ .icon {
    margin: 0;
    vertical-align: middle;
    .mdi::before {
      font-size: 1em;
      transform: scaleX(-1);
    }
  }
}
</style>
