<template>
  <div class="message-realtor-realestate-warp">
    <div class="columns i-multiline is-mobile">
      <template v-for="(r, i) of realEstates">
        <div :key="r.id" class="column is-narrow">
          <div class="count">提案{{ i + 1 }}</div>
          <router-link :to="route(r)">
            <ZImageTileView :newArrivalObject="r" />
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ZImageTileView from "@/views/investor/home/parts/ZImageTileView.vue";

export default {
  name: "realtor-realestate-list",
  components: {
    ZImageTileView: ZImageTileView,
  },
  props: {
    realEstates: {
      type: [Array],
      default: () => [],
    },
    realtor: {
      type: [Boolean],
      default: false,
    },
    investor: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    route(realEstate) {
      let name = null;
      const id = realEstate.id;
      if (this.investor) {
        if (realEstate.data.isInvetor) {
          name = "investor-collection-realestatepreview";
        }
        if (realEstate.data.isReatlor) {
          name = "investor-search-realestates-detail";
        }
      }
      if (this.realtor) {
        if (realEstate.data.isInvetor) {
          name = "realtor-search-realestates-detail";
        }
        if (realEstate.data.isReatlor) {
          name = "realtor-setting-realestates-preview";
        }
      }
      return {
        name: name,
        params: {
          id: id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message-realtor-realestate-warp {
  width: 100%;
  height: 332px;
  overflow-y: hidden;
  overflow-x: scroll;
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.message-realtor-realestate-warp {
  height: 320px;
}

// for Edge hack
_:-ms-lang(x)::backdrop,
.message-realtor-realestate-warp {
  height: 320px;
}

.count {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
