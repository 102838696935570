<template>
  <div>
    <section>
      <ZHeader title="メッセージ" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="columns">
          <div class="column is-narrow">
            <ZEditAccordion
              v-if="bulma.isMobile !== undefined"
              title="絞り込み検索条件"
              icon="magnify"
              isProtrude
              :opened="!bulma.isMobile"
            >
              <p class="menu__title">メッセージ種別</p>
              <ul>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.message.proposal"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">提案オファー</span>
                  </div>
                </li>
                <li class="exclusive">
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.message.exclusive"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">エクスクルーシブ</span>
                  </div>
                </li>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.message.buying"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">売却オファー</span>
                  </div>
                </li>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.message.contact"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">問い合わせ</span>
                  </div>
                </li>
              </ul>
              <hr />
              <p class="menu__title">スコア</p>
              <div class="menu__select">
                <z-select v-model="filter.score.from" :options="scoreFrom" />
                <z-select v-model="filter.score.to" :options="scoreTo" />
              </div>
              <hr />
              <p class="menu__title">ステータス</p>
              <ul>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox v-model="filter.status.unRead" type="is-info" />
                    <span class="menu__checkBox__label">未読</span>
                  </div>
                </li>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox v-model="filter.status.asRead" type="is-info" />
                    <span class="menu__checkBox__label">既読</span>
                  </div>
                </li>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.status.unanswered"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">未返信</span>
                  </div>
                </li>
                <li>
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.status.withReply"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">返信あり</span>
                  </div>
                </li>
              </ul>
              <button
                class="button is-primary menu__button"
                @click="execFilter"
              >
                検索する
              </button>
            </ZEditAccordion>
          </div>
          <div class="column">
            <template v-if="!isLoading">
              <h4 class="list-title">メッセージ一覧</h4>
              <b-table
                :data="messages"
                :row-class="
                  (row, index) =>
                    notification(row) === 1 ? 'is-asNotRead' : 'is-asRead'
                "
                :hoverable="true"
                :narrowed="true"
                custom-row-key="id"
                @click="onRowClick"
              >
                <template slot-scope="props">
                  <b-table-column field="id" width="8">
                    <div class="content message-list__icon">
                      <template v-if="notification(props.row) === 0">
                        <span class="icon">
                          <i class="mdi mdi-redo-variant"></i>
                        </span>
                      </template>
                      <template v-else-if="notification(props.row) === 1">
                        <span class="icon has-text-unread">
                          <i class="mdi mdi-circle"></i>
                        </span>
                      </template>
                      <template v-else-if="notification(props.row) === 2">
                        <span class="icon">
                          <i class="mdi mdi-minus"></i>
                        </span>
                      </template>
                    </div>
                  </b-table-column>
                  <b-table-column
                    field="data.latestScore"
                    label="投資家"
                    width="100"
                  >
                    <div class="content messagesBox__column__content">
                      <p class="is-size-7">
                        <span class="rank">{{
                          $$ks.getRank(
                            props.row.investor.user.data.latestScore.rank
                          ).text
                        }}</span>
                        {{ props.row.investor.user.data.latestScore.score
                        }}<br />
                        <template v-if="!hideProfile(props.row)">
                          {{ props.row.investor.user.data.base.name }}
                        </template>
                        <template v-else> 未公開 </template>
                      </p>
                    </div>
                  </b-table-column>
                  <b-table-column
                    v-if="bulma.isMobile !== undefined"
                    field="data.threads"
                    :label="bulma.isMobile ? '' : 'メッセージ'"
                  >
                    <div class="content message-content">
                      <template
                        v-if="
                          $$ks.message.getType(props.row.data.type).value === 1
                        "
                      >
                        <div class="message-content__exclusive-label">
                          <img src="@/assets/message/label-exclusive-S.png" />
                        </div>
                        <z-truncator
                          class="is-size-7 message-content__title message-content__exclusive-title"
                          :text="props.row.data.title"
                          :line="1"
                        ></z-truncator>
                      </template>
                      <template v-else>
                        <z-truncator
                          class="is-size-7 message-content__title"
                          :text="props.row.data.title"
                        ></z-truncator>
                      </template>
                      <z-truncator
                        class="has-text-gray is-size-7"
                        :text="latestThread(props.row)"
                      ></z-truncator>
                    </div>
                  </b-table-column>
                  <b-table-column field="data.types" label="種別" width="78">
                    <div class="message-type has-text-centered">
                      <template
                        v-if="
                          $$ks.message.getType(props.row.data.type).value ===
                            3 ||
                          $$ks.message.getType(props.row.data.type).value === 4
                        "
                      >
                        {{ $$ks.message.getType(props.row.data.type).en }}<br />
                        ({{ $$ks.message.getType(props.row.data.type).text }})
                      </template>
                    </div>
                  </b-table-column>
                  <b-table-column
                    field="data.latestSendDt"
                    label="送信日"
                    width="80"
                  >
                    <div class="content">
                      <p class="is-dt is-size-7">
                        {{ todayFormat(props.row.data.latestSendDt) }}
                      </p>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
              <div class="message-pagination__wrap">
                <ZPagination
                  :total="total"
                  :perPage="perPage"
                  :page.sync="page"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
import * as message from "@/modules/message/message";

export default {
  name: "message-index",
  data: function () {
    return {
      filter: {
        message: {
          proposal: false,
          exclusive: false,
          buying: false,
          contact: false,
        },
        score: {
          from: 0,
          to: 1000,
        },
        status: {
          unRead: false,
          asRead: false,
          unanswered: false,
          withReply: true,
        },
      },
      isLoading: true,
      total: 0,
      page: 1,
      perPage: 50,
      sortType: 1,
      // currentPage: 0,
      messages: [],
      scoreFrom: kubuns.filters.score.from,
      scoreTo: kubuns.filters.score.to,
      messageTypesHideName: [1, 2, 3],
    };
  },
  watch: {
    async page() {
      const loadingComponent = this.$loading.open();
      try {
        await this.loadMessages();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
  },
  methods: {
    todayFormat(dt) {
      return this.$$calender$todayFormat(new Date(dt));
    },
    hideProfile(message) {
      return !message.data.threads.some(
        (t) => t.from === message.data.investor
      );
    },
    onRowClick(row) {
      const routeData = this.$router.resolve({
        name: "realtor-message-detail",
        params: { id: row.id },
      });
      window.open(routeData.href, "_blank");
    },
    async loadMessages() {
      const uid = this.me.user.id;
      const rs = await message.searchByfilter(
        {
          ...this.filter,
          realtor: uid,
        },
        this.sortType,
        this.page,
        this.perPage,
        uid
      );
      this.messages = rs.hits.map((m) => m._source);
      this.total = rs.total.value;
    },
    async execFilter() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        await this.loadMessages();
        this.isLoading = false;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        await this.loadMessages();
        this.isLoading = false;
        return;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    notification(message) {
      return this.$$message$JudgmentNotification(this.auth.uid, message);
    },
    latestThread(message) {
      const m = message.data.threads.slice(-1)[0];
      const ln = m.contents.replace("\n", "");
      return ln;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message-pagination__wrap {
  margin-top: 12px;
  margin-bttom: 12px;
}

.menuBox {
  border: solid 1px #dddddd;
  border-radius: 8px;
  overflow: hidden;
  &__top {
    display: flex;
    color: #0664d8;
    line-height: 43px;
    background-color: #eff4fc;
    &__icon {
      color: #0664d8;
      height: 43px;
      padding: 7px 0 0 15px;
    }
  }
}

.menu {
  padding: 15px;
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin: 13px 0 7px;
  }
  &__checkBox {
    font-size: 14px;
    &__label {
      color: #333333;
      vertical-align: 4px;
    }
  }
  &__select {
    padding-right: 20px;
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
}

.rank {
  width: 12px;
  height: 12px;
  color: #ffffff;
  background-color: #0a244e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-flow: column;
}

//.message-content {
//  width: auto;
//  @include mobile {
//    width: 240px;
//  }
//}

//.is-dt {
//  margin-top: 3px;
//  @include mobile {
//    margin-top: 0px;
//  }
//}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: #dbdbdb;
//  border-bottom: solid 2px #707070;
//  color: black;
//}

.blankBox {
  height: 300px;
}

.exclusive {
  margin-left: 25px;
}

.message-type {
  font-size: 12px;
}

.message-list {
  &__icon {
    @include lg {
      margin-top: 7px;
    }
    @include md {
      margin-top: 6px;
    }
    @include sp {
      margin-top: 3px;
    }
  }
}

.message-content {
  &__exclusive-label {
    width: 18%;
    display: inline-block;
    @include md {
      width: 30%;
    }
    @include sp {
      width: 30%;
    }
  }
  &__exclusive-title {
    width: 50%;
    display: inline-block;
    vertical-align: 4px;
    @include md {
      width: 60%;
    }
    @include sp {
      width: 65%;
    }
  }
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.message-content__exclusive-label {
  width: 15%;
  margin-right: 5px;
}

// for Edge hack
_:-ms-lang(x)::backdrop,
.message-content__exclusive-label {
  margin-right: 5px;
}
</style>
