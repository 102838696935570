<template>
  <b-modal
    class="z-modal"
    :class="isModalOnModal ? 'is-modalOnModal' : ''"
    :active.sync="internalIsActive"
    :can-cancel="isShowCloseBtn"
    has-modal-card
  >
    <div class="modal-card" :style="{ maxWidth: `${maxWidth}px` }">
      <a
        class="modal-card__closeBtn"
        @click="onCloseClick"
        v-if="isShowCloseBtn"
      >
        <b-icon icon="close" size="is-midium"></b-icon>
      </a>
      <p class="modal-card__title" v-if="title !== null">{{ title }}</p>
      <section class="modal-card-body">
        <slot name="content"></slot>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ZModal",
  props: {
    // モーダルが開いているか
    isActive: {
      type: Boolean,
      default: false,
    },
    // モーダルの最大幅
    maxWidth: {
      type: Number,
      default: 640,
    },
    // タイトル
    title: {
      type: String,
      default: null,
    },
    // 閉じるボタンを表示するか
    isShowCloseBtn: {
      type: Boolean,
      default: true,
    },
    // モーダルonモーダルになる場合
    isModalOnModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalIsActive: {
      get() {
        return this.isActive;
      },
      set(newVal) {
        if (!newVal && this.isActive) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    onCloseClick() {
      this.internalIsActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-modal {
  padding: 16px;
  /deep/ .modal-close {
    display: none;
  }
  .modal-card {
    width: 100%;
    max-width: 640px;
    min-width: 280px;
    padding: 35px 0 32px;
    position: relative;
    background: #fff;
    border: 1px solid #979797;
    border-radius: 8px;
    &__closeBtn {
      top: 15px;
      right: 15px;
      position: absolute;
      color: #707070;
    }
    &__title {
      padding-left: 28px;
      font-size: 20px;
      font-weight: bold;
      color: #474747;
    }
  }
  .modal-card-body {
    margin-top: 8px;
    padding: 0 28px;
  }
  &.is-modalOnModal {
    z-index: $zindex-modalOnModal;
  }
}
</style>
