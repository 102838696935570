<template>
  <ZModal title="希望売却設定" :isActive="isActive" @close="close">
    <template v-slot:content>
      <div class="editSaleRealEstate">
        <p class="editSaleRealEstate__name">{{ realEstate.data.name }}</p>

        <z-price-input
          label="売却希望金額"
          name="askingPrice"
          v-model="realEstate.data.saleSetting.askingPrice"
          :validate="'required|price_min_value:1'"
          maxlength="12"
          placeholder="例)20,000,000"
          additionalMessage=""
          isRequired
        />

        <div class="editSaleRealEstate__incomePrice">
          <z-field label="年間想定収入" maxlength="12" :disabled="true">
            <p>{{ annualEstimatedIncomePrice }}</p>
          </z-field>
        </div>

        <div
          class="edit__section edit__section--box editSaleRealEstate__couponYield"
        >
          <z-field
            label="売却利回り"
            helpMessage="年間想定収入／売却希望金額×１００"
            message="(自動計算)"
          >
            <p>{{ couponYieldPercentage }}</p>
          </z-field>
        </div>

        <div class="editSaleRealEstate__publicBox">
          <z-radio
            name="publicType"
            label="情報公開設定"
            v-model="realEstate.data.saleSetting.publicType"
            :options="publishTypes"
            :validate="'required|min_value:1'"
            isRequired
          />
          <p>（不動産会社等から売却の提案メッセージを受けられます）</p>
        </div>

        <div
          class="edit__section edit__section--box editSaleRealEstate__confirm"
        >
          <div class="editSaleRealEstate__confirm__needs">
            <div class="editSaleRealEstate__confirm__needs__label">
              <p>買いニーズ マッチング件数</p>
              <p></p>
              <div class="helpIconWrapper">
                <ZHelpIcon
                  class="helpIcon"
                  help="本売却希望条件に対し、StockFromer内の投資家が設定する不動産購入ニーズとマッチする件数。マッチする件数が多いほど早期で売却できる可能性が高い等、売却希望条件に対するマーケット評価の指標となる。"
                />
              </div>
            </div>
            <p class="editSaleRealEstate__confirm__needs__count">
              <span class="count">
                <template v-if="!isLoading">
                  {{ buyingNeeds }}
                </template>
                <template v-else>
                  <img src="@/assets/ajax-loader.gif" />
                </template> </span
              >件
            </p>
          </div>
          <b-button
            class="button z-button is-primary is-wide is-rounded"
            :disabled="$$validator$invalid"
            @click="saveSaleSetting"
          >
            設定する
          </b-button>
        </div>
      </div>
    </template>
  </ZModal>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "EditSaleRealEstate",
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publishTypes: this.$$ks.realEstate.publicTypes,
      realEstate: this.$$realEstate$getNewRealEstate(),
      buyingNeeds: 0,
      couponYieldRate: 0.5,
      isBuyingNeedsHelpActive: false,
      isLoading: false,
    };
  },
  methods: {
    calculateBuyingNeeds: debounce(async function (realEstate) {
      // console.log("test");
      try {
        this.isLoading = true;
        const result = await this.$$realEstate$countBuyingNeeds(
          realEstate,
          realEstate.data.saleSetting.askingPrice
        );
        this.buyingNeeds = result.count;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("買いニーズ マッチング件数の取得に失敗しました");
      } finally {
        this.isLoading = false;
        // nop
      }
    }, 1000),
    calculateCouponYieldRate(realEstate) {
      this.couponYieldRate = this.$$realEstate$calcYieldPerYear(
        realEstate.data.annualEstimatedIncome,
        realEstate.data.saleSetting.askingPrice
      );
    },
    async saveSaleSetting() {
      try {
        const realEstate = this.$$cloneDeep(this.realEstate);
        realEstate.data.saleSetting.couponYieldRate = this.couponYieldRate;

        await this.$$realEstate$saveSaleSettingOfRealEstate(
          this.auth.uid,
          realEstate,
          realEstate.data.saleSetting.askingPrice
        );

        await this.$$investor$saveOnlyScore(this.auth.uid, this.me.user.id);

        // console.log(result);

        this.$emit("input", realEstate);

        this.close();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        // nop
      }
    },
    close() {
      this.$emit("update:isActive", false);
    },
    onClickBuyingNeedsHelp() {
      this.isBuyingNeedsHelpActive = !this.isBuyingNeedsHelpActive;
    },
  },
  computed: {
    couponYieldPercentage() {
      return this.$$rete$rateFormat(this.couponYieldRate);
    },
    annualEstimatedIncomePrice() {
      return this.$$price$priceFormat(
        this.realEstate.data.annualEstimatedIncome
      );
    },
  },
  watch: {
    isActive(newVal) {
      if (newVal) {
        this.isLoading = true;
        this.calculateBuyingNeeds(this.realEstate);
        this.calculateCouponYieldRate(this.realEstate);
      }
    },
    realEstate: {
      handler(newVal) {
        this.isLoading = true;
        this.calculateBuyingNeeds(newVal);
        this.calculateCouponYieldRate(newVal);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        if (newVal) {
          this.realEstate = this.$$cloneDeep(newVal);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.editSaleRealEstate {
  min-width: 580px;
  @include sp {
    min-width: 0;
  }
  &__name {
    margin: 20px 0 40px;
  }
  &__couponYield {
    margin-bottom: 20px;
    /deep/ .z-field__info__main__required {
      display: none;
    }
    /deep/ .z-field {
      margin-bottom: 0;
      .notification {
        margin-bottom: 8px;
      }
    }
  }
  &__incomePrice {
    /deep/ .z-field__info__main__required {
      display: none;
    }
  }
  &__publicBox {
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    @include sp {
      display: block;
    }
    p {
      font-size: 12px;
      margin-bottom: 10px;
      @include sp {
        margin-bottom: 0px;
      }
    }
    /deep/ .z-field {
      margin-bottom: 0;
      &__inputs {
        .block {
          @include sp {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  &__confirm {
    padding-bottom: 22px;
    background-color: rgba(#0262d8, 0.1);
    text-align: center;
    &__needs {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        flex-direction: column;
      }
      &__help {
        margin-left: 4px;
        margin-top: 4px;
        /deep/ i::before {
          font-size: 20px;
        }
      }
      &__label {
        font-weight: bold;
        display: flex;
      }
      &__count {
        margin-left: 16px;
        font-size: 14px;
        font-weight: bold;
        color: #0262d8;
        .count {
          margin-right: 4px;
          margin-bottom: 4px;
          display: inline-block;
          font-size: 24px;
          vertical-align: middle;
          img {
            margin-top: 6px;
            width: 24px;
          }
        }
      }
    }

    .helpIconWrapper {
      margin: 0 0 4px 4px;
      /deep/ .b-tooltip {
        &::after {
          transform: translateX(-90%);
        }
      }
    }

    /deep/ .notification {
      padding: 6px;
      color: #6d7278;
      font-size: 12px;
      .delete {
        right: 5px !important;
        top: 5px !important;
        background-color: transparent;
        &::before,
        &::after {
          background-color: #333333;
        }
        &::before {
          width: 80%;
        }
        &::after {
          height: 80%;
        }
      }
    }
  }
}
</style>
