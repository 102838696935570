<template>
  <div class="message-realtor-realestate-warp">
    <div class="columns i-multiline is-mobile">
      <template v-for="(r, i) of message.realEstates">
        <div :key="r.id" class="column is-narrow">
          <div class="count">提案{{ i + 1 }}</div>
          <template v-if="message.data.type === 3">
            <router-link
              :to="{
                name: 'admin-setting-messages-investor-realEstate',
                params: {
                  rid: r.id,
                },
              }"
            >
              <ZImageTileView :newArrivalObject="r" />
            </router-link>
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'admin-setting-messages-realtor-realEstate',
                params: {
                  rid: r.id,
                },
              }"
            >
              <ZImageTileView :newArrivalObject="r" />
            </router-link>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ZImageTileView from "@/views/investor/home/parts/ZImageTileView.vue";

export default {
  name: "admin-realestate-list",
  components: {
    ZImageTileView: ZImageTileView,
  },
  props: {
    message: null,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message-realtor-realestate-warp {
  width: 100%;
  height: 300px;
  overflow-y: hidden;
  overflow-x: scroll;
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.message-realtor-realestate-warp {
  height: 320px;
}

// for Edge hack
_:-ms-lang(x)::backdrop,
.message-realtor-realestate-warp {
  height: 320px;
}

.count {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
