<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="admin-setting-zero--wrap">
        <table>
          <thead>
            <tr>
              <th>登録日</th>
              <th>UID</th>
              <th>名前</th>
              <th>email</th>
              <th>TEL</th>
              <th>年収</th>
              <th>金融資産</th>
              <th>借入金額</th>
              <th>業種</th>
              <th>職種</th>
              <th>勤め先</th>
              <th>開始時期</th>
              <th>誕生日</th>
              <th width="80px">住所</th>
              <th width="420px">リクエスト</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="iq in inquiries">
              <tr :key="iq.id">
                <td>
                  {{ $$calender$dateTimeFormat(iq.data.createdDt) }}
                </td>
                <td>
                  <p class="admin-setting-zero--p">{{ iq.data.uid || "-" }}</p>
                </td>
                <td>
                  {{ iq.data.data.data.name }}
                </td>
                <td>
                  <p class="admin-setting-zero--p">
                    {{ iq.data.data.data.email }}
                  </p>
                </td>
                <td>
                  {{ iq.data.data.data.phoneNumber }}
                </td>
                <td>
                  {{ $$price$priceFormat(iq.data.data.data.income.value) }}
                </td>
                <td>
                  {{
                    $$price$priceFormat(iq.data.data.data.financialAsset.value)
                  }}
                </td>
                <td>
                  {{ $$price$priceFormat(iq.data.data.data.debt.value) }}
                </td>
                <td>
                  {{ iq.data.data.data.categoryOfBusiness.text }}
                </td>
                <td>
                  {{ iq.data.data.data.typeOfOccupation.text }}
                </td>
                <td>
                  {{ iq.data.data.data.placeOfEmployment }}
                </td>
                <td>
                  {{ iq.data.data.data.whenStartedWorking.year.value }}/{{
                    iq.data.data.data.whenStartedWorking.month.value
                  }}
                </td>
                <td>
                  {{ iq.data.data.data.birthday.year.value }}/{{
                    iq.data.data.data.birthday.month.value
                  }}/{{ iq.data.data.data.birthday.day.value }}
                </td>
                <td>
                  {{ iq.data.data.data.prefecture.text }}
                </td>
                <td>{{ iq.data.data.data.request }}<br /></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </section>
    <!--
    <hr />
    {{ inquiries }}
    -->
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

export default {
  name: "admin-setting-zero",
  data: function () {
    return {
      isLoading: true,
      inquiries: [],
    };
  },
  async mounted() {
    const qs = await firebase.db
      .collection("pb_zero_inquiry_v1")
      .orderBy("updatedDt", "desc")
      .get();

    for (const doc of qs.docs) {
      const id = doc.id;
      const data = doc.data();
      if (!data.uid) {
        this.inquiries.push({
          id,
          data,
        });
      } else {
        const user = data.data.data.user;
        // console.log(user.base.birthday);
        const birthday = this.$$calender$toDate(user.base.birthday);
        // console.log(birthday);
        this.inquiries.push({
          id,
          data: {
            ...data,
            data: {
              ...data.data,
              data: {
                ...data.data.data,
                name: user.base.name,
                email: user.base.email,
                income: { value: user.latestPL.salary, text: "" }, //ご年収
                financialAsset: {
                  value: user.latestBS.netAssets.sum,
                  text: "",
                }, //現在の金融資産
                debt: { value: user.latestBS.liabilities.sum, text: "" }, //現在のお借入金額
                categoryOfBusiness:
                  user.profile.currentOfCareer.categoryOfBusiness, //業種
                typeOfOccupation: user.profile.currentOfCareer.typeOfOccupation, //職種
                placeOfEmployment: user.profile.currentOfCareer.name, //お勤め先
                whenStartedWorking: {
                  //お勤め開始時期
                  year: {
                    value: user.profile.currentOfCareer.period.from.year,
                    text: "",
                  },
                  month: {
                    value: user.profile.currentOfCareer.period.from.month,
                    text: "",
                  },
                },
                birthday: {
                  //生年月日
                  year: { value: birthday.getFullYear(), text: "" },
                  month: { value: birthday.getMonth() + 1, text: "" },
                  day: { value: birthday.getDate(), text: "" },
                },
                prefecture: user.base.prefecture, //都道府県
                phoneNumber: user.base.phoneNumber, //電話番号
                // "request": "", //ご質問・ご要望
              },
            },
          },
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.admin-setting-zero {
  &--wrap {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  &--p {
    overflow-wrap: break-word;
    word-break: break-all;
  }
}

table {
  width: 100%;
  // width: 1024px;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
}
tr:nth-child(2n + 1) {
  background: #e9faf9;
}
th {
  padding: 10px;
  background: #778ca3;
  border-right: solid 1px #778ca3;
  color: #ffffff;
}
th:last-child {
  border-right: none;
}
td {
  padding: 10px;
  border-right: solid 1px #778ca3;
}
td:last-child {
  border-right: none;
}
</style>
