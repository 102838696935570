<template>
  <div class="plan">
    <ZHeader title="Sign Up" sub="サインアップ" />

    <section class="edit">
      <div class="edit__inner">
        <h3 class="plan__title">
          あなたの情報を入力しポートフォリオを作成します
        </h3>
        <p class="plan__texts">以下のステップにそって、情報入力します。</p>

        <PortfolioSteps forceAllAvtive />
        <p class="plan__texts">
          ポートフォリオ作成し、あなたの資産レベルをスコアリングします。<br />スコアを算出することよって、ご自身のスコアに応じた不動産の提案が受けられます。<br />
          また、似たスコアの投資家の投資経験や融資の状況を共有することで投資判断の指針にすることができます。
        </p>
        <!--
        <p class="plan__texts">

          ポートフォリオの作成にあたり、以下の必要情報を入力すると、よりご要望に沿った提案を受けられる<b>プレミアムプラン(90日間無料お試し)</b>へお申し込みいただけます。
        </p>
        <ul class="plan__labels">
          <li>収入源</li>
          <li>資産</li>
          <li>保有不動産</li>
          <li>買いニーズ</li>
        </ul>

        <PlanDetail />

        <div class="plan__buttons">
          <a
            class="button z-button is-text"
            href="https://stockformer.com/"
            target="_blank"
            >機能概要はこちら</a
          >
        </div>
          -->
        <div class="plan__buttons">
          <router-link
            id="investor-register-planintroduction-submit"
            class="button z-button is-primary is-wide is-rounded"
            :to="{ name: 'investor-register-workexperience' }"
          >
            次へ
          </router-link>
        </div>
        <!--
        <PlanAttention />
        -->
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioSteps from "./parts/PortfolioSteps";
// import PlanDetail from "../common/PlanDetail";
// import PlanAttention from "../common/PlanAttention";

export default {
  name: "Register-PlanIntroduction",
  // data: function() {
  //   return {
  //   };
  // },
  components: {
    PortfolioSteps,
    // PlanDetail,
    // PlanAttention
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.plan {
  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
  }
  &__texts {
    margin-bottom: 16px;
    text-align: center;
    @include sp {
      font-size: 14px;
    }
  }
  &__labels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    @include sp {
      font-size: 14px;
    }
    & > li {
      padding-left: 8px;
      white-space: nowrap;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 8px;
        border-radius: 50px;
        background-color: #000;
        vertical-align: middle;
      }
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  &__buttons {
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
  }
}
</style>
