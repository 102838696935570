<template>
  <div>
    <section>
      <ZHeader title="プロフィール" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <b-tabs
          :animated="false"
          size="is-small"
          v-model="activeTab"
          v-if="!isLoading"
        >
          <b-tab-item label="あなた">
            <UserProfilePage :editable="false" :user="me.user">
            </UserProfilePage>
            <hr />
            <div class="buttons is-centered">
              <router-link
                :to="{
                  name: 'realtor-setting-basic-edit',
                }"
                class="button is-primary z-button is-rounded"
                >プロフィール編集
              </router-link>
            </div>
          </b-tab-item>
          <!-- プライマリーだけ表示 -->
          <template v-if="user.data.role === 10">
            <b-tab-item label="コンサルタント一覧">
              <router-link
                :to="{
                  name: 'realtor-setting-users-detail',
                  params: { uid: 'new' },
                }"
                class="button is-primary z-button is-rounded is-small"
                >新規コンサルタント登録</router-link
              >
              <hr />
              <b-table
                :data="realtor.users"
                checkable
                custom-row-key="id"
                :striped="true"
                :hoverable="true"
              >
                <template slot-scope="props">
                  <!--
                    <b-table-column field="id">
                    <template v-if="props.row.data.role === 10">
                    <div class="tag" :class="roleKs(props.row.data.role).color">
                    {{ roleKs(props.row.data.role).text }}
                    </div>
                    </template>
                    <RealtorProfile :user="props.row" />
                    </b-table-column>
                  -->
                  <b-table-column field="id" label="詳細" width="20">
                    <router-link
                      :to="{
                        name: 'realtor-setting-users-detail',
                        params: { uid: props.row.id },
                      }"
                      class="button is-small"
                      >詳細</router-link
                    >
                  </b-table-column>
                  <b-table-column field="data.base.email" label="写真">
                    <z-profile-thumbnail
                      v-model="props.row.data.profile.image"
                    />
                  </b-table-column>
                  <b-table-column field="data.base.email" label="email">
                    <div class="content realtor-user-email">
                      <!-- <z-truncator
                        :text="props.row.data.base.email"
                      ></z-truncator> -->
                      {{ truncateEmail(props.row.data.base.email) }}
                    </div>
                  </b-table-column>
                  <b-table-column field="id" label="名前">
                    {{ props.row.data.base.name }}
                  </b-table-column>
                  <b-table-column field="id" label="所属・部署">
                    {{ props.row.data.company.department }}
                  </b-table-column>
                  <b-table-column field="id" label="役職">
                    {{ props.row.data.company.title }}
                  </b-table-column>
                  <b-table-column field="data.base.email" label="権限">
                    <template v-if="props.row.data.role === 10">
                      <div
                        class="tag"
                        :class="roleKs(props.row.data.role).color"
                      >
                        {{ roleKs(props.row.data.role).text }}
                      </div>
                    </template>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </b-tab-item>
          </template>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
// import RealtorUser from "./RealtorUser";

// import RealtorProfile from "@/components/realtor/RealtorProfile";
import UserProfilePage from "@/components/realtor/UserProfilePage";
//import RealtorProfile from "@/components/realtor/RealtorProfile";
//import UserBasic from "@/components/realtor/UserBasic";
//import UserProfile from "@/components/realtor/UserProfile";
//import UserCompany from "@/components/realtor/UserCompany";

export default {
  name: "realtor-setting-basic",
  components: {
    UserProfilePage: UserProfilePage,
    // RealtorProfile: RealtorProfile
    // UserBasic: UserBasic,
    // UserProfile: UserProfile,
    // UserCompany: UserCompany,
    // RealtorProfile: RealtorProfile
  },
  data: function () {
    return {
      isLoading: true,
      realtor: null,
    };
  },
  computed: {
    user() {
      return this.me.user;
    },
    activeTab: {
      get: function () {
        if (this.$route.query) {
          return Number(this.$route.query.at);
        } else {
          return null;
        }
      },
      set: function (val) {
        this.$router.push({ query: { at: val } });
      },
    },
  },
  methods: {
    roleKs: function (val) {
      return this.$$ks.get(this.$$ks.realtor.roles, val);
    },
    async init() {
      this.isLoading = true;
      this.realtor = await this.$$realtor$loadRealtorById(this.user.company.id);
      this.isLoading = false;
      return;
    },
    truncateEmail(email) {
      const isMobile = this.bulma && this.bulma.isMobile;
      const maxLength = 20;
      if (isMobile) {
        return email.length <= maxLength
          ? email
          : email.substr(0, maxLength) + "...";
      } else {
        return email;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realtor-user-email {
  @include sp {
    width: 200px;
  }
}
</style>
