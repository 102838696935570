<template>
  <div class="z-hejpAccordion">
    <div @click="toggle()" class="z-hejpAccordion__head">
      <p>{{ title }}</p>
      <a class="z-hejpAccordion__head__icon">
        <b-icon :icon="isActive ? 'chevron-up' : 'chevron-down'"> </b-icon>
      </a>
    </div>
    <transition>
      <div
        v-show="isActive"
        class="z-hejpAccordion__body"
        :class="isActive ? 'is-close' : ''"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ZHelpAccordion",
  inject: ["$validator"],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-hejpAccordion {
  margin: 12px 0;
  border: solid 1px #e1e1e1;
  border-radius: 6px;
  &__head {
    position: relative;
    padding: 8px ($edit-padding + 20px) 8px $edit-padding;
    background-color: #e1e1e1;
    font-size: 14px;
    color: #696969;
    @include sp {
      padding: 4px ($edit-padding-sp + 20px) 4px $edit-padding-sp;
      font-size: 17px;
    }
    &__icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #696969;
        &:hover {
          color: #696969;
        }
      }
    }
    &.is-protrude {
      margin-left: -($edit-padding);
      margin-right: -($edit-padding);
      padding: 12px ($edit-padding + 20px) 12px $edit-padding;
      background-color: rgba($primary, 0.06);
      border-bottom: none;
      font-size: 16px;
      color: $primary;
      cursor: pointer;
      @include sp {
        margin-left: -($edit-padding-sp);
        margin-right: -($edit-padding-sp);
        padding: 10px ($edit-padding-sp + 20px) 10px $edit-padding-sp;
      }
      .icon {
        color: $primary;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &__body {
    position: relative;
    padding: 12px $edit-padding;
    font-size: 12px;
  }
}
</style>
