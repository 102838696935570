<template>
  <div class="formAssets">
    <!-- 現金 -->
    <section class="edit__section">
      <!--
      <div v-if="isFirst" class="edit__premium">
        <b-icon
          class="edit__premium__icon"
          icon="check"
        />プレミアムプラン必須情報
      </div>
      -->
      <h3 class="edit__subtitle">現金</h3>

      <ul class="edit__list">
        <li
          v-for="(cash, i) in value.data.assets.cashes"
          :index="i"
          :key="i"
          class="edit__list__item"
        >
          <z-edit-accordion :title="'現金 ' + (i + 1)" isProtrude>
            <div>
              <z-radio
                label="預金種別"
                v-model="cash.type"
                :validate="'required|min_value:1'"
                :options="incomeCashTypes"
                isRequired
                isPrivate
              />

              <template v-if="!isRegister">
                <z-input
                  label="金融機関"
                  v-model="cash.bank"
                  maxlength="30"
                  isPrivate
                />

                <z-input
                  label="支店名"
                  v-model="cash.branch"
                  maxlength="30"
                  isPrivate
                />
              </template>
              <z-price-input
                label="金額"
                v-model="cash.amount"
                :validate="'required|price_min_value:1'"
                maxlength="12"
                placeholder="例)30,000,000"
                isRequired
                isPrivate
              />

              <div class="has-text-right">
                <delete-button label="削除する" @click="deleteCash(i)" />
              </div>
            </div>
          </z-edit-accordion>
        </li>
      </ul>

      <add-button label="追加する" @click="addCash()" />
    </section>
    <!-- / 現金 -->

    <hr class="edit__hr" />

    <!-- 有価証券 -->
    <section class="edit__section">
      <h3 class="edit__subtitle">有価証券</h3>

      <div class="z-field">
        <b-switch v-model="isActiveSecurity" type="is-info">
          有価証券を所有
        </b-switch>
      </div>

      <transition>
        <div v-if="isActiveSecurity">
          <ul class="edit__list">
            <li
              v-for="(security, i) in value.data.assets.securities"
              :index="i"
              :key="i"
              class="edit__list__item"
            >
              <z-edit-accordion :title="'有価証券 ' + (i + 1)" isProtrude>
                <div>
                  <z-radio
                    label="証券種別"
                    v-model="security.type"
                    :validate="'required|min_value:1'"
                    :options="incomeSecurityTypes"
                    isRequired
                    isPrivate
                  />

                  <z-input
                    label="金融機関"
                    v-model="security.bank"
                    maxlength="30"
                    isPrivate
                  />

                  <z-input
                    label="支店名"
                    v-model="security.branch"
                    maxlength="30"
                    isPrivate
                  />

                  <z-price-input
                    label="金額"
                    v-model="security.amount"
                    :validate="'required|price_min_value:1'"
                    maxlength="12"
                    placeholder="例)30,000,000"
                    additionalMessage="不動産収入を除いて入力してください"
                    isRequired
                    isPrivate
                  />

                  <div class="has-text-right">
                    <delete-button
                      label="削除する"
                      @click="deleteSecurities(i)"
                    />
                  </div>
                </div>
              </z-edit-accordion>
            </li>
          </ul>

          <add-button label="追加する" @click="addSecurities()" />
        </div>
      </transition>
    </section>
    <!-- / 有価証券 -->

    <hr class="edit__hr" />

    <!-- 保険 -->
    <section class="edit__section">
      <h3 class="edit__subtitle">保険</h3>

      <div class="z-field">
        <b-switch v-model="isActiveInsurance" type="is-info">
          保険を所有
        </b-switch>
      </div>

      <transition>
        <div v-if="isActiveInsurance">
          <ul class="edit__list">
            <li
              v-for="(insurance, i) in value.data.assets.insurances"
              :index="i"
              :key="i"
              class="edit__list__item"
            >
              <z-edit-accordion :title="'保険 ' + (i + 1)" isProtrude>
                <div>
                  <z-radio
                    label="保険種別"
                    v-model="insurance.type"
                    :validate="'required|min_value:1'"
                    :options="incomeInsuranceTypes"
                    isRequired
                    isPrivate
                  />

                  <z-input
                    label="金融機関"
                    v-model="insurance.bank"
                    maxlength="30"
                    isPrivate
                  />

                  <z-input
                    label="支店名"
                    v-model="insurance.branch"
                    maxlength="30"
                    isPrivate
                  />

                  <z-price-input
                    label="金額"
                    v-model="insurance.amount"
                    :validate="'required|price_min_value:1'"
                    maxlength="12"
                    placeholder="例)30,000,000"
                    additionalMessage="不動産収入を除いて入力してください"
                    isRequired
                    isPrivate
                  />

                  <div class="has-text-right">
                    <delete-button
                      label="削除する"
                      @click="deleteInsurances(i)"
                    />
                  </div>
                </div>
              </z-edit-accordion>
            </li>
          </ul>

          <add-button label="追加する" @click="addInsurances()" />
        </div>
      </transition>
    </section>
    <!-- / 保険 -->
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "FormAssets",
  inject: ["$validator"],
  props: {
    value: Object,
    isFirst: Boolean,
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActiveSecurity: false,
      isActiveInsurance: false,
      incomeCashTypes: kubuns.incomeCashTypes,
      incomeSecurityTypes: kubuns.incomeSecurityTypes,
      incomeInsuranceTypes: kubuns.incomeInsuranceTypes,
      selectedIncomeType: null,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  methods: {
    addCash() {
      let item = this.value.data.assets.cashes;
      item.push(this.$$investor$getNewCash());
    },
    deleteCash(index) {
      let item = this.value.data.assets.cashes;
      item.splice(index, 1);
    },
    addSecurities() {
      let item = this.value.data.assets.securities;
      item.push(this.$$investor$getNewSecurity());
    },
    deleteSecurities(index) {
      let item = this.value.data.assets.securities;
      item.splice(index, 1);
    },
    addInsurances() {
      let item = this.value.data.assets.insurances;
      item.push(this.$$investor$getNewInsurance());
    },
    deleteInsurances(index) {
      let item = this.value.data.assets.insurances;
      item.splice(index, 1);
    },
  },
  watch: {
    isActiveSecurity(newVal) {
      if (newVal && this.value.data.assets.securities.length < 1) {
        this.addSecurities();
      }
    },
    isActiveInsurance(newVal) {
      if (newVal && this.value.data.assets.insurances.length < 1) {
        this.addInsurances();
      }
    },
  },
  mounted() {
    if (this.value.data.assets.cashes.length < 1) this.addCash();
    if (this.value.data.assets.securities.length > 0)
      this.isActiveSecurity = true;
    if (this.value.data.assets.insurances.length > 0)
      this.isActiveInsurance = true;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-field {
  margin-bottom: 18px;
}
</style>
