<template>
  <div>
    <div
      class="columns is-centered is-multiline is-gapless signup-content__boxs"
    >
      <div class="column is-narrow">
        <section class="section signup-content">
          <div class="box signup-content__box">
            <div
              class="content has-text-centered has-text-white has-text-weight-semibold signup-content__box__content"
            >
              <div class="main-message">
                <p class="main-message__content">
                  StockFormerは<br />
                  今までにない全く新しい不動産投資の<br />
                  情報プラットフォームです。
                </p>
              </div>
              <div class="sub-message">
                <p>
                  新規で会員登録する場合は、<br />
                  以下「お申し込み」ボタンから<br />
                  ご登録（無料）いただけます。
                </p>
              </div>
              <a href="https://stockformer.com/">さらに詳しい情報をみる</a>
              <router-link
                :to="{ name: 'auth-investor-signup' }"
                class="button is-fullwidth is-rounded is-dark signup-content__box__button"
              >
                お申し込み
              </router-link>
            </div>
          </div>
        </section>
      </div>
      <div class="column is-narrow">
        <section class="section signin-content">
          <div class="box signin-content__box">
            <div class="content has-text-centered">
              <h5 class="content__title">WELCOME</h5>
            </div>
            <div class="content">
              <div class="z-input-vritical-warp form__mail">
                <z-input
                  v-model="email"
                  hideRequired
                  as="メールアドレス"
                  @blur="onBlurEmail"
                  type="email"
                  placeholder="メールアドレス"
                  maxlength="512"
                  :validate="'required|email'"
                  icon="email"
                  isRequired
                >
                </z-input>
              </div>
              <div class="z-input-vritical-warp form__password">
                <z-input
                  v-model="password"
                  @blur="onBlurPassword"
                  as="パスワード"
                  type="password"
                  placeholder="パスワード"
                  maxlength="128"
                  :validate="'required:true|min:8'"
                  icon-pack="fas"
                  icon="lock"
                  hideRequired
                  password-reveal
                  isRequired
                >
                </z-input>
              </div>
              <b-field>
                <router-link
                  class="password-remind-link is-pulled-right content__password-remind"
                  :to="{ name: 'auth-resetpassword' }"
                  >パスワードをお忘れの方はこちら</router-link
                >
              </b-field>
              <button
                class="button is-primary is-rounded is-fullwidth"
                @click="signIn"
              >
                メールアドレスでログイン
              </button>
              <div class="sns-login">
                <div class="is-divider" data-content="OR" />
                <div class="sns-login__buttons">
                  <ZGoogleButton @click="signInWithGoogle" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import topImage from "@/assets/top/top.jpg";
import firebase, { GoogleAuthProvider } from "@/plugins/firebase";
import ZGoogleButton from "@/components/parts/ZGoogleButton.vue";

export default {
  name: "SignIn",
  components: {
    ZGoogleButton,
  },
  data: function () {
    return {
      topImage: topImage,
      email: "",
      password: "",
    };
  },
  async created() {
    this.email = this.$route.query.email || "";
    const loadingComponent = this.$loading.open();
    try {
      const result = await firebase.auth().getRedirectResult();
      if (!result?.user) {
        firebase.auth().onAuthStateChanged((user) => {
          console.log("user", user);
          if (user && !user.isAnonymous) {
            window.location.href = "/";
          }
        });
        return;
      }

      const now = firebase.serverTimestamp();
      const email = result.user.email;
      const uid = result.user.uid;
      let user = await this.$$investor$loadUser(uid);

      console.log("user", user);
      if (!user?.data?.isActivate) {
        user = this.$$investor$getNewUser();
        user.id = uid;
        user.data.base.email = email;
        user.data.uid = uid;
        user.data.isInvestor = true;
        user.data.isActivate = true;
        user.data.updatedBy = uid;
        user.data.updatedDt = now;
        user.data.registerDt = now;
        user.data.createdBy = uid;
        user.data.createdDt = now;
        await this.$$investor$save(uid, user);

        if (this.$gtm) {
          await this.$gtm.trackEvent({
            event: "signup-with-google-su",
          });
        }
        window.location.href = "/investor/register";
      } else {
        user.id = uid;
        user.data.base.email = email;
        user.data.uid = uid;
        user.data.updatedBy = uid;
        user.data.updatedDt = now;
        await this.$$investor$save(uid, user);
        if (user.data.wasCompletedRegister) {
          window.location.href = "/";
        } else {
          window.location.href = "/investor/register";
        }
      }
    } catch (e) {
      this.$raven.captureException(e.message ? new Error(e.message) : e);
      this.$$toast$openError("サインアップに失敗しました");
    } finally {
      loadingComponent.close();
    }
  },
  methods: {
    onBlurEmail(event) {
      if (event && this.email !== event.target.value)
        this.email = event.target.value;
    },
    onBlurPassword(event) {
      if (event && this.password !== event.target.value)
        this.password = event.target.value;
    },
    async signInWithGoogle() {
      console.log("gtm", this.$gtm);
      const loadingComponent = this.$loading.open();
      try {
        const provider = new GoogleAuthProvider();
        // const gauth = await firebase.auth().signInWithPopup(provider);
        const gauth = await firebase.auth().signInWithRedirect(provider);
        // const auth = firebase.auth().currentUser;
        // const now = firebase.serverTimestamp();
        // const email = gauth.user.email;
        // const uid = auth.uid;
        // let user = await this.$$investor$loadUser(uid);

        // console.log("user", user);
        // if (!user?.data?.isActivate) {
        //   user = this.$$investor$getNewUser();
        //   user.id = uid;
        //   user.data.base.email = email;
        //   user.data.uid = uid;
        //   user.data.updatedBy = uid;
        //   user.data.updatedDt = now;
        //   user.data.registerDt = now;
        //   user.data.createdBy = uid;
        //   user.data.createdDt = now;
        //   if (this.$gtm) {
        //     await this.$gtm.trackEvent({
        //       event: "signup-with-google-si", // Event type [default = 'interaction
        //       // category: 'Calculator',
        //       // action: 'click',
        //       // label: 'Home page SIP calculator',
        //       // value: 5000,
        //       // noninteraction: false, // Optional
        //     });
        //   }
        //   await this.$$investor$save(uid, user);
        //   // window.location.href = "/investor/register";
        // } else {
        //   user.id = uid;
        //   user.data.base.email = email;
        //   user.data.uid = uid;
        //   user.data.isInvestor = true;
        //   user.data.isActivate = true;
        //   user.data.updatedBy = uid;
        //   user.data.updatedDt = now;
        //   await this.$$investor$save(uid, user);
        //   // window.location.href = "/investor/";
        // }
      } catch (e) {
        if (e.code == "auth/popup-blocked") {
          this.$$toast$openError(
            "サインアップのポップアップがブロックされました。"
          );
          this.$raven.captureException(new Error(e.message));
        } else {
          this.$raven.captureException(e.message ? new Error(e.message) : e);
          this.$$toast$openError("サインアップに失敗しました");
        }
      } finally {
        loadingComponent.close();
      }
    },
    signIn: function () {
      const self = this;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          console.log(user);
        })
        .then(() => {
          const pageTransition = localStorage.getItem("transitionParam");
          if (pageTransition) {
            window.location.href = pageTransition;
            localStorage.removeItem("transitionParam");
          } else {
            window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error(error);
          self.$$toast$openError("ログインに失敗しました。");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.signup-content {
  height: 100%;
  padding: 8px;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 420px;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
    &__inner {
    }
    &__content {
      width: 100%;
      // margin-top: 78px;
    }
    &__button {
      margin-top: 30px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &__boxs {
    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .content {
    a {
      color: white;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}

.sns-login {
  margin-top: 20px;
  width: 100%;
  &__text {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: gray;
    margin-bottom: 10px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
  }
}

.signin-content {
  height: 100%;
  padding: 16px;
  &__box {
    height: 100%;
    width: 420px;
    border-radius: 0px;
    @include mobile {
      width: 100%;
    }
  }
  .content {
    .field {
      font-size: 12px;
      .password-remind-link {
        margin-top: 4px;
      }
    }
    &__title {
      color: blue;
      font-size: 30px;
      margin-bottom: 40px;
      margin-top: 15px;
    }
    &__password-remind {
      margin-bottom: 32px;
    }
    .button {
      margin-top: 34px;
    }
  }
}

.main-message {
  margin-bottom: 40px;
}

.sub-message {
  font-size: 14px;
  margin-bottom: 30px;
}

// .hr-text {
//   border-top: 1px solid gray;
// 	// line-height: 1em;
// 	// position: relative;
// 	// outline: 0;
// 	// border: 0;
// 	// color: black;
// 	// text-align: center;
// 	// height: 1.5em;
// 	// opacity: .5;
// }

// .hr-text:before {
//   content: '';
// 	position: absolute;
// 	left: 0;
// 	top: 50%;
// 	width: 100%;
// 	height: 1px;
// }
// .hr-text:before {
// 	content: '';
// 	background: linear-gradient(to right, transparent, #818078, transparent);
// 	position: absolute;
// 	left: 0;
// 	top: 50%;
// 	width: 100%;
// 	height: 1px;
// }
// .hr-text:after {
// 	content: attr(data-content);
// 	position: relative;
// 	display: inline-block;
// 	color: black;
// 	padding: 0 .5em;
// 	line-height: 1.5em;
// 	color: #818078;
//   background-color: white;
// 	// background-color: #fcfcfa;
// }
</style>
