<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="container">
        <div class="content">
          <h4>専門家(不動産屋)一覧</h4>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: 'admin-setting-realtors-detail',
              params: { cid: 'new' },
            }"
            class="button is-small is-primary"
            >新規追加</router-link
          >
          <button
            class="button is-danger is-small"
            :disabled="!checkedRealtors.length"
            @click="accept"
          >
            承認
          </button>
          <!--
          <button class="button is-danger" @click="denial">否認</button>
          -->
        </div>
        <hr />
        <b-table
          :data="realtors"
          checkable
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort="company.examination.data.status"
          custom-row-key="company.id"
          :checked-rows.sync="checkedRealtors"
          :striped="true"
          :hoverable="true"
          :narrowed="true"
          :mobile-cards="true"
        >
          <template slot-scope="props">
            <b-table-column field="company.id" label="詳細" width="20" sortable>
              <router-link
                :to="{
                  name: 'admin-setting-realtors-detail',
                  params: { cid: props.row.company.id },
                }"
                class="button is-small"
                >詳細</router-link
              >
            </b-table-column>
            <b-table-column
              field="company.examination.data.status"
              label="認証ステータス"
              width="140"
              sortable
            >
              <z-tag
                :options="$$ks.realtor.application.company.statuses"
                v-model="props.row.company.examination.data.status"
              />
            </b-table-column>
            <b-table-column field="company.data.name" label="企業名" sortable>
              {{ props.row.company.data.name }}
            </b-table-column>
            <b-table-column
              field="company.data.phoneNumber"
              label="電話番号"
              sortable
            >
              {{ props.row.company.data.phoneNumber }}
            </b-table-column>
            <b-table-column
              field="company.data.registerDt.toDate()"
              label="登録日時"
              sortable
            >
              {{ $$calender$dateFormat(props.row.company.data.registerDt)
              }}<br />
              {{ props.row.company.id }}<br />
              <template v-if="props.row.company.data.isActivate">
                <div class="tag is-danger">ACTIVE</div>
              </template>
              <template v-else>
                <div class="tag">NON ACTIVE</div>
              </template>
            </b-table-column>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

export default {
  name: "admin-setting-realtors",
  data: function () {
    return {
      isLoading: true,
      realtors: [],
      checkedRealtors: [],
    };
  },
  methods: {
    statusKS: function (val) {
      return this.$$ks.get(this.$$ks.realtor.application.company.statuses, val);
    },
    async accept() {
      const loadingComponent = this.$loading.open();
      try {
        for (const realtor of this.checkedRealtors) {
          await this.$$realtor$issueAccountRealtor(this.auth.uid, realtor);
        }
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        this.realtors = await this.$$realtor$loadRealtors();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
