<template>
  <div class="offersListItem">
    <div class="offersListItem__titleArea">
      <p class="offersListItem__titleArea__txt" ref="title">{{ title }}</p>
    </div>
    <div class="offersListItem__profileArea">
      <div class="profile">
        <div class="profile__icon">
          <ZProfileThumbnail
            class="profile__icon__user"
            :value="prfImage"
            :scoreObj="score"
          />
        </div>
        <div class="profile__info">
          <div class="profile__info__detail">
            <p class="profile__info__detail__name">{{ name }}</p>
            <p class="profile__info__detail__offerDate">{{ date }}</p>
          </div>
          <p class="profile__info__corporationName">{{ company }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ZProfileThumbnail from "@/components/parts/ZProfileThumbnail.vue";
import { truncate } from "truncator";

export default {
  name: "OffersListItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
    prfImage: {
      type: Object,
      required: false,
    },
    score: {
      type: Object,
    },
  },
  components: {
    ZProfileThumbnail: ZProfileThumbnail,
  },
  data: function () {
    return {
      observer: null,
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      this.truncateTitle();
    });
    window.addEventListener("resize", this.truncateTitle);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.truncateTitle);
  },
  computed: {},
  methods: {
    truncateTitle() {
      const titleRef = this.$refs.title;
      const titleOrg = this.title;
      var opt = {
        line: 2,
        ellipsis: "...",
      };
      truncate(titleRef, titleOrg, opt);
      // var truncator = truncate(titleRef, titleOrg, opt);
    },
  },
  watch: {
    title: {
      handler: function (newVal) {
        console.log("watch");
        if (newVal) {
          this.truncateTitle();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.offersListItem {
  border-bottom: solid 1px;
  border-color: #dddddd;
  padding-top: 10px;
  &__titleArea {
    padding: 0 8px;
    &__txt {
      font-size: 15px;
      line-height: 20px;
    }
  }
  &__profileArea {
    padding: 6px 0;
    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__icon {
        width: 34px;
        height: 34px;
        position: relative;
        &__user {
          position: absolute;
          left: 0;
          width: 34px;
          height: 34px;
          box-shadow: none;
        }
      }
      &__info {
        width: calc(100% - 40px);
        margin-left: 4px;
        &__detail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &__name {
            font-size: 16px;
            line-height: 18px;
            color: #333333;
          }
          &__offerDate {
            font-size: 12px;
          }
        }
        &__corporationName {
          font-size: 13px;
          color: #777777;
        }
      }
    }
  }
}
</style>
