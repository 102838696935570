<template>
  <div class="z-realestateImages">
    <z-carousel
      class="z-realestateImages__main"
      v-model="currentIndex"
      :navigateTo="internalValue"
      :images="imgs"
      :emptySrc="emptySrc"
    >
    </z-carousel>
    <z-carousel-list
      v-if="imgs.length > 1"
      class="z-realestateImages__thmb"
      @selected="changeCurrent"
      :images="imgs"
      :emptySrc="emptySrc"
    >
    </z-carousel-list>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";
// import firebase from "@/plugins/firebase";
const emptyImage = require("@/assets/empty/noimage.png");

export default {
  name: "ZRealestateImages",
  mixins: [InputMixin],
  props: {
    images: {
      type: [Object],
      default: () => {},
    },
    value: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    imgs() {
      if (this.images) {
        const ls = []
          .concat(this.images.exteriors)
          .concat(this.images.interiors)
          .concat(this.images.layouts)
          .concat(this.images.others);
        return ls;
      } else {
        return [];
      }
    },
    emptySrc() {
      return emptyImage;
    },
  },
  methods: {
    changeCurrent(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.z-realestateImages {
  margin: 0 auto;
  max-width: 648px;
  @include sp {
    max-width: auto;
  }
  &__main {
    height: 484px;
    @include sp {
      height: 268px;
    }
  }
  &__thmb {
    margin-top: 16px;
    height: 92px;
    @include sp {
      display: none;
    }
  }
}
</style>
