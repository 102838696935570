<template>
  <div class="z-form">
    <ZField
      :label="label"
      :class="errors.has(internalName) ? 'is-danger' : ''"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
      :isShort="isShort"
      :showCounter="true"
    >
      <p class="edit__explanation">
        具体的な希望条件をご記入ください。最大600文字<br />
        <span class="edit__explanation_red">
          ※
          一言コメントをご登録いただくと、物件のご提案数が格段にあがります。<br />
          &emsp;具体的希望条件をご登録いただくことを推奨いたします。
        </span>
      </p>
      <z-help-accordion title="記入例を見る">
        <p>
          例１）<br />既に中古収益物件を２件保有しています。<br />次は、新築物件にチャレンジしたく、土地・建物プランのご提案をいただきたいです。<br />建物はRCもしくは鉄骨を想定しています。
        </p>
        <br />
        <p>
          例２）<br />親族の相続対策を目的に、都内23区で資産性が高いマンションを探しています。<br />1000万～2000万を自己資金にしてローンを組みたいのですが、条件の良い金融機関のご紹介を含めて、ご提案よろしくお願いします。
        </p>
      </z-help-accordion>
      <div class="z-field__input">
        <b-input
          :name="internalName"
          :icon="icon"
          rows="10"
          :size="size"
          type="textarea"
          placeholder="一言コメントを記入してください"
          v-model="internalValue"
          :data-vv-as="label"
          v-validate="validate"
          :maxlength="maxlength"
          :password-reveal="passwordReveal"
        >
        </b-input>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZComment",
  mixins: [InputMixin],
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.edit__explanation_red {
  color: $danger;
}
</style>
