<script>
import firebase from "@/plugins/firebase";

import informationType from "@/types/informationType";
const type = informationType.type;

export default {
  name: "Mixins-information",
  methods: {
    $$information$getNewInformation() {
      return {
        id: null,
        data: this.$$cloneDeep(type.information),
      };
    },
    async $$information$loadsByUID(uid) {
      const infos = await firebase.db
        .collection("informations_v1")
        .where("to", "==", uid)
        .orderBy("sendDt", "desc")
        .get()
        .then((querySnapshot) => {
          const is = [];
          querySnapshot.forEach((doc) => {
            is.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          return is;
        });
      return infos;
    },
    async $$information$loads() {
      const infos = await firebase.db
        .collection("informations_v1")
        .orderBy("sendDt", "desc")
        .get()
        .then((querySnapshot) => {
          const is = [];
          querySnapshot.forEach((doc) => {
            is.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          return is;
        });
      return infos;
    },
    async $$information$loadsWithUser() {
      const infos = await firebase.db
        .collection("informations_v1")
        .orderBy("sendDt", "desc")
        .get()
        .then((querySnapshot) => {
          const is = [];
          querySnapshot.forEach((doc) => {
            is.push(this.$$information$loadWithUser(doc.id));
          });
          return Promise.all(is);
        });
      return infos;
    },
    async $$information$load(uid, id) {
      const doc = await firebase.db.collection("informations_v1").doc(id).get();

      if (doc.exists) {
        const data = doc.data();
        return {
          id: doc.id,
          data: data,
        };
      } else {
        return null;
      }
    },
    async $$information$loadWithUser(id) {
      const doc = await firebase.db.collection("informations_v1").doc(id).get();

      if (doc.exists) {
        const info = {
          id: doc.id,
          data: doc.data(),
        };
        info.sender = await this.$$admin$loadUser(doc.data().sendBy);
        info.receiver = await this.$$user$loadUser(doc.data().to);

        return info;
      } else {
        return null;
      }
    },
    async $$information$asRead(uid, information) {
      if (!information.data.asRead) {
        if (information.data.to === uid) {
          const now = firebase.serverTimestamp();
          const ret = await firebase.db
            .collection("informations_v1")
            .doc(information.id)
            .set(
              {
                asRead: true,
                readDt: now,
              },
              { merge: true }
            )
            .then(() => {
              return information;
            });
          return ret;
        }
      }
      return information;
    },
    async $$information$sendMail(mid) {
      const m = await this.$$information$loadWithUser(mid);
      let link = "";
      if (m.receiver.data.isRealtor) {
        link =
          location.protocol +
          "//" +
          location.host +
          this.$router.resolve({ name: "realtor-setting-informations" }).href;
      } else {
        link =
          location.protocol +
          "//" +
          location.host +
          this.$router.resolve({ name: "investor-informations" }).href;
      }
      console.log(m);
      await firebase.functions("sendMailOfInfomationToRealtor", {
        to: {
          email: m.receiver.data.base.email,
          name: m.receiver.data.base.name,
        },
        information: m,
        link: link,
      });
      return m;
    },
    async $$information$sendTo(uid, to, title, message) {
      const info = this.$$information$getNewInformation();
      info.data.to = to;
      info.data.title = title;
      info.data.message = message;
      return this.$$information$save(uid, info);
    },
    async $$information$save(uid, information) {
      const now = firebase.serverTimestamp();
      information.data.sendDt = now;
      information.data.sendBy = uid;
      information.data.createdDt = now;
      information.data.createdBy = uid;

      const ret = await firebase.db
        .collection("informations_v1")
        .add(information.data)
        .then((ref) => {
          return {
            id: ref.id,
            data: information.data,
          };
        });
      return ret;
    },
    async $$information$send(uid, information) {
      const ret = await this.$$information$save(uid, information);
      await this.$$information$sendMail(ret.id);
      // await firebase.fucntions("sendMailOfInformation", information);
      return ret;
    },
  },
};
</script>
