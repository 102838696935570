<template>
  <div>
    <section>
      <ZHeader title="投資家検索" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <section class="z-section">
      <div class="z-section__inner">
        <template v-if="this.filter.buyingNeeds">
          <div class="columns">
            <div class="column">
              <div class="menuBox">
                <ZEditAccordion
                  title="絞り込み検索条件"
                  icon="magnify"
                  isProtrude
                  :opened="false"
                >
                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <h4 class="edit__subtitle2 menu__title">会員区分</h4>
                      <div class="menu__membership">
                        <z-field>
                          <b-checkbox
                            class="menu__membership__checkBox"
                            v-model="filter.membership.premium"
                            type="is-info"
                          />
                        </z-field>
                        <span class="menu__membership__label"
                          >プレミアム会員</span
                        >
                        <z-field>
                          <b-checkbox
                            class="menu__membership__checkBox"
                            v-model="filter.membership.free"
                            type="is-info"
                          />
                        </z-field>
                        <span class="menu__membership__label">無料会員</span>
                      </div>
                    </div>
                    <div class="column is-6">
                      <h4 class="edit__subtitle2 menu__title">居住地</h4>
                      <div class="menu__select">
                        <z-select
                          class="menu__select__input"
                          v-model="filter.prefecture"
                          :options="this.$$ks.prefectures"
                          placeholder="指定なし"
                        />
                      </div>
                    </div>
                    <div class="column is-6">
                      <h4 class="edit__subtitle2 menu__title">スコア</h4>
                      <div class="menu__select">
                        <z-select
                          class="menu__select__input"
                          v-model="filter.score.from"
                          :options="$$ks.filters.score.from"
                        />
                        <span class="menu__select__tilde">~</span>
                        <z-select
                          class="menu__select__input"
                          v-model="filter.score.to"
                          :options="$$ks.filters.score.to"
                        />
                      </div>
                    </div>
                    <div class="column is-full">
                      <h4 class="edit__subtitle2 menu__title">買いニーズ</h4>
                      <section>
                        <ZAreaLineFieldSet
                          ref="areaLineFieldSet"
                          v-model="filter.buyingNeeds"
                          hideRequired
                        />
                        <z-checkbox
                          label="物件種別"
                          v-model="filter.buyingNeeds.realEstateTypes"
                          :options="$$ks.realEstateTypes"
                          hideRequired
                        />
                        <z-checkbox
                          label="建物種別"
                          v-model="filter.buyingNeeds.buildingTypes"
                          :options="$$ks.typeOfBuildingTypes"
                          hideRequired
                        />
                        <div class="columns">
                          <div class="column">
                            <z-rate-from-to-select
                              label="表面利回り"
                              v-model="filter.buyingNeeds.couponYieldRate"
                              hideRequired
                            />
                          </div>
                          <div class="column">
                            <z-price-from-to-select
                              label="価格"
                              v-model="filter.buyingNeeds.askingPrice"
                              hideRequired
                            />
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column">
                            <z-age-ofbuilding-from-to-select
                              label="築年数"
                              v-model="filter.buyingNeeds.ageOfBuilding"
                              hideRequired
                            />
                          </div>
                          <div class="column">
                            <z-transport-select
                              label="最寄り駅までの徒歩分数"
                              v-model="filter.buyingNeeds.transport"
                              hideRequired
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div class="menu__button">
                    <b-button
                      class="z-button"
                      type="is-primary"
                      size="is-small"
                      @click="updateInvestors"
                    >
                      検索する
                    </b-button>
                  </div>
                </ZEditAccordion>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <template v-if="!isLoading">
                <div class="levels">
                  <div class="level-right count">
                    該当件数
                    <span class="count__number"
                      >{{ $$num$commaFormat(this.total) }}件</span
                    >
                  </div>
                </div>
                <h4 class="list-title">投資家一覧</h4>
                <div class="levels">
                  <div class="level-right sort">
                    <label class="sort__title">並び替え</label>
                    <z-select
                      v-model="sortType"
                      :options="sortTypes"
                      @input="updateInvestors"
                    />
                  </div>
                </div>
                <b-table
                  :data="investors"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  detailed
                  detail-key="id"
                  custom-row-key="id"
                  :show-detail-icon="true"
                  paginated
                  backend-pagination
                  :per-page="perPage"
                  :total="total"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                >
                  <template slot-scope="props">
                    <b-table-column>
                      <a @click="onOpenInvestorDetail(props.row)">
                        <InvestorProfile
                          class="cursor-pointer"
                          :hideProfile="true"
                          :investor="props.row"
                        />
                      </a>

                      <template v-if="bulma.isMobile">
                        <tile-box-overlay
                          title1="投資家詳細・"
                          title2="提案メッセージ送信"
                          ref="overlay"
                          :isTitleNewline="true"
                        >
                          <template v-slot:OverlayReceive>
                            <InvestorDetail :investor="selectedRow" />
                          </template>
                        </tile-box-overlay>
                      </template>
                      <template v-else>
                        <tile-box-overlay
                          title="投資家詳細・提案メッセージ送信"
                          ref="overlay"
                        >
                          <template v-slot:OverlayReceive>
                            <InvestorDetail :investor="selectedRow" />
                          </template>
                        </tile-box-overlay>
                      </template>
                    </b-table-column>
                  </template>
                  <template slot="detail" slot-scope="props">
                    <div class="columns is-multiline investorDetail">
                      <template v-if="props.row.data.buyingNeeds.length">
                        <template v-for="(bn, i) in props.row.data.buyingNeeds">
                          <div :key="i" class="column is-6">
                            <div class="investorDetail__title">
                              買いニーズ({{ i + 1 }})
                            </div>
                            <hr class="investorDetail__hr" />
                            <InvestorBuyingNeeds :value="bn" />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <span class="investorDetail__empty"
                          >買いニーズは登録されていません</span
                        >
                      </template>
                    </div>
                  </template>
                  <template slot="empty">
                    <div class="blankBox">
                      <z-blank-box>投資家は見つかりませんでした</z-blank-box>
                    </div>
                  </template>
                </b-table>
              </template>
            </div>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import InvestorProfile from "@/components/investor/InvestorProfile";
import InvestorBuyingNeeds from "./parts2/InvestorBuyingNeeds2";
import InvestorDetail from "./parts2/InvestorDetail2";

export default {
  name: "realtor-setting-investors",
  components: {
    InvestorProfile: InvestorProfile,
    InvestorBuyingNeeds: InvestorBuyingNeeds,
    InvestorDetail: InvestorDetail,
  },
  data: function () {
    return {
      filter: {
        prefecture: null,
        buyingNeeds: null,
        score: {
          from: 0,
          to: 1000,
        },
        membership: {
          free: false,
          premium: false,
        },
      },
      sortTypes: [
        { value: 6, text: "会員登録日順" },
        { value: 1, text: "スコアの高い順" },
        { value: 2, text: "保有不動産数の多い順" },
        { value: 3, text: "保有不動産数の少ない順" },
        { value: 4, text: "年齢の高い順" },
        { value: 5, text: "年齢の低い順" },
        // { value: 6, text: "会員登録日が近い順" }
      ],
      isLoading: true,
      investors: [0],
      currentPage: 1,
      perPage: 30,
      total: 0,
      sortType: 6,
      selectedRow: null,
    };
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        // this.investors = await this.$$investor$loadUsersWithMessage();
        this.filter.buyingNeeds = this.$$investor$getNewBuyingNeedsFilter();
        await this.insertUsersByFilter();

        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    async updateInvestors() {
      this.currentPage = 1;
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        await this.insertUsersByFilter();
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // async sortInvestors() {
    // if (this.sortType === 1) {
    //   this.investors.sort((iA, iB) => {
    //     if (iA.data.latestScore.score < iB.data.latestScore.score) return 1;
    //     if (iA.data.latestScore.score > iB.data.latestScore.score) return -1;
    //     return 0;
    //   });
    // }
    // if (this.sortType === 2) {
    //   this.investors.sort((iA, iB) => {
    //     if (iA.realEstates.length < iB.realEstates.length) return 1;
    //     if (iA.realEstates.length > iB.realEstates.length) return -1;
    //     return 0;
    //   });
    // }
    // if (this.sortType === 3) {
    //   this.investors.sort((iA, iB) => {
    //     if (iA.realEstates.length > iB.realEstates.length) return 1;
    //     if (iA.realEstates.length < iB.realEstates.length) return -1;
    //     return 0;
    //   });
    // }
    // if (this.sortType === 4) {
    //   this.investors.sort((iA, iB) => {
    //     if (
    //       this.$$age$calc(iA.data.base.birthday) <
    //       this.$$age$calc(iB.data.base.birthday)
    //     )
    //       return 1;
    //     if (
    //       this.$$age$calc(iA.data.base.birthday) >
    //       this.$$age$calc(iB.data.base.birthday)
    //     )
    //       return -1;
    //     return 0;
    //   });
    // }
    // if (this.sortType === 5) {
    //   this.investors.sort((iA, iB) => {
    //     if (
    //       this.$$age$calc(iA.data.base.birthday) >
    //       this.$$age$calc(iB.data.base.birthday)
    //     )
    //       return 1;
    //     if (
    //       this.$$age$calc(iA.data.base.birthday) <
    //       this.$$age$calc(iB.data.base.birthday)
    //     )
    //       return -1;
    //     return 0;
    //   });
    // }
    // if (this.sortType === 6) {
    //   this.investors.sort((iA, iB) => {
    //     if (iA.data.registerDt < iB.data.registerDt) return 1;
    //     if (iA.data.registerDt > iB.data.registerDt) return -1;
    //     return 0;
    //   });
    // }
    // },
    async onPageChange(page) {
      this.currentPage = page;
      const loadingComponent = this.$loading.open();
      try {
        await this.insertUsersByFilter();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    async insertUsersByFilter() {
      await this.$$investor$loadUsersByFilter(
        this.currentPage,
        this.perPage,
        this.sortType,
        this.filter
      ).then((data) => {
        if (!data.result) {
          throw new Error();
        }
        this.investors = data.users;
        this.total = data.total;
      });
    },
    onOpenInvestorDetail(row) {
      this.selectedRow = row;
      this.$refs.overlay.open();
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.menu {
  padding: 15px;
  &__title {
    font-weight: bold;
  }
  &__membership {
    display: flex;
    &__label {
      font-size: 14px;
      vertical-align: 4px;
      margin-right: 20px;
    }
  }
  &__select {
    display: flex;
    &__input {
      width: 40%;
    }
    &__tilde {
      margin: 8px;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-top: 20px;
  }
}

.count {
  margin-top: -25px;
  margin-bottom: 10px;
  &__number {
    font-size: 1.3rem;
    font-weight: bold;
    color: #f00;
    padding-left: 5px;
    line-height: 3px;
  }
  @include mobile {
    margin-top: 10px;
  }
}

.sort {
  margin-top: 15px;
  margin-bottom: -25px;
  &__title {
    margin: -28px 8px 0 0;
    font-weight: bold;
  }
  @include mobile {
    margin-bottom: 5px;
  }
}

.investorDetail {
  margin: 20px;
  @include lg {
    padding-left: 30px;
  }
  &__title {
    font-size: 15px;
  }
  &__hr {
    margin: 5px auto;
  }
  &__empty {
    font-size: 15px;
    margin-left: 7px;
  }
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: #dbdbdb;
//  border-bottom: solid 2px #707070;
//  color: black;
//}

.blankBox {
  height: 300px;
}

/deep/ .modal-card-head {
  &__title1,
  &__title2 {
    text-align: left !important;
  }
}
</style>
