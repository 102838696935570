<template>
  <div class="message-docs-list">
    <div class="columns i-multiline is-mobile">
      <template v-for="(thread, i) in sortedthreads">
        <template v-if="thread.docs && thread.docs.length">
          <div class="column is-narrow message-docs-list__column" :key="i">
            <p
              class="message-docs-list__date"
              v-text="$$calender$dateTimeFormat(thread.sendDt)"
            />
            <template v-for="doc in thread.docs">
              <div :key="doc.id">
                <z-docs class="message-docs-list__content" :value="doc" />
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "z-message-docs-list",
  props: {
    threads: {
      type: Array,
      default: null,
    },
  },
  computed: {
    sortedthreads() {
      return this.threads.slice(0, this.threads.length).sort((reA, reB) => {
        return reA.createdDt < reB.createdDt ? 1 : -1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.message-docs-list {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px 0 25px 15px;
  &__column {
    border: solid 3px #dddddd;
    border-radius: 10px;
    margin-right: 10px;
  }
  &__date {
    color: #666666;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__content {
    margin-bottom: 10px;
  }
}

// // for IE11 hack
// _:lang(x)::-ms-backdrop,
// .message-docs-list {
//   height: 320px;
// }

// // for Edge hack
// _:-ms-lang(x)::backdrop,
// .message-docs-list {
//   height: 320px;
// }

.count {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
