<template>
  <div class="realestateDetail">
    <div class="titlebar">
      <div class="z-section__inner">
        <h2 class="titlebar__title">
          {{ rsInfo.data.name }}
          <a class="titlebar__title__icon" @click="openEdit()">
            <b-icon icon="pencil"></b-icon>
          </a>
        </h2>
      </div>
    </div>
    <div class="z-section__inner">
      <!-- <InvestorInfo /> -->
      <RealestateOverview :data="rsInfo.data" />
      <z-realestate-images :images="rsInfo.data.images" />
      <RealestateDetail
        :data="rsInfo.data"
        showLoanInfo
        showBildingInfo
        showlandInfo
      />
    </div>

    <tile-box-overlay title="My Collection" subTitle="保有不動産" ref="overlay">
      <template v-slot:OverlayReceive>
        <EditRealEstateUnit :id="rsInfo.id" @saved="savedRealEstate" />
      </template>
    </tile-box-overlay>
  </div>
</template>

<script>
import RealestateOverview from "@/components/realestate/RealestateOverview";
import RealestateDetail from "@/components/realestate/RealestateDetail";
import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
import EditRealEstateUnit from "@/views/investor/common/EditRealEstateUnit";

export default {
  name: "collection-realestate",
  props: {
    rsInfo: {
      type: Object,
    },
  },
  data: function () {
    return {
      isLoading: true,
      //rsInfo: () => {}
    };
  },
  components: {
    RealestateOverview: RealestateOverview,
    RealestateDetail: RealestateDetail,
    TileBoxOverlay: TileBoxOverlay,
    EditRealEstateUnit: EditRealEstateUnit,
  },
  //computed: {
  //  data() {
  //    return this.rsInfo.data;
  //  }
  //},
  methods: {
    //async init() {
    //  const loadingComponent = this.$loading.open();
    //  try {
    //    this.isLoading = true;
    //    this.rsInfo = await this.$$realEstate$loadRealEstate(
    //      this.$route.params.id
    //    );
    //    this.isLoading = false;
    //    return;
    //  } catch (error) {
    //    this.$$error$catchLoadError(error);
    //  } finally {
    //    loadingComponent.close();
    //  }
    //},
    openEdit() {
      this.$refs.overlay.open();
    },
    savedRealEstate(rsInfo) {
      this.rsInfo = rsInfo;
      this.$refs.overlay.close();
      this.$emit("saved", rsInfo);
      // if (!rsInfo.data.isActivate) {
      //   this.$router.push({ name: "investor-collection" });
      // }
    },
  },
  //mounted() {
  //  this.init();
  //}
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realestateDetail {
  .titlebar {
    padding: 6px 0;
    background-color: #f4f4f4;
    color: #787878;
    &__title {
      position: relative;
      font-size: 24px;
      &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
