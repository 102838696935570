import Vue from "vue";
import Router from "vue-router";

import store from "@/store";

import base from "@/routers/base";

import auth from "@/routers/auth";
import investor from "@/routers/investor";
import realtor from "@/routers/realtor";
import admin from "@/routers/admin";

import options from "@/routers/options";
const RequiresAuth = options.RequiresAuth;
const role = options.role;
// const examination = options.examination;

import * as analysisActions from "@/modules/analysis/actions";

Vue.use(Router);

const routes = []
  .concat(base.routes)
  .concat(auth.routes)
  .concat(realtor.routes)
  .concat(investor.routes)
  .concat(admin.routes)
  .concat([
    {
      path: "*",
      component: {
        template: "<div></div>",
        created() {
          location.href = "/";
        },
      },
    },
  ]);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const convertRouter = (ro) => {
  const { name, fullPath, hash, params, meta, path, query } = ro;

  return {
    name,
    hash,
    meta,
    params,
    path,
    query,
    fullPath,
  };
};

const trackingAction = async (to, from) => {
  try {
    const { uid } = store.getters["auth/auth"];
    if (uid) {
      const at = analysisActions.createNewAction(uid);
      await analysisActions.save(uid, {
        id: null,
        data: {
          ...at.data,
          type: 1,
          action: {
            from: convertRouter(from),
            to: convertRouter(to),
          },
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
};

// 認証を確認する関数
const nextAuth = async (to, from, next) => {
  await trackingAction(to, from);
  const rAuth = to.meta.requiresAuth;
  if (rAuth === RequiresAuth.required) {
    nextRequireAuth(to, from, next);
  } else if (rAuth === RequiresAuth.noRequired) {
    nextNoRequireAuth(to, from, next);
  } else {
    next();
  }
};

// 認証を確認する関数
const nextRequireAuth = (to, from, next) => {
  if (store.getters["auth/user"].auth) {
    const me = store.getters["user/me"];

    // adminのみ遷移
    if (to.meta.role === role.admin) {
      const isAdmin = store.getters["auth/auth"].admin;
      if (!isAdmin) {
        if (me && me.user && me.user.data.isInvestor) {
          next({
            name: "investor",
          });
        }
        if (me && me.user && me.user.data.isRealtor) {
          next({
            name: "realtor-route",
          });
        }
        window.location.href = "/auth/signin";
      }
    }

    if (to.name === "/home") {
      if (me && me.user && me.user.data.isInvestor) {
        next({
          name: "investor",
        });
        return;
      }
      if (me && me.user && me.user.data.isRealtor) {
        next({
          name: "realtor-route",
        });
        return;
      }

      //どこにも飛び先がない場合はLPに飛ばす
      // window.location.href = "https://stockformer.com";
      window.location.href = "/auth/signin";
    }
    // /homeへの移動なら各ロールごとに飛び先を変える

    if (me && me.user && me.user.data.isInvestor) {
      if (to.meta.role === role.realtor) {
        next({
          name: "investor",
        });
        return;
      }
    }

    if (me && me.user && me.user.data.isRealtor) {
      if (to.meta.role === role.investor) {
        next({
          name: "realtor-route",
        });
        return;
      }
    }

    next();
  } else {
    // ログイン前のリンクを保持
    const url = window.location.href;
    localStorage.setItem("transitionParam", url);

    // 認証結果が false なら Home へリダイレクト
    window.location.href = "/auth/signin";

    // location.href = "https://stockformer.com/";
    //next({
    //  path: "/auth/signin"
    //});
  }
};

// 認証を必要としない関数
const nextNoRequireAuth = (to, from, next) => {
  if (store.getters["auth/user"].auth) {
    next({
      name: "/home",
    });
  } else {
    next();
  }
};

const waitLoadUser = () => {
  return new Promise((resolve) => {
    // const self = this;
    const init = store.getters["user/inited"];
    if (!init) {
      const unwatch = store.watch(
        (state, getters) => getters["user/inited"],
        () => {
          resolve();
          unwatch();
        }
      );
    } else {
      resolve();
    }
  });
};

const waitSetAuth = () => {
  return new Promise((resolve) => {
    // const self = this;
    const auth = store.getters["auth/auth"];
    if (!auth) {
      const unwatch = store.watch(
        (state, getters) => getters["auth/auth"],
        () => {
          resolve();
          unwatch();
        }
      );
    } else {
      resolve();
    }
  });
};

router.beforeEach(async (to, from, next) => {
  console.log("from:" + from.path + ",to:" + to.path);
  const init = store.getters["auth/init"];
  if (init !== true) {
    // まだ認証していなければ init が更新されるのを監視
    const unwatch = store.watch(
      (state, getters) => getters["auth/init"],
      async () => {
        // 初回の認証が終わったら認証状態を確認
        await waitSetAuth();
        await waitLoadUser();
        nextAuth(to, from, next);

        unwatch();
      }
    );
  } else {
    // 認証済みならすぐ認証状態を確認
    await waitSetAuth();
    await waitLoadUser();
    nextAuth(to, from, next);
  }
});

router.afterEach((to /*, from */) => {
  if (to.meta.title) {
    document.title = to.meta.title + " | StockFormer(ストックフォーマ)";
  }
  store.commit("hideLogoLoading");
});

export default router;
