<template>
  <div>
    <z-select
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :hideRequired="hideRequired"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
    >
    </z-select>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

// import axios from "axios-jsonp-pro";
export default {
  name: "ZLineSelect",
  mixins: [InputMixin],
  data: function () {
    return {
      // loading: false,
      // selected: null,
      lines: [],
    };
  },
  props: {
    value: {
      type: [Number],
      default: null,
    },
    prefecture: {
      type: [Number],
      default: null,
    },
  },
  computed: {
    options() {
      const opts = [];
      for (const c of this.lines) {
        opts.push({
          value: c.line_cd,
          text: c.line_name,
        });
      }
      return opts;
    },
  },
  created: async function () {
    try {
      // this.loading = true;
      this.lines = [];
      const val = this.prefecture;
      console.log(val);
      if (val) {
        this.lines = await this.loadLines(val);
        // const selected = this.lines.find(l => l.line_cd === this.internalValue);
        // this.selected = selected ? selected.line_name : null;
        // console.log(this.selected);
      }
    } finally {
      // this.loading = false;
    }
  },
  methods: {
    loadLines: async function (code) {
      return await this.$$line$loads(code);
    },
  },
  watch: {
    prefecture: async function (val, oldVal) {
      try {
        if (val) {
          if (val !== oldVal) {
            // this.loading = true;
            this.lines = [];
            this.internalValue = null;
            // this.selected = null;
            console.log(val);
            if (val) {
              const lines = await this.loadLines(val);
              this.lines = lines;
            }
          } else {
            this.lines = [];
            this.internalValue = null;
          }
        }
      } finally {
        // this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
