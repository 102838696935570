<template>
  <div class="z-scoreStandartDeviation">
    <div class="z-scoreStandartDeviation__score">{{ score.score }}</div>
    <ZScoreStandardDeviationChart
      class="z-scoreStandartDeviation__chart"
      :score="score"
      :chartData="chartData"
      :compressionXAxes="bulma.isMobile"
    />
  </div>
</template>

<script>
import ZScoreStandardDeviationChart from "@/components/charts/ZScoreStandardDeviationChart.vue";

export default {
  name: "ZScoreStandardDeviation",
  components: {
    ZScoreStandardDeviationChart,
  },
  props: {
    score: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
      //required: true
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-scoreStandartDeviation {
  position: relative;
  &__chart {
    width: 100%;
    height: 248px;
    background: linear-gradient(
      0deg,
      rgba(106, 186, 253, 0.4) 0%,
      rgb(255, 255, 255) 75%
    );
  }
  &__score {
    width: 80px;
    height: 80px;
    top: 24px;
    left: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid #b6b6b6;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2px);
    font-size: 28px;
    font-weight: bold;
    color: #092e5d;
    @include sp {
      top: 10px;
      left: 16px;
    }
  }
}
</style>
