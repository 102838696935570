<template>
  <div class="buyingNeeds">
    <button class="buyingNeeds__button" @click="editBuyingNeeds">
      <p class="buyingNeeds__button__txt">買いニーズ設定</p>
      <ZIcon class="buyingNeeds__button__icon" name="hand" />
    </button>
    <tile-box-overlay title="Buying Needs" subTitle="買いニーズ" ref="overlay">
      <template v-slot:OverlayReceive>
        <EditBuyingneeds @saved="savedBuyingNeeds" />
      </template>
    </tile-box-overlay>
  </div>
</template>
<script>
// import ExclusiveOfferInfo from "./ExclusiveOfferInfo";
// import NormalOfferInfo from "./NormalOfferInfo";
// import ValuationOfferInfo from "./ValuationOfferInfo";
import EditBuyingneeds from "./EditBuyingneeds";
// import EditBuyingneeds from "./EditBuyingneeds";
import TileBoxOverlay from "@/components/parts/TileBoxOverlay";

export default {
  name: "BuyingNeedsButton",
  data: function () {
    return {
      // isModalActive: false,
      isLoading: true,
    };
  },
  components: {
    // ExclusiveOfferInfo: ExclusiveOfferInfo,
    // NormalOfferInfo: NormalOfferInfo,
    // ValuationOfferInfo: ValuationOfferInfo,
    TileBoxOverlay: TileBoxOverlay,
    EditBuyingneeds: EditBuyingneeds,
  },
  // async created(){
  //   this.masterEntity = await this.$$message$getMessagesOfTypes(this.me.user.id);
  //   this.isLoading = false;
  //   return;
  // },
  // asyncComputed: {
  //   async messagesOfTypes() {
  //     const messageEntity = await this.$$message$getMessagesOfTypes(
  //       this.me.user.id
  //     );
  //     this.isLoading = false;
  //     return messageEntity;
  //   }
  // },
  methods: {
    editBuyingNeeds() {
      this.$refs.overlay.open();
    },
    savedBuyingNeeds() {
      this.$refs.overlay.close();
    },
    // openAllMessages() {
    //   this.$router.push({ name: "investor-message" });
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.buyingNeeds {
  &__button {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 38px;
    width: 168px;
    font-size: 15px;
    color: #555555;
    // background-color: transparent;
    background-color: $white;
    border: solid 1px;
    border-radius: 4px;
    border-color: #dddddd;
  }
}

// .offers {
//   min-width: 330px;
//   min-height: 654px;
//   @include lg {
//     margin-left: 16px;
//   }
//
//   &__titleArea {
//     display: flex;
//     flex-wrap: nowrap;
//     justify-content: space-between;
//     align-items: center;
//     &__mainTitle {
//       font-size: 32px;
//       line-height: 1;
//       color: #333333;
//       font-family: $family-secondary;
//     }
//     &__buyingNeedsButton {
//       cursor: pointer;
//       display: flex;
//       justify-content: space-around;
//       align-items: center;
//       height: 38px;
//       width: 168px;
//       font-size: 15px;
//       color: #555555;
//       background-color: transparent;
//       border: solid 1px;
//       border-radius: 4px;
//       border-color: #dddddd;
//     }
//   }
//   &__subTitle {
//     font-size: 14px;
//     line-height: 14px;
//     color: #aaaaaa;
//     font-weight: bold;
//   }
//   &__allOfferButton {
//     text-align: center;
//     @include md {
//       text-align: right;
//     }
//   }
// }
//
// .z-button {
//   width: 200px;
//   height: 32px;
//   font-size: 14px;
// }
</style>
