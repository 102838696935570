import firebase from "@/plugins/firebase";
import kubuns from "@/kubuns/kubuns";
import { queryPagination } from "@/modules/pagination";
import moment from "moment";
import "moment-timezone";

const sortTypes = [
  {
    value: 1,
    text: "スコアの高い順",
    field: {
      "user.user.data.latestScore.score": {
        order: "desc",
      },
    },
  },
  {
    value: 2,
    text: "スコアの低い順",
    field: {
      "user.user.data.latestScore.score": {
        order: "asc",
      },
    },
  },
  {
    value: 3,
    text: "融資金額の高い順",
    field: {
      "data.purchase.loan.amount": {
        order: "desc",
      },
    },
  },
  {
    value: 4,
    text: "返済期間の長い順",
    field: {
      "data.purchase.loan.paymentPeriods.yearMonth": {
        order: "desc",
      },
    },
  },
  {
    value: 5,
    text: "金利の低い順",
    field: {
      "data.purchase.loan.interestRate": {
        order: "asc",
      },
    },
  },
  {
    value: 6,
    text: "融資実行年月順",
    field: {
      "data.purchase.loan.executeDate.date": {
        order: "desc",
      },
    },
  },
  {
    value: 13,
    text: "購入価格の高い順",
    field: {
      "data.purchase.amount": {
        order: "desc",
      },
    },
  },
  {
    value: 14,
    text: "利回りの高い順",
    field: {
      "data.couponYieldRate": {
        order: "desc",
      },
    },
  },
  {
    value: 15,
    text: "築年月が浅い順",
    field: {
      "data.constructionDate.date": {
        order: "desc",
      },
    },
  },
  {
    value: 16,
    text: "購入年月順",
    field: {
      "data.purchase.buyingDt.date": {
        order: "desc",
      },
    },
  },
];

const defaultQuery = {
  query: {
    match_all: {},
  },
  size: 200,
};

export const search = async (query) => {
  const q = query || defaultQuery;
  // console.log(q);
  const ret = await firebase.functions("public_realestates_search", {
    query: q,
  });
  return ret.data;
};

export const searchByOwner = async (owner, pagination) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              "data.owner.keyword": {
                value: owner,
              },
            },
          },
          {
            term: {
              "data.isActivate": {
                value: true,
              },
            },
          },
        ],
      },
    },
  };

  return await search(queryPagination(query, pagination));
};

const _filter_force = (force) => {
  if (force) {
    return [];
  } else {
    return [
      {
        term: {
          "data.isActivate": true,
        },
      },
    ];
  }
};

const _filter_score = (filter) => {
  // console.log(filter);
  const r = [];

  if (filter.from && filter.from !== kubuns.filters.score.min) {
    r.push({
      range: {
        "user.user.data.latestScore.score": {
          gte: filter.from,
        },
      },
    });
  }

  if (filter.to && filter.to !== kubuns.filters.score.max) {
    r.push({
      range: {
        "user.user.data.latestScore.score": {
          lt: filter.to,
        },
      },
    });
  }

  return r;
};

const _filter_salePeriod = (filter) => {
  // console.log(filter);
  const r = [];

  if (filter.from) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.purchase.buyingDt.year": {
                gt: filter.from,
              },
            },
          },
        ],
      },
    });
  }

  if (filter.to) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.purchase.buyingDt.year": {
                lte: filter.to,
              },
            },
          },
        ],
      },
    });
  }
  return r;
};

const _filter_realEstateTypes = (filter) => {
  // console.log(filter);
  const r = [];
  if (filter && filter.length > 0) {
    r.push({
      bool: {
        must: [
          {
            terms: {
              "data.realEstateType.value": filter,
            },
          },
        ],
      },
    });
  }
  return r;
};

const _filter_buildingTypes = (filter) => {
  // console.log(filter);
  const r = [];
  if (filter && filter.length > 0) {
    r.push({
      bool: {
        must: [
          {
            terms: {
              "data.buildingType.value": filter,
            },
          },
        ],
      },
    });
  }
  return r;
};
const _filter_couponYieldRate = (filter) => {
  const r = [];
  if (filter.from && filter.from !== kubuns.filters.yieldRate.min) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.couponYieldRate": {
                gte: filter.from,
              },
            },
          },
        ],
      },
    });
  }

  if (filter.to && filter.to !== kubuns.filters.yieldRate.max) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.couponYieldRate": {
                lt: filter.to,
              },
            },
          },
        ],
      },
    });
  }

  return r;
};

const _filter_amount = (filter) => {
  // console.log(filter);
  const r = [];
  if (filter.from && filter.from !== kubuns.filters.price.min) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.purchase.amount": {
                gte: filter.from,
              },
            },
          },
        ],
      },
    });
  }

  if (filter.to && filter.to !== kubuns.filters.price.max) {
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.purchase.amount": {
                lt: filter.to,
              },
            },
          },
        ],
      },
    });
  }

  return r;
};

const _filter_ageOfBuilding = (filter) => {
  // console.log(filter);
  const now = new Date();
  const r = [];
  if (filter.from && filter.from !== kubuns.filters.yearOfconstruction.min) {
    const from = moment(now).subtract(filter.from, "year");
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.constructionDate.date": {
                lt: from.format(),
              },
            },
          },
        ],
      },
    });
  }

  if (filter.to && filter.to !== kubuns.filters.yearOfconstruction.max) {
    const to = moment(now).subtract(filter.to, "year");
    r.push({
      bool: {
        must: [
          {
            range: {
              "data.constructionDate.date": {
                gte: to.format(),
              },
            },
          },
        ],
      },
    });
  }

  return r;
};

const _filter_elapsedTime = (filter) => {
  // console.log(filter);
  const r = [];

  if (filter.elapsedTime && filter.elapsedTime !== 999) {
    r.push({
      bool: {
        must: [
          {
            nested: {
              path: "data.transports",
              query: {
                bool: {
                  must: [
                    {
                      range: {
                        "data.transports.elapsedTime": {
                          lte: filter.elapsedTime,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    });
  }
  return r;
};

const _filter_area = (filter) => {
  // area: {
  //   isSelected: false,
  //   prefecture: null, // 県
  //   cities: [] // 市町村
  // },
  const r = [];
  if (!filter.isSelected) return r;

  if (!filter.prefecture) return r;

  r.push({
    term: {
      "data.prefecture.value": filter.prefecture,
    },
  });

  if (filter.cities.length) {
    r.push({
      terms: {
        "data.city.cityCode": filter.cities,
      },
    });
  }
  return r;
};

const _filter_Line = (filter) => {
  // Line: {
  //   isSelected: false,
  //   prefecture: null, // 県
  //   line: null, // 沿線
  //   stations: [] // 駅
  // }
  const r = [];
  if (!filter.isSelected) return r;
  if (!filter.prefecture) return r;

  r.push({
    term: {
      "data.prefecture.value": filter.prefecture,
    },
  });

  if (!filter.line) return r;

  r.push({
    bool: {
      must: [
        {
          nested: {
            path: "data.transports",
            query: {
              bool: {
                must: [
                  {
                    term: {
                      "data.transports.line.line.line_cd": filter.line,
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  });

  if (filter.stations.length) {
    r.push({
      bool: {
        must: [
          {
            nested: {
              path: "data.transports",
              query: {
                bool: {
                  must: [
                    {
                      terms: {
                        "data.transports.station.station.station_cd":
                          filter.stations,
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    });
  }

  return r;
};

const _filter_bank = (filter) => {
  const r = [];
  if (filter) {
    r.push({
      bool: {
        must: [
          {
            term: {
              "data.purchase.loan.bankId.bank_cd.keyword": filter,
            },
          },
        ],
      },
    });
  }
  return r;
};

const _filter_is_not_owner = (filter) => {
  const r = [];
  if (filter) {
    r.push({
      bool: {
        must_not: [
          {
            term: {
              "data.buildingUse.value": 2,
            },
          },
        ],
      },
    });
  }
  return r;
};

export const buildQuery = (
  filter,
  sortType = null,
  page = null,
  perPage = null,
  owner = null,
  force = false
) => {
  const query = {
    bool: {
      must: [],
    },
  };
  // console.log(filter);
  query.bool.must = []
    .concat(_filter_force(force))
    .concat(_filter_score(filter.score))
    .concat(_filter_salePeriod(filter.salePeriod))
    .concat(_filter_realEstateTypes(filter.realEstateTypes))
    .concat(_filter_buildingTypes(filter.buildingTypes))
    .concat(_filter_couponYieldRate(filter.couponYieldRate))
    .concat(_filter_amount(filter.amount))
    .concat(_filter_ageOfBuilding(filter.ageOfBuilding))
    .concat(_filter_elapsedTime(filter.transport))
    .concat(_filter_area(filter.area))
    .concat(_filter_Line(filter.Line))
    .concat(_filter_is_not_owner(filter.isNotOwner))
    .concat(_filter_bank(filter.bank));

  const sort = sortType
    ? sortTypes.filter((s) => s.value === sortType).map((s) => s.field)
    : [];

  const from = (page - 1) * perPage;

  return {
    query,
    sort,
    from: from > 0 ? from : 0,
    size: perPage || 9999,
  };
};

export const searchByfilter = async (
  filter,
  sortType = null,
  page = null,
  perPage = null,
  owner = null,
  force = false
) => {
  const q = buildQuery(filter, sortType, page, perPage, owner, force);

  return await search(q);
};

export const searchBankRank = async (
  filter,
  sortType = null,
  page = null,
  perPage = null,
  owner = null,
  force = false
) => {
  filter.isNotOwner = true;
  const q = buildQuery(filter, sortType, page, perPage, owner, force);

  q.aggs = {
    value_count_total_price: {
      terms: {
        field: "data.purchase.loan.bankId.bank_cd.keyword",
        order: { _count: "desc" },
        size: 9999,
      },
      aggs: {
        bank_name: {
          terms: {
            field: "data.purchase.loan.bankId.bank_name.keyword",
          },
        },
        rate_min: {
          min: {
            field: "data.purchase.loan.interestRate",
          },
        },
        rate_max: {
          max: {
            field: "data.purchase.loan.interestRate",
          },
        },
      },
    },
  };

  q.size = 0;
  return await search(q);
};

export const searchYieldCharts = async (
  filter,
  scoreRange,
  couponYieldRateRange,
  ranges,
  owner = null,
  force = false
) => {
  filter.isNotOwner = true;
  const q = buildQuery(filter);
  q.aggs = {
    scores: {
      range: {
        field: "user.user.data.latestScore.score",
        ranges: scoreRange,
      },
      aggs: {
        yields: {
          range: {
            field: "data.couponYieldRate",
            ranges: couponYieldRateRange,
          },
        },
      },
    },
  };

  q.size = 10;

  return await search(q);
};

export const searchPriceCharts = async (
  filter,
  scoreRange,
  priceRange,
  ranges,
  owner = null,
  force = false
) => {
  filter.isNotOwner = true;
  const q = buildQuery(filter);
  q.aggs = {
    scores: {
      range: {
        field: "user.user.data.latestScore.score",
        ranges: scoreRange,
      },
      aggs: {
        prices: {
          range: {
            field: "data.purchase.amount",
            ranges: priceRange,
          },
        },
      },
    },
  };

  q.size = 0;
  return await search(q);
};

export const makeAllIndex = async (query) => {
  // console.log(query);
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_realestates_makeAll", {});
  return ret.data;
};

export const putMapping = async () => {
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_realestates_putMapping", {});
  return ret.data;
};

export const createIndex = async () => {
  const ret = await firebase.functions("public_realestates_createIndex", {});
  return ret.data;
};

export const deleteIndex = async () => {
  const ret = await firebase.functions("public_realestates_deleteIndex", {});
  return ret.data;
};

export const getIndex = async () => {
  const ret = await firebase.functions("public_realestates_getIndex", {});
  return ret.data;
};
