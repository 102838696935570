<template>
  <div>
    <router-view></router-view>
    <hr />
    {{ $$user$zero }}
    <hr />
    <button @click="onClickTEST" class="button is-primary">応募</button>
    <button @click="onClickTEST2" class="button is-primary">
      応募取り消し
    </button>
    <hr />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import * as zero from "@/modules/investor/zero";
import axios from "axios";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

export default {
  name: "admin-demo-pb",
  data: function () {
    return {
      index: null,
    };
  },
  methods: {
    async onClickTEST() {
      console.log(this.me.id);
      const r = await zero.inquire(this.me.id, "TEST テストrequest");
      console.log(r);
    },
    async onClickTEST2() {
      console.log(this.me.id);
      const r = await zero.uninquire(this.me.id);
      console.log(r);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
