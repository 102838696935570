<script>
import firebase from "@/plugins/firebase";
import { mapState } from "vuex";
import investorType from "@/types/investorType";
import realtorType from "@/types/realtorType";

export default {
  name: "Mixins-user",
  // data: function() {
  //   return {
  //     auth: false
  //   };
  // },
  computed: {
    $$env$version: function () {
      return process.env.VUE_APP_VERSION;
    },
    $$env$isProduction: function () {
      return process.env.NODE_ENV === "production";
    },
    ...mapState("auth", {
      auth: "auth",
    }),
    ...mapState("user", {
      __me: "user",
    }),
    me: function () {
      if (this.__me && this.__me.user) {
        return {
          id: this.__me.user.id,
          user: {
            id: this.__me.user.id,
            data: this.__me.user.data,
            realEstates: this.__me.realEstates,
            examination: this.__me.examination,
            messages: this.__me.messages,
            informations: this.__me.informations,
            company: this.__me.company,
            companyExamination: this.__me.companyExamination,
          },
        };
      } else {
        return null;
      }
    },
    $$user$messagesOfNotAsRead: function () {
      if (this.me && this.me.user.messages) {
        return this.$$message$getMessagesOfNotAsRead(
          this.me.id,
          this.me.user.messages
        );
      }
      return [];
    },
    $$user$countMessageOfNotAsRead: function () {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary", en: "Exit" },
      //{ value: 4, text: "問い合せ", color: "", en: "Inquiry" }
      if (this.me && this.me.user.messages) {
        if (this.me.user.data.isInvestor) {
          const ms = this.$$user$messagesOfNotAsRead;
          if (!this.$$user$isPremium) {
            return ms.filter(
              (m) => m.data.type === 1 || m.data.type === 4 || m.data.type === 3
            ).length;
          } else {
            return ms.length;
          }
        } else {
          const ms = this.$$user$messagesOfNotAsRead;
          return ms.length;
        }
      }
      return 0;
    },
    $$user$informationsOfNotAsRead: function () {
      const ls = [];
      if (this.me && this.me.user && this.me.user.informations) {
        for (const info of this.me.user.informations) {
          if (!info.data.asRead) {
            ls.push(info);
          }
        }
      }
      return ls;
    },
    $$user$countInformationOfNotAsRead: function () {
      if (this.me && this.me.user && this.me.user.informations) {
        return this.$$user$informationsOfNotAsRead.length;
      }
      return 0;
    },
    $$user$allowExamination: function () {
      if (this.$$user$examination) {
        return this.$$user$examination.data.status === 20; //承認
      } else {
        return false;
      }
    },
    $$user$denyExamination: function () {
      if (this.$$user$examination) {
        return this.$$user$examination.data.status === 90; //否認
      } else {
        return true;
      }
    },
    $$user$zero: function () {
      if (
        this.me &&
        this.me.user.examination &&
        this.me.user.examination.data &&
        this.me.user.examination.data.zero
      ) {
        return this.me.user.examination.data.zero;
      } else {
        return {
          isInquiry: false,
        };
      }
    },
    $$user$examination: function () {
      if (this.me && this.me.user.examination) {
        return this.me.user.examination;
      } else {
        if (this.$$user$isInvestor) {
          return this.$$investor$getNewExamination();
        } else if (this.$$user$isRealtor) {
          return this.$$realtor$getNewExamination();
        } else {
          return this.$$investor$getNewExamination();
        }
      }
    },
    $$user$score: function () {
      if (this.me && this.me.user) {
        if (this.$$user$isInvestor) {
          return this.$$investor$calcScore(this.me.user);
        } else {
          return this.me.user.data.score;
        }
      } else {
        return this.$$investor$getNewScore();
      }
    },
    $$user$BS: function () {
      if (this.me && this.me.user && this.$$user$isInvestor) {
        return this.$$investor$calcBS(this.me.user);
      } else {
        return this.$$investor$getNewBS();
      }
    },
    $$user$PL: function () {
      if (this.me && this.me.user && this.$$user$isInvestor) {
        return this.$$investor$calcPL(this.me.user);
      } else {
        return this.$$investor$getNewPL();
      }
    },
    $$user$permission: function () {
      if (
        this.me &&
        this.me.user &&
        this.me.user.companyExamination &&
        this.me.user.companyExamination.data.permission
      ) {
        return this.me.user.companyExamination.data.permission;
      } else {
        return {
          searchInvestors: 1, // 1:通常 20:業務提携会社   99:ZIRITZ
        };
      }
    },
    $$user$isPremium: function () {
      return (
        this.me &&
        this.me.user.examination &&
        this.me.user.examination.data.membership === 20
      );
    },
    $$user$isTrial: function () {
      return this.me && this.me.user.examination.data.isTrial;
      // return this.me && this.me.user.data.isTrial;
    },
    $$user$isCancelled: function () {
      return this.me && this.me.user.examination.data.isCancelled;
      // return this.me && this.me.user.data.isCancelled;
    },
    $$user$alreadyUsedTrial: function () {
      return this.me && this.me.user.data.wasArleadyUseTrial;
    },
    $$user$wasCompletedRegister: function () {
      return this.me && this.me.user.data.wasCompletedRegister;
    },
    $$user$isAdmin: function () {
      return this.me && this.me.user.data.isAdmin;
    },
    $$user$isInvestor: function () {
      return this.me && this.me.user.data.isInvestor;
    },
    $$user$isRealtor: function () {
      return this.me && this.me.user.data.isRealtor;
    },
    $$user$loggedin: function () {
      return this.auth && !this.auth.auth.isAnonymous;
    },
    $$user$isAdministrator: function () {
      return this.auth && !this.auth.auth.isAdmin;
    },
    $$user$verifyEmail: function () {
      if (this.auth) {
        return this.auth.auth.emailVerified;
      } else {
        return false;
      }
    },
  },
  methods: {
    $$user$wait: function () {
      const self = this;
      return new Promise((resolve) => {
        // const self = this;
        const init = this.$store.getters["user/inited"];
        if (!init) {
          const unwatch = this.$store.watch(
            (state, getters) => getters["user/inited"],
            () => {
              resolve(self.me);
              unwatch();
            }
          );
        } else {
          resolve(self.me);
        }
      });
    },
    $$user$signOut: function () {
      //const self = this;
      return firebase
        .auth()
        .signOut()
        .then(() => {
          window.location.href = "/auth/signin";
          console.log("Signout!!");
        })
        .finally(() => {
          window.location.href = "/auth/signin";
        })
        .catch((error) => {
          this.$$toast$openError();
          // this.$toast.open({
          //   message: "サインアウトに失敗しました。",
          //   position: "is-top",
          //   type: "is-danger"
          // });
          self.$raven.captureException(error);
        });
    },
    $$user$loadUser: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();
      if (doc.exists) {
        const user = {
          id: doc.id,
        };

        if ("isInvestor" in doc.data()) {
          user.data = this.$$assign(investorType.type.user, doc.data());
        } else {
          user.data = this.$$assign(realtorType.type.user, doc.data());
        }
        return user;
      } else {
        return null;
      }
    },
  },
};
</script>
