<template>
  <footer class="footer" :class="{ 'is-hideMenu': isHideMenu }">
    <div class="footer__inner">
      <a class="footer__logo" href="https://stockformer.com/" target="_blank">
        <img
          src="@/assets/logo.png"
          alt="StockFormer Powerd by ZIRITZ"
          width="117"
          height="84"
        />
      </a>
      <div class="footer__link" v-if="!isHideMenu">
        <ul class="footer__link__menu footer__link__menu--secondary">
          <li>
            <router-link :to="{ name: 'investor-home' }">Dashboard</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'investor-portfolio' }"
              >My Portfolio</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'investor-collection' }"
              >My Collection</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'investor-message' }">Offers</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'investor-markettrend' }"
              >Market trend</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: '' }">New arrival</router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: '' }">StockForm Experts</router-link>
          </li> -->
        </ul>
        <ul class="footer__link__menu">
          <li>
            <router-link :to="{ name: 'investor-account' }"
              >アカウント設定</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'investor-informations' }"
              >お知らせ</router-link
            >
          </li>
          <!--
          <li><router-link :to="{ name: '' }">お問い合わせ</router-link></li>
          <li><router-link :to="{ name: '' }">ヘルプ</router-link></li>
            -->
        </ul>
      </div>
      <div class="footer__link footer__link--info" v-if="!isHideMenu">
        <ul class="footer__link__menu footer__link__menu--info">
          <li>
            <a href="https://stockformer.com/terms" target="_blank">利用規約</a>
          </li>
          <li>
            <a href="https://stockformer.com/" target="_blank">運営会社</a>
          </li>
          <li>
            <a href="https://stockformer.com/privacy" target="_blank"
              >プライバシー<br class="pcOnly" />ポリシー</a
            >
          </li>
        </ul>
        <!--
        <ul class="footer__link__menu footer__link__menu--info">
          <li>
            <a href="https://stockformer.com/transaction" target="_blank"
              >特定商取引法に<br class="pcOnly" />基づく表記</a
            >
          </li>
        </ul>
        -->
      </div>
      <div class="footer__share" v-if="!isHideMenu">
        <a
          class="footer__share__item footer__share__item--facebook"
          href="https://www.facebook.com/Stock-Former-119806009407717/"
          target="_blank"
        >
          <ZIcon
            name="facebook"
            :color="bulma.isMobile ? '#202c66' : '#fff'"
            width="40"
            height="40"
          />
        </a>
        <a
          class="footer__share__item footer__share__item--twitter"
          href="https://twitter.com/Stock_Former"
          target="_blank"
        >
          <ZIcon
            name="twitter"
            :color="bulma.isMobile ? '#202c66' : '#fff'"
            width="40"
            height="40"
          />
        </a>
      </div>
    </div>
    <div class="footer__copyright">
      <p>&copy; COPYRIGHT ZIRITZ</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isHideMenu: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.footer {
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 43px 16px 18px;
  position: absolute;
  background: linear-gradient(#202c66, #242630);
  color: #fff;
  @include md {
    display: flex;
    flex-direction: column;
  }
  @include sp {
    padding: 30px 16px 18px;
  }
  &__inner {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    position: relative;
    justify-content: space-around;
    @include md {
      display: block;
    }
    @include sp {
      max-width: 400px;
      flex-direction: column-reverse;
    }
  }
  &__logo {
    display: block;
    padding: 7px 10px;
    flex: 1;
    img {
      width: 117px;
      height: 84px;
      margin: 0 auto;
      display: block;
    }
    .is-hideMenu & {
      padding: 0 0 32px;
      pointer-events: none;
      cursor: default;
    }
    @include md {
      top: 0;
      left: 0;
      padding: 10px;
      position: absolute;
      .is-hideMenu & {
        position: relative;
        padding: 0 0 32px;
      }
    }
    @include sp {
      padding: 48px 0 32px;
      .is-hideMenu & {
        padding-top: 12px;
      }
    }
  }
  &__link {
    display: flex;
    justify-content: space-around;
    flex: 2.5;
    @include md {
      padding-left: 180px;
      justify-content: flex-start;
    }
    @include sp {
      display: none;
    }
    &--info {
      flex: 2;
      @include sp {
        display: flex;
      }
    }
    &__menu {
      font-size: 17px;
      line-height: 17px;
      @include md {
        width: 180px;
      }
      &--secondary {
        font-family: $family-secondary;
      }
      &--info {
        font-size: 13px;
        line-height: 17px;
        @include sp {
          font-size: 15px;
          line-height: 15px;
        }
      }
      li {
        a {
          padding: 7px 0;
          display: inline-block;
          color: #fff;
          @include md {
            .pcOnly {
              display: none;
            }
          }
          @include sp {
            padding: 10px 0;
            .pcOnly {
              display: none;
            }
          }
        }
      }
    }
  }
  &__share {
    padding: 7px 0;
    flex: 1;
    text-align: center;
    @include md {
      padding-top: 12px;
      padding-left: 180px;
      padding-bottom: 24px;
      text-align: left;
    }
    @include sp {
      top: -90px;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
    }
    &__item {
      display: inline-block;
      transition: opacity 0.2s ease;
      &:not(:first-child) {
        padding-left: 16px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__copyright {
    font-size: 14px;
    text-align: center;
  }
}

// .mtr-footer {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   padding: 48px 60px 22px 64px;
//   background: linear-gradient(#202c66, #242630);
//   color: #fff;
// }
// .mtr-footer__links {
//   display: flex;
//   padding-left: 190px;
//   @include sp {
//     padding-left: 0;
//   }
//   ul {
//     width: 50%;
//   }
//   a {
//     color: #fff;
//     font-size: 13px;
//   }
// }
// .mtr-footer__logo {
//   position: absolute;
//   top: 0;
//   left: 0;
//   @include sp {
//     position: relative;
//   }
// }
// .mtr-footer__logo__item {
//   display: block;
//   width: 117px;
//   height: 84px;
// }
// .mtr-footer__copyright {
//   margin-top: 20px;
//   font-family: $family-secondary;
//   font-size: 14px;
//   text-align: center;
// }
</style>
