<template>
  <div>
    <ZEditAccordion
      title="絞り込み検索条件"
      icon="magnify"
      isProtrude
      :opened="true"
    >
      <h4 class="edit__subtitle2 menu__title">種別</h4>
      <ul>
        <template v-for="realEstateType in $$ks.realEstateTypes">
          <div :key="realEstateType.value">
            <li>
              <div class="content menu__checkBox">
                <b-checkbox
                  v-model="filters.realEstateTypes[realEstateType.value]"
                  type="is-info"
                />
                <span class="menu__checkBox__label">{{
                  realEstateType.text
                }}</span>
              </div>
            </li>
          </div>
        </template>
      </ul>
      <h4 class="edit__subtitle2 menu__title">構造</h4>
      <ul>
        <template v-for="buildingType in $$ks.typeOfBuildingTypes">
          <div :key="buildingType.value">
            <li>
              <div class="content menu__checkBox">
                <b-checkbox
                  v-model="filters.buildingTypes[buildingType.value]"
                  type="is-info"
                />
                <span class="menu__checkBox__label">{{
                  buildingType.text
                }}</span>
              </div>
            </li>
          </div>
        </template>
      </ul>
      <h4 class="edit__subtitle2 menu__title">価格</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.price.from"
          :options="$$ks.filters.price.from"
        />
        <span class="menu__select__tilde">~</span>
        <z-select
          class="menu__select__input"
          v-model="filters.price.to"
          :options="$$ks.filters.price.to"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">売却利回り</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.yieldRate.from"
          :options="$$ks.filters.yieldRate.from"
        />
        <span class="menu__select__tilde">~</span>
        <z-select
          class="menu__select__input"
          v-model="filters.yieldRate.to"
          :options="$$ks.filters.yieldRate.to"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">築年数</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.yearOfConstruction.from"
          :options="$$ks.filters.yearOfconstruction.from"
        />
        <span class="menu__select__tilde">~</span>
        <z-select
          class="menu__select__input"
          v-model="filters.yearOfConstruction.to"
          :options="$$ks.filters.yearOfconstruction.to"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">駅からの距離</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.elapsedTime"
          :options="$$ks.filters.elapsedTime"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">建物面積</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.buildingArea.from"
          :options="$$ks.filters.buildingArea.from"
        />
        <span class="menu__select__tilde">~</span>
        <z-select
          class="menu__select__input"
          v-model="filters.buildingArea.to"
          :options="$$ks.filters.buildingArea.to"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">土地面積</h4>
      <div class="menu__select">
        <z-select
          class="menu__select__input"
          v-model="filters.landArea.from"
          :options="$$ks.filters.landArea.from"
        />
        <span class="menu__select__tilde">~</span>
        <z-select
          class="menu__select__input"
          v-model="filters.landArea.to"
          :options="$$ks.filters.landArea.to"
        />
      </div>
      <h4 class="edit__subtitle2 menu__title">間取り</h4>
      <ul>
        <template v-for="layout in $$ks.layouts">
          <div :key="layout.value">
            <li>
              <div class="content menu__checkBox">
                <b-checkbox
                  v-model="filters.layouts[layout.value]"
                  type="is-info"
                />
                <span class="menu__checkBox__label">{{ layout.text }}</span>
              </div>
            </li>
          </div>
        </template>
      </ul>
      <button class="button is-primary menu__button" @click="execFilter">
        検索する
      </button>
    </ZEditAccordion>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

//import RealEstateList from "@/views/realtor/common/RealEstateList";
// import RealEstate from "./parts/RealEstate";

export default {
  name: "comp-realtor-realestates-filter",
  // data: function() {
  //   return {
  //   };
  // },
  props: {
    filters: {
      type: [Object],
      required: true,
    },
  },
  methods: {
    // onRowClick(realEstate) {
    //   const routeData = this.$router.resolve({
    //     name: "realtor-search-realestates-detail",
    //     params: { id: realEstate.id }
    //   });
    //   window.open(routeData.href, "_blank");
    // },
    // onOfferClick(realEstate) {
    //   const routeData = this.$router.resolve({
    //     name: "realtor-message-detail",
    //     params: { id: "new" },
    //     query: {
    //       realEstateId: realEstate.id,
    //       messageType: 3 //売却オファー
    //     }
    //   });
    //   window.open(routeData.href, "_blank");
    //   // this.$router.push({
    //   //   name: "realtor-message-detail",
    //   //   params: { id: "new" },
    //   //   query: {
    //   //     realEstateId: realEstate.id,
    //   //     messageType: 3 //売却オファー
    //   //   }
    //   // });
    // },
    // async address(dt) {
    //   const text = await this.$$area$generateTextOfAddress(dt);
    //   return text;
    // },
    //async loadRealEstate(r) {
    //  r.address = await this.address(r.data);
    //  // r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
    //  return r;
    //},
    async execFilter() {
      this.$emit("filter");
    },
    // async init() {
    //   //const loadingComponent = this.$loading.open();
    //   try {
    //     this.isLoading = true;
    //     this.realEstates = await this.$$realEstate$loadRealEstatesToRealtor();
    //     // const promises = [];
    //     // for (const r of res) {
    //     //   promises.push(this.loadRealEstate(r));
    //     // }
    //     // this.realEstates = await Promise.all(promises).then(rs => rs);
    //     this.isLoading = false;
    //     return;
    //   } catch (error) {
    //     this.$$error$catchLoadError(error);
    //   } finally {
    //     //loadingComponent.close();
    //   }
    // },
    // buildingTypeText(val) {
    //   return this.$$ks.getTypeOfBuildingType(val).text;
    // },
    // sortRealEstates() {
    //   if (this.sortType === 1) {
    //     this.realEstates.sort((reA, reB) => {
    //       if (reA.data.registerDt < reB.data.registerDt) return 1;
    //       if (reA.data.registerDt > reB.data.registerDt) return -1;
    //       return 0;
    //     });
    //   }
    //   if (this.sortType === 2) {
    //     this.realEstates.sort((reA, reB) => {
    //       if (
    //         reA.data.saleSetting.askingPrice > reB.data.saleSetting.askingPrice
    //       )
    //         return 1;
    //       if (
    //         reA.data.saleSetting.askingPrice < reB.data.saleSetting.askingPrice
    //       )
    //         return -1;
    //       return 0;
    //     });
    //   }
    //   if (this.sortType === 3) {
    //     this.realEstates.sort((reA, reB) => {
    //       if (
    //         reA.data.saleSetting.askingPrice < reB.data.saleSetting.askingPrice
    //       )
    //         return 1;
    //       if (
    //         reA.data.saleSetting.askingPrice > reB.data.saleSetting.askingPrice
    //       )
    //         return -1;
    //       return 0;
    //     });
    //   }
    //   if (this.sortType === 4) {
    //     this.realEstates.sort((reA, reB) => {
    //       if (
    //         reA.data.saleSetting.couponYieldRate <
    //         reB.data.saleSetting.couponYieldRate
    //       )
    //         return 1;
    //       if (
    //         reA.data.saleSetting.couponYieldRate >
    //         reB.data.saleSetting.couponYieldRate
    //       )
    //         return -1;
    //       return 0;
    //     });
    //   }
    //   if (this.sortType === 5) {
    //     this.realEstates.sort((reA, reB) => {
    //       const yocA = this.$$realEstate$calcDayOfConstruction(
    //         reA.data.constructionDate
    //       );
    //       const yocB = this.$$realEstate$calcDayOfConstruction(
    //         reB.data.constructionDate
    //       );
    //       if (yocA > yocB) return 1;
    //       if (yocA < yocB) return -1;
    //       return 0;
    //     });
    //   }
    // }
  },
  // beforeRouteUpdate(to, from, next) {
  //   next();
  //   return this.init();
  // },
  // mounted() {
  //   this.init();
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.menu {
  &__title {
    margin-top: 17px;
  }
  &__checkBox {
    font-size: 14px;
    &__label {
      color: #333333;
      vertical-align: 4px;
    }
  }
  &__select {
    display: flex;
    &__input {
      width: 38%;
    }
    &__tilde {
      margin: 8px;
    }
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
}

// .count {
//   margin-top: -25px;
//   margin-bottom: 10px;
//   &__number {
//     font-size: 1.3rem;
//     font-weight: bold;
//     color: #f00;
//     padding-left: 5px;
//     line-height: 3px;
//   }
// }
//
// .list-title {
//   width: 100%;
//   margin-top: 0px;
//   margin-bottom: 0px;
//   padding: 11px 22px;
//   background-color: $important;
//   border-bottom: solid 2px #707070;
//   color: $white;
// }

// .sort {
//   margin-top: 15px;
//   margin-bottom: -25px;
//   &__title {
//     margin: -28px 8px 0 0;
//     font-weight: bold;
//   }
// }

// .realestate-list {
//   margin-left: 20px;
//   @include mobile {
//     margin-left: 0;
//   }
//   &__column {
//     &:hover {
//       outline: solid 2px $important;
//     }
//   }
//   &__detail {
//     margin-top: 8px;
//     margin-bottom: 8px;
//   }
//   &__hr {
//     margin-top: 4px;
//     margin-bottom: 8px;
//   }
//   &__footer {
//     display: flex;
//     justify-content: space-between;
//     &__date {
//       margin-top: 15px;
//     }
//   }
// }
</style>
