<template>
  <div>
    <router-view></router-view>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <z-select label="" v-model="prefecture" :options="$$ks.prefectures">
            </z-select>
          </div>
          <div class="column is-6">
            {{ $$ks.getPrefecture(prefecture) }}
          </div>
        </div>
        <hr />
        {{ $$ks.getDeliveryDateMonth(val) }}
        <hr />
        {{ $$ks.getTypeOfSex(val) }}
        <hr />
        {{ $$ks.getTypeOfOccupation(val) }}
        <hr />
        {{ $$ks.getCategoryOfBusiness(val) }}
        <hr />
        {{ $$ks.realEstateType(val) }}
        <hr />
        {{ $$ks.getRank(val) }}
        <hr />
        {{ $$ks.loanMethodType(val) }}
        <hr />
        {{ $$ks.getTypeOfBuildingType(val) }}
        <hr />
        {{ $$ks.getLandRightType(val) }}
        <hr />
        {{ $$ks.getUrbanPlanningAreaType(val) }}
        <hr />
        {{ $$ks.getIncomeType(val) }}
        <hr />
        {{ $$ks.getIncomeCashType(val) }}
        <hr />
        {{ $$ks.getIncomeSecurityType(val) }}
        <hr />
        {{ $$ks.getIncomeInsuranceType(val) }}
        <hr />
        {{ $$ks.getEducationType(val) }}
        <hr />
        {{ $$ks.getTextureType(val) }}
        <hr />
        {{ $$ks.getAreaOfUseType(val) }}
        <hr />
        {{ $$ks.investor.application.getMembership(val) }}
        <hr />
        {{ $$ks.investor.application.getStatus(val) }}
        <hr />
        {{ $$ks.realtor.application.company.getStatus(val) }}
        <hr />
        {{ $$ks.realtor.application.user.getStatus(10) }}
        <hr />
        {{ $$ks.realtor.application.getReasonForRejection(100) }}
        <hr />
        {{ $$ks.realtor.getRole(10) }}
        <hr />
        {{ $$ks.realtor.getSpecialty(val) }}
        <hr />
        {{ $$ks.realtor.getYearOfexperience(5) }}
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-kubuns",
  data: function () {
    return {
      prefecture: null,
      val: 1,
    };
  },
};
</script>
