var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"data":_vm.realEstates,"sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort":['data.registerDt', 'desc'],"current-page.sync":"currentPage","per-page":"50","paginated":"","loading":_vm.isLoading,"checked-rows":_vm.internalValue,"checkable":_vm.checkable,"hoverable":true,"narrowed":true,"custom-row-key":"id","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},on:{"update:checkedRows":function($event){_vm.internalValue=$event},"update:checked-rows":function($event){_vm.internalValue=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"id","label":"物件概要"}},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-4"},[_c('router-link',{attrs:{"to":{
                name: 'realtor-setting-realestates-preview',
                params: { id: props.row.id },
              }}},[_c('figure',{staticClass:"image"},[_c('z-image',{model:{value:(_vm.images(props.row)[0]),callback:function ($$v) {_vm.$set(_vm.images(props.row), 0, $$v)},expression:"images(props.row)[0]"}})],1)]),_c('br')],1),_c('div',{staticClass:"column"},[_c('div',{staticClass:"content is-small"},[_c('p',[_c('z-tag',{attrs:{"options":_vm.$$ks.realEstateTypes},model:{value:(props.row.data.realEstateType),callback:function ($$v) {_vm.$set(props.row.data, "realEstateType", $$v)},expression:"props.row.data.realEstateType"}})],1),_c('z-truncator',{staticClass:"real-estate-name",attrs:{"text":props.row.data.name}}),_c('p',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm.$$price$priceJPFormat(props.row.data.price))+" / "+_vm._s(_vm.$$rete$rateFormat(props.row.data.couponYieldRate))+" ")]),(props.row.data.overseasRealEstate)?[_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.overseasAddress(props.row.data.overseas))+" ")])]:[_c('p',{staticClass:"address",domProps:{"textContent":_vm._s(props.row.address)}})],_c('div',{staticClass:"flex-wrap"},[(
                    props.row.data.realEstateType !== 10 &&
                    props.row.data.realEstateType !== 7
                  )?[_c('p',[_vm._v(" 築年月："+_vm._s(_vm.$$calender$yearMonthFormat( props.row.data.constructionDate ))+" ")]),_c('p',[_vm._v(" 建物種別："+_vm._s(_vm.buildingTypeText(props.row.data.buildingType))+" ")])]:[_c('p',[_vm._v("築年月：-")]),_c('p',[_vm._v("建物種別：-")])]],2)],2)])])]),_c('b-table-column',{attrs:{"field":"data.saleSetting.publicType","label":"公開範囲","width":"80","sortable":""}},[_c('div',{staticClass:"content is-small"},[_vm._v(" "+_vm._s(_vm.$$ks.realtor.realEstate.publicType( props.row.data.saleSetting.publicType ).text)+" ")])]),_c('b-table-column',{attrs:{"field":"","label":"オファー状況・掲載状況","width":"176"}},[_c('div',{staticClass:"content is-small"},[_c('p',[_vm._v(" 問い合せ："+_vm._s(_vm.countContact(props.row))+"件"),_c('br'),_vm._v(" オファー："+_vm._s(_vm.countOffers(props.row))+"件"),_c('br'),_vm._v(" 返答数："+_vm._s(_vm.countReply(props.row))+"件"),_c('br'),_vm._v(" 閲覧人数："+_vm._s(props.row.viewsCount)+"件 ")])])]),_c('b-table-column',{attrs:{"field":"data.registerDt","label":"登録日","width":"192","sortable":""}},[_c('div',{staticClass:"content is-small"},[_c('p',[_vm._v(" 初回登録日   :"+_vm._s(_vm.$$calender$dateFormat(props.row.data.registerDt))),_c('br'),_vm._v(" 更新日          :"+_vm._s(_vm.$$calender$dateFormat(props.row.data.updatedDt))),_c('br'),_vm._v(" 更新予定日   :"+_vm._s(_vm.$$calender$dateFormat(props.row.data.scheduledUpdateDate))),_c('br')]),(!_vm.hideModifybutton)?[_c('router-link',{staticClass:"button is-primary is-small is-rounded z-button",attrs:{"to":{
                name: 'realtor-setting-realestates-detail',
                params: { id: props.row.id },
              }}},[_vm._v("表示/変更")])]:_vm._e()],2)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }