<template>
  <div class="z-balanceSheet">
    <div class="z-balanceSheet__inner">
      <ul class="z-balanceSheet__legends">
        <li
          class="z-balanceSheet__legends__item z-balanceSheet__legends__item--cash"
        >
          現金
        </li>
        <li
          class="z-balanceSheet__legends__item z-balanceSheet__legends__item--investment"
        >
          証券・保険
        </li>
        <li
          class="z-balanceSheet__legends__item z-balanceSheet__legends__item--realestate"
        >
          不動産
        </li>
        <li
          class="z-balanceSheet__legends__item z-balanceSheet__legends__item--longterm"
        >
          融資
        </li>
        <li
          class="z-balanceSheet__legends__item z-balanceSheet__legends__item--equity"
        >
          純資産
        </li>
      </ul>
      <ZBalanceSheetChart
        class="z-balanceSheet__chart"
        :quarterData="chartData"
        :currentBS="bs"
      />
    </div>
    <ZQuarterAxis
      :quarterData="chartData"
      :currentScore="score"
      :year.sync="selectYear"
    />
  </div>
</template>

<script>
import ZBalanceSheetChart from "@/components/charts/ZBalanceSheetChart.vue";
import ZQuarterAxis from "@/components/charts/ZQuarterAxis.vue";

export default {
  name: "ZBalanceSheet",
  components: {
    ZBalanceSheetChart,
    ZQuarterAxis,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    bs: {
      type: Object,
      required: true,
    },
    score: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectYear: {
      get() {
        return this.year;
      },
      set(newVal) {
        if (this.year !== newVal) this.$emit("update:year", newVal);
      },
    },
    quarterBS() {
      return this.chartData.map((item) => item.BS);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-balanceSheet {
  width: 100%;
  // padding: 0 46px;
  position: relative;
  overflow: hidden;
  @include sp {
    padding: 0;
  }
}

.z-balanceSheet__inner {
  border: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  @include sp {
    border-radius: 0;
  }
}

.z-balanceSheet__chart {
  padding-right: 70px;
  /deep/ canvas {
    left: -1px;
    height: 320px !important;
    position: relative;
  }
  @include sp {
    padding-right: 0;
  }
}

.z-balanceSheet__legends {
  width: 100%;
  height: 62px;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  @include sp {
    padding: 9px 0;
    flex-wrap: wrap;
  }
  &__item {
    font-size: 12px;
    margin-left: 20px;
    @include sp {
      font-size: 10px;
    }
    &:last-child {
      margin-right: 20px;
    }
    &::before {
      width: 12px;
      height: 12px;
      margin-right: 6px;
      display: inline-block;
      background-color: #333;
      vertical-align: -1.5px;
      content: "";
    }
    &--cash {
      &::before {
        background-color: #b0d8f8;
      }
    }
    &--investment {
      &::before {
        background-color: #48adff;
      }
    }
    &--realestate {
      &::before {
        background-color: #3074c2;
      }
    }
    &--longterm {
      &::before {
        background-color: #092550;
      }
    }
    &--equity {
      &::before {
        background-color: #b8ecff;
      }
    }
  }
}
</style>
