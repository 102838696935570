<template>
  <div>
    <div class="auth-footer">
      <span v-html="htmlOfFooter"></span>
    </div>
  </div>
</template>

<script>
import axios from "axios-jsonp-pro";

export default {
  name: "auht-Footer",
  asyncComputed: {
    async htmlOfFooter() {
      const url = "/lp/footer.html";

      const res = await axios.get(url, {
        "Content-Type": "text/html;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      });
      return res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.auth-footer {
  top: 100vh;
  top: calc(var(--vh, 1vh) * 100 - 81px);
  @include sp {
    top: 100vh;
    top: calc(var(--vh, 1vh) * 100 - 51px);
  }
}
</style>
