<template>
  <div class="collectionDetail">
    <div class="collectionDetail__overviewArea" @click="openRealEstateDetail">
      <div class="collectionDetail__overviewArea__outline">
        <p class="realEstateType">{{ realEstateType }}</p>
        <ul class="commonList">
          <li class="commonList__item">
            <p class="commonList__item__label">購入価格</p>
            <p class="commonList__item__amount">{{ purchasePrice }}</p>
          </li>
          <li class="commonList__item">
            <p class="commonList__item__label">年間想定収入</p>
            <p class="commonList__item__amount">{{ annualEstimatedIncome }}</p>
          </li>
          <li class="commonList__item">
            <p class="commonList__item__label">表面利回り</p>
            <p class="commonList__item__amount">{{ yieldRate }}</p>
          </li>
        </ul>
        <p class="buyingDate">購入年月 {{ buyingDate }}</p>
      </div>
      <div class="collectionDetail__overviewArea__detailInfo">
        <div class="locationInfo">
          <dl class="info">
            <dt class="info__title">所在地</dt>
            <dd class="info__description">{{ realEstateAddress }}</dd>
          </dl>
          <dl class="info">
            <dt class="info__title">交通</dt>
            <dd class="info__description">
              <ul class="transportsList">
                <li
                  class="ransportsList__item"
                  v-for="(transport, transports_index) in accessArray"
                  :key="transports_index"
                >
                  <p class="info__description">
                    {{ accessArray[transports_index] }}
                  </p>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
        <div class="otherInfo">
          <div class="otherInfo__block">
            <dl class="info">
              <dt class="info__title">建物種別</dt>
              <dd class="info__description">
                {{
                  realEstateObj.data.buildingType != null ? buildingType : "-"
                }}
              </dd>
              <!-- <dd class="info__description">{{ buildingType }}</dd> -->
            </dl>
            <dl class="info">
              <dt class="info__title">建物面積</dt>
              <dd class="info__description">
                {{
                  realEstateObj.data.buildingArea != null ? buildingArea : "-"
                }}
              </dd>
              <!-- <dd class="info__description">{{ buildingArea }}</dd> -->
            </dl>
            <dl class="info">
              <dt class="info__title">土地面積</dt>
              <dd class="info__description">{{ landArea }}</dd>
            </dl>
          </div>
          <div class="otherInfo__block">
            <dl class="info">
              <dt class="info__title">築年月</dt>
              <dd class="info__description">
                {{
                  realEstateObj.data.constructionDate.year != null &&
                  realEstateObj.data.constructionDate.month != null
                    ? constructionDate
                    : "-"
                }}
              </dd>
              <!-- <dd class="info__description">{{ constructionDate }}</dd> -->
            </dl>
            <dl class="info">
              <dt class="info__title">階数</dt>
              <dd class="info__description">
                {{
                  realEstateObj.data.numberOfFloors != null
                    ? numberOfFloors
                    : "-"
                }}
              </dd>
              <!-- <dd class="info__description">{{ numberOfFloors }}</dd> -->
            </dl>
          </div>
        </div>
      </div>
      <div class="collectionDetail__overviewArea__img">
        <div class="zimageWrapper" v-if="isImageExist">
          <z-image class="image" :value="allImgArray[0]" />
        </div>

        <!-- 画像未登録時 -->
        <div class="zblankBoxWrapper" v-if="!isImageExist">
          <ZBlankBox :borderActive="false" :roundEdgeActive="false">
            <div class="emptyTxt">
              <p class="emptyTxt__title">登録画像なし</p>
              <p class="emptyTxt__description">
                右上の
                <span class="emptyTxt__description__editIconWrapper">
                  <b-icon class="editIcon" icon="border-color" size="is-small">
                  </b-icon>
                </span>
                マークから追加できます
              </p>
            </div>
          </ZBlankBox>
        </div>
      </div>
      <p class="collectionDetail__overviewArea__moreInfoTxt">詳しくみる</p>
    </div>
    <div class="collectionDetail__saleArea">
      <!--
    <div
      class="collectionDetail__saleArea"
      v-bind:class="{
        collectionDetail__saleArea__freeUserBG: !$$user$isPremium
      }"
    -->
      <div class="collectionDetail__saleAreaInner">
        <p class="collectionDetail__saleArea__titleTxt">希望売却設定</p>
        <div class="collectionDetail__saleArea__priceInfo">
          <ul class="commonList commonList--sale">
            <li class="commonList__item">
              <p class="commonList__item__label">売却希望価格</p>
              <p class="commonList__item__amount">{{ askingPrice }}</p>
            </li>
            <li class="commonList__item">
              <p class="commonList__item__label">売却利回り</p>
              <p class="commonList__item__amount">{{ saleYieldRate }}</p>
            </li>
          </ul>
        </div>
        <div class="collectionDetail__saleArea__needsAndOffers">
          <div class="needsAndOffersBlock">
            <dl class="needsAndOffersBlock__detail">
              <dt class="needsAndOffersBlock__detail__label">
                買いニーズマッチング件数
                <div class="helpIconWrapper">
                  <ZHelpIcon
                    class="helpIcon"
                    help="本売却希望条件に対し、StockFromer内の投資家が設定する不動産購入ニーズとマッチする件数。マッチする件数が多いほど早期で売却できる可能性が高い等、売却希望条件に対するマーケット評価の指標となる。"
                  />
                </div>
              </dt>
              <dd class="needsAndOffersBlock__detail__amount">
                {{ buyingNeeds
                }}<span class="needsAndOffersBlock__detail__amount__unit"
                  >件</span
                >
              </dd>
            </dl>
          </div>
          <div class="needsAndOffersBlock">
            <dl class="needsAndOffersBlock__detail">
              <dt class="needsAndOffersBlock__detail__label">
                売却オファー
                <div class="helpIconWrapper">
                  <ZHelpIcon
                    class="helpIcon"
                    help="保有不動産の売却希望条件に対する、不動産会社・専門家からのご提案メッセージ件数。提案件数が多いほど売却できる可能性が高いと感じている専門家が多い等、売却希望条件に対するマーケット評価の指標となる。"
                  />
                </div>
              </dt>
              <dd
                class="needsAndOffersBlock__detail__amount needsAndOffersBlock__detail__amount--sale"
                @click="openSaleOfferDetail"
              >
                {{ saleOffer
                }}<span class="needsAndOffersBlock__detail__amount__unit"
                  >件<span class="unreadOfferBadge" v-if="unreadOfferBadge">
                    <p class="unreadOfferBadge__amount">
                      {{ unreadOfferBadge }}
                    </p>
                  </span>
                </span>
              </dd>
            </dl>
          </div>
        </div>
        <div class="collectionDetail__saleArea__changePriceButton">
          <b-button
            class="z-button"
            type="is-primary"
            size="is-full-width"
            rounded
            outlined
            @click="onOpenEditSaleRealEstate"
            >希望売却設定を変更する</b-button
          >
          <EditSaleRealEstate
            v-model="realEstateObj"
            :isActive.sync="isActiveEditSaleRealEstate"
          />
        </div>
        <button
          class="collectionDetail__saleArea__howItLooks"
          @click="openRealEstatePreview"
        >
          <div class="eyeIconWrapper">
            <b-icon class="eyeIcon" icon="eye"> </b-icon>
          </div>
          <p class="collectionDetail__saleArea__howItLooks__label">
            売却時の見え方を確認する
          </p>
        </button>
      </div>

      <!-- 無料ユーザー向け表現 -->
      <!-- /無料ユーザー向け表現 -->
    </div>
  </div>
</template>

<script>
import objectFitImages from "object-fit-images";
import EditSaleRealEstate from "@/views/investor/portfolio/parts/EditSaleRealEstate";
import IeBlurImageLg from "@/assets/investor/collection-dummy-pc.png";
import IeBlurImageSp from "@/assets/investor/collection-dummy-sp.png";

export default {
  name: "CollectionDetail",
  props: {
    entity: {
      type: Object,
    },
  },
  components: {
    EditSaleRealEstate,
  },
  data: function () {
    return {
      realEstateObj: this.entity,
      isLoading: true,
      isActiveEditSaleRealEstate: false,
    };
  },
  asyncComputed: {
    async realEstateAddress() {
      const result = await this.$$area$generateTextOfAddress(
        this.realEstateObj.data
      );
      return result;
    },
    async accessArray() {
      const result = await this.$$realEstate$transportListFormt(
        this.realEstateObj.data.transports
      );
      return result;
    },
    async saleOffer() {
      const result = await this.$$message$getMessagesByRealEstate(
        this.realEstateObj.id
      );
      // if (this.realEstateObj.data.saleSetting.publicType === 1) {
      //   return 0;
      // }
      return result.length;
    },
    async buyingNeeds() {
      // console.log(this.realEstateObj);
      const result = await this.$$realEstate$countBuyingNeeds(
        this.realEstateObj
      );
      // if (this.realEstateObj.data.saleSetting.publicType === 1) {
      //   return 0;
      // }
      return result.count;
    },
    async unreadOfferBadge() {
      const result = await this.$$message$countMesaageAndNotAsRead(
        this.me.id,
        this.realEstateObj.id
      );
      if (this.realEstateObj.data.saleSetting.publicType === 1) {
        return 0;
      }
      return result.countOfNotAsRead;
    },
  },
  computed: {
    dummyImage() {
      if (this.bulma.isMobile) {
        return IeBlurImageSp;
      } else {
        return IeBlurImageLg;
      }
    },
    isUnreadOfferBadgeExist() {
      if (this.unreadOfferBadge) {
        if (this.$$userAgent$isIE()) {
          return false;
        }
        return true;
      }
      return false;
    },
    realEstateType() {
      return this.$$ks.realEstateType(this.realEstateObj.data.realEstateType)
        .text;
    },
    purchasePrice() {
      return this.$$price$priceJPFormat(
        this.realEstateObj.data.purchase.amount
      );
    },
    annualEstimatedIncome() {
      if (
        this.realEstateObj.data.realEstateType === 7 ||
        this.realEstateObj.data.realEstateType === 10
      ) {
        if (this.realEstateObj.data.annualEstimatedIncome === 0) {
          return "-";
        }
      }
      return this.$$price$priceJPFormat(
        this.realEstateObj.data.annualEstimatedIncome
      );
    },
    yieldRate() {
      if (
        this.realEstateObj.data.realEstateType === 7 ||
        this.realEstateObj.data.realEstateType === 10
      ) {
        if (this.realEstateObj.data.couponYieldRate === 0) {
          return "-";
        }
      }
      const couponYieldRate = this.realEstateObj.data.couponYieldRate || 0;
      return this.$$rete$rateFormat(couponYieldRate);
    },
    buyingDate() {
      return this.$$calender$yearMonthFormat(
        this.realEstateObj.data.purchase.buyingDt,
        "/"
      );
      // return this.$$calender$yeartMonthFormat(
      //   this.realEstateObj.data.purchase.buyingDt
      // );
    },
    buildingType() {
      return this.$$ks.getTypeOfBuildingType(
        this.realEstateObj.data.buildingType
      ).text;
    },
    buildingArea() {
      return String(this.realEstateObj.data.buildingArea) + "㎡";
    },
    landArea() {
      return String(this.realEstateObj.data.saleSetting.landArea) + "㎡";
    },
    constructionDate() {
      const year = String(this.realEstateObj.data.constructionDate.year);
      const month = String(this.realEstateObj.data.constructionDate.month);
      const calcYearOfConstruction = String(
        this.$$realEstate$calcYearOfConstruction(
          this.realEstateObj.data.constructionDate
        )
      );
      return year + "/" + month + "(築" + calcYearOfConstruction + "年)";
    },
    numberOfFloors() {
      return this.realEstateObj.data.numberOfFloors;
    },
    allImgArray() {
      const img = this.realEstateObj.data.images;
      const exteriors = img.exteriors;
      const interiors = img.interiors;
      const others = img.others;
      return exteriors.concat(interiors).concat(others);
    },
    isImageExist() {
      if (this.allImgArray.length === 0) {
        return false;
      }
      return true;
    },
    askingPrice() {
      return this.$$price$priceJPFormat(
        this.realEstateObj.data.saleSetting.askingPrice
      );
    },
    saleYieldRate() {
      const couponYieldRate =
        this.realEstateObj.data.saleSetting.couponYieldRate || 0;
      return this.$$rete$rateFormat(couponYieldRate);
    },
  },
  methods: {
    openRealEstateDetail() {
      this.$router.push({
        name: "investor-collection-realestate",
        params: { id: this.realEstateObj.id },
      });
    },
    openRealEstatePreview() {
      this.$router.push({
        name: "investor-collection-realestatepreview",
        params: { id: this.realEstateObj.id },
      });
    },
    onOpenEditSaleRealEstate() {
      this.isActiveEditSaleRealEstate = true;
    },
    //この物件に紐付くオファー画面への繊維
    openSaleOfferDetail() {
      this.$router.push({
        name: "investor-message",
        query: { rid: this.realEstateObj.id },
      });
    },
  },
  mounted() {
    objectFitImages();
  },
  watch: {
    entity: {
      handler: function (newVal) {
        this.realEstateObj = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.collectionDetail {
  color: #333333;
  &__overviewArea {
    padding: 16px 16px 0 16px;
    cursor: pointer;
    @include sp {
      padding: 14px 14px 0 14px;
      &__outline {
        font-size: 14px;
      }
    }
    &__detailInfo {
      padding-top: 16px;
      @include sp {
        padding-top: 6px;
      }
    }
    &__img {
      min-height: 372px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include sp {
        min-height: 240px;
      }

      .zimageWrapper {
        width: 100%;
        height: 352px;
        @include sp {
          height: 218px;
        }
        background-color: #f5f5f5;
        /deep/ img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          font-family: "object-fit: contain;";
        }
      }

      .zblankBoxWrapper {
        width: 100%;
        .emptyTxt {
          height: 352px;
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          @include sp {
            height: 218px;
          }
          &__title {
            font-size: 20px;
            font-weight: bold;
            @include sp {
              font-size: 16px;
            }
          }
          &__description {
            font-size: 16px;
            padding: 16px 16px 0 16px;
            @include sp {
              font-size: 14px;
            }
            .editIcon {
              vertical-align: middle;
              @include sp {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    &__moreInfoTxt {
      font-size: 14px;
      line-height: 14px;
      text-align: right;
      padding-bottom: 10px;
    }
    .realEstateType {
      font-size: 16px;
      line-height: 16px;
      @include sp {
        font-size: 14px;
      }
    }
    .buyingDate {
      font-size: 12px;
      line-height: 12px;
      padding-top: 10px;
      text-align: right;
    }
    .otherInfo {
      display: flex;
      &__block {
        min-width: 50%;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      padding-top: 8px;
      &__title {
        min-width: 60px;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        color: #777777;
      }
      &__description {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  &__saleArea {
    padding: 16px;
    background-color: #e6effe;
    border-radius: 0 0 8px 8px;
    @include sp {
      padding: 14px;
    }
    &__titleTxt {
      font-size: 16px;
      line-height: 16px;
      @include sp {
        font-size: 14px;
      }
    }
    &__needsAndOffers {
      padding: 28px 0 8px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp {
        padding: 6px 0 6px 0;
      }
      .needsAndOffersBlock {
        width: 48.5%;
        align-self: stretch;
        &__detail {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          font-weight: bold;
          @include sp {
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
          &__label {
            text-align: center;
            line-height: 24px;
            @include sp {
              font-size: 14px;
              line-height: 14px;
            }
          }
          &__amount {
            margin-left: 1px;
            white-space: nowrap;
            font-size: 24px;
            line-height: 24px;
            color: #0062d7;
            @include sp {
              font-size: 22px;
            }
            &--sale {
              cursor: pointer;
            }
            &__unit {
              font-size: 14px;
              position: relative;
              .unreadOfferBadge {
                position: absolute;
                height: 20px;
                min-width: 20px;
                left: 8px;
                top: -14px;
                background-color: #ff0090;
                border-radius: 20px;
                &__amount {
                  padding: 0 4px;
                  text-align: center;
                  line-height: 20px;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .helpIconWrapper {
          display: inline;
          @include sp {
            display: none;
          }
        }
      }
    }
    &__howItLooks {
      margin: 14px 0 0 auto;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      cursor: pointer;
      .eyeIconWrapper {
        .eyeIcon {
          vertical-align: middle;
          color: #777777;
        }
      }
      &__label {
        font-size: 15px;
        color: #333333;
        line-height: 21px;
        padding-left: 6px;
      }
    }
    &__freeUserBG {
      background-color: #f2f7fd;
    }
  }
  .commonList {
    padding-top: 20px;
    &--sale {
      padding-top: 18px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px;
      border-color: #dddddd;
      padding-top: 20px;
      padding-bottom: 2px;
      &:first-child {
        padding-top: 0;
      }
      &__label {
        font-size: 14px;
        font-weight: bold;
        color: #777777;
      }
      &__amount {
        font-size: 20px;
        line-height: 24px;
      }
    }
    @include sp {
      padding-top: 12px;
      &--sale {
        padding-top: 12px;
      }
      &__item {
        padding-top: 12px;
        padding-bottom: 0;
        &:first-child {
          padding-top: 0;
        }
        &__label {
          font-size: 14px;
          font-weight: bold;
          color: #777777;
        }
        &__amount {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
  .forFreeUser {
    position: relative;
    min-height: 268px;
    &__blur {
      height: 100%;
      width: 100%;
    }
    &__blurOverlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      color: #333333;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .flexBlock {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .mainDescription {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        width: 100%;
        @include sp {
          font-size: 18px;
          line-height: 24px;
        }
      }
      .subDescription {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
      }
      .membershipsButton {
        padding-bottom: 8px;
        @include sp {
          padding-bottom: 0;
        }
        /deep/ .memberships-button {
          width: 280px;
          @include md {
            width: 260px;
            font-size: 18px;
          }
          @include sp {
            width: 240px;
          }
        }
      }
    }
    &__dummyImage {
      border: none;
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      margin: auto;
      width: calc(100% + 20px);
      max-width: none;
      height: calc(100% + 20px);
    }
  }
}
</style>
