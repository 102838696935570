<template>
  <span>
    <template v-if="type === this.category.fromTo">
      <template v-if="$$isNullOrEmpty(value.from) && $$isNullOrEmpty(value.to)">
        {{ noSetting }}
      </template>
      <template v-else-if="$$isNullOrEmpty(value.from)">
        {{ noSetting }}
        <span>~</span>
        {{
          kubun.to.find((item) => {
            return item.value === value.to;
          }).text
        }}
      </template>
      <template v-else-if="$$isNullOrEmpty(value.to)">
        {{
          kubun.from.find((item) => {
            return item.value === value.from;
          }).text
        }}
        <span>~</span>
        {{ noSetting }}
      </template>
      <template v-else>
        {{
          kubun.from.find((item) => {
            return item.value === value.from;
          }).text
        }}
        <span>~</span>
        {{
          kubun.to.find((item) => {
            return item.value === value.to;
          }).text
        }}
      </template>
    </template>
    <template v-else-if="type === this.category.array">
      {{
        kubun
          .filter((item) => {
            return Array.isArray(value)
              ? value.includes(item.value)
              : value === item.value;
          })
          .map((item) => item.text)
          .join("、") || noSetting
      }}
    </template>
    <template v-else-if="type === this.category.text">
      {{
        $$isNullOrEmpty(value)
          ? noSetting
          : Array.isArray(value)
          ? value.map((item) => item.text).join("、") || noSetting
          : typeof value === "object"
          ? value.text
          : value
      }}
    </template>
  </span>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "investor-saved-filter-item",
  mixins: [DisplayTextMixin],
  props: {
    kubun: {
      type: [Object, Array],
    },
    value: {
      type: [Object, Number, Array],
      require: true,
    },
    type: {
      type: Number,
      require: true,
    },
  },
  data: function () {
    return {
      category: {
        fromTo: 0,
        array: 1,
        text: 2,
      },
      noSetting: "設定なし",
    };
  },
};
</script>
