<template>
  <div>
    <z-checkbox
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :isRequired="isRequired"
      :hideRequired="hideRequired"
      :isPrivate="isPrivate"
      :showAllCheck="showAllCheck"
    >
    </z-checkbox>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZCityCheckbox",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      cities: [],
    };
  },
  props: {
    value: {
      type: [Array],
      required: true,
    },
    prefecture: {
      type: [Number],
      default: null,
    },
    showAllCheck: {
      type: Boolean,
      default: false,
    },
  },
  created: async function () {
    try {
      this.loading = true;
      this.cities = [];
      const val = this.prefecture;
      if (val) {
        this.cities = await this.$$city$loads(val);
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    options() {
      const opts = [];
      for (const c of this.cities) {
        opts.push({
          value: c.cityCode,
          text: c.cityName,
        });
      }
      return opts;
    },
  },
  watch: {
    prefecture: async function (val) {
      try {
        this.loading = true;
        //this.cities = [];
        //this.internalValue = null;
        if (val) {
          this.cities = await this.$$city$loads(val);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
