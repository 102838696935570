<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="content">
        <h4>専門家(コンサルタント)一覧</h4>
      </div>
      <div class="columns">
        <div class="column is-narrow realtors">
          <div class="realtors__search">
            <z-input label="検索" name="search.text" v-model="search.text">
            </z-input>
            名前とemailのみ(簡易)
            <hr />
            <z-select
              label="申請ステータス"
              name="search.statuses"
              :options="$$ks.realtor.application.user.statuses"
              v-model="search.statuses"
              nullable
            >
            </z-select>
            <hr />
            スコア検索は未実装
          </div>
        </div>
        <div class="column">
          <div class="container">
            <div class="buttons">
              <!--
              <button class="button is-warning" @click="issueAccount">
                アカウント発行
              </button>
              -->
              <button class="button is-danger" @click="accept">承認</button>
              <!--
                <button class="button is-danger" @click="denial">否認</button>
              -->
            </div>
            <hr />
            <RealtorUserList ref="users" :users="users"></RealtorUserList>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
//import RealtorExamination from "@/views/admin/components/RealtorCompanyExamination";
//import RealtorCompany from "@/views/admin/components/RealtorCompany";
import RealtorUserList from "@/views/admin/components/RealtorUserList";

export default {
  name: "admin-setting-realtors-users",
  components: {
    RealtorUserList: RealtorUserList,
  },
  data: function () {
    return {
      isLoading: true,
      //realtors: [],
      realtorUsers: [],
      search: {
        text: "",
        memberships: null,
        statuses: null,
      },
    };
  },
  computed: {
    users: function () {
      return this.realtorUsers
        .filter((i) => {
          if (this.search.text) {
            const words = this.search.text.split(/\s+/);
            for (const w of words) {
              const reg = new RegExp(w, "i");
              return (
                i.data.base.name.match(reg) ||
                i.data.base.email.match(reg) ||
                i.data.base.kana.match(reg)
              );
            }
            return true;
          } else {
            return true;
          }
        })
        .filter((i) => {
          const st = this.search.statuses;
          if (st) {
            return i.examination.data.status === st;
          } else {
            return true;
          }
        })
        .filter((i) => {
          const st = this.search.memberships;
          if (st) {
            return i.data.membership === st;
          } else {
            return true;
          }
        });
    },
    //users: function() {
    //  const users = [];
    //  for(const realtor of this.realtors) {
    //    console.log(realtor.users);
    //    Array.prototype.push.apply(users, realtor.users);
    //  }
    //  return users;
    //}
  },
  methods: {
    statusKS: function (val) {
      return this.$$ks.get(this.$$ks.realtor.application.company.statuses, val);
    },
    async issueAccount() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$refs.users.issueAccount();
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async accept() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$refs.users.accept();
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        this.realtorUsers = await this.$$realtor$loadUsers();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realtors {
  &__search {
    width: 128px;
    height: 100%;
    @include mobile {
      width: 100px;
    }
  }
}
</style>
