<template>
  <div>
    <section>
      <h2 class="edit__title">会社情報</h2>
      <section class="edit__section">
        <z-input
          label="会社名"
          v-model="company.data.name"
          type="text"
          maxlength="100"
          isRequired
          :validate="'required'"
        />
        <z-input
          label="電話番号(代表)"
          v-model="company.data.phoneNumber"
          type="tel"
          isRequired
          maxlength="11"
          :validate="'required|min:10|max:11|numeric'"
        />

        <z-postalcode
          label="郵便番号"
          v-model="company.data.postalCode"
          @chageAddress="chageAddress"
          isRequired
          :validate="'required'"
        />
        <z-prefecture-select
          label="都道府県"
          v-model="company.data.prefecture"
          isRequired
          :validate="'required'"
          @changePrefecture="changePrefecture"
        />
        <z-city-select
          label="市区町村"
          :prefecture="company.data.prefecture"
          isRequired
          v-model="company.data.city"
          :validate="'required'"
        />
        <z-input
          label="町名・番地"
          v-model="company.data.address"
          type="text"
          maxlength="30"
          isRequired
          :validate="'required'"
        />
        <z-input
          label="建物名・部屋番号"
          v-model="company.data.building"
          type="text"
          maxlength="64"
          :validate="''"
        />
      </section>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "admin-setting-realtors-detail-company",
  inject: ["$validator"],
  props: {
    company: {
      type: [Object],
      default: null,
    },
  },
  methods: {
    changePrefecture(val) {
      if (!val.initial) {
        this.company.data.city = null;
      }
    },
    chageAddress(val) {
      this.company.data.prefecture = val.prefectureCode;
      this.company.data.city = val.cityCode;
      this.company.data.address = val.address;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
