<script>
// import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
export default {
  name: "Mixins-Num",
  methods: {
    $$num$commaFormat: function (num) {
      return String(num).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
  },
};
</script>
