// const paginationType = {
//   from: null,
//   size: null,
//   sorts: [],  // [{ filed: 'フィールド名', "order": "asc | desc"
// }

export const queryPagination = (query, pagination) => {
  if (!pagination) return query;

  if (pagination.from) query.from = pagination.from;
  if (pagination.size) query.size = pagination.size;
  if (pagination.sorts) {
    if (!query.sort) query.sort = [];
    for (const sort of pagination.sorts) {
      const s = {};
      s[sort.filed] = {
        order: sort.order,
      };
      query.sort.push(s);
    }
  }
  return query;
};
