import firebase from "@/plugins/firebase";
// import { firebaseAction } from "vuexfire";
// import raven from "@/plugins/raven";
// import { cloneDeep } from "lodash";
import { cloneDeep, defaultsDeep } from "lodash";
import realEstateType from "@/types/realEstateType";
import investorType from "@/types/investorType";
import realtorType from "@/types/realtorType";

const assign = function (type, target) {
  return defaultsDeep(cloneDeep(target), type);
};

const db = firebase.db;
const usersRef = db.collection("users_v1");
const companiesRef = db.collection("companies_v1");

export default {
  namespaced: true,
  state: {
    initUser: false,
    initCompany: false,
    user: {
      user: {
        id: null,
        data: {},
      },
      realEstates: [],
      messages: [],
      informations: [],
      company: {
        id: null,
        data: {},
      },
      companyExamination: {
        id: null,
        data: {},
      },
      examination: {
        id: null,
        data: {},
      },
    },
  },
  getters: {
    inited: (state) => {
      return state.initUser && state.initCompany;
    },
    me: (state) => {
      return state.user;
    },
  },
  mutations: {
    initedUser(state) {
      state.initUser = true;
    },
    initedCompany(state) {
      state.initCompany = true;
    },
    setUser(state, user) {
      state.user.user = user;
    },
    setRealEstates(state, realEstates) {
      state.user.realEstates = realEstates;
    },
    setmessages(state, messages) {
      state.user.messages = messages;
    },
    setinfrmations(state, informations) {
      state.user.informations = informations;
    },
    setExamination(state, examination) {
      state.user.examination = examination;
    },
    setCompany(state, company) {
      state.user.company = company;
    },
    setCompanyExamination(state, examination) {
      state.user.companyExamination = examination;
    },
    unset(state) {
      state.user = {
        id: null,
        data: {},
        realEstates: [],
      };
      state.company = {
        id: null,
        data: {},
      };
      state.companyExamination = {
        id: null,
        data: {},
      };
      state.examination = {
        id: null,
        data: {},
      };
    },
  },
  actions: {
    init: function (context) {
      firebase.auth().onAuthStateChanged((auth) => {
        if (auth) {
          usersRef.doc(auth.uid).onSnapshot((doc) => {
            if (doc.exists) {
              const data = doc.data();
              let userData = null;
              if (data.isInvestor) {
                userData = assign(investorType.type.user, data);
              } else if (data.isRealtor) {
                userData = assign(realtorType.type.user, data);
              } else {
                userData = data;
              }
              // console.log(userData);
              const user = {
                id: doc.id,
                data: userData,
                realEstates: [], //TODOここに打ち込む
              };
              context.commit("setUser", user);
              context.commit("initedUser");

              firebase.db
                .collection("informations_v1")
                .where("to", "==", user.id)
                .onSnapshot((qs) => {
                  const ls = [];
                  qs.forEach((doc) => {
                    const r = {
                      id: doc.id,
                      data: doc.data(),
                    };
                    ls.push(r);
                  });
                  context.commit("setinfrmations", ls);
                });

              firebase.db
                .collection("messages_v1")
                .where("uids", "array-contains", user.id)
                .onSnapshot((qs) => {
                  const ls = [];
                  qs.forEach((doc) => {
                    const r = {
                      id: doc.id,
                      data: doc.data(),
                    };
                    ls.push(r);
                  });
                  context.commit("setmessages", ls);
                });

              firebase.db
                .collection("realEstates_v1")
                .where("owner", "==", user.id)
                .where("isActivate", "==", true)
                .onSnapshot((qs) => {
                  const ls = [];
                  qs.forEach((doc) => {
                    const r = {
                      id: doc.id,
                      //data: doc.data()
                      data: assign(realEstateType.type.realEstate, doc.data()),
                    };
                    ls.push(r);
                  });
                  context.commit("setRealEstates", ls);
                });

              usersRef
                .doc(user.id)
                .collection("examinations_v1")
                .doc(user.id)
                .onSnapshot((doc) => {
                  if (doc.exists) {
                    const examination = {
                      id: doc.id,
                      data: doc.data(),
                    };
                    context.commit("setExamination", examination);
                  } else {
                    context.commit("setExamination", {
                      id: null,
                      data: {
                        //この辺を共通かしたい
                        status: 1, //申請中
                        membership: 10, //無料会員
                        reasonForRejection: null,
                        permission: {
                          searchInvestors: 1, // 1:通常 20:業務提携会社   99:ZIRITZ
                        },
                        comment: "",
                      },
                    });
                  }
                });

              if (user.data.company && user.data.company.companyId) {
                companiesRef
                  .doc(user.data.company.companyId)
                  .onSnapshot(function (doc) {
                    const company = {
                      id: doc.id,
                      data: doc.data(),
                    };
                    context.commit("setCompany", company);
                    context.commit("initedCompany");
                  });
                companiesRef
                  .doc(user.data.company.companyId)
                  .collection("examinations_v1")
                  .doc(user.data.company.companyId)
                  .onSnapshot(function (doc) {
                    const examination = {
                      id: doc.id,
                      data: doc.data(),
                    };
                    context.commit("setCompanyExamination", examination);
                    context.commit("initedCompany");
                  });
              } else {
                context.commit("initedCompany");
              }
            } else {
              const user = {
                uid: auth.uid,
              };
              firebase.db
                .collection("users_v1")
                .doc(auth.uid)
                .set(user, { merge: true });
            }
          });
        } else {
          context.commit("unset");
        }
      });
    },
  },
};
