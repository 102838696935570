<template>
  <div>
    <z-select
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :hideRequired="hideRequired"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
    >
    </z-select>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZCitySelect",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      cities: [],
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    prefecture: {
      type: [Number],
      default: null,
    },
  },
  created: async function () {
    try {
      this.loading = true;
      this.cities = [];
      const val = this.prefecture;
      if (val) {
        this.cities = await this.$$city$loads(val);
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    options() {
      const opts = [];
      for (const c of this.cities) {
        opts.push({
          value: c.cityCode,
          text: c.cityName,
        });
      }
      return opts;
    },
  },
  watch: {
    // internalValue(val, old) {
    // },
    prefecture: async function (val, old) {
      //this.internalValue = null;
      try {
        if (val === old) return;
        this.loading = true;
        //this.cities = [];
        // this.internalValue = null;
        if (val) {
          this.cities = await this.$$city$loads(val);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
