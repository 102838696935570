<template>
  <div class="z-docsInput">
    <label
      class="z-docsInput__uploadArea"
      @dragover.prevent
      @dragleave.prevent
      @drop.prevent
      @drop="uploadFiles"
    >
      <input
        id="inputDocs"
        type="file"
        name="file"
        @change="uploadFiles"
        multiple
      />
      <div class="z-docsInput__uploadArea__label">
        <b-icon icon="plus-circle" size="is-medium" />
        <span class="z-docsInput__uploadArea__title">追加する</span>
      </div>
    </label>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZDocsInput",
  mixins: [InputMixin],
  props: {
    uploadPath: {
      type: String,
      default: null,
    },
  },
  methods: {
    async uploadFiles(event) {
      const files = event.target.files
        ? event.target.files
        : event.dataTransfer.files;
      const loadingComponent = this.$loading.open();
      try {
        for (const file of files) {
          const data = await this.$$docs$upload(this.uploadPath, file);
          if (data.id) {
            this.$emit("addFile", {
              fileData: data,
            });
          }
        }
        /* eslint no-undef:0 */
        $("#inputDocs").val("");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("ファイルのアップロードに失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-docsInput {
  &__uploadArea {
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 2px dashed #d8d8d8;
    background-color: #f5f5f5;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center center;
    cursor: pointer;
    input {
      display: none;
    }
    &:before {
      padding-top: 30%;
      display: block;
      content: "";
    }
    // /deep/ img {
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   position: absolute;
    //   object-fit: contain;
    //   font-family: "object-fit: contain;";
    // }
    &__label {
      display: flex;
      text-align: center;
      color: #777;
      /deep/ span {
        color: #777;
      }
    }
    &__title {
      font-size: 15px;
      font-weight: bold;
      padding: 6px 0 0 4px;
      color: #777;
    }
  }
}
</style>
