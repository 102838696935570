<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告" sub="融資情報の入力">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <!--
      <ZHeader title="不動産売買申告"
    /> -->
    <section class="edit">
      <div class="edit__notice">
        <h3>
          今回の不動産売買における<br
            class="br-show"
          />融資情報のご登録をお願いします。
        </h3>
      </div>
      <TransactionSteps :activeStep="1" />
      <div class="edit__section">
        <h3 class="edit__subtitle">融資情報</h3>

        <z-radio
          label="融資有無"
          v-model="transaction.data.loanOrCash"
          :validate="'required'"
          :options="loanOrCashTypes"
          isRequired
          isPrivate
        />

        <template v-if="transaction.data.loanOrCash !== 2">
          <z-price-input
            label="融資金額"
            v-model="transaction.data.loan.amount"
            :validate="'required|price_min_value:1'"
            maxlength="12"
            placeholder="例)100,000,000"
            isRequired
            isPrivate
          />

          <z-bank-select
            label="金融機関"
            v-model="transaction.data.loan.bankId"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-branch-select
            label="支店名"
            :bank="transaction.data.loan.bankId"
            v-model="transaction.data.loan.branchId"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-year-month-select
            label="融資実行年月"
            v-model="transaction.data.loan.executeDate"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-year-month-period-input
            label="返済期間"
            v-model="transaction.data.loan.paymentPeriods"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-radio
            label="金利タイプ"
            v-model="transaction.data.loan.interestType.type"
            :validate="'required'"
            :options="interestTypes"
            helpMessage="借入金利の種類。固定金利：借入れ時の金利が全借入期間もしくは一定期間変わらないタイプ。変動金利：金融情勢の変化に伴い返済の途中でも定期的に金利が変動するタイプ。"
            isRequired
            isPrivate
          />
          <z-year-month-period-input
            v-if="transaction.data.loan.interestType.type === 1"
            class="no-fieldMargin"
            name="期間"
            v-model="transaction.data.loan.interestType.interestYearMonth"
            :validate="'required'"
            isRequired
            isPrivate
          />

          <z-percent-input
            label="金利"
            v-model="transaction.data.loan.interestRate"
            :validate="'required|min_value:0.01|max_value:100'"
            :decimal="2"
            placeholder="例)1.55"
            isRequired
            isPrivate
            isShort
          />

          <z-radio
            label="返済方法"
            v-model="transaction.data.loan.method"
            :validate="'required'"
            :options="loanMethodTypes"
            helpMessage="融資・ローンの毎月の返済方法。元利均等返済：毎月の支払いの返済額が一定となる返済方法。元金均等返済：毎月の支払いの返済額のうち、元金の額が一定となる返済方法。"
            isRequired
            isPrivate
          />
        </template>
      </div>

      <div class="edit__foot">
        <router-link
          :to="{ name: 'transaction-review-confirmation' }"
          class="button z-button is-rounded"
          >戻る</router-link
        >
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button is-wide button__mobile"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
import TransactionSteps from "../parts/TransactionSteps";

export default {
  name: "investor-transaction-review-loan",
  data: function () {
    return {
      isLoading: true,
      transaction: null,
      savedTransaction: null,
      loanMethodTypes: kubuns.loanMethodTypes,
      loanOrCashTypes: kubuns.loanOrCashTypes,
      interestTypes: kubuns.interestTypes,
    };
  },
  components: {
    TransactionSteps,
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async init() {
      try {
        this.transaction =
          await this.$$transaction$loadRealtorTransactionByLink(
            this.transactionId
          );
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.transaction.data.statusOfRegister !== 990) {
          this.savedTransaction =
            await this.$$transaction$saveRealtorTransaction(
              this.me.id,
              this.transaction
            );
        }
        this.$router.push({
          name: "transaction-review-staffevaluation",
          // params: { id: this.savedTransaction.data.link }
          params: { id: this.transactionId },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
  },
  watch: {
    "transaction.data.loanOrCash": function () {
      if (this.transaction.data.loanOrCash === 2) {
        this.transaction.data.loan.amount = null;
        this.transaction.data.loan.bankId = null;
        this.transaction.data.loan.branchId = null;
        this.transaction.data.loan.executeDate = {
          month: null,
          year: null,
        };
        this.transaction.data.loan.paymentPeriods = {
          month: null,
          year: null,
        };
        this.transaction.data.loan.interestType.type = null;
        this.transaction.data.loan.interestType.interestYearMonth = {
          month: null,
          year: null,
        };
        this.transaction.data.loan.interestRate = null;
        this.transaction.data.loan.method = null;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  &__notice {
    padding: 0 22px;
    @include sp {
      padding: 0 16px;
    }
    .br-show {
      display: none;
      @include sp {
        display: block;
      }
    }
  }
}

.button {
  &__mobile {
    @include sp {
      margin: 10px 0 0 !important;
    }
  }
}
</style>
