<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-mobile is-6">
            <h3 class="list-title">お問い合せ完了</h3>
            <div class="box">
              <div class="content has-text-centered">
                <p>
                  お問い合せいただきまして、<br />誠にありがとうございます。
                </p>
                <p>
                  後程、担当者からご登録いただいた<br
                    class="br-show"
                  />連絡先に<br />ご連絡させていただきます。
                </p>
                <br />
                <p>
                  その際につきましては<br
                    class="br-show"
                  />何卒よろしくお願いいたします。
                </p>
                <br />
                <p>
                  今後とも、StockFormerの<br />ご利用よろしくお願い申し上げます。
                </p>
                <hr />
                <a
                  class="button is-primary is-rounded is-wide z-button is-small"
                  href="/"
                  >トップに戻る</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "auth-realtor-confirm-contact",
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.column {
  width: 500px;
  .list-title {
    width: 100%;
    margin-top: 0px;
    padding: 11px 22px;
    background-color: #0066ffeb;
    border-bottom: solid 2px #707070;
    color: $white;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .box {
    padding: 30px 50px;
    border-radius: 0px;
    p {
      font-size: 14px;
    }
  }
}

.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}

@include sp {
  .column {
    width: 380px;
  }
  .z-button {
    min-width: 100%;
  }
}
</style>
