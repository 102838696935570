<template>
  <section class="newArrival">
    <div class="newArrival__title">
      <p class="newArrival__title__mainTitle">New arrival</p>
      <p class="newArrival__title__subTitle">新着物件</p>
    </div>
    <ul class="newArrival__imageTileViewList">
      <li
        class="newArrival__imageTileViewList__item"
        v-for="(item, item_index) in newArrival"
        :key="item_index"
        @click="openRealEstateDetail(item.id)"
      >
        <ZImageTileView :newArrivalObject="item" />
      </li>
    </ul>
  </section>
</template>

<script>
import ZImageTileView from "./ZImageTileView.vue";

export default {
  name: "NewArrival",
  data: function () {
    return {};
  },
  components: {
    ZImageTileView: ZImageTileView,
  },
  asyncComputed: {
    newArrival() {
      return this.$$realtor$loadNewArraivalRealEstates(this.$$user$isPremium);
    },
  },
  methods: {
    openRealEstateDetail(rid) {
      const url = this.$router.resolve({
        name: "investor-search-realestates-detail",
        params: { id: rid },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.newArrival {
  @include lg {
    margin-right: 16px;
  }
  &__title {
    &__mainTitle {
      margin-bottom: 4px;
      font-size: 32px;
      line-height: 1;
      color: #333333;
      font-family: $family-secondary;
    }
    &__subTitle {
      font-size: 14px;
      font-weight: bold;
      color: #aaaaaa;
    }
  }
  &__imageTileViewList {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    // @include md{
    //   justify-content:
    // }
    &__item {
      cursor: pointer;
      margin: 10px 12px 12px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
