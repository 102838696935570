<template>
  <div>
    <div v-if="!isLoading" class="transactionBox">
      <div class="transactionBox__head head">
        <div class="head__score">
          <z-score-progress
            :score="user.data.latestScore.score"
            :rank="user.data.latestScore.rank"
            :transitionDuration="0"
            scaleType="small"
          />
        </div>
        <div class="head__investor">
          <div>
            <b-icon icon="account-outline" />{{ age }}歳
            {{ user.data.base.sex.text }}
          </div>
          <div>
            {{ user.data.profile.currentOfCareer.categoryOfBusiness.text }}
          </div>
          <div>
            {{ user.data.profile.currentOfCareer.typeOfOccupation.text }}
          </div>
        </div>
        <!--
        <template v-if="user.data.membership.value === 20">
          <div class="head__premium-wrap">
            <img
              class="head__premium-badge"
              src="@/assets/investor/premium-card.png"
            />
          </div>
        </template>
        -->
      </div>
      <div class="transactionBox__body body">
        <dl class="body__lead" v-if="type === 'loan'">
          <dt>融資金額</dt>
          <dd>{{ $$price$priceJPFormat(info.data.purchase.loan.amount) }}</dd>
        </dl>
        <dl class="body__lead" v-if="type === 'realEstate'">
          <dt>購入価格</dt>
          <dd>{{ $$price$priceJPFormat(info.data.purchase.amount) }}</dd>
        </dl>
        <dl class="body__lead" v-if="type === 'realEstate'">
          <dt>表面利回り</dt>
          <dd>{{ yieldRate }}</dd>
        </dl>

        <dl class="body__detail is-full" v-if="type === 'loan'">
          <dt>金融機関・支店名</dt>
          <dd>
            {{
              info.data.purchase.loan.bankId
                ? info.data.purchase.loan.bankId.bank_name
                : "-"
            }}
            {{
              info.data.purchase.loan.branchId
                ? info.data.purchase.loan.branchId.branch_name
                : "-"
            }}
          </dd>
        </dl>
        <dl class="body__detail" v-if="type === 'loan'">
          <dt>返済期間</dt>
          <dd>
            {{ info.data.purchase.loan.paymentPeriods.year }}年{{
              info.data.purchase.loan.paymentPeriods.month
            }}ヶ月
          </dd>
        </dl>
        <dl class="body__detail" v-if="type === 'loan'">
          <dt>金利</dt>
          <dd>
            {{
              $$realEstate$interestTypeFormt(
                info.data.purchase.loan.interestType
              )
            }}
            {{ $$rete$rateFormat(info.data.purchase.loan.interestRate) }}
          </dd>
        </dl>

        <div>
          <dl class="body__detail">
            <dt>物件種別</dt>
            <dd>{{ info.data.realEstateType.text || "-" }}</dd>
          </dl>
          <dl class="body__detail">
            <dt>所在地</dt>
            <dd>
              {{ info.data.prefecture.text }} {{ info.data.city.cityName }}
            </dd>
          </dl>
          <dl class="body__detail">
            <dt>交通</dt>
            <dd>
              {{ info.data.transports[0].line.line.line_name }}
              {{ info.data.transports[0].station.station.station_name }} 徒歩{{
                info.data.transports[0].elapsedTime
              }}分
            </dd>
          </dl>
          <dl class="body__detail" v-if="type === 'realEstate'">
            <dt>建物種別</dt>
            <dd>
              {{ info.data.buildingType.text || "-" }}
            </dd>
          </dl>
          <dl class="body__detail">
            <dt>築年月</dt>
            <dd>
              {{ constructionDate }}
            </dd>
          </dl>
        </div>

        <div class="body__notification" v-if="type === 'loan'">
          融資実行年月
          {{ $$calender$yearMonthFormat(info.data.purchase.loan.executeDate) }}
        </div>
        <div class="body__notification" v-if="type === 'realEstate'">
          購入年月 {{ $$calender$yearMonthFormat(info.data.purchase.buyingDt) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "TransactionBox",
  data() {
    return {
      isLoading: true,
      info: null,
      user: null,
    };
  },
  props: {
    realestate: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "loan",
    },
  },
  mounted() {
    this.isLoading = true;
    this.info = this.realestate._source;
    this.user = this.realestate._source.user.user;
    this.isLoading = false;
  },
  computed: {
    age() {
      if (!this.user) return "";
      return this.$$age$calc(new Date(this.user.data.base.birthday));
    },
    yieldRate() {
      if (!this.info) return "-";

      if (
        this.info.data.realEstateType.value === 7 ||
        this.info.data.realEstateType.value === 10
      ) {
        if (this.info.data.couponYieldRate === 0) {
          return "-";
        }
      }
      return this.$$rete$rateFormat(this.info.data.couponYieldRate);
    },
    constructionDate() {
      if (!this.info) return "-";
      if (
        this.info.data.realEstateType.value === 7 ||
        this.info.data.realEstateType.value === 10
      ) {
        return "-";
      } else {
        return (
          this.info.data.constructionDate.year +
          "/" +
          this.info.data.constructionDate.month +
          "(築" +
          this.$$realEstate$calcYearOfConstruction(
            this.info.data.constructionDate
          ) +
          "年)"
        );
      }
    },
    // sex() {
    //   return kubuns.getTypeOfSex(this.info.investor.sex).text;
    // },
    // categoryOfBusiness() {
    //   return kubuns.getCategoryOfBusiness(this.info.investor.categoryOfBusiness)
    //     .text;
    // },
    // typeOfOccupation() {
    //   return kubuns.getTypeOfOccupation(this.info.investor.typeOfOccupation)
    //     .text;
    // },
    // realEstateType() {
    //   return kubuns.realEstateType(this.info.realEstateType).text;
    // },
    // buildingType() {
    //   if (this.info.buildingType) {
    //     return kubuns.getTypeOfBuildingType(this.info.buildingType).text;
    //   } else {
    //     return "-";
    //   }
    //   // return kubuns.getTypeOfBuildingType(this.info.buildingType).text;
    // },
    // premiumMemberJudge() {
    //   return (
    //     kubuns.investor.application.getMembership(this.info.investor.membership)
    //       .value === 20
    //   );
    // },
    // yieldRate() {
    //   if (this.info.realEstateType === 7 || this.info.realEstateType === 10) {
    //     if (this.info.couponYieldRate === 0) {
    //       return "-";
    //     }
    //   }
    //   return this.$$rete$rateFormat(this.info.couponYieldRate);
    // },
    // constructionDate() {
    //   if (this.info.realEstateType === 7 || this.info.realEstateType === 10) {
    //     return "-";
    //   } else {
    //     return (
    //       this.info.constructionDate.year +
    //       "/" +
    //       this.info.constructionDate.month +
    //       "(築" +
    //       this.$$realEstate$calcYearOfConstruction(this.info.constructionDate) +
    //       "年)"
    //     );
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.transactionBox {
  .head {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(90deg, #2a7bcf, #37d2ff);
    color: #fff;
    // &__score {
    // }
    &__investor {
      padding-left: 12px;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      /deep/ .icon {
        margin-left: -4px;
        height: 12px;
        vertical-align: middle;
        .mdi::before {
          font-size: 16px;
        }
      }
    }
    &__premium-wrap {
      display: inline-block;
      width: 60px;
      height: 45px;
      margin: 0 0 0 auto;
      @include sp {
        width: 40px;
        height: 30px;
      }
    }
    &__premium-badge {
      height: 100%;
    }
  }
  .body {
    padding: 10px 14px;
    border-radius: 0 0 8px 8px;
    border: 1px solid #e5e5e5;
    border-top: none;
    color: #777770;
    &__lead {
      display: table;
      margin-bottom: 4px;
      width: 100%;
      border-bottom: 1px solid #dddddd;
      dt {
        display: table-cell;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
      }
      dd {
        display: table-cell;
        width: 100%;
        font-size: 18px;
        text-align: right;
      }
    }
    &__detail {
      display: inline-block;
      min-width: 50%;
      &.is-full {
        min-width: 100%;
      }
      dt {
        display: inline-block;
        min-width: 60px;
        font-size: 12px;
        font-weight: bold;
      }
      dd {
        display: inline-block;
        padding-left: 8px;
        font-size: 14px;
      }
    }
    &__notification {
      font-size: 12px;
      text-align: right;
    }
  }
}
</style>
