<template>
  <div>
    <section class="section">
      <div class="continer">
        <template v-if="!isLoading">
          <template v-if="!canRegister">
            <template v-if="!isSignInWithEmailLink">
              <div class="columns is-centered">
                <div class="box column is-8">
                  <b-message
                    title="リンクが正しくありません"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    リンクの有効期限が切れているか不正です。<br />申し訳ありませんが、
                    <a @click="cancel">会員登録</a
                    >に進み、ご確認メールを再送信してください。
                  </b-message>
                  <div class="has-text-centered">
                    <a @click="cancel">もう一度会員登録をする</a>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="existsAccount">
              <div class="columns is-centered">
                <div class="box column is-6">
                  <b-message
                    title="会員はすでに登録されています"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    会員はすでに登録されています。<br />
                    別の会員で登録したい場合は<a @click="cancel">会員登録</a
                    >から登録をお願いします。<br />
                    パスワードを忘れた場合は<router-link
                      :to="{ name: 'auth-resetpassword' }"
                      >パスワードリセット</router-link
                    >からパスワードの再設定をお願いします。<br />
                  </b-message>
                  <div class="has-text-centered">
                    <a @click="cancel">別の会員で登録をする</a>
                  </div>
                  <div class="has-text-centered">
                    <router-link :to="{ name: 'auth-resetpassword' }"
                      >パスワードを再設定する</router-link
                    >
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="columns is-centered">
              <div class="column is-6">
                <h2 class="list-title">ログインパスワード情報の設定</h2>
                <div class="box">
                  <div class="content is-small box__confirmation-text">
                    <p>
                      これからご利用するログインパスワードを新たに設定してください。
                    </p>
                  </div>
                  <z-input
                    label="パスワード"
                    name="password"
                    v-model="account.password"
                    data-vv-as="パスワード"
                    type="password"
                    placeholder="新規にパスワードを設定してください"
                    maxlength="128"
                    :validate="'required:true|min:8'"
                    icon-pack="fas"
                    icon="lock"
                    password-reveal
                    isRequired
                  >
                  </z-input>

                  <div class="buttons is-centered">
                    <button
                      id="complete_password_registration_button"
                      class="button is-primary is-rounded is-wide z-button is-small"
                      :disabled="$$validator$invalid"
                      @click="save"
                    >
                      登録する
                    </button>
                  </div>
                  <div class="is-divider signup-divider"></div>
                  <div class="has-text-centered">
                    <p class="sub-message1">
                      リンクの有効期限が切れている場合は、<br />
                      再度「ご確認メール」を再送信してください。<br />
                      届いた最新のメールのリンクから再登録をお願いします。<br />
                    </p>
                  </div>
                  <div class="buttons is-centered restart-button">
                    <div
                      id="resent-button"
                      class="button z-button is-text restart-button"
                      @click="signUp"
                    >
                      「ご確認メール」を再送信する
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
// import RegisterMixin from "@/mixins/investor/auth/RegisterMixin";
import firebase from "@/plugins/firebase";
import SentMail from "@/mixins/investor/auth/SentMail";
import _ from "lodash";

export default {
  name: "investor-auth-Register",
  // mixins: [RegisterMixin],
  mixins: [SentMail],
  data: function () {
    return {
      //user: null,
      account: {
        email: this.$route.query.email.replace(" ", "+"),
        password: "",
      },
      existsAccount: false,
      isSignInWithEmailLink: false,
      isLoading: true,
    };
  },
  computed: {
    canRegister: function () {
      return !this.existsAccount && this.isSignInWithEmailLink;
    },
  },
  methods: {
    cancel: function () {
      this.$router.push({ name: "auth-investor-signup" });
    },
    signOut: function () {
      const self = this;
      return firebase
        .auth()
        .signOut()
        .catch((error) => {
          self.$raven.captureException(error);
        });
    },
    signUp: function () {
      const self = this;
      const loadingComponent = this.$loading.open();
      const analyticsParam = localStorage.getItem("analyticsParam");
      this.sendMail(this.account.email, analyticsParam)
        .then(function () {
          self.$$toast$openSuccess("ご確認メールが再送信されました");
        })
        .finally(() => {
          loadingComponent.close();
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("ご確認メールの送信に失敗しました");
        });
    },
    save: function () {
      const email = this.account.email;
      if (
        email &&
        firebase.auth().isSignInWithEmailLink(window.location.href)
      ) {
        const self = this;
        const loadingComponent = this.$loading.open();
        // const promise = Promise.resolve();
        return (
          Promise.resolve()
            .then(() => {
              const credential =
                firebase.auth.EmailAuthProvider.credentialWithLink(
                  email,
                  window.location.href
                );
              return firebase.auth().currentUser.linkWithCredential(credential);
            })
            .then(() => {
              const auth = firebase.auth().currentUser;
              return auth.updatePassword(self.account.password);
            })
            //.then(() => {
            //  const auth = firebase.auth().currentUser;
            //  return firebase.functions("setCustomTokenInvestor", {
            //    uid: auth.uid
            //  });
            //})
            .then(() => {
              const auth = firebase.auth().currentUser;
              const now = firebase.serverTimestamp();
              const uid = auth.uid;
              const user = this.$$investor$getNewUser();
              console.log(user);

              user.id = uid;
              user.data.base.email = email;
              user.data.uid = uid;
              user.data.registerDt = now;
              user.data.isInvestor = true;
              user.data.isActivate = true;

              user.data.updatedBy = uid;
              user.data.updatedDt = now;
              user.data.createdBy = uid;
              user.data.createdDt = now;

              return this.$$investor$save(uid, user);
            })
            .then(() => {
              console.log("Sucess!!");
              this.$nextTick(() => {
                setTimeout(() => {
                  window.location.href = "/investor/register";
                  loadingComponent.close();
                }, 3000);
              });
            })
            .catch((error) => {
              loadingComponent.close();
              self.$raven.captureException(error);
              self.$$toast$openError(
                "リンクの認証が切れています。「ご確認メール」の再送信をお願いします。"
              );
            })
        );
      } else {
        const self = this;
        self.$raven.captureException("Link is invalid");
        self.$$toast$openError(
          "リンクの認証が切れています。再度サインアップからやり直しをお願いします。"
        );
        // self.$toast.open({
        //   duration: 10000,
        //   message:
        //     "リンクの認証が切れています。再度サインアップからやり直しをお願いします。",
        //   position: "is-top",
        //   type: "is-danger"
        // });
        return Promise.resolve();
      }
    },
  },
  created() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.isSignInWithEmailLink = true;
      firebase.db
        .collection("users_v1")
        .where("base.email", "==", this.account.email)
        .get()
        .then((querySnapshot) => {
          this.existsAccount = !querySnapshot.empty;
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          self.$catchLoadError(error);
        });
    } else {
      this.isSignInWithEmailLink = false;
    }

    //解析用クエリパラメータをローカルストレージに保存
    var urlParam = location.search.substr(1);
    const queryObj = _.reduce(
      urlParam.split("&"),
      function (obj, v) {
        var pair = v.split("=");
        obj[pair[0].toLowerCase()] = decodeURIComponent(pair[1]);
        return obj;
      },
      {}
    );
    if (queryObj.hasOwnProperty("a8")) {
      localStorage.setItem("analyticsParam", queryObj.a8);
    }
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.has-label-centerted {
  height: 2rem;
  margin-top: 4px;
  margin-right: 24px;
}

.is-input-helper {
  margin: 0;
}

.column {
  width: 100%;
}

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0066ffeb;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.box {
  border-radius: 0px;
  .content {
    font-size: 14px;
  }
  .mail-adress {
    font-size: 16px;
  }
  &__confirmation-text {
    text-align: center;
  }
  .br-show {
    display: none;
    @include mobile {
      display: block;
    }
  }
}

.signup-divider {
  margin-top: 40px;
  margin-bottom: 10px;
}

.sub-message1 {
  color: gray;
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.restart-button {
  color: gray;
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 12px;
}
</style>
