<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <ZInput label="UID" v-model="uid" maxlength="50" hideRequired />
      <button @click="onClickAll" class="button">全件</button>
      <hr />
      <table>
        <thead>
          <tr>
            <th>date</th>
            <th>uid</th>
            <th>path</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="a in actions">
            <tr :key="a.id">
              <td>
                {{ $$calender$dateTimeFormat(a.data.createdDt) }}
              </td>
              <td>
                {{ a.data.uid }}
              </td>
              <td>
                {{ a.data.action.to.fullPath }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
import * as analysisActions from "@/modules/analysis/actions";

export default {
  name: "admin-settings-analysis-action",
  data: function () {
    return {
      uid: null,
      actions: [],
    };
  },
  methods: {
    async onClickAll() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.uid) {
          this.actions = await analysisActions.loadByUID(this.uid);
        } else {
          this.actions = await analysisActions.loads();
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = false;
      return;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
