<template>
  <div class="z-notification" ref="notification">
    <div class="z-notification__buttons">
      <div class="z-notification__buttons__item">
        <template v-if="$$user$isInvestor">
          <router-link :to="{ name: 'investor-message' }">
            <ZIcon name="mail" width="24" height="24" :color="color" />
            <span class="badge" v-if="$$user$countMessageOfNotAsRead"
              >{{ $$user$countMessageOfNotAsRead }}
            </span>
          </router-link>
        </template>
        <template v-if="$$user$isRealtor">
          <router-link :to="{ name: 'realtor-message' }">
            <ZIcon name="mail" width="24" height="24" :color="color" />
            <span class="badge" v-if="$$user$countMessageOfNotAsRead"
              >{{ $$user$countMessageOfNotAsRead }}
            </span>
          </router-link>
        </template>
      </div>
      <div class="z-notification__buttons__item" @click="toggleInformation">
        <ZIcon name="bell" width="24" height="24" :color="color" />
        <span class="badge" v-if="$$user$countInformationOfNotAsRead"
          >{{ $$user$countInformationOfNotAsRead }}
        </span>
      </div>
      <div class="z-notification__buttons__item" @click="toggleAccount">
        <ZIcon name="account" width="24" height="24" :color="color" />
      </div>
      <div class="z-notification__buttons__item" v-if="$$user$isInvestor">
        <template>
          <router-link :to="{ name: 'investor-zero-index' }">
            <ZZeroIcon
              class="icon-zero"
              width="26"
              height="26"
              color="#37d2ff"
            />
          </router-link>
        </template>
      </div>
    </div>

    <template v-if="$$user$isInvestor">
      <div
        class="z-notification__account"
        :class="{ 'is-active': isShowAccount }"
      >
        <ul>
          <li @click="closeMenu">
            <router-link :to="{ name: 'investor-account' }">
              <ZIcon
                width="24"
                height="24"
                name="profile"
                color="#fff"
              />アカウント設定
            </router-link>
          </li>
          <li @click="closeMenu">
            <router-link :to="{ name: 'investor-account' }">
              <ZIcon
                width="24"
                height="24"
                name="password"
                color="#fff"
              />パスワード設定
            </router-link>
          </li>
          <!-- <li @click="closeMenu">
            <router-link :to="{ name: 'investor-premium' }">
              <ZIcon
                width="24"
                height="24"
                name="plan"
                color="#fff"
              />プラン変更
            </router-link>
          </li> -->
          <li>
            <a @click="signOut">
              <ZIcon
                width="24"
                height="24"
                name="logout"
                color="#fff"
              />ログアウト
            </a>
          </li>
        </ul>
      </div>
    </template>

    <template v-if="$$user$isRealtor">
      <div
        class="z-notification__account"
        :class="{ 'is-active': isShowAccount }"
      >
        <ul>
          <!--
          <li @click="closeMenu">
            <router-link :to="{ name: 'realtor-setting-account' }">
              <ZIcon
                width="24"
                height="24"
                name="profile"
                color="#fff"
              />アカウント設定
            </router-link>
          </li>
          -->
          <li @click="closeMenu">
            <router-link :to="{ name: 'realtor-setting-account' }">
              <ZIcon
                width="24"
                height="24"
                name="password"
                color="#fff"
              />パスワード設定
            </router-link>
          </li>
          <li>
            <a @click="signOut">
              <ZIcon
                width="24"
                height="24"
                name="logout"
                color="#fff"
              />ログアウト
            </a>
          </li>
        </ul>
      </div>
    </template>
    <div
      class="z-notification__information"
      :class="{ 'is-active': isShowInformation }"
    >
      <ul class="z-notification__information__list">
        <li
          class="z-notification__information__item"
          v-for="(item, index) in $$user$informationsOfNotAsRead"
          :key="index"
        >
          <ZProfileThumbnail :emptySrc="defaultThumbnail" />
          <div class="information">
            <p class="information__title">{{ item.data.title }}</p>
            <p class="information__message">{{ item.data.message }}</p>
            <p class="information__data">
              {{ notificationDate(item.data.createdDt) }}
            </p>
          </div>
        </li>
        <li
          class="z-notification__information__item"
          v-if="!$$user$informationsOfNotAsRead.length"
        >
          <p class="information information--empty">
            最新のお知らせはありません
          </p>
        </li>
      </ul>
      <div class="z-notification__information__footer">
        <router-link
          v-if="$$user$isInvestor"
          class="button z-button is-primary is-small is-outlined is-rounded"
          :to="{ name: 'investor-informations' }"
          @click.native="closeMenu"
          >全てのお知らせを見る</router-link
        >
        <router-link
          v-if="$$user$isRealtor"
          class="button z-button is-primary is-small is-outlined is-rounded"
          :to="{ name: 'realtor-setting-informations' }"
          @click.native="closeMenu"
          >全てのお知らせを見る</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
const defaultThumbnail = require("@/assets/logo-white.png");

export default {
  name: "ZNotification",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
    isShowMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultThumbnail: defaultThumbnail,
      isShowAccount: false,
      isShowInformation: false,
    };
  },
  methods: {
    toggleAccount() {
      this.isShowAccount = !this.isShowAccount;
      this.isShowInformation = false;
      this.$emit(
        "update.isShowMenu",
        this.isShowAccount || this.isShowInformation
      );
    },
    toggleInformation() {
      this.isShowAccount = false;
      this.isShowInformation = !this.isShowInformation;
      this.$emit(
        "update.isShowMenu",
        this.isShowAccount || this.isShowInformation
      );
    },
    closeMenu() {
      this.isShowInformation = false;
      this.isShowAccount = false;
      this.$emit("update.isShowMenu", false);
    },
    signOut: async function () {
      return await this.$$user$signOut();
    },
    handleClick(event) {
      if (
        (this.isShowAccount || this.isShowInformation) &&
        !this.$refs.notification.contains(event.target)
      ) {
        this.closeMenu();
      }
    },
    notificationDate(timeStamp) {
      return this.$$calender$monthTimeFormat(timeStamp);
    },
  },
  watch: {
    "bulma.isMobile": function () {
      this.isShowAccount = false;
      this.isShowInformation = false;
      this.$emit("update.isShowMenu", false);
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-notification {
  height: 100%;
  position: relative;
  &__buttons {
    height: 100%;
    display: flex;
    align-items: center;
    &__item {
      position: relative;
      cursor: pointer;
      /deep/ .z-icon {
        padding: 12px;
      }
      /deep/ .z-zeroIcon {
        padding: 12px;
        transform: scale(1.2);
      }
      a {
        display: inline-block;
      }
    }
  }
}
.z-notification__account {
  width: 216px;
  position: absolute;
  top: 72px;
  right: 0;
  background-color: #000;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.16);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: $zindex-menu;
  ul {
    padding: 16px 8px;
    li {
      padding: 10px 0;
      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        opacity: 1;
        transition: opacity 0.2s ease;
        /deep/ .z-icon {
          padding: 0 16px 0 8px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
  @include sp {
    width: 100%;
    position: fixed;
    top: 64px;
  }
}
.z-notification__information {
  width: 536px;
  position: absolute;
  top: 72px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.16);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: $zindex-menu;
  @include sp {
    width: 100%;
    top: 64px;
    position: fixed;
    color: #000;
  }
  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
  &__list {
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
  }
  &__item {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    /deep/ .ZProfileThumbnail {
      width: 64px;
      height: 64px;
      img {
        border: 1px solid #dedede;
      }
    }
    .information {
      min-width: 0;
      flex: 1 1;
      padding-left: 12px;
      &--empty {
        width: 100%;
        height: 66px;
        display: flex;
        padding-left: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &__title,
      &__message {
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__data {
        font-size: 12px;
      }
    }
  }
  &__footer {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.badge {
  min-width: 20px;
  height: 20px;
  top: 4px;
  right: 4px;
  padding: 0 6px;
  display: inline-block;
  position: absolute;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  background-color: #ff0090;
  color: #fff;
  border-radius: 10px;
}
</style>
