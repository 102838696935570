<template>
  <ZScoreStandardDeviation
    :score="$$user$score"
    :chartData="scoreStandardDeviationChartData"
  />
</template>

<script>
import ZScoreStandardDeviation from "@/components/charts/ZScoreStandardDeviation.vue";

export default {
  name: "ScoreChart",
  components: {
    ZScoreStandardDeviation,
  },
  asyncComputed: {
    async scoreStandardDeviationChartData() {
      const rs = await this.$$ScoreChart$getScoreStandardDeviation();
      return rs;
    },
  },
  //computed: {
  //  scoreStandardDeviationChartData() {
  //    return this.$$invester$getScoreStandardDeviation();
  //  }
  //}
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
