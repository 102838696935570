<template>
  <section class="section">
    <div class="animated fadeIn sent-mail-wrap">
      <div class="columns is-centered is-gapless">
        <div class="column is-6">
          <h3 class="list-title">ご確認メールを送信しました</h3>
          <div class="confirm__box">
            <div class="has-text-centered">
              <h1 class="main-message1">
                ご登録いただいたメールアドレスに<br
                  class="br-show"
                />「ご確認メール」を送信しました。
              </h1>
              <h1 class="main-message2">
                ご確認メールから、<br
                  class="br-show"
                />会員情報をご登録ください。
              </h1>
              <br />
              <div class="signup__svg__wrap">
                <lottie
                  class="signup__svg"
                  :options="defaultOptions"
                  :height="200"
                  :width="200"
                  :loop="true"
                  :autoplay="true"
                />
              </div>
              <h2 class="has-text-weight-semibold">{{ email }}</h2>
            </div>
            <div class="is-divider signup-divider"></div>
            <div class="has-text-centered">
              <p class="sub-message1">
                万一メールが届かない場合は、大変恐縮ですが、<br />
                「迷惑フォルダ」にメールが届いていないかを一度ご確認ください。<br />
                それでも確認できない場合は、再度「ご確認メール」を再送信してください。
              </p>
            </div>
            <div class="buttons is-centered restart-button">
              <div
                id="resent-button"
                class="button z-button is-text restart-button"
                @click="signUp"
              >
                「ご確認メール」を再送信する
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SentMail from "@/mixins/investor/auth/SentMail";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/mailsent.json";

export default {
  name: "investor-auth-SentMail",
  mixins: [SentMail],
  components: {
    Lottie: Lottie,
  },
  data() {
    return {
      caption: "確認メールの再送信",
      email: this.$route.query.email,
      defaultOptions: {
        animationData: animationData.default,
        loop: false,
      },
    };
  },
  beforeMount() {
    if (!this.$route.query.email) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    signUp: function () {
      const self = this;
      const loadingComponent = this.$loading.open();
      this.sendMail(this.email)
        .then(function () {
          self.$$toast$openSuccess("確認メールが再送信されました");
        })
        .finally(() => {
          loadingComponent.close();
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("確認メールの送信に失敗しました");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.column {
  width: 100%;
  a {
    color: white;
  }
}
.signup__svg {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  &__wrap {
    margin: -28px auto -4px;
  }
}

.sent-mail-wrap {
  background-color: white;
}

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0257bf;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  @include sp {
    padding: 10px 0px;
  }
}

.confirm {
  &__box {
    padding: 30px 50px;
    @include sp {
      padding: 20px 15px 20px;
    }
    h1 {
      font-size: 14px;
    }
    h2 {
      margin-bottom: 10px;
    }
    .main-message1 {
      font-size: 16px;
      font-weight: bold;
      //margin-bottom: 10px;
      @include sp {
        font-size: 14px;
      }
    }
    .main-message2 {
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      @include sp {
        font-size: 14px;
      }
    }
    p {
      font-size: 11px;
    }
    .br-show {
      display: none;
      @include mobile {
        display: block;
      }
    }
  }
}

.button {
  &__mail {
    background-color: #0257bf;
  }
  @include mobile {
    width: 80%;
  }
}

.signup-divider {
  margin-top: 40px;
  margin-bottom: 10px;
}

.sub-message1 {
  color: gray;
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.restart-button {
  color: gray;
  font-size: 10px;
  margin-top: 4px;
  margin-bottom: 10px;
}
</style>
