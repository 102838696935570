<template>
  <div>
    <div class="box">
      <z-select
        name="user.data.role"
        label="会員権限"
        v-model="user.data.role"
        :options="options"
      >
      </z-select>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "realtors-user-role",
  inject: ["$validator"],
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.$$ks.realtor.roles;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
