<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="buttons">
        <button class="button" @click="load">ロード</button>
        <button class="button" @click="save">保存</button>
      </div>
      <hr />
      <z-input label="UID" :validate="'required'" isRequired v-model="uid">
      </z-input>
      <h1>ユーザ情報</h1>
      <div class="columns">
        <div class="column is-6">
          <z-input
            label="給与"
            :validate="'required'"
            name="user.data.income.salary"
            isRequired
            v-model.number="user.data.income.salary"
          >
          </z-input>
          <z-input
            label="配当・利子"
            name="user.data.income.dividend"
            v-model.number="user.data.income.dividend"
          >
          </z-input>
          <z-input
            label="年金"
            name="user.data.income.pension"
            v-model.number="user.data.income.pension"
          >
          </z-input>
          <z-input
            label="その他"
            name="user.data.income.others"
            v-model.number="user.data.income.others"
          >
          </z-input>
          <hr />
          <z-input
            label="現金"
            name="user.data.assets.cashes[0].amount"
            v-model.number="user.data.assets.cashes[0].amount"
          >
          </z-input>
          <z-input
            label="有価証券"
            name="user.data.assets.securities[0].amount"
            v-model.number="user.data.assets.securities[0].amount"
          >
          </z-input>
          <z-input
            label="保険"
            name="user.data.assets.insurances[0].amount"
            v-model.number="user.data.assets.insurances[0].amount"
          >
          </z-input>
          <hr />
          <z-input
            label="購入金額"
            name="user.realEstates[0].data.purchase.amount"
            v-model.number="user.realEstates[0].data.purchase.amount"
          >
          </z-input>
          <z-input
            label="年間想定収入"
            name="user.realEstates[0].data.annualEstimatedIncome"
            v-model.number="user.realEstates[0].data.annualEstimatedIncome"
          >
          </z-input>
          <z-input
            label="借入金額"
            name="user.realEstates[0].data.purchase.loan.amount"
            v-model.number="user.realEstates[0].data.purchase.loan.amount"
          >
          </z-input>
          <z-input
            label="融資実行年月(年) "
            name="user.realEstates[0].data.purchase.loan.executeDate.year"
            v-model.number="
              user.realEstates[0].data.purchase.loan.executeDate.year
            "
          >
          </z-input>
          <z-input
            label="融資実行年月(月) "
            name="user.realEstates[0].data.purchase.loan.executeDate.month"
            v-model.number="
              user.realEstates[0].data.purchase.loan.executeDate.month
            "
          >
          </z-input>

          <z-input
            label="返済期間(年）"
            name="user.realEstates[0].data.purchase.loan.paymentPeriods.year"
            v-model.number="
              user.realEstates[0].data.purchase.loan.paymentPeriods.year
            "
          >
          </z-input>
          <z-input
            label="返済期間(月）"
            name="user.realEstates[0].data.purchase.loan.paymentPeriods.month"
            v-model.number="
              user.realEstates[0].data.purchase.loan.paymentPeriods.month
            "
          >
          </z-input>
          <z-input
            label="金利"
            name="user.realEstates[0].data.purchase.loan.interestRate"
            v-model.number="user.realEstates[0].data.purchase.loan.interestRate"
          >
          </z-input>
        </div>
        <div class="column is-6">
          全体スコア<br />
          score: {{ score.score }}<br />
          rank: {{ score.rank }}

          <hr />
          <h1>PL</h1>
          スコア：{{ scoreOfPL }} <br />
          合計：{{ PL.sum }}<br />
          給与：{{ PL.salary }}<br />
          配当・利子：{{ PL.dividend }}<br />
          年金：{{ PL.pension }}<br />
          不動産：{{ PL.realEstate }}<br />
          その他：{{ PL.others }}<br />
          <hr />
          {{ PL }}
          <hr />
          <h1>BS</h1>
          スコア：{{ scoreOfBS }} <br />
          資産<br />
          合計：{{ BS.assets.sum }}<br />
          現金：{{ BS.assets.cash.sum }}<br />
          有価証券：{{ BS.assets.security.sum }}<br />
          保険：{{ BS.assets.insurance.sum }}<br />
          不動産：{{ BS.assets.realEstate.sum }}<br />
          負債<br />
          合計：{{ BS.liabilities.sum }}<br />
          純資産<br />
          合計：{{ BS.netAssets.sum }}<br />

          <hr />
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column is-6">
          {{ user }}
          <hr />
          {{ user.realEstates }}
          <hr />
          {{ user.realEstates[0].data.purchase }}
        </div>
        <div class="column is-6"></div>
      </div>
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-userScore",
  data: function () {
    return {
      isLoading: true,
      uid: null,
      user: null,
    };
  },
  computed: {
    score: function () {
      return this.$$investor$calcScore(this.user);
    },
    BS: function () {
      return this.$$investor$calcBS(this.user);
    },
    PL: function () {
      return this.$$investor$calcPL(this.user);
    },
    scoreOfPL: function () {
      return this.$$investor$calcScoreOfPL(this.PL);
    },
    scoreOfBS: function () {
      return this.$$investor$calcScoreOfBS(this.BS);
    },
  },
  methods: {
    async load() {
      this.user = await this.$$investor$loadUser(this.uid);
    },
    async save() {
      await this.$$investor$save(this.me.user.id, this.user);
    },
  },
  mounted() {
    const user = this.$$investor$getNewUser();
    user.id = this.me.user.id;
    user.data.income.salary = 100 * 10000;
    user.data.income.dividend = 200 * 10000;
    user.data.income.pension = 300 * 10000;
    user.data.income.others = 400 * 10000;

    //cashes: [], //現金

    const cashe1 = this.$$investor$getNewCash();
    cashe1.amount = 500 * 10000;
    user.data.assets.cashes.push(cashe1);

    //securities: [], //有価証券
    const security1 = this.$$investor$getNewSecurity();
    security1.amount = 600 * 10000;
    user.data.assets.securities.push(security1);

    //insurances: [] //保険
    const insurance1 = this.$$investor$getNewInsurance();
    insurance1.amount = 700 * 10000;
    user.data.assets.insurances.push(insurance1);

    //realEstate
    const realEstate1 = this.$$realEstate$getNewRealEstate(user.id);
    //const purchase1 = this.$$realEstate$getNewPurchase();
    // const loan1 = this.$$realEstate$getNewLoan();

    realEstate1.data.purchase.amount = 800 * 10000;
    realEstate1.data.purchase.loan.amount = 300 * 10000;
    realEstate1.data.purchase.loan.executeDate.year = 2017;
    realEstate1.data.purchase.loan.executeDate.month = 10;
    realEstate1.data.purchase.loan.paymentPeriods.year = 20;
    realEstate1.data.purchase.loan.paymentPeriods.month = 10;
    realEstate1.data.purchase.loan.interestRate = 0.07; // 金利
    realEstate1.data.annualEstimatedIncome = 140 * 10000;

    //realEstate1.data.purchase = purchase1;
    user.realEstates.push(realEstate1);

    this.user = user;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
