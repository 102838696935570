<template>
  <div>
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
      :hideRequired="hideRequired"
    >
      <b-select
        :name="internalName"
        v-model="internalValue.elapsedTime"
        v-validate="validate"
        placeholder="駅からの距離を選択してください"
        expanded
      >
        <template v-for="o in elapsedTime">
          <option :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </template>
      </b-select>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZTransportRangeSelect",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
    hideRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elapsedTime: kubuns.filters.elapsedTimeRange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
