<template>
  <div class="register" v-if="!isLoading">
    <ZHeader title="Sign Up" sub="サインアップ" />
    <section class="edit">
      <h2 class="edit__title">プロフィール</h2>
      <FormProfile v-model="eUser" :isFirstRegist="true" />

      <div class="edit__foot">
        <b-button
          id="investor-register-index-submit"
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button is-wide"
          >登録する</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import FormProfile from "../common/FormProfile";

export default {
  name: "Register-Index",
  data: function () {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    FormProfile: FormProfile,
  },
  methods: {
    onSubmit: function () {
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();

        this.$router.push({ name: "investor-register-planintroduction" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.register {
  &__foot {
    text-align: center;
  }
}
</style>
