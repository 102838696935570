<template>
  <div>
    <section>
      <ZHeader title="Consultant" sub="コンサルタント" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>

    <div class="z-section">
      <div class="z-section__inner">
        <router-link :to="{ name: 'realtor-setting-basic' }">
          前に戻る
        </router-link>
        <template v-if="!isLoading">
          <FormUserBasic :user="user"> </FormUserBasic>
          <hr />
          <FormUserProfile :user="user"> </FormUserProfile>
          <div class="buttons is-centered">
            <button
              :disabled="$$validator$invalid"
              class="button z-button is-primary is-rounded"
              @click="save"
            >
              登録する
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
//import UserBasic from "@/components/realtor/UserBasic";
//import UserProfile from "@/components/realtor//UserProfile";
import FormUserBasic from "@/components/realtor/FormUserBasic";
import FormUserProfile from "@/components/realtor/FormUserProfile";
//import UserProfilePage from "@/components/realtor/UserProfilePage";

export default {
  name: "admin-setting-realtors-detail",
  components: {
    FormUserBasic: FormUserBasic,
    FormUserProfile: FormUserProfile,
    //UserBasic: UserBasic,
    //UserProfile: UserProfile,
    //UserProfilePage: UserProfilePage
  },
  data: function () {
    return {
      isLoading: true,
      activeTab: 0,
      realtor: null,
      user: null,
    };
  },
  computed: {
    uid: function () {
      return this.me.user.id;
    },
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveUser(this.auth.uid, this.user);
        this.$router.push({ name: "realtor-setting-basic" });
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async beforeRouteUpdate(to, from, next) {
      next();
      await this.init();
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.user = await this.$$realtor$loadUser(this.uid);
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
