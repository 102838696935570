import firebase from "@/plugins/firebase";
import {
  cloneDeep,
  //  defaultsDeep,
} from "lodash";

const TemplateType = {
  uid: null,

  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

export const createNewTempate = (uid) => {
  const data = cloneDeep(TemplateType);
  data.uid = uid;
  return {
    id: null,
    data,
  };
};

const docRef = (uid) =>
  firebase.db
    .collection("users_v1")
    .doc(uid)
    .collection("message_templates_v1");

// compnayID
export const loads = async (uid) => {
  const qs = await docRef(uid).get();
  const ls = [];
  qs.docs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: doc.data(),
    });
  });
  return ls;
};

export const load = async (uid, id) => {
  const doc = await docRef(uid).doc(id).get();
  if (doc.exists) {
    return {
      id: doc.id,
      data: doc.data(),
    };
  } else {
    return null;
  }
};

export const save = async (uid, targetUID, template) => {
  const now = new Date();
  template.data.updatedDt = now;
  template.data.updatedBy = uid;
  if (template.id) {
    await docRef(targetUID).doc(template.id).set(template.data);
  } else {
    template.data.createdDt = now;
    template.data.createdBy = uid;
    template.data.registerDt = now;
    const ref = await docRef(targetUID).add(template.data);
    template.id = ref.id;
  }
  return template;
};

export const remove = async (uid, targetUID, template) => {
  if (template.id) {
    await docRef(targetUID).doc(template.id).delete();
  }
};
