<template>
  <div class="formProfile">
    <section class="edit__section">
      <h3 class="edit__subtitle">基本情報</h3>

      <z-input
        label="姓名"
        name="user"
        v-model="value.data.base.name"
        :validate="'required'"
        maxlength="32"
        placeholder="例)須藤 九太郎"
        isRequired
        isPrivate
      />

      <z-input
        label="セイメイ"
        name="kana"
        v-model="value.data.base.kana"
        :validate="'required|isKata'"
        maxlength="32"
        placeholder="例)ストウ クタロウ"
        isRequired
        isPrivate
      />

      <z-radio
        label="性別"
        name="sex"
        v-model="value.data.base.sex"
        :validate="'required'"
        :options="typeOfSex"
        isRequired
        isPrivate
      />

      <z-date-select
        label="生年月日"
        name="birthday"
        v-model="value.data.base.birthday"
        :validate="'required'"
        placeholder="例)1990/04/01"
        isRequired
        isPrivate
      />

      <z-input
        label="電話番号"
        name="phoneNumber"
        v-model="value.data.base.phoneNumber"
        :validate="'required|min:10|max:11'"
        type="tel"
        placeholder="例)0123456789"
        additionalMessage="ハイフン不要。携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
        isRequired
        isPrivate
        isShort
      />
    </section>

    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">住所</h3>

      <z-postalcode
        label="郵便番号"
        name="postalCode"
        type="tel"
        v-model="value.data.base.postalCode"
        :validate="'required|length:7'"
        placeholder="例)1110000"
        additionalMessage="ハイフン不要。"
        @chageAddress="chageAddress"
        isRequired
        isPrivate
      >
      </z-postalcode>

      <z-prefecture-select
        label="都道府県"
        name="prefecture-select"
        v-model="value.data.base.prefecture"
        :validate="'required'"
        @changePrefecture="changePrefecture"
        isRequired
        isPrivate
      >
      </z-prefecture-select>

      <z-city-select
        label="市区町村"
        name="city-select"
        v-model="value.data.base.city"
        :prefecture="value.data.base.prefecture"
        :validate="'required'"
        isRequired
        isPrivate
      >
      </z-city-select>

      <z-input
        label="番地"
        name="address"
        v-model="value.data.base.address"
        placeholder="番地：1-1-1"
        maxlength="30"
        isPrivate
      />

      <z-input
        label="マンション名・部屋番号"
        name="building"
        v-model="value.data.base.building"
        placeholder="マンション名：ZIRITZ TOWER 1111号室"
        maxlength="40"
        isPrivate
      />

      <ZField label="プロフィール画像">
        <ZProfileImageSelect v-model="value.data.profile.image" />
      </ZField>

      <z-switch
        label="メール配信設定"
        name="mailingList"
        v-model="value.data.confirm.mailingList"
        additionalMessage="StockFormerから各種ご案内・お知らせをご登録のメールアドレスに配信いたします"
        isRequired
        showBox
      >
        メール配信を希望する
      </z-switch>
      <p class="edit__explanation">
        ※ 重要なお知らせ等は、設定有無に関わらず配信されます
      </p>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "FormProfile",
  inject: ["$validator"],
  props: {
    value: Object,
    isFirstRegist: Boolean,
  },
  data() {
    return {
      typeOfSex: kubuns.typeOfSex,
      incomeTypes: kubuns.incomeTypes,
      selectedIncomeType: null,
      selectedMailingList: false,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  methods: {
    changePrefecture(val) {
      if (!val.initial) {
        this.value.data.base.city = null;
      }
    },
    chageAddress(val) {
      this.value.data.base.prefecture = val.prefectureCode;
      this.value.data.base.city = val.cityCode;
      this.value.data.base.address = val.address;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
