<template>
  <div class="loanTransactionRankingSP">
    <div class="loanTransactionRankingSP__inner">
      <ul class="loanTransactionRankingSP__list">
        <li v-for="(info, i) in loanBankInfos" :key="info.bank">
          <!--rank-accordion :opened="i < 1"-->
          <rank-accordion :key="i" :opened="false">
            <BankRankingInfo slot="head" :info="info" />
            <LoanTransactionList
              slot="contents"
              :filter="filter"
              :bank="info.bank"
            />
          </rank-accordion>
        </li>
      </ul>

      <div
        class="loanTransactionRankingSP__more"
        v-if="maxCount > loanBankInfos.length && !isLoading"
      >
        <MoreButton
          :label="loanBankInfos.length + 1 + '位以降をみる'"
          @click="loadMoreInfo()"
        />
      </div>
    </div>
    <div class="loanTransactionRankingSP__loading" v-if="isLoading">
      <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
    </div>
  </div>
</template>

<script>
import BankRankingInfo from "./BankRankingInfo";
import RankAccordion from "./RankAccordion";
import LoanTransactionList from "./LoanTransactionList";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "LoanTransactionRankingSP",
  components: {
    BankRankingInfo,
    RankAccordion,
    LoanTransactionList,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      pageIndex: 0,
      loadNumber: 10,
      loanBankInfos: [],
      maxCount: 0,
    };
  },
  methods: {
    onClickMore() {
      this.loadMoreInfo(false);
    },
    async loadMoreInfo(isRefresh) {
      this.isLoading = true;

      // console.log("isRefresh: " + isRefresh);

      try {
        if (isRefresh) {
          this.pageIndex = 0;
          this.loanBankInfos.splice(-this.loanBankInfos.length);
        } else {
          this.pageIndex++;
        }

        // const result = await this.$$MarketTrendChart$loadLoanBankRanking({
        //   marketTrendFilter: this.filter,
        //   pagination: this.pageIndex
        // });
        const result = await marketTrend.searchBankRank({
          marketTrendFilter: this.filter,
          pagination: this.pageIndex,
          getNumber: 50,
        });

        console.log(result);
        const { /* countOfData, pagination, */ maxCount, data } = result;
        // console.log(data);
        this.loanBankInfos = this.loanBankInfos.concat(data);
        this.maxCount = maxCount;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("データの取得に失敗しました。");
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        if (newVal !== null) {
          this.loanBankInfos = [];
          this.loadMoreInfo(true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.loanTransactionRankingSP {
  width: 100%;
  position: relative;
  // &__inner {
  // }
  &__list {
    & > li {
      border-bottom: solid 1px #e5e5e5;
    }
  }
  &__more {
    padding: 12px;
    text-align: center;
  }
  &__loading {
    width: 100%;
    height: 100px;
    position: relative;
  }
}
/deep/ .bankRankingInfo {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.1);
    border: solid 2px $primary;
    border-radius: 8px;
    opacity: 0;
    transition: 0.1s;
  }
  &.is-selected {
    color: $primary;
    &::after {
      opacity: 1;
    }
  }
}
</style>
