<template>
  <div>
    <z-select2
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
    >
    </z-select2>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZBranchSelect",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      options: [],
    };
  },
  props: {
    value: {
      type: [String],
      default: null,
    },
    bank: {
      type: [String],
      default: null,
    },
  },
  created: async function () {
    try {
      this.loading = true;
      if (this.bank) {
        this.options = await this.$$bank$getBranches(this.bank);
      } else {
        this.options = [];
      }
    } finally {
      this.loading = false;
    }
  },
  //computed: {
  //  options() {
  //    const opts = [];
  //    for (const c of this.cities) {
  //      opts.push({
  //        value: c.cityCode,
  //        text: c.cityName
  //      });
  //    }
  //    return opts;
  //  }
  //},
  watch: {
    bank: async function (val) {
      try {
        this.loading = true;
        this.internalValue = null;
        if (val) {
          this.options = await this.$$bank$getBranches(val);
        } else {
          this.options = [];
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
