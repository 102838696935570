<template>
  <div>
    <b-pagination
      :total="total"
      :current.sync="currentPage"
      :per-page="perPage"
      :range-before="3"
      :range-after="3"
      icon-prev="chevron-left"
      icon-next="chevron-right"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: "ZPagination",
  props: {
    total: {
      type: [Number],
      required: true,
    },
    perPage: {
      type: [Number],
      default: 50,
    },
    page: {
      type: [Number],
      required: true,
    },
    isLoading: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        if (!val) {
          this.$emit("update:page", 1);
        } else {
          this.$emit("update:page", val);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
