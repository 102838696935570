<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
// import realEstateType from "@/types/realEstateType";
import { isNil, cloneDeep, defaultsDeep, round, intersection } from "lodash";
//import kubuns from "@/kubuns/kubuns";
import realEstateType from "@/types/realEstateType";
import investorType from "@/types/investorType";

const slice = function (ls, num, page) {
  return {
    countOfData: num, // 取得件数
    pagination: page, // ページ数
    maxCount: ls.length, //全取得数
    data: ls.slice(page * num, num * (page + 1)), //返すデータ
  };
};

const assign = function (type, target) {
  return defaultsDeep(cloneDeep(target), type);
};

// 重複しているので統一する
const calcYearOfConstruction = function (constructionDate) {
  const yearMillisecond = 1000 * 60 * 60 * 24 * 365;
  const now = new Date();
  const cd = new Date();
  cd.setFullYear(constructionDate.year);
  cd.setMonth(constructionDate.month - 1);
  const diff = now.getTime() - cd.getTime();

  return diff > 0 ? Math.floor(diff / yearMillisecond) : 0;
};

const isRangeSp = function (target, start, end, min = 0, max = 10000000000) {
  const st = isNil(start) ? min : start;
  const ed = isNil(end) ? max : end;
  if (st <= target && target < ed) {
    return true;
  } else {
    return false;
  }
};

const isRange = function (target, start, end, min = 0, max = 10000000000) {
  return isRangeSp(target, start, end, min, max);
};
const loadUser = async function (r) {
  const doc = await firebase.db.collection("users_v1").doc(r.data.owner).get();

  if (doc.exists) {
    return {
      id: r.id,
      data: r.data,
      investor: {
        id: doc.id,
        data: assign(investorType.type.user, doc.data()),
      },
    };
  } else {
    return null;
  }
};

const loadRealestates = async function (marketTrendFilter) {
  ///console.log(filter);
  const ret = await firebase.db
    .collection("realEstates_v1")
    .where("isActivate", "==", true)
    .get()
    .then((querySnapshot) => {
      const ls = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        //const investor = await loadUser(data);
        ls.push({
          id: doc.id,
          data: assign(realEstateType.type.realEstate, data),
          //invetor: investor
        });
      });
      return ls;
    });

  const ps = [];
  for (const r of ret) {
    ps.push(loadUser(r));
  }

  const rs = await Promise.all(ps);

  // TODO: 本番用
  const filter = marketTrendFilter;

  // TODO: デバック用
  // const filter = {
  //   score: {
  //     // スコア
  //     from: null,
  //     to: null
  //   },
  //   salePeriod: {
  //     // 取引時期
  //     from: null,
  //     to: null
  //   },
  //   area: {
  //     isSelected: false,
  //     prefecture: null, // 県
  //     cities: [] // 市町村
  //   },
  //   Line: {
  //     isSelected: false,
  //     prefecture: null, // 県
  //     line: null, // 沿線
  //     stations: [] // 駅
  //   },
  //   realEstateTypes: [], // 物件種別
  //   buildingTypes: [], // 建物種別
  //   couponYieldRate: {
  //     // 表面利回り
  //     from: null, //
  //     to: null
  //   },
  //   amount: {
  //     from: null, // 購入金額（From-To）
  //     to: null // 購入金額（From-To）
  //   },
  //   ageOfBuilding: {
  //     //築年数
  //     from: null,
  //     to: null
  //   },
  //   transport: {
  //     elapsedTime: null //最寄り駅までの時間
  //   }
  // };

  // テスト中にfilterがNULLになることがあったので、
  //一応入れておく
  // filterが渡ってくる
  //if (filter.transport) return rs;
  //console.log(filter);

  const filtered = rs
    .filter((r) => {
      return r.data.buildingUse === 1;
    })
    .filter((r) => {
      //  score: {
      //    // スコア
      //    from: null,
      //    to: null
      //  },
      const target = r.investor.data.latestScore.score;
      //console.log(target);
      const fil = filter.score;
      const filterFrom = fil.from;
      const filterTo = fil.to;
      //console.log(score);
      return isRangeSp(target, filterFrom, filterTo);
    })
    .filter((r) => {
      //salePeriod: {
      //  // 取引時期
      //  from: 0,
      //  to: 0
      //},
      const target = r.data.purchase.buyingDt.year;
      //console.log(target);
      const fil = filter.salePeriod;
      const filterFrom = fil.from;
      const filterTo = fil.to;
      return isRangeSp(target, filterFrom, filterTo);
    })
    .filter((r) => {
      //area: {
      //  isSelected: false,
      //  prefecture: null, // 県
      //  cities: [] // 市町村
      //},
      const prefecture = r.data.prefecture;
      const city = r.data.city;

      const af = filter.area;
      if (af.isSelected) {
        if (prefecture === af.prefecture) {
          if (af.cities.length) {
            return af.cities.some((c) => c === city);
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
      // return true;
    })
    .filter((r) => {
      //Line: {
      //  isSelected: false,
      //  prefecture: null, // 県
      //  line: null, // 沿線
      //  stations: [] // 駅
      //},

      const prefecture = r.data.prefecture;
      const lines = r.data.transports.map((t) => t.line);
      const stations = r.data.transports.map((t) => t.station);

      const lf = filter.Line;
      if (lf.isSelected) {
        if (prefecture === lf.prefecture) {
          if (lf.line) {
            if (lines.some((l) => l === lf.line)) {
              if (lf.stations.length) {
                return intersection(lf.stations, stations).length > 0;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .filter((r) => {
      //realEstateTypes: [], // 物件種別
      const target = r.data.realEstateType;
      //console.log(target);
      const fil = filter.realEstateTypes;
      if (fil.length) {
        return fil.some((t) => t === target);
      } else {
        return true;
      }
    })
    .filter((r) => {
      // buildingTypes: [], // 建物種別
      const target = r.data.buildingType;
      //console.log(target);
      const fil = filter.buildingTypes;
      if (fil.length) {
        return fil.some((t) => t === target);
      } else {
        return true;
      }
    })
    .filter((r) => {
      //   couponYieldRate: {
      //     // 表面利回り
      //     from: null, //
      //     to: null
      //   },
      const target = round(r.data.couponYieldRate, 4);
      //console.log(r.id);
      //console.log(target);
      const fil = filter.couponYieldRate;
      const filterFrom = fil.from;
      const filterTo = fil.to;
      const h = isRangeSp(target, filterFrom, filterTo);
      //if (h) console.log(target);
      return h;
    })
    .filter((r) => {
      //amount: {
      //  from: null, // 購入金額（From-To）
      //    to: null // 購入金額（From-To）
      //},
      const target = r.data.purchase.amount;
      //console.log(target);
      const fil = filter.amount;
      const filterFrom = fil.from;
      const filterTo = fil.to;
      return isRangeSp(target, filterFrom, filterTo);
    })
    .filter((r) => {
      //ageOfBuilding: {
      //  //築年数
      //  from: null,
      //    to: null
      //},
      const target = calcYearOfConstruction(r.data.constructionDate);
      // console.log(target);
      const fil = filter.ageOfBuilding;
      const filterFrom = fil.from;
      const filterTo = fil.to;
      return isRangeSp(target, filterFrom, filterTo);
    })
    .filter((r) => {
      //transport: {
      //  elapsedTime: null //最寄り駅までの時間
      //}
      const target = r.data.transports.map((t) => t.elapsedTime);
      const fil = filter.transport.elapsedTime;
      if (!isNil(fil)) {
        return target.some((t) => fil >= t);
      } else {
        return true;
      }
    });

  return filtered;
};

export default {
  name: "Mixins-market-trend-chart",
  methods: {
    async $$MarketTrendChart$RealEstateTransactionChartsOfYield(filter) {
      // console.log(filter);
      const res = await loadRealestates(filter.marketTrendFilter);

      // const marketTrendFilter = filter.marketTrendFilter;
      // const scoreSpan = 100;
      // const minScoreRange = marketTrendFilter.score.from || 0;
      // const maxScoreRange = marketTrendFilter.score.to || 990; //1000
      // const couponYieldRateSpan = 0.01;
      // const minCouponYieldRateRange =
      //   marketTrendFilter.couponYieldRate.from || 0;
      // const maxCouponYieldRateRange =
      //   marketTrendFilter.couponYieldRate.to || 10;

      const rets = [];
      // let ss = minScoreRange;
      // ss <= maxScoreRange;
      // ss = round(ss + scoreSpan, 4)
      for (const scoreRange of filter.scoreRange) {
        const minSs = scoreRange.from || 0;
        const maxSs = scoreRange.to || 1000;
        // let ps = minCouponYieldRateRange;
        // ps <= maxCouponYieldRateRange;
        // ps = round(ps + couponYieldRateSpan, 4)
        for (const couponYieldRateRange of filter.couponYieldRateRange) {
          let count = 0;
          const minPs = couponYieldRateRange.from || 0;
          const maxPs = couponYieldRateRange.to || 10;
          // const maxSs = round(ss + scoreSpan, 4);
          //const maxPs = round(ps + couponYieldRateSpan, 4);
          for (const re of res) {
            const investor = re.investor;
            //console.log(investor);
            if (
              isRange(investor.data.latestScore.score, minSs, maxSs) &&
              // isRange(investor.data.latestScore.score, ss, maxSs) &&
              isRange(re.data.couponYieldRate, minPs, maxPs)
              // isRange(re.data.couponYieldRate, ps, maxPs)
            ) {
              count++;
            }
          }
          rets.push({
            score: {
              from: minSs, //ss,
              to: maxSs,
            },
            couponYieldRate: {
              from: minPs, //ps,
              to: maxPs,
            },
            count: count,
          });
        }
      }
      //console.log(rets);
      return rets.filter((r) => {
        return r.count > 0;
      });
    },
    async $$MarketTrendChart$RealEstateTransactionChartsOfPrice(filter) {
      const res = await loadRealestates(filter.marketTrendFilter);

      // const marketTrendFilter = filter.marketTrendFilter;
      // const scoreSpan = 100;
      // const minScoreRange = marketTrendFilter.score.from || 0;
      // const maxScoreRange = marketTrendFilter.score.to || 990; // 1000
      // const amountSpan = 10000000;
      // const minAmountRange = marketTrendFilter.amount.from || 0;
      // const maxAmountRange = marketTrendFilter.amount.to || 100000000000;

      const rets = [];
      // let ss = minScoreRange;
      // ss <= maxScoreRange;
      // ss = ss + scoreSpan
      for (const scoreRange of filter.scoreRange) {
        const minSs = scoreRange.from || 0;
        const maxSs = scoreRange.to || 1000;
        // let ps = minAmountRange;
        // ps <= maxAmountRange;
        // ps = ps + amountSpan
        for (const priceRange of filter.priceRange) {
          let count = 0;
          const minPs = priceRange.from || 0;
          const maxPs = priceRange.to || 100000000000;
          // const maxSs = ss + scoreSpan;
          // const maxPs = ps + amountSpan;
          for (const re of res) {
            const investor = re.investor;
            //console.log(investor);
            if (
              isRange(investor.data.latestScore.score, minSs, maxSs) &&
              // isRange(investor.data.latestScore.score, ss, maxSs) &&
              isRange(re.data.purchase.amount, minPs, maxPs)
              // isRange(re.data.purchase.amount, ps, maxPs)
            ) {
              count++;
            }
          }
          rets.push({
            score: {
              from: minSs, // ss,
              to: maxSs,
            },
            amount: {
              from: minPs, // ps,
              to: maxPs,
            },
            count: count,
          });
        }
      }
      return rets.filter((r) => {
        return r.count > 0;
      });
    },
    async $$MarketTrendChart$RealEstateTransaction(re) {
      //console.log("$$MarketTrendChart$RealEstateTransaction");
      const purchase = re.data.purchase;
      //const loan = re.data.purchase.loan;
      //const bankId = loan.bankId;
      //const branchId = loan.branchId;
      const investor = re.investor;
      //const investor = await this.$$investor$loadUserSimple(re.data.owner);
      //const bank = await this.$$bank$getInfOfBank(bankId);
      //const branch = await this.$$bank$getInfOfBranch(branchId);

      const prefecture = await this.$$prefecture$load(re.data.prefecture);
      const city = await this.$$city$load(re.data.prefecture, re.data.city);

      const transport = re.data.transports[0];
      // line: 0, // 路線名
      // station: 0, //駅名
      // elapsedTime: 0 //分

      let ln = {};
      let st = {};
      let et = 0;
      if (transport) {
        ln = await this.$$line$load(transport.line);
        st = await this.$$station$load(transport.station);
        et = transport.elapsedTime;
      }

      //console.log(investor);
      // console.log(transport);
      const ret = {
        id: re.id,
        investor: {
          score: investor.data.latestScore.score, // スコア
          rank: investor.data.latestScore.rank, // ランク
          age: this.$$age$calc(investor.data.base.birthday), // 年代
          sex: investor.data.base.sex, // 性別
          categoryOfBusiness:
            investor.data.profile.currentOfCareer.categoryOfBusiness, // 業種
          typeOfOccupation:
            investor.data.profile.currentOfCareer.typeOfOccupation, // 職種
          membership: investor.data.membership, //会員プラン
        },
        amount: purchase.amount, //購入金額
        couponYieldRate: re.data.couponYieldRate, // 表面利回り
        realEstateType: re.data.realEstateType, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
        buildingArea: re.data.buildingArea, // 建物面積
        buildingType: re.data.buildingType,
        prefectureName: prefecture.text, // 県名
        cityName: city.cityName, // 市区町村名
        transport: {
          lineName: ln.line_name, // 路線名
          stationName: st.station_name, // 駅名
          elapsedTime: et, // 分
        },
        buyingDt: {
          yearMonth: purchase.buyingDt.year * 100 + purchase.buyingDt.month,
          year: purchase.buyingDt.year,
          month: purchase.buyingDt.month,
        }, // 購入年月
        constructionDate: {
          yearMonth:
            re.data.constructionDate.year * 100 +
            re.data.constructionDate.month,
          year: re.data.constructionDate.year,
          month: re.data.constructionDate.month,
        }, // 築年月
      };

      return ret;
    },
    async $$MarketTrendChart$loadRealEstateTransactionList(filter) {
      console.log(filter);
      console.log("$$MarketTrendChart$loadRealEstateTransactionList");
      const res = await loadRealestates(filter.marketTrendFilter);
      //console.log(res);

      const ps = [];
      for (const re of res) {
        ps.push(this.$$MarketTrendChart$RealEstateTransaction(re));
      }
      const rets = await Promise.all(ps);
      //console.log(rets);

      //type.realEstateTransactions.filter = {
      //  marketTrendFilter: null, // 市況情報検索条件
      //  getNumber: 50, // 取得件数
      //  pagination: 0, // ページ数
      //  sortType: 0, // ソート順（1:スコア順、2:利回り順、3:価格順、4:取引時期順）
      //  selectedFilter: {
      //    score: {
      //      from: 0,
      //      to: 0
      //    },
      //    amount: {
      //      from: null,
      //      to: null
      //    },
      //    couponYieldRate: {
      //      from: null,
      //      to: null
      //    }
      //  }
      //};
      const selectedFilter = filter.selectedFilter;
      const ranks = rets
        .filter((r) => {
          const target = r.investor.score;
          const start = selectedFilter.score.from;
          const end = selectedFilter.score.to;
          return isRange(target, start, end);
        })
        .filter((r) => {
          const target = r.amount;
          const start = selectedFilter.amount.from;
          const end = selectedFilter.amount.to;
          return isRange(target, start, end);
        })
        .filter((r) => {
          const target = r.couponYieldRate;
          const start = selectedFilter.couponYieldRate.from;
          const end = selectedFilter.couponYieldRate.to;
          return isRange(target, start, end);
        })
        .sort((a, b) => {
          // 1 : スコアの高い順
          // 2 : スコアの低い順
          // 3 : 購入価格の高い順
          // 4 : 利回りの高い順
          // 5 : 築年月が浅い順
          // 6 : 購入年月の近い順
          // filter.sortType = 6

          if (filter.sortType === 1) {
            // 1 : スコアの高い順
            return b.investor.score - a.investor.score;
          } else if (filter.sortType === 2) {
            // 2 : スコアの低い順
            return a.investor.score - b.investor.score;
          } else if (filter.sortType === 3) {
            // 3 : 購入価格の高い順
            return b.amount - a.amount;
          } else if (filter.sortType === 4) {
            // 4 : 利回りの高い順
            return b.couponYieldRate - a.couponYieldRate;
          } else if (filter.sortType === 5) {
            // 5 : 築年月が浅い順
            return b.constructionDate.yearMonth - a.constructionDate.yearMonth;
          } else if (filter.sortType === 6) {
            // 6 : 購入年月の近い順
            return b.buyingDt.yearMonth - a.buyingDt.yearMonth;
          } else {
            return b.investor.score - a.investor.score;
          }
        });

      const page = filter.pagination || 0;
      const num = filter.getNumber || 50;
      //return ranks.slice(page * num, num);
      return slice(ranks, num, page);
    },
    async $$MarketTrendChart$LoanTransaction(re) {
      const purchase = re.data.purchase;
      const loan = re.data.purchase.loan;
      const bankId = loan.bankId;
      const branchId = loan.branchId;
      const investor = re.investor;
      //const investor = await this.$$investor$loadUserSimple(re.data.owner);
      const bank = await this.$$bank$getInfOfBank(bankId);
      const branch = await this.$$bank$getInfOfBranch(branchId);

      const prefecture = await this.$$prefecture$load(re.data.prefecture);
      const city = await this.$$city$load(re.data.prefecture, re.data.city);

      const transport = re.data.transports[0];
      // line: 0, // 路線名
      // station: 0, //駅名
      // elapsedTime: 0 //分

      let ln = {};
      let st = {};
      let et = 0;
      if (transport) {
        ln = await this.$$line$load(transport.line);
        st = await this.$$station$load(transport.station);
        et = transport.elapsedTime;
      }

      //console.log(investor);
      const ret = {
        id: re.id,
        investor: {
          score: investor.data.latestScore.score, // スコア
          rank: investor.data.latestScore.rank, // ランク
          age: this.$$age$calc(investor.data.base.birthday), // 年代
          sex: investor.data.base.sex, // 性別
          categoryOfBusiness:
            investor.data.profile.currentOfCareer.categoryOfBusiness, // 業種
          typeOfOccupation:
            investor.data.profile.currentOfCareer.typeOfOccupation, // 職種
          membership: investor.data.membership, //会員プラン
        },
        amount: loan.amount, // 融資金額
        couponYieldRate: re.data.couponYieldRate, // 表面利回り
        bankId: bankId,
        bankName: bank ? bank.bank_name : "", // 銀行名
        branchName: branch ? branch.branch_name : "", // 支店名
        //paymentPeriods: loan.paymentPeriods,
        interestRate: loan.interestRate, // 金利
        interestType: loan.interestType, // 金利タイプ
        realEstateType: re.data.realEstateType, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
        prefectureName: prefecture.text, // 県名
        cityName: city.cityName, // 市区町村名
        transport: {
          lineName: ln.line_name, // 路線名
          stationName: st.station_name, // 駅名
          elapsedTime: et, // 分
        },
        paymentPeriods: {
          yearMonth: loan.paymentPeriods.year * 12 + loan.paymentPeriods.month,
          year: loan.paymentPeriods.year,
          month: loan.paymentPeriods.month,
        }, // 返済期間年月
        buyingDt: {
          yearMonth: purchase.buyingDt.year * 100 + purchase.buyingDt.month,
          year: purchase.buyingDt.year,
          month: purchase.buyingDt.month,
        }, // 購入年月
        constructionDate: {
          yearMonth:
            re.data.constructionDate.year * 100 +
            re.data.constructionDate.month,
          year: re.data.constructionDate.year,
          month: re.data.constructionDate.month,
        }, // 築年月
        executeDate: {
          yearMonth: loan.executeDate.year * 100 + loan.executeDate.month,
          year: loan.executeDate.year,
          month: loan.executeDate.month,
        }, // 融資実行年月
      };

      return ret;
    },
    async $$MarketTrendChart$loadLoanBankRanking(filter) {
      const ret = await firebase.functions(
        "MarketTrendChart_loadLoanBankRanking",
        {
          filter,
        }
      );
      return ret.data;
    },
    async $$MarketTrendChart$LoanTransactionList(filter) {
      console.log("$$MarketTrendChart$LoanTransactionList");
      const res = await loadRealestates(filter.marketTrendFilter);
      //console.log(res);

      const ps = [];
      for (const re of res) {
        ps.push(this.$$MarketTrendChart$LoanTransaction(re));
      }
      const rets = await Promise.all(ps);
      //console.log(rets[0]);
      //console.log(rets);

      //type.loanBankTransactions.filter = {
      //  marketTrendFilter: null, // 市況情報検索条件
      //  bank: null, // 	金融機関 bank(String)
      //  sortType: 1, // ソート順（1:スコア順、2:利回り順、3:価格順、4:取引時期順）
      //  getNumber: 50, // 取得件数
      //  pagination: 0 // ページ数
      //};

      //＜ローン取引動向＞
      //1 : スコアの高い順
      //2 : スコアの低い順
      //3 : 融資金額の高い順
      //4 : 返済期間の長い順
      //5 : 金利の低い順
      //6 : 融資実行年月の近い順
      //filter.sortType = 6

      const ranks = rets
        .filter((r) => {
          if (filter.bank) {
            return r.bankId === filter.bank;
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          if (filter.sortType === 1) {
            //1 : スコアの高い順
            return b.investor.score - a.investor.score;
          } else if (filter.sortType === 2) {
            //2 : スコアの低い順
            return a.investor.score - b.investor.score;
          } else if (filter.sortType === 3) {
            //3 : 融資金額の高い順
            return b.amount - a.amount;
          } else if (filter.sortType === 4) {
            //4 : 返済期間の長い順
            return b.paymentPeriods.yearMonth - a.paymentPeriods.yearMonth;
          } else if (filter.sortType === 5) {
            //5 : 金利の低い順
            return a.interestRate - b.interestRate;
          } else if (filter.sortType === 6) {
            //6 : 融資実行年月の近い順
            return b.executeDate.yearMonth - a.executeDate.yearMonth;
            // return a.executeDate.yearMonth - b.executeDate.yearMonth;
          } else {
            return b.executeDate.yearMonth - a.executeDate.yearMonth;
            // return b.investor.score - a.investor.score;
          }
        });

      const page = filter.pagination || 0;
      const num = filter.getNumber || 50;
      // return ranks.slice(page * num, num);
      return slice(ranks, num, page);

      //return rets;
    },
    // async $$MarketTrendChart$loadLoanBankRanking(filter) {
    //   //type.loanBankInfo = {
    //   //  ranking: "", // 順位
    //   //  imagePath: "", // 銀行画像
    //   //  bank: null, // 	金融機関 bank(String)
    //   //  bankName: "", // 銀行名
    //   //  number: 0, // 件数
    //   //  interestRate: {
    //   //    from: 0,
    //   //    to: 0
    //   //  } // 金利
    //   //};
    //   //type.loanBankRanking.filter = {
    //   //  marketTrendFilter: null, // 市況情報検索条件
    //   //  getNumber: 50, // 取得件数
    //   //  pagination: 0 // ページ数
    //   //};

    //   const res = await loadRealestates(filter.marketTrendFilter);
    //   //console.log(res);
    //   const banks = {};
    //   for (const re of res) {
    //     const loan = re.data.purchase.loan;
    //     //console.log(loan);
    //     const bankId = loan.bankId;
    //     //console.log(re);
    //     //console.log(bankId);
    //     if (bankId && bankId.length) {
    //       if (!banks[bankId]) {
    //         const bank = await this.$$bank$getInfOfBank(bankId);
    //         if (bank) {
    //           const bankImagePath = bank.bank_cd
    //             ? "/bank/" + bank.bank_cd
    //             : null;
    //           banks[bankId] = {
    //             number: 1,
    //             imagePath: bankImagePath,
    //             bank: bankId,
    //             bankName: bank.bank_name,
    //             interestRate: {
    //               from: loan.interestRate,
    //               to: loan.interestRate
    //             }
    //           };
    //         }
    //       } else {
    //         banks[bankId].number = banks[bankId].number + 1;
    //         if (loan.interestRate < banks[bankId].interestRate.from) {
    //           banks[bankId].interestRate.from = loan.interestRate;
    //         }
    //         if (banks[bankId].interestRate.to < loan.interestRate) {
    //           banks[bankId].interestRate.to = loan.interestRate;
    //         }
    //       }
    //     }
    //   }
    //   //console.log(banks);

    //   const bankRanks = Object.keys(banks)
    //     .map(k => banks[k])
    //     .sort((a, b) => b.number - a.number)
    //     .map((b, idx) => {
    //       b.ranking = idx + 1;
    //       return b;
    //     });
    //   //console.log(bankRanks);
    //   const page = filter.pagination || 0;
    //   const num = filter.getNumber || 50;
    //   //return bankRanks.slice(page * num, num);
    //   return slice(bankRanks, num, page);
    // }
  },
};
</script>
