<template>
  <div>
    <section>
      <ZHeader title="Message" sub="メッセージ">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="columns">
          <div class="column is-narrow">
            <div class="messageFilter">
              <ZEditAccordion
                v-if="bulma.isMobile !== undefined"
                title="絞り込み検索条件"
                icon="magnify"
                isProtrude
                :opened="!bulma.isMobile"
              >
                <p class="menu__title">メッセージ種別</p>
                <ul>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.message.proposal"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">提案オファー</span>
                    </div>
                  </li>
                  <li class="exclusive">
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.message.exclusive"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label"
                        >エクスクルーシブ</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.message.buying"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">売却オファー</span>
                    </div>
                  </li>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.message.contact"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">問い合わせ</span>
                    </div>
                  </li>
                </ul>
                <hr />
                <p class="menu__title">ステータス</p>
                <ul>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.status.unRead"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">未読</span>
                    </div>
                  </li>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.status.asRead"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">既読</span>
                    </div>
                  </li>
                  <li>
                    <div class="content menu__checkBox">
                      <b-checkbox
                        v-model="filter.status.unanswered"
                        type="is-info"
                      />
                      <span class="menu__checkBox__label">未返信</span>
                    </div>
                  </li>
                </ul>
                <button
                  class="button is-primary menu__button"
                  @click="execFilter"
                >
                  検索する
                </button>
              </ZEditAccordion>
            </div>
          </div>
          <div class="column">
            <template v-if="!isLoading">
              <div class="message-list">
                <h4 class="list-title">メッセージ一覧</h4>
                <div class="investor-message-index__header">
                  <template v-if="countOfFavorite === 0">
                    <div class="investor-message-index__header__message">
                      <p>
                        返信率が高いほど、優先的に提案を受けやすくなります。
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="investor-message-index__header__favorite">
                      <p
                        class="investor-message-index__header__favorite__message"
                      >
                        あなたをお気に入りしてる不動産担当者
                      </p>
                      <p class="investor-message-index__header__favorite__cnt">
                        {{ countOfFavorite }}
                      </p>
                      <p class="investor-message-index__header__favorite__nin">
                        人
                      </p>
                    </div>
                  </template>
                  <div class="investor-message-index__header__buttons">
                    <div class="investor-message-index__header__button">
                      <buyingNeedsButton />
                      <div class="investor-message-index__header__button__help">
                        <ZHelpIcon
                          vertical="top"
                          help="提案を受けたい物件の希望条件を登録できます。"
                        />
                      </div>
                    </div>
                    <div class="investor-message-index__header__button">
                      <ZFavoriteRealtorButton @click="onClickFavoriteRealtor" />
                      <div class="investor-message-index__header__button__help">
                        <ZHelpIcon
                          vertical="top"
                          help="過去に提案された不動産担当者を評価し、今後の継続提案の可否を管理できます。"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <b-table
                  :data="messages"
                  current-page.sync="currentPage"
                  :row-class="(row, index) => rowClass(row)"
                  per-page="50"
                  paginated
                  :hoverable="true"
                  :narrowed="true"
                  custom-row-key="id"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  @click="onRowClick"
                >
                  <template slot-scope="props">
                    <template v-if="needMask(props.row)">
                      <b-table-column field="id" colspan="5">
                        <NotPremiumCard />
                      </b-table-column>
                    </template>
                    <template v-else>
                      <b-table-column field="id" width="8">
                        <div class="content message-list__icon">
                          <template v-if="notification(props.row) === 0">
                            <span class="icon">
                              <i class="mdi mdi-redo-variant"></i>
                            </span>
                          </template>
                          <template v-else-if="notification(props.row) === 1">
                            <span class="icon has-text-unread">
                              <i class="mdi mdi-circle"></i>
                            </span>
                          </template>
                          <template v-else-if="notification(props.row) === 2">
                            <span class="icon">
                              <i class="mdi mdi-minus"></i>
                            </span>
                          </template>
                        </div>
                      </b-table-column>
                      <b-table-column
                        field="realtor.user.data.base.name"
                        label="専門家"
                        width="160"
                      >
                        <div class="profile">
                          <div class="image is-32x32 profile__image">
                            <z-profile-thumbnail
                              v-model="
                                props.row.realtor.user.data.profile.image
                              "
                            />
                          </div>
                          <div class="content">
                            <p class="is-size-7">
                              <z-truncator
                                :text="props.row.realtor.user.data.base.name"
                              />
                              <z-truncator
                                :text="props.row.realtor.company.data.name"
                              />
                            </p>
                          </div>
                        </div>
                      </b-table-column>
                      <b-table-column
                        field="data.threads"
                        :label="bulma.isMobile ? '' : 'メッセージ'"
                      >
                        <div class="content message-content">
                          <template
                            v-if="
                              $$ks.message.getType(props.row.data.type)
                                .value === 1
                            "
                          >
                            <div class="message-content__exclusive-label">
                              <img
                                src="@/assets/message/label-exclusive-S.png"
                              />
                            </div>
                            <z-truncator
                              class="is-size-7 message-content__title message-content__exclusive-title"
                              :text="props.row.data.title"
                              :line="1"
                            ></z-truncator>
                          </template>
                          <template v-else>
                            <div>
                              <z-truncator
                                class="is-size-7 message-content__title"
                                :text="props.row.data.title"
                              ></z-truncator>
                            </div>
                          </template>
                          <z-truncator
                            class="has-text-gray is-size-7"
                            :text="latestThread(props.row)"
                          ></z-truncator>
                        </div>
                      </b-table-column>
                      <b-table-column
                        field="data.types"
                        label="種別"
                        width="78"
                      >
                        <div class="message-type has-text-centered">
                          <template
                            v-if="
                              $$ks.message.getType(props.row.data.type)
                                .value === 3 ||
                              $$ks.message.getType(props.row.data.type)
                                .value === 4
                            "
                          >
                            {{ $$ks.message.getType(props.row.data.type).en
                            }}<br />
                            ({{
                              $$ks.message.getType(props.row.data.type).text
                            }})
                          </template>
                        </div>
                      </b-table-column>
                      <b-table-column
                        field="data.latestSendDt"
                        label="送信日"
                        width="80"
                      >
                        <div class="content">
                          <p class="is-dt is-size-7">
                            {{
                              $$calender$todayFormat(
                                props.row.data.latestSendDt
                              )
                            }}
                          </p>
                        </div>
                      </b-table-column>
                    </template>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                        </p>
                        <p>Nothing here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Menu from "@/components/fl/my/Menu";
import * as InvestorModule from "@/modules/investor/investor";
import BuyingNeedsButton from "@/views/investor/common/BuyingNeedsButton";
import NotPremiumCard from "./NotPremiumCard.vue";
import * as message from "@/modules/message/message";

export default {
  name: "message-index",
  components: {
    BuyingNeedsButton,
    NotPremiumCard,
  },
  data: function () {
    return {
      countOfFavorite: 0,
      staticSampleImg1: {
        description: "",
        id: "offer-blur-icon1.png",
        isApproved: true,
        title: "",
      },
      staticSampleImg2: {
        description: "",
        id: "offer-blur-icon2.png",
        isApproved: true,
        title: "",
      },
      filter: {
        message: {
          proposal: false,
          exclusive: false,
          buying: false,
          contact: false,
        },
        rank: {
          s: false,
          a: false,
          b: false,
          c: false,
        },
        status: {
          unRead: false,
          asRead: false,
          unanswered: false,
        },
      },
      isLoading: true,
      messages: [],
      total: 0,
      page: 1,
      perPage: 50,
      sortType: 1,
    };
  },
  computed: {},
  methods: {
    onClickFavoriteRealtor() {
      this.$router.push({
        name: "investor-favorite-realtor",
      });
    },
    staticSampleImg(idx) {
      return idx % 2;
    },
    onRowClick(row) {
      if (!this.needMask(row)) {
        this.$router.push({
          name: "investor-message-detail",
          params: { id: row.id },
        });
      }
    },
    async countOfFavoriteSearch() {
      const uid = this.auth.uid;
      const query = {
        term: {
          "uid.keyword": {
            value: uid,
          },
        },
      };

      const rt = await InvestorModule.search({
        query: query,
      });
      this.countOfFavorite = rt.hits[0]._source.favorite.countOfFavorites;
    },
    async loadMessages() {
      const uid = this.me.user.id;
      const rs = await message.searchByfilter(
        {
          ...this.filter,
          investor: uid,
        },
        this.sortType,
        this.page,
        this.perPage,
        uid
      );

      this.messages = rs.hits.map((m) => m._source);
      this.total = rs.total.value;

      // const ms = await this.$$message$loadMessagesByFilter(
      //   this.me.user.id,
      //   this.filter
      // );
      // this.messages = ms;
    },
    async execFilter() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        await this.loadMessages();
        await this.countOfFavoriteSearch();
        // const ms = await this.$$message$loadMessagesByFilter(
        //   this.me.user.id,
        //   this.filter
        // );
        // this.messages = ms.filter(m => this.filterOnlyIE(m));
        // const result = await InvestorModule.search(this.query);
        this.isLoading = false;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;

        await this.loadMessages();
        await this.countOfFavoriteSearch();

        // const ms = await this.$$message$loadMessagesByUID(this.me.user.id);
        // this.messages = ms.filter(m => this.filterOnlyIE(m));
        // await this.countOfFavoriteSearch();

        this.isLoading = false;
        return;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    filterOnlyIE(message) {
      if (!this.$$user$isPremium && this.$$userAgent$isIE()) {
        return message.data.type === 1;
      } else {
        return true;
      }
    },
    needMask(message) {
      return (
        !this.$$user$isPremium &&
        !(
          message.data.type == 1 ||
          message.data.type == 3 ||
          message.data.type == 4
        )
      );
    },
    rowClass(message) {
      // console.log(message);
      if (this.needMask(message)) {
        return "is-needMask";
      } else if (this.notification(message) === 1) {
        return "is-asNotRead";
      } else {
        return "is-asRead";
      }
    },
    notification(message) {
      return this.$$message$JudgmentNotification(this.auth.uid, message);
    },
    latestThread(message) {
      const m = message.data.threads.slice(-1)[0];
      const ln = m.contents.replace("\n", "");
      return ln;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
  // computed: {
  //   ...mapState("user", {
  //     user: "user"
  //   })
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investor-message-index {
  &__header {
    margin-top: 15px;
    width: 100%;
    height: 70px;
    @include sp {
      height: 100px;
    }
    &__message {
      float: left;
      flex: 1;
      margin-top: 6px;
      > p {
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
    &__favorite {
      float: left;
      background-color: #f1f6fc;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0px 23px;
      @include sp {
        margin-left: 0;
        width: 100%;
      }
      &__message {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        letter-spacing: 0;
        line-height: 14px;
      }
      &__cnt {
        margin-left: 14px;
        margin-top: -4px;
        font-size: 26px;
        font-weight: bold;
        color: #0062d7;
        letter-spacing: 0;
        line-height: 26px;
      }
      &__nin {
        margin-left: 0px;
        font-size: 14px;
        font-weight: bold;
        color: #0062d7;
        letter-spacing: 0;
        line-height: 14px;
      }
    }
    &__buttons {
      @include sp {
        margin-top: 8px;
        float: left;
        // width: 100%;
      }
    }
    &__button {
      float: right;
      display: flex;
      margin-left: 10px;
      &:last-child {
        margin-left: 0px;
      }
      &__help {
        margin-left: 2px;
        margin-top: -2px;
      }
    }
  }
}

.menu {
  padding: 15px;
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin: 13px 0 7px;
  }
  &__checkBox {
    font-size: 14px;
    &__label {
      color: #333333;
      vertical-align: 4px;
    }
  }
  &__select {
    padding-right: 20px;
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
}

.profile {
  display: flex;
  &__image {
    margin-right: 12px;
  }
}

.message-list {
  margin-left: 8px;
  @include sp {
    margin-left: 0px;
  }
}

.message-content {
  width: auto;
  @include mobile {
    width: 240px;
  }
  &__title {
    margin: 0px;
  }
  &__exclusive-label {
    width: 22%;
    display: inline-block;
    @include md {
      width: 30%;
      margin-right: 10px;
    }
  }
  &__exclusive-title {
    width: 70%;
    display: inline-block;
    vertical-align: 3px;
    @include md {
      width: 60%;
    }
    @include sp {
      width: 65%;
    }
  }
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.message-content__exclusive-label {
  width: 15%;
  margin-right: 5px;
}

// for Edge hack
_:-ms-lang(x)::backdrop,
.message-content__exclusive-label {
  width: 18%;
  margin-right: 5px;
}

.is-dt {
  margin-top: 3px;
  @include mobile {
    margin-top: 0px;
  }
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: #dbdbdb;
//  border-bottom: solid 2px #707070;
//  color: black;
//}

.blankBox {
  height: 300px;
}

.exclusive {
  margin-left: 25px;
}

.message-type {
  font-size: 12px;
}

.message-list {
  &__icon {
    @include lg {
      margin-top: 7px;
    }
    @include md {
      margin-top: 6px;
    }
    @include sp {
      margin-top: 3px;
    }
  }
}

.blur-text {
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  margin: 3px 15px 0 0;
  &__icon {
    font-size: 10px;
    vertical-align: -5px;
  }
  &__title {
    // line-height: 28px;
    // font-size: 13px;
    @include sp {
      font-size: 11px;
    }
  }
}
</style>
