<template>
  <div class="z-imageCrop">
    <VueCropper
      class="z-imageCrop__crop"
      :class="{ 'is-circle': isCircle }"
      ref="cropper"
      alt=""
      drag-mode="crop"
      :auto-crop-area="0.5"
      :min-container-width="90"
      :min-container-height="90"
      :background="true"
      :rotatable="true"
      :aspect-ratio="aspect"
      :img-style="{ width: '100%', height: 'auto' }"
      :src="sourceImage"
    />
    <div class="z-imageCrop__footer">
      <a class="z-imageCrop__footer__rotate" @click="rotate">
        <b-icon icon="format-rotate-90" />
      </a>
      <button
        class="z-imageCrop__footer__apply button is-secondary"
        @click="crop"
      >
        適用
      </button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ZImageCrop",
  components: {
    VueCropper,
  },
  props: {
    // 入力画像
    sourceImage: {
      type: String,
      default: "",
    },
    // トリミングされた画像
    croppedImage: {
      type: String,
      default: "",
    },
    // トリミングのアスペクト比
    aspect: {
      type: Number,
      default: null,
    },
    // 円型トリム
    isCircle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    crop() {
      this.$emit(
        "update:croppedImage",
        this.$refs.cropper.getCroppedCanvas().toDataURL("image/jpeg")
      );
      this.$emit("update:sourceImage", "");
      this.$refs.cropper.reset();
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
  },
  watch: {
    sourceImage: {
      handler(newVal) {
        if (!newVal) {
          this.$refs.cropper.reset();
          return;
        }
        this.$refs.cropper.replace(newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-imageCrop {
  &__crop {
    width: 100%;
    max-height: 516px;
    font-size: 0;
    /deep/.cropper-bg {
      background-image: none;
      background-color: #000;
    }
    /deep/.cropper-view-box {
      box-shadow: 0 0 0 3px #39f;
      outline: 0;
    }
    &.is-circle {
      /deep/.cropper-crop-box,
      /deep/.cropper-view-box {
        border-radius: 50%;
      }
      /deep/.cropper-face {
        background-color: inherit !important;
      }
      /deep/.cropper-dashed {
        display: none !important;
      }
    }
  }
  &__footer {
    width: 100%;
    height: 54px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    &__rotate {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
    }
    &__apply {
      /deep/ &.button {
        color: #0262d8;
        border-color: #0262d8;
      }
    }
  }
}
</style>
