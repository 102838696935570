<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告" sub="担当者評価の入力">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <!--
      <ZHeader title="不動産売買申告"
    /> -->
    <section class="edit">
      <div class="edit__notice">
        <h3>
          今回のご担当した不動産会社担当者の評価を<br
            class="br-show"
          />入力してください
        </h3>
      </div>
      <TransactionSteps :activeStep="2" />
      <div class="edit__section">
        <h3 class="edit__subtitle">不動産担当者に対する評価</h3>

        <RealtorProfile :user="staff" />
        <z-select
          label="不動産提案力"
          v-model="transaction.data.realtorEvaluation.realEstateProposal"
          placeholder="５段階から選択してください"
          :options="evaluationTypes"
          :validate="'required'"
          isRequired
        />
        <z-select
          label="融資先金融機関提案力"
          v-model="transaction.data.realtorEvaluation.bankProposal"
          placeholder="５段階から選択してください"
          :options="evaluationTypes"
          :validate="'required'"
          isRequired
        />
        <z-select
          label="投資知識・事業計画提案力"
          v-model="
            transaction.data.realtorEvaluation
              .investmentKnowledgeAndBussinessPlanProposal
          "
          placeholder="５段階から選択してください"
          :options="evaluationTypes"
          :validate="'required'"
          isRequired
        />
        <z-select
          label="信頼感・対応力"
          v-model="transaction.data.realtorEvaluation.trustAndResponse"
          placeholder="５段階から選択してください"
          :options="evaluationTypes"
          :validate="'required'"
          isRequired
        />
        <z-input
          label="評価コメント"
          v-model="transaction.data.realtorEvaluation.comment"
          type="textarea"
          placeholder="不動産会社ご担当者の特に優れていた点、または不満な点等を具体的にご記載ください。（本内容は、不動産会社の口コミとして他投資家が閲覧する重要な指標になります。）"
          maxlength="1000"
          isRequired
          :validate="'required'"
        />
      </div>

      <div class="edit__foot">
        <router-link
          :to="{ name: 'transaction-review-loan' }"
          class="button z-button is-rounded"
          >戻る</router-link
        >
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button is-wide button__mobile"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";
import TransactionSteps from "../parts/TransactionSteps";
import RealtorProfile from "@/components/realtor/RealtorProfile";

export default {
  name: "investor-transaction-review-staff-evaluation",
  data: function () {
    return {
      isLoading: true,
      transaction: null,
      staff: null,
      savedTransaction: null,
      evaluationTypes: kubuns.transaction.evaluationTypes,
    };
  },
  components: {
    TransactionSteps,
    RealtorProfile,
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async init() {
      try {
        this.transaction =
          await this.$$transaction$loadRealtorTransactionByLink(
            this.transactionId
          );
        this.staff = await this.$$realtor$loadUser(
          this.transaction.data.realtor
        );
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.transaction.data.statusOfRegister !== 990) {
          this.savedTransaction =
            await this.$$transaction$saveRealtorTransaction(
              this.me.id,
              this.transaction
            );
        }
        this.$router.push({
          name: "transaction-review-bankaccount",
          // params: { id: this.savedTransaction.data.link }
          params: { id: this.transactionId },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  &__notice {
    padding: 0 22px;
    @include sp {
      padding: 0 16px;
    }
    .br-show {
      display: none;
      @include sp {
        display: block;
      }
    }
  }
}

.button {
  &__mobile {
    @include sp {
      margin: 10px 0 0 !important;
    }
  }
}
</style>
