<template>
  <div class="realestateDetail" v-if="!isLoading">
    <section>
      <ZHeader title="My Collection" sub="保有不動産">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
      <section class="z-section">
        <RealEstate :rsInfo="rsInfo" @saved="savedRealEstate" />
      </section>
    </section>
  </div>
</template>

<script>
// import RealestateOverview from "@/components/realestate/RealestateOverview";
import RealEstate from "@/components/investor/RealEstate";
// import RealestateDetail from "@/components/realestate/RealestateDetail";
// import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
// import EditRealEstateUnit from "../common/EditRealEstateUnit";

export default {
  name: "collection-realestate",
  data: function () {
    return {
      isLoading: true,
      rsInfo: null,
    };
  },
  components: {
    RealEstate: RealEstate,
  },
  //components: {
  //  RealestateOverview: RealestateOverview,
  //  RealestateDetail: RealestateDetail,
  //  TileBoxOverlay: TileBoxOverlay,
  //  EditRealEstateUnit: EditRealEstateUnit
  //},
  //computed: {
  //  data() {
  //    return this.rsInfo.data;
  //  }
  //},
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.rsInfo = await this.$$realEstate$loadRealEstate(
          this.$route.params.id
        );
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    //openEdit() {
    //  this.$refs.overlay.open();
    //},
    savedRealEstate(rsInfo) {
      //this.rsInfo = rsInfo;
      //this.$refs.overlay.close();
      if (!rsInfo.data.isActivate) {
        this.$router.push({ name: "investor-collection" });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realestateDetail {
  .titlebar {
    padding: 6px 0;
    background-color: #f4f4f4;
    color: #787878;
    &__title {
      position: relative;
      font-size: 24px;
      &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
