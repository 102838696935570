<script>
export default {
  name: "ChartMixins",
  inject: ["$validator"],
  // props: {
  //   name: String,
  //   placeholder: String,
  //   size: String,
  //   as: String,
  //   validate: String
  // },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
