<template>
  <div class="realEstateTransactionList">
    <template v-if="transactionInfos.length">
      <ul class="realEstateTransactionList__list">
        <li v-for="info in transactionInfos" :key="info._source.id">
          <a
            :href="'/investor/markettrend/realestate/' + info._source.id"
            target="_blank"
          >
            <TransactionBox :realestate="info" type="realEstate" />
          </a>
        </li>
      </ul>

      <div
        class="loanTransactionLoan__more"
        v-if="maxCount > transactionInfos.length && !isLoading"
      >
        <MoreButton
          :label="'もっと見る (' + maxCount + '件)'"
          @click="loadInfo(false)"
          expanded
        />
      </div>
    </template>
    <template v-if="!transactionInfos.length && !isLoading">
      対象がありません
    </template>
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import TransactionBox from "./TransactionBox";
// import investorType from "@/types/investorType";
//import { request } from "http";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "RealEstateTransactionList",
  components: {
    TransactionBox,
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    selectedFilter: {
      type: Object,
      default: null,
    },
    sortType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      pageIndex: 0,
      loadNumber: 10,
      transactionInfos: [],
      maxCount: 0,
    };
  },
  computed: {
    request() {
      const request = this.$$investor$getRealEstateTransactionsFilter();
      const selectedFilter = {
        ...request.selectedFilter,
        ...this.selectedFilter,
      };
      return {
        ...request,
        selectedFilter,
        marketTrendFilter: this.filter,
        getNumber: this.loadNumber,
        sortType: this.sortType,
      };
    },
  },
  watch: {
    request: {
      handler() {
        if (
          this.filter !== null &&
          this.selectedFilter &&
          Object.keys(this.selectedFilter).length
        ) {
          this.loadInfo(true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onClick(realEstate) {
      if (realEstate.id) {
        this.$router.push({
          name: "investor-markettrend-realestate",
          params: { id: realEstate.id },
        });
      }
    },
    onClickMore() {
      if (this.isLoading) {
        return;
      }
      this.loadInfo();
    },
    async loadInfo(isRefresh) {
      this.isLoading = true;
      if (isRefresh) {
        this.pageIndex = 0;
      }
      try {
        const request = { ...this.request, pagination: this.pageIndex };
        // const oldresult = await this.$$MarketTrendChart$loadRealEstateTransactionList(
        //   request
        // );
        const result = await marketTrend.searchRealestes(request);

        const { /*countOfData, */ pagination, maxCount, data } = result;
        if (isRefresh) {
          this.transactionInfos.splice(-this.transactionInfos.length);
        }
        this.transactionInfos.push(...data);
        this.pageIndex = pagination + 1;
        this.maxCount = maxCount;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("データの取得に失敗しました。");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realEstateTransactionList {
  position: relative;
  min-height: 200px;
  @include sp {
    min-height: 140px;
  }
  &__list {
    & > li {
      margin-bottom: 16px;
    }
  }
  &__more {
    padding: 12px 0 36px;
    text-align: center;
  }
}
</style>
