<template>
  <div class="investor--favorite">
    <a class="investor--favorite__button" @click="onToggleFavorite">
      <ZIcon name="favorite" width="18" height="18" :color="iconColor" />
    </a>
  </div>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";
import * as FavoriteModule from "@/modules/realtor/favorite";

export default {
  name: "investor-favorite",
  mixins: [DisplayTextMixin],
  props: {
    uid: {
      type: String,
      require: true,
    },
  },
  data: function () {
    return {
      isFavorite: null,
      iconColor: null,
    };
  },
  methods: {
    // お気に入りをトグル
    onToggleFavorite() {
      this.isFavorite = !this.isFavorite;
      this.iconColor = this.$$favoriteIconColor(this.isFavorite);
      FavoriteModule.save(this.me.user.id, this.uid, this.isFavorite);
    },
  },
  asyncComputed: {
    // お気に入りのアイコン色を表示
    async favoriteColor() {
      const favoriteState = await FavoriteModule.load(
        this.me.user.id,
        this.uid
      );

      this.isFavorite = favoriteState;
      this.iconColor = this.$$favoriteIconColor(this.isFavorite);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investor {
  &--favorite {
    height: 18px;
    margin: 0 auto;
    width: 18px;

    &__button {
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0;
      width: 100%;
    }
  }
}
</style>
