<template>
  <div class="z-docsListSelect">
    <ZField
      :label="label"
      :class="errors.has(internalName) ? 'is-danger' : ''"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      :isShort="isShort"
    >
      <z-docs-input
        class="z-docsListSelect__input"
        :uploadPath="uploadPath"
        @addFile="addFile"
      />
    </ZField>
    <z-docs-list v-model="value" />
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZDocsListSelect",
  mixins: [InputMixin],
  // inject: ["$validator"],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    uploadPath: {
      type: String,
      default: null,
    },
  },
  methods: {
    addFile(val) {
      this.value.push(val.fileData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-docsListSelect {
  display: flex;
  margin-bottom: 50px;
  &__input {
    margin-right: 50px;
  }
}
</style>
