<template>
  <div class="z-marketTrendTransactionChart">
    <div class="z-marketTrendTransactionChart__inner">
      <ZMarketTrendTransactionChart
        class="z-marketTrendTransactionChart__chart"
        :datas="datas"
        :yAxes="type === 'price' ? priceRange : couponYieldRateRange"
        :xAxes="scoreRange"
        :type="type"
        :current.sync="current"
      />
    </div>
    <ul
      class="z-marketTrendTransactionChart__axis"
      :class="{ 'is-price': type === 'price' }"
    >
      <li
        v-for="(item, index) in xAxes"
        class="z-marketTrendTransactionChart__axis__item"
        :key="index"
        :class="{
          'is-current':
            currentScoreIndex === index || currentScoreIndex === index + 1,
        }"
      >
        <span>{{ item }}</span>
        <ZProfileThumbnail
          class="thumbnail"
          v-if="currentScoreIndex === index"
          v-model="profileImage"
        />
      </li>
    </ul>
    <div class="z-marketTrendTransactionChart__empty" v-if="!datas.length">
      <p>対象がありません</p>
    </div>
  </div>
</template>

<script>
import { findIndex, sortedIndex, /* clamp, */ last, get } from "lodash";
import ZMarketTrendTransactionChart from "@/components/charts/ZMarketTrendTransactionChart.vue";

export default {
  name: "ZMarketTrendTransaction",
  components: {
    ZMarketTrendTransactionChart,
  },
  props: {
    // チャートのタイプ(price: 金額 / couponYieldRate: 利回り)
    type: {
      validator(value) {
        return ["price", "couponYieldRate"].indexOf(value) !== -1;
      },
    },
    // バブルにするデータ
    groups: {
      type: Array,
      default: () => [],
    },
    // 現在のスコア
    score: {
      type: Object,
      default: () => {},
    },
    // 選択中のバブルのデータ
    currentGroups: {
      type: Object,
      default: null,
    },
    // スコアの軸情報
    scoreRange: {
      type: Array,
      default: () => [],
    },
    // 価格の軸情報
    priceRange: {
      type: Array,
      default: () => [],
    },
    // 利回りの軸情報
    couponYieldRateRange: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: null,
    };
  },
  computed: {
    // データをグラフ表示用に整形
    datas() {
      if (!this.type) return [];

      const items = this.groups.map((item) => {
        // y軸のどの位置にいるか
        let yIndex = 0;
        // yの実際の値
        let y = {};

        if (this.type === "price") {
          // y軸の位置を計算
          yIndex =
            findIndex(
              this.priceRange,
              (range) =>
                item.amount.from <= range.from && item.amount.to >= range.to
            ) + 0.5; // ラベルの間にバブルが来るように、0.5を足しておく
          y = { amount: item.amount };
        } else {
          // y軸の位置を計算
          yIndex =
            findIndex(
              this.couponYieldRateRange,
              (range) =>
                item.couponYieldRate.from <= range.from &&
                item.couponYieldRate.to >= range.to
            ) + 0.5; // ラベルの間にバブルが来るように、0.5を足しておく
          y = { couponYieldRate: item.couponYieldRate };
        }

        // フィルタ条件
        const filter = { score: item.score, count: item.count, ...y };

        return {
          // x軸の位置
          x: (item.score.from + item.score.to) / 2,
          // y軸の位置
          y: yIndex,
          // 件数
          v: item.count,
          // リスト検索時の条件
          filter,
        };
      });

      return items;
    },
    // スコア軸のラベル
    xAxes() {
      if (!this.scoreRange.length) {
        return [];
      }
      return [
        ...this.scoreRange.map((range) => range.from || 0),
        last(this.scoreRange).to || 1000,
      ];
    },
    // 現在のスコアから、スコア軸上の位置を計算
    currentScoreIndex() {
      const index = sortedIndex(this.xAxes, this.score.score);
      if (index === 0 || index === this.xAxes.length) {
        return null;
      }
      return index;
    },
    // プロフィール画像
    profileImage() {
      return get(
        this.me.user,
        "data.profile.image",
        this.$$investor$getNewImage()
      );
    },
  },
  watch: {
    // データ更新時にカレントを選択する必要がなくなったのでコメントアウト
    // datas: {
    //   handler(newVal, olaVal) {
    //     if (newVal !== null && newVal.length) {
    //       const { score } = this.score;
    //       let currentIndex = newVal.findIndex(
    //         item =>
    //           item.filter.score.from <= score && item.filter.score.to > score
    //       );
    //       currentIndex = currentIndex > 0 ? currentIndex : 0;
    //       // カレントを移動
    //       this.current = currentIndex;
    //       this.$emit("update:currentGroups", newVal[currentIndex].filter);
    //     }
    //   },
    //   immediate: true
    // },
    datas: {
      handler() {
        this.current = null;
      },
    },
    current: {
      handler(newVal, olaVal) {
        if (newVal !== olaVal && newVal !== null) {
          this.$emit("update:currentGroups", this.datas[newVal].filter);
        }
      },
    },
    currentGroups: {
      handler(newVal) {
        // 洗濯中の条件がリセットされた時はカレント表示もリセットする
        if (newVal === null) {
          this.current = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-marketTrendTransactionChart {
  width: 100%;
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -14px;
    transform: translateY(-50%) scale3d(1, -1.2, 0.5) rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); // for IE11
    width: 30px;
    height: 30px;
    border-bottom: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
    background-color: #fff;
    @include sp {
      content: none;
    }
  }
  &__empty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__inner {
    width: 100%;
    position: relative;
    &:before {
      position: relative;
      padding-top: 80%;
      display: block;
      content: "";
    }
  }
  &__chart {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  &__axis {
    margin-left: -5%;
    margin-right: -5%;
    padding-left: 52px;
    padding-right: 40px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-around;
    &.is-price {
      padding-left: 96px;
      @include sp {
        padding-left: 76px;
      }
    }
    &__item {
      width: 100%;
      position: relative;
      font-size: 14px;
      line-height: 15px;
      color: #7b7b7b;
      text-align: center;
      @include md {
        font-size: 12px;
        line-height: 13px;
      }
      @include sp {
        font-size: 11px;
        line-height: 12px;
      }
      &.is-current {
        font-size: 15px;
        font-weight: bold;
        color: #0262d8;
        @include md {
          font-size: 13px;
        }
        @include sp {
          font-size: 12px;
        }
      }
      .thumbnail {
        width: 34px;
        height: 34px;
        top: -44px;
        left: 0;
        transform: translateX(-50%);
        padding-top: 0;
        position: absolute;
        /deep/ img {
          border: 1px solid #0262d8;
        }
        @include sp {
          width: 24px;
          height: 24px;
          top: -42px;
        }
      }
    }
  }
}
</style>
