<template>
  <div class="z-areaLineFieldSet b-tabs z-tabsButton">
    <nav class="tabs is-toggle">
      <ul>
        <li :class="activeTabIndex === 0 ? 'is-active' : ''">
          <a @click="onTabChange(0)"><span>エリア</span></a>
        </li>
        <li :class="activeTabIndex === 1 ? 'is-active' : ''">
          <a @click="onTabChange(1)"><span>沿線</span></a>
        </li>
      </ul>
    </nav>

    <div class="tab-content">
      <div v-if="activeTabIndex === 0" class="tab-item">
        <ZAreaField
          ref="areaField"
          v-model="value.area"
          :validate="validate"
          :isRequired="isRequired"
          :hideRequired="hideRequired"
        />
      </div>
      <div v-if="activeTabIndex === 1" class="tab-item">
        <ZLineField
          ref="lineField"
          v-model="value.Line"
          :validate="validate"
          :isRequired="isRequired"
          :hideRequired="hideRequired"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZAreaLineFieldSet",
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      default: null,
    },
    validate: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hideRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  methods: {
    onTabChange(index) {
      this.activeTabIndex = index;
      this.$emit("tabAreaLineChange", this.activeTabIndex);
      // this.setAreaLineSelected(index);
      if (index === 0) {
        if (this.$refs.lineField) this.$refs.lineField.reset();
      } else {
        if (this.$refs.areaField) this.$refs.areaField.reset();
      }
    },
    // setAreaLineSelected(index) {
    //   if (index === 0) {
    //     this.value.area.isSelected = true;
    //     this.value.Line.isSelected = false;
    //   } else {
    //     this.value.area.isSelected = false;
    //     this.value.Line.isSelected = true;
    //   }
    // },
    reset() {
      if (this.$refs.areaField) this.$refs.areaField.reset();
      if (this.$refs.lineField) this.$refs.lineField.reset();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { area, Line } = newVal;
        this.value.area.isSelected =
          area && (area.prefecture !== null || area.cities.length)
            ? true
            : false;
        this.value.Line.isSelected =
          Line &&
          (Line.prefecture !== null ||
            Line.line !== null ||
            Line.stations.length)
            ? true
            : false;

        if (
          this.value.area.prefecture === null ||
          this.value.Line.prefecture === null
        ) {
          this.$emit("tabAreaLineReset", this.activeTabIndex);
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.value.Line.isSelected) this.onTabChange(1);
    else this.onTabChange(0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-areaLineFieldSet {
  margin-bottom: 32px;
  /deep/ .tab-content {
    margin-top: 24px;
    padding: 0;
    background-color: #f4f4f4;
  }
}
</style>
