<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <h1>BS</h1>
      <div class="columns">
        <div class="column is-6">
          <z-input
            label="現金"
            type="number"
            name="bufbs.assets.cash.sum"
            v-model.number="bufbs.assets.cash.sum"
          >
          </z-input>
          <z-input
            label="有価証券"
            type="number"
            v-model.number="bufbs.assets.security.sum"
            name="bufbs.assets.security.sum"
          >
          </z-input>
          <z-input
            label="保険"
            type="number"
            v-model.number="bufbs.assets.insurance.sum"
            name="bufbs.assets.insurance.sum"
          >
          </z-input>
          <z-input
            label="不動産"
            type="number"
            v-model.number="bufbs.assets.realEstate.sum"
            name="bufbs.assets.realEstate.sum"
          >
          </z-input>
          <z-input
            label="負債"
            type="text"
            v-model.number="bufbs.liabilities.sum"
            name="bufbs.liabilities.sum"
          >
          </z-input>
        </div>
        <div class="column is-6">
          <div class="content">
            <h2>スコア</h2>
            {{ bs }}
            <p>
              資産: {{ bs.assets.sum }} <br />
              負債：{{ bs.liabilities.sum }}<br />
              純資産：{{ bs.netAssets.sum }}<br />
              スコア：{{ scoreFromBS }}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <h1>PL</h1>
      <div class="columns">
        <div class="column is-6">
          <z-input
            type="number"
            label="給与"
            name="pl.salary"
            v-model.number="pl.salary"
          >
          </z-input>
          <z-input
            type="number"
            label="配当"
            name="pl.dividend"
            v-model.number="pl.dividend"
          >
          </z-input>
          <z-input
            type="number"
            label="年金"
            name="pl.pension"
            v-model.number="pl.pension"
          >
          </z-input>
          <z-input
            type="number"
            label="その他"
            name="pl.others"
            v-model.number="pl.others"
          >
          </z-input>
        </div>
        <div class="column is-6">
          <div class="content">
            <h2>スコア</h2>
            <p>
              {{ scoreFromPL }}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <b-field label="純資産">
        <b-input v-model.number="netAssets"></b-input>
      </b-field>
      <b-field label="収入">
        <b-input v-model.number="income"></b-input>
      </b-field>
      <hr />
      スコア:{{ score }}({{ rank }})<br />
      スコアBS:{{ scoreOfBS }}({{ rankOfBS }})<br />
      スコアPL:{{ scoreOfPL }}({{ rankOfPL }})<br />
      <hr />
      S(1)：800～990(190)<br />
      A(2)：600～799(200)<br />
      B(3)：450～599(150)<br />
      C(4)：～449<br />
      <hr />
      <div class="columns">
        <div class="column is-6">
          BS
          <table class="table is-bordered is-striped">
            <thead>
              <tr>
                <th>金額（万円）</th>
                <th>スコア</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(rec, idx) in netAssetsList">
                <tr :key="idx">
                  <td>
                    {{ rec }}
                  </td>
                  <td>
                    {{ calcScoreOfBS(rec * 10000) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="column is-6">
          PL
          <table class="table is-bordered is-striped">
            <thead>
              <tr>
                <th>金額（万円）</th>
                <th>スコア</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(rec, idx) in incomeList">
                <tr :key="idx">
                  <td>
                    {{ rec }}
                  </td>
                  <td>
                    {{ calcScoreOfPL(rec * 10000) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-realestate",
  data: function () {
    return {
      isLoading: true,
      pl: null,
      bufbs: null,
      netAssetsList: [0, 500, 1000, 5000, 6000, 10000, 20000, 50000, 100000],
      incomeList: [0, 1000, 1500, 2000, 5000, 8000, 10000, 20000],
      netAssets: 2000 * 10000,
      income: 3000 * 10000,
    };
  },
  methods: {
    calcRank: function (score) {
      return this.$$investor$__calcRank(score);
    },
    calcScoreOfBS: function (netAssets) {
      return this.$$investor$__calcScoreOfBS(netAssets);
    },
    calcScoreOfPL: function (income) {
      return this.$$investor$__calcScoreOfPL(income);
    },
  },
  computed: {
    bs: function () {
      const bs = this.$$cloneDeep(this.bufbs);
      bs.assets.sum =
        bs.assets.cash.sum +
        bs.assets.security.sum +
        bs.assets.insurance.sum +
        bs.assets.realEstate.sum;
      bs.netAssets.sum = bs.assets.sum - bs.liabilities.sum;
      return bs;
    },
    scoreFromBS: function () {
      return this.$$investor$calcScoreOfBS(this.bs);
    },
    scoreFromPL: function () {
      return this.$$investor$calcScoreOfPL(this.pl);
    },
    rank: function () {
      return this.calcRank(this.score);
    },
    rankOfPL: function () {
      return this.calcRank(this.scoreOfPL);
    },
    rankOfBS: function () {
      return this.calcRank(this.scoreOfBS);
    },
    score: function () {
      return this.$$investor$__calcScore(this.scoreOfBS, this.scoreOfPL);
    },
    scoreOfPL: function () {
      return this.calcScoreOfPL(this.income);
    },
    scoreOfBS: function () {
      return this.calcScoreOfBS(this.netAssets);
    },
  },
  mounted() {
    this.bufbs = this.$$investor$getNewBS();
    this.bufbs.assets.cash.sum = 500 * 10000;
    this.bufbs.assets.security.sum = 600 * 10000;
    this.bufbs.assets.insurance.sum = 700 * 10000;
    this.bufbs.assets.realEstate.sum = 800 * 10000;
    this.bufbs.liabilities.sum = 900 * 10000;

    this.pl = this.$$investor$getNewPL();

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
