<template>
  <div class="box">
    <h1>お知らせ</h1>
    <z-input
      label="タイトル"
      v-model="information.data.title"
      maxlength="200"
      isRequired
      :validate="'required'"
    />
    <z-input
      label="メッセージ"
      v-model="information.data.message"
      maxlength="2000"
      type="textarea"
      isRequired
      :validate="'required'"
    />
    <b-button
      :disabled="$$validator$invalid"
      @click="onSendInformation"
      class="button z-button is-primary is-rounded is-small is-wide"
    >
      送信
    </b-button>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
// import examination from "@/views/admin/components/InvestorExamination";

export default {
  name: "admin-setting-infromation",
  //components: {
  //  examination: examination
  //},
  props: {
    uid: {
      type: [String],
      default: null,
    },
  },
  data: function () {
    return {
      information: this.$$information$getNewInformation(),
      // isLoading: true,
      // investor: null
    };
  },
  //computed: {
  //  uid() {
  //    return this.$route.params.uid;
  //  }
  //},
  methods: {
    async onSendInformation() {
      const loadingComponent = this.$loading.open();
      try {
        this.information.data.to = this.uid;
        // console.log(this.information);
        await this.$$information$send(this.auth.uid, this.information);
        this.$$toast$openSuccess("お知らせを送信しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("お知らせの送信に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
