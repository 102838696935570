<template>
  <div>
    <router-view></router-view>
    <hr />
    <section class="section">
      <ul>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.message.proposal" type="is-info" />
            <span class="menu__checkBox__label">提案オファー</span>
          </div>
        </li>
        <li class="exclusive">
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.message.exclusive" type="is-info" />
            <span class="menu__checkBox__label">エクスクルーシブ</span>
          </div>
        </li>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.message.buying" type="is-info" />
            <span class="menu__checkBox__label">売却オファー</span>
          </div>
        </li>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.message.contact" type="is-info" />
            <span class="menu__checkBox__label">問い合わせ</span>
          </div>
        </li>
      </ul>
      <hr />
      <div class="menu__select">
        <z-select v-model="filter.score.from" :options="scoreFrom" />
        <z-select v-model="filter.score.to" :options="scoreTo" />
      </div>
      <ul>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.status.unRead" type="is-info" />
            <span class="menu__checkBox__label">未読</span>
          </div>
        </li>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.status.asRead" type="is-info" />
            <span class="menu__checkBox__label">既読</span>
          </div>
        </li>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.status.unanswered" type="is-info" />
            <span class="menu__checkBox__label">未返信</span>
          </div>
        </li>
        <li>
          <div class="content menu__checkBox">
            <b-checkbox v-model="filter.status.withReply" type="is-info" />
            <span class="menu__checkBox__label">返信あり</span>
          </div>
        </li>
      </ul>
      <hr />
      {{ filter }}
      <hr />
      <button @click="onClickTEST" class="button is-primary">検索</button>
      <hr />
      {{ total }}
      <hr />
      <template v-for="m in messages">
        <div :key="m._id">
          <p>
            id: {{ m._source.id }}<br />
            asReads: {{ m._source.data.asReads }}<br />
            latestFrom:{{ m._source.data.latestFrom }}<br />
            type: {{ m._source.data.type }}<br />
            investor: {{ m._source.data.investor }}<br />
            threds: {{ m._source.data.threads.map((x) => x.from) }}<br />
            score: {{ m._source.investor.user.data.latestScore }}<br />
          </p>
          <hr />
        </div>
      </template>
      <ZPagination :total="total" :perPage="perPage" :page.sync="page" />
      <hr />
      <!--
        <b-tabs :animated="false" size="is-small" v-model="activeTab">
        <b-tab-item label="publicデータ">
        TEST
        </button>
        </b-tab-item>
        </b-tabs>
      -->
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
// import CitySelect from "@/components/parts/CitySelect";
// import * as investor from "@/modules/investor/investor";
// import * as realestate from "@/modules/investor/realestate";
import * as message from "@/modules/message/message";
// import * as realtorRealestate from "@/modules/realtor/realestate";
// import vueJsonEditor from 'vue-json-editor'
// import JsonEditor from 'vue-json-ui-editor';
import kubuns from "@/kubuns/kubuns";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

export default {
  name: "admin-demo-message",
  data: function () {
    return {
      total: 0,
      page: 1,
      perPage: 50,
      sortType: 1,
      filter: {
        message: {
          proposal: false,
          exclusive: false,
          buying: false,
          contact: false,
        },
        score: {
          from: 0,
          to: 1000,
          // to: 990
        },
        status: {
          unRead: false,
          asRead: false,
          unanswered: false,
          withReply: false,
        },
      },
      messages: [],
      searchFilter: null,
      // activeTab: 0,
      // isLoading: true,
      // total: null,
      // investors: [],
      // index: null
      scoreFrom: kubuns.filters.score.from,
      scoreTo: kubuns.filters.score.to,
    };
  },
  watch: {
    async page() {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      const rs = await message.searchByfilter(
        {
          ...this.filter,
          realtor: "SJ2iKfmVwBPA9WrLwd2NYusdZmE2",
        },
        1,
        this.page,
        this.perPage,
        "SJ2iKfmVwBPA9WrLwd2NYusdZmE2"
      );
      this.messages = rs.hits;
      this.total = rs.total.value;
    },
    async onClickTEST() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
