<template>
  <div>
    <z-select2
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :hideRequired="hideRequired"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
    >
    </z-select2>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZBankSelect",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      options: [],
    };
  },
  props: {
    value: {
      type: [String],
      default: null,
    },
  },
  created: async function () {
    try {
      this.loading = true;
      this.options = await this.$$bank$getBanks();
      //this.cities = [];
      //const val = this.prefecture;
      //if (val) {
      //  this.cities = await this.$$city$loads(val);
      //}
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
