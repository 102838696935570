<template>
  <div class="editWorkExperience">
    <div v-if="!isLoading">
      <section class="edit">
        <p class="edit__explanation">
          買いニーズ情報（提案を受けたい物件の希望条件）をご登録してください。
          <br />
          <span class="edit__explanation_red">
            ※
            買いニーズ情報を登録すると、より多くの提案を受けることができ、より希望に沿った物件が提案されます。
          </span>
        </p>

        <h2 class="edit__title">買いニーズ情報入力</h2>
        <FormBuyingNeeds v-model="eUser" />

        <div class="edit__foot">
          <b-button
            :disabled="$$validator$invalid"
            @click="onSubmit()"
            type="is-primary"
            rounded
            class="z-button"
            >保存する</b-button
          >
        </div>
      </section>
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import FormBuyingNeeds from "./FormBuyingNeeds";

export default {
  name: "EditBuyingneeds",
  // props: {
  // },
  data() {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    FormBuyingNeeds: FormBuyingNeeds,
  },
  // computed: {
  // },
  methods: {
    onSubmit() {
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
        this.$emit("saved");
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.edit__explanation_red {
  color: $danger;
}
</style>
