//import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";
const type = {};

type.doc = {
  id: null,
  name: null,
  type: null,
  path: null,
  description: null,
};

export default {
  type,
};
