<template>
  <button class="ZFavoriteRealtorButton" @click="onClick">
    <div class="ZFavoriteRealtorButton__label">
      <p>評価</p>
    </div>
    <div class="ZFavoriteRealtorButton__image">
      <img src="@/assets/favorite/estimate.png" />
    </div>
  </button>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZFavoriteRealtorButton",
  mixins: [InputMixin],
  props: {},
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.ZFavoriteRealtorButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 90px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  cursor: pointer;
  &__label {
    > p {
      padding-left: 1px;
      font-size: 15px;
      color: #777777;
      letter-spacing: 0;
      text-align: left;
      line-height: 15px;
    }
  }
  &__image {
    margin-top: 6px;
    margin-left: 9px;
    > img {
      width: 23px;
      height: 22px;
    }
  }
}
</style>
