<template>
  <div class="AssetsInfoRealEstateDetail">
    <div class="AssetsInfoRealEstateDetail__titleArea">
      <p class="AssetsInfoRealEstateDetail__titleArea__title">{{ title }}</p>
      <p class="AssetsInfoRealEstateDetail__titleArea__amount">
        ¥ {{ amount | addComma }}
      </p>
    </div>
    <div class="AssetsInfoRealEstateDetail__estimatedIncomeArea">
      <p class="AssetsInfoRealEstateDetail__estimatedIncomeArea__title">
        年間想定収入
      </p>
      <p class="AssetsInfoRealEstateDetail__estimatedIncomeArea__amount">
        ¥ {{ income | addComma }}
      </p>
    </div>
    <div class="AssetsInfoRealEstateDetail__repaymentArea">
      <p class="AssetsInfoRealEstateDetail__repaymentArea__title">
        年返済額(利息・元本)
      </p>
      <p class="AssetsInfoRealEstateDetail__repaymentArea__amount">
        ¥ <span v-if="payment !== 0">-</span>{{ payment | addComma }}
      </p>
    </div>
    <div class="AssetsInfoRealEstateDetail__descriptionArea">
      <p class="AssetsInfoRealEstateDetail__descriptionArea__address">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetsInfoRealEstateDetail",
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    income: {
      type: Number,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  filters: {
    addComma: function (val) {
      return val.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.AssetsInfoRealEstateDetail {
  color: #777777;
  padding-top: 14px;
  &__titleArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    &__title {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      margin: 5px 0 4px 0;
    }
    &__amount {
      margin-left: auto;
      white-space: nowrap;
      font-size: 16px;
      line-height: 24px;
      padding-right: 18px;
    }
  }
  &__estimatedIncomeArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    &__title {
      font-size: 12px;
      line-height: 12px;
      margin: 5px 0 4px 0;
    }
    &__amount {
      margin-left: auto;
      white-space: nowrap;
      font-size: 14px;
      line-height: 22px;
      padding-right: 18px;
    }
  }
  &__repaymentArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    border-bottom: solid 1px;
    border-color: #dddddd;
    padding-bottom: 6px;
    margin-bottom: 4px;
    &__title {
      font-size: 12px;
      line-height: 12px;
      margin: 5px 0 4px 0;
    }
    &__amount {
      margin-left: auto;
      white-space: nowrap;
      color: #092550;
      font-size: 14px;
      line-height: 22px;
      padding-right: 18px;
    }
  }
  &__descriptionArea {
    line-height: 16px;
    font-size: 12px;
  }
}
</style>
