<template>
  <transition name="fade">
    <div v-if="isActive" class="z-loading">
      <lottie
        class="z-loading__svg"
        :options="defaultOptions"
        :height="200"
        :width="200"
        :loop="true"
        :autoplay="true"
        @animCreated="handleAnimation"
      />
    </div>
  </transition>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/loading/Stark.json";
// import * as animationData from '@/assets/loading/loading.json';

export default {
  name: "ZLoading",
  components: {
    Lottie: Lottie,
  },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    // stop: function () {
    //   this.anim.stop();
    // },
    // play: function () {
    //   this.anim.play();
    // },
    // pause: function () {
    //   this.anim.pause();
    // },
    // onSpeedChange: function () {
    //   this.anim.setSpeed(this.animationSpeed);
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.7);
  z-index: $zindex-loading;
  &__svg {
    position: absolute;
    top: 50%;
    left: calc(50% - 16px); // svg内のズレを調整
    transform: translate(-50%, -50%);
  }
}
</style>
