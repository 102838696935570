<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <z-user-select :users="users" v-model="uid"> </z-user-select>
      <hr />
      {{ uid }}
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-userScore",
  data: function () {
    return {
      isLoading: true,
      uid: null,
      users: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    this.users = await this.$$investor$loadUsers();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
