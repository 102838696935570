<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep, isNil } from "lodash";
// import realEstateType from "@/types/realEstateType";
// import kubuns from "@/kubuns/kubuns";

//const type = realEstateType.type;

// const isRangeSp = function(target, start, end, min = 0, max = 10000000000) {
//   const st = isNil(start) ? min : start;
//   const ed = isNil(end) ? max : end;
//   if (st <= target && target < ed) {
//     return true;
//   } else {
//     return false;
//   }
// };

// const couponYieldRateDivs = [
//   {
//     from: 0,
//     to: 0.03
//   },
//   {
//     from: 0.03,
//     to: 0.04
//   },
//   {
//     from: 0.04,
//     to: 0.05
//   },
//   {
//     from: 0.05,
//     to: 0.06
//   },
//   {
//     from: 0.06,
//     to: 0.07
//   },
//   {
//     from: 0.07,
//     to: 0.08
//   },
//   {
//     from: 0.08,
//     to: 0.09
//   },
//   {
//     from: 0.09,
//     to: 0.1
//   },
//   {
//     from: 0.1,
//     to: null
//   }
// ];
export default {
  name: "Mixins-market-chart",
  methods: {
    async $$MarketChart$getMarketTrendHeatMap() {
      const marketTrendHeatMap = firebase.db
        .doc("market_chart_v1/main")
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data().data;
          } else {
            return false;
          }
        });
      return marketTrendHeatMap;

      // const rets = [];

      // for (const ty of kubuns.marketTrendTypes) {
      //   const bt = {
      //     buildingType: ty.value,
      //     countsOfbuildingType: 0,
      //     countsOfYield: []
      //   };
      //   for (const div of couponYieldRateDivs) {
      //     bt.countsOfYield.push({
      //       couponYieldRate: cloneDeep(div),
      //       count: 0
      //     });
      //   }
      //   rets.push(bt);
      // }

      // const res = await firebase.db
      //   .collection("realEstates_v1")
      //   .where("isActivate", "==", true)
      //   .get()
      //   .then(querySnapshot => {
      //     const ls = [];
      //     querySnapshot.forEach(doc => {
      //       ls.push({
      //         id: doc.id,
      //         data: doc.data()
      //       });
      //     });
      //     return ls;
      //   });

      // for (const re of res) {
      //   let bt = null;
      //   if (re.data.realEstateType === 1) {
      //     // value: 1, text: "マンション・アパート" },
      //     bt = rets.find(r => r.buildingType === re.data.buildingType);
      //   } else if (re.data.realEstateType === 8) {
      //     //{ value: 8, text: "区分マンション" },
      //     bt = rets.find(r => r.buildingType === 5);
      //   }
      //   if (bt) {
      //     bt.countsOfbuildingType = bt.countsOfbuildingType + 1;
      //     for (const div of bt.countsOfYield) {
      //       if (
      //         isRangeSp(
      //           re.data.couponYieldRate,
      //           div.couponYieldRate.from,
      //           div.couponYieldRate.to
      //         )
      //       ) {
      //         div.count = div.count + 1;
      //       }
      //     }
      //   }
      // }
      // console.log(rets);
      // return rets;

      //for
      //kubuns.marketTrendTypes = [
      //  { value: 1, text: "RC・SRC" },
      //  { value: 2, text: "鉄骨" },
      //  { value: 3, text: "軽量鉄骨" },
      //  { value: 4, text: "木造" },
      //  { value: 5, text: "区分所有" }
      //];

      //const ms = [];
      //for (const bt of kubuns.typeOfBuildingTypes) {
      //  const bts = {
      //    buildingType: bt.value,
      //    countsOfbuildingType: 0,
      //    countsOfYield: []
      //  };
      //  for (const r of rs) {
      //    if (r.data.buildingType === bt.value) {
      //      bts.countsOfbuildingType = bts.countsOfbuildingType + 1;
      //    }
      //  }
      //  for (const div of couponYieldRateDivs) {
      //    let countOfYe = 0;
      //    for (const r of rs) {
      //      if (r.data.buildingType === bt.value) {
      //        if (r.data.couponYieldRate) {
      //          if (
      //            div.from <= r.data.couponYieldRate &&
      //            r.data.couponYieldRate <= div.to
      //          ) {
      //            countOfYe = countOfYe + 1;
      //          }
      //        }
      //      }
      //    }
      //    bts.countsOfYield.push({
      //      couponYieldRate: div,
      //      count: countOfYe
      //    });
      //  }
      //  ms.push(bts);
      //}
      //return ms;
      //return [
      //  {
      //    buildingType: 1,
      //    countsOfbuildingType: 315,
      //    countsOfYield: [
      //      {
      //        couponYieldRate: {
      //          from: 0,
      //          to: 0.03
      //        },
      //        count: 5
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.03,
      //          to: 0.04
      //        },
      //        count: 80
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.04,
      //          to: 0.05
      //        },
      //        count: 3
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.05,
      //          to: 0.06
      //        },
      //        count: 13
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.06,
      //          to: 0.07
      //        },
      //        count: 60
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.07,
      //          to: 0.08
      //        },
      //        count: 4
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.08,
      //          to: 0.09
      //        },
      //        count: 0
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.09,
      //          to: 0.1
      //        },
      //        count: 20
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.1,
      //          to: null
      //        },
      //        count: 40
      //      }
      //    ]
      //  },
      //  {
      //    buildingType: 2,
      //    countsOfbuildingType: 240,
      //    countsOfYield: [
      //      {
      //        couponYieldRate: {
      //          from: 0,
      //          to: 0.03
      //        },
      //        count: 5
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.03,
      //          to: 0.04
      //        },
      //        count: 40
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.04,
      //          to: 0.05
      //        },
      //        count: 3
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.05,
      //          to: 0.06
      //        },
      //        count: 35
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.06,
      //          to: 0.07
      //        },
      //        count: 6
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.07,
      //          to: 0.08
      //        },
      //        count: 4
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.08,
      //          to: 0.09
      //        },
      //        count: 0
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.09,
      //          to: 0.1
      //        },
      //        count: 2
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.1,
      //          to: null
      //        },
      //        count: 4
      //      }
      //    ]
      //  },
      //  {
      //    buildingType: 3,
      //    countsOfbuildingType: 215,
      //    countsOfYield: [
      //      {
      //        couponYieldRate: {
      //          from: 0,
      //          to: 0.03
      //        },
      //        count: 5
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.03,
      //          to: 0.04
      //        },
      //        count: 4
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.04,
      //          to: 0.05
      //        },
      //        count: 0
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.05,
      //          to: 0.06
      //        },
      //        count: 3
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.06,
      //          to: 0.07
      //        },
      //        count: 60
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.07,
      //          to: 0.08
      //        },
      //        count: 40
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.08,
      //          to: 0.09
      //        },
      //        count: 0
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.09,
      //          to: 0.1
      //        },
      //        count: 2
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.1,
      //          to: null
      //        },
      //        count: 40
      //      }
      //    ]
      //  },
      //  {
      //    buildingType: 4,
      //    countsOfbuildingType: 300,
      //    countsOfYield: [
      //      {
      //        couponYieldRate: {
      //          from: 0,
      //          to: 0.03
      //        },
      //        count: 50
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.03,
      //          to: 0.04
      //        },
      //        count: 4
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.04,
      //          to: 0.05
      //        },
      //        count: 30
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.05,
      //          to: 0.06
      //        },
      //        count: 35
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.06,
      //          to: 0.07
      //        },
      //        count: 6
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.07,
      //          to: 0.08
      //        },
      //        count: 4
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.08,
      //          to: 0.09
      //        },
      //        count: 0
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.09,
      //          to: 0.1
      //        },
      //        count: 2
      //      },
      //      {
      //        couponYieldRate: {
      //          from: 0.1,
      //          to: null
      //        },
      //        count: 40
      //      }
      //    ]
      //  }
      //];
    },
    //async $$MarketChart$getScoreStandardDeviation() {
    //  return [];
    //}
  },
};
</script>
