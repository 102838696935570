<script>
import firebase from "@/plugins/firebase";
import transactionType from "@/types/transactionType";

const type = transactionType.type;

// const querys = function(email, query) {
//   const qus = Object.assign({ email: email }, query);
//   return Object.keys(qus)
//     .map(k => k + "=" + qus[k])
//     .reduce((qs, q) => qs + "&" + q, "?q=1");
// };

export default {
  name: "Mixins-transaction",
  methods: {
    $$transaction$getNewLayout: function () {
      return this.$$cloneDeep(type.layout);
    },
    $$transaction$getNewTransaction: function () {
      return this.$$cloneDeep(type.transaction);
    },
    $$transaction$getNewRealtorTransaction(uid) {
      const data = this.$$cloneDeep(type.realtorTransaction);
      data.realtor = uid;
      data.link = this.$uuid.v4();
      return {
        id: null,
        data: data,
      };
    },
    async $$transaction$buildLink() {
      return;
    },
    async $$transaction$createTransaction(uid, transaction) {
      const now = firebase.serverTimestamp();
      transaction.data.investor = uid;
      transaction.data.createdBy = uid;
      transaction.data.createdDt = now;
      transaction.data.updatedBy = uid;
      transaction.data.updatedDt = now;
      transaction.data.registerDt = now;

      await firebase.db.collection("transaction_v1").add(transaction.data);
    },
    async $$transaction$saveRealtorTransaction(uid, transaction) {
      const now = firebase.serverTimestamp();
      if (!transaction.id) {
        transaction.data.createdBy = uid;
        transaction.data.createdDt = now;
        transaction.data.updatedBy = uid;
        transaction.data.updatedDt = now;
        transaction.data.registerDt = now;

        const ret = await firebase.db
          .collection("realtor_transaction_v1")
          .add(transaction.data)
          .then((docRef) => {
            return {
              id: docRef.id,
              data: transaction.data,
            };
          });
        return ret;
      } else {
        transaction.data.updatedBy = uid;
        transaction.data.updatedDt = now;

        const ret = await firebase.db
          .collection("realtor_transaction_v1")
          .doc(transaction.id)
          .set(transaction.data, { merge: true })
          .then(() => {
            return transaction;
          });
        return ret;
      }
    },
    async $$transaction$loadRealtorTransactionByLink(link) {
      const ret = await firebase.db
        .collection("realtor_transaction_v1")
        .where("link", "==", link)
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realtorTransaction, doc.data()),
            });
          });
          return ls;
        });
      return ret[0];
    },
    async $$transaction$loadTransactionsByRealtor(uid) {
      const ret = await firebase.db
        .collection("realtor_transaction_v1")
        .where("realtor", "==", uid)
        .orderBy("registerDt", "desc")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push({
              id: doc.id,
              data: this.$$assign(type.realtorTransaction, doc.data()),
            });
          });
          return ls;
        });
      return ret;
    },
    async $$transaction$loadTransaction(id) {
      const doc = await firebase.db
        .collection("realtor_transaction_v1")
        .doc(id)
        .get();

      if (doc.exists) {
        const transaction = {
          id: doc.id,
          data: this.$$assign(type.realtorTransaction, doc.data()),
        };
        transaction.realtor = await this.$$realtor$loadUser(
          transaction.data.realtor
        );

        if (transaction.data.investor) {
          transaction.investor = await this.$$investor$loadUser(
            transaction.data.investor
          );
        }

        return transaction;
      } else {
        return null;
      }
    },
    async $$transaction$loadTransactions() {
      const transactions = await firebase.db
        .collection("realtor_transaction_v1")
        .orderBy("statusOfAccounting", "asc")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push(this.$$transaction$loadTransaction(doc.id));
          });
          return Promise.all(ls);
        });
      return transactions;
    },
    async $$transaction$loadTransactionWithCompany(id) {
      const doc = await firebase.db.collection("transaction_v1").doc(id).get();
      if (doc.exists) {
        const transaction = {
          id: doc.id,
          data: doc.data(),
        };
        transaction.company = await this.$$realtor$loadCompany(
          transaction.data.companyId
        );
        transaction.staff = await this.$$realtor$loadUser(
          transaction.data.staffId
        );
        transaction.investor = await this.$$investor$loadUser(
          transaction.data.investor
        );

        return transaction;
      } else {
        return null;
      }
    },
    async $$transaction$loadTransactionsWithCompany() {
      const transactions = await firebase.db
        .collection("transaction_v1")
        .get()
        .then((querySnapshot) => {
          const ls = [];
          querySnapshot.forEach((doc) => {
            ls.push(this.$$transaction$loadTransactionWithCompany(doc.id));
          });
          return Promise.all(ls);
        });
      return transactions;
    },
    // async $$transaction$loadTransactionsWithCompany() {
    //   const transactions = await firebase.db
    //     .collection("transaction_v1")
    //     .get()
    //     .then(querySnapshot => {
    //       const ls = [];
    //       querySnapshot.forEach(async doc => {
    //         ls.push({
    //           id: doc.id,
    //           data: this.$$assign(type.transaction, doc.data()),
    //           company: await this.$$realtor$loadCompany(doc.data().companyId),
    //           staff: await this.$$realtor$loadUser(doc.data().staffId)
    //         });
    //       });
    //       return ls;
    //     });
    //   return transactions;
    // }
  },
};
</script>
