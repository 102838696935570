<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <b-field label="返済方法 1:元利均等 2:元金均等">
        <b-input v-model.number="loan1.method"></b-input>
      </b-field>
      <b-field label="融資金額">
        <b-input v-model.number="loan1.amount"></b-input>
      </b-field>
      <b-field label="返済期間(年)">
        <b-input v-model.number="loan1.paymentPeriods.year"></b-input>
      </b-field>
      <b-field label="返済期間(月)">
        <b-input v-model.number="loan1.paymentPeriods.month"></b-input>
      </b-field>
      <b-field label="金利(年)">
        <b-input v-model.number="loan1.interestRate"></b-input>
      </b-field>
      <b-field label="融資実行年月(年)">
        <b-input v-model.number="loan1.executeDate.year"></b-input>
      </b-field>
      <b-field label="融資実行年月(月)">
        <b-input v-model.number="loan1.executeDate.month"></b-input>
      </b-field>
      <hr />
      月利:{{ loan1Table.interestRatePerMonth }}<br />
      毎月の返済額:{{ loan1Table.repaymentAmountOfMonth }}<br />
      年返済額（利息）:{{ amountInterestRepaidPerYear }}<br />
      年返済額（元本）: {{ amountPrincipalRepaidPerYear }}<br />
      残債：{{ outstandingDebt }}<br />
      <table class="table is-bordered is-striped">
        <thead>
          <tr>
            <th>回</th>
            <th>年</th>
            <th>月</th>
            <th>元本</th>
            <th>利息</th>
            <th>返済額</th>
            <th>借入残額</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(rec, idx) in loan1Table.table">
            <tr :key="idx">
              <td>
                {{ idx + 1 }}
              </td>
              <td>
                {{ rec.year }}
              </td>
              <td>
                {{ rec.month }}
              </td>
              <td>
                {{ rec.amount }}
              </td>
              <td>
                {{ rec.interest }}
              </td>
              <td>
                {{ rec.repaymentAmount }}
              </td>
              <td>
                {{ rec.lastAmonut }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <hr />
      {{ loan1Table }}
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-realestate",
  data: function () {
    return {
      loan1: {
        bankId: "", // 金融機関・支店名 金融機関のID(String)
        amount: 20000000, // 融資金額
        executeDate: {
          year: 2019,
          month: 6,
        }, // 融資実行年月
        paymentPeriods: {
          year: 20,
          month: 10,
        }, // 返済期間(月）
        interestRate: 0.07, // 金利
        method: 1, // 返済方法 1:元利均等 2:元金均等
      },
    };
  },
  computed: {
    loan1Table: function () {
      return this.$$realEstate$calcRepaymentAmonts(this.loan1);
    },
    amountInterestRepaidPerYear: function () {
      return this.$$realEstate$calcAmountInterestRepaidPerYear(this.loan1Table);
    },
    amountPrincipalRepaidPerYear: function () {
      return this.$$realEstate$calcAmountPrincipalRepaidPerYear(
        this.loan1Table
      );
    },
    outstandingDebt: function () {
      return this.$$realEstate$calcOutstandingDebt(this.loan1Table);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
