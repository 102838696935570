<template>
  <div class="realtor-favorite-card">
    <div
      class="realtor-favorite-card__content"
      :class="{ 'realtor-favorite-card--fa999': favorite.data.type === 999 }"
    >
      <div class="realtor-favorite-card__inner" @click="onClickRealtor">
        <div class="realtor-favorite-card__base">
          <div class="realtor-favorite-card__base__image">
            <div
              class="realtor-favorite-card__base__image__mask"
              :class="{
                'realtor-favorite-card__base__image__mask--fa1':
                  favorite.data.type === 1,
              }"
            />
            <z-profile-thumbnail
              class="realtor-favorite-card__base__image__img"
              :class="{
                'realtor-favorite-card__base__image__img--fa1':
                  favorite.data.type === 1,
              }"
              v-model="realtor.data.profile.image"
            />
            <div
              class="realtor-favorite-card__base__image__star"
              :class="{
                'realtor-favorite-card__base__image__star--fa1':
                  favorite.data.type === 1,
              }"
            >
              <img src="@/assets/favorite/fa_star.png" />
            </div>
          </div>
          <div class="realtor-favorite-card__base__content">
            <div class="realtor-favorite-card__base__content__name">
              <p>
                <z-truncator :line="1" :text="realtor.data.base.name" />
              </p>
            </div>
            <div class="realtor-favorite-card__base__content__cname">
              <p>
                <z-truncator :line="2" :text="realtor.company.data.name" />
              </p>
            </div>
          </div>
        </div>
        <hr
          class="realtor-favorite-card__hr"
          :class="{
            'realtor-favorite-card__hr--fa999': favorite.data.type === 999,
          }"
        />
        <div class="realtor-favorite-card__profile">
          <div class="realtor-favorite-card__profile__ex">
            <div class="realtor-favorite-card__profile__ex__title">
              <p>不動産経験:</p>
            </div>
            <div class="realtor-favorite-card__profile__ex__content">
              <p>{{ yearOfexperience }}</p>
            </div>
          </div>
          <div class="realtor-favorite-card__profile__spec">
            <div class="realtor-favorite-card__profile__spec__title">
              <p>得意領域:</p>
            </div>
            <div class="realtor-favorite-card__profile__spec__content">
              <p>
                <z-truncator :line="2" :text="specialties" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="realtor-favorite-card__favorite"
        :class="{
          'realtor-favorite-card__favorite--fa999': favorite.data.type === 999,
        }"
      >
        <div class="realtor-favorite-card__favorite__title">
          <p>担当者を<br class="sp-none" />評価する</p>
        </div>
        <div
          class="realtor-favorite-card__favorite__fa"
          :class="{
            'realtor-favorite-card__favorite__fa--activate':
              favorite.data.type === 1,
          }"
        >
          <RealtorFavoriteButton
            @click="onClickFa(1)"
            :favoriteType="1"
            :favorite="favorite.data.type"
          />
          <!--
          <template v-if="favorite.data.type === 1">
            <img src="@/assets/favorite/fa1_at.png" />
          </template>
          <template v-else>
            <img @click="onClickFa(1)" src="@/assets/favorite/fa1_no.png" />
          </template>
          -->
          <p>優先提案</p>
        </div>
        <div
          class="realtor-favorite-card__favorite__fa"
          :class="{
            'realtor-favorite-card__favorite__fa--activate':
              favorite.data.type === 2,
          }"
        >
          <RealtorFavoriteButton
            @click="onClickFa(2)"
            :favoriteType="2"
            :favorite="favorite.data.type"
          />
          <!--
          <template v-if="favorite.data.type === 2">
            <img src="@/assets/favorite/fa2_at.png" />
          </template>
          <template v-else>
            <img @click="onClickFa(2)" src="@/assets/favorite/fa2_no.png" />
          </template>
          -->
          <p>継続</p>
        </div>
        <div class="realtor-favorite-card__favorite__fa">
          <RealtorFavoriteButton
            @click="onClickFa(999)"
            :favoriteType="999"
            :favorite="favorite.data.type"
          />
          <!--
          <template v-if="favorite.data.type === 999">
            <img src="@/assets/favorite/fa999_at.png" />
          </template>
          <template v-else>
            <img @click="onClickFa(999)" src="@/assets/favorite/fa999_no.png" />
          </template>
          -->
          <p>拒否</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import RealestateOverview from "@/components/realestate/RealestateOverview";
// import RealestateDetail from "@/components/realestate/RealestateDetail";
// import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
// import EditRealEstateUnit from "@/views/investor/common/EditRealEstateUnit";
import * as favorite from "@/modules/investor/favorite";
import RealtorFavoriteButton from "@/components/investor/RealtorFavoriteButton";

export default {
  name: "realtor-favorite-card",
  props: {
    realtor: {
      type: Object,
      required: true,
    },
    favorite: {
      type: Object,
      required: true,
    },
  },
  components: {
    RealtorFavoriteButton,
    // RealestateOverview: RealestateOverview,
    // RealestateDetail: RealestateDetail,
    // TileBoxOverlay: TileBoxOverlay,
    // EditRealEstateUnit: EditRealEstateUnit
  },
  computed: {
    yearOfexperience() {
      return this.$$ks.realtor.getYearOfexperience(
        this.realtor.data.profile.yearOfexperience
      ).text;
      // return "5〜10年";
    },
    specialties() {
      // return "相続  ";
      let tx = "";
      for (const s of this.realtor.data.profile.specialties) {
        tx = tx + this.$$ks.realtor.getSpecialty(s).text + " ";
      }
      return tx;
      // return "相続  非公開案件  売却  融資先金融機関・提携ローン  マンション・アパート";
    },
  },
  methods: {
    onClickRealtor() {
      this.$router.push({
        name: "investor-search-realotrs-detail",
        params: { id: this.realtor.id },
      });
    },
    async onClickFa(ty) {
      // const loadingComponent = this.$loading.open();
      try {
        this.favorite.data.type = ty;
        const uid = this.auth.uid;
        await favorite.save(uid, uid, this.favorite);
        this.$$toast$openSuccess("担当者の評価を変更しました。");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("担当者の評価の変更に失敗しました。");
      } finally {
        // loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realtor-favorite-card {
  position: relative;
  width: 264px;
  height: 230px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 1px 10px 0 rgba(202, 202, 202, 0.57);
  }
  @include sp {
    width: 100%;
  }
  &__content {
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__hr {
    margin: 10px 15px -6px 15px;
    &--fa999 {
      background-color: #dddddd;
    }
  }

  &--fa999 {
    background-color: #dddddd;
  }
  &__base {
    position: relative;
    padding: 12px 12px 0px 12px;
    display: flex;
    flex-direction: row;
    &__image {
      width: 99px;
      padding-left: 9px;
      &__star {
        display: none;
        position: absolute;
        top: 15px;
        left: 15px;
        > img {
          width: 22px;
          height: 22px;
        }
        &--fa1 {
          display: block;
        }
      }
      &__mask {
        display: none;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        // border: solid 3px;
        background: linear-gradient(to right, #8fbdf6 0%, #37d2ff 100%);
        width: 68px;
        height: 68px;
        &--fa1 {
          display: block;
        }
      }
      &__img {
        // &--fa1 {
        //   padding: 3px;
        // }
        // margin: 2px;
        margin-left: 2px;
        width: 64px;
        height: 64px;
      }
    }
    &__content {
      &__name {
        > p {
          font-size: 18px;
          font-weight: bold;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      &__cname {
        > p {
          margin-top: 3px;
          font-size: 12px;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
  }
  &__profile {
    padding: 12px;
    &__ex {
      display: flex;
      flex-direction: row;
      margin-bottom: 4px;
      &__title {
        width: 64px;
        margin-right: 8px;
        > p {
          font-size: 12px;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
      &__content {
        flex: 1;
        > p {
          text-align: left;
          font-size: 12px;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
    &__spec {
      display: flex;
      flex-direction: row;
      &__title {
        width: 52px;
        margin-right: 8px;
        > p {
          font-size: 12px;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
      &__content {
        flex: 1;
        > p {
          font-size: 12px;
          color: #4a4a4a;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
  }
  &__favorite {
    position: absolute;
    padding: 0px 6px 0px 0px;
    display: flex;
    flex-direction: row;
    bottom: 0px;
    width: 100%;
    height: 73px;
    background-color: rgba(#dbdbdb, 0.2);
    &--fa999 {
      background-color: #dddddd;
    }
    &__title {
      width: 78px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      @include sp {
        width: 154px;
      }

      > p {
        font-size: 12px;
        font-weight: bold;
        color: #4a4a4a;
        letter-spacing: 0;
        line-height: 16.8px;
      }
    }
    &__fa {
      display: inline-flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      > img {
        cursor: pointer;
        width: 48px;
        height: auto;
      }
      > p {
        margin-top: -4px;
        font-size: 12px;
        font-weight: bold;
        color: #919191;
        letter-spacing: 0;
        text-align: center;
        line-height: 12px;
      }
      &--activate {
        > p {
          color: #00997e;
        }
      }
    }
  }
}

.sp-none {
  display: block;
  @include sp {
    display: none;
  }
}
</style>
