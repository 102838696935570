<template>
  <div class="rankAccordion">
    <div class="rankAccordion__head" @click="toggle()">
      <h4 class="rankAccordion__head__title">
        <slot name="head" />
      </h4>
      <a class="rankAccordion__head__icon">
        <b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'"> </b-icon>
      </a>
    </div>
    <transition>
      <div
        v-show="isOpen"
        class="rankAccordion__body"
        :class="isOpen ? 'is-close' : ''"
      >
        <template v-if="isOpen">
          <slot name="contents" />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "RankAccordion",
  inject: ["$validator"],
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: this.opened,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.rankAccordion {
  &__head {
    position: relative;
    padding-right: ($edit-padding + 20px);
    border-top: solid 2px #707070;
    background-color: rgba(#dddddd, 0.3);
    @include sp {
      margin-left: -$edit-padding-sp;
      margin-right: -$edit-padding-sp;
      padding-right: ($edit-padding-sp + 16px);
    }
    &__title {
      &__icon {
        margin-right: 2px;
        vertical-align: bottom;
        /deep/ .mdi:before {
          font-size: 1.3em;
        }
      }
    }
    &__icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #838383;
        &:hover {
          color: #838383;
        }
      }
    }
    &.is-protrude {
      margin-left: -($edit-padding);
      margin-right: -($edit-padding);
      padding: 12px ($edit-padding + 20px) 12px $edit-padding;
      background-color: rgba($primary, 0.06);
      border-bottom: none;
      font-size: 16px;
      color: $primary;
      cursor: pointer;
      @include sp {
        margin-left: -($edit-padding-sp);
        margin-right: -($edit-padding-sp);
        padding: 10px ($edit-padding-sp + 20px) 10px $edit-padding-sp;
      }
      .icon {
        color: $primary;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &__body {
    position: relative;
    padding-top: $edit-padding;
  }
}
</style>
