<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <b-tabs>
        <template v-for="template in templates">
          <b-tab-item :key="template.id" :label="template.data.label">
            <ZInput
              label="タイトル"
              maxlength="50"
              v-model="template.data.label"
              isRequired
            />
            <ZTextarea
              :rows="10"
              label="メッセージ"
              v-model="template.data.message"
              maxlength="2000"
            />
            <ZInput
              label="順番"
              isRequired
              v-model.number="template.data.ord"
            />
            <b-button
              class="z-button"
              type="is-primary"
              size="is-small"
              @click="onClickSave(template)"
              >保存</b-button
            >
            {{ template.id }}
          </b-tab-item>
        </template>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import * as mtemplate from "@/modules/message/messageTemplate";

export default {
  name: "realtor-setteing-messages-template",
  data: function () {
    return {
      templates: [],
    };
  },
  methods: {
    async onClickSave(template) {
      console.log(template);
      const loadingComponent = this.$loading.open();
      try {
        this.templates = await mtemplate.save(template);
        await this.init();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.templates = await mtemplate.loads();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.message-warp {
  margin: 0 15px;
}

.message {
  &__list {
    width: 100%;
    height: 756px;
  }
}

.message-detial {
  padding: 0;
  &__box {
    width: 100%;
    box-shadow: none;
    padding: 16px;
  }
}

.message-type {
  display: inline-block;
  width: 20%;
  text-align: right;
  @include md {
    width: 30%;
  }
}

.message-title-wrap {
  margin: 45px 0px 0px 0px;
  &__title {
    margin: 0px;
    display: inline-block;
    width: 80%;
    @include md {
      width: 70%;
    }
    &__message {
      margin-bottom: 0px;
    }
  }
  &__exclusive-label {
    width: 11%;
    display: inline-block;
    margin-right: 5px;
    @include md {
      margin-right: 15px;
    }
  }
  &__exclusive-title {
    width: 86%;
    display: inline-block;
    vertical-align: 4px;
  }
}

.real-estate-title {
  background-color: white;
  color: black;
  font-weight: bold;
  border-bottom: none;
  padding-bottom: 0px;
  padding: 11px 22px;
}

.message-title-separator {
  margin-top: 0px;
}

.box {
  &__realestate-list {
    padding-top: 0px;
    box-shadow: none;
  }
  &__price {
    margin-bottom: 0px;
    margin-top: 10px;
    .offer-price {
      font-size: 1.1rem !important;
    }
  }
}
</style>
