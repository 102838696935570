<template>
  <div class="tileBox">
    <div
      class="tileBox__titleSection"
      v-bind:class="{ withNoDetail: !withDetail }"
    >
      <div class="tileBox__titleSection__title">
        <slot name="title"></slot>
      </div>
      <template v-if="editable">
        <button
          v-if="isEditButtonExist"
          class="tileBox__titleSection__edit"
          @click="onOpenOverlay"
        >
          <b-icon
            class="tileBox__titleSection__edit__editBtn"
            icon="border-color"
            size="is-small"
          ></b-icon>
        </button>
      </template>
    </div>

    <tile-box-overlay
      v-if="$slots.overlay"
      v-on:handleOnClose="onClosePassFunction()"
      v-on:handleOnSubmit="onSubmitPassFunction()"
      :title="overlayTitle"
      :subTitle="overlaySubTitle"
      :submitBtnLabel="overlaySubmitBtnLabel"
      ref="overlay"
    >
      <template v-slot:OverlayReceive>
        <slot name="overlay"></slot>
      </template>
    </tile-box-overlay>

    <div class="tileBox__bodySection">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import TileBoxOverlay from "@/components/parts/TileBoxOverlay";

export default {
  name: "TileBox",
  components: {
    TileBoxOverlay,
  },
  data: function () {
    return {
      // isModalActive: false
    };
  },
  props: {
    overlayTitle: String,
    overlaySubTitle: String,
    overlaySubmitBtnLabel: String,
    editable: {
      type: [Boolean],
      default: true,
    },
    isEditButtonExist: {
      type: Boolean,
      default: true,
    },
    withDetail: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onOpenOverlay: function () {
      this.$emit("onOpenOverlay");
      if (this.$refs.overlay) this.$refs.overlay.open();
    },
    onClosePassFunction: function () {
      this.$emit("receiveOnClose");
      // this.isModalActive = false;
      console.log("closeclose");
    },
    onSubmitPassFunction: function () {
      this.$emit("receiveOnSubmit");
      console.log("submitsubmit");
      this.onClosePassFunction();
    },
    open() {
      if (this.$refs.overlay) this.$refs.overlay.open();
    },
    close() {
      if (this.$refs.overlay) this.$refs.overlay.close();
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.tileBox {
  border: 1px solid;
  border-color: #ddd;
  border-radius: 8px;
  &__titleSection {
    position: relative;
    line-height: 43px;
    background-color: #f4f4f4;
    border-radius: 8px 8px 0 0;
    &__title {
      width: 100%;
      padding-right: 34px;
    }
    &__edit {
      height: 100%;
      width: 34px;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      &__editBtn {
        width: 16px;
        height: 16px;
        color: #0262d8;
        font-size: 16px;
        display: inline-block;
        vertical-align: -5px;
      }
    }
  }
  .withNoDetail {
    border-radius: 8px 8px 8px 8px;
  }
}
</style>
