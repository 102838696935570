<template>
  <div class="realestate" v-if="!isLoading">
    <ZHeader title="Sign Up" sub="サインアップ" />
    <section class="edit">
      <p class="edit__explanation">
        保有不動産をご登録ください。<br />
        不動産をお持ちでない場合、もしくはサインアップ後に入力する場合は「入力をスキップする」を押下ください。
      </p>
      <PortfolioSteps :activeStep="3" />

      <section class="edit__skip">
        <router-link
          class="edit__skip__link"
          :to="{ name: 'investor-register-buyingneeds' }"
        >
          <span>入力をスキップする</span>
          <b-icon icon="chevron-double-right" />
        </router-link>
      </section>

      <h2 class="edit__title">
        保有不動産
        <!--
        <router-link
          class="edit__title__link"
          :to="{ name: 'investor-register-buyingneeds' }"
        >
          <span>スキップ</span>
          <b-icon icon="chevron-double-right" />
        </router-link>
        -->
      </h2>
      <FormRealEstates v-model="eUser" />

      <div class="edit__foot">
        <b-button @click="onPrev()" rounded class="z-button">戻る</b-button>
        <b-button
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioSteps from "./parts/PortfolioSteps";
import FormRealEstates from "../common/FormRealEstates";

export default {
  name: "Register-Realestate",
  data: function () {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    PortfolioSteps: PortfolioSteps,
    FormRealEstates: FormRealEstates,
  },
  methods: {
    onPrev: function () {
      this.$router.push({ name: "investor-register-assets" });
    },
    onSubmit: function () {
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();

        this.$router.push({ name: "investor-register-buyingneeds" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.register {
  &__foot {
    text-align: center;
    & > * {
      &:not(:first-child) {
        margin-left: 14px;
      }
    }
  }
}
</style>
