<script>
export default {
  name: "DisplayTextMixin",
  methods: {
    // モーデル表示モード
    $$selectRealEstates$modes() {
      return {
        investor: 0, // 絞り込み検索
        message: 1, // 添付不動産選択
      };
    },
    // 物件画像
    $$selectRealEstates$image(data) {
      if (data) {
        return []
          .concat(data.images.exteriors)
          .concat(data.images.interiors)
          .concat(data.images.others);
      } else {
        return [];
      }
    },
    // 金額と利回り
    $$selectRealEstates$priceCouponYieldRate(price, couponYieldRate) {
      let result = [];
      result.push(price ? this.$$price$priceJPFormat(price) : "-");
      result.push(
        couponYieldRate ? this.$$rete$rateFormat(couponYieldRate) : "-"
      );
      return result.join(" / ");
    },
    // 所在地
    $$selectRealEstates$location(prefecture, city) {
      let result = "-";
      if (!prefecture.text) return result;

      result = prefecture.text;
      result += city ? city.cityName : "";
      return result;
    },
    // 築年月
    $$selectRealEstates$constructionDate(constructionDate) {
      let result = "-";
      if (!constructionDate.year && !constructionDate.month) return result;

      result = constructionDate.year ? `${constructionDate.year}年` : "";
      result += constructionDate.month ? `${constructionDate.month}月` : "";
      result += `（築${this.$$realEstate$calcYearOfConstruction(
        constructionDate
      )}年）`;
      return result;
    },
    // 建物面積
    $$selectRealEstates$area(buildingArea) {
      return buildingArea ? `${this.$$num$commaFormat(buildingArea)}㎡` : "-";
    },
    // 物件種別
    $$selectRealEstates$realEstateType(realEstateType) {
      return realEstateType.text.length > 0 ? realEstateType.text : "-";
    },
    // 建物種別
    $$selectRealEstates$buildingType(buildingType) {
      return buildingType.text.length > 0 ? buildingType.text : "-";
    },
    // 件
    $$selectRealEstates$countMessage(message, key) {
      return message ? `${message[key].count}件` : "0件";
    },
    // 件
    $$selectRealEstates$formatDate(date, isConvertDate = true) {
      const convertDate = isConvertDate ? new Date(date) : date;
      return date ? this.$$calender$dateFormat(convertDate) : "-";
    },
    // 詳しくみる
    $$selectRealEstates$routerRealEstate(id) {
      const routeData = this.$router.resolve({
        name: "realtor-setting-realestates-preview",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
    // デフォルトで不動産がどうか
    $$selectRealEstates$isDefaultRealEstate(id) {
      if (this.mode !== this.$$selectRealEstates$modes().message) return false;
      return id === this.$route.query.defaultRealEstateId;
    },
  },
};
</script>
