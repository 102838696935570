<template>
  <div class="formIncome">
    <section class="edit__section">
      <!--
      <div v-if="isFirst" class="edit__premium">
        <b-icon
          class="edit__premium__icon"
          icon="check"
        />プレミアムプラン必須情報
      </div>
      -->
      <h3 class="edit__subtitle">給与</h3>

      <z-price-input
        label="年収"
        name="income-salary"
        v-model="value.data.income.salary"
        :validate="'required|price_min_value:0'"
        maxlength="12"
        placeholder="例)20,000,000"
        additionalMessage="不動産収入を除いて入力してください"
        isRequired
        isPrivate
      />
    </section>

    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">配当・利子</h3>

      <z-price-input
        label="年配当・利子"
        name="income-dividend"
        v-model="value.data.income.dividend"
        :validate="'price_min_value:1'"
        maxlength="12"
        placeholder="例)20,000,000"
        additionalMessage="不動産収入を除いて入力してください"
        isPrivate
      />
    </section>

    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">年金</h3>

      <z-price-input
        label="受給額"
        name="income-pension"
        v-model="value.data.income.pension"
        :validate="'price_min_value:1'"
        maxlength="12"
        placeholder="例)30,000,000"
        additionalMessage="不動産収入を除いて入力してください"
        isPrivate
      />
    </section>

    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">その他</h3>

      <z-price-input
        label="その他所得"
        name="income-others"
        v-model="value.data.income.others"
        :validate="'price_min_value:1'"
        maxlength="12"
        placeholder="例)20,000,000"
        additionalMessage="不動産収入を除いて入力してください"
        isPrivate
      />
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "EditIncome",
  inject: ["$validator"],
  props: {
    value: Object,
    isFirst: Boolean,
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      incomeCashTypes: kubuns.incomeCashTypes,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  // methods: {
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
