<template>
  <div class="not-premium-card">
    <div class="not-premium-card__msg3">
      <p>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>
    </div>
    <div class="not-premium-card__inr">
      <div class="not-premium-card__box">
        <div class="not-premium-card__msg">
          <div class="not-premium-card__card">
            <img src="@/assets/investor/premium-card.png" />
          </div>
          <div class="not-premium-card__msg1">
            <p>
              閲覧するにはプレミアムプラン<br
                class="sp-show"
              />へのご登録が必要です
            </p>
          </div>
        </div>
        <div class="not-premium-card__msg2">
          <router-link :to="{ name: 'investor-premium' }"
            >無料トライアルではじめる &gt;&gt;</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "investor-messages-NotPremiumCard",
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.not-premium-card {
  width: 100%;
  height: 42px;
  @include sp {
    height: 180px;
  }
  &__inr {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f1f6fc;
    // border: 1px solid #e0e0e0;
    // box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
    color: #4a4a4a;
  }
  &__box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp {
      flex-direction: column;
    }
  }

  &__card {
    img {
      height: 25px;
      weight: 35px;
      @include sp {
        height: 61px;
        weight: 84px;
      }
    }
  }
  &__msg {
    display: flex;
  }
  &__msg1 {
    margin-left: 12px;
    margin-top: 4px;
    font-size: 14px;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 18px;
    @include sp {
      text-align: left;
      margin-left: 7px;
      margin-bottom: 0;
      margin-top: 14px;
    }
  }
  &__msg2 {
    margin-left: 12px;
    margin-bottom: 4px;
    font-size: 14px;
    color: #0262d8;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;

    @include sp {
      text-align: center;
    }
  }
  &__msg3 {
    position: relative;
    height: 0px;
  }
}

.sp-show {
  display: none;
  @include sp {
    display: block;
  }
}
</style>
