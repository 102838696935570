<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
import investorType from "@/types/investorType";
const type = investorType.type;

const applictionAcceptUserText = `
プレミアム会員にお申し込みいただき、誠にありがとうございます。

ポートフォリオのご登録内容に不備がないか等を、
確認・審査させていただきまして、
1営業日以内に結果をメールにてご連絡させていただきます。

なお、登録内容に関しまして、
当方事務局よりお問い合わせ差し上げることがありますが、
その際につきましては何卒よろしくお願いいたします。

今後とも、StockFormerのご利用よろしくお願い申し上げます。
`;

const dummyBS = {
  2019: [
    {
      quarter: 1,
      score: {
        score: 0,
        bs: 0,
        income: 0,
        rank: 0,
      },
      BS: {
        assets: {
          sum: 0,
          cash: {
            sum: 0,
          },
          security: {
            sum: 0,
          },
          insurance: {
            sum: 0,
          },
          realEstate: {
            sum: 0,
          },
        },
        liabilities: {
          sum: 0,
        },
        netAssets: {
          sum: 0,
        },
      },
    },
    {
      quarter: 2,
      score: {
        score: 0,
        bs: 0,
        income: 0,
        rank: 0,
      },
      BS: {
        assets: {
          sum: 0,
          cash: {
            sum: 0,
          },
          security: {
            sum: 0,
          },
          insurance: {
            sum: 0,
          },
          realEstate: {
            sum: 0,
          },
        },
        liabilities: {
          sum: 0,
        },
        netAssets: {
          sum: 0,
        },
      },
    },
    {
      quarter: 3,
      score: {
        score: 0,
        bs: 0,
        income: 0,
        rank: 0,
      },
      BS: {
        assets: {
          sum: 0,
          cash: {
            sum: 0,
          },
          security: {
            sum: 0,
          },
          insurance: {
            sum: 0,
          },
          realEstate: {
            sum: 0,
          },
        },
        liabilities: {
          sum: 0,
        },
        netAssets: {
          sum: 0,
        },
      },
    },
  ],
};
const dummyPL = {
  2019: [
    {
      quarter: 1,
      score: {
        score: 0,
        bs: 0,
        income: 0,
        rank: 0,
      },
      PL: {
        sum: 0,
        salary: 0,
        dividend: 0,
        pension: 0,
        realEstate: 0,
        others: 0,
      },
    },
    {
      quarter: 2,
      score: {
        score: 0,
        rank: 0,
      },
      PL: {
        sum: 0,
        salary: 0,
        dividend: 0,
        pension: 0,
        realEstate: 0,
        others: 0,
      },
    },
    {
      quarter: 3,
      score: {
        score: 0,
        rank: 0,
      },
      PL: {
        sum: 0,
        salary: 0,
        dividend: 0,
        pension: 0,
        realEstate: 0,
        others: 0,
      },
    },
  ],
};

export default {
  name: "Mixins-investor",
  methods: {
    $$investor$getNewImage: function () {
      return this.$$cloneDeep(type.image);
    },
    $$investor$getNewExamination: function () {
      return this.$$cloneDeep(type.examination);
    },
    $$investor$getNewScore: function () {
      return this.$$cloneDeep(type.score);
    },
    $$investor$getNewBS: function () {
      return this.$$cloneDeep(type.BS);
    },
    $$investor$getNewPL: function () {
      return this.$$cloneDeep(type.PL);
    },
    $$investor$getNewBuyingNeeds: function () {
      return this.$$cloneDeep(type.buyingNeeds);
    },
    $$investor$getNewCash: function () {
      return this.$$cloneDeep(type.cash);
    },
    $$investor$getNewSecurity: function () {
      return this.$$cloneDeep(type.security);
    },
    $$investor$getNewInsurance: function () {
      return this.$$cloneDeep(type.insurance);
    },
    $$investor$getNewCareer: function () {
      return this.$$cloneDeep(type.career);
    },
    $$investor$getNewMarketTrendFilter: function () {
      return this.$$cloneDeep(type.marketTrend.filter);
    },
    $$investor$getNewBuyingNeedsFilter: function () {
      return this.$$cloneDeep(type.buyingNeeds);
    },
    $$investor$getNewBuyingNeedsFilterNew: function () {
      return this.$$cloneDeep(type.buyingNeeds.filter);
    },
    $$investor$getNewInvestorInfoFilter: function () {
      return this.$$cloneDeep(type.investorInfo.filter);
    },
    $$investor$getNewOtherInfoFilter: function () {
      return this.$$cloneDeep(type.otherInfo.filter);
    },
    $$investor$getNewUserByRelation: function (relationship) {
      const user = this.$$cloneDeep(type.user);
      user.relationship = relationship;
      return {
        id: null,
        data: user,
        realEstates: [], //不動産情報はここ
        examination: {
          id: null,
          data: this.$$cloneDeep(type.examination),
        },
      };
    },
    $$investor$getNewUser: function () {
      return this.$$investor$getNewUserByRelation(99); //本人
    },
    async $$investor$getPLHistory(uid, filter) {
      const doc = await firebase.db
        .collection("PL_v1")
        .doc(uid)
        .collection("years")
        .doc(`${filter.year}`)
        .get();

      if (doc.exists) {
        return doc.data().data;
      } else {
        const pl = dummyPL[filter.year];
        return pl ? pl : [];
        // return [];
      }
      // const dt = dummyPL[filter.year];
      // return dt;
    },
    async $$investor$getBSHistory(uid, filter) {
      const doc = await firebase.db
        .collection("BS_v1")
        .doc(uid)
        .collection("years")
        .doc(`${filter.year}`)
        .get();

      if (doc.exists) {
        return doc.data().data;
      } else {
        const bs = dummyBS[filter.year];
        return bs ? bs : [];
        // return dummyBS[filter.year];
      }
      // const dt = dummyBS[filter.year];
      // return dt;
    },
    async $$investor$applicateUserByUID(uid, targetUID, analyticsOrderNum) {
      // 応募
      const user = await this.$$investor$loadUser(targetUID);
      user.data.isActivate = true;
      user.data.wasArleadyUseTrial = true;
      // user.data.membership = 10; //無料会員
      user.data.membership = 20; //プレミアム会員
      // user.examination.data.membership = 10; //無料会員
      user.examination.data.membership = 20; //プレミアム会員
      // user.examination.data.status = 10; //申請中
      user.examination.data.status = 20; // 承認
      user.examination.data.isTrial = true; // トライアル
      // user.examination.data.status = 99; //申請中

      //3つめの引数がある場合は、ロードしてきたuserオブジェクトのanalytics.orderNumにデータを格納
      if (
        analyticsOrderNum !== null &&
        analyticsOrderNum !== undefined &&
        analyticsOrderNum !== ""
      ) {
        user.data.analytics.orderNum = analyticsOrderNum;
      }

      await this.$$investor$save(uid, user);
      const ret = await this.$$investor$saveExaminationOfUser(
        uid,
        user.id,
        user.examination
      );

      return ret;
    },
    async $$investor$denyUserByUID(uid, targetUID) {
      //否認
      const user = await this.$$investor$loadUser(targetUID);
      return await this.$$investor$denyUser(uid, user);
    },
    async $$investor$denyUser(uid, user) {},
    async $$investor$acceptUserByUID(uid, targetUID) {},
    async $$investor$acceptUser(uid, user) {},
    async $$investor$cancelUserByUID(uid, targetUID) {
      // 解約
      const user = await this.$$investor$loadUser(targetUID);
      user.examination.data.isCancelled = true;
      await this.$$investor$saveExaminationOfUser(
        uid,
        targetUID,
        user.examination
      );
      return user;
    },
    async $$investor$saveRelationshipHistory(uid, now, user, relationship) {
      await firebase.db
        .collection("users_v1")
        .doc(user.id)
        .collection("relationship_v1")
        .doc(relationship.id)
        .collection("histories_v1")
        .add({
          date: now,
          data: relationship,
        });
    },
    async $$investor$saveHistory(uid, now, user) {
      await firebase.db
        .collection("users_v1")
        .doc(user.id)
        .collection("histories_v1")
        .add({
          date: now,
          data: user,
        });
    },
    async $$investor$loadExaminationOfUser(id) {
      const doc = await firebase.db
        .collection("users_v1")
        .doc(id)
        .collection("examinations_v1")
        .doc(id)
        .get();
      if (doc.exists) {
        return {
          id: doc.id,
          data: this.$$assign(type.examination, doc.data()),
        };
      } else {
        return null;
      }
    },
    async $$investor$saveExaminationOfUser(uid, userId, examination) {
      const now = firebase.serverTimestamp();
      if (examination.id) {
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;

        const history = this.$$cloneDeep(examination.data);
        delete history.histories;
        examination.data.histories.push(history);

        const ret = await firebase.db
          .collection("users_v1")
          .doc(userId)
          .collection("examinations_v1")
          .doc(userId)
          .set(examination.data, { merge: true });
        return ret;
      } else {
        examination.data.createdBy = uid;
        examination.data.createdDt = now;
        examination.data.updatedBy = uid;
        examination.data.updatedDt = now;
        examination.data.registerDt = now;

        const history = this.$$cloneDeep(examination.data);
        delete history.histories;
        examination.data.histories.push(history);

        const ret = await firebase.db
          .collection("users_v1")
          .doc(userId)
          .collection("examinations_v1")
          .doc(userId)
          .set(examination.data, { merge: true });
        return ret;
      }
    },
    async $$investor$saveRelationship(uid, user, relationship) {
      if (relationship.id) {
        const now = firebase.serverTimestamp();
        relationship.data.updatedBy = uid;
        relationship.data.updatedDt = now;

        await firebase.db
          .collection("users_v1")
          .doc(user.id)
          .collection("relationships_v1")
          .doc(relationship.id)
          .set(relationship.data, { merge: true });

        await this.$$investor$saveRelationshipHistory(
          uid,
          now,
          user,
          relationship
        );
        return relationship;
      } else {
        const now = firebase.serverTimestamp();
        relationship.data.createdBy = uid;
        relationship.data.createdDt = now;
        relationship.data.updatedBy = uid;
        relationship.data.updatedDt = now;

        const newRelationship = await firebase.db
          .collection("users_v1")
          .doc(user.id)
          .collection("relationships_v1")
          .add(relationship.data)
          .then((ref) => {
            return {
              id: ref.id,
              data: relationship.data,
            };
          });
        await this.$$investor$saveRelationshipHistory(
          uid,
          now,
          user,
          newRelationship
        );
        return newRelationship;
      }
    },
    async $$investor$saveOnlyScore(uid, targetUID) {
      const user = await this.$$investor$loadUser(targetUID);
      return this.$$investor$save(uid, user);
    },
    async $$investor$save(uid, user) {
      // const self = this;
      const now = firebase.serverTimestamp();
      user.data.updatedBy = uid;
      user.data.updatedDt = now;

      // await this.$$investor$saveExaminationOfUser(uid, user);
      const latestScore = this.$$investor$calcScore(user);
      if (latestScore) {
        user.data.latestScore = latestScore;
      }

      const latestBS = this.$$investor$calcBS(user);
      if (latestBS) {
        user.data.latestBS = latestBS;
      }

      const latestPL = this.$$investor$calcPL(user);
      if (latestPL) {
        user.data.latestPL = latestPL;
      }

      for (const r of user.realEstates) {
        await this.$$realEstate$saveRealEstate(uid, r);
      }

      await firebase.db
        .collection("users_v1")
        .doc(user.id)
        .set(user.data, { merge: true });
      //console.log(user);
      await this.$$investor$saveHistory(uid, now, user);

      return user;
    },
    $$investor$checkSatisfyPremium: function () {
      // プレミアムプランの利用条件チェック
      const assets = this.$$user$BS.assets.sum;
      const buyingNeeds = this.me.user.data.buyingNeeds.length;
      return assets && buyingNeeds;
    },
    $$investor$calcScore: function (user) {
      // スコア計算
      const BS = this.$$investor$calcBS(user);
      const PL = this.$$investor$calcPL(user);
      const scoreOfPL = this.$$investor$calcScoreOfPL(PL);
      const scoreOfBS = this.$$investor$calcScoreOfBS(BS);
      const score = this.$$investor$__calcScore(scoreOfBS, scoreOfPL);
      const rank = this.$$investor$__calcRank(score);
      return {
        score: score,
        bs: scoreOfBS,
        income: scoreOfPL,
        rank: rank,
      };
    },
    $$investor$calcBS: function (user) {
      const bs = this.$$cloneDeep(type.BS);
      bs.assets.cash.sum = user.data.assets.cashes.reduce(
        (s, c) => s + c.amount,
        0
      );
      bs.assets.security.sum = user.data.assets.securities.reduce(
        (s, c) => s + c.amount,
        0
      );
      bs.assets.insurance.sum = user.data.assets.insurances.reduce(
        (s, c) => s + c.amount,
        0
      );
      //console.log(user);
      bs.assets.realEstate.sum = user.realEstates.reduce((s, r) => {
        //■ストック＝購入金額ー（融資金額ー残債）
        // console.log(r.data.purchase);
        const debt = this.$$realEstate$calcDebtSummary(r.data.purchase.loan);
        const stock = this.$$realEstate$calcStockByRealEstate(r, debt);
        //const stock =
        //  r.data.purchase.amount -
        //  (r.data.purchase.loan.amount - debt.outstandingDebt);
        return s + stock;
      }, 0);

      bs.liabilities.sum = user.realEstates.reduce((s, r) => {
        //console.log(r.data.purchase);
        const debt = this.$$realEstate$calcDebtSummary(r.data.purchase.loan);
        return s + debt.outstandingDebt;
      }, 0);

      bs.assets.sum =
        bs.assets.cash.sum +
        bs.assets.security.sum +
        bs.assets.insurance.sum +
        bs.assets.realEstate.sum;
      bs.netAssets.sum = bs.assets.sum - bs.liabilities.sum;
      return bs;
    },
    $$investor$calcRealEstateRepaid: function (r) {
      const debt = this.$$realEstate$calcDebtSummary(r.data.purchase.loan);
      // 年返済額(利息) + 年返済額(元本)
      const repaid =
        debt.amountInterestRepaidPerYear + debt.amountPrincipalRepaidPerYear;
      return repaid;
    },
    $$investor$calcRealEstateIncome: function (r) {
      // ■インカム＝年想定収入（家賃）－年返済額（利息）－年返済額（元金）
      const income =
        r.data.annualEstimatedIncome - this.$$investor$calcRealEstateRepaid(r);
      return income;
    },
    $$investor$calcPL: function (user) {
      const pl = this.$$assign(type.PL, user.data.income);
      pl.realEstate = user.realEstates.reduce((s, r) => {
        // ■インカム＝年想定収入（家賃）－年返済額（利息）－年返済額（元金）
        const income = this.$$investor$calcRealEstateIncome(r);
        return s + income;
      }, 0);

      pl.sum = pl.salary + pl.dividend + pl.pension + pl.others + pl.realEstate;
      return pl;
    },
    $$investor$__calcRank: function (score) {
      // S：800～990(190)
      // A：600～799(200)
      // B：450～599(150)
      // C：～449
      if (800 <= score) {
        return 1; //S
      } else if (600 <= score && score <= 799) {
        return 2; //A
      } else if (450 <= score && score <= 599) {
        return 3; //B
      } else {
        return 4; //C
      }
    },
    $$investor$__calcScore: function (scoreOfBS, scoreOfPL) {
      const score = Math.round(scoreOfBS / 2 + scoreOfPL / 2);
      if (score === 0) return 0;
      if (score < 350) return 350;
      if (score > 990) return 990;
      return score;
    },
    $$investor$calcScoreOfPL: function (pl) {
      //salary: 0, // 給与	年収
      //dividend: 0, // 配当・利子	年配当・利子
      //pension: 0, // 年金	年収
      //others: 0 // その他	年収
      const amount = pl.sum;
      return this.$$investor$__calcScoreOfPL(amount);
    },
    $$investor$__calcScoreOfPL: function (amount) {
      if (amount === 0) return 0;
      // 平均値：1500万円
      // 標準偏差：2000万円
      const ave = 1500 * 10000;
      const standardDeviation = 2000 * 10000;
      // スコア＝(ターゲット金額 - 平均金額) / 標準偏差 * 200 + 500
      const score = Math.round(
        ((amount - ave) / standardDeviation) * 200 + 500
      );

      if (score > 990) return 990;
      if (score < 0) return 0;

      return score;
    },
    $$investor$calcScoreOfBS(bs) {
      return this.$$investor$__calcScoreOfBS(bs.netAssets.sum);
    },
    $$investor$__calcScoreOfBS: function (amount) {
      if (amount === 0) return 0;

      // 平均値：3000万円
      // 標準偏差：2000万円
      const ave = 3000 * 10000;
      const standardDeviation = 2000 * 10000;
      // スコア＝(ターゲット金額 - 平均金額) / 標準偏差 * 200 + 500
      const score = Math.round(
        ((amount - ave) / standardDeviation) * 100 + 500
      );

      if (score > 990) return 990;
      if (score < 0) return 0;

      return score;
    },
    $$investor$loadUsers: async function () {
      //TODO:
      const users = await firebase.db
        .collection("users_v1")
        .where("isInvestor", "==", true)
        .where("isActivate", "==", true)
        .orderBy("latestScore.score", "desc")
        .get()
        .then((querySnapshot) => {
          const is = [];
          querySnapshot.forEach((doc) => {
            is.push(doc.id);
          });
          return is;
        })
        .then((uids) => {
          const us = [];
          for (const uid of uids) {
            us.push(this.$$investor$loadUser(uid));
          }
          return Promise.all(us);
        });
      return users;
    },
    $$investor$loadUsersWithMessage: async function () {
      const users = await firebase.db
        .collection("users_v1")
        .where("isInvestor", "==", true)
        .where("isActivate", "==", true)
        .orderBy("latestScore.score", "desc")
        .get()
        .then((querySnapshot) => {
          const is = [];
          querySnapshot.forEach((doc) => {
            is.push(doc.id);
          });
          return is;
        })
        .then((uids) => {
          const us = [];
          for (const uid of uids) {
            us.push(this.$$investor$loadUserWithMessage(uid));
          }
          return Promise.all(us);
        });
      return users;
    },
    $$investor$loadUserSimple: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();

      if (doc.exists) {
        const user = {
          id: doc.id,
          data: this.$$assign(type.user, doc.data()),
        };
        return user;
      } else {
        return null;
      }
    },
    $$investor$loadUser: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();

      const examination = await this.$$investor$loadExaminationOfUser(uid);
      const realEstates = await this.$$realEstate$loadRealEstatesByUID(uid);

      if (doc.exists) {
        const user = {
          id: doc.id,
          data: this.$$assign(type.user, doc.data()),
          realEstates: realEstates,
          examination: examination || {
            id: null,
            data: this.$$assign(type.examination, examination),
          },
        };
        return user;
      } else {
        return null;
      }
    },
    $$investor$loadUserWithMessage: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();

      const examination = await this.$$investor$loadExaminationOfUser(uid);
      const realEstates = await this.$$realEstate$loadRealEstatesByUID(uid);
      const messages = await this.$$message$loadMessagesByUID(uid);

      if (doc.exists) {
        const user = {
          id: doc.id,
          data: this.$$assign(type.user, doc.data()),
          realEstates: realEstates,
          examination: examination || {
            id: null,
            data: this.$$assign(type.examination, examination),
          },
          messages: messages,
        };
        return user;
      } else {
        return null;
      }
    },
    $$investor$filterByActivate(investors) {
      return investors.filter((u) => u.data.isActivate);
    },
    async $$investor$loadUsersByFilter(currentPage, perPage, sortType, filter) {
      return await firebase
        .functions("loadUsersForInvestorSearch", {
          currentPage: currentPage,
          perPage: perPage,
          sortType: sortType,
          filter: filter,
        })
        .then((re) => {
          return re.data;
        });
    },
    $$investor$getRealEstateTransactionsFilter() {
      return this.$$cloneDeep(type.realEstateTransactions.filter);
    },
    // スコア--価格の件数(MarketTrendのRealEstateTransactionChart用)
    $$invester$getMarketTrendTransactionPriceGroups(filter) {
      console.log(filter);
      return [
        {
          score: {
            // スコア帯
            from: 300, // スコア帯最小
            to: 400, // スコア帯最大
          },
          askingPrice: {
            // 価格帯
            from: 1000000, // 価格帯最小
            to: 2000000, // 価格帯最大
          },
          count: 20, // 件数
        },
        {
          score: {
            from: 400,
            to: 500,
          },
          askingPrice: {
            from: 2000000,
            to: 3000000,
          },
          count: 40,
        },
        {
          score: {
            from: 500,
            to: 600,
          },
          askingPrice: {
            from: 2000000,
            to: 3000000,
          },
          count: 70,
        },
        {
          score: {
            from: 600,
            to: 700,
          },
          askingPrice: {
            from: 2000000,
            to: 3000000,
          },
          count: 65,
        },
        {
          score: {
            from: 600,
            to: 700,
          },
          askingPrice: {
            from: 6000000,
            to: 7000000,
          },
          count: 25,
        },
        {
          score: {
            from: 700,
            to: 800,
          },
          askingPrice: {
            from: 2000000,
            to: 3000000,
          },
          count: 65,
        },
        {
          score: {
            from: 800,
            to: 900,
          },
          askingPrice: {
            from: 5000000,
            to: 6000000,
          },
          count: 65,
        },
        {
          score: {
            from: 800,
            to: 900,
          },
          askingPrice: {
            from: 2000000,
            to: 3000000,
          },
          count: 65,
        },
      ];
    },
    // スコア--利回りの件数(MarketTrendのRealEstateTransactionChart用)
    $$invester$getMarketTrendTransactionCouponYieldRateGroups(filter) {
      console.log(filter);
      return [
        {
          score: {
            // スコア帯
            from: 400, // スコア帯最小
            to: 500, // スコア帯最大
          },
          couponYieldRate: {
            // 表面利回り帯
            from: 8, // 表面利回り帯最小
            to: 9, // 表面利回り帯最大
          },
          count: 65, // 件数
        },
        {
          score: {
            from: 500,
            to: 600,
          },
          couponYieldRate: {
            from: 9,
            to: 10,
          },
          count: 72,
        },
        {
          score: {
            from: 600,
            to: 700,
          },
          couponYieldRate: {
            from: 9,
            to: 10,
          },
          count: 40,
        },
        {
          score: {
            from: 600,
            to: 700,
          },
          couponYieldRate: {
            from: 12,
            to: 13,
          },
          count: 70,
        },
        {
          score: {
            from: 700,
            to: 800,
          },
          couponYieldRate: {
            from: 18,
            to: 19,
          },
          count: 60,
        },
      ];
    },
    // DashBoardのスコアチャート用
    $$invester$getScoreStandardDeviation() {
      return [
        {
          score: {
            from: 0,
            to: 349,
          },
          count: {
            1: 20,
            2: 15,
            3: 20,
          },
        },
        {
          score: {
            from: 350,
            to: 399,
          },
          count: {
            1: 30,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 400,
            to: 449,
          },
          count: {
            1: 25,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 500,
            to: 549,
          },
          count: {
            1: 30,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 550,
            to: 599,
          },
          count: {
            1: 35,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 600,
            to: 649,
          },
          count: {
            1: 40,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 650,
            to: 699,
          },
          count: {
            1: 85,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 700,
            to: 749,
          },
          count: {
            1: 50,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 750,
            to: 799,
          },
          count: {
            1: 55,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 800,
            to: 849,
          },
          count: {
            1: 60,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 850,
            to: 899,
          },
          count: {
            1: 65,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 900,
            to: 949,
          },
          count: {
            1: 50,
            2: 45,
            3: 20,
          },
        },
        {
          score: {
            from: 950,
            to: 999,
          },
          count: {
            1: 50,
            2: 45,
            3: 20,
          },
        },
      ];
    },
    // DashBoardの不動産取引動向用
    $$invester$getMarketTrendHeatMap() {
      return [
        {
          marketTrendType: 1,
          countsOfMarketTrendType: 315,
          countsOfYield: [
            {
              couponYieldRate: {
                from: 0,
                to: 0.03,
              },
              count: 5,
            },
            {
              couponYieldRate: {
                from: 0.03,
                to: 0.04,
              },
              count: 80,
            },
            {
              couponYieldRate: {
                from: 0.04,
                to: 0.05,
              },
              count: 3,
            },
            {
              couponYieldRate: {
                from: 0.05,
                to: 0.06,
              },
              count: 13,
            },
            {
              couponYieldRate: {
                from: 0.06,
                to: 0.07,
              },
              count: 60,
            },
            {
              couponYieldRate: {
                from: 0.07,
                to: 0.08,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.08,
                to: 0.09,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.09,
                to: 0.1,
              },
              count: 20,
            },
            {
              couponYieldRate: {
                from: 0.1,
                to: null,
              },
              count: 40,
            },
          ],
        },
        {
          marketTrendType: 2,
          countsOfMarketTrendType: 240,
          countsOfYield: [
            {
              couponYieldRate: {
                from: 0,
                to: 0.03,
              },
              count: 5,
            },
            {
              couponYieldRate: {
                from: 0.03,
                to: 0.04,
              },
              count: 40,
            },
            {
              couponYieldRate: {
                from: 0.04,
                to: 0.05,
              },
              count: 3,
            },
            {
              couponYieldRate: {
                from: 0.05,
                to: 0.06,
              },
              count: 35,
            },
            {
              couponYieldRate: {
                from: 0.06,
                to: 0.07,
              },
              count: 6,
            },
            {
              couponYieldRate: {
                from: 0.07,
                to: 0.08,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.08,
                to: 0.09,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.09,
                to: 0.1,
              },
              count: 2,
            },
            {
              couponYieldRate: {
                from: 0.1,
                to: null,
              },
              count: 4,
            },
          ],
        },
        {
          marketTrendType: 3,
          countsOfMarketTrendType: 215,
          countsOfYield: [
            {
              couponYieldRate: {
                from: 0,
                to: 0.03,
              },
              count: 5,
            },
            {
              couponYieldRate: {
                from: 0.03,
                to: 0.04,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.04,
                to: 0.05,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.05,
                to: 0.06,
              },
              count: 3,
            },
            {
              couponYieldRate: {
                from: 0.06,
                to: 0.07,
              },
              count: 60,
            },
            {
              couponYieldRate: {
                from: 0.07,
                to: 0.08,
              },
              count: 40,
            },
            {
              couponYieldRate: {
                from: 0.08,
                to: 0.09,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.09,
                to: 0.1,
              },
              count: 2,
            },
            {
              couponYieldRate: {
                from: 0.1,
                to: null,
              },
              count: 40,
            },
          ],
        },
        {
          marketTrendType: 4,
          countsOfMarketTrendType: 300,
          countsOfYield: [
            {
              couponYieldRate: {
                from: 0,
                to: 0.03,
              },
              count: 50,
            },
            {
              couponYieldRate: {
                from: 0.03,
                to: 0.04,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.04,
                to: 0.05,
              },
              count: 30,
            },
            {
              couponYieldRate: {
                from: 0.05,
                to: 0.06,
              },
              count: 35,
            },
            {
              couponYieldRate: {
                from: 0.06,
                to: 0.07,
              },
              count: 6,
            },
            {
              couponYieldRate: {
                from: 0.07,
                to: 0.08,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.08,
                to: 0.09,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.09,
                to: 0.1,
              },
              count: 2,
            },
            {
              couponYieldRate: {
                from: 0.1,
                to: null,
              },
              count: 40,
            },
          ],
        },
        {
          marketTrendType: 5,
          countsOfMarketTrendType: 300,
          countsOfYield: [
            {
              couponYieldRate: {
                from: 0,
                to: 0.03,
              },
              count: 50,
            },
            {
              couponYieldRate: {
                from: 0.03,
                to: 0.04,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.04,
                to: 0.05,
              },
              count: 30,
            },
            {
              couponYieldRate: {
                from: 0.05,
                to: 0.06,
              },
              count: 35,
            },
            {
              couponYieldRate: {
                from: 0.06,
                to: 0.07,
              },
              count: 6,
            },
            {
              couponYieldRate: {
                from: 0.07,
                to: 0.08,
              },
              count: 4,
            },
            {
              couponYieldRate: {
                from: 0.08,
                to: 0.09,
              },
              count: 0,
            },
            {
              couponYieldRate: {
                from: 0.09,
                to: 0.1,
              },
              count: 2,
            },
            {
              couponYieldRate: {
                from: 0.1,
                to: null,
              },
              count: 40,
            },
          ],
        },
      ];
    },
    async $$investor$cardInfo(user) {
      if (user.data.payment.id) {
        const ret = await firebase.functions("card_info", {
          id: user.data.payment.id,
        });
        return ret.data;
      } else {
        return null;
      }
    },
    async $$investor$isRegisteredCard(user) {
      if (user.data.payment.id) {
        const ret = await firebase.functions("card_info", {
          id: user.data.payment.id,
        });
        return ret.data.REGISTERED;
      } else {
        return false;
      }
    },
    async $$investor$saveRealtorEvaluationOfUser(uid, transaction) {
      const now = firebase.serverTimestamp();
      const realtorId = transaction.data.realtor;
      const evaluation = transaction.data.realtorEvaluation;
      evaluation.transaction = transaction.id;
      evaluation.investor = uid;

      evaluation.createdBy = uid;
      evaluation.createdDt = now;
      evaluation.updatedBy = uid;
      evaluation.updatedDt = now;
      evaluation.registerDt = now;

      const ret = await firebase.db
        .collection("users_v1")
        .doc(realtorId)
        .collection("realtor_evaluations_v1")
        .add(evaluation);
      return ret;
    },
  },
};
</script>
