<template>
  <section class="marketTrend">
    <div class="marketTrend__title">
      <p class="marketTrend__title__mainTitle">Market trend</p>
      <p class="marketTrend__title__subTitle">不動産取引動向</p>
    </div>
    <div class="marketTrend__chart">
      <ZMarketTrendHeatMap
        class="marketTrend__chart__inner"
        :class="{
          'is-locked': !$$user$isPremium,
          'is-hide': !$$user$isPremium && $$userAgent$isIE(),
        }"
        :chartData="chartData"
      />
      <div
        class="marketTrend__chart__forFreeUser forFreeUser"
        v-if="!$$user$isPremium"
      >
        <img
          class="forFreeUser__dummyImage"
          alt=""
          v-if="!$$user$isPremium && $$userAgent$isIE()"
          :src="dummyImage"
        />
        <p class="forFreeUser__title">
          <b-icon class="icon" icon="lock" />
          こちらを閲覧するにはプレミアムプランへのご登録が必要です
        </p>
        <div class="forFreeUser__sub">
          <div class="forFreeUser__sub__text">
            <p>
              プレミアムプランにご登録いただくと、本サービスを利用できる他、StockFormer内の全ての不動産取引の仲介手数料に対し、10％のキャッシュバック優遇を受けることができます
            </p>
          </div>
          <MembershipsButton
            class="forFreeUser__sub__button"
            :isKeyIconActive="false"
          />
        </div>
      </div>
    </div>
    <div class="marketTrend__link">
      <b-button
        class="z-button"
        type="is-primary"
        size="is-very-small"
        rounded
        outlined
        @click="onClickMore"
        >すべての取引動向を見る</b-button
      >
    </div>
  </section>
</template>

<script>
import ZMarketTrendHeatMap from "@/components/charts/ZMarketTrendHeatMap.vue";

import dummy_chart_pc from "@/assets/investor/markettrend-dummy-pc.png";
import dummy_chart_md from "@/assets/investor/markettrend-dummy-md.png";
import dummy_chart_sp from "@/assets/investor/markettrend-dummy-sp.png";

export default {
  name: "MarketTrend",
  components: {
    ZMarketTrendHeatMap,
  },
  asyncComputed: {
    async chartData() {
      if (!this.$$user$isPremium) {
        return DUMMY_CHART_DATA;
      }
      return await this.$$MarketChart$getMarketTrendHeatMap();
    },
  },
  computed: {
    dummyImage() {
      if (!this.bulma) {
        return null;
      }
      if (this.bulma.isWidescreen) {
        return dummy_chart_pc;
      }
      if (this.bulma.isMobile) {
        return dummy_chart_sp;
      }
      return dummy_chart_md;
    },
  },
  methods: {
    onClickMore() {
      if (this.$$user$isPremium) {
        // スコアの絞り込み初期条件を付与する
        const score_from = Math.floor(this.$$user$score.score / 100) * 100;
        const score_to = score_from + 100;
        this.$router.push({
          name: "investor-markettrend",
          query: { score_from, score_to },
        });
      } else {
        this.$router.push({ name: "investor-premium" });
      }
    },
  },
  //computed: {
  //  chartData() {
  //    return this.$$invester$getMarketTrendHeatMap();
  //  }
  //}
};

const DUMMY_CHART_DATA = [
  {
    buildingType: 1,
    countsOfbuildingType: 82,
    countsOfYield: [
      {
        couponYieldRate: {
          from: 0,
          to: 0.03,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.03,
          to: 0.04,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.04,
          to: 0.05,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.05,
          to: 0.06,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.06,
          to: 0.07,
        },
        count: 15,
      },
      {
        couponYieldRate: {
          from: 0.07,
          to: 0.08,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.08,
          to: 0.09,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.09,
          to: 0.1,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.1,
          to: null,
        },
        count: 20,
      },
    ],
  },
  {
    buildingType: 2,
    countsOfbuildingType: 78,
    countsOfYield: [
      {
        couponYieldRate: {
          from: 0,
          to: 0.03,
        },
        count: 80,
      },
      {
        couponYieldRate: {
          from: 0.03,
          to: 0.04,
        },
        count: 40,
      },
      {
        couponYieldRate: {
          from: 0.04,
          to: 0.05,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.05,
          to: 0.06,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.06,
          to: 0.07,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.07,
          to: 0.08,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.08,
          to: 0.09,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.09,
          to: 0.1,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.1,
          to: null,
        },
        count: 40,
      },
    ],
  },
  {
    buildingType: 3,
    countsOfbuildingType: 22,
    countsOfYield: [
      {
        couponYieldRate: {
          from: 0,
          to: 0.03,
        },
        count: 30,
      },
      {
        couponYieldRate: {
          from: 0.03,
          to: 0.04,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.04,
          to: 0.05,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.05,
          to: 0.06,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.06,
          to: 0.07,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.07,
          to: 0.08,
        },
        count: 10,
      },
      {
        couponYieldRate: {
          from: 0.08,
          to: 0.09,
        },
        count: 30,
      },
      {
        couponYieldRate: {
          from: 0.09,
          to: 0.1,
        },
        count: 30,
      },
      {
        couponYieldRate: {
          from: 0.1,
          to: null,
        },
        count: 30,
      },
    ],
  },
  {
    buildingType: 4,
    countsOfbuildingType: 46,
    countsOfYield: [
      {
        couponYieldRate: {
          from: 0,
          to: 0.03,
        },
        count: 1,
      },
      {
        couponYieldRate: {
          from: 0.03,
          to: 0.04,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.04,
          to: 0.05,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.05,
          to: 0.06,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.06,
          to: 0.07,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.07,
          to: 0.08,
        },
        count: 40,
      },
      {
        couponYieldRate: {
          from: 0.08,
          to: 0.09,
        },
        count: 40,
      },
      {
        couponYieldRate: {
          from: 0.09,
          to: 0.1,
        },
        count: 30,
      },
      {
        couponYieldRate: {
          from: 0.1,
          to: null,
        },
        count: 80,
      },
    ],
  },
  {
    buildingType: 5,
    countsOfbuildingType: 195,
    countsOfYield: [
      {
        couponYieldRate: {
          from: 0,
          to: 0.03,
        },
        count: 50,
      },
      {
        couponYieldRate: {
          from: 0.03,
          to: 0.04,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.04,
          to: 0.05,
        },
        count: 30,
      },
      {
        couponYieldRate: {
          from: 0.05,
          to: 0.06,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.06,
          to: 0.07,
        },
        count: 0,
      },
      {
        couponYieldRate: {
          from: 0.07,
          to: 0.08,
        },
        count: 20,
      },
      {
        couponYieldRate: {
          from: 0.08,
          to: 0.09,
        },
        count: 60,
      },
      {
        couponYieldRate: {
          from: 0.09,
          to: 0.1,
        },
        count: 80,
      },
      {
        couponYieldRate: {
          from: 0.1,
          to: null,
        },
        count: 40,
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.marketTrend {
  &__title {
    &__mainTitle {
      margin-bottom: 4px;
      font-size: 32px;
      line-height: 1;
      color: #333333;
      font-family: $family-secondary;
    }
    &__subTitle {
      font-size: 14px;
      font-weight: bold;
      color: #aaaaaa;
    }
  }
  &__chart {
    margin-top: 10px;
    position: relative;
    &__inner {
      &.is-locked {
        filter: blur(7px);
        opacity: 0.5;
      }
      &.is-hide {
        visibility: hidden;
      }
    }
    &__forFreeUser {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: auto;
      position: absolute;
    }
  }
  &__link {
    width: 100%;
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
    /deep/ .z-button {
      width: 200px;
      height: 32px;
      font-size: 14px;
    }
    @include sp {
      margin-top: 30px;
      justify-content: center;
    }
  }
}

.forFreeUser {
  display: flex;
  flex-direction: column;
  padding: 8px 14px;
  @include md {
    padding: 0;
  }
  color: #333333;
  text-align: center;
  &__dummyImage {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    margin: auto;
    width: calc(100% + 20px);
    max-width: none;
    height: calc(100% + 20px);
    z-index: -1;
  }
  &__title {
    margin-top: 10px;
    padding-left: 26px;
    position: relative;
    font-size: 19px;
    font-weight: bold;
    line-height: 28px;
    @media (max-width: 1500px) {
      margin-top: 0;
    }
    @include md {
      padding-left: 0;
      text-align: center;
    }
    @include sp {
      margin-top: 32px;
      padding-left: 0;
      text-align: center;
    }
    /deep/ .icon {
      vertical-align: -3px;
      @include md {
        margin: 0 auto 12px;
        display: block;
        position: relative;
      }
      @include sp {
        margin: 0 auto 12px;
        display: block;
        position: relative;
      }
    }
  }
  &__sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    @media (max-width: 1500px) {
      justify-content: center;
      flex-direction: column;
    }
    &__text {
      padding-right: 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      @media (max-width: 1500px) {
        padding-right: 16px;
        padding-left: 16px;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
      }
      @include md {
        width: 100%;
        font-size: 16px;
        text-align: center;
      }
      @include sp {
        width: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        padding-top: 8px;
      }
    }
    &__button {
      /deep/ .memberships-button {
        width: 280px;
        margin-bottom: 4px;
        @include md {
          height: 60px;
        }
      }
      @media (max-width: 1500px) {
        margin-top: 9px;
      }
      @include sp {
        margin-top: 28px;
      }
    }
  }
}
</style>
