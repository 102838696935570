<template>
  <section class="zero-conversion-area">
    <div class="zero-conversion-area__inner simulation">
      <h1 class="simulation__copy">
        {{ title }}
      </h1>
      <b-button
        class="z-button simulation__button"
        size="is-small"
        @click="$emit('click', $event)"
        >{{ label }}</b-button
      >
      <small class="simulation__notice"
        >お預かりする個人情報は、シミュレーションおよびサービスのご案内以外の目的には利用しません。</small
      >
    </div>
    <img
      class="zero-conversion-area__image"
      src="@/assets/investor/zero/simulation.png"
      alt=""
    />
  </section>
</template>

<script>
export default {
  name: "ZeroConversionArea",
  props: {
    title: {
      type: String,
      default:
        "新築一棟物件をフルローンで入手できるか\nいますぐ確認してみましょう",
    },
    label: {
      type: String,
      default: "融資可能額を見る",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.zero-conversion-area {
  display: block;
  color: #34485e;
  position: relative;
  overflow: hidden;
  &__inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 84px 60px;
    @include sp() {
      padding: 48px 24px;
    }
  }
  .simulation {
    padding-top: 56px;
    padding-bottom: 50px;
    text-align: center;
    position: relative;
    z-index: 2;
    @include sp() {
      padding: 36px 24px;
    }
    &__copy {
      color: #fff;
      margin: 0 auto 48px;
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      line-height: 48px;
      white-space: pre-wrap;
      .sp {
        display: none;
      }
      @include sp() {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0;
        margin-bottom: 30px;
        .sp {
          display: block;
        }
      }
    }
    &__button {
      display: inline-block;
      width: 100%;
      max-width: 420px;
      height: 96px;
      margin: 0 auto 16px;
      background: #fff;
      border: none;
      border-radius: 128px;
      position: relative;
      transition: background-color 300ms ease;
      @include sp() {
        height: 64px;
      }
      /deep/ span {
        font-size: 21px;
        line-height: 21px;
        color: #3c7d9b;
        @include sp() {
          font-size: 18px;
          line-height: 18px;
        }
      }
      &:after {
        content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%233c7d9b%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
        font-size: 0;
        display: block;
        width: 25px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 36px;
        transform: translate(0, -50%);
        transition: transform 300ms ease;
        @include sp() {
          width: 20px;
          height: 14px;
        }
      }
      &:hover {
        background-color: #70acce;
        transition: background-color 300ms ease;
        border: none;
        /deep/ span {
          color: #fff;
          transition: color 300ms ease;
        }
        &:after {
          content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
          transform: translate(6px, -50%);
          transition: transform 300ms ease;
        }
      }
    }
    &__notice {
      display: block;
      font-size: 11px;
      line-height: 16px;
      color: #fff;
      text-align: center;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    overflow: hidden;
  }
}
</style>
