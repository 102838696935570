<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <ZPriceInput
        label="収入_v0"
        placeholder="例)¥500,000"
        maxlength="6"
        isRequired
        validate="required|price_min_value:0"
        v-model="testNum"
      />
      <p>値：{{ testNum }}</p>
      <hr />
      <ZPriceInput
        label="収入_v1"
        placeholder="例)¥500,000"
        maxlength="6"
        isRequired
        :validate="'required|price_min_value:1'"
        v-model="testNum"
      />
      <p>値：{{ testNum }}</p>
      <hr />
      <ZPriceInput
        label="収入NULL"
        placeholder="例)¥500,000"
        maxlength="6"
        isRequired
        v-model="testNumNull"
      />
      <p>値：{{ testNumNull }}</p>

      <ZPriceInput
        label="収入1"
        placeholder="例)¥500,000"
        maxlength="6"
        isRequired
        v-model="testNum"
      />
      <p>値：{{ testNum }}</p>

      <ZPriceInput
        label="収入2"
        placeholder="例)¥600,000"
        maxlength="12"
        v-model="testNum2"
        validate="required|price_min_value:1"
        isRequired
      />

      <p>値：{{ testNum2 }}</p>
      <p>金額変換：{{ $$price$priceFormat(testNum2) }}</p>
      <p>金額変換(JP)：{{ $$price$priceJPFormat(testNum2) }}</p>
    </section>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";
//import firebase from "@/plugins/firebase";

// const emptyImage = require("@/assets/top/top.jpg");

export default {
  name: "admin-demo-price",
  components: {
    // PrefectureSelect: PrefectureSelect
  },
  data() {
    return {
      testNumNull: null,
      testNum: 10000,
      testNum2: 20000,
    };
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
