<template>
  <div>
    <section>
      <ZHeader title="掲載物件" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <a @click="$router.go(-1)"> 前に戻る </a>
        <hr />
        <RealtorRealEstate :realEstate="realEstate" />
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import kubuns from "@/kubuns/kubuns";

import RealtorRealEstate from "@/components/realtor/RealtorRealEstate";

export default {
  name: "realtor-setteing-realestates-preview",
  components: {
    RealtorRealEstate: RealtorRealEstate,
  },
  data: function () {
    return {
      isLoading: true,
      realEstate: null,
    };
  },
  computed: {
    realEstateId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      //const loadingComponent = this.$loading.open();
      try {
        this.realEstate = await this.$$realtor$loadRealEstate(
          this.realEstateId
        );
        //console.log(this.realEstate.data.staff);
        //this.realEstate.staff = await this.$$realtor$loadUser(
        //  this.realEstate.data.staff
        //);
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.formRealEstateUnit {
  .edit__section--box {
    margin-top: 50px;
  }
  .opt-margin-top {
    margin-top: 72px;
  }
}
</style>
