<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-parts-demo-2",
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
