// import * as firebase from '@/plugins/firebase';
import firebase from "@/plugins/firebase";

export const load = async (lineCode) => {
  const doc = await firebase.db
    .collection("lines_v1")
    .doc(String(lineCode))
    .get();

  if (doc.exists) {
    return doc.data();
  } else {
    return null;
  }
};

export const loadStation = async (stationCode) => {
  const doc = await firebase.db
    .collection("stations_v1")
    .doc(String(stationCode))
    .get();

  if (doc.exists) {
    return doc.data();
  } else {
    return null;
  }
};

export const loadStationsByCodes = async (stationCodes) => {
  const ls = [];
  for (const code of stationCodes) {
    ls.push(await loadStation(code));
  }
  return ls;
};
