<template>
  <div :key="rs.id" class="realestate-card__wrap">
    <div class="realestate-card__con">
      <div class="realestate-card__con__image">
        <router-link
          :to="{
            name: 'realtor-setting-realestates-preview',
            params: { id: rs.id },
          }"
        >
          <figure class="image reale">
            <z-image v-model="images[0]"> </z-image>
          </figure>
        </router-link>
      </div>
      <div class="realestate-card__con__desc">
        <z-tag
          :options="$$ks.realEstateTypes"
          v-model="rs.data.realEstateType.value"
        />
        <z-truncator :text="rs.data.name" />
        <p class="realestate-card__red">
          {{ $$price$priceJPFormat(rs.data.price) }} /
          {{ $$rete$rateFormat(rs.data.couponYieldRate) }}
        </p>
        <div class="content is-small">
          <p v-text="address"></p>
          <template
            v-if="rs.data.realEstateType !== 10 && rs.data.realEstateType !== 7"
          >
            <div class="realestate-card__con__desc__dec">
              <div class="realestate-card__con__desc__dec__const">
                <p>
                  <span class="realestate-card__left-title">築年月：</span>
                  <span class="realestate-card__right-data">
                    {{
                      $$calender$yearMonthFormat(rs.data.constructionDate) ||
                      "-"
                    }}
                  </span>
                </p>
              </div>
              <div class="realestate-card__con__desc__dec__buil">
                <p>
                  <span class="realestate-card__left-title">建物種別：</span>
                  <span class="realestate-card__right-data">{{
                    rs.data.buildingType.text || "-"
                  }}</span>
                </p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="realestate-card__con__desc__dec">
              <div class="realestate-card__con__desc__dec__const">
                <p>築年月：-</p>
              </div>
              <div class="realestate-card__con__desc__dec__buil">
                <p>建物種別：-</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!--
      <div class="realestate-card__con__pub">
        <div class="content is-small">
          <p>{{ rs.data.saleSetting.publicType.text }}</p>
        </div>
      </div>
      -->
      <div class="realestate-card__con__msg">
        <div class="content is-small">
          <p>掲載状況：{{ rs.data.saleSetting.publicType.text || "-" }}</p>
          <p>
            問い合せ：{{ rs.message ? rs.message.inquiry.count : 0 }}件<br />
            オファー：{{ rs.message ? rs.message.offer.count : 0 }}件<br />
            返答数：{{ rs.message ? rs.message.replay.count : 0 }}件<br />
            閲覧人数：{{ rs.visitor ? rs.visitor.count : 0 }}件
          </p>
        </div>
        <div class="content is-small">
          <p>
            初回登録日&nbsp;&nbsp;&nbsp;:{{ formatDate(rs.data.registerDt)
            }}<br />
            更新日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{{
              formatDate(rs.data.updatedDt)
            }}<br />
            更新予定日&nbsp;&nbsp;&nbsp;:{{
              formatDate(rs.data.scheduledUpdateDate)
            }}<br />
          </p>
          <router-link
            class="button is-primary is-small is-rounded z-button is-wide"
            :to="{
              name: 'realtor-setting-realestates-detail',
              params: { id: rs.id },
            }"
          >
            表示/変更
          </router-link>
        </div>
      </div>
      <!--
      <div class="realestate-card__con__dt">
        <div class="content is-small">
          <p>
            初回登録日&nbsp;&nbsp;&nbsp;:{{ formatDate(rs.data.registerDt)
            }}<br />
            更新日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{{
              formatDate(rs.data.updatedDt)
            }}<br />
            更新予定日&nbsp;&nbsp;&nbsp;:{{
              formatDate(rs.data.scheduledUpdateDate)
            }}<br />
          </p>
        </div>
        <router-link
          class="button is-primary is-small is-rounded z-button"
          :to="{
            name: 'realtor-setting-realestates-detail',
            params: { id: rs.id }
          }"
        >
          表示/変更
        </router-link>
      </div>
      -->
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "relator-RealEstateCard",
  props: {
    realEstate: {
      type: [Object],
      required: true,
    },
  },
  methods: {
    formatDate(dt) {
      const date = new Date(dt);
      return date ? this.$$calender$dateFormat(date) : "-";
    },
    // async address(dt) {
    //   const text = await this.$$area$generateTextOfAddress(dt);
    //   return text;
    // },
  },
  computed: {
    address() {
      const rs = this.rs;
      if (rs.data.overseasRealEstate) {
        const os = rs.data.overseas;
        return (
          os.country.text +
          ", " +
          os.state +
          ", " +
          os.address +
          ", " +
          os.building
        );
      } else {
        return (
          rs.data.prefecture.text +
          rs.data.city.cityName +
          rs.data.address +
          rs.data.building
        );
      }
    },
    rs() {
      return this.realEstate._source;
    },
    images() {
      return []
        .concat(this.rs.data.images.exteriors)
        .concat(this.rs.data.images.interiors)
        .concat(this.rs.data.images.others);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realestate-card {
  &__wrap {
    border-top: 1px solid whitesmoke;
    margin-bottom: 12px;
    width: 100%;
  }
  &__red {
    font-weight: bold;
    font-size: 1.5em;
    color: red;
  }
  &__con {
    margin-top: 12px;
    width: 100%;
    display: flex;
    @include sp {
      flex-wrap: wrap;
    }
    &__image {
      width: 130px;
      @include sp {
        width: 30%;
      }
    }
    &__desc {
      margin-left: 8px;
      flex: 1;
      width: 100%;
      @include sp {
        margin-left: 8px;
        width: calc(70% - 8px);
      }

      &__dec {
        display: flex;
        width: 100%;
        &__const {
          width: 50%;
        }
        &__buil {
          width: 50%;
        }
      }
    }
    // &__pub {
    //   margin-left: 16px;
    //   width: 136px;
    // }
    &__msg {
      margin-left: 8px;
      width: 196px;
      @include sp {
        width: 100%;
        margin-left: 0px;
      }
    }
    // &__dt {
    //   margin-left: 16px;
    //   flex: 1;
    // }
  }
}
</style>
