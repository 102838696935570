<template>
  <div class="z-header">
    <div class="z-header__main">
      <h1 class="z-header__main__title">{{ title }}</h1>
      <p class="z-header__main__description">{{ sub }}</p>
    </div>
    <div class="z-header__sub" v-if="!bulma.isMobile">
      <slot name="rightIcons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    sub: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-header {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  padding: 0 46px;
  border-bottom: 1px solid #dbdbdb;
  @include sp {
    margin-bottom: 16px;
    padding: 0 26px;
    border-bottom: 1px solid #dbdbdb;
  }
  &__main {
    padding: 24px 0 20px;
    flex: 1 1;
    @include sp {
      padding: 16px 0 8px;
    }
    &__title {
      display: inline-block;
      vertical-align: middle;
      font-family: $family-secondary;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.05em;
      color: #333;
      @include sp {
        display: block;
        font-size: 32px;
        line-height: 32px;
      }
    }
    &__description {
      display: inline-block;
      vertical-align: middle;
      margin-left: 12px;
      font-size: 13px;
      line-height: 30px;
      font-weight: bold;
      letter-spacing: 0.005em;
      color: #aaa;
      @include sp {
        display: block;
        margin-top: 6px;
        margin-left: 0;
        line-height: normal;
      }
    }
  }
  &__sub {
  }
}
</style>
