<template>
  <div class="tooltip">
    <template v-if="type === types.messages">
      <!--
      <template
        v-if="
          lastestMessage.message &&
            lastestMessage.message._source.data.investor === this.id
        "
        >
      -->
      <template v-if="mycount">
        <a
          href="javascript:void(0)"
          class="tooltip--link"
          @mouseover="onShowTooltip"
        >
          詳細をみる
        </a>
      </template>
      <template v-else>
        <p>&nbsp;</p>
      </template>
    </template>
    <template v-else>
      <a
        href="javascript:void(0)"
        class="tooltip--link"
        @mouseover="onShowTooltip"
      >
        {{ count }}件
      </a>
    </template>
    <div
      class="tooltip--detail"
      :class="{
        'tooltip--detail__normal': type !== types.messages,
        'tooltip--detail__message': type === types.messages,
      }"
      v-bind:id="`${type}-${id}`"
    >
      <div class="tooltip--header">
        <p>{{ title }}</p>
        <span class="tooltip--close" @click="onHideTooltip"></span>
      </div>
      <div class="tooltip--body">
        <!-- 最近のメッセージ -->
        <template v-if="type === types.messages">
          <template
            v-if="
              lastestMessage.message &&
              lastestMessage.message._source.data.investor === this.id
            "
          >
            <p class="tooltip--title">
              {{ lastestMessage.message._source.data.title }}
            </p>
            <template v-if="lastestMessage.realEstates.length > 0">
              <p class="tooltip--subtitle">ご提案物件・案件</p>
              <div class="tooltip--building--container">
                <div
                  class="tooltip--building--item"
                  v-for="item in lastestMessage.realEstates"
                  v-bind:key="item._source.id"
                >
                  <figure class="tooltip--building--image">
                    <z-image
                      v-model="$$selectRealEstates$image(item._source.data)[0]"
                    />
                  </figure>
                  <div class="tooltip--building--text">
                    <p>
                      {{
                        $$selectRealEstates$realEstateType(
                          item._source.data.realEstateType
                        )
                      }}
                    </p>
                    <p class="tooltip--building--text__highlight">
                      {{
                        $$selectRealEstates$priceCouponYieldRate(
                          item._source.data.price,
                          item._source.data.couponYieldRate
                        )
                      }}
                    </p>
                    <p>
                      {{
                        $$selectRealEstates$location(
                          item._source.data.prefecture,
                          item._source.data.city
                        )
                      }}
                    </p>
                    <a
                      class="tooltip--building--link"
                      @click="
                        $$selectRealEstates$routerRealEstate(item._source.id)
                      "
                    >
                      詳しくみる
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <template
              v-if="lastestMessage.message._source.data.threads.length > 0"
            >
              <p class="tooltip--subtitle">メッセージ内容</p>
              <div class="tooltip--message--container">
                <div
                  class="tooltip--message--block"
                  :class="{
                    'is-receive': item.from === id,
                  }"
                  v-for="(item, index) in lastestMessage.message._source.data
                    .threads"
                  v-bind:key="index"
                >
                  <figure class="tooltip--message--avatar">
                    <template v-if="item.from === id">
                      <z-profile-thumbnail v-model="basicInfo.profile.image" />
                    </template>
                    <template v-else-if="me.user && item.from === me.user.id">
                      <z-profile-thumbnail
                        v-model="me.user.data.profile.image"
                      />
                    </template>
                  </figure>
                  <p class="tooltip--message--name">
                    <template v-if="item.from === id">
                      <span>
                        {{ basicInfo.base.name }}
                      </span>
                    </template>
                    <template v-else-if="me.user && item.from === me.user.id">
                      <span>
                        {{ me.user.data.base.name }}
                      </span>
                    </template>
                    <span>
                      {{ $$calender$dateFormat(new Date(item.sendDt)) }}
                    </span>
                  </p>
                  <p class="tooltip--message--content">
                    {{ item.contents }}
                  </p>
                </div>
              </div>
            </template>
          </template>
        </template>
        <!-- /最近のメッセージ -->
        <template v-else>
          <div
            class="tooltip--block"
            v-for="(item, index) in data"
            v-bind:key="index"
          >
            <p class="tooltip--block--title">{{ title }}{{ index + 1 }}</p>
            <div class="tooltip--block--content">
              <!-- 買いニーズ -->
              <template v-if="type === types.buyingNeeds">
                <template v-if="item.area.isSelected">
                  <p>エリア：{{ $$area(item.area) }}</p>
                </template>
                <template v-if="item.Line.isSelected">
                  <p>沿線：{{ $$line(item.Line) }}</p>
                </template>
                <p>希望物件価格：{{ $$priceFromTo(item.askingPrice) }}</p>
                <p>表面利回り：{{ $$yieldRateFromTo(item.couponYieldRate) }}</p>
                <p>物件種別：{{ $$types(item.realEstateTypes) }}</p>
                <p>建物種別：{{ $$types(item.buildingTypes) }}</p>
                <p>築年数：{{ $$ageOfBuildingFromTo(item.ageOfBuilding) }}</p>
                <p>
                  最寄り駅までの徒歩分数：{{ item.transport.elapsedTime.text }}
                </p>
                <p>
                  自己資金・担保可能金額：{{
                    $$isNullOrEmpty(item.ownFund)
                      ? "N/A"
                      : $$price$priceJPFormat(item.ownFund)
                  }}
                </p>
                <p>
                  <span class="tooltip--block--content__break"
                    >コメント：{{ item.comment }}</span
                  >
                </p>
              </template>
              <!-- /買いニーズ -->
              <!-- 保育不動産 -->
              <template v-else-if="type === types.realEstate">
                <p>
                  物件種別：{{ $$realEstateType(item.data.realEstateType) }}
                </p>
                <p></p>
                <p>購入金額：{{ purchasePrice(item.data.purchase.amount) }}</p>
                <p>
                  表面利回り：{{ couponYieldRate(item.data.couponYieldRate) }}
                </p>
                <p>所在地：{{ $$location(item.data) }}</p>
                <p>
                  沿線：
                  {{ $$traffic(item.data.transports) }}
                </p>
                <p>建物種別：{{ $$buildingType(item.data.buildingType) }}</p>
                <p>
                  築年月：{{
                    $$yearOfconstruction(
                      item.data.realEstateType,
                      item.data.constructionDate
                    )
                  }}
                </p>
                <p>建物面積：{{ $$buildingArea(item.data.buildingArea) }}</p>
                <p>
                  土地面積：{{ $$buildingArea(item.data.saleSetting.landArea) }}
                </p>
                <p>階数：{{ $$numberOfFloors(item.data.numberOfFloors) }}</p>
                <template
                  v-if="
                    [$$authority().company, $$authority().ziritz].includes(
                      $$user$permission.searchInvestors
                    )
                  "
                >
                  <p>
                    融資金額：{{
                      $$price$priceJPFormat(item.data.purchase.loan.amount)
                    }}
                  </p>
                  <p>
                    融資実行年月：{{
                      $$loanExecutionDate(item.data.purchase.loan.executeDate)
                    }}
                  </p>
                  <p>
                    金融機関・支店名：{{ $$loanBank(item.data.purchase.loan) }}
                  </p>
                  <p>
                    返済期間：{{
                      $$paymentPeriod(item.data.purchase.loan.paymentPeriods)
                    }}
                  </p>
                  <p>
                    金利：{{
                      $$interestRate(item.data.purchase.loan.interestRate)
                    }}
                  </p>
                  <p>
                    返済方法：{{
                      $$paymentMethod(item.data.purchase.loan.method)
                    }}
                  </p>
                </template>
              </template>
              <!-- /保育不動産 -->
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as RealtorModule from "@/modules/realtor/realestate";
import * as MessageModule from "@/modules/message/message";
import DisplayTextMixin from "./DisplayTextMixin";
import SelectedRealEstatesDisplayTextMixin from "@/views/realtor/common/DisplayTextMixin";

export default {
  name: "investor-tooltip-buying-needs",
  mixins: [DisplayTextMixin, SelectedRealEstatesDisplayTextMixin],
  props: {
    id: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    mycount: {
      type: Number,
      require: true,
    },
    count: {
      type: Number,
      require: true,
    },
    data: {
      type: Array,
    },
    basicInfo: {
      type: Object,
    },
  },
  data: function () {
    return {
      isLoading: true,
      show: false,
      types: {
        buyingNeeds: "buyingNeeds",
        realEstate: "realEstate",
        messages: "messages",
      },
      lastestMessage: {
        message: null,
        realEstates: [],
      },
    };
  },
  methods: {
    // ツールチップを表示
    async onShowTooltip() {
      this.show = true;
      for (let element of document.getElementsByClassName("tooltip--detail")) {
        element.style.display = "none";
      }
      document.getElementById(`${this.type}-${this.id}`).style.display =
        "block";
    },
    // ツールチップを未表示
    onHideTooltip() {
      this.show = false;
      document.getElementById(`${this.type}-${this.id}`).style.display = "none";
    },
    // 購入金額を表示
    purchasePrice(purchase) {
      let result = [];

      if (purchase < 20000000) {
        result = [0, 20000000];
      } else if (purchase < 30000000) {
        result = [20000000, 30000000];
      } else if (purchase < 40000000) {
        result = [30000000, 40000000];
      } else if (purchase < 50000000) {
        result = [40000000, 50000000];
      } else if (purchase < 100000000) {
        result = [50000000, 100000000];
      } else if (purchase < 200000000) {
        result = [100000000, 200000000];
      } else if (purchase < 300000000) {
        result = [200000000, 300000000];
      } else if (purchase < 400000000) {
        result = [300000000, 400000000];
      } else if (purchase < 500000000) {
        result = [400000000, 500000000];
      } else {
        result = [500000000, ""];
      }

      result = result.map((item) => {
        if (item.length === 0) return "";
        return this.$$price$priceJPFormat(item).replace("円", "");
      });
      return result.join("~");
    },
    // 表面利回りを表示
    couponYieldRate(couponYieldRate) {
      let result = [];

      if (couponYieldRate < 0.04) {
        result = [0, 0.04];
      } else if (couponYieldRate < 0.05) {
        result = [0.04, 0.05];
      } else if (couponYieldRate < 0.06) {
        result = [0.05, 0.06];
      } else if (couponYieldRate < 0.07) {
        result = [0.06, 0.07];
      } else if (couponYieldRate < 0.08) {
        result = [0.07, 0.08];
      } else if (couponYieldRate < 0.09) {
        result = [0.08, 0.09];
      } else if (couponYieldRate < 0.1) {
        result = [0.09, 0.1];
      } else if (couponYieldRate < 0.15) {
        result = [0.1, 0.15];
      } else if (couponYieldRate < 0.2) {
        result = [0.15, 0.2];
      } else {
        result = [0.2, ""];
      }

      result = result.map((item) => {
        if (item.length === 0) return "";
        return this.$$rete$rateFormat(item);
      });
      return result.join("~");
    },
    async getLastestMessage() {
      // if (!this.show) return;
      if (this.type !== this.types.messages) return;
      if (!this.me.user) return;

      // const result = []
      const result = await MessageModule.searchByRealtorAndInvestor(
        this.me.user.id,
        this.id,
        {
          "data.latestSendDt": "desc",
        },
        {
          size: 1,
        }
      );

      if (result.hits.length === 0) return;

      this.lastestMessage.message = result.hits[0];

      // 送信した物件リストを取得
      const resultRealEstate = await RealtorModule.search({
        query: {
          terms: {
            _id: this.lastestMessage.message._source.data.realtorRealEstates,
          },
        },
      });
      if (resultRealEstate.hits.length > 0) {
        this.lastestMessage.realEstates = resultRealEstate.hits;
      }
    },
  },
  watch: {
    show(val) {
      if (val && !this.lastestMessage.message) {
        this.getLastestMessage();
      }
    },
  },
  // asyncComputed: {
  //   // 最近のメッセージを表示
  //   async getLastestMessage() {
  //     // if (!this.show) return;
  //     if (this.type !== this.types.messages) return;
  //     if (!this.me.user) return;

  //     // const result = []
  //     const result = await MessageModule.searchByRealtorAndInvestor(
  //       this.me.user.id,
  //       this.id,
  //       {
  //         "data.latestSendDt": "desc"
  //       },
  //       {
  //         size: 1
  //       }
  //     );

  //     if (result.hits.length === 0) return;

  //     this.lastestMessage.message = result.hits[0];

  //     // 送信した物件リストを取得
  //     const resultRealEstate = await RealtorModule.search({
  //       query: {
  //         terms: {
  //           _id: this.lastestMessage.message._source.data.realtorRealEstates
  //         }
  //       }
  //     });
  //     if (resultRealEstate.hits.length > 0) {
  //       this.lastestMessage.realEstates = resultRealEstate.hits;
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;

  &--detail {
    color: $white;
    display: none;
    position: absolute;
    top: -40px;
    z-index: 1;

    &__normal {
      left: 50px;
      width: 300px;

      &:before {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 12px solid $tooltip-background-color;
        content: " ";
        left: -12px;
        position: absolute;
        top: 40px;
      }
    }

    &__message {
      left: -650px;
      width: 620px;

      &:before {
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 12px solid $tooltip-background-color;
        content: " ";
        right: -12px;
        position: absolute;
        top: 40px;
      }
    }
  }

  &--header {
    background-color: $tooltip-sub-background-color;
    font-size: 14px;
    height: 30px;
    padding: 5px 18px;
    position: relative;
  }

  &--body {
    background-color: $tooltip-background-color;
    max-height: 400px;
    padding: 18px;
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 24px;
    }

    &::-webkit-scrollbar-track {
      border: solid 8px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $tooltip-sub-background-color;
      border: solid 8px transparent;
      border-radius: 12px;
    }
  }

  &--block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--title {
      font-size: 16px;
      font-weight: bold;
    }

    &--content {
      font-size: 14px;

      &__break {
        overflow-wrap: break-word;
        white-space: pre-line;
      }
    }
  }

  &--title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &--subtitle {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  &--building {
    &--container {
      display: flex;
      justify-content: space-between;
    }

    &--item {
      background-color: $white;
      border-radius: 8px;
      color: $snackbar-color;
      padding: 8px 8px 24px;
      position: relative;
      width: 49%;
    }

    &--image {
      float: left;
      height: 65px;
      width: 95px;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }

    &--text {
      float: right;
      font-size: 12px;
      width: calc(100% - 103px);

      &__highlight {
        font-size: 14px;
        font-weight: bold;
        margin: 5px 0;
      }
    }

    &--link {
      color: $link;
      bottom: 4px;
      float: right;
      font-size: 14px;
      position: absolute;
      right: 16px;
      text-align: right;

      @include sp {
        bottom: 4px;
        font-size: 14px;
        right: 12px;
      }

      &:hover {
        color: $link;
      }
    }
  }

  &--message {
    &--container {
      width: 100%;
    }

    &--avatar {
      background-color: $white;
      border-radius: 50%;
      height: 40px;
      left: 15px;
      position: absolute;
      top: -30px;
      width: 40px;
      z-index: 1;
    }

    &--name {
      color: $white;
      font-size: 12px;
      left: 65px;
      position: absolute;
      top: -25px;
    }

    &--block {
      background-color: $white;
      border-radius: 8px;
      color: $snackbar-color;
      margin: 40px 0 50px;
      padding: 15px;
      position: relative;
      width: 100%;

      &.is-receive {
        background-color: #e4ecfb;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--content {
      font-size: 14px;
    }
  }

  &--close {
    background-color: $white;
    border-radius: 50%;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 6px;
    width: 18px;

    &:before,
    &:after {
      background-color: $tooltip-close-background-color;
      content: " ";
      height: 14px;
      left: 8px;
      position: absolute;
      top: 2px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &--link {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      color: $primary;
    }
  }
}
</style>
