<template>
  <div class="inputInfoStatus">
    <dl class="inputInfoStatus__dl">
      <dt><b-icon icon="check" />収入源</dt>
      <dd>{{ $$price$priceJPFormat($$user$PL.sum) }}</dd>
    </dl>

    <template v-if="assetsSum > 0">
      <dl class="inputInfoStatus__dl">
        <dt><b-icon icon="check" />資産</dt>
        <dd>{{ $$price$priceJPFormat(assetsSum) }}</dd>
      </dl>
    </template>
    <template v-else>
      <dl class="inputInfoStatus__dl is-error">
        <dt><b-icon icon="check" />資産</dt>
        <dd>
          <router-link class="button is-text" :to="{ name: assetsPath }">
            入力する<b-icon icon="pencil" />
          </router-link>
        </dd>
      </dl>
      <p class="inputInfoStatus__error">
        未入力です。プレミアムプラン登録には資産入力が必須です。
      </p>
    </template>

    <dl class="inputInfoStatus__dl">
      <dt><b-icon icon="check" />負債(保有不動産の融資額)</dt>
      <template v-if="isFirst">
        <dd>サインアップ後、設定可能</dd>
      </template>
      <template v-else>
        <dd>{{ $$price$priceJPFormat($$user$BS.liabilities.sum) }}</dd>
      </template>
    </dl>

    <dl class="inputInfoStatus__dl">
      <dt><b-icon icon="check" />純資産(自動計算)</dt>
      <dd>{{ $$price$priceJPFormat($$user$BS.netAssets.sum) }}</dd>
    </dl>

    <dl class="inputInfoStatus__dl">
      <dt><b-icon icon="check" />保有不動産登録数</dt>
      <template v-if="isFirst">
        <dd>サインアップ後、設定可能</dd>
      </template>
      <template v-else>
        <dd>{{ realEstatesLength }}件</dd>
      </template>
    </dl>
    <dl class="inputInfoStatus__dl">
      <dt><b-icon icon="check" />買いニーズ登録数</dt>
      <dd>{{ buyingNeedsLength }}件</dd>
    </dl>

    <!--
    {{ this.me.user.buyingNeeds }}
    <template v-if="buyingNeedsLength > 0">
      <dl class="inputInfoStatus__dl">
        <dt><b-icon icon="check" />買いニーズ登録数</dt>
        <dd>{{ buyingNeedsLength }}件</dd>
      </dl>
    </template>
    <template v-else>
      <dl class="inputInfoStatus__dl is-error">
        <dt><b-icon icon="check" />買いニーズ登録数</dt>
        <dd>
          <router-link class="button is-text" :to="{ name: buyingNeedsPath }">
            入力する<b-icon icon="pencil" />
          </router-link>
        </dd>
      </dl>
      <p class="inputInfoStatus__error">
        未入力です。プレミアムプラン登録には買いニーズ入力が必須です。
      </p>
    </template>
    -->
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "InputInfoStatus",
  props: {
    isFirst: Boolean,
  },
  computed: {
    assetsSum() {
      return this.$$user$BS.assets.sum;
    },
    realEstatesLength() {
      if (this.me.user.realEstates) {
        return this.me.user.realEstates.length;
      }
      return 0;
    },
    buyingNeedsLength() {
      if (this.me.user.data.buyingNeeds) {
        return this.me.user.data.buyingNeeds.length;
      }
      return 0;
    },
    assetsPath() {
      if (this.isFirst) return "investor-register-assets";
      else return "investor-portfolio";
    },
    buyingNeedsPath() {
      if (this.isFirst) return "investor-register-buyingneeds";
      else return "investor-home";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.inputInfoStatus {
  &__dl {
    padding: 14px 16px 10px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #dddddd;
    & > dt {
      float: left;
      /deep/ .mdi::before {
        margin-right: 8px;
        font-size: 18px;
        vertical-align: middle;
      }
    }
    & > dd {
      float: right;
      font-size: 18px;
      /deep/ .mdi::before {
        margin-left: 8px;
        font-size: 18px;
        vertical-align: middle;
      }
    }
    &.is-error {
      padding: 12px 16px 7px;
      background-color: rgba($danger, 0.1);
      border-color: $danger;
      color: $danger;
      & > dt {
        /deep/ .mdi-check {
          opacity: 0;
        }
      }
      .button {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        vertical-align: middle;
        /deep/ .mdi-check {
          font-size: 14px;
        }
      }
    }
  }
  &__error {
    padding: 8px 0 8px 40px;
    font-size: 14px;
    color: $danger;
  }
  a {
    color: $link;
    text-decoration: none;
  }
}
</style>
