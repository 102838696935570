<template>
  <div>
    <router-view></router-view>
    <hr />
    <section class="section">
      <button @click="onClick" class="button is-primary">抽出</button>
      <br />
      <template v-for="user in users">
        <div :key="user">
          {{ user }}
          <br />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
// import * as lineV1M from "@/modules/line/line_v1";
// import * as lineM from "@/modules/line/line";

// const sleep = function(msec) {
//   return new Promise(function(resolve) {
//     setTimeout(function() {
//       resolve();
//     }, msec);
//   });
// };

export default {
  name: "admin-demo-career",
  data() {
    return {
      users: [],
    };
  },
  methods: {
    async onClick() {
      const loadingComponent = this.$loading.open();
      try {
        const users = [];
        const qs = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .where("isActivate", "==", true)
          .get();

        qs.forEach((doc) => {
          const u = doc.data();
          console.log(u.profile.currentOfCareer);
          if (
            u.profile.currentOfCareer.typeOfOccupation === 805 ||
            u.profile.currentOfCareer.typeOfOccupation === 804
          ) {
            users.push(doc.id);
          }

          for (const c of u.profile.careers) {
            if (c.typeOfOccupation === 805 || c.typeOfOccupation === 804) {
              users.push(doc.id);
            }
          }
        });

        this.users = users;
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
