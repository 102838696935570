<script>
import firebase from "@/plugins/firebase";

const encodeRFC5987ValueChars = function (str) {
  return encodeURIComponent(str)
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, "%2A")
    .replace(/%(?:7C|60|5E)/g, unescape);
};

const upload = function (id, path, file) {
  return new Promise(function (resolve, reject) {
    if (file instanceof File || file instanceof Blob) {
      firebase.storage
        .child(path)
        .child(id)
        .put(file, {
          contentDisposition:
            "inline; filename*=UTF-8''" + encodeRFC5987ValueChars(file.name),
          cacheControl: "public,max-age=315360000",
        })
        .on(
          "state_changed",
          function (snapshot) {
            console.log(snapshot.state);
          },
          function (error) {
            reject(error);
          },
          function () {
            resolve(id);
          }
        );
    } else {
      resolve(id);
    }
  });
};
export default {
  name: "ImageMixin",
  methods: {
    $$image$watch: function (targes, outputs) {
      for (const image of targes) {
        const reader = new FileReader();
        reader.onload = (e) => {
          outputs.push({
            src: e.target.result,
            type: image.type,
            file: image,
            title: "",
            description: "",
          });
        };
        reader.readAsDataURL(image);
      }
    },
    $$image$upload: async function (path, image) {
      if (image.id) {
        return {
          id: image.id,
          type: image.type,
          description: image.description,
        };
      } else {
        const id = this.$uuid.v4();
        await upload(id, path, image.file);
        return {
          id: id,
          type: image.type,
          description: image.description,
        };
      }
    },
    $$images$base64pngToFileOutput(name, base64png) {
      const type = "image/jpeg";
      const data = atob(base64png.replace(/^.*,/, ""));
      const buffer = new Uint8Array(data.length);

      for (let i = 0; i < data.length; i++) {
        buffer[i] = data.charCodeAt(i);
      }

      const file = new Blob([buffer.buffer], { type });
      file.lastModifiedDate = new Date();
      file.name = "profile";

      return { type, file };
    },
  },
};
</script>
