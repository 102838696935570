<template>
  <div class="ZProfileThumbnail">
    <figure class="ZProfileThumbnail__prfImage image is-square">
      <z-image
        :rounded="true"
        :emptySrc="emptyThumbnail"
        :loadImmediate="true"
        v-model="internalValue"
      />
    </figure>
    <div
      class="ZProfileThumbnail__rank"
      v-bind:class="{
        scoreS: isScoreS,
        scoreA: isScoreA,
        scoreB: isScoreB,
        scoreC: isScoreC,
      }"
      v-if="isRankActive"
    >
      {{ rankFix }}
    </div>
  </div>
</template>

<script>
import objectFitImages from "object-fit-images";
import InputMixin from "./InputMixin";

const emptyImage = require("@/assets/empty/profileThumbnail.png");

export default {
  name: "ZProfileThumbnail",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object],
    },
    scoreObj: {
      type: Object,
      default: null,
    },
    emptySrc: {
      type: [String],
      default: null,
    },
  },
  data: function () {
    return {
      isScoreS: false,
      isScoreA: false,
      isScoreB: false,
      isScoreC: false,
    };
  },
  created() {
    if (this.scoreObj !== null) {
      switch (this.scoreObj.rank) {
        case 1:
          this.isScoreS = true;
          break;
        case 2:
          this.isScoreA = true;
          break;
        case 3:
          this.isScoreB = true;
          break;
        case 4:
          this.isScoreC = true;
          break;
        default:
          throw Error(
            "Can not match score object in messages",
            this.scoreObj.rank
          );
      }
    }
  },
  computed: {
    emptyThumbnail() {
      return this.emptySrc || emptyImage;
    },
    rankFix() {
      return this.$$ks.getRank(this.scoreObj.rank).text;
    },
    isRankActive() {
      if (this.scoreObj === null) {
        return false;
      } else {
        if (this.scoreObj.score === 0) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  mounted() {
    objectFitImages();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.ZProfileThumbnail {
  height: 100%;
  width: 100%;
  /deep/ img {
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
  &__rank {
    position: absolute;
    right: -2px;
    bottom: -2px;
    height: 20px;
    width: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    background-color: #ffffff;
    border-radius: 50%;
  }
}
.scoreS {
  background-color: #0a244e;
}
.scoreA {
  background-color: #1f57b2;
}
.scoreB {
  background-color: #5a8ece;
}
.scoreC {
  background-color: #93aecf;
}
//.z-profileThumbnail {
//  width: 80px;
//  height: 80px;
//  margin: 0 auto;
//  border-radius: 50%;
//  background-color: rgba(#fff, 0.3);
//  background-repeat: none;
//  background-size: cover;
//  background-position: center center;
//  box-shadow: 0px 0px 39px 0px rgba(0, 1, 1, 0.45);
//}
</style>
