<script>
import firebase from "@/plugins/firebase";
import { cloneDeep } from "lodash";

const type = {};

type.user = {
  uid: null,
  isAdmin: true,
  isActivate: false,
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
  base: {
    email: "",
    name: "",
    kana: "",
    phoneNumber: "",
    department: "",
  },
};

export default {
  name: "Mixins-admin",
  methods: {
    $$admin$getNewUser: function () {
      return {
        id: null,
        data: cloneDeep(type.user),
      };
    },
    $$admin$save: function (uid, user) {
      const now = firebase.serverTimestamp();
      user.data.updatedBy = uid;
      user.data.updatedDt = now;

      return Promise.resolve().then(() => {
        return firebase.db
          .collection("users_v1")
          .doc(user.id)
          .set(user.data, { merge: true });
      });
    },
    $$admin$loadUser: async function (uid) {
      const doc = await firebase.db.collection("users_v1").doc(uid).get();

      if (doc.exists) {
        const user = {
          id: doc.id,
          data: this.$$assign(type.user, doc.data()),
        };
        return user;
      } else {
        return null;
      }
    },
    $$admin$changeAdminStatus: async function (uid, admin) {
      return await firebase
        .functions("setCustomTokenAdmin", {
          uid: uid,
          admin: admin,
        })
        .then((re) => {
          return re.data;
        });
    },
    $$admin$restoreExclusiveMessageCount: async function () {
      return await firebase
        .functions("restoreExclusiveMessageCount")
        .then((re) => {
          return re.data.result;
        });
    },
    $$admin$updateBSAndPLChartData: async function (filter) {
      return await firebase
        .functions("updateBSAndPLChartData", {
          filter: filter,
        })
        .then((re) => {
          return re.data.result;
        });
    },
    $$admin$updateMembership: async function () {
      return await firebase.functions("updateMembership").then((re) => {
        return re.data.result;
      });
    },
  },
};
</script>
