<template>
  <section class="z-section">
    <div class="z-section__inner">
      <div class="parent">
        <div class="child">
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tileProfile"
          >
            <template v-slot:title>
              <div class="title">
                <p class="title__titleTxt">プロフィール</p>
              </div>
            </template>
            <template v-slot:body>
              <div class="portfolioDetail">
                <div class="portfolioDetail__profileArea">
                  <ul class="portfolioDetail__profileArea__profileList">
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">氏名</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ userName }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">性別</p>
                        <p class="mainItem__txt">{{ userSex }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">生年月日</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ userBirthday }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">住所</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ userAddress }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">電話番号</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ userPhoneNumber }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__profileArea__profileList__profileItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">プロフィール画像</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                      </div>
                      <ZProfileThumbnail
                        class="profileImg"
                        :value="profileImage"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-slot:overlay>
              <EditProfile ref="editProfile" @saved="savedProfile()" />
            </template>
          </tile-box>
        </div>
        <div class="child">
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tileWorkExperience"
          >
            <template v-slot:title>
              <div class="title">
                <p class="title__titleTxt">職務経歴</p>
              </div>
            </template>
            <template v-slot:body>
              <div class="portfolioDetail">
                <div class="portfolioDetail__careerArea">
                  <ul class="portfolioDetail__careerArea__careerList">
                    <li class="careerUnit">
                      <ul class="careerUnit__careerList">
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">メイン職業</p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">{{ currentCareerName }}</p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">業種</p>
                            <p class="mainItem__txt">
                              {{ currentCareerBusiness }}
                            </p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">職種</p>
                            <p class="mainItem__txt">
                              {{ currentCareerOccupation }}
                            </p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">在籍期間</p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">
                              {{ currentCareerPeriod }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li
                      class="careerUnit"
                      v-for="(career, career_index) in careerList"
                      :key="career_index"
                    >
                      <ul class="careerUnit__careerList">
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">
                              {{ pastCareersLabel(career_index) }}
                            </p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">
                              {{ pastCareersName(career_index) }}
                            </p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">業種</p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">
                              {{ pastCareersBusiness(career_index) }}
                            </p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">職種</p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">
                              {{ pastCareersOccupation(career_index) }}
                            </p>
                          </div>
                        </li>
                        <li class="careerUnit__careerList__careerItem">
                          <div class="mainItem">
                            <p class="mainItem__label">在籍期間</p>
                            <div class="mainItem__privateTag">
                              <p class="mainItem__privateTag__txt">非公開</p>
                              <b-icon
                                class="mainItem__privateTag__icon"
                                icon="lock"
                                size="is-small"
                              ></b-icon>
                            </div>
                            <p class="mainItem__txt">
                              {{ pastCareersPeriod(career_index) }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="portfolioDetail__educationArea">
                  <ul class="portfolioDetail__educationArea__educationList">
                    <li
                      class="portfolioDetail__educationArea__educationList__educationItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">最終学歴</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ educationName }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__educationArea__educationList__educationItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">学歴区分</p>
                        <p class="mainItem__txt">{{ educationType }}</p>
                      </div>
                    </li>
                    <li
                      class="portfolioDetail__educationArea__educationList__educationItem"
                    >
                      <div class="mainItem">
                        <p class="mainItem__label">学部・学科</p>
                        <div class="mainItem__privateTag">
                          <p class="mainItem__privateTag__txt">非公開</p>
                          <b-icon
                            class="mainItem__privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                        <p class="mainItem__txt">{{ educationDepartment }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <template v-slot:overlay>
              <EditWorkExperience
                ref="editWorkExperience"
                @saved="savedWorkExperience()"
              />
            </template>
          </tile-box>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { get } from "lodash";
import EditProfile from "./EditProfile";
import EditWorkExperience from "./EditWorkExperience";
import ZProfileThumbnail from "@/components/parts/ZProfileThumbnail.vue";

export default {
  name: "Profile-Info",
  components: {
    EditProfile: EditProfile,
    EditWorkExperience: EditWorkExperience,
    ZProfileThumbnail: ZProfileThumbnail,
  },
  computed: {
    userName: function () {
      return (
        this.me.user.data.base.name + "(" + this.me.user.data.base.kana + ")"
      );
    },
    userSex: function () {
      return this.$$ks.getTypeOfSex(this.me.user.data.base.sex).text;
    },
    userBirthday: function () {
      return String(
        this.$$calender$dateFormat(this.me.user.data.base.birthday)
      );
    },
    userPhoneNumber: function () {
      return this.me.user.data.base.phoneNumber;
    },
    currentCareerName: function () {
      return this.me.user.data.profile.currentOfCareer.name;
    },
    currentCareerBusiness: function () {
      return this.$$ks.getCategoryOfBusiness(
        this.me.user.data.profile.currentOfCareer.categoryOfBusiness
      ).text;
    },
    currentCareerOccupation: function () {
      return this.$$ks.getTypeOfOccupation(
        this.me.user.data.profile.currentOfCareer.typeOfOccupation
      ).text;
    },
    currentCareerPeriod: function () {
      const currentCareer = this.me.user.data.profile.currentOfCareer;
      if (!currentCareer.isCurrent) {
        return (
          String(
            currentCareer.period.from.year +
              "/" +
              currentCareer.period.from.month
          ) +
          " 〜 " +
          String(
            currentCareer.period.toDt.year +
              "/" +
              currentCareer.period.toDt.month
          )
        );
      } else {
        return String(
          currentCareer.period.from.year +
            "/" +
            currentCareer.period.from.month +
            " 〜 (在籍中)"
        );
      }
    },
    careerList: function () {
      return this.me.user.data.profile.careers;
    },
    educationName: function () {
      const eName = this.me.user.data.profile.education.name;
      if (!eName.length) {
        return "-";
      }
      return eName;
    },
    educationType: function () {
      return this.$$ks.getEducationType(
        this.me.user.data.profile.education.type
      ).text;
    },
    educationDepartment: function () {
      const department = this.me.user.data.profile.education.department;
      if (!department.length) {
        return "-";
      }
      return department;
    },
    profileImage() {
      return get(
        this.me.user,
        "data.profile.image",
        this.$$investor$getNewImage()
      );
    },
  },
  asyncComputed: {
    async userAddress() {
      const text = await this.$$area$generateTextOfAddress(
        this.me.user.data.base
      );
      return text;
    },
  },
  methods: {
    pastCareersLabel: function (index) {
      const fixedIndex = index + 1;
      if (fixedIndex <= 9) {
        return "経験職業" + "0" + String(fixedIndex);
      } else {
        return "経験職業" + String(fixedIndex);
      }
    },
    pastCareersName: function (index) {
      return this.me.user.data.profile.careers[index].name;
    },
    pastCareersBusiness: function (index) {
      return this.$$ks.getCategoryOfBusiness(
        this.me.user.data.profile.careers[index].categoryOfBusiness
      ).text;
    },
    pastCareersOccupation: function (index) {
      return this.$$ks.getTypeOfOccupation(
        this.me.user.data.profile.careers[index].typeOfOccupation
      ).text;
    },
    pastCareersPeriod: function (index) {
      const pastCareer = this.me.user.data.profile.careers[index];
      if (!pastCareer.isCurrent) {
        return (
          String(
            pastCareer.period.from.year + "/" + pastCareer.period.from.month
          ) +
          " 〜 " +
          String(
            pastCareer.period.toDt.year + "/" + pastCareer.period.toDt.month
          )
        );
      } else {
        return String(
          pastCareer.period.from.year +
            "/" +
            pastCareer.period.from.month +
            " 〜 (在籍中)"
        );
      }
    },
    savedProfile() {
      this.$refs.tileProfile.close();
    },
    savedWorkExperience() {
      this.$refs.tileWorkExperience.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.title {
  height: 42px;
  padding: 0 16px;
  &__titleTxt {
    font-size: 16px;
    font-weight: normal;
    line-height: 42px;
  }
}

.portfolioDetail {
  padding: 16px;
  &__profileArea {
    &__profileList {
      list-style-type: none;
      &__profileItem {
        padding-top: 20px;
        &:first-child {
          padding-top: 0;
          .mainItem__txt {
            font-weight: bold;
          }
        }
      }
    }
  }
  &__careerArea {
    &__careerList {
      list-style-type: none;
      :first-child {
        padding-top: 0;
      }
    }
  }
  &__educationArea {
    padding-top: 16px;
    &__educationList {
      list-style-type: none;
      &__educationItem {
        padding-top: 20px;
        &:first-child {
          padding-top: 0;
          .mainItem__txt {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.careerUnit {
  border-bottom: solid 1px;
  border-color: #dddddd;
  padding-top: 16px;
  padding-bottom: 12px;
  &__careerList {
    &__careerItem {
      padding-top: 20px;
      &:first-child {
        .mainItem__txt {
          font-weight: bold;
        }
      }
    }
  }
}

.mainItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &__label {
    font-size: 12px;
    font-weight: bold;
    color: #777777;
  }
  &__privateTag {
    height: 14px;
    width: 56px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #dddddd;
    &__txt {
      font-size: 12px;
      line-height: 14px;
      padding-left: 4px;
      color: #666666;
    }
    &__icon {
      height: 14px;
      width: 14px;
      font-size: 10px;
      padding-left: 1px;
      display: inline-block;
      vertical-align: middle;
      color: #666666;
    }
  }
  &__txt {
    font-size: 16px;
    flex: 0 0 100%;
    color: #333333;
    word-break: break-word;
  }
}

.profileImg {
  width: 80px;
  height: 80px;
  padding-top: 0;
  margin-top: 10px;
}

@include lg {
  .parent {
    display: flex;
    align-items: stretch;
    & > div:first-child {
      padding: 26px 25px 0 0px;
    }
    & > div:last-child {
      padding: 26px 0px 0 25px;
    }
    .child {
      flex: 0 0 50%;
      max-width: 50%;
      & > * {
        height: 100%;
      }
    }
  }
}

@include md {
  .parent {
    display: flex;
    align-items: stretch;
    & > div:first-child {
      padding: 26px 15px 0 0px;
    }
    & > div:last-child {
      padding: 26px 0px 0 15px;
    }
    .child {
      flex: 0 0 50%;
      max-width: 50%;
      & > * {
        height: 100%;
      }
    }
  }
}

@include sp {
  .parent {
    & > div:first-child {
      padding-top: 20px;
    }
    & > div:last-child {
      padding-top: 16px;
    }
  }

  .title {
    height: 32px;
    padding: 0 14px;
    &__titleTxt {
      font-size: 14px;
      line-height: 32px;
    }
  }

  .mainItem {
    &__privateTag {
      height: 12px;
      width: 45px;
      border-radius: 4px;

      &__txt {
        font-size: 10px;
        line-height: 12px;
        padding-left: 2px;
      }
      &__icon {
        height: 12px;
        width: 12px;
        font-size: 8px;
      }
    }
    &__txt {
      font-size: 14px;
    }
  }
}
</style>
