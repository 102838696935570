<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="container">
        <div class="content">
          <h4>専門家(コンサルタント)詳細</h4>
        </div>
        <b-tabs>
          <b-tab-item label="お知らせ">
            <Information :uid="uid" />
          </b-tab-item>
          <b-tab-item label="否認">
            <template v-if="user.id">
              <div class="box">
                <RealtorExamination
                  :examination="user.examination"
                  :user="user"
                ></RealtorExamination>
              </div>
            </template>
          </b-tab-item>
          <b-tab-item label="情報">
            <div class="buttons">
              <button
                :disabled="$$validator$invalid"
                class="button is-primary"
                @click="save"
              >
                保存
              </button>
              <template v-if="user.examination.data.status === 5">
                <button
                  :disabled="$$validator$invalid"
                  class="button is-warning"
                  @click="sendMail"
                >
                  招待メールを送る
                </button>
              </template>
            </div>
            <z-input
              label="メールアドレス"
              v-model="user.data.base.email"
              type="email"
              maxlength="512"
              isRequired
              :validate="'required|email'"
              icon="email"
            >
            </z-input>
            <hr />
            <z-input
              label="スコア"
              v-model.number="user.data.score.score"
              maxlength="3"
              isRequired
              :validate="'required'"
            />
            <z-select
              label="ランク"
              v-model="user.data.score.rank"
              :options="$$ks.ranks"
              isRequired
              :validate="'required'"
            />
            <hr />
            <RealtorUserBasic :user="user"> </RealtorUserBasic>
            <hr />
            <template v-if="uid !== 'new'">
              <RealtorUserProfile :user="user"> </RealtorUserProfile>
              <hr />
              <RealtorUserRole :user="user"> </RealtorUserRole>
              <hr />
            </template>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import RealtorExamination from "@/views/admin/components/RealtorUserExamination";
import RealtorUserRole from "@/components/realtor/EditUserRole";
import RealtorUserBasic from "@/components/realtor/FormUserBasic";
import RealtorUserProfile from "@/components/realtor/FormUserProfile";
import Information from "@/views/admin/components/Information.vue";

export default {
  name: "admin-setting-realtors-detail",
  components: {
    //    RealtorUser: RealtorUser,
    Information: Information,
    RealtorUserRole: RealtorUserRole,
    RealtorExamination: RealtorExamination,
    RealtorUserBasic: RealtorUserBasic,
    RealtorUserProfile: RealtorUserProfile,
  },
  data: function () {
    return {
      isLoading: true,
      activeTab: 0,
      realtor: null,
      user: null,
    };
  },
  computed: {
    uid: function () {
      return this.$route.params.uid;
    },
    realtorId: function () {
      return this.$route.params.cid;
    },
  },
  methods: {
    async sendMail() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$sendVerificationMail(this.auth.uid, this.user);

        this.$$toast$openSuccess("招待メールを送信しました");
        // this.$toast.open({
        //   message: "招待メールを送信しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("招待メールの送信に失敗しました");
        // this.$toast.open({
        //   duration: 10000,
        //   message: "招待メールの送信に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        if (this.uid === "new") {
          await this.$$realtor$createUser(
            this.auth.uid,
            this.realtor,
            this.user
          );
        } else {
          await this.$$realtor$saveUser(this.auth.uid, this.user);
        }

        this.$router.go(-1);
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.realtor = await this.$$realtor$loadRealtorById(this.realtorId);
        if (this.realtor) {
          if (this.uid === "new") {
            this.user = this.$$realtor$getNewUser();
            this.user.data.role = 10; //プライマリー会員
          } else {
            this.user = await this.$$realtor$loadUser(this.uid);
          }
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
        this.isLoading = false;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
