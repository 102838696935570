import firebase from "@/plugins/firebase";
import messageType from "@/types/messageType";
import kubuns from "@/kubuns/kubuns";
import { queryPagination } from "@/modules/pagination";

import {
  cloneDeep,
  //  defaultsDeep,
} from "lodash";

const sortTypes = [
  {
    value: 1,
    text: "送信日時",
    field: {
      "data.latestSendDt": {
        order: "desc",
      },
    },
  },
];
const docRef = firebase.db.collection("messages_v1");

export const createNewMessage = () => {
  const message = {
    id: null,
    data: cloneDeep(messageType.type.message),
  };
  return message;
};

// ****
//
// placeholders = [{
//  text: 'score'
//  value: 456
// }];
export const replacePlaceholder = (message, placeholders) => {
  return message.replace(RegExp("{{(.+?)}}", "g"), (match, prop) => {
    const v = placeholders.find((p) => p.text === prop);
    if (v) {
      return String(v.value);
    }
    return match;
  });
};

export const createMessages = async (
  uids, //uid[]
  from,
  to,
  message,
  thread,
  realtorRealEstates = [], //専門家の不動産
  realEstates = [] //投資家の不動産
) => {
  const ps = [];
  for (const uid of uids) {
    ps.push(
      createMessage(
        uid,
        from,
        to,
        message,
        thread,
        realtorRealEstates,
        realEstates
      )
    );
  }
  return Promise.all(ps);
};

export const createMessage = async (
  uid,
  from,
  to,
  message,
  thread,
  realtorRealEstates = [], //専門家の不動産
  realEstates = [] //投資家の不動産
) => {
  const now = firebase.serverTimestamp();

  message.data.createdBy = uid;
  message.data.createdDt = now;
  message.data.updatedBy = uid;
  message.data.updatedDt = now;
  message.data.latestSendDt = now;
  message.data.uids.push(from);
  message.data.uids.push(to);
  message.data.asReads = [from];

  if (message.data.type === 4) {
    //問い合せのみ逆転
    message.data.investor = from;
    message.data.realtor = to;
  } else {
    message.data.investor = to;
    message.data.realtor = from;
  }

  message.data.latestTo = to;
  message.data.latestFrom = from;
  message.data.realtorRealEstates = realtorRealEstates.concat();
  message.data.realEstates = realEstates.concat();

  thread.createdBy = uid;
  thread.createdDt = now;
  thread.updatedBy = uid;
  thread.updatedDt = now;
  thread.sendDt = now;
  thread.from = from;
  thread.to = to;

  message.data.threads.push(thread);

  console.log(message);
  const newmessage = await docRef.add(message.data).then((ref) => {
    return {
      id: ref.id,
      data: message.data,
    };
  });
  return newmessage;
};

// LINKだけは下記のロジックでvue側で生成して下さい
//   const link =
//     location.protocol +
//     "//" +
//     location.host +
//     this.$router.resolve({
//       name: "realtor-message-detail",
//       params: {
//         id: m.id
//       }
//     }).href;
export const sendMailMessageToRealtor = async (message, realtor, link) => {
  // const m = await this.$$message$loadMessage(mid);
  const m = message;
  const mtype = kubuns.message.getMailType(m.data.type);
  // console.log(m);
  await firebase.functions("sendMailOfMessgeToRealtor", {
    to: {
      email: realtor.data.base.email,
      name: realtor.data.base.name,
    },
    message: m,
    link: link,
    messageType: mtype.text,
    type: mtype,
  });
  return m;
};

// *
// LINKだけは下記のロジックでvue側で生成して下さい
// const link =
//   location.protocol +
//   "//" +
//   location.host +
//   this.$router.resolve({
//     name: "investor-message-detail",
//     params: {
//       id: m.id
//     }
//   }).href;
//
export const sendMailMessageToInvestor = async (message, investor, link) => {
  // const m = await this.$$message$loadMessage(mid);
  const m = message;
  const mtype = kubuns.message.getMailType(m.data.type);
  const score = m.realtor.data.score;
  const rank = score.score ? kubuns.getRank(score.rank).text : 0;
  await firebase.functions("sendMailOfMessgeToInvestor", {
    to: {
      email: investor.data.base.email,
      name: investor.data.base.name,
    },
    message: m,
    link: link,
    messageType: mtype.text,
    rank: rank,
    type: mtype,
  });
  return m;
};

///--- search

const defaultQuery = {
  query: {
    match_all: {},
  },
  size: 200,
};

export const search = async (query) => {
  const q = query || defaultQuery;
  const ret = await firebase.functions("public_messages_search", {
    query: q,
  });
  return ret.data;
};

export const createIndex = async () => {
  const ret = await firebase.functions("public_messages_createIndex", {});
  return ret.data;
};

export const putMapping = async () => {
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_messages_putMapping", {});
  return ret.data;
};

export const searchByRealtorAndInvestor = async (
  realtorId,
  investorId,
  sort,
  pagination
) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              "data.investor.keyword": {
                value: investorId,
              },
            },
          },
          {
            term: {
              "data.realtor.keyword": {
                value: realtorId,
              },
            },
          },
        ],
      },
    },
    sort: [sort],
  };
  return await search(queryPagination(query, pagination));
};

export const searchByRealtor = async (uid, pagination) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              "data.realtor.keyword": {
                value: uid,
              },
            },
          },
        ],
      },
    },
  };

  return await search(queryPagination(query, pagination));
};

export const searchByInvestor = async (uid, pagination) => {
  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              "data.investor.keyword": {
                value: uid,
              },
            },
          },
        ],
      },
    },
  };
  return await search(queryPagination(query, pagination));
};

// const _filter_force = force => {
//   if (force) {
//     return [];
//   } else {
//     return [
//       {
//         term: {
//           "data.isActivate": true
//         }
//       }
//     ];
//   }
// };
//
const _filter_investor = (filter) => {
  const r = [];
  if (filter) {
    r.push({
      term: {
        "data.investor.keyword": filter,
      },
    });
  }
  return r;
};

const _filter_realtor = (filter) => {
  const r = [];
  if (filter) {
    r.push({
      term: {
        "data.realtor.keyword": filter,
      },
    });
  }
  return r;
};
const _filter_type = (filter) => {
  const r = [];
  if (!filter) return r;

  // message: {
  //   proposal: false,
  //   exclusive: false,
  //   buying: false,
  //   contact: false
  // },
  //
  // { value: 1, text: "エクスクルーシブ", color: "is-warning" },
  // { value: 2, text: "オファー", color: "is-info" },
  // { value: 3, text: "売却", color: "is-primary", en: "Exit" },
  // { value: 4, text: "問い合せ", color: "", en: "Inquiry" }
  const f = [];
  if (filter.proposal) {
    f.push(2);
  }
  if (filter.exclusive) {
    f.push(1);
  }
  if (filter.buying) {
    f.push(3);
  }
  if (filter.contact) {
    f.push(4);
  }

  if (f.length) {
    r.push({
      terms: {
        "data.type": f,
      },
    });
  }
  return r;
};

const _filter_status_withReply = (filter) => {
  const r = [];
  //   withReply: true
  if (!filter) return r;

  if (filter.withReply) {
    r.push({
      bool: {
        must: [
          {
            script: {
              script: {
                lang: "painless",
                source: `
                  List ts = doc['data.threads.from.keyword'];
                  String inr = doc['data.investor.keyword'].value;
                  int i = 0;
                  for (t in ts) {
                    if (t == inr) {
                      i = i + 1;
                    }
                  }
                  return i > 0;
                  `,
              },
            },
          },
        ],
      },
    });
  }
  return r;
};

const _filter_status = (filter, owner) => {
  const r = [];
  if (!filter || !owner) return r;
  // status: {
  //   unRead: false,
  //   asRead: false,
  //   unanswered: false,
  //   withReply: true
  // }
  // if (!message.data.asReads.some(r => r === uid)) {
  //   return 1; //既読
  // } else if (message.data.latestFrom !== uid) {
  //   return 2; //既読で未返信
  // }
  // return 0; //未読

  const f = [];
  if (filter.asRead) {
    f.push({
      terms: {
        "data.asReads.keyword": [owner],
      },
    });
  }
  if (filter.unRead) {
    f.push({
      bool: {
        must_not: [
          {
            terms: {
              "data.asReads.keyword": [owner],
            },
          },
        ],
      },
    });
  }
  //   unanswered: false,
  if (filter.unanswered) {
    f.push({
      bool: {
        must_not: [
          {
            term: {
              "data.latestFrom.keyword": owner,
            },
          },
        ],
      },
    });
  }
  r.push({
    bool: {
      should: f,
    },
  });

  return r;
};

const _filter_score = (filter) => {
  // console.log(filter);
  const r = [];

  if (!filter) return r;
  if (filter.from && filter.from !== kubuns.filters.score.min) {
    r.push({
      range: {
        "investor.user.data.latestScore.score": {
          gte: filter.from,
        },
      },
    });
  }

  if (filter.to && filter.to !== kubuns.filters.score.max) {
    r.push({
      range: {
        "investor.user.data.latestScore.score": {
          lte: filter.to,
        },
      },
    });
  }

  return r;
};
export const buildQuery = (
  filter,
  sortType = null,
  page = null,
  perPage = null,
  owner = null,
  force = false
) => {
  const query = {
    bool: {
      must: [],
    },
  };
  query.bool.must = []
    .concat(_filter_investor(filter.investor))
    .concat(_filter_realtor(filter.realtor))
    .concat(_filter_type(filter.message))
    .concat(_filter_score(filter.score))
    .concat(_filter_status_withReply(filter.status, owner))
    .concat(_filter_status(filter.status, owner));

  console.log(query);
  // const sort = [];
  const sort = sortType
    ? sortTypes.filter((s) => s.value === sortType).map((s) => s.field)
    : [];

  const from = (page - 1) * perPage;

  return {
    query,
    sort,
    from: from > 0 ? from : 0,
    size: perPage || 9999,
  };
};

export const searchByfilter = async (
  filter,
  sortType = null,
  page = null,
  perPage = null,
  owner = null,
  force = false
) => {
  const q = buildQuery(filter, sortType, page, perPage, owner, force);
  console.log(q);
  return await search(q);
};
export const makeAllIndex = async () => {
  // console.log(query);
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_messages_makeAll", {});
  return ret.data;
};

export const deleteIndex = async () => {
  const ret = await firebase.functions("public_messages_deleteIndex", {});
  return ret.data;
};

export const getIndex = async () => {
  const ret = await firebase.functions("public_messages_getIndex", {});
  return ret.data;
};
