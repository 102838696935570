<template>
  <div>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <div class="columns">
          <div class="column is-full">
            <h4 class="edit__subtitle2">エリア・沿線の選択</h4>
            <ZAreaLineFieldSet
              ref="areaLineFieldSet"
              v-model="filter"
              hideRequired
            />
            <div class="levels">
              <div class="level-right">
                <button class="button is-primary is-wide" @click="execFilter">
                  検索する
                </button>
              </div>
            </div>
            <hr id="searched-scroll" />
          </div>
        </div>
        <div class="levels">
          <div class="level-right count">
            該当件数
            <span class="count__number"
              >{{ $$num$commaFormat(this.realEstates.length) }}件</span
            >
          </div>
        </div>
        <div class="columns">
          <div class="column is-narrow">
            <ZEditAccordion
              title="絞り込み検索条件"
              icon="magnify"
              isProtrude
              :opened="true"
            >
              <h4 class="edit__subtitle2 menu__title">種別</h4>
              <ul>
                <template v-for="realEstateType in $$ks.realEstateTypes">
                  <div :key="realEstateType.value">
                    <li>
                      <div class="content menu__checkBox">
                        <b-checkbox
                          v-model="filter.realEstateTypes[realEstateType.value]"
                          type="is-info"
                        />
                        <span class="menu__checkBox__label">{{
                          realEstateType.text
                        }}</span>
                      </div>
                    </li>
                  </div>
                </template>
              </ul>
              <h4 class="edit__subtitle2 menu__title">構造</h4>
              <ul>
                <template v-for="buildingType in $$ks.typeOfBuildingTypes">
                  <div :key="buildingType.value">
                    <li>
                      <div class="content menu__checkBox">
                        <b-checkbox
                          v-model="filter.buildingTypes[buildingType.value]"
                          type="is-info"
                        />
                        <span class="menu__checkBox__label">{{
                          buildingType.text
                        }}</span>
                      </div>
                    </li>
                  </div>
                </template>
              </ul>
              <h4 class="edit__subtitle2 menu__title">価格</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.price.from"
                  :options="$$ks.filters.price.from"
                />
                <span class="menu__select__tilde">~</span>
                <z-select
                  class="menu__select__input"
                  v-model="filter.price.to"
                  :options="$$ks.filters.price.to"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">表面利回り</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.yieldRate.from"
                  :options="$$ks.filters.yieldRate.from"
                />
                <span class="menu__select__tilde">~</span>
                <z-select
                  class="menu__select__input"
                  v-model="filter.yieldRate.to"
                  :options="$$ks.filters.yieldRate.to"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">築年数</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.yearOfConstruction.from"
                  :options="$$ks.filters.yearOfconstruction.from"
                />
                <span class="menu__select__tilde">~</span>
                <z-select
                  class="menu__select__input"
                  v-model="filter.yearOfConstruction.to"
                  :options="$$ks.filters.yearOfconstruction.to"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">駅からの距離</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.elapsedTime"
                  :options="$$ks.filters.elapsedTime"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">建物面積</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.buildingArea.from"
                  :options="$$ks.filters.buildingArea.from"
                />
                <span class="menu__select__tilde">~</span>
                <z-select
                  class="menu__select__input"
                  v-model="filter.buildingArea.to"
                  :options="$$ks.filters.buildingArea.to"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">土地面積</h4>
              <div class="menu__select">
                <z-select
                  class="menu__select__input"
                  v-model="filter.landArea.from"
                  :options="$$ks.filters.landArea.from"
                />
                <span class="menu__select__tilde">~</span>
                <z-select
                  class="menu__select__input"
                  v-model="filter.landArea.to"
                  :options="$$ks.filters.landArea.to"
                />
              </div>
              <h4 class="edit__subtitle2 menu__title">間取り</h4>
              <template v-for="(layout, i) in $$ks.layouts">
                <div :key="layout.value" class="menu__layouts">
                  <div class="content menu__checkBox">
                    <b-checkbox
                      v-model="filter.layouts[layout.value]"
                      type="is-info"
                    />
                    <span class="menu__checkBox__label">{{ layout.text }}</span>
                  </div>
                </div>
                <br :key="layout.value + 'br'" v-if="i % 3 === 0" />
              </template>
              <button
                class="button is-primary menu__button"
                @click="execFilter"
              >
                検索する
              </button>
            </ZEditAccordion>
          </div>
          <div class="realestate-list">
            <h4 class="list-title">物件検索結果</h4>
            <div class="levels">
              <div class="level-right sort">
                <label class="sort__title">並び替え</label>
                <z-select
                  v-model="sortType"
                  :options="sortTypes"
                  @input="sortRealEstates"
                />
              </div>
            </div>
            <b-table
              :data="realEstates"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              current-page.sync="currentPage"
              :row-class="(row, index) => rowClass(row)"
              per-page="50"
              paginated
              custom-row-key="id"
              :hoverable="true"
              :narrowed="true"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <template slot-scope="props">
                <b-table-column field="id" class="realestate-list__column">
                  <!--
                  <template
                    v-if="
                      props.row.data.saleSetting.publicType === 2 &&
                        !$$user$isPremium
                    "
                  >
                    <NotPremiumCard />
                  </template>
                  <template v-else>
                  -->
                  <div class="realestate-list__detail">
                    <RealEstate
                      @click="onRowClick(props.row)"
                      v-model="props.row"
                    />
                    <hr class="realestate-list__hr" />
                    <div class="realestate-list__footer">
                      <div class="is-size-7 realestate-list__footer__date">
                        <p>
                          {{
                            $$calender$monthFormat(props.row.data.registerDt)
                          }}登録
                        </p>
                        <br />
                      </div>
                      <div>
                        <button
                          @click="onOfferClick(props.row)"
                          class="button z-button is-small is-rounded is-primary"
                        >
                          <span class="icon is-small">
                            <i class="mdi mdi-24px mdi-email-outline"></i>
                          </span>
                          <span> お問い合せ </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--
                  </template>
                    -->
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

//import RealEstateList from "@/views/realtor/common/RealEstateList";
import RealEstate from "./RealEstate";
// import NotPremiumCard from "./NotPremiumCard";

// import blurImage01 from "@/assets/realEstate/blur01.png";
// import blurImage02 from "@/assets/realEstate/blur02.png";
// import blurImageSP01 from "@/assets/realEstate/blur01-sp.png";
// import blurImageSP02 from "@/assets/realEstate/blur02-sp.png";

export default {
  name: "investor-search-realestates",
  components: {
    RealEstate: RealEstate,
    // NotPremiumCard: NotPremiumCard
  },
  data: function () {
    return {
      // currentTabIndex: 0,
      // query: this.$route.query.ctg,
      // blurImage01: blurImage01,
      // blurImage02: blurImage02,
      // blurImageSP01: blurImageSP01,
      // blurImageSP02: blurImageSP02,
      filter: {
        yieldRate: {
          from: 0,
          to: 100,
        },
        price: {
          from: 0,
          to: 9999999999,
        },
        yearOfConstruction: {
          from: 0,
          to: 999,
        },
        elapsedTime: 999,
        realEstateTypes: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
          10: false,
        },
        buildingTypes: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
        layouts: {
          10: false,
          101: false,
          110: false,
          120: false,
          201: false,
          210: false,
          220: false,
          301: false,
          310: false,
          320: false,
          401: false,
          410: false,
          420: false,
          500: false,
        },
        buildingArea: {
          from: 0,
          to: 9999,
        },
        landArea: {
          from: 0,
          to: 9999,
        },
        area: {
          isSelected: false,
          prefecture: null, // 県
          cities: [], // 市町村
        },
        Line: {
          isSelected: false,
          prefecture: null, // 県
          line: null, // 沿線
          stations: [], // 駅
        },
      },
      sortTypes: [
        { value: 1, text: "新着順" },
        { value: 2, text: "価格の安い順" },
        { value: 3, text: "価格の高い順" },
        { value: 4, text: "利回りの高い順" },
        { value: 5, text: "築年数の新しい順" },
      ],
      isLoading: true,
      currentPage: 0,
      realEstates: [],
      sortType: 1,
    };
  },
  computed: {
    currentTabIndex: {
      get() {
        const ctg = this.$route.query.ctg;
        if (ctg === "jp") {
          return 0;
        }
        if (ctg === "os") {
          return 1;
        } else {
          return 0;
        }
      },
      set(val) {
        const ctg = val === 0 ? "jp" : "os";
        if (this.$route.query.ctg != ctg) {
          this.$router.push({
            name: "investor-search-realestates",
            query: { ctg: ctg },
          });
        }
      },
    },
  },
  // watch: {
  //   // $route() {
  //   //   const ctg = this.$route.query.ctg;
  //   //   if (ctg === "os") this.currentTabIndex = 1;
  //   // },
  //   currentTabIndex(to) {
  //     const ctg = to === 0 ? "jp" : "os";
  //     if (this.$route.query.ctg != ctg)
  //       this.$router.push({ name: "investor-search-realestates", query: { ctg: ctg } });
  //   }
  // },
  methods: {
    rowClass(realsetate) {
      return null;
      // if (
      //   realsetate.data.saleSetting.publicType === 2 &&
      //   !this.$$user$isPremium
      // ) {
      //   return "is-needMask";
      // } else {
      //   return null;
      // }
    },
    checkNull(val) {
      // console.log(val);
      // console.log(val == NaN);
      // console.log(val == "NaN");
      if (val && val != "NaN") return val;
      else return "- ";
    },
    onRowClick(realEstate) {
      const routeData = this.$router.resolve({
        name: "investor-search-realestates-detail",
        params: { id: realEstate.id },
      });
      window.open(routeData.href, "_blank");
    },
    onOfferClick(realEstate) {
      this.$router.push({
        name: "investor-message-detail",
        params: { id: "new" },
        query: {
          realEstateId: realEstate.id,
          messageType: 4,
        },
      });
    },
    async execFilter() {
      await this.loadFilter();
      this.$scrollTo("#searched-scroll");
    },
    async loadFilter() {
      this.realEstates = this.$$realtor$filterByPublicType(
        await this.$$realtor$loadRealEstatesByFilterByJp(this.filter),
        this.$$user$isPremium
      );
      this.sortRealEstates();
      // this.$scrollTo("#searched-scroll");
    },
    async init() {
      //const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        // await this.loadFilter();
        // console.log("fuga");
        // console.log(this.realEstates);
        this.realEstates = this.$$realtor$filterByPublicType(
          await this.$$realtor$loadRealEstatesByJp(),
          this.$$user$isPremium
        );
        this.sortRealEstates();

        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
    buildingTypeText(val) {
      return this.$$ks.getTypeOfBuildingType(val).text;
    },
    sortRealEstates() {
      if (this.sortType === 1) {
        this.realEstates.sort((reA, reB) => {
          if (reA.data.registerDt < reB.data.registerDt) return 1;
          if (reA.data.registerDt > reB.data.registerDt) return -1;
          return 0;
        });
      }
      if (this.sortType === 2) {
        this.realEstates.sort((reA, reB) => {
          if (reA.data.price > reB.data.price) return 1;
          if (reA.data.price < reB.data.price) return -1;
          return 0;
        });
      }
      if (this.sortType === 3) {
        this.realEstates.sort((reA, reB) => {
          if (reA.data.price < reB.data.price) return 1;
          if (reA.data.price > reB.data.price) return -1;
          return 0;
        });
      }
      if (this.sortType === 4) {
        this.realEstates.sort((reA, reB) => {
          if (reA.data.couponYieldRate < reB.data.couponYieldRate) return 1;
          if (reA.data.couponYieldRate > reB.data.couponYieldRate) return -1;
          return 0;
        });
      }
      if (this.sortType === 5) {
        this.realEstates.sort((reA, reB) => {
          const yocA = this.$$realEstate$calcDayOfConstruction(
            reA.data.constructionDate
          );
          const yocB = this.$$realEstate$calcDayOfConstruction(
            reB.data.constructionDate
          );
          if (yocA > yocB) return 1;
          if (yocA < yocB) return -1;
          return 0;
        });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    return this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.menu {
  &__title {
    margin-top: 17px;
  }
  &__layouts {
    display: inline-block;
    margin-right: 35px;
  }
  &__checkBox {
    font-size: 14px;
    &__label {
      color: #333333;
      vertical-align: 4px;
    }
  }
  &__select {
    display: flex;
    &__input {
      width: 38%;
    }
    &__tilde {
      margin: 8px;
    }
  }
  &__button {
    width: 100%;
    margin-top: 20px;
  }
}

.count {
  margin-top: -25px;
  margin-bottom: 10px;
  &__number {
    font-size: 1.3rem;
    font-weight: bold;
    color: #f00;
    padding-left: 5px;
    line-height: 3px;
  }
}

.list-title {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 0px;
  padding: 11px 22px;
  background-color: $important;
  border-bottom: solid 2px #707070;
  color: $white;
}

.sort {
  margin-top: 15px;
  margin-bottom: -25px;
  &__title {
    margin: -28px 8px 0 0;
    font-weight: bold;
  }
}

.realestate-list {
  margin-left: 20px;
  width: 100%;
  max-width: 1020px;
  @include sp {
    margin-left: 0;
  }
  &__column {
    &:hover {
      outline: solid 2px $important;
    }
  }
  &__detail {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &__hr {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
      align-items: center;
    }
    &__date {
      margin-bottom: 8px;
      text-align: left;
      width: 100%;
      @include sp {
        text-align: right;
      }
    }
  }
}
@include md {
  .realestate-list__footer {
    width: 50vw !important;
  }
}

.blur-realestate {
  &__warp {
    position: relative;
  }
  &__content {
    @include md {
      margin: 20px 16px 0px;
    }
    position: absolute;
    margin: 120px 16px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    &__icon {
      vertical-align: -3px;
    }
    &__title {
      line-height: 28px;
      font-size: 20px;
      @include sp {
        font-size: 18px;
      }
    }
  }
}
</style>
