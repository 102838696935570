// import Vue from "vue";
// import Router from "vue-router";

import Home from "@/views/Home";
// import accountAccount from "@/views/account/Account";

import options from "@/routers/options";
const RequiresAuth = options.RequiresAuth;
const role = options.role;
const examination = options.examination;

const routes = [
  {
    path: "/",
    name: "/home",
    component: Home,
    meta: {
      examination: examination.all,
      role: role.none,
      requiresAuth: RequiresAuth.required,
    },
  },
  //{
  //  path: "/resetpassword",
  //  name: "/resetpassword",
  //  component: ResetPassword,
  //  meta: {
  //    requiresAuth: RequiresAuth.noRequired
  //  }
  //},
  //{
  //  path: "/account/account",
  //  name: "/account/account",
  //  component: accountAccount,
  //  meta: {
  //    requiresAuth: RequiresAuth.required
  //  }
  //}
  //{
  //  path: "/signin",
  //  name: "/signin",
  //  component: SignIn,
  //  meta: {
  //    requiresAuth: RequiresAuth.noRequired
  //  }
  //}
];

export default { routes: routes };
