<template>
  <div ref="text">{{ text }}</div>
</template>
<script>
import { truncate } from "truncator";

export default {
  name: "ZTruncator",
  props: {
    text: {
      type: String,
      default: "",
    },
    line: {
      type: Number,
      default: 1,
    },
    //cls: {
    //  type: [String, Array],
    //  default: "",
    //},
    //styles: {
    //  type: [String, Array],
    //  default: "",
    //},
  },
  data: function () {
    return {
      observer: null,
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      this.truncateTitle();
    });
    window.addEventListener("resize", this.truncateTitle);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.truncateTitle);
  },
  computed: {},
  methods: {
    truncateTitle() {
      const titleRef = this.$refs.text;
      const titleOrg = this.text;
      var opt = {
        line: this.line,
        ellipsis: "...",
      };
      truncate(titleRef, titleOrg, opt);
      // var truncator = truncate(titleRef, titleOrg, opt);
    },
  },
  watch: {
    text: {
      handler: function (newVal) {
        if (newVal) {
          this.truncateTitle();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.content p {
  margin-bottom: 0px;
}
.z-truncate-p {
  margin-bottom: 0px;
}
</style>
