<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告" sub="登録完了">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <section class="section message-wrap">
      <div class="content has-text-centered">
        <h4>売買申告のご登録<br class="br-show" />ありがとうございました</h4>
        <br />

        <p>
          売買申告のお手続きが完了いたしました。<br />後日、ご登録いただきました振込先口座に<br
            class="br-show"
          />キャッシュバック金をご入金いたします。
        </p>
        <br />

        <p>
          【キャッシュバック対象】<br />プレミアムプラン会員の方（※）：<br
            class="br-show"
          />仲介手数料10％<br />無料会員の方：一律3万円
        </p>
        <p class="notice">
          （※）不動産売買契約日及び不動産決済日の両方の時点で<br
            class="br-show"
          />プレミアム会員に加入している方
        </p>
        <br class="br-sp" />

        <p>
          引き続き、StockFormerのご利用<br
            class="br-show"
          />よろしくお願いいたします。
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "transaction-review-finish",
  data: function () {
    return {
      isLoading: true,
    };
  },
  methods: {
    async init() {
      // try {
      //   return;
      // } catch (error) {
      //   this.$$error$catchLoadError(error);
      // } finally {
      //   this.isLoading = false;
      // }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.br-show {
  display: none;
  @include sp {
    display: block;
  }
}

.message-wrap {
  @include sp {
    padding: 46px 20px;
  }
}

.notice {
  font-size: 10px;
}
</style>
