var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realtor-favorite-view"},[_c('div',{staticClass:"realtor-favorite-view__content",class:{ 'realtor-favorite-view--fa999': _vm.favorite.data.type === 999 }},[_c('div',{staticClass:"realtor-favorite-view__profile",on:{"click":_vm.onClickRealtor}},[_c('div',{staticClass:"realtor-favorite-view__profile__image"},[_c('div',{staticClass:"realtor-favorite-view__profile__image__mask",class:{
            'realtor-favorite-view__profile__image__mask--fa1':
              _vm.favorite.data.type === 1,
          }}),_c('z-profile-thumbnail',{staticClass:"realtor-favorite-view__profile__image__img",class:{
            'realtor-favorite-view__profile__image__img--fa1':
              _vm.favorite.data.type === 1,
          },model:{value:(_vm.realtor.data.profile.image),callback:function ($$v) {_vm.$set(_vm.realtor.data.profile, "image", $$v)},expression:"realtor.data.profile.image"}}),_c('div',{staticClass:"realtor-favorite-view__profile__image__star",class:{
            'realtor-favorite-view__profile__image__star--fa1':
              _vm.favorite.data.type === 1,
          }},[_c('img',{attrs:{"src":require("@/assets/favorite/fa_star.png")}})])],1),_c('div',{staticClass:"realtor-favorite-view__profile__content"},[_c('div',{staticClass:"realtor-favorite-view__profile__content__name"},[_c('p',[_c('z-truncator',{attrs:{"line":1,"text":_vm.realtor.data.base.name}})],1)]),_c('div',{staticClass:"realtor-favorite-view__profile__content__cname"},[_c('p',[_c('z-truncator',{attrs:{"line":_vm.bulma.isMobile ? 2 : 1,"text":_vm.realtor.company.data.name +
                ' ' +
                _vm.realtor.data.company.department +
                ' ' +
                (_vm.realtor.data.company.title || '')}})],1)]),_c('hr',{staticClass:"realtor-favorite-view__hr",class:{
            'realtor-favorite-view__hr--fa999': _vm.favorite.data.type === 999,
          }}),_c('div',{staticClass:"realtor-favorite-view__profile__content__ex"},[_vm._m(0),_c('div',{staticClass:"realtor-favorite-view__profile__content__ex__content"},[_c('p',[_vm._v(_vm._s(_vm.yearOfexperience))])])]),_c('div',{staticClass:"realtor-favorite-view__profile__content__spec"},[_vm._m(1),_c('div',{staticClass:"realtor-favorite-view__profile__content__spec__content"},[_c('p',[_c('z-truncator',{attrs:{"line":_vm.bulma.isMobile ? 3 : 1,"text":_vm.specialties}})],1)])])])]),_c('div',{staticClass:"realtor-favorite-view__favorite",class:{
        'realtor-favorite-view__favorite--fa999': _vm.favorite.data.type === 999,
      }},[_vm._m(2),_c('div',{staticClass:"realtor-favorite-view__favorite__fa",class:{
          'realtor-favorite-view__favorite__fa--activate':
            _vm.favorite.data.type === 1,
        }},[_c('RealtorFavoriteButton',{attrs:{"favoriteType":1,"favorite":_vm.favorite.data.type},on:{"click":function($event){return _vm.onClickFa(1)}}}),_c('p',[_vm._v("優先提案")])],1),_c('div',{staticClass:"realtor-favorite-view__favorite__fa",class:{
          'realtor-favorite-view__favorite__fa--activate':
            _vm.favorite.data.type === 2,
        }},[_c('RealtorFavoriteButton',{attrs:{"favoriteType":2,"favorite":_vm.favorite.data.type},on:{"click":function($event){return _vm.onClickFa(2)}}}),_c('p',[_vm._v("継続")])],1),_c('div',{staticClass:"realtor-favorite-view__favorite__fa"},[_c('RealtorFavoriteButton',{attrs:{"favoriteType":999,"favorite":_vm.favorite.data.type},on:{"click":function($event){return _vm.onClickFa(999)}}}),_c('p',[_vm._v("拒否")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realtor-favorite-view__profile__content__ex__title"},[_c('p',[_vm._v("不動産経験:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realtor-favorite-view__profile__content__spec__title"},[_c('p',[_vm._v("得意領域:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"realtor-favorite-view__favorite__title"},[_c('p',[_vm._v("担当者を評価する")])])
}]

export { render, staticRenderFns }