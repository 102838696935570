// import Vue from "vue";
// import Router from "vue-router";
// import Home from "./views/Home";

// import store from "@/store";
//

import index from "@/views/auth/Index";
import Registor from "@/views/auth/Registor";
import signIn from "@/views/auth/SignIn";
import resetPassword from "@/views/auth/ResetPassword";
import PasswordConfirm from "@/views/auth/PasswordConfirm";

import adminAuthSignUp from "@/views/auth/admin/SignUp";
import adminAuthSentMail from "@/views/auth/admin/SentMail";
import adminAuthRegister from "@/views/auth/admin/Register";

import investorAuthSignUp from "@/views/auth/investor/SignUp";
import investorAuthSentMail from "@/views/auth/investor/SentMail";
import investorAuthRegister from "@/views/auth/investor/Register";

import realtorAuthSignIn from "@/views/auth/realtor/SignIn";
//import realtorAuthSentMail from "@/views/auth/realtor/SentMail";
//import realtorAuthRegister from "@/views/auth/realtor/Register";
import realtorAuthApplication from "@/views/auth/realtor/Application";
import realtorAuthConfirmApplication from "@/views/auth/realtor/ConfirmApplication";
import realtorAuthContact from "@/views/auth/realtor/Contact";
import realtorAuthConfirmContact from "@/views/auth/realtor/ConfirmContact";

import realtorAuthRegister from "@/views/auth/realtor/Register";

import realtorWelcomeWelcome from "@/views/auth/realtor/welcome/Welcome";
import realtorWelcomeBasic from "@/views/auth/realtor/welcome/Basic";
import realtorWelcomeProfile from "@/views/auth/realtor/welcome/Profile";
import realtorWelcomeFinish from "@/views/auth/realtor/welcome/Finish";
// import realtorWelcomeSales from "@/views/auth/realtor/welcome/Sales";

import options from "@/routers/options";
const RequiresAuth = options.RequiresAuth;
const role = options.role;
const examination = options.examination;

const routes = [
  {
    path: "/auth/",
    component: index,
    meta: {
      examination: examination.all,
      role: role.none,
      requiresAuth: RequiresAuth.noRequired,
    },
    children: [
      {
        path: "signin",
        name: "auth-signin",
        component: signIn,
        meta: {
          title: "LOG IN ログイン",
          examination: examination.all,
          role: role.none,
          // requiresAuth: RequiresAuth.noRequired,
          requiresAuth: RequiresAuth.none,
        },
      },
      {
        path: "realtor/signin",
        name: "auth-relator-signin",
        component: realtorAuthSignIn,
        meta: {
          title: "LOG IN ログイン",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "resetpassword/confirm",
        name: "auth-resetpassword-confirm",
        component: PasswordConfirm,
        meta: {
          title: "Rest Passowrd リセットパスワード",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "resetpassword",
        name: "auth-resetpassword",
        component: resetPassword,
        meta: {
          title: "Rest Passowrd リセットパスワード",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "realtor/application",
        name: "auth-realtor-application",
        component: realtorAuthApplication,
        meta: {
          title: "法人のお客様へ",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.none,
        },
      },
      {
        path: "realtor/application/confirm",
        name: "auth-realtor-application-confirm",
        component: realtorAuthConfirmApplication,
        meta: {
          title: "法人のお客様へ",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.none,
        },
      },
      {
        path: "realtor/contact",
        name: "auth-realtor-contact",
        component: realtorAuthContact,
        meta: {
          title: "お問い合せ",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.none,
        },
      },
      {
        path: "realtor/contact/confirm",
        name: "auth-realtor-contact-confirm",
        component: realtorAuthConfirmContact,
        meta: {
          title: "お問い合せ",
          examination: examination.all,
          role: role.none,
          requiresAuth: RequiresAuth.none,
        },
      },
      {
        path: "realtor/register",
        name: "auth-realtor-register",
        component: realtorAuthRegister,
        meta: {
          title: "お申し込み完了",
          examination: examination.all,
          role: role.realtor,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "investor/signup",
        name: "auth-investor-signup",
        component: investorAuthSignUp,
        meta: {
          title: "お申し込み",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.none,
          // requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "investor/register",
        name: "auth-investor-register",
        component: investorAuthRegister,
        meta: {
          title: "アカウント情報の登録",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "investor/sentmail",
        name: "auth-investor-sentmail",
        component: investorAuthSentMail,
        meta: {
          title: "ご確認メールをお送りしました",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "admin/signup",
        name: "auth-admin-signup",
        component: adminAuthSignUp,
        meta: {
          examination: examination.all,
          role: role.admin,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "admin/register",
        name: "auth-admin-register",
        component: adminAuthRegister,
        meta: {
          examination: examination.all,
          role: role.admin,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
      {
        path: "admin/sentmail",
        name: "auth-admin-sentmail",
        component: adminAuthSentMail,
        meta: {
          examination: examination.all,
          role: role.admin,
          requiresAuth: RequiresAuth.noRequired,
        },
      },
    ],
  },
  {
    path: "/registor/",
    component: Registor,
    meta: {
      examination: examination.all,
      role: role.none,
      requiresAuth: RequiresAuth.noRequired,
    },
    children: [
      {
        path: "realtor/welcome",
        name: "auth-realtor-welcome",
        component: realtorWelcomeWelcome,
        meta: {
          examination: examination.notAllow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "realtor/welcome/basic",
        name: "auth-realtor-welcome-basic",
        component: realtorWelcomeBasic,
        meta: {
          examination: examination.notAllow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "realtor/welcome/profile",
        name: "auth-realtor-welcome-profile",
        component: realtorWelcomeProfile,
        meta: {
          examination: examination.notAllow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
      {
        path: "realtor/welcome/finish",
        name: "auth-realtor-welcome-finish",
        component: realtorWelcomeFinish,
        meta: {
          examination: examination.notAllow,
          role: role.realtor,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
];

export default { routes: routes };
