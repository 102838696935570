<script>
export default {
  name: "ESQueryMixin",
  methods: {
    // ES用のクエリを作成
    $$createQuery: function (type, key, value) {
      return {
        [type]: {
          [key]: value,
        },
      };
    },
    // ES用のscriptクエリを作成
    $$createScriptQuery: function (type, field, defaultValue, order) {
      return {
        _script: {
          type: type,
          script: `return doc['${field}'].size() === 0
            ? ${typeof defaultValue === "string" ? '""' : defaultValue}
            : doc['${field}'].value`,
          order: order,
        },
      };
    },
    // ES用のnestedクエリを作成
    $$createNestedQuery: function (
      path,
      subQueryMust = [],
      subQueryMustNot = [],
      subQueryShould = []
    ) {
      return {
        nested: {
          path: path,
          query: {
            bool: {
              must: subQueryMust,
              must_not: subQueryMustNot,
              should: subQueryShould,
            },
          },
        },
      };
    },
    // ES用のnestedクエリを作成
    $$createNestedWithOrderQuery: function (key, path, order, filter) {
      return {
        [key]: {
          order: order,
          nested: {
            path: path,
            filter: filter,
          },
        },
      };
    },
    // ES用のmatchクエリを作成
    $$createMatchQuery: function (key, value) {
      return this.$$createQuery("match", key, value);
    },
    // ES用のmatchクエリを作成
    $$createTermsQuery: function (key, value) {
      return this.$$createQuery("terms", key, value);
    },
    // ES用のrangeクエリを作成
    $$createRangeQuery: function (key, value) {
      return this.$$createQuery("range", key, value);
    },
    // ES用の空の文字列ではないクエリを作成
    $$createNotEmptyStringQuery: function (key) {
      return this.$$createQuery("regexp", key, ".+");
    },
    // ES用のquery_stringクエリを作成
    $$createContainSubstringQuery: function (key, value, isFullText = true) {
      return {
        query_string: {
          default_field: key,
          query: isFullText ? `*${value}*` : '"' + value + '"',
        },
      };
    },
    // ES用の存在クエリを作成
    $$createExistsQuery: function (value) {
      return this.$$createQuery("exists", "field", value);
    },
    // ES用のnulに関係ないクエリを作成
    $$createWithNullQuery: function (type, key, value) {
      let subQuery = null;

      switch (type) {
        case "match":
          subQuery = this.$$createMatchQuery(key, value);
          break;
        case "terms":
          subQuery = this.$$createTermsQuery(key, value);
          break;
        case "range":
          subQuery = this.$$createRangeQuery(key, value);
          break;
      }

      return {
        bool: {
          should: [
            subQuery,
            {
              bool: {
                must_not: this.$$createExistsQuery(key),
              },
            },
          ],
        },
      };
    },
  },
};
</script>
