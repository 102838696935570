<template>
  <div id="app" class="app">
    <transition>
      <z-loading :isActive="$store.state.isShowLogoLoading"></z-loading>
    </transition>

    <router-view></router-view>
    <!--
      <Menu></Menu>
      <Summary></Summary>
      <div class="wrapper">
      <router-view name="Header"></router-view>
      <Header></Header>
      <Footer></Footer>
      </div>
    -->
    <!--
    <template v-if="production !== 'production'">
      <hr />
      DEBUG
      <hr />
      {{ me }}
      <hr />
      {{ auth }}
    </template>
    -->
  </div>
</template>

<script>
// import Menu from "@/components/layout/Menu";
// import Summary from "@/components/layout/Summary";
// import Header from "@/components/layout/Header";
// import Footer from "@/components/layout/Footer";
//
import AuthMixin from "@/mixins/common/auth/Auth";
import VersionsMixin from "@/mixins/common/versions/Versions";
import MaintenanceMixin from "@/mixins/Maintenance";

export default {
  name: "App",
  mixins: [AuthMixin, VersionsMixin, MaintenanceMixin],
  computed: {
    production: function () {
      return process.env.NODE_ENV;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/variables.scss";

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "~bulma-divider";
@import "vue-select/src/scss/vue-select.scss";
@import "bulma-accordion/dist/css/bulma-accordion.sass";
// base
html {
  height: 100%;
  &.iosBugFixCaret {
    // position: fixed;
    overflow: hidden !important;
  }
}
body {
  height: 100%;
  letter-spacing: 0.02em;
}

// Buefy update
.title {
  font-family: $family-secondary;
  letter-spacing: 0.1em;
}
.button {
  font-weight: bold;
  transition: 0.2s;
  &.is-text {
    font-weight: normal;
  }
}
.notices {
  padding: 0;
}

.toast {
  margin: 24px 24px 24px (24px + $menu-width + $summary-width) !important;
  min-width: 540px;
  border-radius: 8px !important;
  font-size: 16px;
  text-align: left;
  opacity: 1 !important;
  @include md {
    margin: 24px 24px 24px (24px + $menu-width-md + $summary-width-md-close) !important;
  }
  @include sp {
    margin: 24px !important;
    min-width: calc(100% - 20px) !important;
  }
}

.z-button {
  padding: 0 22px !important;
  font-size: 18px;
  height: 3.6em;
  color: #777777;
  @include sp() {
    font-size: 16px;
    height: 3em;
  }
  &.is-multiline {
    line-height: 1.2;
  }
  &.is-primary {
    min-width: 230px;
    &:disabled {
      background-color: #dddddd;
    }
  }
  &.is-wide {
    // min-width: 344px;
    width: 100%;
    @include sp() {
      width: 100%;
      min-width: auto;
    }
  }
  &.is-expanded {
    width: 100%;
    @include sp() {
      width: 100%;
    }
  }
  &.is-full-width {
    width: 100%;
    font-size: 14px;
    height: 2.3em;
  }
  &.is-small {
    font-size: 15px;
    height: 2.4em;
  }
  &.is-very-small {
    font-size: 14px;
    min-width: 200px;
  }
  &.is-rounded {
    padding: 0 36px !important;
    &.is-very-small {
      padding: 0 0 !important;
    }
  }
  &.is-text {
    padding: 0 1px !important;
    height: auto;
    font-size: inherit;
    border: none;
  }
  /deep/ .icon {
    font-size: 1.4em;
  }
}
pre.normal {
  padding: 0px;
  overflow-x: visible;
  overflow: visible;
  font-size: inherit;
  width: 100%;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: $white;
  font-family: $family-primary;
}
.z-field {
  input:-webkit-autofill,
  textarea:-webkit-autofill {
    background-color: transparent;
    background-image: none;
  }
  .input,
  .select select,
  .textarea {
    padding-left: 0;
    // padding-right: 0;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    background-color: transparent;
    &::placeholder {
      color: #aeaeae;
      opacity: 1;
    }
    &::-webkit-input-placeholder {
      color: #aeaeae;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #aeaeae;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #aeaeae;
      opacity: 1;
    }
    &[type="number"] {
      -moz-appearance: textfield; // spin-button
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &:focus {
      border-bottom-width: 2px;
    }
  }
  .select.is-empty {
    select {
      color: #aeaeae; // select placeholder
    }
  }
  &.is-danger {
    .input,
    .select select,
    .textarea {
      background-color: rgba($danger, 0.1);
      border-color: $danger;
      border-bottom-width: 2px;
    }
  }
  // .input {
  //   &:focus {
  //     transform: translateY(1px);
  //   }
  //   &.is-danger {
  //     transform: translateY(1px);
  //   }
  // }
  .select.is-danger {
    select {
      background-color: rgba($danger, 0.1);
      border-bottom-width: 2px;
    }
  }
  .autocomplete,
  .select:not(.is-multiple):not(.is-loading),
  .navbar-link:not(.is-arrowless),
  .select:not(.is-multiple):not(.is-loading):hover,
  .navbar-link:not(.is-arrowless):hover {
    &::after {
      top: 58%;
      right: 0.5em;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 5px 0 5px;
      border-color: $link transparent transparent transparent;
      transform: rotate(0);
    }
  }
  .autocomplete {
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 44%;
      right: 0.5em;
      pointer-events: none;
      transform-origin: center;
      z-index: 4;
    }
  }
  .b-checkbox.checkbox input[type="checkbox"] + .check {
    border-color: #dddddd;
    background-color: transparent;
  }
  .b-radio.radio input[type="radio"] + .check {
    border-color: #f4f4f4;
    background-color: #f4f4f4;
  }
  .b-radio.radio .control-label,
  .b-checkbox.checkbox .control-label {
    padding-left: 0.4em;
  }
  .b-radio.radio + .radio,
  .b-checkbox.checkbox + .checkbox {
    margin-left: 1.2em;
  }
  .switch {
    .control-label {
      font-size: 14px;
    }
  }
  // 単位付き
  .is-unit {
    position: relative;
    .input,
    .select {
      &::before {
        position: absolute;
        font-size: 12px;
        top: 8px;
        right: 26px;
      }
      input,
      select {
        min-width: 200px;
        @include sp {
          min-width: 120px;
        }
      }
    }
  }
  .is-unit--yen {
    .input,
    .select {
      &::before {
        content: "円";
      }
    }
  }
  .is-unit--rate {
    .input,
    .select {
      &::before {
        content: "%";
      }
    }
  }
  .is-unit--year {
    .input,
    .select {
      &::before {
        content: "年";
      }
    }
  }
}
.no-fieldMargin {
  /deep/ .z-field {
    margin-top: -28px;
  }
}
.z-radio,
.z-checkbox {
  .block {
    margin-left: -22px;
    .radio,
    .checkbox {
      margin-left: 22px !important;
      border-radius: 4px;
      font-size: 14px;
      line-height: 38px;
      &:hover {
        color: $input-color;
      }
    }
  }
}

.z-section {
  position: relative;
  width: 100%;
  &__inner {
    position: relative;
    margin: 0 auto;
    padding: 0 $section-padding;
    max-width: $contents-maxwidth;
    @include sp {
      padding: 0 $section-padding-sp;
    }
    &--investerSrc {
      padding: 0 38px;
      max-width: unset;
      width: 100%;

      @include sp {
        padding: 0 16px;
        max-width: unset;
      }
    }
  }
}

.z-tabs {
  .tabs {
    ul {
      border: none;
    }
    li {
      padding: 0 16px;
      transition: 0.2s;
      border-bottom: solid 2px #dddddd;
      @include sp {
        padding: 0 12px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        margin-bottom: -2px;
        padding: 0;
        border-bottom-width: 2px;
        font-family: $family-secondary;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0.05em;
        &:hover {
          border-color: $primary;
        }
        @include sp {
          font-size: 20px;
          line-height: 32px;
        }
      }
      &.is-active {
        a {
          font-weight: bold;
          color: $primary;
          &:hover {
            border-color: $primary;
          }
        }
      }
    }
  }
  .tab-content {
    padding: 20px 0 32px;
    @include sp {
      padding: 16px 0;
    }
  }
  &--page {
    .tabs {
      @extend .z-section__inner;
    }
  }
  &--jp {
    .tabs {
      li {
        a {
          font-size: 24px;
          @include sp {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.z-tabsSmall {
  min-height: 546px;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  .tabs {
    width: 255px;
    // height: 28px;
    margin: 0 0 0 auto;
    ul {
      border: none;
      width: 100%;
    }
    li {
      width: 85px;
      transition: 0.2s;
      border-bottom: solid 2px #dddddd;
      a {
        margin-bottom: -2px;
        padding: 0;
        border-bottom-width: 2px;
        font-family: $family-secondary;
        font-size: 15px;
        line-height: 28px;
        &:hover {
          border-color: $primary;
        }
      }
      &.is-active {
        a {
          color: $primary;
          &:hover {
            border-color: $primary;
          }
        }
      }
    }
  }
  .tab-content {
    padding: 16px 0 0 0;
  }
}

.z-tabsButton /deep/ {
  .tabs {
    ul {
      li {
        &:not(:first-child) {
          margin-left: 16px;
        }
        &.is-active {
          a {
            border-width: 2px;
            border-color: $info;
            background-color: rgba(#00997e, 0.1);
            color: $info;
            font-weight: bold;
          }
        }
        a {
          padding: 0.3em 1em;
          min-width: 88px;
          border-radius: 4px !important;
          border-color: #777777;
          background-color: #fff;
          color: #777777;
        }
      }
    }
  }
  .tab-content {
    margin-top: 24px;
    background-color: #f4f4f4;
  }
}

// edit系
.contents {
  .edit {
    margin: 0 auto 70px;
    max-width: $edit-maxwidth;
    @include sp {
      max-width: none;
    }
    &__inner,
    &__section {
      position: relative;
      padding: 0 $edit-padding;
      @include sp() {
        padding: 0 $edit-padding-sp;
      }
      &--box {
        padding: $edit-padding $edit-padding;
        background-color: #f8f8f8;
        @include sp() {
          padding: 22px $edit-padding-sp;
        }
        & > *:last-child {
          .z-field {
            margin-bottom: 0;
          }
        }
      }
    }
    &__skip {
      position: relative;
      margin-top: $edit-padding;
      // margin-bottom: 16px;
      padding: 5px $edit-padding;
      font-size: 24px;
      // background-color: #f4f3f5;
      // border-bottom: solid 2px #707070;
      // color: #838383;
      @include sp {
        padding: 9px $edit-padding-sp;
        font-size: 18px;
      }
      &__link {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        font-size: 14px;
        // color: #787878;
        @include sp {
          font-size: 12px;
        }
        & > * {
          vertical-align: middle;
          height: 14px;
        }
        /deep/ .icon {
          // color: #787878;
          .mdi::before {
            font-size: 20px;
            @include sp {
              font-size: 18px;
            }
          }
        }
      }
    }
    &__title {
      position: relative;
      margin-top: $edit-padding;
      margin-bottom: 16px;
      padding: 5px $edit-padding;
      font-size: 24px;
      background-color: #f4f3f5;
      border-bottom: solid 2px #707070;
      color: #838383;
      @include sp {
        padding: 9px $edit-padding-sp;
        font-size: 18px;
      }
      &__link {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        font-size: 14px;
        color: #787878;
        @include sp {
          font-size: 12px;
        }
        & > * {
          vertical-align: middle;
          height: 14px;
        }
        /deep/ .icon {
          color: #787878;
          .mdi::before {
            font-size: 20px;
            @include sp {
              font-size: 18px;
            }
          }
        }
      }
    }
    &__premium {
      position: absolute;
      top: 0;
      right: $edit-padding;
      font-size: 14px;
      @include sp {
        right: $edit-padding-sp;
        font-size: 12px;
      }
      /deep/ .icon {
        .mdi::before {
          font-size: 16px;
          line-height: 14px;
          @include sp {
            font-size: 14px;
          }
        }
      }
      &__icon {
        // for FireFox
        .mdi::before {
          font-size: 16px;
          line-height: 14px;
          @include sp {
            font-size: 14px;
          }
        }
      }
    }
    &__subtitle {
      padding-top: 0;
      padding-bottom: 14px;
      font-weight: bold;
      font-size: 18px;
    }
    &__subtitle2 {
      position: relative;
      padding-left: 10px;
      padding-top: 0;
      padding-bottom: 20px;
      font-size: 14px;
      line-height: 1em;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 14px;
        background-color: $primary;
      }
    }
    &__explanation {
      font-size: 13px;
      @include sp {
        padding-left: $edit-padding-sp;
        padding-right: $edit-padding-sp;
      }
    }
    &__hr {
      margin-top: 40px;
      margin-bottom: 20px;
      background-color: #707070;
    }
    &__hr2 {
      margin-top: 40px;
      margin-bottom: 20px;
      height: 1px;
      background-color: #dddddd;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &__item {
        padding: 0;
      }
    }
    &__foot {
      margin-top: 70px;
      padding: 0 $edit-padding;
      text-align: center;
      @include sp() {
        margin-top: 36px;
        padding: 0 $edit-padding-sp;
      }
      & > * {
        &:not(.is-primary) {
          width: 100px;
        }
        &:not(:first-child) {
          margin-left: 13px;
        }
      }
    }
    &__delete {
      margin-top: 24px;
      text-align: center;
    }
  }
}

.blur {
  filter: blur(7px);
}

.box.sharp {
  border-radius: 0;
}

// ここはグローバルに設定しているのが間違いなので注意が必要
.th-wrap {
  font-size: 12px;
}

td::before {
  font-size: 12px;
}
.b-table .table tr.detail td {
  padding: 1px;
}
.b-table .table tr.detail .detail-container {
  padding: 0;
}
tr.is-asRead {
  background-color: hsl(0, 0%, 96%);
  cursor: pointer;
  cursor: hand;
}
tr.is-asNotRead {
  background-color: inherit;
  cursor: pointer;
  cursor: hand;
}

tr.is-needMask {
  background: #f1f6fc;
  :hover {
    background: #f1f6fc;
  }
}

.blur-realestate {
  top: 0;
  left: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  margin: -10px; /* blurの値と同じ値だけマイナス */
  width: calc(100% + 20px); /* 左右の10ピクセル分加算 */
}

.blur-message {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

// ここはグローバルに設定しているのが間違いなので注意が必要

// transition
.v-enter-active,
.v-leafe-active {
  transition: opacity 0.4s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
// transition fadeInOut
.v-fadeInOut-enter-active {
  transition: opacity 0.3s 0.2s;
}
.v-fadeInOut-leafe-active {
  transition: opacity 0.2s;
}
.v-fadeInOut-enter,
.v-fadeInOut-leave-to {
  opacity: 0;
}
// no-transition for ie11
.v-noanime-enter-active,
.v-noanime-leafe-active {
  opacity: 1;
}
.v-noanime-enter,
.v-noanime-leave-to {
  opacity: 0;
}

.top-section {
  padding-top: 12px;
}

.has-label-centerted {
  height: 2rem;
  margin-top: 4px;
  margin-right: 24px;
}

.button.is-primary.is-outlined {
  background-color: $white;
}
.tag.is-outlined {
  background-color: $white;
  border: 1px solid $grey;
  color: $grey;
}
.tag.is-black.is-outlined {
  background-color: $white;
  border: 1px solid $black;
  color: $black;
}
.tag.is-dark.is-outlined {
  background-color: $white;
  border: 1px solid $dark;
  color: $dark;
}
.tag.is-info.is-outlined {
  background-color: $white;
  border: 1px solid $info;
  color: $info;
}
.tag.is-success.is-outlined {
  background-color: $white;
  border: 1px solid $success;
  color: $success;
}
.tag.is-primary.is-outlined {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}
.tag.is-danger.is-outlined {
  background-color: $white;
  border: 1px solid $danger;
  color: $danger;
}
.tag.is-warning.is-outlined {
  background-color: $white;
  border: 1px solid $warning;
  color: $warning;
}

.notification {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.notification.is-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.notification.is-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.notification.is-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.notification.is-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.notification.is-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.image.is-256x256 {
  width: 256px;
}

.list-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 11px 22px;
  background-color: $important;
  border-bottom: solid 2px #707070;
  color: $white;
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}
.is-elliptical-1 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}
.is-elliptical-2 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: none !important;
}
// .z-input-vritical-warp {
//   height: 90px;
// }

.cursor-pointer {
  cursor: pointer;
  cursor: hand;
}

.investor-search-realestates {
  &__tabs {
    section.tab-content {
      padding: 0px;
    }
  }
}
</style>
