<template>
  <div class="z-sort-select z-field">
    <b-select :name="internalName" v-model="internalValue" expanded>
      <template v-for="o in options">
        <option :value="o.value" :key="o.value">
          {{ o.text }}
        </option>
      </template>
    </b-select>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZSortSelect",
  mixins: [InputMixin],
  props: {
    // options: {
    //   type: [Array],
    //   default: () => []
    // },
    type: {
      type: String,
      default: "loan",
    },
    value: {
      type: [Number],
      required: true,
    },
  },
  computed: {
    options() {
      switch (this.type) {
        case "realEstate":
          return kubuns.transaction.realEstateSortTypes;
        case "loan":
        default:
          return kubuns.transaction.loanSortTypes;
      }
    },
  },
  created() {
    // if (this.value === 0) this.value = null;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-field {
  margin-bottom: 0;
}
</style>
