<template>
  <div class="offerInfoCommon">
    <div v-if="isVisible">
      <div class="offerInfoCommon__empty" v-if="!isArrayExist">
        <p class="offerInfoCommon__empty__txt">
          あたらしいオファーはありません
        </p>
      </div>
      <ul class="offerInfoCommon__mainList" v-if="isArrayExist">
        <li
          class="offerInfoCommon__mainList__offerCell"
          v-for="(offer, offer_index) in messageEntity"
          :key="offer_index"
          @click="openMessageDetail(offer.id)"
        >
          <OffersListItem
            class="offerInfoCommon__mainList__offerCell__comp"
            :title="offerTitle(offer)"
            :name="realtorName(offer)"
            :date="offerDate(offer)"
            :company="realtorCompany(offer)"
            :prfImage="offer.realtor.data.profile.image"
            :score="offer.realtor.data.score"
          />
        </li>
      </ul>
    </div>

    <!-- 課金障壁用静的HTML -->
    <div class="forFreeUser" v-if="!isVisible">
      <div class="forFreeUser_blur blur" v-if="!$$userAgent$isIE()">
        <ul class="offerInfoCommon__mainList">
          <li class="offerInfoCommon__mainList__offerCell">
            <OffersListItem
              class="offerInfoCommon__mainList__offerCell__comp"
              title="無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル"
              name="ストックフォーマー"
              date="09/25 23:00"
              company="ZIRITZ"
              :prfImage="staticSampleImg1"
            />
          </li>
          <li class="offerInfoCommon__mainList__offerCell">
            <OffersListItem
              class="offerInfoCommon__mainList__offerCell__comp"
              title="無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル"
              name="ストックフォーマー"
              date="09/25 23:00"
              company="ZIRITZ"
              :prfImage="staticSampleImg2"
            />
          </li>
          <li class="offerInfoCommon__mainList__offerCell">
            <OffersListItem
              class="offerInfoCommon__mainList__offerCell__comp"
              title="無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル"
              name="ストックフォーマー"
              date="09/25 23:00"
              company="ZIRITZ"
              :prfImage="staticSampleImg1"
            />
          </li>
          <li class="offerInfoCommon__mainList__offerCell">
            <OffersListItem
              class="offerInfoCommon__mainList__offerCell__comp"
              title="無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル"
              name="ストックフォーマー"
              date="09/25 23:00"
              company="ZIRITZ"
              :prfImage="staticSampleImg2"
            />
          </li>
          <li class="offerInfoCommon__mainList__offerCell">
            <OffersListItem
              class="offerInfoCommon__mainList__offerCell__comp"
              title="無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル無課金ユーザー向けダミータイトル"
              name="ストックフォーマー"
              date="09/25 23:00"
              company="ZIRITZ"
              :prfImage="staticSampleImg1"
            />
          </li>
        </ul>
      </div>
      <img
        class="forFreeUser__dummyImage"
        alt=""
        v-if="!$$user$isPremium && $$userAgent$isIE()"
        :src="dummyImage"
      />
      <div
        class="forFreeUser__blurOverlay"
        v-bind:class="{
          forFreeUser__blurOverlay__isNotIE: !$$userAgent$isIE(),
        }"
      >
        <div class="flexBlock">
          <b-icon class="icon" icon="lock" />
          <div class="mainDescription">
            <p class="mainDescription__txt">
              こちらを閲覧するには<br />プレミアムプランへの<br />ご登録が必要です
            </p>
          </div>
          <div class="subDescription">
            <p class="subDescription__txt">
              プレミアムプランにご登録いただくと、<br />本サービスを利用できる他、<br />StockFormer内の全ての不動産取引の仲介手数料に対し、10％のキャッシュバック優遇を受けることができます
            </p>
          </div>
          <MembershipsButton
            class="membershipsButton"
            :isKeyIconActive="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OffersListItem from "./OffersListItem";
import IeBlurImageLg from "@/assets/investor/offers-dummy-pc.png";
import IeBlurImageMd from "@/assets/investor/offers-dummy-md.png";
import IeBlurImageSp from "@/assets/investor/offers-dummy-sp.png";

export default {
  name: "OfferInfoCommon",
  props: {
    tabOfferType: {
      type: Number,
      required: true,
    },
    isVisible: {
      required: true,
    },
    entity: {
      type: Array,
    },
  },
  data: function () {
    return {
      staticSampleImg1: {
        description: "",
        id: "offer-blur-icon1.png",
        isApproved: true,
        title: "",
      },
      staticSampleImg2: {
        description: "",
        id: "offer-blur-icon2.png",
        isApproved: true,
        title: "",
      },
    };
  },
  components: {
    OffersListItem: OffersListItem,
  },
  asyncComputed: {},
  computed: {
    messageEntity() {
      const list = this.entity || [];

      // if (this.tabOfferType !== 1 && this.tabOfferType !== 2 && this.tabOfferType !== 3) {
      //     throw Error("Can not match type in messages", this.tabOfferType);
      // }
      // const filterdList = await list.filter(message => message.type === this.tabOfferType);
      // return filterdList.length? filterdList[0].messages : {};

      switch (this.tabOfferType) {
        case 1: {
          const filterdList = list.filter((message) => message.type === 1);
          return filterdList.length ? filterdList[0].messages : {};
        }

        case 2: {
          const filterdList = list.filter((message) => message.type === 2);
          return filterdList.length ? filterdList[0].messages : {};
        }

        case 3: {
          const filterdList = list.filter((message) => message.type === 3);
          return filterdList.length ? filterdList[0].messages : {};
        }

        default:
          throw Error("Can not match type in messages", this.tabOfferType);
      }
    },
    isArrayExist() {
      if (this.messageEntity.length) {
        return true;
      } else {
        return false;
      }
    },
    dummyImage() {
      if (this.bulma.isMobile) {
        return IeBlurImageSp;
      } else if (this.bulma.isWidescreen) {
        return IeBlurImageLg;
      } else {
        return IeBlurImageMd;
      }
    },
  },
  methods: {
    offerTitle: function (offer) {
      return offer.data.title;
    },
    offerDate: function (offer) {
      return this.$$calender$monthTimeFormat(offer.data.createdDt);
    },
    realtorName: function (offer) {
      return offer.realtor.data.base.name;
    },
    realtorCompany: function (offer) {
      return offer.realtor.company.data.name;
    },
    openMessageDetail: function (messageId) {
      this.$router.push({
        name: "investor-message-detail",
        params: { id: messageId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.offerInfoCommon {
  min-height: 500px;
  &__empty {
    min-height: 498px;
    background-color: #fafafa;
    border: solid 1px;
    border-color: #eeeeee;
    &__txt {
      text-align: center;
      font-size: 16px;
      line-height: 498px;
      color: #777777;
    }
  }
  &__mainList {
    list-style: none;
    &__offerCell {
      cursor: pointer;
      &:first-child {
        .offerInfoCommon__mainList__offerCell__comp {
          padding-top: 8px;
        }
      }
    }
  }
}

.forFreeUser {
  position: relative;
  min-height: 498px;
  &__blur {
    height: 100%;
    width: 100%;
  }
  &__blurOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    color: #333333;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__isNotIE {
      background-color: rgba($color: $white, $alpha: 0.35);
    }
    .flexBlock {
      height: 380px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .mainDescription {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }
    .subDescription {
      font-size: 16px;
      line-height: 24px;
      padding: 0 14px;
      width: 100%;
    }
    .membershipsButton {
      /deep/ .memberships-button {
        width: 280px;
        @include md {
          height: 60px;
        }
      }
    }
    &.isNotIE {
      background: none;
    }
  }
  &__dummyImage {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    margin: auto;
    width: calc(100% + 20px);
    max-width: none;
    height: calc(100% + 20px);
    z-index: -1;
  }
}
</style>
