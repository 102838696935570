import firebase from "@/plugins/firebase";
// import { cloneDeep, defaultsDeep } from "lodash";

const docRef = (uid, targetUID) =>
  firebase.db
    .collection("users_v1")
    .doc(uid)
    .collection("favorites_v1")
    .doc(targetUID);

export const load = async (uid, targetUID) => {
  const doc = await docRef(uid, targetUID).get();

  if (doc.exists) {
    return doc.data().enabled;
  } else {
    return false;
  }
};

export const save = async (uid, targetUID, enabled) => {
  const now = new Date();
  await firebase.db
    .collection("users_v1")
    .doc(uid)
    .collection("favorites_v1")
    .doc(targetUID)
    .set({
      realtorId: uid,
      investorId: targetUID,
      enabled,
      updatedBy: uid,
      updatedDt: now,
    });
};
