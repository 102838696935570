<template>
  <div class="z-balanceTable">
    <ul class="z-balanceTable__header">
      <li class="z-balanceTable__header__item">ASSETS</li>
      <li class="z-balanceTable__header__item">LIABILITIES</li>
    </ul>
    <div class="z-balanceTable__body">
      <ul class="z-balanceTable__list">
        <li class="z-balanceTable__list__item">
          <dl class="balance">
            <dt class="balance__title">Cash</dt>
            <dd class="balance__value">
              <ZAnimatedPriceLabel :value="this.bs.assets.cash.sum" />
            </dd>
          </dl>
        </li>
        <li class="z-balanceTable__list__item">
          <dl class="balance">
            <dt class="balance__title">Investment</dt>
            <dd class="balance__value">
              <ZAnimatedPriceLabel :value="investment" />
            </dd>
          </dl>
        </li>
        <li class="z-balanceTable__list__item">
          <dl class="balance">
            <dt class="balance__title">Buildings & Land</dt>
            <dd class="balance__value">
              <ZAnimatedPriceLabel :value="this.bs.assets.realEstate.sum" />
            </dd>
          </dl>
        </li>
      </ul>
      <ul class="z-balanceTable__list">
        <li
          class="z-balanceTable__list__item z-balanceTable__list__item--large"
        >
          <dl class="balance">
            <dt class="balance__title">Long-term Debt</dt>
            <dd class="balance__value">
              <ZAnimatedPriceLabel :value="this.bs.liabilities.sum" />
            </dd>
          </dl>
        </li>
        <li class="z-balanceTable__list__item">
          <dl class="balance">
            <dt class="balance__title">Net Assets</dt>
            <dd class="balance__value">
              <ZAnimatedPriceLabel :value="this.bs.netAssets.sum" />
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ZAnimatedPriceLabel from "@/components/parts/ZAnimatedPriceLabel.vue";

export default {
  name: "ZBalanceTable",
  components: {
    ZAnimatedPriceLabel,
  },
  props: {
    bs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    investment() {
      if (
        !Number.isFinite(this.bs.assets.security.sum) &&
        !Number.isFinite(this.bs.assets.insurance.sum)
      ) {
        return null;
      }
      return (
        (this.bs.assets.security.sum || 0) + (this.bs.assets.insurance.sum || 0)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-balanceTable {
  color: #fff;
  text-align: center;
  &__header {
    padding: 10px 0;
    display: flex;
    font-family: $family-secondary;
    font-size: 16px;
    letter-spacing: 0.025em;
    & > * {
      width: 50%;
      text-align: center;
      &.z-balanceTable__header__item {
        font-weight: bold;
      }
    }
  }
  &__body {
    display: flex;
    padding: 0 0 17px;
  }
  &__list {
    width: 50%;
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
      border-left: solid 1px rgba(#fff, 0.3);
    }
    &__item {
      margin: 0 11px;
      padding: 12px 0;
      &:not(:first-child) {
        border-top: solid 1px rgba(#fff, 0.3);
      }
      &--large {
        flex: 1 1;
      }
      .balance {
        &__title {
          font-family: $family-secondary;
          font-size: 13px;
          line-height: 1.38;
          letter-spacing: 0.025em;
        }
        &__value {
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
