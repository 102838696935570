var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-realtor-realestate-warp"},[_c('div',{staticClass:"columns i-multiline is-mobile"},[_vm._l((_vm.message.realEstates),function(r,i){return [_c('div',{key:r.id,staticClass:"column is-narrow"},[_c('div',{staticClass:"count"},[_vm._v("提案"+_vm._s(i + 1))]),(_vm.message.data.type === 3)?[_c('router-link',{attrs:{"to":{
              name: 'admin-setting-messages-investor-realEstate',
              params: {
                rid: r.id,
              },
            }}},[_c('ZImageTileView',{attrs:{"newArrivalObject":r}})],1)]:[_c('router-link',{attrs:{"to":{
              name: 'admin-setting-messages-realtor-realEstate',
              params: {
                rid: r.id,
              },
            }}},[_c('ZImageTileView',{attrs:{"newArrivalObject":r}})],1)]],2)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }