<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
import messageType from "@/types/messageType";

import * as message from "@/modules/message/message/";

export default {
  name: "Mixins-message",
  methods: {
    $$message$getNewMessage: function () {
      const message = {
        id: null,
        data: this.$$cloneDeep(messageType.type.message),
      };
      return message;
    },
    $$message$getNewThread: function () {
      return this.$$cloneDeep(messageType.type.thread);
    },
    async $$message$asRead(id, uid) {
      const docRef = firebase.db.collection("messages_v1").doc(id);
      await firebase.db.runTransaction(async (transaction) => {
        await transaction.get(docRef).then(async (doc) => {
          if (doc.exists) {
            const message = doc.data();
            if (!message.asReads.some((r) => r === uid)) {
              message.asReads.push(uid);
            }
            await transaction.update(docRef, message);
          }
        });
      });
    },
    async $$message$sendMailMessageToRealtor(mid) {
      const m = await this.$$message$loadMessage(mid);
      const link =
        location.protocol +
        "//" +
        location.host +
        this.$router.resolve({
          name: "realtor-message-detail",
          params: {
            id: m.id,
          },
        }).href;
      return message.sendMailMessageToRealtor(m, m.realtor, link);

      // const mtype = this.$$ks.message.getMailType(m.data.type);
      // // console.log(m);
      // await firebase.functions("sendMailOfMessgeToRealtor", {
      //   to: {
      //     email: m.realtor.data.base.email,
      //     name: m.realtor.data.base.name
      //   },
      //   message: m,
      //   link: link,
      //   messageType: mtype.text,
      //   type: mtype
      // });
      // return m;
    },
    async $$message$sendMailMessageToInvestor(mid) {
      const m = await this.$$message$loadMessage(mid);
      const link =
        location.protocol +
        "//" +
        location.host +
        this.$router.resolve({
          name: "investor-message-detail",
          params: {
            id: m.id,
          },
        }).href;

      return await message.sendMailMessageToInvestor(m, m.investor, link);

      // const mtype = this.$$ks.message.getMailType(m.data.type);
      // const score = m.realtor.data.score;
      // const rank = score.score ? this.$$ks.getRank(score.rank).text : 0;
      // // const rank = score.score ? this.$$ks.getRank(score.rank).text : "NEW";
      // // console.log(m);
      // await firebase.functions("sendMailOfMessgeToInvestor", {
      //   to: {
      //     email: m.investor.data.base.email,
      //     name: m.investor.data.base.name
      //   },
      //   message: m,
      //   link: link,
      //   messageType: mtype.text,
      //   rank: rank,
      //   type: mtype
      // });
      // return m;
    },
    async $$message$createMessage(
      uid,
      from,
      to,
      mes,
      thread,
      realtorRealEstates = [], //専門家の不動産
      realEstates = [] //投資家の不動産
    ) {
      return await message.createMessage(
        uid,
        from,
        to,
        mes,
        thread,
        realtorRealEstates,
        realEstates
      );
    },
    async $$message$sendMessage(uid, from, to, message, thread) {
      const now = firebase.serverTimestamp();

      message.data.updatedBy = uid;
      message.data.updatedDt = now;
      message.data.latestTo = to;
      message.data.latestFrom = from;
      message.data.latestSendDt = now;
      message.data.asReads = [from];

      thread.createdBy = uid;
      thread.createdDt = now;
      thread.updatedBy = uid;
      thread.updatedDt = now;
      thread.sendDt = now;
      thread.from = from;
      thread.to = to;

      message.data.threads.push(thread);

      await firebase.db
        .collection("messages_v1")
        .doc(message.id)
        .set(message.data, { merge: true });

      // await this.$$message$sendMailMessage(message);
      return message;
    },
    async $$message$loadMessagesByFilter(uid, filter) {
      const ls = await firebase.db
        .collection("messages_v1")
        .where("uids", "array-contains", uid)
        .orderBy("latestSendDt", "desc")
        .get()
        .then((querySnapshot) => {
          const ps = [];
          querySnapshot.forEach((doc) => {
            ps.push(this.$$message$loadMessage(doc.id, doc.data()));
          });
          return Promise.all(ps);
        });
      const fs = ls
        .filter((l) => {
          if (
            "message" in filter &&
            (filter.message.proposal ||
              filter.message.exclusive ||
              filter.message.buying ||
              filter.message.contact)
          ) {
            if (filter.message.proposal && l.data.type === 2) {
              return true;
            }
            if (filter.message.exclusive && l.data.type === 1) {
              return true;
            }
            if (filter.message.buying && l.data.type === 3) {
              return true;
            }
            if (filter.message.contact && l.data.type === 4) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })
        .filter((l) => {
          if (
            "rank" in filter &&
            (filter.rank.s || filter.rank.a || filter.rank.b || filter.rank.c)
          ) {
            if (filter.rank.s && l.realtor.data.score.rank === 1) {
              return true;
            }
            if (filter.rank.a && l.realtor.data.score.rank === 2) {
              return true;
            }
            if (filter.rank.b && l.realtor.data.score.rank === 3) {
              return true;
            }
            if (filter.rank.c && l.realtor.data.score.rank === 4) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((l) => {
          if (!filter.status.withReply) return true;

          // console.log(l);
          const investor = l.data.investor;
          // console.log(investor);
          for (const t of l.data.threads) {
            if (t.from === investor) {
              return true;
            }
          }
          return false;
        })
        .filter((l) => {
          const st = this.$$message$JudgmentNotification(uid, l);
          if (
            "status" in filter &&
            (filter.status.unRead ||
              filter.status.asRead ||
              filter.status.unanswered)
          ) {
            if (filter.status.unRead && st === 1) {
              return true;
            }
            if (filter.status.asRead && (st === 2 || st === 0)) {
              return true;
            }
            if (filter.status.unanswered && st === 2) {
              return true;
            }
            return false;
          }
          return true;
        })
        .filter((l) => {
          const userScore = l.investor.data.latestScore.score;
          if ("score" in filter && (filter.score.from || filter.score.to)) {
            if (
              filter.score.from <= userScore &&
              userScore <= filter.score.to
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        });
      return fs;
    },
    async $$message$countNotAsRead(uid, messages) {
      // 投資家の不動産についているメッセージの取得
      //console.log(messages);
      if (messages) {
        return messages.filter((m) => !m.data.asReads.some((i) => i === uid))
          .length;
      } else {
        return 0;
      }
    },
    async $$message$countMesaageAndNotAsRead(uid, realEstateId) {
      const messages = await this.$$message$getMessagesByRealEstate(
        realEstateId
      );
      const countOfNotAsRead = await this.$$message$countNotAsRead(
        uid,
        messages
      );
      return {
        count: messages.length,
        countOfNotAsRead: countOfNotAsRead,
      };
    },
    async $$message$getMessagesByRealEstate(realEstateId) {
      // 投資家の不動産についているメッセージの取得
      const ls = await firebase.db
        .collection("messages_v1")
        .where("realEstates", "array-contains", realEstateId)
        .get()
        .then((querySnapshot) => {
          const ps = [];
          querySnapshot.forEach((doc) => {
            ps.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          return ps;
        });
      return ls;
    },
    async $$message$getMessagesByRealEstateInRealtor(realEstateId) {
      // 不動産屋の不動産についているメッセージの取得
      const ls = await firebase.db
        .collection("messages_v1")
        .where("realtorRealEstates", "array-contains", realEstateId)
        .get()
        .then((querySnapshot) => {
          const ps = [];
          querySnapshot.forEach((doc) => {
            ps.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          return ps;
        });
      return ls;
    },
    $$message$getMessagesByType: function (uid, messages, type, len = 5) {
      const ls = [];
      for (const m of messages) {
        if (m.data.type === type) {
          ls.push(m);
        }
      }
      return ls.slice(0, len);
    },
    $$message$getMessagesOfNotAsReadByType: function (uid, messages, type) {
      const ls = [];
      const ms = this.$$message$getMessagesOfNotAsRead(uid, messages);
      for (const m of ms) {
        if (m.data.type === type) {
          ls.push(m);
        }
      }
      return ls;
    },
    async $$message$getMessagesOfTypes(uid, len = 5) {
      const messages = await this.$$message$loadMessagesByUID(uid);
      return [
        {
          type: 1,
          messages: this.$$message$getMessagesByType(uid, messages, 1, len),
        },
        {
          type: 2,
          messages: this.$$message$getMessagesByType(uid, messages, 2, len),
        },
        {
          type: 3,
          messages: this.$$message$getMessagesByType(uid, messages, 3, len),
        },
        {
          type: 4,
          messages: this.$$message$getMessagesByType(uid, messages, 4, len),
        },
      ];
    },
    $$message$getMessagesOfNotAsRead: function (uid, messages) {
      const ls = [];
      if (messages) {
        for (const mes of messages) {
          const st = this.$$message$JudgmentNotification(uid, mes);
          if (st === 1) {
            //未読
            ls.push(mes);
          }
        }
      }
      return ls;
    },
    $$message$JudgmentNotification(uid, message) {
      if (!message.data.asReads.some((r) => r === uid)) {
        return 1; //既読
      } else if (message.data.latestFrom !== uid) {
        return 2; //既読で未返信
      }
      return 0; //未読
    },
    async $$message$loadMessagesByUID(uid) {
      const ls = await firebase.db
        .collection("messages_v1")
        .where("uids", "array-contains", uid)
        .orderBy("latestSendDt", "desc")
        .get()
        .then((querySnapshot) => {
          const ps = [];
          querySnapshot.forEach((doc) => {
            ps.push(this.$$message$loadMessage(doc.id, doc.data()));
          });
          return Promise.all(ps);
        });
      return ls;
    },
    async $$message$loadMessage(id, messageData = null) {
      let message = null;
      if (!messageData) {
        const doc = await firebase.db.collection("messages_v1").doc(id).get();
        if (doc.exists) {
          message = {
            id: doc.id,
            data: doc.data(),
          };
        } else {
          return null;
        }
      } else {
        message = {
          id: id,
          data: messageData,
        };
      }

      message.investor = await this.$$investor$loadUser(message.data.investor);
      message.realtor = await this.$$realtor$loadUser(message.data.realtor);
      return message;

      // const doc = await firebase.db
      //   .collection("messages_v1")
      //   .doc(id)
      //   .get();
      // if (doc.exists) {
      //   const message = {
      //     id: doc.id,
      //     data: doc.data()
      //   };
      //   message.investor = await this.$$investor$loadUser(
      //     message.data.investor
      //   );
      //   message.realtor = await this.$$realtor$loadUser(message.data.realtor);

      //   return message;
      // } else {
      //   return null;
      // }
    },
    async $$message$loadMessageWithRealEstates(id) {
      const doc = await firebase.db.collection("messages_v1").doc(id).get();
      if (doc.exists) {
        const message = {
          id: doc.id,
          data: this.$$assign(messageType.type.message, doc.data()),
          realEstates: [],
        };
        message.investor = await this.$$investor$loadUser(
          message.data.investor
        );
        message.realtor = await this.$$realtor$loadUser(message.data.realtor);
        if (message.data.realtorRealEstates.length > 0) {
          for (const rid of message.data.realtorRealEstates) {
            message.realEstates.push(await this.$$realtor$loadRealEstate(rid));
          }
        }
        if (message.data.realEstates.length > 0) {
          for (const rid of message.data.realEstates) {
            message.realEstates.push(
              await this.$$realEstate$loadRealEstate(rid)
            );
          }
        }

        return message;
      } else {
        return null;
      }
    },
    async $$message$loadMessages() {
      const ls = await firebase.db
        .collection("messages_v1")
        .orderBy("createdDt")
        .get()
        .then((querySnapshot) => {
          const ps = [];
          querySnapshot.forEach((doc) => {
            ps.push(this.$$message$loadMessage(doc.id, doc.data()));
          });
          return Promise.all(ps);
        });
      return ls;
    },
    async $$message$loadMessagesWithRealEstates(page, offset) {
      // const ls = await firebase.db
      //   .collection("messages_v1")
      //   .orderBy("latestSendDt", 'desc')
      //   .get()
      //   .then(querySnapshot => {
      //     const ps = [];
      //     querySnapshot.forEach(doc => {
      //       ps.push(this.$$message$loadMessageWithRealEstates(doc.id));
      //     });
      //     return Promise.all(ps);
      //   });
      // return ls;
      // const ret = await firebase.functions("message_loadMessagesWithRealEstates", {
      // });
      // const ls = ret.data;
      // console.log();

      // console.log(page);
      // console.log(offset);
      if (page === 1) {
        const ls = await firebase.db
          .collection("messages_v1")
          .orderBy("latestSendDt", "desc")
          .limit(offset)
          .get()
          .then((querySnapshot) => {
            const ps = [];
            querySnapshot.forEach((doc) => {
              ps.push(this.$$message$loadMessageWithRealEstates(doc.id));
            });
            return Promise.all(ps);
          });
        return ls;
      } else {
        const lastDoc = await firebase.db
          .collection("messages_v1")
          .orderBy("latestSendDt", "desc")
          .limit((page - 1) * offset)
          .get()
          .then((documentSnapshots) => {
            return documentSnapshots.docs[documentSnapshots.docs.length - 1];
            // const ps = [];
            // querySnapshot.forEach(doc => {
            //   ps.push(this.$$message$loadMessageWithRealEstates(doc.id));
            // });
            // return Promise.all(ps);
          });

        const ls = await firebase.db
          .collection("messages_v1")
          .orderBy("latestSendDt", "desc")
          .startAfter(lastDoc)
          .limit(offset)
          .get()
          .then((querySnapshot) => {
            const ps = [];
            querySnapshot.forEach((doc) => {
              ps.push(this.$$message$loadMessageWithRealEstates(doc.id));
            });
            return Promise.all(ps);
          });
        return ls;
      }

      // const ls = await firebase.db
      //   .collection("messages_v1")
      //   .orderBy("createdDt")
      //   .get()
      //   .then(querySnapshot => {
      //     const ps = [];
      //     querySnapshot.forEach(doc => {
      //       ps.push(this.$$message$loadMessageWithRealEstates(doc.id));
      //     });
      //     return Promise.all(ps);
      //   });
      // return ls;
    },
    // メッセージ本文がXSS対応し、URLが含まれていた場合リンクとして表示する
    $$message$displayText(text) {
      const patterns = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#x27;",
        "`": "&grave;",
      };
      const regexPattern = /[&<>"']/gi;
      const regexUrl = /((http|https)?:\/\/[^\s]+)/g;

      text = text.replace(regexPattern, (match) => patterns[match]);
      text = text.replace(
        regexUrl,
        (url) => `<a target="_blank" href="${url}">${url}</a>`
      );
      return text;
    },
  },
};
</script>
