<template>
  <div class="zero">
    <zero-content @onConversionClick="showModal" />
    <zero-inquiry-modal
      :is-open-modal="isOpenModal"
      @dismissModal="dismissModal"
    />
  </div>
</template>

<script>
import ZeroContent from "./parts/ZeroContent";
import ZeroInquiryModal from "./parts/ZeroInquiryModal";

export default {
  name: "investor-zero-index",
  components: { ZeroContent, ZeroInquiryModal },
  data: function () {
    return {
      isOpenModal: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    showModal() {
      this.isOpenModal = true;
    },
    dismissModal() {
      this.isOpenModal = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.zero {
  width: 100%;
}
</style>
