<script>
import firebase from "@/plugins/firebase";
import docType from "@/types/docType";
const type = docType.type;

const encodeRFC5987ValueChars = function (str) {
  return encodeURIComponent(str)
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, "%2A")
    .replace(/%(?:7C|60|5E)/g, unescape);
};

const upload = function (id, path, file) {
  return new Promise(function (resolve, reject) {
    if (file instanceof File || file instanceof Blob) {
      firebase.storage
        .child(path)
        .child(id)
        .put(file, {
          contentDisposition:
            "inline; filename*=UTF-8''" + encodeRFC5987ValueChars(file.name),
          cacheControl: "public,max-age=315360000",
        })
        .on(
          "state_changed",
          function (snapshot) {
            console.log(snapshot.state);
          },
          function (error) {
            reject(error);
          },
          function () {
            resolve(id);
          }
        );
    } else {
      resolve(id);
    }
  });
};
export default {
  name: "DocsMixin",
  methods: {
    $$docs$getNewDoc: function () {
      return this.$$cloneDeep(type.doc);
    },
    $$docs$upload: async function (path, file) {
      const id = this.$uuid.v4();
      await upload(id, path, file);
      let doc = this.$$docs$getNewDoc();

      doc.id = id;
      doc.name = file.name;
      doc.path = path;
      doc.type = file.type;
      doc.description = file.description || "";

      return doc;
    },
  },
};
</script>
