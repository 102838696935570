//import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";
// import { cloneDeep } from "lodash";
//

const type = {};
type.information = {
  to: null, //送信先 uid
  title: "",
  isImportant: false,
  message: "",
  sendDt: null,
  sendBy: null,
  asRead: false,
  readDt: null,

  createdDt: null,
  createdBy: null,
  updatedDt: null,
  updatedBy: null,
};

export default {
  type,
};
