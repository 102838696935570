<template>
  <div>
    <section>
      <ZHeader title="Property Search" sub="不動産検索">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>

    <b-tabs
      class="z-tabs z-tabs--page investor-search-realestates__tabs"
      v-model="currentTabIndex"
      :destroy-on-hide="true"
    >
      <b-tab-item
        class="investor-search-realestates__tab-content"
        label="国内不動産"
      >
      </b-tab-item>
      <b-tab-item
        class="investor-search-realestates__tab-content"
        label="海外不動産"
      >
      </b-tab-item>
    </b-tabs>
    <JpRealEstates v-if="currentTabIndex === 0" />
    <OsRealEstates v-if="currentTabIndex === 1" />
  </div>
</template>

<script>
import JpRealEstates from "./parts/JpRealEstates";
import OsRealEstates from "./parts/OsRealEstates";

export default {
  name: "investor-search-realestates",
  components: {
    JpRealEstates: JpRealEstates,
    OsRealEstates: OsRealEstates,
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    currentTabIndex: {
      get() {
        const ctg = this.$route.query.ctg;
        if (ctg === "jp") {
          return 0;
        }
        if (ctg === "os") {
          return 1;
        } else {
          return 0;
        }
      },
      set(val) {
        const ctg = val === 0 ? "jp" : "os";
        if (this.$route.query.ctg != ctg) {
          this.$router.push({
            name: "investor-search-realestates",
            query: { ctg: ctg },
          });
        }
      },
    },
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    next();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.investor-search-realestates {
  &__tabs {
    section.tab-content {
      padding: 0px;
    }
    ::v-deep span {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
</style>
