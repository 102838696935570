<template>
  <div class="AssetsInfoTitle">
    <div class="AssetsInfoTitle__titleDetail">
      <div class="AssetsInfoTitle__titleDetail__box">
        <div
          class="colorBox"
          v-bind:class="{
            cash: isCash,
            securityInsurance: isSecurityInsurance,
            realEstateBS: isRealEstateBS,
            liabilities: isLiabilities,
            netAssets: isNetAssets,
            salary: isSalary,
            dividend: isDividend,
            pension: isPension,
            realEstatePL: isRealEstatePL,
            others: isOthers,
          }"
        ></div>
      </div>
      <p class="AssetsInfoTitle__titleDetail__txt">{{ titleTxt }}</p>
    </div>
    <div class="AssetsInfoTitle__amount">
      <span class="AssetsInfoTitle__amount__yenMark" v-if="isAmountExist"
        >¥
      </span>
      <span class="AssetsInfoTitle__amount__money" v-if="isAmountExist"
        >{{ amount | addComma }}
      </span>
      <p class="AssetsInfoTitle__amount__blankTxt" v-if="!isAmountExist">
        記入するとスコアアップ
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AssetsInfoTitle",
  props: {
    withDetail: {
      type: Boolean,
      default: true,
    },
    tileType: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: false,
    },
  },
  data: function () {
    return {
      titleTxt: "",
      isCash: false,
      isSecurityInsurance: false,
      isRealEstateBS: false,
      isLiabilities: false,
      isNetAssets: false,
      isSalary: false,
      isDividend: false,
      isPension: false,
      isRealEstatePL: false,
      isOthers: false,
    };
  },
  filters: {
    addComma: function (val) {
      return val.toLocaleString();
    },
  },
  mounted() {
    switch (this.tileType) {
      case "cash":
        this.isCash = true;
        this.titleTxt = "現金";
        break;

      case "securityInsurance":
        this.isSecurityInsurance = true;
        this.titleTxt = "証券・保険";
        break;

      case "realEstateBS":
        this.isRealEstateBS = true;
        this.titleTxt = "不動産";
        break;

      case "liabilities":
        this.isLiabilities = true;
        this.titleTxt = "融資";
        break;

      case "netAssets":
        this.isNetAssets = true;
        this.titleTxt = "純資産";
        break;

      case "salary":
        this.isSalary = true;
        this.titleTxt = "給与・役員報酬";
        break;

      case "realEstatePL":
        this.isRealEstatePL = true;
        this.titleTxt = "不動産収入";
        break;

      case "dividend":
        this.isDividend = true;
        this.titleTxt = "配当・利子";
        break;

      case "pension":
        this.isPension = true;
        this.titleTxt = "年金";
        break;

      case "others":
        this.isOthers = true;
        this.titleTxt = "その他";
        break;
    }
  },
  computed: {
    isAmountExist() {
      if (this.withDetail) {
        return true;
      } else {
        if (this.amount) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.AssetsInfoTitle {
  min-height: 42px;
  width: 100%;
  line-height: 42px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__titleDetail {
    display: flex;
    &__txt {
      font-size: 16px;
    }
  }
  &__amount {
    margin-left: auto;
    white-space: nowrap;
    &__yenMark {
      font-size: 18px;
    }
    &__money {
      font-size: 22px;
    }
    &__blankTxt {
      font-size: 12px;
      color: #162c55;
    }
  }
}

.colorBox {
  width: 14px;
  height: 14px;
  .AssetsInfoTitle__titleDetail__box & {
    margin: 15px 6px 13px 16px;
  }
}
.cash {
  background-color: #b0d8f8;
}
.securityInsurance {
  background-color: #48adff;
}
.realEstateBS {
  background-color: #3074c2;
}
.liabilities {
  background-color: #092550;
}
.netAssets {
  background-color: #b8ecff;
}
.salary {
  background-color: #9ad6ff;
}
.dividend {
  background-color: #39ddb2;
}
.pension {
  background-color: #3d75f5;
}
.realEstatePL {
  background-color: #839dff;
}
.others {
  background-color: #2d3f6b;
}
</style>
