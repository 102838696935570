<script>
import firebase from "@/plugins/firebase";
import options from "@/routers/options";

// const RequiresAuth = options.RequiresAuth;
//const role = options.role;
const examination = options.examination;
const needCompleteRegister = options.needCompleteRegister;

// const RequiresAuth = {
//   none: 0,
//   required: 1,
//   noRequired: 2
// };
export default {
  name: "AuthMixin",
  computed: {
    requiresAuth: function () {
      return this.$route.meta.requiresAuth;
    },
    needCompleteRegister: function () {
      return this.$route.meta.needCompleteRegister;
    },
    examination: function () {
      return this.$route.meta.examination;
    },
  },
  watch: {
    needCompleteRegister: function (val) {
      console.log(val);
      if (this.$$user$isInvestor) {
        if (
          val === needCompleteRegister.yes &&
          !this.$$user$wasCompletedRegister
        ) {
          this.$$toast$openSuccess(
            "初回登録が完了していません、登録をお願いします。"
          );
          this.$nextTick(() => {
            window.location.href = "/investor/register";
          });
        }
      }
    },
    examination: function (val) {
      if (this.$$user$isRealtor) {
        if (val === examination.allow && !this.$$user$allowExamination) {
          console.log("Allow Examination");
          window.location.href = "/registor/realtor/welcome";
        }
        if (val === examination.notAllow && this.$$user$allowExamination) {
          console.log("not Allow Examination");
          window.location.href = "/realtor";
        }
        console.log("not check Allow Examination");
      }
    },
  },
  mounted() {
    const self = this;
    if (this.$$env$isProduction) {
      firebase.auth().onAuthStateChanged((auth) => {
        // 別タブでサインアウトした場合にLPに戻すための処理
        if (!auth || auth.isAnonymous) {
          const res = [1];
          if (res.indexOf(self.requiresAuth) >= 0) {
            console.log("Jump root, because auth signed Out!");
            window.location.href = "/auth/signin";
          }
        }
      });
    }
  },
};
</script>
