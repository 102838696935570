<template>
  <div>
    <ZTextarea :rows="8" label="備考" maxlength="256" v-model="message" />
    <hr />
    {{ sanitizeMessage }}
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";
import * as mes from "@/modules/message/message";

export default {
  name: "admin-demo-placeholder",
  data() {
    return {
      message: "あなたのスコアは{{score}}で、rankは{{rank}}です。",
    };
  },
  computed: {
    sanitizeMessage() {
      return mes.replacePlaceholder(this.message, [
        {
          text: "score",
          value: 333,
        },
        {
          text: "rank",
          value: "A",
        },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
