<template>
  <div>
    <b-table
      :data="informations"
      detailed
      detail-key="id"
      custom-row-key="id"
      :show-detail-icon="showDetail"
      :striped="true"
      :hoverable="true"
      :narrowed="true"
      @click="onClickRow"
    >
      <template slot-scope="props">
        <b-table-column field="data.asRead" width="8">
          <template v-if="!props.row.data.asRead">
            <span class="icon has-text-unread information-list__mark">
              <i class="mdi mdi-circle"></i>
            </span>
          </template>
        </b-table-column>
        <b-table-column field="data.title" label="件名">
          <div class="content">
            <z-truncator
              class="is-size-7 information-list__title"
              :text="props.row.data.title"
            ></z-truncator>
          </div>
        </b-table-column>
        <!--
        <b-table-column field="data.message" label="本文" sortable>
          <div class="content">
            <p class="is-elliptical is-size-7">
              {{ props.row.data.message }}
            </p>
          </div>
        </b-table-column>
        -->
        <b-table-column field="data.sendDt" label="受信日時" width="120">
          <div class="content">
            <p class="is-dt is-size-7 information-list__dt">
              {{ $$calender$todayFormat(props.row.data.sendDt) }}
            </p>
          </div>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <div class="content message-detial">
          <div class="box message-detial__box sharp" ref="messagebox">
            <pre class="is-size-7 normal" v-text="props.row.data.message"></pre>
          </div>
        </div>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>お知らせはありません</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
//import firebase from "@/plugins/firebase";
export default {
  name: "relator-informationList",
  props: {
    informations: {
      type: [Array],
      default: () => [],
    },
    showDetail: {
      type: [Boolean],
      default: true,
    },
    clickable: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    onClickRow(row) {
      if (this.clickable) {
        this.$emit("click", row);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      for (const info of this.informations) {
        this.$$information$asRead(this.me.user.id, info);
      }
      if (this.$refs.messagebox) {
        this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message {
  &__list {
    width: 100%;
  }
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

.information-list {
  &__mark {
    margin-top: 2px;
    @include sp {
      margin-top: 0px;
    }
  }
  &__title {
    margin-top: 3px;
    @include sp {
      margin-top: 0px;
    }
  }
  &__dt {
    margin-top: 3px;
    @include sp {
      margin-top: 0px;
    }
  }
}
</style>
