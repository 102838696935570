<template>
  <div class="stationField">
    <transition name="v-fadeInOut">
      <div v-if="!isSelected" class="stationField__form">
        <div class="columns stationField__form__input">
          <div class="column">
            <z-prefecture-select
              label="都道府県"
              v-model="value.prefecture"
              :validate="validate"
              :isRequired="isRequired"
              :hideRequired="hideRequired"
            >
            </z-prefecture-select>
          </div>
          <div class="column">
            <z-line-select
              label="沿線"
              v-model="value.line"
              :prefecture="value.prefecture"
              :validate="validate"
              :isRequired="isRequired"
              :hideRequired="hideRequired"
            >
            </z-line-select>
          </div>
        </div>
        <div class="columns stationField__form__button">
          <div class="column">
            <b-button
              class="z-button"
              size="is-small"
              :disabled="!value.line"
              @click="onOpenModal"
              >駅を選択</b-button
            >
          </div>
        </div>
      </div>
    </transition>

    <transition name="v-fadeInOut">
      <div v-if="isSelected" class="columns">
        <div class="column">
          <div class="stationField__selected">
            <div class="stationField__selected__info">
              <div>都道府県：{{ prefectureInfo }} 沿線：{{ lineInfo }}</div>
              <div>{{ stationInfo }}</div>
            </div>
            <div class="stationField__selected__button">
              <b-button class="z-button" size="is-small" @click="reset"
                >再設定</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </transition>

    <z-modal
      title="駅選択"
      :isActive="isActiveModal"
      isModalOnModal
      @close="onCloseModal"
    >
      <template v-slot:content>
        <h3 class="edit__title">{{ lineInfo }}</h3>
        <z-station-checkbox
          label="駅にチェックをいれてください（複数選択できます）"
          v-model="stations"
          :line="value.line"
          :validate="validate"
          :isRequired="isRequired"
          :hideRequired="hideRequired"
          showAllCheck
        >
        </z-station-checkbox>
        <div class="edit__foot">
          <b-button
            class="z-button"
            type="is-primary"
            rounded
            @click="setSearch()"
            >保存する</b-button
          >
        </div>
      </template>
    </z-modal>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZLineField",
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      default: null,
    },
    validate: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hideRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActiveModal: false,
      isSelected: false,
      stations: [],
    };
  },
  asyncComputed: {
    async prefectureInfo() {
      const ls = await this.$$prefecture$load(this.value.prefecture);
      return ls.text;
    },
    async lineInfo() {
      const ls = await this.$$line$load(this.value.line);
      if (ls) return ls.line_name;
      else return "";
    },
    async stationInfo() {
      // 情報取得
      const resultPromise = [];
      const stationCodes = this.value.stations;
      for (let i = 0, len = stationCodes.length; i < len; ++i) {
        resultPromise.push(this.$$station$load(stationCodes[i]));
      }
      const result = await Promise.all(resultPromise);

      // 成形
      let infos = "";
      for (let i = 0, len = result.length; i < len; ++i) {
        infos += (i === 0 ? "" : " , ") + result[i].station_name;
      }
      return infos;
    },
  },
  methods: {
    onOpenModal() {
      this.stations = this.value.stations;
      this.isActiveModal = true;
    },
    onCloseModal() {
      this.isActiveModal = false;
    },
    setSearch() {
      this.value.stations = this.stations;
      this.isActiveModal = false;
      this.checkSelected();
    },
    checkSelected() {
      if (
        this.value.prefecture &&
        this.value.line &&
        this.value.stations.length > 0
      )
        this.isSelected = true;
    },
    reset() {
      this.value.prefecture = null;
      this.value.line = null;
      this.value.stations = [];
      this.isSelected = false;
    },
  },
  created() {
    this.checkSelected();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.stationField {
  position: relative;
  /deep/ .z-field {
    margin: 0;
  }
  /deep/ .columns {
    margin: 0;
    .column {
      position: relative;
      padding: 22px 16px;
    }
    &:not(:first-child) {
      .column {
        padding-top: 0;
      }
    }
  }
  &__form {
    &__button {
      position: relative;
      text-align: right;
      .z-button {
        // position: absolute;
        // right: 0;
        // bottom: 0;
        width: 200px;
      }
    }
  }
  &__selected {
    &__info {
      font-size: 14px;
    }
    &__button {
      margin-top: 12px;
      text-align: right;
      .z-button {
        width: 200px;
      }
    }
  }
  .edit__foot {
    margin-top: 32px;
    margin-bottom: 20px;
  }
}
</style>
