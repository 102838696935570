<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <b-tabs>
        <b-tab-item label="お知らせ">
          <Information :uid="uid" />
        </b-tab-item>
        <b-tab-item label="否認">
          <div class="box">
            <h1>否認</h1>
            <div class="buttons">
              <button class="button is-danger" @click="deny">否認</button>
            </div>
            <examination
              :user="investor"
              :examination="investor.examination"
            ></examination>
          </div>
        </b-tab-item>
        <b-tab-item v-if="isPremium" label="解約">
          <button class="button is-danger" @click="cancelPremium">
            解約する
          </button>
        </b-tab-item>
        <b-tab-item label="管理">
          <div class="content">
            <p class="has-text-danger">メンテナンス用です</p>
          </div>
          <examinationForm
            :user="investor"
            :examination="investor.examination"
          />
        </b-tab-item>
      </b-tabs>
      <hr />
      <!--
      {{ investor }}
      -->
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
import examinationForm from "@/views/admin/components/InvestorExaminationForm";
import examination from "@/views/admin/components/InvestorExamination";
import Information from "@/views/admin/components/Information.vue";

export default {
  name: "admin-setting-investors-detail",
  components: {
    examinationForm: examinationForm,
    examination: examination,
    Information: Information,
  },
  data: function () {
    return {
      //information: this.$$information$getNewInformation(),
      isLoading: true,
      investor: null,
    };
  },
  computed: {
    uid() {
      return this.$route.params.uid;
    },
    isPremium() {
      return this.investor.data.membership === 20 ? true : false;
    },
  },
  methods: {
    //async onSendInformation() {
    //  const loadingComponent = this.$loading.open();
    //  try {
    //    this.information.data.to = this.uid;
    //    console.log(this.information);
    //    await this.$$information$send(this.auth.uid, this.information);
    //    this.$$toast$openSuccess("お知らせを送信しました");
    //  } catch (error) {
    //    this.$raven.captureException(error);
    //    this.$$toast$openError("お知らせの送信に失敗しました");
    //  } finally {
    //    loadingComponent.close();
    //  }
    //
    //},
    async cancelPremium() {
      const loadingComponent = this.$loading.open();
      try {
        this.investor.data.membership = 10;
        this.investor.examination.data.isTrial = false;
        this.investor.examination.data.isCancelled = false;
        this.investor.examination.data.membership = 10;
        this.investor.examination.data.status = 1;
        this.investor.examination.data.startDt = null;
        this.investor.examination.data.endDt = null;

        await this.$$investor$save(this.auth.uid, this.investor);
        await this.$$investor$saveExaminationOfUser(
          this.auth.uid,
          this.investor.id,
          this.investor.examination
        );

        this.$$toast$openSuccess("解約しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async deny() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$denyUser(this.auth.uid, this.investor);
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        const investor = await this.$$investor$save(
          this.auth.uid,
          this.investor
        );
        this.$$toast$openSuccess();

        this.$router.replace({
          name: "admin-setting-investors-detail",
          params: {
            uid: investor.id,
          },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.investor = await this.$$investor$loadUser(this.uid);
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
        this.isLoading = false;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
