<template>
  <div class="labelDetail">
    <div class="labelDetail__labelArea">
      <ul class="labelDetail__labelArea__labelList">
        <template v-for="(label, idx) in internalValue">
          <li :key="idx" class="labelDetail__labelArea__labelList__labelItem">
            <div class="mainItem">
              <p class="mainItem__label">{{ label.label }}</p>
              <template v-if="label.isPrivate">
                <div class="mainItem__privateTag">
                  <p class="mainItem__privateTag__txt">非公開</p>
                  <b-icon
                    class="mainItem__privateTag__icon"
                    icon="lock"
                    size="is-small"
                  ></b-icon>
                </div>
              </template>
              <template v-if="label.type === 'text'">
                <p class="mainItem__txt">{{ label.value }}</p>
              </template>
              <template v-else-if="label.type === 'pre'">
                <div class="mainItem__txt">
                  <pre class="normal mainItem__txt" v-text="label.value"></pre>
                </div>
              </template>
              <template v-else-if="label.type === 'kubun'">
                <p class="mainItem__txt">
                  {{ kubun(label.options, label.value) }}
                </p>
              </template>
              <template v-else-if="label.type === 'kubunList'">
                <template v-for="k in kubunList(label.options, label.value)">
                  <p :key="k" class="mainItem__txt">{{ k }}</p>
                </template>
              </template>
              <template v-else-if="label.type === 'date'">
                <p class="mainItem__txt">
                  {{ $$calender$dateFormat(label.value) }}
                </p>
              </template>
              <template v-else-if="label.type === 'dateTime'">
                <p class="mainItem__txt">
                  {{ $$calender$dateTimeFormat(label.value) }}
                </p>
              </template>
              <template v-else-if="label.type === 'image'">
                <div class="mainItem__txt">
                  <figure class="image is-128x128">
                    <img :src="label.value" />
                  </figure>
                </div>
              </template>
              <template v-else>
                <p class="mainItem__txt">---</p>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";
export default {
  name: "ZLabelList",
  mixins: [InputMixin],
  inject: ["$validator"],
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  methods: {
    kubun(options, val) {
      return this.$$ks.get(options, val).text;
    },
    kubunList(options, vals) {
      const ls = [];
      if (vals) {
        for (const v of vals) {
          ls.push(this.$$ks.get(options, v).text);
        }
      }
      return ls;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.labelDetail {
  &__labelArea {
    &__labelList {
      list-style-type: none;
      :first-child {
        padding-top: 0;
      }
      &__labelItem {
        padding-top: 20px;
      }
    }
  }
}

.mainItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &__label {
    font-size: 12px;
    font-weight: bold;
    color: #777777;
  }
  &__privateTag {
    height: 14px;
    width: 56px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #dddddd;
    &__txt {
      font-size: 12px;
      line-height: 14px;
      padding-left: 4px;
      color: #666666;
    }
    &__icon {
      height: 14px;
      width: 14px;
      font-size: 10px;
      padding-left: 1px;
      display: inline-block;
      vertical-align: middle;
      color: #666666;
    }
  }
  &__txt {
    font-size: 16px;
    flex: 0 0 100%;
    color: #333333;
  }
}

@include sp {
  .mainItem {
    &__privateTag {
      height: 12px;
      width: 45px;
      border-radius: 4px;

      &__txt {
        font-size: 10px;
        line-height: 12px;
        padding-left: 2px;
      }
      &__icon {
        height: 12px;
        width: 12px;
        font-size: 8px;
      }
    }
    &__txt {
      font-size: 14px;
    }
  }
}
</style>
