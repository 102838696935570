<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <z-postalcode
        label="郵便番号"
        v-model="postalcode"
        @chageAddress="chageAddress"
      >
      </z-postalcode>
      {{ retPostalcode }}
      <hr />
      <z-input label="市区町村(テキスト)" :validate="'required'" v-model="city">
      </z-input>

      <z-prefecture-select
        label="都道府県"
        v-model="prefecture"
        :validate="'required'"
        @changePrefecture="changePrefecture"
      >
      </z-prefecture-select>
      {{ prefectureInfo }}
      <z-city-select
        label="市区町村"
        :prefecture="prefecture"
        v-model="cityCode"
        :validate="'required'"
        isRequired
      >
      </z-city-select>
      {{ cityInfo }}
      <z-line-select
        label="沿線"
        :prefecture="prefecture"
        v-model="line"
        :validate="'required'"
        isRequired
      >
      </z-line-select>
      {{ lineInfo }}
      <z-station-select
        label="駅"
        :line="line"
        v-model="station"
        :validate="'required'"
      >
      </z-station-select>
      {{ stationInfo }}
    </section>
    <!--
    <hr />
    <z-prefecture-checkbox
      label="都道府県"
      v-model="prefectures"
      :validate="'required'"
    >
    </z-prefecture-checkbox>
    <z-city-checkbox
      label="市区町村"
      v-model="cities"
      :prefecture="prefecture"
      :validate="'required'"
    >
    </z-city-checkbox>

    <z-input label="町名・番地" :validate="'required'" v-model="address">
    </z-input>
    <z-line-checkbox
      label="沿線"
      :prefecture="prefecture"
      v-model="lines"
      :validate="'required'"
    >
    </z-line-checkbox>
    <z-station-checkbox
      label="駅"
      :line="line"
      v-model="stations"
      :validate="'required'"
    >
    </z-station-checkbox>
    -->
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-parts-demo",
  // components: {
  //   PrefectureSelect: PrefectureSelect
  // },
  asyncComputed: {
    async prefectureInfo() {
      const ls = await this.$$prefecture$load(this.prefecture);
      return ls;
    },
    async cityInfo() {
      const ls = await this.$$city$load(this.prefecture, this.cityCode);
      return ls;
    },
    async stationInfo() {
      const st = await this.$$station$load(this.station);
      return st;
    },
    async lineInfo() {
      const line = await this.$$line$load(this.line);
      return line;
    },
  },
  data: function () {
    return {
      postalcode: null,
      retPostalcode: null,
      demoInput: null,
      prefecture: null,
      prefectures: [],
      cities: [],
      cityCode: null,
      city: null,
      address: "",
      line: null,
      station: null,
      lines: [],
      stations: [],
    };
  },
  methods: {
    changePrefecture(val) {
      if (!val.initial) {
        this.cityCode = null;
      }
    },
    chageAddress(val) {
      this.retPostalcode = val;
      this.prefecture = val.prefectureCode;
      this.cityCode = val.cityCode;
      this.city = val.fullCityText;
      this.address = val.address;
    },
  },
  mounted() {
    this.$validator.validateAll();
  },
};
</script>
