import _ from "lodash";
// import * as mintrich from "mintrich-common";

const kubuns = {};

const initial = function (Vue) {
  Object.defineProperty(Vue.prototype, "$$ks", {
    get: () => this,
  });
};
kubuns.initial = initial;

const get = (ks, value) => {
  const empty = { value: null, text: "", color: "" };
  if (ks && value !== null) {
    const r = ks.find((x) => x.value === value);
    return r ? r : empty;
  } else {
    return empty;
  }
};
kubuns.get = get;

const getValue = (ks, text) => {
  const empty = { value: null, text: "", color: "" };
  if (ks && text) {
    const r = ks.find((x) => x.text === text);
    return r ? r : empty;
  } else {
    return empty;
  }
};
kubuns.getValue = getValue;

// kubuns.prefectures = mintrich.kubuns.prefectures;
kubuns.prefectures = [
  { text: "北海道", value: 1 },
  { text: "青森県", value: 2 },
  { text: "岩手県", value: 3 },
  { text: "宮城県", value: 4 },
  { text: "秋田県", value: 5 },
  { text: "山形県", value: 6 },
  { text: "福島県", value: 7 },
  { text: "茨城県", value: 8 },
  { text: "栃木県", value: 9 },
  { text: "群馬県", value: 10 },
  { text: "埼玉県", value: 11 },
  { text: "千葉県", value: 12 },
  { text: "東京都", value: 13 },
  { text: "神奈川県", value: 14 },
  { text: "新潟県", value: 15 },
  { text: "富山県", value: 16 },
  { text: "石川県", value: 17 },
  { text: "福井県", value: 18 },
  { text: "山梨県", value: 19 },
  { text: "長野県", value: 20 },
  { text: "岐阜県", value: 21 },
  { text: "静岡県", value: 22 },
  { text: "愛知県", value: 23 },
  { text: "三重県", value: 24 },
  { text: "滋賀県", value: 25 },
  { text: "京都府", value: 26 },
  { text: "大阪府", value: 27 },
  { text: "兵庫県", value: 28 },
  { text: "奈良県", value: 29 },
  { text: "和歌山県", value: 30 },
  { text: "鳥取県", value: 31 },
  { text: "島根県", value: 32 },
  { text: "岡山県", value: 33 },
  { text: "広島県", value: 34 },
  { text: "山口県", value: 35 },
  { text: "徳島県", value: 36 },
  { text: "香川県", value: 37 },
  { text: "愛媛県", value: 38 },
  { text: "高知県", value: 39 },
  { text: "福岡県", value: 40 },
  { text: "佐賀県", value: 41 },
  { text: "長崎県", value: 42 },
  { text: "熊本県", value: 43 },
  { text: "大分県", value: 44 },
  { text: "宮崎県", value: 45 },
  { text: "鹿児島", value: 46 },
  { text: "沖縄県", value: 47 },
];

kubuns.getPrefecture = (val) => get(kubuns.prefectures, val);

// 将来的にデータベース化するのでこれを直接使用するのではなくて、
// 下のデータ仮に入れている
// $$banks$$loadsを使用する
//kubuns.banks = [
//  { value: 0, text: "日本銀行", branches: [{ value: "93-1", text: "本店" }] },
//  {
//    value: 1,
//    text: "みずほ銀行",
//    branches: [
//      { value: "1-1", text: "東京営業部" },
//      { value: "1-2", text: "東京都庁公営企業出張所" },
//      { value: "4-1", text: "丸の内中央支店" },
//      { value: "5-1", text: "丸之内支店" }
//    ]
//  },
//  {
//    value: 5,
//    text: "三菱ＵＦＪ銀行",
//    branches: [
//      { value: "1-1", text: "本店" },
//      { value: "2-1", text: "丸の内支店" },
//      { value: "3-1", text: "瓦町支店" }
//    ]
//  },
//  {
//    value: 9,
//    text: "三井住友銀行",
//    branches: [
//      { value: "1-1", text: "神保町支店" },
//      { value: "5-1", text: "春日部支店" },
//      { value: "7-1", text: "新潟支店" }
//    ]
//  }
//];

// 1950-2025
kubuns.years = (function () {
  let ary = [];
  for (let i = 1950; i < 2051; i++) {
    ary.push({ value: i, text: i + "" });
  }
  return ary;
})();
kubuns.getYears = (val) => get(kubuns.years, val);

kubuns.months = [
  { value: 1, text: "01" },
  { value: 2, text: "02" },
  { value: 3, text: "03" },
  { value: 4, text: "04" },
  { value: 5, text: "05" },
  { value: 6, text: "06" },
  { value: 7, text: "07" },
  { value: 8, text: "08" },
  { value: 9, text: "09" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
];
kubuns.getMonth = (val) => get(kubuns.months, val);

kubuns.countOfMonths = [
  { value: 0, text: "00" },
  { value: 1, text: "01" },
  { value: 2, text: "02" },
  { value: 3, text: "03" },
  { value: 4, text: "04" },
  { value: 5, text: "05" },
  { value: 6, text: "06" },
  { value: 7, text: "07" },
  { value: 8, text: "08" },
  { value: 9, text: "09" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
];
kubuns.getCountOfMonth = (val) => get(kubuns.countOfMonths, val);

// グラフ表示用 年
// kubuns.chartYears = [
//   { value: 2019, text: "2019" },
//   { value: 2020, text: "2020" }
// ];
const thisYear = new Date().getFullYear();
const startYear = 2019;

kubuns.chartYears = [];
for (let y = startYear; y <= thisYear; y++) {
  kubuns.chartYears.push({ value: y, text: `${y}` });
}

kubuns.getChartYears = (val) => get(kubuns.chartYears, val);

kubuns.quarters = [
  { value: 1, text: "3" },
  { value: 2, text: "6" },
  { value: 3, text: "9" },
  { value: 4, text: "12" },
];

kubuns.getQuarter = (val) => get(kubuns.quarters, val);

kubuns.typeOfSex = [
  { value: 1, text: "男性" },
  { value: 2, text: "女性" },
];
kubuns.getTypeOfSex = (val) => get(kubuns.typeOfSex, val);

kubuns.typeOfOccupations = [
  // 経営
  { value: 101, text: "経営者・CEO", category: 1 },
  { value: 102, text: "COO・CIO・CFO等", category: 1 },
  { value: 103, text: "独立・自営業・フリーランス", category: 1 },
  // 独立業・自営業
  { value: 1501, text: "投資業・個人経営業", category: 1 },
  { value: 1502, text: "フリーランス", category: 1 },
  // 企画・管理
  { value: 201, text: "経営企画・事業開発・M&A（提携含む）", category: 3 },
  { value: 202, text: "内部監査・内部統制 ", category: 3 },
  { value: 203, text: "法務・コンプライアンス", category: 3 },
  { value: 204, text: "総務・秘書", category: 3 },
  { value: 205, text: "財務・経理", category: 3 },
  { value: 206, text: "商品企画・開発", category: 3 },
  { value: 207, text: "営業企画", category: 3 },
  { value: 208, text: "業務・オペレーション", category: 3 },
  { value: 209, text: "システム企画・管理・運用", category: 3 },
  { value: 210, text: "物流・購買・在庫管理", category: 3 },
  // 人事
  { value: 301, text: "人事・採用", category: 3 },
  { value: 302, text: "労務・給与", category: 3 },
  // 営業
  { value: 401, text: "法人営業", category: 3 },
  { value: 402, text: "個人営業", category: 3 },
  { value: 403, text: "海外営業", category: 3 },
  { value: 404, text: "コールセンター・カスタマーサポート", category: 3 },
  // マーケティング
  { value: 501, text: "広報・PR", category: 3 },
  { value: 502, text: "リサーチ・マーケティング", category: 3 },
  // 研究・開発
  { value: 601, text: "研究・開発", category: 3 },
  // 生産・品質
  { value: 701, text: "生産技術・生産管理・製造技術", category: 3 },
  { value: 702, text: "知的財産", category: 3 },
  { value: 703, text: "工場長運営者", category: 3 },
  { value: 704, text: "工場責任者・運営者", category: 3 },
  // 専門職（士業など）
  { value: 801, text: "経営・戦略コンサルタント", category: 2 },
  { value: 802, text: "ITコンサルタント", category: 2 },
  { value: 803, text: "公認会計士・税理士", category: 1 },
  // { value: 804, text: "公認会計士・税理士", category: 1 },
  // { value: 805, text: "税理士", category: 1 },
  { value: 806, text: "弁護士", category: 1 },
  { value: 807, text: "行政書士・司法書士", category: 1 },
  { value: 808, text: "教授・准教授・教諭", category: 3 },
  { value: 809, text: "講師・トレーナー", category: 3 },
  // 専門職（医療）
  { value: 901, text: "医師", category: 1 },
  { value: 902, text: "看護師", category: 3 },
  { value: 903, text: "薬剤師・管理薬剤師", category: 3 },
  { value: 904, text: "臨床検査技師", category: 3 },
  { value: 905, text: "医療研究", category: 3 },
  { value: 906, text: "医療・医薬系研究職", category: 3 },
  { value: 907, text: "MR ・医療機器営業", category: 2 },
  { value: 908, text: "その他医療従事者", category: 3 },
  // 専門職（金融）
  { value: 1001, text: "ディーラー・トレーダー", category: 2 },
  { value: 1002, text: "ファンドマネージャー", category: 2 },
  { value: 1003, text: "プライベートバンカー", category: 2 },
  { value: 1004, text: "アナリスト・エコノミスト", category: 2 },
  { value: 1005, text: "M&Aアドバイザリー", category: 2 },
  { value: 1006, text: "PE", category: 2 },
  { value: 1007, text: "その他金融・ファイナンス関連", category: 2 },
  // IT
  { value: 1101, text: "プロダクトマネージャー", category: 3 },
  { value: 1102, text: "プロジェクトマネージャー", category: 3 },
  { value: 1103, text: "SE・プログラマー", category: 3 },
  // メディア・デザイン
  {
    value: 1201,
    text: "プロデューサー・ディレクター・プランナー",
    category: 3,
  },
  { value: 1202, text: "デザイナー", category: 3 },
  { value: 1203, text: "コピーライター", category: 3 },
  { value: 1204, text: "制作・編集", category: 3 },
  { value: 1205, text: "記者・ライター", category: 3 },
  // 不動産・建築
  { value: 1301, text: "不動産企画・開発 ・仕入", category: 3 },
  { value: 1302, text: "アセット・プロパティマネジメント", category: 3 },
  { value: 1303, text: "不動産・マンション・ビル管理", category: 3 },
  { value: 1304, text: "建設・土木設計", category: 3 },
  { value: 1305, text: "建築施工管理", category: 3 },
  // その他サービス
  { value: 1401, text: "議員・政治家", category: 1 },
  { value: 1402, text: "芸能・スポーツ選手", category: 1 },
  { value: 1403, text: "パイロット・車掌", category: 3 },
  { value: 1404, text: "官僚・公務員", category: 3 },
  { value: 1405, text: "店舗管理・店舗運営", category: 3 },
  { value: 1406, text: "介護福祉士", category: 3 },
  { value: 1407, text: "その他", category: 3 },
];
kubuns.getTypeOfOccupation = (val) => get(kubuns.typeOfOccupations, val);
kubuns.getTypeOfOccupationRealEstateValues = [1301, 1302, 1303];

// kubuns.typeOfOccupations = [
//   { value: 1, text: "経営" },
//   { value: 2, text: "管理" },
//   { value: 3, text: "マーケティング" },
//   { value: 4, text: "営業 コンサルタント" },
//   { value: 5, text: "ITコンサルタント" },
//   { value: 6, text: "専門職" },
//   { value: 7, text: "IT技術職" },
//   { value: 8, text: "ゲーム" },
//   { value: 9, text: "電気・電子" },
//   { value: 10, text: "半導体" },
//   { value: 11, text: "機械" },
//   { value: 12, text: "化学" },
//   { value: 13, text: "金融" },
//   { value: 14, text: "研究・臨床開発・治験 建築・土木" },
//   { value: 15, text: "人事" },
//   { value: 16, text: "サービス" },
//   { value: 17, text: "プロジェクト管理" },
//   { value: 18, text: "Webサービス・制作" },
//   { value: 19, text: "デジタルマーケティング" },
//   { value: 20, text: "広告" },
//   { value: 21, text: "新聞・出版" },
//   { value: 22, text: "テレビ・放送・映像・音響" },
//   { value: 23, text: "デザイン" },
//   { value: 24, text: "素材" },
//   { value: 25, text: "食品" },
//   { value: 26, text: "化粧品" },
//   { value: 27, text: "日用品" },
//   { value: 28, text: "不動産" },
//   { value: 29, text: "施工管理" },
//   { value: 30, text: "医療営業" },
//   { value: 31, text: "生産管理・品質管理・品質保証" },
//   { value: 32, text: "学術・PMS・薬事" },
//   { value: 33, text: "医療・看護・薬剤" }
// ];
//
// kubuns.getTypeOfOccupation = val => get(kubuns.typeOfOccupations, val);

kubuns.categoryOfBusinesses = [
  // 金融
  { value: 101, text: "銀行（信託銀行・信用金庫・組合含む）" },
  { value: 102, text: "証券" },
  { value: 103, text: "投資銀行" },
  { value: 104, text: "アセットマネジメント" },
  { value: 105, text: "プライベートエクイティ・ファンド" },
  { value: 106, text: "ベンチャーキャピタル" },
  { value: 107, text: "クレジット・信販" },
  { value: 108, text: "その他金融機関" },
  // 保険
  { value: 201, text: "生命保険" },
  { value: 202, text: "損害保険" },
  // コンサルティング
  { value: 301, text: "コンサルティング ・シンクタンク" },
  // 士業
  { value: 401, text: "監査事務所・税理士事務所・特許事務所" },
  { value: 402, text: "弁護士事務所・法律系事務所" },
  // IT・インターネット
  { value: 501, text: "インターネットサービス" },
  { value: 502, text: "SIer" },
  { value: 503, text: "ソフトウエア・ハードウエア" },
  { value: 504, text: "通信・キャリア" },
  // マスコミ・メディア
  { value: 601, text: "広告・PR" },
  { value: 602, text: "テレビ・放送・映像・音響" },
  { value: 603, text: "新聞・出版 ・印刷" },
  // エンターテインメント
  { value: 701, text: "音楽 ・映画・ゲーム" },
  // 商社
  { value: 801, text: "総合商社" },
  { value: 802, text: "専門商社" },
  // 流通・小売
  { value: 901, text: "流通・小売" },
  // 運輸・交通
  { value: 1001, text: "海運" },
  { value: 1002, text: "鉄道" },
  { value: 1003, text: "空輸・空港" },
  // 物流・倉庫
  { value: 1101, text: "物流・倉庫" },
  // メーカー
  { value: 1201, text: "電気・電子メーカー" },
  { value: 1202, text: "半導体メーカー" },
  { value: 1203, text: "機械メーカー" },
  { value: 1204, text: "精密・計測機器メーカー" },
  { value: 1205, text: "自動車・自動車部品メーカー" },
  { value: 1206, text: "化学・石油メーカー" },
  { value: 1207, text: "素材メーカー" },
  { value: 1208, text: "食品・飲料メーカー" },
  { value: 1209, text: "日用品メーカー" },
  { value: 1210, text: "化粧品メーカー" },
  { value: 1211, text: "アパレル・ファッション" },
  { value: 1212, text: "その他メーカー" },
  // メディカル
  { value: 1301, text: "病院・クリニック" },
  { value: 1302, text: "ドラッグストア・調剤薬局" },
  { value: 1303, text: "医薬品メーカー・卸" },
  { value: 1304, text: "医療機器メーカー・卸" },
  { value: 1305, text: "その他医療関連" },
  // 不動産
  { value: 1401, text: "デベロッパー" },
  { value: 1402, text: "不動産仲介・管理" },
  // 建設
  { value: 1501, text: "ゼネコン" },
  { value: 1502, text: "サブコン" },
  { value: 1503, text: "住宅設備・ハウスメーカー" },
  { value: 1504, text: "プラント・エンジニアリング" },
  // エネルギー
  { value: 1601, text: "電力・ガス・水道" },
  { value: 1602, text: "エネルギー関連" },
  // サービス
  { value: 1701, text: "人材紹介・人材派遣・コールセンター" },
  { value: 1702, text: "旅行・観光・ホテル" },
  { value: 1703, text: "アミューズメント" },
  { value: 1704, text: "福祉・介護" },
  { value: 1705, text: "外食" },
  { value: 1706, text: "ブライダル" },
  { value: 1707, text: "その他サービス" },
  // 教育・官公庁
  { value: 1801, text: "教育" },
  { value: 1802, text: "官公庁" },
  { value: 1803, text: "自治体" },
  { value: 1804, text: "大学・研究施設" },
  { value: 1805, text: "農林・水産" },
  { value: 1806, text: "その他" },
];
kubuns.getCategoryOfBusiness = (val) => get(kubuns.categoryOfBusinesses, val);
kubuns.getCategoryOfBusinessRealEstateValues = [1401, 1402];

// kubuns.categoryOfBusinesses = [
//   { value: 1, text: "IT・インターネット" },
//   { value: 2, text: "メーカー" },
//   { value: 3, text: "商社" },
//   { value: 4, text: "流通・小売" },
//   { value: 5, text: "コンサルティング" },
//   { value: 6, text: "マスコミ・メディア" },
//   { value: 7, text: "エンターテインメント" },
//   { value: 8, text: "金融" },
//   { value: 9, text: "建設" },
//   { value: 10, text: "メディカル" },
//   { value: 11, text: "エネルギー" },
//   { value: 12, text: "保険" },
//   { value: 13, text: "不動産" },
//   { value: 14, text: "士業" },
//   { value: 15, text: "サービス" },
//   { value: 16, text: "運輸・交通" },
//   { value: 17, text: "物流・倉庫" },
//   { value: 18, text: "その他（教育・官公庁）など" }
// ];
// kubuns.getCategoryOfBusiness = val => get(kubuns.categoryOfBusinesses, val);

kubuns.realEstateTypes = [
  { value: 1, text: "一棟マンション・アパート" },
  { value: 2, text: "ビル" },
  { value: 3, text: "ホテル" },
  { value: 4, text: "戸建" },
  { value: 5, text: "倉庫" },
  { value: 6, text: "工場" },
  { value: 7, text: "駐車場" },
  { value: 8, text: "区分マンション" },
  { value: 9, text: "区分店舗・事務所" },
  { value: 10, text: "土地" },
  { value: 99, text: "その他" },
];
kubuns.realEstateType = (val) => get(kubuns.realEstateTypes, val);

kubuns.ranks = [
  { value: 1, text: "S" },
  { value: 2, text: "A" },
  { value: 3, text: "B" },
  { value: 4, text: "C" },
];
kubuns.getRank = (val) => get(kubuns.ranks, val);

kubuns.interestTypes = [
  { text: "固定", value: 1 },
  { text: "変動", value: 2 },
];
kubuns.interestType = (val) => get(kubuns.interestTypes, val);

kubuns.loanOrCashTypes = [
  { value: 1, text: "融資あり" },
  { value: 2, text: "融資なし（現金買い）" },
];
kubuns.loanOrCashType = (val) => get(kubuns.loanOrCash, val);

kubuns.loanMethodTypes = [
  { value: 1, text: "元利均等" },
  { value: 2, text: "元金均等" },
];
kubuns.loanMethodType = (val) => get(kubuns.loanMethodTypes, val);

kubuns.typeOfBuildingTypes = [
  { value: 1, text: "RC・SRC" },
  { value: 2, text: "鉄骨" },
  { value: 3, text: "軽量鉄骨" },
  { value: 4, text: "木造" },
  { value: 99, text: "その他" },
];
kubuns.getTypeOfBuildingType = (val) => get(kubuns.typeOfBuildingTypes, val);

kubuns.marketTrendTypes = [
  { value: 1, text: "RC・SRC" },
  { value: 2, text: "鉄骨" },
  { value: 3, text: "軽量鉄骨" },
  { value: 4, text: "木造" },
  { value: 5, text: "区分所有" },
];
kubuns.getMarketTrendType = (val) => get(kubuns.marketTrendTypes, val);

kubuns.landRightTypes = [
  { value: 1, text: "所有権" },
  { value: 2, text: "普通賃借権" },
  // { value: 6, text: "定期借地権" },
  { value: 3, text: "旧法賃借権" },
  { value: 4, text: "普通地上権" },
  { value: 5, text: "旧法地上権" },

  { value: 6, text: "定期借地権" },
  { value: 7, text: "定期地上権" },
  { value: 8, text: "地上権" },
  { value: 9, text: "旧法地上権" },

  { value: 99, text: "その他" },
];
kubuns.getLandRightType = (val) => get(kubuns.landRightTypes, val);

kubuns.urbanPlanningAreaTypes = [
  { value: 1, text: "市街化区域" },
  { value: 2, text: "市街化調整区域" },
  { value: 3, text: "その他" },
];
kubuns.getUrbanPlanningAreaType = (val) =>
  get(kubuns.urbanPlanningAreaTypes, val);

kubuns.incomeTypes = [
  { value: 1, text: "給与・役員報酬" },
  { value: 2, text: "配当・利子" },
  { value: 3, text: "年金" },
  { value: 4, text: "その他" },
];
kubuns.getIncomeType = (val) => get(kubuns.incomeTypes, val);

kubuns.incomeCashTypes = [
  { value: 1, text: "普通" },
  { value: 2, text: "定期" },
  { value: 3, text: "その他" },
];
kubuns.getIncomeCashType = (val) => get(kubuns.incomeCashTypes, val);

kubuns.incomeSecurityTypes = [
  { value: 1, text: "株式" },
  { value: 2, text: "債券" },
  { value: 3, text: "投信" },
  { value: 4, text: "その他" },
];
kubuns.getIncomeSecurityType = (val) => get(kubuns.incomeSecurityTypes, val);

kubuns.incomeInsuranceTypes = [
  { value: 1, text: "生保" },
  { value: 2, text: "損保" },
  { value: 3, text: "その他" },
];
kubuns.getIncomeInsuranceType = (val) => get(kubuns.incomeInsuranceTypes, val);

kubuns.educationTypes = [
  { value: 1, text: "大学院" },
  { value: 2, text: "大学" },
  { value: 3, text: "高専・専門・短大" },
  { value: 4, text: "高校" },
  { value: 5, text: "その他" },
];
kubuns.getEducationType = (val) => get(kubuns.educationTypes, val);

kubuns.layouts = [
  { value: 10, text: "1R" },
  { value: 101, text: "1K" },
  { value: 110, text: "1DK" },
  { value: 120, text: "1LDK" },
  { value: 201, text: "2K" },
  { value: 210, text: "2DK" },
  { value: 220, text: "2LDK" },
  { value: 301, text: "3K" },
  { value: 310, text: "3DK" },
  { value: 320, text: "3LDK" },
  { value: 401, text: "4K" },
  { value: 410, text: "4DK" },
  { value: 420, text: "4LDK" },
  { value: 500, text: "5K以上" },
];
kubuns.getlayout = (val) => get(kubuns.layouts, val);

kubuns.textureTypes = [
  {
    text: "宅地",
    description: "建物の敷地及びその維持若しくは効用を果たすために必要な土地",
    value: 10,
  },
  { text: "田", description: "農耕地で用水を利用して耕作する土地", value: 40 },
  {
    text: "畑",
    description: "農耕地で用水を利用しないで耕作する土地",
    value: 50,
  },
  {
    text: "学校用地",
    description: "校舎、附属施設の敷地及び運動場",
    value: 31,
  },
  {
    text: "鉄道用地",
    description: "鉄道の駅舎、附属施設及び路線の敷地",
    value: 36,
  },
  { text: "塩田", description: "海水を引き入れて塩を採取する土地", value: 89 },
  {
    text: "鉱泉地",
    description: "鉱泉（温泉を含む）の湧出口及びその維持に必要な土地",
    value: 88,
  },
  { text: "池沼", description: "かんがい用水でない水の貯留地", value: 87 },
  {
    text: "山林",
    description: "耕作の方法によらないで竹木の生育する土地",
    value: 71,
  },
  { text: "牧場", description: "家畜を放牧する土地", value: 60 },
  {
    text: "原野",
    description: "耕作の方法によらないで雑草、かん木類の生育する土地",
    value: 73,
  },
  {
    text: "墓地",
    description:
      "人の遺体又は遺骨を埋葬する土地。墓地、埋葬等に関する法律（昭和23年5月31日法律第48号） ",
    value: 34,
  },
  {
    text: "境内地",
    description:
      "境内に属する土地であって、宗教法人法（昭和26年法律第126号）第3条第2号及び第3号に掲げる土地（宗教法人の所有に属しないものを含む）",
    value: 33,
  },
  {
    text: "運河用地",
    description:
      "運河法（大正2年法律第16号）第12条第1項第1号又は第2号に掲げる土地",
    value: 83,
  },
  {
    text: "水道用地",
    description:
      "専ら給水の目的で敷設する水道の水源地、貯水池、ろ水場又は水道線路に要する土地 ",
    value: 82,
  },
  {
    text: "用悪水路",
    description: "かんがい用又は悪水はいせつ用の水路",
    value: 84,
  },
  { text: "ため池", description: "耕地かんがい用の用水貯留地", value: 86 },
  { text: "堤", description: "防水のために築造した堤防", value: 81 },
  { text: "井溝", description: "田畝又は村落の間にある通水路", value: 85 },
  {
    text: "保安林",
    description:
      "森林法（昭和26年法律第249号）に基づき農林水産大臣が保安林として指定した土地 ",
    value: 72,
  },
  {
    text: "公衆用道路",
    description:
      "一般交通の用に供する道路（道路法（昭和27年法律第180号）による道路であるかどうかを問わない）",
    value: 35,
  },
  { text: "公園", description: "公衆の遊楽のために供する土地", value: 32 },
  { text: "雑種地", description: "以上のいずれにも該当しない土地", value: 90 },
  { text: "その他", description: "その他", value: 999 },
];
kubuns.getTextureType = (val) => get(kubuns.textureTypes, val);

kubuns.areaOfUseTypes = [
  { text: "第一種低層住居専用地域", value: 1 },
  { text: "第二種低層住居専用地域", value: 2 },
  { text: "第一種中高層住居専用地域", value: 3 },
  { text: "第二種中高層住居専用地域", value: 4 },
  { text: "第一種住居地域", value: 5 },
  { text: "第二種住居地域", value: 6 },
  { text: "準住居地域", value: 7 },
  { text: "近隣商業地域	", value: 8 },
  { text: "商業地域	", value: 9 },
  { text: "準工業地域	", value: 10 },
  { text: "工業地域	", value: 11 },
  { text: "工業専用地域	", value: 12 },
  { text: "不明	", value: 99 },
];
kubuns.getAreaOfUseType = (val) => get(kubuns.areaOfUseTypes, val);

// https://ja.wikipedia.org/wiki/ISO_3166-1
// 上記を参考に番号をふる
kubuns.countries = [
  { text: "Thailand（タイ）", value: 764 },
  { text: "United States（アメリカ合衆国）", value: 840 },
  { text: "Philippines（フィリピン）", value: 608 },
];

kubuns.getCountry = (val) => get(kubuns.countries, val);

kubuns.favorite = {};

kubuns.favorite.types = [
  { text: "優先提案", value: 1 },
  { text: "継続", value: 2 },
  { text: "拒否", value: 999 },
];

kubuns.favorite.type = (val) => get(kubuns.favorite.types, val);

kubuns.realEstate = {};
kubuns.realEstate.publicTypes = [
  { value: 1, text: "非公開" },
  { value: 2, text: "専門家" },
];

kubuns.getRealEstatePublicType = (val) =>
  get(kubuns.realEstate.publicTypes, val);

kubuns.realEstate.buildingUses = [
  { value: 1, text: "投資用(収益不動産)" },
  { value: 2, text: "自宅" },
  { value: 3, text: "事業用(自社ビル等)" },
];
kubuns.realEstate.getBuildingUses = (val) =>
  get(kubuns.realEstate.buildingUses, val);

kubuns.filters = {};
kubuns.filters.score = {};
kubuns.filters.score.min = 0;
kubuns.filters.score.max = 900;
kubuns.filters.score.from = [
  { value: 0, text: "下限なし" },
  { value: 100, text: "100" },
  { value: 200, text: "200" },
  { value: 300, text: "300" },
  { value: 400, text: "400" },
  { value: 500, text: "500" },
  { value: 600, text: "600" },
  { value: 700, text: "700" },
  { value: 800, text: "800" },
  { value: 900, text: "900" },
];

kubuns.filters.score.to = [
  //{ value: 990, text: "上限なし" },
  { value: 1000, text: "上限なし" },
  { value: 100, text: "100" },
  { value: 200, text: "200" },
  { value: 300, text: "300" },
  { value: 400, text: "400" },
  { value: 500, text: "500" },
  { value: 600, text: "600" },
  { value: 700, text: "700" },
  { value: 800, text: "800" },
  { value: 900, text: "900" },
];

kubuns.filters.yieldRate = {};
kubuns.filters.yieldRate.min = 0;
kubuns.filters.yieldRate.max = 100;
kubuns.filters.yieldRate.from = [
  { value: 0, text: "下限なし" },
  { value: 0.05, text: "5.0" },
  { value: 0.06, text: "6.0" },
  { value: 0.07, text: "7.0" },
  { value: 0.08, text: "8.0" },
  { value: 0.09, text: "9.0" },
  { value: 0.1, text: "10.0" },
  { value: 0.11, text: "11.0" },
  { value: 0.12, text: "12.0" },
  { value: 0.13, text: "13.0" },
  { value: 0.14, text: "14.0" },
  { value: 0.15, text: "15.0" },
  { value: 0.2, text: "20.0" },
];

kubuns.filters.yieldRate.to = [
  { value: 100, text: "上限なし" },
  { value: 0.05, text: "5.0" },
  { value: 0.06, text: "6.0" },
  { value: 0.07, text: "7.0" },
  { value: 0.08, text: "8.0" },
  { value: 0.09, text: "9.0" },
  { value: 0.1, text: "10.0" },
  { value: 0.11, text: "11.0" },
  { value: 0.12, text: "12.0" },
  { value: 0.13, text: "13.0" },
  { value: 0.14, text: "14.0" },
  { value: 0.15, text: "15.0" },
  { value: 0.2, text: "20.0" },
];

kubuns.getYieldRateFrom = (val) => get(kubuns.filters.yieldRate.from, val);
kubuns.getYieldRateTo = (val) => get(kubuns.filters.yieldRate.to, val);

kubuns.filters.price = {};
kubuns.filters.price.min = 0;
kubuns.filters.price.max = 9999999999;
kubuns.filters.price.from = [
  { value: 0, text: "下限なし" },
  { value: 5000000, text: "500万" },
  { value: 10000000, text: "1000万" },
  { value: 20000000, text: "2000万" },
  { value: 30000000, text: "3000万" },
  { value: 40000000, text: "4000万" },
  { value: 50000000, text: "5000万" },
  { value: 60000000, text: "6000万" },
  { value: 70000000, text: "7000万" },
  { value: 80000000, text: "8000万" },
  { value: 90000000, text: "9000万" },
  { value: 100000000, text: "1億" },
  { value: 200000000, text: "2億" },
  { value: 300000000, text: "3億" },
  { value: 500000000, text: "5億" },
];

kubuns.filters.price.to = [
  { value: 9999999999, text: "上限なし" },
  { value: 5000000, text: "500万" },
  { value: 10000000, text: "1000万" },
  { value: 20000000, text: "2000万" },
  { value: 30000000, text: "3000万" },
  { value: 40000000, text: "4000万" },
  { value: 50000000, text: "5000万" },
  { value: 60000000, text: "6000万" },
  { value: 70000000, text: "7000万" },
  { value: 80000000, text: "8000万" },
  { value: 90000000, text: "9000万" },
  { value: 100000000, text: "1億" },
  { value: 200000000, text: "2億" },
  { value: 300000000, text: "3億" },
  { value: 500000000, text: "5億" },
];

kubuns.getPriceFrom = (val) => get(kubuns.filters.price.from, val);
kubuns.getPriceTo = (val) => get(kubuns.filters.price.to, val);

kubuns.filters.income = {};
kubuns.filters.income.min = 0;
kubuns.filters.income.max = 9999999999;
kubuns.filters.income.from = [
  { value: 0, text: "下限なし" },
  { value: 5000000, text: "500万" },
  { value: 6000000, text: "600万" },
  { value: 7000000, text: "700万" },
  { value: 8000000, text: "800万" },
  { value: 9000000, text: "900万" },
  { value: 10000000, text: "1000万" },
  { value: 15000000, text: "1500万" },
  { value: 20000000, text: "2000万" },
  { value: 30000000, text: "3000万" },
  { value: 40000000, text: "4000万" },
  { value: 50000000, text: "5000万" },
  { value: 100000000, text: "1億" },
];

kubuns.filters.income.to = [
  { value: 9999999999, text: "上限なし" },
  { value: 5000000, text: "500万" },
  { value: 6000000, text: "600万" },
  { value: 7000000, text: "700万" },
  { value: 8000000, text: "800万" },
  { value: 9000000, text: "900万" },
  { value: 10000000, text: "1000万" },
  { value: 15000000, text: "1500万" },
  { value: 20000000, text: "2000万" },
  { value: 30000000, text: "3000万" },
  { value: 40000000, text: "4000万" },
  { value: 50000000, text: "5000万" },
  { value: 100000000, text: "1億" },
];

kubuns.getIncomeFrom = (val) => get(kubuns.filters.income.from, val);
kubuns.getIncomeTo = (val) => get(kubuns.filters.income.to, val);

kubuns.filters.excludeOfferedPeriod = {};
kubuns.filters.excludeOfferedPeriod = [
  { value: 0, text: "指定なし" },
  { value: 1, text: "1日以内" },
  { value: 2, text: "1週間以内" },
  { value: 3, text: "2週間以内" },
  { value: 4, text: "1ヶ月以内" },
  { value: 5, text: "3ヶ月以内" },
  { value: 6, text: "6ヶ月以内" },
  { value: 7, text: "1年以内" },
];

kubuns.getExcludeOfferedPeriod = (val) =>
  get(kubuns.filters.excludeOfferedPeriod, val);

kubuns.filters.yearOfconstruction = {};
kubuns.filters.yearOfconstruction.min = 0;
kubuns.filters.yearOfconstruction.max = 999;
kubuns.filters.yearOfconstruction.from = [
  { value: 0, text: "指定なし" },
  { value: 1, text: "新築" },
  { value: 5, text: "5" },
  { value: 10, text: "10" },
  { value: 15, text: "15" },
  { value: 20, text: "20" },
  { value: 25, text: "25" },
  { value: 30, text: "30" },
];

kubuns.filters.yearOfconstruction.to = [
  { value: 999, text: "指定なし" },
  { value: 5, text: "5" },
  { value: 10, text: "10" },
  { value: 15, text: "15" },
  { value: 20, text: "20" },
  { value: 25, text: "25" },
  { value: 30, text: "30" },
];

kubuns.getYearOfconstructionFrom = (val) =>
  get(kubuns.filters.yearOfconstruction.from, val);
kubuns.getYearOfconstructionTo = (val) =>
  get(kubuns.filters.yearOfconstruction.to, val);

kubuns.filters.elapsedTime = [
  { value: 999, text: "指定なし" },
  { value: 3, text: "～3分" },
  { value: 5, text: "～5分" },
  { value: 7, text: "～7分" },
  { value: 10, text: "～10分" },
  { value: 15, text: "～15分" },
  { value: 20, text: "～20分" },
];

kubuns.getElapsedTime = (val) => get(kubuns.filters.elapsedTime, val);

kubuns.filters.elapsedTimeRange = [
  { value: 999, text: "指定なし" },
  { value: 0, text: "0～3分" },
  { value: 4, text: "4～5分" },
  { value: 6, text: "6～7分" },
  { value: 8, text: "8～10分" },
  { value: 11, text: "11～15分" },
  { value: 16, text: "16～20分" },
  { value: 20, text: "20分以上" },
];

kubuns.getElapsedTimeRange = (val) => get(kubuns.filters.elapsedTimeRange, val);

kubuns.filters.buildingArea = {};
kubuns.filters.buildingArea.min = 0;
kubuns.filters.buildingArea.max = 9999;
kubuns.filters.buildingArea.from = [
  { value: 0, text: "下限なし" },
  { value: 16, text: "16㎡以上" },
  { value: 18, text: "18㎡以上" },
  { value: 20, text: "20㎡以上" },
  { value: 30, text: "30㎡以上" },
  { value: 50, text: "50㎡以上" },
  { value: 60, text: "60㎡以上" },
  { value: 70, text: "70㎡以上" },
  { value: 80, text: "80㎡以上" },
  { value: 90, text: "90㎡以上" },
  { value: 100, text: "100㎡以上" },
  { value: 120, text: "120㎡以上" },
  { value: 150, text: "150㎡以上" },
  { value: 200, text: "200㎡以上" },
  { value: 250, text: "250㎡以上" },
  { value: 300, text: "300㎡以上" },
  { value: 400, text: "400㎡以上" },
  { value: 500, text: "500㎡以上" },
  { value: 700, text: "700㎡以上" },
  { value: 1000, text: "1000㎡以上" },
];

kubuns.filters.buildingArea.to = [
  { value: 9999, text: "上限なし" },
  { value: 16, text: "16㎡以下" },
  { value: 18, text: "18㎡以下" },
  { value: 20, text: "20㎡以下" },
  { value: 30, text: "30㎡以下" },
  { value: 50, text: "50㎡以下" },
  { value: 60, text: "60㎡以下" },
  { value: 70, text: "70㎡以下" },
  { value: 80, text: "80㎡以下" },
  { value: 90, text: "90㎡以下" },
  { value: 100, text: "100㎡以下" },
  { value: 120, text: "120㎡以下" },
  { value: 150, text: "150㎡以下" },
  { value: 200, text: "200㎡以下" },
  { value: 250, text: "250㎡以下" },
  { value: 300, text: "300㎡以下" },
  { value: 400, text: "400㎡以下" },
  { value: 500, text: "500㎡以下" },
  { value: 700, text: "700㎡以下" },
  { value: 1000, text: "1000㎡以下" },
];

kubuns.filters.landArea = {};
kubuns.filters.landArea.min = 0;
kubuns.filters.landArea.max = 9999;
kubuns.filters.landArea.from = [
  { value: 0, text: "下限なし" },
  { value: 50, text: "50㎡以上" },
  { value: 60, text: "60㎡以上" },
  { value: 70, text: "70㎡以上" },
  { value: 80, text: "80㎡以上" },
  { value: 90, text: "90㎡以上" },
  { value: 100, text: "100㎡以上" },
  { value: 120, text: "120㎡以上" },
  { value: 150, text: "150㎡以上" },
  { value: 200, text: "200㎡以上" },
  { value: 250, text: "250㎡以上" },
  { value: 300, text: "300㎡以上" },
  { value: 400, text: "400㎡以上" },
  { value: 500, text: "500㎡以上" },
  { value: 700, text: "700㎡以上" },
  { value: 1000, text: "1000㎡以上" },
];

kubuns.filters.landArea.to = [
  { value: 9999, text: "上限なし" },
  { value: 50, text: "50㎡以下" },
  { value: 60, text: "60㎡以下" },
  { value: 70, text: "70㎡以下" },
  { value: 80, text: "80㎡以下" },
  { value: 90, text: "90㎡以下" },
  { value: 100, text: "100㎡以下" },
  { value: 120, text: "120㎡以下" },
  { value: 150, text: "150㎡以下" },
  { value: 200, text: "200㎡以下" },
  { value: 250, text: "250㎡以下" },
  { value: 300, text: "300㎡以下" },
  { value: 400, text: "400㎡以下" },
  { value: 500, text: "500㎡以下" },
  { value: 700, text: "700㎡以下" },
  { value: 1000, text: "1000㎡以下" },
];

kubuns.getFilterType = (val, key) => get(_.get(kubuns.filters, key), val);

kubuns.investor = {};
kubuns.investor.relationships = [
  { value: 99, text: "本人" },
  { value: 1, text: "配偶者" },
  { value: 2, text: "父" },
  { value: 3, text: "母" },
  { value: 4, text: "子供" },
  { value: 5, text: "兄弟" },
  { value: 6, text: "その他" },
];

kubuns.investor.application = {};
kubuns.investor.application.memberships = [
  { value: 10, text: "無料会員", color: "is-primary" },
  { value: 20, text: "プレミアム会員", color: "is-warning" },
];
kubuns.investor.application.getMembership = (val) =>
  get(kubuns.investor.application.memberships, val);

kubuns.investor.application.statuses = [
  { value: 1, text: "未申請", color: "is-light" },
  { value: 10, text: "申請中", color: "is-danger" },
  { value: 20, text: "承認", color: "is-primary" },
  { value: 90, text: "否認", color: "" },
  { value: 99, text: "設定なし", color: "" },
];
kubuns.investor.application.getStatus = (val) =>
  get(kubuns.investor.application.statuses, val);

kubuns.realtor = {};

kubuns.realtor.realEstate = {};
kubuns.realtor.realEstate.vacancyRates = [
  { value: 1, text: "満室" },
  { value: 2, text: "空き室あり" },
];

kubuns.realtor.realEstate.getVacancyRate = (val) =>
  get(kubuns.realtor.realEstate.vacancyRates, val);

kubuns.realtor.realEstate.transactionTypes = [
  { value: 1, text: "一般媒介" },
  { value: 2, text: "専任媒介" },
  { value: 3, text: "専属選任媒介" },
  { value: 4, text: "代理" },
  { value: 5, text: "仲介" },
  { value: 6, text: "売主" },
];
kubuns.realtor.realEstate.getTransactionType = (val) =>
  get(kubuns.realtor.realEstate.transactionTypes, val);

kubuns.realtor.realEstate.publicTypes = [
  { value: 1, text: "公開", color: "is-primary" },
  //  { value: 2, text: "有料会員限定公開", color: "is-warning" },
  { value: 3, text: "非公開", color: "is-dark" },
];

kubuns.realtor.realEstate.publicType = (val) =>
  get(kubuns.realtor.realEstate.publicTypes, val);

kubuns.realtor.application = {};
kubuns.realtor.application.company = {};
kubuns.realtor.application.company.statuses = [
  { value: 1, text: "申請中", color: "is-danger" },
  { value: 20, text: "承認", color: "is-primary" },
  { value: 90, text: "否認", color: "" },
];
kubuns.realtor.application.company.getStatus = (val) =>
  get(kubuns.realtor.application.company.statuses, val);

kubuns.realtor.application.user = {};
kubuns.realtor.application.user.statuses = [
  { value: 1, text: "アカウント未発行", color: "is-dark" },
  { value: 5, text: "アカウント発行済み", color: "is-warning" },
  { value: 10, text: "内容申請中", color: "is-danger" },
  { value: 20, text: "承認", color: "is-primary" },
  { value: 90, text: "否認", color: "" },
];
kubuns.realtor.application.user.getStatus = (val) =>
  get(kubuns.realtor.application.user.statuses, val);

kubuns.realtor.application.reasonForRejections = [
  { value: 100, text: "入力不備・不十分" },
  { value: 200, text: "反社の可能性" },
  { value: 999, text: "その他" },
];
kubuns.realtor.application.getReasonForRejection = (val) =>
  get(kubuns.realtor.application.reasonForRejections, val);

kubuns.realtor.roles = [
  { value: 10, text: "プライマリー会員", color: "is-warning" },
  { value: 20, text: "セカンダリー会員", color: "is-primary" },
];
kubuns.realtor.getRole = (val) => get(kubuns.realtor.roles, val);

kubuns.realtor.specialties = [
  { value: 1, text: "高級マンション" },
  { value: 2, text: "新築・土地" },
  { value: 3, text: "海外不動産" },
  { value: 4, text: "民泊・ホテル" },
  { value: 5, text: "相続" },
  { value: 6, text: "非公開案件" },
  { value: 7, text: "競売案件" },
  { value: 8, text: "売却" },
  { value: 9, text: "海外投資家" },
  { value: 10, text: "REIT・レンディング" },
  { value: 11, text: "富裕層" },
  { value: 12, text: "融資先金融機関・提携ローン" },
];
kubuns.realtor.getSpecialty = (val) => get(kubuns.realtor.specialties, val);

kubuns.realtor.yearOfexperiences = [
  { value: 5, text: "1～5年" },
  { value: 10, text: "5～10年" },
  { value: 15, text: "10年～15年" },
  { value: 20, text: "15年～20年" },
  { value: 99, text: "20年以上" },
];
kubuns.realtor.getYearOfexperience = (val) =>
  get(kubuns.realtor.yearOfexperiences, val);

kubuns.message = {};

kubuns.message.suggestions = [
  { value: 1, text: "未公開物件" },
  { value: 2, text: "価格交渉可" },
  { value: 3, text: "早期売却希望案件" },
  { value: 4, text: "フルローン可能性" },
  { value: 5, text: "銀行融資紹介可" },
];
kubuns.message.getSuggestion = (val) => get(kubuns.message.suggestions, val);

kubuns.message.types = [
  { value: 1, text: "エクスクルーシブ", color: "is-warning" },
  { value: 2, text: "オファー", color: "is-info" },
  { value: 3, text: "売却", color: "is-primary", en: "Exit" },
  { value: 4, text: "問い合せ", color: "", en: "Inquiry" },
];
kubuns.message.getType = (val) => get(kubuns.message.types, val);
kubuns.message.mailTypes = [
  { value: 1, text: "エクスクルーシブ", color: "is-warning" },
  { value: 2, text: "提案オファー", color: "is-info" },
  { value: 3, text: "売却オファー", color: "is-primary", en: "Exit" },
  { value: 4, text: "問い合わせ", color: "", en: "Inquiry" },
];
kubuns.message.getMailType = (val) => get(kubuns.message.mailTypes, val);

kubuns.message.templateTypes = [{ value: 0, text: "使用しない" }];
kubuns.message.getTemplateTypes = (val) =>
  get(kubuns.message.templateTypes, val);

kubuns.message.terms = [
  {
    value: 1,
    text: "貴社・ご担当者様ご連絡先（メールアドレス・電話番号等）を記載していない",
  },
  {
    value: 2,
    text: "投資家の個人情報（年収・資産・不動産情報等）を記載していない",
  },
];
kubuns.message.getTerms = (val) => get(kubuns.message.terms, val);

// kubuns.transaction.realEstateSortTypes とvalueが重ならようにする
kubuns.transaction = {};
kubuns.transaction.loanSortTypes = [
  { value: 6, text: "融資実行年月順" },
  { value: 1, text: "スコアの高い順" },
  { value: 2, text: "スコアの低い順" },
  { value: 3, text: "融資金額の高い順" },
  { value: 4, text: "返済期間の長い順" },
  { value: 5, text: "金利の低い順" },
  // { value: 6, text: "融資実行年月の近い順" }
];
kubuns.transaction.getLoanSortType = (val) =>
  get(kubuns.transaction.loanSortTypes, val);

// kubuns.transaction.loanSortTypesとvalueが重ならようにする
kubuns.transaction.realEstateSortTypes = [
  { value: 16, text: "購入年月順" },
  { value: 1, text: "スコアの高い順" },
  { value: 2, text: "スコアの低い順" },
  { value: 13, text: "購入価格の高い順" },
  { value: 14, text: "利回りの高い順" },
  { value: 15, text: "築年月が浅い順" },
  // { value: 6, text: "購入年月の近い順" }
];
kubuns.transaction.getRealEstateSortType = (val) =>
  get(kubuns.transaction.realEstateSortTypes, val);

kubuns.transaction.tradeTypes = [
  { value: 0, text: "購入" },
  { value: 1, text: "売却" },
];

kubuns.transaction.statusOfRegisterTypes = [
  //登録ステータス
  { value: 100, text: "専門家登録中", color: "is-light" },
  { value: 190, text: "専門家登録完了", color: "is-warning" }, // 登録する
  { value: 200, text: "投資家確認中", color: "is-light" },
  { value: 990, text: "売買申告済み", color: "is-primary" },
];

kubuns.transaction.getStatusOfRegisterTypes = (val) =>
  get(kubuns.transaction.statusOfRegisterTypes, val);

kubuns.transaction.statusOfAccountingTypes = [
  //会計ステータス
  { value: 100, text: "入金前", color: "is-light" },
  { value: 200, text: "入金確認済み", color: "is-warning" },
  { value: 900, text: "投資家CB済み", color: "is-primary" },
];

kubuns.transaction.getStatusOfAccountingTypes = (val) =>
  get(kubuns.transaction.statusOfAccountingTypes, val);

kubuns.transaction.getTradeType = (val) =>
  get(kubuns.transaction.tradeTypes, val);

kubuns.transaction.evaluationTypes = [
  { value: 1, text: "★★★★★：非常に優れている" },
  { value: 2, text: "★★★★：優れている" },
  { value: 3, text: "★★★：普通" },
  { value: 4, text: "★★：やや不満" },
  { value: 5, text: "★：不満" },
  // { value: 1, text: "★" },
  // { value: 2, text: "★★" },
  // { value: 3, text: "★★★" },
  // { value: 4, text: "★★★★" },
  // { value: 5, text: "★★★★★" }
];
kubuns.transaction.getEvaluationType = (val) =>
  get(kubuns.transaction.evaluationTypes, val);

kubuns.transaction.depositTypes = [
  { value: 1, text: "普通" },
  { value: 2, text: "当座" },
];
kubuns.transaction.getDepositType = (val) =>
  get(kubuns.transaction.depositTypes, val);

export default kubuns;
