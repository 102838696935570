<template>
  <div></div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  async created() {
    setInterval(async () => {
      window.location.href = "/auth/signin";
      // location.href = "https://stockformer.com/";
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
