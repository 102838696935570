<script>
import firebase from "@/plugins/firebase";
export default {
  name: "AgeMixin",
  methods: {
    $$age$calc: function (dt) {
      if (!dt) return "";
      // const birthday = dt.toDate ? dt.toDate() : dt;
      let birthday = null;
      if (dt.toDate) {
        birthday = dt.toDate();
      } else {
        if (dt.getTime) {
          birthday = dt;
        } else {
          const timestamp = firebase.createTimestamp(
            dt._seconds,
            dt._nanoseconds
          );
          birthday = timestamp.toDate();
        }
      }

      // 文字列に分解
      const y2 = birthday.getFullYear().toString().padStart(4, "0");
      const m2 = (birthday.getMonth() + 1).toString().padStart(2, "0");
      const d2 = birthday.getDate().toString().padStart(2, "0");

      // 今日の日付
      const today = new Date();
      const y1 = today.getFullYear().toString().padStart(4, "0");
      const m1 = (today.getMonth() + 1).toString().padStart(2, "0");
      const d1 = today.getDate().toString().padStart(2, "0");

      // 引き算
      const age = Math.floor(
        (Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000
      );
      return age;
    },
  },
};
</script>
