<template>
  <div class="tag" :class="clazz">
    {{ option.text }}
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZTag",
  mixins: [InputMixin],
  props: {
    options: {
      type: [Array],
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    clazz: function () {
      const clz = [];
      clz.push(this.size);
      if (this.option.color) {
        clz.push(this.option.color);
      } else {
        clz.push("is-black");
        clz.push("is-outlined");
      }
      return clz;
    },
    option: function () {
      return this.$$ks.get(this.options, this.internalValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
