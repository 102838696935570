<template>
  <div>
    <section class="section" v-if="!isLoading">
      <div class="container">
        <div class="content has-text-centered">
          <p>ようこそ</p>
        </div>
        <z-input
          label="お名前"
          name="base.name"
          v-model="user.data.base.name"
          type="text"
          placeholder="お名前を入力してくください。"
          maxlength="128"
          :validate="'required'"
        >
        </z-input>
        <z-input
          label="ふりがな"
          name="base.kana"
          v-model="user.data.base.kana"
          type="text"
          placeholder="ふりがなを入力してくください。"
          maxlength="128"
          :validate="'required'"
        >
        </z-input>
        <z-input
          label="電話番号"
          name="base.phoneNumber"
          v-model="user.data.base.phoneNumber"
          type="tel"
          placeholder="電話番号を入力してくください。"
          maxlength="11"
          :validate="'required|min:10|max:11|numeric'"
        >
        </z-input>
        <button
          class="button is-light is-fullwidth is-info"
          :disabled="$$validator$invalid"
          @click="save"
        >
          登録
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
export default {
  name: "investor-welcome-Welcome",
  data: () => {
    return {
      user: null,
      isLoading: true,
    };
  },
  async mounted() {
    this.user = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        const user = await this.$$investor$save(this.auth.uid, this.user);
        await firebase.functions("sendMailToInvestor", {
          to: {
            email: user.data.base.email,
            name: user.data.base.name,
          },
          user: user,
        });
        window.location.href = "/investor";
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   message: "保存に失敗しました。",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
