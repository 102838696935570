<template>
  <div class="z-carousel">
    <template v-if="images.length">
      <carousel
        :per-page="6"
        :navigationEnabled="true"
        :paginationEnabled="false"
        navigation-prev-label="〈"
        navigation-next-label="〉"
        :navigateTo="internalValue"
        :loop="true"
        v-model="internalValue"
        :speed="400"
      >
        <template v-for="(image, idx) in images">
          <slide :key="image.id">
            <figure class="image" @click="select(idx)">
              <div class="image__container">
                <z-image
                  class="image__container__img"
                  v-model="images[idx]"
                ></z-image>
              </div>
            </figure>
          </slide>
        </template>
      </carousel>
    </template>
    <template v-else>
      <img class="empty-img" :src="emptyImageSrc" />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
// import firebase from "@/plugins/firebase";
const emptyImage = require("@/assets/empty.png");

export default {
  name: "ZCarouselList",
  mixins: [InputMixin],
  props: {
    images: {
      type: [Array],
      default: () => [],
    },
    value: {
      type: [Number],
      default: 0,
    },
    emptySrc: {
      type: [String],
      default: null,
    },
  },
  computed: {
    emptyImageSrc() {
      if (this.emptySrc) {
        return this.emptySrc;
      } else {
        return emptyImage;
      }
    },
  },
  methods: {
    select(index) {
      this.$emit("selected", index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.empty-img {
  width: 100%;
  height: 100%;
}

.z-carousel {
  padding: 10px 53px;
  background-color: #f4f4f4;
  .image {
    padding: 0 5px;
    width: 100%;
    height: 72px;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      &__img {
        // width: auto;
        // height: auto;
        // max-width: 100%;
        // max-height: 100%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__caption {
      padding: 4px 10px;
      background-color: #f4f4f4;
      font-size: 16px;
    }
  }
  /deep/ .VueCarousel {
    height: 100%;
    .VueCarousel-wrapper {
      position: relative;
      height: 100%;
      z-index: 2;
    }
    .VueCarousel-inner {
      height: 100%;
    }
    .VueCarousel-navigation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .VueCarousel-navigation-button {
      position: absolute;
      width: 44px;
      height: 100%;
      background: rgba(#000, 0.8);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
      border-radius: 4px;
      color: #fff;
      // SVGで置き換えられないためデザイン未反映
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      // text-indent: -100px;
      // overflow: hidden;
      transition: 0.2s;
      &:hover {
        background-color: #666;
      }
    }
    .VueCarousel-navigation-next {
      right: 0;
    }
    .VueCarousel-navigation-prev {
      left: 0;
    }
  }
}
</style>
