// import Vue from "vue";
// import Router from "vue-router";
// import Home from "./views/Home";
// import store from "@/store";

import investorWelcomeWelcome from "@/views/investor/welcome/Welcome";
import investorSettingAccount from "@/views/investor/setting/Account";
import investorSettingTransaction from "@/views/investor/setting/Transaction";

import investorTransactionReviewConfirmation from "@/views/investor/transaction/review/Confirmation";
import investorTransactionReviewLoan from "@/views/investor/transaction/review/Loan";
import investorTransactionReviewStaffEvaluation from "@/views/investor/transaction/review/StaffEvaluation";
import investorTransactionReviewBankAccount from "@/views/investor/transaction/review/BankAccount";
import investorTransactionReviewFinish from "@/views/investor/transaction/review/Finish";
import investorTransactionReviewCongratulations from "@/views/investor/transaction/review/Congratulations";

import investorIndex from "@/views/investor/Index";
import investorPortfolioIndex from "@/views/investor/portfolio/Index";
import investorHomeIndex from "@/views/investor/home/Index";
import investorMarkettrendIndex from "@/views/investor/markettrend_v2/Index";
import investorMarkettrendRealestate from "@/views/investor/markettrend_v2/realestate";
// import investorMarkettrendIndex from "@/views/investor/markettrend_v2/Index";
// import investorMarkettrendRealestate from "@/views/investor/markettrend_v2/realestate";

import investorRegisterIndex from "@/views/investor/register/Index";
import investorRegisterPlanintroduction from "@/views/investor/register/planintroduction";
import investorRegisterWorkexperience from "@/views/investor/register/workexperience";
import investorRegisterIncome from "@/views/investor/register/income";
import investorRegisterAssets from "@/views/investor/register/assets";
import investorRegisterRealestate from "@/views/investor/register/realestate";
import investorRegisterBuyingneeds from "@/views/investor/register/buyingneeds";
import investorRegisterPlan from "@/views/investor/register/plan";
// import investorRealestateIndex from "@/views/investor/realestate/Index";
import investorCollectionIndex from "@/views/investor/collection/Index";
import investorCollectionRealestate from "@/views/investor/collection/realestate";
import investorCollectionRealestatepreview from "@/views/investor/collection/realestatepreview";

import investorMessageIndex from "@/views/investor/message/Index";
import investorMessageDetail from "@/views/investor/message/Detail";

import investorInformationsIndex from "@/views/investor/information/Index";

import investorPremiumIndex from "@/views/investor/premium/Index";
import investorPremiumDetail from "@/views/investor/premium/Detail";

import investorSearchRealEstateIndex from "@/views/investor/search/realEstate/Index";
import investorSearchRealEstateDetail from "@/views/investor/search/realEstate/Detail";

import investorSearchRealtorDetail from "@/views/investor/search/realtor/Detail";

import investorFavoriteRealtor from "@/views/investor/favorite/Realtor";

import investorZeroRealtor from "@/views/investor/zero/Index";

import options from "@/routers/options";
const RequiresAuth = options.RequiresAuth;
const role = options.role;
const examination = options.examination;

const needCompleteRegister = options.needCompleteRegister;

const routes = [
  {
    path: "/investor",
    meta: {
      examination: examination.all,
      role: role.investor,
      requiresAuth: RequiresAuth.required,
    },
    component: investorIndex,
    children: [
      {
        path: "",
        name: "investor",
        meta: {
          title: "Score Chart 市場スコアチャート",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorHomeIndex,
      },
      {
        path: "informations",
        name: "investor-informations",
        meta: {
          title: "Information お知らせ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorInformationsIndex,
      },
      {
        path: "messages",
        name: "investor-message",
        meta: {
          title: "Message メッセージ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorMessageIndex,
      },
      {
        path: "messages/:id",
        name: "investor-message-detail",
        meta: {
          title: "Message メッセージ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorMessageDetail,
      },
      {
        path: "portfolio",
        name: "investor-portfolio",
        meta: {
          title: "My Portfolio マイポートフォリオ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorPortfolioIndex,
      },
      {
        path: "home",
        name: "investor-home",
        meta: {
          title: "Score Chart 市場スコアチャート",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorHomeIndex,
      },
      {
        path: "markettrend",
        name: "investor-markettrend",
        meta: {
          title: "Market Trend 市況情報",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorMarkettrendIndex,
      },
      {
        path: "markettrend/realestate/:id",
        name: "investor-markettrend-realestate",
        meta: {
          title: "Market Trend 市況情報",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.yes,
        },
        component: investorMarkettrendRealestate,
      },
      {
        path: "register",
        name: "investor-register",
        meta: {
          title: "Sign Up サインアップ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterIndex,
      },
      {
        path: "register/planintroduction",
        name: "investor-register-planintroduction",
        meta: {
          title: "Plan Introduction プラン紹介",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterPlanintroduction,
      },
      {
        path: "register/workexperience",
        name: "investor-register-workexperience",
        meta: {
          title: "My Portfolio マイポートフォリオ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterWorkexperience,
      },
      {
        path: "register/income",
        name: "investor-register-income",
        meta: {
          title: "My Portfolio マイポートフォリオ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterIncome,
      },
      {
        path: "register/assets",
        name: "investor-register-assets",
        meta: {
          title: "My Portfolio マイポートフォリオ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterAssets,
      },
      {
        path: "register/realestate",
        name: "investor-register-realestate",
        meta: {
          title: "My Portfolio マイポートフォリオ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterRealestate,
      },
      {
        path: "register/buyingneeds",
        name: "investor-register-buyingneeds",
        meta: {
          title: "Buying Needs 買いニーズ",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterBuyingneeds,
      },
      {
        path: "register/plan",
        name: "investor-register-plan",
        meta: {
          title: "Plan プラン選択",
          examination: examination.all,
          role: role.investor,
          requiresAuth: RequiresAuth.required,
          needCompleteRegister: needCompleteRegister.no,
        },
        component: investorRegisterPlan,
      },
      {
        path: "premium",
        name: "investor-premium",
        meta: {
          title: "Plan プラン選択",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorPremiumIndex,
      },
      {
        path: "premium/detail",
        name: "investor-premium-detail",
        meta: {
          title: "Plan プラン選択",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorPremiumDetail,
      },
      // {
      //   path: "realestate",
      //   name: "investor-realestate",
      //   meta: {
      //     examination: examination.all,
      //     role: role.investor,
      //     needCompleteRegister: needCompleteRegister.yes,
      //     requiresAuth: RequiresAuth.required
      //   },
      //   component: investorRealestateIndex
      // },
      {
        path: "collection",
        name: "investor-collection",
        meta: {
          title: "My Collection 保有不動産",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorCollectionIndex,
      },
      {
        path: "collection/realestate/:id",
        name: "investor-collection-realestate",
        meta: {
          title: "My Collection 保有不動産",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorCollectionRealestate,
      },
      {
        path: "collection/realestatepreview/:id",
        name: "investor-collection-realestatepreview",
        meta: {
          title: "Sales Price 売却価格",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorCollectionRealestatepreview,
      },
      {
        path: "search/realtor/realestates/:id",
        name: "investor-search-realestates-detail",
        meta: {
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorSearchRealEstateDetail,
      },
      {
        path: "search/realtor/realestates",
        name: "investor-search-realestates",
        meta: {
          title: "Property Search 不動産検索",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorSearchRealEstateIndex,
      },
      {
        path: "search/realtor/realtors/:id",
        name: "investor-search-realotrs-detail",
        meta: {
          title: "Property 不動産詳細",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorSearchRealtorDetail,
      },
      {
        path: "account",
        name: "investor-account",
        meta: {
          title: "Account アカウント設定",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorSettingAccount,
      },
      {
        path: "setting/transaction",
        name: "investor-setting-transaction",
        meta: {
          title: "Transaction 不動産売買の申告",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorSettingTransaction,
      },
      {
        path: "transaction/review/congratulations/:id",
        name: "transaction-review-congratulations",
        meta: {
          title: "Transaction ご成約誠におめでとうございます",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewCongratulations,
      },
      {
        path: "transaction/review/confirmation/:id",
        name: "transaction-review-confirmation",
        meta: {
          title: "Transaction 不動産売買の確認",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewConfirmation,
      },
      {
        path: "transaction/review/loan/:id",
        name: "transaction-review-loan",
        meta: {
          title: "Transaction 融資情報の入力",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewLoan,
      },
      {
        path: "transaction/review/staffevaluation/:id",
        name: "transaction-review-staffevaluation",
        meta: {
          title: "Transaction 担当者評価の入力",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewStaffEvaluation,
      },
      {
        path: "transaction/review/bankaccount/:id",
        name: "transaction-review-bankaccount",
        meta: {
          title: "Transaction 振込先口座の登録",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewBankAccount,
      },
      {
        path: "transaction/review/finish/:id",
        name: "transaction-review-finish",
        meta: {
          title: "Transaction お申し込み完了",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorTransactionReviewFinish,
      },
      {
        path: "favorite/realtor",
        name: "investor-favorite-realtor",
        meta: {
          title: "My Agent  不動産担当者評価",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorFavoriteRealtor,
      },
      {
        path: "zero",
        name: "investor-zero-index",
        meta: {
          title: "StockFormer-ZERO",
          examination: examination.all,
          role: role.investor,
          needCompleteRegister: needCompleteRegister.yes,
          requiresAuth: RequiresAuth.required,
        },
        component: investorZeroRealtor,
      },
    ],
  },
  //{
  //  path: "/investor",
  //  meta: {
  //    requiresAuth: RequiresAuth.required
  //  },
  //  component: investorIndex,
  //  children: [
  //    {
  //      path: "",
  //      name: "investor-portfolio",
  //      meta: {
  //        requiresAuth: RequiresAuth.required
  //      },
  //      component: investorPortfolioIndex
  //    }
  //  ]
  //},
  {
    path: "/investor/welcome",
    name: "investor-welcome",
    component: investorWelcomeWelcome,
    meta: {
      examination: examination.all,
      role: role.investor,
      requiresAuth: RequiresAuth.required,
    },
  },
];

export default { routes: routes };
