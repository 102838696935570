<template>
  <div>
    <section>
      <ZHeader title="Transaction" sub="不動産売買の申告">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div v-if="!isLoading">
          <div class="content-wrapper">
            <div
              class="content-main letter"
              :style="{ 'background-image': 'url(' + AssetsImage + ')' }"
            >
              <div class="letter-content-wrapper">
                <p>{{ me.user.data.base.name }} 様</p>
                <p>
                  StockFormerを通じて不動産売買を成約いただくと、<br
                    class="sp-none"
                  />仲介手数料の10％を キャッシュバック優遇いたします。<br />
                </p>
                <p>以下からキャッシュバック優遇をお申し込みください。</p>
              </div>
            </div>
          </div>
          <div class="content is-small">
            <h3 class="list-title">
              不動産売買の申告（仲介手数料10％キャッシュバック優遇のお申込み）
            </h3>
          </div>
          <div class="transaction-form">
            <ZSelect
              label="不動産会社"
              v-model="transaction.data.companyId"
              :validate="'required'"
              :options="companyOptions"
              placeholder="不動産会社を選択"
              isRequired
            />
            <template v-if="isSelectedCompany">
              <z-user-select
                label="担当者"
                v-model="transaction.data.staffId"
                :users="companyUsers"
                :validate="'required'"
                placeholder="担当者を選択してください"
                isRequired
              />
            </template>
            <ZRadio
              label="購入 / 売却"
              v-model="transaction.data.tradeType"
              :options="$$ks.transaction.tradeTypes"
              isRequired
            />
            <z-select
              label="取引種別"
              v-model.number="transaction.data.transactionType"
              :options="$$ks.realtor.realEstate.transactionTypes"
              :validate="'required'"
              isRequired
            />
            <ZDatepicker
              label="売買契約日"
              v-model="transaction.data.contractDate"
              :validate="'required'"
              placeholder="例)2010/01/01"
              isRequired
            />
            <ZDatepicker
              label="決済実行日"
              v-model="transaction.data.settlementDate"
              :validate="'required'"
              placeholder="例)2010/01/01"
              isRequired
            />
            <ZPriceInput
              label="取引金額"
              placeholder="例)¥500,000"
              :validate="'required|price_min_value:0'"
              maxlength="12"
              isRequired
              v-model.number="transaction.data.price"
            />
            <ZPriceInput
              label="仲介手数料"
              placeholder="例)¥500,000"
              :validate="'required|price_min_value:0'"
              maxlength="12"
              isRequired
              v-model.number="transaction.data.fee"
            />
            <div class="buttons is-centered">
              <button
                class="button z-button is-primary is-rounded is-wide"
                @click="save"
                :disabled="$$validator$invalid"
              >
                申告する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetsImage from "@/assets/letter-background.jpg";

export default {
  name: "investor-setting-transaction",
  data: function () {
    return {
      AssetsImage: AssetsImage,
      isLoading: false,
      transaction: null,
      companies: null,
    };
  },
  computed: {
    companyOptions() {
      return this.companies.map((company) => {
        return { text: company.data.name, value: company.id };
      });
    },
    isSelectedCompany() {
      return this.transaction.data.companyId !== null ? true : false;
    },
  },
  asyncComputed: {
    async companyUsers() {
      const companyId = this.transaction.data.companyId;
      if (companyId) {
        const loadingComponent = this.$loading.open();
        const realtor = await this.$$realtor$loadRealtorById(companyId);
        loadingComponent.close();
        return realtor.users;
      } else {
        return [];
      }
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      try {
        this.transaction = this.$$transaction$getNewTransaction();
        this.companies = await this.$$realtor$loadCompanies();
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      }
    },
    async save() {
      this.isLoading = true;
      try {
        await this.$$transaction$createTransaction(
          this.me.id,
          this.transaction
        );
        await this.$router.go(-1);
        this.$$toast$openSuccess("申し込みが完了しました");
        return;
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("申し込みに失敗しました");
      } finally {
        this.isLoading = false;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.content {
  p:nth-child(1) {
    font-weight: bold;
  }
  p:nth-child(2) {
    font-weight: bold;
    color: gray;
    font-size: 1.2em;
  }
  .list-title {
    color: white;
  }
}
.content-main {
  padding-left: 20px;
  p:nth-child(1) {
    font-weight: bold;
    margin: 5px auto;
  }
  p:nth-child(2) {
    margin: 5px auto;
  }
}
.content-wrapper {
  margin: 50px 0;
  .letter {
    width: 60%;
    margin: 100px auto 0px auto;
    height: 400px;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 2px solid #b22222;
    position: relative;
    @include lg {
      min-width: 626px;
    }
    @include md {
      width: 80%;
      background-size: 80%;
      min-width: 600px;
    }
    @include sp {
      width: 95%;
      background-size: 70%;
      background-repeat: no-repeat;
      height: 350px;
    }
    .letter-content-wrapper {
      position: absolute;
      top: 53%;
      left: 55%;
      transform: translate(-50%, -50%);
      p {
        margin: 15px 0;
        font-weight: lighter;
        width: 70%;
      }
      @include lg {
        p {
          white-space: nowrap;
        }
      }
      @include md {
        top: 50%;
        left: 55%;
        width: 70%;
        p {
          white-space: nowrap;
        }
      }
      @include sp {
        top: 50%;
        left: 52%;
        margin: auto;
        width: 70%;
        p {
          width: 95%;
        }
        .sp-none {
          display: none;
        }
      }
    }
  }
}
.transaction-form {
  padding: 2% 10%;
}
</style>
