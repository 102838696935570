<template>
  <section class="section">
    <h3 class="list-title">パスワードリセット</h3>
    <div class="columns is-centered is-gapless">
      <div class="column is-narrow">
        <div class="box is-message-box">
          <div class="content is-small has-text-centered">
            <p><br />新しいパスワードを入力してください</p>
          </div>
          <div class="z-input-vritical-warp">
            <z-input
              label="パスワード"
              v-model="password"
              data-vv-as="パスワード"
              type="password"
              placeholder="パスワード"
              maxlength="128"
              :validate="'required:true|min:8'"
              icon-pack="fas"
              icon="lock"
              hideRequired
              password-reveal
              isRequired
            >
            </z-input>
          </div>
          <div class="buttons is-centered">
            <button
              :disabled="$$validator$invalid"
              class="button is-primary is-rounded is-wide z-button is-small"
              @click="reset"
            >
              パスワードを変更する
            </button>
          </div>
          <div class="member-login">
            <router-link :to="{ name: 'auth-signin' }">こちら</router-link
            >からログインをやり直す
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import firebase from "firebase/app";
import firebase from "@/plugins/firebase";

export default {
  name: "ResetPassword-confirm",
  data() {
    return {
      password: "",
    };
  },
  computed: {
    now() {
      if (this.$route.query.p) {
        return this.$route.query.p;
      } else {
        return "";
      }
    },
    uuid() {
      if (this.$route.query.q) {
        return this.$route.query.q;
      } else {
        return "";
      }
    },
    email() {
      if (this.$route.query.email) {
        return this.$route.query.email.replace(" ", "+");
      } else {
        return "";
      }
    },
  },
  methods: {
    async reset() {
      const loadingComponent = this.$loading.open();
      try {
        await firebase.functions("resetPassword", {
          uuid: this.uuid,
          now: this.now,
          password: this.password,
          email: this.email,
        });
        this.$$toast$openSuccess("パスワードを変更しました");
        this.$router.push({ name: "auth-signin" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("パスワードの変更に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
    //reset: function() {
    //  const self = this;
    //  const email = this.email;
    //  const loadingComponent = this.$loading.open();
    //  const actionCodeSettings = {
    //    url: location.protocol + "//" + location.host + "/signin",
    //    handleCodeInApp: true
    //  };
    //  firebase
    //    .auth()
    //    .sendPasswordResetEmail(email, actionCodeSettings)
    //    .then(() => {
    //      self.$$toast$openSuccess("メールを送信しました");
    //      // self.$toast.open({
    //      //   message: "メールを送信しました",
    //      //   position: "is-top"
    //      // });
    //    })
    //    .then(() => {
    //      self.$router.go(-1);
    //    })
    //    .finally(() => {
    //      loadingComponent.close();
    //    })
    //    .catch(error => {
    //      self.$raven.captureException(error);
    //      self.$$toast$openError("メールの送信に失敗しました");
    //      // self.$toast.open({
    //      //   message: "メールの送信に失敗しました",
    //      //   position: "is-top",
    //      //   type: "is-danger"
    //      // });
    //    });
    //}
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0066ffeb;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.box {
  border-radius: 0px;
  padding: 30px 50px;
  .content {
    margin-bottom: 30px;
    p {
      font-size: 14px;
    }
  }
  button {
    margin-top: 15px;
  }
}

.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
.member-login {
  font-size: 10px;
  text-align: right;
  margin-top: 10px;
}

@include sp {
  .section {
    width: 440px;
  }
}
</style>
