<template>
  <div>
    <section class="section">
      <div class="container signup-warp">
        <div class="signup__box">
          <div class="content has-text-centered">
            <h3>運営者登録</h3>
          </div>
          <z-input
            label="メールアドレス"
            name="email"
            v-model="email"
            type="email"
            placeholder="メールアドレスを入力してください"
            maxlength="512"
            :validate="'required|email'"
            icon="email"
          >
          </z-input>
          <button
            class="button is-info is-fullwidth"
            :disabled="$$validator$invalid"
            @click="signUp"
          >
            よろしく
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import SentMail from "@/mixins/admin/auth/SentMail";

export default {
  name: "admin-auth-SignUp",
  mixins: [SentMail],
  data() {
    return {
      agreement: false,
      email: "",
    };
  },
  methods: {
    signUp: function () {
      const self = this;
      const email = this.email;
      this.sendMail(email)
        .then(function () {
          self.$router.push({
            name: "auth-admin-sentmail",
            query: Object.assign({ email: email }, self.$route.query),
          });
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("確認メールの送信に失敗しました");
          // self.$toast.open({
          //   duration: 3000,
          //   message: "確認メールの送信に失敗しました",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.signup-warp {
  width: 600px;
  @include mobile {
    width: 100%;
  }
}
.signup {
  width: 100%;
  &__title {
    width: 100%;
    margin-bottom: 40px;
  }
  &__box {
    padding: 30px 50px;
    background-color: $light;
  }
}
</style>
