<script>
import firebase from "@/plugins/firebase";

import moment from "moment";
import "moment-timezone";

export default {
  name: "CalenderMixin",
  methods: {
    $$calender$toDate: function (dt) {
      if (dt.toDate) {
        return dt.toDate();
      } else {
        return dt;
      }
    },
    $$calender$todayFormat: function (dt) {
      if (dt) {
        const d = dt.toDate ? dt.toDate() : dt;
        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0,
          0,
          0
        );

        // const now = Date.now();
        // var termDay = (now - d) / 86400000;
        // if (termDay > 1) {
        if (d < today) {
          return this.$$calender$dateFormat(dt);
        } else {
          return this.$$calender$timeFormat(dt);
        }
      } else {
        return "";
      }
    },
    $$calender$timeFormat: function (dt) {
      if (dt) {
        if (dt.toDate) {
          return moment(dt.toDate()).tz("Asia/Tokyo").format("HH:mm");
        } else {
          return moment(dt).tz("Asia/Tokyo").format("HH:mm");
        }
      } else {
        return "";
      }
    },
    $$calender$dateFormat: function (dt) {
      if (!dt) {
        return "";
      }
      if (dt.toDate) {
        return moment(dt.toDate()).tz("Asia/Tokyo").format("YYYY/MM/DD");
      } else {
        if (dt.getTime) {
          return moment(dt).tz("Asia/Tokyo").format("YYYY/MM/DD");
        } else {
          const timestamp = firebase.createTimestamp(
            dt._seconds,
            dt._nanoseconds
          );
          return moment(timestamp.toDate())
            .tz("Asia/Tokyo")
            .format("YYYY/MM/DD");
        }
      }
      // if (dt) {
      //   if (dt.toDate) {
      //     return moment(dt.toDate())
      //       .tz("Asia/Tokyo")
      //       .format("YYYY/MM/DD");
      //   } else {
      //     return moment(dt)
      //       .tz("Asia/Tokyo")
      //       .format("YYYY/MM/DD");
      //   }
      // } else {
      //   return "";
      // }
    },
    $$calender$dateTimeFormat: function (dt) {
      if (dt) {
        if (dt.toDate) {
          return moment(dt.toDate())
            .tz("Asia/Tokyo")
            .format("YYYY/MM/DD HH:mm:ss");
        } else {
          return moment(dt).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
        }
      } else {
        return "";
      }
    },
    $$calender$yeartMonthFormat: function (dt) {
      if (dt) {
        if (dt.toDate) {
          return moment(dt.toDate()).tz("Asia/Tokyo").format("YYYY/MM");
        } else {
          return moment(dt).tz("Asia/Tokyo").format("YYYY/MM");
        }
      } else {
        return "";
      }
    },
    $$calender$monthFormat: function (dt) {
      if (dt) {
        if (dt.toDate) {
          return moment(dt.toDate()).tz("Asia/Tokyo").format("MM/DD");
        } else {
          return moment(dt).tz("Asia/Tokyo").format("MM/DD");
        }
      } else {
        return "";
      }
    },
    $$calender$monthTimeFormat: function (dt) {
      if (dt) {
        if (dt.toDate) {
          return moment(dt.toDate()).tz("Asia/Tokyo").format("MM/DD HH:mm");
        } else {
          return moment(dt).tz("Asia/Tokyo").format("MM/DD HH:mm");
        }
      } else {
        return "";
      }
    },
    $$calender$yearMonthFormat: function (dt, separetor = null) {
      if (dt && dt.year && dt.month) {
        if (separetor) {
          return (
            this.$$ks.getYears(dt.year).text +
            separetor +
            this.$$ks.getMonth(dt.month).text
          );
        } else {
          return (
            this.$$ks.getYears(dt.year).text +
            "年" +
            this.$$ks.getMonth(dt.month).text +
            "月"
          );
        }
      } else {
        if (separetor) {
          return "-" + separetor + "-";
        } else {
          return "-年-月";
        }
      }
    },
    $$calender$compareYearMonth: function (a, b) {
      const ad = a.year * 100 + a.month;
      const bd = b.year * 100 + b.month;
      return ad > bd;
    },
  },
  computed: {
    $$calender$firstDayOfWeek: function () {
      return 1;
    },
    $$calender$jpMonthNames: function () {
      return [...Array(12).keys()].map((e) => `${e + 1}月`);
    },
    $$calender$jpDayNames: function () {
      return ["日", "月", "火", "水", "木", "金", "土"];
    },
  },
};
</script>
