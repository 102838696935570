<script>
// import firebase from "@/plugins/firebase";
export default {
  name: "ToastMixin",
  methods: {
    $$toast$openSuccess: function (message) {
      this.$toast.open({
        message: message ? message : "保存完了しました",
        type: "is-success",
        position: "is-bottom",
      });
    },
    $$toast$openError: function (message) {
      this.$toast.open({
        duration: 6000,
        message: message ? message : "保存失敗しました",
        type: "is-danger",
        position: "is-bottom",
      });
    },
  },
};
</script>
