<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="container">
        <div v-if="!isLoading">
          <div class="box animated fadeIn">
            <div class="content">
              <div class="content is-small">
                <h2>基本情報</h2>
              </div>
            </div>
            <!-- -->
            <div class="columns is-gapless">
              <div class="column is-narrow is-3">
                <div class="has-label-centerted">
                  <div class="content level-right">
                    <p>
                      氏名
                      <template
                        v-if="
                          !$$validator$validRequire('user_firstName') ||
                          !$$validator$validRequire('user_lastName')
                        "
                      >
                        <span class="tag is-danger">必須</span>
                      </template>
                      <template v-else>
                        <span class="tag is-success">ＯＫ</span>
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="columns">
                  <div class="column is-6">
                    <b-field
                      :type="$$validator$validatedType('user_firstName')"
                      :message="errors.first('user_firstName')"
                    >
                      <b-input
                        name="user_firstName"
                        type="text"
                        data-vv-as="姓"
                        placeholder="民鳥"
                        v-model="user.data.firstName"
                        maxlength="32"
                        v-validate="'required'"
                      />
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field
                      :type="$$validator$validatedType('user_lastName')"
                      :message="errors.first('user_lastName')"
                    >
                      <b-input
                        name="user_lastName"
                        type="text"
                        data-vv-as="名"
                        placeholder="太郎"
                        v-model="user.data.lastName"
                        maxlength="32"
                        v-validate="'required'"
                      />
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
            <!-- -->
            <div class="columns is-gapless">
              <div class="column is-narrow is-3">
                <div class="has-label-centerted">
                  <div class="content level-right">
                    <p>
                      ふりがな
                      <template
                        v-if="
                          !$$validator$validRequire('user_firstKana') ||
                          !$$validator$validRequire('user_lastKana')
                        "
                      >
                        <span class="tag is-danger">必須</span>
                      </template>
                      <template v-else>
                        <span class="tag is-success">ＯＫ</span>
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="columns">
                  <div class="column is-6">
                    <b-field
                      :type="$$validator$validatedType('user_firstKana')"
                      :message="errors.first('user_firstKana')"
                    >
                      <b-input
                        name="user_firstKana"
                        type="text"
                        data-vv-as="姓(せい)"
                        placeholder="みんとり"
                        v-model="user.data.firstKana"
                        maxlength="32"
                        v-validate="'required'"
                      />
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field
                      :type="$$validator$validatedType('user_lastKana')"
                      :message="errors.first('user_lastKana')"
                    >
                      <b-input
                        name="user_lastKana"
                        type="text"
                        data-vv-as="名(せい)"
                        placeholder="たろう"
                        v-model="user.data.lastKana"
                        maxlength="32"
                        v-validate="'required'"
                      />
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
            <button
              class="button is-fullwidth is-info"
              :disabled="$$validator$invalid"
              @click="save"
            >
              登録
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

export default {
  name: "admin-setting-basic",
  data: function () {
    return {
      isLoading: true,
      user: null,
    };
  },
  methods: {
    save: async function () {
      const self = this;
      const loadingComponent = this.$loading.open();
      try {
        self.user.data.wasSavedBase = true;
        await self.$$admin$$save(self.user);
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
      //return Promise.resolve()
      //  .then(() => {
      //    return self.$$admin$$save(self.user);
      //  })
      //  .finally(() => {
      //    loadingComponent.close();
      //  })
      //  .catch(error => {
      //    console.error(error);
      //  });
    },
    init: async function () {
      const self = this;
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        await this.$$admin$loadUser().then((user) => {
          if (user) {
            self.user = user;
          }
        });
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        self.$nextTick(() => {
          if (self.user && self.user.data.wasSavedBase) {
            self.$validator.validateAll();
          }
        });
        /* loadingComponent.close(); */
        this.isLoading = false;
      }
    },
  },
  mounted() {
    return this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
