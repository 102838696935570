<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="container">
        <div class="content has-text-centered">
          <p>ようこそ</p>
        </div>
        <z-input
          label="氏名"
          name="base.name"
          v-model="user.data.base.name"
          type="text"
          maxlength="128"
          :validate="'required'"
        >
        </z-input>
        <z-input
          label="ふりがな"
          name="base.kana"
          v-model="user.data.base.kana"
          type="text"
          maxlength="128"
          :validate="'required'"
        >
        </z-input>
        <z-input
          label="所属部署"
          name="base.department"
          v-model="user.data.base.department"
          type="text"
          maxlength="256"
          :validate="'required'"
        >
        </z-input>
        <z-input
          label="電話番号"
          name="base.phoneNumber"
          v-model="user.data.base.phoneNumber"
          type="tel"
          maxlength="11"
          :validate="'required|min:10|max:11|numeric'"
        >
        </z-input>
        <button
          class="button is-light is-fullwidth is-info"
          :disabled="$$validator$invalid"
          @click="save"
        >
          登録
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "admin-welcome-Welcome",
  data: () => {
    return {
      user: null,
      isLoading: true,
    };
  },
  async mounted() {
    this.user = await this.$$admin$loadUser(this.me.user.id);
    this.isLoading = false;
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$admin$save(this.auth.uid, this.user);
        window.location.href = "/admin";
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   message: "保存に失敗しました。",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
