//import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";
// import { cloneDeep } from "lodash";
const type = {};

type.filter = {
  message: {
    proposal: false,
    exclusive: false,
    buying: false,
    contact: false,
  },
  rank: {
    s: false,
    a: false,
    b: false,
    c: false,
  },
  status: {
    unRead: false,
    asRead: false,
    unanswered: false,
  },
};

type.thread = {
  from: null, //送信者は最初からtrue
  to: null, //uid
  contents: "",
  docs: [],
  sendDt: null,

  createdDt: null,
  createdBy: null,
  updatedDt: null,
  updatedBy: null,
};

type.message = {
  uids: [],
  asReads: [],

  title: "",
  investor: null,
  realtor: null,
  type: null,

  suggestions: [],

  latestTo: null,
  latestFrom: null,
  latestSendDt: null,

  threads: [],
  proposalSalePrice: null, //売却希望金額
  realEstates: [], //投資家が所有している不動産 今は使わないと思う。
  realtorRealEstates: [], //不動産が所有している不動産
  createdDt: null,
  createdBy: null,
  updatedDt: null,
  updatedBy: null,
};

export default {
  type,
};
