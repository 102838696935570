<template>
  <div>
    <div class="field">
      <tile-box
        v-on:receiveOnClose="onCloseFunction()"
        overlayTitle="Profile Input"
        overlaySubTitle="プロフィール情報登録"
        :editable="editable"
        ref="tileBox"
      >
        <template v-slot:title>
          <z-tile-box-title title="自己PR・実績"> </z-tile-box-title>
        </template>
        <template v-slot:body>
          <z-tile-box-body-list v-model="profileLabels" />
        </template>
        <template v-slot:overlay>
          <EditUserProfile :tileBox="$refs.tileBox" :uid="user.id">
          </EditUserProfile>
        </template>
      </tile-box>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

import EditUserProfile from "@/components/realtor/EditUserProfilePr";

export default {
  name: "realtor-setting-profile",
  components: {
    //RealtorUser: RealtorUser,
    EditUserProfile: EditUserProfile,
    //EditUserBasic: EditUserBasic
    //RealtorUserRole: RealtorUserRole
    //RealtorCompany: RealtorCompany
  },
  props: {
    user: {
      type: [Object],
      default: null,
    },
    editable: {
      type: [Boolean],
      default: true,
    },
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    profileLabels() {
      return [
        {
          label: "",
          value: this.user.data.profile.pr,
          isPrivate: false,
          type: "pre",
        },
      ];
    },
  },
  methods: {
    onCloseFunction() {
      return;
    },
    //init() {
    //  this.isLoading = true;
    //  this.isLoading = false;
    //  return;
    //}
  },
  //beforeRouteUpdate(to, from, next) {
  //  next();
  //  this.init();
  //},
  //mounted() {
  //  this.init();
  //}
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

//.consultant-warp {
//  margin: 0 10px;
//  @include mobile {
//    margin: 0;
//  }
//}
//
//  .consultant {
//    &__image {
//      height: 256px;
//      width: 256px;
//      background-color: $mintrich-blank;
//    }
//    &__details {
//      height: 100%;
//      width: 100%;
//      background-color: $mintrich-blank;
//    }
//  }
//
//  .company {
//    &__details {
//      height: 100%;
//      width: 100px;
//      background-color: $mintrich-blank;
//    }
//  }
</style>
