<template>
  <div>
    <z-select
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :hideRequired="hideRequired"
      :options="options"
      :isRequired="isRequired"
    >
    </z-select>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZUserSelect",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
    };
  },
  props: {
    value: {
      type: [String],
      default: null,
    },
    users: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    options() {
      const ls = [];
      for (const u of this.users) {
        ls.push({
          value: u.id,
          text: u.data.base.name,
        });
      }
      return ls;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
