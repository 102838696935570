<template>
  <div>
    <section>
      <ZHeader title="Property" sub="不動産詳細">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <div class="realtor-realestate-warap">
          <RealtorRealEstate hasLinkOfStaff :realEstate="realEstate" />
        </div>
        <button
          @click="onOfferClick(realEstate)"
          class="button z-button is-rounded is-primary is-fullwidth"
        >
          <span class="icon is-small">
            <i class="mdi mdi-24px mdi-email-outline"></i>
          </span>
          <span> お問い合せ </span>
        </button>
        <template v-if="realEstate.data.overseasRealEstate">
          <div class="notes">
            <p>
              ※ 海外不動産会社へのお問い合わせは、<br
                class="br-show"
              />英語でのご対応をお願いいたします。
            </p>
            <p>
              &nbsp;（海外スタッフでの対応になるため、<br
                class="br-show"
              />日本語でお問い合わせされた場合、<br
                class="br-show"
              />ご返信できない可能性がございます）
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import kubuns from "@/kubuns/kubuns";

import RealtorRealEstate from "@/components/realtor/RealtorRealEstate";

export default {
  name: "investor-search-realestates-detail",
  components: {
    RealtorRealEstate: RealtorRealEstate,
  },
  data: function () {
    return {
      isLoading: true,
      realEstate: null,
    };
  },
  computed: {
    realEstateId() {
      return this.$route.params.id;
    },
  },
  methods: {
    onOfferClick(realEstate) {
      this.$router.push({
        name: "investor-message-detail",
        params: { id: "new" },
        query: {
          realEstateId: realEstate.id,
          messageType: 4,
        },
      });
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.realEstate = await this.$$realtor$loadRealEstate(
          this.realEstateId
        );
        await this.$$realtor$saveViews(this.me.id, this.realEstate.id);
        //console.log(this.realEstate.data.staff);
        //this.realEstate.staff = await this.$$realtor$loadUser(
        //  this.realEstate.data.staff
        //);
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.realtor-realestate-warap {
  margin-bottom: 18px;
}
.formRealEstateUnit {
  .edit__section--box {
    margin-top: 50px;
  }
  .opt-margin-top {
    margin-top: 72px;
  }
}
.notes {
  padding: 0 36px;
  margin-top: 20px;
  color: $danger;
  font-weight: bold;
  @include sp {
    padding: 0 20px;
  }
}
.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
</style>
