//import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";
import { cloneDeep } from "lodash";
const type = {};

type.score = {
  score: 0,
  bs: 0,
  income: 0,
  rank: 4, //C
};

type.PL = {
  sum: 0,
  salary: 0, // 給与	年収
  dividend: 0, // 配当・利子	年配当・利子
  pension: 0, // 年金	年収
  realEstate: 0, // 不動産
  others: 0, // その他	年収
};

type.BS = {
  assets: {
    sum: 0,
    cash: {
      sum: 0,
    },
    security: {
      sum: 0,
    },
    insurance: {
      sum: 0,
    },
    realEstate: {
      sum: 0,
    },
  },
  liabilities: {
    sum: 0,
  },
  netAssets: {
    sum: 0,
  },
};

type.cash = {
  type: 0, // 現金	普通種別（普通・定期・その他）1:普通 2:定期 3:その他
  bank: null, // 	金融機関 bank(String)
  branch: null, // 	支店 branch(String)
  amount: null, // 	金額
};

type.security = {
  type: 0, // 有価証券	証券種別（株式・債券・投信・その他）1:株式 2:債券 3:投信 4:その他
  bank: null, // 	金融機関 bank(String)
  branch: null, // 	支店 branch(String)
  amount: null, // 	金額
};

type.insurance = {
  type: 0, // 保険	保険種別（生保・損保・その他）1:生保 2:損保 3:その他
  bank: null, // 	金融機関 bank(String)
  branch: null, // 	支店 branch(String)
  amount: null, // 	金額
};

type.career = {
  categoryOfBusiness: null, // 業種
  typeOfOccupation: null, // 職種
  name: null, // 会社名
  isCurrent: true,
  period: {
    from: {
      year: null,
      month: null,
    }, // 在籍期間（from-To）
    toDt: {
      year: null,
      month: null,
    }, // 在籍期間（from-To）
  },
};

type.image = {
  title: "", //タイトル
  description: "",
  id: null,
  // src: "",
  isApproved: true, //承認
};

// 買いニーズ
type.buyingNeeds = {
  realEstateTypes: [], // 物件種別
  area: {
    isSelected: false,
    prefecture: null, // 県
    cities: [], // 市町村
  },
  Line: {
    isSelected: false,
    prefecture: null, // 県
    line: null, // 沿線
    stations: [], // 駅
  },
  askingPrice: {
    // 希望物件価格（From-To）
    from: 0, // default: kubuns.filters.price.from 下限なし
    to: 9999999999, // default: kubuns.filters.price.to 上限なし
  },
  couponYieldRate: {
    // 表面利回り
    from: 0, // default: kubuns.filters.yieldRate.from 下限なし
    to: 100, // default: kubuns.filters.yieldRate.to 上限なし
  },
  transport: {
    elapsedTime: 999, // default: kubuns.filters.elapsedTime 最寄り駅までの時間
  },
  buildingTypes: [], // 建物種別
  ageOfBuilding: {
    //築年数
    from: 0, // default: kubuns.filters.yearOfconstruction.from 下限なし
    to: 999, // default: kubuns.filters.yearOfconstruction.to 上限なし
  },
  ownFund: null, // 自己資金・担保可能金額
  comment: "", // コメント
};

// 買いニーズフィルター
type.buyingNeeds.filter = {
  // エリア
  area: {
    isSelected: false,
    prefecture: null, // 県
    cities: [], // 市町村
  },
  // 沿線
  Line: {
    isSelected: false,
    prefecture: null, // 県
    line: null, // 沿線
    stations: [], // 駅
  },
  realEstateTypes: [], // 物件種別
  buildingTypes: [], // 建物種別
  // 表面利回り（From-To）
  couponYieldRate: {
    from: null,
    to: null,
  },
  // 価格（From-To）
  askingPrice: {
    from: null,
    to: null,
  },
  // 築年数
  ageOfBuilding: {
    from: null,
    to: null,
  },
  // 駅からの距離
  transport: {
    elapsedTime: null,
  },
  // 自己資金・担保可能金額
  ownFund: {
    from: 0,
    to: 9999999999,
  },
};

// 投資家条件
type.investorInfo = {};

// 投資家条件フィルター
type.investorInfo.filter = {
  memberships: [], // 会員区分
  // スコア
  score: {
    from: 0, // default: kubuns.filters.score.from 下限なし
    to: 1000, // default: kubuns.filters.score.to 上限なし
  },
  isExcludeScoreBelow350: true, // スコア350以下の投資家を除く default: true
  // 収入
  income: {
    from: 0, // default: kubuns.filters.income.from 下限なし
    to: 999999999999, // default: kubuns.filters.income.to 上限なし
  },
  // 純資産
  netAssets: {
    from: 0, // default: kubuns.filters.price.from 下限なし
    to: 999999999999, // default: kubuns.filters.price.to 上限なし
  },
  businesses: [], // 職業
  isExcludeBusinessRealEstate: false, // 不動産関係の業種を除く
  occupations: [], // 職種
  isExcludeOccupationRealEstate: false, // 不動産関係の職種を除く
};

// その他絞り込み
type.otherInfo = {};

// その他絞り込みフィルター
type.otherInfo.filter = {
  excludeOfferedPeriod: 3, // 指定期間以内でオファー済みの投資家を除く default: 2週間以内
  isExcludeUnregisteredInvestors: true, // 未登録の投資家を除く
  isExcludeInvestorsWithNoComments: false, // 提案希望コメントが未登録の投資家を除く
  commentKeywords: [], // コメントフリーワードで検索
};

type.examination = {
  status: 1, //申請中
  membership: 10, //無料会員
  reasonForRejection: null,
  comment: "",
  isTrial: false, // 無料体験期間中
  isCancelled: false, // 解約
  isSubscribed: false,
  startDt: null, // プレミアム開始日
  endDt: null, // プレミアム終了日
  histories: [],
  pb: {
    isSubscribed: false,
  },
};

type.user = {
  v2: true,
  uid: null,
  isInvestor: true,
  isActivate: false,
  wasCompletedRegister: false,
  //isPremium: false,
  wasArleadyUseTrial: false,
  // isTrial: false, // 無料体験期間中
  // isCancelled: false, // 解約

  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,

  membership: 10, // 10: 無料会員, 20: プレミアム会員

  latestScore: cloneDeep(type.score), //前回保存時のスコア
  latestBS: cloneDeep(type.BS), //前回保存時のBS
  latestPL: cloneDeep(type.PL), //前回保存時のPL

  relationship: 99, // 本人,

  payment: {
    id: null,
  },
  base: {
    email: "",
    name: "",
    kana: "",
    sex: null,
    birthday: null,
    phoneNumber: null,
    postalCode: "",
    prefecture: null,
    city: null,
    address: "",
    building: "",
  },
  profile: {
    image: null,
    currentOfCareer: cloneDeep(type.career),
    careers: [],
    education: {
      type: null, // 最終学歴	学歴区分 1:大学院 2:大学 3:高専・専門・短大 4:高校 5:その他
      name: "", // 学校名
      department: "", // 学部・学科
    },
  },
  income: {
    salary: null, // 給与	年収
    dividend: null, // 配当・利子	年配当・利子
    pension: null, // 年金	年収
    others: null, // その他	年収
  },
  assets: {
    cashes: [], //現金
    securities: [], //有価証券
    insurances: [], //保険
    //不動産は外にあるので別データとして取得します。
  },
  buyingNeeds: [],
  confirm: {
    mailingList: true,
  },
  analytics: {},
};

// ここにグラフごとのtypeを書いてください。
// 例として、hogeグラフの場合
//
//  欲しいデータはリストになると思うんですが、リストの一つの要素を記載してください。
// type.hoge.data = {}
//
//  データを要求するための条件
// type.hoge.filter = {} //条件

// バランスシート
// 四半期(グラフのx軸)ごとのデータ定義
type.balanceSheetChart = {};
type.balanceSheetChart.data = {
  quarter: null, // 四半期 1, 2, 3, 4
  score: cloneDeep(type.score), // スコア
  BS: cloneDeep(type.BS), // バランスシート値
};

type.balanceSheetChart.filter = {
  year: 2019,
};

// 収入区分グラフ
// 四半期(グラフのx軸)ごとのデータ定義
type.incometChart = {};
type.incometChart.data = {
  quarter: null, // 四半期 1, 2, 3, 4
  score: cloneDeep(type.score), // スコア
  PL: cloneDeep(type.PL), // 収入値
};

type.incometChart.filter = {
  year: 2019, // 参照する年(kubuns.portfolioChartYearsで定数管理)
};

// 市況情報検索条件
type.marketTrend = {};
type.marketTrend.filter = {
  score: {
    // スコア
    from: null,
    to: null,
  },
  salePeriod: {
    // 取引時期
    from: null,
    to: null,
  },
  area: {
    isSelected: false,
    prefecture: null, // 県
    cities: [], // 市町村
  },
  Line: {
    isSelected: false,
    prefecture: null, // 県
    line: null, // 沿線
    stations: [], // 駅
  },
  realEstateTypes: [], // 物件種別
  buildingTypes: [], // 建物種別
  couponYieldRate: {
    // 表面利回り
    from: null, //
    to: null,
  },
  amount: {
    from: null, // 購入金額（From-To）
    to: null, // 購入金額（From-To）
  },
  ageOfBuilding: {
    //築年数
    from: null,
    to: null,
  },
  transport: {
    elapsedTime: null, //最寄り駅までの時間
  },
};

// 市況情報検索表示
// スコア--価格の件数のアイテム
type.marketTrendTransactionPrice = {};
type.marketTrendTransactionPrice.filter = {
  marketTrendFilter: null, // 市況情報検索条件
};
type.marketTrendTransactionPrice.data = {
  score: {
    from: 0, // スコア帯最小
    to: 0, // スコア帯最大
  }, // スコア帯
  askingPrice: {
    from: 0, // 価格帯最小
    to: 0, // 価格帯最大
  }, // 価格帯
  count: 0, // 件数
};

// 市況情報検索表示
// スコア--利回りの件数のアイテム
type.marketTrendTransactionYield = {};
type.marketTrendTransactionYield.filter = {
  marketTrendFilter: null, // 市況情報検索条件
};
type.marketTrendTransactionYield.data = {
  score: {
    from: 0,
    to: 0,
  },
  couponYieldRate: {
    from: 0, // 表面利回り帯最小
    to: 0, // 表面利回り帯最大
  }, // 表面利回り帯
  count: 0, // 件数
};

// 不動産取引動向
type.realEstateTransactions = {};
type.realEstateTransactions.filter = {
  marketTrendFilter: null, // 市況情報検索条件
  getNumber: 50, // 取得件数
  pagination: 0, // ページ数
  sortType: 0, // ソート順（1:スコア順、2:利回り順、3:価格順、4:取引時期順）
  selectedFilter: {
    score: {
      from: 0,
      to: 1000,
    },
    amount: {
      from: 0,
      to: null,
    },
    couponYieldRate: {
      from: 0,
      to: null,
    },
  },
};
type.realEstateTransactions.data = [];
type.realEstateTransaction = {
  id: "", // 物件情報のid(詳細画面に遷移するためのパラメタ)
  investor: {
    score: 0, // スコア
    rank: "", // ランク
    age: 0, // 年代
    sex: "", // 性別
    categoryOfBusiness: null, // 業種
    typeOfOccupation: null, // 職種
  },
  amount: 0, // 購入金額
  couponYieldRate: 0, // 表面利回り
  realEstateType: 0, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
  buildingArea: 0, // 建物面積
  prefectureName: "", // 県名
  cityName: "", // 市区町村名
  transport: {
    lineName: "", // 路線名
    stationName: "", // 駅名
    elapsedTime: 0, // 分
  },
  buildingType: 0,
  constructionDate: {
    year: 0,
    month: 0,
  }, // 築年月
  buyingDt: {
    year: 0,
    month: 0,
  }, // 購入年月
};

// ローン取引動向ランキング
type.loanBankRanking = {};
type.loanBankRanking.filter = {
  marketTrendFilter: null, // 市況情報検索条件
  getNumber: 50, // 取得件数
  pagination: 0, // ページ数
};
type.loanBankRanking.data = [];

type.loanBankInfo = {
  ranking: "", // 順位
  imagePath: "", // 銀行画像
  bank: null, // 	金融機関 bank(String)
  bankName: "", // 銀行名
  number: 0, // 件数
  interestRate: {
    from: 0,
    to: 0,
  }, // 金利
};

// ローン取引情報
type.loanBankTransactions = {};
type.loanBankTransactions.filter = {
  marketTrendFilter: null, // 市況情報検索条件
  bank: null, // 	金融機関 bank(String)
  sortType: 1, // ソート順（1:スコア順、2:利回り順、3:価格順、4:取引時期順）
  getNumber: 50, // 取得件数
  pagination: 0, // ページ数
};
type.loanBankTransactions.data = [];
type.loanBankTransaction = {
  investor: {
    score: 0, // スコア
    rank: "", // ランク
    age: 0, // 年代
    sex: "", // 性別
    categoryOfBusiness: null, // 業種
    typeOfOccupation: null, // 職種
  },
  amount: 0, // 融資金額
  bankName: "", // 銀行名
  branchName: "", // 支店名
  paymentPeriods: {
    year: 0,
    month: 0,
  }, // 返済期間年月
  interestRate: 0, // 金利
  interestType: {
    type: null, // 金利タイプ
    interestYearMonth: {
      year: null, // 年
      month: null, // 月
    },
  },
  realEstateType: 0, // 物件種別 1:マンション・アパート 2:ビル 3:ホテル 4:戸建 5：倉庫 6:工場 7:駐車場 8:区分マンション 9:区分店舗・事務所 10:土地
  prefectureName: "", // 県名
  cityName: "", // 市区町村名
  transport: {
    lineName: "", // 路線名
    stationName: "", // 駅名
    elapsedTime: 0, // 分
  },
  constructionDate: {
    year: 0,
    month: 0,
  }, // 築年月
  executeDate: {
    year: 0,
    month: 0,
  }, // 融資実行年月
};

// スコア偏差グラフ
// スコア帯(グラフのx軸)ごとのデータ定義としています。
// リクエストパラメタは特に指定しない想定です。
type.scoreStandardDeviationChart = {};
type.scoreStandardDeviationChart.data = {
  score: {
    // スコア帯
    from: 0, // スコア帯最小
    to: 0, // スコア帯最大
  },
  count: {
    // 職種(category)ごとの総数
    1: 0, // 総数(category : 1)
    2: 0, // ユーザーごとの総数(category : 2)
    3: 0, // ユーザーごとの総数(category : 3)
  },
};

// 表面利回りごとの件数
type.countOfYield = {
  couponYieldRate: {
    // 表面利回り帯
    from: 0, // 表面利回り帯最小
    to: 0, // 表面利回り帯最大
  },
  count: 0, // 件数
};

// 市況情報のヒートマップ
// 物件種別(ヒートマップの行)ごとのデータを定義しています。
// リクエストパラメタは特に指定しない想定です。
type.marketTrendHeatMap = {};
type.marketTrendHeatMap.data = {
  marketTrendType: 1, // kubuns.marketTrendTypesの値
  countsOfMarketTrendType: 0, // 総計( countsOfYield[].countの総数と一致、フロントでやっても良いです )
  countsOfYield: [], // 表面利回りごとの件数
};

type.transactionLogs = {};
type.transactionLogs.data = {
  companyId: null,
  staffId: null,
  transactionType: null, // 契約種別
  contractDate: null, // 売買契約日
  settlementDate: null, // 決済実行日
  price: null, // 取引金額
  fee: null, // 仲介手数料
  // transactionType: {
  //   buying: null,
  //   selling: null,
  // },
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

export default {
  type,
};
