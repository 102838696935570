<template>
  <div>
    <router-view></router-view>
    <hr />
    プレミアム廃止のバッチ
    <hr />
    <b-field label="UID">
      <b-input type="text" v-model="uid" />
    </b-field>
    <button @click="onClickSingle" class="button is-primary">一つ</button>
    <hr />
    <button @click="onClickAll" class="button is-primary">全部</button>
    <hr />
    <div>{{ ids.length }} 個</div>
    <hr />
    <div v-for="id in ids" :key="id">
      {{ id }}
    </div>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import investorType from "@/types/investorType";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

const removePremium = async (uid) => {
  console.log(uid);
  const doc = await firebase.db.collection("users_v1").doc(uid).get();
  const edoc = await firebase.db
    .collection("users_v1")
    .doc(uid)
    .collection("examinations_v1")
    .doc(uid)
    .get();
  // console.log(uid);
  // console.log(data);
  // console.log(edata);
  const data = doc.data();
  const edata = edoc.data();
  await firebase.db
    .collection("users_v1")
    .doc(uid)
    .set(
      {
        bk_membership: data.membership | null,
        membership: 20,
      },
      {
        merge: true,
      }
    );

  await sleep(1000);
  if (edata) {
    await firebase.db
      .collection("users_v1")
      .doc(uid)
      .collection("examinations_v1")
      .doc(uid)
      .set(
        {
          bk_membership: edata.membership | null,
          bk_isTrial: edata.isTrial | null,
          bk_status: edata.status | null,
          isTrial: true,
          membership: 20,
          status: 20,
        },
        {
          merge: true,
        }
      );
  } else {
    await firebase.db
      .collection("users_v1")
      .doc(uid)
      .collection("examinations_v1")
      .doc(uid)
      .set(
        {
          status: 20,
          membership: 20,
          isTrial: true,

          reasonForRejection: null,
          comment: "",
          isCancelled: false, // 解約
          isSubscribed: false,
          startDt: null, // プレミアム開始日
          endDt: null, // プレミアム終了日
          histories: [],
          pb: {
            isSubscribed: false,
          },
        },
        {
          merge: true,
        }
      );
  }
  // await sleep(1000);
  // const edoc = await firebase.db
  //   .collection("users_v1")
  //   .doc(uid)
};

export default {
  name: "admin-batch-remove-premium",
  data() {
    return {
      uid: "7oF1DyVoHMgw3Yc7PHnh8C4jBGs2",
      ids: [],
    };
  },
  methods: {
    async onClickSingle() {
      const uid = this.uid;
      await removePremium(uid);
    },
    async onClickAll() {
      console.log("START");
      const qs = await firebase.db
        .collection("users_v1")
        .where("isInvestor", "==", true)
        .where("membership", "==", 10)
        .get();

      for (const doc of qs.docs) {
        const uid = doc.id;
        await removePremium(uid);
      }
      console.log("FINISH");
    },
  },
  async mounted() {
    const qs = await firebase.db
      .collection("users_v1")
      .where("isInvestor", "==", true)
      .where("membership", "==", 10)
      .get();
    for (const doc of qs.docs) {
      this.ids.push(doc.id);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
