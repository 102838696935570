<template>
  <div class="contents">
    <div v-if="!isLoading">
      <section class="realestate-wrap">
        <RealtorRealEstate :realEstate="rsInfo" />
      </section>
    </div>
  </div>
</template>

<script>
import RealtorRealEstate from "@/components/realtor/RealtorRealEstate";

export default {
  name: "admin-setting-messages-realtor-realEstate",
  components: {
    RealtorRealEstate: RealtorRealEstate,
  },
  data: function () {
    return {
      isLoading: true,
      rsInfo: null,
    };
  },
  methods: {
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        this.rsInfo = await this.$$realtor$loadRealEstate(
          this.$route.params.rid
        );
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
        this.isLoading = false;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realestate-wrap {
  margin: 35px;
}
</style>
