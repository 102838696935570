<template>
  <div>
    <router-view></router-view>
    <hr />
    <section class="section">
      <b-tabs :animated="false" size="is-small" v-model="activeTab">
        <b-tab-item label="aqua">
          <button @click="onClickAquaTest" class="button is-primary">
            TEST
          </button>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
// import CitySelect from "@/components/parts/CitySelect";
import * as investor from "@/modules/investor/investor";
import * as realestate from "@/modules/investor/realestate";
import * as message from "@/modules/message/message";
import * as realtorRealestate from "@/modules/realtor/realestate";
// import vueJsonEditor from 'vue-json-editor'
// import JsonEditor from 'vue-json-ui-editor';

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

export default {
  name: "admin-setting-linkage",
  data() {
    return {
      activeTab: 0,
      isLoading: true,
    };
  },
  methods: {
    async onClickAquaTest() {
      await firebase.functions("linkage-aqua-link_test", {
        owner: "Z40kJwHS92zUy5fwM4rq",
        linkageId: "aqua_test_2",
        staff: "3zVZ1siXNyVHHjNAZpajHhjV5G13",
        type: "aqua",
      });
      // const qs = await firebase
      //   .db
      //   .collectionGroup("examinations_v1")
      //   .where("linkages", "array-contains", {
      //     linkageId: "aqua_test_2",
      //     staff: "3zVZ1siXNyVHHjNAZpajHhjV5G13",
      //     type: "aqua"
      //   })
      //   .get()

      // qs.forEach(doc => {
      //   console.log(doc.id);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
