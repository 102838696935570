<script>
import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
// import realEstateType from "@/types/realEstateType";
// import kubuns from "@/kubuns/kubuns";

//const type = realEstateType.type;

// const scoreDev = [
//   {
//     from: 0,
//     to: 349
//   },
//   {
//     from: 350,
//     to: 399
//   },
//   {
//     from: 400,
//     to: 449
//   },
//   {
//     from: 450,
//     to: 499
//   },
//   {
//     from: 500,
//     to: 549
//   },
//   {
//     from: 550,
//     to: 599
//   },
//   {
//     from: 600,
//     to: 649
//   },
//   {
//     from: 650,
//     to: 699
//   },
//   {
//     from: 700,
//     to: 749
//   },
//   {
//     from: 750,
//     to: 799
//   },
//   {
//     from: 800,
//     to: 849
//   },
//   {
//     from: 850,
//     to: 899
//   },
//   {
//     from: 900,
//     to: 949
//   },
//   {
//     from: 950,
//     to: 999
//   }
// ];

export default {
  name: "Mixins-score-chart",
  methods: {
    async $$ScoreChart$getScoreStandardDeviation() {
      const scoreStandardDeviation = firebase.db
        .doc("score_chart_v1/main")
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data().data;
          } else {
            return false;
          }
        });
      return scoreStandardDeviation;
    },

    // async $$ScoreChart$getScoreStandardDeviation() {
    //   const users = await firebase.db
    //     .collection("users_v1")
    //     .where("isInvestor", "==", true)
    //     .where("isActivate", "==", true)
    //     .get()
    //     .then(querySnapshot => {
    //       const is = [];
    //       querySnapshot.forEach(doc => {
    //         is.push({
    //           id: doc.id,
    //           data: doc.data()
    //         });
    //       });
    //       return is;
    //     });

    //   const rs = [];
    //   for (const dev of scoreDev) {
    //     const cs = {
    //       1: 0,
    //       2: 0,
    //       3: 0
    //     };

    //     for (const user of users) {
    //       const score = user.data.latestScore.score;
    //       if (dev.from <= score && score <= dev.to) {
    //         const oc = user.data.profile.currentOfCareer.typeOfOccupation;
    //         if (oc) {
    //           const cat = this.$$ks.getTypeOfOccupation(oc);
    //           cs[cat.category] = cs[cat.category] + 1;
    //         }
    //       }
    //     }
    //     rs.push({
    //       score: dev,
    //       count: cs
    //     });
    //   }
    //   return rs;
    // }
  },
};
</script>
