<template>
  <div class="AssetsInfoDetail">
    <div class="AssetsInfoDetail__titleArea">
      <p class="AssetsInfoDetail__titleArea__title">{{ title }}</p>
      <p class="AssetsInfoDetail__titleArea__amount" v-if="isAmountExist">
        ¥ {{ amount | addComma }}
      </p>
      <p class="AssetsInfoDetail__titleArea__blankTxt" v-else>
        記入するとスコアアップ
      </p>
    </div>
    <div class="AssetsInfoDetail__descriptionArea">
      <p class="AssetsInfoDetail__descriptionArea__type">{{ assetsType }}</p>
      <p
        class="AssetsInfoDetail__descriptionArea__description"
        v-if="isDescriptionExist"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetsInfoDetail",
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      required: true,
    },
    assetsType: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {};
  },
  filters: {
    addComma: function (val) {
      return val.toLocaleString();
    },
  },
  computed: {
    isAmountExist() {
      if (this.amount === null) {
        return false;
      } else {
        return true;
      }
    },
    isDescriptionExist() {
      if (
        this.description === "" ||
        this.description === null ||
        typeof this.description === "undefined"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.AssetsInfoDetail {
  color: #777777;
  padding-top: 14px;
  &__titleArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 3px;
    margin-bottom: 6px;
    border-bottom: solid 1px;
    border-color: #dddddd;
    &__title {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      margin: 5px 0 4px 0;
    }
    &__amount {
      margin-left: auto;
      white-space: nowrap;
      line-height: 24px;
      font-size: 16px;
      padding-right: 18px;
    }
    &__blankTxt {
      line-height: 24px;
      font-size: 12px;
      padding-right: 18px;
      color: #162c55;
    }
  }
  &__descriptionArea {
    line-height: 16px;
    font-size: 12px;
    &__type {
      margin-bottom: 4px;
    }
  }
}
</style>
