<template>
  <div>
    <tile-box-overlay
      class="createTemplateMessage"
      title="メッセージテンプレート作成"
      ref="overlayCreateTemplateMessage"
    >
      <template late v-slot:OverlayReceive>
        <p>
          提案メッセージのテンプレートを作成・編集いただけます。記入例を参考に、ご活用ください。
        </p>
        <div class="createTemplateMessage--banner">テンプレート作成</div>

        <template v-for="(item, index) in customTemplates">
          <ZEditAccordion
            :title="
              item.id
                ? `テンプレート：${$$createTemplateMessage$replaceScore(
                    item.data.label
                  )}`
                : '新規テンプレート'
            "
            class="createTemplateMessage--accordion"
            :id="`accordionCustomTemplate__${item.data.ord}`"
            :key="item.id"
            :opened="!item.id || false"
          >
            <div class="createTemplateMessage--item">
              <!-- 提案内容 -->
              <section class="createTemplateMessage--section">
                <div class="columns">
                  <div class="column is-full">
                    <p class="createTemplateMessage--header">メッセージ入力</p>
                  </div>
                </div>
                <!-- 提案の特徴 -->
                <div
                  class="columns"
                  :class="{
                    'createTemplateMessage--border__title': !bulma.isMobile,
                  }"
                >
                  <div class="column is-full">
                    <ZSuggestionsCheckbox
                      label="提案の特徴"
                      v-model="item.data.suggestions"
                    />
                  </div>
                </div>

                <!-- 件名 -->
                <div
                  class="columns"
                  :class="{
                    'createTemplateMessage--border__title': !bulma.isMobile,
                  }"
                >
                  <div class="column is-full">
                    <ZInput
                      label="件名"
                      :class="`createTemplateMessage--title createTemplateMessage--title__${item.data.ord}`"
                      placeholder="件名を入力してください"
                      v-model="item.data.label"
                      type="text"
                      maxlength="9999"
                      validate="required"
                      isRequired
                    />
                  </div>
                </div>
                <!-- /件名 -->

                <!-- メッセージ -->
                <div
                  class="columns"
                  :class="{
                    'createTemplateMessage--border__content': !bulma.isMobile,
                  }"
                >
                  <div class="column is-full">
                    <ZTextarea
                      label="メッセージ"
                      :class="`createTemplateMessage--content createTemplateMessage--content__${item.data.ord}`"
                      :rows="8"
                      v-model="item.data.message"
                      type="textarea"
                      placeholder="メッセージを入力してください"
                      maxlength="9999"
                      validate="required"
                      isRequired
                    />
                  </div>
                </div>
                <!-- /メッセージ -->

                <!-- 投資家のスコア名を入力する -->
                <div class="columns">
                  <div class="column is-full">
                    <div class="createTemplateMessage--scorename">
                      <b-button
                        class="createTemplateMessage--scorename__button"
                        type="is-primary"
                        outlined
                        rounded
                        @click="onAddInvestorScore($event, item)"
                      >
                        投資家のスコア名を入力する
                      </b-button>
                      <b-tooltip
                        v-if="!bulma.isMobile"
                        :label="$$createTemplateMessage$labelTooltip()"
                        multilined
                        position="is-top"
                        type="is-dark"
                      >
                        <img
                          class="createTemplateMessage--scorename__help"
                          src="@/assets/investor/help.png"
                        />
                      </b-tooltip>
                    </div>
                  </div>
                </div>
                <!-- /投資家のスコア名を入力する -->

                <!-- 記入例を見る -->
                <div class="columns">
                  <div class="column is-full">
                    <ZEditAccordion
                      title="記入例を見る"
                      class="createTemplateMessage--example"
                      :opened="false"
                    >
                      <section class="createTemplateMessage--example--section">
                        <p>
                          ①宛名・自己紹介、②提案理由・物件優位性、③今後のアクション、④その他アピール事項、をメッセージにご記入いただくことをおすすめいたします。以下記入例です。
                        </p>
                        <div
                          class="createTemplateMessage--example--seperate"
                        ></div>
                        <p>
                          スコア760様
                          <br />（氏名が非表示のため、スコアで失礼いたします。）
                          <br />本メッセージをご覧いただき、ありがとうございます。
                          <br />株式会社ZIRITZの●●と申します。
                          <br />弊社は、ご資産背景が高い投資家様やプロ投資家様向けに、非公開物件紹介に特化した会社です。
                          <br />スコア760様の買いニーズ登録等を確認させていただき、スコア760様なら
                          <br />弊社取扱い非公開物件をご検討いただけるのではと思い、優先的にご紹介させていただきたくご連絡いたしました。
                          <br />中野区RCマンション　18,900万円　利回り7.0％
                          <br />近隣エリアの相場と比較してもお値打ち感があり、将来的にはキャピタルゲインも考えられる物件です。
                          <br />また、積算価格も18,000万円の評価が出ており、スコア760様であれば十分にフルローンの可能性がございます。
                          <br />また、売主様が売却を急いでいることもあり、18,000万（利回り7.5％）まで指値が可能な可能性があります。
                          <br />ご興味をお持ちいただけるようでしたら、是非詳細のご説明の機会をいただければ幸いです。（各種資料をご送付することも可能ですので、ご連絡ください。）
                          <br />弊社は独自のネットワークを構築し物件調達を強みとしているため、その他非公開物件も多数取り扱っております。
                          <br />その他詳細なニーズ等をいただければ、ご要望に応じた物件もご提供可能ですのでお申し付けください。
                          <br />お返事を心よりお待ちしております。
                        </p>
                      </section>
                    </ZEditAccordion>
                    <hr class="createTemplateMessage--seperate" />
                  </div>
                </div>
                <!-- /記入例を見る -->

                <!-- プレビュー -->
                <div class="columns">
                  <div class="column is-full">
                    <ZField
                      label="プレビュー"
                      class="createTemplateMessage--preview"
                      hideRequired
                    >
                      <p class="createTemplateMessage--preview--title">
                        {{
                          $$createTemplateMessage$replaceScore(
                            item.data.label
                          ) || "件名を入力してください"
                        }}
                      </p>
                      <div class="createTemplateMessage--preview--suggestions">
                        <SuggestionsView :suggestions="item.data.suggestions" />
                      </div>
                      <p class="createTemplateMessage--preview--content">
                        {{
                          $$createTemplateMessage$replaceScore(
                            item.data.message
                          ) || "メッセージを入力してください"
                        }}
                      </p>
                    </ZField>
                    <b-button
                      class="createTemplateMessage--remove"
                      @click="onRemove($event, item, index)"
                    >
                      <b-icon
                        class="createTemplateMessage--remove__icon"
                        icon="close"
                        size="is-midium"
                      />
                      <span class="createTemplateMessage--remove__text">
                        このテンプレートを削除する
                      </span>
                    </b-button>
                  </div>
                </div>
                <!-- /このテンプレートを削除する -->
              </section>
              <!-- /提案内容 -->
            </div>
          </ZEditAccordion>
        </template>

        <b-button class="createTemplateMessage--insert" @click="onInsert">
          <b-icon
            class="createTemplateMessage--insert__icon"
            icon="plus-circle"
            size="is-midium"
          />
          <span class="createTemplateMessage--insert__text">
            カスタムテンプレートを追加する
          </span>
        </b-button>

        <div class="terms">
          <div class="terms--wrapper">
            <p class="terms--subtitle">
              StockFormer利用規約に基づき、以下注意事項を確認してください。
            </p>
            <ZCheckbox
              label=""
              v-model="checkedTerms"
              :options="$$ks.message.terms"
              hideRequired
            />
            <b-button
              class="z-button"
              type="is-primary"
              rounded
              :disabled="isDisable()"
              @click="onSave"
            >
              保存する
            </b-button>
          </div>
        </div>
      </template>
    </tile-box-overlay>
  </div>
</template>

<script>
import CreateTemplateMessageMixin from "./CreateTemplateMessageMixin";
import * as MessageTemplateModule from "@/modules/realtor/MessageTemplate";

export default {
  name: "modal-create-template-message",
  mixins: [CreateTemplateMessageMixin],
  props: {
    data: {
      type: Array,
      require: true,
    },
    mode: {
      type: Number,
      require: true,
    },
  },
  data: function () {
    return {
      isLoading: true,
      checkedTerms: [],
      customTemplates: [],
      elemFocus: {
        previous: null,
        current: null,
        typePrevious: null,
        typeCurrent: null,
      },
    };
  },
  methods: {
    // メッセージテンプレート作成を開く
    async open() {
      this.customTemplates = this.$$cloneDeep(this.data);
      this.checkedTerms = [];
      this.$refs.overlayCreateTemplateMessage.open();
    },
    // 保存する
    async onSave() {
      const loadingComponent = this.$loading.open();
      try {
        for (const item of this.customTemplates) {
          await MessageTemplateModule.save(this.me.user.id, this.me.user.id, {
            id: item.id,
            data: item.data,
          });
        }

        const customTemplates =
          await this.$$createTemplateMessage$getCustomTemplates();
        this.customTemplates = customTemplates;
        this.$parent.customTemplates = customTemplates;
        if (
          this.mode === this.$$createTemplateMessage$modes().bulkmessages ||
          this.mode === this.$$createTemplateMessage$modes().messages
        ) {
          this.$parent.onChangeCustomTemplates();
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.checkedTerms = [];
        loadingComponent.close();
      }
    },
    // カスタムテンプレートテンプレートを削除する
    async onRemove($event, item, index) {
      const loadingComponent = this.$loading.open();
      try {
        if (item.id) {
          // 作成されたカスタムテンプレートテンプレートの場合
          await MessageTemplateModule.remove(this.me.user.id, this.me.user.id, {
            id: item.id,
            data: item.data,
          });

          const customTemplates =
            await this.$$createTemplateMessage$getCustomTemplates();
          this.customTemplates = customTemplates;
          this.$parent.customTemplates = customTemplates;
          if (
            this.mode === this.$$createTemplateMessage$modes().bulkmessages ||
            this.mode === this.$$createTemplateMessage$modes().messages
          ) {
            this.$parent.onChangeCustomTemplates();
          }
        } else {
          // 新規作成カスタムテンプレートテンプレートの場合
          this.customTemplates.splice(index, 1);
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // カスタムテンプレートを追加する
    onInsert() {
      const maxOrd =
        Math.max(...this.customTemplates.map((item) => item.data.ord), 0) || 0;
      const newOrd = maxOrd + 1;

      this.customTemplates.push({
        id: null,
        data: {
          label: null,
          message: null,
          suggestions: [],
          ord: newOrd,
        },
      });

      setTimeout(() => {
        const elemAccordionCustomTemplateNew = document.querySelector(
          `#accordionCustomTemplate__${newOrd}`
        );
        if (!elemAccordionCustomTemplateNew) return;

        const elemModalBody = document
          .querySelector(".createTemplateMessage")
          .querySelector(".modal-card-body");
        if (!elemModalBody) return;

        elemModalBody.scrollTop = elemAccordionCustomTemplateNew.offsetTop - 12;
      }, 100);
    },
    // 投資家のスコア名を入力する
    onAddInvestorScore($event, item) {
      const result = this.$$createTemplateMessage$onAddInvestorScore(
        `.createTemplateMessage--title__${item.data.ord}`,
        `.createTemplateMessage--content__${item.data.ord}`,
        this.elemFocus.previous,
        this.elemFocus.typePrevious
      );

      if (this.elemFocus.typePrevious === "textarea") {
        item.data.message = result;
      } else if (this.elemFocus.typePrevious === "textbox") {
        item.data.label = result;
      }
    },
    // 無効にするかどうか
    isDisable() {
      if (this.$$createTemplateMessage$isCheckedTerms(this.checkedTerms.length))
        return true;
      return (
        this.customTemplates.some(
          (item) => !item.data.label || !item.data.message
        ) || false
      );
    },
    // フォカスを切り替える
    focusChanged($event) {
      this.elemFocus = this.$$createTemplateMessage$focusChanged(
        $event,
        this.elemFocus
      );
    },
  },
  created() {
    document.addEventListener("focusin", this.focusChanged);
  },
  beforeDestroy() {
    document.removeEventListener("focusin", this.focusChanged);
  },
  watch: {
    customTemplates: {
      handler: function (value /*, oldVal */) {
        // var vm = this;
        value.filter((item /*, index */) => {
          this.$$createTemplateMessage$adjustHeightTextarea(
            `.createTemplateMessage--content__${item.data.ord}`
          );
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.createTemplateMessage {
  /deep/ .tag {
    border-radius: 0;
    font-size: 12px;
    font-weight: bold;
    color: #777777;
  }

  /deep/ .modal-card-head {
    padding: 24px 44px;

    @include sp {
      padding: 20px 16px;
    }

    &__subtitle {
      display: none;
    }
  }

  /deep/ .modal-card-body {
    padding: 28px calc((100% - 715px) / 2);

    @include sp {
      overflow-y: auto;
      padding: 16px 16px 0 16px;
    }
  }
  &--banner {
    background-color: #d8d8d8;
    border-bottom: solid 2px #707070;
    color: #838383;
    height: 48px;
    font-size: 24px;
    margin: 32px 0 10px;
    padding: 8px 20px;
  }

  &--accordion {
    margin: 16px 0;
  }

  &--insert {
    background-color: #f4f4f4;
    border: 1px dashed #d8d8d8;
    border-radius: 0;
    color: #777777;
    height: 64px;
    margin: 24px auto 40px;
    width: 100%;

    &__icon {
      float: left;
      margin: 20px 0;

      &.icon {
        color: #777777;
      }
    }

    &__text {
      float: left;
      font-size: 16px;
      font-weight: bold;
      line-height: 64px;
      text-align: center;
    }
  }

  &--item {
    /deep/ .z-field {
      margin-bottom: 0;
    }
  }

  &--section {
    padding: 0 20px;

    @include sp {
      padding: 0;
    }
  }

  &--header {
    font-size: 18px;
    font-weight: bold;
  }

  &--border {
    &__title {
      border-top: 1px solid $input-border-color;
      border-left: 1px solid $input-border-color;
      border-right: 1px solid $input-border-color;
      border-radius: 4px 4px 0 0;
    }
    &__content {
      border-bottom: 1px solid $input-border-color;
      border-left: 1px solid $input-border-color;
      border-right: 1px solid $input-border-color;
      border-radius: 0 0 4px 4px;
    }
  }

  &--customtemplate {
    /deep/ .b-radio {
      width: calc(100% - 22px);
    }
  }

  &--createtemplate {
    color: #777777;
    border: 1px solid #777777;
    border-radius: 4px;
    height: 36px;
    margin-bottom: 20px;
    width: 210px;
  }

  &--content {
    /deep/ .textarea {
      border-bottom: none;
      resize: none;
    }
  }

  &--scorename {
    text-align: right;

    &__button {
      font-size: 14px;
      height: 32px;
      width: 220px;
    }

    &__help {
      height: 16px;
      margin: 8px 0 0 12px;
      width: 16px;
    }
  }

  &--example {
    border: 1px solid #e1e1e1;
    border-radius: 6px;

    /deep/ .z-editAccordion {
      &__head {
        background-color: #e1e1e1;
        border: none;
        border-radius: 6px;
        color: #696969;
        font-size: 14px;
        height: 36px;

        &__icon {
          .icon {
            color: #696969;
          }
        }
      }

      &__body {
        padding: 16px 20px;
      }
    }
    &--section {
      font-size: 12px;
    }

    &--seperate {
      border-top: 1px dashed #333333;
      height: 1px;
      margin: 12px 0 32px;
    }
  }

  &--seperate {
    background-color: #696969;
    height: 2px;
    margin: 0;
    width: 100%;
  }

  &--preview {
    &--title {
      border-bottom: 1px solid $input-border-color;
      color: #aeaeae;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      padding: 12px 0;
      overflow-wrap: break-word;
    }
    &--suggestions {
      margin-top: 9px;
    }

    &--content {
      border: 1px solid $input-border-color;
      color: #aeaeae;
      font-size: 16px;
      min-height: 180px;
      margin-top: 20px;
      padding: 12px;
      white-space: pre-line;
      word-break: break-all;
    }
  }

  &--remove {
    border: none;
    border-radius: 0;
    color: #777777;
    float: right;
    height: 22px;
    margin-top: 16px;
    padding: 5px 0;

    &__icon {
      background-color: #f4f4f4;
      border-radius: 50%;
      float: left;

      &.icon {
        color: #777777;
      }
    }

    &__text {
      float: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
    }
  }
}

.terms {
  background-color: #e1eaf7;
  margin: 0 auto;
  padding: 24px;
  width: 700px;

  @include sp {
    margin-left: -12px;
    padding: 12px;
    width: calc(100% + 24px);

    /deep/ .z-field {
      margin-bottom: 12px;
    }
  }

  &--wrapper {
    max-width: 550px;
    margin: 0 auto;
  }

  &--title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;

    &__number {
      color: $primary;
      display: inline-block;
      font-size: 22px;
      margin-left: 8px;
      margin-right: 2px;
    }

    &__highlight {
      color: $primary;
    }
  }

  &--subtitle {
    font-size: 12px;
    text-align: center;

    @include sp {
      text-align: left;
    }
  }

  .z-checkbox {
    margin: 0 auto;

    @include sp {
      width: 100%;
    }

    /deep/ .b-checkbox {
      margin: 4px 0;

      .control-label {
        line-height: 24px;
      }

      input[type="checkbox"]:not(:checked) + .check {
        background-color: $white;
      }
    }
  }

  .z-button {
    margin: 0 calc((100% - 344px) / 2);
    width: 344px;

    @include sp {
      margin: 0 calc((100% - 288px) / 2);
      width: 288px;
    }
  }
}
</style>
