import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";
import firebase from "@/plugins/firebase";

const initial = function (Vue) {
  console.log(process.env.VUE_APP_SENTRY_URL);
  if (process.env.VUE_APP_SENTRY_URL) {
    console.log("Use sentry!");
    Raven.config(process.env.VUE_APP_SENTRY_URL, {
      autoBreadcrumbs: {
        console: true,
        location: true,
      },
    })
      .addPlugin(RavenVue, Vue)
      .install();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("Set user context in Sentry");
        Raven.setUserContext({
          id: user.uid,
          email: user.email,
          appVersion: process.env.VUE_APP_VERSION,
        });
        Raven.setExtraContext({
          isAnonymous: user.isAnonymous,
          appVersion: process.env.VUE_APP_VERSION,
        });
      } else {
        console.log("Clear user context in Sentry");
        Raven.clearContext();
      }
    });
  } else {
    console.log("Not use sentry!");
  }
  Object.defineProperty(Vue.prototype, "$raven", {
    get: () => this,
  });
};

const captureException = (error) => {
  console.error(error);
  if (process.env.VUE_APP_SENTRY_URL) {
    Raven.captureException(error);
  }
};

export default { captureException, initial };
