<template>
  <div class="zero" :class="{ loadCompleted: !isLoading }">
    <div id="zero__header" ref="header" :class="{ isView: isShowHeader }">
      <div class="content__inner">
        <h1>
          <a href="javascript:void(0);" @click="anchorScroll('top')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302.4 41.67">
              <title>StockFormer-ZERO</title>
              <path
                d="M13.35,8.38H43.64V12.2H22.89l7.63,7.63H24.8ZM5.72,6.48,11.44.75H5.72L0,6.48,13.35,19.83h5.72ZM43.64,4.57V.75H17.17L13.35,4.57Zm-11,28.84H2.35V29.6H23.11L15.48,22H21.2Zm7.63,1.91L34.55,41h5.73L46,35.32,32.65,22H26.93ZM2.35,37.22V41H28.83l3.82-3.82Z"
              />
              <path
                d="M68.43,24.32c-7.55-1.26-12.74-5.18-12.74-12.1C55.69,4.38,63,0,70.62,0A21.15,21.15,0,0,1,86.18,6.46l-5.76,6.05A14.13,14.13,0,0,0,70.16,7.84c-3.63,0-5.94,1.61-5.94,4,0,2,1.5,3.51,5.77,4.26l4,.64c8.88,1.44,13.61,5.47,13.61,12.62,0,7.38-6.35,12.33-16.09,12.33A21.35,21.35,0,0,1,54.71,34L60.59,28A14.13,14.13,0,0,0,72,33.83c4.5,0,7-1.9,7-4.38,0-2-1.49-3.51-5.64-4.26Z"
              />
              <path
                d="M93.12,41V.63H121v7.9H101.71V19.08h17.93v7.84H101.71V41Z"
              />
              <path d="M124.59,19.08h21.67v7.84H124.59Z" />
              <path
                d="M181.3.63V8.19l-20.4,25h21V41H150.06V33.49l20.4-25H150.23V.63Z"
              />
              <path
                d="M187.91.63h29.8v7.9H196.5v8h20.23v7.78H196.5v8.82h21.21V41h-29.8Z"
              />
              <path
                d="M240.39,28.07h-6.86V41h-8.59V.63h16.72c8.76,0,15.44,4.79,15.44,13.2a13.64,13.64,0,0,1-8,12.63L258.08,41h-9.74ZM233.53,8.53v11.7h6.63c5.76,0,8.41-2.07,8.41-5.94S246,8.53,240.16,8.53Z"
              />
              <path
                d="M281.48,0c11.59,0,20.92,8.7,20.92,20.81s-9.33,20.86-20.92,20.86S260.73,33,260.73,20.81A20.32,20.32,0,0,1,281.48,0Zm.23,33.6c7.49,0,12-5.82,12-12.79s-4.84-12.74-12-12.74c-7.67,0-12.16,5.88-12.16,12.74S274,33.6,281.71,33.6Z"
              />
            </svg>
          </a>
        </h1>
        <nav>
          <ul>
            <li>
              <a
                href="javascript:void(0);"
                @click="anchorScroll('feature')"
                data-navigation-close
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 87.63 14.24"
                >
                  <title>FEATURE</title>
                  <path d="M0,14V0H9.66V2.74H3v3H9.2V8.5H3V14Z" />
                  <path
                    d="M11.87,0H22.21V2.74H14.85V5.52h7v2.7h-7v3.06h7.36V14H11.87Z"
                  />
                  <path
                    d="M37.49,14H34.41l-1.12-2.76H27.87L26.77,14H23.69L29.17,0H32Zm-8.64-5.4h3.48L30.59,4Z"
                  />
                  <path d="M47.58,0V2.74h-4V14h-3V2.74h-4V0Z" />
                  <path
                    d="M58,0h3V7.8c0,4.28-2.28,6.44-5.88,6.44s-5.88-2.16-5.88-6.44V0h3V7.94c0,2,.9,3.58,2.92,3.58S58,10.16,58,7.9Z"
                  />
                  <path
                    d="M69.08,9.52H66.69V14h-3V0h5.8c3,0,5.37,1.66,5.37,4.58A4.73,4.73,0,0,1,72.12,9L75.22,14H71.84ZM66.69,2.74V6.8H69c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
                  />
                  <path
                    d="M77.29,0H87.63V2.74H80.27V5.52h7v2.7h-7v3.06h7.36V14H77.29Z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="anchorScroll('benefit')"
                data-navigation-close
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 82.93 14.02"
                >
                  <title>BENEFIT</title>
                  <path
                    d="M0,0H6.3c2.86,0,4.84,1.32,4.84,3.88A3.65,3.65,0,0,1,9.7,6.72a3.69,3.69,0,0,1,1.78,3.2c0,2.46-1.92,4.1-4.84,4.1H0ZM3,2.74V5.62H5.82c1.62,0,2.32-.5,2.32-1.44S7.38,2.74,5.7,2.74Zm3,8.54c1.54,0,2.52-.38,2.52-1.62S7.48,8.12,6,8.12H3v3.16Z"
                  />
                  <path
                    d="M13.9,0H24.25V2.74H16.88V5.52h7v2.7h-7v3.06h7.37V14H13.9Z"
                  />
                  <path d="M30,4.56V14H27V0h3.58l5.66,9.5V0h3V14H35.68Z" />
                  <path
                    d="M42.19,0H52.53V2.74H45.17V5.52h7v2.7h-7v3.06h7.36V14H42.19Z"
                  />
                  <path d="M55.32,14V0H65V2.74H58.3v3h6.22V8.5H58.3V14Z" />
                  <path d="M67.24,0h3V14h-3Z" />
                  <path d="M82.93,0V2.74h-4V14H76V2.74h-4V0Z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="anchorScroll('flow')"
                data-navigation-close
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 55.64 14.46"
                >
                  <title>FLOW</title>
                  <path d="M0,14.24V.22H9.66V3H3V6H9.2V8.72H3v5.52Z" />
                  <path d="M11.87.22h3V11.5h6.4v2.74H11.87Z" />
                  <path
                    d="M29.29,0a7.1,7.1,0,0,1,7.26,7.22,7.09,7.09,0,0,1-7.26,7.24,7,7,0,0,1-7.21-7.24A7.05,7.05,0,0,1,29.29,0Zm.07,11.66a4.15,4.15,0,0,0,4.17-4.44A4.22,4.22,0,0,0,29.36,2.8a4.16,4.16,0,0,0-4.22,4.42A4.15,4.15,0,0,0,29.36,11.66Z"
                  />
                  <path
                    d="M42.59,9.2l2.27-9H48l2.26,9,2.16-9h3.18l-3.74,14h-3L46.46,5,44,14.24H41L37.25.22h3.18Z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="anchorScroll('archive')"
                data-navigation-close
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 86.85 14.46"
                >
                  <title>ARCHIVE</title>
                  <path
                    d="M13.8,14.24H10.72L9.6,11.48H4.18l-1.1,2.76H0L5.48.22H8.32ZM5.16,8.84H8.64L6.9,4.26Z"
                  />
                  <path
                    d="M20.69,9.74H18.31v4.5h-3V.22h5.8c3,0,5.36,1.66,5.36,4.58a4.73,4.73,0,0,1-2.76,4.38l3.1,5.06H23.45ZM18.31,3V7h2.3c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
                  />
                  <path
                    d="M41.21,11.82a7,7,0,0,1-5.7,2.64c-4.2,0-7.44-2.8-7.44-7.2A7,7,0,0,1,35.41,0,7,7,0,0,1,41,2.64l-2,2a4.5,4.5,0,0,0-3.72-1.9A4.18,4.18,0,0,0,31.13,7.2a4.18,4.18,0,0,0,4.3,4.48,4.53,4.53,0,0,0,3.8-2Z"
                  />
                  <path
                    d="M51.94,8.44H46.06v5.8h-3V.22h3v5.5h5.88V.22h3v14h-3Z"
                  />
                  <path d="M57.89.22h3v14h-3Z" />
                  <path d="M72,.22h3l-4.8,14H67.16l-4.8-14h3l3.34,10.16Z" />
                  <path
                    d="M76.51.22H86.85V3H79.49V5.74h7v2.7h-7V11.5h7.36v2.74H76.51Z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="anchorScroll('philosophy')"
                data-navigation-close
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 127.67 14.46"
                >
                  <title>PHILOSOPHY</title>
                  <path
                    d="M3,9.8v4.44H0V.22H5.68c3.26,0,5.48,1.78,5.48,4.7S8.94,9.8,5.68,9.8ZM3,3v4.1H5.16c2,0,3-.68,3-2.08s-1-2-3-2Z"
                  />
                  <path d="M21.83,8.44H16v5.8H13V.22h3v5.5h5.88V.22h3v14h-3Z" />
                  <path d="M27.79.22h3v14h-3Z" />
                  <path d="M33.71.22h3V11.5H43.1v2.74H33.71Z" />
                  <path
                    d="M51.13,0a7.1,7.1,0,0,1,7.26,7.22,7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,51.13,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,51.21,2.8,4.16,4.16,0,0,0,47,7.22,4.15,4.15,0,0,0,51.21,11.66Z"
                  />
                  <path
                    d="M64.38,8.44C61.76,8,60,6.64,60,4.24,60,1.52,62.48,0,65.14,0a7.36,7.36,0,0,1,5.41,2.24l-2,2.1A4.9,4.9,0,0,0,65,2.72c-1.26,0-2.06.56-2.06,1.38s.52,1.22,2,1.48l1.38.22C69.39,6.3,71,7.7,71,10.18s-2.2,4.28-5.59,4.28a7.42,7.42,0,0,1-5.82-2.68l2-2.06a4.91,4.91,0,0,0,4,2c1.56,0,2.45-.66,2.45-1.52,0-.7-.53-1.22-2-1.48Z"
                  />
                  <path
                    d="M79.64,0A7.1,7.1,0,0,1,86.9,7.22a7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,79.64,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,79.72,2.8,4.16,4.16,0,0,0,75.5,7.22,4.15,4.15,0,0,0,79.72,11.66Z"
                  />
                  <path
                    d="M92.11,9.8v4.44h-3V.22h5.68c3.27,0,5.49,1.78,5.49,4.7S98.08,9.8,94.81,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                  />
                  <path d="M111,8.44h-5.88v5.8h-3V.22h3v5.5H111V.22h3v14h-3Z" />
                  <path
                    d="M121.53,6.12l2.9-5.9h3.24L123,9.34v4.9h-2.94V9.34L115.41.22h3.24Z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
        <a
          class="zero__header__simulation"
          href="javascript:void(0);"
          @click="$emit('onConversionClick', $event)"
          ><small>無料</small>フルローン診断</a
        >
        <button
          type="button"
          id="js-touch-device-navigation"
          class="zero__header__touch-device-navigation"
        >
          <span>touch device navigation open</span>
        </button>
      </div>
    </div>
    <main>
      <section class="zero-content zero-content--keyVisual" ref="top">
        <div class="keyVisual">
          <div class="zero-content__inner">
            <h1 class="keyVisual__logo">
              <svg
                class="pc"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 949.34 69.57"
              >
                <title>StockFormer-ZERO</title>
                <path
                  d="M22.29,14.06H72.87v6.37H38.21L51,33.16H41.4ZM9.55,10.87l9.56-9.55H9.55L0,10.87,22.29,33.16h9.55ZM72.87,7.69V1.32H28.66L22.29,7.69ZM54.51,55.84H3.93V49.47H38.58L25.85,36.74H35.4ZM67.24,59l-9.55,9.55h9.55L76.8,59,54.51,36.74H45ZM3.93,62.21v6.37H48.14l6.37-6.37Z"
                />
                <path
                  d="M114.07,40.61C101.46,38.49,92.8,32,92.8,20.4,92.8,7.31,104.92,0,117.72,0a35.32,35.32,0,0,1,26,10.78l-9.63,10.1c-4.33-4.42-9.91-7.79-17.13-7.79-6.06,0-9.91,2.69-9.91,6.64,0,3.27,2.5,5.87,9.62,7.12l6.64,1.06C138.12,30.31,146,37.05,146,49c0,12.32-10.58,20.59-26.84,20.59a35.72,35.72,0,0,1-28-12.89L101,46.77a23.61,23.61,0,0,0,19,9.72c7.51,0,11.74-3.18,11.74-7.32,0-3.36-2.5-5.87-9.43-7.12Z"
                />
                <path
                  d="M155.6,51.87V34h-6.16V21.36h6.16V8.18h13.57V21.36h10.49V34H169.17V50.23c0,4.72,2.31,5.78,6.25,5.78h4.05V68.52s-3.66.19-7.41.19C160.89,68.71,155.6,62.74,155.6,51.87Z"
                />
                <path
                  d="M209.82,20.21c14.14,0,24.92,10.58,24.92,24.73S224,69.57,209.82,69.57s-24.93-10.49-24.93-24.63S195.67,20.21,209.82,20.21Zm0,36.66c6.35,0,11-5,11-11.93S216.17,33,209.82,33s-11,5-11,11.93S203.47,56.87,209.82,56.87Z"
                />
                <path
                  d="M285.43,59.57c-4.81,6.35-11.55,10-20.69,10-14.15,0-24.93-10.49-24.93-24.63s10.78-24.73,24.93-24.73c8.95,0,14.72,3.85,19.72,9.43l-9.23,9c-2.7-3.27-5.68-5.49-10-5.49-6.45,0-11.45,4.91-11.45,11.84s5,11.84,11.45,11.84c4.71,0,8-2.31,11-6.16Z"
                />
                <path
                  d="M291.74,1.06H305.4V36.28l13.09-14.92h17.23L318.3,40.8l18.19,27.72H319.84l-11.26-17.9-3.18,3.46V68.52H291.74Z"
                />
                <path
                  d="M344.35,68.52V1.06h46.48V14.24H358.69V31.35h29.93V44.44H358.69V68.52Z"
                />
                <path
                  d="M420,20.21c14.15,0,24.93,10.58,24.93,24.73S434.19,69.57,420,69.57s-24.92-10.49-24.92-24.63S405.9,20.21,420,20.21Zm0,36.66c6.36,0,11-5,11-11.93S426.4,33,420,33s-11,5-11,11.93S413.69,56.87,420,56.87Z"
                />
                <path
                  d="M480.44,34h-2.12c-7.6,0-13.08,4.81-13.08,14.82V68.52H451.57V21.36H465V25.6c2.6-3.08,6.93-4.91,11.74-4.91,1.64,0,2.31,0,3.66.1Z"
                />
                <path
                  d="M501.05,68.52H487.39V21.36h13.47v3.27a16.31,16.31,0,0,1,11.36-4.42,16.91,16.91,0,0,1,13.08,5.68,20.19,20.19,0,0,1,14.15-5.68c12.61,0,19.73,7.5,19.73,20.88V68.52H545.51V41.67c0-5.1-2.31-8.76-7.22-8.76-5.19,0-8.17,4-8.17,10.49V68.52H516.45V41.67c0-5.1-2.31-8.76-7.22-8.76-5.39,0-8.18,4.24-8.18,11.45Z"
                />
                <path
                  d="M579.09,48.4c.77,4.91,4.52,8.66,10.58,8.66a13.82,13.82,0,0,0,11.55-5.48l9,8.47c-4.14,5.19-10.11,9.52-20.21,9.52-14.15,0-24.83-9.52-24.83-24.34,0-14.24,9.53-25,23.19-25,15.21,0,22.71,12.22,22.71,24.15,0,1.64-.09,4-.09,4Zm.67-9.14H596.7a7.63,7.63,0,0,0-8-7A8.68,8.68,0,0,0,579.76,39.26Z"
                />
                <path
                  d="M648,34H645.9c-7.6,0-13.08,4.81-13.08,14.82V68.52H619.15V21.36h13.47V25.6c2.6-3.08,6.93-4.91,11.74-4.91,1.64,0,2.31,0,3.66.1Z"
                />
                <path d="M652.48,40.87h36.18V53.19H652.48Z" />
                <path
                  d="M747.16,1.06V13.67L713.09,55.33h35.13V68.52H695V55.91l34.07-41.67H695.29V1.06Z"
                />
                <path
                  d="M758.19,1.06h49.75V14.24H772.53V27.62H806.3v13H772.53V55.33h35.41V68.52H758.19Z"
                />
                <path
                  d="M845.81,46.86H834.36V68.52H820V1.06h27.9c14.63,0,25.79,8,25.79,22a22.75,22.75,0,0,1-13.28,21.07l14.92,24.35H859.09ZM834.36,14.24V33.78h11.06c9.63,0,14.05-3.47,14.05-9.91s-4.33-9.63-14.05-9.63Z"
                />
                <path
                  d="M914.41,0c19.34,0,34.93,14.53,34.93,34.74,0,20.4-15.59,34.83-34.93,34.83s-34.64-14.43-34.64-34.83C879.77,14.53,895.07,0,914.41,0Zm.39,56.1c12.51,0,20-9.72,20-21.36,0-11.45-8.08-21.27-20-21.27-12.8,0-20.31,9.82-20.31,21.27C894.49,46.38,902,56.1,914.8,56.1Z"
                />
              </svg>
              <svg
                class="sp"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 80.37 98.43"
              >
                <polygon
                  points="9.96 9.96 19.91 0 9.95 0 0 9.96 16.59 26.55 26.55 26.55 9.96 9.96"
                />
                <polygon
                  points="40.2 50.18 0.28 50.18 0.28 56.81 56.8 56.81 43.52 43.54 33.57 43.54 40.2 50.18"
                />
                <polygon
                  points="79.74 0 29.86 0 29.86 0 23.23 6.64 79.74 6.64 79.74 0"
                />
                <polygon
                  points="39.82 19.91 79.74 19.91 79.74 13.27 23.23 13.27 36.5 26.55 46.45 26.55 39.82 19.91"
                />
                <polygon
                  points="0.28 70.08 50.16 70.08 50.16 70.08 56.8 63.45 0.28 63.45 0.28 70.08"
                />
                <polygon
                  points="63.43 43.54 53.48 43.54 70.07 60.13 60.11 70.08 70.07 70.08 80.02 60.13 63.43 43.54"
                />
                <path
                  d="M16.65,76.72v4.7L7.29,93.22h9.3V98.1H.16V93.4L9.49,81.6H.22V76.72Z"
                />
                <path
                  d="M20.05,76.72H36.16V81.6H26v3.26h9.67v4.7H26v3.66H36.16V98.1H20.05Z"
                />
                <path
                  d="M47.54,91.48h-2.2V98.1H39.79V76.72H48.3c5.49,0,8.87,2.56,8.87,7.38a6.66,6.66,0,0,1-3.87,6.38l4.55,7.62H51.47Zm-2.2-9.88v5H48c2.53,0,3.66-.88,3.66-2.59S50.49,81.6,48,81.6Z"
                />
                <path
                  d="M69.48,76.39a11,11,0,1,1-10.82,11A10.8,10.8,0,0,1,69.48,76.39Zm-5.09,11c0,3.26,1.86,6,5.12,6s5.1-2.71,5.1-6-1.83-6-5.1-6S64.39,84.1,64.39,87.43Z"
                />
                <path
                  d="M9.28,37.48V34.89h-.9V33.06h.9V31.14h2v1.92h1.53v1.83H11.24v2.36c0,.68.34.83.91.83h.59V39.9s-.53,0-1.08,0A2.14,2.14,0,0,1,9.28,37.48Z"
                />
                <path
                  d="M17.23,32.89a3.58,3.58,0,1,1,0,7.16,3.58,3.58,0,1,1,0-7.16Zm0,5.32a1.74,1.74,0,1,0-1.6-1.73A1.61,1.61,0,0,0,17.23,38.21Z"
                />
                <path
                  d="M28.28,38.6a3.57,3.57,0,0,1-3,1.45,3.58,3.58,0,1,1,0-7.16,3.63,3.63,0,0,1,2.86,1.37l-1.34,1.3a1.82,1.82,0,0,0-1.45-.8,1.72,1.72,0,0,0,0,3.43,1.94,1.94,0,0,0,1.59-.89Z"
                />
                <path
                  d="M29.28,31.14h2v4.08l1.9-2.16h2.5l-2.53,2.82,2.64,4H33.36l-1.64-2.6-.46.5v2.1h-2Z"
                />
                <path
                  d="M36.81,39.9V30.11h6.74v2H38.89v2.3h3.22v2H38.89V39.9Z"
                />
                <path
                  d="M46.61,32.89A3.58,3.58,0,1,1,43,36.48,3.53,3.53,0,0,1,46.61,32.89Zm0,5.32A1.74,1.74,0,1,0,45,36.48,1.61,1.61,0,0,0,46.61,38.21Z"
                />
                <path
                  d="M55.55,34.89h-.31c-1.1,0-1.9.69-1.9,2.15V39.9h-2V33.06h2v.61A2.18,2.18,0,0,1,55,33h.53Z"
                />
                <path
                  d="M58.45,39.9h-2V33.06h2v.47a2.39,2.39,0,0,1,1.65-.64,2.48,2.48,0,0,1,1.9.82,2.93,2.93,0,0,1,2-.82c1.83,0,2.86,1.09,2.86,3v4h-2V36c0-.74-.34-1.27-1.05-1.27s-1.19.57-1.19,1.52V39.9h-2V36c0-.74-.33-1.27-1-1.27s-1.18.62-1.18,1.66Z"
                />
                <path
                  d="M69.94,37a1.46,1.46,0,0,0,1.54,1.26,2,2,0,0,0,1.67-.8l1.31,1.23a3.49,3.49,0,0,1-2.93,1.38,3.41,3.41,0,0,1-3.6-3.53,3.39,3.39,0,0,1,3.37-3.63,3.28,3.28,0,0,1,3.29,3.5c0,.24,0,.59,0,.59Zm.1-1.33H72.5a1.12,1.12,0,0,0-1.16-1A1.27,1.27,0,0,0,70,35.65Z"
                />
                <path
                  d="M80,34.89h-.3c-1.11,0-1.9.69-1.9,2.15V39.9h-2V33.06h2v.61a2.2,2.2,0,0,1,1.7-.71H80Z"
                />
                <path
                  d="M3.26,35.81c-1.8-.3-3-1.23-3-2.88C.23,31.07,2,30,3.78,30a5,5,0,0,1,3.69,1.54L6.1,33a3.34,3.34,0,0,0-2.43-1.11c-.87,0-1.41.39-1.41,1s.35.83,1.37,1l.94.15c2.11.34,3.23,1.3,3.23,3S6.29,39.93,4,39.93a5.06,5.06,0,0,1-4-1.84l1.4-1.41a3.33,3.33,0,0,0,2.7,1.39c1.07,0,1.67-.46,1.67-1s-.35-.84-1.34-1Z"
                />
              </svg>
            </h1>
            <p class="keyVisual__copy">
              はじめたいけど 一歩が踏み出せない<br />
              不動産投資の不安を一気に解消<br />
              初心者におすすめの限定プランが誕生
            </p>
            <a
              class="keyVisual__button--simulation"
              href="javascript:void(0);"
              @click="$emit('onConversionClick', $event)"
              ><small>無料</small>フルローン診断</a
            >
          </div>
          <picture class="picture keyVisual__image">
            <source
              type="image/webp"
              srcset="@/assets/investor/zero/key-visual.webp"
            />
            <img
              src="@/assets/investor/zero/key-visual.png"
              alt="StockFormer-ZERO"
            />
          </picture>
        </div>
        <div class="appeal">
          <div class="zero-content__inner">
            <dl class="appeal__text">
              <dt>
                不動産投資プラットフォームNo.1<sup>※</sup>の<br
                  class="sp"
                />StockFormerで<br />自分らしく多様な生き方を<br
                  class="sp"
                />選択できる未来を創りませんか？
              </dt>
              <dd class="appeal__image">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 242.92 85.44"
                >
                  <title>三冠達成 No.1</title>
                  <g class="leaf">
                    <path
                      d="M40.89,67.42a9.47,9.47,0,0,1-1.6,8.33,9.5,9.5,0,0,1-5.78-6.21c-1.38-4.82.77-11.25.77-11.25S39.51,62.6,40.89,67.42Z"
                    />
                    <path
                      d="M32,63a9.5,9.5,0,0,1-2.21,8.2,9.5,9.5,0,0,1-5.31-6.62c-1-4.91,1.58-11.16,1.58-11.16S31,58.1,32,63Z"
                    />
                    <path
                      d="M24.34,55.31a9,9,0,0,1-5.18,6.18,9,9,0,0,1-1.92-7.83c1.08-4.64,5.83-9,5.83-9S25.42,50.67,24.34,55.31Z"
                    />
                    <path
                      d="M20.78,45.13A9,9,0,0,1,14,49.42a9,9,0,0,1,.57-8c2.45-4.09,8.31-6.76,8.31-6.76S23.24,41,20.78,45.13Z"
                    />
                    <path
                      d="M21.54,34.14A8.54,8.54,0,0,1,14,35.21a8.57,8.57,0,0,1,3.61-6.75c3.71-2.59,9.83-2.64,9.83-2.64S25.25,31.55,21.54,34.14Z"
                    />
                    <path
                      d="M26.22,24.6a8.58,8.58,0,0,1-7.66-.11,8.58,8.58,0,0,1,4.6-6.12c4.07-2,10.12-1.1,10.12-1.1S30.28,22.6,26.22,24.6Z"
                    />
                    <path
                      d="M27,11.43a7.7,7.7,0,0,1-4.94,4.75,7.68,7.68,0,0,1-.92-6.79c1.33-3.83,5.74-7.06,5.74-7.06S28.33,7.6,27,11.43Z"
                    />
                    <path
                      d="M33.24,16a7.68,7.68,0,0,1-6.73-1.33,7.7,7.7,0,0,1,5-4.64c3.89-1.1,9.07.67,9.07.67S37.13,14.9,33.24,16Z"
                    />
                    <path
                      d="M35,7.63a6.53,6.53,0,0,1-5.73,1,6.51,6.51,0,0,1,2.57-5.22c2.75-2.06,7.39-2.26,7.39-2.26S37.73,5.56,35,7.63Z"
                    />
                    <path
                      d="M19.75,17.17a8.56,8.56,0,0,1-3.89,6.59,8.57,8.57,0,0,1-3-7c.29-4.52,4.07-9.33,4.07-9.33S20,12.65,19.75,17.17Z"
                    />
                    <path
                      d="M14,27.11a8.56,8.56,0,0,1-2.21,7.33,8.59,8.59,0,0,1-4.61-6.11c-.8-4.46,1.73-10,1.73-10S13.16,22.65,14,27.11Z"
                    />
                    <path
                      d="M10.81,38.87a9,9,0,0,1,.1,8.06,9,9,0,0,1-6.57-4.68C2.14,38,2.91,31.64,2.91,31.64S8.6,34.65,10.81,38.87Z"
                    />
                    <path
                      d="M10.55,50.66A9,9,0,0,1,15,57.4a9,9,0,0,1-8-.42C2.78,54.61,0,48.8,0,48.8S6.41,48.28,10.55,50.66Z"
                    />
                    <path
                      d="M14.94,62.18a9.51,9.51,0,0,1,6.64,5.29,9.47,9.47,0,0,1-8.19,2.23c-4.91-1-9.6-5.9-9.6-5.9S10,61.17,14.94,62.18Z"
                    />
                    <path
                      d="M21.5,72.39A9.51,9.51,0,0,1,30,73a9.5,9.5,0,0,1-5.48,6.48c-4.62,2-11.26.58-11.26.58S16.88,74.34,21.5,72.39Z"
                    />
                    <path
                      d="M34.31,77.26a9.51,9.51,0,0,1,8.48.43,9.51,9.51,0,0,1-5.36,6.58c-4.58,2-11.24.8-11.24.8S29.73,79.3,34.31,77.26Z"
                    />
                  </g>
                  <g class="leaf">
                    <path
                      d="M202,67.42a9.5,9.5,0,0,0,1.61,8.33,9.48,9.48,0,0,0,5.77-6.21c1.39-4.82-.76-11.25-.76-11.25S203.41,62.6,202,67.42Z"
                    />
                    <path
                      d="M210.88,63a9.5,9.5,0,0,0,2.21,8.2,9.5,9.5,0,0,0,5.31-6.62c1-4.91-1.59-11.16-1.59-11.16S211.91,58.1,210.88,63Z"
                    />
                    <path
                      d="M218.57,55.31a9,9,0,0,0,5.19,6.18,9,9,0,0,0,1.92-7.83c-1.08-4.64-5.84-9-5.84-9S217.49,50.67,218.57,55.31Z"
                    />
                    <path
                      d="M222.13,45.13A9,9,0,0,0,229,49.42a9,9,0,0,0-.57-8c-2.45-4.09-8.31-6.76-8.31-6.76S219.68,41,222.13,45.13Z"
                    />
                    <path
                      d="M221.37,34.14A8.56,8.56,0,0,0,229,35.21a8.6,8.6,0,0,0-3.62-6.75c-3.71-2.59-9.82-2.64-9.82-2.64S217.66,31.55,221.37,34.14Z"
                    />
                    <path
                      d="M216.7,24.6a8.58,8.58,0,0,0,7.66-.11,8.58,8.58,0,0,0-4.61-6.12c-4.06-2-10.11-1.1-10.11-1.1S212.64,22.6,216.7,24.6Z"
                    />
                    <path
                      d="M215.92,11.43a7.68,7.68,0,0,0,4.94,4.75,7.68,7.68,0,0,0,.92-6.79C220.44,5.56,216,2.33,216,2.33S214.59,7.6,215.92,11.43Z"
                    />
                    <path
                      d="M209.68,16a7.65,7.65,0,0,0,6.72-1.33,7.67,7.67,0,0,0-5-4.64c-3.9-1.1-9.07.67-9.07.67S205.78,14.9,209.68,16Z"
                    />
                    <path
                      d="M207.94,7.63a6.51,6.51,0,0,0,5.72,1,6.48,6.48,0,0,0-2.56-5.22c-2.75-2.06-7.39-2.26-7.39-2.26S205.19,5.56,207.94,7.63Z"
                    />
                    <path
                      d="M223.17,17.17a8.56,8.56,0,0,0,3.89,6.59,8.54,8.54,0,0,0,3-7C229.8,12.21,226,7.4,226,7.4S222.88,12.65,223.17,17.17Z"
                    />
                    <path
                      d="M229,27.11a8.56,8.56,0,0,0,2.21,7.33,8.59,8.59,0,0,0,4.61-6.11c.8-4.46-1.73-10-1.73-10S229.76,22.65,229,27.11Z"
                    />
                    <path
                      d="M232.11,38.87a9,9,0,0,0-.1,8.06,9,9,0,0,0,6.56-4.68C240.78,38,240,31.64,240,31.64S234.32,34.65,232.11,38.87Z"
                    />
                    <path
                      d="M232.37,50.66A9,9,0,0,0,228,57.4a9,9,0,0,0,8-.42c4.13-2.37,6.91-8.18,6.91-8.18S236.5,48.28,232.37,50.66Z"
                    />
                    <path
                      d="M228,62.18a9.5,9.5,0,0,0-6.63,5.29,9.47,9.47,0,0,0,8.19,2.23c4.91-1,9.6-5.9,9.6-5.9S232.89,61.17,228,62.18Z"
                    />
                    <path
                      d="M221.42,72.39A9.51,9.51,0,0,0,213,73a9.5,9.5,0,0,0,5.48,6.48c4.62,2,11.26.58,11.26.58S226,74.34,221.42,72.39Z"
                    />
                    <path
                      d="M208.61,77.26a9.5,9.5,0,0,0-8.48.43,9.47,9.47,0,0,0,5.36,6.58c4.58,2,11.24.8,11.24.8S213.19,79.3,208.61,77.26Z"
                    />
                  </g>
                  <path
                    d="M61.57,52.17c0,12.19.58,22.23.72,24.45.14,3.59,1,5.31,3.58,5.31h1.87c.57,0,.57.29.57.65v1.65c0,.43,0,.57-.57.57s-5-.22-7.75-.22c-1.86,0-6.74.22-7.45.22-.29,0-.43-.14-.43-.65V82.36a.46.46,0,0,1,.43-.43H55c1.87,0,2.87-1.58,3.23-6,.07-1.29.5-11.33.5-22.8V44.07c0-4.3-2.43-7.6-5.59-7.6H50.82c-.29-.07-.44-.22-.44-.5V34a.38.38,0,0,1,.44-.43c.35,0,.93.07,2.22.14s3.23.07,6.38.07c1.22,0,2.29-.07,3.16-.14s1.57-.07,2-.07a2.11,2.11,0,0,1,1.72.64C67,35,81.08,52.6,82.51,54.61c1,1.15,12.19,15.06,13.34,16.49h.29V63.65c0-6.32,0-9.9-.15-13.49-.14-2.72-.43-9.75-.79-11a3.85,3.85,0,0,0-3.8-2.72H89.11c-.43,0-.43-.29-.43-.65V34.17c0-.43,0-.57.57-.57.86,0,3.16.21,8,.21,4.31,0,6-.21,6.89-.21.57,0,.71.29.71.5v1.65c0,.43-.14.72-.43.72h-2c-1.44,0-2.16,1.14-2.37,3.8C99.72,45.14,99,57.19,99,64.51V83.44c0,1,0,2-.86,2a3.56,3.56,0,0,1-2.43-1.14c-.58-.72-4.45-5.17-8.9-10.47-5-6.17-10.61-12.91-11.47-14.06S62.86,44.43,62,43.42h-.43Z"
                  />
                  <path
                    d="M134.65,58.05a15.47,15.47,0,0,1,4.95,11.33,16.43,16.43,0,0,1-4.45,10.9,15.89,15.89,0,0,1-12,5.16c-4.52,0-8.39-1.29-11.62-5.16s-3.87-6.74-3.87-11.11a16,16,0,0,1,6-11.91,15.5,15.5,0,0,1,10.18-3.44A15.19,15.19,0,0,1,134.65,58.05Zm-3.8,11.12c0-7.89-4.16-12.48-7.46-12.48a5.59,5.59,0,0,0-4.51,1.94c-2.23,2.51-3.09,5.52-3.09,10.75,0,4.16.29,6.89,2.23,10a6,6,0,0,0,5.66,3.08C128.41,82.43,130.85,76.48,130.85,69.17Z"
                  />
                  <path
                    d="M144.48,80.43a5,5,0,0,1,10,.28,4.59,4.59,0,0,1-4.73,4.73C147.21,85.44,144.48,83.87,144.48,80.43Z"
                  />
                  <path
                    d="M170.85,45c0-2.39-.48-4.14-1.92-4.14a10.78,10.78,0,0,0-3.34.64l-4.78,1.43c-.32.16-.64.16-.8-.16l-.64-1.59q-.24-.48.48-1l18.33-9.56a3.33,3.33,0,0,1,1.43-.48c.48,0,.64.32.64,1.28,0,.64-.32,13.07-.32,23.34v3.35c0,2.71.16,17.85.16,18.89,0,4.14.32,4.62,4.78,4.62h2.87c.63,0,.79.24.79.55v2c0,.47-.16.63-.79.63s-11.48-.24-12.75-.24c-1.6,0-12.75.24-13.23.24s-.64-.16-.64-.55V82.19c0-.47.32-.55.88-.55h3.11c4.78,0,5.42-.16,5.58-4.39.16-2.23.16-14.34.16-16.57Z"
                  />
                  <path
                    d="M90.91,18.29a15,15,0,0,0-2.83-.16H75.27a21.84,21.84,0,0,0-3,.16V15.58a26.68,26.68,0,0,0,3,.13H88a27.17,27.17,0,0,0,2.9-.13ZM73.71,1.19a19.84,19.84,0,0,0,2.68.13h10.3a22.12,22.12,0,0,0,2.77-.13V3.83a24.16,24.16,0,0,0-2.77-.13H76.44a17.54,17.54,0,0,0-2.73.13ZM75.05,8.1a16.53,16.53,0,0,0,2.44.13h8.16a19.6,19.6,0,0,0,2.47-.13v2.62a20.65,20.65,0,0,0-2.44-.11H77.51a19.24,19.24,0,0,0-2.46.11Z"
                  />
                  <path
                    d="M100.68,10.56a12.28,12.28,0,0,0-2,.11V8.43a13.07,13.07,0,0,0,2.09.11h6a7.72,7.72,0,0,0,1.45-.09v2.2a7.3,7.3,0,0,0-1.3-.09h-.48V16c0,.53.11.68.57.79A23.25,23.25,0,0,0,110,17a38.81,38.81,0,0,0,4.31-.13c1-.2,1.21-.68,1.28-3.1a7,7,0,0,0,2.22.7c-.18,2.09-.49,3.15-1.08,3.74s-1.85.89-6,.89a22.24,22.24,0,0,1-5.19-.31c-.93-.29-1.17-.68-1.17-1.92V10.56H103a23,23,0,0,1-.26,3.61A9.69,9.69,0,0,1,101.21,18a10.72,10.72,0,0,1-1.46,1.6,9.24,9.24,0,0,0-1.6-1.8,6.52,6.52,0,0,0,2.55-4.16,17.69,17.69,0,0,0,.24-3.06Zm14.41-4.35h.49a14.77,14.77,0,0,0,2-.09V8.34a11.28,11.28,0,0,0-2-.11h-.49v5.61c0,1.67-.62,2.11-3,2.11-.53,0-1.23,0-1.74-.06a6.55,6.55,0,0,0-.61-2.16,17,17,0,0,0,2.48.18c.55,0,.68-.09.68-.47V8.23h-3.36a7.48,7.48,0,0,0-1.43.09V6.12a12.71,12.71,0,0,0,1.6.09h3.19v-1a8.44,8.44,0,0,0-.08-1.34h2.22V3H101V4.73c.45,0,.75,0,1.3,0h3.26a14.2,14.2,0,0,0,2-.08V6.89a12.65,12.65,0,0,0-2-.09h-3.3a12.45,12.45,0,0,0-1.94.09V5.68h-1.6a11,11,0,0,0,.08-1.5V2.33A11.86,11.86,0,0,0,98.68.79a22.26,22.26,0,0,0,2.24.09h14.15a21.12,21.12,0,0,0,2.29-.09,8.86,8.86,0,0,0-.09,1.48V3.81a9.86,9.86,0,0,0,.09,1.52h-2.27Zm-4.8,7a13.16,13.16,0,0,0-1.76-3.28L110.25,9a12.88,12.88,0,0,1,1.83,3.1Z"
                  />
                  <path
                    d="M124.72,8.1a8.79,8.79,0,0,0,1.7.11H128a11.21,11.21,0,0,0,1.61-.09,19.11,19.11,0,0,0-.09,2.31V15a3.41,3.41,0,0,0,1.65,1.48c.86.39,2.27.55,5.3.55a61.59,61.59,0,0,0,8.06-.4,12.13,12.13,0,0,0-.62,2.42c-2,.07-5,.14-7.19.14a20.21,20.21,0,0,1-5.61-.44,5.16,5.16,0,0,1-2.67-1.9,24.27,24.27,0,0,1-2.48,2l-.62.46-1-2.4a12.09,12.09,0,0,0,3-2.07V10.3h-1.15a7.16,7.16,0,0,0-1.43.11ZM126.55.26A21.66,21.66,0,0,1,130,3.94l-1.73,1.72a18.42,18.42,0,0,0-3.41-3.83Zm6.12,3.08c-.95,0-1.34,0-1.87.07v-2a12.9,12.9,0,0,0,2.11.11h2.77A10.73,10.73,0,0,0,135.57,0H138a9,9,0,0,0-.11,1.52h3A14.56,14.56,0,0,0,143,1.41v2a16,16,0,0,0-1.87-.09h-3.26V4.4h4a15,15,0,0,0,2.07-.09v2a16,16,0,0,0-2.07-.09h-.88a14.51,14.51,0,0,1-.62,1.41h1.17a14.17,14.17,0,0,0,2-.09v2a16.15,16.15,0,0,0-2-.09h-3.61v1h3a14.18,14.18,0,0,0,1.89-.09v1.85a14,14,0,0,0-1.87-.09h-3.06v1h3.79a18.94,18.94,0,0,0,2.07-.08v1.91a16.31,16.31,0,0,0-2.05-.09h-3.81a11.23,11.23,0,0,0,.09,1.67h-2.4a12.39,12.39,0,0,0,.11-1.67h-3.36a15.92,15.92,0,0,0-2,.09V13.14a18.42,18.42,0,0,0,2,.08h3.36v-1h-2.79a14.09,14.09,0,0,0-1.89.09V10.43a13.9,13.9,0,0,0,1.89.09h2.79v-1h-3.14a15.51,15.51,0,0,0-2,.09v-2a13.74,13.74,0,0,0,2,.09h.74a9.25,9.25,0,0,0-.61-1.41h-.62a15.25,15.25,0,0,0-2.05.09v-2a14.52,14.52,0,0,0,2.05.09h3.63V3.34Zm2.15,2.91a10,10,0,0,1,.6,1.41h2.7a15.32,15.32,0,0,0,.6-1.41Z"
                  />
                  <path
                    d="M169.21,7.39l-.46,1.13a23,23,0,0,1-2.86,5.28,8.5,8.5,0,0,0,1.28,1.8,2.73,2.73,0,0,0,.94.77c.09,0,.18-.13.27-.4a9.43,9.43,0,0,0,.41-2.48,7,7,0,0,0,1.9,1.43c-.49,2.86-1.21,4.09-2.38,4.09s-2.77-1.43-4-3.41a19.67,19.67,0,0,1-5.11,3.72,6.62,6.62,0,0,0-1.6-1.85,15.67,15.67,0,0,0,5.67-3.89,26.29,26.29,0,0,1-1.58-8.41h-6.56c0,2.09,0,2.09,0,2.77H159a13.76,13.76,0,0,0,1.5-.06c-.05.44-.07.92-.11,1.78-.2,3.72-.35,4.93-.71,5.81s-1.1,1.16-2.7,1.16c-.55,0-.93,0-1.63-.08a7.36,7.36,0,0,0-.53-2.23,12.18,12.18,0,0,0,2.05.18c.64,0,.83-.13.94-.64a24.35,24.35,0,0,0,.31-3.85H155a31.38,31.38,0,0,1-.66,5.3,10.81,10.81,0,0,1-1.74,4.12,7.28,7.28,0,0,0-1.82-1.78,10.43,10.43,0,0,0,1.45-3.35A39.84,39.84,0,0,0,152.91,6a26.92,26.92,0,0,0-.11-3c.74.07,1.6.11,2.7.11h6.12l0-1.85a11.18,11.18,0,0,0-.11-1.12H164a12.32,12.32,0,0,0-.14,2v1h2.8a10.6,10.6,0,0,0-1.87-1.94l1.65-1a13.45,13.45,0,0,1,2.13,2.2l-1.12.77h.64A13.59,13.59,0,0,0,170.33,3V5.28a25.3,25.3,0,0,0-2.57-.11H164a28.11,28.11,0,0,0,1,6.29,14.17,14.17,0,0,0,2-4.92Z"
                  />
                </svg>
              </dd>
              <dd class="appeal__text__note">
                ※「ハイクラスが集まる不動産投資プラットフォーム」「ハイレベルな不動産会社から提案が来るプラットフォーム」「不動産投資プラットフォーム情報活用度」部門において（実施委託先：日本トレンドリサーチ（2019年11月実施：サイトのイメージ調査）
              </dd>
            </dl>
          </div>
        </div>
      </section>

      <section
        id="FEATURE"
        class="zero-content zero-content--feature"
        ref="feature"
        data-section-fade
      >
        <div class="zero-content__inner feature">
          <h1 class="zero-content__title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.63 14.24">
              <title>FEATURE</title>
              <path d="M0,14V0H9.66V2.74H3v3H9.2V8.5H3V14Z" />
              <path
                d="M11.87,0H22.21V2.74H14.85V5.52h7v2.7h-7v3.06h7.36V14H11.87Z"
              />
              <path
                d="M37.49,14H34.41l-1.12-2.76H27.87L26.77,14H23.69L29.17,0H32Zm-8.64-5.4h3.48L30.59,4Z"
              />
              <path d="M47.58,0V2.74h-4V14h-3V2.74h-4V0Z" />
              <path
                d="M58,0h3V7.8c0,4.28-2.28,6.44-5.88,6.44s-5.88-2.16-5.88-6.44V0h3V7.94c0,2,.9,3.58,2.92,3.58S58,10.16,58,7.9Z"
              />
              <path
                d="M69.08,9.52H66.69V14h-3V0h5.8c3,0,5.37,1.66,5.37,4.58A4.73,4.73,0,0,1,72.12,9L75.22,14H71.84ZM66.69,2.74V6.8H69c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
              />
              <path
                d="M77.29,0H87.63V2.74H80.27V5.52h7v2.7h-7v3.06h7.36V14H77.29Z"
              />
            </svg>
          </h1>
          <h2 class="zero-content__copy">
            資金は少なく、資産は大きく<br />
            はじめての不動産投資を<br class="sp" />ゼロからプロデュース
          </h2>
          <ul class="feature__horizontal-list">
            <li>
              <dl class="feature__item">
                <dt>
                  <h3>フルローンだからはじめやすい</h3>
                  <p>
                    独自の不動産投資スコアリングをもとに、提携金融機関からフルローン相当の融資条件を提供。少ない自己資金で始められる、無理のない不動産投資をコーディネートします。
                  </p>
                  <small
                    >※属性情報次第ではフルローンにならない可能性もあります。</small
                  >
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/feature-1.webp"
                    />
                    <img
                      src="@/assets/investor/zero/feature-1.png"
                      alt="フルローンだからはじめやすい"
                    />
                  </picture>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 277.92 23.86"
                  >
                    <path
                      d="M17.89.36V4.69L6.2,19H18.25V23.5H0V19.18L11.68,4.88H.1V.36Z"
                    />
                    <path
                      d="M25.33.36H42.39V4.88H30.25V9.47H41.83v4.46H30.25V19H42.39V23.5H25.33Z"
                    />
                    <path
                      d="M59,16.07H55.09V23.5H50.18V.36h9.57c5,0,8.84,2.74,8.84,7.56A7.81,7.81,0,0,1,64,15.15l5.11,8.35H63.58ZM55.09,4.88v6.7h3.8c3.3,0,4.82-1.18,4.82-3.4s-1.49-3.3-4.82-3.3Z"
                    />
                    <path
                      d="M86.24,0a11.72,11.72,0,0,1,12,11.92,11.93,11.93,0,1,1-23.86,0A11.63,11.63,0,0,1,86.24,0Zm.13,19.24c4.29,0,6.86-3.33,6.86-7.32s-2.77-7.3-6.86-7.3c-4.39,0-7,3.37-7,7.3S82,19.24,86.37,19.24Z"
                    />
                    <path
                      d="M150.65,9.29V9.22l-6-5.21h7.09l7.79,7.92-7.79,7.92h-7.09l6-5.21v-.07H129.33V9.29Z"
                    />
                    <path
                      d="M191.41,23.5V.36h15.94V4.88h-11v5h10.27v4.49H196.32V23.5Z"
                    />
                    <path
                      d="M228.36.36h4.88V13.23c0,7.07-3.76,10.63-9.7,10.63s-9.71-3.56-9.71-10.63V.36h4.89V13.47c0,3.3,1.49,5.9,4.82,5.9s4.82-2.24,4.82-6Z"
                    />
                    <path d="M241,.36h4.92V19H256.5V23.5H241Z" />
                    <path d="M262.44.36h4.92V19h10.56V23.5H262.44Z" />
                  </svg>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="feature__item">
                <dt>
                  <h3>利回り7〜8％の新築物件プラン</h3>
                  <p>
                    土地・不動産・工務店データベースから、建築家、プランナーまで、当社が保有する情報と人材のネットワークを駆使し、長期にわたり安定収益（利回り7-8％）が見込める新築一棟物件をプロデュースします。
                  </p>
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/feature-2.webp"
                    />
                    <img
                      src="@/assets/investor/zero/feature-2.png"
                      alt="利回り7〜8％の新築物件プラン"
                    />
                  </picture>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 279.65 23.86"
                  >
                    <title>ZERO → HIGH</title>
                    <path
                      d="M17.89.36V4.69L6.2,19H18.25V23.5H0V19.18L11.68,4.88H.1V.36Z"
                    />
                    <path
                      d="M25.33.36H42.39V4.88H30.25V9.47H41.83v4.46H30.25V19H42.39V23.5H25.33Z"
                    />
                    <path
                      d="M59,16.07H55.09V23.5H50.18V.36h9.57c5,0,8.84,2.74,8.84,7.56A7.81,7.81,0,0,1,64,15.15l5.11,8.35H63.58ZM55.09,4.88v6.7h3.8c3.3,0,4.82-1.18,4.82-3.39s-1.49-3.31-4.82-3.31Z"
                    />
                    <path
                      d="M86.24,0a11.72,11.72,0,0,1,12,11.92,11.93,11.93,0,1,1-23.86,0A11.63,11.63,0,0,1,86.24,0Zm.13,19.24c4.29,0,6.86-3.33,6.86-7.32s-2.77-7.3-6.86-7.3c-4.39,0-7,3.37-7,7.3S82,19.24,86.37,19.24Z"
                    />
                    <path
                      d="M150.65,9.29V9.23l-6-5.22h7.09l7.79,7.92-7.79,7.92h-7.09l6-5.21v-.07H129.33V9.29Z"
                    />
                    <path
                      d="M206,13.93h-9.71V23.5h-4.91V.36h4.91V9.44H206V.36h4.88V23.5H206Z"
                    />
                    <path d="M219,.36h4.88V23.5H219Z" />
                    <path
                      d="M243.12,10.63h9.41V19.9a13.39,13.39,0,0,1-9.6,4c-6.74,0-12.08-4.82-12.08-11.88,0-7.36,5.34-12,12.11-12a11.49,11.49,0,0,1,9.21,4.36l-3.3,3.36a7.45,7.45,0,0,0-6.14-3.13,6.89,6.89,0,0,0-6.83,7.29,7,7,0,0,0,7.09,7.4,7.93,7.93,0,0,0,4.72-1.39V15.15h-4.59Z"
                    />
                    <path
                      d="M274.76,13.93h-9.7V23.5h-4.92V.36h4.92V9.44h9.7V.36h4.89V23.5h-4.89Z"
                    />
                  </svg>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="feature__item">
                <dt>
                  <h3>全方位マネジメントで安心</h3>
                  <p>
                    不動産投資の最前線で活躍するスペシャリストを、専任のプロジェクトマネージャー（PM）としてアサイン。投資から建築、運用まで一貫して相談でき、投資初心者をフォローアップします。
                  </p>
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/feature-3.webp"
                    />
                    <img
                      src="@/assets/investor/zero/feature-3.png"
                      alt="全方位マネジメントで安心"
                    />
                  </picture>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 254.89 23.86"
                  >
                    <title>ZERO → FULL</title>
                    <path
                      d="M17.89.36V4.69L6.2,19H18.25V23.5H0V19.18L11.68,4.88H.1V.36Z"
                    />
                    <path
                      d="M25.33.36H42.39V4.88H30.25V9.47H41.83v4.46H30.25V19H42.39V23.5H25.33Z"
                    />
                    <path
                      d="M59,16.07H55.09V23.5H50.18V.36h9.57c5,0,8.84,2.74,8.84,7.56A7.81,7.81,0,0,1,64,15.15l5.11,8.35H63.58ZM55.09,4.88v6.7h3.8c3.3,0,4.82-1.18,4.82-3.4s-1.49-3.3-4.82-3.3Z"
                    />
                    <path
                      d="M86.24,0a11.72,11.72,0,0,1,12,11.92,11.93,11.93,0,1,1-23.86,0A11.63,11.63,0,0,1,86.24,0Zm.13,19.24c4.29,0,6.86-3.33,6.86-7.32s-2.77-7.3-6.86-7.3c-4.39,0-7,3.37-7,7.3S82,19.24,86.37,19.24Z"
                    />
                    <path
                      d="M150.65,9.29V9.22l-6-5.21h7.09l7.79,7.92-7.79,7.92h-7.09l6-5.21v-.07H129.33V9.29Z"
                    />
                    <path
                      d="M212.3,23.5h-5.09l-1.84-4.56h-9l-1.81,4.56h-5.09l9-23.14h4.68ZM198,14.59h5.74L200.91,7Z"
                    />
                    <path d="M218,.36h4.92V19h10.56V23.5H218Z" />
                    <path d="M239.41.36h4.92V19h10.56V23.5H239.41Z" />
                  </svg>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <section
        id="BENEFIT"
        class="zero-content zero-content--benefit"
        ref="benefit"
        data-section-fade
      >
        <div class="zero-content__inner benefit">
          <h1 class="zero-content__title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.93 14.02">
              <title>BENEFIT</title>
              <path
                d="M0,0H6.3c2.86,0,4.84,1.32,4.84,3.88A3.65,3.65,0,0,1,9.7,6.72a3.69,3.69,0,0,1,1.78,3.2c0,2.46-1.92,4.1-4.84,4.1H0ZM3,2.74V5.62H5.82c1.62,0,2.32-.5,2.32-1.44S7.38,2.74,5.7,2.74Zm3,8.54c1.54,0,2.52-.38,2.52-1.62S7.48,8.12,6,8.12H3v3.16Z"
              />
              <path
                d="M13.9,0H24.25V2.74H16.88V5.52h7v2.7h-7v3.06h7.37V14H13.9Z"
              />
              <path d="M30,4.56V14H27V0h3.58l5.66,9.5V0h3V14H35.68Z" />
              <path
                d="M42.19,0H52.53V2.74H45.17V5.52h7v2.7h-7v3.06h7.36V14H42.19Z"
              />
              <path d="M55.32,14V0H65V2.74H58.3v3h6.22V8.5H58.3V14Z" />
              <path d="M67.24,0h3V14h-3Z" />
              <path d="M82.93,0V2.74h-4V14H76V2.74h-4V0Z" />
            </svg>
          </h1>
          <h2 class="zero-content__copy">
            本業の収入以外の<br class="sp" />キャッシュエンジンを実現<br />
            未来の選択肢はグッと広がる
          </h2>
          <ul class="benefit__horizontal-list">
            <li>
              <dl class="benefit__item">
                <dt>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 61.56 14.46"
                  >
                    <title>START</title>
                    <path
                      d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                    />
                    <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                    <path
                      d="M36.46,14.24H33.38l-1.13-2.76H26.83l-1.1,2.76H22.65l5.48-14H31Zm-8.65-5.4h3.48L29.55,4.26Z"
                    />
                    <path
                      d="M43.34,9.74H41v4.5H38V.22h5.8c3,0,5.36,1.66,5.36,4.58a4.73,4.73,0,0,1-2.76,4.38l3.1,5.06H46.1ZM41,3V7h2.3c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
                    />
                    <path d="M61.56.22V3h-4V14.24h-3V3h-4V.22Z" />
                  </svg>
                </dt>
                <dd>
                  <h3>
                    新築一棟物件（利回り7〜8％）を<br />
                    頭金0円のフルローン※で
                  </h3>
                  <dl class="benefit__item__features">
                    <dt>
                      物件例: 新築一棟アパート<span
                        >（木造2階建て・12部屋）</span
                      >
                    </dt>
                    <dd>
                      <picture>
                        <source
                          type="image/webp"
                          srcset="@/assets/investor/zero/benefit-1.webp"
                        />
                        <img
                          src="@/assets/investor/zero/benefit-1.png"
                          alt="物件例: 新築一棟アパート（木造2階建て・12部屋）"
                        />
                      </picture>
                      <picture>
                        <source
                          type="image/webp"
                          srcset="@/assets/investor/zero/benefit-2.webp"
                        />
                        <img
                          src="@/assets/investor/zero/benefit-2.png"
                          alt="物件例: 新築一棟アパート（木造2階建て・12部屋）"
                        />
                      </picture>
                    </dd>
                  </dl>
                  <table class="benefit__item__table">
                    <tbody>
                      <tr>
                        <th>事業費</th>
                        <td>135,000,000円</td>
                      </tr>
                      <tr>
                        <th>年間収入</th>
                        <td>10,800,000円</td>
                      </tr>
                      <tr>
                        <th>表面利回り</th>
                        <td>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 49.34 17.35"
                          >
                            <title>8.00</title>
                            <path
                              d="M0,12.41a4.37,4.37,0,0,1,2.38-4,4.05,4.05,0,0,1-2-3.62C.41,1.9,2.74,0,6,0s5.54,1.9,5.54,4.8a4.05,4.05,0,0,1-2,3.62,4.37,4.37,0,0,1,2.38,4c0,3.05-2.47,4.94-5.95,4.94S0,15.46,0,12.41Zm2.88-.1c0,1.66,1.44,2.45,3.1,2.45S9.05,14,9.05,12.31,7.66,9.79,6,9.79,2.88,10.78,2.88,12.31Zm.41-7.39c0,1.39,1,2.26,2.69,2.26s2.66-.87,2.66-2.26A2.4,2.4,0,0,0,6,2.59,2.41,2.41,0,0,0,3.29,4.92Z"
                            />
                            <path
                              d="M16.72,13.92a1.72,1.72,0,1,1,0,3.43,1.72,1.72,0,1,1,0-3.43Z"
                            />
                            <path
                              d="M27.43,0c4.22,0,6.41,3.74,6.41,8.66s-2.19,8.69-6.41,8.69S21,13.58,21,8.66,23.18,0,27.43,0Zm0,2.59C25.1,2.59,23.9,5,23.9,8.66s1.2,6.1,3.53,6.1S31,12.36,31,8.66,29.76,2.59,27.43,2.59Z"
                            />
                            <path
                              d="M42.93,0c4.22,0,6.41,3.74,6.41,8.66s-2.19,8.69-6.41,8.69-6.41-3.77-6.41-8.69S38.68,0,42.93,0Zm0,2.59C40.6,2.59,39.4,5,39.4,8.66s1.2,6.1,3.53,6.1,3.53-2.4,3.53-6.1S45.25,2.59,42.93,2.59Z"
                            />
                          </svg>
                          <span>&nbsp;%</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </dd>
              </dl>
              <small
                >※属性情報次第ではフルローンにならない可能性もあります。</small
              >
            </li>
            <li>
              <dl class="benefit__item">
                <dt>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 93.12 14.46"
                  >
                    <title>PROCESS</title>
                    <path
                      d="M3,9.8v4.44H0V.22H5.68c3.26,0,5.48,1.78,5.48,4.7S8.94,9.8,5.68,9.8ZM3,3v4.1H5.16c2,0,3-.68,3-2.08s-1-2-3-2Z"
                    />
                    <path
                      d="M18.33,9.74H16v4.5H13V.22h5.8c3,0,5.36,1.66,5.36,4.58a4.73,4.73,0,0,1-2.76,4.38l3.1,5.06H21.09ZM16,3V7h2.3c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
                    />
                    <path
                      d="M32.91,0a7.1,7.1,0,0,1,7.26,7.22,7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,32.91,0ZM33,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,33,2.8a4.16,4.16,0,0,0-4.22,4.42A4.15,4.15,0,0,0,33,11.66Z"
                    />
                    <path
                      d="M54.86,11.82a7,7,0,0,1-5.7,2.64c-4.2,0-7.44-2.8-7.44-7.2A7.3,7.3,0,0,1,54.64,2.64l-2,2a4.5,4.5,0,0,0-3.72-1.9A4.18,4.18,0,0,0,44.78,7.2a4.18,4.18,0,0,0,4.3,4.48,4.53,4.53,0,0,0,3.8-2Z"
                    />
                    <path
                      d="M56.73.22H67.07V3H59.71V5.74h7v2.7h-7V11.5h7.36v2.74H56.73Z"
                    />
                    <path
                      d="M73.7,8.44c-2.62-.44-4.42-1.8-4.42-4.2C69.28,1.52,71.8,0,74.46,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,74.3,2.72c-1.26,0-2.06.56-2.06,1.38s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28a7.42,7.42,0,0,1-5.82-2.68l2-2.06a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                    />
                    <path
                      d="M86.48,8.44c-2.62-.44-4.42-1.8-4.42-4.2C82.06,1.52,84.58,0,87.24,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1a4.9,4.9,0,0,0-3.56-1.62C85.82,2.72,85,3.28,85,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28a7.42,7.42,0,0,1-5.82-2.68l2-2.06a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                    />
                  </svg>
                </dt>
                <dd>
                  <h3>
                    月々40万円の不労所得で<br />
                    長期的な安定収入を確保
                  </h3>

                  <table class="benefit__item__table">
                    <tbody>
                      <tr>
                        <th rowspan="2">月間収支</th>
                        <th>月間収入（月間賃料）</th>
                        <td>900,000円</td>
                      </tr>
                      <tr>
                        <th>月間経費</th>
                        <td>▲ 507,205円</td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <svg
                            class="with-comma"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 89.03 20.4"
                          >
                            <title>392,795</title>
                            <path
                              d="M11.88,12.27c0,3.31-2.66,5.08-6,5.08A7.62,7.62,0,0,1,0,14.67l1.94-1.95a5.1,5.1,0,0,0,4,2c1.77,0,3-1,3-2.45,0-1.63-1.37-2.47-3.61-2.47H4.18V7.2h1c2.35,0,3.46-1,3.46-2.45S7.25,2.59,5.88,2.59A4.53,4.53,0,0,0,2.26,4.42L.41,2.57A7.05,7.05,0,0,1,5.86,0c2.81,0,5.61,1.37,5.61,4.66a4.14,4.14,0,0,1-2,3.64A4.38,4.38,0,0,1,11.88,12.27Z"
                            />
                            <path
                              d="M26.6,8.83c0,5.12-2.59,8.52-6.84,8.52a6.24,6.24,0,0,1-4.89-2.16l1.84-1.89a4.06,4.06,0,0,0,3.12,1.46c2,0,3.94-1.63,4-5.28a4.89,4.89,0,0,1-3.86,1.68,5.35,5.35,0,0,1-5.5-5.57A5.46,5.46,0,0,1,20.22,0C24.39,0,26.6,3.48,26.6,8.83ZM23.07,5.62a2.89,2.89,0,0,0-3-3,2.71,2.71,0,0,0-2.79,3,2.75,2.75,0,0,0,2.91,2.95A2.76,2.76,0,0,0,23.07,5.62Z"
                            />
                            <path
                              d="M40.27,14.5v2.59H28.8v-.55c0-4,1.51-6.08,5.71-8.19l.43-.21c1.71-.84,2.5-1.64,2.5-3.15s-1.08-2.4-2.83-2.4a4.23,4.23,0,0,0-3.94,2.93L28.8,3.65A6.18,6.18,0,0,1,34.53,0c3,0,5.81,1.7,5.81,5.14,0,2.35-1.34,4-3.74,5.18l-1.47.75c-1.44.74-3,1.68-3.21,3.43Z"
                            />
                            <path d="M42.09,20.4l2.44-6h3l-2.81,6Z" />
                            <path
                              d="M48.82.26H60.56v2.4a22,22,0,0,0-5.81,13.47l-.07,1H51.77l.07-.94A24.34,24.34,0,0,1,57,2.86H51.58V5.81H48.82Z"
                            />
                            <path
                              d="M74.52,8.83c0,5.12-2.59,8.52-6.84,8.52a6.28,6.28,0,0,1-4.9-2.16l1.85-1.89a4.06,4.06,0,0,0,3.12,1.46c1.94,0,3.94-1.63,4-5.28a4.91,4.91,0,0,1-3.86,1.68,5.35,5.35,0,0,1-5.5-5.57A5.45,5.45,0,0,1,68.13,0C72.31,0,74.52,3.48,74.52,8.83ZM71,5.62a2.9,2.9,0,0,0-3-3,2.7,2.7,0,0,0-2.78,3,2.88,2.88,0,1,0,5.76,0Z"
                            />
                            <path
                              d="M83,8.57a3.18,3.18,0,0,0-3,1.89H77.44L77.85.26h9.84v2.6h-7.2L80.27,7a4.8,4.8,0,0,1,3.07-1A5.36,5.36,0,0,1,89,11.64c0,3.31-2.52,5.71-6.38,5.71a6.7,6.7,0,0,1-5.52-2.66l2-2a4.73,4.73,0,0,0,3.74,2,3.1,3.1,0,0,0,3.29-3.12A3,3,0,0,0,83,8.57Z"
                            />
                          </svg>
                          <span>&nbsp;円</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="benefit__item__table">
                    <tbody>
                      <tr>
                        <th rowspan="4">年間収支</th>
                        <th>年間収入<sup>※</sup></th>
                        <td>10,800,000円</td>
                      </tr>
                      <tr>
                        <th>年間経費</th>
                        <td>▲ 6,086,457円</td>
                      </tr>
                      <tr>
                        <th>固定資産税（概算）</th>
                        <td>▲ 900,000円</td>
                      </tr>
                      <tr>
                        <th>火災地震保険（概算）</th>
                        <td>▲ 60,000円</td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <svg
                            class="with-comma"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 109.13 20.4"
                          >
                            <title>3,753,543</title>
                            <path
                              d="M11.88,12.26c0,3.32-2.66,5.09-6,5.09A7.65,7.65,0,0,1,0,14.67l1.94-1.95a5.1,5.1,0,0,0,4,2c1.77,0,3-1,3-2.45,0-1.63-1.36-2.47-3.6-2.47H4.18V7.2h1c2.35,0,3.46-1,3.46-2.45S7.25,2.59,5.88,2.59A4.53,4.53,0,0,0,2.26,4.42L.41,2.57A7.05,7.05,0,0,1,5.86,0c2.8,0,5.61,1.37,5.61,4.66a4.14,4.14,0,0,1-2,3.64A4.38,4.38,0,0,1,11.88,12.26Z"
                            />
                            <path d="M13.47,20.4l2.45-6h3l-2.81,6Z" />
                            <path
                              d="M20.21.26H31.94v2.4a22,22,0,0,0-5.81,13.47l-.07,1h-2.9l.07-.94A24.3,24.3,0,0,1,28.34,2.86H23V5.81H20.21Z"
                            />
                            <path
                              d="M39.61,8.57a3.19,3.19,0,0,0-3,1.89H34L34.43.26h9.84v2.6h-7.2L36.85,7a4.83,4.83,0,0,1,3.08-1,5.36,5.36,0,0,1,5.68,5.66c0,3.31-2.52,5.71-6.38,5.71a6.7,6.7,0,0,1-5.52-2.66l2-2a4.76,4.76,0,0,0,3.75,2,3.09,3.09,0,0,0,3.28-3.12A3,3,0,0,0,39.61,8.57Z"
                            />
                            <path
                              d="M59.15,12.26c0,3.32-2.67,5.09-6,5.09a7.69,7.69,0,0,1-5.86-2.68l2-1.95a5.06,5.06,0,0,0,4,2c1.78,0,3-1,3-2.45,0-1.63-1.37-2.47-3.6-2.47H51.44V7.2h1c2.36,0,3.46-1,3.46-2.45s-1.37-2.16-2.74-2.16a4.53,4.53,0,0,0-3.62,1.83L47.67,2.57A7.07,7.07,0,0,1,53.12,0c2.81,0,5.62,1.37,5.62,4.66a4.16,4.16,0,0,1-2,3.64A4.4,4.4,0,0,1,59.15,12.26Z"
                            />
                            <path d="M60.73,20.4l2.45-6h3l-2.8,6Z" />
                            <path
                              d="M74.83,8.57a3.17,3.17,0,0,0-3,1.89h-2.6L69.64.26h9.84v2.6h-7.2L72.07,7a4.78,4.78,0,0,1,3.07-1,5.36,5.36,0,0,1,5.69,5.66c0,3.31-2.52,5.71-6.39,5.71a6.7,6.7,0,0,1-5.52-2.66l2-2a4.76,4.76,0,0,0,3.75,2A3.1,3.1,0,0,0,78,11.64,3,3,0,0,0,74.83,8.57Z"
                            />
                            <path
                              d="M93.26,13.39v3.7H90.38v-3.7H82.7V11.06L90.6.26h2.66V10.8h2.33v2.59ZM90.38,10.8V4.42L85.82,10.8Z"
                            />
                            <path
                              d="M109.13,12.26c0,3.32-2.67,5.09-6,5.09a7.65,7.65,0,0,1-5.85-2.68l1.94-1.95a5.08,5.08,0,0,0,4,2c1.78,0,3-1,3-2.45,0-1.63-1.37-2.47-3.6-2.47h-1.25V7.2h1c2.35,0,3.45-1,3.45-2.45s-1.37-2.16-2.73-2.16A4.53,4.53,0,0,0,99.5,4.42L97.65,2.57A7.09,7.09,0,0,1,103.1,0c2.81,0,5.62,1.37,5.62,4.66a4.16,4.16,0,0,1-2,3.64A4.4,4.4,0,0,1,109.13,12.26Z"
                            />
                          </svg>
                          <span>&nbsp;円</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </dd>
              </dl>
              <small>※稼働率100％想定</small>
            </li>
            <li>
              <dl class="benefit__item">
                <dt>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.48 14.46"
                  >
                    <title>GOAL</title>
                    <path
                      d="M7.44,6.44h5.7v5.62a8.12,8.12,0,0,1-5.82,2.4A7.05,7.05,0,0,1,0,7.26,7,7,0,0,1,7.34,0a7,7,0,0,1,5.58,2.64l-2,2A4.5,4.5,0,0,0,7.2,2.78,4.18,4.18,0,0,0,3.06,7.2a4.24,4.24,0,0,0,4.3,4.48,4.82,4.82,0,0,0,2.86-.84V9.18H7.44Z"
                    />
                    <path
                      d="M22.27,0a7.1,7.1,0,0,1,7.26,7.22,7.09,7.09,0,0,1-7.26,7.24,7.05,7.05,0,0,1-7.21-7.24A7.06,7.06,0,0,1,22.27,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,22.35,2.8a4.17,4.17,0,0,0-4.23,4.42A4.16,4.16,0,0,0,22.35,11.66Z"
                    />
                    <path
                      d="M43.57,14.24H40.49l-1.12-2.76H34l-1.1,2.76H29.77l5.48-14h2.84Zm-8.64-5.4h3.48L36.67,4.26Z"
                    />
                    <path d="M45.1.22h3V11.5h6.4v2.74H45.1Z" />
                  </svg>
                </dt>
                <dd>
                  <h3>
                    さまざまなライフステージで<br />
                    自分らしく輝くための資産をその手に
                  </h3>

                  <dl class="benefit__item__goals">
                    <dt>開業費に</dt>
                    <dd>
                      <p class="benefit__item__goals__term">
                        <strong>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 12.89 16.83"
                          >
                            <title>4</title>
                            <path
                              d="M10.56,13.13v3.7H7.68v-3.7H0V10.8L7.9,0h2.66V10.54h2.33v2.59ZM7.68,10.54V4.15L3.12,10.54Z"
                            /></svg
                          >年
                        </strong>
                        <span>運用</span>
                      </p>
                      <p class="benefit__item__goals__revenue">
                        <b>収益総額<sup>※</sup></b>
                        <strong
                          ><span>約</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 63.8 20.4"
                          >
                            <title>1,000</title>
                            <path
                              d="M4.18,14.5V3.86A7,7,0,0,1,0,5.09V2.57C1.7,2.5,3.65,1.9,4.44.26H7V14.5h4v2.59H0V14.5Z"
                            />
                            <path d="M12.44,20.4l2.45-6h3l-2.81,6Z" />
                            <path
                              d="M26.4,0c4.22,0,6.41,3.75,6.41,8.67s-2.19,8.68-6.41,8.68S20,13.59,20,8.67,22.15,0,26.4,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.53-2.4,3.53-6.09S28.73,2.59,26.4,2.59Z"
                            />
                            <path
                              d="M41.89,0c4.23,0,6.41,3.75,6.41,8.67s-2.18,8.68-6.41,8.68-6.4-3.76-6.4-8.68S37.65,0,41.89,0Zm0,2.59c-2.32,0-3.52,2.38-3.52,6.08s1.2,6.09,3.52,6.09,3.53-2.4,3.53-6.09S44.22,2.59,41.89,2.59Z"
                            />
                            <path
                              d="M57.39,0c4.23,0,6.41,3.75,6.41,8.67s-2.18,8.68-6.41,8.68S51,13.59,51,8.67,53.14,0,57.39,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.53-2.4,3.53-6.09S59.72,2.59,57.39,2.59Z"
                            />
                          </svg>
                          <span>万円</span></strong
                        >
                      </p>
                    </dd>
                  </dl>
                  <dl class="benefit__item__goals">
                    <dt>子育て費に</dt>
                    <dd>
                      <p class="benefit__item__goals__term">
                        <strong>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 11.74 16.83"
                          >
                            <title>7</title>
                            <path
                              d="M0,0H11.74V2.4A21.86,21.86,0,0,0,5.93,15.87l-.07,1H3L3,15.89A24.34,24.34,0,0,1,8.14,2.59H2.76v3H0Z"
                            /></svg
                          >年
                        </strong>
                        <span>運用</span>
                      </p>
                      <p class="benefit__item__goals__revenue">
                        <b>収益総額</b>
                        <strong
                          ><span>約</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64.65 20.4"
                          >
                            <title>2,000</title>
                            <path
                              d="M11.47,14.5v2.59H0v-.55c0-4,1.51-6.08,5.71-8.19l.43-.21C7.85,7.3,8.64,6.5,8.64,5s-1.08-2.4-2.83-2.4A4.25,4.25,0,0,0,1.87,5.52L0,3.65A6.2,6.2,0,0,1,5.74,0c3,0,5.8,1.7,5.8,5.14,0,2.35-1.34,4-3.74,5.18l-1.46.75c-1.44.74-3,1.68-3.22,3.43Z"
                            />
                            <path d="M13.29,20.4l2.45-6h3l-2.81,6Z" />
                            <path
                              d="M27.25,0c4.22,0,6.4,3.75,6.4,8.67s-2.18,8.68-6.4,8.68-6.41-3.76-6.41-8.68S23,0,27.25,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.52-2.4,3.52-6.09S29.57,2.59,27.25,2.59Z"
                            />
                            <path
                              d="M42.74,0C47,0,49.15,3.75,49.15,8.67S47,17.35,42.74,17.35s-6.41-3.76-6.41-8.68S38.49,0,42.74,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.53-2.4,3.53-6.09S45.07,2.59,42.74,2.59Z"
                            />
                            <path
                              d="M58.24,0c4.22,0,6.41,3.75,6.41,8.67s-2.19,8.68-6.41,8.68-6.41-3.76-6.41-8.68S54,0,58.24,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.53-2.4,3.53-6.09S60.57,2.59,58.24,2.59Z"
                            />
                          </svg>
                          <span>万円</span></strong
                        >
                      </p>
                    </dd>
                  </dl>
                  <dl class="benefit__item__goals">
                    <dt>老後の<br class="sp" />準備金に</dt>
                    <dd>
                      <p class="benefit__item__goals__term">
                        <strong>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 25.76 17.35"
                          >
                            <title>10</title>
                            <path
                              d="M4.18,14.5V3.86A7,7,0,0,1,0,5.09V2.57C1.7,2.5,3.65,1.9,4.44.26H7V14.5h4v2.59H0V14.5Z"
                            />
                            <path
                              d="M19.35,0c4.23,0,6.41,3.75,6.41,8.67s-2.18,8.68-6.41,8.68S13,13.59,13,8.67,15.11,0,19.35,0Zm0,2.59C17,2.59,15.83,5,15.83,8.67s1.2,6.09,3.52,6.09,3.53-2.4,3.53-6.09S21.68,2.59,19.35,2.59Z"
                            /></svg
                          >年
                        </strong>
                        <span>運用</span>
                      </p>
                      <p class="benefit__item__goals__revenue">
                        <b>収益総額</b>
                        <strong
                          ><span>約</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64.83 20.4"
                          >
                            <title>3,000</title>
                            <path
                              d="M11.88,12.27c0,3.31-2.66,5.08-6,5.08A7.62,7.62,0,0,1,0,14.67l1.94-1.95a5.1,5.1,0,0,0,4,2c1.77,0,3-1,3-2.45,0-1.63-1.36-2.47-3.6-2.47H4.18V7.2h1c2.35,0,3.46-1,3.46-2.45S7.25,2.59,5.88,2.59A4.53,4.53,0,0,0,2.26,4.42L.41,2.57A7.05,7.05,0,0,1,5.86,0c2.8,0,5.61,1.37,5.61,4.66a4.14,4.14,0,0,1-2,3.64A4.38,4.38,0,0,1,11.88,12.27Z"
                            />
                            <path d="M13.47,20.4l2.45-6h3l-2.81,6Z" />
                            <path
                              d="M27.43,0c4.22,0,6.41,3.75,6.41,8.67s-2.19,8.68-6.41,8.68S21,13.59,21,8.67,23.18,0,27.43,0Zm0,2.59C25.1,2.59,23.9,5,23.9,8.67s1.2,6.09,3.53,6.09S31,12.36,31,8.67,29.75,2.59,27.43,2.59Z"
                            />
                            <path
                              d="M42.92,0c4.23,0,6.41,3.75,6.41,8.67s-2.18,8.68-6.41,8.68-6.41-3.76-6.41-8.68S38.67,0,42.92,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09,3.53-2.4,3.53-6.09S45.25,2.59,42.92,2.59Z"
                            />
                            <path
                              d="M58.42,0c4.22,0,6.41,3.75,6.41,8.67s-2.19,8.68-6.41,8.68S52,13.59,52,8.67,54.17,0,58.42,0Zm0,2.59c-2.33,0-3.53,2.38-3.53,6.08s1.2,6.09,3.53,6.09S62,12.36,62,8.67,60.75,2.59,58.42,2.59Z"
                            />
                          </svg>
                          <span>万円</span></strong
                        >
                      </p>
                    </dd>
                  </dl>
                </dd>
              </dl>
              <small
                >※家賃収入からローン返済（元金・利息）、各種経費（管理費・清掃費・光熱費・火災地震保険・
                固定資産税など）を差し引いた税引き前の収益の総額 ※家賃下落率:
                5年目以降1％/年を前提にした計算 ※入居率:
                1％/年の下落を前提にした計算</small
              >
            </li>
          </ul>
        </div>
      </section>

      <zero-conversion-area
        @click="$emit('onConversionClick', $event)"
        data-section-fade
      />

      <section
        id="FLOW"
        class="zero-content zero-content--flow"
        ref="flow"
        data-section-fade
      >
        <div class="zero-content__inner flow">
          <div class="flow__keyVisulal">
            <h1 class="zero-content__title">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.64 14.46">
                <title>FLOW</title>
                <path d="M0,14.24V.22H9.66V3H3V6H9.2V8.72H3v5.52Z" />
                <path d="M11.87.22h3V11.5h6.4v2.74H11.87Z" />
                <path
                  d="M29.29,0a7.1,7.1,0,0,1,7.26,7.22,7.09,7.09,0,0,1-7.26,7.24,7,7,0,0,1-7.21-7.24A7.05,7.05,0,0,1,29.29,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,29.37,2.8a4.16,4.16,0,0,0-4.23,4.42A4.15,4.15,0,0,0,29.37,11.66Z"
                />
                <path
                  d="M42.59,9.2l2.26-9H48l2.26,9,2.17-9h3.18l-3.74,14h-3L46.45,5,44,14.24H41L37.25.22h3.18Z"
                />
              </svg>
            </h1>
            <h2 class="zero-content__copy">
              StockFormer-ZEROなら安心・安全<br />
              新築一棟物件の入手をトータルサポート
            </h2>
            <picture class="flow__keyVisulal__image">
              <source
                type="image/webp"
                srcset="@/assets/investor/zero/flow-1.webp"
              />
              <img
                src="@/assets/investor/zero/flow-1.png"
                alt=" StockFormer-ZEROなら安心・安全。新築一棟物件の入手をトータルサポート"
              />
            </picture>
          </div>
          <p class="flow__text">
            土地の購入や一棟物件の建築には、様々なリスクが伴います。土壌汚染・埋設物の処理、工事中止のトラブル、建築費の増加、建築途中のつなぎ融資対策など…。これらに個人で対応するのは難易度が高く、投資をためらう原因のひとつとなっていました。StockFormer-ZEROは、このような課題を独自のICT技術で解決。保有するデータベースから最適な土地・不動産会社・施工会社・建材メーカーを選定。投資家のリスクを軽減し、安心・安全な一棟投資をお手伝いします。
          </p>
          <ul class="flow__vertical-list">
            <li data-section-fade>
              <dl class="flow__item">
                <dt>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66.53 14.46"
                    >
                      <title>STEP 1</title>
                      <path
                        d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                      />
                      <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                      <path
                        d="M25.27.22H35.62V3H28.25V5.74h7v2.7h-7V11.5h7.37v2.74H25.27Z"
                      />
                      <path
                        d="M41.39,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S47.35,9.8,44.09,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                      />
                      <path
                        d="M60.27,11.5V3.92a6.48,6.48,0,0,1-3.42.88V2.14c1.42-.06,3-.56,3.7-1.92h2.68V11.5h3.3v2.74H56.85V11.5Z"
                      />
                    </svg>
                  </span>
                  <strong>診断・シミュレーション</strong>
                </dt>
                <dd>
                  <h3>
                    独自のスコアリング技術で<br />一棟投資の可能性を数値化
                  </h3>
                  <p>
                    資産、収入などの属性情報から、フルローンが見込める投資可能額を診断。自己資金や融資条件の面であきらめていた、一棟物件投資の可能性を見える化します。
                  </p>
                </dd>
              </dl>
            </li>
            <li data-section-fade>
              <dl class="flow__item">
                <dt>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66.53 14.46"
                    >
                      <title>STEP 2</title>
                      <path
                        d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                      />
                      <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                      <path
                        d="M25.27.22H35.62V3H28.25V5.74h7v2.7h-7V11.5h7.37v2.74H25.27Z"
                      />
                      <path
                        d="M41.39,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S47.35,9.8,44.09,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                      />
                      <path
                        d="M66.75,11.5v2.74h-10v-.46c0-3.3,1.08-5.42,4.58-7.18l.78-.38c1.5-.76,1.74-1.4,1.74-2,0-.84-.72-1.48-1.94-1.48A3.62,3.62,0,0,0,58.65,5L56.73,3a5.56,5.56,0,0,1,5-3c2.6,0,5.06,1.42,5.06,4.16,0,2.2-1.34,3.42-3.34,4.44l-1.22.62c-1.2.62-1.92,1.18-2.12,2.28Z"
                      />
                    </svg>
                  </span>
                  <strong>プランニング</strong>
                </dt>
                <dd>
                  <h3>
                    ICT活用と専任サポートで<br />利回り7〜8％の物件・収益プランをご提案
                  </h3>
                  <p>
                    独自のデータベースを活用し、好立地・高入居率の物件プラン、コストを抑えた施工プランをご提案。専任のプロジェクトマネージャー（PM）が、安定した収益（利回り7〜8％）、先々の売却（出口戦略）を見据えた収益計画をご提示します。
                  </p>
                </dd>
              </dl>
            </li>
            <li data-section-fade>
              <dl class="flow__item">
                <dt>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66.53 14.46"
                    >
                      <title>STEP 3</title>
                      <path
                        d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                      />
                      <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                      <path
                        d="M25.27.22H35.62V3H28.25V5.74h7v2.7h-7V11.5h7.37v2.74H25.27Z"
                      />
                      <path
                        d="M41.39,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S47.35,9.8,44.09,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                      />
                      <path
                        d="M66.43,10.22c0,2.76-2.36,4.24-5.28,4.24a6.61,6.61,0,0,1-5-2.24l2-2a3.83,3.83,0,0,0,3.1,1.54c1.26,0,2.2-.56,2.2-1.6s-.8-1.64-2.66-1.64H59.65V5.74h.94c1.84,0,2.52-.64,2.52-1.58s-.7-1.44-1.84-1.44a3.25,3.25,0,0,0-2.82,1.36L56.51,2.14A6.19,6.19,0,0,1,61.19,0c2.46,0,4.9,1.14,4.9,3.88a3.4,3.4,0,0,1-1.56,3A3.58,3.58,0,0,1,66.43,10.22Z"
                      />
                    </svg>
                  </span>
                  <strong>ファイナンス</strong>
                </dt>
                <dd>
                  <h3>
                    最大35年のフルローンで<br />少ない自己資金での取り組みが可能
                  </h3>
                  <p>
                    ご自身の属性情報と物件の担保価値を適切に組み合わせることで、最大融資期間35年のフルローンファイナンスが可能な融資条件を提携金融機関から提供。少ない自己資金、無理のない返済で投資に取り組めます。
                  </p>
                </dd>
              </dl>
            </li>
            <li data-section-fade>
              <dl class="flow__item">
                <dt>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66.53 14.46"
                    >
                      <title>STEP 4</title>
                      <path
                        d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                      />
                      <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                      <path
                        d="M25.27.22H35.62V3H28.25V5.74h7v2.7h-7V11.5h7.37v2.74H25.27Z"
                      />
                      <path
                        d="M41.39,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S47.35,9.8,44.09,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                      />
                      <path
                        d="M65.53,11.28v3h-3v-3h-6.3V8.84l6-8.62h3.26V8.66h1.94v2.62Zm-3-2.62V4l-3.22,4.7Z"
                      />
                    </svg>
                  </span>
                  <strong>設計・建築</strong>
                </dt>
                <dd>
                  <h3>
                    高付加価値の新築プロデュースで<br />入居率＆収益性の高い一棟を実現
                  </h3>
                  <p>
                    実績のあるプランナーが設計・デザインを監修し、ハイスペックな設備を採用した建物を建築。入居者から選ばれやすく、より高い家賃設定を可能にするだけでなく、資産価値も落ちにくいので先々の売却も安心です。
                  </p>
                </dd>
              </dl>
            </li>
            <li data-section-fade>
              <dl class="flow__item">
                <dt>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 66.53 14.46"
                    >
                      <title>STEP 5</title>
                      <path
                        d="M4.76,8.44C2.14,8,.34,6.64.34,4.24.34,1.52,2.86,0,5.52,0a7.34,7.34,0,0,1,5.4,2.24l-2,2.1A4.9,4.9,0,0,0,5.36,2.72C4.1,2.72,3.3,3.28,3.3,4.1s.52,1.22,2,1.48l1.38.22c3.08.5,4.72,1.9,4.72,4.38s-2.2,4.28-5.58,4.28A7.42,7.42,0,0,1,0,11.78L2,9.72a4.91,4.91,0,0,0,4,2c1.56,0,2.44-.66,2.44-1.52,0-.7-.52-1.22-2-1.48Z"
                      />
                      <path d="M23.58.22V3h-4V14.24h-3V3h-4V.22Z" />
                      <path
                        d="M25.27.22H35.62V3H28.25V5.74h7v2.7h-7V11.5h7.37v2.74H25.27Z"
                      />
                      <path
                        d="M41.39,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S47.35,9.8,44.09,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
                      />
                      <path
                        d="M61.85,7.5a2.18,2.18,0,0,0-2.12,1.34H57L57.33.22H66V3H60.11L60,5.54a4,4,0,0,1,2.34-.64,4.59,4.59,0,0,1,4.78,4.62c0,3-2.32,4.94-5.54,4.94a6,6,0,0,1-4.84-2.22l2.12-2.1a3.54,3.54,0,0,0,2.94,1.6,2.15,2.15,0,0,0,2.36-2.06A2.18,2.18,0,0,0,61.85,7.5Z"
                      />
                    </svg>
                  </span>
                  <strong>引渡し・決済</strong>
                </dt>
                <dd>
                  <h3>
                    建築完了後の決済で物件を入手<br />キャッシュエンジンとして運用開始
                  </h3>
                  <p>
                    フルローンファイナンスは、物件の施工・管理の準備が完了したタイミングで実行。建築後の運用に関するご相談も、プロジェクトマネージャー（PM）が常時サポート。キャッシュフローの健全化をお手伝いします。
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <section
        id="ARCHIVE"
        class="zero-content zero-content--archive"
        ref="archive"
        data-section-fade
      >
        <div class="zero-content__inner archive">
          <h1 class="zero-content__title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.03 14.46">
              <title>PROJECT ARCHIVE</title>
              <path
                d="M3,9.8v4.44H0V.22H5.68c3.26,0,5.48,1.78,5.48,4.7S8.94,9.8,5.68,9.8ZM3,3v4.1H5.16c2,0,3-.68,3-2.08s-1-2-3-2Z"
              />
              <path
                d="M18.33,9.74H16v4.5H13V.22h5.8c3,0,5.36,1.66,5.36,4.58a4.73,4.73,0,0,1-2.76,4.38l3.1,5.06H21.09ZM16,3V7h2.3c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
              />
              <path
                d="M32.91,0a7.1,7.1,0,0,1,7.26,7.22,7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,32.91,0ZM33,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,33,2.8a4.16,4.16,0,0,0-4.22,4.42A4.15,4.15,0,0,0,33,11.66Z"
              />
              <path
                d="M42.55,10.3a2.25,2.25,0,0,0,2,1.44c1.18,0,1.92-.8,1.92-2.2V.22h3v9.2c0,3.3-1.8,5-4.8,5a4.45,4.45,0,0,1-4.06-2.18Z"
              />
              <path
                d="M52.21.22H62.55V3H55.19V5.74h7v2.7h-7V11.5h7.36v2.74H52.21Z"
              />
              <path
                d="M77.58,11.82a7,7,0,0,1-5.71,2.64c-4.2,0-7.44-2.8-7.44-7.2A7.3,7.3,0,0,1,77.35,2.64l-2,2a4.5,4.5,0,0,0-3.72-1.9A4.18,4.18,0,0,0,67.49,7.2a4.18,4.18,0,0,0,4.3,4.48,4.53,4.53,0,0,0,3.8-2Z"
              />
              <path d="M89.82.22V3h-4V14.24h-3V3h-4V.22Z" />
              <path
                d="M110,14.24H106.9l-1.12-2.76h-5.42l-1.1,2.76H96.18l5.48-14h2.84Zm-8.65-5.4h3.48l-1.74-4.58Z"
              />
              <path
                d="M116.87,9.74h-2.38v4.5h-3V.22h5.8c3,0,5.36,1.66,5.36,4.58a4.72,4.72,0,0,1-2.76,4.38l3.1,5.06h-3.38ZM114.49,3V7h2.3c2,0,2.92-.72,2.92-2.06s-.9-2-2.92-2Z"
              />
              <path
                d="M137.39,11.82a7,7,0,0,1-5.7,2.64c-4.2,0-7.44-2.8-7.44-7.2a7.3,7.3,0,0,1,12.92-4.62l-2,2a4.5,4.5,0,0,0-3.72-1.9,4.18,4.18,0,0,0-4.14,4.42,4.18,4.18,0,0,0,4.3,4.48,4.53,4.53,0,0,0,3.8-2Z"
              />
              <path d="M148.12,8.44h-5.88v5.8h-3V.22h3v5.5h5.88V.22h3v14h-3Z" />
              <path d="M154.07.22h3v14h-3Z" />
              <path d="M168.18.22h3l-4.8,14h-3.08l-4.8-14h3l3.34,10.16Z" />
              <path
                d="M172.69.22H183V3h-7.36V5.74h7v2.7h-7V11.5H183v2.74H172.69Z"
              />
            </svg>
          </h1>
          <ul class="archive__list">
            <li>
              <dl class="archive__item">
                <dt>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 201.29 17.35"
                    >
                      <title>KUGENUMA 2021</title>
                      <path
                        d="M0,.26H3.58V7.13L9,.26h4.25L7.49,7.46l6.31,9.63H9.7L5.21,10.23,3.58,12.31v4.78H0Z"
                      />
                      <path
                        d="M25.9.26h3.55V9.62c0,5.14-2.74,7.73-7.06,7.73s-7-2.59-7-7.73V.26h3.55V9.79c0,2.4,1.08,4.3,3.5,4.3s3.51-1.63,3.51-4.34Z"
                      />
                      <path
                        d="M40.77,7.73h6.84v6.74a9.74,9.74,0,0,1-7,2.88,8.46,8.46,0,0,1-8.79-8.64C31.84,3.36,35.73,0,40.65,0a8.34,8.34,0,0,1,6.7,3.17L45,5.62a5.37,5.37,0,0,0-4.46-2.28,5,5,0,0,0-5,5.3A5.09,5.09,0,0,0,40.68,14a5.75,5.75,0,0,0,3.43-1V11H40.77Z"
                      />
                      <path
                        d="M50.81.26H63.22V3.55H54.39V6.89h8.42v3.24H54.39V13.8h8.83v3.29H50.81Z"
                      />
                      <path
                        d="M70.12,5.74V17.09H66.54V.26h4.3l6.79,11.41V.26h3.55V17.09H76.91Z"
                      />
                      <path
                        d="M95.07.26h3.55V9.62c0,5.14-2.73,7.73-7.05,7.73s-7.06-2.59-7.06-7.73V.26h3.55V9.79c0,2.4,1.08,4.3,3.51,4.3s3.5-1.63,3.5-4.34Z"
                      />
                      <path
                        d="M105.52,7.54v9.55h-3.58V.26h3.58l4.77,9.44,4.8-9.44h3.56V17.09h-3.56V7.54l-3.21,6.14h-3.17Z"
                      />
                      <path
                        d="M137,17.09h-3.69L132,13.78h-6.5l-1.32,3.31h-3.7L127,.26h3.41Zm-10.37-6.48h4.18l-2.09-5.5Z"
                      />
                      <path
                        d="M157.23,13.8v3.29h-12v-.55c0-4,1.29-6.51,5.49-8.62l.94-.46c1.8-.91,2.09-1.67,2.09-2.42,0-1-.87-1.78-2.33-1.78A4.35,4.35,0,0,0,147.51,6l-2.3-2.3a6.66,6.66,0,0,1,6-3.65c3.12,0,6.07,1.7,6.07,5,0,2.64-1.61,4.11-4,5.33l-1.46.75c-1.44.74-2.3,1.41-2.54,2.73Z"
                      />
                      <path
                        d="M166.26,0c4.22,0,6.67,3.6,6.67,8.67s-2.45,8.68-6.67,8.68-6.68-3.62-6.68-8.68S162,0,166.26,0Zm0,3.26c-2.07,0-3.12,2.12-3.12,5.41s1.05,5.42,3.12,5.42,3.12-2.14,3.12-5.42S168.3,3.26,166.26,3.26Z"
                      />
                      <path
                        d="M186.89,13.8v3.29h-12v-.55c0-4,1.3-6.51,5.5-8.62l.93-.46c1.8-.91,2.09-1.67,2.09-2.42,0-1-.86-1.78-2.33-1.78A4.35,4.35,0,0,0,177.17,6l-2.3-2.3a6.68,6.68,0,0,1,6-3.65C184,0,187,1.7,187,5c0,2.64-1.61,4.11-4,5.33l-1.47.75c-1.44.74-2.3,1.41-2.54,2.73Z"
                      />
                      <path
                        d="M193.78,13.8V4.71a7.82,7.82,0,0,1-4.1,1V2.57c1.7-.07,3.65-.67,4.44-2.31h3.21V13.8h4v3.29H189.68V13.8Z"
                      />
                    </svg>
                  </h3>
                  <p>
                    江ノ島電鉄「鵠沼駅」「柳小路駅」徒歩5分<br />
                    木造2階建て（12部屋）<br />
                    価格: 13,500万円（自己資金0円）<br />
                    利回り: 8.00%
                  </p>
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/kugenuma-2.webp"
                    />
                    <img
                      src="@/assets/investor/zero/kugenuma-2.png"
                      alt="KUGENUMA 2021"
                    />
                  </picture>
                </dd>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/kugenuma-1.webp"
                    />
                    <img
                      src="@/assets/investor/zero/kugenuma-1.png"
                      alt="KUGENUMA 2021"
                    />
                  </picture>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="archive__item">
                <dt>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 165.88 17.35"
                    >
                      <title>TSUJIDO 2021</title>
                      <path d="M13.2.26V3.55H8.38V17.09H4.82V3.55H0V.26Z" />
                      <path
                        d="M20.25,10.13c-3.14-.53-5.31-2.16-5.31-5C14.94,1.82,18,0,21.16,0a8.79,8.79,0,0,1,6.48,2.69l-2.4,2.52A5.85,5.85,0,0,0,21,3.26c-1.51,0-2.47.68-2.47,1.66,0,.82.62,1.46,2.4,1.78L22.55,7c3.7.6,5.67,2.28,5.67,5.26s-2.64,5.13-6.7,5.13a8.89,8.89,0,0,1-7-3.21L17,11.67a5.88,5.88,0,0,0,4.75,2.42c1.87,0,2.93-.79,2.93-1.82,0-.84-.63-1.47-2.36-1.78Z"
                      />
                      <path
                        d="M41.06.26h3.55V9.62c0,5.14-2.74,7.73-7.06,7.73s-7-2.59-7-7.73V.26h3.55V9.79c0,2.4,1.08,4.3,3.5,4.3s3.51-1.63,3.51-4.34Z"
                      />
                      <path
                        d="M48.09,12.36a2.7,2.7,0,0,0,2.38,1.73c1.41,0,2.3-1,2.3-2.64V.26h3.55V11.31c0,4-2.16,6-5.76,6a5.33,5.33,0,0,1-4.87-2.61Z"
                      />
                      <path d="M59.73.26h3.55V17.09H59.73Z" />
                      <path
                        d="M66.81.26h5.55c4.54,0,9.38,2.21,9.38,8.31s-4.84,8.52-9.38,8.52H66.81Zm3.58,3.29V13.8h1.44c3.58,0,6.29-1.32,6.29-5.23s-2.71-5-6.31-5Z"
                      />
                      <path
                        d="M92.2,0a8.52,8.52,0,0,1,8.71,8.67,8.68,8.68,0,1,1-17.35,0A8.46,8.46,0,0,1,92.2,0Zm.1,14c3.12,0,5-2.42,5-5.32a5.07,5.07,0,0,0-5-5.31,5,5,0,0,0-5.07,5.31C87.23,11.57,89.1,14,92.3,14Z"
                      />
                      <path
                        d="M121.82,13.8v3.29h-12v-.55c0-4,1.3-6.51,5.5-8.62l.93-.46c1.8-.91,2.09-1.67,2.09-2.42,0-1-.86-1.78-2.33-1.78A4.34,4.34,0,0,0,112.1,6l-2.31-2.3a6.69,6.69,0,0,1,6-3.65c3.12,0,6.07,1.7,6.07,5,0,2.64-1.61,4.11-4,5.33l-1.46.75c-1.44.74-2.31,1.41-2.55,2.73Z"
                      />
                      <path
                        d="M130.84,0c4.23,0,6.67,3.6,6.67,8.67s-2.44,8.68-6.67,8.68-6.67-3.62-6.67-8.68S126.59,0,130.84,0Zm0,3.26c-2.06,0-3.12,2.12-3.12,5.41s1.06,5.42,3.12,5.42S134,12,134,8.67,132.88,3.26,130.84,3.26Z"
                      />
                      <path
                        d="M151.48,13.8v3.29h-12v-.55c0-4,1.3-6.51,5.5-8.62l.94-.46C147.69,6.55,148,5.79,148,5c0-1-.87-1.78-2.33-1.78A4.35,4.35,0,0,0,141.76,6l-2.31-2.3a6.69,6.69,0,0,1,6-3.65c3.12,0,6.07,1.7,6.07,5,0,2.64-1.61,4.11-4,5.33l-1.46.75c-1.44.74-2.3,1.41-2.54,2.73Z"
                      />
                      <path
                        d="M158.37,13.8V4.71a7.86,7.86,0,0,1-4.11,1V2.57C156,2.5,157.91,1.9,158.7.26h3.22V13.8h4v3.29H154.26V13.8Z"
                      />
                    </svg>
                  </h3>
                  <p>
                    東海道本線「辻堂駅」徒歩8分<br />
                    木造2階建て（20部屋）<br />
                    価格: 24,500万円（自己資金0円）<br />
                    利回り: 8.03％
                  </p>
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/tsujido-2.webp"
                    />
                    <img
                      src="@/assets/investor/zero/tsujido-2.png"
                      alt="TSUJIDO 2021"
                    />
                  </picture>
                </dd>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/tsujido-1.webp"
                    />
                    <img
                      src="@/assets/investor/zero/tsujido-1.png"
                      alt="TSUJIDO 2021"
                    />
                  </picture>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="archive__item">
                <dt>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 252.88 17.35"
                    >
                      <title>SHINKEMIGAWA 2021</title>
                      <path
                        d="M5.71,10.13C2.57,9.6.41,8,.41,5.09.41,1.82,3.43,0,6.62,0A8.77,8.77,0,0,1,13.1,2.69L10.71,5.21A5.89,5.89,0,0,0,6.43,3.26C4.92,3.26,4,3.94,4,4.92c0,.82.62,1.46,2.4,1.78L8,7c3.69.6,5.66,2.28,5.66,5.26S11,17.35,7,17.35a8.89,8.89,0,0,1-7-3.21l2.45-2.47A5.88,5.88,0,0,0,7.2,14.09c1.87,0,2.93-.79,2.93-1.82,0-.84-.63-1.47-2.35-1.78Z"
                      />
                      <path
                        d="M27,10.13H19.89v7H16.31V.26h3.58V6.87H27V.26H30.5V17.09H27Z"
                      />
                      <path d="M34.07.26h3.55V17.09H34.07Z" />
                      <path
                        d="M44.73,5.74V17.09H41.15V.26h4.3l6.79,11.41V.26h3.55V17.09H51.52Z"
                      />
                      <path
                        d="M59.31.26h3.57V7.13L68.31.26h4.25L66.8,7.46l6.31,9.63H69l-4.48-6.86-1.64,2.08v4.78H59.31Z"
                      />
                      <path
                        d="M75.05.26H87.46V3.55H78.63V6.89h8.42v3.24H78.63V13.8h8.83v3.29H75.05Z"
                      />
                      <path
                        d="M94.36,7.54v9.55H90.79V.26h3.57L99.14,9.7l4.8-9.44h3.55V17.09h-3.55V7.54l-3.22,6.14H97.55Z"
                      />
                      <path d="M111.07.26h3.55V17.09h-3.55Z" />
                      <path
                        d="M126.26,7.73h6.84v6.74a9.75,9.75,0,0,1-7,2.88,8.46,8.46,0,0,1-8.78-8.64c0-5.35,3.89-8.71,8.81-8.71a8.33,8.33,0,0,1,6.69,3.17l-2.4,2.45A5.37,5.37,0,0,0,126,3.34a5,5,0,0,0-5,5.3A5.09,5.09,0,0,0,126.16,14a5.75,5.75,0,0,0,3.43-1V11h-3.33Z"
                      />
                      <path
                        d="M150.91,17.09h-3.7l-1.34-3.31h-6.51L138,17.09h-3.7L140.92.26h3.41Zm-10.37-6.48h4.17l-2.09-5.5Z"
                      />
                      <path
                        d="M156.84,11,159.56.26h3.81L166.08,11,168.68.26h3.81L168,17.09h-3.64L161.48,6l-2.91,11.11h-3.65L150.43.26h3.82Z"
                      />
                      <path
                        d="M188.61,17.09h-3.7l-1.34-3.31h-6.51l-1.32,3.31h-3.69L178.62.26H182Zm-10.37-6.48h4.18l-2.09-5.5Z"
                      />
                      <path
                        d="M208.81,13.8v3.29h-12v-.55c0-4,1.3-6.51,5.5-8.62l.93-.46c1.8-.91,2.09-1.67,2.09-2.42,0-1-.86-1.78-2.33-1.78A4.35,4.35,0,0,0,199.09,6l-2.3-2.3a6.68,6.68,0,0,1,6-3.65c3.12,0,6.08,1.7,6.08,5,0,2.64-1.61,4.11-4,5.33l-1.47.75c-1.44.74-2.3,1.41-2.54,2.73Z"
                      />
                      <path
                        d="M217.84,0c4.22,0,6.67,3.6,6.67,8.67s-2.45,8.68-6.67,8.68-6.67-3.62-6.67-8.68S213.59,0,217.84,0Zm0,3.26c-2.06,0-3.12,2.12-3.12,5.41s1.06,5.42,3.12,5.42S221,12,221,8.67,219.88,3.26,217.84,3.26Z"
                      />
                      <path
                        d="M238.48,13.8v3.29h-12v-.55c0-4,1.3-6.51,5.5-8.62l.94-.46C234.69,6.55,235,5.79,235,5c0-1-.86-1.78-2.32-1.78A4.35,4.35,0,0,0,228.76,6l-2.31-2.3a6.69,6.69,0,0,1,6-3.65c3.12,0,6.07,1.7,6.07,5,0,2.64-1.61,4.11-4,5.33l-1.46.75c-1.44.74-2.31,1.41-2.55,2.73Z"
                      />
                      <path
                        d="M245.37,13.8V4.71a7.86,7.86,0,0,1-4.11,1V2.57C243,2.5,244.91,1.9,245.7.26h3.22V13.8h4v3.29H241.26V13.8Z"
                      />
                    </svg>
                  </h3>
                  <p>
                    JR総武線「新検見川駅」徒歩9分<br />
                    木造2階建て（4部屋）<br />
                    価格: 4,600万円（自己資金0円）<br />
                    利回り: 7.51%
                  </p>
                </dt>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/shinkemigawa-2.webp"
                    />
                    <img
                      src="@/assets/investor/zero/shinkemigawa-2.png"
                      alt="SHINKEMIGAWA 2021"
                    />
                  </picture>
                </dd>
                <dd>
                  <picture>
                    <source
                      type="image/webp"
                      srcset="@/assets/investor/zero/shinkemigawa-1.webp"
                    />
                    <img
                      src="@/assets/investor/zero/shinkemigawa-1.png"
                      alt="SHINKEMIGAWA 2021"
                    />
                  </picture>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <section class="zero-content zero-content--faq" data-section-fade>
        <div class="zero-content__inner faq">
          <div class="faq__title">
            <h1 class="zero-content__title">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.42 14.8">
                <title>FAQ</title>
                <path d="M0,14.24V.22H9.66V3H3V6H9.2V8.72H3v5.52Z" />
                <path
                  d="M23.5,14.24H20.42L19.3,11.48H13.88l-1.1,2.76H9.7l5.48-14H18Zm-8.64-5.4h3.48L16.6,4.26Z"
                />
                <path
                  d="M30.94,0A7.1,7.1,0,0,1,38.2,7.22a7.31,7.31,0,0,1-1.28,4.2l1.5,1.5-1.9,1.88L35,13.3a7.53,7.53,0,0,1-4.06,1.16,7,7,0,0,1-7.21-7.24A7.05,7.05,0,0,1,30.94,0Zm2.2,7.62,1.62,1.64a4.9,4.9,0,0,0,.42-2A4.22,4.22,0,0,0,31,2.8a4.16,4.16,0,0,0-4.23,4.42,4.3,4.3,0,0,0,6.15,4l-1.7-1.72Z"
                />
              </svg>
            </h1>
            <h2 class="zero-content__copy">
              始める不安に<br />
              確かな安心を
            </h2>
          </div>
          <ul id="js-faq" class="faq__list">
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(0) }"
              >
                <dt @click="toggleAccordion(0)">
                  <p>なぜフルローンが可能なのか？</p>
                </dt>
                <dd>
                  <p>
                    StockFormer-ZEROは、独自の不動産投資スコアリング技術で、投資家様の属性情報からフルローン相当の融資可能額を算出します。また、収益性の高い一棟物件をプロデュースすることで、金融機関からの物件評価を高め、フルローンを実現しています。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(1) }"
              >
                <dt @click="toggleAccordion(1)">
                  <p>フルローンできないのはどんな人？</p>
                </dt>
                <dd>
                  <p>
                    以下の属性の方は、本プランでのフルローンでのご融資が困難な可能性があります。
                  </p>
                  <ul class="faq__list__item__list">
                    <li>すでに複数棟の物件を保有して多額の既存借入がある方</li>
                    <li>現在の勤務先での勤続年数が3年未満の方</li>
                    <li>年収が500万円未満の方</li>
                    <li>年齢が60歳以上の方</li>
                  </ul>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(2) }"
              >
                <dt @click="toggleAccordion(2)">
                  <p>
                    扱う物件が「区分マンション」ではなく「新築一棟物件」なのはなぜ？
                  </p>
                </dt>
                <dd>
                  <p>
                    現在の不動産マーケットを鑑みると、「区分マンション」への投資は、利回りが低く、必ずしもキャッシュフローを生む商品ではなくなっております。一方、「新築一棟物件」は、相対的に利回りが高く、安定的な入居も見込めるため、皆さまにとってしっかりとキャッシュエンジンを構築できるプランになると考えています。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(3) }"
              >
                <dt @click="toggleAccordion(3)">
                  <p>高い利回りが可能なのはなぜ？</p>
                </dt>
                <dd>
                  <p>
                    StockFormer-ZEROでは、土地・不動産・工務店データベースを駆使して、最適なコストを実現しています。一般的に大手工務店では、多額の営業費・広告費などが建築費用に含まれていますが、土地に応じた地場の優良な工務店をマッチングすることで不要なコストを排除しています。加えて、実績のあるプランナーが設計・デザイン監修を行い、ハイスペック設備を採用することで、家賃収益を高めて高利回りを実現しています。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(4) }"
              >
                <dt @click="toggleAccordion(4)">
                  <p>
                    安定収入は分かった。が、10年後、20年後の売却価格はどうなる？
                  </p>
                </dt>
                <dd>
                  <p>
                    相場と比較して収益性・利回りが見込める物件を取得することで、将来的なキャピタルゲイン（売却益）を得られる可能性が高まります。（なお、不動産マーケットの市況によってはその限りではありません）StockFormer-ZEROでは、キャピタルゲインも見据えて、最適な物件プランをご提案します。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(5) }"
              >
                <dt @click="toggleAccordion(5)">
                  <p>家族に迷惑はかからない？途中でやめることはできるの？</p>
                </dt>
                <dd>
                  <p>
                    StockFormer-ZEROでは、ご融資の際に団体信用生命保険（団信）の加入が可能なため、返済中に万が一のことがあった場合にも、生命保険会社から支払われる保険金によってローンの残債務が弁済され、残されたご家族にローンが残らず、不動産を保有し続けることが可能です。
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl
                class="faq__list__item"
                :class="{ 'is-open': faq.includes(6) }"
              >
                <dt @click="toggleAccordion(6)">
                  <p>
                    日々の清掃や入居者の募集、賃料管理、定期的な修繕など、物件管理は誰が行うの？
                  </p>
                </dt>
                <dd>
                  <p>
                    物件管理業務は、管理会社に委託するため、ご本人の手間は必要最低限です。StockFormer-ZEROでは、実績の高い管理会社様をご紹介し、条件交渉・賃貸管理契約の締結までサポートします。
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>

      <zero-conversion-area
        @click="$emit('onConversionClick', $event)"
        data-section-fade
      />

      <section
        id="PHILOSOPHY"
        class="zero-content zero-content--philosophy"
        ref="philosophy"
        data-section-fade
      >
        <div class="zero-content__inner philosophy">
          <h1 class="zero-content__title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.67 14.46">
              <title>PHILOSOPHY</title>
              <path
                d="M3,9.8v4.44H0V.22H5.68c3.26,0,5.48,1.78,5.48,4.7S8.94,9.8,5.68,9.8ZM3,3v4.1H5.16c2,0,3-.68,3-2.08s-1-2-3-2Z"
              />
              <path d="M21.83,8.44H16v5.8H13V.22h3v5.5h5.88V.22h3v14h-3Z" />
              <path d="M27.79.22h3v14h-3Z" />
              <path d="M33.71.22h3V11.5H43.1v2.74H33.71Z" />
              <path
                d="M51.13,0a7.1,7.1,0,0,1,7.26,7.22,7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,51.13,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,51.21,2.8,4.16,4.16,0,0,0,47,7.22,4.15,4.15,0,0,0,51.21,11.66Z"
              />
              <path
                d="M64.38,8.44C61.76,8,60,6.64,60,4.24,60,1.52,62.48,0,65.14,0a7.35,7.35,0,0,1,5.41,2.24l-2,2.1A4.9,4.9,0,0,0,65,2.72c-1.26,0-2.06.56-2.06,1.38s.52,1.22,2,1.48l1.38.22C69.39,6.3,71,7.7,71,10.18s-2.2,4.28-5.59,4.28a7.42,7.42,0,0,1-5.82-2.68l2-2.06a4.91,4.91,0,0,0,4,2c1.57,0,2.45-.66,2.45-1.52,0-.7-.53-1.22-2-1.48Z"
              />
              <path
                d="M79.64,0A7.1,7.1,0,0,1,86.9,7.22a7.23,7.23,0,1,1-14.46,0A7.05,7.05,0,0,1,79.64,0Zm.08,11.66a4.14,4.14,0,0,0,4.16-4.44A4.22,4.22,0,0,0,79.72,2.8,4.16,4.16,0,0,0,75.5,7.22,4.15,4.15,0,0,0,79.72,11.66Z"
              />
              <path
                d="M92.11,9.8v4.44h-3V.22h5.68c3.26,0,5.48,1.78,5.48,4.7S98.07,9.8,94.81,9.8Zm0-6.84v4.1h2.18c2,0,3-.68,3-2.08s-1-2-3-2Z"
              />
              <path d="M111,8.44h-5.89v5.8h-3V.22h3v5.5H111V.22h3v14h-3Z" />
              <path
                d="M121.53,6.12l2.9-5.9h3.24L123,9.34v4.9H120V9.34L115.4.22h3.24Z"
              />
            </svg>
          </h1>
          <h2 class="zero-content__copy">
            StockFormer-ZEROを通じて<br class="sp" />提供したいこと<i class="sp"
              >。</i
            ><br class="pc" />
            それは自立・自律した資産形成をする「経験」
          </h2>
          <div class="philosophy__message">
            <p>
              StockFormer-ZEROは、新たな不動産投資ICTサービスです。不動産投資が初めての方でも、ご自身の属性・信用力を正しく理解でき、専任のパートナーが伴走することで、ご自身にあった投資額を見極め、資産を安心して構築できます。また、投資経験を通じて、正しい不動産投資・資産形成のナレッジ・能力が養え、これからの投資人生において、確かな行動指針を築くことができます。
            </p>
            <p>
              ZIRITZは、今後も誰もが自分らしく輝ける投資環境を実現していきます。
            </p>
            <p class="philosophy__signature">
              株式会社ZIRITZ 代表取締役 島﨑怜平
            </p>
          </div>
          <div class="philosophy__pagetop">
            <a
              class="philosophy__pagetop__btn"
              href="javascript:void(0);"
              @click="anchorScroll('top')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 117.53 54.14"
              >
                <path
                  d="M6.82,48.55H3.58v5.33H0V37.06H6.82a7,7,0,0,1,4.77,1.53,5.09,5.09,0,0,1,1.8,4.11A5.42,5.42,0,0,1,11.57,47,6.93,6.93,0,0,1,6.82,48.55Zm-.63-8.21H3.58v4.92H6.19q3.64,0,3.65-2.49A2,2,0,0,0,9,40.92,5.2,5.2,0,0,0,6.19,40.34Z"
                />
                <path
                  d="M19.46,37.06h3.41l6.58,16.82h-3.7l-1.34-3.31H17.9l-1.32,3.31H12.89Zm3.8,10.34-2.09-5.5-2.09,5.5Z"
                />
                <path
                  d="M42,47.81H38.68V44.52h6.84v6.74a9.42,9.42,0,0,1-3.09,2.11,9.94,9.94,0,0,1-3.9.77,8.76,8.76,0,0,1-6.26-2.42,8.24,8.24,0,0,1-2.52-6.22,8.28,8.28,0,0,1,2.52-6.31,8.7,8.7,0,0,1,6.29-2.4A8.17,8.17,0,0,1,45.25,40l-2.4,2.45a5,5,0,0,0-1.92-1.68,5.49,5.49,0,0,0-2.54-.6,4.91,4.91,0,0,0-3.48,1.44,5.08,5.08,0,0,0-1.49,3.86,5.28,5.28,0,0,0,1.49,3.91,5,5,0,0,0,3.67,1.47,5.77,5.77,0,0,0,3.43-1Z"
                />
                <path
                  d="M61.06,53.88H48.65V37.06H61.06v3.28H52.23v3.34h8.42v3.24H52.23v3.67h8.83Z"
                />
                <path
                  d="M69.94,40.34V37.06h13.2v3.28H78.32V53.88H74.77V40.34Z"
                />
                <path
                  d="M84.16,45.46a8.47,8.47,0,0,1,8.64-8.67A8.48,8.48,0,0,1,99,39.26a8.29,8.29,0,0,1,2.52,6.2A8.38,8.38,0,0,1,99,51.7a8.66,8.66,0,0,1-14.83-6.24Zm3.67,0a5.55,5.55,0,0,0,1.37,3.79,4.7,4.7,0,0,0,3.69,1.54,4.55,4.55,0,0,0,3.63-1.54,5.55,5.55,0,0,0,1.37-3.79,5.41,5.41,0,0,0-1.42-3.75,4.62,4.62,0,0,0-3.58-1.56,4.75,4.75,0,0,0-3.69,1.54A5.47,5.47,0,0,0,87.83,45.46Z"
                />
                <path
                  d="M111,48.55h-3.24v5.33h-3.57V37.06H111a7.09,7.09,0,0,1,4.78,1.53,5.09,5.09,0,0,1,1.8,4.11A5.42,5.42,0,0,1,115.71,47,7,7,0,0,1,111,48.55Zm-.62-8.21h-2.62v4.92h2.62q3.65,0,3.65-2.49a2,2,0,0,0-.89-1.85A5.2,5.2,0,0,0,110.33,40.34Z"
                />
                <polygon
                  points="72.06 16.6 58.28 2.83 44.51 16.6 43.1 15.19 58.28 0 73.47 15.19 72.06 16.6"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <div id="js-stalker">
        <a
          class="zero__stalker__simulation"
          href="javascript:void(0);"
          @click="$emit('onConversionClick', $event)"
          ><small>無料</small>フルローン診断</a
        >
      </div>
    </main>
  </div>
</template>

<script>
import ZeroConversionArea from "./ZeroConversionArea";

export default {
  name: "ZeroContent",
  components: { ZeroConversionArea },
  data: function () {
    return {
      isLoading: true,
      isShowHeader: false,
      faq: [],
      sections: [],
    };
  },
  methods: {
    toggleAccordion(id) {
      const index = this.faq.indexOf(id);
      if (index > -1) {
        this.faq.splice(index, 1);
      } else {
        this.faq.push(id);
      }
    },
    initScrollFader() {
      this.sections = [...this.$el.querySelectorAll("*[data-section-fade]")];
      this.sections.forEach((section) =>
        section.classList.add("section-fade--standby")
      );
    },
    anchorScroll(targetRefName) {
      if (targetRefName in this.$refs) {
        window.scrollTo({
          top: this.$refs[targetRefName].offsetTop - 30,
          behavior: "smooth",
        });
      }
    },
    handleScrollFade() {
      const height = window.innerHeight;
      this.sections.forEach((section) => {
        const top = section.getBoundingClientRect().top;
        const limit = 1.5;
        if (
          height / limit > top &&
          section.classList.contains("section-fade--standby")
        ) {
          section.classList.remove("section-fade--standby");
          section.classList.add("section-fade--visible");
        }
      });

      // is expiration of scroll
      const documentHeight = this.$el.clientHeight;

      if (documentHeight <= window.pageYOffset + window.innerHeight + 96) {
        document.body.classList.add("scrollEnd");

        // absolutely visibile
        this.sections.forEach((section) => {
          section.classList.remove("section-fade--standby");
          section.classList.add("section-fade--visible");
        });
      } else {
        document.body.classList.remove("scrollEnd");
      }
    },
    handleHeaderView() {
      if (this.$refs.header) {
        this.isShowHeader = window.pageYOffset > 100;
      }
    },
    handleScroll() {
      this.handleHeaderView();
      this.handleScrollFade();
    },
    handleResize() {
      this.initScrollFader();
    },
  },
  mounted() {
    this.isLoading = false;
    window.addEventListener("scroll", this.handleScroll);

    this.initScrollFader();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

// color
$zero__text-color: #34485e;
$zero__text-color--grey: #4d4d4d;
$zero__text-color--turquoise: #70acce;
$zero__text-color--blue: #3c7d9b;
$zero__bg-color--grey: #e6e6e6;
$zero__bg-color--turquoise: #e5eef4;
$zero__bg-color--blue: #79a7d9;

// width
$zero__mode-tab-max: 640px;
$zero__break-point-laptop: 1280px + $menu-width-md + $summary-width-md;
$zero__break-point-tab: 1080px + $menu-width-md + $summary-width-md;
$zero__break-point-sp: 768px + $menu-width;

@mixin fl($px, $line-height) {
  font-size: $px + px;
  line-height: ($line-height / $px);
}

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin under-sp() {
  @media screen and (max-width: $zero__break-point-sp) {
    @content;
  }
}

@mixin under-tab() {
  @media screen and (max-width: $zero__break-point-tab) {
    @content;
  }
}

@mixin over-tab() {
  @media screen and (min-width: $zero__break-point-tab) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (max-width: $zero__break-point-laptop) {
    @content;
  }
}

.zero {
  font-feature-settings: "palt";
  -webkit-font-smoothing: auto;
  letter-spacing: 0;
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  ol,
  ul,
  li {
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div {
    word-wrap: break-word;
  }
}

.zero-content {
  display: block;
  color: $zero__text-color;
  &__inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 84px 60px;
    @include sp() {
      padding: 48px 24px;
    }
  }
  &__title {
    margin: 0 auto 24px;
    svg {
      display: block;
      height: 14px;
      margin: 0 auto;
      @include sp() {
        height: 12px;
      }
      path {
        fill: $zero__text-color--turquoise;
      }
    }
  }
  &__copy {
    margin: 0 auto 48px;
    text-align: center;
    @include fl(32, 46);
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: $zero__text-color;
    .sp {
      display: none;
    }
    @include sp() {
      @include fl(17, 26);
      letter-spacing: 0;
      margin: 0 auto 36px;
      .sp {
        display: inline;
      }
      .pc {
        display: none;
      }
    }
  }

  // content sections
  &--keyVisual {
    background: #000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include under-tab() {
      display: block;
      height: auto;
    }
    .keyVisual {
      position: relative;
      height: inherit;
      overflow: hidden;
      @include under-tab() {
        height: 100vh;
        max-height: 444px;
      }
      .zero-content__inner {
        position: relative;
        z-index: 2;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
      &__logo {
        width: 100%;
        max-width: 1010px;
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translate(-50%, 0);
        padding: 0 60px;
        @include under-tab() {
          max-width: 80px;
          padding: 0;
          bottom: 24px;
        }
        svg {
          display: block;
          width: 100%;
          path,
          polygon {
            fill: #fff;
          }
          &.sp {
            display: none;
          }
          @include under-tab() {
            &.pc {
              display: none;
            }
            &.sp {
              display: block;
            }
          }
        }
      }
      &__copy {
        position: absolute;
        top: 60px;
        left: 60px;
        color: #fff;
        @include fl(22, 40);
        @include under-tab() {
          width: 100%;
          @include fl(16, 24);
          text-align: center;
          margin: 0;
          top: 24px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      &__button--simulation {
        position: absolute;
        top: 60px;
        right: 60px;
        border-radius: 128px;
        padding: 21px 38px 21px 60px;
        color: $zero__text-color--blue;
        background: #fff;
        @include fl(17, 0);
        font-weight: 700;
        transition: background-color 300ms ease;
        small {
          display: inline-block;
          padding: 4px 6px 4px 8px;
          background-color: $zero__text-color--blue;
          border-radius: 24px;
          @include fl(12, 12);
          line-height: 0.9; // Hack to make the conditions the same on iphone and others.
          font-weight: 700;
          letter-spacing: 0.2rem;
          text-align: center;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translate(0, -50%);
        }
        &:after {
          content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%233c7d9b%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
          font-size: 0;
          display: block;
          width: 15px;
          height: 12px;
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translate(0, -50%);
          transition: transform 300ms ease;
        }
        &:hover {
          background-color: $zero__text-color--blue;
          color: #fff;
          transition: background-color 300ms ease;
          small {
            background-color: #fff;
            color: $zero__text-color--blue;
          }
          &:after {
            content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
          }
        }
        @include under-tab() {
          display: none;
        }
      }
      &__image {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 2000px;
        transform: translate(-50%, -50%);
        // for animation
        opacity: 0;
        @include under-tab() {
          width: 960px;
        }
      }
    }
    .appeal {
      background: $zero__bg-color--grey;
      padding: 36px 0 34px;
      .zero-content__inner {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: calc(890px + (60px * 2));
        padding-top: 0;
        padding-bottom: 0;
        @include under-tab() {
          display: block;
        }
      }
      &__text {
        margin: 0 auto;
        padding-right: 292px;
        position: relative;
        @include under-tab() {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
        }
        dt {
          @include fl(22, 32);
          color: $zero__text-color--grey;
          font-weight: 500;
          margin: 0 0 8px;
          @include under-tab() {
            width: 100%;
            @include fl(16, 24);
            text-align: center;
            margin: 0 auto 16px;
            order: 2;
          }
          br {
            @include under-tab() {
              display: none;
            }
            &.sp {
              display: none;
              @include under-tab() {
                display: block;
              }
            }
          }
          sup {
            @include fl(12, 12);
            position: relative;
            top: 2px;
          }
        }
        &__note {
          @include fl(11, 16);
          color: $zero__text-color--grey;
          @include under-tab() {
            order: 3;
            max-width: 400px;
            margin: 0 auto;
          }
        }
      }
      &__image {
        display: block;
        width: 256px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        @include under-tab() {
          width: 216px;
          position: static;
          transform: none;
          margin: 0 auto 20px;
          order: 1;
        }
        g.leaf {
          opacity: 0.4;
        }
        path {
          fill: #736357;
        }
      }
    }
  }
  &--feature {
    .feature {
      &__horizontal-list {
        display: flex;
        align-items: top;
        justify-content: space-between;
        margin: 0 auto;
        @include under-tab() {
          display: block;
          max-width: $zero__mode-tab-max;
        }
        li {
          flex: 1;
          + li {
            margin-left: 60px;
            @include under-tab() {
              margin-left: 0;
              margin-top: 36px;
            }
          }
          > small {
            display: block;
            @include fl(12, 18);
            margin: 8px 0 0;
          }
        }
      }
      &__item {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        dt {
          h3 {
            @include fl(24, 24);
            font-weight: 700;
            margin: 0 0 16px;
            text-align: center;
            @include sp() {
              @include fl(18, 18);
            }
          }
          p {
            @include fl(13, 24);
          }
          small {
            display: block;
            margin-top: 8px;
            @include fl(11, 16);
          }
        }
        dd {
          margin: 0 0 24px;
          position: relative;
          svg {
            display: block;
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  &--benefit {
    background: $zero__bg-color--turquoise;
    .benefit {
      padding-bottom: 110px;
      @include laptop() {
        padding-bottom: 60px;
      }
      @include sp() {
        padding-bottom: 36px;
      }
      &__horizontal-list {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        @include laptop() {
          display: block;
          max-width: $zero__mode-tab-max;
        }
        li {
          flex: 1;
          background: #f5f9fb;
          position: relative;
          + li {
            margin-left: 60px;
            @include laptop() {
              margin-left: 0;
              margin-top: 60px;
            }
            &:before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 17px solid $zero__bg-color--blue;
              border-right: 0;
              position: absolute;
              top: 8px;
              left: -38px;
              @include laptop() {
                top: -48px;
                left: 50%;
                transform: translate(-50%, 0);
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                border-top: 17px solid $zero__bg-color--blue;
                border-bottom: 0;
              }
            }
          }
          > small {
            display: block;
            @include fl(12, 18);
            padding: 12px 0 0;
            position: absolute;
            bottom: 0;
            transform: translate(0, 100%);
            @include laptop() {
              position: static;
              transform: none;
              background: $zero__bg-color--turquoise;
            }
          }
        }
      }
      &__item {
        > dt {
          background: $zero__bg-color--blue;
          padding: 12px 0;
          svg {
            display: block;
            height: 14px;
            margin: 0 auto;
            path {
              fill: #fff;
            }
          }
        }
        > dd {
          padding: 20px;
          h3 {
            @include fl(18, 26);
            font-weight: 700;
            text-align: center;
            margin: 0 auto 18px;
            @include sp() {
              @include fl(16, 24);
            }
          }
        }
        &__features {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;
          margin: 0 auto 20px;
          > dt {
            width: 100%;
            background: $zero__text-color--blue;
            @include fl(14, 21);
            text-align: center;
            padding: 4px 8px;
            color: #fff;
            span {
              @include sp() {
                display: block;
                @include fl(13, 13);
              }
            }
          }
          > dd {
            display: flex;
          }
        }
        &__table {
          width: 100%;
          tr {
            border-top: 1px solid $zero__text-color--grey;
          }
          th {
            @include fl(14, 14);
            color: $zero__text-color--grey;
            vertical-align: top;
            padding: 6px 0;
            sup {
              @include fl(10, 10);
              line-height: 0;
              position: relative;
              top: 3px;
            }
          }
          td {
            @include fl(14, 14);
            color: $zero__text-color--grey;
            text-align: right;
            padding: 6px 0;
            svg {
              display: inline-block;
              vertical-align: bottom;
              height: 18px;
              &.with-comma {
                height: 20px;
              }
              path {
                fill: $zero__text-color--blue;
              }
            }
            span {
              display: inline-block;
              vertical-align: bottom;
              color: $zero__text-color--blue;
            }
          }
          & + .benefit__item__table {
            margin-top: 24px;
          }
        }
        &__goals {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          height: 64px;
          overflow: hidden;
          @include sp() {
            flex-wrap: wrap;
            height: auto;
            overflow: visible;
            flex-direction: column-reverse;
          }
          > dt {
            @include fl(16, 18);
            color: $zero__text-color--blue;
            flex: 0.75;
            padding: 0 6px 0 6px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            min-width: 72px;
            text-align: center;
            @include sp() {
              @include fl(16, 24);
              font-weight: 700;
              flex: none;
              width: 100%;
              height: auto;
              padding: 12px;
              justify-content: center;
              br.sp {
                display: none;
              }
            }
          }
          & + .benefit__item__goals {
            margin-top: 20px;
          }
          > dd {
            display: flex;
            flex: 2.6;
            height: 64px;
            @include sp() {
              flex: none;
              width: 100%;
              height: auto;
            }
          }
          &__term {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            align-content: space-evenly;
            justify-content: center;
            flex: 0.75;
            background: $zero__text-color--blue;
            padding: 10px;
            min-width: 96px;
            @include sp() {
              flex: none;
              width: 96px;
            }
            svg {
              display: inline-block;
              vertical-align: bottom;
              height: 16px;
              position: relative;
              bottom: 1px;
              margin: 0 2px 0 0;
              @include sp() {
                height: 14px;
                bottom: 0px;
              }
              path {
                fill: #fff;
              }
            }
            span,
            strong {
              display: block;
              @include fl(14, 14);
              color: #fff;
              @include sp() {
                @include fl(12, 12);
              }
            }
          }
          &__revenue {
            flex: 2;
            background: $zero__text-color--turquoise;
            padding: 12px 6px 0 0;
            position: relative;
            text-align: center;
            @include sp() {
              flex: none;
              width: calc(100% - 96px);
              padding: 6px 10px 10px 10px;
            }
            b {
              display: block;
              @include fl(14, 14);
              color: #fff;
              margin: 0 0 6px;
              @include sp() {
                display: inline-block;
                vertical-align: bottom;
                @include fl(12, 12);
                margin: 0;
              }
              sup {
                @include fl(8, 8);
                line-height: 0;
                position: relative;
                top: 2px;
              }
            }
            svg {
              display: inline-block;
              vertical-align: bottom;
              height: 20px;
              position: relative;
              bottom: -2px;
              @include sp() {
                height: 18px;
                bottom: -3px;
                margin: 0 2px;
              }
              path {
                fill: #fff;
              }
            }
            span {
              display: inline-block;
              vertical-align: bottom;
              @include fl(14, 14);
              color: #fff;
              @include sp() {
                @include fl(12, 12);
              }
            }
            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 32px 0;
              border-color: transparent #fff transparent transparent;
              @include sp() {
                content: none;
              }
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 32px 10px 0 0;
              border-color: transparent #fff transparent transparent;
              @include sp() {
                content: none;
              }
            }
          }
        }
      }
    }
  }
  &--flow {
    .flow {
      &__keyVisulal {
        max-width: 960px;
        margin: 0 auto 36px;
        position: relative;
        @include under-tab() {
          max-width: $zero__mode-tab-max;
        }
        @include sp() {
          margin: 0 auto 24px;
        }
      }
      &__text {
        @include fl(20, 36);
        max-width: 960px;
        margin: 0 auto 60px;
        @include under-tab() {
          max-width: $zero__mode-tab-max;
        }
        @include sp() {
          @include fl(14, 24);
          margin: 0 auto 36px;
        }
      }
      &__vertical-list {
        max-width: 960px;
        margin: 0 auto;
        @include under-tab() {
          max-width: $zero__mode-tab-max;
        }
        li {
          padding: 36px 36px 30px;
          box-shadow: 2px 2px 4px 2px #ddd;
          border-radius: 4px;
          position: relative;
          @include sp() {
            padding: 20px 16px 18px;
          }
          + li {
            margin-top: 60px;
            &:before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-right: 10px solid transparent;
              border-left: 10px solid transparent;
              border-top: 17px solid $zero__text-color--turquoise;
              border-bottom: 0;
              position: absolute;
              top: -38px;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
      &__item {
        margin: 0 auto;
        max-width: 480px;
        dt {
          display: flex;
          margin: 0 auto 24px;
          border: 1px solid $zero__text-color--turquoise;
          span {
            width: 110px;
            background: $zero__text-color--turquoise;
            padding: 10px 0;
            svg {
              display: block;
              height: 12px;
              margin: 0 auto;
              @include sp() {
                height: 11px;
              }
              path {
                fill: #fff;
              }
            }
          }
          strong {
            width: 100%;
            padding: 8px 0;
            @include fl(16, 16);
            font-weight: 700;
            color: $zero__text-color--turquoise;
            text-align: center;
            @include sp() {
              @include fl(14, 14);
              padding: 9px 0 0;
            }
          }
        }
        dd {
          h3 {
            @include fl(24, 36);
            font-weight: 700;
            text-align: center;
            margin: 0 0 20px;
            @include sp() {
              @include fl(16, 24);
            }
          }
          p {
            @include fl(14, 21);
          }
        }
      }
    }
  }
  &--archive {
    background: $zero__bg-color--grey;
    .archive {
      padding-top: 60px;
      padding-bottom: 60px;
      .zero-content__title {
        margin-bottom: 56px;
        @include under-tab() {
          margin-bottom: 48px;
        }
      }
      &__list {
        margin: 0 auto;
        @include under-tab() {
          max-width: $zero__mode-tab-max;
        }
        > li {
          + li {
            margin-top: 48px;
            @include under-tab() {
              margin-top: 24px;
            }
          }
        }
      }
      &__item {
        display: flex;
        flex-direction: row-reverse;
        max-width: 960px;
        margin: 0 auto;
        background: #fff;
        position: relative;
        @include under-tab() {
          display: block;
          @include clearfix();
        }
        dt {
          width: 320px;
          padding: 20px;
          @include under-tab() {
            width: 100%;
            float: left;
            padding: 12px 24px;
          }
          h3 {
            margin: 20px 0 36px;
            @include under-tab() {
              margin: 12px 0 20px;
            }
            svg {
              display: block;
              height: 18px;
              @include under-tab() {
                height: 16px;
              }
              path {
                fill: $zero__text-color;
              }
            }
          }
          p {
            @include fl(14, 21);
          }
        }
        dd {
          flex: 1;
          @include under-tab() {
            width: calc(100% / 2);
            float: right;
          }
        }
      }
    }
  }
  &--faq {
    .faq {
      position: relative;
      @include over-tab() {
        &__title {
          position: absolute;
          top: 96px;
          left: 60px;
          text-align: left;
          h1 {
            svg {
              margin: 0 0 0 2px;
            }
            margin: 0 0 16px;
          }
          h2 {
            text-align: left;
          }
        }
      }
      &__list {
        @include over-tab() {
          padding-left: 386px;
        }
        @include under-tab() {
          border-top: 1px solid $zero__bg-color--grey;
        }
        > li {
          border-bottom: 1px solid $zero__bg-color--grey;
        }
        &__item {
          position: relative;
          > dt {
            display: block;
            padding-right: 36px;
            position: relative;
            cursor: pointer;
            &::before {
              content: "";
              width: 2px;
              height: 16px;
              background: $zero__text-color--blue;
              position: absolute;
              top: 50%;
              right: 7px;
              transform: translate(0, -50%);
              transition: transform 300ms ease;
              pointer-events: none;
            }
            &::after {
              content: "";
              width: 16px;
              height: 2px;
              background: $zero__text-color--blue;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
              transition: transform 300ms ease;
              pointer-events: none;
            }
            p {
              padding: 16px 0 16px 36px;
              position: relative;
              @include fl(18, 27);
              @include sp() {
                @include fl(14, 21);
              }
              &:before {
                content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2017.21%2017.64%22%3E%20%3Cpath%20fill%3D%22%2334485e%22%20d%3D%22M8.5%2C0a8.5%2C8.5%2C0%2C0%2C1%2C8.57%2C8.67A8.75%2C8.75%2C0%2C0%2C1%2C15.36%2C14l1.85%2C1.82-1.87%2C1.87-1.85-1.85a8.43%2C8.43%2C0%2C0%2C1-5%2C1.56A8.42%2C8.42%2C0%2C0%2C1%2C0%2C8.67%2C8.43%2C8.43%2C0%2C0%2C1%2C8.5%2C0Zm2.76%2C9.84L13.37%2C12a6.62%2C6.62%2C0%2C0%2C0%2C.82-3.28c0-3.39-2.36-6-5.6-6a5.7%2C5.7%2C0%2C0%2C0-5.66%2C6c0%2C3.45%2C2.35%2C6.07%2C5.66%2C6.07a5.3%2C5.3%2C0%2C0%2C0%2C3-.87L9.41%2C11.69Z%22%20%2F%3E%20%3C%2Fsvg%3E");
                display: block;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 18px;
                left: 0;
              }
            }
          }
          > dd {
            display: block;
            height: 0;
            overflow: hidden;
            position: relative;
            &:before {
              content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016.01%2016.83%22%3E%20%3Cpath%20fill%3D%22%2334485e%22%20d%3D%22M16%2C16.83H13l-1.42-3.58H4.42L3%2C16.83H0L6.58%2C0H9.46ZM5.45%2C10.61h5.11L8%2C3.89Z%22%20%2F%3E%20%3C%2Fsvg%3E");
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 26px;
              left: 0;
            }
            p {
              @include fl(14, 24);
              + p {
                margin-top: 24px;
              }
            }
            ul {
              margin-top: 24px;
              li {
                @include fl(14, 24);
                padding: 0 0 0 24px;
                position: relative;
                &:before {
                  content: "●";
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                + li {
                  margin-top: 12px;
                }
              }
            }
          }
          &.is-open {
            > dt {
              border-bottom: 1px solid $zero__bg-color--grey;
              &::before {
                transform: translate(0, -50%) rotate(90deg);
                transition: transform 300ms ease;
              }
              &::after {
                transform: translate(0, -50%) scale(0);
                transition: transform 300ms ease;
              }
            }
            > dd {
              overflow: visible;
              height: auto;
              padding: 24px 16px 24px 36px;
            }
          }
        }
      }
    }
  }
  &--philosophy {
    .philosophy {
      padding-bottom: 60px;
      .zero-content__copy {
        margin-bottom: 72px;
        @include sp() {
          margin-bottom: 30px;
        }
      }
      &__message {
        max-width: 960px;
        margin: 0 auto;
        p {
          @include fl(20, 40);
          @include sp() {
            @include fl(14, 28);
          }
          &.philosophy__signature {
            text-align: right;
          }
          + p {
            margin-top: 24px;
            @include sp() {
              margin-top: 20px;
            }
          }
        }
      }
      &__item {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin-top: 60px;
        background: $zero__bg-color--grey;
        @include sp() {
          margin-top: 36px;
        }
        &__company {
          display: flex;
          align-items: center;
          margin: 0 auto;
          padding: 36px;
          @include sp() {
            display: block;
            padding: 24px;
          }
          dt {
            flex: 1;
            @include sp() {
              margin: 0 auto 20px;
            }
            svg {
              display: block;
              margin: 0 auto;
              width: 100%;
              max-width: 196px;
              @include sp() {
                max-width: 128px;
              }
              path,
              polygon {
                fill: $zero__text-color--grey;
              }
            }
          }
          dd {
            flex: 1.5;
            margin-left: 60px;
            @include sp() {
              margin: 0;
            }
            h3 {
              @include fl(18, 29);
              color: $zero__text-color--grey;
              font-weight: 900;
              margin: 0 0 14px;
              @include sp() {
                @include fl(15, 24);
                margin: 0 0 12px;
                br {
                  display: none;
                }
              }
            }
            p {
              @include fl(14, 21);
              color: $zero__text-color--grey;
              @include sp() {
                @include fl(12, 18);
              }
            }
          }
        }
      }
      &__pagetop {
        margin-top: 96px;
        text-align: center;
        @include sp() {
          margin-top: 60px;
        }
        &__btn {
          display: block;
          width: 116px;
          margin: 0 auto;
          transition: opacity 300ms ease;
          &:hover {
            opacity: 0.7;
            transition: opacity 300ms ease;
          }
          @include sp() {
            width: 96px;
          }
          svg {
            path {
              fill: #000;
            }
          }
        }
      }
    }
  }
}
picture {
  display: block;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
}

// for loaded
.loadCompleted .keyVisual__image {
  opacity: 1;
  transition: opacity 300ms ease 600ms;
}

// for scroll fade
.section-fade {
  &--standby {
    opacity: 0;
    transform: translate(0, 40px);
  }
  &--visible {
    opacity: 1;
    transform: none;
    transition: opacity 1024ms ease, transform 1024ms ease;
  }
}
#zero__header {
  display: block;
  width: 100%;
  background: #000;
  position: fixed;
  z-index: 10;
  top: -80px;
  left: 0;
  transition: transform 300ms ease;
  padding-left: $menu-width-md + $summary-width + 60px;
  padding-right: 60px;
  @include md {
    padding-left: $menu-width-md + $summary-width-md-close + 60px;
  }
  @include sp {
    padding-left: 0;
  }
  @include under-tab() {
    display: none;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #efefef;
    &::after {
      content: "";
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.75);
      position: fixed;
      z-index: 1;
      top: 50px;
      right: -100vw;
      transition: transform 300ms ease;
    }
  }
  .content__inner {
    max-width: 1320px;
    display: flex;
    align-items: center;
    height: 80px;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    @include under-tab() {
      height: 50px;
    }
    @include sp() {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  h1 {
    width: 100%;
    max-width: 192px;
    @include under-tab() {
      max-width: 128px;
      margin: 0;
    }
    a {
      display: block;
    }
    svg {
      display: block;
      path {
        fill: #fff;
        @include under-tab() {
          fill: #000;
        }
      }
    }
  }
  nav {
    margin: 0 24px 0 auto;
    @include under-tab() {
      width: 160px;
      height: 100vh;
      margin: 0;
      padding: 24px;
      background: #fff;
      position: fixed;
      z-index: 2;
      top: 50px;
      right: -160px;
      border-top: 1px solid $zero__bg-color--grey;
      transition: transform 300ms ease;
    }
    ul {
      display: flex;
      @include under-tab() {
        display: block;
      }
      li {
        a {
          display: block;
          @include fl(16, 16);
          transition: opacity 300ms ease;
          &:hover {
            opacity: 0.7;
            transition: opacity 300ms ease;
          }
          svg {
            display: block;
            height: 11px;
            path {
              fill: #fff;
              @include under-tab() {
                fill: #000;
              }
            }
          }
        }
        + li {
          margin-left: 24px;
          @include under-tab() {
            margin: 24px 0 0;
          }
        }
      }
    }
    body.nav-open & {
      transform: translate(-100%, 0);
      transition: transform 300ms ease;
    }
  }
  .zero__header__simulation {
    border-radius: 128px;
    padding: 21px 38px 21px 60px;
    color: $zero__text-color--blue;
    background: #fff;
    @include fl(17, 0);
    font-weight: 700;
    transition: background-color 300ms ease;
    position: relative;
    small {
      display: inline-block;
      padding: 4px 6px 4px 8px;
      background-color: $zero__text-color--blue;
      border-radius: 24px;
      @include fl(12, 12);
      line-height: 0.9; // Hack to make the conditions the same on iphone and others.
      font-weight: 700;
      letter-spacing: 0.2rem;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
    }
    &:after {
      content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%233c7d9b%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
      font-size: 0;
      display: block;
      width: 15px;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
      transition: transform 300ms ease;
    }
    &:hover {
      background-color: $zero__text-color--blue;
      color: #fff;
      transition: background-color 300ms ease;
      small {
        background-color: #fff;
        color: $zero__text-color--blue;
      }
      &:after {
        content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
      }
    }
    @include under-tab() {
      display: none;
    }
  }
  .zero__header__touch-device-navigation {
    display: none;
    width: 18px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
    span {
      display: block;
      width: 18px;
      height: 2px;
      background: #000;
      text-indent: -9999px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 300ms ease;
    }
    &::before {
      content: "";
      width: 18px;
      height: 2px;
      background: #000;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 300ms ease;
    }
    &::after {
      content: "";
      width: 18px;
      height: 2px;
      background: #000;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 300ms ease;
    }
    @include under-tab() {
      display: block;
    }
  }
  &.isView {
    transform: translate(0, 100%);
    transition: transform 300ms ease;
    @include under-tab() {
      transform: none;
    }
  }
  body.nav-open & {
    &::after {
      @include under-tab() {
        transform: translate(-100vw, 0);
        transition: transform 300ms ease;
      }
    }
    .zero__header__touch-device-navigation {
      span {
        transform: translate(-50%, -50%) scaleX(0);
        transition: transform 300ms ease;
      }
      &::before {
        top: 7px;
        transform: translate(-50%, 0) rotate(45deg);
        transition: transform 300ms ease;
      }
      &::after {
        bottom: 7px;
        transform: translate(-50%, 0) rotate(-45deg);
        transition: transform 300ms ease;
      }
    }
  }
}

#js-stalker {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: 61px;
  background: rgba(0, 0, 0, 0.75);
  left: 0;
  bottom: 0;
  transition: transform 300ms ease;
  .scrollEnd & {
    transform: translate(0, 100%);
    transition: transform 300ms ease;
  }
  padding-left: $menu-width-md + $summary-width;
  @include under-tab() {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include md {
    padding-left: $menu-width-md + $summary-width-md-close;
  }
  @include sp {
    padding-left: 0;
  }
  .zero__stalker__simulation {
    display: inline-block;
    width: fit-content;
    border-radius: 128px;
    padding: 21px 38px 21px 60px;
    color: $zero__text-color--blue;
    background: #fff;
    @include fl(17, 0);
    font-weight: 700;
    transition: background-color 300ms ease;
    position: relative;
    small {
      display: inline-block;
      margin: 0;
      padding: 5px 5px 5px 8px;
      background-color: $zero__text-color--blue;
      border-radius: 24px;
      @include fl(12, 12);
      line-height: 0.8; // Hack to make the conditions the same on iphone and others.
      letter-spacing: 0.2rem;
      font-weight: 700;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
    }
    &:after {
      content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%233c7d9b%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
      font-size: 0;
      display: block;
      width: 15px;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
      transition: transform 300ms ease;
    }
    &:hover {
      background-color: $zero__text-color--blue;
      color: #fff;
      transition: background-color 300ms ease;
      small {
        background-color: #fff;
        color: $zero__text-color--blue;
      }
      &:after {
        content: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025.8%2020.96%22%3E%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2215.32%200%2013.91%201.41%2021.98%209.48%200%209.48%200%2011.48%2021.98%2011.48%2013.91%2019.55%2015.32%2020.96%2025.8%2010.48%2015.32%200%22%20%2F%3E%20%3C%2Fsvg%3E");
      }
    }
  }
}
</style>
