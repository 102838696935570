<template>
  <div>
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      :isShort="true"
    >
      <b-input
        :name="internalName"
        :icon="icon"
        :size="size"
        :type="type"
        :placeholder="placeholder || label + 'を入力してください'"
        v-model="internalValue"
        :data-vv-as="label"
        v-validate="validate"
        :maxlength="7"
      >
      </b-input>
    </ZField>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";
import axios from "axios-jsonp-pro";

export default {
  name: "ZPostalcode",
  mixins: [InputMixin],
  props: {
    value: {
      type: [String],
      default: null,
    },
  },
  watch: {
    async internalValue(postalCode) {
      await this.getData(postalCode);
    },
  },
  methods: {
    async getData(postalCode) {
      if (!postalCode || postalCode.length < 7) {
        return;
      }
      let url = "";
      if (this.$$env$isProduction) {
        url = "https://postcode.teraren.com/postcodes/" + postalCode + ".json";
      } else {
        url = "http://localhost:4003/api/postcodes/" + postalCode + ".json";
      }

      const res = await axios.get(url);
      //console.log(res);

      const prefectureCode = this.$$ks.getValue(
        this.$$ks.prefectures,
        res.data.prefecture
      );

      this.$emit("chageAddress", {
        prefectureCode: prefectureCode.value,
        prefectureText: res.data.prefecture,
        cityCode: res.data.jis,
        cityText: res.data.city,
        subUrb: res.data.suburb,
        address: res.data.suburb,
        fullCityText: res.data.city + res.data.suburb,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
