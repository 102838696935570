<template>
  <div class="formBuyingNeedUnit">
    <section class="edit__section">
      <h3 class="edit__subtitle">ニーズ情報</h3>

      <z-checkbox
        label="物件種別"
        v-model="value.realEstateTypes"
        :validate="'required'"
        :options="realEstateTypes"
        isRequired
      />

      <z-checkbox
        label="建物種別"
        v-model="value.buildingTypes"
        :validate="'required'"
        :options="typeOfBuildingTypes"
        helpMessage="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
        isRequired
      />

      <section class="areaLineFieldSet">
        <h4 class="edit__subtitle2">エリア・沿線</h4>
        <ZAreaLineFieldSet v-model="value" :validate="'required'" isRequired />
      </section>

      <z-price-from-to-select
        label="価格"
        v-model="value.askingPrice"
        :validate="'required'"
        isRequired
      />

      <z-rate-from-to-select
        label="表面利回り"
        v-model="value.couponYieldRate"
        :validate="'required'"
        isRequired
      />

      <z-transport-select
        label="最寄り駅までの徒歩分数"
        v-model="value.transport"
        :validate="'required'"
        isRequired
      />

      <z-age-ofbuilding-from-to-select
        label="築年数"
        v-model="value.ageOfBuilding"
        :validate="'required'"
        isRequired
      />
    </section>

    <hr class="edit__hr" />

    <section class="edit__section">
      <h3 class="edit__subtitle">提案依頼情報</h3>

      <z-price-input
        label="自己資金・担保可能金額"
        v-model="value.ownFund"
        :validate="'required|price_min_value:0'"
        isRequired
        maxlength="12"
        placeholder="例)10,000,000"
      />

      <z-comment
        label="一言コメント"
        v-model="value.comment"
        placeholder="一言コメントを記入してください"
        maxlength="600"
      />
    </section>
  </div>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "FormBuyingNeedUnit",
  inject: ["$validator"],
  props: {
    value: Object,
  },
  data() {
    return {
      realEstateTypes: kubuns.realEstateTypes,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
    };
  },
  // components: {
  // },
  // computed: {
  // },
  // methods: {
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.formBuyingNeedUnit {
  .edit__section--box {
    margin-top: 50px;
  }
  .opt-margin-top {
    margin-top: 72px;
  }
}
</style>
