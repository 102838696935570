import firebase from "@/plugins/firebase";
import {
  cloneDeep,
  //  defaultsDeep,
} from "lodash";

const filterType = {
  // TODO: 項目入れて下さい。
  updatedBy: null,
  updatedDt: null,
  createdBy: null,
  createdDt: null,
  registerDt: null,
};

export const createNewTempate = () => {
  return {
    id: null,
    data: cloneDeep(filterType),
  };
};

const docRef = (uid) =>
  firebase.db.collection("users_v1").doc(uid).collection("investor_filters_v1");

// compnayID
export const loads = async (uid) => {
  const qs = await docRef(uid).get();
  const ls = [];
  qs.docs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: doc.data(),
    });
  });
  return ls;
};

export const load = async (uid, id) => {
  const doc = await docRef(uid).doc(id).get();
  if (doc.exists) {
    return {
      id: doc.id,
      data: doc.data(),
    };
  } else {
    return null;
  }
};

export const save = async (uid, targetUID, filter) => {
  const now = new Date();
  filter.data.updatedDt = now;
  filter.data.updatedBy = uid;
  if (filter.id) {
    await docRef(targetUID).doc(filter.id).set(filter.data);
  } else {
    filter.data.createdDt = now;
    filter.data.createdBy = uid;
    filter.data.registerDt = now;
    const ref = await docRef(targetUID).add(filter.data);
    filter.id = ref.id;
  }
  return filter;
};
