import * as realestate from "@/modules/investor/realestate";
import { isNil, cloneDeep, defaultsDeep, round, intersection } from "lodash";

const slice = function (ls, num, page) {
  return {
    countOfData: num, // 取得件数
    pagination: page, // ページ数
    maxCount: ls.length, //全取得数
    data: ls.slice(page * num, num * (page + 1)), //返すデータ
  };
};

export const searchBankRank = async (filter) => {
  const ret = await realestate.searchBankRank(filter.marketTrendFilter);
  const rs = ret.aggregations.value_count_total_price.buckets
    .map((b, idx) => {
      return {
        ranking: idx + 1,
        bank: b.key,
        bankName: b.bank_name.buckets[0].key,
        imagePath: "/bank/" + b.key,
        number: b.doc_count,
        interestRate: {
          from: b.rate_min.value,
          to: b.rate_max.value,
        },
      };
    })
    .slice(
      filter.pagination * filter.getNumber,
      filter.getNumber * (filter.pagination + 1)
    );

  const rets = {
    data: rs,
    countOfData: rs.length,
    maxCount: ret.aggregations.value_count_total_price.buckets.length,
    pagination: filter.pagination,
  };
  return rets;
};

export const searchRealestes = async (filter) => {
  // console.log(filter);
  const ret = await realestate.searchByfilter(
    {
      ...filter.marketTrendFilter,
      ...filter.selectedFilter,
      bank: filter.bank,
    },
    filter.sortType,
    filter.pagination + 1,
    filter.getNumber
  );

  const rets = {
    data: ret.hits.hits,
    pagination: filter.pagination,
    maxCount: ret.hits.total.value,
  };
  // console.log(rets);
  return rets;
};

export const searchYieldCharts = async (
  filter,
  owner = null,
  force = false
) => {
  const ret = await realestate.searchYieldCharts(
    filter.marketTrendFilter,
    filter.scoreRange,
    filter.couponYieldRateRange
  );
  const rs = [];
  for (const s of ret.aggregations.scores.buckets) {
    for (const p of s.yields.buckets) {
      if (p.doc_count > 0) {
        rs.push({
          score: {
            from: s.from,
            to: s.to || 1000,
          },
          couponYieldRate: {
            from: p.from,
            to: p.to || 100,
          },
          count: p.doc_count,
        });
      }
    }
  }
  return rs;
};

// export const searchYieldCharts = async filter => {
//   const ret = await realestate.searchByfilter(filter.marketTrendFilter);
//   const res = ret.hits.hits;
//
//   const rets = [];
//   for (const scoreRange of filter.scoreRange) {
//     const minSs = scoreRange.from || 0;
//     const maxSs = scoreRange.to || 1000;
//     for (const couponYieldRateRange of filter.couponYieldRateRange) {
//       let count = 0;
//       const minPs = couponYieldRateRange.from || 0;
//       const maxPs = couponYieldRateRange.to || 10;
//       for (const re of res) {
//         const investor = re._source.user.user;
//         if (
//           isRange(investor.data.latestScore.score, minSs, maxSs) &&
//           isRange(re._source.data.couponYieldRate, minPs, maxPs)
//         ) {
//           count++;
//         }
//       }
//       rets.push({
//         score: {
//           from: minSs, //ss,
//           to: maxSs
//         },
//         couponYieldRate: {
//           from: minPs, //ps,
//           to: maxPs
//         },
//         count: count
//       });
//     }
//   }
//   return rets.filter(r => {
//     return r.count > 0;
//   });
// };

export const searchPriceCharts = async (
  filter,
  owner = null,
  force = false
) => {
  const ret = await realestate.searchPriceCharts(
    filter.marketTrendFilter,
    filter.scoreRange,
    filter.priceRange
  );
  const rs = [];
  for (const s of ret.aggregations.scores.buckets) {
    for (const p of s.prices.buckets) {
      if (p.doc_count > 0) {
        rs.push({
          score: {
            from: s.from,
            to: s.to || 1000,
          },
          amount: {
            from: p.from,
            to: p.to || 1000000000000,
          },
          count: p.doc_count,
        });
      }
    }
  }
  return rs;
};

// export const searchPriceChart = async (filter, owner = null, force = false) => {
//   const ret = await realestate.searchByfilter(filter.marketTrendFilter);
//   const res = ret.hits.hits;
//   const rets = [];
//
//   // console.log(res);
//   for (const scoreRange of filter.scoreRange) {
//     const minSs = scoreRange.from || 0;
//     const maxSs = scoreRange.to || 1000;
//     for (const priceRange of filter.priceRange) {
//       let count = 0;
//       const minPs = priceRange.from || 0;
//       const maxPs = priceRange.to || MAX_PRICE;
//       for (const re of res) {
//         const investor = re._source.user.user;
//         if (
//           isRange(investor.data.latestScore.score, minSs, maxSs) &&
//           isRange(re._source.data.purchase.amount, minPs, maxPs)
//         ) {
//           count++;
//         }
//       }
//       rets.push({
//         score: {
//           from: minSs, // ss,
//           to: maxSs
//         },
//         amount: {
//           from: minPs, // ps,
//           to: maxPs
//         },
//         count: count
//       });
//     }
//   }
//   // console.log(rets);
//   return rets.filter(r => {
//     return r.count > 0;
//   });
//
//   // return rets;
// };
