<script>
export default {
  name: "Mixins-UserAgent",

  methods: {
    $$userAgent$getUserAgent() {
      return window.navigator.userAgent.toLowerCase();
    },
    $$userAgent$getAgentVersion() {
      return window.navigator.appVersion.toLowerCase();
    },
    // ブラウザ名を取得
    $$userAgent$getBowser() {
      const ua = this.$$userAgent$getUserAgent();
      const ver = this.$$userAgent$getAgentVersion();
      if (ua.indexOf("edge") !== -1) return "edge";
      if (ua.indexOf("trident/7") !== -1) return "ie11";
      if (ua.indexOf("msie") !== -1 && ua.indexOf("opera") === -1) {
        if (ver.indexOf("msie 6.") !== -1) return "ie6";
        if (ver.indexOf("msie 7.") !== -1) return "ie7";
        if (ver.indexOf("msie 8.") !== -1) return "ie8";
        if (ver.indexOf("msie 9.") !== -1) return "ie9";
        if (ver.indexOf("msie 10.") !== -1) return "ie10";
      }
      if (ua.indexOf("chrome") !== -1 && ua.indexOf("edge") === -1)
        return "chrome";
      if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1)
        return "safari";
      if (ua.indexOf("opera") !== -1) return "opera";
      if (ua.indexOf("firefox") !== -1) return "firefox";
      return "";
    },
    // モバイルブラウザか判別
    $$userAgent$getIsMobile() {
      const ua = this.$$userAgent$getUserAgent();
      if (
        ua.indexOf("ipad") !== -1 ||
        ua.indexOf("iphone") !== -1 ||
        ua.indexOf("ipod") !== -1 ||
        ua.indexOf("android") !== -1 ||
        (ua.indexOf("firefox") !== -1 && ua.indexOf("tablet") !== -1) ||
        (ua.indexOf("firefox") !== -1 && ua.indexOf("mobile") !== -1) ||
        (ua.indexOf("windows") !== -1 && ua.indexOf("phone") !== -1) ||
        (ua.indexOf("windows") !== -1 &&
          ua.indexOf("touch") !== -1 &&
          ua.indexOf("tablet pc") === -1) ||
        ua.indexOf("kindle") !== -1 ||
        ua.indexOf("silk") !== -1 ||
        ua.indexOf("playbook") !== -1 ||
        ua.indexOf("blackberry") !== -1
      ) {
        return true;
      }
      return false;
    },
    // ieのチェック
    $$userAgent$isIE() {
      return this.$$userAgent$getBowser().indexOf("ie") !== -1;
    },
    // iPadのチェック
    $$userAgent$isIPad() {
      return this.$$userAgent$getUserAgent().indexOf("ipad") !== -1;
    },
  },
};
</script>
