import { render, staticRenderFns } from "./ZDocsInput.vue?vue&type=template&id=8af8dc80&scoped=true&"
import script from "./ZDocsInput.vue?vue&type=script&lang=js&"
export * from "./ZDocsInput.vue?vue&type=script&lang=js&"
import style0 from "./ZDocsInput.vue?vue&type=style&index=0&id=8af8dc80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8af8dc80",
  null
  
)

export default component.exports