<template>
  <section class="section">
    <h3 class="list-title">パスワードリセット</h3>
    <div class="columns is-centered is-gapless">
      <div class="column is-narrow">
        <div class="box is-message-box">
          <div class="content is-small has-text-centered">
            <p>
              登録したメールアドレスに<br />パスワードをリセットするための<br
                class="br-show"
              />メールを送信します。
            </p>
          </div>

          <div class="z-input-vritical-warp">
            <z-input
              label="メールアドレス"
              name="email"
              v-model="email"
              type="email"
              placeholder="メールアドレスを入力してください"
              maxlength="512"
              :validate="'required|email'"
              icon="email"
              isRequired
            >
            </z-input>
          </div>
          <div class="buttons is-centered">
            <button
              :disabled="$$validator$invalid"
              class="button is-primary is-rounded is-wide z-button is-small"
              @click="reset"
            >
              メール送信
            </button>
          </div>
          <div class="member-login">
            <router-link :to="{ name: 'auth-signin' }">こちら</router-link
            >からログインをやり直す
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import firebase from "firebase/app";
import firebase from "@/plugins/firebase";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    querys: function (email, uuid, now) {
      const qus = Object.assign({ email: email }, this.$route.query);
      return Object.keys(qus)
        .map((k) => k + "=" + qus[k])
        .reduce((qs, q) => qs + "&" + q, "?q=" + uuid + "&" + "p=" + now);
    },
    async reset() {
      const loadingComponent = this.$loading.open();
      try {
        const email = this.email;
        //const actionCodeSettings = {
        //  url: location.protocol + "//" + location.host + "/signin",
        //  handleCodeInApp: true
        //};
        const uuid = this.$uuid.v4();
        const now = Date.now();
        const link =
          location.protocol +
          "//" +
          location.host +
          "/auth/resetpassword/confirm" +
          this.querys(encodeURIComponent(email), uuid, now);

        console.log(link);
        await firebase.functions("sendMailOfResetPassword", {
          to: {
            name: email,
            email: email,
          },
          email: email,
          link: link,
        });
        this.$$toast$openSuccess("メールを送信しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("メールの送信に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0066ffeb;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.box {
  border-radius: 0px;
  padding: 30px 50px;
  .content {
    margin-bottom: 30px;
    p {
      font-size: 14px;
    }
  }
  button {
    margin-top: 15px;
  }
}

.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
.member-login {
  font-size: 10px;
  text-align: right;
  margin-top: 10px;
}

@include sp {
  .section {
    width: 440px;
  }
}
</style>
