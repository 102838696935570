<template>
  <div>
    <section>
      <ZHeader title="物件掲載管理-編集" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <a @click="$router.go(-1)"> 前に戻る </a>
        <z-switch
          name="overseasRealEstateRegistration"
          v-model="realEstate.data.overseasRealEstate"
          class="registration-btn"
        >
          海外物件登録
        </z-switch>
        <hr class="edit__hr edit__hr-first" />
        <section class="edit__section">
          <section class="edit__section">
            <h3 class="edit__subtitle">販売情報</h3>
            <z-input
              label="物件名"
              v-model="realEstate.data.name"
              maxlength="100"
              :validate="'required'"
              isRequired
            />
            <z-select
              label="物件種別"
              v-model="realEstate.data.realEstateType"
              :options="realEstateTypes"
              :validate="'required'"
              isRequired
            />
            <z-price-input
              label="販売価格"
              v-model="realEstate.data.price"
              maxlength="12"
              placeholder="例)100,000,000"
              :validate="'required|price_min_value:1 '"
              isRequired
            />

            <z-price-input
              label="年間想定収入"
              v-model="realEstate.data.annualEstimatedIncome"
              :validate="''"
              maxlength="12"
              placeholder="例)20,000,000"
              helpMessage="当該物件が満室、かつ、想定賃料（又は現在の賃料）が一年間得られた場合の収入を想定。公租公課その他物件を維持するために必要な費用（管理費等）を控除する前のもの。"
            />

            <z-percent-input
              label="表面利回り"
              v-model="realEstate.data.couponYieldRate"
              :validate="'max_value:100'"
              :decimal="2"
              maxlength="6"
              placeholder="例)8.00"
              isShort
            />
            <template
              v-if="
                realEstate.data.realEstateType !== 7 &&
                realEstate.data.realEstateType !== 10
              "
            >
              <z-select
                label="現況空室率"
                v-model="realEstate.data.vacancyRate"
                :options="vacancyRates"
                :validate="'required'"
                isRequired
              />
            </template>
            <template v-if="realEstate.data.vacancyRate === 2">
              <z-input
                label="空き室数"
                v-model="realEstate.data.vacantRoom"
                :validate="'required'"
                placeholder="例)10部屋"
                isRequired
                maxlength="30"
              />
            </template>
            <z-input
              label="PR/注意事項"
              type="textarea"
              v-model="realEstate.data.pr"
              maxlength="1000"
            />

            <!-- 物件画像 -->
            <h3 class="edit__subtitle">物件画像</h3>
            <ZField label="外観">
              <ZThumbnailListSelect
                v-model="realEstate.data.images.exteriors"
                title="外観"
                :max="10"
              />
            </ZField>

            <hr class="edit__hr2" />

            <ZField label="内装">
              <ZThumbnailListSelect
                v-model="realEstate.data.images.interiors"
                title="内装"
                :max="10"
              />
            </ZField>

            <hr class="edit__hr2" />

            <ZField label="間取り図・プラン">
              <ZThumbnailListSelect
                v-model="realEstate.data.images.layouts"
                title="間取り図・プラン"
                :max="10"
              />
            </ZField>

            <hr class="edit__hr2" />

            <ZField label="その他">
              <ZThumbnailListSelect
                v-model="realEstate.data.images.others"
                title="その他"
                :max="10"
              />
            </ZField>

            <!-- / 物件画像 -->
          </section>
          <hr class="edit__hr" />

          <section class="edit__section">
            <template v-if="realEstate.data.overseasRealEstate">
              <h3 class="edit__subtitle">所在地</h3>
              <z-select
                label="国名"
                v-model="realEstate.data.overseas.country"
                :options="countries"
                :validate="'required'"
                isRequired
              >
              </z-select>

              <z-input
                label="都市・州"
                v-model="realEstate.data.overseas.state"
                :validate="'required'"
                maxlength="100"
                isRequired
              >
              </z-input>

              <z-input
                label="住所詳細"
                v-model="realEstate.data.overseas.address"
                maxlength="100"
                :validate="'required'"
                isRequired
              />

              <z-input
                label="マンション名・部屋番号"
                v-model="realEstate.data.overseas.building"
                maxlength="100"
              />

              <!-- 交通 -->
              <h3 class="edit__subtitle edit__transportation">交通</h3>

              <ul class="edit__list">
                <li
                  v-for="(transport, i) in realEstate.data.overseas.transports"
                  :index="i"
                  :key="i"
                  class="edit__list__item"
                >
                  <z-edit-accordion :title="'交通' + (i + 1)" isProtrude>
                    <div>
                      <z-input label="沿線" v-model="transport.line"> </z-input>
                      <z-input
                        label="駅"
                        v-model="transport.station"
                        placeholder="駅名を入力してください"
                      >
                      </z-input>

                      <z-input
                        label="駅からの距離（徒歩）"
                        v-model.number="transport.elapsedTime"
                        type="number"
                        maxlength="2"
                        placeholder="例)8"
                        unit="分"
                        isShort
                      />

                      <div class="has-text-right">
                        <delete-button
                          v-if="i > 0"
                          label="削除する"
                          @click="deleteTransport(i)"
                        />
                      </div>
                    </div>
                  </z-edit-accordion>
                </li>
              </ul>
              <add-button label="追加する" @click="addTransport()" />
            </template>
            <template v-else>
              <h3 class="edit__subtitle">所在地</h3>
              <z-prefecture-select
                label="都道府県"
                v-model="realEstate.data.prefecture"
                :validate="'required'"
                isRequired
              >
              </z-prefecture-select>

              <z-city-select
                label="市区町村"
                v-model="realEstate.data.city"
                :prefecture="realEstate.data.prefecture"
                :validate="'required'"
                isRequired
              >
              </z-city-select>

              <z-input
                label="番地"
                v-model="realEstate.data.address"
                placeholder="番地：1-1-1"
                maxlength="30"
              />

              <z-input
                label="マンション名・部屋番号"
                v-model="realEstate.data.building"
                maxlength="40"
                placeholder="マンション名：ZIRITZ TOWER 1111号室"
              />

              <!-- 交通 -->
              <h3 class="edit__subtitle edit__transportation">交通</h3>

              <ul class="edit__list">
                <li
                  v-for="(transport, i) in realEstate.data.transports"
                  :index="i"
                  :key="i"
                  class="edit__list__item"
                >
                  <z-edit-accordion :title="'交通' + (i + 1)" isProtrude>
                    <div>
                      <z-line-select
                        label="沿線"
                        :prefecture="realEstate.data.prefecture"
                        v-model="transport.line"
                        :validate="'required'"
                        isRequired
                      >
                      </z-line-select>
                      <z-station-select
                        label="駅"
                        :line="transport.line"
                        v-model="transport.station"
                        :validate="'required'"
                        isRequired
                      >
                      </z-station-select>

                      <z-input
                        label="駅からの距離（徒歩）"
                        v-model.number="transport.elapsedTime"
                        :validate="'required'"
                        type="number"
                        maxlength="2"
                        placeholder="例)8"
                        unit="分"
                        isRequired
                        isShort
                      />

                      <div class="has-text-right">
                        <delete-button
                          v-if="i > 0"
                          label="削除する"
                          @click="deleteTransport(i)"
                        />
                      </div>
                    </div>
                  </z-edit-accordion>
                </li>
              </ul>
              <add-button label="追加する" @click="addTransport()" />
            </template>
          </section>
          <!-- / 交通 -->

          <hr class="edit__hr" />

          <!-- 建物情報 -->
          <template
            v-if="
              realEstate.data.realEstateType !== 7 &&
              realEstate.data.realEstateType !== 10
            "
          >
            <section class="edit__section">
              <h3 class="edit__subtitle">建物情報</h3>

              <z-select
                label="建物種別"
                v-model="realEstate.data.buildingType"
                :options="typeOfBuildingTypes"
                helpMessage="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
                :validate="'required'"
                isRequired
              />

              <z-year-month-select
                label="築年月"
                v-model.number="realEstate.data.constructionDate"
                :validate="'required'"
                isRequired
              />

              <z-input
                label="建物面積"
                v-model.number="realEstate.data.buildingArea"
                :validate="'required|max_value:99999.99'"
                placeholder="例)100.00"
                unit="㎡"
                isRequired
                isShort
              />

              <z-input
                label="階数"
                v-model="realEstate.data.numberOfFloors"
                :validate="'required'"
                maxlength="30"
                placeholder="例)1階/6階建て"
                helpMessage="建物の階数、及び物件の階数。"
                isRequired
              />

              <!-- 間取り -->
              <!-- <section class="edit__section"> -->
              <h3 class="edit__subtitle edit__layout">間取り</h3>

              <ul class="edit__list">
                <li
                  v-for="(lo, i) in realEstate.data.saleSetting.layouts"
                  :index="i"
                  :key="i"
                  class="edit__list__item"
                >
                  <z-edit-accordion :title="'間取り' + (i + 1)" isProtrude>
                    <div>
                      <z-layout-select
                        label="間取"
                        :validate="'required'"
                        isRequired
                        v-model="realEstate.data.saleSetting.layouts[i]"
                      />
                      <div class="has-text-right">
                        <delete-button
                          label="削除する"
                          @click="deleteLayout(i)"
                        />
                      </div>
                    </div>
                  </z-edit-accordion>
                </li>
              </ul>
              <add-button label="追加する" @click="addLayout()" />
              <!-- </section> -->
              <!--/ 間取り -->
              <!-- <hr class="edit__hr" /> -->
              <div class="edit__total-units">
                <z-input
                  label="総戸数"
                  v-model="realEstate.data.saleSetting.numberOfHouseholds"
                  type="number"
                  maxlength="4"
                  placeholder="例)12"
                  helpMessage="その物件にある住戸の数の合計数。"
                  unit="戸"
                  isShort
                />
              </div>

              <z-input
                label="駐車場"
                v-model="realEstate.data.saleSetting.parking"
                maxlength="100"
                placeholder="例)屋根付き、10車まで駐車可能"
                helpMessage="駐車場の有無。台数、料金などを表示。"
              />
            </section>

            <hr class="edit__hr" />
          </template>
          <!-- / 建物情報 -->

          <!-- <hr class="edit__hr" /> -->

          <!-- 土地情報 -->
          <section class="edit__section">
            <h3 class="edit__subtitle">土地情報</h3>

            <z-input
              :key="isKubunApartment + 'landarea'"
              label="土地面積"
              v-model.number="realEstate.data.saleSetting.landArea"
              placeholder="例)100.00"
              unit="㎡"
              :validate="{ required: !isKubunApartment, max_value: 99999.99 }"
              :isRequired="!isKubunApartment"
            />

            <z-select
              label="土地権利"
              v-model.number="realEstate.data.saleSetting.landRight"
              :options="landRightTypes"
              :validate="'required'"
              isRequired
              helpMessage="土地の権利形態。大きく、一般的な土地の売買である「所有権」、他人から土地を借りて、その上に自分の建物を建てる場合に生じる「借地権」がある。"
            />

            <z-select
              label="地目"
              v-model.number="realEstate.data.saleSetting.texture"
              :options="textureTypes"
              helpMessage="土地の現況と利用状況による区分のこと。「宅地」、「雑種地」、「畑」、「用悪水路」、「その他」で表示。"
            />

            <z-radio
              label="都市計画区域"
              v-model.number="realEstate.data.saleSetting.urbanPlanningArea"
              :options="urbanPlanningAreaTypes"
              helpMessage="都道府県ごとに定められ、無秩序な市街化を防止し、計画的な市街化を図るために定めている。「市街化区域」：優先的かつ計画的に市街化を進める区域「市街化調整区域」：開発行為は原則として抑制され、都市施設の整備も原則として行われない区域"
            />

            <z-select
              label="用途地域"
              v-model.number="realEstate.data.saleSetting.areaOfUse"
              :options="areaOfUseTypes"
              helpMessage="用途の混在を防ぐ目的で定められた、13種類の区域。設定された区域ごとに建築物の用途、容積率、構造等に関し一定の制限を加えることができる。"
            />

            <z-percent-input
              label="建蔽率"
              v-model.number="realEstate.data.saleSetting.buildingCoverageRatio"
              :decimal="2"
              placeholder="例)60"
              maxlength="3"
              isShort
              helpMessage="敷地（土地）面積に対する建築面積の割合。"
            />

            <z-percent-input
              label="容積率"
              v-model.number="realEstate.data.saleSetting.floorAreaRatio"
              :decimal="2"
              placeholder="例)300"
              maxlength="4"
              isShort
              helpMessage="敷地（土地）面積に対する建物の延床面積の割合。"
            />

            <z-input
              label="私道負担面積"
              v-model.number="realEstate.data.saleSetting.roadBurdenAreaRatio"
              :validate="'max_value:99999.99'"
              placeholder="例)100.00"
              maxlength="6"
              unit="㎡"
              isShort
            />
          </section>
          <!-- / 土地情報 -->

          <hr class="edit__hr" />
          <!-- 社内・担当者管理情報-->
          <section class="edit__section">
            <h3 class="edit__subtitle">社内・担当者管理情報</h3>
            <z-select
              label="取引種別"
              v-model.number="realEstate.data.transactionType"
              :options="transactionTypes"
              :validate="'required'"
              isRequired
            />
            <template v-if="realEstate.data.transactionType === 5">
              <z-input
                label="元付会社"
                v-model="realEstate.data.company.name"
                placeholder="例)株式会社ZIRITZ"
                maxlength="32"
                :validate="'required'"
                isRequired
              />
              <z-input
                label="担当者名"
                v-model="realEstate.data.company.staffName"
                placeholder="例)須藤 九太郎"
                maxlength="100"
                :validate="'required'"
                isRequired
              />

              <z-input
                label="電話番号"
                v-model="realEstate.data.company.phoneNumber"
                placeholder="例)0123456789"
                maxlength="11"
                additionalMessage="ハイフン不要。携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
                :validate="'required'"
                isRequired
                isShort
              />
            </template>
            <template v-else>
              <z-input
                label="元付会社"
                v-model="realEstate.data.company.name"
                placeholder="例)株式会社ZIRITZ"
                maxlength="32"
              />
              <z-input
                label="元付会社 担当者名"
                v-model="realEstate.data.company.staffName"
                placeholder="例)須藤 九太郎"
                maxlength="100"
              />

              <z-input
                label="元付会社 電話番号"
                v-model="realEstate.data.company.phoneNumber"
                placeholder="例)0123456789"
                maxlength="11"
                additionalMessage="ハイフン不要。携帯電話など、ご連絡のつきやすい電話番号をご入力ください。"
                isShort
              />
            </template>

            <z-datepicker
              label="元付会社 広告承諾確認日"
              v-model="realEstate.data.company.AdConfirmationDate"
              placeholder="例)1990/04/01"
            />
          </section>
          <section class="edit__section">
            <z-datepicker
              label="更新予定日"
              v-model="realEstate.data.scheduledUpdateDate"
              placeholder="例)1990/04/01"
              :validate="'required'"
              :maxOfToday="false"
              isRequired
            />
            <z-user-select
              label="問い合せ担当者"
              :users="realtor.users"
              :validate="'required'"
              isRequired
              placeholder="担当者を選択してください"
              v-model="realEstate.data.staff"
            />
            <z-input
              label="備考・メモ"
              v-model="realEstate.data.memo"
              type="textarea"
              maxlength="500"
            />
            <z-radio
              label="公開範囲"
              :options="$$ks.realtor.realEstate.publicTypes"
              :validate="'required'"
              isRequired
              v-model="realEstate.data.saleSetting.publicType"
            />
          </section>
        </section>
      </div>
      <hr class="edit__hr" />

      <div class="edit__foot">
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button"
          >保存する</b-button
        >
      </div>
      <div v-if="realEstate.id" class="edit__delete reatltor-realEstate-delete">
        <delete-button label="削除する" @click="deleteRealEstate" />
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "realtor-setteing-realestates-detail",
  data: function () {
    return {
      isLoading: true,
      realEstate: null,
      // realtor: null,
      realEstateTypes: kubuns.realEstateTypes,
      typeOfBuildingTypes: kubuns.typeOfBuildingTypes,
      loanMethodTypes: kubuns.loanMethodTypes,
      landRightTypes: kubuns.landRightTypes,
      textureTypes: kubuns.textureTypes,
      urbanPlanningAreaTypes: kubuns.urbanPlanningAreaTypes,
      areaOfUseTypes: kubuns.areaOfUseTypes,
      deliveryDateMonths: kubuns.deliveryDateMonths,
      vacancyRates: kubuns.realtor.realEstate.vacancyRates,
      transactionTypes: kubuns.realtor.realEstate.transactionTypes,
      publicTypes: kubuns.realtor.realEstate.publicTypes,
      countries: kubuns.countries,
    };
  },
  computed: {
    realEstateId() {
      return this.$route.params.id;
    },
    isKubunApartment() {
      if (this.realEstate && this.realEstate.data.realEstateType === 8) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async deleteRealEstate() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$deleteRealEstate(
          this.auth.uid,
          this.realEstate.id
        );
        this.$router.go(-1);
        this.$$toast$openSuccess("削除しました。");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveRealEstate(this.auth.uid, this.realEstate);
        this.$router.go(-1);
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      //const loadingComponent = this.$loading.open();
      try {
        if (this.realEstateId === "new") {
          this.realEstate = this.$$realtor$getNewRealEstate(
            this.me.user.company.id
          );
          this.addTransport();
          this.realtor = await this.$$realtor$loadRealtorById(
            this.me.user.company.id
          );
        } else {
          this.realEstate = await this.$$realtor$loadRealEstate(
            this.realEstateId
          );
          this.realtor = await this.$$realtor$loadRealtorById(
            this.realEstate.data.owner
          );
        }

        // this.realtor = await this.$$realtor$loadRealtorById(
        //   this.me.user.company.id
        // );
        this.realtor.users = this.$$realtor$filterByActivate(
          this.realtor.users
        );
        // this.realtor = await this.$$realtor$loadRealtorById(
        //   this.me.user.company.id
        // );
        // this.realtor.users = this.$$realtor$filterByActivate(
        //   this.realtor.users
        // );
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
    addTransport() {
      if (this.realEstate.data.overseasRealEstate) {
        let item = this.realEstate.data.overseas.transports;
        item.push(this.$$realEstate$getNewTransport(this.auth.uid));
      } else {
        let item = this.realEstate.data.transports;
        item.push(this.$$realEstate$getNewTransport(this.auth.uid));
      }
      // let item = this.realEstate.data.transports;
      // item.push(this.$$realEstate$getNewTransport(this.auth.uid));
    },
    deleteTransport(index) {
      if (this.realEstate.data.overseasRealEstate) {
        let item = this.realEstate.data.overseas.transports;
        item.splice(index, 1);
      } else {
        let item = this.realEstate.data.transports;
        item.splice(index, 1);
      }
      // let item = this.realEstate.data.transports;
      // item.splice(index, 1);
    },
    addLayout() {
      let item = this.realEstate.data.saleSetting.layouts;
      item.push(this.$$realtor$getNewLayout());
    },
    deleteLayout(index) {
      let item = this.realEstate.data.saleSetting.layouts;
      item.splice(index, 1);
    },
  },
  watch: {
    "realEstate.data.realEstateType": function () {
      if (
        this.realEstate.data.realEstateType === 10 ||
        this.realEstate.data.realEstateType === 7
      ) {
        this.realEstate.data.vacancyRate = null;
        this.realEstate.data.vacantRoom = null;
        this.realEstate.data.buildingType = null;
        this.realEstate.data.buildingArea = null;
        this.realEstate.data.numberOfFloors = null;
        this.realEstate.data.saleSetting.layouts = [];
        this.realEstate.data.saleSetting.numberOfHouseholds = null;
        this.realEstate.data.saleSetting.parking = null;
        this.realEstate.data.constructionDate = {
          month: null,
          year: null,
        };
      }
      // if (this.realEstate.data.realEstateType === 10) {
      //   this.realEstate.data.vacancyRate = null;
      //   this.realEstate.data.vacantRoom = null;
      //   this.realEstate.data.buildingType = null;
      //   this.realEstate.data.buildingArea = null;
      //   this.realEstate.data.numberOfFloors = null;
      //   this.realEstate.data.saleSetting.layouts = [];
      //   this.realEstate.data.saleSetting.numberOfHouseholds = null;
      //   this.realEstate.data.saleSetting.parking = null;
      //   this.realEstate.data.constructionDate = {
      //     month: null,
      //     year: null
      //   };
      // } else {
      //   this.realEstate.data.vacancyRate;
      //   this.realEstate.data.vacantRoom;
      //   this.realEstate.data.buildingType;
      //   this.realEstate.data.buildingArea;
      //   this.realEstate.data.numberOfFloors;
      //   this.realEstate.data.saleSetting.layouts;
      //   this.realEstate.data.saleSetting.numberOfHouseholds;
      //   this.realEstate.data.saleSetting.parking;
      //   this.realEstate.data.constructionDate;
      // }
    },
    "realEstate.data.overseasRealEstate": function () {
      if (this.realEstate.data.overseasRealEstate) {
        this.realEstate.data.prefecture = null;
        this.realEstate.data.city = null;
        this.realEstate.data.address = null;
        this.realEstate.data.building = null;
        this.realEstate.data.transports = [];
      } else {
        this.realEstate.data.overseas.country = null;
        this.realEstate.data.overseas.state = null;
        this.realEstate.data.overseas.address = null;
        this.realEstate.data.overseas.building = null;
        this.realEstate.data.overseas.transports = [];
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.formRealEstateUnit {
  .edit__section--box {
    margin-top: 50px;
  }
  .opt-margin-top {
    margin-top: 72px;
  }
}

.edit {
  &__transportation {
    font-size: 14px;
    color: #000000;
  }
  &__layout {
    font-size: 14px;
    color: #000000;
  }
  &__total-units {
    margin-top: 25px;
  }
  &__hr-first {
    margin-top: 5px;
  }
}

.registration-btn {
  margin-bottom: 0;
  text-align: right;
  font-weight: bold;
  /deep/ .z-field {
    margin-bottom: 0;
  }
}
</style>
