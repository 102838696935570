<template>
  <div class="menu">
    <nav class="menu__nav">
      <div class="menu__nav__head">
        <div class="menu__nav__brand">
          <a class="menu__nav__brand__logo" href="/">
            <img class="logo" src="@/assets/logo-mini.png" alt="StockFormer" />
            <img
              class="title"
              src="@/assets/logoTitle.png"
              alt="StockFormer Powered By ZIRITZ"
            />
          </a>
        </div>
        <div
          class="menu__nav__notification z-notification"
          v-if="bulma.isMobile"
        >
          <slot name="rightIcons" />
        </div>
        <div class="menu__nav__burger" @click="toggleNav" v-if="!hideMenu">
          <div class="burger" :class="{ 'is-active': isShowNav }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="menu__nav__tabs" :class="{ 'is-active': isShowNav }">
        <ul>
          <li
            :class="{ 'is-active': isActive(['realtor-route', 'realtor-my']) }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-my' }">
              <ZIcon name="dashboard" width="40" height="40" color="#fff" />
              <p>マイページ</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': isActive([
                'realtor-setting-realestates',
                'realtor-setting-realestates-detail',
                'realtor-setting-realestates-preview',
              ]),
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-setting-realestates' }">
              <ZIcon name="collection" width="40" height="40" color="#fff" />
              <p>物件掲載管理</p>
            </router-link>
          </li>
          <li
            :class="{ 'is-active': isActive(['realtor-setting-investors']) }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-setting-investors' }">
              <ZIcon name="transaction" width="40" height="40" color="#fff" />
              <p>投資家検索</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': isActive([
                'realtor-search-realestates-detail',
                'realtor-search-realestates',
              ]),
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-search-realestates' }">
              <ZIcon name="property" width="40" height="40" color="#fff" />
              <p>売却物件検索</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': isActive([
                'realtor-message',
                'realtor-message-detail',
              ]),
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-message' }">
              <ZIcon name="offer" width="40" height="40" color="#fff" />
              <p>メッセージ</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': isActive([
                'realtor-setting-basic',
                'realtor-setting-basic-edit',
                'realtor-setting-users-detail',
              ]),
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'realtor-setting-basic' }">
              <ZIcon name="portfolio" width="40" height="40" color="#fff" />
              <p>プロフィール</p>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div
      class="menu__background"
      :class="{ 'is-active': isShowNav || isShowMenu }"
      @click="closeMenu"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isLoading: true,
      isShowNav: false,
      isAdmin: false,
      isInvestor: false,
      isRealtor: false,
      isShowMenu: false,
    };
  },
  methods: {
    isActive(names) {
      return names.some((n) => this.$route.name === n);
    },
    closeMenu() {
      this.isShowNav = false;
    },
    signOut: async function () {
      return await this.$$user$signOut();
    },
    toggleNav() {
      this.isShowNav = !this.isShowNav;
    },
  },
  watch: {
    "bulma.isMobile": function () {
      this.closeMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.menu {
  //width: $menu-width;
  width: $menu-width-md;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: linear-gradient(#202c66, #242630);
  color: #fff;
  pointer-events: none;
  z-index: $zindex-menu;
  // @include md {
  //   width: $menu-width-md;
  // }
  @include sp {
    width: 100%;
    background: none;
  }
}
.menu__background {
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  position: fixed;
  background-color: #000;
  pointer-events: none;
  z-index: -1;
  @include sp {
    display: block;
    opacity: 0;
    transition: opacity 0.2s ease;
    &.is-active {
      opacity: 0.3;
      pointer-events: all;
    }
  }
}
.menu__nav {
  width: 100%;
  pointer-events: all;
  @include sp {
    height: 64px;
  }
}
.menu__nav__head {
  background: linear-gradient(#202c66, #242630);
  @include sp {
    height: $menu-height-sp;
    padding-left: 16px;
    display: flex;
  }
}
.menu__nav__brand {
  @include sp {
    height: 100%;
    flex: 1;
  }
}
.menu__nav__brand__logo {
  position: relative;
  display: block;
  white-space: nowrap;
  &:before {
    padding-top: 100%;
    display: block;
    content: "";
  }
  @include sp {
    height: 100%;
    padding-top: 17px;
    display: inline-block;
    &:before {
      content: none;
    }
  }
  .logo {
    //width: 48px;
    width: 32px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // @include md {
    //   width: 32px;
    // }
    @include sp {
      width: 32px;
      top: auto;
      left: auto;
      display: inline-block;
      position: relative;
      transform: none;
      vertical-align: middle;
    }
  }
  .title {
    width: 100px;
    height: auto;
    margin-left: 16px;
    display: none;
    @include sp {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.menu__nav__burger {
  width: 42px;
  display: none;
  cursor: pointer;
  @include sp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.menu__nav__tabs {
  // margin-top: 42px;
  margin-top: 32px;
  // @include md {
  //   margin-top: 32px;
  // }
  @include sp {
    margin-top: 0;
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }
  ul {
    @include sp {
      background: linear-gradient(#202c66, #242630);
    }
  }
  li {
    position: relative;
    // padding: 21px 0;
    padding: 16px 0;
    .lock__icon {
      display: none;
    }
    .lock__tooltip {
      display: none;
    }
    &.is-lock {
      .lock__tooltip {
        top: 40px;
        left: 0;
        // right: 30px;
        right: 10px;
        margin: auto;
        display: block;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease;
        // @include md {
        //   right: 10px;
        // }
        @include sp {
          display: none;
        }
      }
      .lock__icon {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        position: absolute;
        transform: translateX(100%);
      }
    }
    &:hover {
      .lock__tooltip {
        opacity: 1;
      }
    }
    // @include md {
    //   padding: 16px 0;
    // }
    @include sp {
      padding: 8px 0;
    }
    /deep/ .z-icon {
      // width: 40px;
      width: 32px;
      // @include md {
      //   width: 32px;
      // }
      @include sp {
        width: 28px;
      }
    }
    p {
      // font-size: 13px;
      font-size: 10px;
      opacity: 0;
      transition: opacity 0.2s ease;
      user-select: none;
      // @include md {
      //   font-size: 10px;
      // }
      @include sp {
        opacity: 1;
      }
    }
    a {
      width: 100%;
      height: 70px;
      display: block;
      position: relative;
      text-align: center;
      font-weight: bold;
      color: #fff;
      opacity: 0.6;
      transition: opacity 0.2s ease;
      &:hover {
        p {
          opacity: 1;
        }
      }
      @include sp {
        height: auto;
        padding: 0 11px 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &.is-active {
      p {
        opacity: 1;
      }
      a {
        opacity: 1;
        &:after {
          width: 4px;
          height: 40px;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: #fff;
          content: "";
          @include sp {
            content: none;
          }
        }
        @include sp {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
.burger {
  width: 24px;
  height: 18px;
  position: relative;
  span {
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    &:nth-of-type(1) {
      bottom: auto;
    }
    &:nth-of-type(3) {
      top: auto;
    }
  }
  &.is-active {
    span:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
  }
}
</style>
