<script>
import _ from "lodash";
import { Bubble } from "vue-chartjs";
import "chartjs-plugin-datalabels";

export default {
  name: "ZMarketTrendTransactionChart",
  extends: Bubble,
  props: {
    // チャートのタイプ(price: 金額 / couponYieldRate: 利回り)
    type: {
      validator(value) {
        return ["price", "couponYieldRate"].indexOf(value) !== -1;
      },
    },
    // バブルのデータ
    datas: {
      type: Array,
      default: () => [],
    },
    // 横軸
    xAxes: {
      type: Array,
      default: () => [],
    },
    // 縦軸
    yAxes: {
      type: Array,
      default: () => [],
    },
    // 選択中のバブル番号
    current: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      isInitialized: false,
    };
  },
  computed: {
    chart() {
      if (!this.type) return {};

      const maxCount = _.max(this.datas.map((item) => item.v));
      const minCount = _.min(this.datas.map((item) => item.v));

      // バブル単体のデータ設定
      const datasets = this.datas.map((item, index) => {
        const countRate = (item.v - minCount) / (maxCount - minCount) || 0;
        return {
          data: [item],
          backgroundColor: `rgba(17, 134, 221, ${countRate * 0.8})`,
          borderColor: this.current === index ? "#0262D8" : "",
          borderWidth: 3,
          hoverBorderColor: "#0262D8",
          hoverBorderWidth: 3,
        };
      });

      return { datasets };
    },
    options() {
      const self = this;
      if (!this.type) return {};

      let priceFontSize = 13;
      let priceLeftPadding = 40;
      if (self.bulma && self.bulma.isMobile) {
        priceFontSize = 11;
        priceLeftPadding = 25;
      }

      // 金額軸の設定
      const yAxesPrice = [
        {
          // 軸のラベル
          ticks: {
            width: 160,
            padding: 5,
            max: this.yAxes.length,
            min: 0,
            stepSize: 1,
            fontSize: priceFontSize,
            callback: (label) => {
              const yAxis = self.yAxes[label];
              if (!yAxis) return "";
              return self.$$price$priceJPFormat(yAxis.from, "");
            },
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          afterFit: function (scaleInstance) {
            scaleInstance.width = 50;
          },
        },
        {
          // 罫線表示用のダミーラベル
          ticks: {
            width: 160,
            padding: 5,
            display: false,
            max: this.yAxes.length - 0.5,
            min: -0.5,
            stepSize: 0.5,
            callback: (label) => {
              const yAxis = self.yAxes[label];
              if (!yAxis) return label;
              return undefined;
            },
          },
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            lineWidth: 1,
          },
        },
      ];

      // 利回り軸の設定
      const yAxisYield = [
        {
          // 軸のラベル
          ticks: {
            width: 160,
            padding: 5,
            max: this.yAxes.length,
            min: 0,
            stepSize: 1,
            fontSize: 14,
            callback: (label) => {
              const yAxis = self.yAxes[label];
              if (!yAxis) return "";
              return self.$$rete$rateFormat(yAxis.from, 1, "");
            },
          },
          gridLines: {
            drawTicks: false,
            drawBorder: false,
          },
          afterFit: function (scaleInstance) {
            scaleInstance.width = 40;
          },
        },
      ];

      // X軸の最小・最大・刻み
      const xMax = _.get(_.last(self.xAxes), "to") || 1000;
      const xMin = _.get(_.head(self.xAxes), "from") || 0;
      const xStepSize = _.get(_.head(self.xAxes), "to") - xMin;

      // ラベルを表示する最小値
      const minLabelValue = _(
        this.datas.map((data) => data.v).sort((a, b) => a - b)
      )
        .reverse()
        .uniq()
        .take(10) // 10番目までの値を表示対象にする
        .last();

      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
            left: self.type === "price" ? priceLeftPadding : 10,
            right: 40,
            bottom: 20,
          },
        },
        // 表示アニメーション
        animation: {
          // 初期化時以外はアニメーションしない
          duration: self.isInitialized ? 0 : 1000,
          onComplete: () => {
            self.isInitialized = true;
          },
        },
        legend: {
          display: false,
        },
        scales: {
          // スコア軸の設定
          xAxes: [
            {
              ticks: {
                max: xMax,
                min: xMin,
                stepSize: xStepSize,
                fontSize: 14,
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          // 金額・利回り軸の設定
          yAxes: self.type === "price" ? yAxesPrice : yAxisYield,
        },
        tooltips: {
          enabled: false,
        },
        elements: {
          // バブルの大きさを計算
          point: {
            radius: (context) => {
              const index = context.dataIndex;
              const data = context.dataset.data[index];

              // グラフ領域の幅
              const size = context.chart.width;
              // 件数の相対値
              const countRate = self.getCountRate(data.v);
              // X軸の分割数
              const xAxisLength = self.xAxes.length;
              // Y軸の分割数
              const yAxesLength = self.yAxes.length;

              // X・Y肉の分割数のうち、大きい方を考慮する
              const axesLength = Math.max(xAxisLength, yAxesLength);

              return countRate * (size / (axesLength * 3.5)) + 5;
            },
          },
        },
        plugins: {
          // 件数表示
          datalabels: {
            // データラベルの表示
            display: (context) => {
              const value = context.dataset.data[context.dataIndex];
              return value.v >= minLabelValue;
            },
            // 表示位置(アンカー)
            anchor: (context) => {
              const value = context.dataset.data[context.dataIndex];
              return self.getCountRate(value.v) < 0.4 ? "end" : "center";
            },
            // 表示位置(揃え)
            align: (context) => {
              const value = context.dataset.data[context.dataIndex];
              return self.getCountRate(value.v) < 0.4 ? "end" : "center";
            },
            // 色
            color: (context) => {
              const value = context.dataset.data[context.dataIndex];
              return self.getCountRate(value.v) < 0.4 ? "#0262D8" : "#fff";
            },
            // フォントサイズ
            font: {
              size: 14,
              weight: "bold",
            },
            // アンカーのオフセット
            offset: 2,
            padding: 0,
            formatter: (value) => Math.round(value.v),
          },
        },
        onClick: (event, active) => {
          if (active.length) {
            const index = active[0]._datasetIndex;
            self.$emit("update:current", index);
          }
        },
      };
    },
  },
  watch: {
    chart: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
    options: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
  },
  methods: {
    // 全体の件数からの相対値を取得
    getCountRate(v) {
      const maxCount = _.max(this.datas.map((item) => item.v));
      const minCount = _.min(this.datas.map((item) => item.v));

      return (v - minCount) / (maxCount - minCount) || 0;
    },
  },
  mounted() {
    this.renderChart(this.chart, this.options);
  },
};
</script>
