<template>
  <div>
    <div class="titlebar">
      <div class="z-section__inner">
        <h2 class="titlebar__title">
          {{ realEstate.data.name }}
        </h2>
      </div>
    </div>
    <RealestateOverview :isRealtor="true" :data="realEstate.data" />
    <z-realestate-images :images="realEstate.data.images" />
    <RealestateDetail
      :data="realEstate.data"
      :isRealtor="true"
      showBildingInfo
      showlandInfo
    />
    <hr />
    <template v-if="hasLinkOfStaff">
      <router-link
        :to="{
          name: 'investor-search-realotrs-detail',
          params: { id: realEstate.staff.id },
        }"
      >
        <RealtorProfile :user="realEstate.staff" />
      </router-link>
    </template>
    <template v-else>
      <RealtorProfile :user="realEstate.staff" />
    </template>
    <template v-if="!hasLinkOfStaff && realEstate.data.overseasRealEstate">
      <div class="notes">
        <p>
          ※ 海外不動産会社へのお問い合わせは、<br
            class="br-show"
          />英語でのご対応をお願いいたします。
        </p>
        <p>
          &nbsp;（海外スタッフでの対応になるため、<br
            class="br-show"
          />日本語でお問い合わせされた場合、<br
            class="br-show"
          />ご返信できない可能性がございます）
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import RealestateOverview from "@/components/realestate/RealestateOverview";
import RealestateDetail from "@/components/realestate/RealestateDetail";
import RealtorProfile from "@/components/realtor/RealtorProfile";

export default {
  name: "realtor-realestate",
  props: {
    realEstate: {
      type: [Object],
      require: true,
    },
    hasLinkOfStaff: {
      type: [Boolean],
      defalut: false,
    },
  },
  components: {
    RealestateOverview: RealestateOverview,
    RealestateDetail: RealestateDetail,
    RealtorProfile: RealtorProfile,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.titlebar {
  padding: 6px 0;
  background-color: #dbdbdb;
  color: #787878;
  &__title {
    position: relative;
    font-size: 24px;
    &__icon {
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      transform: translateY(-50%);
    }
  }
}
.notes {
  padding: 0 36px;
  margin-top: 20px;
  color: $danger;
  font-weight: bold;
  @include sp {
    padding: 0 20px;
  }
}
.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
</style>
