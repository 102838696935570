<template>
  <div v-if="!isLoading">
    <section>
      <UserForm :user="user" />
      <div class="edit__foot">
        <div class="buttons is-centered">
          <b-button
            :disabled="$$validator$invalid"
            @click="save"
            type="is-primary"
            rounded
            class="z-button"
            >保存する</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

import UserForm from "@/components/realtor/FormUserProfile";

export default {
  name: "realtors-user-profile",
  inject: ["$validator"],
  components: {
    UserForm: UserForm,
  },
  props: {
    tileBox: {
      type: [Object],
      default: null,
    },
    uid: {
      type: [String],
      default: null,
    },
  },
  data: function () {
    return {
      user: null,
      isLoading: true,
    };
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveUser(this.auth.uid, this.user);
        if (this.tileBox) {
          this.tileBox.close();
        }
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = true;
      if (this.uid === "new") {
        this.user = this.$$realtor$getNewUser();
      } else {
        this.user = await this.$$realtor$loadUser(this.uid);
      }
      this.isLoading = false;
      return;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
