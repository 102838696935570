<template>
  <div class="assets" v-if="!isLoading">
    <ZHeader title="Sign Up" sub="サインアップ" />
    <section class="edit">
      <p class="edit__explanation">
        ポートフォリオを充実させ、よりご希望に沿った提案を受けることができます。
      </p>
      <PortfolioSteps :activeStep="2" />

      <h2 class="edit__title">
        資産情報入力
        <!--
        <router-link
          class="edit__title__link"
          :to="{ name: 'investor-register-realestate' }"
        >
          <span>スキップ</span>
          <b-icon icon="chevron-double-right" />
        </router-link>
        -->
      </h2>

      <FormAssets ref="formAssets" v-model="eUser" isFirst isRegister />

      <div class="edit__foot">
        <b-button @click="onPrev()" rounded class="z-button">戻る</b-button>
        <b-button
          id="investor-register-assets-submit"
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioSteps from "./parts/PortfolioSteps";
import FormAssets from "../common/FormAssets";

export default {
  name: "Register-Assets",
  data: function () {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    PortfolioSteps: PortfolioSteps,
    FormAssets: FormAssets,
  },
  methods: {
    onPrev: function () {
      this.$router.push({ name: "investor-register-income" });
    },
    onSubmit: function () {
      this.save();
    },
    async save() {
      if (!this.$refs.formAssets.isActiveSecurity)
        this.eUser.data.assets.securities = [];
      if (!this.$refs.formAssets.isActiveInsurance)
        this.eUser.data.assets.insurances = [];

      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();

        this.$router.push({ name: "investor-register-buyingneeds" });
        // this.$router.push({ name: "investor-register-realestate" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
