<template>
  <div class="investor--profile">
    <div class="investor--profile--avatar">
      <figure class="image is-50x50">
        <z-profile-thumbnail v-model="basicInfo.profile.image" />
      </figure>
    </div>
    <div>
      <template v-if="hideProfile.name">
        <p class="investor--text--main investor--text--main__name">非公開</p>
      </template>
      <template v-else>
        <p class="investor--text--main investor--text--main__name">
          {{ basicInfo.base.name }}
        </p>
      </template>
      <p class="investor--text--main investor--text--main__age-gender">
        <span>{{ $$ageAndSex(this.basicInfo.base) }}</span>
      </p>
    </div>
    <div class="investor--profile--premium-badge">
      <img
        v-if="basicInfo.membership.value === 20"
        src="@/assets/investor/premium-card.png"
      />
    </div>
    <div class="investor--text--small">
      <p>
        {{ residency }}
      </p>
      <p v-if="!hideProfile.email">{{ basicInfo.base.email }}</p>
      <p v-if="!hideProfile.phoneNumber">{{ basicInfo.base.phoneNumber }}</p>
    </div>
  </div>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "investor-basic-info",
  mixins: [DisplayTextMixin],
  props: {
    basicInfo: {
      type: Object,
      require: true,
    },
    hideProfile: {
      type: Object,
      require: true,
    },
  },
  data: function () {
    return {};
  },
  asyncComputed: {
    // 住まいを表示
    async residency() {
      const result = await this.$$city$load(
        this.basicInfo.base.prefecture.value,
        this.basicInfo.base.city
      );
      return `${this.basicInfo.base.prefecture.text}${result.cityName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investor {
  &--profile {
    display: grid;
    grid-template-columns: 64px 111px;

    &--avatar {
      height: 50px;
      width: 50px;
    }

    &--sub-title {
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
      word-wrap: break-word;
      width: 100px;
    }

    &--premium-badge {
      margin: 10px 4px 0;
      width: 42px;
    }
  }

  &--text {
    &--main {
      font-weight: bold;
      width: 100px;

      &__name {
        font-size: 18px;
        margin-top: 4px;
        margin-bottom: 4px;
        line-height: 1.2;
      }

      &__age-gender {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }

    &--small {
      font-size: 12px;
      line-height: 18px;
      word-wrap: break-word;
    }
  }
}
</style>
