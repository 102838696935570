<script>
import { Bar } from "vue-chartjs";
import "chartjs-plugin-datalabels";

export default {
  name: "ZBalanceSheetChart",
  extends: Bar,
  props: {
    quarterData: {
      type: Array,
      required: true,
    },
    currentBS: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInitialized: false,
    };
  },
  computed: {
    chart() {
      const isMobile = this.bulma && this.bulma.isMobile;

      const chartData = {
        sum: [...Array(5)],
        cash: [...Array(5)],
        investment: [...Array(5)],
        realEstate: [...Array(5)],
        longTermDebt: [...Array(5)],
        netAssets: [...Array(5)],
      };

      [...this.quarterData, { BS: this.currentBS }].forEach((item, index) => {
        const { assets, liabilities, netAssets } = item.BS;
        chartData.sum[index] = assets.sum;
        chartData.cash[index] = assets.cash.sum;
        chartData.investment[index] =
          assets.security.sum + assets.insurance.sum;
        chartData.realEstate[index] = assets.realEstate.sum;
        chartData.longTermDebt[index] = liabilities.sum;
        chartData.netAssets[index] = netAssets.sum;
      });

      return {
        labels: [...Array(5)],
        datasets: [
          // ラインチャート
          {
            type: "line",
            lineTension: 0,
            borderColor: "#bc72ff",
            borderWidth: 5,
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointRadius: 5,
            pointBackgroundColor: "#bc72ff",
            pointBorderColor: "#fff",
            pointBorderWidth: 2,
            data: chartData.netAssets,
            datalabels: {
              anchor: "start",
              align: "bottom",
              font: {
                size: isMobile ? 10 : 14,
              },
              color: "#fff",
              borderRadius: 6,
              backgroundColor: "rgba(0, 0, 0, .7)",
              padding: {
                left: 8,
                right: 8,
                bottom: 2,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // ラインチャート
          // 合計値ラベルの出力用
          {
            type: "line",
            borderColor: "rgba(0, 0, 0, 0)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            data: chartData.sum,
            datalabels: {
              anchor: "end",
              align: "end",
              offset: -4,
              font: {
                size: isMobile ? 10 : 16,
              },
              formatter: (value) => this.formatPrice(value),
            },
          },
          // 不動産
          {
            stack: "assets",
            label: "Buildings & Land",
            backgroundColor: "#3074c2",
            data: chartData.realEstate,
            datalabels: {
              display: false,
            },
          },
          // 証券・保険
          {
            stack: "assets",
            label: "Investment",
            backgroundColor: "#48adff",
            data: chartData.investment,
            datalabels: {
              display: false,
            },
          },
          // 現金
          {
            stack: "assets",
            label: "Cash",
            backgroundColor: "#b0d8f8",
            data: chartData.cash,
            datalabels: {
              display: false,
            },
          },
          // 純資産
          {
            stack: "liabilities",
            label: "Net Assets",
            backgroundColor: "#b8ecff",
            data: chartData.netAssets,
            datalabels: {
              display: false,
            },
          },
          // 長期債務
          {
            stack: "liabilities",
            label: "Long-term Debt",
            backgroundColor: "#092550",
            data: chartData.longTermDebt,
            datalabels: {
              display: false,
            },
          },
        ],
      };
    },
    options() {
      const self = this;
      const maxAssetsSum = Math.max.apply(
        null,
        [...this.quarterData, { BS: this.currentBS }].map(
          (item) => item.BS.assets.sum
        )
      );

      return {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: this.isInitialized ? 0 : 1000,
          onComplete: () => {
            self.isInitialized = true;
          },
        },
        layout: {
          padding: {
            top: 62,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1.0,
              categoryPercentage: 0.5,
              gridLines: {
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawTicks: false,
              },
              ticks: {
                display: false,
                max: maxAssetsSum * 1.2 || 1000000,
              },
            },
          ],
        },
        tooltips: {
          enabled: this.bulma && !this.bulma.isMobile,
          xAlign: "center",
          yAlign: "bottom",
          xPadding: 10,
          yPadding: 10,
          displayColors: false,
          titleFontSize: 12,
          titleFontStyle: "normal",
          titleMarginBottom: 10,
          bodyFontSize: 16,
          bodyFontStyle: "bold",
          callbacks: {
            title: (tooltipItems, data) => {
              if (tooltipItems.length) {
                return data.datasets[tooltipItems[0].datasetIndex].label || "";
              }
              return "";
            },
            label: (tooltipItems /*, data */) => {
              return this.formatPrice(tooltipItems.yLabel);
            },
          },
          filter: (a) => {
            if (a.datasetIndex > 1) {
              return true;
            }
            return false;
          },
        },
      };
    },
  },
  watch: {
    chart: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
    options: {
      handler() {
        this.renderChart(this.chart, this.options);
      },
    },
  },
  methods: {
    /**
     * 価格のフォーマット
     */
    formatPrice(price) {
      return "¥" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  mounted() {
    this.renderChart(this.chart, this.options);
    //this.isInitialized = true;
  },
};
</script>
