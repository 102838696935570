import firebase from "@/plugins/firebase";

export default {
  namespaced: true,
  state: {
    user: {
      // 認証済のフラグ
      init: false,
      // 認証結果
      auth: false,
      // 匿名ユーザフラグ
      isAnonymous: false,
    },
    auth: null,
  },
  getters: {
    init: (state) => state.user.init,
    user: (state) => state.user,
    auth: (state) => state.auth,
  },
  mutations: {
    setAuth(state, { auth }) {
      state.auth = auth;
    },
    unsetAuth(state) {
      state.auth = null;
    },
    set(state, { user }) {
      state.user = user;
    },
    unset(state) {
      state.user = {
        init: true,
        auth: false,
        isAnonymous: true,
      };
    },
  },
  actions: {
    init({ commit }) {
      firebase.auth().onAuthStateChanged((auth) => {
        if (auth) {
          // auth.getIdTokenResult(true).then(idTokenResult => {
          auth.getIdTokenResult(true).then((idTokenResult) => {
            console.log(auth);
            commit("setAuth", {
              auth: {
                uid: auth.uid,
                admin: idTokenResult.claims.isAdmin,
                auth: {
                  displayName: auth.displayName,
                  phoneNumber: auth.phoneNumber,
                  email: auth.email,
                  metadata: auth.metadata,
                  emailVerified: auth.emailVerified,
                  providerData: auth.providerData,
                  isAnonymous: auth.isAnonymous,
                },
                token: idTokenResult,
              },
            });
          });
        } else {
          commit("unsetAuth");
        }
      });
      // 状態が変わった時の処理を登録
      firebase.auth().onAuthStateChanged((auth) => {
        console.log("onAuthStateChanged callback");
        if (auth) {
          if (auth.isAnonymous) {
            console.log("onAuthStateChanged Auth as Anonymous");
            const profile = {
              init: true,
              auth: false,
              isAnonymous: true,
              uid: auth.uid,
            };
            commit("set", { user: profile });
          } else {
            console.log("onAuthStateChanged Auth as normal user");
            const profile = {
              init: true,
              auth: true,
              isAnonymous: false,
              uid: auth.uid,
              displayName: auth.displayName,
              phoneNumber: auth.phoneNuber,
              email: auth.email,
            };
            commit("set", { user: profile });
          }
        } else {
          console.log("onAuthStateChanged no Auth and SignIn Anoymous");
          firebase
            .auth()
            .signInAnonymously()
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
  },
};
