<template>
  <div>
    <router-view></router-view>
    <div class="container message-warp" v-if="!isLoading">
      <div class="content">
        <h1>メッセージ</h1>
      </div>

      <InvestorProfile :investor="message.investor" />
      <RealtorProfile :user="message.realtor" />
      <div class="content message-title-wrap">
        <template v-if="$$ks.message.getType(message.data.type).value === 1">
          <div class="message-title-wrap__exclusive-label">
            <img src="@/assets/message/label-exclusive-M.png" />
          </div>
          <div
            class="title is-size-6 message-title-wrap__title message-title-wrap__exclusive-title"
          >
            {{ message.data.title }}
          </div>
        </template>
        <template v-else>
          <div class="title is-size-6 message-title-wrap__title">
            {{ message.data.title }}
          </div>
        </template>
        <template
          v-if="
            $$ks.message.getType(message.data.type).value === 3 ||
            $$ks.message.getType(message.data.type).value === 4
          "
        >
          <div class="message-type">
            【{{ $$ks.message.getType(message.data.type).en }} ({{
              $$ks.message.getType(message.data.type).text
            }})】
          </div>
        </template>
      </div>

      <hr class="message-title-separator" />
      <b-table
        :data="threads"
        sort-icon="arrow-up"
        detailed
        detail-key="id"
        custom-row-key="id"
        :opened-detailed="defaultOpenedDetails"
        :show-detail-icon="true"
        sort-icon-size="is-small"
        :striped="true"
        :hoverable="true"
        :narrowed="true"
      >
        <template slot-scope="props">
          <b-table-column field="name" label="送信者" width="160">
            <div class="columns is-mobile is-multiline">
              <div class="column is-narrow">
                <figure class="image is-32x32">
                  <z-profile-thumbnail
                    v-model="getUser(props.row.data.from).data.profile.image"
                  />
                </figure>
              </div>
              <div class="column">
                <div class="content">
                  <z-truncator
                    class="is-size-7"
                    :text="getUser(props.row.data.from).data.base.name"
                  ></z-truncator>
                </div>
              </div>
            </div>
          </b-table-column>
          <b-table-column field="contents" label="Message">
            <div class="content message-content">
              <z-truncator
                class="is-size-7"
                :text="latestThread(props.row.data.contents)"
              ></z-truncator>
            </div>
          </b-table-column>
          <b-table-column field="sendDt" label="送信日" width="80">
            <div class="content">
              <p class="is-size-7">
                {{ $$calender$todayFormat(props.row.data.sendDt) }}
              </p>
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
              </p>
              <p>メッセージがありません。</p>
            </div>
          </section>
        </template>
        <template slot="detail" slot-scope="props">
          <div class="content message-detial">
            <div class="box message-detial__box sharp" ref="messagebox">
              <pre
                :style="'width:' + widthOfMessageBox + 'px'"
                class="is-size-7 normal"
                v-text="props.row.data.contents"
              ></pre>
            </div>
          </div>
        </template>
      </b-table>
      <hr />
      <h4 class="real-estate-title">添付されたご提案物件・案件</h4>
      <div class="box box__realestate-list">
        <template v-if="message.data.type === 3">
          <div class="content box__price">
            <p class="is-size-5 offer-price">
              提案売却金額：<span class="has-text-danger">{{
                $$price$priceJPFormat(message.data.proposalSalePrice)
              }}</span>
            </p>
          </div>
        </template>
        <RealEstateList :message="message" />
      </div>

      <div class="box box__realestate-list">
        <z-message-docs-list :threads="message.data.threads" />
      </div>
      <!-- <z-input label="UID" v-model="uid"> </z-input>
      <z-input
        label="件名"
        v-model="message.data.title"
        type="text"
        validate="required"
        isRequired
      >
      </z-input>
      <hr />
      <z-radio
        label="メッセージ区分"
        v-model="message.data.type"
        :options="$$ks.message.types"
        validate="required"
        isRequired
      >
      </z-radio>
      <hr />
      <div class="box">
        <article class="media">
          <div class="media-content">
            <div class="field">
              <p class="control">
                <z-input
                  label="送信元"
                  v-model="from"
                  validate="required"
                  isRequired
                >
                </z-input>
                <z-input
                  label="送信先"
                  v-model="to"
                  validate="required"
                  isRequired
                >
                </z-input>
                <z-input
                  label="メッセージ"
                  v-model="thread.contents"
                  type="textarea"
                  placeholder="Add a comment..."
                  validate="required"
                  isRequired
                >
                </z-input>
              </p>
            </div>
            <nav class="level">
              <div class="level-left">
                <div class="level-item">
                  <button
                    @click="send"
                    class="button is-info"
                    :disabled="$$validator$invalid"
                  >
                    Send
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </article>
      </div> -->
      <!-- <hr />
      メッセージ
      {{ message.data.messages }} -->
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import RealEstate from "@/components/investor/RealEstate";
// import RealtorRealEstate from "@/components/realtor/RealtorRealEstate";
import RealEstateList from "@/views/admin/components/RealEstateList";
import RealtorProfile from "@/components/realtor/RealtorProfile";
import InvestorProfile from "@/components/investor/InvestorProfile";

export default {
  name: "realtor-setteing-messages-detail",
  components: {
    InvestorProfile: InvestorProfile,
    RealtorProfile: RealtorProfile,
    // RealEstate: RealEstate,
    // RealtorRealEstate: RealtorRealEstate,
    RealEstateList: RealEstateList,
  },
  data: function () {
    return {
      isLoading: true,
      // uid: null,
      message: null,
      // from: null,
      // to: null,
      title: "",
      thread: null,
      defaultOpenedDetails: [],
      widthOfMessageBox: 0,
    };
  },
  computed: {
    messageId: function () {
      return this.$route.params.id;
    },
    threads: function () {
      const mes = [];
      for (const idx in this.message.data.threads) {
        mes.push({
          id: String(idx),
          data: this.message.data.threads[idx],
        });
      }
      return mes;
    },
  },
  methods: {
    // async send() {
    //   const loadingComponent = this.$loading.open();
    //   try {
    //     if (this.messageId === "new") {
    //       await this.$$message$createMessage(
    //         this.auth.uid,
    //         this.from,
    //         this.to,
    //         this.message,
    //         this.thread
    //       );
    //     } else {
    //       await this.$$message$sendMessage(
    //         this.auth.uid,
    //         this.from,
    //         this.to,
    //         this.message,
    //         this.thread
    //       );
    //     }
    //     await this.$router.go(-1);

    //     this.$$toast$openSuccess();
    //     // this.$toast.open({
    //     //   message: "登録に成功しました",
    //     //   type: "is-success",
    //     //   position: "is-top"
    //     // });
    //   } catch (error) {
    //     this.$raven.captureException(error);
    //     this.$$toast$openError();
    //     // this.$toast.open({
    //     //   duration: 10000,
    //     //   message: "登録に失敗しました",
    //     //   position: "is-top",
    //     //   type: "is-danger"
    //     // });
    //   } finally {
    //     loadingComponent.close();
    //   }
    // },
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.thread = this.$$message$getNewThread();
        // if (this.messageId === "new") {
        //   this.message = this.$$message$getNewMessage();
        // } else {
        //   this.message = await this.$$message$loadMessageWithRealEstates(this.messageId);
        // }
        this.message = await this.$$message$loadMessageWithRealEstates(
          this.messageId
        );
        this.defaultOpenedDetails = [
          String(this.message.data.threads.length - 1),
        ];
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.messagebox) {
            this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
          }
        });
        loadingComponent.close();
      }
      // this.isLoading = true;
      // this.uid = this.me.user.id;
      // this.from = this.me.user.id;
      // this.to = this.me.user.id;

      // this.thread = this.$$message$getNewThread();

      // if (this.messageId === "new") {
      //   this.message = this.$$message$getNewMessage();
      // } else {
      //   this.message = await this.$$message$loadMessage(this.messageId);
      //   this.from = this.message.data.realtor;
      //   this.to = this.message.data.investor;
      // }
      // this.isLoading = false;
      // return;
    },
    getUser: function (uid) {
      if (this.message.investor.id === uid) {
        return this.message.investor;
      }
      if (this.message.realtor.id === uid) {
        return this.message.realtor;
      }
      return null;
    },
    latestThread(m) {
      const ln = m.replace("\n", "");
      return ln;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.message-warp {
  margin: 0 15px;
}

.message {
  &__list {
    width: 100%;
    height: 756px;
  }
}

.message-detial {
  padding: 0;
  &__box {
    width: 100%;
    box-shadow: none;
    padding: 16px;
  }
}

.message-type {
  display: inline-block;
  width: 20%;
  text-align: right;
  @include md {
    width: 30%;
  }
}

.message-title-wrap {
  margin: 45px 0px 0px 0px;
  &__title {
    margin: 0px;
    display: inline-block;
    width: 80%;
    @include md {
      width: 70%;
    }
    &__message {
      margin-bottom: 0px;
    }
  }
  &__exclusive-label {
    width: 11%;
    display: inline-block;
    margin-right: 5px;
    @include md {
      margin-right: 15px;
    }
  }
  &__exclusive-title {
    width: 86%;
    display: inline-block;
    vertical-align: 4px;
  }
}

.real-estate-title {
  background-color: white;
  color: black;
  font-weight: bold;
  border-bottom: none;
  padding-bottom: 0px;
  padding: 11px 22px;
}

.message-title-separator {
  margin-top: 0px;
}

.box {
  &__realestate-list {
    padding-top: 0px;
    box-shadow: none;
  }
  &__price {
    margin-bottom: 0px;
    margin-top: 10px;
    .offer-price {
      font-size: 1.1rem !important;
    }
  }
}
</style>
