<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="columns">
        <div class="column is-6">
          <div class="content has-text-centered">
            <bs-chart
              v-model="portfolio.bs"
              :height="400"
              :width="400"
              @selected="onSelected"
            >
            </bs-chart>
            <hr />
          </div>
          <div class="content has-text-centered is-large">
            <p>
              スコア:<span class="is-size-1">{{ score }}</span>
            </p>
            <p>(純資産 + 収入)</p>
          </div>
        </div>
        <div class="column is-6">
          {{ selected }}
          <div class="content">
            <h3>純資産:{{ sumOfNetAssets }}万円</h3>
          </div>
          <div class="content">
            <h3>資産:{{ sumOfAssets }}万円</h3>
          </div>
          <b-field label="現金">
            <b-numberinput min="0" step="10" v-model="assets.deposit">
            </b-numberinput>
          </b-field>
          <b-field label="有価証券">
            <b-numberinput min="0" step="10" v-model="assets.securities">
            </b-numberinput>
          </b-field>
          <b-field label="保険">
            <b-numberinput min="0" step="10" v-model="assets.insurance">
            </b-numberinput>
          </b-field>
          <b-field label="不動産">
            <b-numberinput min="0" step="10" v-model="assets.realEstate">
            </b-numberinput>
          </b-field>
          <hr />
          <div class="content">
            <h3>負債:{{ sumOfLiabilities }}万円</h3>
          </div>
          <b-field label="ローン">
            <b-numberinput min="0" step="10" v-model="liabilities.loan">
            </b-numberinput>
          </b-field>
          <hr />
          <div class="content">
            <h3>収入:{{ sumOfIncome }}万円</h3>
          </div>
          <b-field label="給与収入">
            <b-numberinput min="0" step="10" v-model="income.salary">
            </b-numberinput>
          </b-field>
          <b-field label="事業所得">
            <b-numberinput min="0" step="10" v-model="income.business">
            </b-numberinput>
          </b-field>
          <b-field label="配当・利子">
            <b-numberinput min="0" step="10" v-model="income.dividend">
            </b-numberinput>
          </b-field>
          <b-field label="年金">
            <b-numberinput min="0" step="10" v-model="income.pension">
            </b-numberinput>
          </b-field>
          <b-field label="不動産利益">
            <b-numberinput min="0" step="10" v-model="income.realEstate">
            </b-numberinput>
          </b-field>
          <b-field label="その他">
            <b-numberinput min="0" step="10" v-model="income.other">
            </b-numberinput>
          </b-field>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";

export default {
  name: "admin-demo-charts",
  data: function () {
    return {
      selected: null,
      assets: {
        deposit: 100,
        securities: 50,
        insurance: 30,
        realEstate: 10000,
      },
      liabilities: {
        loan: 8000,
      },
      income: {
        salary: 100,
        business: 200,
        dividend: 300,
        pension: 400,
        realEstate: 100,
        other: 500,
      },
    };
  },
  computed: {
    score: function () {
      return Math.floor(this.sumOfNetAssets + this.sumOfIncome);
    },
    sumOfBs: function () {
      return this.sumOfAssets;
    },
    sumOfAssets: function () {
      return (
        this.assets.deposit +
        this.assets.securities +
        this.assets.insurance +
        this.assets.realEstate
      );
    },
    sumOfLiabilities: function () {
      return this.liabilities.loan;
    },
    sumOfNetAssets: function () {
      return this.sumOfAssets - this.sumOfLiabilities;
    },
    sumOfIncome: function () {
      return (
        this.income.salary +
        this.income.business +
        this.income.realEstate +
        +this.income.dividend +
        this.income.pension +
        this.income.other
      );
    },
    portfolio: function () {
      return {
        bs: {
          sum: this.sumOfBs,
          assets: {
            sum: this.sumOfAssets,
            deposit: this.assets.deposit,
            securities: this.assets.securities,
            insurance: this.assets.insurance,
          },
          liabilities: {
            sum: this.sumOfLiabilities,
            loan: this.liabilities.loan,
          },
          netAssets: {
            sum: this.sumOfNetAssets,
          },
        },
        lp: {
          income: {
            sum: this.sumOfIncome,
            business: this.income.business,
            salary: this.income.salary,
            dividend: this.income.dividend,
            pension: this.income.pension,
            realEstate: this.income.realEstate,
            other: this.income.other,
          },
        },
      };
    },
  },
  methods: {
    onSelected: function (val) {
      this.selected = val.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
