<template>
  <div>
    <svg viewbox="0 0 300 300" width="300" height="300">
      <circle
        r="50"
        cx="150"
        cy="150"
        @click="active = !active"
        :class="{ active: active }"
      ></circle>
    </svg>
  </div>
</template>

<script>
import ChartMixin from "./ChartMixin";

export default {
  name: "DemoChart",
  mixins: [ChartMixin],
  data: function () {
    return {
      active: false,
    };
  },
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

circle {
  fill: #333;
  transition: all 0.4s cubic-bezier(0.96, 0.04, 0.04, 0.96);
  stroke: rgb(119, 0, 255);
  stroke-width: 1px;
}
.active {
  fill: white;
  stroke-width: 50px;
}
</style>
