<template>
  <div>
    <form
      action="https://www.paydesign.jp/settle/settle3/bp3.dll"
      method="post"
    >
      <template v-if="editing">
        <a @click="onClick">{{ label }}</a>
      </template>
      <template v-else>
        <button
          class="button z-button is-primary is-wide is-rounded is-multiline"
          :disabled="disabled"
          @click="onClick"
        >
          {{ label }}
        </button>
      </template>
      <button style="display: none" ref="form" type="submit"></button>
      <div :style="debug ? '' : 'display:none'">
        <z-input lable="IP" name="IP" v-model="IP" />
        <z-input lable="PASS" name="PASS" v-model="PASS" />
        <z-input lable="KAKUTEI" name="KAKUTEI" v-model="KAKUTEI" />
        <z-input lable="SID" name="SID" v-model="SID" />
        <z-input lable="IP_USER_ID" name="IP_USER_ID" v-model="IP_USER_ID" />
        <z-input lable="N1" name="N1" v-model="N1" />
        <z-input lable="K1" name="K1" v-model="K1" />
        <z-input lable="NAME1" name="NAME1" v-model="NAME1" />
        <z-input lable="NAME2" name="NAME2" v-model="NAME2" />
        <z-input lable="TEL" name="TEL" v-model="TEL" />
        <z-input lable="MAIL" name="MAIL" v-model="MAIL" />
        <z-input v-if="OKURL" lable="OKURL" name="OKURL" v-model="OKURL" />
        <z-input v-if="RT" lable="RT" name="RT" v-model="RT" />
      </div>
    </form>
  </div>
</template>

<script>
// import moment from "moment";
// import "moment-timezone";

import firebase from "@/plugins/firebase";

export default {
  name: "ZCreditCardRegister",
  props: {
    uid: {
      type: [String],
      required: true,
    },
    OKURL: {
      type: [String],
      default: null,
    },
    RT: {
      type: [String],
      default: null,
    },
    label: {
      type: [String],
      default: null,
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    debug: {
      type: [Boolean],
      default: false,
    },
    editing: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      // IP: "TA77425002",
      // PASS: "test5003",
      // SID: (new Date()).getTime(),
      // IP_USER_ID: "TEST_01",
      KAKUTEI: 0,
      N1: "StockFormer PLAMIUN PLAN",
      K1: 5500,
      // MONTH_AMOUNT: 5500,
      // MONTH_DAY: 1,
      // NAME1: "TAIKI NAKADA",
      NAME2: " ",
      user: null,
      // TEL: "09045681586",
      // MAIL: "taiki.nakada@ziritz.co.jp",
      // MONTH_NEXT: 20200107,
    };
  },
  methods: {
    async onClick() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.user);
        this.$refs.form.click();
        // this.$refs.from.submit();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async created() {
    this.user = await this.$$investor$loadUser(this.uid);
    if (!this.user.data.payment.id) {
      const ret = await firebase.functions("gen_card_id");
      this.user.data.payment.id = ret.data;
      await this.$$investor$save(this.auth.uid, this.user);
      console.log("Generate card new id:" + ret.data);
    }
  },
  computed: {
    price() {
      return 5500;
    },
    IP() {
      return process.env.VUE_APP_CREDIT_IP;
    },
    PASS() {
      return process.env.VUE_APP_CREDIT_PASS;
    },
    SID() {
      return new Date().getTime();
    },
    // MONTH_NEXT() {
    //   if (this.user) {
    //     console.log(this.user.examination);
    //     const endDt = this.user.examination.data.endDt;
    //     console.log(endDt);
    //     const now = endDt ? endDt.toDate() : new Date();
    //     // const now = new Date();
    //     const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    //     firstDay.setMonth(firstDay.getMonth() + 1);
    //     const dt = moment(firstDay)
    //       .tz("Asia/Tokyo")
    //       .format("YYYYMMDD");
    //     console.log(dt);
    //     return dt;
    //   } else {
    //     return null;
    //   }
    // },
    IP_USER_ID() {
      if (this.user) {
        return this.user.data.payment.id;
      } else {
        return null;
      }
    },
    NAME1() {
      if (this.user) {
        return this.user.id;
      } else {
        return null;
      }
    },
    TEL() {
      if (this.user) {
        return this.user.data.base.phoneNumber;
      } else {
        return null;
      }
    },
    MAIL() {
      if (this.user) {
        return this.user.data.base.email;
      } else {
        return null;
      }
    },
  },
};
</script>
