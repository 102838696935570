<template>
  <div>
    <section class="section">
      <div class="continer">
        <template v-if="!isLoading">
          <template v-if="!canRegister">
            <template v-if="!isSignInWithEmailLink">
              <div class="columns is-centered">
                <div class="box column is-8">
                  <b-message
                    title="リンクが正しくありません"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    リンクの有効期限が切れているか不正です。
                  </b-message>
                  <div class="has-text-centered">
                    <a @click="cancel">もう一度会員登録をする</a>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="existsAccount">
              <div class="columns is-centered">
                <div class="box column is-6">
                  <b-message
                    title="会員はすでに登録されています"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    会員はすでに登録されています。<br />
                    パスワードを忘れた場合は<router-link
                      :to="{ name: 'auth-resetpassword' }"
                      >パスワードリセット</router-link
                    >からパスワードの再設定をお願いします。<br />
                  </b-message>
                  <div class="has-text-centered">
                    <router-link :to="{ name: 'auth-resetpassword' }"
                      >パスワードを再設定する</router-link
                    >
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="columns is-centered">
              <div class="column is-6">
                <h2 class="list-title">アカウント情報の登録</h2>
                <div class="box">
                  <div class="content is-small box__confirmation-text">
                    <p>
                      ご登録いただいたメールアドレスを<br
                        class="br-show"
                      />ご確認の上、パスワードをご登録ください。
                    </p>
                  </div>
                  <div
                    class="content has-label-centerted has-text-centered mail-adress"
                  >
                    <p v-text="account.email"></p>
                  </div>
                  <div class="z-input-vritical-warp">
                    <z-input
                      label="パスワード"
                      name="password"
                      v-model="account.password"
                      data-vv-as="パスワード"
                      type="password"
                      placeholder="パスワード"
                      maxlength="128"
                      :validate="'required:true|min:8'"
                      icon-pack="fas"
                      icon="lock"
                      password-reveal
                      isRequired
                    >
                    </z-input>
                  </div>
                  <div class="buttons is-centered box__button">
                    <button
                      class="button is-primary is-rounded is-wide z-button is-small"
                      :disabled="$$validator$invalid"
                      @click="save"
                    >
                      登録
                    </button>
                  </div>
                  <div class="buttons is-centered">
                    <button
                      @click="signUp"
                      class="button is-rounded is-wide z-button is-small"
                    >
                      「ご登録メール」を再送信する
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
// import RegisterMixin from "@/mixins/investor/auth/RegisterMixin";
import firebase from "@/plugins/firebase";

export default {
  name: "investor-auth-Register",
  // mixins: [RegisterMixin],
  data: function () {
    return {
      //user: null,
      account: {
        email: this.$route.query.email.replace(" ", "+"),
        password: "",
      },
      user: null,
      existsAccount: false,
      isSignInWithEmailLink: false,
      isLoading: true,
    };
  },
  computed: {
    canRegister: function () {
      return !this.existsAccount && this.isSignInWithEmailLink;
    },
  },
  methods: {
    cancel: function () {
      this.$router.push({ name: "auth-relator-signin" });
    },
    async signUp() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$retrySendVerificationMail(
          this.auth.uid,
          this.user
        );
        this.$$toast$openSuccess("ご登録メールを送信しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("ご登録メールの送信に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
    signOut: function () {
      const self = this;
      return firebase
        .auth()
        .signOut()
        .catch((error) => {
          self.$raven.captureException(error);
        });
    },
    save: function () {
      const email = this.account.email;
      if (
        email &&
        firebase.auth().isSignInWithEmailLink(window.location.href)
      ) {
        const self = this;
        const loadingComponent = this.$loading.open();
        // const promise = Promise.resolve();
        return Promise.resolve()
          .then(() => {
            return firebase
              .auth()
              .signInWithEmailLink(email, window.location.href);
          })
          .then(() => {
            const auth = firebase.auth().currentUser;
            return auth.updatePassword(self.account.password);
          })
          .then(async () => {
            const auth = firebase.auth().currentUser;
            const user = await this.$$realtor$loadUser(auth.uid);
            console.log(user);
            user.isActivate = false;
            user.examination.data.status = 10;
            await this.$$realtor$saveUser(this.auth.uid, user);
          })
          .then(async () => {
            const auth = firebase.auth().currentUser;
            const user = await this.$$realtor$loadUser(auth.uid);
            console.log(user);
            return firebase.functions("sendMailToRealtorSignup", {
              to: {
                email: email,
                name: user.data.base.name,
              },
              user: user,
            });
          })
          .then(() => {
            console.log("Sucess!!");
            window.location.href = "/auth/realtor/welcome";
            // this.$nextTick(() => {
            //   setTimeout(() => {
            //     window.location.href = "/auth/realtor/welcome";
            //     loadingComponent.close();
            //   }, 3000);
            // });
          })
          .catch((error) => {
            // loadingComponent.close();
            self.$raven.captureException(error);
            self.$$toast$openError(
              "リンクの認証が切れています。「ご登録メール」の再送信をお願いします。"
            );
          })
          .finally(() => {
            loadingComponent.close();
          });
      } else {
        const self = this;
        self.$raven.captureException("Link is invalid");
        self.$$toast$openError(
          "リンクの認証が切れています。「ご登録メール」の再送信をお願いします。"
        );
        // self.$toast.open({
        //   duration: 10000,
        //   message:
        //     "リンクの認証が切れています。再度サインアップからやり直しをお願いします。",
        //   position: "is-top",
        //   type: "is-danger"
        // });
        return Promise.resolve();
      }
    },
  },
  created() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.isSignInWithEmailLink = true;
      //firebase.db
      //  .collection("users_v1")
      //  .where("email", "==", this.account.email)
      //  .get()
      //  .then(querySnapshot => {
      //    this.existsAccount = !querySnapshot.empty;
      //  })
      //  .finally(() => {
      //    this.isLoading = false;
      //  })
      //  .catch(error => {
      //    self.$catchLoadError(error);
      //  });
    } else {
      this.isSignInWithEmailLink = false;
    }
  },
  async mounted() {
    this.user = await this.$$realtor$loaduserByEmail(this.account.email);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.has-label-centerted {
  height: 2rem;
  margin-top: 4px;
  margin-right: 24px;
}

.is-input-helper {
  margin: 0;
}

.column {
  width: 100%;
}

.list-title {
  width: 100%;
  margin-top: 0px;
  padding: 11px 22px;
  background-color: #0066ffeb;
  border-bottom: solid 2px #707070;
  color: $white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.box {
  border-radius: 0px;
  .content {
    font-size: 14px;
  }
  .mail-adress {
    font-size: 16px;
  }
  &__confirmation-text {
    text-align: center;
  }
  .br-show {
    display: none;
    @include mobile {
      display: block;
    }
  }
  &__button {
    margin-top: 5px;
  }
}
</style>
