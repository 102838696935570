<template>
  <div>
    <AdminMenu />
    <section class="section">
      <div class="container">
        <div class="content">
          <button class="button" @click="signOut">
            <p>サインアウト</p>
          </button>
          <hr />
          VER. {{ $$env$version }}
          <hr />
          ADMIN: {{ $$user$isAdministrator }}<br />
          ID: {{ me.id }}<br />
          email: {{ me.user.data.base.email }}
          <hr />
          メッセージ未読数：{{ $$user$countMessageOfNotAsRead }} <br />
          お知らせ未読数：{{ $$user$countInformationOfNotAsRead }} <br />
          <hr />
          <template v-if="$$user$isAdmin">
            <p>運用者でログイン</p>
          </template>
          <template v-else-if="$$user$isInvestor">
            <p>投資家でログイン</p>
          </template>
          <template v-else-if="$$user$isRealtor">
            <p>専門家でログイン</p>
          </template>
          <template v-else>
            <p>ログアウト中</p>
          </template>

          <hr />

          <p>ブラウザ名 : {{ this.$$userAgent$getBowser() }}</p>
          <p>モバイル端末で見ているか : {{ this.$$userAgent$getIsMobile() }}</p>
          <p>IEで見ているか : {{ this.$$userAgent$isIE() }}</p>
          <hr />

          <div class="box">
            <h1>投資家</h1>
            <h6>ログイン前にしか行けないページ</h6>
            <router-link :to="{ name: 'auth-signin' }">
              サインイン or サインアップ </router-link
            ><br />
            <router-link :to="{ name: 'auth-resetpassword' }">
              Reset Password </router-link
            ><br />
            <h6>ログイン後に行けるページ</h6>
            <router-link :to="{ name: 'investor-message' }">
              オファー</router-link
            ><br />
            <router-link :to="{ name: 'investor-home' }">
              01_ダッシュボード</router-link
            ><br />
            <router-link :to="{ name: 'investor-portfolio' }">
              02_マイポートフォリオ</router-link
            ><br />
            <router-link :to="{ name: 'investor-markettrend' }">
              03_市場動向</router-link
            ><br />
            <router-link :to="{ name: 'investor-register' }">
              07_ポートフォリオ情報登録（一通登録）</router-link
            ><br />
            <router-link :to="{ name: 'investor-realestate' }">
              09_不動産情報？？？</router-link
            ><br />
            <router-link :to="{ name: 'investor-collection' }">
              10_マイコレクション</router-link
            ><br />
            <router-link :to="{ name: 'investor-search-realestates' }">
              不動産検索 </router-link
            ><br />
            <router-link :to="{ name: 'investor-informations' }">
              お知らせ一覧</router-link
            ><br />
            <router-link :to="{ name: 'investor-account' }">
              アカウント </router-link
            ><br />
          </div>

          <hr />
          <div class="box notification is-primary">
            <h1>専門家(不動産)</h1>
            <h6>ログイン前にしか行けないページ</h6>
            <router-link :to="{ name: 'auth-signin' }"> サインイン</router-link
            ><br />
            <router-link :to="{ name: 'auth-realtor-application' }">
              申し込み</router-link
            ><br />

            <h6>ログイン後に行けるページ</h6>

            <router-link :to="{ name: 'auth-realtor-welcome' }">
              ウエルカム </router-link
            ><br />
            <hr />
            <router-link :to="{ name: 'realtor-my' }"> マイページ </router-link
            ><br />
            <router-link :to="{ name: 'realtor-setting-investors' }">
              投資家検索</router-link
            ><br />
            <router-link :to="{ name: 'realtor-search-realestates' }">
              売却物件検索 </router-link
            ><br />
            <router-link :to="{ name: 'realtor-setting-realestates' }">
              物件掲載管理 </router-link
            ><br />
            <router-link :to="{ name: 'realtor-message' }">
              メッセージ(オファー)</router-link
            ><br />
            <router-link :to="{ name: 'realtor-setting-informations' }">
              お知らせ一覧</router-link
            ><br />
            <router-link :to="{ name: 'realtor-setting-basic' }">
              プロフィール設定 </router-link
            ><br />
            <router-link :to="{ name: 'realtor-setting-account' }">
              アカウント設定</router-link
            ><br />
            <hr />
            <!--
            <router-link :to="{ name: 'realtor-setting-realestates-v1' }">
              (OLD)物件掲載管理 </router-link
            ><br />
            -->
            <!--
          <router-link :to="{ name: 'realtor-setting-company' }">
            企業情報登録 </router-link
          ><br />
          --></div>
          <hr />

          <div class="box notification is-light">
            <h1>運用者</h1>
            <h6>ログイン前にしか行けないページ</h6>
            <router-link :to="{ name: 'auth-signin' }"> サインイン</router-link
            ><br />
            <router-link :to="{ name: 'auth-admin-signup' }">
              サインアップ</router-link
            ><br />
            <h6>ログイン後に行けるページ</h6>
            <router-link :to="{ name: 'admin-setting-investors' }">
              投資家一覧 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-realtors' }">
              専門家(不動産屋)一覧 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-realtors-users' }">
              専門家(コンサルタント)一覧 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-messages-index' }">
              メッセージー覧 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-informations-index' }">
              お知らせー覧 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-informations-bulk' }">
              お知らせ一括送信 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-admin-index' }">
              運用者設定 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-linkage' }">
              外部連携 </router-link
            ><br />
            <router-link :to="{ name: 'admin-setting-messages-template' }">
              メッセージテンプレート </router-link
            ><br />
          </div>
          <div class="box notification is-grey">
            <h1>アナリティクス</h1>
            <router-link :to="{ name: 'admin-setting-analysis-action' }">
              アクション</router-link
            ><br />
            <router-link
              :to="{ name: 'admin-setting-analysis-investor-favorite' }"
            >
              資本家のFAVORITE</router-link
            ><br />
          </div>
          <hr />
          <div class="box notification is-grey">
            <h1>マスタ</h1>
            <router-link :to="{ name: 'admin-setting-areas' }">
              エリアデータ生成 </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/bank' }"> Bank </router-link
            ><br />
          </div>
          <hr />
          <div class="box notification is-grey">
            <h1>ZERO管理</h1>
            <router-link :to="{ name: 'admin-setting-zero' }">
              応募者</router-link
            ><br />
          </div>
          <hr />
          <div class="box notification is-grey">
            <h1>デモ</h1>
            <router-link :to="{ name: 'admin-setting-admin-index' }">
              設定 </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/base' }"> base </router-link
            ><br />
            <router-link :to="{ name: 'admin-demo-price' }"> price </router-link
            ><br />
            <router-link :to="{ name: 'admin-demo-scoreuser' }">
              scoreUser </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/score' }">
              score </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/realestate' }">
              realestate </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/charts' }">
              charts </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/parts' }">
              県と駅のパーツ </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/kubuns' }">
              区分 </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/summary-parts' }">
              サマリー関連のパーツ </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/chart-parts' }">
              チャート関連のパーツ </router-link
            ><br />

            <router-link :to="{ name: '/admin/demo/bank' }"> Bank </router-link
            ><br />
            <router-link :to="{ name: 'admin-demo-userlist' }">
              ユーザセレクト </router-link
            ><br />
            <!--
            <router-link :to="{ name: 'admin-demo-creaditcard' }">
              クレジットカード </router-link
            ><br />
            -->
            <router-link :to="{ name: '/admin/demo/realtorrs' }">
              realtorrs </router-link
            ><br />
            <router-link :to="{ name: '/admin/demo/transaction' }">
              transaction</router-link
            ><br />
            <router-link :to="{ name: 'admin-demo-message' }">
              message</router-link
            ><br />
            <!--
            <router-link :to="{ name: '/admin/demo/realtorrs2' }">
              realtorrs2</router-link
            ><br />
            -->
            <router-link :to="{ name: '/admin/demo/pb' }"> pb</router-link
            ><br />
            <hr />
            <!--
            <router-link :to="{ name: 'admin-demo-investor' }">
              Elasticsearch</router-link
            ><br />
            <hr />
            <router-link :to="{ name: 'admin-demo-fix01' }"> fix01</router-link
            ><br />
            <router-link :to="{ name: 'admin-demo-fix02' }"> fix02</router-link
            ><br />
            -->
          </div>
          <!--
          <router-link :to="{ name: 'realtor-setting-applications' }">
            申し込み一覧
          </router-link><br />
          -->
          <!--
            <div class="columns">
            <div class="column is-6">
            <img src="@/assets/sample/01.png" alt="Mintrich" />
            </div>
            <div class="column is-6">
            <img src="@/assets/sample/02.png" alt="Mintrich" />
            </div>
            </div>
          -->
        </div>
      </div>
    </section>
    <section class="section">
      <h1>投資家申請</h1>
      <z-tag
        :options="$$ks.investor.application.statuses"
        v-model="me.user.examination.data.status"
      />
      <z-tag
        :options="$$ks.investor.application.memberships"
        v-model="me.user.data.membership"
      />
      <hr />
      <button class="button" @click="onApplicateInvestor">応募</button>
      <button class="button" @click="onAcceptInvestor">承認</button>
      <button class="button" @click="onDenyInvetor">否認</button>
    </section>
    <!--
    <hr />
    <section class="section">
      {{ me }}
    </section>
    <hr />
    <section class="section">
      <h1>各タイプごとのメッセージ一覧(5件)</h1>
      {{ messagesOfTypes }}
    </section>
    <section class="section">
      <h1>未読メッセージ一覧</h1>
      {{ $$user$messagesOfNotAsRead }}
    </section>
    <section class="section">
      <h1>未読お知らせ一覧</h1>
      {{ $$user$informationsOfNotAsRead }}
    </section>
    <section class="section">
      <h1>NewArraival</h1>
      {{ newArraival }}
    </section>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import AdminMenu from "@/views/admin/components/AdminMenu";

export default {
  name: "admin-home",
  components: {
    AdminMenu,
  },
  methods: {
    signOut: function () {
      return this.$$user$signOut();
    },
    async onApplicateInvestor() {
      await this.$$investor$applicateUserByUID(this.me.id, this.me.id);
    },
    async onAcceptInvestor() {
      await this.$$investor$acceptUserByUID(this.me.id, this.me.id);
    },
    async onDenyInvetor() {
      await this.$$investor$denyUserByUID(this.me.id, this.me.id);
    },
  },
  asyncComputed: {
    messagesOfTypes() {
      return this.$$message$getMessagesOfTypes(this.me.user.id);
    },
    newArraival() {
      return this.$$realtor$loadNewArraivalRealEstates();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
