var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.investor && _vm.investor.id,staticClass:"box invetor-profile-card"},[_c('div',{staticClass:"columns is-muliline is-mobile invetor-profile-card__detail"},[_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"invetor-profile-card__score"},[_c('ZScoreProgress',{attrs:{"score":_vm.investor.data.latestScore.score,"rank":_vm.investor.data.latestScore.rank,"transitionDuration":0,"scaleType":"medium"}}),_c('div',{staticClass:"content is-small has-text-centered has-text-white"},[_c('span',[_vm._v(" "+_vm._s(_vm.$$age$calc(_vm.investor.data.base.birthday))+"歳 ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$$ks.getTypeOfSex(_vm.investor.data.base.sex).text)+" ")]),_c('br')])],1),(
          _vm.$$ks.investor.application.getMembership(_vm.investor.data.membership)
            .value === 20
        )?[(_vm.bulma && _vm.bulma.isMobile)?_c('div',{staticClass:"column is-narrow invetor-profile-card__description__badge-wrap"}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"column invetor-profile-card__description"},[_c('div',{staticClass:"invetor-profile-card__description__user"},[_c('div',{staticClass:"image is-32x32 invetor-profile-card__description__image"},[_c('z-profile-thumbnail',{model:{value:(_vm.investor.data.profile.image),callback:function ($$v) {_vm.$set(_vm.investor.data.profile, "image", $$v)},expression:"investor.data.profile.image"}})],1),_c('div',{staticClass:"invetor-profile-card__description__name"},[(_vm.hideProfile)?[_c('z-truncator',{staticClass:"is-size-6",attrs:{"text":"非公開"}})]:[_c('z-truncator',{attrs:{"text":_vm.investor.data.base.name}})]],2),(!(_vm.bulma && _vm.bulma.isMobile))?[_c('div',{staticClass:"invetor-profile-card__description__message"},[(_vm.latestSendMailDate)?[_c('div',[_vm._v("最終メッセージ送信日："+_vm._s(_vm.latestSendMailDate))])]:_vm._e(),(_vm.latestRecieveMailDate)?[_c('div',[_vm._v("最終メッセージ受信日："+_vm._s(_vm.latestRecieveMailDate))])]:_vm._e()],2)]:_vm._e()],2),_c('hr',{staticClass:"investor-profile-card__hr"}),_c('div',{staticClass:"columns is-mobile is-multiline"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"content is-small"},[_c('div',{staticClass:"invetor-profile-card__description__tr"},[_c('div',{staticClass:"invetor-profile-card__description__desc"},[_vm._v(" "+_vm._s(_vm.$$ks.getPrefecture(_vm.investor.data.base.prefecture).text)+" "+_vm._s(_vm.cityInfo)+"在住  "+_vm._s(_vm.$$ks.getEducationType(_vm.investor.data.profile.education.type) .text)+"卒 ")])]),_c('div',{staticClass:"invetor-profile-card__description__tr"},[_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 業種: ")]),_c('div',{staticClass:"invetor-profile-card__description__desc"},[_c('z-truncator',{attrs:{"text":_vm.$$ks.getCategoryOfBusiness(
                      _vm.investor.data.profile.currentOfCareer.categoryOfBusiness
                    ).text}})],1),_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 職種: ")]),_c('div',{staticClass:"invetor-profile-card__description__desc"},[_c('z-truncator',{attrs:{"text":_vm.$$ks.getTypeOfOccupation(
                      _vm.investor.data.profile.currentOfCareer.typeOfOccupation
                    ).text}})],1)]),_c('div',{staticClass:"invetor-profile-card__description__tr"},[_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 保有不動産数: ")]),_c('div',{staticClass:"invetor-profile-card__description__desc invetor-profile-card__description__desc__realestate"},[_vm._v(" "+_vm._s(_vm.investor.realEstates.length)+"件 ")]),_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 会員登録日: ")]),_c('div',{staticClass:"invetor-profile-card__description__desc invetor-profile-card__description__desc__registration-date"},[_vm._v(" "+_vm._s(_vm.$$calender$dateFormat(_vm.investor.data.registerDt))+" ")])]),(_vm.bulma && _vm.bulma.isMobile)?[_c('div',{staticClass:"invetor-profile-card__description__tr"},[(_vm.latestSendMailDate)?[_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 最終メッセージ送信日： ")]),_c('div',{staticClass:"invetor-profile-card__description__desc"},[_vm._v(" "+_vm._s(_vm.latestSendMailDate)+" ")])]:_vm._e(),(_vm.latestRecieveMailDate)?[_c('div',{staticClass:"invetor-profile-card__description__title"},[_vm._v(" 最終メッセージ受信日： ")]),_c('div',{staticClass:"invetor-profile-card__description__desc"},[_vm._v(" "+_vm._s(_vm.latestRecieveMailDate)+" ")])]:_vm._e()],2)]:_vm._e()],2)]),(
            _vm.$$ks.investor.application.getMembership(_vm.investor.data.membership)
              .value === 20
          )?[(!(_vm.bulma && _vm.bulma.isMobile))?_c('div',{staticClass:"column is-narrow invetor-profile-card__description__badge-wrap"}):_vm._e()]:_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }