var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('ZHeader',{attrs:{"title":"プロフィール","showNotification":""},scopedSlots:_vm._u([{key:"rightIcons",fn:function(){return [_c('ZNotification',{attrs:{"color":"#777"}})]},proxy:true}])})],1),_c('div',{staticClass:"z-section"},[_c('div',{staticClass:"z-section__inner"},[(!_vm.isLoading)?_c('b-tabs',{attrs:{"animated":false,"size":"is-small"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"あなた"}},[_c('UserProfilePage',{attrs:{"editable":false,"user":_vm.me.user}}),_c('hr'),_c('div',{staticClass:"buttons is-centered"},[_c('router-link',{staticClass:"button is-primary z-button is-rounded",attrs:{"to":{
                name: 'realtor-setting-basic-edit',
              }}},[_vm._v("プロフィール編集 ")])],1)],1),(_vm.user.data.role === 10)?[_c('b-tab-item',{attrs:{"label":"コンサルタント一覧"}},[_c('router-link',{staticClass:"button is-primary z-button is-rounded is-small",attrs:{"to":{
                name: 'realtor-setting-users-detail',
                params: { uid: 'new' },
              }}},[_vm._v("新規コンサルタント登録")]),_c('hr'),_c('b-table',{attrs:{"data":_vm.realtor.users,"checkable":"","custom-row-key":"id","striped":true,"hoverable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"id","label":"詳細","width":"20"}},[_c('router-link',{staticClass:"button is-small",attrs:{"to":{
                      name: 'realtor-setting-users-detail',
                      params: { uid: props.row.id },
                    }}},[_vm._v("詳細")])],1),_c('b-table-column',{attrs:{"field":"data.base.email","label":"写真"}},[_c('z-profile-thumbnail',{model:{value:(props.row.data.profile.image),callback:function ($$v) {_vm.$set(props.row.data.profile, "image", $$v)},expression:"props.row.data.profile.image"}})],1),_c('b-table-column',{attrs:{"field":"data.base.email","label":"email"}},[_c('div',{staticClass:"content realtor-user-email"},[_vm._v(" "+_vm._s(_vm.truncateEmail(props.row.data.base.email))+" ")])]),_c('b-table-column',{attrs:{"field":"id","label":"名前"}},[_vm._v(" "+_vm._s(props.row.data.base.name)+" ")]),_c('b-table-column',{attrs:{"field":"id","label":"所属・部署"}},[_vm._v(" "+_vm._s(props.row.data.company.department)+" ")]),_c('b-table-column',{attrs:{"field":"id","label":"役職"}},[_vm._v(" "+_vm._s(props.row.data.company.title)+" ")]),_c('b-table-column',{attrs:{"field":"data.base.email","label":"権限"}},[(props.row.data.role === 10)?[_c('div',{staticClass:"tag",class:_vm.roleKs(props.row.data.role).color},[_vm._v(" "+_vm._s(_vm.roleKs(props.row.data.role).text)+" ")])]:_vm._e()],2)]}}],null,false,234629568)},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing here.")])])])])],2)],1)]:_vm._e()],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }