<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "DisplayTextMixin",
  methods: {
    // 権限
    $$authority: function () {
      return {
        normal: 1, // 通常
        company: 20, // 業務提携会社
        ziritz: 99, // ZIRITZ
      };
    },
    // お気にりアイコン色
    $$favoriteIconColor: function (state, isSort = false) {
      if (isSort) {
        return state ? "#00997e" : "#ffffff";
      }
      return state ? "#00997e" : "#d8d8d8";
    },
    // nullか空白かどうか
    $$isNullOrEmpty: function (value) {
      return value === null || value === undefined || value.length === 0;
    },
    // nullか0かどうか
    $$isNullOrZero: function (value) {
      return value === null || value === undefined || value === 0;
    },
    // 年と性別表示
    $$ageAndSex: function (base) {
      let result = [];
      const age = base.birthday
        ? this.$$age$calc(new Date(base.birthday))
        : null;

      if (age) {
        result.push(`${age}歳`);
      }
      if (base.sex) {
        result.push(base.sex.text);
      }

      return result.join(" ");
    },
    // 所在地
    $$location: function (location) {
      return `${location.prefecture.text}${location.city.cityName}`;
    },
    // エリア
    $$area: function (area) {
      return `${area.prefecture.text}${area.cities
        .map((item) => item.cityName)
        .join(", ")}`;
    },
    // 交通
    $$traffic: function (transports) {
      const result = [];
      transports.forEach((transport) => {
        if (transport.station && transport.line) {
          const stationNames = transport.station.station
            ? `${transport.station.station.station_name}駅`
            : "";
          const elapsedTime = transport.elapsedTime
            ? `${transport.elapsedTime}分`
            : "";
          result.push(
            `${transport.line.line.line_name} ${stationNames} ${elapsedTime}`
          );
        }
      });
      return result.join("・");
    },
    // 沿線
    $$line: function (line) {
      let stationNames = [];

      line.stations.forEach((item) => {
        stationNames.push(`${item.station.station_name}駅`);
      });
      if (line && line.line && stationNames) {
        return `${line.prefecture.text} ${
          line.line.line.line_name
        } ${stationNames.join(", ")}`;
      } else {
        return "";
      }
    },
    // 価格（From-To）
    $$priceFromTo: function (price) {
      const priceFrom =
        price.from.text === kubuns.filters.price.from[0].text
          ? price.from.text
          : price.from.text.length === 0
          ? "下限なし"
          : `${price.from.text}円`;
      const priceTo =
        price.to.text === kubuns.filters.price.to[0].text
          ? price.to.text
          : price.to.text.length === 0
          ? "上限なし"
          : `${price.to.text}円`;
      return `${priceFrom} ~ ${priceTo}`;
    },
    // 表面利回り（From-To）
    $$yieldRateFromTo: function (yieldRate) {
      const yieldRateFrom =
        yieldRate.from.text === kubuns.filters.yieldRate.from[0].text
          ? yieldRate.from.text
          : yieldRate.from.text.length === 0
          ? "下限なし"
          : `${yieldRate.from.text}%`;
      const yieldRateTo =
        yieldRate.to.text === kubuns.filters.yieldRate.to[0].text
          ? yieldRate.to.text
          : yieldRate.to.text.length === 0
          ? "上限なし"
          : `${yieldRate.to.text}%`;
      return `${yieldRateFrom} ~ ${yieldRateTo}`;
    },
    // 種別リスト
    $$types: function (types) {
      return types.map((item) => item.text).join("、");
    },
    // 物件種別
    $$realEstateType: function (realEstateType) {
      return realEstateType ? realEstateType.text : "-";
    },
    // 建物種別
    $$buildingType: function (buildingType) {
      return buildingType ? buildingType.text : "-";
    },
    // 築年数（From-To）
    $$ageOfBuildingFromTo: function (ageOfBuilding) {
      const ageOfBuildingFrom =
        ageOfBuilding.from.text ===
          kubuns.filters.yearOfconstruction.from[0].text ||
        ageOfBuilding.from.text ===
          kubuns.filters.yearOfconstruction.from[1].text
          ? ageOfBuilding.from.text
          : ageOfBuilding.from.text.length === 0
          ? "下限なし"
          : `${ageOfBuilding.from.text}年`;
      const ageOfBuildingTo =
        ageOfBuilding.to.text === kubuns.filters.yearOfconstruction.to[0].text
          ? ageOfBuilding.to.text
          : ageOfBuilding.to.text.length === 0
          ? "上限なし"
          : `${ageOfBuilding.to.text}年`;
      return `${ageOfBuildingFrom} ~ ${ageOfBuildingTo}`;
    },
    // 築年月
    $$yearOfconstruction: function (type, constructionDate) {
      if (type === 10 || type === 7) return "-";
      const date = this.$$calender$yearMonthFormat(constructionDate);
      const ageOfBuilding =
        this.$$realEstate$calcYearOfConstruction(constructionDate);

      return `${date}（築${ageOfBuilding}年）`;
    },
    // 建物面積
    $$buildingArea: function (area) {
      return area ? `${area}㎡` : "-";
    },
    // 階数
    $$numberOfFloors: function (numberOfFloors) {
      return numberOfFloors ? `${numberOfFloors}` : "-";
    },
    // 融資実行年月
    $$loanExecutionDate: function (date) {
      if (!date) return "-";
      if (!date.year && !date.month) return "-";

      let result = "";
      if (date.year) result += `${date.year}年`;
      if (date.month) result += `${date.month}月`;

      return result;
    },
    // 金融機関・支店名
    $$loanBank: function (bank) {
      let result = "";
      if (bank.bankId) result += bank.bankId.bank_name;
      if (bank.branchId) result += bank.branchId.branch_name;
      return result.length === 0 ? "-" : result;
    },
    // 借入金融機関
    $$loanBanks: function (banks) {
      if (banks.length === 0) return "";

      let result = [];
      banks.map((item) => {
        if (item.data.purchase.loan.bankId) {
          result.push(item.data.purchase.loan.bankId.bank_name);
        }
      });

      return result.filter((v, i, a) => a.indexOf(v) === i).join("、");
    },
    // 借入金融機関数
    $$countLoanBanks: function (banks) {
      if (banks.length === 0) return 0;

      let result = [];
      banks.map((item) => {
        if (item.data.purchase.loan.bankId) {
          result.push(item.data.purchase.loan.bankId.bank_cd);
        }
      });

      return result.length;
    },
    // 返済期間
    $$paymentPeriod: function (period) {
      if (!period) return "-";
      if (!period.year && !period.month) return "-";

      let result = "";
      if (period.year) result += `${period.year}年`;
      if (period.month) result += `${period.month}ヶ月`;

      return result;
    },
    // 金利
    $$interestRate: function (rate) {
      return rate ? this.$$rete$rateFormat(rate) : "-";
    },
    // 返済方法
    $$paymentMethod: function (value) {
      if (!value) return "-";
      const result = kubuns.loanMethodTypes.find(
        (item) => item.value === value
      );
      if (result) return result.text;
      return "-";
    },
    //「コメントあり」と表示するかどうか
    $$hasComment: function (buyingNeeds) {
      if (buyingNeeds.length === 0) return false;

      const comments = buyingNeeds.filter((item) => item.comment.length > 0);
      return comments.length > 0;
    },
    // 勤続年数
    $$yearOfServe: function (period) {
      const currentYear = new Date().getFullYear();
      let yearOfServe = null;

      if (period.to && period.to.year) {
        yearOfServe =
          period.from.year > period.to.year
            ? 0
            : period.to.year - period.from.year;
      } else {
        yearOfServe =
          period.from.year > currentYear ? 0 : currentYear - period.from.year;
      }

      return `${yearOfServe}年` || "N/A";
    },
    // 最終メッセージ送信日
    $$latestSendMessageDate: function (realtor, isText = true) {
      if (!realtor) return isText ? "" : null;

      // ログインしている不動産会社のみで取得
      if (realtor.length === 0) return isText ? "" : null;

      let latestDate = realtor[0].latestSendDt.realtor;
      realtor.forEach((r) => {
        if (r.latestSendDt.realtor && r.latestSendDt.realtor > latestDate) {
          latestDate = r.latestSendDt.realtor;
        }
      });

      if (isText) {
        return latestDate
          ? this.$$calender$dateFormat(new Date(latestDate))
          : "";
      }
      return latestDate;
    },
    // $$latestSendMessageDate: function(realtor, isText = true) {
    //   if (!realtor.realtors) return isText ? "" : null;

    //   // ログインしている不動産会社のみで取得
    //   const currentRealtor = realtor.realtors.filter(
    //     item => item.realtor === this.me.user.id
    //   );
    //   if (currentRealtor.length === 0) return isText ? "" : null;

    //   let latestDate = currentRealtor[0].latestSendDt.realtor;
    //   currentRealtor.forEach(realtor => {
    //     if (
    //       realtor.latestSendDt.realtor &&
    //       realtor.latestSendDt.realtor > latestDate
    //     ) {
    //       latestDate = realtor.latestSendDt.realtor;
    //     }
    //   });

    //   if (isText) {
    //     return latestDate
    //       ? this.$$calender$dateFormat(new Date(latestDate))
    //       : "";
    //   }
    //   return latestDate;
    // },
    // 最終メッセージ受信日
    $$latestRecieveMessageDate: function (realtor, isText = true) {
      if (!realtor) return isText ? "" : null;

      // ログインしている不動産会社のみで取得
      if (realtor.length === 0) return isText ? "" : null;

      let latestDate = realtor[0].latestSendDt.investor;
      realtor.forEach((r) => {
        if (r.latestSendDt.investor && r.latestSendDt.investor > latestDate) {
          latestDate = r.latestSendDt.investor;
        }
      });

      if (isText) {
        return latestDate
          ? this.$$calender$dateFormat(new Date(latestDate))
          : "";
      }
      return latestDate;
    },
    // // 最終メッセージ受信日
    // $$latestRecieveMessageDate: function(realtor, isText = true) {
    //   if (!realtor.realtors) return isText ? "" : null;

    //   // ログインしている不動産会社のみで取得
    //   const currentRealtor = realtor.realtors.filter(
    //     item => item.realtor === this.me.user.id
    //   );
    //   if (currentRealtor.length === 0) return isText ? "" : null;

    //   let latestDate = currentRealtor[0].latestSendDt.investor;
    //   currentRealtor.forEach(realtor => {
    //     if (
    //       realtor.latestSendDt.investor &&
    //       realtor.latestSendDt.investor > latestDate
    //     ) {
    //       latestDate = realtor.latestSendDt.investor;
    //     }
    //   });

    //   if (isText) {
    //     return latestDate
    //       ? this.$$calender$dateFormat(new Date(latestDate))
    //       : "";
    //   }
    //   return latestDate;
    // }
  },
};
</script>
