<template>
  <div class="z-checkbox">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
    >
      <div class="z-suggestions-checkbox">
        <p>投資家にPRできる特徴をお選びください。</p>
      </div>
      <div class="block">
        <template v-for="o in options">
          <b-checkbox
            :key="o.value"
            :name="internalName"
            :native-value="o.value"
            :data-vv-as="label"
            v-model="internalValue"
            v-validate="validate"
            type="is-info"
          >
            {{ o.text }}
          </b-checkbox>
        </template>
      </div>
    </ZField>
    <!--
    <z-checkbox
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :isRequired="isRequired"
      >
    </z-checkbox>
    -->
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

// import axios from "axios-jsonp-pro";
export default {
  name: "ZSuggestionsCheckbox",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    options() {
      return this.$$ks.message.suggestions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.z-suggestions-checkbox {
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  line-height: 16px;
}
</style>
