<template>
  <div class="z-field" :class="[rootClasses, fieldType(), type]">
    <div v-if="label" class="z-field__info">
      <div class="z-field__info__main">
        <label
          @click="isHelpActive = !isHelpActive"
          :for="labelFor"
          :class="customClass"
          class="label z-field__info__main__label"
        >
          <span>{{ label }}</span>
          <z-help-icon v-if="helpMessage" />
          <template v-if="!hideRequired">
            <span
              v-if="isRequired"
              class="z-field__info__main__required is-required"
              >必須</span
            >
            <span v-else class="z-field__info__main__required">任意</span>
          </template>
        </label>
      </div>
      <div class="z-field__info__sub">
        <private-label v-if="isPrivate" />
      </div>
    </div>
    <b-notification
      v-if="helpMessage"
      :active.sync="isHelpActive"
      aria-close-label="Close notification"
    >
      {{ helpMessage }}
    </b-notification>
    <div class="z-field__inputs">
      <slot />
    </div>
    <p
      v-if="newMessage"
      v-html="formattedMessage"
      class="help"
      :class="newType"
    />
    <p class="z-field__message" v-if="additionalMessage">
      {{ additionalMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ZField",
  inject: ["$validator"],
  // components: {
  //     [FieldBody.name]: FieldBody
  // },
  props: {
    type: [String, Object],
    label: String,
    labelFor: String,
    message: [String, Array, Object],
    grouped: Boolean,
    groupMultiline: Boolean,
    position: String,
    expanded: Boolean,
    horizontal: Boolean,
    addons: {
      type: Boolean,
      default: true,
    },
    customClass: String,
    isRequired: Boolean,
    isPrivate: Boolean,
    hideRequired: {
      type: [Boolean],
      default: false,
    },
    helpMessage: String,
    additionalMessage: String,
    isShort: Boolean,
    showBox: Boolean,
    showCounter: Boolean,
  },
  data() {
    return {
      newType: this.type,
      newMessage: this.message,
      fieldLabelSize: null,
      isField: true, // Used internally by Input and Select
      isHelpActive: false,
    };
  },
  computed: {
    rootClasses() {
      return [
        {
          "is-expanded": this.expanded,
          "is-grouped": this.expanded,
          "is-grouped-multiline": this.groupMultiline,
          "is-horizontal": this.horizontal,
          "is-floating-in-label":
            this.hasLabel &&
            !this.horizontal &&
            this.labelPosition === "inside",
          "is-floating-label":
            this.hasLabel &&
            !this.horizontal &&
            this.labelPosition === "on-border",
          "is-short": this.isShort,
          "is-box": this.showBox,
          "is-showCounter": this.showCounter,
        },
      ];
    },
    /**
     * Formatted message in case it's an array
     * (each element is separated by <br> tag)
     */
    formattedMessage() {
      if (typeof this.newMessage === "string") {
        return this.newMessage;
      } else {
        let messages = [];
        if (Array.isArray(this.newMessage)) {
          this.newMessage.forEach((message) => {
            if (typeof message === "string") {
              messages.push(message);
            } else {
              for (let key in message) {
                if (message[key]) {
                  messages.push(key);
                }
              }
            }
          });
        } else {
          for (let key in this.newMessage) {
            if (this.newMessage[key]) {
              messages.push(key);
            }
          }
        }
        return messages
          .filter((m) => {
            if (m) return m;
            return false;
          })
          .join(" <br> ");
      }
    },
    hasLabel() {
      return this.label || this.$slots.label;
    },
  },
  watch: {
    /**
     * Set internal type when prop change.
     */
    type(value) {
      this.newType = value;
    },
    /**
     * Set internal message when prop change.
     */
    message(value) {
      this.newMessage = value;
    },
  },
  methods: {
    /**
     * Field has addons if there are more than one slot
     * (element / component) in the Field.
     * Or is grouped when prop is set.
     * Is a method to be called when component re-render.
     */
    fieldType() {
      if (this.grouped) return "is-grouped";
      let renderedNode = 0;
      if (this.$slots.default) {
        renderedNode = this.$slots.default.reduce(
          (i, node) => (node.tag ? i + 1 : i),
          0
        );
      }
      if (renderedNode > 1 && this.addons && !this.horizontal) {
        return "has-addons";
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-field {
  margin-bottom: 28px;
  &__info {
    margin-bottom: 8px;
    overflow: hidden;
    &__main {
      float: left;
      &__label {
        display: inline-block;
        color: #000;
        font-size: 14px;
        vertical-align: middle;
        & > * {
          vertical-align: middle;
        }
      }
      &__required {
        display: inline-block;
        margin-left: 8px;
        padding: 0 3px;
        background-color: $grey;
        color: #fff;
        font-size: 12px;
        line-height: 14px;
        border-radius: 7px;
        &.is-required {
          background-color: $warning;
        }
      }
    }
    &__sub {
      float: right;
    }
  }
  &__help.collapse /deep/ {
    .collapse-trigger {
      background-color: #e1e1e1;
    }
  }
  &__inputs {
    .is-grouped & {
      margin-left: -20px;
      @include sp {
        margin-left: -12px;
      }
      /deep/ .control {
        display: inline-block;
      }
      & > * {
        vertical-align: middle;
        margin-left: 20px;
        @include sp {
          margin-left: 12px;
        }
      }
    }
  }
  &__message {
    margin-top: 4px;
    font-size: 12px;
  }
  .z-helpIcon {
    margin-left: 4px;
  }
  /deep/ .notification {
    max-width: 300px;
    margin-bottom: 0;
    padding: 6px 24px 6px 6px;
    color: #6d7278;
    font-size: 12px;
    .delete {
      right: 5px !important;
      top: 5px !important;
      background-color: transparent;
      &::before,
      &::after {
        background-color: #333333;
      }
      &::before {
        width: 80%;
      }
      &::after {
        height: 80%;
      }
    }
  }
  /deep/ .control {
    p.help {
      padding-right: 40px;
      font-size: 14px;
    }
    .counter {
      display: none;
    }
  }
  &.is-danger {
    .z-field__info__main__label {
      color: $danger;
    }
  }
  &.is-short {
    /deep/ .control {
      display: inline-block;
    }
  }
  &.is-box {
    padding: 13px;
    margin-bottom: 4px;
    background-color: #f8f8f8;
    /deep/ .block {
      margin: 0;
    }
  }
  &.is-showCounter {
    /deep/ .control {
      .counter {
        display: block;
        position: absolute;
        bottom: -22px;
        right: 0;
      }
    }
  }
  // &.is-grouped {
  //   /deep/ .control {
  //     display: inline-block;
  //     &:not(:first-child) {
  //       margin-left: 20px;
  //     }
  //   }
  // }
}
</style>
