<template>
  <div class="z-quarterAxis">
    <div class="z-quarterAxis__xticks">
      <div
        class="z-quarterAxis__xticks__item"
        v-for="(tick, index) in ticks"
        :key="index"
      >
        <p class="xticks__title">{{ tick.label }}</p>
        <p class="xticks__score" v-if="tick.score && tick.score.score > 0">
          {{ tick.score.score }}
          <span class="xticks__rank">{{
            $$ks.getRank(tick.score.rank).text || ""
          }}</span>
        </p>
      </div>
    </div>
    <b-select
      class="z-quarterAxis__year"
      :name="selectYear"
      v-model="selectYear"
    >
      <template v-for="o in $$ks.chartYears">
        <option :value="o.text" :key="o.text">
          {{ o.text }}
        </option>
      </template>
    </b-select>
  </div>
</template>

<script>
export default {
  name: "ZQuarterAxis",
  props: {
    year: {
      type: String,
      required: true,
    },
    quarterData: {
      type: Array,
      required: true,
    },
    currentScore: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectYear: {
      get() {
        return this.year;
      },
      set(newVal) {
        if (this.year !== newVal) this.$emit("update:year", newVal);
      },
    },
    ticks() {
      return [...Array(5)].map((_, index) => {
        if (index < this.quarterData.length) {
          const data = this.quarterData[index];
          return {
            label: `${this.year}/${this.$$ks.getQuarter(data.quarter).text}`,
            score: data.score,
          };
        }
        if (index === this.quarterData.length) {
          return { label: "Current", score: this.currentScore };
        }
        return { label: null, score: null };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-quarterAxis {
  position: relative;
  padding-top: 6px;
  padding-right: 70px;
  background-color: #fff;
  @include sp {
    padding-top: 0;
    padding-right: 0;
  }
  &__xticks {
    width: 100%;
    display: flex;
    &__item {
      width: 100%;
      text-align: center;
      @include sp {
        margin-top: 6px;
      }
    }
  }
  &__year {
    top: 6px;
    right: 0;
    margin: auto;
    display: flex;
    position: absolute;
    align-items: center;
    /deep/ select {
      border: none;
      border-bottom: 1px solid #ddd;
    }
    @include sp {
      position: relative;
      justify-content: flex-end;
    }
  }
}

.xticks {
  &__title {
    font-size: 16px;
    font-weight: bold;
    @include sp {
      font-size: 14px;
    }
  }
  &__score {
    font-size: 14px;
  }
  &__rank {
    width: 13px;
    height: 13px;
    margin-left: 0;
    display: inline-block;
    border-radius: 50%;
    background-color: #1f57b2;
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    color: #fff;
    vertical-align: 2px;
  }
}
</style>
