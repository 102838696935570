<template>
  <div>
    <section>
      <ZHeader title="Account" sub="アカウント設定">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div v-if="!isLoading">
          <div>
            <!--
            <div class="content is-small">
              <h3 class="list-title">会員プランの変更</h3>
            </div>
            <div class="content-main">
              <p>加入会員プラン変更</p>
              <p>
                {{ $$user$isPremium ? "プレミアム会員" : "無料会員" }}
              </p>
              <template v-if="$$user$isPremium">
                <div>
                  <img src="@/assets/investor/premium-card.png" />
                </div>
              </template>
            </div>
            <div class="content-main second-content">
              <p>加入状況</p>
              <div class="table-box">
                <dl>
                  <dt>加入コース</dt>
                  <dd>{{ paramsForChangingPlan.course }}</dd>
                </dl>
                <dl>
                  <dt>プレミアム有効期限</dt>
                  <dd>{{ paramsForChangingPlan.expirationDate }}</dd>
                </dl>
              </div>
            </div>
            <div class="buttons has-addons is-centered">
              <router-link
                :to="{ name: paramsForChangingPlan.linkName }"
                class="button z-button is-primary is-rounded is-wide"
              >
                {{ paramsForChangingPlan.buttonText }}
              </router-link>
            </div>
            <hr />
            <div class="content-main">
              <p>登録クレジットカード情報の変更</p>
              <p>
                決済代行会社（株式会社メタップスペイメント）のサイトで確認・変更してください
              </p>
            </div>
            <template v-if="isRegisteredCard && cardInfo">
              <div class="content-main second-content">
                <p>カード情報</p>
                <div class="table-box">
                  <dl>
                    <dt>カード番号</dt>
                    <dd>{{ cardInfo.CREDITNO }}</dd>
                  </dl>
                  <dl>
                    <dt>有効期限</dt>
                    <dd>{{ formatCardEXP(cardInfo.EXP) }}</dd>
                  </dl>
                </div>
              </div>
            </template>
            <div class="buttons is-centered">
              <ZCreditCardRegister
                class="cardButton"
                :disabled="!$$user$isPremium"
                :OKURL="successURLOfCardRegistration"
                :RT="failureURLOfCardRegistration"
                :uid="user.id"
                label="登録・変更する"
              />
            </div>
            -->
          </div>
          <div class="content-wrapper">
            <div class="content is-small">
              <h3 class="list-title">パスワードの変更</h3>
            </div>
            <div class="content-main">
              <p>アカウント情報：メールアドレス</p>
              <p>{{ this.auth.auth.email }}</p>
              <z-input
                label="今のパスワード"
                name="oldpassword"
                v-model="account.oldpassword"
                data-vv-as="今のパスワード"
                type="password"
                maxlength="128"
                :validate="'required:true|min:8'"
                icon-pack="fas"
                icon="lock"
                password-reveal
                isRequired
              >
              </z-input>
              <z-input
                label="新しいパスワード"
                name="password"
                v-model="account.password"
                data-vv-as="新しいパスワード"
                type="password"
                maxlength="128"
                :validate="'required:true|min:8'"
                icon-pack="fas"
                icon="lock"
                password-reveal
                isRequired
              >
              </z-input>
            </div>
            <div class="buttons has-addons is-centered">
              <button
                class="button z-button is-primary is-primary is-rounded is-wide"
                @click="changePassword"
              >
                変更する
              </button>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="content is-small">
              <h3 class="list-title">メール配信設定の変更</h3>
            </div>
            <div class="field content-main">
              <p>メール配信設定</p>
              <p>
                StockFormerから各種ご案内・お知らせをご登録のメールアドレスに配信いたします
              </p>
              <div class="b-switch-flex">
                <b-switch
                  v-model="user.data.confirm.mailingList"
                  class="is-small"
                >
                </b-switch>
                <p>
                  メール配信を希望する<br /><span
                    class="has-text-gray is-size-7"
                    >※ 重要なお知らせ等は、設定有無に関わらず配信されます</span
                  ><span class="has-text-gray is-size-7"
                    ><br />※
                    設定変更の際は、必ず以下「変更する」ボタンを押下して変更してください</span
                  >
                </p>
              </div>
            </div>
            <div class="buttons has-addons is-centered">
              <button
                class="button z-button is-wide is-primary z-button is-primary is-rounded"
                @click="save"
              >
                変更する
              </button>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="content is-small">
              <h3 class="list-title">
                不動産売買の申告（仲介手数料10％キャッシュバック優遇のお申込み）
              </h3>
            </div>
            <div
              class="content-main letter"
              :style="{ 'background-image': 'url(' + AssetsImage + ')' }"
            >
              <div class="letter-content-wrapper">
                <p>{{ me.user.data.base.name }} 様</p>
                <p>
                  StockFormerを通じて不動産売買を成約いただくと、<br
                    class="sp-none"
                  />仲介手数料の10％を キャッシュバック優遇いたします。<br />
                </p>
                <p>以下からキャッシュバック優遇をお申し込みください。</p>
              </div>
            </div>
            <div class="buttons has-addons is-centered">
              <button
                class="button z-button is-primary is-rounded is-wide"
                @click="movePageToTransaction"
              >
                キャッシュバック優遇を申し込む
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="content is-small has-text-right">
          <p class="is-size-7 has-text-gray">VER.{{ $$env$version }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetsImage from "@/assets/letter-background.jpg";
import firebase from "@/plugins/firebase";
// import { join } from 'path';
export default {
  name: "admin-account-Account",
  data: function () {
    return {
      AssetsImage: AssetsImage,
      isLoading: true,
      user: null,
      account: {
        oldpassword: "",
        password: "",
      },
    };
  },
  async created() {
    this.user = await this.$$investor$loadUser(this.me.user.id);
    //if (auth) {
    //  this.auth = auth;
    //  this.account.email = auth.email;
    //}
    this.isLoading = false;
  },
  computed: {
    paramsForChangingPlan() {
      if (this.$$user$isPremium) {
        if (this.$$user$isTrial) {
          return {
            course: "90日間無料トライアル",
            expirationDate: this.$$calender$dateFormat(
              this.user.examination.data.endDt
            ),
            linkName: "investor-premium-detail",
            buttonText: "変更する",
          };
        } else {
          return {
            course: "30日間コース　5,000円（税別）の一括払い",
            expirationDate: this.$$calender$dateFormat(
              this.user.examination.data.endDt
            ),
            linkName: "investor-premium-detail",
            buttonText: "変更する",
          };
        }
      } else {
        if (this.$$user$alreadyUsedTrial) {
          return {
            course: "無料会員",
            expirationDate: "-",
            linkName: "investor-premium",
            buttonText: "プレミアムプラン申込み",
          };
        } else {
          return {
            course: "無料会員",
            expirationDate: "-",
            linkName: "investor-premium",
            buttonText: "90日無料トライアル",
          };
        }
      }
    },
    successURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-account",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
    failureURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-account",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
  },
  asyncComputed: {
    async isRegisteredCard() {
      return this.user && (await this.$$investor$isRegisteredCard(this.user));
    },
    async cardInfo() {
      return await this.$$investor$cardInfo(this.user);
      // const loadingComponent = this.$loading.open();
      // try {
      //   if (!this.user) {
      //     return null;
      //   }
      //   return await this.$$investor$cardInfo(this.user);
      // } catch (error) {
      //   this.$raven.captureException(error);
      //   this.$$toast$openError();
      // } finally {
      //   loadingComponent.close();
      // }
    },
  },
  methods: {
    formatCardEXP(exp) {
      const str = String(exp);
      return `${str.slice(2, 4)}/${str.slice(0, 2)}`;
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.user);
        this.$router.push({
          name: "investor-account",
        });
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    changePassword: function () {
      const self = this;
      const loadingComponent = this.$loading.open();
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.auth.auth.email,
        this.account.oldpassword
      );
      user
        .reauthenticateAndRetrieveDataWithCredential(credential)
        .then(() => {
          return user.updatePassword(self.account.password);
        })
        .then(() => {
          self.$$toast$openSuccess("新しいパスワードを登録しました");
          // self.$toast.open({
          //   duration: 3000,
          //   message: "新しいパスワードを登録しました",
          //   position: "is-top",
          //   type: "is-success"
          // });
        })
        .finally(() => {
          loadingComponent.close();
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("登録に失敗しました。");
          // self.$toast.open({
          //   message: "登録に失敗しました。",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        });
    },
    movePageToTransaction() {
      this.$router.push({
        name: "investor-setting-transaction",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.content {
  p:nth-child(1) {
    font-weight: bold;
  }
  p:nth-child(2) {
    font-weight: bold;
    color: gray;
    font-size: 1.2em;
  }
  .list-title {
    color: white;
  }
}
.content-main {
  padding-left: 20px;
  p:nth-child(1) {
    font-weight: bold;
    margin: 5px auto;
  }
  p:nth-child(2) {
    margin: 5px auto;
  }
}

.second-content {
  margin: 30px auto;
}

.table-box {
  display: table;
  border-top: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
  border-left: 2px solid #cccccc;
  font-size: 14px;
  width: 95%;
}
.table-box dl {
  display: table-row;
}
.table-box dt,
.table-box dd {
  display: table-cell;
  padding: 12px;
  border-bottom: 2px solid #cccccc;
}
.table-box dt {
  width: 20%;
  @include sp {
    width: 40%;
  }
  background-color: #ddd;
}

.cardButton {
  text-align: center;
  @include md {
    width: 100%;
  }
  @include sp {
    width: 100%;
  }
}

.buttons {
  margin-top: 50px;
  // button {
  //   margin-top: 50px;
  // }
}
.field {
  p:nth-child(1) {
    font-weight: bold;
  }
  p {
    margin: 5px auto;
  }
  label {
    margin-top: 20px;
  }
}
.has-text-gray {
  color: rgba(0, 0, 0, 0.5);
}
.control-label {
  margin-top: 50px;
}
.b-switch-flex {
  display: flex;
  margin-top: 50px;
  label {
    height: 0px;
  }
  p {
    margin: 8px 0 0 15px !important;
  }
}
.content-wrapper {
  margin: 50px 0;
  .letter {
    width: 60%;
    margin: 100px auto 0px auto;
    height: 400px;
    background-size: 100%;
    background-repeat: no-repeat;
    border: 2px solid #b22222;
    position: relative;
    @include lg {
      min-width: 626px;
    }
    @include md {
      width: 80%;
      background-size: 80%;
      min-width: 600px;
    }
    @include sp {
      width: 95%;
      background-size: 70%;
      background-repeat: no-repeat;
      height: 350px;
    }
    .letter-content-wrapper {
      position: absolute;
      top: 53%;
      left: 55%;
      transform: translate(-50%, -50%);
      p {
        margin: 15px 0;
        font-weight: lighter;
        width: 70%;
      }
      @include lg {
        p {
          white-space: nowrap;
        }
      }
      @include md {
        top: 50%;
        left: 55%;
        width: 70%;
        p {
          white-space: nowrap;
        }
      }
      @include sp {
        top: 50%;
        left: 52%;
        margin: auto;
        width: 70%;
        p {
          width: 95%;
        }
        .sp-none {
          display: none;
        }
      }
    }
  }
}
</style>
