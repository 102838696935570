<template>
  <div class="portfolioCharts">
    <b-tabs class="portfolioCharts__tab" :animated="false">
      <b-tab-item class="portfolioCharts__tab__item" label="Balance Sheet">
        <ZBalanceSheet
          v-if="balanceSheetChartData"
          :chartData="balanceSheetChartData"
          :score="$$user$score"
          :bs="$$user$BS"
          :year.sync="balanceSheetChartYear"
        />
      </b-tab-item>
      <b-tab-item class="portfolioCharts__tab__item" label="Income">
        <ZIncome
          v-if="incomeChartData"
          :chartData="incomeChartData"
          :score="$$user$score"
          :pl="$$user$PL"
          :year.sync="incomeChartYear"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ZBalanceSheet from "@/components/charts/ZBalanceSheet.vue";
import ZIncome from "@/components/charts/ZIncome.vue";

export default {
  name: "PortfolioCharts",
  components: {
    ZBalanceSheet,
    ZIncome,
  },
  data() {
    return {
      // 読み込み中フラグ
      isLoading: false,
      // バランスシート(APIのリクエスト)
      balanceSheetChartYear: new Date().getFullYear().toString(),
      // 収入区分グラフ(APIのリクエスト)
      incomeChartYear: new Date().getFullYear().toString(),
    };
  },
  asyncComputed: {
    async balanceSheetChartData() {
      return await this.$$investor$getBSHistory(this.me.id, {
        year: this.balanceSheetChartYear,
      });
    },
    async incomeChartData() {
      return await this.$$investor$getPLHistory(this.me.id, {
        year: this.incomeChartYear,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.portfolioCharts {
  /deep/ .tab-content {
    padding: 16px 0 0;
  }
  &__tab {
    @include sp {
      // padding-top: 12px;
    }
    &__item {
      width: 100%;
      max-width: 1224px;
      padding: 0 46px;
      margin: 0 auto;
      @include sp {
        padding: 0;
      }
    }
  }
}
</style>
