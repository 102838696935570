<template>
  <div class="tileBoxOverlay">
    <b-modal
      :active="isModalActiveReceive"
      has-modal-card
      full-screen
      :can-cancel="true"
      :animation="$$userAgent$isIE() ? 'v-noanime' : 'zoom-out'"
      ref="modal"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-head">
          <template v-if="isTitleNewline">
            <h2 class="modal-card-head__title modal-card-head__title1">
              {{ title1 }}
            </h2>
            <h2 class="modal-card-head__title modal-card-head__title2">
              {{ title2 }}
            </h2>
          </template>
          <template v-else>
            <h2 class="modal-card-head__title">{{ title }}</h2>
          </template>
          <!-- <h2 class="modal-card-head__title">{{ title }}</h2> -->
          <h3 class="modal-card-head__subtitle">{{ subTitle }}</h3>
        </div>
        <div class="modal-card-body">
          <div class="modal-card-body__content">
            <slot name="OverlayReceive"></slot>
            <!-- <div class="modal-card-body__foot">
              <b-button
                :disabled="$$validator$invalid"
                @click="
                  onSubmit()
                  $refs.modal.close();
                "
                type="is-primary"
                rounded
              >{{submitBtnLabel}}</b-button>
            </div> -->
          </div>
        </div>
        <button class="modal-card__closeBtn" @click="close()">
          <b-icon icon="close" size="is-midium"></b-icon>
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "TileBoxOverlay",
  inject: ["$validator"],
  data: function () {
    return {
      isModalActiveReceive: false,
    };
  },
  props: {
    //isModalActiveReceive: {
    //  type: Boolean,
    //  required: true,
    //  default: false
    //},
    title: String,
    subTitle: String,
    submitBtnLabel: String,
    title1: String,
    title2: String,
    isTitleNewline: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    //onClose: function() {
    //  this.$emit("handleOnClose");
    //},
    open() {
      this.isModalActiveReceive = true;
      document.documentElement.classList.add("iosBugFixCaret");
    },
    close() {
      this.isModalActiveReceive = false;
      document.documentElement.classList.remove("iosBugFixCaret");
      this.$emit("handleOnClose");
    },
    // onSubmit: function() {
    //   this.$emit("handleOnSubmit");
    // }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

// for IE11 hack
// _:-ms-lang(x)::-ms-backdrop,
// .modal {
//   opacity: 1;
// }

.tileBoxOverlay /deep/ {
  & > .modal {
    & > .animation-content {
      // position: relative;
      width: calc(100% - 48px) !important;
      height: calc(100% - 48px) !important;
      @include sp {
        width: calc(100% - 32px) !important;
        height: calc(100% - 32px) !important;
      }
    }
  }
  .modal-close {
    display: none;
  }
  .modal-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    &-head {
      display: block;
      padding: 22px 44px 26px;
      background-color: #fff;
      border-bottom: solid 1px #d8d8d8;
      border-top-left-radius: 8px; // for iOS
      border-top-right-radius: 8px; // for iOS
      @include sp() {
        padding: 22px 16px 16px;
      }
      &__title {
        display: block;
        font-family: $family-secondary;
        font-size: 36px;
        line-height: 1em;
        color: #333333;
        @include sp() {
          font-size: 32px;
        }
      }
      &__subtitle {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        line-height: 1em;
        color: #aaaaaa;
        @include sp() {
          margin-top: 4px;
        }
      }
    }
    &-body {
      position: relative;
      padding-top: 24px;
      padding-bottom: 50px;
      border-bottom-left-radius: 8px; // for iOS
      border-bottom-right-radius: 8px; // for iOS
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      @include sp() {
        padding: 0 0 36px 0;
      }
      &__content {
        margin: 0 auto;
      }
      &__foot {
        margin: 50px 0;
        text-align: center;
      }
    }
    &__closeBtn {
      display: block;
      position: absolute;
      right: 24px;
      top: 24px;
      padding: 0;
      border: none;
      background-color: transparent;
      &:hover {
        cursor: pointer;
      }
      @include sp() {
        right: 14px;
        top: 14px;
      }
    }
  }
}
</style>
