<template>
  <div
    class="investor"
    :class="{
      'is-hideMenu': isRegistration,
      'is-hideSummery': isRealEstate,
      'is-show-summary-sp': isDashBoard,
    }"
  >
    <div>
      <Menu
        v-if="(!isRegistration && !isRealEstate) || bulma.isMobile"
        :hideMenu="isRegistration || isRealEstate"
      >
        <template v-slot:rightIcons v-if="!isRegistration">
          <ZNotification color="#fff" />
        </template>
      </Menu>
      <Summary
        v-if="!isRealEstate"
        :isHideMenu="isRegistration || isRealEstate"
      />
    </div>
    <div class="wrapper">
      <div class="contents">
        <router-view></router-view>
      </div>
      <Footer :isHideMenu="isRegistration || isRealEstate" />
    </div>
  </div>
</template>

<script>
import Summary from "@/components/layout/investor/Summary";
import Footer from "@/components/layout/investor/Footer";
import Menu from "@/components/layout/investor/Menu";

export default {
  name: "investor-Index",
  // mixins: [AuthMixin, VersionsMixin],
  // mixins: [
  //   VersionsMixin
  // ],
  components: {
    Menu: Menu,
    Summary: Summary,
    Footer: Footer,
  },
  computed: {
    isRegistration() {
      return this.$route.path.indexOf("/investor/register") > -1;
    },
    isRealEstate() {
      return (
        this.$route.path.indexOf("/investor/markettrend/realestate/") > -1 ||
        this.$route.path.indexOf("/investor/search/realtor/realestates/") > -1
      );
    },
    isDashBoard() {
      return (
        this.$route.path.indexOf("/investor/home") > -1 ||
        this.$route.path === "/investor/" ||
        this.$route.path === "/investor"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.investor {
  padding-left: $menu-width-md + $summary-width;
  height: 100%;
  &.is-hideMenu {
    padding-left: $summary-width;
    /deep/ .summary {
      left: 0;
    }
  }
  &.is-hideSummery {
    padding-left: 0;
  }
  @include md {
    padding-left: $menu-width-md + $summary-width-md-close;
    &.is-hideMenu {
      padding-left: $summary-width-md-close;
    }
    &.is-hideSummery {
      padding-left: 0;
    }
  }
  @include sp {
    padding-left: 0;
    &.is-hideMenu {
      padding-left: 0;
    }
    padding-top: 64px;
    /deep/ .summary {
      display: none;
    }
    &.is-show-summary-sp {
      padding-top: 0;
      /deep/ .summary {
        display: block;
      }
    }
  }
}
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 268px;
  @include md {
    padding-bottom: 443px;
  }
  @include sp {
    padding-bottom: 400px;
  }
}
.contents {
  margin-bottom: 50px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

// common
.mtr-inner {
  position: relative;
}
</style>
