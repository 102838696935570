<template>
  <span class="privateLabel">非公開<b-icon icon="lock"></b-icon></span>
</template>

<script>
export default {
  name: "PrivateLabel",
  props: {
    label: String,
    callback: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.privateLabel {
  display: inline-block;
  padding: 1px 4px 0;
  border-radius: 6px;
  background-color: #f4f4f4;
  color: #797979;
  font-size: 12px;
  line-height: 14px;
  @include sp {
    font-size: 12px;
  }
  /deep/ .icon {
    width: auto;
    height: auto;
    .mdi::before {
      position: relative;
      top: 1px;
      font-size: 13px;
      @include sp {
        font-size: 11px;
        vertical-align: -1px;
      }
    }
  }
}
</style>
