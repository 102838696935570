const RequiresAuth = {
  none: 0,
  required: 1,
  noRequired: 2,
};

const needCompleteRegister = {
  yes: 0,
  no: 1,
};

const role = {
  realtor: 1,
  investor: 2,
  admin: 3,
  auth: 4,
  none: 9,
};

const examination = {
  all: 0,
  allow: 1,
  deny: 2,
  notAllow: 11, // Allow状態だとみえない
};

export default {
  needCompleteRegister,
  examination,
  RequiresAuth,
  role,
};
