<template>
  <div class="z-datepicker">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
    >
      <div class="z-datepicker__picker">
        <b-datepicker
          :name="internalName"
          v-model="internalDate"
          v-validate="validate"
          :data-vv-as="label"
          :placeholder="placeholder"
          :years-range="[-100, 30]"
          :max-date="maxOfDate"
          ref="datepicker"
        >
        </b-datepicker>
        <b-button
          v-if="!bulma.isMobile"
          @click="$refs.datepicker.toggle()"
          icon-left="calendar-today"
          type="is-primary"
          outlined
        />
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZDatepicker",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object, Date],
      default: null,
    },
    maxOfToday: {
      type: [Boolean],
      default: true,
    },
  },
  computed: {
    maxOfDate() {
      if (this.maxOfToday) {
        return new Date();
      } else {
        return new Date("2100/12/31");
      }
    },
    internalDate: {
      get() {
        if (this.internalValue && this.internalValue.toDate) {
          return this.internalValue.toDate();
        } else {
          return this.internalValue;
        }
      },
      set(val) {
        this.internalValue = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-datepicker {
  .z-datepicker__picker {
    display: flex;
    /deep/ .button {
      margin-left: 20px;
      padding: 0 10px;
      .icon {
        font-size: 22px;
        line-height: 1em;
      }
    }
  }
}
</style>
