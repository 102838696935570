<template>
  <div>
    <z-checkbox
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :isRequired="isRequired"
    >
    </z-checkbox>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

// import axios from "axios-jsonp-pro";
export default {
  name: "ZLineCheckbox",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      selected: null,
      lines: [],
    };
  },
  props: {
    value: {
      type: [Array],
      required: true,
    },
    prefecture: {
      type: [Number],
      default: null,
    },
  },
  computed: {
    options() {
      const opts = [];
      for (const c of this.lines) {
        opts.push({
          value: c.line_cd,
          text: c.line_name,
        });
      }
      return opts;
    },
  },
  created: async function () {
    try {
      this.loading = true;
      this.lines = [];
      const val = this.prefecture;
      if (val) {
        this.lines = await this.loadLines(val);
        const selected = this.lines.find(
          (l) => l.line_cd === this.internalValue
        );
        this.selected = selected ? selected.line_name : null;
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadLines: function (code) {
      return this.$$line$loads(code);
    },
  },
  watch: {
    prefecture: async function (val) {
      try {
        this.loading = true;
        this.lines = [];
        this.internalValue = [];
        this.selected = null;
        if (val) {
          const lines = await this.loadLines(val);
          this.lines = lines;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
