<template>
  <section class="rsDetail">
    <!-- 融資情報 -->
    <div v-if="showLoanInfo">
      <h3 class="rsDetail__title">融資情報</h3>
      <ul class="rsDetail__table">
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>融資金額</span></dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>融資なし</span>
              </template>
              <template v-else>
                <span>{{
                  $$price$priceJPFormat(data.purchase.loan.amount)
                }}</span>
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>融資実行年月</span></dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>-</span>
              </template>
              <template v-else>
                <span>{{
                  $$calender$yearMonthFormat(data.purchase.loan.executeDate)
                }}</span>
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>金融機関・支店名</span></dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>-</span>
              </template>
              <template v-else>
                <span>{{ bankStr }}</span>
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>返済期間</span></dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>-</span>
              </template>
              <template v-else>
                <span
                  >{{ data.purchase.loan.paymentPeriods.year }}年{{
                    data.purchase.loan.paymentPeriods.month
                  }}ヶ月</span
                >
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >金利<z-help-icon
                  help="借入金利の種類。固定金利：借入れ時の金利が全借入期間もしくは一定期間変わらないタイプ。変動金利：金融情勢の変化に伴い返済の途中でも定期的に金利が変動するタイプ。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>-</span>
              </template>
              <template v-else>
                <span
                  >{{
                    $$realEstate$interestTypeFormt(
                      data.purchase.loan.interestType
                    )
                  }}
                  {{ $$rete$rateFormat(data.purchase.loan.interestRate) }}</span
                >
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>返済方法</span></dt>
            <dd>
              <template v-if="data.purchase.loanOrCash === 2">
                <span>-</span>
              </template>
              <template v-else>
                <span>{{ method }}</span>
              </template>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <!-- / 融資情報 -->

    <!-- 建物情報(詳細) -->
    <div v-if="showBildingInfo">
      <h3 class="rsDetail__title">建物情報(詳細)</h3>
      <ul class="rsDetail__table">
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>間取</span></dt>
            <dd>
              <template v-if="data.realEstateType === 10">
                <span>-</span>
              </template>
              <template v-else>
                <template v-if="isRealtor">
                  <template v-if="layouts && layouts.length > 0">
                    <template v-for="(l, idx) in layouts">
                      <span :key="idx">{{ l }}<br /></span>
                    </template>
                  </template>
                  <template v-else>
                    <span>-</span>
                  </template>
                </template>
                <template v-else>
                  <template v-if="layouts">
                    <span>{{ layouts }}</span>
                  </template>
                  <template v-else>
                    <span>-</span>
                  </template>
                </template>
              </template>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >総戸数<z-help-icon
                  help="その物件にある住戸の数の合計数。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span>{{ checkNull(data.saleSetting.numberOfHouseholds) }}</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >駐車場<z-help-icon
                  help="駐車場の有無。台数、料金などを表示。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span>{{ checkNull(data.saleSetting.parking) }}</span>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <!-- / 建物情報(詳細) -->

    <!-- 土地情報(詳細) -->
    <div v-if="showlandInfo">
      <h3 class="rsDetail__title">土地情報(詳細)</h3>
      <ul class="rsDetail__table">
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>面積</span></dt>
            <dd>
              <span>{{ data.saleSetting.landArea }}㎡</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >土地権利<z-help-icon
                  help="土地の権利形態。大きく、一般的な土地の売買である「所有権」、他人から土地を借りて、その上に自分の建物を建てる場合に生じる「借地権」がある。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span>{{ landRight }}</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >都市計画区域<z-help-icon
                  help="都道府県ごとに定められ、無秩序な市街化を防止し、計画的な市街化を図るために定めている。「市街化区域」：優先的かつ計画的に市街化を進める区域。「市街化調整区域」：開発行為は原則として抑制され、都市施設の整備も原則として行われない区域"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span>{{ checkNull(urbanPlanningArea) }}</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >用途地域<z-help-icon
                  help="用途の混在を防ぐ目的で定められた、13種類の区域。設定された区域ごとに建築物の用途、容積率、構造等に関し一定の制限を加えることができる。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span>{{ checkNull(areaOfUse) }}</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >建蔽率<z-help-icon
                  help="敷地（土地）面積に対する建築面積の割合。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span v-if="data.saleSetting.buildingCoverageRatio">{{
                $$rete$rateFormat(data.saleSetting.buildingCoverageRatio)
              }}</span>
              <span v-else>-</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt>
              <span
                >容積率<z-help-icon
                  help="敷地（土地）面積に対する建物の延床面積の割合。"
                  onlyPC
              /></span>
            </dt>
            <dd>
              <span v-if="data.saleSetting.floorAreaRatio">{{
                $$rete$rateFormat(data.saleSetting.floorAreaRatio)
              }}</span>
              <span v-else>-</span>
            </dd>
          </dl>
        </li>
        <li>
          <dl class="rsDetail__table__dl">
            <dt><span>私道負担面積</span></dt>
            <dd>
              <span v-if="data.saleSetting.roadBurdenAreaRatio"
                >{{ data.saleSetting.roadBurdenAreaRatio }}㎡</span
              >
              <span v-else>-</span>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <!-- / 土地情報(詳細) -->
    <template v-if="isRealtor">
      <!-- 詳細（運営） -->
      <div v-if="showlandInfo">
        <h3 class="rsDetail__title">詳細（運営）</h3>
        <ul class="rsDetail__table">
          <li class="width-max">
            <dl class="rsDetail__table__dl">
              <dt><span>現状空室率</span></dt>
              <template v-if="data.realEstateType === 10">
                <dd>
                  <span>-</span>
                </dd>
              </template>
              <template v-else>
                <dd v-if="data.vacancyRate === 2">
                  <pre
                    class="normal"
                  >空室あり&nbsp;:&nbsp;<span v-text="data.vacantRoom"></span></pre>
                </dd>
                <dd v-if="data.vacancyRate !== 2">
                  <pre class="normal">満室</pre>
                </dd>
              </template>
            </dl>
          </li>
        </ul>
      </div>
      <!-- /詳細（運営） -->
      <!-- その他 -->
      <div v-if="showlandInfo">
        <h3 class="rsDetail__title">その他</h3>
        <ul class="rsDetail__table">
          <li class="width-max">
            <dl class="rsDetail__table__dl">
              <dt><span>PR/注意事項</span></dt>
              <dd>
                <pre class="normal" v-text="data.pr"></pre>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <!-- /その他 -->
    </template>
  </section>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "RealestateDetail",
  props: {
    data: Object,
    showLoanInfo: Boolean,
    showBildingInfo: Boolean,
    showlandInfo: Boolean,
    isRealtor: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    method() {
      return kubuns.loanMethodType(this.data.purchase.loan.method).text;
    },
    landRight() {
      return kubuns.getLandRightType(this.data.saleSetting.landRight).text;
    },
    urbanPlanningArea() {
      return kubuns.getUrbanPlanningAreaType(
        this.data.saleSetting.urbanPlanningArea
      ).text;
    },
    areaOfUse() {
      return kubuns.getAreaOfUseType(this.data.saleSetting.areaOfUse).text;
    },
    layouts() {
      if (this.isRealtor) {
        return this.$$realEstate$getLayoutsFormats(
          this.data.saleSetting.layouts
        );
      } else {
        return this.$$realEstate$getLayoutsFormat(
          this.data.saleSetting.layouts
        );
      }
    },
  },
  methods: {
    checkNull(val) {
      if (val) return val;
      else return "-";
    },
  },
  asyncComputed: {
    async bankStr() {
      if (this.data.purchase) {
        const loan = this.data.purchase.loan;
        return await this.$$bank$generateTextOfBank(loan.bankId, loan.branchId);
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.rsDetail {
  &__title {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 20px;
    border-bottom: solid 1px #dddddd;
    @include sp {
      margin-top: 16px;
    }
  }
  &__table {
    display: flex;
    flex-wrap: wrap;
    border-top: solid 1px #dddddd;
    border-left: solid 1px #dddddd;
    & > li {
      width: 50%;
      min-height: 58px;
      border-right: solid 1px #dddddd;
      border-bottom: solid 1px #dddddd;
      @include sp {
        width: 100%;
        min-height: 43px;
      }
    }
    &__dl {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 100%;
      line-height: 1.2em;
      & > dt {
        display: table-cell;
        position: relative;
        padding: 4px;
        width: 120px;
        height: 100%;
        background-color: #f4f4f4;
        vertical-align: middle;
        text-align: center;
        & > span {
          padding: 2px;
          font-size: 14px;
          text-align: center;
          line-height: 1em;
          font-weight: bold;
          color: #777777;
          vertical-align: middle;
          @include sp {
            font-size: 12px;
          }
        }
      }
      & > dd {
        display: table-cell;
        padding: 8px 20px;
        font-size: 15px;
        vertical-align: middle;
        @include sp {
          font-size: 14px;
        }
      }
      /deep/ .z-helpIcon {
        margin-left: 4px;
      }
    }
  }
}
.width-max {
  width: 100% !important;
}
</style>
