<template>
  <div class="z-dateSelect">
    <ZField
      :label="label"
      :type="hasError ? 'is-danger' : ''"
      :message="errorMessage"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
      grouped
    >
      <div class="z-dateSelect__inputs">
        <b-select
          class="z-dateSelect__inputs__year z-select--unit"
          :name="internalName + '-year'"
          v-model="internalDate.year"
          v-validate="validate"
          :data-vv-as="label"
          placeholder="例) 1970"
        >
          <template v-for="o in years">
            <option :value="o.value" :key="o.value">
              {{ o.text }}
            </option>
          </template>
        </b-select>

        <b-select
          class="z-dateSelect__inputs__month z-select--unit"
          :name="internalName + '-month'"
          v-model="internalDate.month"
          v-validate="validate"
          :data-vv-as="label"
          placeholder="例) 04"
        >
          <template v-for="o in months">
            <option :value="o.value" :key="o.value">
              {{ o.text }}
            </option>
          </template>
        </b-select>

        <b-select
          class="z-dateSelect__inputs__date z-select--unit"
          :name="internalName + '-date'"
          v-model="internalDate.date"
          v-validate="validate"
          :data-vv-as="label"
          placeholder="例) 01"
        >
          <template v-for="o in dates">
            <option :value="o.value" :key="o.value">
              {{ o.text }}
            </option>
          </template>
        </b-select>
      </div>
    </ZField>
  </div>
</template>

<script>
import _ from "lodash";

import moment from "moment";
import "moment-timezone";

import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZDateSelect",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object, Date],
      default: null,
    },
    maxOfToday: {
      type: [Boolean],
      default: true,
    },
  },
  data() {
    return {
      internalDate: {
        year: 1970,
        month: null,
        date: null,
      },
    };
  },
  computed: {
    hasError() {
      return (
        this.errors.has(this.internalName + "-year") ||
        this.errors.has(this.internalName + "-month") ||
        this.errors.has(this.internalName + "-date")
      );
    },
    errorMessage() {
      return (
        this.errors.first(this.internalName + "-year") ||
        this.errors.first(this.internalName + "-month") ||
        this.errors.first(this.internalName + "-date") ||
        ""
      );
    },
    years() {
      const today = new Date();
      const todayYear = today.getFullYear();

      // 現在の年までの選択肢
      const maxYear = this.maxOfToday ? todayYear : todayYear + 20;

      return _.range(todayYear - 100, maxYear + 1).map((i) => ({
        value: i,
        text: i + "",
      }));
    },
    months() {
      const { year } = this.internalDate;

      const today = new Date();
      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth() + 1;

      // 現在の月までの選択肢
      if (this.maxOfToday && year === todayYear) {
        return _.filter(kubuns.months, (month) => month.value <= todayMonth);
      }

      return kubuns.months;
    },
    dates() {
      const { year, month } = this.internalDate;

      const today = new Date();
      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth() + 1;
      const todayDate = today.getDate();

      const maxDate = new Date(year, month, 0).getDate();
      const dates = _.range(1, maxDate + 1).map((i) => ({
        value: i,
        text: _.padStart(i, 2, 0),
      }));

      // 現在の日付までの選択肢
      if (this.maxOfToday && year === todayYear && month === todayMonth) {
        return _.filter(dates, (date) => date.value <= todayDate);
      }

      return dates;
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          const date = newVal.toDate ? newVal.toDate() : newVal;
          const current = moment(date).tz("Asia/Tokyo");

          this.internalDate.year = current.year();
          this.internalDate.month = current.month() + 1;
          this.internalDate.date = current.date();
        }
      },
      immediate: true,
    },
    internalDate: {
      handler(val) {
        const { year, month, date } = val;

        // 日付が埋まっていない場合はnullとして扱う
        if (year === null || month === null || date === null) {
          this.$emit("input", null);
          return;
        }

        // 存在しない日付を選択していた場合は日をリセット
        const maxDate = new Date(year, month, 0).getDate();
        if (date && date > maxDate) {
          this.internalDate.date = null;
          this.$emit("input", null);
          return;
        }

        // 未来の日付を選択していた場合は月・日をリセット
        const newDate = new Date(`${year}/${month}/${date}`);
        if (this.maxOfToday && moment().isBefore(newDate)) {
          this.internalDate.month = null;
          this.internalDate.date = null;
          this.$emit("input", null);
          return;
        }
        this.$emit("input", newDate);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-dateSelect {
  &__inputs {
    display: flex;
    &__year,
    &__month,
    &__date {
      display: inline-block;
      /deep/ .select {
        &:before {
          position: absolute;
          font-size: 12px;
          top: 8px;
          right: 26px;
        }
      }
    }
    &__year {
      /deep/ .select {
        &:before {
          content: "年";
        }
        select {
          min-width: 200px;
          @include sp {
            min-width: 100px;
          }
        }
      }
    }
    &__month {
      margin-left: 22px;
      @include sp {
        margin-left: 12px;
      }
      /deep/ .select {
        &:before {
          content: "月";
        }
        select {
          min-width: 200px;
          @include sp {
            min-width: 80px;
          }
        }
      }
    }
    &__date {
      margin-left: 22px;
      @include sp {
        margin-left: 12px;
      }
      /deep/ .select {
        &:before {
          content: "日";
        }
        select {
          min-width: 200px;
          @include sp {
            min-width: 80px;
          }
        }
      }
    }
  }
}
</style>
