var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AssetsInfoTitle"},[_c('div',{staticClass:"AssetsInfoTitle__titleDetail"},[_c('div',{staticClass:"AssetsInfoTitle__titleDetail__box"},[_c('div',{staticClass:"colorBox",class:{
          cash: _vm.isCash,
          securityInsurance: _vm.isSecurityInsurance,
          realEstateBS: _vm.isRealEstateBS,
          liabilities: _vm.isLiabilities,
          netAssets: _vm.isNetAssets,
          salary: _vm.isSalary,
          dividend: _vm.isDividend,
          pension: _vm.isPension,
          realEstatePL: _vm.isRealEstatePL,
          others: _vm.isOthers,
        }})]),_c('p',{staticClass:"AssetsInfoTitle__titleDetail__txt"},[_vm._v(_vm._s(_vm.titleTxt))])]),_c('div',{staticClass:"AssetsInfoTitle__amount"},[(_vm.isAmountExist)?_c('span',{staticClass:"AssetsInfoTitle__amount__yenMark"},[_vm._v("¥ ")]):_vm._e(),(_vm.isAmountExist)?_c('span',{staticClass:"AssetsInfoTitle__amount__money"},[_vm._v(_vm._s(_vm._f("addComma")(_vm.amount))+" ")]):_vm._e(),(!_vm.isAmountExist)?_c('p',{staticClass:"AssetsInfoTitle__amount__blankTxt"},[_vm._v(" 記入するとスコアアップ ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }