<template>
  <div>
    <section>
      <ZHeader title="マイページ" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <h4 class="list-title">お知らせ</h4>
        <InformationList class="information-list" :informations="infos" />
        <router-link
          class="button z-button is-small is-primary is-rounded is-outlined is-fullwidth"
          :to="{ name: 'realtor-setting-informations' }"
        >
          すべてのお知らせを見る
        </router-link>
        <hr />
        <!--
          @click="onClick"
          :clickable="true"
          :showDetail="false"
        -->
        <h4 class="list-title">成約状況推移</h4>
        <TransactionList
          class="information-list"
          :transactions="transactions"
        />
        <!--
        <div
          :style="{ 'background-image': 'url(' + graphImage + ')' }"
          class="graph"
        >
          <div class="graph graph-content content has-text-centered">
            <p>ご成約後、弊社宛に売買申告すると表示されます。</p>
          </div>
        </div>
        -->
        <router-link
          class="button z-button is-small is-primary is-rounded is-outlined is-fullwidth"
          :to="{
            name: 'realtor-setting-transaction-contract',
            params: { id: 'new' },
          }"
        >
          売買申告をする
        </router-link>
        <!--
        <b-tabs>
          <b-tab-item label="成約状況">
            <div class="graph"></div>
          </b-tab-item>
        </b-tabs>
        -->
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
import InformationList from "@/components/parts/InformationList";
import TransactionList from "@/components/transaction/TransactionList";
import GraphImage from "@/assets/realtor/graphImage.png";

export default {
  name: "realtor-mypage",
  components: {
    InformationList: InformationList,
    TransactionList: TransactionList,
  },
  data: function () {
    return {
      isLoading: true,
      graphImage: GraphImage,
      informations: [],
      transactions: [],
    };
  },
  computed: {
    infos() {
      return this.informations.slice(0, 10);
    },
  },
  methods: {
    //onClick(/*row*/) {
    //  this.$router.push({
    //    name: "realtor-setting-informations",
    //    params: {id:id}
    //  });
    //},
    async init() {
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.isLoading = true;
        this.transactions = await this.$$transaction$loadTransactionsByRealtor(
          this.me.user.id
        );
        this.informations = await this.$$information$loadsByUID(
          this.me.user.id
        );
        for (const info of this.informations) {
          this.$$information$asRead(this.me.user.id, info);
        }
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.information-list {
  margin-bottom: 16px;
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: $important;
//  border-bottom: solid 2px #707070;
//  color: $white;
//}

.graph {
  width: 100%;
  height: 422px;
  // margin-top: 8px;
  margin: 8px 0 16px;

  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden; /*ブラー効果でボヤけた部分を非表示*/
}
.graph:before {
  content: "";
  background: inherit; /*.bgImageで設定した背景画像を継承する*/
  /*-webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);*/
  position: absolute;
  /*ブラー効果で画像の端がボヤけた分だけ位置を調整*/
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1; /*重なり順序を一番下にしておく*/
}
.graph-content {
  margin: 150px 0 200px;
  text-align: center;
  font-size: 24px;
  color: black;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
</style>
