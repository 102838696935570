<template>
  <div>
    <b-field
      :type="$$validator$validatedType(internalName)"
      :message="errors.first(internalName)"
    >
      <b-input
        :name="internalName"
        :size="size"
        :placeholder="placeholder"
        v-model="internalValue"
        :data-vv-as="as"
        v-validate="validate"
      >
      </b-input>
    </b-field>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "DempInput",
  mixins: [InputMixin],
  props: {
    value: {
      type: [String],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
