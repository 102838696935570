<template>
  <div>
    <router-view></router-view>
    <hr />
    Salesforceバッチ
    <hr />
    <b-field label="UID">
      <b-input type="text" v-model="uid" />
    </b-field>
    {{ uid }}<br />
    <button @click="onClickFindAccount" class="button is-primary">検索</button>
    <hr />
    <button @click="onClickSingle" class="button is-primary">一つ</button>
    <hr />
    <button @click="onClickAll" class="button is-primary">全部</button>
    <hr />
    <button @click="onClickMetadata" class="button is-primary">METADATA</button>
    <hr />
    <hr />
    <button @click="onClickDeleteAllAccount" class="button is-primary">
      全削除
    </button>
    <hr />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import investorType from "@/types/investorType";

const public_investors_doc = "public_investors_v1";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

const upsertAccount = async (uid) => {
  await firebase.functions("public_salesforce_upsertAccount", {
    uid,
  });
};

export default {
  name: "admin-batch-salesforce",
  data() {
    return {
      // uid: "7oF1DyVoHMgw3Yc7PHnh8C4jBGs2",
      // uid: "0sjiksWwGAYTxPQVWLubmuPImhI2",
      uid: "8icZVgDJr5V61RHrUw7jm5rQebM2",
    };
  },
  methods: {
    async onClickFindAccount() {
      const uid = this.uid;
      const ret = await firebase.functions("public_salesforce_findAccount", {
        uid,
      });
      console.log(ret.data.account.records[0]);
      console.log(ret.data.realEstate.records);
      console.log(ret.data.buyingNeeds.records);
    },
    async onClickDeleteAllAccount() {
      const uid = this.uid;
      await firebase.functions("public_salesforce_deleteAllBuyignNeeds", {});
      await firebase.functions("public_salesforce_deleteAllAccount", {});
    },
    async onClickMetadata() {
      const uid = this.uid;
      await firebase.functions(
        "public_salesforce_upsertMetaadata",
        {},
        60000,
        1
      );
      // await firebase.functions("public_salesforce_upsertPrmissions", {}, 60000, 1);
    },
    async onClickSingle() {
      const uid = this.uid;
      await upsertAccount(uid);
      const ret = await firebase.functions("public_salesforce_findAccount", {
        uid,
      });
      console.log(ret.data.account.records[0]);
      console.log(ret.data.realEstate.records);
      console.log(ret.data.buyingNeeds.records);
    },
    async onClickAll() {
      console.log("START");
      const qs = await firebase.db.collection(public_investors_doc).get();

      for (const doc of qs.docs) {
        const uid = doc.id;
        console.log(uid);
        const data = doc.data();
        // console.log(data)
        if (!data.user) {
          console.log(`*** Can not find data: ${uid}`);
          continue;
        }

        if (!data.user.data.isActivate) {
          console.log(`*** user is activate: ${uid}`);
          continue;
        }
        if (
          data.user.data.assets.cashes.length > 0 &&
          data.user.data.assets.securities.length > 0 &&
          data.user.data.assets.insurances.length > 0
        ) {
          console.log(
            `*** Can find assets: ${uid} :${data.user.data.assets.cashes.length}:${data.user.data.assets.securities.length}:${data.user.data.assets.insurances.length}`
          );
        }

        if (data.user.data.profile.careers.length > 0) {
          console.log(`### Can find Careers: ${uid}`);
        }
        await upsertAccount(uid);
      }
      console.log("FINISH");
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
