<template>
  <div>
    <b-table
      :data="transactions"
      :hoverable="true"
      :narrowed="true"
      custom-row-key="id"
    >
      <template slot-scope="props">
        <b-table-column field="data.asRead" label="売約契約日">
          {{ $$calender$dateFormat(props.row.data.contractDate) }}
        </b-table-column>
        <b-table-column field="data.asRead" label="契約実行日">
          {{ $$calender$dateFormat(props.row.data.settlementDate) }}
        </b-table-column>
        <b-table-column field="data.asRead" label="投資家氏名">
          {{ props.row.data.user.name }}
        </b-table-column>
        <b-table-column field="data.asRead" label="契約種別">
          {{
            $$ks.realtor.realEstate.getTransactionType(
              props.row.data.transactionType
            ).text
          }}
        </b-table-column>
        <b-table-column field="data.asRead" label="購入／売却">
          {{ $$ks.transaction.getTradeType(props.row.data.tradeType).text }}
        </b-table-column>
        <b-table-column field="data.asRead" label="取引金額">
          {{ $$price$priceFormat(props.row.data.price) }}
        </b-table-column>
        <b-table-column field="data.asRead" label="仲介手数料">
          {{ $$price$priceFormat(props.row.data.fee) }}
        </b-table-column>
        <b-table-column field="data.asRead" label="詳細">
          <router-link
            class="button z-button is-small is-outlined"
            :to="{
              name: 'realtor-setting-transaction-contract',
              params: { id: props.row.id },
            }"
          >
            詳細表示
          </router-link>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>売買申告はありません。</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
//import firebase from "@/plugins/firebase";
export default {
  name: "relator-informationList",
  props: {
    transactions: {
      type: [Array],
      default: () => [],
    },
  },
  // methods: {
  //   onClickRow(row) {
  //     if (this.clickable) {
  //       this.$emit("click", row);
  //     }
  //   }
  // },
  // mounted() {
  //   this.$nextTick(() => {
  //     for (const info of this.informations) {
  //       this.$$information$asRead(this.me.user.id, info);
  //     }
  //     if (this.$refs.messagebox) {
  //       this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
  //     }
  //   });
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message {
  &__list {
    width: 100%;
  }
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}

.information-list {
  &__mark {
    margin-top: 2px;
    @include sp {
      margin-top: 0px;
    }
  }
  &__title {
    margin-top: 3px;
    @include sp {
      margin-top: 0px;
    }
  }
  &__dt {
    margin-top: 3px;
    @include sp {
      margin-top: 0px;
    }
  }
}
</style>
