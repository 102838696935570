<template>
  <div>
    <b-table
      :data="users"
      checkable
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="examination.data.status"
      custom-row-key="id"
      :checked-rows.sync="checkedUsers"
      :striped="true"
      :hoverable="true"
      :mobile-cards="true"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="詳細" width="20">
          <router-link
            :to="{
              name: 'admin-setting-realtors-realtors-users-detail',
              params: {
                cid: props.row.data.company.companyId,
                uid: props.row.id,
              },
            }"
            class="button is-small"
            >詳細</router-link
          >
        </b-table-column>
        <b-table-column
          field="examination.data.status"
          label="承認"
          width="20"
          sortable
        >
          <z-tag
            size="is-small"
            :options="$$ks.realtor.application.user.statuses"
            v-model="props.row.examination.data.status"
          >
          </z-tag>
        </b-table-column>
        <b-table-column field="data.base.email" label="email" sortable>
          {{ props.row.data.base.email }}
        </b-table-column>
        <b-table-column field="data.base.name" label="名前" sortable>
          {{ props.row.data.base.name }}
        </b-table-column>
        <b-table-column field="data.base.name" label="スコア" sortable>
          {{ props.row.data.score.score }}({{
            $$ks.getRank(props.row.data.score.rank).text
          }})
        </b-table-column>
        <b-table-column
          field="data.company.department"
          label="所属・部署"
          sortable
        >
          {{ props.row.data.company.department }}<br />
          {{ props.row.data.company.title }}
        </b-table-column>
        <b-table-column field="company.data.name" label="会社名" sortable>
          <router-link
            :to="{
              name: 'admin-setting-realtors-detail',
              params: {
                cid: props.row.company.id,
              },
            }"
          >
            {{ props.row.company.data.name }}
          </router-link>
          <br />
          <z-tag
            size="is-small"
            :options="$$ks.realtor.application.company.statuses"
            v-model="props.row.company.examination.data.status"
          >
          </z-tag>
        </b-table-column>
        <b-table-column field="data.registerDt" label="登録日" sortable>
          {{ $$calender$dateFormat(props.row.data.registerDt) }}<br />
          {{ props.row.id }}<br />
          <z-tag :options="$$ks.realtor.roles" v-model="props.row.data.role">
          </z-tag>
          <template v-if="props.row.data.isActivate">
            <div class="tag is-danger">ACTIVE</div>
          </template>
          <template v-else>
            <div class="tag">NON ACTIVE</div>
          </template>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "reartors-user-list",
  data: () => {
    return {
      checkedUsers: [],
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  methods: {
    roleKs: function (val) {
      return this.$$ks.get(this.$$ks.realtor.roles, val);
    },
    async issueAccount() {
      for (const user of this.checkedUsers) {
        await this.$$realtor$issueAccountUser(this.auth.uid, user);
      }
    },
    async accept() {
      for (const user of this.checkedUsers) {
        await this.$$realtor$acceptUser(this.auth.uid, user);
      }
    },
    statusCKS: function (val) {
      return this.$$ks.get(this.$$ks.realtor.application.company.statuses, val);
    },
    statusKS: function (val) {
      return this.$$ks.get(this.$$ks.realtor.application.user.statuses, val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
