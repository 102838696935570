<template>
  <div class="areaField">
    <transition name="v-fadeInOut">
      <div v-if="!isSelected" class="columns areaField__form">
        <div class="column areaField__form__input">
          <z-prefecture-select
            label="都道府県"
            v-model="value.prefecture"
            :validate="validate"
            :isRequired="isRequired"
            :hideRequired="hideRequired"
          >
          </z-prefecture-select>
        </div>
        <div class="column">
          <div class="areaField__form__button">
            <b-button
              class="z-button"
              size="is-small"
              :disabled="!value.prefecture"
              @click="onOpenModal"
              >市区町村を選択</b-button
            >
          </div>
        </div>
      </div>
    </transition>

    <transition name="v-fadeInOut">
      <div v-if="isSelected" class="columns">
        <div class="column">
          <div class="areaField__selected">
            <div class="areaField__selected__info">
              <div>都道府県：{{ prefectureInfo }}</div>
              <div>{{ cityInfo }}</div>
            </div>
            <div class="areaField__selected__button">
              <b-button class="z-button" size="is-small" @click="reset"
                >再設定</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </transition>

    <z-modal
      title="エリア選択"
      :isActive="isActiveModal"
      isModalOnModal
      @close="onCloseModal"
    >
      <template v-slot:content>
        <h3 class="edit__title">{{ prefectureInfo }}</h3>
        <z-city-checkbox
          label="エリアにチェックをいれてください（複数選択できます）"
          v-model="cities"
          :prefecture="value.prefecture"
          :validate="validate"
          :isRequired="isRequired"
          :hideRequired="hideRequired"
          showAllCheck
        >
        </z-city-checkbox>
        <div class="edit__foot">
          <b-button
            class="z-button"
            type="is-primary"
            rounded
            @click="setSearch()"
            >保存する</b-button
          >
        </div>
      </template>
    </z-modal>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZAreaField",
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      default: null,
    },
    validate: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    hideRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActiveModal: false,
      isSelected: false,
      cities: [],
    };
  },
  asyncComputed: {
    async prefectureInfo() {
      const ls = await this.$$prefecture$load(this.value.prefecture);
      return ls.text;
    },
    async cityInfo() {
      // 市区町村情報取得
      const resultPromise = [];
      const cityCodes = this.value.cities;
      for (let i = 0, len = cityCodes.length; i < len; ++i) {
        resultPromise.push(
          this.$$city$load(this.value.prefecture, cityCodes[i])
        );
      }
      const result = await Promise.all(resultPromise);

      // 成形
      let infos = "";
      for (let i = 0, len = result.length; i < len; ++i) {
        infos += (i === 0 ? "" : " , ") + result[i].cityName;
      }
      return infos;
    },
  },
  methods: {
    onOpenModal() {
      this.cities = this.value.cities;
      this.isActiveModal = true;
    },
    onCloseModal() {
      this.isActiveModal = false;
    },
    setSearch() {
      this.value.cities = this.cities;
      this.isActiveModal = false;
      this.checkSelected();
    },
    checkSelected() {
      if (this.value.prefecture && this.value.cities.length > 0)
        this.isSelected = true;
    },
    reset() {
      this.value.prefecture = null;
      this.value.cities = [];
      this.isSelected = false;
    },
  },
  created() {
    this.checkSelected();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.areaField {
  position: relative;
  /deep/ .z-field {
    margin: 0;
  }
  /deep/ .columns {
    margin: 0;
    .column {
      position: relative;
      padding: 22px 16px;
    }
  }
  &__form {
    &__button {
      position: relative;
      width: 100%;
      height: 100%;
      .z-button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 200px;
      }
    }
  }
  &__selected {
    &__info {
      font-size: 14px;
    }
    &__button {
      margin-top: 12px;
      text-align: right;
      .z-button {
        width: 200px;
      }
    }
  }
  .edit__foot {
    margin-top: 32px;
    margin-bottom: 20px;
  }
}
</style>
