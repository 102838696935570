<script>
// import firebase from "@/plugins/firebase";
export default {
  name: "ValidatorMixin",
  computed: {
    $$validator$invalid: function () {
      return Object.keys(this.fields).some((key) => {
        if (this.fields[key].invalid) {
          //console.log(key, this.fields[key]);
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    $$validator$validRequire: function (name) {
      const field = this.fields[name];
      if (field && field.validated) {
        if (field.invalid) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    $$validator$validatedType: function (name) {
      const field = this.fields[name];
      if (field && field.validated) {
        if (field.invalid) {
          return "is-danger";
        } else {
          return "is-success";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
