<template>
  <div class="realestate" @click="$emit('click')">
    <template v-if="!bulma.isMobile">
      <div class="columns">
        <div class="column is-narrow">
          <p>
            {{ $$ks.realEstateType(value.data.realEstateType).text }}
          </p>
          <figure class="image realestate__image">
            <z-image v-model="images(value)[0]" />
          </figure>
        </div>
        <div class="column">
          <table class="realestate__gross">
            <!--
            <tr>
              <th class="realestate__gross__th">
                物件名
              </th>
              <td colspan="5">
                <p class="is-elliptical">{{ value.data.name }}</p>
              </td>
            </tr>
            -->
            <tr>
              <th class="realestate__gross__th">売却希望金額</th>
              <td class="realestate__gross__red">
                {{ $$price$priceJPFormat(value.data.saleSetting.askingPrice) }}
              </td>
            </tr>
            <tr>
              <th class="realestate__gross__th">売却利回り</th>
              <td class="realestate__gross__red">
                {{ couponYieldRate }}
                <!-- {{ $$rete$rateFormat(value.data.saleSetting.couponYieldRate) }} -->
              </td>
            </tr>
          </table>

          <hr class="realestate__hr" />
          <table class="realestate__descriptions">
            <tr>
              <th class="realestate__descriptions__th">所在地</th>
              <td colspan="5">
                <span>{{ address }}</span
                ><br />
                <template v-for="(t, idx) in transports">
                  <p :key="idx">{{ t }}</p>
                </template>
              </td>
            </tr>
            <template
              v-if="
                value.data.realEstateType !== 7 &&
                value.data.realEstateType !== 10
              "
            >
              <tr>
                <th>建物種別</th>
                <td>
                  {{ $$ks.getTypeOfBuildingType(value.data.buildingType).text }}
                </td>
                <th>築年月</th>
                <td colspan="3">
                  {{ value.data.constructionDate.year }}年{{
                    value.data.constructionDate.month
                  }}月（築{{
                    this.$$realEstate$calcYearOfConstruction(
                      value.data.constructionDate
                    )
                  }}年）
                </td>
              </tr>
              <tr>
                <th>建物面積</th>
                <td>{{ $$num$commaFormat(value.data.buildingArea) }}㎡</td>
                <th>土地面積</th>
                <td>
                  {{ $$num$commaFormat(value.data.saleSetting.landArea) }}㎡
                </td>
                <th>階数</th>
                <td>
                  {{ value.data.numberOfFloors }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th>建物種別</th>
                <td>-</td>
                <th>築年月</th>
                <td colspan="3">-</td>
              </tr>
              <tr>
                <th>建物面積</th>
                <td>-</td>
                <th>土地面積</th>
                <td>
                  {{ $$num$commaFormat(value.data.saleSetting.landArea) }}㎡
                </td>
                <th>階数</th>
                <td>-</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content has-text-left">
        <p>
          <span class="has-text-grey is-size-6"
            >{{ $$ks.realEstateType(value.data.realEstateType).text }}<br
          /></span>
          <!--
          <span class="is-elliptical">{{ value.data.name }}</span>
          -->
        </p>
      </div>
      <div class="columns is-gapless is-multiline is-mobile">
        <div class="column is-5">
          <figure class="image realestate__image">
            <z-image v-model="images(value)[0]" />
          </figure>
        </div>
        <div class="column is-7">
          <p class="realestate__gross__p">
            <span class="realestate__gross__th">
              売却希望金額&nbsp;&nbsp;&nbsp;
            </span>
            <span class="realestate__gross__red">
              {{ $$price$priceJPFormat(value.data.saleSetting.askingPrice) }}
            </span>
          </p>
          <p class="realestate__gross__p">
            <span class="realestate__gross__th"> 売却利回り </span>
            <span class="realestate__gross__red">
              {{ couponYieldRate }}
              <!-- {{ $$rete$rateFormat(value.data.saleSetting.couponYieldRate) }} -->
            </span>
          </p>
        </div>
      </div>
      <div class="columns is-gapless is-multiline is-mobile">
        <div class="column">
          <div class="content has-text-left realestate__descriptions">
            <p>
              <span class="realestate__descriptions__mobile__title">
                所在地
              </span>
              <br />
              <span>{{ address }}</span
              ><br />
              <template v-for="(t, idx) in transports">
                <span :key="idx"> {{ t }}<br /></span>
              </template>
            </p>
            <div class="columns is-gapless is-multiline is-mobile">
              <template
                v-if="
                  value.data.realEstateType !== 7 &&
                  value.data.realEstateType !== 10
                "
              >
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物種別 </span
                    ><br />
                    <span>
                      {{
                        $$ks.getTypeOfBuildingType(value.data.buildingType).text
                      }}</span
                    >
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物面積 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.buildingArea) }}㎡
                    </span>
                  </p>
                  <!--
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      階数 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.numberOfFloors) }}
                    </span>
                  </p>
                  -->
                </div>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      築年月 </span
                    ><br />
                    <span>
                      {{ value.data.constructionDate.year }}年{{
                        value.data.constructionDate.month
                      }}月（築{{
                        this.$$realEstate$calcYearOfConstruction(
                          value.data.constructionDate
                        )
                      }}年）
                    </span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      土地面積 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.saleSetting.landArea) }}㎡
                    </span>
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物種別 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      建物面積 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <!--
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      階数 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.numberOfFloors) }}
                    </span>
                  </p>
                  -->
                </div>
                <div class="column is-6">
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      築年月 </span
                    ><br />
                    <span>-</span>
                  </p>
                  <p>
                    <span class="realestate__descriptions__mobile__title">
                      土地面積 </span
                    ><br />
                    <span>
                      {{ $$num$commaFormat(value.data.saleSetting.landArea) }}㎡
                    </span>
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "investor-search-realestates-parts",
  //data: function() {
  //  return {
  //    isLoading: true,
  //    currentPage: 0,
  //    realEstates: []
  //  };
  //},
  props: {
    value: {
      type: [Object],
      require: true,
    },
  },
  asyncComputed: {
    async address() {
      const text = await this.$$area$generateTextOfAddress(this.value.data);
      return text;
    },
    async transports() {
      return this.$$realEstate$transportListFormt(this.value.data.transports);
    },
  },
  computed: {
    couponYieldRate() {
      if (
        this.value.data.realEstateType === 7 ||
        this.value.data.realEstateType === 10
      ) {
        if (
          !this.value.data.annualEstimatedIncome ||
          this.value.data.annualEstimatedIncome === 0
        ) {
          return "-";
        }
      }
      return this.$$rete$rateFormat(
        this.value.data.saleSetting.couponYieldRate
      );
    },
  },
  methods: {
    images(realEstate) {
      if (realEstate) {
        return []
          .concat(realEstate.data.images.exteriors)
          .concat(realEstate.data.images.interiors)
          .concat(realEstate.data.images.others);
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.list-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 11px 22px;
  background-color: $important;
  border-bottom: solid 2px #707070;
  color: $white;
}

.realestate {
  &__image {
    width: 192px;
    @include mobile {
      width: 100%;
    }
  }
  &__hr {
    margin-top: 2px;
    margin-bottom: 4px;
  }
  &__gross {
    font-size: 1.3em;
    &__th {
      width: 160px;
      @include mobile {
        width: 0px;
        text-align: left;
      }
    }
    &__red {
      color: #f00;
    }
    &__p {
      margin-left: 8px;
      text-align: left;
    }
    &__mobile {
      &__th {
        width: 0px;
        text-align: left;
      }
      &__red {
        color: #f00;
      }
      &__p {
        margin-left: 8px;
        text-align: left;
      }
    }
  }
  &__descriptions {
    font-size: 14px;
    &__th {
      min-width: 54px;
    }
    &__mobile {
      color: #363636;
      &__title {
        font-color: black;
        font-size: 0.8rem;
      }
    }
  }
}
.table td,
.table th {
  border-width: 0;
}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}
</style>
