<template>
  <div class="register" v-if="!isLoading">
    <ZHeader title="Sign Up" sub="サインアップ" />
    <section class="edit">
      <p class="edit__explanation">
        ポートフォリオを充実させ、よりご希望に沿った提案を受けることができます。
      </p>
      <PortfolioSteps :activeStep="0" />

      <h2 class="edit__title">職務経歴入力</h2>
      <FormWorkExperience v-model="eUser" :isRegister="true" />

      <div class="edit__foot">
        <b-button
          id="investor-register-workexperience-submit"
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button is-wide"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioSteps from "./parts/PortfolioSteps";
import FormWorkExperience from "../common/FormWorkExperience";

export default {
  name: "Register-Workexperience",
  data: function () {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    PortfolioSteps: PortfolioSteps,
    FormWorkExperience: FormWorkExperience,
  },
  methods: {
    onPrev: function () {
      this.$router.push({ name: "investor-register" });
    },
    onSubmit: function () {
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();

        this.$router.push({ name: "investor-register-income" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
