<template>
  <div class="edit edit-profile">
    <h2 class="edit__title">プロフィール</h2>
    <section class="edit__section">
      <div>
        <ZField label="プロフィール画像" :isRequired="isRequired">
          <ZProfileImageSelect
            v-model="user.data.profile.image"
            :isRequired="true"
          />
        </ZField>
        <div class="content has-text-grey is-small">
          <p>
            プロフィール画像は、背景が目立たずプロフェッショナルらしい画像をご登録ください。
          </p>
        </div>
        <z-checkbox
          label="得意分野"
          v-model="user.data.profile.specialties"
          :options="$$ks.realtor.specialties"
          :isRequired="isRequired"
          :validate="validates"
        >
        </z-checkbox>
        <z-select
          label="不動産経験"
          v-model="user.data.profile.yearOfexperience"
          placeholder="10（年）"
          :options="$$ks.realtor.yearOfexperiences"
          :isRequired="isRequired"
          :validate="validates"
        >
        </z-select>
        <z-input
          label="プロフィール"
          v-model="user.data.profile.profile"
          type="textarea"
          placeholder="（フリーコメント欄）"
          maxlength="500"
          :isRequired="isRequired"
          :validate="validates"
        >
        </z-input>
        <z-input
          label="自己PR・実績"
          v-model="user.data.profile.pr"
          type="textarea"
          placeholder="（フリーコメント欄）"
          maxlength="500"
          :isRequired="isRequired"
          :validate="validates"
        >
        </z-input>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "realtors-user-profile",
  inject: ["$validator"],
  props: {
    user: {
      type: [Object],
      require: true,
    },
    isRequired: {
      type: [Boolean],
      default: true,
    },
  },
  computed: {
    validates() {
      if (this.isRequired) {
        return "required";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  margin: 0 auto 70px;
  max-width: $edit-maxwidth;
  &__section {
    padding: 0 $edit-padding;
    @include sp() {
      padding: 0 $edit-padding-sp;
    }
  }
  &__title {
    position: relative;
    margin-top: $edit-padding;
    margin-bottom: $edit-padding;
    padding: 5px $edit-padding;
    font-size: 24px;
    background-color: #f4f3f5;
    border-bottom: solid 2px #707070;
    color: #838383;
    @include sp {
      padding: 5px $edit-padding-sp;
    }
  }
  &__subtitle {
    padding-top: 0;
    padding-bottom: 14px;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
