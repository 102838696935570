<template>
  <section class="z-section">
    <div class="z-section__inner">
      <!-- selectedBankInfo{{selectedBankInfo}}
      selectedSortType:{{selectedSortType}} -->
      <div v-if="bulma.isTablet" class="holder holder--pc">
        <div class="ranking"></div>
        <div class="transaction">
          <div class="transaction__head">
            <div class="transaction__head__bank">
              <span>銀行：</span>
              <b>{{ bankName }}</b>
            </div>
            <div class="transaction__head__sort">
              <z-sort-select v-model="selectedSortType" type="loan" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="bulma.isTablet" class="holder holder--pc">
        <div class="ranking">
          <LoanTransactionRanking
            :filter="filter"
            :pageIndex.sync="pageIndex"
            v-model="selectedBankInfo"
            :style="followingStyle"
            ref="following"
          />
        </div>
        <div class="transaction">
          <div class="transaction__body">
            <LoanTransactionList
              v-if="selectedBankInfo"
              :filter="filter"
              :bank="selectedBankInfo.bank"
              :sortType="selectedSortType"
              ref="list"
            />
          </div>
        </div>
      </div>

      <div v-if="bulma.isMobile" class="holder holder--sp">
        <LoanTransactionRankingSP :filter="filter" />
      </div>
    </div>
  </section>
</template>

<script>
import LoanTransactionRanking from "./LoanTransactionRanking";
import LoanTransactionList from "./LoanTransactionList";
import LoanTransactionRankingSP from "./LoanTransactionRankingSP";

export default {
  name: "LoanTransaction",
  components: {
    LoanTransactionRanking,
    LoanTransactionList,
    LoanTransactionRankingSP,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSortType: 1, // ソート順
      selectedBankInfo: null, // 銀行
      pageIndex: 0,
      followingStyle: {
        top: "",
      },
      offset: 12,
      isFirst: true,
    };
  },
  computed: {
    bankName() {
      if (this.selectedBankInfo) {
        return this.selectedBankInfo.bankName;
      } else {
        return "-";
      }
    },
  },
  methods: {
    handleScroll() {
      if (!this.$refs.following || !this.$refs.list) return false;
      const followingCL = this.$refs.following.$el.getBoundingClientRect();
      const listCL = this.$refs.list.$el.getBoundingClientRect();
      if (listCL.top < 0) {
        const max = listCL.height - followingCL.height;
        if (listCL.top * -1 > max) {
          this.followingStyle["top"] = max + "px";
        } else {
          this.followingStyle["top"] = listCL.top * -1 + this.offset + "px";
        }
      } else {
        this.followingStyle["top"] = 0;
      }
      return false;
    },
    moveToListTop() {
      if (!this.$el) return false;

      const cl = this.$el.getBoundingClientRect();
      if (cl.top < 0) {
        window.scrollTo(0, window.scrollY + cl.top - this.offset);
      }
      return false;
    },
    updateFilter() {
      this.$store.commit("investor/setMtLoanFilter", {
        pageIndex: this.pageIndex,
        sortType: this.selectedSortType,
        filter: this.selectedBankInfo,
      });
    },
  },
  watch: {
    filter() {
      this.selectedBankInfo = null;
      this.pageIndex = 0;
    },
    selectedBankInfo() {
      this.updateFilter();
      this.moveToListTop();
    },
    selectedSortType() {
      this.updateFilter();
    },
    pageIndex() {
      this.updateFilter();
    },
  },
  created() {
    const filter = this.$store.state.investor.mtLoanFilter;
    this.selectedBankInfo = filter.filter;
    this.selectedSortType = filter.sortType;
    this.pageIndex = filter.pageIndex;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-section {
  overflow: hidden;
  &__inner {
    .holder {
      position: relative;
      display: flex;
    }
    .ranking {
      position: relative;
      flex: 1 1;
      margin-right: 22px;
      @include sp {
        width: 100%;
      }
    }
    .transaction {
      flex: 1 1;
      @include sp {
        margin-left: 0;
        max-width: 100%;
        width: 100%;
        margin-top: 24px;
      }
      &__head {
        overflow: hidden;
        &__bank {
          display: inline-block;
          padding: 0 0 10px;
          float: left;
          font-size: 14px;
          b {
            font-weight: normal;
            font-size: 16px;
            color: $primary;
          }
        }
        &__sort {
          display: inline-block;
          float: right;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
