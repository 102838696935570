<template>
  <section class="rsOverview">
    <h3 class="rsOverview__title">物件概要</h3>
    <div class="rsOverview__sub">
      <div class="rsOverview__sub__type">{{ realEstateType }}</div>
      <div class="rsOverview__sub__buyingDt">
        <template v-if="isRealtor">
          登録日 {{ $$calender$dateFormat(data.registerDt) }}
        </template>
        <template v-else>
          購入年月 {{ $$calender$yearMonthFormat(data.purchase.buyingDt) }}
        </template>
      </div>
    </div>

    <div class="columns">
      <!-- 物件価格 -->
      <div class="column">
        <dl
          class="rsOverview__borderDl"
          :class="{ rsOverview__overseas: data.overseasRealEstate }"
        >
          <template v-if="isRealtor">
            <dt>販売価格</dt>
            <dd v-if="data.price">{{ $$price$priceJPFormat(data.price) }}</dd>
            <dd v-else>-</dd>
          </template>
          <template v-else-if="isSale">
            <dt>売却価格</dt>
            <dd v-if="data.saleSetting.askingPrice">
              {{ $$price$priceJPFormat(data.saleSetting.askingPrice) }}
            </dd>
            <dd v-else>-</dd>
          </template>
          <template v-else>
            <dt>購入価格</dt>
            <dd>{{ $$price$priceJPFormat(data.purchase.amount) }}</dd>
          </template>
        </dl>
        <template v-if="data.overseasRealEstate">
          <dl class="rsOverview__rateNotes">
            <dd class="rsOverview__rateNotes__has-text-gray is-size-7">
              ※ JPY参考価格（取引時の為替レートに応じて<br
                class="br-show"
              />価格は変動します）
            </dd>
            <dd class="rsOverview__rateNotes__has-text-gray is-size-7">
              ※ JPY参考価格算出方法は、以下「PR･注意事項」を参照
            </dd>
          </dl>
        </template>
        <dl
          class="rsOverview__borderDl"
          :class="{ rsOverview__overseas: data.overseasRealEstate }"
        >
          <!--
          <dt>年間想定収入</dt>
          <dd>
            {{ $$price$priceJPFormat(data.purchase.annualEstimatedIncome) }}
          </dd>
          -->
          <dt>年間想定収入</dt>
          <dd>
            {{ annualEstimatedIncome }}
          </dd>
          <!-- <dd>
            {{
              data.annualEstimatedIncome !== null
                ? $$price$priceJPFormat(data.annualEstimatedIncome)
                : "-"
            }}
          </dd> -->
        </dl>
        <template v-if="data.overseasRealEstate">
          <dl class="rsOverview__rateNotes">
            <dd class="rsOverview__rateNotes__has-text-gray is-size-7">
              ※ JPY参考価格（取引時の為替レートに応じて<br
                class="br-show"
              />価格は変動します）
            </dd>
          </dl>
        </template>
        <dl class="rsOverview__borderDl">
          <template v-if="isRealtor">
            <dt>表面利回り</dt>
            <dd>{{ realtorCouponYieldRate }}</dd>
            <!-- <dd v-if="data.couponYieldRate">
              {{ $$rete$rateFormat(data.couponYieldRate) }}
            </dd>
            <dd v-else>-</dd> -->
          </template>
          <template v-else-if="isSale">
            <dt>売却利回り</dt>
            <dd v-if="data.saleSetting.couponYieldRate">
              {{ $$rete$rateFormat(data.saleSetting.couponYieldRate) }}
            </dd>
            <dd v-else>-</dd>
          </template>
          <template v-else>
            <dt>表面利回り</dt>
            <dd>{{ couponYieldRate }}</dd>
            <!-- <dd v-if="data.couponYieldRate">
              {{ $$rete$rateFormat(data.couponYieldRate) }}
            </dd>
            <dd v-else>-</dd> -->
          </template>
        </dl>
      </div>
      <!-- /物件価格 -->

      <!-- 物件情報 -->
      <div class="column">
        <dl class="rsOverview__labelDl">
          <dt>
            <span class="rsOverview__labelDl__label"> 所在地 </span>
          </dt>
          <dd>{{ address }}</dd>
        </dl>
        <dl class="rsOverview__labelDl">
          <dt>
            <span class="rsOverview__labelDl__label"> 交通 </span>
          </dt>
          <dd>
            <ul>
              <template v-if="transports && transports.length > 0">
                <li v-for="(transport, index) in transports" :key="index">
                  {{ transport }}
                </li>
              </template>
              <template v-else> - </template>
              <!-- <li v-for="(transport, index) in transports" :key="index">
                {{ transport }}
              </li> -->
            </ul>
          </dd>
        </dl>

        <div class="columns columns--noGap is-desktop">
          <div class="column">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label">
                  建物種別
                  <z-help-icon
                    help="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
                    onlyPC
                  />
                </span>
              </dt>
              <dd>{{ BuildingType }}</dd>
            </dl>
          </div>
          <div class="column">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label"> 築年月 </span>
              </dt>
              <dd>
                <template
                  v-if="data.realEstateType === 10 || data.realEstateType === 7"
                >
                  <span>-</span>
                </template>
                <template v-else>
                  {{ $$calender$yearMonthFormat(data.constructionDate) }}(築{{
                    $$realEstate$calcYearOfConstruction(data.constructionDate)
                  }}年)
                </template>
              </dd>
            </dl>
          </div>
        </div>

        <div class="columns columns--noGap is-desktop">
          <div class="column">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label"> 建物面積 </span>
              </dt>
              <dd>
                {{ buildingArea }}
                <!-- {{ data.buildingArea }}㎡ -->
              </dd>
            </dl>
          </div>
          <div class="column columns--noGap">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label">
                  階数
                  <z-help-icon help="建物の階数、及び物件の階数。" onlyPC />
                </span>
              </dt>
              <dd>
                {{ numberOfFloors }}
                <!-- {{ data.numberOfFloors }} -->
              </dd>
            </dl>
          </div>
        </div>

        <div class="columns is-desktop">
          <div class="column">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label"> 土地面積 </span>
              </dt>
              <dd>{{ checkNull(data.saleSetting.landArea) }}㎡</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- /物件情報 -->
    </div>
  </section>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "RealestateOverview",
  props: {
    data: Object,
    isRealtor: {
      type: [Boolean],
      default: false,
    },
    isSale: {
      type: [Boolean],
      default: false,
    },
    isPublic: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    realEstateType() {
      return kubuns.realEstateType(this.data.realEstateType).text;
    },
    BuildingType() {
      if (this.data.buildingType) {
        return kubuns.getTypeOfBuildingType(this.data.buildingType).text;
      } else {
        return "- ";
      }
    },
    // BuildingType() {
    //   return kubuns.getTypeOfBuildingType(this.data.buildingType).text;
    // }
    buildingArea() {
      if (this.data.buildingArea) {
        return this.data.buildingArea + "㎡";
      } else {
        return "-";
      }
    },
    numberOfFloors() {
      if (this.data.numberOfFloors) {
        return this.data.numberOfFloors;
      } else {
        return "-";
      }
    },
    annualEstimatedIncome() {
      if (this.data.realEstateType === 7 || this.data.realEstateType === 10) {
        if (this.data.annualEstimatedIncome === 0) {
          return "-";
        }
      }
      return this.data.annualEstimatedIncome !== null
        ? this.$$price$priceJPFormat(this.data.annualEstimatedIncome)
        : "-";
    },
    couponYieldRate() {
      if (this.data.realEstateType === 7 || this.data.realEstateType === 10) {
        if (this.data.couponYieldRate === 0) {
          return "-";
        }
      }
      if (this.data.couponYieldRate) {
        return this.$$rete$rateFormat(this.data.couponYieldRate);
      } else {
        return "-";
      }
    },
    realtorCouponYieldRate() {
      if (this.data.realEstateType === 7 || this.data.realEstateType === 10) {
        if (
          !this.data.annualEstimatedIncome ||
          this.data.annualEstimatedIncome === 0
        ) {
          return "-";
        }
      }
      if (this.data.couponYieldRate) {
        return this.$$rete$rateFormat(this.data.couponYieldRate);
      } else {
        return "-";
      }
    },
  },
  asyncComputed: {
    async address() {
      if (this.data.overseasRealEstate) {
        return this.data.overseas.building
          ? this.addressText(this.data.overseas.country) +
              ", " +
              this.data.overseas.state +
              ", " +
              this.data.overseas.address +
              ", " +
              this.data.overseas.building
          : this.addressText(this.data.overseas.country) +
              ", " +
              this.data.overseas.state +
              ", " +
              this.data.overseas.address;
      } else {
        const text = await this.$$area$generateTextOfAddress(
          this.data,
          this.isPublic
        );
        return text;
      }
      // const text = await this.$$area$generateTextOfAddress(
      //   this.data,
      //   this.isPublic
      // );
      // return text;
    },
    async transports() {
      if (this.data.overseasRealEstate) {
        const text = await this.$$realEstate$overseasTransportListFormt(
          this.data.overseas.transports
        );
        return text;
      } else {
        console.log(this.data.transports);
        const text = await this.$$realEstate$transportListFormt(
          this.data.transports
        );
        console.log(text);
        return text;
      }
      // const text = await this.$$realEstate$transportListFormt(
      //   this.data.transports
      // );
      // return text;
    },
  },
  methods: {
    checkNull(val) {
      console.log(val);
      //console.log(val == NaN);
      console.log(val == "NaN");
      if (val && val != "NaN") return val;
      else return "- ";
    },
    addressText(val) {
      return this.$$ks.getCountry(val).text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.rsOverview {
  // margin-bottom: 16px;
  .columns {
    margin-top: 0;
    margin-bottom: 0;
    .is-desktop {
      @include md {
        display: block;
      }
    }
  }
  .column {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    &:not(:first-child) {
      @include sp {
        margin-top: 16px;
      }
    }
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 20px;
    border-bottom: solid 1px #ddd;
    @include sp {
      margin-bottom: 12px;
      font-size: 18px;
    }
  }
  &__sub {
    overflow: hidden;
    margin-bottom: 20px;
    @include sp {
      margin-bottom: 8px;
    }
    &__type {
      float: left;
      font-size: 18px;
      @include sp {
        font-size: 16px;
      }
    }
    &__buyingDt {
      float: right;
      padding-top: 6px;
      font-size: 12px;
    }
  }
  &__borderDl {
    &:not(:last-child) {
      margin-bottom: 46px;
      @include sp {
        margin-bottom: 8px;
      }
    }
    display: float;
    border-bottom: solid 1px #ddd;
    overflow: hidden;
    & > dt {
      float: left;
      margin-top: 8px;
      font-size: 14px;
    }
    & > dd {
      float: right;
      font-size: 22px;
      text-align: right;
    }
  }
  &__labelDl {
    margin-bottom: 16px;
    display: table;
    table-layout: fixed;
    & > dt {
      display: table-cell;
      min-width: 90px;
      .rsOverview__labelDl__label {
        display: block;
        padding: 2px;
        font-size: 14px;
        background-color: #f4f4f4;
        text-align: center;
        vertical-align: middle;
        line-height: 1em;
        font-weight: bold;
        color: #777777;
      }
    }
    & > dd {
      display: table-cell;
      width: 100%;
      padding-left: 26px;
      font-size: 15px;
      @include sp {
        font-size: 14px;
      }
    }
    &__icon {
      margin-left: 4px;
    }
  }
  &__overseas {
    margin-bottom: 0 !important;
  }
  &__rateNotes {
    text-align: right;
    margin-bottom: 20px;
    &__has-text-gray {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}
</style>
