<template>
  <span class="realtor-favorite-button">
    <b-tooltip type="is-dark" :label="help" multilined animated>
      <template v-if="favoriteType === 1">
        <template v-if="favorite === favoriteType">
          <img src="@/assets/favorite/fa1_at.png" />
        </template>
        <template v-else>
          <img @click="onClickFa(1)" src="@/assets/favorite/fa1_no.png" />
        </template>
      </template>
      <template v-if="favoriteType === 2">
        <template v-if="favorite === favoriteType">
          <img src="@/assets/favorite/fa2_at.png" />
        </template>
        <template v-else>
          <img @click="onClickFa(2)" src="@/assets/favorite/fa2_no.png" />
        </template>
      </template>
      <template v-if="favoriteType === 999">
        <template v-if="favorite === favoriteType">
          <img src="@/assets/favorite/fa999_at.png" />
        </template>
        <template v-else>
          <img @click="onClickFa(999)" src="@/assets/favorite/fa999_no.png" />
        </template>
      </template>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: "RealtorFavoriteButton",
  props: {
    favoriteType: {
      favoriteType: Number,
      required: true,
    },
    favorite: {
      favoriteType: Number,
      required: true,
    },
  },
  computed: {
    help() {
      if (this.favoriteType === 1) {
        return "優先的に提案をもらえるようになります";
      } else if (this.favoriteType === 2) {
        return "継続して提案を受け取ることができます";
      } else if (this.favoriteType === 999) {
        return "今後の提案をブロックします";
      }
      return "";
    },
  },
  methods: {
    async onClickFa(ty) {
      this.$emit("click", ty);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.realtor-favorite-button {
  img {
    cursor: pointer;
    width: 46px;
    height: 46px;
  }
}
</style>
