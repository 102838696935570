<template>
  <div class="ZImageTileView">
    <div class="ZImageTileView__imageArea">
      <div class="tileImagesThreeItem" v-if="imgCount === 3">
        <div class="tileImagesThreeItem__mainImage">
          <div class="zimageWrapper">
            <z-image class="image" :value="selectedImages[0]" />
          </div>
        </div>
        <div class="tileImagesThreeItem__subImages">
          <div class="tileImagesThreeItem__subImages__image">
            <div class="zimageWrapper">
              <z-image class="image" :value="selectedImages[1]" />
            </div>
          </div>
          <div class="tileImagesThreeItem__subImages__image">
            <div class="zimageWrapper">
              <z-image class="image" :value="selectedImages[2]" />
            </div>
          </div>
        </div>
      </div>

      <div class="tileImagesTwoItem" v-if="imgCount === 2">
        <div class="tileImagesTwoItem__mainImage">
          <div class="zimageWrapper">
            <z-image class="image" :value="selectedImages[0]" />
          </div>
        </div>
        <div class="tileImagesTwoItem__subImage">
          <div class="zimageWrapper">
            <z-image class="image" :value="selectedImages[1]" />
          </div>
        </div>
      </div>

      <div class="tileImagesOneItem" v-if="imgCount === 1">
        <div class="zimageWrapper">
          <z-image class="image" :value="selectedImages[0]" />
        </div>
      </div>
    </div>
    <div class="ZImageTileView__descriptionArea">
      <b-tag class="realEstateTypeTag">
        {{
          $$ks.realEstateType(this.newArrivalObject.data.realEstateType).text
        }}
      </b-tag>
      <div class="priceDescription">
        <div class="priceDescription__price">
          <p class="priceDescription__price__amount">{{ price }}</p>
        </div>
        <div class="priceDescription__yieldRate">
          <p class="priceDescription__yieldRate__amount">
            {{ yieldRate
            }}<span class="percent" v-if="yieldRate !== '-'">%</span>
          </p>
        </div>
      </div>
      <div class="areaDescription">
        <p class="descriptionTxt">{{ address }}</p>
        <ul class="areaDescription__transportsList">
          <li
            class="areaDescription__transportsList__item"
            v-for="(transport, transports_index) in accessArray"
            :key="transports_index"
          >
            <p class="descriptionTxt">{{ accessArray[transports_index] }}</p>
          </li>
        </ul>
      </div>
      <div class="specDescription">
        <p class="descriptionTxt">{{ areaAndBuildingType }}</p>
        <p class="descriptionTxt">{{ constructionDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import objectFitImages from "object-fit-images";
import _ from "lodash";

export default {
  name: "ZImageTileView",
  props: {
    newArrivalObject: {
      type: Object,
      default: null,
      //required: true
    },
  },
  asyncComputed: {
    async address() {
      if (this.newArrivalObject) {
        const data = this.newArrivalObject.data;
        if (data.overseasRealEstate) {
          return data.overseas.building
            ? this.addressText(data.overseas.country) +
                ", " +
                data.overseas.state +
                ", " +
                data.overseas.address +
                ", " +
                data.overseas.building
            : this.addressText(data.overseas.country) +
                ", " +
                data.overseas.state +
                ", " +
                data.overseas.address;
        } else {
          const result = await this.$$area$generateTextOfAddress(data);
          return result;
        }
      } else {
        return "";
      }
    },
    async accessArray() {
      if (this.newArrivalObject) {
        const result = await this.$$realEstate$transportListFormt(
          this.newArrivalObject.data.transports
        );
        return result;
      } else {
        return [];
      }
    },
  },
  computed: {
    selectedImages() {
      if (!this.newArrivalObject) {
        return [];
      }
      const { exteriors, interiors, layouts } =
        this.newArrivalObject.data.images; // TODO: 間取りが追加されたら修正
      const allImages = [exteriors, interiors, layouts]; // TODO: 間取りが追加されたら修正
      const maxCount = Math.max(...allImages.map((item) => item.length));
      const invertedImages = _.times(maxCount).map((_, index) =>
        allImages.map((rowItems) => rowItems[index])
      );

      return _(invertedImages).flatten().compact().take(3).value();
    },
    imgCount() {
      return this.selectedImages.length || 1;
    },
    price() {
      let price;
      if (this.newArrivalObject.data.isReatlor) {
        price = this.newArrivalObject.data.price;
      }
      if (this.newArrivalObject.data.isInvetor) {
        price = this.newArrivalObject.data.saleSetting.askingPrice;
      }
      return this.$$price$priceJPFormat(price);
    },
    yieldRate() {
      let rate = 0;
      if (this.newArrivalObject.data.isReatlor) {
        rate = this.newArrivalObject.data.couponYieldRate;
      }
      if (this.newArrivalObject.data.isInvetor) {
        rate = this.newArrivalObject.data.saleSetting.couponYieldRate;
      }
      const fixedRate = this.$$rete$rateFormat(rate, 2, "");
      return fixedRate;
    },
    areaAndBuildingType() {
      if (
        this.newArrivalObject.data.buildingArea &&
        this.newArrivalObject.data.buildingType
      ) {
        const area = String(this.newArrivalObject.data.buildingArea) + "㎡";
        const buildingType = this.$$ks.getTypeOfBuildingType(
          this.newArrivalObject.data.buildingType
        ).text;
        return "建物面積:" + area + "/ 建物種別:" + buildingType;
      } else {
        return "建物面積:" + " - " + "/ 建物種別:" + " - ";
      }
      // const area = String(this.newArrivalObject.data.buildingArea) + "㎡";
      // const buildingType = this.$$ks.getTypeOfBuildingType(
      //   this.newArrivalObject.data.buildingType
      // ).text;
      // return "建物面積:" + area + "/ 建物種別:" + buildingType;
    },
    constructionDate() {
      if (
        this.newArrivalObject.data.constructionDate.year &&
        this.newArrivalObject.data.constructionDate.month
      ) {
        const year = String(this.newArrivalObject.data.constructionDate.year);
        const month = String(this.newArrivalObject.data.constructionDate.month);
        const calcYearOfConstruction = String(
          this.$$realEstate$calcYearOfConstruction(
            this.newArrivalObject.data.constructionDate
          )
        );
        return (
          "築年月:" +
          year +
          "年" +
          month +
          "月(築" +
          calcYearOfConstruction +
          "年)"
        );
      } else {
        return "築年月:" + " - ";
      }
    },
  },
  methods: {
    checkNull(val) {
      // console.log(val);
      // console.log(val == NaN);
      // console.log(val == "NaN");
      if (val && val != "NaN") return val;
      else return "- ";
    },
    addressText(val) {
      return this.$$ks.getCountry(val).text;
    },
  },
  mounted() {
    objectFitImages();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.ZImageTileView {
  &__imageArea {
    height: 144px;
    width: 240px;
    border-radius: 14px;
    overflow: hidden;
    background-color: #ffffff;
    .tileImagesThreeItem {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__mainImage {
        height: 100%;
        width: calc(50% - 1px);
        background-color: #f5f5f5;
      }
      &__subImages {
        height: 100%;
        width: calc(50% - 1px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__image {
          height: calc(50% - 1px);
          width: 100%;
          background-color: #f5f5f5;
        }
      }
    }

    .tileImagesTwoItem {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__mainImage {
        height: 100%;
        width: calc(50% - 1px);
        background-color: #f5f5f5;
      }
      &__subImage {
        height: 100%;
        width: calc(50% - 1px);
        background-color: #f5f5f5;
      }
    }

    .tileImagesOneItem {
      background-color: #f5f5f5;
      height: 100%;
      width: 100%;
    }

    .zimageWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .image {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: "object-fit: contain;";
    }
  }
  &__descriptionArea {
    font-weight: bold;
    color: #666666;
    .realEstateTypeTag {
      background-color: $white;
      border: 1px solid #666666;
      color: #666666;
      margin-top: 12px;
    }
    .priceDescription {
      padding: 12px 0 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 16px;
      &__price {
        font-size: 16px;
        text-align: left;
      }
      &__yieldRate {
        font-size: 16px;
        .percent {
          font-size: 12px;
        }
      }
    }
    .areaDescription {
      padding: 3px 0;
    }
    .specDescription {
      padding: 3px 0;
    }
    .descriptionTxt {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
