<template>
  <div class="box invetor-profile-card" :key="investor && investor.id">
    <div class="columns is-muliline is-mobile invetor-profile-card__detail">
      <div class="column is-narrow">
        <div class="invetor-profile-card__score">
          <ZScoreProgress
            :score="investor.user.data.latestScore.score"
            :rank="investor.user.data.latestScore.rank"
            :transitionDuration="0"
            scaleType="medium"
          />
          <div class="content is-small has-text-centered has-text-white">
            <span>{{ $$ageAndSex(this.investor.user.data.base) }}</span>
          </div>
        </div>
        <template v-if="investor.user.data.membership.value === 20">
          <div
            class="column is-narrow invetor-profile-card__description__badge-wrap"
            v-if="bulma && bulma.isMobile"
          >
            <div class="content">
              <div class="invetor-profile-card__description__premium-badge">
                <img src="@/assets/investor/premium-card.png" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="column invetor-profile-card__description">
        <div class="invetor-profile-card__description__user">
          <div class="image is-32x32 invetor-profile-card__description__image">
            <z-profile-thumbnail v-model="investor.user.data.profile.image" />
          </div>
          <div class="invetor-profile-card__description__name">
            <template v-if="hideProfile.name">
              <z-truncator class="is-size-6" text="非公開" />
            </template>
            <template v-else>
              <z-truncator :text="investor.user.data.base.name" />
            </template>
          </div>
          <template v-if="!(bulma && bulma.isMobile)">
            <div class="invetor-profile-card__description__message">
              <template v-if="latestSendMessageDate.length > 0">
                <div>最終メッセージ送信日：{{ latestSendMessageDate }}</div>
              </template>
              <template v-if="latestRecieveMessageDate.length > 0">
                <div>最終メッセージ受信日：{{ latestRecieveMessageDate }}</div>
              </template>
            </div>
          </template>
        </div>
        <hr class="investor-profile-card__hr" />
        <div class="columns is-mobile is-multiline">
          <div class="column">
            <div class="content is-small">
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__desc">
                  {{ residency }}
                  {{ education }}
                </div>
              </div>
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__title">
                  業種:
                </div>
                <div class="invetor-profile-card__description__desc">
                  <z-truncator
                    :text="
                      investor.user.data.profile.currentOfCareer
                        .categoryOfBusiness.text
                    "
                  />
                </div>
                <div class="invetor-profile-card__description__title">
                  職種:
                </div>
                <div class="invetor-profile-card__description__desc">
                  <z-truncator
                    :text="
                      investor.user.data.profile.currentOfCareer
                        .typeOfOccupation.text
                    "
                  />
                </div>
              </div>
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__title">
                  保有不動産数:
                </div>
                <div
                  class="invetor-profile-card__description__desc invetor-profile-card__description__desc__realestate"
                >
                  {{ investor.realEstate.countOfRealEstate }}件
                </div>
                <div class="invetor-profile-card__description__title">
                  会員登録日:
                </div>
                <div
                  class="invetor-profile-card__description__desc invetor-profile-card__description__desc__registration-date"
                >
                  {{
                    investor.user.data.registerDt
                      ? $$calender$dateFormat(
                          new Date(investor.user.data.registerDt)
                        )
                      : ""
                  }}
                </div>
              </div>
              <template v-if="bulma && bulma.isMobile">
                <div class="invetor-profile-card__description__tr">
                  <template v-if="latestSendMessageDate.length > 0">
                    <div class="invetor-profile-card__description__title">
                      最終メッセージ送信日：
                    </div>
                    <div class="invetor-profile-card__description__desc">
                      {{ latestSendMessageDate }}
                    </div>
                  </template>
                  <template v-if="latestRecieveMessageDate.length > 0">
                    <div class="invetor-profile-card__description__title">
                      最終メッセージ受信日：
                    </div>
                    <div class="invetor-profile-card__description__desc">
                      {{ latestRecieveMessageDate }}
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <template v-if="investor.user.data.membership.value === 20">
            <div
              class="column is-narrow invetor-profile-card__description__badge-wrap"
              v-if="!(bulma && bulma.isMobile)"
            >
              <div class="content">
                <div class="invetor-profile-card__description__premium-badge">
                  <img src="@/assets/investor/premium-card.png" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "investor-profile-card",
  mixins: [DisplayTextMixin],
  props: {
    investorInfo: {
      type: [Object, Number],
      require: true,
    },
    // investor: {
    //   type: [Object, Number],
    //   require: true
    // },
    hideProfile: {
      type: Object,
      require: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    investor() {
      return this.investorInfo._source;
    },
    realtorInfo() {
      return this.investorInfo.fields
        ? this.investorInfo.fields.realtorInfo
        : null;
    },
    latestSendMessageDate() {
      return this.$$latestSendMessageDate(this.realtorInfo);
    },
    latestRecieveMessageDate() {
      return this.$$latestRecieveMessageDate(this.realtorInfo);
    },
  },
  asyncComputed: {
    // 住まいを表示
    async residency() {
      const result = await this.$$city$load(
        this.investor.user.data.base.prefecture.value,
        this.investor.user.data.base.city
      );
      return this.investor.user.data.base.prefecture.text || result.cityName
        ? `${this.investor.user.data.base.prefecture.text}${result.cityName}在住`
        : "";
    },
    education() {
      return this.investor.user.data.profile.education.type.text
        ? `${this.investor.user.data.profile.education.type.text}卒`
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.invetor-profile-card {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#37d2ff),
    to(#2a7bcf)
  );
  background: linear-gradient(#37d2ff, #2a7bcf);
  padding: 0;

  &__detail {
    margin: 0;
  }

  &__description {
    text-align: left;
    background: white;

    &__tr {
      display: block;
    }

    &__title {
      display: inline-block;
      padding-right: 0.5rem;
      // width: 4em;
      @include mobile {
        width: 12em;
      }
    }

    &__desc {
      display: inline-block;
      width: 20em;
      color: gray;
      @include mobile {
        width: 100%;
      }
      &__realestate {
        width: 15.8em;
      }
      &__registration-date {
        @include md {
          width: 19em;
        }
      }
    }

    &__badge-wrap {
      padding: 5px 5px 0px;
      @include md {
        padding: 12px 0px;
      }
      @include sp {
        padding: 30px 0px;
      }
    }

    &__premium-badge {
      width: 100px;
      height: 50px;
      @include md {
        width: 80px;
        height: 50px;
      }
      @include sp {
        width: 60px;
        height: 30px;
      }
    }

    &__user {
      display: flex;
    }

    &__image {
      margin-right: 12px;
    }

    &__name {
      margin-top: 4px;
    }

    &__message {
      margin: 0 0 0 auto;
      font-size: 12px;
    }
  }

  &__hr hr {
    margin: 0;
  }
}
.investor-profile-card__hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
