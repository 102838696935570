<template>
  <section class="portfolio">
    <ZHeader title="My Portfolio" sub="マイポートフォリオ">
      <template v-slot:rightIcons>
        <ZNotification color="#777" />
      </template>
    </ZHeader>
    <div class="portfolio__examination" v-if="$$user$isPremium === 0">
      <MembershipsButton />
    </div>

    <b-tabs
      class="portfolio__tab z-tabs z-tabs--page"
      v-model="currentTabIndex"
      :destroy-on-hide="true"
    >
      <b-tab-item class="portfolio__tab__item" label="Assets">
        <PortfolioCharts />
        <AssetsInfo />
      </b-tab-item>
      <b-tab-item class="portfolio__tab__item" label="Profile">
        <ProfileInfo />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import AssetsInfo from "./parts/AssetsInfo";
import ProfileInfo from "./parts/ProfileInfo";
import PortfolioCharts from "./parts/PortfolioCharts";

export default {
  name: "portfolio-Index",
  data: function () {
    return {
      // isLoading: true,
      user: null,
      currentTabIndex: 0,
      query: this.$route.query.ctg,
    };
  },
  components: {
    AssetsInfo,
    ProfileInfo,
    PortfolioCharts,
  },
  created() {
    const ctg = this.$route.query.ctg;
    if (ctg === "profile") this.currentTabIndex = 1;
  },
  watch: {
    $route() {
      const ctg = this.$route.query.ctg;
      if (ctg === "profile") this.currentTabIndex = 1;
    },
    currentTabIndex(to) {
      const ctg = to === 0 ? "assets" : "profile";
      if (this.$route.query.ctg != ctg)
        this.$router.push({ path: "portfolio", query: { ctg: ctg } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.portfolio {
  position: relative;
  /deep/ .tabs {
    span {
      width: 90px;
      font-size: 24px;
      text-align: center;
    }
  }
  &__tab {
    &__item {
      /deep/ .tabs {
        li {
          padding: 0;
        }
        span {
          width: 122px;
          font-size: 15px;
          text-align: center;
        }
      }
    }
  }
  &__examination {
    position: absolute;
    top: 10px;
    right: $section-padding;
    @include sp {
      position: static;
      padding-right: $section-padding-sp;
      text-align: right;
    }
  }
}
</style>
