<template>
  <div class="z-docsList">
    <ul class="z-docsList__list">
      <template v-for="(item, index) in value">
        <li class="z-docsList__item" :key="item.id">
          <div>
            <z-docs :value="item" />
          </div>
          <a class="z-docsList__item__delete" @click="removeFile(index)">
            <b-icon
              class="z-docsList__item__delete__icon"
              icon="close"
              size="is-small"
            />
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ZDocsList",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    removeFile(index) {
      // const value = this.$$cloneDeep(this.value);
      this.value.splice(index, 1);

      // this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-docsList {
  &__list {
    margin-left: -14px;
    margin-right: -14px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__item {
    margin: 0 25px 15px 0;
    position: relative;
    &__delete {
      width: 20px;
      height: 20px;
      top: -10px;
      right: -10px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      border-radius: 50%;
      /deep/ span {
        color: #707070;
      }
      &__icon {
        margin-top: 2px;
      }
    }
  }
}
</style>
