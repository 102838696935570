<template>
  <div class="z-form z-percentInput">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :isShort="isShort"
    >
      <b-input
        :name="internalName"
        :icon="icon"
        :size="size"
        type="number"
        :step="minValue"
        :placeholder="placeholder || label + 'を入力してください'"
        v-model="displayValue"
        :data-vv-as="label"
        :maxlength="maxlength"
        v-validate="
          validate + `|percent_decimal_value:${decimal}|min_value:${minValue}`
        "
        :password-reveal="passwordReveal"
        :disabled="disabled"
      >
      </b-input>
    </ZField>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  name: "ZPercentInput",
  mixins: [InputMixin],
  props: {
    value: {
      type: Number,
      default: null,
    },
    // 小数点以下何桁まで入力を許容するのかを設定
    decimal: {
      type: Number,
      default: 2,
    },
  },
  data: function () {
    return {
      displayValue: "",
    };
  },
  created() {
    this.fixedMaxlength = this.maxlength;
    this.$validator.extend("percent_decimal_value", {
      validate: function isPercentDecimalValue(value, decimal) {
        if (value === null) return false;
        const decimalValue = String(value).split(".")[1];
        return !decimalValue || decimalValue.length <= Number(decimal[0])
          ? true
          : false;
      },
      params: ["decimal"],
      getMessage: (field, decimal) =>
        `${field}は小数点${decimal}以下で入力してください`,
    });
  },
  computed: {
    minValue() {
      return 1 / Math.pow(10, this.decimal);
    },
  },
  methods: {
    numToSave(num) {
      return (
        (num * Math.pow(10, this.decimal + 2)) /
        (100 * Math.pow(10, this.decimal + 2))
      );
    },
    numToLoad(num) {
      return (
        Math.floor(num * 100 * Math.pow(10, this.decimal)) /
        Math.pow(10, this.decimal)
      );
    },
  },
  watch: {
    internalValue: {
      handler(newVal) {
        if (!this.$el) {
          if (newVal === null) {
            this.displayValue = "";
            return;
          } else {
            this.displayValue = this.numToLoad(newVal);
            return;
          }
        }
      },
      immediate: true,
    },
    displayValue: {
      handler(newVal) {
        if (newVal !== "") {
          this.internalValue = this.numToSave(newVal);
        } else {
          this.internalValue = null;
          return;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.z-percentInput /deep/ .control {
  // カウンターを非表示に
  /deep/ .counter {
    display: none;
  }

  &::after {
    content: "%";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
