<script>
import firebase from "@/plugins/firebase";
//import { cloneDeep } from "lodash";

export default {
  name: "Mixins-bank",
  methods: {
    async $$bank$getBanks() {
      const qs = await firebase.db
        .collection("banks_v1")
        .orderBy("bank_cd")
        .get();
      const ls = [];
      qs.forEach((doc) => {
        const data = doc.data();
        ls.push({
          value: data.bank_cd,
          text: data.bank_name,
        });
      });
      return ls;
    },
    async $$bank$getBranches(bankCd) {
      if (bankCd) {
        const qs = await firebase.db
          .collection("branches_v1")
          .where("bank_cd", "==", bankCd)
          .orderBy("branch_cd")
          .get();

        const ls = [];
        qs.forEach((doc) => {
          const data = doc.data();
          ls.push({
            value: doc.id,
            text: data.branch_name,
          });
        });
        return ls;
      } else {
        return [];
      }
    },
    async $$bank$getInfOfBank(bankCd) {
      if (bankCd) {
        const info = await firebase.db
          .collection("banks_v1")
          .doc(bankCd)
          .get()
          .then((doc) => {
            if (doc.exists) {
              return doc.data();
            } else {
              return null;
            }
          });
        return info;
      } else {
        return null;
      }
    },
    async $$bank$getInfOfBranch(branchCd) {
      if (branchCd) {
        const info = await firebase.db
          .collection("branches_v1")
          .doc(branchCd)
          .get()
          .then((doc) => {
            if (doc.exists) {
              return doc.data();
            } else {
              return null;
            }
          });
        return info;
      } else {
        return null;
      }
    },
    async $$bank$generateTextOfBank(bankCd, branchCd) {
      const b = (await this.$$bank$getInfOfBank(bankCd)) || { bank_name: "" };
      const r = (await this.$$bank$getInfOfBranch(branchCd)) || {
        branch_name: "",
      };
      return b.bank_name + " " + r.branch_name;
    },
  },
};
</script>
