<template>
  <p class="z-animatedPrieLabel">
    <span ref="priceLabel"><span class="prefix">¥</span> {{ price }}</span>
  </p>
</template>

<script>
import fitty from "fitty";

export default {
  name: "ZAnimatedPriceLabel",
  data: function () {
    return {
      countValue: 0,
      countTimer: null,
    };
  },
  props: {
    // 値
    value: {
      type: [Number, String],
      default: null,
    },
    // トランジションスピード
    transitionDuration: {
      type: Number,
      default: 1000,
    },
  },
  computed: {
    price() {
      if (this.countValue === null) {
        return "-";
      }
      return this.countValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
    value: {
      handler: function (value, oldValue) {
        window.clearTimeout(this.countTimer);

        if (!Number.isFinite(value)) {
          this.countValue = null;
          return;
        }

        if (oldValue === undefined) {
          this.countValue = value;
          return;
        }

        const interval = this.transitionDuration / 100;
        const initialValue = oldValue || 0;

        let counter = 0;
        const animate = () => {
          counter++;
          if (counter <= 100) {
            this.countValue =
              Math.floor(((value - initialValue) * counter) / 100) +
              initialValue;
            this.countTimer = setTimeout(() => {
              animate();
            }, interval);
          } else {
            window.clearTimeout(this.countTimer);
            this.countTimer = null;
            this.countValue = value;
          }
        };
        animate();
      },
      immediate: true,
    },
  },
  mounted() {
    fitty(this.$refs.priceLabel, {
      minSize: 10,
      maxSize: 27,
      multiLine: false,
    });
  },
  beforeDestroy() {
    if (this.countTimer) {
      window.clearTimeout(this.countTimer);
      this.countTimer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.z-animatedPrieLabel {
  font-size: 27px;
  text-align: center;
  color: #fff;
  white-space: nowrap !important;
  .prefix {
    font-size: 16px;
  }
}
</style>
