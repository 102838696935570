<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="不動産売買申告" sub="成約情報">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <!--
    <ZHeader title="不動産売買申告" />
    -->
    <section class="edit">
      <TransactionSteps :activeStep="0" />

      <section class="edit__section">
        <h2 class="edit__subtitle">成約情報</h2>
        <z-input
          label="投資家氏名"
          v-model="transaction.data.user.name"
          :validate="'required'"
          placeholder="例)島崎 怜平"
          maxlength="32"
          isRequired
        />
        <z-input
          label="投資家電話番号"
          v-model="transaction.data.user.phoneNumber"
          type="tel"
          placeholder="例)09011111111"
          maxlength="11"
          isRequired
          :validate="'required|min:10|max:11|numeric'"
        />
        <z-select
          label="契約種別"
          v-model="transaction.data.transactionType"
          :options="$$ks.realtor.realEstate.transactionTypes"
          :validate="'required'"
          isRequired
        />
        <ZRadio
          label="購入 / 売却"
          v-model="transaction.data.tradeType"
          :options="$$ks.transaction.tradeTypes"
          :validate="'required'"
          isRequired
        />
        <ZDatepicker
          label="売買契約日"
          v-model="transaction.data.contractDate"
          :validate="'required'"
          placeholder="例)1999/01/01"
          :maxOfToday="false"
          isRequired
        />
        <ZDatepicker
          label="決済実行日"
          v-model="transaction.data.settlementDate"
          :validate="'required'"
          placeholder="例)1999/01/01"
          :maxOfToday="false"
          isRequired
        />
        <ZPriceInput
          label="取引金額"
          v-model="transaction.data.price"
          placeholder="例)¥500,000"
          :validate="'required|price_min_value:1'"
          maxlength="12"
          isRequired
        />
        <ZPriceInput
          label="仲介手数料"
          v-model="transaction.data.fee"
          placeholder="例)¥500,000"
          :validate="'required|price_min_value:1'"
          maxlength="12"
          isRequired
        />
      </section>

      <div class="edit__foot">
        <!--
        <router-link
          :to="{ name: 'realtor-my' }"
          class="button z-button is-rounded"
          >戻る</router-link
        >
        -->
        <b-button
          :disabled="$$validator$invalid"
          @click="save"
          type="is-primary"
          rounded
          class="z-button is-wide button__mobile"
          >次へ</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import TransactionSteps from "./parts/TransactionSteps";

export default {
  name: "relator-transaction-contract-form",
  data: function () {
    return {
      isLoading: true,
      transaction: null,
      savedTransaction: null,
    };
  },
  components: {
    TransactionSteps,
  },
  computed: {
    transactionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async init() {
      try {
        if (this.transactionId === "new") {
          this.transaction = await this.$$transaction$getNewRealtorTransaction(
            this.me.id
          );
        } else {
          this.transaction = await this.$$transaction$loadTransaction(
            this.transactionId
          );
        }
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        if (
          !this.transaction.data.statusOfRegister ||
          this.transaction.data.statusOfRegister === 100 ||
          this.transaction.data.statusOfRegister === 190
        ) {
          this.transaction.data.statusOfRegister = 100;
          this.savedTransaction =
            await this.$$transaction$saveRealtorTransaction(
              this.me.id,
              this.transaction
            );
          this.$router.push({
            name: "realtor-setting-transaction-realestate",
            params: { id: this.savedTransaction.id },
          });
        } else {
          this.$router.push({
            name: "realtor-setting-transaction-realestate",
            params: { id: this.transactionId },
          });
        }
        // this.transaction.data.statusOfRegister = 100;
        // this.savedTransaction = await this.$$transaction$saveRealtorTransaction(this.me.id, this.transaction);
        // this.$router.push({
        //   name: "realtor-setting-transaction-realestate",
        //   params: { id: this.savedTransaction.id }
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.button {
  &__mobile {
    @include sp {
      margin-top: 10px;
    }
  }
}
</style>
