<template>
  <div>
    <section class="section">
      <div class="content has-text-centered">
        <h4>プロフィールのご登録を<br class="br-show" />受け付けました</h4>
        <br />
        <h4>
          {{ me.user.company.data.name }}<br />
          {{ me.user.data.base.name }}様
        </h4>
        <hr />

        <p>
          ご担当者様情報を
          <br class="br-show" />
          ご登録いただきまして、
          <br class="br-show" />
          ありがとうございます。
        </p>
        <br class="br-sp" />

        <p>
          ご登録内容に不備がないか等を、<br
            class="br-show"
          />確認・審査させていただきまして、<br />
          結果をメールにて<br class="br-show" />ご連絡させていただきます。
        </p>
        <br class="br-sp" />

        <p>
          なお、登録内容に関しまして、<br
            class="br-show"
          />当方事務局より<br />お問い合わせ差し上げることがありますが、
          その際につきましては何卒よろしくお願いいたします。
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";

// import UserBasicEditList from "@/components/realtor/FormUserBasic";

export default {
  name: "realtors-welcome-finish",
  //components: {
  //  UserBasicEditList: UserBasicEditList
  //},
  data: function () {
    return {
      user: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.br-show {
  display: none;
  @include mobile {
    display: block;
  }
}

.br-sp {
  @include mobile {
    display: none;
  }
}
</style>
