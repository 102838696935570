<template>
  <div v-if="!isLoading">
    <section>
      <ZHeader title="Market Trend" sub="市況情報">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
      <section class="z-section">
        <div class="z-section__inner">
          <InvestorInfo :data="rsInfo.owner.data" />
          <RealestateOverview :data="rsInfo.data" isPublic />
          <RealestateDetail
            :data="rsInfo.data"
            showLoanInfo
            showBildingInfo
            showlandInfo
          />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import RealestateOverview from "@/components/realestate/RealestateOverview";
import RealestateDetail from "@/components/realestate/RealestateDetail";
import InvestorInfo from "@/components/realestate/InvestorInfo";

export default {
  name: "markettrend-realestate",
  data: function () {
    return {
      isLoading: true,
      rsInfo: null,
    };
  },
  components: {
    RealestateOverview: RealestateOverview,
    RealestateDetail: RealestateDetail,
    InvestorInfo: InvestorInfo,
  },
  computed: {
    data() {
      return this.rsInfo.data;
    },
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.rsInfo = await this.$$realEstate$loadRealEstateSaleInfo(
          this.$route.params.id
        );
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    openEdit() {
      // console.log("TODO: openEdit " + this.rsInfo.id);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
