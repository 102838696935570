<template>
  <div>
    <template
      v-if="
        savedFilter &&
        (savedFilter.data.activeTab === 0 || savedFilter.data.activeTab === 1)
      "
    >
      <div class="columns">
        <div class="column is-full">
          <div class="conditions">
            <div class="conditions--container">
              <div class="conditions--item">
                <p class="conditions--header">保存した検索条件</p>
              </div>
              <!-- 不動産情報 -->
              <template v-if="savedFilter.data.activeTab === 0">
                <template
                  v-if="$$isNullOrEmpty(savedFilter.data.selectedRealEstate)"
                >
                  <div class="conditions--item">
                    <div class="conditions--row">
                      <span class="conditions--title"> 提案不動産： </span>
                      <span class="conditions--text"> 設定なし </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="conditions--item">
                    <div class="conditions--row">
                      <span class="conditions--title"> 物件種別： </span>
                      <span class="conditions--text">
                        <InvestorSavedFilterItem
                          :value="
                            savedFilter.data.selectedRealEstate._source.data
                              .realEstateType
                          "
                          :type="2"
                        />
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 建物種別： </span>
                      <span class="conditions--text">
                        <InvestorSavedFilterItem
                          :value="
                            savedFilter.data.selectedRealEstate._source.data
                              .buildingType
                          "
                          :type="2"
                        />
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> エリア： </span>
                      <span class="conditions--text">
                        <InvestorSavedFilterItem
                          :value="
                            savedFilter.data.selectedRealEstate._source.data
                              .prefecture
                          "
                          :type="2"
                        />
                        {{
                          savedFilter.data.selectedRealEstate._source.data.city
                            ? savedFilter.data.selectedRealEstate._source.data
                                .city.cityName
                            : ""
                        }}
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 沿線： </span>
                      <span class="conditions--text">
                        {{
                          transport(
                            savedFilter.data.selectedRealEstate._source.data
                              .transports
                          ) || "指定なし"
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="conditions--item">
                    <div class="conditions--row">
                      <span class="conditions--title"> 価格： </span>
                      <span class="conditions--text">
                        {{
                          $$price$priceJPFormat(
                            savedFilter.data.selectedRealEstate._source.data
                              .price
                          ) || "指定なし"
                        }}
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 表面利回り： </span>
                      <span class="conditions--text">
                        {{
                          $$rete$rateFormat(
                            savedFilter.data.selectedRealEstate._source.data
                              .couponYieldRate
                          ) || "指定なし"
                        }}
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title">
                        最寄り駅までの徒歩分数：
                      </span>
                      <span class="conditions--text">
                        {{
                          `${Math.min.apply(
                            null,
                            savedFilter.data.selectedRealEstate._source.data.transports.map(
                              (item) => item.elapsedTime
                            )
                          )}分` || "指定なし"
                        }}
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 築年数： </span>
                      <span class="conditions--text">
                        {{
                          `${$$realEstate$calcYearOfConstruction(
                            savedFilter.data.selectedRealEstate._source.data
                              .constructionDate
                          )}年` || "指定なし"
                        }}
                      </span>
                    </div>
                  </div>
                </template>
              </template>
              <!-- /不動産情報 -->
              <!-- 買いニーズ -->
              <template v-else-if="savedFilter.data.activeTab === 1">
                <div class="conditions--item">
                  <template v-if="savedFilter.data.buyingNeeds.area.isSelected">
                    <div class="conditions--row">
                      <span class="conditions--title"> 都道府県： </span>
                      <span class="conditions--text">
                        <InvestorSavedFilterItem
                          :kubun="$$ks.prefectures"
                          :value="savedFilter.data.buyingNeeds.area.prefecture"
                          :type="1"
                        />
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 市区町村： </span>
                      <span class="conditions--text">
                        {{ savedFilterCities || "設定なし" }}
                      </span>
                    </div>
                  </template>
                  <template
                    v-else-if="savedFilter.data.buyingNeeds.Line.isSelected"
                  >
                    <div class="conditions--row">
                      <span class="conditions--title"> 都道府県： </span>
                      <span class="conditions--text">
                        <InvestorSavedFilterItem
                          :kubun="$$ks.prefectures"
                          :value="savedFilter.data.buyingNeeds.Line.prefecture"
                          :type="1"
                        />
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 沿線： </span>
                      <span class="conditions--text">
                        {{ savedFilterLine || "設定なし" }}
                      </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 駅： </span>
                      <span class="conditions--text">
                        {{ savedFilterStations || "設定なし" }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="conditions--row">
                      <span class="conditions--title"> エリア： </span>
                      <span class="conditions--text"> 設定なし </span>
                    </div>
                    <div class="conditions--row">
                      <span class="conditions--title"> 沿線： </span>
                      <span class="conditions--text"> 設定なし </span>
                    </div>
                  </template>
                  <div class="conditions--row">
                    <span class="conditions--title"> 物件種別： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.realEstateTypes"
                        :value="savedFilter.data.buyingNeeds.realEstateTypes"
                        :type="1"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 建物種別： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.typeOfBuildingTypes"
                        :value="savedFilter.data.buyingNeeds.buildingTypes"
                        :type="1"
                      />
                    </span>
                  </div>
                </div>
                <div class="conditions--item">
                  <div class="conditions--row">
                    <span class="conditions--title"> 表面利回り： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.yieldRate"
                        :value="savedFilter.data.buyingNeeds.couponYieldRate"
                        :type="0"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 価格： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.price"
                        :value="savedFilter.data.buyingNeeds.askingPrice"
                        :type="0"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 築年数： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.yearOfconstruction"
                        :value="savedFilter.data.buyingNeeds.ageOfBuilding"
                        :type="0"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 駅からの距離： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.elapsedTimeRange"
                        :value="
                          savedFilter.data.buyingNeeds.transport.elapsedTime
                        "
                        :type="1"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title">
                      自己資金・担保可能金額：
                    </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.price"
                        :value="savedFilter.data.buyingNeeds.ownFund"
                        :type="0"
                      />
                    </span>
                  </div>
                </div>
              </template>
              <!-- /買いニーズ -->
              <!-- 投資家情報 -->
              <template
                v-if="
                  savedFilter.data.activeTab === 0 ||
                  savedFilter.data.activeTab === 1
                "
              >
                <div class="conditions--item">
                  <div class="conditions--row">
                    <span class="conditions--title"> 会員区分： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.investor.application.memberships"
                        :value="savedFilter.data.investorInfo.memberships"
                        :type="1"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> スコア： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.score"
                        :value="savedFilter.data.investorInfo.score"
                        :type="0"
                      />
                      <template
                        v-if="
                          savedFilter.data.investorInfo.isExcludeScoreBelow350
                        "
                      >
                        （スコア350以下の投資家を除く）
                      </template>
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 収入： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.income"
                        :value="savedFilter.data.investorInfo.income"
                        :type="0"
                      />
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 純資産： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.price"
                        :value="savedFilter.data.investorInfo.netAssets"
                        :type="0"
                      />
                    </span>
                  </div>
                </div>
                <div class="conditions--item">
                  <div class="conditions--row">
                    <span class="conditions--title"> 業種： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :value="savedFilter.data.investorInfo.businesses"
                        :type="2"
                      />
                      <template
                        v-if="
                          savedFilter.data.investorInfo
                            .isExcludeBusinessRealEstate
                        "
                      >
                        （不動産関係の業種を除く）
                      </template>
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 職種： </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :value="savedFilter.data.investorInfo.occupations"
                        :type="2"
                      />
                      <template
                        v-if="
                          savedFilter.data.investorInfo
                            .isExcludeOccupationRealEstate
                        "
                      >
                        （不動産関係の職種を除く）
                      </template>
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> 買いニーズ登録： </span>
                    <span class="conditions--text">
                      {{ savedFilterBuyingneedsRegister || "指定なし" }}
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title"> コメント： </span>
                    <span class="conditions--text">
                      {{
                        savedFilter.data.otherInfo.commentKeywords.join("、") ||
                        "設定なし"
                      }}
                    </span>
                  </div>
                  <div class="conditions--row">
                    <span class="conditions--title">
                      指定期間以内でオファー済みの投資家を除く：
                    </span>
                    <span class="conditions--text">
                      <InvestorSavedFilterItem
                        :kubun="$$ks.filters.excludeOfferedPeriod"
                        :value="savedFilter.data.otherInfo.excludeOfferedPeriod"
                        :type="1"
                      />
                    </span>
                  </div>
                </div>
              </template>
              <!-- /投資家情報 -->
            </div>
            <div class="conditions--delete">
              <a @click="onDelete">
                <ZIcon name="delete" width="15" height="17" color="#0262D8" />
              </a>
            </div>
            <div class="conditions--button">
              <b-button type="is-primary" @click="onSearchBySavedFilter">
                保存した条件で検索する
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="columns">
      <div class="column">
        <div class="menuBox">
          <ZEditAccordionModified
            id="accordionSearchConditions"
            title="絞り込み検索条件"
            icon="magnify"
            :opened="false"
            ref="accordionSearchConditions"
            @toggle="toggleAccordion"
          >
            <p class="menuBox--errorText" v-if="isShowErrorText">
              一括提案するには、「不動産から絞り込む」もしくは「買いニーズから絞り込む」から「一括提案必須」項目を設定・検索の上、提案する投資家を選択して提案してください。
            </p>
            <b-tabs :animated="false" size="is-medium" v-model="activeTab">
              <!-- 不動産から絞り込む -->
              <b-tab-item
                :label="
                  bulma.isMobile ? '不動産から\n絞り込む' : '不動産から絞り込む'
                "
              >
                <!-- 提案不動産（一括提案　必須設定） -->
                <div class="columns is-multiline filter">
                  <div class="column is-full">
                    <h4 class="filter--title">
                      提案不動産（一括提案　必須設定）
                    </h4>
                    <section>
                      <div class="columns">
                        <div class="column is-7">
                          <div class="filter--selectRealEstate--wrapper">
                            <p class="filter--hint">
                              <b>
                                提案する不動産を選択して、買いニーズにマッチした投資家を検索してください。
                                <span class="filter--requirelabel">
                                  一括提案必須
                                </span>
                              </b>
                              <br />※一括提案するには、「提案不動産」を設定・検索して投資家を絞り込む必要があります。
                            </p>
                          </div>
                          <b-button
                            class="z-button"
                            type="is-primary"
                            size="is-small"
                            @click="onOpenSelectRealEstate"
                          >
                            不動産を選択
                          </b-button>
                        </div>
                        <div class="column is-5">
                          <div class="filter--selectRealEstate"></div>
                          <template
                            v-if="filterByRealEstate.selectedRealEstate"
                          >
                            <SelectedRealEstates
                              :mode="modeModalSelectRealEstate"
                              :selectedRealEstates="[
                                filterByRealEstate.selectedRealEstate,
                              ]"
                            />
                          </template>
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /提案不動産（一括提案　必須設定） -->

                  <!-- 投資家条件 -->
                  <div class="column is-full">
                    <h4 class="filter--title">投資家条件</h4>
                    <section>
                      <div class="columns">
                        <div class="column">
                          <ZCheckbox
                            class="filter--membership__checkbox"
                            label="会員区分"
                            v-model="
                              filterByRealEstate.investorInfo.memberships
                            "
                            :options="typeOfMemberships"
                            hideRequired
                          />
                          <div class="filter--membership__hint">
                            <b-icon icon="alert-circle" />
                            <p class="filter--membership__text">
                              <span>今月提案可能な無料会員投資家の数：</span>
                              <span class="filter--membership__number">
                                {{
                                  this.$parent.loginUser.exclusiveMessageCount
                                }}
                              </span>
                            </p>
                            <b-tooltip
                              :label="labelTooltipProposal"
                              multilined
                              position="is-top"
                              type="is-dark"
                            >
                              <img
                                class="filter--membership__help"
                                src="@/assets/investor/help.png"
                              />
                            </b-tooltip>
                          </div>
                        </div>
                        <div class="column">
                          <ZScoreFromToSelect
                            label="スコア"
                            class="filter--score"
                            v-model="filterByRealEstate.investorInfo.score"
                            hideRequired
                          />
                          <div class="z-checkbox">
                            <div class="block">
                              <b-checkbox
                                v-model="
                                  filterByRealEstate.investorInfo
                                    .isExcludeScoreBelow350
                                "
                                type="is-info"
                                :true-value="true"
                              >
                                スコア350以下の投資家を除く
                              </b-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZIncomeFromToSelect
                            label="収入"
                            v-model="filterByRealEstate.investorInfo.income"
                            hideRequired
                          />
                        </div>
                        <div class="column">
                          <ZPriceFromToSelect
                            label="純資産"
                            v-model="filterByRealEstate.investorInfo.netAssets"
                            hideRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="filter--business z-checkbox">
                            <ZField label="業種" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByRealEstate.investorInfo
                                      .isExcludeBusinessRealEstate
                                  "
                                  type="is-info"
                                  @input="
                                    onChangeIsExcludeBusinessRealEstate($event)
                                  "
                                >
                                  不動産関係の業種を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                          <multiselect
                            v-model="filterByRealEstate.investorInfo.businesses"
                            :options="filteredTagBusiness"
                            :multiple="true"
                            :close-on-select="false"
                            :taggable="false"
                            :searchable="false"
                            placeholder="業種を選択"
                            selectLabel="クリックで選択"
                            selectedLabel="選択済み"
                            deselectLabel="選択解除"
                            label="text"
                            track-by="value"
                          />
                        </div>
                        <div class="column">
                          <div class="filter--occupation z-checkbox">
                            <ZField label="職種" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByRealEstate.investorInfo
                                      .isExcludeOccupationRealEstate
                                  "
                                  type="is-info"
                                  @input="
                                    onChangeIsExcludeOccupationRealEstate(
                                      $event
                                    )
                                  "
                                >
                                  不動産関係の職種を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                          <multiselect
                            v-model="
                              filterByRealEstate.investorInfo.occupations
                            "
                            :options="filteredTagOccupation"
                            :multiple="true"
                            :close-on-select="false"
                            :taggable="false"
                            :searchable="false"
                            placeholder="業種を選択"
                            selectLabel="クリックで選択"
                            selectedLabel="選択済み"
                            deselectLabel="選択解除"
                            label="text"
                            track-by="value"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /投資家条件 -->

                  <!-- その他絞り込み -->
                  <div class="column is-full">
                    <h4 class="filter--title">その他絞り込み</h4>
                    <section>
                      <div class="columns">
                        <div class="column">
                          <div class="z-checkbox">
                            <ZField label="買いニーズ登録" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByRealEstate.otherInfo
                                      .isExcludeUnregisteredInvestors
                                  "
                                  type="is-info"
                                >
                                  未登録の投資家を除く
                                </b-checkbox>
                                <b-checkbox
                                  v-model="
                                    filterByRealEstate.otherInfo
                                      .isExcludeInvestorsWithNoComments
                                  "
                                  type="is-info"
                                >
                                  提案希望コメントが未登録の投資家を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                        </div>
                        <div class="column">
                          <ZField
                            label="フリーワード検索（買いニーズ内コメント）"
                            class="filter--commentKeywords"
                            hideRequired
                          >
                            <b-taginput
                              v-model="
                                filterByRealEstate.otherInfo.commentKeywords
                              "
                              ellipsis
                              icon="label"
                              placeholder="フリーワード検索"
                            />
                          </ZField>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <ZField
                            label="指定期間以内でオファー済みの投資家を除く"
                            hideRequired
                          >
                            <ZSelect
                              class="filter--excludeOfferedPeriod"
                              v-model="
                                filterByRealEstate.otherInfo
                                  .excludeOfferedPeriod
                              "
                              :options="this.$$ks.filters.excludeOfferedPeriod"
                              placeholder="指定なし"
                            />
                          </ZField>
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /その他絞り込み -->
                </div>

                <div class="columns is-multiline filter">
                  <div class="column is-full">
                    <div class="filter--button">
                      <b-button
                        class="filter--button__search z-button"
                        type="is-primary"
                        size="is-small"
                        @click="onSearch"
                      >
                        検索する
                      </b-button>
                    </div>
                    <div class="filter--button">
                      <b-button
                        class="filter--button__reset z-button"
                        type="is-default"
                        size="is-small"
                        @click="onReset"
                      >
                        リセットする
                      </b-button>
                      <b-button
                        class="filter--button__save z-button"
                        type="is-primary"
                        size="is-small"
                        outlined
                        @click="onSave"
                      >
                        条件を保存する
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <!-- /不動産から絞り込む -->

              <!-- 買いニーズから絞り込む -->
              <b-tab-item
                :label="
                  bulma.isMobile
                    ? '買いニーズから\n絞り込む'
                    : '買いニーズから絞り込む'
                "
              >
                <!-- 買いニーズ（一括提案　必須設定） -->
                <div class="columns is-multiline filter">
                  <div class="column is-full">
                    <h4 class="filter--title">
                      買いニーズ（一括提案　必須設定）
                    </h4>
                    <p class="filter--hint">
                      <b>
                        提案する不動産の条件を具体的に選択して、買いニーズにマッチした投資家を検索してください。（指定範囲を具体的且つ指定幅を小さくすることで、提案に対するマッチング率が高まります。）
                      </b>
                      <br />※一括提案するには、「買いニーズ」を設定・検索して投資家を絞り込む必要があります。
                    </p>
                    <section>
                      <div class="columns">
                        <div class="column">
                          <ZAreaLineFieldSet
                            ref="areaLineFieldSet"
                            class="filter--areaLine"
                            v-model="filterByBuyingNeeds.buyingNeeds"
                            isRequired
                            @tabAreaLineChange="tabAreaLineChangeHandle"
                            @tabAreaLineReset="tabAreaLineResetHandle"
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZCheckbox
                            label="物件種別"
                            class="filter--realEstateTypes"
                            v-model="
                              filterByBuyingNeeds.buyingNeeds.realEstateTypes
                            "
                            :options="$$ks.realEstateTypes"
                            isRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZCheckbox
                            label="建物種別"
                            class="filter--buildingTypes"
                            v-model="
                              filterByBuyingNeeds.buyingNeeds.buildingTypes
                            "
                            :options="$$ks.typeOfBuildingTypes"
                            isRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZRateFromToSelect
                            label="表面利回り"
                            class="filter--couponYieldRate"
                            v-model="
                              filterByBuyingNeeds.buyingNeeds.couponYieldRate
                            "
                            isRequired
                            placeholder=""
                          />
                        </div>
                        <div class="column">
                          <ZPriceFromToSelect
                            label="価格"
                            class="filter--askingPrice"
                            v-model="
                              filterByBuyingNeeds.buyingNeeds.askingPrice
                            "
                            isRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZAgeOfbuildingFromToSelect
                            label="築年数"
                            class="filter--ageOfBuilding"
                            v-model="
                              filterByBuyingNeeds.buyingNeeds.ageOfBuilding
                            "
                            isRequired
                          />
                        </div>
                        <div class="column">
                          <ZTransportRangeSelect
                            label="駅からの距離"
                            class="filter--transport"
                            v-model="filterByBuyingNeeds.buyingNeeds.transport"
                            isRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <ZPriceFromToSelect
                            label="自己資金・担保可能金額"
                            v-model="filterByBuyingNeeds.buyingNeeds.ownFund"
                            hideRequired
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /買いニーズ（一括提案　必須設定） -->

                  <!-- 投資家条件 -->
                  <div class="column is-full">
                    <h4 class="filter--title">投資家条件</h4>
                    <section>
                      <div class="columns">
                        <div class="column">
                          <ZCheckbox
                            class="filter--membership__checkbox"
                            label="会員区分"
                            v-model="
                              filterByBuyingNeeds.investorInfo.memberships
                            "
                            :options="typeOfMemberships"
                            hideRequired
                          />
                          <div class="filter--membership__hint">
                            <b-icon icon="alert-circle" />
                            <p class="filter--membership__text">
                              <span>今月提案可能な無料会員投資家の数：</span>
                              <span class="filter--membership__number">
                                {{
                                  this.$parent.loginUser.exclusiveMessageCount
                                }}
                              </span>
                            </p>
                            <b-tooltip
                              :label="labelTooltipProposal"
                              multilined
                              position="is-top"
                              type="is-dark"
                            >
                              <img
                                class="filter--membership__help"
                                src="@/assets/investor/help.png"
                              />
                            </b-tooltip>
                          </div>
                        </div>
                        <div class="column">
                          <ZScoreFromToSelect
                            label="スコア"
                            class="filter--score"
                            v-model="filterByBuyingNeeds.investorInfo.score"
                            hideRequired
                          />
                          <div class="z-checkbox">
                            <div class="block">
                              <b-checkbox
                                v-model="
                                  filterByBuyingNeeds.investorInfo
                                    .isExcludeScoreBelow350
                                "
                                type="is-info"
                                :true-value="true"
                              >
                                スコア350以下の投資家を除く
                              </b-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <ZIncomeFromToSelect
                            label="収入"
                            v-model="filterByBuyingNeeds.investorInfo.income"
                            hideRequired
                          />
                        </div>
                        <div class="column">
                          <ZPriceFromToSelect
                            label="純資産"
                            v-model="filterByBuyingNeeds.investorInfo.netAssets"
                            hideRequired
                          />
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="filter--business z-checkbox">
                            <ZField label="業種" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByBuyingNeeds.investorInfo
                                      .isExcludeBusinessRealEstate
                                  "
                                  type="is-info"
                                  @input="
                                    onChangeIsExcludeBusinessRealEstate($event)
                                  "
                                >
                                  不動産関係の業種を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                          <multiselect
                            v-model="
                              filterByBuyingNeeds.investorInfo.businesses
                            "
                            :options="filteredTagBusiness"
                            :multiple="true"
                            :close-on-select="false"
                            :taggable="false"
                            :searchable="false"
                            placeholder="業種を選択"
                            selectLabel="クリックで選択"
                            selectedLabel="選択済み"
                            deselectLabel="選択解除"
                            label="text"
                            track-by="value"
                          />
                        </div>
                        <div class="column">
                          <div class="filter--occupation z-checkbox">
                            <ZField label="職種" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByBuyingNeeds.investorInfo
                                      .isExcludeOccupationRealEstate
                                  "
                                  type="is-info"
                                  @input="
                                    onChangeIsExcludeOccupationRealEstate(
                                      $event
                                    )
                                  "
                                >
                                  不動産関係の職種を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                          <multiselect
                            v-model="
                              filterByBuyingNeeds.investorInfo.occupations
                            "
                            :options="filteredTagOccupation"
                            :multiple="true"
                            :close-on-select="false"
                            :taggable="false"
                            :searchable="false"
                            placeholder="業種を選択"
                            selectLabel="クリックで選択"
                            selectedLabel="選択済み"
                            deselectLabel="選択解除"
                            label="text"
                            track-by="value"
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /投資家条件 -->

                  <!-- その他絞り込み -->
                  <div class="column is-full">
                    <h4 class="filter--title">その他絞り込み</h4>
                    <section>
                      <div class="columns">
                        <div class="column">
                          <div class="z-checkbox">
                            <ZField label="買いニーズ登録" hideRequired>
                              <div class="block">
                                <b-checkbox
                                  v-model="
                                    filterByBuyingNeeds.otherInfo
                                      .isExcludeUnregisteredInvestors
                                  "
                                  type="is-info"
                                >
                                  未登録の投資家を除く
                                </b-checkbox>
                                <b-checkbox
                                  v-model="
                                    filterByBuyingNeeds.otherInfo
                                      .isExcludeInvestorsWithNoComments
                                  "
                                  type="is-info"
                                >
                                  提案希望コメントが未登録の投資家を除く
                                </b-checkbox>
                              </div>
                            </ZField>
                          </div>
                        </div>
                        <div class="column">
                          <ZField
                            label="フリーワード検索（買いニーズ内コメント）"
                            class="filter--commentKeywords"
                            hideRequired
                          >
                            <b-taginput
                              v-model="
                                filterByBuyingNeeds.otherInfo.commentKeywords
                              "
                              ellipsis
                              icon="label"
                              placeholder="フリーワード検索"
                            />
                          </ZField>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <ZField
                            label="指定期間以内でオファー済みの投資家を除く"
                            hideRequired
                          >
                            <ZSelect
                              class="filter--excludeOfferedPeriod"
                              v-model="
                                filterByBuyingNeeds.otherInfo
                                  .excludeOfferedPeriod
                              "
                              :options="this.$$ks.filters.excludeOfferedPeriod"
                              placeholder="指定なし"
                            />
                          </ZField>
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- /その他絞り込み -->
                </div>

                <div class="columns is-multiline filter">
                  <div class="column is-full">
                    <div class="filter--button">
                      <b-button
                        class="filter--button__search z-button"
                        type="is-primary"
                        size="is-small"
                        @click="onSearch"
                      >
                        検索する
                      </b-button>
                    </div>
                    <div class="filter--button">
                      <b-button
                        class="filter--button__reset z-button"
                        type="is-default"
                        size="is-small"
                        @click="onReset"
                      >
                        リセットする
                      </b-button>
                      <b-button
                        class="filter--button__save z-button"
                        type="is-primary"
                        size="is-small"
                        outlined
                        @click="onSave"
                      >
                        条件を保存する
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
            <!-- /買いニーズから絞り込む -->
          </ZEditAccordionModified>
        </div>
      </div>
    </div>

    <!-- 物件選択オーバーレイ -->
    <ModalSelectRealEstates
      ref="modeModalSelectRealEstate"
      :mode="modeModalSelectRealEstate"
    />
    <!-- /物件選択オーバーレイ -->
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import kubuns from "@/kubuns/kubuns";
import InvestorSavedFilterItem from "./InvestorSavedFilterItem";
import ModalSelectRealEstates from "@/views/realtor/common/ModalSelectRealEstates";
import SelectedRealEstates from "@/views/realtor/common/SelectedRealEstates";
import ZEditAccordionModified from "./ZEditAccordionModified";
import * as InvestorFiltersModule from "@/modules/realtor/InvestorFilters";
import RealtorType from "@/types/realtorType";
import DisplayTextMixin from "./DisplayTextMixin";
import ESQueryMixin from "./ESQueryMixin";
import * as RealtorModule from "@/modules/realtor/realestate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "investor-search-conditions",
  mixins: [DisplayTextMixin, ESQueryMixin],
  components: {
    InvestorSavedFilterItem,
    ModalSelectRealEstates,
    SelectedRealEstates,
    ZEditAccordionModified,
    Multiselect,
  },
  data: function () {
    return {
      activeTab: 0,
      isShowErrorText: false,
      isSearched: false,
      modeModalSelectRealEstate: 0,
      typeOfMemberships: [...kubuns.investor.application.memberships].reverse(),
      savedFilter: null,
      selectingRealEstate: null,
      filteredTagBusiness: kubuns.categoryOfBusinesses,
      filteredTagOccupation: kubuns.typeOfOccupations,
      filterByRealEstate: {
        selectedRealEstate: null, // 提案不動産
        investorInfo: this.$$investor$getNewInvestorInfoFilter(), // 投資家情報
        otherInfo: this.$$investor$getNewOtherInfoFilter(), // その他絞り込み
      },
      filterByBuyingNeeds: {
        buyingNeeds: this.$$investor$getNewBuyingNeedsFilterNew(), // 買いニーズ
        investorInfo: this.$$investor$getNewInvestorInfoFilter(), // 投資家情報
        otherInfo: this.$$investor$getNewOtherInfoFilter(), // その他絞り込み
      },
      finalValidateRequire: true,
    };
  },
  methods: {
    // 初期化
    async init() {
      const savedFilters = await InvestorFiltersModule.loads(this.me.user.id);
      if (!savedFilters || savedFilters.length === 0) return;

      this.savedFilter = savedFilters.reduce(function (prev, current) {
        if (!prev.data || !current.data) return current;

        return prev.data.updatedDt.toDate() > current.data.updatedDt.toDate()
          ? prev
          : current;
      }, 0);

      // 元の検索結果画面に遷移する場合
      if (
        this.$route.query.isCallback ||
        (window.history &&
          window.history.state &&
          window.history.state.name === this.$parent.browserBack)
      ) {
        this.getFiltersFromLocalStorage();
      }
    },
    // 保存した条件で検索
    async onSearchBySavedFilter() {
      if (!this.savedFilter) return;

      // 不動産から絞り込むの場合、保存した不動産が存在しているかどうか
      if (
        this.savedFilter.data.activeTab === 0 &&
        this.savedFilter.data.selectedRealEstate
      ) {
        const errorMessage = "保存していた不動産が提案不可になっています。";
        const result = await RealtorModule.searchByOwner(
          this.me.user.company.id,
          null
        );

        if (result.hits.length === 0) {
          this.$$toast$openError(errorMessage);
          return;
        }

        const foundItem = result.hits.find((item) => {
          return (
            item._source.id ===
            this.savedFilter.data.selectedRealEstate._source.id
          );
        });

        if (!foundItem) {
          this.$$toast$openError(errorMessage);
          return;
        }
      }

      this.activeTab = this.savedFilter.data.activeTab;
      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          this.filterByRealEstate = {
            selectedRealEstate: _.cloneDeep(
              this.savedFilter.data.selectedRealEstate
            ), // 選択中の不動産情報
            investorInfo: _.cloneDeep(this.savedFilter.data.investorInfo), // 投資家情報
            otherInfo: _.cloneDeep(this.savedFilter.data.otherInfo), // その他絞り込み
          };
          break;
        case 1: // 買いニーズから絞り込む
          this.filterByBuyingNeeds = {
            buyingNeeds: _.cloneDeep(this.savedFilter.data.buyingNeeds), // 選択中の不動産情報
            investorInfo: _.cloneDeep(this.savedFilter.data.investorInfo), // 投資家情報
            otherInfo: _.cloneDeep(this.savedFilter.data.otherInfo), // その他絞り込み
          };
          break;
      }

      this.onSearch();
    },
    // 条件で検索
    async onSearch() {
      this.isShowErrorText = false;
      this.clearAllValidateRequire();

      // 再度検索を実行したら、一括提案した投資家を表示する
      if (this.$route.query.isCallback) {
        let savedData =
          JSON.parse(
            window.localStorage.getItem(this.$parent.localStorageFilter)
          ) || null;

        if (savedData && savedData.uidInvestors) {
          delete savedData.uidInvestors;
          window.localStorage.removeItem(this.$parent.localStorageFilter);
          window.localStorage.setItem(
            this.$parent.localStorageFilter,
            JSON.stringify(savedData)
          );
        }
      }

      const loadingComponent = this.$loading.open();
      try {
        this.$parent.checkedRows = [];
        this.isLoading = true;
        this.$parent.filter = this.createFilter();
        await this.$parent.loadData();
        this.isLoading = false;
        this.isSearched = true;
        this.$parent.currentPage = 1;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
    // 検索条件をリセット
    onReset() {
      this.isShowErrorText = false;
      this.clearAllValidateRequire();

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          this.filterByRealEstate = {
            selectedRealEstate: null, // 選択中の不動産情報
            investorInfo: this.$$investor$getNewInvestorInfoFilter(), // 投資家情報
            otherInfo: this.$$investor$getNewOtherInfoFilter(), // その他絞り込み
          };
          break;
        case 1: // 買いニーズから絞り込む
          this.$refs.areaLineFieldSet.onTabChange(0);
          this.$refs.areaLineFieldSet.reset();
          this.filterByBuyingNeeds = {
            buyingNeeds: this.$$investor$getNewBuyingNeedsFilterNew(), // 買いニーズ
            investorInfo: this.$$investor$getNewInvestorInfoFilter(), // 投資家情報
            otherInfo: this.$$investor$getNewOtherInfoFilter(), // その他絞り込み
          };
          break;
      }
    },
    // 検索条件を保存
    async onSave() {
      let savedData = null;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          savedData = this.filterByRealEstate;
          break;
        case 1: // 買いニーズから絞り込む
          savedData = this.filterByBuyingNeeds;
          break;
      }

      if (savedData) {
        savedData.activeTab = this.activeTab;

        this.savedFilter = await InvestorFiltersModule.save(
          this.me.user.id,
          this.me.user.id,
          {
            id: this.savedFilter.id,
            data: savedData,
          }
        );
      }
    },
    // 検索条件を削除
    async onDelete() {
      this.savedFilter = await InvestorFiltersModule.save(
        this.me.user.id,
        this.me.user.id,
        {
          id: this.savedFilter.id,
          data: {},
        }
      );
    },
    // 不動産関係の業種を除くをチェック
    onChangeIsExcludeBusinessRealEstate($event) {
      const categoryOfBusinessRealEstateValues =
        kubuns.getCategoryOfBusinessRealEstateValues;

      this.filteredTagBusiness = $event
        ? this.filteredTagBusiness.filter(
            (item) => !categoryOfBusinessRealEstateValues.includes(item.value)
          )
        : kubuns.categoryOfBusinesses;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          this.filterByRealEstate.investorInfo.businesses =
            this.filterByRealEstate.investorInfo.businesses.filter(
              (item) => !categoryOfBusinessRealEstateValues.includes(item.value)
            );
          break;
        case 1: // 買いニーズから絞り込む
          this.filterByBuyingNeeds.investorInfo.businesses =
            this.filterByBuyingNeeds.investorInfo.businesses.filter(
              (item) => !categoryOfBusinessRealEstateValues.includes(item.value)
            );
          break;
      }
    },
    // 不動産関係の職種を除くをチェック
    onChangeIsExcludeOccupationRealEstate($event) {
      const typeOfOccupationRealEstateValues =
        kubuns.getTypeOfOccupationRealEstateValues;

      this.filteredTagOccupation = $event
        ? this.filteredTagOccupation.filter(
            (item) => !typeOfOccupationRealEstateValues.includes(item.value)
          )
        : kubuns.typeOfOccupations;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          this.filterByRealEstate.investorInfo.occupations =
            this.filterByRealEstate.investorInfo.occupations.filter(
              (item) => !typeOfOccupationRealEstateValues.includes(item.value)
            );
          break;
        case 1: // 買いニーズから絞り込む
          this.filterByBuyingNeeds.investorInfo.occupations =
            this.filterByBuyingNeeds.investorInfo.occupations.filter(
              (item) => !typeOfOccupationRealEstateValues.includes(item.value)
            );
          break;
      }
    },
    // 物件選択オーバーレイを開く
    async onOpenSelectRealEstate() {
      this.$refs.modeModalSelectRealEstate.open();
    },
    // セレクトボックスにブランクオプションを追加
    addBlankOptionToSelect(target) {
      const elemTarget = document
        .querySelector(target)
        .querySelectorAll(".select");
      if (!elemTarget) return;

      elemTarget.forEach((elem) => {
        const elemSelect = elem.getElementsByTagName("select")[0];
        let blankOption = document.createElement("option");

        blankOption.value = "";
        blankOption.text = "";

        elemSelect.insertBefore(blankOption, elemSelect.firstChild);
        elemSelect.value = blankOption.value;
        elemSelect.options[0].disabled = true;

        elemSelect.options[1].remove();
      });
    },
    // 必須ラベルの中身を変更
    changeRequireLabel() {
      const elemRequireLabels = document.querySelectorAll(
        ".z-field__info__main__required"
      );
      if (!elemRequireLabels) return;

      elemRequireLabels.forEach((elem) => {
        elem.innerText = "一括提案必須";
      });
    },
    // 全ての一括提案必須項目をチェックする
    checkValidateRequire() {
      let elemTabAreaLineFieldSet = null;
      this.finalValidateRequire = true;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          // 提案不動産の必須チェック
          this.checkValidateRequireItemSelectRealEstate(
            ".filter--selectRealEstate",
            !this.$$isNullOrEmpty(this.filterByRealEstate.selectedRealEstate)
          );
          break;
        case 1: // 買いニーズから絞り込む
          elemTabAreaLineFieldSet = document
            .querySelector(".z-areaLineFieldSet")
            .querySelector(".tab-content");

          // アリアまたは沿線の必須チェック
          if (elemTabAreaLineFieldSet.querySelector(".areaField")) {
            this.checkValidateRequireItemAreaLine(
              ".areaField",
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.area.prefecture
              ),
              0
            );
          } else if (elemTabAreaLineFieldSet.querySelector(".stationField")) {
            this.checkValidateRequireItemAreaLine(
              ".stationField",
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.Line.prefecture
              ),
              0
            );
            this.checkValidateRequireItemAreaLine(
              ".stationField",
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.Line.line
              ),
              1
            );
          }

          // 物件種別の必須チェック
          this.checkValidateRequireItem(
            ".filter--realEstateTypes",
            this.filterByBuyingNeeds.buyingNeeds.realEstateTypes.length > 0
          );

          // 建物種別の必須チェック
          this.checkValidateRequireItem(
            ".filter--buildingTypes",
            this.filterByBuyingNeeds.buyingNeeds.buildingTypes.length > 0
          );

          // 表面利回りの必須チェック
          this.checkValidateRequireItem(
            ".filter--couponYieldRate",
            !this.$$isNullOrEmpty(
              this.filterByBuyingNeeds.buyingNeeds.couponYieldRate.from
            ) &&
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.couponYieldRate.to
              )
          );

          // 価格の必須チェック
          this.checkValidateRequireItem(
            ".filter--askingPrice",
            !this.$$isNullOrEmpty(
              this.filterByBuyingNeeds.buyingNeeds.askingPrice.from
            ) &&
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.askingPrice.to
              )
          );

          // 築年数の必須チェック
          this.checkValidateRequireItem(
            ".filter--ageOfBuilding",
            !this.$$isNullOrEmpty(
              this.filterByBuyingNeeds.buyingNeeds.ageOfBuilding.from
            ) &&
              !this.$$isNullOrEmpty(
                this.filterByBuyingNeeds.buyingNeeds.ageOfBuilding.to
              )
          );

          // 駅からの距離の必須チェック
          this.checkValidateRequireItem(
            ".filter--transport",
            !this.$$isNullOrEmpty(
              this.filterByBuyingNeeds.buyingNeeds.transport.elapsedTime
            )
          );
          break;
      }

      return this.finalValidateRequire;
    },
    // 全ての一括提案必須項目のチェックをクリアする
    clearAllValidateRequire() {
      let elemTabAreaLineFieldSet = null;
      this.finalValidateRequire = true;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          // 提案不動産の必須チェック
          this.checkValidateRequireItemSelectRealEstate(
            ".filter--selectRealEstate",
            true
          );
          break;
        case 1: // 買いニーズから絞り込む
          elemTabAreaLineFieldSet = document
            .querySelector(".z-areaLineFieldSet")
            .querySelector(".tab-content");
          if (!elemTabAreaLineFieldSet) return;

          // アリアまたは沿線の必須チェック
          if (elemTabAreaLineFieldSet.querySelector(".areaField")) {
            this.checkValidateRequireItemAreaLine(".areaField", true, 0);
          } else if (elemTabAreaLineFieldSet.querySelector(".stationField")) {
            this.checkValidateRequireItemAreaLine(".stationField", true, 0);
            this.checkValidateRequireItemAreaLine(".stationField", true, 1);
          }

          // 物件種別の必須チェック
          this.checkValidateRequireItem(".filter--realEstateTypes", true);

          // 建物種別の必須チェック
          this.checkValidateRequireItem(".filter--buildingTypes", true);

          // 表面利回りの必須チェック
          this.checkValidateRequireItem(".filter--couponYieldRate", true);

          // 価格の必須チェック
          this.checkValidateRequireItem(".filter--askingPrice", true);

          // 築年数の必須チェック
          this.checkValidateRequireItem(".filter--ageOfBuilding", true);

          // 駅からの距離の必須チェック
          this.checkValidateRequireItem(".filter--transport", true);
          break;
      }
    },
    // 一括提案必須項目をチェックする
    checkValidateRequireItem(target, isValidate, isFromClassName = true) {
      this.finalValidateRequire = this.finalValidateRequire && isValidate;

      const elemTarget = isFromClassName
        ? document.querySelector(target)
        : target;
      if (!elemTarget) return;

      const elemZField = elemTarget.querySelector(".z-field");
      if (!elemZField) return;

      const elemZFieldLabel = elemZField.querySelector(
        ".z-field__info__main__label"
      );
      if (!elemZFieldLabel) return;

      this.createElementValidateErrorText(elemZField, isValidate);
    },
    // 一括提案必須項目をチェックする
    checkValidateRequireItemSelectRealEstate(target, isValidate) {
      this.finalValidateRequire = this.finalValidateRequire && isValidate;

      const elemTarget = document.querySelector(target);
      if (!elemTarget) return;

      this.createElementValidateErrorText(elemTarget, isValidate);
    },
    // 一括提案必須項目をチェックする
    checkValidateRequireItemAreaLine(target, isValidate, index) {
      const elemTarget = document.querySelector(target);
      if (!elemTarget) return;

      const elemZSelect = elemTarget.querySelectorAll(".z-select");
      if (!elemZSelect) return;

      this.checkValidateRequireItem(elemZSelect[index], isValidate, false);
    },
    // バリデーションエラーテキストのエレメントを作成
    createElementValidateErrorText(elemTarget, isValidate) {
      if (isValidate) {
        const elemError = elemTarget.querySelector(".filter--error");

        if (elemError) elemError.remove();
        elemTarget.classList.remove("is-danger");
      } else {
        if (elemTarget.querySelector(".filter--error")) return;

        const elemError = document.createElement("p");
        const title = "提案不動産";
        const errorTextNode = document.createTextNode(
          `${title}は一括提案必須項目です`
        );

        elemError.classList.add("filter--error");
        elemError.style.color = "#ff0000";
        elemError.style.fontSize = "12px";
        elemError.style.marginTop = "4px";
        elemError.appendChild(errorTextNode);

        elemTarget.appendChild(elemError);
        elemTarget.classList.add("is-danger");
      }
    },
    // アリアまたは沿線の切り替えをハンドル
    tabAreaLineChangeHandle() {
      const elemTarget = document.querySelector(".filter--areaLine");
      if (!elemTarget) return;

      setTimeout(() => {
        const elemRequireLabels = elemTarget.querySelectorAll(
          ".z-field__info__main__required"
        );

        if (!elemRequireLabels) return;

        elemRequireLabels.forEach((elem) => {
          elem.innerText = "一括提案必須";
        });
      }, 10);
    },
    // アリアまたは沿線の再設定をハンドル
    tabAreaLineResetHandle() {
      const elemTarget = document.querySelector(".filter--areaLine");
      if (!elemTarget) return;

      setTimeout(() => {
        const elemRequireLabels = elemTarget.querySelectorAll(
          ".z-field__info__main__required"
        );

        if (!elemRequireLabels) return;

        elemRequireLabels.forEach((elem) => {
          elem.innerText = "一括提案必須";
        });
      }, 10);
    },
    // 沿線を表示
    transport: function (transports) {
      let result = [];
      transports.forEach((item) => {
        result.push(
          `${item.line.line.line_name} ${item.station.station.station_name}駅`
        );
      });
      return result.join(", ");
    },
    // ローカルストレージから検索条件を取得
    getFiltersFromLocalStorage() {
      let savedData =
        JSON.parse(
          window.localStorage.getItem(this.$parent.localStorageFilter)
        ) || null;

      if (!savedData) return;

      this.activeTab = savedData.activeTab;
      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          this.filterByRealEstate = {
            selectedRealEstate: _.cloneDeep(savedData.selectedRealEstate), // 選択中の不動産情報
            investorInfo: _.cloneDeep(savedData.investorInfo), // 投資家情報
            otherInfo: _.cloneDeep(savedData.otherInfo), // その他絞り込み
          };
          break;
        case 1: // 買いニーズから絞り込む
          this.filterByBuyingNeeds = {
            buyingNeeds: _.cloneDeep(savedData.buyingNeeds), // 選択中の不動産情報
            investorInfo: _.cloneDeep(savedData.investorInfo), // 投資家情報
            otherInfo: _.cloneDeep(savedData.otherInfo), // その他絞り込み
          };
          break;
      }
      setTimeout(() => {
        this.isSearched = true;
      }, 500);
    },
    // ローカルストレージに検索条件を保存
    saveFiltersToLocalStorage() {
      let savedData = null;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          savedData = this.filterByRealEstate;
          break;
        case 1: // 買いニーズから絞り込む
          savedData = this.filterByBuyingNeeds;
          break;
      }
      savedData.activeTab = this.activeTab;
      savedData.filter = this.createFilter();
      savedData.uidInvestors = this.$parent.checkedRows.map(
        (item) => item._source.uid
      );

      window.localStorage.removeItem(this.$parent.localStorageFilter);
      window.localStorage.setItem(
        this.$parent.localStorageFilter,
        JSON.stringify(savedData)
      );
    },
    // 業種のフィルタータグを取得
    getFilteredTagForBusiness: function (input) {
      this.filteredTagBusiness = kubuns.categoryOfBusinesses.filter(
        (option) => {
          return (
            option.text.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0
          );
        }
      );
    },
    // 職種のフィルタータグを取得
    getFilteredTagsForOccupation: function (input) {
      this.filteredTagOccupation = kubuns.typeOfOccupations.filter((option) => {
        return (
          option.text.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      });
    },
    // 絞り込みのためにフィルタを作成
    createFilter: function () {
      const defaultFilter = {
        bool: {
          must: [],
          must_not: [],
          should: [],
        },
      };

      let filterRealEstate = null;
      let filterByBuyingNeeds = null;
      let filterInvestorInfo = null;
      let filterOtherInfo = null;

      switch (this.activeTab) {
        case 0: // 不動産から絞り込む
          filterRealEstate = this.createFilterRealEstate(
            this.filterByRealEstate.selectedRealEstate,
            defaultFilter
          );
          filterInvestorInfo = this.createFilterInvestorInfo(
            this.filterByRealEstate.investorInfo,
            defaultFilter
          );
          filterOtherInfo = this.createFilterOtherInfo(
            this.filterByRealEstate.otherInfo,
            defaultFilter
          );
          return {
            ...filterRealEstate,
            ...filterInvestorInfo,
            ...filterOtherInfo,
          };
        case 1: // 買いニーズから絞り込む
          filterByBuyingNeeds = this.createFilterBuyingNeeds(
            this.filterByBuyingNeeds.buyingNeeds,
            defaultFilter
          );
          filterInvestorInfo = this.createFilterInvestorInfo(
            this.filterByBuyingNeeds.investorInfo,
            defaultFilter
          );
          filterOtherInfo = this.createFilterOtherInfo(
            this.filterByBuyingNeeds.otherInfo,
            defaultFilter
          );
          return {
            ...filterByBuyingNeeds,
            ...filterInvestorInfo,
            ...filterOtherInfo,
          };
        default:
          return defaultFilter;
      }
    },
    // 買いニーズのフィルタを作成
    createFilterBuyingNeeds: function (searchConditions, filter) {
      let subQueryMust = [];
      let subQueryMustNot = [];
      let subQueryShould = [];

      // エリアの条件
      if (searchConditions.area.isSelected) {
        if (searchConditions.area.prefecture) {
          subQueryMust.push(
            this.$$createMatchQuery(
              "user.data.buyingNeeds.area.prefecture.value",
              searchConditions.area.prefecture
            )
          );
        }
        if (searchConditions.area.cities.length > 0) {
          subQueryMust.push(
            this.$$createTermsQuery(
              "user.data.buyingNeeds.area.cities.cityCode",
              searchConditions.area.cities
            )
          );
        }
      }

      // 沿線の条件
      if (searchConditions.Line.isSelected) {
        if (searchConditions.Line.prefecture) {
          subQueryMust.push(
            this.$$createMatchQuery(
              "user.data.buyingNeeds.Line.prefecture.value",
              searchConditions.Line.prefecture
            )
          );
        }
        if (searchConditions.Line.line) {
          subQueryMust.push(
            this.$$createMatchQuery(
              "user.data.buyingNeeds.Line.line.line.line_cd",
              searchConditions.Line.line
            )
          );
        }
        if (searchConditions.Line.stations.length > 0) {
          subQueryMust.push(
            this.$$createTermsQuery(
              "user.data.buyingNeeds.Line.stations.station.station_cd",
              searchConditions.Line.stations
            )
          );
        }
      }

      // 物件種別の条件
      if (searchConditions.realEstateTypes.length > 0) {
        subQueryMust.push(
          this.$$createTermsQuery(
            "user.data.buyingNeeds.realEstateTypes.value",
            searchConditions.realEstateTypes
          )
        );
      }

      // 建物種別の条件
      if (searchConditions.buildingTypes.length > 0) {
        subQueryMust.push(
          this.$$createTermsQuery(
            "user.data.buyingNeeds.buildingTypes.value",
            searchConditions.buildingTypes
          )
        );
      }

      // 表面利回り（From）の条件
      if (
        searchConditions.couponYieldRate.from &&
        searchConditions.couponYieldRate.from !==
          kubuns.filters.yieldRate.from[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.couponYieldRate.to.value",
            { gte: searchConditions.couponYieldRate.from }
          )
        );
      }
      // 表面利回り（To）の条件
      if (
        searchConditions.couponYieldRate.to &&
        searchConditions.couponYieldRate.to !==
          kubuns.filters.yieldRate.to[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.couponYieldRate.from.value",
            { lte: searchConditions.couponYieldRate.to }
          )
        );
      }

      // 価格（From）の条件
      if (
        searchConditions.askingPrice.from &&
        searchConditions.askingPrice.from !== kubuns.filters.price.from[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.askingPrice.to.value",
            { gte: searchConditions.askingPrice.from }
          )
        );
      }
      // 価格（To）の条件
      if (
        searchConditions.askingPrice.to &&
        searchConditions.askingPrice.to !== kubuns.filters.price.to[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.askingPrice.from.value",
            { lte: searchConditions.askingPrice.to }
          )
        );
      }

      // 築年数（From）の条件
      if (
        searchConditions.ageOfBuilding.from &&
        searchConditions.ageOfBuilding.from !==
          kubuns.filters.yearOfconstruction.from[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.ageOfBuilding.to.value",
            { gte: searchConditions.ageOfBuilding.from }
          )
        );
      }
      // 築年数（To）の条件
      if (
        searchConditions.ageOfBuilding.to &&
        searchConditions.ageOfBuilding.to !==
          kubuns.filters.yearOfconstruction.to[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.ageOfBuilding.from.value",
            { lte: searchConditions.ageOfBuilding.to }
          )
        );
      }

      // 駅からの距離の条件
      if (
        searchConditions.transport.elapsedTime &&
        searchConditions.transport.elapsedTime !==
          kubuns.filters.elapsedTimeRange[0].value
      ) {
        if (
          searchConditions.transport.elapsedTime ===
          kubuns.filters.elapsedTimeRange[7].value
        ) {
          //「20分以上」→「駅からの距離」に「指定なし」を登録しているユーザーのみヒットする
          subQueryMust.push(
            this.$$createMatchQuery(
              "user.data.buyingNeeds.transport.elapsedTime.value",
              kubuns.filters.elapsedTimeRange[0].value
            )
          );
        } else {
          subQueryMust.push(
            this.$$createRangeQuery(
              "user.data.buyingNeeds.transport.elapsedTime.value",
              { gte: searchConditions.transport.elapsedTime }
            )
          );
        }
      }

      // 自己資金・担保可能金額（From）の条件
      if (
        searchConditions.ownFund.from &&
        searchConditions.ownFund.from !== kubuns.filters.price.from[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery("range", "user.data.buyingNeeds.ownFund", {
            gte: searchConditions.ownFund.from,
          })
        );
      }
      // 自己資金・担保可能金額（To）の条件
      if (
        searchConditions.ownFund.to &&
        searchConditions.ownFund.to !== kubuns.filters.price.to[0].value
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery("range", "user.data.buyingNeeds.ownFund", {
            lte: searchConditions.ownFund.to,
          })
        );
      }

      if (
        subQueryMust.length > 0 ||
        subQueryMustNot.length > 0 ||
        subQueryShould.length > 0
      ) {
        let buyingNeedsQuery = {
          bool: {
            should: [],
          },
        };

        // 買いニーズ条件を作成する
        buyingNeedsQuery.bool.should.push(
          this.$$createNestedQuery(
            "user.data.buyingNeeds",
            subQueryMust,
            subQueryMustNot,
            subQueryShould
          )
        );

        // 「未登録の投資家を除く」にチェックが付いていない場合、絞り込み検索の条件にかかわらず検索結果に未登録ユーザーも含める
        if (
          !this.filterByBuyingNeeds.otherInfo.isExcludeUnregisteredInvestors
        ) {
          buyingNeedsQuery.bool.should.push(
            this.$$createMatchQuery("user.data.countOfBuyingNeeds", 0)
          );
        }

        filter.bool.must.push(buyingNeedsQuery);
      }
    },
    // 提案不動産のフィルタを作成
    createFilterRealEstate: function (searchConditions, filter) {
      if (!searchConditions) return;

      const selectedRealEstate = searchConditions._source.data;
      let subQueryMust = [];
      let subQueryMustNot = [];
      let subQueryShould = [];

      // 選択不動産の物件種別の条件
      if (selectedRealEstate.realEstateType) {
        subQueryMust.push(
          this.$$createMatchQuery(
            "user.data.buyingNeeds.realEstateTypes.value",
            selectedRealEstate.realEstateType.value
          )
        );
      }

      // 選択不動産の建物種別の条件
      if (
        selectedRealEstate.buildingType &&
        selectedRealEstate.buildingType.value
      ) {
        subQueryMust.push(
          this.$$createMatchQuery(
            "user.data.buyingNeeds.buildingTypes.value",
            selectedRealEstate.buildingType.value
          )
        );
      }

      // 選択不動産のエリア・都道府県 又は 選択不動産の沿線・線の条件
      const queryPrefectureOrLine = [];
      if (selectedRealEstate.prefecture) {
        queryPrefectureOrLine.push(
          this.$$createMatchQuery(
            "user.data.buyingNeeds.area.prefecture.value",
            selectedRealEstate.prefecture.value
          )
        );
      }
      if (selectedRealEstate.transports.length > 0) {
        queryPrefectureOrLine.push(
          this.$$createTermsQuery(
            "user.data.buyingNeeds.Line.line.line.line_cd",
            selectedRealEstate.transports.map((item) => item.line.line.line_cd)
          )
        );
      }
      subQueryMust.push({
        bool: {
          should: queryPrefectureOrLine,
        },
      });

      // 選択不動産のエリア・市区町村の条件
      if (selectedRealEstate.city) {
        const query1 = {
          bool: {
            must_not: [
              this.$$createExistsQuery(
                "user.data.buyingNeeds.area.cities.cityCode"
              ),
            ],
          },
        };
        const query2 = this.$$createMatchQuery(
          "user.data.buyingNeeds.area.cities.cityCode",
          selectedRealEstate.city.cityCode
        );
        subQueryShould.push(query1, query2);
      }

      // 選択不動産の沿線・駅の条件
      if (selectedRealEstate.transports.length > 0) {
        const query1 = {
          bool: {
            must_not: [
              this.$$createExistsQuery(
                "user.data.buyingNeeds.Line.stations.station.station_cd"
              ),
            ],
          },
        };
        const query2 = this.$$createTermsQuery(
          "user.data.buyingNeeds.Line.stations.station.station_cd",
          selectedRealEstate.transports.map(
            (item) => item.station.station.station_cd
          )
        );
        subQueryShould.push(query1, query2);
      }

      // 選択不動産の価格の条件
      if (selectedRealEstate.price) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.askingPrice.from.value",
            { lte: selectedRealEstate.price }
          ),
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.askingPrice.to.value",
            { gte: selectedRealEstate.price }
          )
        );
      }

      // 選択不動産の表面利回りの条件
      if (selectedRealEstate.couponYieldRate) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.couponYieldRate.from.value",
            { lte: selectedRealEstate.couponYieldRate }
          ),
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.couponYieldRate.to.value",
            { gte: selectedRealEstate.couponYieldRate }
          )
        );
      }

      // 選択不動産の築年数の条件
      if (
        selectedRealEstate.constructionDate &&
        selectedRealEstate.constructionDate.year &&
        selectedRealEstate.constructionDate.month
      ) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.ageOfBuilding.from.value",
            {
              lte: this.$$realEstate$calcYearOfConstruction(
                selectedRealEstate.constructionDate
              ),
            }
          ),
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.ageOfBuilding.to.value",
            {
              gte: this.$$realEstate$calcYearOfConstruction(
                selectedRealEstate.constructionDate
              ),
            }
          )
        );
      }

      // 選択不動産の最寄り駅までの徒歩分数の条件
      if (selectedRealEstate.transports.length > 0) {
        subQueryMust.push(
          this.$$createWithNullQuery(
            "range",
            "user.data.buyingNeeds.transport.elapsedTime.value",
            {
              gte: Math.min.apply(
                null,
                selectedRealEstate.transports.map((item) => item.elapsedTime)
              ),
            }
          )
        );
      }

      if (
        subQueryMust.length > 0 ||
        subQueryMustNot.length > 0 ||
        subQueryShould.length > 0
      ) {
        let buyingNeedsQuery = {
          bool: {
            should: [],
          },
        };

        // 買いニーズ条件を作成する
        buyingNeedsQuery.bool.should.push(
          this.$$createNestedQuery(
            "user.data.buyingNeeds",
            subQueryMust,
            subQueryMustNot,
            subQueryShould
          )
        );

        // 「未登録の投資家を除く」にチェックが付いていない場合、絞り込み検索の条件にかかわらず検索結果に未登録ユーザーも含める
        if (!this.filterByRealEstate.otherInfo.isExcludeUnregisteredInvestors) {
          buyingNeedsQuery.bool.should.push(
            this.$$createMatchQuery("user.data.countOfBuyingNeeds", 0)
          );
        }

        filter.bool.must.push(buyingNeedsQuery);
      }
    },
    // 投資家条件のフィルタを作成
    createFilterInvestorInfo: function (searchConditions, filter) {
      // 会員区分の条件
      if (searchConditions.memberships.length > 0) {
        filter.bool.must.push(
          this.$$createTermsQuery(
            "user.data.membership.value",
            searchConditions.memberships
          )
        );
      }

      // スコア（From）の条件
      if (
        searchConditions.score.from &&
        searchConditions.score.from !== kubuns.filters.score.from[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestScore.score", {
            gte: searchConditions.score.from,
          })
        );
      }
      // スコア（To）の条件
      if (
        searchConditions.score.to &&
        searchConditions.score.to !== kubuns.filters.score.to[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestScore.score", {
            lte: searchConditions.score.to,
          })
        );
      }
      // スコア350以下の投資家を除くの条件
      if (searchConditions.isExcludeScoreBelow350) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestScore.score", {
            gt: 350,
          })
        );
      }

      // 収入（From）の条件
      if (
        searchConditions.income.from &&
        searchConditions.income.from !== kubuns.filters.income.from[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestPL.sum", {
            gte: searchConditions.income.from,
          })
        );
      }
      // 収入（To）の条件
      if (
        searchConditions.income.to &&
        searchConditions.income.to !== kubuns.filters.income.to[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestPL.sum", {
            lte: searchConditions.income.to,
          })
        );
      }

      // 純資産（From）の条件
      if (
        searchConditions.netAssets.from &&
        searchConditions.netAssets.from !== kubuns.filters.price.from[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestBS.netAssets.sum", {
            gte: searchConditions.netAssets.from,
          })
        );
      }
      // 純資産（To）の条件
      if (
        searchConditions.netAssets.to &&
        searchConditions.netAssets.to !== kubuns.filters.price.to[0].value
      ) {
        filter.bool.must.push(
          this.$$createRangeQuery("user.data.latestBS.netAssets.sum", {
            lte: searchConditions.netAssets.to,
          })
        );
      }

      // 職種・業種の検索条件がORで検索する
      let subQueryBusinessesOccupations = {
        bool: {
          should: [],
          must_not: [],
        },
      };

      // 業種の条件
      if (searchConditions.businesses.length > 0) {
        subQueryBusinessesOccupations.bool.should.push(
          this.$$createTermsQuery(
            "user.data.profile.currentOfCareer.categoryOfBusiness.value",
            searchConditions.businesses.map((item) => item.value)
          )
        );
      }
      // 不動産関係の業種を除くの条件
      if (searchConditions.isExcludeBusinessRealEstate) {
        subQueryBusinessesOccupations.bool.must_not.push(
          this.$$createTermsQuery(
            "user.data.profile.currentOfCareer.categoryOfBusiness.value",
            kubuns.categoryOfBusinesses
              .filter((item) =>
                kubuns.getCategoryOfBusinessRealEstateValues.includes(
                  item.value
                )
              )
              .map((item) => item.value)
          )
        );
      }

      // 職種の条件
      if (searchConditions.occupations.length > 0) {
        subQueryBusinessesOccupations.bool.should.push(
          this.$$createTermsQuery(
            "user.data.profile.currentOfCareer.typeOfOccupation.value",
            searchConditions.occupations.map((item) => item.value)
          )
        );
      }
      // 不動産関係の職種を除くの条件
      if (searchConditions.isExcludeOccupationRealEstate) {
        subQueryBusinessesOccupations.bool.must_not.push(
          this.$$createTermsQuery(
            "user.data.profile.currentOfCareer.typeOfOccupation.value",
            kubuns.typeOfOccupations
              .filter((item) =>
                kubuns.getTypeOfOccupationRealEstateValues.includes(item.value)
              )
              .map((item) => item.value)
          )
        );
      }

      filter.bool.must.push(subQueryBusinessesOccupations);

      return filter;
    },
    // その他絞り込みのフィルタを作成
    createFilterOtherInfo: function (searchConditions, filter) {
      // 未登録の投資家を除くの条件
      if (searchConditions.isExcludeUnregisteredInvestors) {
        filter.bool.must_not.push(
          this.$$createMatchQuery("user.data.countOfBuyingNeeds", 0)
        );
      }

      // 提案希望コメントが未登録の投資家を除くの条件
      if (searchConditions.isExcludeInvestorsWithNoComments) {
        let subQueryMust = [];
        let buyingNeedsQuery = {
          bool: {
            should: [],
          },
        };

        subQueryMust.push(
          this.$$createNotEmptyStringQuery("user.data.buyingNeeds.comment")
        );

        // 買いニーズ条件を作成する
        buyingNeedsQuery.bool.should.push(
          this.$$createNestedQuery(
            "user.data.buyingNeeds",
            subQueryMust,
            [],
            []
          )
        );

        filter.bool.must.push(buyingNeedsQuery);
      }

      // コメントフリーワードで検索の条件
      if (searchConditions.commentKeywords.length > 0) {
        let subQueryShould = [];
        let buyingNeedsQuery = {
          bool: {
            should: [],
          },
        };

        searchConditions.commentKeywords.forEach((keyword) => {
          subQueryShould.push(
            this.$$createContainSubstringQuery(
              "user.data.buyingNeeds.comment",
              keyword
            ),
            this.$$createContainSubstringQuery(
              "user.data.buyingNeeds.comment",
              keyword,
              false
            )
          );
        });

        // 買いニーズ条件を作成する
        buyingNeedsQuery.bool.should.push(
          this.$$createNestedQuery(
            "user.data.buyingNeeds",
            [],
            [],
            subQueryShould
          )
        );

        filter.bool.must.push(buyingNeedsQuery);
      }

      // 指定期間以内でオファー済みの投資家を除くの条件
      const formatDate = "YYYY-MM-DD";
      let timeRange = null;
      if (searchConditions.excludeOfferedPeriod !== 0) {
        switch (searchConditions.excludeOfferedPeriod) {
          case kubuns.filters.excludeOfferedPeriod[1].value:
            timeRange = moment().subtract(1, "day").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[2].value:
            timeRange = moment().subtract(7, "day").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[3].value:
            timeRange = moment().subtract(14, "day").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[4].value:
            timeRange = moment().subtract(1, "months").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[5].value:
            timeRange = moment().subtract(3, "months").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[6].value:
            timeRange = moment().subtract(6, "months").format(formatDate);
            break;
          case kubuns.filters.excludeOfferedPeriod[7].value:
            timeRange = moment().subtract(1, "years").format(formatDate);
            break;
        }
      }
      if (timeRange) {
        filter.bool.must_not.push(
          this.$$createNestedQuery("realtor.realtors", [
            this.$$createExistsQuery("realtor"),
            this.$$createRangeQuery("realtor.realtors.latestSendDt.realtor", {
              gte: timeRange,
            }),
            this.$$createMatchQuery(
              "realtor.realtors.realtor",
              this.me.user.id
            ),
          ])
        );
      }

      return filter;
    },
    toggleAccordion(isOpen) {
      this.$emit("toggleAccordion", isOpen);
    },
  },
  asyncComputed: {
    // 条件が保存した市区町村を表示
    async savedFilterCities() {
      if (
        !this.savedFilter ||
        !this.savedFilter.data.buyingNeeds ||
        !this.savedFilter.data.buyingNeeds.area.prefecture ||
        !this.savedFilter.data.buyingNeeds.area.cities
      )
        return "";

      let result = [];
      for (let cityCode of this.savedFilter.data.buyingNeeds.area.cities) {
        const city = await this.$$city$load(
          this.savedFilter.data.buyingNeeds.area.prefecture,
          cityCode
        );
        result.push(city.cityName);
      }
      return result.join("、");
    },
    // 条件が保存した沿線を表示
    async savedFilterLine() {
      if (
        !this.savedFilter ||
        !this.savedFilter.data.buyingNeeds ||
        !this.savedFilter.data.buyingNeeds.Line.line
      )
        return "";

      const result = await this.$$line$load(
        this.savedFilter.data.buyingNeeds.Line.line
      );
      return result.line_name;
    },
    // 条件が保存した駅を表示
    async savedFilterStations() {
      if (
        !this.savedFilter ||
        !this.savedFilter.data.buyingNeeds ||
        !this.savedFilter.data.buyingNeeds.Line.stations
      )
        return "";

      let result = [];

      for (let stationCode of this.savedFilter.data.buyingNeeds.Line.stations) {
        const station = await this.$$station$load(stationCode);
        result.push(station.station_name);
      }
      return result.join("、");
    },
  },
  computed: {
    // ツールチップの文言を表示
    labelTooltipProposal() {
      return `無料会員が閲覧できるメッセージは、月${RealtorType.type.user.exclusiveMessageCount}件のみお送りできる特別なメッセージ「エクスクルーシブ提案」のみになります。提案の際、ご注意ください。`;
    },
    // 条件が保存した買いニーズ登録を表示
    savedFilterBuyingneedsRegister() {
      if (!this.savedFilter || !this.savedFilter.data.investorInfo) return "";

      let result = [];
      if (this.savedFilter.data.otherInfo.isExcludeUnregisteredInvestors)
        result.push("未登録の投資家を除く");
      if (this.savedFilter.data.otherInfo.isExcludeInvestorsWithNoComments)
        result.push("提案希望コメントが未登録の投資家を除く");
      return result.join("、");
    },
  },
  mounted() {
    this.init();

    this.changeRequireLabel();

    this.addBlankOptionToSelect(".filter--couponYieldRate");
    this.addBlankOptionToSelect(".filter--askingPrice");
    this.addBlankOptionToSelect(".filter--ageOfBuilding");
    this.addBlankOptionToSelect(".filter--transport");
  },
  watch: {
    activeTab: function () {
      this.isSearched = false;
    },
    filterByRealEstate: {
      handler() {
        this.isSearched = false;
      },
      deep: true,
    },
    filterByBuyingNeeds: {
      handler() {
        this.isSearched = false;
      },
      deep: true,
    },
    "filterByRealEstate.selectedRealEstate": function () {
      if (this.filterByRealEstate.selectedRealEstate) {
        this.checkValidateRequireItemSelectRealEstate(
          ".filter--selectRealEstate",
          true
        );
      }
    },
    "filterByBuyingNeeds.buyingNeeds.area.prefecture": function () {
      this.checkValidateRequireItemAreaLine(".areaField", true, 0);
    },
    "filterByBuyingNeeds.buyingNeeds.Line.prefecture": function () {
      this.checkValidateRequireItemAreaLine(".stationField", true, 0);
    },
    "filterByBuyingNeeds.buyingNeeds.Line.line": function () {
      this.checkValidateRequireItemAreaLine(".stationField", true, 1);
    },
    "filterByBuyingNeeds.buyingNeeds.realEstateTypes": function () {
      this.checkValidateRequireItem(".filter--realEstateTypes", true);
    },
    "filterByBuyingNeeds.buyingNeeds.buildingTypes": function () {
      this.checkValidateRequireItem(".filter--buildingTypes", true);
    },
    "filterByBuyingNeeds.buyingNeeds.couponYieldRate.from": function () {
      this.checkValidateRequireItem(".filter--couponYieldRate", true);
    },
    "filterByBuyingNeeds.buyingNeeds.couponYieldRate.to": function () {
      this.checkValidateRequireItem(".filter--couponYieldRate", true);
    },
    "filterByBuyingNeeds.buyingNeeds.askingPrice.from": function () {
      this.checkValidateRequireItem(".filter--askingPrice", true);
    },
    "filterByBuyingNeeds.buyingNeeds.askingPrice.to": function () {
      this.checkValidateRequireItem(".filter--askingPrice", true);
    },
    "filterByBuyingNeeds.buyingNeeds.ageOfBuilding.from": function () {
      this.checkValidateRequireItem(".filter--ageOfBuilding", true);
    },
    "filterByBuyingNeeds.buyingNeeds.ageOfBuilding.to": function () {
      this.checkValidateRequireItem(".filter--ageOfBuilding", true);
    },
    "filterByBuyingNeeds.buyingNeeds.transport.elapsedTime": function () {
      this.checkValidateRequireItem(".filter--transport", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

/deep/ .multiselect {
  width: 455px;

  @include sp {
    width: 280px;
  }

  &__tags {
    border-radius: 0;
  }
  &__tag {
    background: #00997e;
    &-icon {
      &:hover {
        background-color: #2e7a68;
      }
    }
  }
  &__element {
    color: #333333;
  }
  &__option {
    &--highlight {
      background: #00997e;
      color: #ffffff;
      &::after {
        background: #00997e;
        color: #ffffff;
      }
      &.multiselect__option--selected {
        background: #e9e9e9;
        color: #777777;
        &::after {
          background: #e9e9e9;
          color: #777777;
        }
      }
    }
    &--selected {
      background: #ebf4f2;
      color: #00997e;
      &::after {
        background: #ebf4f2;
        color: #00997e;
      }
    }
  }
}

/deep/ .taginput {
  .tag {
    background-color: #00997e;
    color: #ffffff;
    border-radius: 5px;
    .delete {
      border-radius: 5px;
      background-color: #00997e;
      &:hover {
        background-color: rgba(10, 10, 10, 0.2);
        &::after {
          background-color: #ffffff;
        }
        &::before {
          background-color: #ffffff;
        }
      }
      &::after {
        width: 1px;
        background-color: #35745a;
      }
      &::before {
        height: 1px;
        background-color: #35745a;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
}

.conditions {
  background-color: #f1f6fd;
  padding: 16px 32px 16px 16px;
  position: relative;
  width: 100%;

  @include sp {
    height: auto;
    padding: 16px;
  }

  &--container {
    display: flex;

    @include sp {
      flex-flow: row wrap;
    }
  }

  &--item {
    margin: 0 10px;
    line-height: 18px;

    &:first-child {
      margin-left: 0;
      width: 180px;
    }

    &:not(:first-child) {
      min-width: calc((100% - 180px) / 4 - 20px);
    }

    &:last-child {
      margin-right: 0;
    }

    @include sp {
      margin: 0;
      width: 100%;
    }
  }

  &--header {
    color: $primary;
    font-size: 16px;
    margin-bottom: 12px;
  }

  &--row {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &--title {
    color: $snackbar-color;
    font-size: 14px;
  }

  &--text {
    color: $snackbar-color;
    font-size: 14px;
    font-weight: bold;
  }

  &--button {
    margin-top: 20px;
    text-align: center;
    width: 100%;

    @include sp {
      .button {
        width: 100%;
      }
    }
  }

  &--delete {
    height: 17px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 15px;

    .button {
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0;
      width: 100%;

      &:focus {
        border: 0;
        outline: none !important;
      }
    }
  }
}

/deep/ .z-editAccordion {
  .z-editAccordion {
    &__head {
      border-bottom: none;
      &__title {
        font-size: 16px;
      }
    }

    &__body {
      padding: 28px 40px;

      @include sp {
        padding: 16px 16px;
      }
    }
  }

  .tabs.is-medium {
    font-size: 24px;
    margin: 0 0 15px;

    @include sp {
      font-size: 18px;
    }

    .is-active {
      font-weight: bold;

      a {
        border-bottom-width: 2px;
      }
    }

    ul {
      border-bottom: none;

      li {
        a {
          margin: 0;
          padding: 10px 8px;
          white-space: pre-line;

          @include sp {
            padding: 8px 8px;
          }
        }

        &:first-child {
          a {
            padding-left: 0;
          }
        }

        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }
    }
  }

  .tab-content {
    padding: 15px 0 0;
  }

  &__body {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.menuBox {
  border: 1px solid $input-border-color;
  border-radius: 8px;

  &--errorText {
    color: $red;
    font-size: 16px;
  }

  .z-editAccordion {
    margin-bottom: 0;
  }
}

.menu {
  padding: 15px;
}

.filter {
  .column {
    padding: 15px;
  }

  /deep/ .z-areaLineFieldSet {
    margin-bottom: 0;
  }

  /deep/ .z-field {
    margin-bottom: 0;
  }

  /deep/ .z-field__info__main__required {
    &.is-required {
      background-color: #e1eaf7 !important;
      color: $primary;
      font-size: 12px;
      padding: 0 8px;
    }
  }

  &--title {
    border-bottom: 1px solid $input-border-color;
    font-size: 20px;
    margin-bottom: 24px;
    padding-bottom: 8px;
  }

  &--requirelabel {
    background-color: #e1eaf7 !important;
    border-radius: 7px;
    color: $primary;
    font-size: 12px;
    font-weight: bold;
    height: 14px;
    line-height: 14px;
    padding: 0 8px;
  }

  &--hint {
    font-size: 14px;
    margin-bottom: 24px;
  }

  &--selectRealEstate {
    &--wrapper {
      position: relative;
    }

    &--badge {
      position: absolute;
      right: 0;
      top: -5px;

      @include sp {
        bottom: -20px;
        top: unset;
      }
    }
  }

  &--transport {
    /deep/ .z-field__inputs {
      width: 455px;

      @include sp {
        width: 280px;
      }
    }
  }

  &--comment {
    /deep/ .z-field {
      margin-bottom: 0;
    }
  }

  &--membership {
    &__checkbox {
      /deep/ .z-field {
        margin-bottom: 14px;
      }

      @include sp {
        float: none;

        /deep/ .z-field {
          margin-bottom: 28px;
        }
      }
    }

    &__hint {
      display: flex;

      @include sp {
        display: none;
      }

      &__realestate {
        float: left;
        margin-left: 15px;
        margin-top: 44px;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
      margin-top: 2px;
    }

    &__number {
      color: $red;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
      margin-top: 2px;
    }

    &__help {
      height: 20px;
      margin-top: 2px;
      width: 20px;
    }
  }

  &--score {
    /deep/ .z-field {
      margin-bottom: 0;
    }

    /deep/ .z-field__inputs {
      .select.is-fullwidth {
        width: 200px;

        @include sp {
          width: 120px;
        }
      }

      select {
        width: 200px;

        @include sp {
          width: 120px;
        }
      }
    }
  }

  &--business,
  &--occupation {
    /deep/ .z-field {
      margin-bottom: 0;
    }
  }

  &--commentKeywords,
  &--excludeOfferedPeriod {
    /deep/ .z-field__inputs {
      width: 455px;

      @include sp {
        width: 280px;
      }
    }
  }

  &--button {
    display: flex;
    justify-content: space-between;
    padding: 0 calc((100% - 305px) / 2);

    @include sp {
      padding: 0;
    }

    &:first-child {
      margin-bottom: 20px;
    }

    &__search {
      height: 36px;
      min-width: 0;
      width: 305px;

      @include sp {
        width: 100%;
      }
    }

    &__reset {
      height: 36px;
      min-width: 0;
      width: 146px;

      @include sp {
        width: 48%;
      }
    }

    &__save {
      height: 36px;
      min-width: 0;
      width: 144px;

      @include sp {
        width: 47%;
      }
    }
  }
}

.selectRealEstate {
  position: relative;
  width: 400px;

  @include sp {
    width: 100%;
  }

  &--section {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: 8px;
    color: $snackbar-color;
    overflow: hidden;
    padding: 16px 16px 24px 16px;

    @include sp {
      padding: 12px 12px 18px 12px;
    }
  }

  &--image {
    float: left;
    height: 72px;
    width: 94px;
  }

  &--detail {
    float: right;
    font-size: 14px;
    margin-bottom: 8px;
    width: calc(100% - 110px);

    &__type {
      font-size: 14px;
      overflow: hidden;

      @include sp {
        font-size: 12px;
      }
    }

    &__highlight {
      font-size: 14px;
      font-weight: bold;
      margin: 6px 0;

      @include sp {
        margin: 4px 0;
      }
    }

    &__address {
      font-size: 14px;
    }

    @include sp {
      width: calc(100% - 136px);
    }
  }

  &--table {
    font-size: 14px;
    width: 100%;

    tr {
      td {
        padding: 2px;

        &:nth-child(odd) {
          color: #777777;
          width: 64px;

          @include sp {
            width: 56px;
          }
        }
      }
    }

    @include sp {
      font-size: 12px;
    }
  }

  &--link {
    color: $link;
    bottom: 6px;
    float: right;
    font-size: 14px;
    position: absolute;
    right: 16px;
    text-align: right;

    @include sp {
      bottom: 4px;
      font-size: 14px;
      right: 12px;
    }

    &:hover {
      color: $link;
    }
  }

  &--remove {
    background-color: $input-border-color;
    border-radius: 50%;
    height: 18px;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 18px;

    &:before,
    &:after {
      background-color: $tooltip-close-background-color;
      content: " ";
      height: 12px;
      left: 8px;
      position: absolute;
      top: 3px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
</style>
