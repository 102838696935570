<template>
  <div>
    <template v-if="!isLoading">
      <tile-box-overlay
        class="selectRealEstate"
        :class="{ 'is-message': mode === $$selectRealEstates$modes().message }"
        title="物件選択"
        ref="overlaySelectRealEstate"
      >
        <template v-slot:OverlayReceive>
          <div class="selectRealEstate--control">
            <template v-if="mode === $$selectRealEstates$modes().investor">
              <p class="selectRealEstate--control__text">
                条件に使用する不動産情報を選択してください。（1件のみ選択してください。）
              </p>
            </template>
            <template v-else-if="mode === $$selectRealEstates$modes().message">
              <p class="selectRealEstate--control__text">
                メッセージに添付する物件を選択してください。（厳選した１件のみの添付を推奨いたします。）
              </p>
            </template>
            <div class="selectRealEstate--control__sort">
              <b-field horizontal label="並び替え">
                <z-select
                  v-model="sortType"
                  :options="sortTypes"
                  @input="onSort"
                />
              </b-field>
            </div>
          </div>
          <template v-if="realEstates.length === 0">
            <div class="blankBox">
              <z-blank-box>提案可能な不動産が登録されていません。</z-blank-box>
            </div>
          </template>
          <template v-else>
            <div class="selectRealEstate--list">
              <div class="selectRealEstate--container">
                <template v-for="item in realEstates">
                  <div
                    class="selectRealEstate--item"
                    :class="{
                      'is-checked': isSelectingRealEstate(item._source.id),
                    }"
                    :key="item._source.id"
                  >
                    <div class="selectRealEstate--item--header">
                      <div class="z-checkbox">
                        <div class="block">
                          <b-checkbox
                            type="is-info"
                            :disabled="isDisable(item._source.id)"
                            :key="item._source.id"
                            :native-value="item._source.id"
                            :true-value="
                              $$selectRealEstates$isDefaultRealEstate(
                                item._source.id
                              )
                            "
                            name="select-realEstate"
                            v-model="selectingRealEstate"
                            @change.native="onSelect($event, item._source.id)"
                          />
                        </div>
                      </div>
                      <p class="selectRealEstate--item--title">
                        {{ item._source.data.name }}
                      </p>
                      <template
                        v-if="
                          item._source.data.saleSetting.publicType.value === 3
                        "
                      >
                        <div class="selectRealEstate--item--privateTag">
                          <p class="selectRealEstate--item--privateTag__label">
                            非公開
                          </p>
                          <b-icon
                            class="selectRealEstate--item--privateTag__icon"
                            icon="lock"
                            size="is-small"
                          ></b-icon>
                        </div>
                      </template>
                    </div>
                    <div class="selectRealEstate--item--body">
                      <section class="selectRealEstate--item--section">
                        <figure class="selectRealEstate--item--image">
                          <z-image
                            v-model="
                              $$selectRealEstates$image(item._source.data)[0]
                            "
                          />
                        </figure>
                        <div class="selectRealEstate--item--detail">
                          <div class="selectRealEstate--item--detail__type">
                            {{
                              $$selectRealEstates$realEstateType(
                                item._source.data.realEstateType
                              )
                            }}
                          </div>
                          <div
                            class="selectRealEstate--item--detail__highlight"
                          >
                            {{
                              $$selectRealEstates$priceCouponYieldRate(
                                item._source.data.price,
                                item._source.data.couponYieldRate
                              )
                            }}
                          </div>
                          <template v-if="bulma.isMobile">
                            <table class="selectRealEstate--item--table">
                              <tr>
                                <td>
                                  <b-tag>所在地</b-tag>
                                </td>
                                <td>
                                  {{
                                    $$selectRealEstates$location(
                                      item._source.data.prefecture,
                                      item._source.data.city
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b-tag>築年月</b-tag>
                                </td>
                                <td>
                                  {{
                                    $$selectRealEstates$constructionDate(
                                      item._source.data.constructionDate
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b-tag>建物種別</b-tag>
                                </td>
                                <td>
                                  {{
                                    $$selectRealEstates$buildingType(
                                      item._source.data.buildingType
                                    )
                                  }}
                                </td>
                              </tr>
                            </table>
                          </template>
                          <template v-else>
                            <table class="selectRealEstate--item--table">
                              <tr>
                                <td>
                                  <b-tag>所在地</b-tag>
                                </td>
                                <td colspan="3">
                                  {{
                                    $$selectRealEstates$location(
                                      item._source.data.prefecture,
                                      item._source.data.city
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b-tag>築年月</b-tag>
                                </td>
                                <td>
                                  {{
                                    $$selectRealEstates$constructionDate(
                                      item._source.data.constructionDate
                                    )
                                  }}
                                </td>
                                <td>
                                  <b-tag>建物種別</b-tag>
                                </td>
                                <td>
                                  {{
                                    $$selectRealEstates$buildingType(
                                      item._source.data.buildingType
                                    )
                                  }}
                                </td>
                              </tr>
                            </table>

                            <table class="selectRealEstate--item--table">
                              <tr>
                                <td>問い合せ</td>
                                <td>
                                  {{
                                    $$selectRealEstates$countMessage(
                                      item._source.message,
                                      "inquiry"
                                    )
                                  }}
                                </td>
                                <td>初回登録日</td>
                                <td>
                                  {{
                                    $$selectRealEstates$formatDate(
                                      item._source.data.registerDt
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>オファー</td>
                                <td>
                                  {{
                                    $$selectRealEstates$countMessage(
                                      item._source.message,
                                      "offer"
                                    )
                                  }}
                                </td>
                                <td>更新日</td>
                                <td>
                                  {{
                                    $$selectRealEstates$formatDate(
                                      item._source.data.updatedDt
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>返答数</td>
                                <td>
                                  {{
                                    $$selectRealEstates$countMessage(
                                      item._source.message,
                                      "replay"
                                    )
                                  }}
                                </td>
                                <td>更新予定日</td>
                                <td>
                                  {{
                                    $$selectRealEstates$formatDate(
                                      item._source.data.scheduledUpdateDate,
                                      true
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>閲覧人数</td>
                                <td>
                                  {{
                                    item._source.visitor
                                      ? `${item._source.visitor.count}件`
                                      : "0件"
                                  }}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </table>
                          </template>
                        </div>
                      </section>
                      <section
                        v-if="bulma.isMobile"
                        class="selectRealEstate--item--section"
                      >
                        <div class="selectRealEstate--item--info">
                          <table class="selectRealEstate--item--table">
                            <tr>
                              <td>問い合せ</td>
                              <td>
                                {{
                                  $$selectRealEstates$countMessage(
                                    item._source.message,
                                    "inquiry"
                                  )
                                }}
                              </td>
                              <td>初回登録日</td>
                              <td>
                                {{
                                  $$selectRealEstates$formatDate(
                                    item._source.data.registerDt
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>オファー</td>
                              <td>
                                {{
                                  $$selectRealEstates$countMessage(
                                    item._source.message,
                                    "offer"
                                  )
                                }}
                              </td>
                              <td>更新日</td>
                              <td>
                                {{
                                  $$selectRealEstates$formatDate(
                                    item._source.data.updatedDt
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>返答数</td>
                              <td>
                                {{
                                  $$selectRealEstates$countMessage(
                                    item._source.message,
                                    "replay"
                                  )
                                }}
                              </td>
                              <td>更新予定日</td>
                              <td>
                                {{
                                  $$selectRealEstates$formatDate(
                                    item._source.data.scheduledUpdateDate,
                                    false
                                  )
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>閲覧人数</td>
                              <td>
                                {{
                                  item._source.visitor
                                    ? `${item._source.visitor.count}件`
                                    : "0件"
                                }}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </table>
                        </div>
                      </section>
                      <a
                        class="selectRealEstate--item--link"
                        @click="
                          $$selectRealEstates$routerRealEstate(item._source.id)
                        "
                      >
                        詳しくみる
                      </a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="selectRealEstate--footer">
              <template v-if="mode === $$selectRealEstates$modes().message">
                <p class="selectRealEstate--state">
                  <span>添付件数</span>
                  <span class="selectRealEstate--state__selecting">{{
                    selectingRealEstate.length
                  }}</span
                  ><span class="selectRealEstate--state__capicity"
                    >件/{{ maxSelectingRealEstate }}件中</span
                  >
                </p>
                <p class="selectRealEstate--hint">
                  添付を1件に厳選いただくと、<template v-if="bulma.isMobile"
                    ><br /></template
                  >提案受け入れの確率が高くなります。
                </p>
              </template>
              <b-button type="is-primary" rounded @click="onSave">
                保存する
              </b-button>
            </div>
          </template>
        </template>
      </tile-box-overlay>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import * as RealtorModule from "@/modules/realtor/realestate";
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "modal-select-realEstate",
  mixins: [DisplayTextMixin],
  props: {
    mode: {
      type: Number, // 0: 絞り込み検索, 1: 添付不動産選択
      require: true,
    },
  },
  data: function () {
    return {
      query: {
        query: {
          match_all: {},
        },
        _source: [],
        sort: [],
        size: 10000,
      },
      sortTypes: [
        { value: 1, text: "新着順" },
        { value: 2, text: "古着順" },
      ],
      isLoading: true,
      sortType: 1,
      total: 0,
      realEstates: [],
      maxSelectingRealEstate: 3, // 添付不動産選択用
      selectingRealEstate: [],
    };
  },
  methods: {
    // データ表示
    async loadData() {
      try {
        this.isLoading = true;

        const orderType = this.sortType === 2 ? "asc" : "desc";
        this.query.sort = [
          {
            "data.registerDt": orderType,
          },
        ];

        const result = await RealtorModule.searchByOwner(
          this.me.user.company.id,
          null
        );
        this.total = result.total;
        this.realEstates = result.hits;
        this.onSort();

        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      }
    },
    // 物件選択オーバーレイを開く
    async open() {
      await this.loadData();

      this.selectingRealEstate = [];

      switch (this.mode) {
        case this.$$selectRealEstates$modes().investor: // 絞り込み検索
          if (this.$parent.filterByRealEstate.selectedRealEstate) {
            this.selectingRealEstate.push(
              this.$parent.filterByRealEstate.selectedRealEstate._source.id
            );
          }
          break;
        case this.$$selectRealEstates$modes().message: // 添付不動産選択
          if (this.$parent.selectedRealEstates.length > 0) {
            this.selectingRealEstate = this.$parent.selectedRealEstates.map(
              (item) => item._source.id
            );
          }
          break;
      }

      this.$refs.overlaySelectRealEstate.open();
    },
    // 物件をソート
    onSort() {
      const orderType = this.sortType === 2 ? "asc" : "desc";
      this.realEstates = _.orderBy(
        this.realEstates,
        ["_source.data.registerDt"],
        [orderType]
      );
    },
    // 物件を選択
    onSelect(event, id) {
      // 絞り込み検索
      if (
        this.mode === this.$$selectRealEstates$modes().investor &&
        event.target.checked
      ) {
        this.selectingRealEstate = [];
        this.selectingRealEstate.push(id);
      }
    },
    // 選択物件を保存
    onSave() {
      const filteredRealEstates = this.realEstates.filter((item) => {
        return this.selectingRealEstate.includes(item._source.id);
      });

      switch (this.mode) {
        case this.$$selectRealEstates$modes().investor: // 絞り込み検索
          this.$parent.filterByRealEstate.selectedRealEstate =
            filteredRealEstates[0];
          break;
        case this.$$selectRealEstates$modes().message: // 添付不動産選択
          this.$parent.selectedRealEstates = filteredRealEstates;
          break;
      }

      this.$refs.overlaySelectRealEstate.close();
    },
    // 無効になるかどうか
    isDisable(id) {
      return (
        this.$$selectRealEstates$isDefaultRealEstate(id) ||
        (this.selectingRealEstate.length >= this.maxSelectingRealEstate &&
          !this.selectingRealEstate.includes(id))
      );
    },
    // 物件を選択中かどうか
    isSelectingRealEstate(id) {
      return this.selectingRealEstate.includes(id);
    },
  },
  asyncComputed: {
    // 閲覧人数を表示
    // async getViews(id) {
    //   return await this.$$realtor$loadViews(id);
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.selectRealEstate {
  /deep/ .tag {
    border-radius: 0;
    font-size: 12px;
    font-weight: bold;
    color: #777777;
  }

  /deep/ .modal-card-head {
    padding: 24px 44px;

    @include sp {
      padding: 20px 16px;
    }

    &__subtitle {
      display: none;
    }
  }

  /deep/ .modal-card-body {
    overflow: hidden;
    padding: 28px calc((100% - 1200px) / 2);

    @include sp {
      overflow-y: auto;
      padding: 16px;
    }
  }

  .blankBox {
    height: calc(100vh - 300px);
  }

  &.is-message {
    .selectRealEstate--list {
      height: calc(100vh - 380px);
    }
  }

  &--control {
    font-size: 16px;
    margin-bottom: 24px;
    overflow: hidden;

    @include sp {
      margin-bottom: 20px;
    }

    &__text {
      float: left;
      line-height: 36px;

      @include sp {
        line-height: unset;
      }
    }

    &__sort {
      float: right;

      /deep/ .field-label {
        height: 36px;

        @include sp {
          height: 24px;
          margin-bottom: 0;
        }

        .label {
          width: 80px;
        }
      }

      /deep/ .field-body {
        height: 36px;
      }

      @include sp {
        margin-top: 16px;
        width: 100%;
      }
    }
  }

  &--list {
    height: calc(100vh - 316px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 24px;
    }

    &::-webkit-scrollbar-track {
      border: solid 8px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $modal-close-color;
      border: solid 8px transparent;
      border-radius: 12px;
    }

    @include sp {
      height: auto;
      overflow: none;
    }
  }

  &--container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &--item {
    background-color: $white;
    border: 2px solid $input-border-color;
    border-radius: 8px;
    color: $snackbar-color;
    height: 300px;
    margin-bottom: 24px;
    position: relative;
    width: 49%;
    min-width: 560px;

    &.is-checked {
      border: 2px solid $info;

      .selectRealEstate--item--header {
        background-color: #e1f6f2;
      }
    }

    @include sp {
      height: auto;
      width: 100%;
      min-width: 100%;
    }

    &--header {
      border-bottom: 2px solid #d8d8d8;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 48px;
      line-height: 48px;
      overflow: hidden;
      padding: 0 16px;

      .z-checkbox {
        float: left;

        .block {
          margin-top: 2px;
        }
      }

      @include sp {
        padding: 0 12px;
      }
    }

    &--body {
      overflow: hidden;
      padding: 16px 16px 0 16px;

      @include sp {
        padding: 12px 12px 24px 12px;
      }
    }

    &--section {
      overflow: hidden;
    }

    &--title {
      float: left;
      font-size: 18px;
      width: calc(100% - 100px);

      @include sp {
        font-size: 16px;
      }
    }

    &--privateTag {
      align-items: center;
      background-color: #dddddd;
      border-radius: 4px;
      display: flex;
      float: right;
      margin-top: 16px;
      height: 14px;
      line-height: 14px;
      width: 56px;

      &__label {
        color: #666666;
        font-size: 12px;
        line-height: 14px;
        padding-left: 4px;
      }

      &__icon {
        color: #666666;
        display: inline-block;
        font-size: 10px;
        height: 14px;
        padding-left: 1px;
        vertical-align: middle;
        width: 14px;
      }
    }

    &--image {
      float: left;
      width: 160px;

      img {
        object-fit: contain;
        width: 100%;
      }

      @include sp {
        height: 95px;
        width: 125px;
      }
    }

    &--detail {
      float: right;
      font-size: 14px;
      margin-bottom: 8px;
      width: calc(100% - 176px);

      &__type {
        font-size: 16px;
        overflow: hidden;

        @include sp {
          font-size: 14px;
        }
      }

      &__highlight {
        color: $red;
        font-size: 20px;
        font-weight: bold;
        margin: 0px 0 4px;

        @include sp {
          font-size: 16px;
        }
      }

      @include sp {
        width: calc(100% - 136px);
      }
    }

    &--info {
      float: right;
      font-size: 12px;
      padding-top: 4px;
      width: calc(100% - 186px);

      @include sp {
        border-top: 1px solid #d8d8d8;
        width: 100%;
      }
    }

    &--table {
      width: 100%;

      tr {
        td {
          padding: 2px;
          vertical-align: middle;

          &:nth-child(odd) {
            font-size: 12px;
            font-weight: bold;
            color: #777777;
            width: 75px;
          }
        }
      }
    }

    &--link {
      color: $link;
      bottom: 4px;
      float: right;
      font-size: 14px;
      position: absolute;
      right: 16px;
      text-align: right;

      @include sp {
        font-size: 14px;
        right: 12px;
      }

      &:hover {
        color: $link;
      }
    }
  }

  &--state {
    font-size: 16px;
    font-weight: bold;

    @include sp {
      font-size: 14px;
    }

    &__selecting {
      color: #0062d7;
      font-size: 24px;
      margin-left: 12px;

      @include sp {
        font-size: 20px;
      }
    }

    &__capicity {
      color: #0062d7;
      font-size: 14px;

      @include sp {
        font-size: 12px;
      }
    }
  }

  &--hint {
    color: $red;
    font-size: 14px;
    margin-bottom: 8px;

    @include sp {
      font-size: 12px;
    }
  }

  &--footer {
    padding: 8px 0;
    text-align: center;

    .button {
      height: 64px;
      width: 344px;

      @include sp {
        height: 48px;
        width: 100%;
      }
    }
  }
}
</style>
