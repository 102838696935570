<template>
  <div>
    <div class="box">
      <RealtorProfile :user="user" />
    </div>
    <UserProfileProfile :editable="editable" :user="user"> </UserProfileProfile>
    <hr />
    <UserProfilePr :editable="editable" :user="user"> </UserProfilePr>
    <!--
      <UserBasic :user="user"> </UserBasic>
      <hr />
      <UserCompany :user="user" :editable="false"></UserCompany>
    -->
  </div>
</template>

<script>
import RealtorProfile from "@/components/realtor/RealtorProfile";
//import UserBasic from "@/components/realtor/UserBasic";
import UserProfilePr from "@/components/realtor/UserProfilePr";
import UserProfileProfile from "@/components/realtor/UserProfileProfile";
// import UserProfile from "@/components/realtor/UserProfile";
//import UserCompany from "@/components/realtor/UserCompany";

export default {
  name: "investor-profile-detail",
  components: {
    //UserBasic: UserBasic,
    UserProfilePr: UserProfilePr,
    UserProfileProfile: UserProfileProfile,
    //UserCompany: UserCompany,
    RealtorProfile: RealtorProfile,
  },
  props: {
    user: {
      type: [Object],
      require: true,
    },
    editable: {
      type: [Boolean],
      default: true,
    },
  },
  data: function () {
    return {
      isLoading: true,
      //user: null,
    };
  },
  //computed: {
  //  realtorId() {
  //    if (this.$route.params) {
  //      return this.$route.params.id;
  //    } else {
  //      return null;
  //    }
  //  }
  //},
  //methods: {
  //  async init() {
  //    const loadingComponent = this.$loading.open();
  //    try {
  //      this.isLoading = true;
  //      this.user = await this.$$realtor$loadUser(this.realtorId);
  //      this.isLoading = false;
  //      return;
  //    } catch (error) {
  //      this.$$error$catchLoadError(error);
  //    } finally {
  //      loadingComponent.close();
  //    }
  //  }
  //},
  //beforeRouteUpdate(to, from, next) {
  //  next();
  //  this.init();
  //},
  //mounted() {
  //  this.init();
  //}
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.consultant-warp {
  margin: 0 10px;
  @include mobile {
    margin: 0;
  }
}

.consultant {
  &__image {
    height: 256px;
    width: 256px;
    background-color: $mintrich-blank;
  }
  &__details {
    height: 100%;
    width: 100%;
    background-color: $mintrich-blank;
  }
}

.company {
  &__details {
    height: 100%;
    width: 100px;
    background-color: $mintrich-blank;
  }
}
</style>
