<template>
  <div class="buyingNeedsField">
    <div class="is-small">
      <template v-if="value.area.isSelected">
        <div class="buyingNeedsField__title">エリア:</div>
        <div class="buyingNeedsField__content">
          {{ $$area(value.area) }}
        </div>
      </template>
      <template v-if="value.Line.isSelected">
        <div class="buyingNeedsField__title">沿線:</div>
        <div class="buyingNeedsField__content">
          {{ $$line(value.Line) }}
        </div>
      </template>
      <br />
      <div class="buyingNeedsField__title">希望物件価格:</div>
      <div class="buyingNeedsField__content">
        {{ $$priceFromTo(value.askingPrice) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">表面利回り:</div>
      <div class="buyingNeedsField__content">
        {{ $$yieldRateFromTo(value.couponYieldRate) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">物件種別:</div>
      <div class="buyingNeedsField__content">
        {{ $$types(value.realEstateTypes) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">建物種別:</div>
      <div class="buyingNeedsField__content">
        {{ $$types(value.buildingTypes) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">築年数:</div>
      <div class="buyingNeedsField__content">
        {{ $$ageOfBuildingFromTo(value.ageOfBuilding) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">最寄り駅までの徒歩分数:</div>
      <div class="buyingNeedsField__content">
        {{ value.transport.elapsedTime.text }}
      </div>
      <br />
      <div class="buyingNeedsField__title">自己資金・担保可能金額:</div>
      <div class="buyingNeedsField__content">
        {{ $$price$priceJPFormat(value.ownFund) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">コメント:</div>
      <br />
      <div class="buyingNeedsField__content">
        {{ value.comment }}
      </div>
    </div>
  </div>
</template>

<script>
import DisplayTextMixin from "./DisplayTextMixin";

export default {
  name: "InvestorBuyingNeeds",
  mixins: [DisplayTextMixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.buyingNeedsField {
  font-size: 12px;
  text-align: left;
  &__title {
    display: inline-block;
    padding-right: 0.5rem;
    @include mobile {
      width: 100%;
    }
  }
  &__content {
    color: gray;
    display: inline-block;
    @include lg {
      max-width: 80%;
    }
  }
  &__trancator {
    display: inline-block;
  }
}
</style>
