<template>
  <div class="searchFormLabel">
    <p class="searchFormLabel__title" v-if="filterLabel">現在の検索条件</p>
    <p class="searchFormLabel__description">{{ filterLabel }}</p>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SearchFormLabel",
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  asyncComputed: {
    async filterLabel() {
      const filter = [
        this.score,
        this.salePeriod,
        this.area,
        this.Line,
        this.realEstateTypes,
        this.buildingTypes,
        this.couponYieldRate,
        this.amount,
        this.ageOfBuilding,
        this.transport,
      ];
      return _.compact(filter).join(" / ");
    },
    area: {
      async get() {
        const { area } = this.filter;
        if (area && area.isSelected) {
          const { prefecture, cities } = area;

          let prefectureLabel = "";
          let cityLabel = "";

          const prefectureObj = await this.$$prefecture$load(prefecture);
          const cityObj = await Promise.all(
            cities.map((city) => this.$$city$load(prefecture, city))
          );

          if (prefectureObj) {
            prefectureLabel = prefectureObj.text || "";
          }
          if (cityObj) {
            cityLabel = cityObj.map((city) => city.cityName || "").join("、");
          }

          return `エリア ${prefectureLabel} ${cityLabel}`;
        }
        return "";
      },
      default() {
        return null;
      },
    },
    Line: {
      async get() {
        const { Line } = this.filter;
        if (Line && Line.isSelected) {
          const { prefecture, line, stations } = Line;

          let prefectureLabel = "";
          let lineLabel = "";
          let stationLabel = "";

          const prefectureObj = await this.$$prefecture$load(prefecture);
          const lineObj = await this.$$line$load(line);
          const stationObj = await Promise.all(
            stations.map((station) => this.$$station$load(station))
          );

          if (prefectureObj) {
            prefectureLabel = prefectureObj.text || "";
          }
          if (lineObj) {
            lineLabel = lineObj.line_name || "";
          }
          if (stationObj) {
            stationLabel = stationObj
              .map((station) => station.station_name || "")
              .join("、");
          }

          return `沿線 ${prefectureLabel} ${lineLabel} ${stationLabel}`;
        }
        return "";
      },
      default() {
        return null;
      },
    },
  },
  computed: {
    score() {
      const { score } = this.filter;
      return this.getSpanLabel(score, "score", "スコア");
    },
    salePeriod() {
      const { salePeriod } = this.filter;
      const { from, to } = salePeriod;
      if (from !== null || to !== null) {
        return `取引時期 ${from || "下限なし"}〜${to || "上限なし"}`;
      }
      return "";
    },
    realEstateTypes() {
      const { realEstateTypes } = this.filter;
      if (realEstateTypes && realEstateTypes.length > 0) {
        const labels = realEstateTypes.map(
          (item) => this.$$ks.realEstateType(item).text
        );
        return `物件種別 ${labels.join("、")}`;
      }
      return "";
    },
    buildingTypes() {
      const { buildingTypes } = this.filter;
      if (buildingTypes && buildingTypes.length > 0) {
        const labels = buildingTypes.map(
          (item) => this.$$ks.getTypeOfBuildingType(item).text
        );
        return `建物種別 ${labels.join("、")}`;
      }
      return "";
    },
    couponYieldRate() {
      const { couponYieldRate } = this.filter;
      return this.getSpanLabel(couponYieldRate, "yieldRate", "表面利回り", "%");
    },
    amount() {
      const { amount } = this.filter;
      return this.getSpanLabel(amount, "price", "価格", "円");
    },
    ageOfBuilding() {
      const { ageOfBuilding } = this.filter;
      return this.getSpanLabel(
        ageOfBuilding,
        "yearOfconstruction",
        "築年数",
        "年",
        "指定なし",
        "指定なし"
      );
    },
    transport() {
      const { transport } = this.filter;
      if (transport && transport.elapsedTime) {
        return `最寄り駅までの徒歩分数 ${
          this.$$ks.getFilterType(transport.elapsedTime, "elapsedTime").text
        }`;
      }
      return "";
    },
  },
  methods: {
    getSpanLabel(
      value,
      key,
      prefix,
      unit = "",
      upperLabel = "上限なし",
      lowerLabel = "下限なし"
    ) {
      if (!value || (value.from === null && value.to === null)) {
        return "";
      }

      const { from, to } = value;
      const fromText =
        this.$$ks.getFilterType(from, `${key}.from`).text || lowerLabel;
      const toText =
        this.$$ks.getFilterType(to, `${key}.to`).text || upperLabel;

      const fromLabel =
        !fromText || fromText === lowerLabel ? lowerLabel : fromText + unit;
      const toLabel =
        !toText || toText === upperLabel ? upperLabel : toText + unit;

      return `${prefix} ${fromLabel}〜${toLabel}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.searchFormLabel {
  margin-bottom: 20px;
  &__title {
    font-weight: bold;
  }
}
</style>
