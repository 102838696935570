<template>
  <div class="loanTransactionRanking">
    <div class="loanTransactionRanking__inner">
      <div class="loanTransactionRanking__holder">
        <template v-if="loanBankInfos.length">
          <ul class="loanTransactionRanking__list">
            <li v-for="info in loanBankInfos" :key="info.bank">
              <a @click="selectBank(info)">
                <BankRankingInfo
                  :info="info"
                  :selected="info.bank === currentBank"
                />
              </a>
            </li>
          </ul>
        </template>
        <template v-else>
          <p class="loanTransactionRanking__list--empty">
            対象の取引情報がありません
          </p>
        </template>

        <div
          class="loanTransactionRanking__more"
          v-if="maxCount > loanBankInfos.length && !isLoading"
        >
          <MoreButton
            :label="loanBankInfos.length + 1 + '位以降をみる'"
            @click="loadMoreInfo()"
          />
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
    </div>
  </div>
</template>

<script>
import BankRankingInfo from "./BankRankingInfo";
import * as marketTrend from "@/modules/chart/marketTrend";

export default {
  name: "LoanTransactionRanking",
  components: {
    BankRankingInfo,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    pageIndex: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      loadNumber: 50,
      loanBankInfos: [],
      internalPageIndex: this.pageIndex,
      maxCount: 0,
      isFirst: true,
    };
  },
  methods: {
    selectBank(info) {
      this.$emit("input", info);
    },
    onClickMore() {
      this.loadMoreInfo();
    },
    async loadMoreInfo(isRefresh) {
      this.isLoading = true;
      try {
        if (isRefresh) {
          if (!this.isFirst) {
            this.internalPageIndex = 0;
          }
          this.loanBankInfos.splice(-this.loanBankInfos.length);
          // this.$emit("input", null);
        } else {
          this.internalPageIndex++;
        }
        // const result = await this.$$MarketTrendChart$loadLoanBankRanking({
        //   marketTrendFilter: this.filter,
        //   pagination: this.isFirst ? 0 : this.internalPageIndex,
        //   getNumber: this.getLoadInfoNum()
        // });
        // const result = [];
        const result = await marketTrend.searchBankRank({
          marketTrendFilter: this.filter,
          pagination: this.isFirst ? 0 : this.internalPageIndex,
          getNumber: this.getLoadInfoNum(),
        });

        // console.log(result);
        const { /* countOfData, pagination,*/ maxCount, data } = result;
        this.loanBankInfos = this.loanBankInfos.concat(data);
        // this.internalPageIndex = pagination;
        this.maxCount = maxCount;
        if (isRefresh && this.loanBankInfos.length) {
          if (this.value) {
            this.selectBank(this.value);
          } else {
            this.selectBank(this.loanBankInfos[0]);
          }
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        this.isLoading = false;
      }
    },
    getLoadInfoNum() {
      // historyback対応
      return (
        (this.internalPageIndex + 1) * this.loadNumber -
        this.loanBankInfos.length
      );
    },
  },
  computed: {
    currentBank() {
      return this.value.bank;
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        if (newVal !== null) {
          this.loadMoreInfo(true);
        }
      },
      deep: true,
      immediate: true,
    },
    internalPageIndex() {
      this.$emit("update:pageIndex", this.internalPageIndex);
    },
    pageIndex() {
      this.internalPageIndex = this.pageIndex;
    },
  },
  mounted() {
    this.isFirst = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.loanTransactionRanking {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -14px;
    transform: translateY(-50%) scale3d(1, -1.2, 0.5) rotate(45deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); // for IE11
    width: 30px;
    height: 30px;
    border-bottom: solid 1px #e5e5e5;
    border-left: solid 1px #e5e5e5;
    background-color: #fff;
  }
  &__inner {
    padding-right: 50px;
    background-color: #f8f8f8;
    border: solid 1px #e5e5e5;
    border-radius: 8px;
    overflow: hidden;
  }
  &__holder {
    position: relative;
    height: 505px;
    overflow-y: auto;
  }
  &__list {
    & > li {
      border-bottom: solid 1px #e5e5e5;
    }
    &--empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #777777;
    }
  }
  &__more {
    padding: 20px 0;
    text-align: center;
  }
}
/deep/ .bankRankingInfo {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.1);
    border: solid 2px $primary;
    border-radius: 8px;
    opacity: 0;
    transition: 0.1s;
  }
  &.is-selected {
    color: $primary;
    &::after {
      opacity: 1;
    }
  }
}
</style>
