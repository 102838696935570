var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-view'),(!_vm.isLoading)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":{
            name: 'admin-setting-realtors-detail',
            params: { cid: 'new' },
          }}},[_vm._v("新規追加")]),_c('button',{staticClass:"button is-danger is-small",attrs:{"disabled":!_vm.checkedRealtors.length},on:{"click":_vm.accept}},[_vm._v(" 承認 ")])],1),_c('hr'),_c('b-table',{attrs:{"data":_vm.realtors,"checkable":"","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort":"company.examination.data.status","custom-row-key":"company.id","checked-rows":_vm.checkedRealtors,"striped":true,"hoverable":true,"narrowed":true,"mobile-cards":true},on:{"update:checkedRows":function($event){_vm.checkedRealtors=$event},"update:checked-rows":function($event){_vm.checkedRealtors=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"company.id","label":"詳細","width":"20","sortable":""}},[_c('router-link',{staticClass:"button is-small",attrs:{"to":{
                name: 'admin-setting-realtors-detail',
                params: { cid: props.row.company.id },
              }}},[_vm._v("詳細")])],1),_c('b-table-column',{attrs:{"field":"company.examination.data.status","label":"認証ステータス","width":"140","sortable":""}},[_c('z-tag',{attrs:{"options":_vm.$$ks.realtor.application.company.statuses},model:{value:(props.row.company.examination.data.status),callback:function ($$v) {_vm.$set(props.row.company.examination.data, "status", $$v)},expression:"props.row.company.examination.data.status"}})],1),_c('b-table-column',{attrs:{"field":"company.data.name","label":"企業名","sortable":""}},[_vm._v(" "+_vm._s(props.row.company.data.name)+" ")]),_c('b-table-column',{attrs:{"field":"company.data.phoneNumber","label":"電話番号","sortable":""}},[_vm._v(" "+_vm._s(props.row.company.data.phoneNumber)+" ")]),_c('b-table-column',{attrs:{"field":"company.data.registerDt.toDate()","label":"登録日時","sortable":""}},[_vm._v(" "+_vm._s(_vm.$$calender$dateFormat(props.row.company.data.registerDt))),_c('br'),_vm._v(" "+_vm._s(props.row.company.id)),_c('br'),(props.row.company.data.isActivate)?[_c('div',{staticClass:"tag is-danger"},[_vm._v("ACTIVE")])]:[_c('div',{staticClass:"tag"},[_vm._v("NON ACTIVE")])]],2)]}}],null,false,930741747)},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing here.")])])])])],2)],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',[_vm._v("専門家(不動産屋)一覧")])])
}]

export { render, staticRenderFns }