import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import Raven from "raven-js";

import retryPromise from "@/plugins/RetryPromise";

console.log("init firebase");
const config = {
  apiKey: process.env.VUE_APP_GCP_API_KEY,
  authDomain: process.env.VUE_APP_GCP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_GCP_DATABASE_URL,
  projectId: process.env.VUE_APP_GCP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_GCP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_GCP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_GCP_API_ID,
};

const fi = firebase.initializeApp(config);
fi.auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .catch((error) => {
    console.error(error.message);
    Raven.captureException(error);
  });

const db = fi.firestore();
// db.settings({ timestampsInSnapshots: true });

const database = firebase.database;

const storage = firebase.storage().ref();

// const functions = fi.functions("asia-northeast1");

// const functions = (name, params) => {
//   return $RetryPromise.then(() => {
//     return fi.functions("asia-northeast1").httpsCallable(name)(params);
//   });
// };

const functions = (name, params, timeout = 60000, retryCount = 3) => {
  const fiFunctions = fi.functions("asia-northeast1");
  if (process.env.VUE_APP_EMULATOR) {
    console.log("User emulator of functions");
    fiFunctions.useFunctionsEmulator("http://localhost:5201");
  }

  // console.log(timeout);
  return retryPromise.then(
    () =>
      fiFunctions.httpsCallable(name, {
        timeout: timeout + 10000,
      })(params),
    retryCount
  );
};

const auth = firebase.auth;

const createTimestamp = (seconds, nanoseconds) => {
  return new firebase.firestore.Timestamp(seconds, nanoseconds);
};

const serverTimestamp = () => {
  return firebase.firestore.Timestamp.now();
  //return firebase.firestore.FieldValue.serverTimestamp();
};

const serverTimestampFromDate = (date) => {
  return firebase.firestore.Timestamp.fromDate(date);
};

const now = () => {
  return firebase.firestore.Timestamp.now();
};

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

const currentAuth = () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((auth) => {
      resolve(auth || false);
    });
  });
};
const toDate = (date) => {
  if (date) {
    return date.toDate();
  } else {
    return null;
  }
};
export default {
  database, //使っては駄目
  db,
  functions,
  auth,
  storage,
  createTimestamp,
  serverTimestamp,
  serverTimestampFromDate,
  now,
  currentAuth,
  // logginedAuth,
  toDate,
  GoogleAuthProvider,
};
