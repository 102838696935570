<template>
  <section class="section">
    <div class="animated fadeIn">
      <div class="columns is-centered is-gapless">
        <div class="column is-6">
          <b-message
            title="確認メールを送信しました"
            class="is-message-box"
            type="is-info"
            :closable="false"
          >
            <div class="has-text-centered">
              <h1>メールアドレスに確認メールを送信しました。</h1>
              <h1>会員登録(仮)が完了しました。</h1>
              <br />
              <h2>{{ email }}</h2>
            </div>
          </b-message>
          <button class="button is-fullwidth" @click="signUp">
            確認メールの再送信
          </button>
          <div class="has-text-centered">
            <a @click="cancel">会員登録をやり直す</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SentMail from "@/mixins/admin/auth/SentMail";

export default {
  name: "admin-auth-SentMail",
  mixins: [SentMail],
  data() {
    return {
      caption: "確認メールの再送信",
      email: this.$route.query.email,
    };
  },
  beforeMount() {
    if (!this.$route.query.email) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    signUp: function () {
      const self = this;
      this.sendMail(this.email)
        .then(function () {
          self.$$toast$openSuccess("確認メールが再送信されました");
          // self.$toast.open({
          //   duration: 3000,
          //   message: "確認メールが再送信されました",
          //   position: "is-top",
          //   type: "is-success"
          // });
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("確認メールの送信に失敗しました");
          // self.$toast.open({
          //   duration: 3000,
          //   message: "確認メールの送信に失敗しました",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        });
    },
  },
};
</script>
