<template>
  <div class="editWorkExperience">
    <div v-if="!isLoading">
      <section class="edit">
        <p class="edit__explanation">
          ポートフォリオを充実させ、よりご希望に沿った提案を受けることができます。
        </p>

        <h2 class="edit__title">資産情報入力</h2>
        <FormAssets ref="formAssets" v-model="eUser" />

        <div class="edit__foot">
          <b-button
            :disabled="$$validator$invalid"
            @click="onSubmit()"
            type="is-primary"
            rounded
            class="z-button"
            >保存する</b-button
          >
        </div>
      </section>
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
  </div>
</template>

<script>
import FormAssets from "../../common/FormAssets";

export default {
  name: "EditAssets",
  // props: {
  // },
  data() {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    FormAssets: FormAssets,
  },
  // computed: {
  // },
  methods: {
    onSubmit() {
      this.save();
    },
    async save() {
      if (!this.$refs.formAssets.isActiveSecurity)
        this.eUser.data.assets.securities = [];
      if (!this.$refs.formAssets.isActiveInsurance)
        this.eUser.data.assets.insurances = [];

      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);
        this.$$toast$openSuccess();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
        this.$emit("saved");
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
