<template>
  <div class="form-wrap">
    <div v-if="!isLoading">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-three-fifths is-offset-one-fifth">
            <div class="box realotr-auth-application-detail">
              <h3 class="has-text-yellow message__main-title">お問い合せ</h3>
              <h2 class="box__form-title">入力フォーム</h2>
              <z-input
                label="会社名"
                v-model="application.data.company.name"
                type="text"
                placeholder="例）株式会社ZIRITZ"
                maxlength="100"
              >
              </z-input>
              <z-input
                label="お名前"
                v-model="application.data.user.name"
                type="text"
                placeholder="例）自律 怜平"
                maxlength="100"
                isRequired
                :validate="'required'"
              >
              </z-input>
              <z-input
                label="所属・部署"
                v-model="application.data.user.department"
                type="text"
                placeholder="例）東京支店・東京営業部"
                maxlength="32"
              >
              </z-input>
              <z-input
                label="役職"
                v-model="application.data.user.title"
                type="text"
                placeholder="例）マネージャー"
                maxlength="32"
              >
              </z-input>
              <z-input
                label="電話番号"
                v-model="application.data.user.phoneNumber"
                type="tel"
                placeholder="例）0311112222"
                maxlength="11"
                isRequired
                :validate="'required|min:10|max:11|numeric'"
              >
              </z-input>
              <z-input
                label="メールアドレス"
                v-model="application.data.user.email"
                type="email"
                placeholder="例）XXXXX@ziritz.co.jp"
                maxlength="512"
                isRequired
                :validate="'required|email'"
                icon="email"
              >
              </z-input>
              <z-input
                label="お問合せ内容"
                name="comment"
                v-model="application.data.comment"
                type="textarea"
                placeholder="ご要望・ご質問・ご不明点など入力してください"
                maxlength="500"
                :validate="''"
              >
              </z-input>
              <div class="agreement-wrap">
                <b-checkbox
                  name="agreement"
                  v-model="application.data.agreement"
                  type="checkbox"
                  v-validate="'required:invalidateFalse'"
                >
                </b-checkbox>
                <span class="agreement__message">
                  ｢<a
                    class="personal-information"
                    href="https://stockformer.com/privacy_client"
                    target="_blank"
                    >プライバシーポリシー</a
                  >｣に同意いただいた上で、お問い合せください。
                </span>
              </div>
              <button
                class="button is-fullwidth is-rounded is-primary"
                :disabled="$$validator$invalid"
                @click="save"
              >
                お問い合せ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "investor-auth-application",
  data: () => {
    return {
      application: null,
      isLoading: true,
    };
  },
  mounted() {
    this.application = this.$$realtor$getNewApplication();
    this.isLoading = false;
  },
  methods: {
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$createApplication(this.auth.uid, this.application);
        this.$router.push({ name: "auth-realtor-contact-confirm" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("送信に失敗しました。");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realotr-auth-application-detail {
  height: 100%;
  @include sp {
    width: 92%;
  }
}

.form-wrap {
  width: 100vw;
  margin: 16px;
  @include mobile {
    width: 100%;
  }
}

.box {
  &__form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.has-text-yellow {
  color: yellow;
}

.personal-information {
  text-decoration: underline;
}

.b-checkbox {
  font-size: 12px;
}

.agreement-wrap {
  margin-bottom: 45px;
}

.message-wrap {
  padding-top: 0px;
  @include sp {
    padding: 46px 15px;
  }
}

.message {
  &__main-title {
    margin-top: 16px;
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: bold;
    color: $black;
    text-align: center;
  }
  &__sub-title {
    font-size: 36px;
  }
  &__content {
    font-size: 20px;
  }
}

.agreement {
  &__message {
    vertical-align: 3px;
    font-size: 12px;
  }
}

.columns {
  .container & {
    margin: 0;
  }
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.agreement__message {
  vertical-align: -3px;
}

// for Edge hack
_:-ms-lang(x)::backdrop,
.agreement__message {
  vertical-align: 1px;
}

.br-show {
  display: none;
  @include sp {
    display: block;
  }
  @include md {
    display: block;
  }
}

// for IE11 hack
_:lang(x)::-ms-backdrop,
.br-show-ie {
  display: block;
}
</style>
