<template>
  <section class="z-section">
    <div class="z-section__inner">
      <div class="holder">
        <RealEstateTransactionCharts
          class="chart"
          :filter="filter"
          :selectedFilter.sync="selectedFilter"
          :tabIndex.sync="tabIndex"
          :followingStyle="followingStyle"
          ref="chart"
        />
        <div class="transaction">
          <div class="transaction__body">
            <div class="">
              <div class="transaction__head">
                <div class="transaction__head__score">
                  <p>
                    <span>スコア：</span><b>{{ filterScore }}</b>
                  </p>
                  <p v-if="filterOption">
                    <span>{{ filterOption.label }}：</span
                    ><b>{{ filterOption.value }}</b>
                  </p>
                </div>
                <div class="transaction__head__sort">
                  <z-sort-select v-model="selectedSortType" type="realEstate" />
                </div>
              </div>
            </div>
            <div class="ranking">
              <RealEstateTransactionList
                :filter="filter"
                :selectedFilter="selectedFilter"
                :sortType="selectedSortType"
                ref="list"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RealEstateTransactionCharts from "./RealEstateTransactionCharts";
import RealEstateTransactionList from "./RealEstateTransactionList";

export default {
  name: "RealEstateTransaction",
  components: {
    RealEstateTransactionCharts,
    RealEstateTransactionList,
  },
  props: {
    // 絞り込み条件
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0, // タブ(価格/利回り)
      selectedSortType: 16, // ソート順
      selectedFilter: null, // RealEstateTransactionで絞り込んだ検索条件
      followingStyle: {
        top: "",
      },
      offset: 12,
      isFirst: true,
    };
  },
  computed: {
    // 選択したバブルの条件(スコア)
    filterScore() {
      if (!this.selectedFilter || !this.selectedFilter.score) {
        return "";
      }
      const { from, to } = this.selectedFilter.score;
      const fromLabel = from !== null ? from : "下限なし";
      const toLabel = to !== null && to <= 900 ? to : "上限なし";

      return `${fromLabel}〜${toLabel}`;
    },
    // 選択したバブルの条件(価格・利回り)
    filterOption() {
      let fromLabel = "";
      let toLabel = "";
      let label = "";

      // 価格
      if (this.selectedFilter && this.selectedFilter.amount) {
        const { from, to } = this.selectedFilter.amount;
        fromLabel = from ? this.$$price$priceJPFormat(from) : "下限なし";
        toLabel =
          to !== null && to <= 500000000
            ? this.$$price$priceJPFormat(to)
            : "上限なし";
        label = "価格";
      }

      // 利回り
      if (this.selectedFilter && this.selectedFilter.couponYieldRate) {
        const { from, to } = this.selectedFilter.couponYieldRate;
        fromLabel = from ? this.$$rete$rateFormat(from) : "下限なし";
        toLabel =
          to !== null && to <= 0.2 ? this.$$rete$rateFormat(to) : "上限なし";
        label = "利回り";
      }

      return { label, value: `${fromLabel}〜${toLabel}` };
    },
  },
  methods: {
    // 初期表示時・絞り込み条件変更時にリスト絞り込み条件を初期化
    // タブ位置を考慮しています
    initSelectedFilter() {
      const { score, amount, couponYieldRate } = this.filter;

      if (!this.selectedFilter) {
        if (this.tabIndex === 0) {
          this.selectedFilter = { score, amount };
        } else {
          this.selectedFilter = { score, couponYieldRate };
        }
      }
    },
    handleScroll() {
      if (!this.$refs.chart || !this.$refs.list) return false;
      const followingCL = this.$refs.chart.getFollowingCL(); // コンテンツ内のtab内chart部分のみ
      const listCL = this.$refs.list.$el.getBoundingClientRect();
      if (listCL.top < 0) {
        const max = listCL.height - followingCL.height;
        if (listCL.top * -1 > max) {
          this.followingStyle["top"] = max + "px";
        } else {
          this.followingStyle["top"] = listCL.top * -1 + this.offset + "px";
        }
      } else {
        this.followingStyle["top"] = 0;
      }
      return true;
    },
    moveToListTop() {
      if (!this.$el) return false;

      const cl = this.$el.getBoundingClientRect();
      if (cl.top < 0) {
        window.scrollTo(0, window.scrollY + cl.top - this.offset);
      }
      return true;
    },
    updateFilter() {
      if (this.isFirst) return false;
      this.$store.commit("investor/setMtRealEstateFilter", {
        tabIndex: this.tabIndex,
        sortType: this.selectedSortType,
        filter: this.selectedFilter,
      });
      return true;
    },
  },
  watch: {
    filter: {
      handler() {
        this.selectedFilter = null;
        this.initSelectedFilter();
      },
    },
    selectedFilter: {
      handler() {
        this.updateFilter();
        this.moveToListTop();
      },
    },
    selectedSortType() {
      this.updateFilter();
    },
    tabIndex() {
      this.updateFilter();
    },
  },
  created() {
    const filter = this.$store.state.investor.mtRealEstateFilter;
    this.selectedFilter = filter.filter;
    this.tabIndex = filter.tabIndex;
    // this.selectedSortType = filter.sortType;
  },
  mounted() {
    this.initSelectedFilter();
    window.addEventListener("scroll", this.handleScroll);
    this.isFirst = false;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-section {
  &__inner {
    padding-top: 0;
    .holder {
      position: relative;
      display: flex;
      @include sp {
        display: block;
      }
    }
    .chart {
      // padding-top: 12px;
      flex: 1 1;
      @include sp {
        padding-top: 0;
      }
    }
    .ranking {
      position: relative;
      /deep/ .transactionBox {
        position: relative;
        a {
          display: block;
        }
      }
    }
    .transaction {
      flex: 1 1;
      margin-top: -10px;
      @include sp {
        margin-top: 16px;
        margin-left: 0;
        max-width: 100%;
        width: 100%;
      }
      &__head {
        display: flex;
        align-items: center;
        &__score {
          display: inline-block;
          flex: 1 1;
          padding: 0 0 10px;
          font-size: 14px;
          b {
            font-weight: normal;
            font-size: 16px;
            color: $primary;
          }
          @include sp {
            span {
              display: block;
            }
          }
        }
        &__sort {
          display: inline-block;
        }
      }
    }
  }
}
</style>
