<template>
  <div class="planAttention">
    <p class="planAttention__txt">
      プレミアムプランの機能を90日間無料でお試しいただけます。
    </p>
    <p class="planAttention__txt">
      無料トライアル終了後はプレミアムプランは自動解約されます。
    </p>
    <p class="planAttention__txt">
      無料トライアルは一度だけご利用いただけます。
    </p>
    <p class="planAttention__txt">
      無料トライアルの開始には、ポートフォリオを作成いただいた後、登録内容の審査が必要になります。
    </p>
    <p class="planAttention__txt">
      無料期間終了後、改めてプレミアムプランをお申込みいただいた場合のみ、月額5000円のご利用料金が発生します。
    </p>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "PlanAttention",
  // computed: {
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.planAttention {
  @include lg {
    margin: 40px (-$edit-padding) 0;
  }
  @include md {
    margin: 40px (-$edit-padding) 0;
  }
  @include sp {
    margin-top: 32px;
  }
  &__txt {
    font-size: 11px;
    line-height: 19px;
    @include sp {
      font-size: 12px;
    }
  }
}
</style>
