<template>
  <div class="buyingNeedsField">
    <div class="is-small">
      <template v-if="value.area.isSelected">
        <div class="buyingNeedsField__title">エリア:</div>
        <div class="buyingNeedsField__content">
          <!-- <z-truncator :line="1" :text="this.areaText" /> -->
          {{ areaText }}
        </div>
      </template>
      <template v-else>
        <div class="buyingNeedsField__title">沿線:</div>
        <div class="buyingNeedsField__content">
          <!-- <z-truncator :line="1" :text="this.lineText" /> -->
          {{ lineText }}
        </div>
      </template>
      <br />
      <div class="buyingNeedsField__title">希望物件価格:</div>
      <div class="buyingNeedsField__content">
        {{ priceFormat($$ks.getPriceFrom(value.askingPrice.from).text) }}
        <span>~</span>
        {{ priceFormat($$ks.getPriceTo(value.askingPrice.to).text) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">表面利回り:</div>
      <div class="buyingNeedsField__content">
        {{
          yieldRateFormat(
            $$ks.getYieldRateFrom(value.couponYieldRate.from).text
          )
        }}
        <span>~</span>
        {{
          yieldRateFormat($$ks.getYieldRateTo(value.couponYieldRate.to).text)
        }}
      </div>
      <br />
      <div class="buyingNeedsField__title">物件種別:</div>
      <div class="buyingNeedsField__content">
        <!-- <z-truncator :line="1" :text="this.realEstateTypeText" /> -->
        {{ realEstateTypeText }}
      </div>
      <br />
      <div class="buyingNeedsField__title">建物種別:</div>
      <div class="buyingNeedsField__content">
        <!-- <z-truncator :line="1" :text="this.buildingTypeText" /> -->
        {{ buildingTypeText }}
      </div>
      <br />
      <div class="buyingNeedsField__title">築年数:</div>
      <div class="buyingNeedsField__content">
        {{
          yearOfConstructionFormat(
            $$ks.getYearOfconstructionFrom(value.ageOfBuilding.from).text
          )
        }}
        <span>~</span>
        {{
          yearOfConstructionFormat(
            $$ks.getYearOfconstructionTo(value.ageOfBuilding.to).text
          )
        }}
      </div>
      <br />
      <div class="buyingNeedsField__title">最寄り駅までの徒歩分数:</div>
      <div class="buyingNeedsField__content">
        {{ $$ks.getElapsedTime(value.transport.elapsedTime).text }}
      </div>
      <br />
      <div class="buyingNeedsField__title">自己資金・担保可能金額:</div>
      <div class="buyingNeedsField__content">
        {{ $$price$priceJPFormat(value.ownFund) }}
      </div>
      <br />
      <div class="buyingNeedsField__title">コメント:</div>
      <br />
      <div class="buyingNeedsField__content">
        <!-- <z-truncator :line="3" :text="value.comment" /> -->
        {{ value.comment }}
      </div>
    </div>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "InvestorBuyingNeeds",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  asyncComputed: {
    areaText() {
      return `${this.areaPrefectureInfo} ${this.cityInfo}`;
    },
    lineText() {
      return `${this.linePrefectureInfo} ${this.lineInfo} ${this.stationInfo}`;
    },
    realEstateTypeText() {
      const str = this.value.realEstateTypes.map((type) => {
        return this.$$ks.realEstateType(type).text;
      });
      return str.join(" , ");
    },
    buildingTypeText() {
      const str = this.value.buildingTypes.map((type) => {
        return this.$$ks.getTypeOfBuildingType(type).text;
      });
      return str.join(" , ");
    },
    async areaPrefectureInfo() {
      const ls = await this.$$prefecture$load(this.value.area.prefecture);
      return ls.text;
    },
    async cityInfo() {
      // 市区町村情報取得
      const resultPromise = [];
      const cityCodes = this.value.area.cities;
      for (let i = 0, len = cityCodes.length; i < len; ++i) {
        resultPromise.push(
          this.$$city$load(this.value.area.prefecture, cityCodes[i])
        );
      }
      const result = await Promise.all(resultPromise);

      // 成形
      let infos = "";
      for (let i = 0, len = result.length; i < len; ++i) {
        infos += (i === 0 ? "" : " , ") + result[i].cityName;
      }
      return infos;
    },
    async linePrefectureInfo() {
      const ls = await this.$$prefecture$load(this.value.Line.prefecture);
      return ls.text;
    },
    async lineInfo() {
      const ls = await this.$$line$load(this.value.Line.line);
      if (ls) return ls.line_name;
      else return "";
    },
    async stationInfo() {
      // 情報取得
      const resultPromise = [];
      const stationCodes = this.value.Line.stations;
      for (let i = 0, len = stationCodes.length; i < len; ++i) {
        resultPromise.push(this.$$station$load(stationCodes[i]));
      }
      const result = await Promise.all(resultPromise);

      // 成形
      let infos = "";
      for (let i = 0, len = result.length; i < len; ++i) {
        infos += (i === 0 ? "" : " , ") + result[i].station_name;
      }
      return infos;
    },
  },
  methods: {
    priceFormat(text) {
      const ksPrice = this.$$ks.filters.price;
      if (text === ksPrice.from[0].text || text === ksPrice.to[0].text) {
        return text;
      } else {
        return text + "円";
      }
    },
    yieldRateFormat(text) {
      const ksYieldRate = this.$$ks.filters.yieldRate;
      if (
        text === ksYieldRate.from[0].text ||
        text === ksYieldRate.to[0].text
      ) {
        return text;
      } else {
        return text + "%";
      }
    },
    yearOfConstructionFormat(text) {
      const ksYOC = this.$$ks.filters.yearOfconstruction;
      if (
        text === ksYOC.from[0].text ||
        text === ksYOC.from[1].text ||
        text === ksYOC.to[0].text
      ) {
        return text;
      } else {
        return text + "年";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.buyingNeedsField {
  font-size: 12px;
  text-align: left;
  &__title {
    display: inline-block;
    padding-right: 0.5rem;
    @include mobile {
      width: 100%;
    }
  }
  &__content {
    color: gray;
    display: inline-block;
    @include lg {
      max-width: 80%;
    }
  }
  &__trancator {
    display: inline-block;
  }
}
</style>
