<script>
// import InputMixin from "./InputMixin";
// import axios from "axios-jsonp-pro";
import firebase from "@/plugins/firebase";

const loadStation = async function (code) {
  const doc = await firebase.db
    .collection("stations_v2")
    .doc(String(code))
    .get();

  if (doc.exists) {
    return doc.data().station;
  } else {
    return null;
  }
  // return new Promise(function(resolve, reject) {
  //   window.$.ajax({
  //     // url: "http://www.ekidata.jp/api/l/11302.json",
  //     url: "https://www.ekidata.jp/api/s/" + code + ".json",
  //     type: "GET",
  //     dataType: "script",
  //     timeout: 100000,
  //     success: function() {
  //       // const stations = window.xml.data.line;
  //       const station = window.xml.data.station;
  //       window.xml = {};
  //       resolve(station[0]);
  //     },
  //     error: function(XMLHttpRequest, textStatus, errorThrown) {
  //       console.error("ng", XMLHttpRequest, textStatus, errorThrown);
  //       reject(errorThrown);
  //     }
  //   });
  // });
};

const loadStations = async function (code) {
  const ls = await firebase.db
    .collection("stations_v2")
    .where("lineCode", "==", Number(code))
    .get()
    .then((querySnapshot) => {
      const ds = [];
      querySnapshot.forEach((doc) => {
        ds.push(doc.data().station);
      });
      return ds;
    });
  return ls;
  // eturn new Promise(function(resolve, reject) {
  //  window.$.ajax({
  //    // url: "http://www.ekidata.jp/api/l/11302.json",
  //    url: "https://www.ekidata.jp/api/l/" + code + ".json",
  //    type: "GET",
  //    dataType: "script",
  //    timeout: 10000,
  //    success: function() {
  //      // const stations = window.xml.data.line;
  //      const stations = window.xml.data.station_l;
  //      window.xml = {};
  //      resolve(stations);
  //    },
  //    error: function(XMLHttpRequest, textStatus, errorThrown) {
  //      console.error("ng", XMLHttpRequest, textStatus, errorThrown);
  //      reject(errorThrown);
  //    }
  //  });
  // );
};

const loadLine = async function (code) {
  const doc = await firebase.db.collection("lines_v2").doc(String(code)).get();

  if (doc.exists) {
    return doc.data().line;
  } else {
    return null;
  }
  // return new Promise(function(resolve, reject) {
  //   window.$.ajax({
  //     url: " https://www.ekidata.jp/api/l/" + code + ".json",
  //     type: "GET",
  //     dataType: "script",
  //     timeout: 100000,
  //     success: function() {
  //       const line = {
  //         line_cd: window.xml.data.line_cd,
  //         line_name: window.xml.data.line_name,
  //         line_lon: window.xml.data.line_lon,
  //         line_lat: window.xml.data.line_lat,
  //         line_zoom: window.xml.data.line_zoom
  //       };
  //       window.xml = {};
  //       resolve(line);
  //     },
  //     error: function(XMLHttpRequest, textStatus, errorThrown) {
  //       console.error("ng", XMLHttpRequest, textStatus, errorThrown);
  //       reject(errorThrown);
  //     }
  //   });
  // });
};

const loadLines = async function (code) {
  const ls = await firebase.db
    .collection("lines_v2")
    .where("prefectureCodes", "array-contains", Number(code))
    .get()
    .then((querySnapshot) => {
      const ds = [];
      querySnapshot.forEach((doc) => {
        ds.push(doc.data().line);
      });
      return ds;
    });
  return ls;
  // return new Promise(function(resolve, reject) {
  //   window.$.ajax({
  //     url: "https://www.ekidata.jp/api/p/" + code + ".json",
  //     type: "GET",
  //     dataType: "script",
  //     timeout: 100000,
  //     success: function() {
  //       const lines = window.xml.data.line;
  //       window.xml = {};
  //       resolve(lines);
  //     },
  //     error: function(XMLHttpRequest, textStatus, errorThrown) {
  //       console.error("ng", XMLHttpRequest, textStatus, errorThrown);
  //       reject(errorThrown);
  //     }
  //   });
  // });
};

export default {
  name: "mixins-line-Line",
  methods: {
    $$tra: async function (stationCode) {
      if (stationCode) {
        const station = await loadStation(stationCode);
        return station;
      } else {
        return null;
      }
    },
    $$station$load: async function (stationCode) {
      if (stationCode) {
        const station = await loadStation(stationCode);
        return station;
      } else {
        return null;
      }
    },
    $$station$loads: async function (lineCode) {
      if (lineCode) {
        const lines = await loadStations(lineCode);
        return lines;
      } else {
        return null;
      }
    },
    $$line$load: async function (lineCode) {
      if (lineCode) {
        const line = await loadLine(lineCode);
        return line;
      } else {
        return null;
      }
    },
    $$line$loads: async function (prefectureCode) {
      if (prefectureCode) {
        const ret = await loadLines(prefectureCode);
        return ret;
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
