<template>
  <div>
    <section>
      <ZHeader title="売却物件詳細" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <RealestateOverview :isSale="true" :data="rsInfo.data" />
        <z-realestate-images :images="rsInfo.data.images" />
        <RealestateDetail :data="rsInfo.data" showBildingInfo showlandInfo />
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

//import RealEstateList from "@/views/realtor/common/RealEstateList";
// import RealEstate from "./parts/RealEstate";

import RealestateOverview from "@/components/realestate/RealestateOverview";
import RealestateDetail from "@/components/realestate/RealestateDetail";

export default {
  name: "investor-search-realestates-detail",
  data: function () {
    return {
      isLoading: true,
      rsInfo: null,
    };
  },
  components: {
    RealestateOverview: RealestateOverview,
    RealestateDetail: RealestateDetail,
  },
  computed: {
    data() {
      return this.rsInfo.data;
    },
  },
  methods: {
    //async address(dt) {
    //  const text = await this.$$area$generateTextOfAddress(dt);
    //  return text;
    //},
    //async loadRealEstate(r) {
    //  r.address = await this.address(r.data);
    //  // r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
    //  return r;
    //},
    async init() {
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.isLoading = true;
        this.rsInfo = await this.$$realEstate$loadRealEstate(
          this.$route.params.id
        );
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
      }
      //const loadingComponent = this.$loading.open();

      //try {
      //  this.isLoading = true;
      //  const res = await this.$$realEstate$loadRealEstate(this.realEstateId);
      //  //const promises = [];
      //  //for (const r of res) {
      //  //  promises.push(this.loadRealEstate(r));
      //  //}
      //  //this.realEstate = await this.loadRealEstate(res);
      //  this.realEstate = res;
      //  this.isLoading = false;
      //  return;
      //} catch (error) {
      //  this.$$error$catchLoadError(error);
      //} finally {
      //  loadingComponent.close();
      //}
    },
    buildingTypeText(val) {
      return this.$$ks.getTypeOfBuildingType(val).text;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    return this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 11px 22px;
//  background-color: $important;
//  border-bottom: solid 2px #707070;
//  color: $white;
//}

.realestate-list {
  margin-left: 20px;
  @include mobile {
    margin-left: 0;
  }
  &__detail {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &__hr {
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
</style>
