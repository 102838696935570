<template>
  <div class="title">
    <p class="title__titleTxt">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "ZTileBoxTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.title {
  height: 42px;
  padding: 0 16px;
  &__titleTxt {
    font-size: 16px;
    font-weight: normal;
    line-height: 42px;
  }
}

@include sp {
  .title {
    height: 32px;
    padding: 0 14px;
    &__titleTxt {
      font-size: 14px;
      line-height: 32px;
    }
  }
}
</style>
