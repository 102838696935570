<template>
  <div>
    <ul class="edit__list">
      <li
        v-for="(buyingNeed, i) in value.data.buyingNeeds"
        :index="i"
        :key="i"
        class="edit__list__item"
      >
        <z-edit-accordion :title="'買いニーズ情報入力 ' + (i + 1)">
          <div>
            <FormBuyingNeedUnit v-model="value.data.buyingNeeds[i]" />

            <template v-if="i > 0">
              <div class="has-text-right">
                <delete-button
                  label="ニーズ情報入力を削除する"
                  @click="deleteBuyingNeeds(i)"
                />
              </div>
            </template>
          </div>
        </z-edit-accordion>
      </li>
    </ul>

    <div class="edit__section is-onlySP">
      <add-button label="ニーズ情報入力を追加する" @click="addBuyingNeeds()" />
    </div>
  </div>
</template>

<script>
import FormBuyingNeedUnit from "./FormBuyingNeedUnit";

export default {
  name: "FormBuyingNeeds",
  inject: ["$validator"],
  props: {
    value: Object,
  },
  // data() {
  //   return {
  //   };
  // },
  components: {
    FormBuyingNeedUnit: FormBuyingNeedUnit,
  },
  // computed: {
  // },
  methods: {
    addBuyingNeeds() {
      let item = this.value.data.buyingNeeds;
      item.push(this.$$investor$getNewBuyingNeeds());
    },
    deleteBuyingNeeds(index) {
      let item = this.value.data.buyingNeeds;
      item.splice(index, 1);
    },
  },
  mounted() {
    if (this.value.data.buyingNeeds.length < 1) this.addBuyingNeeds();
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit__section.is-onlySP {
  padding: 0 !important;
  @include sp {
    padding: 0 $edit-padding-sp !important;
  }
}
</style>
