<template>
  <div class="plan">
    <ZHeader title="Plan" sub="プラン選択">
      <template v-slot:rightIcons>
        <ZNotification color="#777" />
      </template>
    </ZHeader>
    <div class="plan__confirm">
      <p class="plan__confirm__title">登録内容の確認</p>
      <div class="table-box">
        <dl>
          <dt>加入コース</dt>
          <dd>30日間コース 5,000円（税別）の一括払い</dd>
        </dl>
        <dl>
          <dt>カード情報</dt>
          <dd>
            <template v-if="isRegisteredCard && cardInfo">
              {{ cardInfo.CREDITNO }}<br />
              有効期限: {{ formatCardEXP(cardInfo.EXP) }}
            </template>
            <ZCreditCardRegister
              :OKURL="successURLOfCardRegistration"
              :RT="failureURLOfCardRegistration"
              :uid="this.me.id"
              label="カード情報を変更する"
              editing
            />
          </dd>
        </dl>
        <template v-if="$$user$isPremium">
          <dl>
            <dt>継続ステータス</dt>
            <dd>{{ currentStatus }}</dd>
          </dl>
        </template>
        <dl>
          <dt>プレミアム有効期限</dt>
          <dd>{{ expirationDate }}</dd>
        </dl>
      </div>
    </div>
    <template v-if="$$user$isPremium">
      <template v-if="$$user$isCancelled">
        <div class="plan__buttons">
          <b-button
            class="button z-button is-primary is-wide is-rounded is-multiline"
            :disabled="!isRegisteredCard || !$$investor$checkSatisfyPremium()"
            @click="changePremium"
          >
            <span>自動更新に変更する</span>
          </b-button>
        </div>
      </template>
      <template v-else>
        <div class="plan__buttons">
          <b-button
            class="button z-button is-primary is-wide is-rounded is-multiline"
            :disabled="!$$investor$checkSatisfyPremium()"
            @click="cancelPremium"
          >
            <span>解約する</span>
          </b-button>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="plan__buttons">
        <b-button
          class="button z-button is-primary is-wide is-rounded is-multiline"
          :disabled="!isRegisteredCard || !$$investor$checkSatisfyPremium()"
          @click="applicatePremium"
        >
          <span>申し込みを完了する</span>
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Premium-Detail",
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    currentStatus() {
      if (this.$$user$isPremium) {
        return this.me.user.examination.data.isCancelled
          ? "自動解約"
          : "自動更新";
      } else {
        return "-";
      }
    },
    expirationDate() {
      if (this.$$user$isPremium) {
        return this.$$calender$dateFormat(this.me.user.examination.data.endDt);
      } else {
        const premiumPeriod = 30;
        const date = new Date();
        date.setDate(date.getDate() + premiumPeriod);
        return this.$$calender$dateFormat(date);
      }
    },
    successURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-premium-detail",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
    failureURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-premium-detail",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
  },
  asyncComputed: {
    async isRegisteredCard() {
      return await this.$$investor$isRegisteredCard(this.me.user);
    },
    async cardInfo() {
      return await this.$$investor$cardInfo(this.me.user);
      // const loadingComponent = this.$loading.open();
      // try {
      //   return await this.$$investor$cardInfo(this.me.user);
      // } catch (error) {
      //   this.$raven.captureException(error);
      //   this.$$toast$openError();
      // } finally {
      //   loadingComponent.close();
      // }
    },
  },
  methods: {
    formatCardEXP(exp) {
      const str = String(exp);
      return `${str.slice(2, 4)}/${str.slice(0, 2)}`;
    },
    async cancelPremium() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$cancelUserByUID(this.auth.uid, this.me.id);

        this.$router.push({ name: "investor-account" });
        this.$$toast$openSuccess("解約が完了しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async changePremium() {
      const loadingComponent = this.$loading.open();
      try {
        const user = await this.$$investor$loadUser(this.me.user.id);
        user.examination.data.isCancelled = false;
        await this.$$investor$saveExaminationOfUser(
          this.auth.uid,
          user.id,
          user.examination
        );
        // await this.$$investor$save(this.auth.uid, user);

        this.$router.push({ name: "investor-account" });
        this.$$toast$openSuccess("自動更新に変更しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async applicatePremium() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$applicateUserByUID(this.auth.uid, this.me.id);

        this.$router.push({ name: "investor-home" });
        this.$$toast$openSuccess("お申込みが完了しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.table-box {
  display: table;
  border-top: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
  border-left: 2px solid #cccccc;
  font-size: 14px;
  width: 100%;
}
.table-box dl {
  display: table-row;
}
.table-box dt,
.table-box dd {
  display: table-cell;
  padding: 12px;
  border-bottom: 2px solid #cccccc;
  // vertical-align: middle;
}
.table-box dt {
  width: 20%;
  @include sp {
    width: 35%;
  }
  background-color: #ddd;
}

.plan {
  &__confirm {
    padding: 15px 30px;
    &__title {
      font-weight: bold;
      margin: 10px auto;
    }
  }
  &__buttons {
    width: 90%;
    margin: 32px auto;
    // margin-top: 32px;
    // margin-bottom: 40px;
    text-align: center;
    @include sp {
      margin-top: 20px;
    }
  }
}
</style>
