<template>
  <img
    :class="rounded ? 'is-rounded' : ''"
    v-lazy="src"
    :key="(internalValue && internalValue.id) || null"
    :alt="imageAlt"
  />
</template>

<script>
import InputMixin from "./InputMixin";
// import firebase from "@/plugins/firebase";
// [const emptyImage = require("@/assets/empty.svg");

export default {
  name: "ZImage",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
    original: {
      type: [Boolean],
      default: true,
    },
    rounded: {
      type: [Boolean],
      default: false,
    },
    emptySrc: {
      type: [String],
      default: null,
    },
    loadImmediate: {
      type: [Boolean],
      default: false,
    },
  },
  asyncComputed: {
    async src() {
      if (this.internalValue && this.internalValue.id) {
        if (this.original) {
          return this.prefixImgUrl + this.internalValue.id;
          //const url = "https://firebasestorage.googleapis.com/v0/b/mintrich-prj-dev.appspot.com/o/images%2F00ca5f9b-7bb4-45b2-be37-e86a7464fb97?alt=media&token=51dfff06-5d3c-42a4-ac8c-4b8fd4d5334c";
          //const url = await firebase.storage
          //  .child("/images/" + this.internalValue.id)
          //  .getDownloadURL()
          //  .then(u => {
          //    return u;
          //  });
          //return url;
        } else {
          return this.prefixImgUrl + this.internalValue.id;
        }
      } else {
        if (this.emptySrc) {
          return this.emptySrc;
          //return require(this.emptySrc);
        } else {
          return this.emptyImage;
        }
      }
    },
  },
  computed: {
    imageAlt: function () {
      if (this.internalValue) {
        return this.internalValue.title;
      } else {
        return "";
      }
    },
    prefixImgUrl: function () {
      return process.env.VUE_APP_IMAGE_PREFIX_URL;
    },
  },
  watch: {
    src: {
      handler(newValue) {
        if (this.loadImmediate && newValue) {
          this.$Lazyload.lazyLoadHandler();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
