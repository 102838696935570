<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <router-link :to="{ name: 'admin-setting-realtors' }">
        前に戻る
      </router-link>
      <div class="container">
        <div class="content">
          <h4>専門家(不動産屋)詳細</h4>
        </div>
        <b-tabs :animated="false" size="is-small" v-model="activeTab">
          <b-tab-item label="企業情報">
            <div class="buttons">
              <button class="button is-small is-primary" @click="save">
                保存
              </button>
            </div>
            <template v-if="realtor.company.id">
              <div class="box">
                <RealtorExamination
                  :examination="realtor.company.examination"
                  :company="realtor.company"
                ></RealtorExamination>
              </div>
              <hr />
            </template>
            <RealtorCompany :company="realtor.company"> </RealtorCompany>
            <hr />
          </b-tab-item>
          <b-tab-item label="コンサルタント一覧">
            <div class="buttons">
              <router-link
                :to="{
                  name: 'admin-setting-realtors-realtors-users-detail',
                  params: { cid: realtorId, uid: 'new' },
                }"
                class="button is-small is-primary"
                >ユーザ追加</router-link
              >
              <button class="button is-small is-warning" @click="issueAccount">
                アカウント発行
              </button>
              <button class="button is-small is-danger" @click="accept">
                承認
              </button>
              <!--
                <button class="button is-danger" @click="denial">否認</button>
              -->
            </div>
            <template v-if="realtor.company.id">
              <RealtorUserList
                ref="users"
                :users="realtor.users"
              ></RealtorUserList>
            </template>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

import RealtorExamination from "@/views/admin/components/RealtorCompanyExamination";
import RealtorCompany from "@/components/realtor/FormCompany";
import RealtorUserList from "@/views/admin/components/RealtorUserList";

export default {
  name: "admin-setting-realtors-detail",
  components: {
    // RealtorUser: RealtorUser,
    RealtorCompany: RealtorCompany,
    RealtorExamination: RealtorExamination,
    RealtorUserList: RealtorUserList,
  },
  data: function () {
    return {
      isLoading: true,
      realtor: {
        company: null,
        users: [],
      },
    };
  },
  computed: {
    activeTab: {
      get: function () {
        if (this.$route.query) {
          return Number(this.$route.query.at);
        } else {
          return null;
        }
      },
      set: function (val) {
        this.$router.push({ query: { at: val } });
      },
    },
    realtorId: function () {
      return this.$route.params.cid;
    },
  },
  methods: {
    async issueAccount() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$refs.users.issueAccount();
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async deny() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$denyAccountCompany(
          this.auth.uid,
          this.realtor.company
        );
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async accept() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$refs.users.accept();
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        const company = await this.$$realtor$saveCompany(
          this.auth.uid,
          this.realtor.company
        );
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
        this.$router.replace({
          name: "admin-setting-realtors-detail",
          params: {
            cid: company.id,
          },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    deleteUser(idx) {
      this.realtor.users.slice(idx);
    },
    addUser() {
      this.realtor.users.push(this.$$realtor$getNewUser());
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        if (this.realtorId === "new") {
          this.realtor.company = this.$$realtor$getNewCompany();
        } else {
          this.realtor = await this.$$realtor$loadRealtorById(this.realtorId);
        }
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
        this.isLoading = false;
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.init();
    return;
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
