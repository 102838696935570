var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[_c('nav',{staticClass:"menu__nav"},[_c('div',{staticClass:"menu__nav__head"},[_c('div',{staticClass:"menu__nav__brand"},[_c('div',{staticClass:"menu__nav__brand__logo",on:{"click":_vm.onLogoClick}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo-mini.png"),"alt":"StockFormer"}}),_c('img',{staticClass:"title",attrs:{"src":require("@/assets/logoTitle.png"),"alt":"StockFormer Powered By ZIRITZ"}})])]),(_vm.bulma.isMobile)?_c('div',{staticClass:"menu__nav__notification z-notification"},[_vm._t("rightIcons")],2):_vm._e(),(!_vm.hideMenu)?_c('div',{staticClass:"menu__nav__burger",on:{"click":_vm.toggleNav}},[_c('div',{staticClass:"burger",class:{ 'is-active': _vm.isShowNav }},[_c('span'),_c('span'),_c('span')])]):_vm._e()]),_c('div',{staticClass:"menu__nav__tabs",class:{ 'is-active': _vm.isShowNav }},[_c('ul',[_c('li',{class:{
            'is-active': _vm.$route.path.indexOf('/investor/zero') > -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{staticClass:"is-zero",attrs:{"to":{ name: 'investor-zero-index' }}},[_c('ZZeroIcon',{staticClass:"icon-zero",attrs:{"active":_vm.$route.path.indexOf('/investor/zero') > -1,"width":"40","height":"40","color":"#8ce5ff"}}),_c('p',[_vm._v("オススメ")])],1)],1),_c('li',{class:{
            'is-active':
              _vm.$route.path.indexOf('/investor/home') > -1 ||
              _vm.$route.path === '/investor/' ||
              _vm.$route.path === '/investor',
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'investor-home' }}},[_c('ZIcon',{attrs:{"name":"dashboard","width":"40","height":"40","color":"#fff"}}),_c('p',[_vm._v("Dashboard")])],1)],1),_c('li',{class:{
            'is-active': _vm.$route.path.indexOf('/investor/portfolio') > -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'investor-portfolio' }}},[_c('ZIcon',{attrs:{"name":"portfolio","width":"40","height":"40","color":"#fff"}}),_c('p',[_vm._v("Portfolio")])],1)],1),_c('li',{class:{
            'is-active': _vm.$route.path.indexOf('/investor/collection') > -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'investor-collection' }}},[_c('ZIcon',{attrs:{"name":"collection","width":"40","height":"40","color":"#fff"}}),_c('p',[_vm._v("Collection")])],1)],1),_c('li',{class:{
            'is-active': _vm.$route.path.indexOf('/investor/messages') > -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'investor-message' }}},[_c('ZIcon',{attrs:{"name":"offer","width":"40","height":"40","color":"#fff"}}),_c('p',[_vm._v("Message")])],1)],1),_c('li',{class:{
            'is-lock': !_vm.$$user$isPremium,
            'is-active': _vm.$route.path.indexOf('/investor/markettrend') > -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{
              name: _vm.$$user$isPremium
                ? 'investor-markettrend'
                : 'investor-premium',
            }}},[_c('ZIcon',{attrs:{"name":"transaction","width":"40","height":"40","color":"#fff"}}),_c('b-icon',{staticClass:"lock__icon",attrs:{"icon":"lock","size":"is-small"}}),_c('p',[_vm._v("Market")])],1),_c('b-tooltip',{staticClass:"lock__tooltip",attrs:{"label":"プレミアムプラン限定機能","position":"is-right","type":"is-dark","active":true,"always":true}})],1),_c('li',{class:{
            'is-active':
              _vm.$route.path.indexOf('/investor/search/realtor/realestates') >
              -1,
          },on:{"click":_vm.closeMenu}},[_c('router-link',{attrs:{"to":{ name: 'investor-search-realestates' }}},[_c('ZIcon',{attrs:{"name":"property","width":"40","height":"40","color":"#fff"}}),_c('p',[_vm._v("Property")])],1)],1)])])]),_c('div',{staticClass:"menu__background",class:{ 'is-active': _vm.isShowNav || _vm.isShowMenu },on:{"click":_vm.closeMenu}})])
}
var staticRenderFns = []

export { render, staticRenderFns }