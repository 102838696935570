<template>
  <div class="z-zeroIcon" :style="styles" :class="{ 'is-active': active }">
    <svg
      class="z-zeroIcon_flash"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      :width="width"
      :height="height"
    >
      <defs>
        <linearGradient
          :id="id"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" />
          <stop offset="33%" />
          <stop offset="66%" />
          <stop offset="100%" />
        </linearGradient>
      </defs>
      <g :fill="`url(#${id})`">
        <path
          d="M18.3 3.8v2.7l-7.6 9h7.8v2.8H6.7v-2.7l7.6-9.1H6.8V3.8h11.5zm3.1 0h11.8v2.8h-8.6v2.8h8.3v2.8h-8.3v3.2h8.6v2.8H21.4V3.8zM12.1 31H9.6v4.7H6.3V21.1h6.3c3.2 0 5.8 1.8 5.8 4.8 0 2.1-1.2 3.8-2.9 4.6l3.5 5.4h-3.7c-.2 0-3.2-4.9-3.2-4.9zm-2.5-7v4.2h2.5c2.1 0 3-.7 3-2.1 0-1.4-.9-2.1-3-2.1H9.6zm17.7-3.2c4.2 0 7.8 3 7.8 7.6s-3.6 7.7-7.8 7.7-7.7-3-7.7-7.6 3.4-7.7 7.7-7.7zm.1 12.2c2.7 0 4.5-2.1 4.5-4.6s-1.8-4.6-4.5-4.6c-2.8 0-4.5 2.1-4.5 4.6s1.7 4.6 4.5 4.6z"
        />
      </g>
    </svg>
    <svg
      class="z-zeroIcon_blink z-zeroIcon_blink-blur"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      :width="width"
      :height="height"
    >
      <g :fill="'#37d2ff'">
        <path
          d="M18.3 3.8v2.7l-7.6 9h7.8v2.8H6.7v-2.7l7.6-9.1H6.8V3.8h11.5zm3.1 0h11.8v2.8h-8.6v2.8h8.3v2.8h-8.3v3.2h8.6v2.8H21.4V3.8zM12.1 31H9.6v4.7H6.3V21.1h6.3c3.2 0 5.8 1.8 5.8 4.8 0 2.1-1.2 3.8-2.9 4.6l3.5 5.4h-3.7c-.2 0-3.2-4.9-3.2-4.9zm-2.5-7v4.2h2.5c2.1 0 3-.7 3-2.1 0-1.4-.9-2.1-3-2.1H9.6zm17.7-3.2c4.2 0 7.8 3 7.8 7.6s-3.6 7.7-7.8 7.7-7.7-3-7.7-7.6 3.4-7.7 7.7-7.7zm.1 12.2c2.7 0 4.5-2.1 4.5-4.6s-1.8-4.6-4.5-4.6c-2.8 0-4.5 2.1-4.5 4.6s1.7 4.6 4.5 4.6z"
        />
      </g>
    </svg>
    <svg
      class="z-zeroIcon_blink z-zeroIcon_blink-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      :width="width"
      :height="height"
    >
      <g :fill="'#37d2ff'">
        <path
          d="M18.3 3.8v2.7l-7.6 9h7.8v2.8H6.7v-2.7l7.6-9.1H6.8V3.8h11.5zm3.1 0h11.8v2.8h-8.6v2.8h8.3v2.8h-8.3v3.2h8.6v2.8H21.4V3.8zM12.1 31H9.6v4.7H6.3V21.1h6.3c3.2 0 5.8 1.8 5.8 4.8 0 2.1-1.2 3.8-2.9 4.6l3.5 5.4h-3.7c-.2 0-3.2-4.9-3.2-4.9zm-2.5-7v4.2h2.5c2.1 0 3-.7 3-2.1 0-1.4-.9-2.1-3-2.1H9.6zm17.7-3.2c4.2 0 7.8 3 7.8 7.6s-3.6 7.7-7.8 7.7-7.7-3-7.7-7.6 3.4-7.7 7.7-7.7zm.1 12.2c2.7 0 4.5-2.1 4.5-4.6s-1.8-4.6-4.5-4.6c-2.8 0-4.5 2.1-4.5 4.6s1.7 4.6 4.5 4.6z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ZZeroIcon",
  data() {
    return {
      id: this.$uuid.v4(),
    };
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#FFF",
    },
  },
  computed: {
    styles() {
      return {
        "--from-color": this.active ? this.color : "#37d2ff",
        "--to-color": this.active ? this.color : "#37d2ff",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

@keyframes gradient {
  0% {
    stop-color: var(--from-color);
    stop-opacity: 0.2;
  }
  25% {
    stop-color: var(--to-color);
    stop-opacity: 1;
  }
  50% {
    stop-color: var(--to-color);
    stop-opacity: 1;
  }
  100% {
    stop-color: var(--to-color);
    stop-opacity: 1;
  }
}

@keyframes blink {
  0% {
    filter: blur(2px);
    transform: scale(1);
  }
  25% {
    filter: blur(4px);
    transform: scale(1.15);
  }
  50% {
    filter: blur(2px);
    transform: scale(1);
  }
  100% {
    filter: blur(4px);
    transform: scale(1.2);
  }
}

@keyframes grow {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.z-zeroIcon {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &_flash {
    display: block;
    @include sp {
      display: none;
    }
    stop {
      stop-color: var(--to-color);
      &:nth-child(1) {
        animation: 0.6s ease-in-out infinite 0s alternate gradient;
      }
      &:nth-child(2) {
        animation: 0.6s ease-in-out infinite 0.1s alternate gradient;
      }
      &:nth-child(3) {
        animation: 0.6s ease-in-out infinite 0.2s alternate gradient;
      }
      &:nth-child(4) {
        animation: 0.6s ease-in-out infinite 0.3s alternate gradient;
      }
    }
  }
  &_blink {
    display: none;
    @include sp {
      display: block;
    }
    &-blur {
      position: absolute;
      animation: 2s ease-in-out infinite 0s alternate blink;
      opacity: 0.8;
    }
    &-logo {
      //opacity: 0.9;
      animation: 2s ease-in-out infinite 0s alternate grow;
    }
    &.is-active {
      .z-zeroIcon_blink-blur {
        filter: blur(4px);
        transform: scale(1.15);
        animation: none;
      }
      .z-zeroIcon_blink-logo {
        opacity: 1;
        animation: none;
      }
    }
  }
}
</style>
