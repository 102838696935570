<template>
  <div
    class="investor"
    :class="{
      'is-show-summary-sp': isDashBoard,
      'is-hideSummery': hideMenuBlank,
      'is-shrinkSummery': isShrinkSummary,
    }"
  >
    <div>
      <Menu v-if="!hideMenuBlank">
        <template v-slot:rightIcons>
          <ZNotification color="#fff" />
        </template>
      </Menu>
      <Summary
        v-if="!hideMenuBlank"
        :isHideMenu="hideMenuBlank"
        :is-shrink-summary="isShrinkSummary"
      ></Summary>
    </div>
    <div class="wrapper">
      <div class="contents">
        <router-view></router-view>
      </div>
      <Footer :isHideMenu="true" />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import Summary from "@/components/layout/realtor/Summary";
import Footer from "@/components/layout/realtor/Footer";
import Menu from "@/components/layout/realtor/Menu";
// import Header from "@/components/layout/investor/Header";

export default {
  name: "realtor-Index",
  // mixins: [AuthMixin, VersionsMixin],
  // mixins: [
  //   VersionsMixin
  // ],
  components: {
    Menu: Menu,
    Summary: Summary,
    Footer: Footer,
    //Header: Header
  },
  data: function () {
    return {
      width: window.innerWidth,
      isShrinkSummary: false,
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.toggleShrinkSummary, false);
    this.toggleShrinkSummary();
  },
  computed: {
    isDashBoard() {
      return (
        this.$route.path === "/realtor/" || this.$route.path === "/realtor/my"
      );
    },
    hideMenuBlank() {
      // console.log(
      //   this.$route.path.indexOf("/realtor/search/investor/realestates/")
      // );
      return (
        this.$route.path.indexOf("/realtor/search/investor/realestates/") >
          -1 ||
        this.$route.path.indexOf("/realtor/messages/") > -1 ||
        this.$route.path.indexOf("/realtor/bulkmessages/new") > -1 ||
        this.$route.path.indexOf("/realtor/solomessages/new") > -1
      );
    },
  },
  methods: {
    toggleShrinkSummary: debounce(function () {
      this.width = window.innerWidth;
      switch (this.$route.path) {
        case "/realtor/setting/investors":
        case "/realtor/setting/investors2":
          if (this.width >= 769) {
            this.isShrinkSummary = true;
          }
          if (this.width < 769) {
            this.isShrinkSummary = false;
          }
          break;
        default:
          if (this.width > 1215) {
            this.isShrinkSummary = false;
          }
          if (this.width >= 769 && this.width <= 1215) {
            this.isShrinkSummary = true;
          }
          if (this.width < 769) {
            this.isShrinkSummary = false;
          }
          break;
      }
    }, 200),
  },
  watch: {
    $route: function () {
      this.toggleShrinkSummary();
    },
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.toggleShrinkSummary, false);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investor {
  padding-left: $menu-width-md + $summary-width;
  height: 100%;
  &.is-registration {
    padding-left: $summary-width;
    /deep/ .summary {
      left: 0;
    }
  }
  &.is-hideSummery {
    padding-left: 0;
    @include sp {
      padding-top: 0;
    }
  }
  &.is-shrinkSummery {
    padding-left: $menu-width-md + $summary-width-md-close;
  }
  @include md {
    &.is-registration {
      padding-left: $summary-width-md-close;
    }
    &.is-hideSummery {
      padding-left: 0;
    }
  }
  @include sp {
    padding-left: 0;
    &.is-registration {
      padding-left: 0;
    }
    padding-top: 64px;
    /deep/ .summary {
      display: none;
    }
    &.is-show-summary-sp {
      padding-top: 0;
      /deep/ .summary {
        display: block;
      }
    }
  }
}
.wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 268px;
  @include md {
    padding-bottom: 443px;
  }
  @include sp {
    padding-bottom: 400px;
  }
}
.contents {
  margin-bottom: 50px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

// common
.mtr-inner {
  position: relative;
}
</style>
