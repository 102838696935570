<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="application-list">
        <div class="content">
          <h4>専門家(申し込み)一覧</h4>
        </div>
        <RealtorApplicationList
          :realtorApplications="realtorApplications"
        ></RealtorApplicationList>
      </div>
    </section>
  </div>
</template>

<script>
import RealtorApplicationList from "@/views/admin/components/RealtorApplicationList";

export default {
  name: "admin-setting-realtors-application",
  components: {
    RealtorApplicationList: RealtorApplicationList,
  },
  data: function () {
    return {
      isLoading: true,
      realtorApplications: [],
    };
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.realtorApplications = await this.$$realtor$loadApplications();
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.application-list {
  width: 100%;
  overflow-x: auto;
}
</style>
