<template>
  <div></div>
</template>

<script>
// import firebase from "@/plugins/firebase";

export default {
  name: "ErrorMixin",
  methods: {
    $$error$captureException: function (error) {
      this.$raven.captureException(error);
    },
    $$error$catchLoadError: function (error) {
      this.$raven.captureException(error);
      this.$snackbar.open({
        message:
          "通信エラーが発生しました。通信状況の良い場所で再読込をお願いします。",
        type: "is-danger",
        position: "is-top",
        actionText: "再読込",
        indefinite: true,
        onAction: () => {
          location.reload(true);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.snackbar {
  background-color: $danger;
}
.notices .snackbar .action .button {
  background: $white;
}
</style>
