<template>
  <section class="z-section">
    <div class="z-section__inner detail-wrap">
      <InvestorProfile
        :hideProfile="hideProfile"
        :investorInfo="investorInfo"
      />
      <div class="buttons is-centered">
        <b-button
          @click="routerNewMessage(investor)"
          type="is-primary"
          rounded
          class="z-button is-wide"
          >投資家に提案する</b-button
        >
      </div>
      <div class="columns is-multiline investorDetail">
        <template v-if="investor.user.data.countOfBuyingNeeds > 0">
          <template v-for="(bn, i) in investor.user.data.buyingNeeds">
            <div :key="i" class="column is-6">
              <div class="investorDetail__title">買いニーズ({{ i + 1 }})</div>
              <hr class="investorDetail__hr" />
              <InvestorBuyingNeeds :value="bn" />
            </div>
          </template>
        </template>
        <template v-else>
          <span class="investorDetail__empty"
            >買いニーズは登録されていません</span
          >
        </template>
      </div>
      <template v-if="investor.realEstate.countOfRealEstate > 0">
        <div v-for="(re, i) in investor.realEstate.realEstates" :key="i">
          <InvestorRealEstate :realEstate="re" :count="i" />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import InvestorProfile from "./InvestorProfile";
import InvestorBuyingNeeds from "./InvestorBuyingNeeds";
import InvestorRealEstate from "./InvestorRealEstate";
import CreateTemplateMessageMixin from "@/views/realtor/common/CreateTemplateMessageMixin";

export default {
  name: "investorDetail",
  mixins: [CreateTemplateMessageMixin],
  props: {
    investorInfo: {
      type: Object,
      default: null,
    },
    hideProfile: {
      type: Object,
      require: true,
    },
  },
  components: {
    InvestorProfile,
    InvestorBuyingNeeds,
    InvestorRealEstate,
  },
  computed: {
    investor() {
      return this.investorInfo._source;
    },
    realEstates() {
      return this.investor.realEstates
        .filter((r) => r.data.buildingUse !== 2)
        .sort((a, b) =>
          this.$$calender$compareYearMonth(
            a.data.purchase.buyingDt,
            b.data.purchase.buyingDt
          )
            ? -1
            : 1
        );
    },
  },
  methods: {
    routerNewMessage(row) {
      const refSearchCondition =
        this.$parent.$parent.$parent.$refs.investorSearchConditions;

      // 「不動産から絞り込み」を選択して投資家を絞り込んでいた場合は、絞り込みに使った不動産を渡す
      let query = {
        uid: row.uid,
        type: this.$$ks.message.types[0].value,
      };

      if (refSearchCondition.filterByRealEstate.selectedRealEstate) {
        query.defaultRealEstateId =
          refSearchCondition.filterByRealEstate.selectedRealEstate._source.id;
      }

      const routeData = this.$router.resolve({
        name: "realtor-message-new",
        query: query,
      });
      window.open(routeData.href, "_blank");
    },
    close() {
      this.isModalActiveReceive = false;
      document.documentElement.classList.remove("iosBugFixCaret");
      this.$emit("handleOnClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investorDetail {
  margin: 20px;
  background: #fafafa;
  box-shadow: inset 0 1px 3px #dbdbdb;
  @include sp {
    margin: 0;
  }
  @include lg {
    padding-left: 30px;
  }
  &__title {
    font-size: 15px;
    @include sp {
      text-align: left;
    }
  }
  &__hr {
    margin: 5px auto;
  }
  &__empty {
    font-size: 15px;
    margin-left: 7px;
  }
}

.detail-wrap {
  @include sp {
    padding-top: 16px;
  }
}
</style>
