import firebase from "@/plugins/firebase";
import kubuns from "@/kubuns/kubuns";

// =====
const defaultQuery = {
  query: {
    match_all: {},
  },
  size: 9999,
};

export const search = async (query) => {
  const q = query || defaultQuery;
  console.log(q);
  const ret = await firebase.functions("public_investor_search", {
    query: q,
  });
  console.log(ret.data);
  return ret.data;
};

export const count = async (query) => {
  const q = query || defaultQuery;
  const ret = await firebase.functions("public_investor_count", {
    query: q,
  });
  return ret.data;
};

// ***************************************************
export const makeAllIndex = async (query) => {
  console.log(query);
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_investor_makeAll", {});
  return ret.data;
};

export const putMapping = async () => {
  // const q = query | defaultQuery;
  const ret = await firebase.functions("public_investor_putMapping", {});
  return ret.data;
};

export const deleteIndex = async () => {
  const ret = await firebase.functions("public_investor_deleteIndex", {});
  return ret.data;
};

export const createIndex = async () => {
  const ret = await firebase.functions("public_investor_createIndex", {});
  return ret.data;
};

export const getIndex = async () => {
  const ret = await firebase.functions("public_investor_getIndex", {});
  return ret.data;
};
