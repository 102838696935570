<script>
import firebase from "firebase/app";

export default {
  name: "components-admin-auth-SentMail",
  methods: {
    cancel: function () {
      this.$router.push({ name: "/admin/auth/SignUp" });
    },
    querys: function (email) {
      const qus = Object.assign({ email: email }, this.$route.query);
      return Object.keys(qus)
        .map((k) => k + "=" + qus[k])
        .reduce((qs, q) => qs + "&" + q, "?q=1");
    },
    sendMail: function (email) {
      const url =
        location.protocol +
        "//" +
        location.host +
        "/auth/admin/register" +
        this.querys(encodeURIComponent(email));
      const actionCodeSettings = {
        url: url,
        handleCodeInApp: true,
      };
      return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
    },
  },
};
</script>
