<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <h1>お知らせ</h1>
      <z-input
        label="タイトル"
        v-model="information.data.title"
        maxlength="200"
        isRequired
        :validate="'required'"
      />
      <z-input
        label="メッセージ"
        v-model="information.data.message"
        maxlength="2000"
        type="textarea"
        isRequired
        :validate="'required'"
      />
      <b-button
        :disabled="$$validator$invalid"
        @click="onSendInformation"
        class="button z-button is-primary is-rounded is-small is-wide"
      >
        送信
      </b-button>
      <hr />
      <div class="content has-text-danger">
        <h3>エラー</h3>
        <p>
          {{ errs }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
// import examination from "@/views/admin/components/InvestorExamination";
//

export default {
  name: "admin-setting-infromations-bulk",
  data: function () {
    return {
      information: this.$$information$getNewInformation(),
      errs: [],
      // isLoading: true,
      // investor: null
    };
  },
  //computed: {
  //  uid() {
  //    return this.$route.params.uid;
  //  }
  //},
  methods: {
    async onSendInformation() {
      const loadingComponent = this.$loading.open();
      try {
        const users = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .where("isActivate", "==", true)
          .get()
          .then((qs) => {
            const us = [];
            qs.forEach((doc) => {
              us.push(doc.id);
            });
            return us;
          });

        console.log(users);
        // this.information.data.to = "8icZVgDJr5V61RHrUw7jm5rQebM2";
        // await this.$$information$send(this.auth.uid, this.information);

        for (const uid of users) {
          console.log(uid);
          try {
            console.log(uid);
            this.information.data.to = uid;
            await this.$$information$send(this.auth.uid, this.information);
          } catch (error) {
            this.$raven.captureException(error);
            this.errs.push(uid);
          }
        }

        this.$$toast$openSuccess("お知らせを送信しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("お知らせの送信に失敗しました");
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
