<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <h1>メール送信</h1>
      <b-field label="メールID">
        <b-input type="text" v-model="mail.id" />
      </b-field>
      <button class="button" @click="onSendMail">送信</button>
    </section>
    <hr />
    <section class="section">
      <h1>エクスクルーシブメッセージ残数を初期化</h1>
      <button class="button" @click="onRestoreExclusiveMessageCount">
        初期化
      </button>
    </section>
    <hr />
    <section class="section">
      <h1>BSとPLのデータを更新</h1>
      <b-select v-model="BSAndPL.year" placeholder="Select a year">
        <template v-for="o in $$ks.chartYears">
          <option :value="o.value" :key="o.text">
            {{ o.text }}
          </option>
        </template>
      </b-select>
      <b-select v-model="BSAndPL.quarter" placeholder="Select a quarter">
        <template v-for="o in $$ks.quarters">
          <option :value="o.value" :key="o.text">
            {{ o.text }}
          </option>
        </template>
      </b-select>
      <div class="field">
        <b-checkbox v-model="BSAndPL.force">force</b-checkbox>
      </div>
      <button class="button button-margin" @click="onUpdateBSAndPLChartData">
        更新
      </button>
    </section>
    <hr />
    <section class="section">
      <h1>プレミアムの更新</h1>
      <button class="button" @click="onUpdateMembership">更新</button>
    </section>
    <hr />
    <section class="section">
      <b-field label="UID">
        <b-input type="text" v-model="uid" />
      </b-field>
      <div class="field">
        <b-checkbox v-model="admin">admin</b-checkbox>
      </div>
      <b-button @click="onChangeAdminStatus">変更</b-button>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
export default {
  name: "admin-setting-admin",
  data: function () {
    return {
      mail: {
        id: null,
      },
      BSAndPL: {
        year: null,
        quarter: null,
        force: false,
      },
      uid: null,
      admin: false,
    };
  },
  methods: {
    async onSendMail() {
      const loadingComponent = this.$loading.open();
      try {
        await firebase.functions("sendMail", {
          id: this.mail.id,
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onRestoreExclusiveMessageCount() {
      const loadingComponent = this.$loading.open();
      try {
        const result = await this.$$admin$restoreExclusiveMessageCount();
        if (result) {
          this.$$toast$openSuccess("初期化を実行しました");
        } else {
          this.$$toast$openError("初期化を実行出来ませんでした");
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onUpdateBSAndPLChartData() {
      const loadingComponent = this.$loading.open();
      try {
        const result = await this.$$admin$updateBSAndPLChartData(this.BSAndPL);
        // const result = await this.$$admin$updateBSAndPLChartData({
        //   year: this.selectedYear,
        //   quarter: this.selectedQuarter,
        //   force: this.force,
        // });
        if (result) {
          this.$$toast$openSuccess("更新を実行しました");
        } else {
          this.$$toast$openError("更新を実行出来ませんでした");
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onUpdateMembership() {
      const loadingComponent = this.$loading.open();
      try {
        const result = await this.$$admin$updateMembership();
        if (result) {
          this.$$toast$openSuccess("更新を実行しました");
        } else {
          this.$$toast$openError("更新を実行出来ませんでした");
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onChangeAdminStatus() {
      const loadingComponent = this.$loading.open();
      try {
        const result = await this.$$admin$changeAdminStatus(
          this.uid,
          this.admin
        );
        if (result) {
          this.$$toast$openSuccess("変更が完了しました");
        }
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.button-margin {
  margin-top: 20px;
}
</style>
