<template>
  <div class="z-layout">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
    >
      <div class="z-layout__inputs">
        <b-select
          class="z-layout__inputs__layout"
          :name="internalName"
          v-model="internalValue.layout"
          :data-vv-as="label"
          v-validate="validate"
          placeholder="間取を選択してください"
        >
          <option v-for="o in layouts" :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </b-select>

        <b-input
          class="z-layout__inputs__numberOfUnits"
          :name="internalName"
          v-model.number="internalValue.NumberOfUnits"
          :data-vv-as="label"
          v-validate="validate"
          placeholder="例) 3"
          type="number"
          unit="戸"
        />
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZLayoutSelect",
  mixins: [InputMixin],
  inject: ["$validator"],
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  data() {
    return {
      layouts: kubuns.layouts,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-layout {
  &__inputs {
    &__layout,
    &__numberOfUnits {
      display: inline-block;
    }
    &__layout {
      margin-right: 22px;
      /deep/ select {
        min-width: 200px;
        @include sp {
          min-width: 120px;
        }
      }
    }
    &__numberOfUnits {
      &:before {
        content: "戸";
        position: absolute;
        font-size: 16px;
        top: 8px;
        right: 8px;
      }
      /deep/ .select {
        select {
          // min-width: 200px;
          @include sp {
            min-width: 90px;
          }
        }
      }
    }
  }
}
</style>
