<template>
  <div>
    <router-view></router-view>
    <section class="section" v-if="!isLoading">
      <div class="content">
        <h4>売買情報一覧</h4>
      </div>
      <InvestorTransactionList
        :investorTransactions="investorTransactions"
      ></InvestorTransactionList>
    </section>
  </div>
</template>

<script>
import InvestorTransactionList from "@/views/admin/components/InvestorTransactionList";

export default {
  name: "admin-setting-investors-transaction",
  components: {
    InvestorTransactionList: InvestorTransactionList,
  },
  data: function () {
    return {
      isLoading: true,
      investorTransactions: [],
    };
  },
  methods: {
    async init() {
      const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.investorTransactions =
          await this.$$transaction$loadTransactionsWithCompany();
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        loadingComponent.close();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
