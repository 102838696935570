<template>
  <div>
    <template v-if="realEstates.length > 0">
      <template v-for="r in realEstates">
        <div :key="r._id">
          <RealEstateCard :realEstate="r" />
        </div>
      </template>
    </template>
    <template v-else> </template>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
import RealEstateCard from "@/components/realtor/RealEstateCard";

export default {
  name: "relator-RealEstateList",
  components: {
    RealEstateCard,
  },
  props: {
    realEstates: {
      type: [Array],
      default: () => [],
    },
    // total: {
    //   type: [Number],
    //   required: true,
    // },
    // perPage: {
    //   type: [Number],
    //   default: 50
    // },
    // page: {
    //   type: [Number],
    //   default: 1
    // },
    isLoading: {
      type: [Boolean],
      default: false,
    },
  },
};

// export default {
//   name: "relator-RealEstateList",
//   props: {
//     realEstates: {
//       type: [Array],
//       default: () => []
//     },
//     checkable: {
//       type: [Boolean],
//       default: false
//     },
//     value: {
//       type: [Array],
//       default: () => []
//     },
//     hideModifybutton: {
//       type: [Boolean],
//       default: false
//     },
//     isLoading: {
//       type: [Boolean],
//       default: false
//     }
//   },
//   data: function() {
//     return {
//       //isLoading: true,
//       currentPage: 0
//       //realEstates: []
//     };
//   },
//   computed: {
//     internalValue: {
//       get() {
//         return this.value;
//       },
//       set(newVal) {
//         if (this.value !== newVal) this.$emit("input", newVal);
//       }
//     }
//   },
//   methods: {
//     images(realEstate) {
//       if (realEstate) {
//         return []
//           .concat(realEstate.data.images.exteriors)
//           .concat(realEstate.data.images.interiors)
//           .concat(realEstate.data.images.others);
//       } else {
//         return [];
//       }
//     },
//     async address(dt) {
//       const text = await this.$$area$generateTextOfAddress(dt);
//       return text;
//     },
//     overseasAddress(val) {
//       if (val.building) {
//         return (
//           this.addressText(val.country) +
//           ", " +
//           val.state +
//           ", " +
//           val.address +
//           ", " +
//           val.building
//         );
//       } else {
//         return (
//           this.addressText(val.country) + ", " + val.state + ", " + val.address
//         );
//       }
//     },
//     countOffers(realEstate) {
//       return realEstate.messages.filter(
//         r => r.data.type === 1 || r.data.type === 2
//       ).length;
//     },
//     //countNormal(realEstate) {
//     //  return realEstate.messages.filter(r => r.data.type === 2).length;
//     //},
//     //countBuying(realEstate) {
//     //  //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
//     //  //{ value: 2, text: "オファー", color: "is-info" },
//     //  //{ value: 3, text: "売却", color: "is-primary" },
//     //  //{ value: 4, text: "問い合せ", color: "" }
//     //  return realEstate.messages.filter(r => r.data.type === 3).length
//     //},
//     countReply(realEstate) {
//       return realEstate.messages
//         .filter(
//           m => m.data.type === 1 || m.data.type === 2 || m.data.type === 3
//         )
//         .filter(m => {
//           const uid = m.data.investor;
//           return m.data.threads.some(t => t.from === uid);
//         }).length;
//     },
//     countContact(realEstate) {
//       return realEstate.messages.filter(r => r.data.type === 4).length;
//     },
//     //async loadRealEstate(r) {
//     //  r.address = await this.address(r.data);
//     //  r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
//     //  return r;
//     //},
//     //async init() {
//     //  const loadingComponent = this.$loading.open();
//     //  try {
//     //    this.isLoading = true;
//     //    const res = await this.$$realtor$loadRealEstatesByCID(
//     //      this.me.user.company.id
//     //    );
//     //    const promises = [];
//     //    for (const r of res) {
//     //      promises.push(this.loadRealEstate(r));
//     //    }
//     //    this.realEstates = await Promise.all(promises).then(rs => rs);
//     //    this.isLoading = false;
//     //    return;
//     //  } catch (error) {
//     //    this.$$error$catchLoadError(error);
//     //  } finally {
//     //    loadingComponent.close();
//     //  }
//     //},
//     buildingTypeText(val) {
//       return this.$$ks.getTypeOfBuildingType(val).text;
//     },
//     addressText(val) {
//       return this.$$ks.getCountry(val).text;
//     }
//   }
//   //beforeRouteUpdate(to, from, next) {
//   //  next();
//   //  return this.init();
//   //},
//   //mounted() {
//   //  this.init();
//   //}
// };
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

// .consultant-warp {
//   margin: 0 10px;
//   @include mobile {
//     margin: 0;
//   }
// }
// //.list-title {
// //  width: 100%;
// //  margin-top: 0px;
// //  margin-bottom: 0px;
// //  padding: 11px 22px;
// //  background-color: $important;
// //  border-bottom: solid 2px #707070;
// //  color: $white;
// //}
// .realtor-realestate-image {
// }
// .realestate {
//   &__list {
//     width: 100%;
//     height: 756px;
//     background-color: $mintrich-blank;
//   }
// }
//
// .button {
//   margin: 20px;
// }
//
// .content {
//   p {
//     margin: 2.5px 0;
//   }
//   p:nth-child(2) {
//     font-size: 1.5em;
//     font-weight: bold;
//     //white-space:nowrap;
//   }
//   p:nth-child(3) {
//     font-weight: bold;
//     font-size: 1.5em;
//     color: red;
//   }
//   .flex-wrap {
//     display: flex;
//
//     p {
//       font-size: 1em;
//       font-weight: lighter;
//     }
//     p:nth-child(1) {
//       margin-right: 30px;
//     }
//   }
// }
//
// .image {
//   max-width: 180px;
//   max-height: 180px;
// }
//
// .real-estate-name {
//   font-size: 18px;
// }
</style>
