import firebase from "@/plugins/firebase";
// import kubuns from "@/kubuns/kubuns";

export const inquire = async (uid, request) => {
  const ret = await firebase.functions("zero-inquiry-inquireForSTF", {
    uid,
    request,
  });
  return ret.data;
};

export const uninquire = async (uid) => {
  const ret = await firebase.functions("zero-inquiry-uninquireForSTF", {
    uid,
  });
  return ret.data;
};
