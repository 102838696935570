<template>
  <div>
    <section>
      <ZHeader title="Information" sub="お知らせ">
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section" v-if="!isLoading">
      <div class="z-section__inner">
        <h4 class="list-title">お知らせ</h4>
        <InformationList :informations="informations" />
      </div>
    </div>
  </div>
</template>

<script>
//import firebase from "@/plugins/firebase";
import InformationList from "@/components/parts/InformationList";
export default {
  name: "investor-informations-index",
  components: {
    InformationList: InformationList,
  },
  data: function () {
    return {
      isLoading: true,
      informations: [],
    };
  },
  methods: {
    async init() {
      //const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        this.informations = await this.$$information$loadsByUID(
          this.me.user.id
        );
        for (const info of this.informations) {
          this.$$information$asRead(this.me.user.id, info);
        }
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //this.$nextTick(() => {
        //  for (const info of this.informations) {
        //    this.$$information$asRead(this.me.user.id, info);
        //  }
        //  console.log(this.$refs);
        //  this.widthOfMessageBox = this.$refs.messagebox.clientWidth - 32;
        //});
        //loadingComponent.close();
      }
    },
    //latestThread(message) {
    //  const m = message.data.threads.slice(-1)[0];
    //  const ln = m.contents.replace("\n", "");
    //  return ln;
    //  //return this.transcate(ln, 60);
    //}
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.message {
  &__list {
    width: 100%;
  }
}

//.list-title {
//  width: 100%;
//  margin-top: 0px;
//  margin-bottom: 0px;
//  padding: 4px 22px;
//  background-color: #dbdbdb;
//  border-bottom: solid 2px #707070;
//  color: black;
//}

.is-elliptical {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: none !important;
}
</style>
