<template>
  <div
    class="z-blank-box"
    v-bind:class="{
      border: borderActive,
      roundEdge: roundEdgeActive,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ZBlankBox",
  props: {
    borderActive: {
      type: Boolean,
      default: true,
    },
    roundEdgeActive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-blank-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}
.border {
  border: solid 1px $input-border-color;
}
.roundEdge {
  border-radius: 5px;
}
</style>
