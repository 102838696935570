<template>
  <div>
    <router-view></router-view>
    <hr />
    <section class="section">
      <b-tabs :animated="false" size="is-small" v-model="activeTab">
        <b-tab-item label="抽出">
          <button @click="onClickLine" class="button is-primary">抽出</button>
          <b-tabs :animated="false" size="is-small" v-model="activeTabSub">
            <b-tab-item label="stationデータ">
              <hr />
              <table>
                <thead>
                  <tr>
                    <th>v1_station_cd</th>
                    <th>v1_line_cd</th>
                    <th>v1_station_name</th>
                    <th>v1_line_name</th>
                    <th>v2_station_cd</th>
                    <th>v2_line_cd</th>
                    <th>station_name</th>
                    <th>line_name</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="l in stations">
                    <tr :key="l.v1_station.station_cd">
                      <td>
                        {{ l.v1_station.station_cd }}
                      </td>
                      <td>
                        {{ l.v1_station.line_cd }}
                      </td>
                      <td>
                        {{ l.v1_station.station_name }}
                      </td>
                      <td>
                        {{ l.v1_line.line_name }}
                      </td>
                      <td>
                        {{ l.v2_station.station_cd }}
                      </td>
                      <td>
                        {{ l.v2_station.line_cd }}
                      </td>
                      <td>
                        {{ l.v2_station.station_name }}
                      </td>
                      <td>
                        {{ l.v2_line.line_name }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
        <b-tab-item label="チェック用">
          <button @click="onClickCheck" class="button is-primary">出力</button>
          <hr />
          <table>
            <thead>
              <tr>
                <th>name</th>
                <th>id</th>
                <th>data</th>
                <th>v1</th>
                <th>v2</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(l, idx) in checks">
                <tr :key="idx">
                  <td>
                    {{ l.name }}
                  </td>
                  <td>
                    {{ l.id }}
                  </td>
                  <td>
                    {{ l.data }}
                  </td>
                  <td>
                    {{ l.v1 }}
                  </td>
                  <td>
                    {{ l.v2 }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </b-tab-item>
        <b-tab-item label="v1=>v2">
          <button @click="onClickFromV1ToV2" class="button is-primary">
            v2生成ALL
          </button>
          <hr />
          <button
            @click="onClickFromV1ToV2ForRealestate"
            class="button is-primary"
          >
            v2生成REALESTATE
          </button>
          <button
            @click="onClickFromV1ToV2ForRealtorRealestate"
            class="button is-primary"
          >
            v2生成REALTOR-REALESTATE
          </button>
          <button
            @click="onClickFromV1ToV2ForBuyningNeeds"
            class="button is-primary"
          >
            v2生成BUYNING-NEEDS
          </button>
          <hr />
          <button
            @click="onClickFromV2ToV1ForRealestate"
            class="button is-danger"
          >
            =>V1 REALESTATE
          </button>
          <button
            @click="onClickFromV2ToV1ForRealtorRealestate"
            class="button is-danger"
          >
            =>v1 REALTOR-REALESTATE
          </button>
          <button
            @click="onClickFromV2ToV1ForBuyningNeeds"
            class="button is-danger"
          >
            =>v1 BUYNING-NEEDS
          </button>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import * as lineV1M from "@/modules/line/line_v1";
// import * as lineM from "@/modules/line/line";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

const getLineByV1Code = async (code) => {
  if (!code) return null;
  const qs = await firebase.db
    .collection("lines_v2")
    .where("sf_line_cds", "array-contains", code)
    .limit(1)
    .get();

  // for (const doc of qs.docs) {
  //   console.log(doc.data());
  // }
  // return true;
  const doc = qs.docs[0];
  return doc ? doc.data() : null;
};

const getStationByV1Code = async (code) => {
  if (!code) return null;
  const qs = await firebase.db
    .collection("stations_v2")
    .where("sf_station_cd", "==", code)
    .limit(1)
    .get();

  // for (const doc of qs.docs) {
  //   console.log(doc.data());
  // }
  // return true;
  const doc = qs.docs[0];
  return doc ? doc.data() : null;
  // return qs.docs[0];
};

export default {
  name: "admin-demo-master",
  data() {
    return {
      activeTab: 0,
      activeTabSub: 0,
      lines: [],
      stations: [],
      checks: [],
    };
  },
  methods: {
    async onClickFromV2ToV1ForRealtorRealestate() {
      const loadingComponent = this.$loading.open();
      try {
        const qs = await firebase.db.collection("realtor_realEstates_v1").get();

        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (data.v2) {
            console.log("REALTOR-REALESTATES:" + id);
            await firebase.functions("v2-migration-realtor_realestate_to_v1", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP REALTOR-REALESTATES:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV2ToV1ForBuyningNeeds() {
      // await firebase
      //   .functions("v2-migration-users_to_v1", {
      //     id: 'SqJYt6RVweWzgcJvrCFzFjsSqqj1'
      //   });
      const loadingComponent = this.$loading.open();
      try {
        const qs = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .get();

        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (data.v2) {
            console.log("USERS:" + id);
            await firebase.functions("v2-migration-users_to_v1", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP USERS:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV2ToV1ForRealestate() {
      const loadingComponent = this.$loading.open();
      try {
        const qs = await firebase.db.collection("realEstates_v1").get();
        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (data.v2) {
            console.log("REALESTATES:" + id);
            await firebase.functions("v2-migration-realestate_to_v1", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP REALESTATES:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV1ToV2() {
      const loadingComponent = this.$loading.open();
      try {
        await this.onClickFromV1ToV2ForRealestate();
        await this.onClickFromV1ToV2ForRealtorRealestate();
        await this.onClickFromV1ToV2ForBuyningNeeds();
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV1ToV2ForBuyningNeeds() {
      const loadingComponent = this.$loading.open();
      try {
        // await firebase
        //   .functions("v2-migration-users", {
        //     id: '1TbRsxaoZjSXV45av6ciViZKheK2'
        //   });
        const qs = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .get();
        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (!data.v2) {
            console.log("USERS:" + id);
            await firebase.functions("v2-migration-users", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP USERS:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV1ToV2ForRealtorRealestate() {
      const loadingComponent = this.$loading.open();
      try {
        // await firebase.functions("v2-migration-realtor_realestate", {
        //   id: "03vJbHcnzutjM4EUtFft",
        // });

        const qs = await firebase.db.collection("realtor_realEstates_v1").get();
        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (!data.v2) {
            console.log("REALTOR-REALESTATES:" + id);
            await firebase.functions("v2-migration-realtor_realestate", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP REALTOR-REALESTATES:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickFromV1ToV2ForRealestate() {
      const loadingComponent = this.$loading.open();
      try {
        // await firebase.functions("v2-migration-realestate", {
        //   id: '0IIn05thmYOx6lanmsvO'
        // });

        const qs = await firebase.db.collection("realEstates_v1").get();
        for (const doc of qs.docs) {
          const data = doc.data();
          const id = doc.id;
          if (!data.v2) {
            console.log("REALESTATES:" + id);
            await firebase.functions("v2-migration-realestate", {
              id: id,
            });
            sleep(1000);
          } else {
            console.log("SKIP REALESTATES:" + id);
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickCheck() {
      const loadingComponent = this.$loading.open();
      try {
        this.checks = [];
        const qs = await firebase.db.collection("realEstates_v1").get();
        for (const doc of qs.docs) {
          const transports = doc.data().transports;
          // const v1_transports = (doc.data().transports_v1 || []).filter(r => r.line);
          const v1_transports = (doc.data().transports_v1 || []).filter(
            (r) => r.line
          );
          // const v2_transports = (doc.data().transports_v2 || []).filter(
          //   r => r.line
          // );
          const v2_transports = (doc.data().transports || []).filter(
            (r) => r.line
          );
          if (v1_transports.length !== v2_transports.length) {
            this.checks.push({
              name: "RS",
              id: doc.id,
              data: transports,
              v1: v1_transports,
              v2: v2_transports,
            });
          }
        }

        const qs2 = await firebase.db
          .collection("realtor_realEstates_v1")
          .get();

        for (const doc of qs2.docs) {
          const transports = doc.data().transports;
          const v1_transports = doc.data().transports_v1 || [];
          // const v2_transports = doc.data().transports_v2 || [];
          const v2_transports = doc.data().transports || [];
          if (v1_transports.length !== v2_transports.length) {
            this.checks.push({
              name: "RR",
              id: doc.id,
              data: transports,
              v1: v1_transports,
              v2: v2_transports,
            });
          }
        }

        const qs3 = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .get();

        for (const doc of qs3.docs) {
          const bns = doc.data().buyingNeeds;
          const bnsv1 = doc.data().buyingNeeds_v1 || [];
          const bnsv2 = doc.data().buyingNeeds_v2 || [];

          const tr_bns = bns
            .filter((b) => b.Line.isSelected)
            .map((b) => b.Line)
            .map((l) => {
              return {
                prefecture: l.prefecture,
                line: l.line,
                stations: l.stations,
              };
            });
          const tr_bnsv1 = bnsv1
            .filter((b) => b.Line.isSelected)
            .map((b) => b.Line)
            .map((l) => {
              return {
                prefecture: l.prefecture,
                line: l.line,
                stations: l.stations,
              };
            });

          const tr_bnsv2 = bnsv2
            .filter((b) => b.Line.isSelected)
            .map((b) => b.Line)
            .map((l) => {
              return {
                prefecture: l.prefecture,
                line: l.line,
                stations: l.stations,
              };
            });

          for (var _i = 0; _i < tr_bnsv1.length; _i++) {
            const d = tr_bns[_i];
            const v1 = tr_bnsv1[_i];
            const v2 = tr_bnsv2[_i];
            if (
              v1.prefecture !== v2.prefecture ||
              v1.stations.length !== v2.stations.length ||
              !v2.stations ||
              !v2.line
            ) {
              this.checks.push({
                name: "IN",
                id: doc.id,
                data: d,
                v1,
                v2,
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async onClickLine() {
      const loadingComponent = this.$loading.open();
      try {
        this.lines = [];
        const ls = [];
        const qs = await firebase.db.collection("realEstates_v1").get();
        for (const doc of qs.docs) {
          const data = doc.data();
          Array.prototype.push.apply(ls, data.transports);
        }

        const qs2 = await firebase.db
          .collection("realtor_realEstates_v1")
          .get();
        for (const doc of qs2.docs) {
          const data = doc.data();
          Array.prototype.push.apply(ls, data.transports);
        }

        const qs3 = await firebase.db
          .collection("users_v1")
          .where("isInvestor", "==", true)
          .get();
        for (const doc of qs3.docs) {
          const data = doc.data();
          // console.log(data.buyingNeeds);
          for (const bn of data.buyingNeeds) {
            if (bn.Line.isSelected) {
              for (const st of bn.Line.stations) {
                ls.push({
                  line: bn.Line.line,
                  station: st,
                  elapsedTime: 0,
                });
              }
            }
          }
        }

        for (const l of ls) {
          await sleep(100);
          if (
            !this.stations.some((x) => x.v1_station.station_cd === l.station)
          ) {
            const st = await lineV1M.loadStation(l.station);
            const ln = await lineV1M.load(l.line);

            const nst = await getStationByV1Code(l.station);
            const nln = await getLineByV1Code(l.line);

            console.log(st);
            console.log(ln);
            console.log(nst);
            console.log(nln);

            if (st && ln) {
              this.stations.push({
                v1_station: st.station,
                v1_line: ln.line,
                v2_station: nst ? nst.station : {},
                v2_line: nln ? nln.line : {},
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
