<template>
  <section>
    <template v-if="realEstate.data.buildingUse !== 2">
      <div class="titlebar">
        <div class="titlebar__inner">
          <h2 class="titlebar__title">
            {{ titleFix(count, "過去投資履歴") }}
          </h2>
        </div>
      </div>
      <div class="rsOverview">
        <h3 class="rsOverview__title">物件概要</h3>
        <div class="rsOverview__sub">
          <div class="rsOverview__sub__type">
            {{ $$ks.realEstateType(realEstate.data.realEstateType).text }}
          </div>
          <div class="rsOverview__sub__buyingDt">
            購入年月
            {{ $$calender$yearMonthFormat(realEstate.data.purchase.buyingDt) }}
          </div>
        </div>
        <div class="columns rsOverview__main">
          <!-- 購入価格・表面利回り -->
          <div
            class="column col-wrap rsOverview__main__price-and-coupon-yield-rate"
          >
            <dl class="rsOverview__borderDl">
              <dt>購入価格</dt>
            </dl>
            <div class="rsOverview__prices">
              <div
                v-for="(price, index) in prices"
                class="rsOverview__prices__value"
                :key="index"
              >
                {{ price.text }}
              </div>
            </div>
            <div class="rsOverview__graph">
              <div
                v-for="(i, index) in 10"
                class="rsOverview__graph__box"
                :key="index"
                :class="{
                  'rsOverview__graph__box-price': purchasePrice === i,
                }"
              ></div>
            </div>
            <dl class="rsOverview__borderDl">
              <dt>表面利回り</dt>
            </dl>
            <div class="rsOverview__coupon-yield-rates">
              <div
                v-for="(couponYieldRate, index) in couponYieldRates"
                class="rsOverview__coupon-yield-rates__value"
                :key="index"
              >
                {{ couponYieldRate.text }}
              </div>
            </div>
            <div class="rsOverview__graph">
              <div
                v-for="(i, index) in 10"
                class="rsOverview__graph__box"
                :key="index"
                :class="{
                  'rsOverview__graph__box-coupon-yield-rate':
                    couponYieldRate === i,
                }"
              ></div>
            </div>
          </div>
          <!-- 購入価格・表面利回り -->
          <!-- 物件情報 -->
          <div class="column col-wrap rsOverview__detail">
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label"> 所在地 </span>
              </dt>
              <dd>
                {{ $$ks.getPrefecture(realEstate.data.prefecture).text }}
                {{ cityInfo }}
              </dd>
            </dl>
            <dl class="rsOverview__labelDl">
              <dt>
                <span class="rsOverview__labelDl__label"> 交通 </span>
              </dt>
              <dd>
                <ul>
                  <li v-for="(transport, index) in transports" :key="index">
                    {{ transport }}
                  </li>
                </ul>
              </dd>
            </dl>

            <div class="columns columns--noGap is-desktop">
              <div class="column col-wrap">
                <dl class="rsOverview__labelDl">
                  <dt>
                    <span class="rsOverview__labelDl__label">
                      建物種別
                      <z-help-icon
                        help="建物の構造。「SRC」（＝鉄骨鉄筋コンクリート造）、「RC」（＝鉄筋コンクリート造）など"
                        onlyPC
                      />
                    </span>
                  </dt>
                  <template v-if="realEstate.data.buildingType">
                    <dd>
                      {{
                        $$ks.getTypeOfBuildingType(realEstate.data.buildingType)
                          .text
                      }}
                    </dd>
                  </template>
                  <template v-else>
                    <dd>-</dd>
                  </template>
                </dl>
              </div>
              <div class="column col-wrap">
                <dl class="rsOverview__labelDl">
                  <dt>
                    <span class="rsOverview__labelDl__label"> 築年月 </span>
                  </dt>
                  <dd>
                    <template
                      v-if="
                        realEstate.data.realEstateType === 10 ||
                        realEstate.data.realEstateType === 7
                      "
                    >
                      <span>-</span>
                    </template>
                    <template v-else>
                      {{
                        $$calender$yearMonthFormat(
                          realEstate.data.constructionDate
                        )
                      }}(築{{
                        $$realEstate$calcYearOfConstruction(
                          realEstate.data.constructionDate
                        )
                      }}年)
                    </template>
                  </dd>
                </dl>
              </div>
            </div>

            <div class="columns columns--noGap is-desktop">
              <div class="column col-wrap">
                <dl class="rsOverview__labelDl">
                  <dt>
                    <span class="rsOverview__labelDl__label"> 建物面積 </span>
                  </dt>
                  <template v-if="realEstate.data.buildingArea">
                    <dd>{{ realEstate.data.buildingArea }}㎡</dd>
                  </template>
                  <template v-else>
                    <dd>-</dd>
                  </template>
                </dl>
              </div>
              <div class="column columns--noGap col-wrap">
                <dl class="rsOverview__labelDl">
                  <dt>
                    <span class="rsOverview__labelDl__label">
                      階数
                      <z-help-icon help="建物の階数、及び物件の階数。" onlyPC />
                    </span>
                  </dt>
                  <template v-if="realEstate.data.numberOfFloors">
                    <dd>
                      {{ realEstate.data.numberOfFloors }}
                    </dd>
                  </template>
                  <template v-else>
                    <dd>-</dd>
                  </template>
                </dl>
              </div>
            </div>

            <div class="columns is-desktop">
              <div class="column col-wrap">
                <dl class="rsOverview__labelDl">
                  <dt>
                    <span class="rsOverview__labelDl__label"> 土地面積 </span>
                  </dt>
                  <dd>{{ realEstate.data.saleSetting.landArea }}㎡</dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- /物件情報 -->
        </div>
      </div>
    </template>
  </section>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "investorDetail",
  data: function () {
    return {
      prices: [
        { value: 1, text: "0万" },
        { value: 2, text: "2,000万" },
        { value: 3, text: "3,000万" },
        { value: 4, text: "4,000万" },
        { value: 5, text: "5,000万" },
        { value: 6, text: "1億" },
        { value: 7, text: "2億" },
        { value: 8, text: "3億" },
        { value: 9, text: "4億" },
        { value: 10, text: "5億〜" },
      ],
      couponYieldRates: [
        { value: 1, text: "0%" },
        { value: 2, text: "4%" },
        { value: 3, text: "5%" },
        { value: 4, text: "6%" },
        { value: 5, text: "7%" },
        { value: 6, text: "8%" },
        { value: 7, text: "9%" },
        { value: 8, text: "10%" },
        { value: 9, text: "15%" },
        { value: 10, text: "20%〜" },
      ],
    };
  },
  props: {
    realEstate: {
      type: Object,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
  },
  asyncComputed: {
    async cityInfo() {
      const result = await this.$$city$load(
        this.realEstate.data.prefecture,
        this.realEstate.data.city
      );
      return result.cityName;
    },
    async transports() {
      const text = await this.$$realEstate$transportListFormt(
        this.realEstate.data.transports
      );
      return text;
    },
  },
  computed: {
    purchasePrice() {
      if (this.realEstate.data.purchase.amount < 20000000) {
        return 1;
      } else if (this.realEstate.data.purchase.amount < 30000000) {
        return 2;
      } else if (this.realEstate.data.purchase.amount < 40000000) {
        return 3;
      } else if (this.realEstate.data.purchase.amount < 50000000) {
        return 4;
      } else if (this.realEstate.data.purchase.amount < 100000000) {
        return 5;
      } else if (this.realEstate.data.purchase.amount < 200000000) {
        return 6;
      } else if (this.realEstate.data.purchase.amount < 300000000) {
        return 7;
      } else if (this.realEstate.data.purchase.amount < 400000000) {
        return 8;
      } else if (this.realEstate.data.purchase.amount < 500000000) {
        return 9;
      } else {
        return 10;
      }
    },
    couponYieldRate() {
      if (this.realEstate.data.couponYieldRate < 0.04) {
        return 1;
      } else if (this.realEstate.data.couponYieldRate < 0.05) {
        return 2;
      } else if (this.realEstate.data.couponYieldRate < 0.06) {
        return 3;
      } else if (this.realEstate.data.couponYieldRate < 0.07) {
        return 4;
      } else if (this.realEstate.data.couponYieldRate < 0.08) {
        return 5;
      } else if (this.realEstate.data.couponYieldRate < 0.09) {
        return 6;
      } else if (this.realEstate.data.couponYieldRate < 0.1) {
        return 7;
      } else if (this.realEstate.data.couponYieldRate < 0.15) {
        return 8;
      } else if (this.realEstate.data.couponYieldRate < 0.2) {
        return 9;
      } else {
        return 10;
      }
    },
  },
  methods: {
    titleFix(index, prefix) {
      const fixedIndex = index + 1;
      if (fixedIndex <= 9) {
        return prefix + "0" + String(fixedIndex);
      } else {
        return prefix + String(fixedIndex);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.titlebar {
  padding: 6px 0;
  background-color: #dbdbdb;
  color: #787878;
  &__inner {
    margin-left: 15px;
  }
  &__title {
    position: relative;
    font-size: 24px;
    @include sp {
      text-align: left;
    }
    &__icon {
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      transform: translateY(-50%);
    }
  }
}

.rsOverview {
  &__main {
    margin-bottom: 35px;
    &__price-and-coupon-yield-rate {
      @include sp {
        padding-right: 5px;
      }
    }
    .is-desktop {
      margin-top: 0;
      @include md {
        display: block;
      }
    }
  }
  .col-wrap {
    padding-top: 0;
    padding-bottom: 0;
    &:not(:first-child) {
      @include sp {
        margin-top: 16px;
      }
    }
  }
  &__detail {
    @include md {
      width: 45%;
      flex: none;
    }
  }
  &__title {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 20px;
    border-bottom: solid 1px #ddd;
    @include sp {
      margin-bottom: 5px;
      font-size: 18px;
      text-align: left;
    }
  }
  &__sub {
    overflow: hidden;
    margin-bottom: 20px;
    &__type {
      float: left;
      font-size: 18px;
      @include sp {
        font-size: 16px;
      }
    }
    &__buyingDt {
      float: right;
      padding-top: 6px;
      font-size: 12px;
    }
  }
  &__borderDl {
    &:not(:last-child) {
      margin-bottom: 20px;
      @include sp {
        margin-bottom: 8px;
      }
    }
    display: float;
    border-bottom: solid 1px #ddd;
    overflow: hidden;
    & > dt {
      float: left;
      margin-top: 8px;
      font-size: 14px;
    }
    & > dd {
      float: right;
      font-size: 22px;
      text-align: right;
    }
  }
  &__labelDl {
    margin-bottom: 16px;
    display: table;
    table-layout: fixed;
    & > dt {
      display: table-cell;
      min-width: 90px;
      .rsOverview__labelDl__label {
        display: block;
        padding: 2px;
        font-size: 14px;
        background-color: #f4f4f4;
        text-align: center;
        vertical-align: middle;
        line-height: 1em;
        font-weight: bold;
        color: #777777;
      }
    }
    & > dd {
      display: table-cell;
      width: 100%;
      padding-left: 26px;
      font-size: 15px;
      @include sp {
        font-size: 14px;
        text-align: left;
      }
    }
    &__icon {
      margin-left: 4px;
    }
  }
  &__prices {
    :first-child {
      width: 8%;
    }
    :nth-child(5) {
      margin-right: 5px;
    }
    &__value {
      display: inline-block;
      width: 10%;
      @include sp {
        font-size: 12px;
        text-align: left;
      }
    }
  }
  &__coupon-yield-rates {
    :nth-child(7) {
      width: 9%;
    }
    &__value {
      display: inline-block;
      width: 10%;
      @include sp {
        font-size: 12px;
        text-align: left;
      }
    }
  }
  &__graph {
    margin: 0 0 20px 10px;
    @include sp {
      margin-left: 7px;
    }
    &__box {
      display: inline-block;
      width: 10%;
      height: 25px;
      border: thin solid #dbdbdb;
    }
    &__box-price {
      background-color: #183ba0;
    }
    &__box-coupon-yield-rate {
      background-color: #0ab937;
    }
  }
}
</style>
