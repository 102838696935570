<template>
  <div>
    <z-select
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :hideRequired="hideRequired"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
    >
    </z-select>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";
export default {
  name: "ZPrefectureSelect",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Number],
      default: null,
    },
  },
  computed: {
    options() {
      return this.$$ks.prefectures;
    },
  },
  watch: {
    internalValue(val, old) {
      this.$emit("changePrefecture", {
        initial: !old,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
