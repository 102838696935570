<template>
  <div class="z-form">
    <ZField :label="label" :isRequired="isRequired" :isShort="isShort">
      <div class="z-form__input">
        <b-input
          :placeholder="placeholder || label + 'を入力してください'"
          class="couponYieldRate"
          v-model="internalValue"
          :disabled="disabled"
        >
        </b-input>
      </div>
    </ZField>
  </div>
</template>

<script>
import InputMixin from "@/components/parts/InputMixin";

export default {
  name: "CouponYieldRate",
  mixins: [InputMixin],
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-form {
  &__input {
    position: relative;
    .is-short & {
      display: inline-block;
    }
  }
}

.couponYieldRate {
  &::after {
    content: "%";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }
}
</style>
