<template>
  <div class="realtor-profile">
    <template v-if="!bulma.isMobile">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image realtor-profile__image">
            <z-profile-thumbnail v-model="user.data.profile.image" />
          </figure>
        </div>
        <div
          class="column"
          :class="{ 'layout-by-score': user.data.score.score === 0 }"
        >
          <div class="content is-small">
            <p class="rank-score">
              <template v-if="user.data.score.score > 0">
                <template v-if="user.data.score.rank === 1">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-S.png" />
                  </span>
                  <span class="score rank-s"> {{ user.data.score.score }}</span>
                </template>
                <template v-else-if="user.data.score.rank === 2">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-A.png" />
                  </span>
                  <span class="score rank-a"> {{ user.data.score.score }}</span>
                </template>
                <template v-else-if="user.data.score.rank === 3">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-B.png" />
                  </span>
                  <span class="score rank-b"> {{ user.data.score.score }}</span>
                </template>
                <template v-else-if="user.data.score.rank === 4">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-C.png" />
                  </span>
                  <span class="score rank-c"> {{ user.data.score.score }}</span>
                </template>
              </template>
              <!-- <template v-else>
                <span class="score-zero">NEW</span>
              </template> -->
            </p>
            <p>
              <span class="is-size-4">{{ user.data.base.name }}</span
              ><br />
              <span class="is-size-7 has-text-gray gray"
                >{{ user.company.data.name }}&nbsp;</span
              >
              <span class="is-size-7 has-text-gray gray"
                >{{ user.data.company.department }}&nbsp;</span
              >
              <span class="is-size-7 has-text-gray gray">{{
                user.data.company.title
              }}</span>
            </p>
            <hr />
            <div class="flex-wrapper">
              <span class="is-size-7 is-gray">不動産経験: </span><br />
              <span class="year">
                {{
                  $$ks.realtor.getYearOfexperience(
                    user.data.profile.yearOfexperience
                  ).text
                }}
              </span>
            </div>
            <div class="flex-wrapper">
              <span class="is-size-7 is-gray">得意領域:<br /></span>
              <div class="goodarea">
                <template
                  class="begoodat-wrapper"
                  v-for="(sp, idx) in user.data.profile.specialties"
                >
                  <span :key="idx" class="begoodat">
                    {{ $$ks.realtor.getSpecialty(sp).text }}&nbsp;&nbsp;
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="columns is-multiline is-mobile">
        <div class="column is-narrow">
          <figure class="image realtor-profile__image">
            <z-profile-thumbnail v-model="user.data.profile.image" />
          </figure>
        </div>
        <div
          class="column"
          :class="{ 'layout-by-score': user.data.score.score === 0 }"
        >
          <div class="content is-small">
            <p class="rank-score">
              <template v-if="user.data.score.score > 0">
                <template v-if="user.data.score.rank === 1">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-S.png" />
                  </span>
                </template>
                <template v-else-if="user.data.score.rank === 2">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-A.png" />
                  </span>
                </template>
                <template v-else-if="user.data.score.rank === 3">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-B.png" />
                  </span>
                </template>
                <template v-else-if="user.data.score.rank === 4">
                  <span class="rank">
                    <img src="@/assets/realtor/rank-C.png" />
                  </span>
                </template>
                <span class="score"> {{ user.data.score.score }}</span>
              </template>
              <!-- <template v-else>
                <span class="score-zero">NEW</span>
              </template> -->
              <!-- <template v-if="user.data.score.score > 0">
                <span class="rank">{{
                  $$ks.getRank(user.data.score.rank).text
                }}</span
                ><span class="score"> {{ user.data.score.score }}</span>
              </template>
              <template v-else>
                <span class="score-zero">NEW</span>
              </template> -->
            </p>
            <p>
              <span class="is-size-4">{{ user.data.base.name }}</span
              ><br />
              <span class="is-size-7 has-text-gray gray"
                >{{ user.company.data.name }}&nbsp;</span
              >
              <span class="is-size-7 has-text-gray gray"
                >{{ user.data.company.department }}&nbsp;</span
              >
              <span class="is-size-7 has-text-gray gray">{{
                user.data.company.title
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex-wrapper">
        <span class="is-size-7 is-gray">不動産経験: </span><br />
        <span class="year">
          {{
            $$ks.realtor.getYearOfexperience(user.data.profile.yearOfexperience)
              .text
          }}
        </span>
      </div>
      <div class="flex-wrapper">
        <span class="is-size-7 is-gray">得意領域:<br /></span>
        <div class="goodarea">
          <template class="" v-for="(sp, idx) in user.data.profile.specialties">
            <span :key="idx" class="begoodat">
              {{ $$ks.realtor.getSpecialty(sp).text }}&nbsp;&nbsp;
            </span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
// import RealtorUser from "./RealtorUser";

//  import UserBasic from "@/components/realtor/UserBasic";
//import UserProfile from "@/components/realtor/UserProfile";
//import UserCompany from "@/components/realtor/UserCompany";

export default {
  name: "realtor-setting-basic-profile",
  props: {
    user: {
      type: [Object],
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.realtor-profile {
  color: #4a4a4a;
  margin-bottom: 24px;

  &__image {
    width: 130px;
    margin: 10px 0px 10px 10px;
    @include mobile {
      width: 60px;
      margin: 10px 0px 10px 10px;
    }
  }
}

hr {
  margin: 0.2rem 0;
}
.content {
  margin: 0;
  .rank-score {
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    .rank {
      //margin: 0 5px;
      margin-right: 4px;
      font-size: 1.2em;
      position: relative;
      width: 30px;
      height: 30px;
      color: goldenrod;
      vertical-align: -3px;
    }
    .score {
      font-size: 1.2em;
      font-weight: bold;
      //color: goldenrod;
      //-webkit-text-stroke: 1px gold;
    }
    // .score-zero {
    //   font-size: 1.2em;
    //   font-weight: bold;
    //   color: goldenrod;
    //   -webkit-text-stroke: 1px gold;
    // }
  }
}
.flex-wrapper {
  display: flex;
  font-size: 12px;
  flex-wrap: nowrap;
  .goodarea {
    flex-wrap: wrap;
    display: flex;
    margin-left: 22px;
  }
  span {
    white-space: nowrap;
  }
  .year {
    margin-left: 10px;
    text-align: left;
  }
}
.layout-by-score {
  padding-top: 1.5rem;
  @include sp {
    padding-top: 1.3rem;
  }
}
</style>
