<template>
  <section class="investorInfo">
    <h3 class="investorInfo__title">投資家情報</h3>
    <div class="infoBox">
      <div class="infoBox__score">
        <z-score-progress
          class="infoBox__score__item"
          :score="data.latestScore.score"
          :rank="data.latestScore.rank"
          scaleType="large-medium"
        />
      </div>
      <div class="infoBox__info">
        <div class="infoBox__info__person">
          <b-icon icon="account-outline" />{{
            $$age$calc(data.base.birthday)
          }}歳 {{ sex }}
        </div>
        <!--
        <template v-if="premiumMemberJudge">
          <div class="infoBox__premium" v-if="bulma.isMobile">
            <img src="@/assets/investor/premium-card.png" />
          </div>
        </template>
        -->
        <dl class="infoBox__info__dl">
          <dt>業種</dt>
          <dd>{{ categoryOfBusiness }}</dd>
        </dl>
        <dl class="infoBox__info__dl">
          <dt>職種</dt>
          <dd>{{ typeOfOccupation }}</dd>
        </dl>
      </div>
      <!--
      <template v-if="premiumMemberJudge">
        <div class="infoBox__premium" v-if="!bulma.isMobile">
          <img src="@/assets/investor/premium-card.png" />
        </div>
      </template>
      -->
    </div>
  </section>
</template>

<script>
import kubuns from "@/kubuns/kubuns";

export default {
  name: "InvestorInfo",
  props: {
    data: Object,
  },
  computed: {
    sex() {
      return kubuns.getTypeOfSex(this.data.base.sex).text;
    },
    categoryOfBusiness() {
      return kubuns.getCategoryOfBusiness(
        this.data.profile.currentOfCareer.categoryOfBusiness
      ).text;
    },
    typeOfOccupation() {
      return kubuns.getTypeOfOccupation(
        this.data.profile.currentOfCareer.typeOfOccupation
      ).text;
    },
    premiumMemberJudge() {
      return (
        kubuns.investor.application.getMembership(this.data.membership)
          .value === 20
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.investorInfo {
  &__title {
    margin-bottom: 16px;
    font-size: 20px;
    border-bottom: solid 1px #ddd;
  }
}
.infoBox {
  display: flex;
  &__score {
    position: relative;
    min-width: 148px;
    min-height: 140px;
    background: linear-gradient(#37d2ff, #2a7bcf);
    color: #fff;
    @include sp {
      min-width: 105px;
      min-height: 120px;
    }
    &__item {
      padding-top: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__info {
    margin-left: 20px;
    font-size: 16px;
    @include sp {
      margin-left: 16px;
      font-size: 14px;
    }
    & > *:not(:last-child) {
      margin-bottom: 12px;
    }
    &__person {
      @include sp {
        display: inline-block;
        width: 75%;
      }
      /deep/ .icon {
        vertical-align: middle;
        .mdi {
          &::before {
            font-size: 18px;
          }
        }
      }
    }
    &__dl {
      & > dt {
        font-size: 12px;
      }
      & > dd {
        margin-top: 2px;
      }
    }
  }
  &__premium {
    width: 100px;
    margin: 0 0 0 auto;
    @include sp {
      display: inline-block;
      width: 20%;
      height: 24px;
      vertical-align: -16px;
    }
  }
}
</style>
