<script>
import firebase from "@/plugins/firebase";
export default {
  name: "Maintenance",
  data() {
    return {
      snapshot: null,
    };
  },
  mounted() {
    if (window.location.host !== process.env.VUE_APP_MT_URL) {
      this.snapshot = firebase.db
        .collection("maintenance_v1")
        .onSnapshot((qs) => {
          qs.forEach((doc) => {
            const data = doc.data();
            if (data.isActivate) {
              window.location.href = data.url;
            }
          });
        });
    }
  },
  destoryed() {
    if (this.snapshot) this.snapshot();
  },
};
</script>
