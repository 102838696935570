<template>
  <div>
    <div class="auth-registor__content">
      <section>
        <ZHeader title="Sign Up" sub="サインアップ" />
      </section>
      <div class="columns is-gapless is-centered">
        <div class="columns is-narrow">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <diV>
      <Footer class="auth-registor__footer" :isHideMenu="true"> </Footer>
    </diV>
  </div>
</template>

<script>
import Footer from "@/components/layout/realtor/Footer";

export default {
  name: "auth-registor",
  components: {
    Footer: Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.auth-registor {
  &__content {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    margin-bottom: 32px;
  }
  &__footer {
    position: static;
  }
}
</style>
