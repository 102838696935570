<script>
import firebase from "@/plugins/firebase";

export default {
  name: "components-investor-auth-SentMail",
  methods: {
    cancel: function () {
      this.$router.push({ name: "auht-investor-signup" });
    },
    sendMail: function (email, analyticsParam) {
      console.log(email);
      const em = encodeURI(email.replace("+", " "));
      let callback =
        location.protocol +
        "//" +
        location.host +
        "/auth/investor/register" +
        "?email=" +
        em;
      if (
        analyticsParam !== null &&
        analyticsParam !== undefined &&
        analyticsParam !== ""
      ) {
        callback = callback + "&a8=" + analyticsParam;
      }
      // const actionCodeSettings = {
      //   url: callback,
      //   handleCodeInApp: true
      // };
      // return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
      return firebase.functions("sendMailToInvestorKari", {
        to: {
          email: email,
          name: email,
        },
        email: email,
        callback: callback,
      });
    },
  },
};
</script>
