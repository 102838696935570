<template>
  <button class="z-template-button" @click="onClick">
    {{ text }}
  </button>
</template>

<script>
// import Vue, { PropType } from 'vue';
// export type DataType = {
//   style: any;
// }
export default {
  name: "ZTemplateButton",
  props: {
    text: { type: String, required: true },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-template-button {
  padding-left: 24px;
  padding-right: 24px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #0062d7;
  border-radius: 23px;
  font-size: 14px;
  line-height: 14px;
  color: #0262d8;
  letter-spacing: 0;
  text-align: center;

  @include sp {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #0262d8;
    border-color: #0262d8;
    color: #fff;
  }
}

// .i-button {
//   &__button {
//     width: 100%;
//     height: 50px;
//     border-radius: 25px;
//     font-size: 16px;
//     text-align: center;
//     box-shadow: none;
//     border: none;
//     outline: none;
//
//     box-shadow: 0 2px 10px 0 transparent;
//     // transition-delay: 0.1s;
//
//     &:disabled {
//       background: $gray;
//       color: $white;
//       &:active {
//         background: $gray;
//         color: $white;
//         opacity: 1;
//         border: none;
//         box-shadow: none;
//       }
//       &:hover {
//         background: $gray;
//         color: $white;
//         border: none;
//         box-shadow: none;
//         opacity: 1;
//       }
//     }
//
//     // &:hover {
//     //   opacity: 0.7;
//     // }
//     &:active {
//       opacity: 0.7;
//     }
//
//     &_red {
//       background: $red;
//       color: $white;
//       &:active {
//         color: #CD2B4D;
//         background: $white;
//         border: 2px solid #CD2B4D;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//         opacity: 0.7;
//       }
//       &:hover {
//         color: #CD2B4D;
//         background: $white;
//         border: 2px solid #CD2B4D;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//       }
//     }
//     &_blue {
//       background: $blue;
//       color: $white;
//       &:active {
//         color: $blue;
//         background: $white;
//         border: 2px solid $blue;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//         opacity: 0.7;
//       }
//       &:hover {
//         color: $blue;
//         background: $white;
//         border: 2px solid $blue;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//       }
//     }
//     &_ghost {
//       background: transparent;
//       border: 2px solid #B4B4B4;
//       color: #B4B4B4;
//       &:active {
//         background: #B4B4B4;
//         color: $white;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//         opacity: 0.7;
//       }
//       &:hover {
//         background: #B4B4B4;
//         color: $white;
//         border: 2px solid #B4B4B4;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//       }
//     }
//     &_gray {
//       background: $gray;
//       color: $white;
//       &:active {
//         color: $gray;
//         background: $white;
//         border: 2px solid $gray;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//         opacity: 0.7;
//       }
//       &:hover {
//         color: $gray;
//         background: $white;
//         border: 2px solid $gray;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//       }
//
//     }
//     &_black {
//       background: $black;
//       color: $white;
//       &:active {
//         color: $black;
//         background: $white;
//         border: 2px solid $black;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//         opacity: 0.7;
//       }
//       &:hover {
//         color: $black;
//         background: $white;
//         border: 2px solid $black;
//         box-shadow: 0 2px 10px 5px rgba(0,0,0,0.07);
//       }
//     }
//     &_small {
//       height: 45px;
//       border-radius: 22.5px;
//     }
//   }
// }
</style>
