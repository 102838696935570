<template>
  <div class="planDetail">
    <div class="planDetail__titleArea">
      <div class="planDetail__titleArea__title">
        <div class="titleInner">
          <p class="titleInner__sub">資産形成の「見える指針」がわかる</p>
          <p class="titleInner__main" v-if="$$user$alreadyUsedTrial">
            プレミアムプラン
          </p>
          <p class="titleInner__main" v-if="!$$user$alreadyUsedTrial">
            プレミアムプラン<br class="titleInnerBr" />90日間無料！
          </p>
        </div>
      </div>
      <p class="planDetail__titleArea__description">
        プレミアムプランにご登録いただくことで、以下のサービスをご利用できます。
      </p>
      <div class="planDetail__titleArea__subscriptionInfo">
        <p class="planDetail__titleArea__subscriptionInfo__txt">
          月額<br /><span class="subscriptionAmount">5,000</span
          ><span class="subscriptionAmountUnit">円</span><br />(税別)
        </p>
      </div>
    </div>
    <div class="planDetail__premiumCardImage">
      <img
        src="@/assets/investor/premium-card.png"
        class="planDetail__premiumCardImage__img"
      />
    </div>
    <div class="planDetail__info">
      <p class="planDetail__info__txt">プラン内容</p>
      <div class="planDetail__info__benefitsList">
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_score"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_score"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">スコアリング</p>
        </div>
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_share"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_share"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">不動産売買経験<br />のシェア・閲覧</p>
        </div>
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_offer"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_offer"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">専門家からの<br />提案・オファー</p>
        </div>
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_search"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_search"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">不動産の検索・<br />閲覧</p>
        </div>
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_setting"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_setting"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">保有不動産の<br />売却設定</p>
        </div>
        <div class="benefitsItem">
          <ZIcon
            class="benefitsItem__icon"
            name="plan_off"
            width="62"
            height="62"
            color="#000000"
          />
          <ZIcon
            class="benefitsItem__icon--sp"
            name="plan_off"
            width="54"
            height="54"
            color="#000000"
          />
          <p class="benefitsItem__title">売買手数料の<br />優遇待遇</p>
          <p class="benefitsItem__title__description">
            10％のキャッシュバック対象は、不動産売買契約日及び不動産決済日の両方の時点でプレミアム会員に加入していることが条件です。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import kubuns from "@/kubuns/kubuns";

export default {
  name: "PlanDetail",
  // computed: {
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.planDetail {
  margin-top: 26px;
  margin-bottom: 12px;
  color: #333333;
  padding-top: 10px;
  @include sp {
    margin-top: 28px;
    padding-top: 0;
  }
  &__titleArea {
    text-align: center;
    position: relative;
    margin: 0 (-$edit-padding);
    @include sp {
      margin: 0 (-$edit-padding-sp);
    }
    &__title {
      background-color: #f4f4f4;
      .titleInner {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &__sub {
          font-size: 18px;
          line-height: 18px;
          font-weight: bold;
          padding: 0 47px;
          @include sp {
            font-size: 14px;
            line-height: 14px;
          }
        }
        &__main {
          font-size: 32px;
          line-height: 32px;
          font-weight: bold;
          color: #0262d8;
          padding-top: 10px;
          @include sp {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
      .titleInnerBr {
        @include lg {
          display: none;
        }
        @include md {
          display: none;
        }
      }
    }
    &__description {
      margin: 16px 36px 0 36px;
      font-size: 16px;
      line-height: 20px;
      @include sp {
        font-size: 14px;
      }
    }
    &__subscriptionInfo {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #0262d8;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transform: rotate(13deg);
      @include sp {
        top: -4px;
        right: 0;
        height: 71px;
        width: 71px;
        font-size: 10px;
      }
      .subscriptionAmount {
        font-size: 22px;
        font-weight: bold;
        @include sp {
          font-size: 18px;
        }
      }
      .subscriptionAmountUnit {
        font-size: 12px;
        font-weight: bold;
        @include sp {
          font-size: 10px;
        }
      }
    }
  }
  &__premiumCardImage {
    margin-top: 12px;
    text-align: center;
  }
  &__info {
    text-align: center;
    font-weight: bold;
    @include lg {
      margin: 14px (-$edit-padding) 0 (-$edit-padding);
    }
    @include md {
      margin: 14px (-$edit-padding) 0 (-$edit-padding);
    }
    @include sp {
      margin-top: 14px;
    }
    &__txt {
      font-size: 18px;
      @include sp {
        font-size: 16px;
      }
    }
    &__benefitsList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .benefitsItem {
        width: 33.33%;
        margin-top: 26px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        @include sp {
          width: 50%;
          &:nth-child(-n + 2) {
            margin-top: 18px;
          }
          margin-top: 10px;
        }
        &__icon {
          @include sp {
            display: none;
          }
          &--sp {
            @include lg {
              display: none;
            }
            @include md {
              display: none;
            }
          }
        }
        &__title {
          margin-top: 8px;
          line-height: 20px;
          @include sp {
            font-size: 14px;
          }
          &__description {
            font-weight: normal;
            margin-top: 0;
            padding: 0 22px;
            font-size: 11px;
            line-height: 19px;
            @include sp {
              padding: 0 16px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
