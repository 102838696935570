var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"data":_vm.users,"checkable":"","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort":"examination.data.status","custom-row-key":"id","checked-rows":_vm.checkedUsers,"striped":true,"hoverable":true,"mobile-cards":true},on:{"update:checkedRows":function($event){_vm.checkedUsers=$event},"update:checked-rows":function($event){_vm.checkedUsers=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"id","label":"詳細","width":"20"}},[_c('router-link',{staticClass:"button is-small",attrs:{"to":{
            name: 'admin-setting-realtors-realtors-users-detail',
            params: {
              cid: props.row.data.company.companyId,
              uid: props.row.id,
            },
          }}},[_vm._v("詳細")])],1),_c('b-table-column',{attrs:{"field":"examination.data.status","label":"承認","width":"20","sortable":""}},[_c('z-tag',{attrs:{"size":"is-small","options":_vm.$$ks.realtor.application.user.statuses},model:{value:(props.row.examination.data.status),callback:function ($$v) {_vm.$set(props.row.examination.data, "status", $$v)},expression:"props.row.examination.data.status"}})],1),_c('b-table-column',{attrs:{"field":"data.base.email","label":"email","sortable":""}},[_vm._v(" "+_vm._s(props.row.data.base.email)+" ")]),_c('b-table-column',{attrs:{"field":"data.base.name","label":"名前","sortable":""}},[_vm._v(" "+_vm._s(props.row.data.base.name)+" ")]),_c('b-table-column',{attrs:{"field":"data.base.name","label":"スコア","sortable":""}},[_vm._v(" "+_vm._s(props.row.data.score.score)+"("+_vm._s(_vm.$$ks.getRank(props.row.data.score.rank).text)+") ")]),_c('b-table-column',{attrs:{"field":"data.company.department","label":"所属・部署","sortable":""}},[_vm._v(" "+_vm._s(props.row.data.company.department)),_c('br'),_vm._v(" "+_vm._s(props.row.data.company.title)+" ")]),_c('b-table-column',{attrs:{"field":"company.data.name","label":"会社名","sortable":""}},[_c('router-link',{attrs:{"to":{
            name: 'admin-setting-realtors-detail',
            params: {
              cid: props.row.company.id,
            },
          }}},[_vm._v(" "+_vm._s(props.row.company.data.name)+" ")]),_c('br'),_c('z-tag',{attrs:{"size":"is-small","options":_vm.$$ks.realtor.application.company.statuses},model:{value:(props.row.company.examination.data.status),callback:function ($$v) {_vm.$set(props.row.company.examination.data, "status", $$v)},expression:"props.row.company.examination.data.status"}})],1),_c('b-table-column',{attrs:{"field":"data.registerDt","label":"登録日","sortable":""}},[_vm._v(" "+_vm._s(_vm.$$calender$dateFormat(props.row.data.registerDt))),_c('br'),_vm._v(" "+_vm._s(props.row.id)),_c('br'),_c('z-tag',{attrs:{"options":_vm.$$ks.realtor.roles},model:{value:(props.row.data.role),callback:function ($$v) {_vm.$set(props.row.data, "role", $$v)},expression:"props.row.data.role"}}),(props.row.data.isActivate)?[_c('div',{staticClass:"tag is-danger"},[_vm._v("ACTIVE")])]:[_c('div',{staticClass:"tag"},[_vm._v("NON ACTIVE")])]],2)]}}])},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing here.")])])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }