// import Vue from "vue";
// import Router from "vue-router";
// import Home from "./views/Home";
// import store from "@/store";
//
// import fix01 from "@/views/admin/demo/Fix01";
// import fix02 from "@/views/admin/demo/Fix02";

import batchRemovePremium from "@/views/admin/batch/RemovePremium";
import batchSalesforce from "@/views/admin/batch/Salesforce";

import zeroIndex from "@/views/admin/setting/zero/Index";

import demoBase from "@/views/admin/demo/Base";
import demoBase2 from "@/views/admin/demo/Base2";
import demoParts from "@/views/admin/demo/Parts";
import demoParts2 from "@/views/admin/demo/Parts2";
import demoCharts from "@/views/admin/demo/Charts";
import demoScore from "@/views/admin/demo/Score";
import demoScoreUser from "@/views/admin/demo/ScoreUser";
import demoBank from "@/views/admin/demo/Bank";
import demoSummaryParts from "@/views/admin/demo/SummaryParts";
import demoChartParts from "@/views/admin/demo/ChartParts";
// import demoCreditCard from "@/views/admin/demo/CreditCard";
import demoPlaceholder from "@/views/admin/demo/Placeholder";

import demoPb from "@/views/admin/demo/Pb";

import demoPrice from "@/views/admin/demo/Price";

import demoKubuns from "@/views/admin/demo/Kubuns";

import demoRealEstate from "@/views/admin/demo/Realestate";

import demoRealtorRS from "@/views/admin/demo/RealtorRS";
// import demoRealtorRS2 from "@/views/admin/demo/RealtorRS2";

import demoTransaction from "@/views/admin/demo/Transaction";

import demoUserList from "@/views/admin/demo/UserList";
// import demoInvestor from "@/views/admin/demo/Investor";
import demoMessage from "@/views/admin/demo/Message";
import demoMaster from "@/views/admin/demo/Master";

import demoCareer from "@/views/admin/demo/Career";

import adminWelcomeWelcome from "@/views/admin/welcome/Welcome";

import adminSettingBasic from "@/views/admin/setting/Basic";

import adminSettingAreas from "@/views/admin/setting/areas/Index";

import adminSettingRealtorsIndex from "@/views/admin/setting/realtors/Index";
import adminSettingRealtorsDetail from "@/views/admin/setting/realtors/Detail";
import adminSettingRealtorsUsers from "@/views/admin/setting/realtors/users/Index";
import adminSettingRealtorsUsersDetail from "@/views/admin/setting/realtors/users/Detail";
import adminSettingRealtorsUsersApplication from "@/views/admin/setting/realtors/Application";

import adminSettingInvestorsIndex from "@/views/admin/setting/investors/Index";
import adminSettingInvestorsDetail from "@/views/admin/setting/investors/Detail";
import adminSettingInvestorsRealEstate from "@/views/admin/setting/investors/RealEstate";
import adminSettingInvestorsTransaction from "@/views/admin/setting/investors/Transaction";

import adminSettingAdminIndex from "@/views/admin/setting/admin/Index";
import adminSettingAdminTransaction from "@/views/admin/setting/admin/transaction/Index";
import adminSettingAdminTransactionDetail from "@/views/admin/setting/admin/transaction/Detail";

import adminSettingMessagesIndex from "@/views/admin/setting/messages/Index";
import adminSettingMessagesDetail from "@/views/admin/setting/messages/Detail";
import adminSettingMessagesInvestorRealEstate from "@/views/admin/setting/messages/InvestorRealEstate";
import adminSettingMessagesRealtorRealEstate from "@/views/admin/setting/messages/RealtorRealEstate";
import adminSettingMessageTemplate from "@/views/admin/setting/messages/Template";

import adminSettingInformationIndex from "@/views/admin/setting/informations/Index";
import adminSettingInformationBulk from "@/views/admin/setting/informations/Bulk";

import adminSettingAnalysisAction from "@/views/admin/setting/analysis/Action";
import adminSettingAnalysisInvestorFavorite from "@/views/admin/setting/analysis/investor/Favorite";

import adminSettingLinkage from "@/views/admin/setting/linkage/Index";

import adminHome from "@/views/admin/Home";
import adminMenu from "@/views/admin/components/AdminMenu";

import options from "@/routers/options";

const RequiresAuth = options.RequiresAuth;
const role = options.role;
const examination = options.examination;

const routes = [
  {
    path: "/admin",
    component: adminHome,
    children: [
      {
        name: "admin-home",
        path: "",
        component: adminMenu,
        meta: {
          examination: examination.all,
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/batch/salesforce",
    component: batchSalesforce,
    children: [
      {
        name: "admin-batch-salesforce",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/batch/remove-premium",
    component: batchRemovePremium,
    children: [
      {
        name: "admin-batch-remove-premium",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/zero",
    component: zeroIndex,
    children: [
      {
        name: "admin-setting-zero",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  // {
  //   path: "/admin/demo/fix01",
  //   component: fix01,
  //   children: [
  //     {
  //       name: "admin-demo-fix01",
  //       path: "",
  //       component: adminMenu,
  //       meta: {
  //         role: role.admin,
  //         requiresAuth: RequiresAuth.required,
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/admin/demo/fix02",
  //   component: fix02,
  //   children: [
  //     {
  //       name: "admin-demo-fix02",
  //       path: "",
  //       component: adminMenu,
  //       meta: {
  //         role: role.admin,
  //         requiresAuth: RequiresAuth.required,
  //       },
  //     },
  //   ],
  // },
  {
    path: "/admin/demo/price",
    component: demoPrice,
    children: [
      {
        name: "admin-demo-price",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  // {
  //   path: "/admin/demo/investor",
  //   component: demoInvestor,
  //   children: [
  //     {
  //       name: "admin-demo-investor",
  //       path: "",
  //       component: adminMenu,
  //       meta: {
  //         role: role.admin,
  //         requiresAuth: RequiresAuth.required,
  //       },
  //     },
  //   ],
  // },
  {
    path: "/admin/demo/message",
    component: demoMessage,
    children: [
      {
        name: "admin-demo-message",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/demo/master",
    component: demoMaster,
    children: [
      {
        name: "admin-demo-master",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/demo/career",
    component: demoCareer,
    children: [
      {
        name: "admin-demo-career",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/demo/userlist",
    component: demoUserList,
    children: [
      {
        name: "admin-demo-userlist",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   role: role.admin,
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/demo/scoreuser",
    component: demoScoreUser,
    children: [
      {
        name: "admin-demo-scoreuser",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  // {
  //   path: "/admin/demo/creditcard",
  //   component: demoCreditCard,
  //   children: [
  //     {
  //       name: "admin-demo-creaditcard",
  //       path: "",
  //       component: adminMenu,
  //       meta: {
  //         role: role.admin,
  //         requiresAuth: RequiresAuth.required,
  //       },
  //     },
  //   ],
  //   // meta: {
  //   //   requiresAuth: RequiresAuth.none
  //   // }
  // },
  {
    path: "/admin/demo/placeholder",
    component: demoPlaceholder,
    children: [
      {
        name: "/admin/demo/placeholder",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/bank",
    component: demoBank,
    children: [
      {
        name: "/admin/demo/bank",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/score",
    component: demoScore,
    children: [
      {
        name: "/admin/demo/score",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/transaction",
    component: demoTransaction,
    children: [
      {
        name: "/admin/demo/transaction",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  // {
  //   path: "/admin/demo/realtorrs2",
  //   component: demoRealtorRS2,
  //   children: [
  //     {
  //       name: "/admin/demo/realtorrs2",
  //       path: "",
  //       component: adminMenu,
  //       meta: {
  //         role: role.admin,
  //         requiresAuth: RequiresAuth.required,
  //       },
  //     },
  //   ],
  // },
  {
    path: "/admin/demo/realtorrs",
    component: demoRealtorRS,
    children: [
      {
        name: "/admin/demo/realtorrs",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/demo/pb",
    component: demoPb,
    children: [
      {
        name: "/admin/demo/pb",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/demo/realestate",
    component: demoRealEstate,
    children: [
      {
        name: "/admin/demo/realestate",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/base2",
    component: demoBase2,
    children: [
      {
        name: "/admin/demo/base2",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/base",
    component: demoBase,
    children: [
      {
        name: "/admin/demo/base",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/charts",
    component: demoCharts,
    children: [
      {
        name: "/admin/demo/charts",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/parts",
    component: demoParts,
    children: [
      {
        name: "/admin/demo/parts",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/parts2",
    component: demoParts2,
    children: [
      {
        name: "/admin/demo/parts2",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/summary-parts",
    component: demoSummaryParts,
    children: [
      {
        name: "/admin/demo/summary-parts",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/chart-parts",
    component: demoChartParts,
    children: [
      {
        name: "/admin/demo/chart-parts",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/demo/kubuns",
    component: demoKubuns,
    children: [
      {
        name: "/admin/demo/kubuns",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/welcome",
    component: adminWelcomeWelcome,
    children: [
      {
        name: "/admin/welcome",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/areas",
    component: adminSettingAreas,
    children: [
      {
        name: "admin-setting-areas",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/investors",
    component: adminSettingInvestorsIndex,
    children: [
      {
        name: "admin-setting-investors",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/investors/transaction",
    component: adminSettingInvestorsTransaction,
    children: [
      {
        name: "admin-setting-investors-transaction",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/investors/:uid",
    component: adminSettingInvestorsDetail,
    children: [
      {
        name: "admin-setting-investors-detail",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/investors/:uid/realestates",
    component: adminSettingInvestorsRealEstate,
    children: [
      {
        name: "admin-setting-investors-realestate",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors",
    component: adminSettingRealtorsIndex,
    children: [
      {
        name: "admin-setting-realtors",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors/applications",
    component: adminSettingRealtorsUsersApplication,
    children: [
      {
        name: "admin-setting-realtors-application",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors/users",
    component: adminSettingRealtorsUsers,
    children: [
      {
        name: "admin-setting-realtors-users",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors/:cid/users",
    component: adminSettingRealtorsUsers,
    children: [
      {
        name: "admin-setting-realtors-realtors-users",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors/:cid/users/:uid",
    component: adminSettingRealtorsUsersDetail,
    children: [
      {
        name: "admin-setting-realtors-realtors-users-detail",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/realtors/:cid",
    component: adminSettingRealtorsDetail,
    children: [
      {
        name: "admin-setting-realtors-detail",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/admin",
    component: adminSettingAdminIndex,
    children: [
      {
        name: "admin-setting-admin-index",
        path: "",
        component: adminMenu,
        // meta: {
        //   role: role.admin,
        //   requiresAuth: RequiresAuth.required
        // }
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/admin/transaction",
    component: adminSettingAdminTransaction,
    children: [
      {
        name: "admin-setting-admin-transaction",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/admin/transaction/:id",
    component: adminSettingAdminTransactionDetail,
    children: [
      {
        name: "admin-setting-admin-transaction-detail",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/analysis/action",
    component: adminSettingAnalysisAction,
    children: [
      {
        name: "admin-setting-analysis-action",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/setting/linkage",
    component: adminSettingLinkage,
    children: [
      {
        name: "admin-setting-linkage",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/setting/analysis/investor/favorite",
    component: adminSettingAnalysisInvestorFavorite,
    children: [
      {
        name: "admin-setting-analysis-investor-favorite",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
  },
  {
    path: "/admin/setting/informations",
    component: adminSettingInformationIndex,
    children: [
      {
        name: "admin-setting-informations-index",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/setting/informations/bulk",
    component: adminSettingInformationBulk,
    children: [
      {
        name: "admin-setting-informations-bulk",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.none
    // }
  },
  {
    path: "/admin/setting/messages/template",
    component: adminSettingMessageTemplate,
    children: [
      {
        name: "admin-setting-messages-template",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/messages/realestate/investor/:rid",
    component: adminSettingMessagesInvestorRealEstate,
    children: [
      {
        name: "admin-setting-messages-investor-realEstate",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/messages/realestate/realtor/:rid",
    component: adminSettingMessagesRealtorRealEstate,
    children: [
      {
        name: "admin-setting-messages-realtor-realEstate",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/messages/:id",
    component: adminSettingMessagesDetail,
    children: [
      {
        name: "admin-setting-messages-detail",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/messages",
    component: adminSettingMessagesIndex,
    children: [
      {
        name: "admin-setting-messages-index",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
  {
    path: "/admin/setting/basic",
    component: adminSettingBasic,
    children: [
      {
        name: "admin-setting-basic",
        path: "",
        component: adminMenu,
        meta: {
          role: role.admin,
          requiresAuth: RequiresAuth.required,
        },
      },
    ],
    // meta: {
    //   requiresAuth: RequiresAuth.required
    // }
  },
];

export default { routes: routes };
