<template>
  <div class="collection">
    <section>
      <ZHeader title="My Collection" sub="保有不動産" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
      <section class="z-section">
        <div class="z-section__inner">
          <div class="collection__head">
            <h3 class="collection__head__title">売却設定中</h3>
            <div class="collection__head__num">
              <b>{{ publicRealEstateArray.length }}</b
              >件
            </div>
          </div>

          <ul class="collection__list" v-if="isPublicRealEstateArrayExist">
            <li
              class="tileBoxWrapper"
              v-for="realEstate in publicRealEstateArray"
              :key="realEstate.id"
            >
              <tile-box
                class="collection__list__item"
                overlayTitle="Real Estate Set"
                overlaySubTitle="不動産情報設定"
                @onOpenOverlay="editRealEstate(realEstate.id)"
              >
                <template v-slot:title>
                  <p class="collectionTitle">
                    {{ realEstateName(realEstate) }}
                  </p>
                </template>
                <template v-slot:body>
                  <div class="collectionDetail__wrapper">
                    <collection-detail
                      :entity="realEstate"
                      @rerender="publicRealEstateArray"
                    />
                  </div>
                </template>
                <!-- <template v-slot:overlay>
                  <EditRealEstate />
                </template> -->
              </tile-box>
            </li>
          </ul>

          <div
            class="collectionListEmpty collectionListEmpty--publicSetting"
            v-if="!isPublicRealEstateArrayExist"
          >
            <p class="collectionListEmpty__txt">
              売却設定中の保有不動産はありません。<br />売却設定をすると保有不動産に対し売却の提案を受けることができます。
            </p>
          </div>
        </div>
      </section>

      <section class="z-section">
        <div class="z-section__inner">
          <div class="collection__head">
            <h3 class="collection__head__title">保有不動産</h3>
            <div class="collection__head__num">
              <b>{{ privateRealEstateArray.length }}</b
              >件
            </div>
          </div>

          <div
            class="collectionListEmpty"
            v-if="
              !isPrivateRealEstateArrayExist && !isPublicRealEstateArrayExist
            "
          >
            <p class="collectionListEmpty__txt">
              保有不動産は未登録です。<br />記入するとスコアアップにつながります。
            </p>
          </div>

          <ul class="collection__list collection__list--privateSetting">
            <li
              class="tileBoxWrapper"
              v-for="realEstate in privateRealEstateArray"
              :key="realEstate.id"
            >
              <tile-box
                class="collection__list__item"
                overlayTitle="Real Estate Set"
                overlaySubTitle="不動産情報設定"
                @onOpenOverlay="editRealEstate(realEstate.id)"
              >
                <template v-slot:title>
                  <p class="collectionTitle">
                    {{ realEstateName(realEstate) }}
                  </p>
                </template>
                <template v-slot:body>
                  <div class="collectionDetail__wrapper">
                    <collection-detail
                      :entity="realEstate"
                      @rerender="privateRealEstateArray"
                    />
                  </div>
                </template>
                <!-- <template v-slot:overlay>
                  <EditRealEstate />
                </template> -->
              </tile-box>
            </li>
            <li class="addRealEstateButton" @click="addRealEstate()">
              <b-icon icon="plus-circle" size="is-medium" />
              <p class="addRealEstateButton__label">追加する</p>
            </li>
          </ul>
        </div>
      </section>
    </section>

    <tile-box-overlay
      title="Real Estate Set"
      subTitle="不動産情報設定"
      ref="overlay"
    >
      <template v-slot:OverlayReceive>
        <EditRealEstateUnit :id="currentId" @saved="savedRealEstate" />
      </template>
    </tile-box-overlay>
  </div>
</template>

<script>
// import EditSaleRealEstate from "../portfolio/parts/EditSaleRealEstate";
//import EditWorkExperience from "../portfolio/parts/EditWorkExperience";
import CollectionDetail from "./parts/CollectionDetail.vue";
import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
import EditRealEstateUnit from "../common/EditRealEstateUnit";

export default {
  name: "collection-Index",
  data: function () {
    return {
      isLoading: true,
      user: null,
      isActiveEditSaleRealEstate: false,
      realEstate: this.$$realEstate$getNewRealEstate(),
      currentId: "",
    };
  },
  components: {
    // EditSaleRealEstate,
    CollectionDetail,
    TileBoxOverlay,
    EditRealEstateUnit,
  },
  methods: {
    onOpenEditSaleRealEstate() {
      this.realEstate = this.me.user.realEstates[0];
      // this.realEstate = this.$$realEstate$getNewRealEstate();
      this.isActiveEditSaleRealEstate = true;
    },
    addRealEstate() {
      // 単一不動産登録用オーバーレイの展開
      this.currentId = "";
      this.$refs.overlay.open();
    },
    editRealEstate(id) {
      this.currentId = id;
      this.$refs.overlay.open();
    },
    savedRealEstate() {
      this.$refs.overlay.close();
    },
    realEstateName(object) {
      return object.data.name;
    },
  },
  computed: {
    publicRealEstateArray() {
      const result = this.me.user.realEstates.filter(
        (realEstate) => realEstate.data.saleSetting.publicType === 2
      );
      return result;
    },
    privateRealEstateArray() {
      const result = this.me.user.realEstates.filter(
        (realEstate) => realEstate.data.saleSetting.publicType === 1
      );
      return result;
    },
    isPublicRealEstateArrayExist() {
      if (this.publicRealEstateArray.length === 0) {
        return false;
      }
      return true;
    },
    isPrivateRealEstateArrayExist() {
      if (this.privateRealEstateArray.length === 0) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.collection {
  &__head {
    display: table;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    color: #474747;
    overflow: hidden;
    &__title {
      display: table-cell;
      font-size: 20px;
      line-height: 1em;
      vertical-align: text-bottom;
      @include sp {
        font-size: 18px;
      }
    }
    &__num {
      display: table-cell;
      font-size: 14px;
      font-weight: bold;
      vertical-align: text-bottom;
      text-align: right;
      b {
        margin-right: 2px;
        font-size: 24px;
      }
      @include sp {
        font-size: 12px;
        b {
          margin-right: 2px;
          font-size: 20px;
        }
      }
    }
  }

  &__list {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }
    .tileBoxWrapper {
      width: 47.88%;
      margin-top: 24px;
      height: 100%;
      @include sp {
        width: 100%;
      }
      &:first-child {
        margin-top: 16px;
        @include sp {
          margin-top: 24px;
        }
      }
      &:nth-child(2) {
        margin-top: 16px;
        @include sp {
          margin-top: 24px;
        }
      }
      &__item {
        width: 100%;
      }
    }
    .collectionTitle {
      font-size: 16px;
      padding-left: 16px;
    }
    .addRealEstateButton {
      width: 47.88%;
      min-height: 1040px;
      margin-top: 24px;
      @include sp {
        width: 100%;
        min-height: 148px;
        margin-top: 24px;
      }
      background-color: #f5f5f5;
      border: 2px dashed #d8d8d8;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #777777;
      cursor: pointer;
      &__label {
        margin-left: 6px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    &--privateSetting {
      min-height: 1040px;
      padding-bottom: 0;
      @include sp {
        min-height: 148px;
      }
    }
  }

  .collectionListEmpty {
    height: 100px;
    font-size: 16px;
    line-height: 20px;
    color: #0262d8;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--publicSetting {
      margin-bottom: 50px;
    }
    @include sp {
      font-size: 14px;
      line-height: 18px;
      min-height: 70px;
      &--publicSetting {
        margin-bottom: 0;
      }
    }
  }
}
</style>
