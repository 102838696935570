<template>
  <div class="bankRankingInfo" :class="selected ? 'is-selected' : ''">
    <div class="bankRankingInfo__rank rank">
      <div>
        <span class="is-num">{{ info.ranking }}</span>
        <span class="is-unit">位</span>
      </div>
    </div>
    <div class="bankRankingInfo__info info">
      <div class="info__bank">
        <div class="info__bank__img">
          <img v-lazy="info.imagePath" />
        </div>
        <div class="info__bank__name">{{ info.bankName }}</div>
      </div>
      <div class="info__numbers">
        <span class="info__numbers__number">
          <span class="is-num">{{ info.number }}</span>
          <span class="is-unit">件</span>
        </span>
        <span class="info__numbers__interestRate">
          <span class="info__numbers__interestRate__from">
            <span class="is-num">{{
              $$rete$rateFormat(info.interestRate.from, 2, "")
            }}</span>
            <span class="is-unit">%</span>
          </span>
          〜
          <span class="info__numbers__interestRate__to">
            <span class="is-num">{{
              $$rete$rateFormat(info.interestRate.to, 2, "")
            }}</span>
            <span class="is-unit">%</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankRankingInfo",
  props: {
    info: {
      type: Object,
      required: null,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.bankRankingInfo {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  line-height: 26px;
  color: $body-color;
  & > * {
    display: table-cell;
    vertical-align: middle;
  }
}
.rank {
  position: relative;
  width: 80px;
  height: 100%;
  background-color: rgba(#dddddd, 0.3);
  @include sp {
    width: 54px;
  }
  & > div {
    width: 100%;
    padding-right: 24px;
    text-align: right;
    @include sp {
      padding-right: 0;
      text-align: center;
    }
  }
  .is-num {
    font-size: 20px;
    font-weight: bold;
    @include sp {
      font-size: 18px;
    }
  }
  .is-unit {
    margin-left: 4px;
    @include sp {
      margin-left: 0;
    }
  }
}
.info {
  width: 100%;
  padding: 10px 22px;
  line-height: 26px;
  overflow: hidden;
  @include sp {
    display: table;
    padding: 6px 8px;
    line-height: 1.2em;
  }
  &__bank {
    float: left;
    display: table;
    @include sp {
      float: none;
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }
    &__img {
      display: table-cell;
      width: 26px;
      height: 26px;
      vertical-align: middle;
      /deep/ img {
        width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
    &__name {
      display: table-cell;
      padding-left: 10px;
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }
  }
  &__numbers {
    float: right;
    @include sp {
      float: none;
      display: table-cell;
      padding-left: 8px;
      vertical-align: middle;
    }
    &__number {
      margin-left: 8px;
      @include sp {
        margin-left: 0;
        display: block;
      }
    }
    &__interestRate {
      margin-left: 12px;
      white-space: nowrap;
      @include sp {
        display: block;
        margin-left: 0;
        min-width: 120px;
      }
      &__from,
      &__to {
        display: inline-block;
        min-width: 55px;
        text-align: right;
        @include sp {
          min-width: auto;
        }
      }
    }
  }
}
.is-num {
  font-size: 18px;
  @include sp {
    font-size: 14px;
  }
}
.is-unit {
  font-size: 12px;
  vertical-align: bottom;
}
</style>
