<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <!--
      <ZInput label="UID" v-model="uid" maxlength="50" hideRequired />
      -->
      <button @click="onClickAll" class="button">全件</button>
      <hr />
      <table>
        <thead>
          <tr>
            <th>date</th>
            <th>タイプ</th>
            <th>投資家UID</th>
            <th>専門家UID</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(f, idx) in favorites">
            <tr :key="f.id + idx">
              <td>
                {{ $$calender$dateTimeFormat(f.data.registerDt) }}
              </td>
              <td>
                {{ favoriteStr(f.data.type) }}
              </td>
              <td>
                {{ f.data.createdBy }}
              </td>
              <td>
                {{ f.data.realtor }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
import * as analysis from "@/modules/investor/favorite";

export default {
  name: "admin-settings-analysis-investor-favorite",
  data: function () {
    return {
      uid: null,
      favorites: [],
    };
  },
  methods: {
    favoriteStr(id) {
      return this.$$ks.favorite.type(id).text;
    },
    async onClickAll() {
      const loadingComponent = this.$loading.open();
      try {
        this.favorites = await analysis.loads();
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
    async init() {
      this.isLoading = false;
      return;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
