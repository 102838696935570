<template>
  <div>
    <section>
      <ZHeader title="物件掲載管理" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <hr />
        <h4 class="list-title">物件一覧</h4>
        <div class="buttons">
          <router-link
            :to="{
              name: 'realtor-setting-realestates-detail',
              params: { id: 'new' },
            }"
            class="button is-small is-primary z-button"
            >新規物件登録</router-link
          >
        </div>
        <RealEstateList :isLoading="isLoading" :realEstates="realEstates">
        </RealEstateList>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

import RealEstateList from "@/views/realtor/common/RealEstateList";

export default {
  name: "realtor-setteing-realestates",
  components: {
    RealEstateList: RealEstateList,
  },
  data: function () {
    return {
      isLoading: true,
      currentPage: 0,
      realEstates: [],
    };
  },
  computed: {},
  methods: {
    // images(realEstate) {
    //   if (realEstate) {
    //     return []
    //       .concat(realEstate.data.images.exteriors)
    //       .concat(realEstate.data.images.interiors)
    //       .concat(realEstate.data.images.others);
    //   } else {
    //     return [];
    //   }
    // },
    async address(dt) {
      const text = await this.$$area$generateTextOfAddress(dt);
      return text;
    },
    countExclusive(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 1).length;
    },
    countNormal(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 2).length;
    },
    //countBuying(realEstate) {
    //  //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
    //  //{ value: 2, text: "オファー", color: "is-info" },
    //  //{ value: 3, text: "売却", color: "is-primary" },
    //  //{ value: 4, text: "問い合せ", color: "" }
    //  return realEstate.messages.filter(r => r.data.type === 3).length
    //},
    countContact(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 4).length;
    },
    async countViews(realEstateId) {
      const views = await this.$$realtor$loadViews(realEstateId);
      return views.length;
    },
    async loadRealEstate(r) {
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      r.viewsCount = await this.countViews(r.id);
      return r;
    },
    async init() {
      //const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        let res = [];

        if (this.$$user$permission.searchInvestors === 99) {
          res = await this.$$realtor$loadAllRealEstates();
        } else {
          res = await this.$$realtor$loadRealEstatesByCID(
            this.me.user.company.id
          );
        }
        const promises = [];
        for (const r of res) {
          promises.push(this.loadRealEstate(r));
        }
        this.realEstates = await Promise.all(promises).then((rs) => rs);
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
    buildingTypeText(val) {
      return this.$$ks.getTypeOfBuildingType(val).text;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    return this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.consultant-warp {
  margin: 0 10px;
  @include mobile {
    margin: 0;
  }
}

.z-section {
  @include md {
    min-width: 800px;
  }
}
.realestate {
  &__list {
    width: 100%;
    height: 756px;
    background-color: $mintrich-blank;
  }
}
.list-title {
  margin-bottom: 12px;
}

.content {
  p:nth-child(2) {
    font-size: 1.5em;
  }
  p:nth-child(3) {
    font-size: 1.5em;
  }
}
</style>
