<template>
  <div>
    <section class="section">
      <div class="continer">
        <template v-if="!isLoading">
          <template v-if="!canRegister">
            <template v-if="!isSignInWithEmailLink">
              <div class="columns is-centered">
                <div class="column is-8">
                  <b-message
                    title="リンクが正しくありません"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    リンクの有効期限が切れているか不正です。<br />申し訳ありませんが、
                    <a @click="cancel">会員登録</a
                    >に進み、確認メールを再送信してください。
                  </b-message>
                  <div class="has-text-centered">
                    <a @click="cancel">もう一度会員登録をする</a>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="existsAccount">
              <div class="columns is-centered">
                <div class="column is-6">
                  <b-message
                    title="会員はすでに登録されています"
                    type="is-danger"
                    :closable="false"
                    has-icon
                  >
                    会員はすでに登録されています。<br />
                    別の会員で登録したい場合は<a @click="cancel">会員登録</a
                    >から登録をお願いします。<br />
                    パスワードを忘れた場合は<router-link
                      :to="{ name: 'auth-resetpassword' }"
                      >パスワードリセット</router-link
                    >からパスワードの再設定をお願いします。<br />
                  </b-message>
                  <div class="has-text-centered">
                    <a @click="cancel">別の会員で登録をする</a>
                  </div>
                  <div class="has-text-centered">
                    <router-link :to="{ name: 'auth-resetpassword' }"
                      >パスワードを再設定する</router-link
                    >
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="columns is-centered">
              <div class="column is-6">
                <div class="box">
                  <div class="content is-small">
                    <h2>アカウント情報の登録</h2>
                    <p>
                      メールアドレスが正しいか確認し、パスワードを入力してください。
                    </p>
                  </div>
                  <div class="content has-label-centerted">
                    <p v-text="account.email"></p>
                  </div>
                  <z-input
                    label="パスワード"
                    name="password"
                    v-model="account.password"
                    data-vv-as="パスワード"
                    type="password"
                    placeholder="パスワード"
                    maxlength="128"
                    :validate="'required:true|min:8'"
                    icon-pack="fas"
                    icon="lock"
                    password-reveal
                  >
                  </z-input>

                  <button
                    class="button is-light is-fullwidth is-info"
                    :disabled="$$validator$invalid"
                    @click="save"
                  >
                    登録
                  </button>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
// import RegisterMixin from "@/mixins/admin/auth/RegisterMixin";
import firebase from "@/plugins/firebase";

export default {
  name: "admin-auth-Register",
  // mixins: [RegisterMixin],
  data: function () {
    return {
      //user: null,
      account: {
        email: this.$route.query.email.replace(" ", "+"),
        password: "",
      },
      existsAccount: false,
      isSignInWithEmailLink: false,
      isLoading: true,
    };
  },
  computed: {
    canRegister: function () {
      return !this.existsAccount && this.isSignInWithEmailLink;
    },
  },
  methods: {
    cancel: function () {
      this.$router.push({ name: "admin-signup" });
    },
    signOut: function () {
      const self = this;
      return firebase
        .auth()
        .signOut()
        .catch((error) => {
          self.$raven.captureException(error);
        });
    },
    save: function () {
      const email = this.account.email;
      if (
        email &&
        firebase.auth().isSignInWithEmailLink(window.location.href)
      ) {
        const self = this;
        const loadingComponent = this.$loading.open();
        // const promise = Promise.resolve();
        return (
          Promise.resolve()
            .then(() => {
              const credential =
                firebase.auth.EmailAuthProvider.credentialWithLink(
                  email,
                  window.location.href
                );
              return firebase.auth().currentUser.linkWithCredential(credential);
            })
            .then(() => {
              const auth = firebase.auth().currentUser;
              return auth.updatePassword(self.account.password);
            })
            // .then(() => {
            //   const auth = firebase.auth().currentUser;
            //   return firebase.functions("setCustomTokenAdmin", {
            //     uid: auth.uid
            //   });
            // })
            .then(() => {
              const auth = firebase.auth().currentUser;
              const now = firebase.serverTimestamp();
              const uid = auth.uid;
              const user = this.$$admin$getNewUser();
              console.log(user);

              user.id = uid;
              user.data.base.email = email;
              user.data.uid = uid;
              user.data.registerDt = now;
              user.data.isAdmin = true;
              user.data.isActivate = true;

              user.data.updatedBy = uid;
              user.data.updatedDt = now;
              user.data.createdBy = uid;
              user.data.createdDt = now;

              return this.$$admin$save(uid, user);
            })
            .then(() => {
              console.log("Sucess!!");
              window.location.href = "/admin/welcome";
            })
            .finally(() => {
              loadingComponent.close();
            })
            .catch((error) => {
              //if (error.code !== "auth/invalid-action-code") {
              //  self.$raven.captureException(error);
              //}
              self.$raven.captureException(error);
              self.$$toast$openError(
                "サインアップに失敗しました。再度サインアップからやり直しをお願いします。"
              );
              // self.$toast.open({
              //   duration: 10000,
              //   message:
              //     "サインアップに失敗しました。再度サインアップからやり直しをお願いします。",
              //   position: "is-top",
              //   type: "is-danger"
              // });
            })
        );
      } else {
        const self = this;
        self.$raven.captureException("Link is invalid");
        self.$$toast$openError(
          "リンクの認証が切れています。再度サインアップからやり直しをお願いします。"
        );
        // self.$toast.open({
        //   duration: 10000,
        //   message:
        //     "リンクの認証が切れています。再度サインアップからやり直しをお願いします。",
        //   position: "is-top",
        //   type: "is-danger"
        // });
        return Promise.resolve();
      }
    },
  },
  created() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.isSignInWithEmailLink = true;
      firebase.db
        .collection("users_v1")
        .where("email", "==", this.account.email)
        .get()
        .then((querySnapshot) => {
          this.existsAccount = !querySnapshot.empty;
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          self.$catchLoadError(error);
        });
    } else {
      this.isSignInWithEmailLink = false;
    }
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.has-label-centerted {
  height: 2rem;
  margin-top: 4px;
  margin-right: 24px;
}

.is-input-helper {
  margin: 0;
}
</style>
