// import * as firebase from '@/plugins/firebase';
import firebase from "@/plugins/firebase";

export const loads = async () => {
  const qs = await firebase.db.collection("lines_v2").get();

  const ls = [];
  qs.forEach((doc) => {
    ls.push(doc.data());
  });
  return ls;
};

export const load = async (lineCode) => {
  const doc = await firebase.db
    .collection("lines_v2")
    .doc(String(lineCode))
    .get();

  if (doc.exists) {
    return doc.data();
  } else {
    return null;
  }
};

export const loadStations = async () => {
  const qs = await firebase.db.collection("stations_v2").get();
  const ls = [];
  qs.forEach((doc) => {
    ls.push(doc.data());
  });
  return ls;
};

export const loadStation = async (stationCode) => {
  const doc = await firebase.db
    .collection("stations_v2")
    .doc(String(stationCode))
    .get();

  if (doc.exists) {
    return doc.data();
  } else {
    return null;
  }
};

export const loadStationsByCodes = async (stationCodes) => {
  const ps = [];
  for (const code of stationCodes) {
    ps.push(loadStation(code));
  }
  return Promise.all(ps);
};

// export const loadStationsByCodes = async stationCodes => {
//   const ls = [];
//   for (const code of stationCodes) {
//     ls.push(await loadStation(code));
//   }
//   return ls;
// };
