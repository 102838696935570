<script>
export default {
  name: "InputMixins",
  inject: ["$validator"],
  props: {
    name: String,
    placeholder: String,
    size: String,
    label: String,
    as: String,
    validate: {
      type: [String, Object],
      default: "",
    },
    type: String,
    maxlength: String,
    icon: String,
    passwordReveal: {
      type: Boolean,
      default: false,
    },
    isRequired: Boolean,
    isPrivate: Boolean,
    hideRequired: {
      type: [Boolean],
      default: false,
    },
    helpMessage: String,
    additionalMessage: String,
    showBox: Boolean,
    isShort: Boolean,
    disabled: Boolean,
  },
  computed: {
    internalName: function () {
      return this.name ? this.name : this.$uuid.v4();
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
