<template>
  <div>
    <div class="buttons">
      <button class="button is-small is-primary" @click="save">保存</button>
      <button
        :disabled="examination.data.status === 90"
        class="button is-small is-danger"
        @click="deny"
      >
        否認
      </button>
    </div>
    <z-tag
      :options="$$ks.realtor.application.company.statuses"
      v-model="company.examination.data.status"
    />
    <hr />
    <z-select
      label="否認理由"
      :name="'examination.data.reasonForRejection' + idx"
      v-model="examination.data.reasonForRejection"
      :options="$$ks.realtor.application.reasonForRejections"
      :validate="''"
    >
    </z-select>
    <z-input
      label="コメント"
      :name="'examination.data.comment' + idx"
      v-model="examination.data.comment"
      type="textarea"
      maxlength="1000"
      :validate="''"
    >
    </z-input>
    <hr />
    <h3 class="has-text-danger">承認ステータスは直接変えないでください！！</h3>
    <z-select
      label="承認ステータス"
      :name="'examination.data.status' + idx"
      v-model="examination.data.status"
      :options="$$ks.realtor.application.company.statuses"
      isRequired
      :validate="'required'"
      additionalMessage="ここは直接変えないでください！！！"
    >
    </z-select>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
// import Basic from "@/components/fl/edit/Basic";
export default {
  name: "admin-setting-realtors-detail-user-company",
  inject: ["$validator"],
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    company: {
      type: Object,
      required: true,
    },
    examination: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deny() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$denyAccountCompany(this.auth.uid, this.company);
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveExaminationOfCompany(
          this.auth.uid,
          this.company.id,
          this.examination
        );
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
        this.$router.replace({
          name: "admin-setting-realtors-detail",
          params: {
            cid: this.company.id,
          },
        });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
