<template>
  <div>
    <section>
      <ZHeader title="アカウント設定" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>
    <div class="z-section">
      <div class="z-section__inner">
        <div v-if="!isLoading">
          <div class="content-wrapper">
            <div class="content is-small">
              <h3 class="list-title">パスワードの変更</h3>
            </div>
            <div class="content-main">
              <p>アカウント情報：メールアドレス</p>
              <p>{{ this.auth.auth.email }}</p>
              <z-input
                label="今のパスワード"
                name="oldpassword"
                v-model="account.oldpassword"
                data-vv-as="今のパスワード"
                type="password"
                maxlength="128"
                :validate="'required:true|min:8'"
                icon-pack="fas"
                icon="lock"
                password-reveal
                isRequired
              >
              </z-input>
              <z-input
                label="新しいパスワード"
                name="password"
                v-model="account.password"
                data-vv-as="新しいパスワード"
                type="password"
                maxlength="128"
                :validate="'required:true|min:8'"
                icon-pack="fas"
                icon="lock"
                password-reveal
                isRequired
              >
              </z-input>
            </div>
            <div class="buttons has-addons is-centered">
              <button
                class="button z-button is-primary is-primary is-rounded is-wide"
                @click="changePassword"
              >
                変更する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="z-section">
      <div class="z-section__inner">
        <div class="content is-small has-text-right">
          <p class="is-size-7 has-text-gray">VER.{{ $$env$version }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
export default {
  name: "admin-account-Account",
  data: function () {
    return {
      isLoading: true,
      user: null,
      account: {
        oldpassword: "",
        password: "",
      },
    };
  },
  async created() {
    this.user = await this.$$investor$loadUser(this.me.user.id);
    //if (auth) {
    //  this.auth = auth;
    //  this.account.email = auth.email;
    //}
    this.isLoading = false;
  },
  methods: {
    changePassword: function () {
      const self = this;
      const loadingComponent = this.$loading.open();
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.auth.auth.email,
        this.account.oldpassword
      );
      user
        .reauthenticateAndRetrieveDataWithCredential(credential)
        .then(() => {
          return user.updatePassword(self.account.password);
        })
        .then(() => {
          self.$$toast$openSuccess("新しいパスワードを登録しました");
          // self.$toast.open({
          //   duration: 3000,
          //   message: "新しいパスワードを登録しました",
          //   position: "is-top",
          //   type: "is-success"
          // });
        })
        .finally(() => {
          loadingComponent.close();
        })
        .catch((error) => {
          self.$raven.captureException(error);
          self.$$toast$openError("登録に失敗しました。");
          // self.$toast.open({
          //   message: "登録に失敗しました。",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.content {
  p:nth-child(1) {
    font-weight: bold;
  }
  p:nth-child(2) {
    font-weight: bold;
    color: gray;
    font-size: 1.2em;
  }

  h3 {
    color: white;
  }
}
.content-main {
  padding-left: 20px;
  p:nth-child(1) {
    font-weight: bold;
    margin: 5px auto;
  }
  p:nth-child(2) {
    margin: 5px auto;
  }
}
</style>
