<template>
  <div class="plan">
    <ZHeader title="Plan" sub="プラン選択">
      <template v-slot:rightIcons>
        <ZNotification color="#777" />
      </template>
    </ZHeader>
    <template v-if="!$$user$isPremium">
      <section class="edit">
        <div class="edit__inner">
          <h3 class="plan__title" v-if="$$user$alreadyUsedTrial">
            プランをお選びください
          </h3>
          <h3 class="plan__title" v-if="!$$user$alreadyUsedTrial">
            その機能をご利用いただくには<br />プレミアムプランの登録が必要です
          </h3>

          <PlanDetail />

          <div class="plan__texts">
            <a
              class="button z-button is-text"
              href="https://stockformer.com/"
              target="_blank"
              >機能概要はこちら</a
            >
          </div>

          <div class="plan__texts">
            <p>
              ポートフォリオの必要情報を入力すると<br />プレミアムプランをはじめることができます。
            </p>
          </div>

          <template v-if="!$$investor$checkSatisfyPremium()">
            <InputInfoStatus />
          </template>

          <template v-if="$$user$alreadyUsedTrial">
            <div class="plan__select">
              <hr />
              <p class="plan__select__txt">
                上記プランの内容とポートフォリオのご登録内容をご確認後、チェックを入れてボタンをクリックしてください。
              </p>
              <div class="plan__select__radio">
                <b-radio
                  v-model="selectedPlan"
                  size="is-small"
                  native-value="basic"
                >
                  30日間 5,000円（税別）の一括払い
                </b-radio>
              </div>
            </div>

            <template v-if="isRegisteredCard">
              <div class="plan__buttons">
                <b-button
                  class="button z-button is-primary is-wide is-rounded is-multiline"
                  :disabled="!selectedPlan || !$$investor$checkSatisfyPremium()"
                  @click="changeResultPage"
                >
                  <span>プレミアムプランをはじめる</span>
                </b-button>
              </div>
            </template>
            <template v-else>
              <div class="plan__buttons">
                <ZCreditCardRegister
                  :OKURL="successURLOfCardRegistration"
                  :RT="failureURLOfCardRegistration"
                  :uid="this.me.id"
                  label="クレジットカードを登録する"
                  :disabled="!selectedPlan || !$$investor$checkSatisfyPremium()"
                />
              </div>
            </template>

            <div class="plan__attention">
              <p class="plan__attention__txt">
                ※2018年6月施行の割賦販売法の改正（カード情報の非保持化）に伴い、カード情報の登録および確認は、決済代行会社（株式会社メタップスペイメント）のサイト上にて行っていただく手順となります。
              </p>
              <p class="plan__attention__txt">
                ※プレミアムプランは期間終了後に自動更新になります。
              </p>
            </div>
          </template>
          <template v-else>
            <div class="plan__buttons">
              <b-button
                class="button z-button is-primary is-wide is-rounded is-multiline"
                :disabled="!$$investor$checkSatisfyPremium()"
                @click="applicateTrial"
              >
                <span
                  >プレミアムプランを<br />90日間無料トライアルではじめる</span
                >
              </b-button>
            </div>

            <PlanAttention />
          </template>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import InputInfoStatus from "../common/InputInfoStatus.vue";
import PlanDetail from "../common/PlanDetail";
import PlanAttention from "../common/PlanAttention";

export default {
  name: "Premium-Index",
  data: function () {
    return {
      isLoading: true,
      selectedPlan: null,
      eUser: null,
    };
  },
  components: {
    InputInfoStatus,
    PlanDetail,
    PlanAttention,
  },
  computed: {
    successURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-premium-detail",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
    failureURLOfCardRegistration() {
      const href = this.$router.resolve({
        name: "investor-premium",
      }).href;

      return location.protocol + "//" + location.host + href;
    },
  },
  asyncComputed: {
    async isRegisteredCard() {
      return await this.$$investor$isRegisteredCard(this.me.user);
    },
  },
  methods: {
    changeResultPage() {
      this.$router.push({
        name: "investor-premium-detail",
      });
    },
    applicateTrial() {
      this.changePremium();
    },
    async changePremium() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$applicateUserByUID(this.auth.uid, this.me.id);

        this.$router.push({ name: "investor-home" });
        this.$$toast$openSuccess("お申込みが完了しました");
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.plan {
  &__title {
    // margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    @include sp {
      margin-top: 28px;
      font-size: 20px;
    }
  }
  &__texts {
    margin-bottom: 16px;
    text-align: center;
  }
  &__select {
    margin: auto 100px;
    @include sp {
      margin: auto 40px;
    }
    &__txt {
      color: #777;
      font-size: 11px;
      line-height: 19px;
      @include sp {
        font-size: 12px;
      }
    }
    &__radio {
      color: #777;
      margin-top: 20px;
    }
  }
  &__buttons {
    margin-top: 32px;
    margin-bottom: 40px;
    text-align: center;
    @include sp {
      margin-top: 20px;
    }
  }
  &__attention {
    margin: auto 100px;
    @include sp {
      margin: auto 40px;
    }
    &__txt {
      color: #777;
      font-size: 11px;
      line-height: 19px;
      @include sp {
        font-size: 12px;
      }
    }
  }
}
</style>
