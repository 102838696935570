<template>
  <div class="z-scoreProgress">
    <div class="z-scoreProgress__progress">
      <div class="z-scoreProgress__circle">
        <svg
          class="z-scoreProgress__circle__svg"
          :width="circleSize"
          :height="circleSize"
        >
          <defs>
            <linearGradient
              :id="`z-scoreProgressColor-${id}`"
              x1="100%"
              y1="0%"
              x2="0%"
              y2="0%"
            >
              <stop
                offset="0%"
                :style="{ 'stop-color': '#fff', 'stop-opacity': 1 }"
              />
              <stop
                offset="100%"
                :style="{ 'stop-color': '#1ccefb', 'stop-opacity': 1 }"
              />
            </linearGradient>
          </defs>
          <circle
            class="z-scoreProgress__circle__svg__inner__line"
            :cx="centerPoint"
            :cy="centerPoint"
            :r="progressScale.radius"
            :style="{
              'stroke-width': progressScale.strokeWidth / 2,
              stroke: '#fff',
            }"
          ></circle>
          <circle
            class="z-scoreProgress__circle__svg__inner__fill"
            :cx="centerPoint"
            :cy="centerPoint"
            :r="progressScale.radius"
            :style="fillStyle"
          ></circle>
        </svg>
        <div
          class="z-scoreProgress__inner"
          :style="{ color: '#fff' }"
          :class="`is-${scaleType}`"
        >
          <b-tooltip
            class="z-scoreProgress__inner__tooltip"
            type="is-dark"
            always
            animated
            :label="(diff > 0 ? '+' : '') + diff"
            :active="isShowTooltip"
          />
          <p class="z-scoreProgress__inner__label">Your Score</p>
          <p class="z-scoreProgress__inner__score">
            {{ value }}
          </p>
          <p class="z-scoreProgress__inner__label">Rank</p>
          <p class="z-scoreProgress__inner__rank">
            {{ rankValue }}
          </p>
        </div>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { clamp } from "lodash";

// 0%〜100%として扱うスコアの値
const RANGE_SCORE_VALUE = [0, 990];

export default {
  name: "ZScoreProgress",
  data: function () {
    return {
      value: 0,
      offset: 0,
      diff: 0,
      isShowTooltip: false,
      countTimer: null,
      offsetTimer: null,
      id: this.$uuid.v4(),
    };
  },
  props: {
    // スコア
    score: {
      type: [Number, String],
      default: 0,
    },
    // ランク
    rank: {
      type: [Number, String],
      default: 4,
    },
    // 大きさ
    scaleType: {
      type: String,
      validator: function (value) {
        return (
          ["large", "large-medium", "medium", "small"].indexOf(value) !== -1
        );
      },
      default: "large",
    },
    // 線の太さ
    strokeWidth: {
      type: Number,
      default: 12,
    },
    // トランジションスピード
    transitionDuration: {
      type: Number,
      default: 1000,
    },
  },
  computed: {
    rankValue() {
      if (!Number.isFinite(this.rank) || this.score === 0) {
        return "-";
      }
      return this.$$ks.get(this.$$ks.ranks, this.rank).text;
    },
    // 円周
    circumference() {
      return 2 * Math.PI * this.progressScale.radius;
    },
    // 円のサイズ
    circleSize() {
      return (this.progressScale.radius + this.progressScale.strokeWidth) * 2;
    },
    // 中心点
    centerPoint() {
      return this.circleSize / 2;
    },
    // プログレスバー(塗り)のスタイル
    fillStyle() {
      return {
        stroke: `url(#z-scoreProgressColor-${this.id})`,
        strokeDasharray: this.circumference,
        strokeWidth: this.progressScale.strokeWidth,
        strokeDashoffset: this.offset,
        transition: `stroke-dashoffset ${this.transitionDuration}ms ease`,
      };
    },
    progressScale() {
      switch (this.scaleType) {
        case "small":
          return { strokeWidth: 2, radius: 18 };
        case "medium":
          return { strokeWidth: 4, radius: 27 };
        case "large-medium":
          return { strokeWidth: 6, radius: 40 };
        case "large":
        default:
          return { strokeWidth: 12, radius: 80 };
      }
    },
  },
  methods: {
    countNumber(value) {
      const percentage =
        (clamp(value, RANGE_SCORE_VALUE[0], RANGE_SCORE_VALUE[1]) /
          RANGE_SCORE_VALUE[1]) *
        100;
      this.offset = (this.circumference * (100 - percentage)) / 100;
    },
  },
  watch: {
    score: {
      handler: function (value, oldValue) {
        window.clearTimeout(this.countTimer);
        this.isShowTooltip = false;

        if (!Number.isFinite(value)) {
          this.value = "-";
          this.countNumber(0);
          return;
        }

        if (oldValue === undefined) {
          this.value = value;
          this.countNumber(value);
          return;
        }

        if (this.transitionDuration === 0) {
          this.value = value;
          this.countNumber(value);
          return;
        }

        const interval = this.transitionDuration / 100;
        const initialValue = oldValue || 0;

        this.diff = value - initialValue;

        let counter = 0;
        const animate = () => {
          counter++;
          if (counter <= 100) {
            this.value = Math.floor((this.diff * counter) / 100) + initialValue;
            this.countTimer = setTimeout(() => {
              animate();
            }, interval);
          } else {
            window.clearTimeout(this.countTimer);
            this.countTimer = null;
            this.value = value;
            this.isShowTooltip = false;
          }
        };
        this.isShowTooltip = true;
        animate();
        this.offsetTimer = setTimeout(() => {
          this.countNumber(value);
        }, 10);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.countTimer) {
      window.clearTimeout(this.countTimer);
      this.countTimer = null;
    }
    if (this.offsetTimer) {
      window.clearTimeout(this.offsetTimer);
      this.offsetTimer = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-scoreProgress {
  display: block;
  text-align: center;
  &__circle {
    position: relative;
    &__svg {
      transform: rotate(-90deg);
      &__inner {
        &__line {
          fill: none;
          stroke-opacity: 0.3;
        }
        &__fill {
          fill: none;
          stroke-linecap: round;
        }
      }
    }
  }
  &__inner {
    width: 100%;
    top: 50%;
    left: 50%;
    padding-top: 6px;
    position: absolute;
    transform: translate(-50%, -50%);
    line-height: 1;
    &.is-large-medium,
    &.is-medium,
    &.is-small {
      padding-top: 0;
    }
    &__tooltip {
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      font-weight: 16px;
      .is-large-medium &,
      .is-medium &,
      .is-small & {
        display: none;
      }
    }
    &__label {
      font-size: 16px;
      font-weight: bold;
      .is-large-medium &,
      .is-medium &,
      .is-small & {
        display: none;
      }
    }
    &__score {
      margin: 6px 0 4px;
      font-size: 44px;
      font-weight: bold;
      .is-large-medium & {
        margin: 0;
        font-size: 28px;
      }
      .is-medium & {
        margin: 0;
        font-size: 16px;
      }
      .is-small & {
        margin: 0;
        font-size: 14px;
      }
    }
    &__rank {
      margin-top: 6px;
      font-size: 28px;
      font-weight: bold;
      .is-large-medium & {
        margin-top: 4px;
        font-size: 20px;
      }
      .is-medium & {
        margin-top: 0;
        font-size: 14px;
      }
      .is-small & {
        margin-top: 0;
        margin-bottom: 1px;
        font-size: 11px;
      }
    }
  }
}
</style>
