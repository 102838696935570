<template>
  <div v-if="chartData" class="z-marketTrendHeatMap">
    <ul class="z-marketTrendHeatMap__buildingList">
      <li
        class="z-marketTrendHeatMap__buildingItem"
        v-for="item in chartData"
        :key="item.buildingType"
      >
        <div class="z-marketTrendHeatMap__buildingItem__label">
          <p class="buildingTitle">
            {{ $$ks.getMarketTrendType(item.buildingType).text || "" }}
          </p>
          <p class="count">{{ item.countsOfbuildingType || 0 }}</p>
        </div>
        <ul class="z-marketTrendHeatMap__buildingItem__heatMapList">
          <li
            class="z-marketTrendHeatMap__buildingItem__heatMapItem"
            v-for="(count, index) in item.countsOfYield"
            :key="index"
            :style="{
              'background-color': getColors(count.count, item.countsOfYield),
            }"
          ></li>
        </ul>
      </li>
      <li class="z-marketTrendHeatMap__buildingItem">
        <div class="z-marketTrendHeatMap__buildingItem__label"></div>
        <ul class="z-marketTrendHeatMap__buildingItem__legend">
          <li
            class="z-marketTrendHeatMap__buildingItem__legendItem"
            v-for="(legend, index) in chartData[0].countsOfYield"
            :key="index"
          >
            <span>{{ Math.round(legend.couponYieldRate.from * 100) }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
const COLOR_HEATMAP = ["#d4eefb", "#9dddff", "#47adff", "#0a2550"];

export default {
  name: "ZMarketTrendHeatMap",
  props: {
    chartData: {
      type: Array,
      default: () => [],
      //required: true
    },
  },
  methods: {
    getColors(current, allCount) {
      const all = allCount.reduce((p, c) => p + c.count, 0);
      const percentage = current / all;
      if (percentage > 0.2) {
        return COLOR_HEATMAP[3];
      }
      if (percentage > 0.1) {
        return COLOR_HEATMAP[2];
      }
      if (percentage > 0.03) {
        return COLOR_HEATMAP[1];
      } else {
        return COLOR_HEATMAP[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-marketTrendHeatMap {
  &__buildingList {
    width: 100%;
  }
  &__buildingItem {
    width: 100%;
    padding: 1px 0;
    display: flex;
    align-items: center;
    @include sp {
      display: block;
    }
    &__label {
      width: 20%;
      max-width: 160px;
      min-width: 130px;
      display: flex;
      padding-right: 8px;
      align-items: center;
      color: #27325f;
      .buildingTitle {
        flex: 1 1;
        font-size: 16px;
      }
      .count {
        font-size: 16px;
      }
    }
    &__heatMapList {
      display: flex;
      flex: 1 1;
      align-items: center;
      justify-content: space-evenly;
    }
    &__heatMapItem {
      height: 32px;
      margin: 0 1px;
      flex: 1 1;
      background-color: #eee;
      border-radius: 4px;
    }
    &__legend {
      display: flex;
      flex: 1 1;
      align-items: center;
      justify-content: space-evenly;
    }
    &__legendItem {
      flex: 1 1;
      text-align: left;
      font-size: 16px;
      color: #777;
      &:first-child {
        span {
          display: none;
        }
      }
      span {
        display: inline-block;
        transform: translateX(-50%);
        &:after {
          display: inline-block;
          font-size: 12px;
          content: "%";
        }
      }
    }
  }
}
</style>
