<template>
  <div class="z-profileImageSelect">
    <div class="z-profileImageSelect__main">
      <div class="z-profileImageSelect__main__thumbnail">
        <div class="empty">
          <b-icon icon="account" size="is-medium" />
        </div>
        <figure class="photo" v-if="value && value.id">
          <ZImage :original="true" v-model="value" />
        </figure>
      </div>
      <template v-if="isRequired">
        <input
          class="z-profileImageSelect__main__input"
          type="text"
          name="image2"
          v-model="internalValue"
          v-validate="'isRequiredImage|required'"
        />
      </template>
      <div class="z-profileImageSelect__main__tool">
        <label
          class="z-profileImageSelect__main__tool__select button is-secondary"
        >
          <span>変更する</span>
          <input
            type="file"
            name="image"
            accept="image/*"
            @change="loadImage"
          />
        </label>

        <DeleteButton
          class="z-profileImageSelect__main__tool__delete"
          v-if="value && value.id"
          label="画像を削除する"
          @click="removeImage"
        />
      </div>
    </div>
    <div class="z-profileImageSelect__cropArea" v-show="sourceImage">
      <ZImageCrop
        :sourceImage.sync="sourceImage"
        :croppedImage.sync="croppedImageBase64"
        :aspect="1"
        :isCircle="true"
      />
    </div>
  </div>
</template>

<script>
import ImageMixin from "@/mixins/common/image/Image.vue";
import ZImageCrop from "@/components/parts/ZImageCrop.vue";

export default {
  name: "ZProfileImageSelect",
  inject: ["$validator"],
  components: {
    ZImageCrop,
  },
  mixins: [ImageMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
    isRequired: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      sourceImage: "",
      croppedImageBase64: "",
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value !== newVal) this.$emit("input", newVal);
      },
    },
  },
  methods: {
    loadImage(event) {
      const file = event.target.files[0];

      if (!file.type.includes("image/")) {
        // TODO: エラーメッセージ
        console.error("Please select an image file");
        return;
      }

      if (typeof FileReader !== "function") {
        // TODO: エラーメッセージ
        console.error("Sorry, FileReader API not supported");
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.sourceImage = fileEvent.target.result;
        event.target.value = ""; // 同一ファイルの再選択を許容するため、inputの値をリセット
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.sourceImage = "";
      this.croppedImageBase64 = "";
      this.$emit("input", this.$$investor$getNewImage());
    },
  },
  watch: {
    croppedImageBase64: {
      async handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) {
          return;
        }
        const loadingComponent = this.$loading.open();
        try {
          // File形式に変換
          const file = this.$$images$base64pngToFileOutput("profile", newVal); // this.base64pngToFileOutput(newVal);

          // 画像をアップロード
          const data = await this.$$image$upload("images", file);

          if (data.id) {
            const imageData = {
              ...this.$$investor$getNewImage(),
              id: data.id,
              description: data.description || "",
            };
            this.$emit("input", imageData);
          }
        } catch (error) {
          this.$raven.captureException(error);
          this.$$toast$openError("画像のアップロードに失敗しました");
          // this.$toast.open({
          //   duration: 10000,
          //   message: "画像のアップロードに失敗しました",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        } finally {
          loadingComponent.close();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-profileImageSelect {
  width: 100%;
}

.z-profileImageSelect__main {
  width: 100%;
  display: flex;
  &__input {
    display: none;
  }
  &__thumbnail {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    .photo {
      width: 100%;
      height: 100%;
      position: absolute;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      /deep/ img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
      }
    }
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      background-color: #ddd;
      /deep/.icon {
        color: #9a9a9a;
      }
    }
  }
  &__tool {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    align-items: flex-start;
    &__select {
      display: block;
      /deep/ &.button {
        color: #0262d8;
        border-color: #0262d8;
      }
      input {
        display: none;
      }
    }
    &__delete {
      margin-top: 6px;
    }
  }
}

.z-profileImageSelect__cropArea {
  margin-top: 16px;
}
</style>
