<template>
  <div class="z-incomeBar">
    <div class="z-incomeBar__title">Income</div>
    <div class="z-incomeBar__barwrapper">
      <div class="z-incomeBar__bar" :class="{ 'is-empty': !pl.sum }">
        <span
          class="z-incomeBar__bar__item z-incomeBar__bar__item--salary"
          :style="{ flexGrow: pl.salary || 0 }"
        ></span>
        <span
          class="z-incomeBar__bar__item z-incomeBar__bar__item--diviend"
          :style="{ flexGrow: pl.dividend || 0 }"
        ></span>
        <span
          class="z-incomeBar__bar__item z-incomeBar__bar__item--pension"
          :style="{ flexGrow: pl.pension || 0 }"
        ></span>
        <span
          class="z-incomeBar__bar__item z-incomeBar__bar__item--realEstate"
          :style="{ flexGrow: pl.realEstate || 0 }"
        ></span>
        <span
          class="z-incomeBar__bar__item z-incomeBar__bar__item--others"
          :style="{ flexGrow: pl.others || 0 }"
        ></span>
      </div>
      <div class="z-incomeBar__tooltips">
        <span
          class="z-incomeBar__tooltips__item"
          :style="{ flexGrow: pl.salary || 0 }"
        >
          <b-tooltip label="給与・役員報酬" type="is-dark" always />
        </span>
        <span
          class="z-incomeBar__tooltips__item"
          :style="{ flexGrow: pl.dividend || 0 }"
        >
          <b-tooltip label="配当・利子" type="is-dark" always />
        </span>
        <span
          class="z-incomeBar__tooltips__item"
          :style="{ flexGrow: pl.pension || 0 }"
        >
          <b-tooltip label="年金" type="is-dark" always />
        </span>
        <span
          class="z-incomeBar__tooltips__item"
          :style="{ flexGrow: pl.realEstate || 0 }"
        >
          <b-tooltip label="不動産収入" type="is-dark" always />
        </span>
        <span
          class="z-incomeBar__tooltips__item"
          :style="{ flexGrow: pl.others || 0 }"
        >
          <b-tooltip label="その他" type="is-dark" always />
        </span>
      </div>
    </div>
    <ZAnimatedPriceLabel :value="pl.sum" class="z-incomeBar__value" />
  </div>
</template>

<script>
import ZAnimatedPriceLabel from "@/components/parts/ZAnimatedPriceLabel.vue";

export default {
  name: "ZIncomeBar",
  components: {
    ZAnimatedPriceLabel,
  },
  props: {
    pl: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-incomeBar {
  position: relative;
  color: #fff;
  text-align: center;
  &__title {
    font-family: $family-secondary;
    font-size: 15px;
  }
  &__barwrapper {
    width: 200px;
    height: 14px;
    margin: 18px auto 0;
    position: relative;
  }
  &__bar {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    border-radius: 7px;
    overflow: hidden;
    background: linear-gradient(to right, #1a579f, #ffffff);
    &__item {
      height: 100%;
      transition: flex 1s ease;
      .is-empty & {
        display: none;
      }
      &--salary {
        background-color: #1a579f;
      }
      &--diviend {
        background-color: #24aae9;
      }
      &--pension {
        background-color: #64cdff;
      }
      &--realEstate {
        background-color: #bae9ff;
      }
      &--others {
        background-color: #ffffff;
      }
    }
  }
  &__tooltips {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    &__item {
      height: 100%;
      display: flex;
      justify-content: center;
      transition: flex 1s ease;
      cursor: pointer;
      /deep/ .b-tooltip {
        opacity: 0;
      }
      &:hover {
        /deep/ .b-tooltip {
          opacity: 1;
        }
      }
    }
  }
  &__value {
    margin-top: 14px;
  }
}
</style>
