import "isomorphic-fetch";
import "custom-event-polyfill";

import Vue from "vue";
import App from "./App.vue";
import router from "./routers";
import store from "./store";

import jQuery from "jquery";
global.jquery = jQuery;
global.$ = jQuery;
window.$ = window.jQuery = require("jquery");

import VeeValidate, { Validator } from "vee-validate";
import ja from "vee-validate/dist/locale/ja";
Validator.localize("ja", ja);
Vue.use(VeeValidate, { locale: ja });
// custom validation
// カタカナ
Validator.extend("isKata", {
  validate: function isKata(value) {
    value = value == null ? "" : value;
    if (value.match(/^[ァ-ヶー\s]*$/)) {
      return true;
    } else {
      return false;
    }
  },
  getMessage: (field) => `${field}はカタカナで入力してください。`,
});

Validator.extend("isRequiredImage", {
  validate: function isRequiredImage(value) {
    if (value && value.id) {
      return true;
    } else {
      return false;
    }
  },
  getMessage: (field) => `${field}を設定してください。`,
});

let vh = window.innerHeight * 0.01;
// カスタム変数--vhの値をドキュメントのルートに設定
document.documentElement.style.setProperty("--vh", `${vh}px`);

import Buefy from "buefy";
Vue.use(Buefy);

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import kubuns from "@/kubuns/kubuns";
kubuns.initial(Vue);

import raven from "@/plugins/raven";
raven.initial(Vue);

import RetryPromise from "@/plugins/RetryPromise";
RetryPromise.initial(Vue);

import Vuex from "vuex";
Vue.use(Vuex);

import VueLazyload from "vue-lazyload";
// const skeltonImage  =require("@/assets/skelton.svg");
const emptyImage = require("@/assets/empty/noimage.png");
Vue.use(VueLazyload, {
  preLoad: 3.0,
  error: emptyImage,
  loading: "/skelton.svg",
  attempt: 3,
});

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

import UUID from "vue-uuid";
Vue.use(UUID);

import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput);

import cloneDeepMixin from "@/mixins/common/cloneDeep";
Vue.mixin(cloneDeepMixin);

import ScoreChartMixin from "@/mixins/chart/ScoreChart";
Vue.mixin(ScoreChartMixin);

import MarketChartMixin from "@/mixins/chart/MarketChart";
Vue.mixin(MarketChartMixin);

import MarketTrendChartMixin from "@/mixins/chart/MarketTrendChart";
Vue.mixin(MarketTrendChartMixin);

import CalenderMixin from "@/mixins/common/calender/Calender";
Vue.mixin(CalenderMixin);

import AgeMixin from "@/mixins/common/Age";
Vue.mixin(AgeMixin);

import PriceMixin from "@/mixins/common/Price";
Vue.mixin(PriceMixin);

import NumMixin from "@/mixins/common/Num";
Vue.mixin(NumMixin);

import RateMixin from "@/mixins/common/Rate";
Vue.mixin(RateMixin);

//import ZipMixin from "@/mixins/common/zip/Zip";
//Vue.mixin(ZipMixin);

import ValidatorMixin from "@/mixins/common/validator/Validator";
Vue.mixin(ValidatorMixin);

// import VersionsMixin from "@/mixins/common/versions/Versions";
// Vue.mixin(VersionsMixin);

import ToastMixin from "@/mixins/common/Toast";
Vue.mixin(ToastMixin);

import ErrorMixin from "@/mixins/common/error/Error";
Vue.mixin(ErrorMixin);

import bulma from "@/mixins/common/bulmas/Bulma";
Vue.mixin(bulma);

import userAgent from "@/mixins/common/UserAgent";
Vue.mixin(userAgent);

import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

import VueGtm from "vue-gtm";
// if (process.env.VUE_APP_GTM_ID) {
//   console.log("Use gtm!:" + process.env.VUE_APP_GTM_ID);
//   Vue.use(VueGtm, {
//     id: process.env.VUE_APP_GTM_ID,
//     debug: true
//   });
// } else {
//   console.log("Not use gtm!");
// }

//== Mixins ==
import UserMixin from "@/mixins/user/User";
Vue.mixin(UserMixin);

import InvestorMixin from "@/mixins/investor/Investor";
Vue.mixin(InvestorMixin);

import RealestateMixin from "@/mixins/realEstate/RealEstate";
Vue.mixin(RealestateMixin);

import AdminMixin from "@/mixins/admin/Admin";
Vue.mixin(AdminMixin);

import RealtorMixin from "@/mixins/realtor/Realtor";
Vue.mixin(RealtorMixin);

import AreaMixin from "@/mixins/area/Area";
Vue.mixin(AreaMixin);

import LineMixin from "@/mixins/line/Line";
Vue.mixin(LineMixin);

import BankMixin from "@/mixins/bank/Bank";
Vue.mixin(BankMixin);

import messageMixin from "@/mixins/message/Message";
Vue.mixin(messageMixin);

import informationMixin from "@/mixins/information/Information";
Vue.mixin(informationMixin);

import TransactionMixin from "@/mixins/transaction/Transaction";
Vue.mixin(TransactionMixin);

import DocsMixin from "@/mixins/docs/Docs";
Vue.mixin(DocsMixin);

//-- buttons
import MoreButton from "@/components/buttons/MoreButton";
Vue.component("MoreButton", MoreButton);

import AddButton from "@/components/buttons/AddButton";
Vue.component("AddButton", AddButton);

import DeleteButton from "@/components/buttons/DeleteButton";
Vue.component("DeleteButton", DeleteButton);

import MembershipsButton from "@/components/buttons/MembershipsButton";
Vue.component("MembershipsButton", MembershipsButton);

import ZTemplateButton from "@/components/parts/ZTemplateButton";
Vue.component(ZTemplateButton.name, ZTemplateButton);

import ZFavoriteRealtorButton from "@/components/parts/ZFavoriteRealtorButton";
Vue.component(ZFavoriteRealtorButton.name, ZFavoriteRealtorButton);

// -- label
import PrivateLabel from "@/components/label/PrivateLabel";
Vue.component("PrivateLabel", PrivateLabel);

// -- loading
import ZLoading from "@/components/loading/ZLoading";
Vue.component("ZLoading", ZLoading);
//-- parts

import DemoInput from "@/components/parts/DemoInput";
Vue.component("DemoInput", DemoInput);

import ZTruncator from "@/components/parts/ZTruncator";
Vue.component(ZTruncator.name, ZTruncator);

import ZUserSelect from "@/components/parts/ZUserSelect";
Vue.component(ZUserSelect.name, ZUserSelect);

import ZPrefectureSelect from "@/components/parts/ZPrefectureSelect";
Vue.component(ZPrefectureSelect.name, ZPrefectureSelect);

import ZPrefectureCheckbox from "@/components/parts/ZPrefectureCheckbox";
Vue.component(ZPrefectureCheckbox.name, ZPrefectureCheckbox);

import ZCitySelect from "@/components/parts/ZCitySelect";
Vue.component(ZCitySelect.name, ZCitySelect);

import ZCityCheckbox from "@/components/parts/ZCityCheckbox";
Vue.component(ZCityCheckbox.name, ZCityCheckbox);

import ZLineSelect from "@/components/parts/ZLineSelect";
Vue.component(ZLineSelect.name, ZLineSelect);

import ZLineCheckbox from "@/components/parts/ZLineCheckbox";
Vue.component(ZLineCheckbox.name, ZLineCheckbox);

import ZStationSelect from "@/components/parts/ZStationSelect";
Vue.component(ZStationSelect.name, ZStationSelect);

import ZStationCheckbox from "@/components/parts/ZStationCheckbox";
Vue.component(ZStationCheckbox.name, ZStationCheckbox);

import ZField from "@/components/parts/ZField";
Vue.component(ZField.name, ZField);

import ZLabelList from "@/components/parts/ZLabelList";
Vue.component(ZLabelList.name, ZLabelList);

import ZImage from "@/components/parts/ZImage";
Vue.component(ZImage.name, ZImage);

import ZRealestateImages from "@/components/parts/ZRealestateImages";
Vue.component(ZRealestateImages.name, ZRealestateImages);

import ZCarousel from "@/components/parts/ZCarousel";
Vue.component(ZCarousel.name, ZCarousel);

import ZCarouselList from "@/components/parts/ZCarouselList";
Vue.component(ZCarouselList.name, ZCarouselList);

import ZProfileThumbnail from "@/components/parts/ZProfileThumbnail";
Vue.component(ZProfileThumbnail.name, ZProfileThumbnail);

import ZInput from "@/components/parts/ZInput";
Vue.component(ZInput.name, ZInput);

import ZTextarea from "@/components/parts/ZTextarea";
Vue.component(ZTextarea.name, ZTextarea);

import ZPriceInput from "@/components/parts/ZPriceInput";
Vue.component(ZPriceInput.name, ZPriceInput);

import ZPercentInput from "@/components/parts/ZPercentInput";
Vue.component(ZPercentInput.name, ZPercentInput);

import ZPostalCode from "@/components/parts/ZPostalCode";
Vue.component(ZPostalCode.name, ZPostalCode);

import ZTag from "@/components/parts/ZTag";
Vue.component(ZTag.name, ZTag);

import ZSelect from "@/components/parts/ZSelect";
Vue.component(ZSelect.name, ZSelect);

import ZSelect2 from "@/components/parts/ZSelect2";
Vue.component(ZSelect2.name, ZSelect2);

import ZCheckbox from "@/components/parts/ZCheckbox";
Vue.component(ZCheckbox.name, ZCheckbox);

import ZRadio from "@/components/parts/ZRadio";
Vue.component(ZRadio.name, ZRadio);

import ZSwitch from "@/components/parts/ZSwitch";
Vue.component(ZSwitch.name, ZSwitch);

import ZDatepicker from "@/components/parts/ZDatepicker";
Vue.component(ZDatepicker.name, ZDatepicker);

import ZBankSelect from "@/components/parts/ZBankSelect";
Vue.component(ZBankSelect.name, ZBankSelect);

import ZBranchSelect from "@/components/parts/ZBranchSelect";
Vue.component(ZBranchSelect.name, ZBranchSelect);

import ZYearMonthSelect from "@/components/parts/ZYearMonthSelect";
Vue.component(ZYearMonthSelect.name, ZYearMonthSelect);

import ZYearMonthPeriodInput from "@/components/parts/ZYearMonthPeriodInput";
Vue.component(ZYearMonthPeriodInput.name, ZYearMonthPeriodInput);

import ZLayoutSelect from "@/components/parts/ZLayoutSelect";
Vue.component(ZLayoutSelect.name, ZLayoutSelect);

import ZPriceFromToSelect from "@/components/parts/ZPriceFromToSelect";
Vue.component(ZPriceFromToSelect.name, ZPriceFromToSelect);

import ZIncomeFromToSelect from "@/components/parts/ZIncomeFromToSelect";
Vue.component(ZIncomeFromToSelect.name, ZIncomeFromToSelect);

import ZRateFromToSelect from "@/components/parts/ZRateFromToSelect";
Vue.component(ZRateFromToSelect.name, ZRateFromToSelect);

import ZTransportSelect from "@/components/parts/ZTransportSelect";
Vue.component(ZTransportSelect.name, ZTransportSelect);

import ZTransportRangeSelect from "@/components/parts/ZTransportRangeSelect";
Vue.component(ZTransportRangeSelect.name, ZTransportRangeSelect);

import ZAgeOfbuildingFromToSelect from "@/components/parts/ZAgeOfbuildingFromToSelect";
Vue.component(ZAgeOfbuildingFromToSelect.name, ZAgeOfbuildingFromToSelect);

import ZScoreFromToSelect from "@/components/parts/ZScoreFromToSelect";
Vue.component(ZScoreFromToSelect.name, ZScoreFromToSelect);

import ZYearFromToSelect from "@/components/parts/ZYearFromToSelect";
Vue.component(ZYearFromToSelect.name, ZYearFromToSelect);

import ZComment from "@/components/parts/ZComment";
Vue.component(ZComment.name, ZComment);

import ZSortSelect from "@/components/parts/ZSortSelect";
Vue.component(ZSortSelect.name, ZSortSelect);

import ZScoreProgress from "@/components/parts/ZScoreProgress";
Vue.component(ZScoreProgress.name, ZScoreProgress);

import ZAreaField from "@/components/parts/ZAreaField";
Vue.component(ZAreaField.name, ZAreaField);

import ZLineField from "@/components/parts/ZLineField";
Vue.component(ZLineField.name, ZLineField);

import ZAreaLineFieldSet from "@/components/parts/ZAreaLineFieldSet";
Vue.component(ZAreaLineFieldSet.name, ZAreaLineFieldSet);

import ZModal from "@/components/parts/ZModal";
Vue.component(ZModal.name, ZModal);

import ZIcon from "@/components/parts/ZIcon";
Vue.component(ZIcon.name, ZIcon);

import ZHelpIcon from "@/components/parts/ZHelpIcon";
Vue.component(ZHelpIcon.name, ZHelpIcon);

import SkeletonBox from "@/components/box/SkeletonBox";
Vue.component(SkeletonBox.name, SkeletonBox);

import TileBox from "@/components/parts/TileBox";
Vue.component(TileBox.name, TileBox);

import TileBoxOverlay from "@/components/parts/TileBoxOverlay";
Vue.component(TileBoxOverlay.name, TileBoxOverlay);

import ZTileBoxTitle from "@/components/parts/ZTileBoxTitle";
Vue.component(ZTileBoxTitle.name, ZTileBoxTitle);

import ZTileBoxBodyList from "@/components/parts/ZTileBoxBodyList";
Vue.component(ZTileBoxBodyList.name, ZTileBoxBodyList);

import ZEditAccordion from "@/components/parts/ZEditAccordion";
Vue.component(ZEditAccordion.name, ZEditAccordion);

import ZHelpAccordion from "@/components/parts/ZHelpAccordion";
Vue.component(ZHelpAccordion.name, ZHelpAccordion);

import ZBlankBox from "@/components/parts/ZBlankBox";
Vue.component(ZBlankBox.name, ZBlankBox);

import ZProfileImageSelect from "@/components/parts/ZProfileImageSelect";
Vue.component(ZProfileImageSelect.name, ZProfileImageSelect);

import ZThumbnailListSelect from "@/components/parts/ZThumbnailListSelect";
Vue.component(ZThumbnailListSelect.name, ZThumbnailListSelect);

import ZHeader from "@/components/parts/ZHeader";
Vue.component(ZHeader.name, ZHeader);

import ZNotification from "@/components/parts/ZNotification";
Vue.component(ZNotification.name, ZNotification);

import ZDocs from "@/components/parts/ZDocs";
Vue.component(ZDocs.name, ZDocs);

import ZDocsList from "@/components/parts/ZDocsList";
Vue.component(ZDocsList.name, ZDocsList);

import ZDocsInput from "@/components/parts/ZDocsInput";
Vue.component(ZDocsInput.name, ZDocsInput);

import ZDocsListSelect from "@/components/parts/ZDocsListSelect";
Vue.component(ZDocsListSelect.name, ZDocsListSelect);

import ZDateSelect from "@/components/parts/ZDateSelect";
Vue.component(ZDateSelect.name, ZDateSelect);

import ZMessageDocsList from "@/components/parts/ZMessageDocsList";
Vue.component(ZMessageDocsList.name, ZMessageDocsList);

import ZCreditCardRegister from "@/components/card/ZCreditCardRegister";
Vue.component(ZCreditCardRegister.name, ZCreditCardRegister);

import ZSuggestionsCheckbox from "@/components/parts/ZSuggestionsCheckbox";
Vue.component(ZSuggestionsCheckbox.name, ZSuggestionsCheckbox);

import SuggestionsView from "@/components/message/SuggestionsView";
Vue.component(SuggestionsView.name, SuggestionsView);

//-- chart
import DemoChart from "@/components/charts/Chart";
Vue.component("DemoChart", DemoChart);

import BsChart from "@/components/charts/BsChart";
Vue.component("BsChart", BsChart);

import RealtorFavoriteCard from "@/components/investor/RealtorFavoriteCard";
Vue.component("RealtorFavoriteCard", RealtorFavoriteCard);

import RealtorFavoriteView from "@/components/investor/RealtorFavoriteView";
Vue.component("RealtorFavoriteView", RealtorFavoriteView);

import ZPagination from "@/components/parts/ZPagination";
Vue.component("ZPagination", ZPagination);

import ZZeroIcon from "@/components/parts/ZZeroIcon";
Vue.component("ZZeroIcon", ZZeroIcon);

Vue.config.productionTip = false;

// Vue.use(VueGtm, {
//   id: process.env.VUE_APP_GTM_ID,
//   vueRouter: router,
//   enabled: true,
//   debug: true,
// });

router.afterEach(async (/*to, from */) => {
  if (!window.dataLayer) {
    if (process.env.VUE_APP_GTM_ID) {
      console.log("Use gtm!:" + process.env.VUE_APP_GTM_ID);
      Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM_ID,
        vueRouter: router,
        enabled: true,
        debug: true,
      });
    } else {
      console.log("Not use gtm!");
    }
  } else {
    console.log("Alrady install gtm!");
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  async beforeCreate() {
    // storeの初期化
    await store.dispatch("auth/init");
    await store.dispatch("user/init");
    // store.dispatch("userAdmin/init");
  },
}).$mount("#app");
