<template>
  <section class="z-section">
    <div class="mainTitle">
      <p class="mainTitle__txt">YOU</p>
    </div>
    <div class="z-section__inner">
      <div class="assetsInfo">
        <div class="assetsInfo__bsArea">
          <h3 class="assetsInfo__bsArea__title">Balance Sheet</h3>
          <div class="assetsInfo__bsArea__flexBlock">
            <div class="assets">
              <h4 class="assets__subTitle">ASSETS</h4>

              <!-- 現金 -->
              <tile-box
                overlayTitle="Portfolio Input"
                overlaySubTitle="ポートフォリオ登録"
                ref="tileAssets"
              >
                <template v-slot:title>
                  <AssetsInfoTitle tileType="cash" :amount="cashSum" />
                </template>
                <template v-slot:body>
                  <div class="AssetsInfoDetail__wrapper">
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(cash, cash_index) in cashesArrayRebuild"
                      :key="'cash_' + cash_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(cash_index, '現金')"
                        :amount="cash.amount"
                        :assetsType="cash.type"
                        :description="bankNameFix(cash.bank, cash.branch)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:overlay>
                  <EditAssets @saved="savedAssets()" />
                </template>
              </tile-box>
              <!-- / 現金 -->

              <!-- 証券・保険 -->
              <tile-box
                overlayTitle="Portfolio Input"
                overlaySubTitle="ポートフォリオ登録"
                ref="tileSecurityInsurance"
              >
                <template v-slot:title>
                  <AssetsInfoTitle
                    tileType="securityInsurance"
                    :amount="securityInsuranceSum"
                  />
                </template>
                <template v-slot:body>
                  <div
                    class="AssetsInfoDetail__wrapper"
                    v-if="isSecuritiesExist && isInsurancesExist"
                  >
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(
                        security, security_index
                      ) in securitiesArrayRebuild"
                      :key="'security_' + security_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(security_index, '有価証券')"
                        :amount="security.amount"
                        :assetsType="security.type"
                        :description="
                          bankNameFix(security.bank, security.branch)
                        "
                      />
                    </div>
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(
                        insurance, insurance_index
                      ) in insurancesArrayRebuild"
                      :key="'insurance_' + insurance_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(insurance_index, '保険')"
                        :amount="insurance.amount"
                        :assetsType="insurance.type"
                        :description="
                          bankNameFix(insurance.bank, insurance.branch)
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="AssetsInfoDetail__wrapper"
                    v-if="!isSecuritiesExist && isInsurancesExist"
                  >
                    <div class="AssetsInfoDetail__wrapper__inner">
                      <AssetsInfoDetail title="有価証券" :amount="null" />
                    </div>
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(
                        insurance, insurance_index
                      ) in insurancesArrayRebuild"
                      :key="'insurance_' + insurance_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(insurance_index, '保険')"
                        :amount="insurance.amount"
                        :assetsType="insurance.type"
                        :description="
                          bankNameFix(insurance.bank, insurance.branch)
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="AssetsInfoDetail__wrapper"
                    v-if="isSecuritiesExist && !isInsurancesExist"
                  >
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(
                        security, security_index
                      ) in securitiesArrayRebuild"
                      :key="'security_' + security_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(security_index, '有価証券')"
                        :amount="security.amount"
                        :assetsType="security.type"
                        :description="
                          bankNameFix(security.bank, security.branch)
                        "
                      />
                    </div>
                    <div class="AssetsInfoDetail__wrapper__inner">
                      <AssetsInfoDetail title="保険" :amount="null" />
                    </div>
                  </div>
                  <div
                    class="AssetsInfoDetail__wrapper"
                    v-if="!isSecuritiesExist && !isInsurancesExist"
                  >
                    <div class="AssetsInfoDetail__wrapper__inner">
                      <AssetsInfoDetail title="有価証券" :amount="null" />
                    </div>
                    <div class="AssetsInfoDetail__wrapper__inner">
                      <AssetsInfoDetail title="保険" :amount="null" />
                    </div>
                  </div>
                </template>
                <template v-slot:overlay>
                  <EditAssets @saved="savedSecurityInsurance()" />
                </template>
              </tile-box>
              <!-- / 証券・保険 -->

              <!-- 不動産（編集選択モーダル） -->
              <tile-box @onOpenOverlay="openSelectRealEstateModal()">
                <template v-slot:title>
                  <AssetsInfoTitle
                    tileType="realEstateBS"
                    :amount="realEstateBSSum"
                  />
                </template>
                <template v-slot:body>
                  <div
                    class="AssetsInfoDetail__wrapper"
                    v-if="
                      realEstatesArray.length &&
                      !isRealEstateAddressArrayLoading
                    "
                  >
                    <div
                      class="AssetsInfoDetail__wrapper__inner"
                      v-for="(
                        realEstateBS, realEstateBS_index
                      ) in realEstatesArray"
                      :key="'realEstateBS_' + realEstateBS_index"
                    >
                      <AssetsInfoDetail
                        :title="titleFix(realEstateBS_index, '保有不動産')"
                        :amount="realEstateCalc(realEstateBS)"
                        :description="
                          realEstateAddressArray[realEstateBS_index] || ''
                        "
                      />
                    </div>
                  </div>
                  <div class="AssetsInfoDetail__wrapper" v-else>
                    <div class="AssetsInfoDetail__wrapper__inner">
                      <AssetsInfoDetail title="保有不動産" :amount="null" />
                    </div>
                  </div>
                </template>
              </tile-box>
              <!-- / 不動産（編集選択モーダル） -->
            </div>

            <div class="liabilities">
              <h4 class="liabilities__subTitle">LIABILITIES</h4>
              <div class="liabilities__tileBoxWrapper">
                <!-- 融資（編集選択モーダル） -->
                <tile-box
                  class="liabilities__tileBoxWrapper__loan"
                  @onOpenOverlay="openSelectRealEstateModal()"
                >
                  <template v-slot:title>
                    <AssetsInfoTitle
                      tileType="liabilities"
                      :amount="liabilitiesSum"
                    />
                  </template>
                  <template v-slot:body>
                    <div
                      class="AssetsInfoDetail__wrapper"
                      v-if="
                        realEstatesArray.length && !isRealEstateBankArrayLoading
                      "
                    >
                      <div
                        class="AssetsInfoDetail__wrapper__inner"
                        v-for="(
                          realEstateBS, liabilities_index
                        ) in realEstatesArray"
                        :key="'liabilities_' + liabilities_index"
                      >
                        <template v-if="realEstateBS.data.purchase.loan.bankId">
                          <AssetsInfoDetail
                            :title="titleFix(liabilities_index, '保有不動産')"
                            :amount="liabilitiesCalc(realEstateBS)"
                            :description="
                              realEstateBankArray[liabilities_index] || ''
                            "
                          />
                        </template>
                      </div>
                    </div>
                    <div class="AssetsInfoDetail__wrapper" v-else>
                      <div class="AssetsInfoDetail__wrapper__inner">
                        <AssetsInfoDetail title="保有不動産" :amount="null" />
                      </div>
                    </div>
                  </template>
                </tile-box>
                <!-- / 融資（編集選択モーダル） -->

                <!-- 純資産 -->
                <tile-box
                  class="liabilities__tileBoxWrapper__netAssets"
                  overlayTitle="Portfolio Input"
                  overlaySubTitle="ポートフォリオ登録"
                  :isEditButtonExist="false"
                  :withDetail="false"
                >
                  <template v-slot:title>
                    <AssetsInfoTitle
                      tileType="netAssets"
                      :amount="netAssetsSum"
                      :withDetail="false"
                    />
                  </template>
                </tile-box>
                <!-- / 純資産 -->
              </div>
            </div>
          </div>
        </div>
        <div class="assetsInfo__incomeArea">
          <h3 class="assetsInfo__incomeArea__title">Income</h3>
          <!-- 給与・役員報酬 -->
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tileSalary"
            :withDetail="false"
          >
            <template v-slot:title>
              <AssetsInfoTitle
                tileType="salary"
                :amount="salarySum"
                :withDetail="false"
              />
            </template>
            <template v-slot:body> </template>
            <template v-slot:overlay>
              <EditIncome @saved="savedSalary()" />
            </template>
          </tile-box>
          <!-- / 給与・役員報酬 -->

          <!-- 不動産収入（編集選択モーダル） -->
          <tile-box @onOpenOverlay="openSelectRealEstateModal()">
            <template v-slot:title>
              <AssetsInfoTitle
                tileType="realEstatePL"
                :amount="realEstatePLSum"
              />
            </template>
            <template v-slot:body>
              <div
                class="AssetsInfoDetail__wrapper"
                v-if="
                  realEstatesArray.length && !isRealEstateAddressArrayLoading
                "
              >
                <div
                  class="AssetsInfoDetail__wrapper__inner"
                  v-for="(realEstatePL, realEstatePL_index) in realEstatesArray"
                  :key="'realEstatePL_' + realEstatePL_index"
                >
                  <AssetsInfoRealEstateDetail
                    :title="titleFix(realEstatePL_index, '保有不動産')"
                    :amount="realEstateIncomeCalc(realEstatePL)"
                    :income="realEstatePL.data.annualEstimatedIncome"
                    :payment="realEstateRepaidCalc(realEstatePL)"
                    :description="
                      realEstateAddressArray[realEstatePL_index] || ''
                    "
                  />
                </div>
              </div>
              <div class="AssetsInfoDetail__wrapper" v-else>
                <div class="AssetsInfoDetail__wrapper__inner">
                  <AssetsInfoDetail title="保有不動産" :amount="null" />
                </div>
              </div>
            </template>
          </tile-box>
          <!-- / 不動産収入（編集選択モーダル） -->

          <!-- 配当・利子 -->
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tileDividend"
            :withDetail="false"
          >
            <template v-slot:title>
              <AssetsInfoTitle
                tileType="dividend"
                :amount="dividendSum"
                :withDetail="false"
              />
            </template>
            <template v-slot:body> </template>
            <template v-slot:overlay>
              <EditIncome @saved="savedDividend()" />
            </template>
          </tile-box>
          <!-- / 配当・利子 -->

          <!-- 年金 -->
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tilePension"
            :withDetail="false"
          >
            <template v-slot:title>
              <AssetsInfoTitle
                tileType="pension"
                :amount="pensionSum"
                :withDetail="false"
              />
            </template>
            <template v-slot:body> </template>
            <template v-slot:overlay>
              <EditIncome @saved="savedPension()" />
            </template>
          </tile-box>
          <!-- / 年金 -->

          <!-- その他 -->
          <tile-box
            overlayTitle="Portfolio Input"
            overlaySubTitle="ポートフォリオ登録"
            ref="tileOthers"
            :withDetail="false"
          >
            <template v-slot:title>
              <AssetsInfoTitle
                tileType="others"
                :amount="othersSum"
                :withDetail="false"
              />
            </template>
            <template v-slot:body> </template>
            <template v-slot:overlay>
              <EditIncome @saved="savedOthers()" />
            </template>
          </tile-box>
          <!-- / その他 -->
        </div>
      </div>
    </div>

    <!-- 不動産・選択モーダル -->
    <z-modal
      :isActive="isSelectModalActive"
      title="編集する保有不動産を選択"
      @close="isSelectModalActive = false"
    >
      <template v-slot:content>
        <div class="selectModalBody">
          <ul class="selectModalBody__list">
            <li
              v-for="(realEstate, i) in me.user.realEstates"
              :index="i"
              :key="realEstate.id"
            >
              <b-button
                class="z-button is-expanded"
                @click="onSelectRealEstate(realEstate.id)"
                rounded
              >
                「{{ titleFix(i, "保有不動産") }}」を編集する
              </b-button>
            </li>
          </ul>
          <div class="selectModalBody__add">
            <AddButton label="追加する" @click="onSelectRealEstate()" />
          </div>
        </div>
      </template>
    </z-modal>
    <!-- / 不動産・選択モーダル -->

    <!-- 不動産・編集モーダル -->
    <tile-box-overlay
      title="Real Estate Set"
      subTitle="不動産情報設定"
      ref="overlay"
    >
      <template v-slot:OverlayReceive>
        <EditRealEstateUnit :id="crrRealEstateId" @saved="savedRealEstate()" />
      </template>
    </tile-box-overlay>
    <!-- / 不動産・編集モーダル -->
  </section>
</template>

<script>
import EditIncome from "./EditIncome";
import EditAssets from "./EditAssets";
import AssetsInfoTitle from "./AssetsInfoTitle";
import AssetsInfoDetail from "./AssetsInfoDetail";
import AssetsInfoRealEstateDetail from "./AssetsInfoRealEstateDetail";
import EditRealEstateUnit from "../../common/EditRealEstateUnit";

export default {
  name: "AssetsInfo",
  data: function () {
    return {
      isRealEstateAddressArrayLoading: true,
      isRealEstateBankArrayLoading: true,
      isSelectModalActive: false,
      crrRealEstateId: "",
    };
  },
  components: {
    EditIncome: EditIncome,
    EditAssets: EditAssets,
    EditRealEstateUnit: EditRealEstateUnit,
    AssetsInfoTitle: AssetsInfoTitle,
    AssetsInfoDetail: AssetsInfoDetail,
    AssetsInfoRealEstateDetail: AssetsInfoRealEstateDetail,
  },
  asyncComputed: {
    realEstateAddressArray: {
      get() {
        return Promise.all(
          this.me.user.realEstates.map(async (object) => {
            const result = await this.$$area$generateTextOfAddress(object.data);
            this.isRealEstateAddressArrayLoading = false;
            return result;
          })
        );
      },
      default() {
        return [];
      },
    },
    realEstateBankArray: {
      get() {
        return Promise.all(
          this.me.user.realEstates.map(async (object) => {
            if (object.data.purchase.loan.bankId) {
              const bankName = await this.$$bank$getInfOfBank(
                object.data.purchase.loan.bankId
              );
              const branchName = await this.$$bank$getInfOfBranch(
                object.data.purchase.loan.branchId
              );
              this.isRealEstateBankArrayLoading = false;
              return bankName.bank_name + " " + branchName.branch_name;
            } else {
              return "";
            }
            // const bankName = await this.$$bank$getInfOfBank(
            //   object.data.purchase.loan.bankId
            // );
            // const branchName = await this.$$bank$getInfOfBranch(
            //   object.data.purchase.loan.branchId
            // );
            // this.isRealEstateBankArrayLoading = false;
            // return bankName.bank_name + " " + branchName.branch_name;
          })
        );
      },
      default() {
        return [];
      },
    },
  },
  computed: {
    cashSum() {
      return this.$$user$BS.assets.cash.sum;
    },
    securityInsuranceSum() {
      return (
        this.$$user$BS.assets.security.sum + this.$$user$BS.assets.insurance.sum
      );
    },
    realEstateBSSum() {
      return this.$$user$BS.assets.realEstate.sum;
    },
    liabilitiesSum() {
      return this.$$user$BS.liabilities.sum;
    },
    netAssetsSum() {
      return this.$$user$BS.netAssets.sum;
    },
    salarySum() {
      return this.$$user$PL.salary;
    },
    realEstatePLSum() {
      return this.$$user$PL.realEstate;
    },
    dividendSum() {
      return this.$$user$PL.dividend;
    },
    pensionSum() {
      return this.$$user$PL.pension;
    },
    othersSum() {
      return this.$$user$PL.others;
    },
    cashesArrayRebuild() {
      const fixedArr = this.$$cloneDeep(this.me.user.data.assets.cashes).map(
        (object) => {
          object.type = this.$$ks.getIncomeCashType(object.type).text;
          return object;
        }
      );
      return fixedArr;
    },
    insurancesArrayRebuild() {
      const fixedArr = this.$$cloneDeep(
        this.me.user.data.assets.insurances
      ).map((object) => {
        object.type = this.$$ks.getIncomeInsuranceType(object.type).text;
        return object;
      });
      return fixedArr;
    },
    securitiesArrayRebuild() {
      const fixedArr = this.$$cloneDeep(
        this.me.user.data.assets.securities
      ).map((object) => {
        object.type = this.$$ks.getIncomeSecurityType(object.type).text;
        return object;
      });
      return fixedArr;
    },
    isInsurancesExist() {
      if (this.insurancesArrayRebuild.length) {
        return true;
      } else {
        return false;
      }
    },
    isSecuritiesExist() {
      if (this.securitiesArrayRebuild.length) {
        return true;
      } else {
        return false;
      }
    },
    realEstatesArray() {
      return this.me.user.realEstates;
    },
  },
  methods: {
    titleFix(index, prefix) {
      const fixedIndex = index + 1;
      if (fixedIndex <= 9) {
        return prefix + "0" + String(fixedIndex);
      } else {
        return prefix + String(fixedIndex);
      }
    },
    bankNameFix(bank, branch) {
      if (bank == null && branch == null) {
        return "";
      }
      if (bank == null) {
        return branch;
      }
      if (branch == null) {
        return bank;
      }
      return bank + " " + branch;
    },
    liabilitiesCalc(realEstates) {
      return this.$$realEstate$calcDebtSummary(realEstates.data.purchase.loan)
        .outstandingDebt;
    },
    realEstateCalc(realEstates) {
      return this.$$realEstate$calcStockByRealEstate(
        realEstates,
        this.$$realEstate$calcDebtSummary(realEstates.data.purchase.loan)
      );
    },
    realEstateIncomeCalc(realEstate) {
      return this.$$investor$calcRealEstateIncome(realEstate);
    },
    realEstateRepaidCalc(realEstate) {
      return this.$$investor$calcRealEstateRepaid(realEstate);
    },
    openSelectRealEstateModal() {
      this.isSelectModalActive = true;
    },
    onSelectRealEstate(id) {
      this.isSelectModalActive = false;
      this.openEditRealEstateModal(id);
    },
    openEditRealEstateModal(id) {
      this.isSelectModalActive = false;
      this.crrRealEstateId = id;
      this.$refs.overlay.open();
    },
    savedRealEstate() {
      this.$refs.overlay.close();
    },
    savedAssets() {
      this.$refs.tileAssets.close();
    },
    savedSecurityInsurance() {
      this.$refs.tileSecurityInsurance.close();
    },
    savedSalary() {
      this.$refs.tileSalary.close();
    },
    savedDividend() {
      this.$refs.tileDividend.close();
    },
    savedPension() {
      this.$refs.tilePension.close();
    },
    savedOthers() {
      this.$refs.tileOthers.close();
    },
  },
  // watch: {
  //   realEstateAddressArray: {
  //     handler: function(val){
  //       console.log(val);
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.mainTitle {
  margin-top: 16px;
  background-color: #f4f4f4;
  &__txt {
    font-size: 24px;
    line-height: 48px;
    padding-left: 46px;
    color: #787878;
    @include sp {
      padding-left: 16px;
      font-size: 20px;
    }
  }
}

.assetsInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @include md {
    display: block;
  }
  @include sp {
    display: block;
  }
  &__bsArea {
    @include lg {
      width: 65%;
      margin-right: 30px;
    }
    &__title {
      font-size: 20px;
      padding-bottom: 4px;
      border-bottom: solid 1px;
      border-color: #dddddd;
      margin-top: 16px;
      margin-bottom: 16px;
      color: #474747;
      @include sp {
        margin-top: 8px;
        font-size: 18px;
      }
    }
    &__flexBlock {
      display: flex;
      justify-content: space-between;
      @include sp {
        display: block;
      }
    }
  }
  .assets {
    width: 48%;
    @include lg {
      margin-right: 15px;
      height: 100%;
    }
    @include md {
      height: 100%;
    }
    @include sp {
      width: 100%;
    }
    &__subTitle {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 6px;
      color: #333333;
    }
  }
  .liabilities {
    width: 48%;
    display: flex;
    flex-direction: column;
    @include lg {
      margin-left: 15px;
    }
    @include sp {
      width: 100%;
    }
    &__subTitle {
      flex: 0 0 auto;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 6px;
      color: #333333;
      @include sp {
        padding-top: 8px;
      }
    }
    &__tileBoxWrapper {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      &__loan {
        flex: 1 1 auto;
      }
      &__netAssets {
        flex: 0 0 auto;
      }
    }
  }
  &__incomeArea {
    @include lg {
      width: 30.4%;
    }
    @include md {
      width: 48%;
    }
    @include sp {
      width: 100%;
    }
    &__title {
      margin-top: 16px;
      font-size: 20px;
      padding-bottom: 4px;
      border-bottom: solid 1px;
      border-color: #dddddd;
      margin-bottom: 38px;
      color: #474747;
      @include sp {
        font-size: 18px;
        margin-bottom: 16px;
      }
    }
  }
}

.assetsInfo /deep/ {
  // .columns.is-widescreen {
  //   @include md {
  //     display: block;
  //   }
  // }
  .tileBox {
    margin-bottom: 16px;
  }
}

.AssetsInfoDetail__wrapper {
  margin: 14px 16px 16px 16px;
  &__inner {
    &:first-child {
      .AssetsInfoDetail {
        padding-top: 0;
      }
      .AssetsInfoRealEstateDetail {
        padding-top: 0;
      }
    }
  }
}

.selectModalBody {
  margin-top: 28px;
  min-width: 584px;
  @include sp {
    margin-top: 20px;
    min-width: auto;
  }
  &__list {
    & li {
      &:not(:first-child) {
        margin-top: 20px;
        @include sp {
          margin-top: 14px;
        }
      }
    }
  }
  &__add {
    margin-top: 36px;
    @include sp {
      margin-top: 22px;
    }
  }
}
</style>
