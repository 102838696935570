<template>
  <div class="box invetor-profile-card" :key="investor && investor.id">
    <div class="columns is-muliline is-mobile invetor-profile-card__detail">
      <div class="column is-narrow">
        <div class="invetor-profile-card__score">
          <ZScoreProgress
            :score="investor.data.latestScore.score"
            :rank="investor.data.latestScore.rank"
            :transitionDuration="0"
            scaleType="medium"
          />
          <div class="content is-small has-text-centered has-text-white">
            <span> {{ $$age$calc(investor.data.base.birthday) }}歳 </span>
            <span> {{ $$ks.getTypeOfSex(investor.data.base.sex).text }} </span
            ><br />
          </div>
        </div>
        <template
          v-if="
            $$ks.investor.application.getMembership(investor.data.membership)
              .value === 20
          "
        >
          <div
            class="column is-narrow invetor-profile-card__description__badge-wrap"
            v-if="bulma && bulma.isMobile"
          >
            <!--
            <div class="content">
              <div class="invetor-profile-card__description__premium-badge">
                <img src="@/assets/investor/premium-card.png" />
              </div>
            </div>
            -->
          </div>
        </template>
      </div>
      <div class="column invetor-profile-card__description">
        <div class="invetor-profile-card__description__user">
          <div class="image is-32x32 invetor-profile-card__description__image">
            <z-profile-thumbnail v-model="investor.data.profile.image" />
          </div>
          <div class="invetor-profile-card__description__name">
            <template v-if="hideProfile">
              <z-truncator class="is-size-6" text="非公開" />
            </template>
            <template v-else>
              <z-truncator :text="investor.data.base.name" />
            </template>
          </div>
          <template v-if="!(bulma && bulma.isMobile)">
            <div class="invetor-profile-card__description__message">
              <template v-if="latestSendMailDate">
                <div>最終メッセージ送信日：{{ latestSendMailDate }}</div>
              </template>
              <template v-if="latestRecieveMailDate">
                <div>最終メッセージ受信日：{{ latestRecieveMailDate }}</div>
              </template>
            </div>
          </template>
        </div>
        <!-- <template v-if="hideProfile">
          <z-truncator class="is-size-6" text="非公開" />
        </template>
        <template v-else>
          <z-truncator :text="investor.data.base.name" />
        </template> -->
        <hr class="investor-profile-card__hr" />
        <div class="columns is-mobile is-multiline">
          <div class="column">
            <div class="content is-small">
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__desc">
                  {{ $$ks.getPrefecture(investor.data.base.prefecture).text }}
                  {{ cityInfo }}在住&nbsp;
                  {{
                    $$ks.getEducationType(investor.data.profile.education.type)
                      .text
                  }}卒
                </div>
              </div>
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__title">
                  業種:
                </div>
                <div class="invetor-profile-card__description__desc">
                  <z-truncator
                    :text="
                      $$ks.getCategoryOfBusiness(
                        investor.data.profile.currentOfCareer.categoryOfBusiness
                      ).text
                    "
                  />
                </div>
                <div class="invetor-profile-card__description__title">
                  職種:
                </div>
                <div class="invetor-profile-card__description__desc">
                  <z-truncator
                    :text="
                      $$ks.getTypeOfOccupation(
                        investor.data.profile.currentOfCareer.typeOfOccupation
                      ).text
                    "
                  />
                </div>
              </div>
              <div class="invetor-profile-card__description__tr">
                <div class="invetor-profile-card__description__title">
                  保有不動産数:
                </div>
                <div
                  class="invetor-profile-card__description__desc invetor-profile-card__description__desc__realestate"
                >
                  {{ investor.realEstates.length }}件
                </div>
                <div class="invetor-profile-card__description__title">
                  会員登録日:
                </div>
                <div
                  class="invetor-profile-card__description__desc invetor-profile-card__description__desc__registration-date"
                >
                  {{ $$calender$dateFormat(investor.data.registerDt) }}
                </div>
              </div>
              <template v-if="bulma && bulma.isMobile">
                <div class="invetor-profile-card__description__tr">
                  <template v-if="latestSendMailDate">
                    <div class="invetor-profile-card__description__title">
                      最終メッセージ送信日：
                    </div>
                    <div class="invetor-profile-card__description__desc">
                      {{ latestSendMailDate }}
                    </div>
                  </template>
                  <template v-if="latestRecieveMailDate">
                    <div class="invetor-profile-card__description__title">
                      最終メッセージ受信日：
                    </div>
                    <div class="invetor-profile-card__description__desc">
                      {{ latestRecieveMailDate }}
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <template
            v-if="
              $$ks.investor.application.getMembership(investor.data.membership)
                .value === 20
            "
          >
            <div
              class="column is-narrow invetor-profile-card__description__badge-wrap"
              v-if="!(bulma && bulma.isMobile)"
            >
              <!--
              <div class="content">
                <div class="invetor-profile-card__description__premium-badge">
                  <img src="@/assets/investor/premium-card.png" />
                </div>
              </div>
              -->
            </div>
          </template>
        </div>
        <!-- <div class="content is-small">
          <div class="invetor-profile-card__description__tr">
            <div class="invetor-profile-card__description__desc">
              {{
                $$ks.getPrefecture(investor.data.base.prefecture).text
              }}在住&nbsp;
              {{
                $$ks.getEducationType(investor.data.profile.education.type)
                  .text
              }}卒
            </div>
          </div>
          <div class="invetor-profile-card__description__tr">
            <div class="invetor-profile-card__description__title">
              業種:
            </div>
            <div class="invetor-profile-card__description__desc">
              <z-truncator
                :text="
                  $$ks.getCategoryOfBusiness(
                    investor.data.profile.currentOfCareer.categoryOfBusiness
                  ).text
                "
              />
            </div>
            <div class="invetor-profile-card__description__title">
              職種:
            </div>
            <div class="invetor-profile-card__description__desc">
              <z-truncator
                :text="
                  $$ks.getTypeOfOccupation(
                    investor.data.profile.currentOfCareer.typeOfOccupation
                  ).text
                "
              />
            </div>
          </div>
          <div class="invetor-profile-card__description__tr">
            <div class="invetor-profile-card__description__title">
              保有不動産数:
            </div>
            <div class="invetor-profile-card__description__desc">
              {{ investor.realEstates.length }}件
            </div>
          </div>
          {{ $$ks.investor.application.getMembership(investor.data.membership).value }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "investor-profile-card",
  props: {
    investor: {
      type: [Object, Number],
      require: true,
    },
    hideProfile: {
      type: [Boolean],
      require: true,
    },
  },
  data: function () {
    return {
      messageTypesHideName: [1, 2, 3],
      messageTypes: [
        { value: 1, text: "エクスクルーシブ" },
        { value: 2, text: "オファー" },
      ],
      widthOfMessageBox: 0,
    };
  },
  asyncComputed: {
    async cityInfo() {
      const result = await this.$$city$load(
        this.investor.data.base.prefecture,
        this.investor.data.base.city
      );
      return result.cityName;
    },
  },
  computed: {
    latestSendMailDate() {
      const ls = [];
      if (this.investor && this.investor.messages) {
        for (const mes of this.investor.messages) {
          if (this.me.id === mes.data.realtor) {
            for (const thread of mes.data.threads) {
              if (thread.from === this.me.id) {
                const r = this.$$calender$dateFormat(thread.sendDt);
                ls.push(r);
              }
            }
          }
        }
        if (ls.length === 0) {
          return null;
        } else {
          const res = ls.reduce((dA, dB) => (dA > dB ? dA : dB));
          return res;
        }
      } else {
        return null;
      }
    },
    latestRecieveMailDate() {
      const ls = [];
      if (this.investor && this.investor.messages) {
        for (const mes of this.investor.messages) {
          if (this.me.id === mes.data.realtor) {
            for (const thread of mes.data.threads) {
              if (thread.to === this.me.id) {
                const r = this.$$calender$dateFormat(thread.sendDt);
                ls.push(r);
              }
            }
          }
        }
        if (ls.length === 0) {
          return null;
        } else {
          const res = ls.reduce((dA, dB) => (dA > dB ? dA : dB));
          return res;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.invetor-profile-card {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#37d2ff),
    to(#2a7bcf)
  );
  background: linear-gradient(#37d2ff, #2a7bcf);
  padding: 0;
  &__score {
  }
  &__detail {
    margin: 0;
  }
  &__description {
    text-align: left;
    background: white;
    &__tr {
      display: block;
    }
    &__title {
      display: inline-block;
      padding-right: 0.5rem;
      // width: 4em;
      @include mobile {
        width: 12em;
      }
    }
    &__desc {
      display: inline-block;
      width: 20em;
      color: gray;
      @include mobile {
        width: 100%;
      }
      &__realestate {
        width: 15.8em;
      }
      &__registration-date {
        @include md {
          width: 19em;
        }
      }
    }
    &__badge-wrap {
      padding: 5px 5px 0px;
      @include md {
        padding: 12px 0px;
      }
      @include sp {
        padding: 30px 0px;
      }
    }
    &__premium-badge {
      width: 100px;
      height: 50px;
      @include md {
        width: 80px;
        height: 50px;
      }
      @include sp {
        width: 60px;
        height: 30px;
      }
    }
    &__user {
      display: flex;
    }
    &__image {
      margin-right: 12px;
    }
    &__name {
      margin-top: 4px;
    }
    &__message {
      margin: 0 0 0 auto;
      // font-size: 8px;
      font-size: 12px;
    }
  }
  &__hr hr {
    margin: 0;
  }
}
.investor-profile-card__hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
