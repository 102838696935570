<template>
  <div>
    <router-view></router-view>
    <hr />
    <hr />
    <section class="section">
      <b-tabs :animated="false" size="is-small" v-model="activeTab">
        <b-tab-item label="comp">
          <div class="z-section">
            <div class="z-section__inner">
              {{ total }}
              <RealEstateCard
                v-if="realEstates[0]"
                :realEstate="realEstates[0]"
              />
            </div>
          </div>
          <div class="z-section">
            <div class="z-section__inner">
              {{ oldrealEstates.length }}
              <oldRealEstateList
                :isLoading="isLoading"
                :realEstates="oldrealEstates"
              />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="NEW">
          <div class="z-section">
            <div class="z-section__inner">
              <RealEstateList
                :isLoading="isLoading"
                :realEstates="realEstates"
              />
              <ZPagination
                :total="total"
                :perPage="perPage"
                :page.sync="page"
              />
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
import oldRealEstateList from "@/views/realtor/common/RealEstateList";
import RealEstateList from "@/components/realtor/RealEstateList";
import RealEstateCard from "@/components/realtor/RealEstateCard";

import * as realestate from "@/modules/realtor/realestate";

import moment from "moment";
import "moment-timezone";

export default {
  name: "admin-demo-investor",
  components: {
    oldRealEstateList,
    RealEstateList,
    RealEstateCard,
  },
  data() {
    return {
      filter: realestate.createNewFilterType(),
      activeTab: 0,
      isLoading: true,
      currentPage: 0,
      realEstates: [],
      total: 0,
      page: 1,
      perPage: 50,
      sortType: 1,
      oldrealEstates: [],
    };
  },
  computed: {
    tiku() {
      const dt = new Date(2000, 10, 1);
      const now = new Date();
      return moment(now).subtract(5, "year");
    },
  },
  watch: {
    async page() {
      await this.init();
    },
  },
  methods: {
    async address(dt) {
      const text = await this.$$area$generateTextOfAddress(dt);
      return text;
    },
    countExclusive(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 1).length;
    },
    countNormal(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 2).length;
    },
    //countBuying(realEstate) {
    //  //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
    //  //{ value: 2, text: "オファー", color: "is-info" },
    //  //{ value: 3, text: "売却", color: "is-primary" },
    //  //{ value: 4, text: "問い合せ", color: "" }
    //  return realEstate.messages.filter(r => r.data.type === 3).length
    //},
    countContact(realEstate) {
      //{ value: 1, text: "エクスクルーシブ", color: "is-warning" },
      //{ value: 2, text: "オファー", color: "is-info" },
      //{ value: 3, text: "売却", color: "is-primary" },
      //{ value: 4, text: "問い合せ", color: "" }
      return realEstate.messages.filter((r) => r.data.type === 4).length;
    },
    async countViews(realEstateId) {
      const views = await this.$$realtor$loadViews(realEstateId);
      return views.length;
    },
    async loadRealEstate(r) {
      r.address = await this.address(r.data);
      r.messages = await this.$$message$getMessagesByRealEstateInRealtor(r.id);
      r.viewsCount = await this.countViews(r.id);
      return r;
    },
    async init() {
      try {
        this.isLoading = true;
        // this.page = 1;
        const rt = await realestate.searchByfilter(
          this.filter,
          this.sortType,
          this.page,
          this.perPage
        );
        console.log(rt);

        this.total = rt.total.value;
        this.realEstates = rt.hits;
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
    async oldinit() {
      //const loadingComponent = this.$loading.open();
      try {
        this.isLoading = true;
        let res = await this.$$realtor$loadAllRealEstates();

        const promises = [];
        for (const r of res) {
          promises.push(this.loadRealEstate(r));
        }
        this.oldrealEstates = await Promise.all(promises).then((rs) => rs);
        this.isLoading = false;
        return;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        //loadingComponent.close();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    return this.oldinit();
  },
  mounted() {
    this.oldinit();
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
