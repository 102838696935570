<template>
  <div class="edit edit-profile">
    <h2 class="edit__title">プロフィール</h2>
    <section class="edit__section">
      <div>
        <z-input
          label="プロフィール"
          v-model="user.data.profile.profile"
          type="textarea"
          maxlength="1000"
          :validate="''"
        >
        </z-input>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";

export default {
  name: "realtors-user-profile",
  inject: ["$validator"],
  props: {
    user: {
      type: [Object],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.edit {
  margin: 0 auto 70px;
  max-width: $edit-maxwidth;
  &__section {
    padding: 0 $edit-padding;
    @include sp() {
      padding: 0 $edit-padding-sp;
    }
  }
  &__title {
    position: relative;
    margin-top: $edit-padding;
    margin-bottom: $edit-padding;
    padding: 5px $edit-padding;
    font-size: 24px;
    background-color: #f4f3f5;
    border-bottom: solid 2px #707070;
    color: #838383;
    @include sp {
      padding: 5px $edit-padding-sp;
    }
  }
  &__subtitle {
    padding-top: 0;
    padding-bottom: 14px;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
