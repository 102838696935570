<script>
import { Bar } from "vue-chartjs";
import { /* drop, */ findIndex } from "lodash";

const CHART_BAR_COLOR = [["#0b193f", "#008de6"], "#26a8f1", "#8ad2ff"];

export default {
  name: "ZScoreStandardDeviationChart",
  extends: Bar,
  props: {
    score: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
      //required: true
    },
    compressionXAxes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    chart() {
      if (this.displayData) {
        if (!this.isMounted || !this.displayData.length) {
          return {};
        }

        return {
          labels: [...Array(this.displayData.length)],
          datasets: [1, 2, 3].map((category) => {
            // データ
            const data = this.displayData.map((item) => item.count[category]);
            // 背景色
            const backgroundColor = this.generateBarColor(category);
            // データラベルのオプション
            const datalabels = { display: false };
            return { data, backgroundColor, datalabels };
          }),
        };
      } else {
        return [];
      }
    },
    options() {
      const self = this;
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 65,
            left: this.compressionXAxes ? 16 : 50,
            right: this.compressionXAxes ? 16 : 50,
            bottom: 0,
          },
        },
        events: [],
        hover: {
          intersect: false,
        },
        animation: {
          duration: 1500,
          onComplete: () => {
            // カレントツールチップの表示
            const currentScoreIndex = this.getCurrentScoreGroup();
            if (currentScoreIndex >= 0) {
              self.showCurrentToolTip(2, currentScoreIndex);
            }
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.9,
              stacked: true,
              gridLines: {
                display: false,
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          xAlign: "center",
          yAlign: "bottom",
          xPadding: 10,
          yPadding: 10,
          displayColors: false,
          titleFontSize: 14,
          titleFontStyle: "normal",
          titleMarginBottom: 0,
          footerSpacing: 0,
          bodySpacing: 0,
          caretSize: 8,
          caretPadding: 12,
          callbacks: {
            title: () => "Your Position",
            label: () => null,
          },
        },
      };
    },
    displayData() {
      // SP用のデータ整形
      if (this.compressionXAxes && this.chartData) {
        const averageData = this.chartData.reduce(
          (result, current, index, arr) => {
            // 隣のスコアと結合
            if (index % 2 !== 0) {
              const prev = arr[index - 1];
              // スコア
              const score = { from: prev.score.from, to: current.score.to };
              // 平均値を求める
              const count = {
                1: (prev.count[1] + current.count[1]) / 2,
                2: (prev.count[2] + current.count[2]) / 2,
                3: (prev.count[3] + current.count[3]) / 2,
              };
              result.push({ score, count });
            }
            return result;
          },
          []
        );
        return [...averageData];
      } else {
        // PCの時はそのまま返却
        return this.chartData;
      }
    },
  },
  methods: {
    // グラフの色を取得
    generateBarColor(category) {
      const color = CHART_BAR_COLOR[category - 1];
      if (Array.isArray(color)) {
        const ctx = this.$refs.canvas.getContext("2d");
        if (!ctx) {
          return color[0];
        }

        const gradientStroke = ctx.createLinearGradient(0, 150, 0, 350);
        gradientStroke.addColorStop(0, color[1]);
        gradientStroke.addColorStop(0.6, color[0]);
        return gradientStroke;
      }
      return color;
    },
    // 自分のスコア帯を取得
    getCurrentScoreGroup() {
      const { score } = this.score;
      return findIndex(this.displayData, (data) => {
        const { from, to } = data.score;
        return score >= (from || 0) && score <= (to || 1000);
      });
    },
    // ツールチップの表示
    showCurrentToolTip(datasetIndex, pointIndex) {
      this.$data._chart.tooltip._active = [];

      const activeElements = this.$data._chart.tooltip._active;
      const requestedElem =
        this.$data._chart.getDatasetMeta(datasetIndex).data[pointIndex];

      if (activeElements.find((item) => item._index === requestedElem._index)) {
        return;
      }

      activeElements.push(requestedElem);

      this.$data._chart.tooltip._active = activeElements;
      this.$data._chart.tooltip.update(true);
      this.$data._chart.draw();
    },
  },
  watch: {
    chart: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.renderChart(this.chart, this.options);
      },
    },
    options: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.renderChart(this.chart, this.options);
      },
    },
  },
  mounted() {
    this.isMounted = true;
    this.renderChart(this.chart, this.options);
    this.getCurrentScoreGroup();
  },
};
</script>
