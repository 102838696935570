<template>
  <div class="z-select2">
    <ZField
      :label="label"
      :type="hasError ? 'is-danger' : ''"
      :message="errorMessage"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :hideRequired="hideRequired"
      :helpMessage="helpMessage"
    >
      <b-autocomplete
        :name="internalName"
        :icon="icon"
        :size="size"
        :placeholder="placeholder || label + 'を選択してください'"
        :data-vv-as="label"
        v-model="inputText"
        v-validate="'oneOf:' + optionTexts + '|' + validate"
        :open-on-focus="true"
        :data="filteredData"
        field="text"
        @select="onSelect"
        expanded
      >
      </b-autocomplete>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import InputMixin from "./InputMixin";

export default {
  name: "ZSelect2",
  mixins: [InputMixin],
  props: {
    options: {
      type: [Array],
      default: () => [],
      required: true,
    },
    value: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      inputText: "",
    };
  },
  computed: {
    filteredData() {
      return this.options.filter((option) => {
        // Filter処理
        return (
          option.text
            .toString()
            .toLowerCase()
            .indexOf(this.inputText.toLowerCase()) >= 0
        );
      });
    },
    optionTexts() {
      let texts = [];
      for (let i = 0; i < this.options.length; i++) {
        texts.push(this.options[i].text);
      }
      return texts;
    },
    hasError() {
      return this.errors.has(this.internalName);
    },
    errorMessage() {
      let msg = this.errors.first(this.internalName);
      if (msg) return msg;
      return null;
    },
  },

  methods: {
    onSelect(opt) {
      if (opt) this.internalValue = opt.value;
      else this.internalValue = null;
    },
    setInputTextByValue() {
      let text = "";
      if (this.value) {
        for (let i = 0; i < this.options.length; i++) {
          const opt = this.options[i];
          if (this.value === opt.value) {
            text = opt.text;
            break;
          }
        }
      }
      this.inputText = text;
    },
    setValueByInputText() {
      let val = null;
      if (this.inputText) {
        for (let i = 0; i < this.options.length; i++) {
          const opt = this.options[i];
          if (this.inputText === opt.text) {
            val = opt.value;
            break;
          }
        }
      }
      this.internalValue = val;
    },
  },
  watch: {
    inputText() {
      this.setValueByInputText();
    },
    value() {
      this.setInputTextByValue();
    },
    options() {
      this.setInputTextByValue();
    },
  },
  created() {
    this.$validator.extend("oneOf", {
      validate: function OneOf(value, texts) {
        if (!value) return true;
        if (texts.indexOf(value) > -1) {
          return true;
        }
        return false;
      },
      getMessage: (field) => `一致する${field}がありません`,
    });
  },
  mounted() {
    this.setInputTextByValue();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
