<template>
  <b-button
    class="add-button"
    :label="label"
    @click="$emit('click', $event)"
    icon-left="plus-circle"
  />
</template>

<script>
export default {
  name: "AddButton",
  props: {
    label: String,
    callback: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.add-button {
  width: 100%;
  height: 4em;
  border: dashed 2px #d8d8d8;
  background-color: #f4f4f4;
  color: #777777;
  font-size: 16px;
  @include sp {
    height: 3.4em;
    font-size: 14px;
  }
  /deep/ .icon {
    margin-right: 0 !important;
    font-size: 1.3em;
    vertical-align: middle;
  }
}
</style>
