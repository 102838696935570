<template>
  <div>
    <z-select
      :name="internalName"
      :icon="icon"
      :size="size"
      :label="label"
      :placeholder="placeholder"
      v-model="internalValue"
      :validate="validate"
      :options="options"
      :hideRequired="hideRequired"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
    >
    </z-select>
  </div>
</template>

<script>
import InputMixin from "./InputMixin";

// import axios from "axios-jsonp-pro";
export default {
  name: "ZStationSelect",
  mixins: [InputMixin],
  data: function () {
    return {
      loading: false,
      selected: null,
      stations: [],
    };
  },
  props: {
    value: {
      type: [Number],
      default: null,
    },
    line: {
      type: [Number],
      default: null,
    },
  },
  computed: {
    options() {
      const opts = [];
      for (const c of this.stations) {
        opts.push({
          value: c.station_cd,
          text: c.station_name,
        });
      }
      return opts;
    },
  },
  created: async function () {
    try {
      this.loading = true;
      this.stations = [];
      const val = this.line;
      if (val) {
        this.stations = await this.loadStations(val);
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadStations(code) {
      return this.$$station$loads(code);
    },
  },
  watch: {
    line: async function (val) {
      try {
        this.loading = true;
        this.stations = [];
        this.internalValue = null;
        this.selected = null;
        if (val) {
          const stations = await this.loadStations(val);
          this.stations = stations;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
