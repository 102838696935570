<template>
  <div>
    <b-table
      :data="investorTransactions"
      checkable
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort="data.registerDt"
      default-sort-direction="desc"
      custom-row-key="id"
      :striped="true"
      :hoverable="true"
      :mobile-cards="true"
    >
      <template slot-scope="props">
        <b-table-column field="investor.data.base.name" label="申告者" sortable>
          {{ props.row.investor.data.base.name }}<br />
          {{ props.row.investor.data.base.email }}
        </b-table-column>
        <b-table-column field="data.tradeType" label="購入/売却" sortable>
          {{ $$ks.transaction.getTradeType(props.row.data.tradeType).text }}
        </b-table-column>
        <b-table-column field="company.data.name" label="不動産会社" sortable>
          {{ props.row.company.data.name }}
        </b-table-column>
        <b-table-column field="staff.data.base.kana" label="担当者名" sortable>
          {{ props.row.staff.data.base.name }}
        </b-table-column>
        <b-table-column field="data.transactionType" label="取引種別" sortable>
          {{
            $$ks.realtor.realEstate.getTransactionType(
              props.row.data.transactionType
            ).text
          }}
        </b-table-column>
        <b-table-column field="data.contractDate" label="売買契約日" sortable>
          {{ $$calender$dateFormat(props.row.data.contractDate) }}
        </b-table-column>
        <b-table-column field="data.settlementDate" label="決済実行日" sortable>
          {{ $$calender$dateFormat(props.row.data.settlementDate) }}
        </b-table-column>
        <b-table-column field="data.price" label="取引金額" sortable>
          {{ $$price$priceFormat(props.row.data.price) }}
        </b-table-column>
        <b-table-column field="data.fee" label="仲介手数料" sortable>
          {{ $$price$priceFormat(props.row.data.fee) }}
        </b-table-column>
        <b-table-column field="data.registerDt" label="登録日" sortable>
          {{ $$calender$dateFormat(props.row.data.registerDt) }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "investors-transaction-list",
  props: {
    investorTransactions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
