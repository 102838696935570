<template>
  <div class="z-yearmonth">
    <ZField
      :label="label"
      :type="errors.has(internalName) ? 'is-danger' : ''"
      :message="errors.first(internalName)"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :hideRequired="hideRequired"
      :additionalMessage="additionalMessage"
      :showBox="showBox"
    >
      <div class="z-yearmonth__inputs">
        <b-select
          class="z-yearmonth__inputs__year z-select--unit"
          :name="internalName"
          v-model="internalValue.year"
          v-validate="validate"
          placeholder="例) 2000"
          @focus="onFocusYear()"
          @input="onChange"
        >
          <template v-for="o in years">
            <option :value="o.value" :key="o.value">
              {{ o.text }}
            </option>
          </template>
        </b-select>

        <b-select
          class="z-yearmonth__inputs__month z-select--unit"
          :name="internalName"
          v-model="internalValue.month"
          v-validate="validate"
          placeholder="例) 04"
        >
          <template v-for="o in months">
            <option :value="o.value" :key="o.value">
              {{ o.text }}
            </option>
          </template>
        </b-select>
      </div>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZYearMonthSelect",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  data() {
    return {
      years: kubuns.years,
      months: kubuns.months,
    };
  },
  // created() {
  //   if (this.value.year === 0) this.value.year = null;
  //   if (this.value.month === 0) this.value.month = null;
  // },
  methods: {
    onFocusYear() {
      console.log(
        "this.$$userAgent$isIOS() = " + this.$$userAgent$getIsMobile()
      );
      if (!this.internalValue.year && !this.$$userAgent$getIsMobile()) {
        console.log("カレント設定します");
        this.internalValue.year = new Date().getFullYear();
      }
    },
    onChange(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.z-yearmonth {
  &__inputs {
    &__year,
    &__month {
      display: inline-block;
      /deep/ .select {
        &:before {
          position: absolute;
          font-size: 12px;
          top: 8px;
          right: 26px;
        }
      }
    }
    &__year {
      /deep/ .select {
        &:before {
          content: "年";
        }
        select {
          min-width: 200px;
          @include sp {
            min-width: 120px;
          }
        }
      }
    }
    &__month {
      margin-left: 22px;
      /deep/ .select {
        &:before {
          content: "月";
        }
        select {
          min-width: 200px;
          @include sp {
            min-width: 90px;
          }
        }
      }
    }
  }
}
</style>
