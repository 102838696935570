<script>
// import firebase from "@/plugins/firebase";
// import { cloneDeep } from "lodash";
export default {
  name: "Mixins-Price",
  methods: {
    $$price$priceFormat: function (price) {
      return "¥" + String(price).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    },
    $$price$priceJPFormat: function (price, suffix = "円") {
      if (price) {
        var num = String(price);
        // ２．数字の桁を配列で定義
        var digit = ["", "万", "億", "兆", "京"];
        var result = "";
        // ３．string型に変換して四桁ずつ区切る
        var nums = num
          .replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,")
          .split(",")
          .reverse();
        // ４．先頭に0がある場合は削除して、数字の単位を付け加える
        for (var i = 0; i < nums.length; i++) {
          if (!nums[i].match(/^[0]+$/)) {
            // ５．頭の0を削除し、先頭が0以外かつ4桁のときに数値に区切りを入れる
            nums[i] = nums[i].replace(/^[0]+/g, "");
            if (nums[i].length == 4) {
              nums[i] = nums[i].replace(/(\d)(?=(\d\d\d)+)/g, "$1,");
            }
            result = nums[i] + digit[i] + result;
          }
        }
        // ６．カンマ区切り、数字桁入りの文字列を表示
        return result + suffix;
      }
      return "0万" + suffix;
    },

    //千円以下を切り落として出力
    $$price$roundPriceJPFormat: function (price, suffix = "円") {
      if (price) {
        var num = String(price);
        // ２．数字の桁を配列で定義
        var digit = ["", "万", "億", "兆", "京"];
        var result = "";
        // ３．string型に変換して四桁ずつ区切る
        var nums = num
          .replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,")
          .split(",")
          .reverse();
        // ４．先頭に0がある場合は削除して、数字の単位を付け加える
        for (var i = 0; i < nums.length; i++) {
          if (!nums[i].match(/^[0]+$/)) {
            // ５．頭の0を削除し、先頭が0以外かつ4桁のときに数値に区切りを入れる
            nums[i] = nums[i].replace(/^[0]+/g, "");
            if (nums.length >= 2 && i == 0) {
              nums[i] = nums[i].replace(/(\d)+/g, "");
            }
            if (nums[i].length == 4 && i !== 0) {
              nums[i] = nums[i].replace(/(\d)(?=(\d\d\d)+)/g, "$1,");
            }
            result = nums[i] + digit[i] + result;
          }
        }
        // ６．カンマ区切り、数字桁入りの文字列を表示
        return result + suffix;
      }
      return "0万" + suffix;
    },
  },
};
</script>
