<template>
  <div class="buyingneeds" v-if="!isLoading">
    <ZHeader title="Sign Up" sub="サインアップ" />
    <section class="edit">
      <p class="edit__explanation">
        最後に、買いニーズ情報（提案を受けたい物件の希望条件）をご登録してください。
        <br />
        <span class="edit__explanation_red">
          ※
          買いニーズ情報を登録すると、より多くの提案を受けることができ、より希望に沿った物件が提案されます。
        </span>
      </p>
      <PortfolioSteps :activeStep="4" />

      <h2 class="edit__title">買いニーズ情報入力</h2>

      <FormBuyingNeeds v-model="eUser" />

      <div class="edit__foot">
        <b-button
          id="investor-register-complete-submit"
          :disabled="$$validator$invalid"
          @click="onSubmit()"
          type="is-primary"
          rounded
          class="z-button is-wide"
          >始める</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import FormBuyingNeeds from "../common/FormBuyingNeeds";
import PortfolioSteps from "./parts/PortfolioSteps";
import CreateTemplateMessageMixin from "@/views/realtor/common/CreateTemplateMessageMixin";
import * as MessageTemplateModule from "@/modules/realtor/MessageTemplate";

const sleep = function (msec) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, msec);
  });
};

export default {
  name: "Register-Buyingneeds",
  mixins: [CreateTemplateMessageMixin],
  data: function () {
    return {
      isLoading: true,
      eUser: null,
    };
  },
  components: {
    PortfolioSteps: PortfolioSteps,
    FormBuyingNeeds: FormBuyingNeeds,
  },
  methods: {
    onSubmit: function () {
      this.save();
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$investor$save(this.auth.uid, this.eUser);

        await this.$$investor$applicateUserByUID(
          this.auth.uid,
          this.me.id,
          null // analyticsOrderNum
        );

        const uid = this.auth.uid;
        const realtorUID = process.env.VUE_APP_REGISTER_REALTOR_UID;
        const templateId = process.env.VUE_APP_REGISTER_TEMPLATE_ID;
        const realestateIds = eval(process.env.VUE_APP_REGISTER_REALESTATE_IDS);
        const score = this.$$user$score.score;

        const message = this.$$message$getNewMessage();
        const thread = this.$$message$getNewThread();
        const patternMessage = await MessageTemplateModule.load(
          realtorUID,
          templateId
        );

        message.investor = await this.$$investor$loadUserWithMessage(uid);
        message.data.title = this.$$createTemplateMessage$replaceName(
          this.$$createTemplateMessage$replaceScore(
            patternMessage.data.label,
            score
          ),
          this.me.user.data.base.name
        );

        message.data.type = 1;
        message.data.suggestions = patternMessage.data.suggestions;
        thread.contents = this.$$createTemplateMessage$replaceName(
          this.$$createTemplateMessage$replaceScore(
            patternMessage.data.message,
            score
          ),
          this.me.user.data.base.name
        );

        thread.docs = [];

        // console.log(message)
        // console.log(thread)
        const newMessage = await this.$$message$createMessage(
          realtorUID,
          realtorUID,
          uid,
          message,
          thread,
          realestateIds,
          []
        );
        await this.$$message$sendMailMessageToInvestor(newMessage.id);

        await sleep(5 * 1000);
        this.$$toast$openSuccess();

        this.$router.push({ name: "investor-home" });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
      } finally {
        loadingComponent.close();
      }
    },
  },
  async mounted() {
    this.eUser = await this.$$investor$loadUser(this.me.user.id);
    this.isLoading = false;
    return;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.edit__explanation_red {
  color: $danger;
}

.buyingneeds {
  .edit__inner {
    .edit__premium {
      position: static;
      margin-bottom: 10px;
      text-align: right;
    }
  }
}
</style>
