<template>
  <div>
    <ZField
      :label="label"
      :type="hasError ? 'is-danger' : ''"
      :message="errorMessage"
      :isRequired="isRequired"
      :isPrivate="isPrivate"
      :helpMessage="helpMessage"
      :additionalMessage="additionalMessage"
      :hideRequired="hideRequired"
      :showBox="showBox"
      grouped
    >
      <b-select
        class="is-unit is-unit--yen"
        :name="internalName + '-from'"
        v-model="internalValue.from"
        v-validate="validate"
        placeholder="下限なし"
      >
        <template v-for="o in from">
          <option :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </template>
      </b-select>

      <span>〜</span>

      <b-select
        class="is-unit is-unit--yen"
        :name="internalName + '-to'"
        v-model="internalValue.to"
        v-validate="validate"
        placeholder="上限なし"
      >
        <template v-for="o in to">
          <option :value="o.value" :key="o.value">
            {{ o.text }}
          </option>
        </template>
      </b-select>
    </ZField>
  </div>
</template>

<script>
// @ is an alias to /src
import InputMixin from "./InputMixin";
import kubuns from "@/kubuns/kubuns";

export default {
  name: "ZIncomeFromToSelect",
  mixins: [InputMixin],
  props: {
    value: {
      type: [Object],
      default: null,
    },
  },
  data() {
    return {
      from: kubuns.filters.income.from,
      to: kubuns.filters.income.to,
    };
  },
  computed: {
    hasError() {
      if (
        this.errors.has(this.internalName + "-from") ||
        this.errors.has(this.internalName + "-to")
      )
        return true;

      // 複合チェック
      if (this.isCompareError) return true;

      return false;
    },
    errorMessage() {
      let fromError = "";
      fromError = this.errors.first(this.internalName + "-from");
      if (fromError) return fromError;

      fromError = this.errors.first(this.internalName + "-to");
      if (fromError) fromError;

      // 複合チェック
      if (this.isCompareError)
        return "下限より上限が大きくなるように入力してください。";

      return fromError;
    },
    isCompareError() {
      if (this.internalValue.from && this.internalValue.to) {
        if (this.internalValue.from >= this.internalValue.to) return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
