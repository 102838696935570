<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <div
        class="navbar-burger burger"
        data-target="navMenubd-example"
        @click="showNav = !showNav"
        :class="{ 'is-active': showNav }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div
      id="navMenubd-example"
      class="navbar-menu"
      :class="{ 'is-active': showNav }"
    >
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> 投資家 </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'admin-setting-investors' }"
              class="navbar-item"
            >
              投資家一覧
            </router-link>
            <router-link
              :to="{ name: 'admin-setting-investors-transaction' }"
              class="navbar-item"
            >
              売買情報一覧
            </router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> 専門家 </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'admin-setting-realtors' }"
              class="navbar-item"
            >
              不動産屋一覧
            </router-link>
            <router-link
              :to="{ name: 'admin-setting-realtors-users' }"
              class="navbar-item"
            >
              コンサルタント一覧
            </router-link>
            <router-link
              :to="{ name: 'admin-setting-realtors-application' }"
              class="navbar-item"
            >
              申し込み一覧
            </router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> 運用者 </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'admin-setting-admin-index' }"
              class="navbar-item"
            >
              運用者管理
            </router-link>
            <router-link
              :to="{ name: 'admin-setting-admin-transaction' }"
              class="navbar-item"
            >
              成約一覧
            </router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> メッセージ </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'admin-setting-messages-index' }"
              class="navbar-item"
            >
              メッセージ一覧
            </router-link>
            <router-link
              :to="{ name: 'admin-setting-messages-template' }"
              class="navbar-item"
            >
              メッセージテンプレート
            </router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> お知らせ </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'admin-setting-informations-index' }"
              class="navbar-item"
            >
              お知らせ一覧
            </router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> その他 </a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" :to="{ name: '/admin/demo/base' }">
              base
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: 'admin-demo-scoreuser' }"
            >
              scoreUser
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/score' }"
            >
              score
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/realestate' }"
            >
              realestate
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/charts' }"
            >
              charts
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/parts' }"
            >
              県と駅のパーツ
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/kubuns' }"
            >
              区分
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/summary-parts' }"
            >
              サマリー関連のパーツ
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: '/admin/demo/chart-parts' }"
            >
              チャート関連のパーツ
            </router-link>
            <router-link class="navbar-item" :to="{ name: '/admin/demo/bank' }">
              Bank
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ name: 'admin-demo-userlist' }"
            >
              ユーザセレクト
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "admin-menu",
  data: function () {
    return {
      showNav: false,
      isActive: true,
    };
  },
  // methods: {
  //   navBarBurger: function() {
  //     document.addEventListener("DOMContentLoaded", () => {
  //       // Get all "navbar-burger" elements
  //       const $navbarBurgers = Array.prototype.slice.call(
  //         document.querySelectorAll(".navbar-burger"),
  //         0
  //       );

  //       // Check if there are any navbar burgers
  //       if ($navbarBurgers.length > 0) {
  //         // Add a click event on each of them
  //         $navbarBurgers.forEach(el => {
  //           el.addEventListener("click", () => {
  //             // Get the target from the "data-target" attribute
  //             const target = el.dataset.target;
  //             const $target = document.getElementById(target);
  //             // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
  //             el.classList.toggle("is-active");
  //             $target.classList.toggle("is-active");
  //           });
  //         });
  //       }
  //     });
  //   }
  // }
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.navbar-item.is-mega {
  position: static;
}
.is-mega-menu-title {
  margin-bottom: 0;
  padding: 0.375rem 1rem;
}
</style>
