<template>
  <div>
    <router-view></router-view>
    <section class="section">
      <div class="columns">
        <div class="column is-6">
          <template v-for="(bank, idx) in banks">
            <div :key="idx">
              <figure class="image is-32x32">
                <img v-lazy="'/bank/' + bank.value" />
              </figure>
              {{ bank }}
              <hr />
            </div>
          </template>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-6">
          <z-bank-select label="銀行" v-model="bankCd"> </z-bank-select>
        </div>

        <div class="column is-6">
          {{ bankInfo }}
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <z-branch-select label="支店" :bank="bankCd" v-model="branchCd">
          </z-branch-select>
        </div>
        <div class="column is-6">
          {{ branchInfo }}
        </div>
      </div>
      <hr />

      {{ textOfBank }}
      <div class="columns">
        <div class="column is-6">
          <z-bank-select label="銀行" v-model="bank2"> </z-bank-select>
        </div>

        <div class="column is-6">
          {{ bank2Info }}
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <z-branch-select label="支店" :bank="bank2" v-model="branch2">
          </z-branch-select>
        </div>
        <div class="column is-6">
          {{ branch2Info }}
        </div>
      </div>
      <hr />
      古い
      <z-select label="銀行" v-model="bank" :options="banks"> </z-select>
      {{ bankInfo }}
      <hr />
      <z-select label="支店" v-model="branch" :options="branches"> </z-select>

      {{ branchInfo }}
    </section>
  </div>
</template>

<script>
// import CitySelect from "@/components/parts/CitySelect";
// import bankInfo from "@/views/investor/markettrend/parts/BankRankingInfo";

export default {
  name: "admin-bank-demo",
  data: function () {
    return {
      bankCd: null,
      branchCd: null,
      bank2: "0001",
      branch2: "0001:013:1",
    };
  },
  asyncComputed: {
    async textOfBank() {
      return this.$$bank$generateTextOfBank(this.bankCd, this.branchCd);
    },
    async bank2Info() {
      if (this.bank2) {
        const ls = await this.$$bank$getInfOfBank(this.bank2);
        return ls;
      } else {
        return null;
      }
    },
    async branch2Info() {
      if (this.branch2) {
        const ls = await this.$$bank$getInfOfBranch(this.branch2);
        return ls;
      } else {
        return null;
      }
    },
    async bankInfo() {
      if (this.bankCd) {
        const ls = await this.$$bank$getInfOfBank(this.bankCd);
        return ls;
      } else {
        return null;
      }
    },
    async branchInfo() {
      if (this.branchCd) {
        const ls = await this.$$bank$getInfOfBranch(this.branchCd);
        return ls;
      } else {
        return null;
      }
    },
    async banks() {
      return await this.$$bank$getBanks();
    },
    async branches() {
      return await this.$$bank$getBranches(this.bankCd);
    },
  },
};
</script>
