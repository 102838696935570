<template>
  <div class="menu">
    <nav class="menu__nav">
      <div class="menu__nav__head">
        <div class="menu__nav__brand">
          <div class="menu__nav__brand__logo" @click="onLogoClick">
            <img class="logo" src="@/assets/logo-mini.png" alt="StockFormer" />
            <img
              class="title"
              src="@/assets/logoTitle.png"
              alt="StockFormer Powered By ZIRITZ"
            />
          </div>
        </div>
        <div
          class="menu__nav__notification z-notification"
          v-if="bulma.isMobile"
        >
          <slot name="rightIcons" />
        </div>
        <div class="menu__nav__burger" @click="toggleNav" v-if="!hideMenu">
          <div class="burger" :class="{ 'is-active': isShowNav }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="menu__nav__tabs" :class="{ 'is-active': isShowNav }">
        <ul>
          <li
            :class="{
              'is-active': $route.path.indexOf('/investor/zero') > -1,
            }"
            @click="closeMenu"
          >
            <router-link class="is-zero" :to="{ name: 'investor-zero-index' }">
              <ZZeroIcon
                :active="$route.path.indexOf('/investor/zero') > -1"
                class="icon-zero"
                width="40"
                height="40"
                color="#8ce5ff"
              />
              <p>オススメ</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active':
                $route.path.indexOf('/investor/home') > -1 ||
                $route.path === '/investor/' ||
                $route.path === '/investor',
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'investor-home' }">
              <ZIcon name="dashboard" width="40" height="40" color="#fff" />
              <p>Dashboard</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': $route.path.indexOf('/investor/portfolio') > -1,
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'investor-portfolio' }">
              <ZIcon name="portfolio" width="40" height="40" color="#fff" />
              <p>Portfolio</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': $route.path.indexOf('/investor/collection') > -1,
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'investor-collection' }">
              <ZIcon name="collection" width="40" height="40" color="#fff" />
              <p>Collection</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-active': $route.path.indexOf('/investor/messages') > -1,
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'investor-message' }">
              <ZIcon name="offer" width="40" height="40" color="#fff" />
              <p>Message</p>
            </router-link>
          </li>
          <li
            :class="{
              'is-lock': !$$user$isPremium,
              'is-active': $route.path.indexOf('/investor/markettrend') > -1,
            }"
            @click="closeMenu"
          >
            <router-link
              :to="{
                name: $$user$isPremium
                  ? 'investor-markettrend'
                  : 'investor-premium',
              }"
            >
              <ZIcon name="transaction" width="40" height="40" color="#fff" />
              <b-icon class="lock__icon" icon="lock" size="is-small" />
              <p>Market</p>
            </router-link>
            <b-tooltip
              class="lock__tooltip"
              label="プレミアムプラン限定機能"
              position="is-right"
              type="is-dark"
              :active="true"
              :always="true"
            />
          </li>
          <li
            :class="{
              'is-active':
                $route.path.indexOf('/investor/search/realtor/realestates') >
                -1,
            }"
            @click="closeMenu"
          >
            <router-link :to="{ name: 'investor-search-realestates' }">
              <ZIcon name="property" width="40" height="40" color="#fff" />
              <p>Property</p>
            </router-link>
          </li>
          <!-- <li :class="{ 'is-active': $route.path === '' }" @click="closeMenu">
            <router-link :to="{ name: '' }">
              <ZIcon name="specialist" width="40" height="40" color="#fff" />
              <p>Specialist</p>
            </router-link>
          </li> -->
        </ul>
        <!-- <div class="bottomMenu" ref="bottomMenu">
          <ul>
            <li>
              <a @click="isShowShareMenu = !isShowShareMenu">
                <ZIcon name="logout" width="24" height="24" color="#fff" />
                <p>Share</p>
              </a>
            </li>
          </ul>
          <div
            class="bottomMenu__popup"
            :class="{ 'is-open': isShowShareMenu || isShowNav }"
          >
            <p class="bottomMenu__popup__title">スコアをシェアする</p>
            <div class="bottomMenu__popup__linkWrap" @click="closeMenu">
              <a
                class="bottomMenu__popup__link bottomMenu__popup__link--facebook"
                target="_blank"
                title="Facebookでスコアをシェア"
                :href="shareFacebookLink"
              >
                <b-icon icon="facebook" /><span class="text">Facebook</span>
              </a>
              <a
                class="bottomMenu__popup__link bottomMenu__popup__link--twitter"
                rel="nofollow"
                target="_blank"
                title="Twitterでスコアをシェア"
                :href="shareTwitterLink"
              >
                <b-icon icon="twitter" /><span class="text">Twitter</span>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </nav>
    <div
      class="menu__background"
      :class="{ 'is-active': isShowNav || isShowMenu }"
      @click="closeMenu"
    ></div>
  </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  name: "Menu",
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isLoading: true,
      isShowNav: false,
      isAdmin: false,
      isInvestor: false,
      isRealtor: false,
      isShowMenu: false,
      isShowShareMenu: false,
    };
  },
  computed: {
    shareTwitterLink() {
      const shareText = `あなたの投資家スコアは${this.$$user$score.score}点。%0aStockFormerは、あなたのスコアに応じた不動産投資環境を提供する今までにない全く新しい不動産投資の情報プラットフォームです。https://stockformer.com`;
      const shareScoreUrl = `https://stockformer.com/share/score_${this.$$user$score.score}.html`;
      return `http://twitter.com/intent/tweet?text=${shareText}&url=${shareScoreUrl}`;
    },
    shareFacebookLink() {
      const shareText = `あなたの投資家スコアは${this.$$user$score.score}点。%0aStockFormerは、あなたのスコアに応じた不動産投資環境を提供する今までにない全く新しい不動産投資の情報プラットフォームです。https://stockformer.com`;
      const shareScoreUrl = `https://stockformer.com/share/score_${this.$$user$score.score}.html`;
      return `http://www.facebook.com/share.php?u=${shareScoreUrl}&t=${shareText}`;
    },
  },
  methods: {
    onLogoClick() {
      // 初回登録時
      if (this.$route.path.indexOf("/investor/register") > -1) {
        return;
      }
      this.$router.push({ name: "investor" });
    },
    closeMenu() {
      this.isShowNav = false;
      this.isShowShareMenu = false;
      document.documentElement.classList.remove("iosBugFixCaret");
      clearAllBodyScrollLocks();
    },
    signOut: async function () {
      return await this.$$user$signOut();
    },
    toggleNav() {
      const spMenu = document.querySelector(".menu__nav__tabs");
      //デバイス判定
      const ua = window.navigator.userAgent.toLowerCase();

      this.isShowNav = !this.isShowNav;

      if (this.isShowNav === true) {
        document.documentElement.classList.add("iosBugFixCaret");
        //iOSの場合のみbody-scroll-lockを使用
        if (ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) {
          disableBodyScroll(spMenu);
        }
        return;
      }
      if (this.isShowNav === false) {
        document.documentElement.classList.remove("iosBugFixCaret");
        clearAllBodyScrollLocks();
        return;
      }
    },
    handleClick(event) {
      if (
        this.isShowShareMenu &&
        !this.$refs.bottomMenu.contains(event.target)
      ) {
        this.closeMenu();
      }
    },
  },
  watch: {
    "bulma.isMobile": function () {
      this.closeMenu();
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClick);
    clearAllBodyScrollLocks();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.menu {
  //width: $menu-width;
  width: $menu-width-md;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: linear-gradient(#202c66, #242630);
  color: #fff;
  pointer-events: none;
  z-index: $zindex-menu;
  // @include md {
  //   width: $menu-width-md;
  // }
  @include sp {
    width: 100%;
    background: none;
  }
}
.menu__background {
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  position: fixed;
  background-color: #000;
  pointer-events: none;
  z-index: -1;
  @include sp {
    display: block;
    opacity: 0;
    transition: opacity 0.2s ease;
    &.is-active {
      opacity: 0.3;
      pointer-events: all;
    }
  }
}
.menu__nav {
  width: 100%;
  height: 100vh;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  @include sp {
    height: 64px;
    display: block;
  }
}
.menu__nav__head {
  background: linear-gradient(#202c66, #242630);
  @include sp {
    height: $menu-height-sp;
    padding-left: 16px;
    display: flex;
  }
}
.menu__nav__brand {
  @include sp {
    height: 100%;
    flex: 1;
  }
}
.menu__nav__tabs {
  .icon-zero {
    transform: scale(0.9);
  }
}
.menu__nav__brand__logo {
  position: relative;
  display: block;
  white-space: nowrap;
  &:before {
    padding-top: 100%;
    display: block;
    content: "";
  }
  @include sp {
    height: 100%;
    padding-top: 17px;
    display: inline-block;
    &:before {
      content: none;
    }
  }
  .logo {
    //width: 48px;
    width: 32px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // @include md {
    //   width: 32px;
    // }
    @include sp {
      width: 32px;
      top: auto;
      left: auto;
      display: inline-block;
      position: relative;
      transform: none;
      vertical-align: middle;
    }
  }
  .title {
    width: 100px;
    height: auto;
    margin-left: 16px;
    display: none;
    @include sp {
      display: inline-block;
      vertical-align: middle;
    }
    @media screen and (max-width: 413px) {
      display: none;
    }
  }
}
.menu__nav__burger {
  width: 42px;
  display: none;
  cursor: pointer;
  @include sp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.menu__nav__tabs {
  height: 100%;
  // margin-top: 42px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  // @include md {
  //   margin-top: 32px;
  // }
  @include sp {
    height: calc(100vh - 64px);
    position: relative;
    margin-top: 0;
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #242630;
    &.is-active {
      opacity: 1;
      pointer-events: all;
    }
  }
  .bottomMenu {
    height: auto;
    max-height: none;
    margin-top: auto;
    padding-top: 100px;
    position: relative;
    justify-content: flex-end;
    @include sp {
      margin-top: 0;
      padding-top: 0;
    }
    ul {
      @include sp {
        display: none;
      }
    }
    &__popup {
      width: 180px;
      left: 66px;
      bottom: 20px;
      padding: 16px 8px 16px 16px;
      position: absolute;
      background-color: #000;
      color: #fff;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.16);
      // transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease;
      &.is-open {
        opacity: 1;
        pointer-events: all;
      }
      @include sp {
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 16px 8px 128px 8px;
        position: relative;
        box-shadow: none;
        background: #242630;
        opacity: 1;
        transition: none;
      }
      &:after {
        width: 20px;
        height: 20px;
        left: 0;
        bottom: 20px;
        margin: auto;
        position: absolute;
        transform: rotate(45deg) translateX(-50%);
        background-color: #000;
        content: "";
        @include sp {
          content: none;
        }
      }
      &__title {
        margin-bottom: 8px;
        @include sp {
          font-size: 12px;
          text-align: center;
        }
      }
      &__linkWrap {
        @include sp {
          display: flex;
          justify-content: center;
          opacity: 0.8;
        }
      }
      &__link {
        padding: 10px 0;
        display: flex;
        align-items: conter;
        color: #fff;
        transition: opacity 0.1s ease;
        &:hover {
          opacity: 0.8;
        }
        .text {
          padding-left: 12px;
          display: inline-block;
          @include sp {
            padding-top: 6px;
            padding-left: 0;
            font-size: 10px;
          }
        }
        @include sp {
          width: 70px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  ul {
    max-height: 580px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include sp {
      max-height: none;
      height: auto;
      display: block;
      background: linear-gradient(#202c66, #242630);
    }
  }
  li {
    position: relative;
    // padding: 21px 0;
    @include sp {
      padding: 16px 0;
    }
    .lock__icon {
      display: none;
    }
    .lock__tooltip {
      display: none;
    }
    &.is-lock {
      .lock__tooltip {
        top: 40px;
        left: 0;
        // right: 30px;
        right: 10px;
        margin: auto;
        display: block;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease;
        // @include md {
        //   right: 10px;
        // }
        @include sp {
          display: none;
        }
      }
      .lock__icon {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        position: absolute;
        transform: translateX(100%);
      }
    }
    &:hover {
      .lock__tooltip {
        opacity: 1;
      }
    }
    // @include md {
    //   padding: 16px 0;
    // }
    @include sp {
      padding: 8px 0;
    }
    /deep/ .z-icon {
      // width: 40px;
      width: 32px;
      // @include md {
      //   width: 32px;
      // }
      @include sp {
        width: 28px;
      }
    }
    p {
      // font-size: 13px;
      font-size: 10px;
      opacity: 0;
      transition: opacity 0.2s ease;
      user-select: none;
      // @include md {
      //   font-size: 10px;
      // }
      @include sp {
        opacity: 1;
      }
    }
    a {
      width: 100%;
      height: 70px;
      display: block;
      position: relative;
      text-align: center;
      font-weight: bold;
      color: #fff;
      opacity: 0.6;
      transition: opacity 0.2s ease;
      &.is-zero {
        opacity: 1;
      }
      &:hover {
        p {
          opacity: 1;
        }
      }
      @include sp {
        height: auto;
        padding: 0 11px 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &.is-active {
      p {
        opacity: 1;
      }
      a {
        opacity: 1;
        &:after {
          width: 4px;
          height: 40px;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: #fff;
          content: "";
          @include sp {
            content: none;
          }
        }
        @include sp {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
.burger {
  width: 24px;
  height: 18px;
  position: relative;
  span {
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    &:nth-of-type(1) {
      bottom: auto;
    }
    &:nth-of-type(3) {
      top: auto;
    }
  }
  &.is-active {
    span:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
  }
}
</style>
