<template>
  <div>
    <router-view></router-view>
    <b-tabs :animated="false" size="is-small" v-model="activeTab">
      <b-tab-item label="メッセージ一覧">
        <div class="container message-warp" v-if="!isLoading">
          <div class="message-warp__list">
            <div class="content">
              <h1>メッセージ一覧</h1>
            </div>
            <b-checkbox v-model="withReply">メッセージあり</b-checkbox>
            <button class="button primary" @click="onClickSearch">検索</button>
            <hr />
            <p>{{ total }}件</p>
            <!--
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              default-sort="data.createdDt"
              default-sort-direction="desc"
              paginated
              per-page="10"
              detailed
            -->
            <b-table
              :data="messages"
              :hoverable="true"
              :narrowed="true"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              detail-key="id"
              custom-row-key="id"
              :show-detail-icon="true"
            >
              <template slot-scope="props">
                <b-table-column label="詳細" width="20">
                  <div class="detail-button">
                    <router-link
                      :to="{
                        name: 'admin-setting-messages-detail',
                        params: { id: props.row.id },
                      }"
                      class="button is-small"
                      >詳細</router-link
                    >
                  </div>
                </b-table-column>
                <b-table-column field="id" width="8">
                  <div class="content message-icon">
                    <template
                      v-if="
                        notification(props.row.investor.user.id, props.row) ===
                        0
                      "
                    >
                      <span class="icon">
                        <i class="mdi mdi-redo-variant"></i>
                      </span>
                    </template>
                    <template
                      v-else-if="
                        notification(props.row.investor.user.id, props.row) ===
                        1
                      "
                    >
                      <span class="icon has-text-unread">
                        <i class="mdi mdi-circle"></i>
                      </span>
                    </template>
                    <template
                      v-else-if="
                        notification(props.row.investor.user.id, props.row) ===
                        2
                      "
                    >
                      <span class="icon">
                        <i class="mdi mdi-minus"></i>
                      </span>
                    </template>
                  </div>
                </b-table-column>
                <b-table-column
                  field="investor.user.data.base.name"
                  label="投資家"
                  width="150"
                >
                  <div class="profile">
                    <div class="image is-32x32 profile__image">
                      <z-profile-thumbnail
                        v-model="props.row.investor.user.data.profile.image"
                      />
                    </div>
                    <div class="content">
                      <p class="is-size-7">
                        <z-truncator
                          :text="props.row.investor.user.data.base.name"
                        />
                        <template v-if="resendByInvestor(props.row)">
                          <span class="is-size-7 has-text-danger has-text-bold"
                            >メッセージあり</span
                          >
                        </template>
                      </p>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="id" width="8" label="">
                  <div class="content message-icon">
                    <template
                      v-if="
                        notification(props.row.realtor.user.id, props.row) === 0
                      "
                    >
                      <span class="icon">
                        <i class="mdi mdi-redo-variant"></i>
                      </span>
                    </template>
                    <template
                      v-else-if="
                        notification(props.row.realtor.user.id, props.row) === 1
                      "
                    >
                      <span class="icon has-text-unread">
                        <i class="mdi mdi-circle"></i>
                      </span>
                    </template>
                    <template
                      v-else-if="
                        notification(props.row.realtor.user.id, props.row) === 2
                      "
                    >
                      <span class="icon">
                        <i class="mdi mdi-minus"></i>
                      </span>
                    </template>
                  </div>
                </b-table-column>
                <b-table-column
                  field="realtor.user.data.base.name"
                  label="専門家"
                  width="150"
                >
                  <div class="profile">
                    <div class="image is-32x32 profile__image">
                      <z-profile-thumbnail
                        v-model="props.row.realtor.user.data.profile.image"
                      />
                    </div>
                    <div class="content">
                      <p class="is-size-7">
                        <z-truncator
                          :text="props.row.realtor.user.data.base.name"
                        />
                        <z-truncator
                          :text="props.row.realtor.company.data.name"
                        />
                      </p>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column label="メッセージ">
                  <div class="content message-content">
                    <template
                      v-if="
                        $$ks.message.getType(props.row.data.type).value === 1
                      "
                    >
                      <div class="message-content__exclusive-label">
                        <img src="@/assets/message/label-exclusive-S.png" />
                      </div>
                      <z-truncator
                        class="is-size-7 message-content__title message-content__exclusive-title"
                        :text="props.row.data.title"
                        :line="1"
                      ></z-truncator>
                    </template>
                    <template v-else>
                      <div>
                        <z-truncator
                          class="is-size-7 message-content__title"
                          :text="props.row.data.title"
                        ></z-truncator>
                      </div>
                    </template>
                    <z-truncator
                      class="has-text-gray is-size-7"
                      :text="latestThread(props.row)"
                    ></z-truncator>
                  </div>
                </b-table-column>
                <b-table-column field="data.type" label="種別" width="78">
                  <div class="message-type has-text-centered">
                    <template
                      v-if="
                        $$ks.message.getType(props.row.data.type).value === 3 ||
                        $$ks.message.getType(props.row.data.type).value === 4
                      "
                    >
                      {{ $$ks.message.getType(props.row.data.type).en }}<br />
                      ({{ $$ks.message.getType(props.row.data.type).text }})
                    </template>
                    <template
                      v-else-if="
                        $$ks.message.getType(props.row.data.type).value === 2
                      "
                    >
                      {{ $$ks.message.getType(props.row.data.type).text }}
                    </template>
                  </div>
                </b-table-column>
                <b-table-column
                  field="data.latestSendDt"
                  label="送信日"
                  width="80"
                >
                  <div class="content">
                    <p class="is-dt is-size-7">
                      {{
                        $$calender$dateTimeFormat(props.row.data.latestSendDt)
                      }}
                    </p>
                    <p class="is-dt is-size-7">
                      {{ props.row.id }}
                    </p>
                  </div>
                </b-table-column>
              </template>

              <!--
              <template slot="detail" slot-scope="props">
                <div class="columns is-multiline realestateDetail">
                  <template v-for="(rsInfo, index) in props.row.realEstates">
                    <div class="column is-4" :key="rsInfo.id">
                      <div class="realestateDetail__title">
                        物件No.{{ index + 1 }}
                      </div>
                      <hr class="realestateDetail__hr" />
                      所在地：{{
                        $$ks.getPrefecture(rsInfo.data.prefecture).text
                      }}<br />
                      物件種別：{{
                        $$ks.realEstateType(rsInfo.data.realEstateType).text
                      }}<br />
                      <template v-if="rsInfo.data.realEstateType !== 10">
                        建物種別：{{
                          $$ks.getTypeOfBuildingType(rsInfo.data.buildingType)
                            .text
                        }}<br />
                        築年月：{{
                          $$calender$yearMonthFormat(
                            rsInfo.data.constructionDate
                          )
                        }}<br />
                      </template>
                      販売価格：{{ $$price$priceJPFormat(rsInfo.data.price)
                      }}<br />
                      <template v-if="props.row.data.type === 3">
                        提案売却金額：{{
                          $$price$priceJPFormat(
                            props.row.data.proposalSalePrice
                          )
                        }}<br />
                      </template>
                    </div>
                  </template>
                </div>
              </template>
              -->
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <hr />
            <b-pagination
              :total="total"
              :current.sync="page"
              :per-page="perPage"
              :range-before="3"
              :range-after="3"
              icon-prev="chevron-left"
              icon-next="chevron-right"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
            <hr />
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="csv出力">
        <b-button class="z-button" @click="onClickCsv" rounded
          >CSV出力</b-button
        >
        <z-input
          label="START"
          v-model="start"
          type="text"
          validate="required"
          isRequired
        >
        </z-input>
        <z-input
          label="END"
          v-model="end"
          type="text"
          validate="required"
          isRequired
        >
        </z-input>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import * as message from "@/modules/message/message";
import moment from "moment";
import "moment-timezone";

export default {
  name: "realtor-setteing-messages",
  data: function () {
    return {
      withReply: true,
      total: 0,
      activeTab: 0,
      isLoading: true,
      page: 1,
      perPage: 50,
      messages: [],
      start: 0,
      end: 1000,
    };
  },
  watch: {
    page: function () {
      this.init();
    },
  },
  // asyncComputed: {
  //   async messages() {
  //     this.isLoading = true;
  //     const loadingComponent = this.$loading.open();
  //     try {
  //       console.log("safsadfasdf");
  //       return await this.$$message$loadMessagesWithRealEstates(this.page, this.perPage);
  //     } finally {
  //       this.isLoading = false;
  //       loadingComponent.close();
  //     }
  //   }
  // },
  methods: {
    async onClickCsv() {
      const loadingComponent = this.$loading.open();
      try {
        const ret = await firebase.functions("message_makeCsvOfMessages", {
          start: this.start,
          end: this.end,
        });
        console.log(ret.data);

        let blob = new Blob([ret.data.rs], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "messages_" +
          moment(firebase.now().toDate())
            .tz("Asia/Tokyo")
            .format("YYYYMMDDHHmmss") +
          ".csv";
        link.click();
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
        loadingComponent.close();
      }
    },
    resendByInvestor(message) {
      // console.log(message.data.investor);
      const uid = message.data.investor;
      const r = message.data.threads.find((t) => t.from == uid);
      // console.log(r);
      // console.log(message.data.threads[0]);
      return r ? true : false;
    },
    async onClickSearch() {
      await this.init();
    },
    async init() {
      this.isLoading = true;
      const loadingComponent = this.$loading.open();
      try {
        const uid = this.me.user.id;
        const filter = {};

        const rs = await message.searchByfilter(
          {
            status: {
              withReply: this.withReply,
            },
          },
          1, // this.sortType,
          this.page,
          this.perPage
          // uid
        );
        this.messages = rs.hits.map((m) => m._source);
        this.total = rs.total.value;

        // this.messages = await this.$$message$loadMessagesWithRealEstates(
        //   this.page,
        //   this.perPage
        // );
        // this.messages = await this.$$message$loadMessagesWithRealEstates();
        // this.message = await me
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        this.isLoading = false;
        loadingComponent.close();
      }
    },
    notification(uid, message) {
      return this.$$message$JudgmentNotification(uid, message);
    },
    latestThread(message) {
      const m = message.data.threads.slice(-1)[0];
      const ln = m.contents.replace("\n", "");
      return ln;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.message-wrap {
  margin: 0 10px;
  &__list {
    width: 100%;
  }
}

.detail-button {
  margin-top: 18px;
}

.message-icon {
  margin-top: 18px;
}

.message-type {
  font-size: 12px;
}

.realestateDetail {
  margin: 10px;
  padding-left: 30px;
  font-size: 12px;
  &__title {
    font-size: 15px;
  }
  &__hr {
    margin: 5px auto;
  }
}
</style>
