import firebase from "@/plugins/firebase";

const docRef = () => firebase.db.collection("message_templates_v1");

export const load = async (templateId) => {
  const doc = await docRef().doc(templateId).get();

  if (doc.exists) {
    return {
      id: doc.id,
      data: doc.data(),
    };
  } else {
    return null;
  }
};

export const loads = async () => {
  const qs = await docRef().orderBy("ord").get();

  const ls = [];
  qs.forEach((doc) => {
    ls.push({
      id: doc.id,
      data: doc.data(),
    });
  });

  return ls;
};

export const save = async (template) => {
  if (template.id) {
    await docRef().doc(template.id).set(template.data, {
      merge: true,
    });
  } else {
    await docRef().add(template.data);
  }
};
