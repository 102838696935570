<template>
  <div>
    <div class="columns is-centered is-multiline is-gapless">
      <div class="column is-narrow">
        <section class="section signin-content">
          <div class="box signin-content__box">
            <div class="content has-text-centered title-wrap">
              <p>
                <span class="tittle is-size-3 title__main">WELCOME</span><br />
                <span class="title__sub">&lt;Business&gt;</span>
              </p>
            </div>
            <div class="content">
              <div class="z-input-vritical-warp form__mail">
                <z-input
                  v-model="email"
                  hideRequired
                  @blur="onBlurEmail"
                  as="メールアドレス"
                  type="email"
                  placeholder="メールアドレス"
                  maxlength="512"
                  :validate="'required|email'"
                  icon="email"
                  isRequired
                >
                </z-input>
              </div>
              <div class="z-input-vritical-warp form__password">
                <z-input
                  v-model="password"
                  @blur="onBlurPassword"
                  as="パスワード"
                  type="password"
                  placeholder="パスワード"
                  maxlength="128"
                  :validate="'required:true|min:8'"
                  icon-pack="fas"
                  icon="lock"
                  hideRequired
                  password-reveal
                  isRequired
                >
                </z-input>
              </div>
              <b-field>
                <router-link
                  class="password-remind-link is-pulled-right"
                  :to="{ name: 'auth-resetpassword' }"
                  >パスワードをお忘れの方はこちら</router-link
                >
              </b-field>
              <button
                class="button is-primary is-rounded is-fullwidth sign-in-button"
                @click="signIn"
              >
                ログイン
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import topImage from "@/assets/top/top.jpg";

// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import firebase from "@/plugins/firebase";

export default {
  name: "SignIn",
  data: function () {
    return {
      topImage: topImage,
      email: "",
      password: "",
    };
  },
  methods: {
    onBlurEmail(event) {
      if (event && this.email !== event.target.value)
        this.email = event.target.value;
    },
    onBlurPassword(event) {
      if (event && this.password !== event.target.value)
        this.password = event.target.value;
    },
    signIn: function () {
      const self = this;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          console.log(user);
        })
        .then(() => {
          const pageTransition = localStorage.getItem("transitionParam");
          if (pageTransition) {
            window.location.href = pageTransition;
            localStorage.removeItem("transitionParam");
          } else {
            window.location.href = "/";
          }
          // window.location.href = "/";
        })
        .catch((error) => {
          console.error(error);
          //ログインのエラーなのでSentryに送らない
          // self.$toast.open({
          //   message: "ログインに失敗しました。",
          //   position: "is-top",
          //   type: "is-danger"
          // });
          self.$$toast$openError("ログインに失敗しました。");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
.signup-content {
  height: 100%;
  padding: 8px;
  &__box {
    height: 100%;
    width: 420px;
    background-color: transparent;
    box-shadow: none;
    &__content {
      margin-top: 78px;
    }
    &__button {
      margin-top: 34px;
    }
    @include mobile {
      width: 100%;
    }
  }
  .content {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}

.signin-content {
  height: 100%;
  padding: 16px;
  &__box {
    height: 100%;
    width: 420px;
    border-radius: 0px;
    background-color: white;
    @include mobile {
      width: 100%;
    }
  }
  .content {
    .field {
      font-size: 12px;
      .password-remind-link {
        margin: 0px 0px 35px;
      }
    }
    .button {
      margin-top: 34px;
    }
  }
}

.title-wrap {
  margin-bottom: 30px;
}

.title {
  &__main {
    font-weight: bold;
  }
  &__sub {
    font-weight: bold;
    font-size: 18px;
  }
}

.form {
  &__mail {
    height: 65px;
  }
  &__password {
    height: 50px;
  }
}

.sign-in-button {
  background-color: #0257bf;
}
</style>
