<template>
  <div>
    <section>
      <ZHeader title="Consultant" sub="コンサルタント" showNotification>
        <template v-slot:rightIcons>
          <ZNotification color="#777" />
        </template>
      </ZHeader>
    </section>

    <div class="z-section">
      <div class="z-section__inner">
        <router-link :to="{ name: 'realtor-setting-basic' }">
          前に戻る
        </router-link>
        <hr />
        <div v-if="!isLoading">
          <template v-if="uid === 'new'">
            <div class="edit edit-profile">
              <h2 class="edit__title">アカウント</h2>
              <section class="edit__section">
                <z-input
                  label="メールアドレス"
                  v-model="user.data.base.email"
                  type="email"
                  maxlength="512"
                  :validate="'required|email'"
                  isRequired
                  icon="email"
                >
                </z-input>
              </section>
            </div>
            <UserBasicEditList :user="user"> </UserBasicEditList>
            <!--
              <hr />
              <EditUserProfile :uid="uid"> </EditUserProfile>
            -->
            <div class="buttons is-centered">
              <b-button
                class="button is-primary z-button is-wide"
                :disabled="$$validator$invalid"
                rounded
                @click="add"
              >
                コンサルタントを追加
              </b-button>
            </div>
          </template>
          <template v-else>
            <UserProfilePage :editable="false" :user="user"> </UserProfilePage>
            <hr />
            <template v-if="user.examination.data.status === 5">
              <div class="buttons is-right">
                <button
                  class="button z-button is-primary is-outlined is-rounded is-small"
                  @click="sendMail"
                >
                  登録メールを再送信する
                </button>
              </div>
            </template>
          </template>
          <!--
            <EditUserProfile :user="user"> </EditUserProfile>
            <hr />
            <EditUserRole :user="user"></EditUserRole>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "@/plugins/firebase";
// import axios from "axios-jsonp-pro";
// import { mapState } from "vuex";
// import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
//import UserBasic from "@/components/realtor/UserBasic";
//import UserProfile from "@/components/realtor//UserProfile";
import UserBasicEditList from "@/components/realtor/FormUserBasic";
import UserProfilePage from "@/components/realtor/UserProfilePage";

export default {
  name: "admin-setting-realtors-detail",
  components: {
    //UserBasic: UserBasic,
    //UserProfile: UserProfile,
    UserBasicEditList: UserBasicEditList,
    UserProfilePage: UserProfilePage,
  },
  data: function () {
    return {
      isLoading: true,
      activeTab: 0,
      realtor: null,
      user: null,
    };
  },
  computed: {
    uid: function () {
      return this.$route.params.uid;
    },
  },
  methods: {
    async sendMail() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$sendVerificationMail(this.auth.uid, this.user);

        this.$$toast$openSuccess("招待メールを送信しました");
        // this.$toast.open({
        //   message: "招待メールを送信しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError("招待メールの送信に失敗しました");
        // this.$toast.open({
        //   duration: 10000,
        //   message: "招待メールの送信に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async add() {
      const loadingComponent = this.$loading.open();
      try {
        const user = await this.$$realtor$createUser(
          this.auth.uid,
          this.realtor,
          this.user
        );
        await this.$$realtor$issueAccountUser(this.auth.uid, user);
        this.$router.go(-1);
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async save() {
      const loadingComponent = this.$loading.open();
      try {
        await this.$$realtor$saveUser(this.auth.uid, this.user);
        this.$router.go(-1);
        this.$$toast$openSuccess();
        // this.$toast.open({
        //   message: "登録に成功しました",
        //   type: "is-success",
        //   position: "is-top"
        // });
      } catch (error) {
        this.$raven.captureException(error);
        this.$$toast$openError();
        // this.$toast.open({
        //   duration: 10000,
        //   message: "登録に失敗しました",
        //   position: "is-top",
        //   type: "is-danger"
        // });
      } finally {
        loadingComponent.close();
      }
    },
    async beforeRouteUpdate(to, from, next) {
      next();
      await this.init();
    },
    async init() {
      this.isLoading = true;
      /* const loadingComponent = this.$loading.open(); */
      try {
        this.realtor = await this.$$realtor$loadRealtorById(
          this.me.user.company.id
        );
        if (this.uid === "new") {
          this.user = this.$$realtor$getNewUser();
        } else {
          this.user = await this.$$realtor$loadUser(this.uid);
        }
        this.isLoading = false;
      } catch (error) {
        this.$$error$catchLoadError(error);
      } finally {
        /* loadingComponent.close(); */
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
