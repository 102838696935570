<template>
  <div class="z-thumbnailListSelect">
    <ul class="z-thumbnailListSelect__list">
      <li
        class="z-thumbnailListSelect__item"
        v-for="(item, index) in value"
        :key="index"
      >
        <div class="z-thumbnailListSelect__item__photo">
          <ZImage :original="true" :value="item" ref="image" />
        </div>
        <p class="z-thumbnailListSelect__item__label">{{ item.title }}</p>
        <a
          class="z-thumbnailListSelect__item__delete"
          @click="removeImage(index)"
        >
          <b-icon icon="close" size="is-small" />
        </a>
      </li>
      <li
        class="z-thumbnailListSelect__item z-thumbnailListSelect__item"
        v-if="max === null || max > value.length"
      >
        <label class="z-thumbnailListSelect__item__add">
          <input
            type="file"
            name="image"
            accept="image/*"
            @change="loadImage"
          />
          <div class="addlabel">
            <b-icon icon="plus-circle" size="is-medium" />
            <p class="label">追加する</p>
          </div>
        </label>
      </li>
    </ul>
    <div class="z-thumbnailListSelect__cropArea" v-show="sourceImage">
      <ZImageCrop
        :sourceImage.sync="sourceImage"
        :croppedImage.sync="croppedImageBase64"
      />
    </div>
  </div>
</template>

<script>
import objectFitImages from "object-fit-images";

import ImageMixin from "@/mixins/common/image/Image.vue";
import ZImageCrop from "@/components/parts/ZImageCrop.vue";

export default {
  name: "ZThumbnailListSelect",
  components: {
    ZImageCrop,
  },
  mixins: [ImageMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    // 画像のタイトル
    title: {
      type: String,
      default: "",
    },
    // 追加できる画像の最大数
    max: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      sourceImage: "",
      croppedImageBase64: "",
    };
  },
  methods: {
    loadImage(event) {
      const file = event.target.files[0];

      if (!file.type.includes("image/")) {
        // TODO: エラーメッセージ
        console.error("Please select an image file");
        return;
      }

      if (typeof FileReader !== "function") {
        // TODO: エラーメッセージ
        console.error("Sorry, FileReader API not supported");
        return;
      }

      const reader = new FileReader();
      reader.onload = (fileEvent) => {
        this.sourceImage = fileEvent.target.result;
        event.target.value = ""; // 同一ファイルの再選択を許容するため、inputの値をリセット
      };
      reader.readAsDataURL(file);
    },
    removeImage(index) {
      const value = this.$$cloneDeep(this.value);
      value.splice(index, 1);

      this.$emit("input", value);
    },
  },
  watch: {
    croppedImageBase64: {
      async handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) {
          return;
        }
        const loadingComponent = this.$loading.open();
        try {
          // File形式に変換
          const file = this.$$images$base64pngToFileOutput("thumbnail", newVal); // this.base64pngToFileOutput(newVal);

          // 画像をアップロード
          const data = await this.$$image$upload("images", file);

          if (data.id) {
            const imageData = {
              ...this.$$investor$getNewImage(),
              id: data.id,
              title: this.title,
              description: data.description || "",
            };
            this.$emit("input", [...this.value, imageData]);

            this.croppedImageBase64 = "";
          }
        } catch (error) {
          this.$raven.captureException(error);
          this.$$toast$openError("画像のアップロードに失敗しました");
          // this.$toast.open({
          //   duration: 10000,
          //   message: "画像のアップロードに失敗しました",
          //   position: "is-top",
          //   type: "is-danger"
          // });
        } finally {
          loadingComponent.close();
        }
      },
    },
  },
  mounted() {
    objectFitImages();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

$gutter: 12px;

.z-thumbnailListSelect {
  margin-top: -$gutter;
  width: 100%;
  &__list {
    margin-left: -14px;
    margin-right: -14px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__item {
    width: 33.33%;
    max-width: 33.33%;
    margin-top: $gutter * 2;
    padding: 0 12px;
    position: relative;
    box-sizing: border-box;
    &__photo,
    &__add {
      width: 100%;
      display: block;
      position: relative;
      background-color: #f5f5f5;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      &:before {
        padding-top: 70%;
        display: block;
        content: "";
      }
      /deep/ img {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        object-fit: contain;
        font-family: "object-fit: contain;";
      }
    }
    &__add {
      padding-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border: 2px dashed #d8d8d8;
      cursor: pointer;
      @include sp {
        padding-bottom: 20px;
      }
      input {
        display: none;
      }
      .addlabel {
        padding-top: 24px;
        font-size: 14px;
        text-align: center;
        color: #777;
        .label {
          color: #777;
        }
        /deep/ span {
          color: #777;
        }
        @include sp {
          padding-top: 20px;
        }
      }
    }
    &__label {
      width: 100%;
      padding: 0 9px;
      font-size: 12px;
      line-height: 24px;
      background-color: #f4f4f4;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include sp {
        font-size: 10px;
        line-height: 20px;
      }
    }
    &__delete {
      width: 20px;
      height: 20px;
      top: -10px;
      right: 2px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: #f4f4f4;
      border-radius: 50%;
      /deep/ span {
        color: #707070;
      }
    }
  }
  &__cropArea {
    margin-top: 16px;
    padding: 0 21px;
  }
}
</style>
