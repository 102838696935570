<template>
  <div>
    <div class="auth-header">
      <Header />
    </div>
    <!--
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="@/assets/logo-title.png" width="112" height="28" />
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a
                href="/auth/signin"
                class="button is-primary is-rounded is-outlined"
              >
                Log In
              </a>
              <a href="/auth/investor/signup" class="button is-black">
                <strong>Sign Up</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    -->
    <div class="auth-content__wrapper">
      <div class="auth-content">
        <span
          class="auth-content__bgImg"
          :style="{ 'background-image': 'url(' + bgimg + ')' }"
        ></span>
        <div class="auth-content__parents">
          <div class="auth-content__contents">
            <router-view></router-view>
            <!--
              <div class="auth-warp">
              </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <div class="auth-footer">
      <Footer />
    </div>
    <div class="modal" :class="isIE ? 'is-active' : ''">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="auht-signin-modal-box__wrapper">
          <div class="box auht-signin-modal-box__inner">
            <p style="font-weight: bold">
              StockFormerはIE(Internet Explorer)を推奨しておりません。 <br />
              推奨環境をご確認の上、ご利用ブラウザの変更をお願いします。<br />
              <br />
            </p>
            <b-button
              class="z-button is-fullwidth"
              type="is-primary"
              size="is-small"
              rounded
              @click="onClickEnv"
            >
              推奨環境はこちら
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import topImage from "@/assets/top/top.jpg";
import Header from "@/components/layout/auth/Header";
import Footer from "@/components/layout/auth/Footer";
import bgimg1 from "@/assets/auth/bg1.png";
import bgimg2 from "@/assets/auth/bg2.jpg";

export default {
  name: "auth-index",
  components: {
    Footer: Footer,
    Header: Header,
  },
  data: function () {
    return {
      bgimg1: bgimg1,
    };
  },
  methods: {
    onClickEnv() {
      window.open("https://stockformer.com/recommendedenvironment");
    },
  },
  created() {
    // if (this.$$userAgent$isIE() === true) {
    //   this.$snackbar.open({
    //     message:
    //       '<p style="font-weight: bold;">StockFormerはInternet Explorerでのご利用を推奨していません</p>',
    //     type: "is-success",
    //     position: "is-top",
    //     actionText: "推奨環境はこちら",
    //     indefinite: true,
    //     onAction: () => {
    //       window.open("https://stockformer.com/recommendedenvironment");
    //       // window.open('https://www.google.com/intl/ja_jp/chrome/');
    //     }
    //   });
    // }
  },
  computed: {
    isIE() {
      return this.$$userAgent$isIE();
    },
    bgimg() {
      if (
        [
          "auth-realtor-application",
          "auth-realtor-application-confirm",
          "auth-realtor-register",
          "auth-relator-signin",
        ].some((n) => n === this.$route.name)
      ) {
        return bgimg2;
      } else {
        return bgimg1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.auth-content__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.auth-content {
  //background-image: url("https://stockformer.com/images/bg_kv.png");

  min-height: calc(100vh - 131px);
  @include sp {
    min-height: calc(100vh - 293px);
  }
  width: 100%;

  position: relative;
  background-color: white;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  overflow: hidden;
  &__bgImg {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0;
  }
  &__parents {
    display: flex;
    z-index: 1;
    /* 左右中央寄せ */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    /* 上下中央寄せ */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    @include sp {
      margin: 60px 0px 20px;
    }
    @include md {
      margin: 90px 0px 20px;
    }
    @include lg {
      margin: 100px 0px 20px;
    }
  }
  &__contents {
    background-color: rgba(#000, 0.6);
  }
}

.auht-signin-modal-box {
  &__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__inner {
    background-color: #fdebeb;
    max-width: 780px;
    margin-left: 18px;
    margin-right: 18px;
  }
}
// for IE11 hack
// _:lang(x)::-ms-backdrop,
// .auth-content__parents {
//   min-height: 100vh;
// }

//.auth-warp {
//  //max-width: 900px;
//  margin: 0px 30px;
//  @include mobile {
//    width: 100%;
//    margin: 0px;
//  }
//}

//.auth-header {
//  height: 81px;
//  width: 100%;
//  @include sp {
//    height: 51px;
//  }
//}

.auth-footer {
  top: 100vh;
  top: calc(var(--vh, 1vh) * 100 - 3.25rem);
}
</style>
